import React from "react"
import { Rate, RateProps } from "antd"

type FiveCircleRateProps = RateProps & {
  circleSize?: number
  defaultValue?: number
  disabled?: boolean
  count?: number
  color?: string
  strokeColor?: string
}

export default function FiveCircleRate({
  circleSize = 20,
  defaultValue = 0,
  disabled,
  count,
  color,
  strokeColor,
}: FiveCircleRateProps) {
  const renderCharacter = (
    input: { index?: number | undefined },
    size: number,
  ) => {
    const fill =
      // @ts-ignore
      defaultValue <= input?.index ? "none" : color ? color : "#D4F5C5"
    const stroke = strokeColor ? strokeColor : "#36505E"
    const strokeWidth = 1
    return (
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={size / 2 - strokeWidth}
          fill={fill}
          stroke={stroke}
          strokeWidth={strokeWidth}
        />
      </svg>
    )
  }

  return (
    <Rate
      className="!inline-flex"
      character={(obj) => renderCharacter(obj, circleSize)}
      disabled={disabled}
      defaultValue={defaultValue}
      count={count === 0 ? 5 : count ? count : 5}
    />
  )
}
