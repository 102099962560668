import React from "react"
import Lottie from "lottie-react"
import success_payment from "../../../assets/json/success_payment.json"
import { Avatar, Image, Layout, Skeleton } from "antd"
import dayjs from "dayjs"
import { usePublishArticle } from "../../skillGalaxy/hooks/usePublishArticle"
import { Moon } from "../../../assets/images"
import { useNavigate } from "react-router-dom"
// import CustomHeader from '../../../common/layout/CustomHeader'

interface NotFoundProps {
  // TODO
}

const NotFound: React.FC<NotFoundProps> = () => {
  const navigate = useNavigate()
  const { publishArticles } = usePublishArticle()

  return (
    <Layout>
      <div className="w-full h-screen bg-backdrop relative -mb-10">
        <div className="bg-backdrop absolute top-[870px] md:top-[55%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center  w-full">
          <div className=" text-white mb-1">
            <h1 className="text-[48px] font-thin">404</h1>
          </div>
          <div className="flex justify-center pt-10 pb-10">
            <div className="flex justify-center w-[120px] md:w-[130px] mx-auto md:mx-0 mt-8 md:mt-0">
              <Lottie className="" animationData={success_payment} />
            </div>
          </div>
          <div className="text-white tracking-wide text-lg mb-10">
            It looks like this page got lost in space
          </div>
          <div className="text-white tracking-wide text-lg mb-8">
            <h2 className="text-[29px] md:text-[35px] font-thin leading-10">
              While you’re here, why don’t you level up with Experts?
            </h2>
          </div>

          {/* CARD COMPONENTS */}
          <div className="mx-10 md:mx-32 mb-10">
            <div className="text-left grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {publishArticles?.isLoading
                ? Array.from({ length: 3 }, (_: any, index: number) => (
                    <div
                      key={index}
                      className="bg-white p-4 shadow-xl rounded-xl hover:-mt-2 transition-all cursor-pointer hover:border-b-primary"
                    >
                      <div className="h-[195px] bg-gray-100 mb-3">
                        <Skeleton.Image
                          active
                          className="!w-[100%] !h-[100%] !rounded-xl"
                        />
                      </div>
                      <div className="relative h-32">
                        <div className="flex items-center gap-2 mb-2  top-1">
                          <Skeleton
                            avatar
                            active={true}
                            paragraph={{ rows: 0 }}
                          />
                        </div>

                        <div className="flex items-center gap-3 text-[#919191] -mb-3 w-full">
                          <Skeleton.Button
                            active={true}
                            size={"small"}
                            shape={"default"}
                            block={true}
                          />
                        </div>

                        <div className="flex items-center gap-3 text-[#919191] -mb-3 absolute bottom-2 w-full">
                          <Skeleton.Button
                            active={true}
                            size={"small"}
                            shape={"default"}
                            block={true}
                          />
                        </div>
                      </div>
                    </div>
                  ))
                : publishArticles?.dataList
                    ?.slice(0, 3)
                    ?.map((article: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className="bg-white p-4 shadow-xl rounded-xl hover:-mt-2 transition-all cursor-pointer hover:border-b-primary"
                          onClick={() =>
                            navigate(`/skill/articles/${article?.slug}`)
                          }
                        >
                          <div className="h-[195px] bg-red-100 mb-3">
                            <Image
                              src={article?.img || Moon}
                              preview={false}
                              className="object-cover rounded-xl"
                              width={"100%"}
                              height={"100%"}
                            />
                          </div>
                          <div className="relative h-32">
                            <div className="flex items-center gap-2 mb-2 absolute top-1">
                              {article?.expert_data?.profile_picture ? (
                                <Avatar
                                  src={article?.expert_data?.profile_picture}
                                  size={"small"}
                                />
                              ) : (
                                <Avatar
                                  src={
                                    "https://stgreenskills001.blob.core.windows.net/default-avatars/7.png"
                                  }
                                  size={"small"}
                                />
                              )}
                              <p className="m-0 text-[12px]">
                                {article?.expert_data?.first_name}{" "}
                                {article?.expert_data?.last_name}
                              </p>
                            </div>
                            <div className="absolute top-9">
                              <h2 className="text-lg font-semibold text-[16px] line-clamp-2">
                                {article?.title}{" "}
                                {index === 2
                                  ? "Lorem ipsum dolor, sit amet consectetur adipisicing elit."
                                  : null}
                              </h2>
                            </div>

                            <div className="flex items-center gap-3 text-[#919191] -mb-3 absolute bottom-0">
                              <p>
                                {dayjs(article?.updated_at).format(
                                  "DD MMM YYYY",
                                )}
                              </p>
                              <span className="text-xl">•</span>
                              <p>{article?.read_time} read</p>
                            </div>
                          </div>
                        </div>
                      )
                    })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFound
