import React from "react"
import { HomeOutlined } from "@ant-design/icons"
import { PageContent, PageHeader } from "../../../common/layout"
import { useParams } from "react-router-dom"
import { useDetailPublicArticle } from "../hooks/usePublishArticle"
import DetailPublicArticleContent from "../components/article/DetailPublicArticleContent"
// import DetailPublicArticleRightContent from "../components/article/DetailPublicArticleRightContent"

interface SinglePublicArticleProps {}

const SinglePublicArticle: React.FC<SinglePublicArticleProps> = () => {
  const { slug } = useParams()

  const { articleResponse, queryArticleResponse, expertQuery } =
    useDetailPublicArticle(slug!)
  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      path: "/skill/articles",
      title: "Expert Articles",
    },
    {
      title: articleResponse?.data?.title ?? "Unknown",
    },
  ]

  return (
    <PageContent
      header={<PageHeader breadcrumbItems={items} showBack={true} />}
      content={
        <DetailPublicArticleContent
          articleResponse={articleResponse}
          queryArticleResponse={queryArticleResponse}
          expertQuery={expertQuery}
        />
      }
      // contentRightSidebar={
      //   <DetailPublicArticleRightContent articleResponse={articleResponse} />
      // }
    />
  )
}

export default SinglePublicArticle
