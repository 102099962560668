/* eslint-disable */

import { useContext, useState } from "react"
import { useMutation } from "react-query"
import { message } from "antd"
import { AccountContext } from "../../../../common/layout/PageLayout"
import instance from "../../../../config/config"
import { JobBoard } from "../../types/jobBoard"
import { errorMessage } from "../../../../common/utils/utils"

export const useApply = (data?: JobBoard) => {
  const user = useContext(AccountContext)
  const [isApply, setIsApply] = useState(false)
  const [stageApply, setStageApply] = useState(0)
  const [disqualify, setDisqualify] = useState(false)

  const { mutate: apply, isLoading: applyLoad } = useMutation({
    mutationFn: (val) => {
      return instance.post("/job-boards/apply", val)
    },
    mutationKey: "export-data-request",
  })

  const applyJob = (val: any, callback: () => void) => {
    apply(val, {
      onSuccess: (res) => {
        if (res.data.data.name === "disqualified") {
          setDisqualify(true)
        }
        callback()
      },
      onError: (err: any) => {
        errorMessage(
          err?.response?.data?.data ===
            "failed communicate with green skill service, with reason: mongo: no documents in result"
            ? "Waw, unfortunately this job not exist anymore"
            : err?.response?.data?.data,
          err,
        )
      },
    })
  }

  const hasEmptyStringValue = (obj: any) => {
    if (Object.keys(obj).length === 0) {
      return true
    }
    for (const key in obj) {
      if (obj[key] === "") {
        return true
      }
    }
    return false
  }

  const handlePassedStrength = () => {
    if (
      !data?.job?.screening_questions ||
      data?.job?.screening_questions?.length === 0
    ) {
      applyJob(
        {
          screening_questions: null,
          job_id: data?.job?.id,
          user_location: user?.data?.data?.country,
        },
        () => setStageApply((prev) => prev + 2),
      )
    } else {
      setStageApply((prev) => prev + 1)
    }
  }

  const handleScreening = (val: any) => {
    let answers = []
    for (const id in val) {
      answers.push({ id, answer: val[id] })
    }
    applyJob(
      {
        screening_questions: answers,
        job_id: data?.job?.id,
        user_location: user?.data?.data?.country,
      },
      () => setStageApply((prev) => prev + 1),
    )
  }

  return {
    drawer: {
      isApply,
      setIsApply,
    },
    stage: {
      stageApply,
      setStageApply,
    },
    user,
    helper: {
      hasEmptyStringValue,
    },
    action: {
      handlePassedStrength,
      handleScreening,
    },
    disqualify,
    applyLoad,
  }
}
