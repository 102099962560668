import React from "react"
import { message } from "antd"
import { addPostComment } from "../apis/feed-comment"
import { useMutation, useQueryClient } from "react-query"
import { AddComment } from "./AddComment"

interface AddCommentToPostProps {
  postId: string
}

const AddCommentToPost = ({ postId }: AddCommentToPostProps) => {
  const addComment = useMutation(addPostComment)
  const queryClient = useQueryClient()

  const onSubmit = async (data: any) => {
    message.loading({ content: "Commenting...", key: "create-comment" })
    await addComment.mutateAsync(
      {
        id: postId,
        comment: data,
      },
      {
        onSuccess: () => {
          // community-comment-list
          queryClient.invalidateQueries(["community-comment-list", postId])
          queryClient.invalidateQueries(["community-post-list"])
          message.success({
            content: "Commented!",
            key: "create-comment",
          })
        },
        onError: (error: any) => {
          message.error({
            content: error.response?.data?.message || "Something went wrong!",
            key: "create-comment",
          })
        },
      },
    )
  }
  return (
    <AddComment
      isSubmissionLoading={addComment.isLoading}
      submittedSuccess={addComment.isSuccess}
      onSubmit={onSubmit}
    />
  )
}

export default AddCommentToPost
