import {
  Card,
  Carousel,
  Skeleton,
  // Image
} from "antd"
import React from "react"
import { useNavigate } from "react-router-dom"
import { useCompanies } from "../../jobGalaxy/hooks/companies/useCompanies"
import { Card as CardCompany } from "../../jobGalaxy/components/companies/Card"
import "./custom.css"

interface HiringSectionProps {}

const HiringSection: React.FC<HiringSectionProps> = () => {
  const navigate = useNavigate()
  // const openModal = useModalStore((state) => state.openModal)

  const {
    dataList,
    isLoading,
    lastCardElementRef,
    handleFollowCompany,
    handleUnfollowCompany,
    loadingFollowUnfollow,
  } = useCompanies(false)

  const totalSlidesMobile = Math.ceil(dataList?.length / 2)
  const totalSlidesNonMobile = Math.ceil(dataList?.length / 8)

  return (
    <div className="bg-white text-backdrop">
      <div className="m-auto w-[87%] py-12">
        <h2 className="font-semibold text-[40px] text-center mb-12">
          See who is hiring
        </h2>
        <Card className="hidden md:inline rounded-xl shadow-custom-sm bg-[#FAFAFA] p-0">
          <div className="mt-0 -mb-5">
            <Carousel autoplay dots={{ className: "" }}>
              {isLoading ? (
                <div className="p-3 mb-10">
                  <div className="grid md:grid-cols-4 gap-6">
                    {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
                      <div
                        key={i}
                        className="shadow-md rounded-xl px-3 py-5 flex flex-col justify-center space-y-2 hover:shadow-lg cursor-pointer bg-white"
                      >
                        <div className="flex justify-between w-full items-center gap-3 ">
                          <Skeleton.Button active />
                          <Skeleton.Button active />
                        </div>
                        <Skeleton
                          className=""
                          title={false}
                          active
                          paragraph={{ rows: 1 }}
                        />
                        <Skeleton.Image
                          active
                          className="object-cover rounded-lg mx-auto"
                        />
                        <Skeleton
                          className=""
                          title={false}
                          active
                          paragraph={{ rows: 1 }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                [...Array(totalSlidesNonMobile)].map((_, slideIndex) => {
                  const startIndex = slideIndex * 8
                  return (
                    <div className="p-3 mb-10" key={slideIndex}>
                      <div className="grid md:grid-cols-4 gap-6">
                        {dataList
                          ?.slice(startIndex, startIndex + 8)
                          .map((expert, cardIndex) => {
                            return (
                              <CardCompany
                                observ={lastCardElementRef}
                                data={expert}
                                key={cardIndex}
                                isFollow={false}
                                handleFollowCompany={handleFollowCompany}
                                handleUnfollowCompany={handleUnfollowCompany}
                                loadingFollowUnfollow={loadingFollowUnfollow}
                                onNavigate={() =>
                                  navigate(`/job/companies/${expert.id}`)
                                }
                              />
                            )
                          })}
                      </div>
                    </div>
                  )
                })
              )}
            </Carousel>
          </div>
        </Card>
        <Card className="inline md:hidden rounded-xl shadow-custom-sm bg-[#FAFAFA] p-0">
          <div className="mt-0 -mb-5">
            <Carousel autoplay dots={{ className: "" }}>
              {[...Array(totalSlidesMobile)].map((_, slideIndex) => {
                const startIndex = slideIndex * 2
                return (
                  <div className="p-3 mb-10" key={slideIndex}>
                    <div className="grid md:grid-cols-4 gap-6">
                      {dataList
                        ?.slice(startIndex, startIndex + 2)
                        .map((expert, cardIndex) => {
                          return (
                            <CardCompany
                              observ={lastCardElementRef}
                              data={expert}
                              key={cardIndex}
                              isFollow={false}
                              handleFollowCompany={handleFollowCompany}
                              handleUnfollowCompany={handleUnfollowCompany}
                              loadingFollowUnfollow={loadingFollowUnfollow}
                              onNavigate={() =>
                                navigate(`/job/companies/${expert.id}`)
                              }
                            />
                          )
                        })}
                    </div>
                  </div>
                )
              })}
            </Carousel>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default HiringSection
