import { Button, Form, Input, message, Modal, Select } from "antd"
import React, { useContext, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { z } from "zod"
import { AccountContext } from "../../../../common/layout/PageLayout"
import { zodResolver } from "@hookform/resolvers/zod"
import { useLanguage } from "../../../missionControl/hooks/cv-builder/useLanguage"
import { usePostReqExpert } from "../../../skillGalaxy/apis/rest"
import ErrorMessageBuilder from "../../../../common/components/ErrorMessageBuilder"
// import { useQueryClient } from "react-query"
import useCommunityModalsStore from "../../store/useCommunityModalsStore"
import ProfileInReview from "./ProfileInReview"
import { Role } from "../stores/useRoleStore"
import { useQueryClient } from "react-query"

export const schema = z.object({
  linkedin_link: z.string().url(),
  english_proficiency: z.number(),
  article_link: z.string().url(),
  domain: z.object({
    id: z.string().min(1, { message: "Skill category is required." }),
    name: z.string().min(1, { message: "Skill category is required." }),
  }),
  justification: z.string(),
})

export type TBecomeAnExpertModal = z.infer<typeof schema>

interface ModalBecomeAnExpertProps {
  is_pending: boolean
}

const ModalBecomeAnExpert: React.FC<ModalBecomeAnExpertProps> = ({
  is_pending,
}) => {
  const becomeAnExpertModal = useCommunityModalsStore(
    (state) => state.becomeAnExpertModal,
  )

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isDirty, errors },
  } = useForm<TBecomeAnExpertModal>({
    resolver: zodResolver(schema),
    defaultValues: {
      article_link: "",
      english_proficiency: 0,
      justification: "",
      linkedin_link: "",
      domain: undefined,
    },
  })
  const account = useContext(AccountContext)
  const accountData = account?.data?.data
  const { proficiencies } = useLanguage()
  const reqExpertMutation = usePostReqExpert()
  const [isSubmittedSuccess, setIsSubmitSuccess] = useState(false)
  const queryClient = useQueryClient()

  const onSubmit = async (data: TBecomeAnExpertModal) => {
    message.loading({
      content: "Submitting expert request...",
      key: "make-expert",
    })

    await reqExpertMutation.mutateAsync(data, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["profile-status"])
        message.success({
          content:
            "We have submitted your request to become an Expert on SkilledIn. Your on your way to becoming a distinguished member of the green community!",
          key: "make-expert",
        })
        setIsSubmitSuccess(true)
      },
      onError: () => {
        message.open({
          key: "make-expert",
          duration: 5,
          content: React.createElement(ErrorMessageBuilder, {
            message:
              "We encountered an issue while attempting to submit your request to become an expert. If the problem continues, please refresh the page.",
            includeReportAt: true,
          }),
          type: "error",
        })
      },
    })
  }

  return (
    <Modal
      centered
      open={becomeAnExpertModal.isOpen}
      onCancel={() => becomeAnExpertModal.close()}
      onOk={() => {}}
      footer={null}
    >
      {!isSubmittedSuccess || !is_pending ? (
        <>
          <div className="text-center">
            <h2 className="text-2xl font-bold">Become an Expert</h2>
          </div>

          <Form className="relative my-6" layout="vertical">
            <div className="grid grid-cols-2 gap-4">
              <Form.Item label="Email" className="col-span-1">
                <Input.TextArea
                  id="email"
                  disabled
                  className="font-default rounded-md"
                  placeholder="Email"
                  defaultValue={accountData?.email}
                  autoSize={{ minRows: 1, maxRows: 3 }}
                />
              </Form.Item>
              <Form.Item
                label="Link"
                className="col-span-1"
                validateStatus={errors.linkedin_link ? "error" : ""}
                help={errors.linkedin_link?.message}
              >
                <Controller
                  name="linkedin_link"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      id="linkedin_link"
                      className="font-default rounded-md"
                      placeholder="Your linkedin profile"
                    />
                  )}
                />
              </Form.Item>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <Form.Item
                label="English Proficiency"
                validateStatus={errors.english_proficiency ? "error" : ""}
                help={errors.english_proficiency?.message}
                className="col-span-1"
              >
                <Controller
                  name="english_proficiency"
                  control={control}
                  render={(field) => (
                    <Select
                      {...field}
                      id="english_proficiency"
                      className="w-full !rounded-md"
                      placeholder="English Proficiency"
                      onChange={(data) => setValue("english_proficiency", data)}
                      options={proficiencies?.data?.map(
                        (val: any, index: number) => ({
                          label: val.name,
                          value: index + 1,
                          title: val.level,
                        }),
                      )}
                    />
                  )}
                />
              </Form.Item>
              <Form.Item
                label="Expert Domain"
                className="col-span-1"
                validateStatus={errors.domain ? "error" : ""}
                help={errors.domain?.message}
              >
                <Controller
                  name="domain"
                  control={control}
                  render={(field) => (
                    <Select
                      {...field}
                      id="domain"
                      className="w-full !rounded-md"
                      placeholder="Your knowledge domain (max 1)"
                      onChange={(data) => setValue("domain", data)}
                      options={proficiencies?.data?.map(
                        (val: any, index: number) => ({
                          label: val.name,
                          value: index + 1,
                          title: val.level,
                        }),
                      )}
                    />
                  )}
                />
              </Form.Item>
            </div>
            <Form.Item
              label="Sample Article Link"
              className="col-span-1"
              validateStatus={errors.linkedin_link ? "error" : ""}
              help={errors.linkedin_link?.message}
            >
              <Controller
                name="article_link"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    id="linkedin_link"
                    className="font-default rounded-md"
                    placeholder="Drop the link of your sample article"
                  />
                )}
              />
            </Form.Item>
            <Form.Item
              label="What expert knowledge would you like to share? And why?"
              className="col-span-1"
              validateStatus={errors.justification ? "error" : ""}
              help={errors.justification?.message}
            >
              <Controller
                name="justification"
                control={control}
                rules={{
                  required: "Justification is required",
                  minLength: {
                    value: 10,
                    message: "Justification must be at least 10 characters",
                  },
                }}
                render={({ field }) => (
                  <>
                    <Input.TextArea
                      {...field}
                      id="justification"
                      className="font-default rounded-md"
                      placeholder="Write your answer....."
                      autoSize={{ minRows: 4, maxRows: 5 }}
                    />
                    {errors.justification && (
                      <p className="m-0 text-red-500">
                        {errors.justification.message}
                      </p>
                    )}
                  </>
                )}
              />
            </Form.Item>
            <Button
              className="rounded-md"
              block
              onClick={handleSubmit(onSubmit)}
              type="primary"
              loading={reqExpertMutation?.isLoading}
              disabled={!isDirty}
            >
              Apply to be an Expert
            </Button>
          </Form>
        </>
      ) : (
        <ProfileInReview
          role={Role.Expert}
          onClick={() => becomeAnExpertModal.close()}
        />
      )}
    </Modal>
  )
}

export default ModalBecomeAnExpert
