import { useEffect, useMemo, useState } from "react"
import instance from "../../../config/config"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { handlingError } from "../../../utils/handling"
import { message } from "antd"
import { useCountriesInfinity } from "../../../hooks/useCountries"
import { useListCompanySectorsInfinity } from "./useListCompanySectors"
import { errorMessage } from "../../../common/utils/utils"

const useListCompanies = (userID, isFollowed) => {
  const [search, setSearch] = useState("")
  const [searchDebounce, setSearchDebounce] = useState("")
  const [searchCountry, setSearchCountry] = useState("")
  const [searchCompanySector, setSearchCompanySector] = useState("")
  const [hq, setHq] = useState("")
  const [sector, setSector] = useState("")
  const [size, setSize] = useState("")
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [optionCountries, setOptionCountries] = useState([])
  const [optionCompanySectors, setOptionCompanySectors] = useState([])
  const [isDropdownHQOpen, setIsDropdownHQOpen] = useState(false)
  const [isDropdownSizeOpen, setIsDropdownSizeOpen] = useState(false)
  const [isDropdownSectorOpen, setIsDropdownSectorOpen] = useState(false)
  const [isFilterByUserFollower, setIsFilterByUserFollower] = useState(
    isFollowed ? true : false,
  )

  const queryKey = useMemo(
    () => [
      "companies-list",
      {
        limit,
        page,
        name: searchDebounce,
        hq,
        sector,
        size,
        user_id: userID,
        is_follow: isFilterByUserFollower,
      },
    ],
    [
      limit,
      page,
      searchDebounce,
      hq,
      sector,
      size,
      userID,
      isFilterByUserFollower,
    ],
  )

  const fetchListCompanies = async () => {
    try {
      const { data } = await instance.get(`/companies`, {
        params: {
          limit,
          page,
          name: searchDebounce,
          hq,
          sector,
          size: size.replace(" ", "%20"),
          user_id: userID,
          is_follow: isFilterByUserFollower,
        },
      })
      return data
    } catch (error) {
      return handlingError(error?.response?.data?.message, error)
    }
  }

  const { data, isLoading } = useQuery(queryKey, fetchListCompanies)
  const listCompanies = data?.data
  const isListCompaniesLoading = isLoading

  const handleSearchCompany = (e) => {
    setSearch(e.target.value)
    setTimeout(() => {
      setSearchDebounce(e.target.value)
    }, 2000)
  }

  // countries infinity
  const handleFilterByHQ = (value, option) => {
    if (option.key !== "input-search") {
      if (option.key === "all") {
        setHq("")
        message.info(`Removed Filter`)
      } else {
        message.info(`Filter by ${value}`)
        setHq(value)
      }
      setIsDropdownHQOpen(false)
    }
    setIsDropdownHQOpen(true)
  }

  const {
    data: countries,
    isLoading: isLoadingCountries,
    fetchNextPage: fetchNextPageCountries,
    isFetchingNextPage: isLoadingFetchingCountriesNextPage,
  } = useCountriesInfinity(searchCountry)

  useEffect(() => {
    if (countries?.pages) {
      setOptionCountries(() =>
        countries?.pages?.flatMap((page) => page.data.data.list),
      )
    }
  }, [countries?.pages])

  const handleScrollCountry = (e) => {
    const { target } = e
    const maxScrollPosition =
      target.scrollHeight - target.clientHeight - target.clientHeight * 0.1

    if (target.scrollTop >= maxScrollPosition && !isLoading) {
      fetchNextPageCountries()
    }
  }

  // company sectors infinity

  const handleFilterByCS = (value, option) => {
    if (option.key !== "input-search") {
      if (option.key === "all") {
        setSector("")
        message.info(`Removed Filter`)
      } else {
        setSector(value)
        message.info(`Filter by ${value}`)
      }
      setIsDropdownSectorOpen(false)
    }
    setIsDropdownSectorOpen(true)
  }

  const {
    data: companySectors,
    isLoading: isLoadingCompanySectors,
    fetchNextPage: fetchNextPageCompanySectors,
    isFetchingNextPage: isLoadingFetchingCompanySectorsNextPage,
  } = useListCompanySectorsInfinity(searchCompanySector)

  useEffect(() => {
    if (companySectors?.pages) {
      setOptionCompanySectors(() =>
        companySectors?.pages?.flatMap((page) => page.data.data.list),
      )
    }
  }, [companySectors?.pages])

  const handleScrollCompanySectors = () => {
    fetchNextPageCompanySectors()
  }

  // company Size
  const handleFilterBySize = (value, option) => {
    if (option.key !== "input-search") {
      if (option.key === "all") {
        setSize("")
        message.info(`Removed Filter`)
      } else {
        setSize(value)
        message.info(`Filter by ${value}`)
      }
      setIsDropdownSizeOpen(false)
    }
    setIsDropdownSizeOpen(true)
  }

  const followCompay = async (newData) => {
    try {
      const response = await instance.post("/companies/favourites", newData)
      return response.data
    } catch (error) {
      throw new Error("Failed to follow company")
    }
  }
  const unFollowCompay = async (companyID) => {
    try {
      const response = await instance.delete(
        `/companies/favourites/${userID}/${companyID}`,
      )
      return response.data
    } catch (error) {
      throw new Error("Failed to follow company")
    }
  }

  const queryClient = useQueryClient()

  const addDataMutationFollowCompany = useMutation(followCompay, {
    onSuccess: () => {
      queryClient.invalidateQueries("companies-list")
    },
  })
  const addDataMutationUnfollowCompany = useMutation(unFollowCompay, {
    onSuccess: () => {
      queryClient.invalidateQueries("companies-list")
    },
  })

  const handleFollowCompany = async (newData) => {
    const data = {
      company_id: newData.id,
      company_name: newData.name,
      user_id: userID,
    }

    try {
      await addDataMutationFollowCompany.mutateAsync(data)
      message.success("Success follow company")
    } catch (error) {
      errorMessage("Failed to follow company")
    }
  }

  const handleUnFollowCompany = async (companyID) => {
    try {
      await addDataMutationUnfollowCompany.mutateAsync(companyID)
      message.success("Success unfollow company")
    } catch (error) {
      errorMessage("Failed to unfollow company")
    }
  }

  return {
    listCompanies,
    isListCompaniesLoading,
    setPage,
    setLimit,
    page,
    limit,
    handleSearchCompany,
    search,
    searchDebounce,
    setSearch,

    //country
    hq,
    handleFilterByHQ,
    optionCountries,
    handleScrollCountry,
    isLoadingFetchingCountriesNextPage,
    isLoadingCountries,
    searchCountry,
    setSearchCountry,
    isDropdownHQOpen,
    setIsDropdownHQOpen,

    //company-sectors
    sector,
    handleFilterByCS,
    optionCompanySectors,
    handleScrollCompanySectors,
    isLoadingFetchingCompanySectorsNextPage,
    isLoadingCompanySectors,
    searchCompanySector,
    setSearchCompanySector,
    isDropdownSectorOpen,
    setIsDropdownSectorOpen,

    // company size
    handleFilterBySize,
    size,
    isDropdownSizeOpen,
    setIsDropdownSizeOpen,

    // follower
    setIsFilterByUserFollower,
    handleFollowCompany,
    handleUnFollowCompany,
  }
}

export default useListCompanies
