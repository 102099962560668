import React from "react"
import Search from "antd/es/input/Search"
import { Button } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { debounce } from "../../utils/debounce"
import { SearchProps } from "antd/es/input"

interface CustomSearchProps extends SearchProps {
  setGreenSkillSearch?: (value: string | null) => void
  withEnterButton: boolean
  defaultValueNull?: boolean
}

export function CustomSearch({
  setGreenSkillSearch,
  withEnterButton = true,
  defaultValueNull = true,
  ...props
}: CustomSearchProps) {
  const handleSkillSearch = debounce((value: string) => {
    if (setGreenSkillSearch) {
      setGreenSkillSearch(
        value === null || value?.trim() === "" || value === undefined
          ? defaultValueNull
            ? null
            : ""
          : value,
      )
    }
  }, 1000)

  return (
    <Search
      placeholder={
        props?.placeholder ? props?.placeholder : "Input Search Text"
      }
      className={`min-w-full md:w-2/5 md:min-w-[518px] ${props?.className}`}
      allowClear
      enterButton={
        withEnterButton ? (
          <Button type="primary" style={{ backgroundColor: "#009ACE" }}>
            <SearchOutlined /> Search
          </Button>
        ) : (
          false
        )
      }
      size="middle"
      onSearch={withEnterButton ? handleSkillSearch : undefined}
      onChange={
        withEnterButton ? undefined : (e) => handleSkillSearch(e.target.value)
      }
      {...props}
    />
  )
}
