import { useState } from "react"
import { debounce } from "../utils/debounce"
import { useQuery } from "react-query"
import instance from "../config/config"
import { AxiosResponse } from "axios"
import { message } from "antd"

export type Location = {
  type: string
  id: string
  score: number
  matchConfidence: {
    score: number
  }
  address: {
    streetName: string
    municipalitySubdivision: string
    municipality: string
    municipalitySecondarySubdivision: string
    countrySubdivision: string
    countrySubdivisionName: string
    countrySubdivisionCode: string
    postalCode: string
    countryCode: string
    country: string
    countryCodeISO3: string
    freeformAddress: string
    localName: string
  }
  position: {
    lat: number
    lon: number
  }
  viewport: {
    topLeftPoint: {
      lat: number
      lon: number
    }
    btmRightPoint: {
      lat: number
      lon: number
    }
  }
}

export const useSearchLocation = () => {
  const [query, setQuery] = useState("")
  const [visible, setVisible] = useState(true)
  const [isSelected, setIsSelected] = useState(true)
  const [value, setValue] = useState<any>("")

  const handleSearch = debounce((value: string) => {
    setQuery(value)
  }, 500)

  const handleSelect = (value: string) => {
    setIsSelected(true)
    setValue(value)
  }
  const { data, isLoading } = useQuery(
    ["search-location", query],
    () =>
      instance.get<AxiosResponse<Location[]>>("/commons/az-locations", {
        params: { search: query },
      }),

    {
      onError: (error: any) =>
        message.error({
          content: error?.response?.data?.data,
          key: "search-location-" + query,
        }),
      enabled: !!query,
    },
  )

  return {
    data: data?.data?.data,
    isLoading,
    handleSearch,
    handleSelect,
    setVisible,
    visible,
    state: {
      value,
      isSelected,
      setIsSelected,
      setValue,
    },
  }
}
