import { message } from "antd"
import { ApiResponsePaginatedInfinite } from "../common/types/ApiResponse"
import instance from "../config/config"
import { Item } from "./useReferences"
import { useInfiniteQuery } from "react-query"

export const useGreenSkillCategoriessInfinity = (searchParam: string) => {
  const handleFetchError = (error: any) => {
    message.error({
      content: `An error occurred while fetching job sector: ${error?.data}`,
      key: searchParam,
    })
  }

  const fetchInfinity = async ({
    pageParam = 1,
    search,
  }: {
    pageParam: number
    search: string
  }) => {
    return instance.get<ApiResponsePaginatedInfinite<Item>>(
      "green-skills/categories",
      {
        params: {
          page: pageParam,
          limit: 10,
          search: search,
        },
      },
    )
  }
  return useInfiniteQuery(
    ["gs-category-infinity", searchParam],
    async ({ pageParam }) => fetchInfinity({ pageParam, search: searchParam }),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
      getNextPageParam: (lastPage) =>
        lastPage?.data?.data?.pagination?.page <
        lastPage?.data?.data?.pagination?.total_page
          ? lastPage?.data?.data?.pagination?.page + 1
          : undefined,
    },
  )
}
