import { Skeleton } from "antd"
import React from "react"

interface ArticleDetailRightContentProps {
  articleResponse: any
  addAction?: any
}

const ArticleDetailRightContent: React.FC<ArticleDetailRightContentProps> = ({
  articleResponse,
  addAction,
}) => {
  return (
    <div className="space-y-5 top-5 sticky">
      {/* <div className="bg-white-custom py-3 px-4 shadow-custom-sm rounded-xl">
        {articleResponse?.isLoading ? (
          <Skeleton.Input
            active={true}
            className="!w-full !rounded-lg my-1"
            size={"small"}
          />
        ) : (
          <p className="tracking-widest uppercase text-xs text-yellow-600">
            {articleResponse?.data?.green_skill_category?.name}
          </p>
        )}

        <div className="flex flex-wrap gap-1">
          {articleResponse?.isLoading
            ? Array.from({ length: 3 }, (_: any, index: number) => (
                <Skeleton.Input
                  active={true}
                  key={index}
                  className="!w-1/3 !rounded-lg my-1 mr-4"
                  size={"small"}
                />
              ))
            : articleResponse?.data?.green_skills?.map(
                (skill: any, index: number) => {
                  return (
                    <Tag
                      onClick={() =>
                        navigate(`/skill/green-skills/${skill?.id}`)
                      }
                      className="!cursor-pointer rounded-full"
                      key={index}
                    >
                      {skill?.name}
                    </Tag>
                  )
                },
              )}
        </div>

        {articleResponse?.isLoading ? (
          <div className="flex items-center gap-3 mt-4 mb-2">
            <Skeleton.Button block active />
            <Skeleton.Button block active />
          </div>
        ) : (
          <div className="flex items-center gap-3 mt-4 mb-2">
            <Button
              onClick={() => {
                
                  "**click",
                  articleResponse?.data?.status === "draft",
                )
                if (articleResponse?.data?.status === "draft") {
                  addAction?.handleSubmit(addAction?.handleEditArticle)()
                } else {
                  addAction?.handleSubmit(addAction?.handleSaveChangesArticle)()
                }
              }}
              block
            >
              {articleResponse?.data?.status === "draft"
                ? " Save as draft"
                : "Save changes"}
            </Button>
            <Button
              onClick={() =>
                addAction?.handleSubmit(addAction?.handlePublishArticle)()
              }
              block
              type="primary"
            >
              Publish
            </Button>
          </div>
        )}
      </div> */}
      <div className="bg-white-custom py-3 px-4 shadow-custom-sm rounded-xl">
        {articleResponse?.isLoading ? (
          <Skeleton.Input
            active={true}
            className="!w-full !rounded-lg my-1"
            size={"small"}
          />
        ) : (
          <p className="tracking-widest uppercase text-xs text-yellow-600">
            Contents
          </p>
        )}

        {articleResponse?.isLoading ? (
          <Skeleton className="mt-3" active paragraph={{ rows: 1 }} />
        ) : (
          <ul className="">
            {addAction?.sectionData?.map((section: any, index: number) => {
              return (
                <li className="rounded-full" key={index}>
                  {section?.section_title}
                </li>
              )
            })}
          </ul>
        )}
      </div>
    </div>
  )
}

export default ArticleDetailRightContent
