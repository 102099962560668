/* eslint-disable */

import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import instance from "../../../../config/config"
import { debounce } from "../../../../utils/debounce"
import { AxiosError, AxiosResponse } from "axios"
import { message } from "antd"
import { Sector } from "../../interface/sector"
import { errorMessage } from "../../../../common/utils/utils"

interface IParams {
  page: number
  limit?: number
  search?: string | null
  sectors?: string | null
}

interface IParamsSector {
  page: number
  limit?: number
  name?: string | null
}
interface ErrorJobs {
  error: boolean
  code: number
  message: string
  data: any
}

interface SuccessSectors {
  error: boolean
  code: number
  message: string
  data: {
    list: Sector[]
    total_data: number
    pagination: { total_page: number; page: number; limit: number }
  }
}
export const useCompanySector = () => {
  const [params, setParams] = useState<IParamsSector>({
    page: 1,
    limit: 20,
    name: null,
  })

  const [dataList, setDataList] = useState<Sector[]>([])
  const [total, setTotal] = useState<number>(0)

  const { data, isLoading } = useQuery<
    AxiosResponse<SuccessSectors>,
    AxiosError<ErrorJobs>
  >(
    ["companySector", params],
    () => instance.get(`/references/company-sectors`, { params }),
    {
      onError: (err) =>
        errorMessage(err?.response?.data?.data || "internal server error", err),
    },
  )

  const handleClear = () => {
    if (params?.page !== 1 || params?.name !== "") {
      setParams((prevParams) => ({ ...prevParams, name: "", page: 1 }))
    }
  }

  const handleSearch = debounce((value: string) => {
    setParams((prev) => ({ ...prev, name: value, page: 1 }))
  }, 1000)

  const handleScroll = (e: React.UIEvent) => {
    const { target } = e
    if (target instanceof HTMLElement) {
      const maxScrollPosition =
        target.scrollHeight - target.clientHeight - target.clientHeight * 0.1

      if (
        target.scrollTop >= maxScrollPosition &&
        !isLoading &&
        total !== dataList.length
      ) {
        setParams((prev) => ({ ...prev, page: prev.page + 1 }))
      }
    }
  }

  const handleChageParams = (newParams: IParams) => {
    setParams((prev) => ({ ...prev, ...newParams }))
  }
  useEffect(() => {
    if (!isLoading) {
      if (params.page === 1) {
        setDataList(data?.data.data.list || [])
        setTotal(data?.data.data.total_data || 0)
      } else if (
        dataList?.[dataList?.length - 1]?.id !==
        data?.data.data.list?.[data?.data.data.list?.length - 1]?.id
      ) {
        setDataList((prev) => [...prev, ...(data?.data.data.list || [])])
        setTotal(data?.data.data.total_data || 0)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data.data.list])

  return {
    dataList,
    isLoading,
    handleChageParams,
    handleClear,
    handleScroll,
    params,
    handleSearch,
  }
}
