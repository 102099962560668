import { useEffect, useState } from "react"
import { useFetchMyArticleList } from "../apis/rest_article"
import { MyArticleResponseTypes } from "../types/response"

export const useExpertProfile = () => {
  const [isEditView, setIsEditView] = useState(false)
  const [dataList, setDataList] = useState<MyArticleResponseTypes[]>([])
  const [limit, setLimit] = useState<number | undefined>(100)
  const [page, setPage] = useState<number | undefined>(1)
  const [totalData, setTotalData] = useState<number>(0)

  const restMyArticleResponse = useFetchMyArticleList({
    limit,
    page,
    status: "publish",
  })

  useEffect(() => {
    if (!restMyArticleResponse.isLoading) {
      if (page === 1) {
        setDataList(restMyArticleResponse.data?.data?.data?.list ?? [])
        setTotalData(restMyArticleResponse.data?.data?.data?.total_data ?? 0)
      } else {
        setDataList((prev) => [
          ...prev,
          ...(restMyArticleResponse.data?.data?.data?.list ?? []),
        ])
        setTotalData(restMyArticleResponse.data?.data?.data?.total_data ?? 0)
      }
    }
    //
  }, [restMyArticleResponse.data?.data?.data?.list])

  return {
    editAction: {
      isEditView,
      setIsEditView,
    },
    myArticles: {
      dataList,
      isLoading: restMyArticleResponse.isLoading,
      totalData,
      limit,
      page,
      setLimit,
      setPage,
    },
  }
}
