import React from "react"
import { Image, Progress, Select, Skeleton, Spin } from "antd"
import { Moon } from "../../../../../assets/images"
import {
  CaretUpOutlined,
  CaretDownOutlined,
  CloseCircleOutlined,
  DotChartOutlined,
  LoadingOutlined,
  CloseOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons"
import useGreenCareer from "../../../hooks/cv-builder/useGreenCareer.ts"
import { useNavigate } from "react-router-dom"

const CVGreenCareer = () => {
  const {
    greencareerResp,
    greencareerJobRolesResp,
    greencareerChartResp,
    greencareersDetailResp,
  } = useGreenCareer()
  const navigate = useNavigate()

  interface Child {
    id: string
    name: string
    value: number
  }

  interface Result {
    children: Child[]
  }

  const result: Result = {
    children: [],
  }

  greencareerChartResp?.greencareerCharts?.forEach((item: any) => {
    return result.children.push({
      id: item.id,
      name: item.name,
      value:
        item.total_course === 0 ? item.total_course + 1 : item.total_course,
    })
  })

  const dropDownContent = () => {
    const filteredJobRoles = greencareerJobRolesResp?.jobroles?.filter(
      (item: any) =>
        item?.name
          ?.toLowerCase()
          ?.includes(greencareerJobRolesResp?.search?.toLowerCase()),
    )
    const filteredJobRolesAdded = greencareerResp?.greencareers?.filter(
      (item: any) =>
        item?.name
          ?.toLowerCase()
          ?.includes(greencareerJobRolesResp?.search?.toLowerCase()),
    )
    return (
      <div className={`min-h-auto max-h-64 overflow-y-scroll text-backdrop`}>
        <div>
          {filteredJobRoles?.length === 0 &&
            filteredJobRolesAdded?.length === 0 && (
              <div className={`bg-secondaryYellow`}>
                Not found! Press enter to create!
              </div>
            )}

          {filteredJobRoles && filteredJobRoles?.length > 0
            ? filteredJobRoles?.map((item: any) => {
                const isDisable = greencareerResp?.greencareers?.some(
                  (otherItem) => otherItem?.id === item?.id,
                )
                return (
                  <div
                    onClick={() =>
                      isDisable
                        ? ""
                        : greencareerResp.handleGreenifyJobRoles(item?.id)
                    }
                    key={item.id}
                    className={`flex items-center justify-between p-1 px-3 hover:bg-gray-50 cursor-pointer ${
                      isDisable ? "text-gray-300 cursor-not-allowed" : ""
                    }`}
                  >
                    <div className={`w-10/12`}>
                      <span>{item.name}</span>
                    </div>
                    {!item?.is_from_user && (
                      <CheckCircleOutlined className={`text-primary`} />
                    )}
                  </div>
                )
              })
            : filteredJobRolesAdded?.map((item: any) => {
                const isDisable = greencareerResp?.greencareers?.some(
                  (otherItem) => otherItem?.id === item?.id,
                )
                return (
                  <div
                    onClick={() =>
                      isDisable
                        ? ""
                        : greencareerResp.handleGreenifyJobRoles(item?.id)
                    }
                    key={item.id}
                    className={`flex items-center justify-between p-1 px-3 hover:bg-gray-50 cursor-pointer ${
                      isDisable ? "text-gray-300 cursor-not-allowed" : ""
                    }`}
                  >
                    <div className={`w-10/12`}>
                      <span>{item.name}</span>
                    </div>
                    {/*{!item?.is_from_user && <CheckCircleOutlined className={`text-primary`}/>}*/}
                  </div>
                )
              })}
        </div>
      </div>
    )
  }

  return greencareerResp.isFetchingGreenCareer ||
    greencareerChartResp.isFetchingGreenCareerChart ? (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  ) : (
    <div className="grid lg:grid-cols-12 grid-cols-6  gap-5">
      <div className="w-full col-span-6">
        <div className="p-5 shadow-md rounded-xl w-full bg-white  sticky top-0">
          <h2 className="">Career Goals</h2>
          {greencareerChartResp?.isFetchingGreenCareerChart ? (
            <div className="flex items-center justify-center mt-10 h-80">
              <Skeleton.Node className={``} active={true} fullSize={true}>
                <DotChartOutlined style={{ fontSize: 40, color: "#bfbfbf" }} />
              </Skeleton.Node>
            </div>
          ) : !result ? (
            <div className="flex items-center justify-center">
              <div>
                <Image preview={false} src={Moon} width={120} />
                <p>My ideal roles are...</p>
              </div>
            </div>
          ) : (
            <div className={`mt-7 mb-2`}>
              {/*<ChartGreenCareerAspiration data={result}/>*/}

              {greencareersDetailResp.isFetchingGreenCareerDetail ? (
                <Skeleton active={true} />
              ) : greencareersDetailResp.greencareersDetail ? (
                greencareersDetailResp.greencareersDetail?.map(
                  (item: any, index: number) => {
                    return (
                      <div className={`space-y-2`} key={index}>
                        <div
                          className={`h-14 flex items-center justify-between rounded-xl mb-3 px-4 py-4 mx-5 bg-white`}
                          style={{
                            border: "1px solid #D0DDE5",
                          }}
                        >
                          <div className={`flex items-center gap-3`}>
                            <p
                              className={`bg-primary-bg w-6 h-6 text-center pt-0.5 rounded-full`}
                            >
                              {index + 1}
                            </p>
                            <p className={`m-0`}>{item?.name}</p>
                          </div>
                          <div>
                            {item?.green_skills === null ? (
                              <CloseOutlined
                                className={`text-red-500 text-lg`}
                              />
                            ) : (
                              <Progress
                                type="circle"
                                strokeColor={
                                  Math.round(
                                    (item?.green_skills?.reduce(
                                      (acc: any, cur: any) =>
                                        acc +
                                        (cur?.total_completed_level >
                                        cur?.total_required_level
                                          ? cur?.total_required_level
                                          : cur?.total_completed_level),
                                      0,
                                    ) /
                                      item?.total_green_skill_levels) *
                                      100,
                                  ) === 100
                                    ? "86C769"
                                    : "#FAC61D"
                                }
                                percent={parseInt(
                                  (
                                    (item?.total_passed_levels /
                                      item?.total_green_skill_levels) *
                                    100
                                  ).toFixed(),
                                )}
                                size={30}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  },
                )
              ) : (
                <div className="flex gap-3 items-center">
                  <img src={Moon} alt="cookies" />
                  <div>{"Nothing to display"}</div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className=" w-[calc(100%-2px)] col-span-6">
        <div className="p-5 mb-10 shadow-md rounded-xl w-full bg-[#FAFAFA]">
          <h3>Green Career Aspirations</h3>
          <p>
            Select up to 5 green or greening Job Roles which you are interested
            in or want to learn skills for..
          </p>

          <Select
            showSearch
            className="w-full job-history"
            mode="multiple"
            value={[]}
            allowClear
            loading={greencareerJobRolesResp?.isFetchingGreenCareerJobRoles}
            filterOption={true}
            labelInValue
            disabled={greencareerResp?.greencareers?.length! === 5}
            dropdownRender={() => dropDownContent()}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                const searchTerm = greencareerJobRolesResp?.search
                const isTermExist = greencareerResp?.greencareers?.some(
                  (item) =>
                    item?.name?.toLowerCase() === searchTerm?.toLowerCase(),
                )
                if (!isTermExist) {
                  greencareerJobRolesResp?.handleAddJobRole(searchTerm)
                }
              }
            }}
            onDropdownVisibleChange={greencareerJobRolesResp?.handleClear}
            placeholder="Type to search"
            onSearch={greencareerJobRolesResp?.handleSearch}
            onClear={greencareerJobRolesResp?.handleClear}
          />

          <p className="mb-7">Order jobs in order to importance to you</p>

          {greencareerResp?.isFetchingGreenCareer ? (
            <Skeleton active />
          ) : (
            greencareerResp?.greencareers?.map((item: any, index: number) => (
              <div className="mb-6" key={index}>
                <div
                  key={index}
                  className="h-[50px] bg-[#F0F2F5] flex items-center justify-between rounded-xl px-5"
                  style={{ border: "1px solid #D0DDE5" }}
                >
                  <div className="flex items-center gap-6">
                    <div className="flex items-center">
                      <div
                        className="text-backdrop hover:text-primary cursor-pointer"
                        onClick={() => greencareerResp?.handleReorderUp(index)}
                      >
                        <CaretUpOutlined />
                      </div>
                      <div
                        className="text-backdrop hover:text-primary cursor-pointer"
                        onClick={() =>
                          greencareerResp?.handleReorderDown(index)
                        }
                      >
                        <CaretDownOutlined />
                      </div>
                    </div>
                    <div className={`w-10/12`}>
                      <p>{item?.name}</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-x-2">
                    <div
                      className="cursor-pointer hover:text-red-500"
                      onClick={() =>
                        greencareerResp.handleUnGreenifyJobRoles(item?.id)
                      }
                    >
                      <CloseCircleOutlined className="text-xl" />{" "}
                    </div>
                  </div>
                </div>
                {!item?.is_from_user && (
                  <p
                    className="m-0 p-0 text-right text-sm"
                    onClick={() =>
                      navigate(`/job/roles/${item?.slug}`, {
                        state: { id: item?.id },
                      })
                    }
                  >
                    <span className="text-secondary cursor-pointer hover:font-bold">
                      Greenify
                    </span>{" "}
                    this Job Role
                  </p>
                )}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  )
}

export default CVGreenCareer
