import React from "react"
import { Route, Routes } from "react-router-dom"

// const PrivacySetting = React.lazy(() => import("./pages/PrivacySetting"))
import PrivacySetting from "./pages/PrivacySetting"
// const NotFound = React.lazy(() => import("../auth/page/NotFound"))
import NotFound from "../auth/page/NotFound"
// const Setting = React.lazy(() => import("./pages/Setting"))
import Setting from "./pages/Setting"

const Index = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Setting />} />
        <Route path="/privacy" element={<PrivacySetting />} />

        {/* note! each route must be contain this route error to handle error after parent route*/}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  )
}

export default Index
