import React, { useMemo } from "react"
import { Gauge } from "@ant-design/plots"

interface ChartProps {
  score: number
  legend?: boolean
}
export const QuizResultChart: React.FC<ChartProps> = ({ score }) => {
  const res = score / 100
  const config = useMemo(() => {
    return {
      max: 100,
      percent: res,
      autoFit: true,
      height: 100,
      width: 70,
      range: {
        ticks: [0, 2.4 / 3, 1],
        color: ["#F4664A", "#30BF78"],
      },
      indicator: {
        pointer: {
          style: {
            stroke: "#D0D0D0",
          },
        },
        pin: {
          style: {
            stroke: "#D0D0D0",
          },
        },
      },
      statistic: {
        content: {
          style: {
            fontSize: "15px",
            lineHeight: "36px",
          },
        },
      },
    }
  }, [res])

  return <Gauge {...config} style={{ marginTop: "-30px" }} />
}
