/* eslint-disable */

import { ApiResponse } from "../../../../common/types/ApiResponse.ts"
import { instance, instanceV2 } from "../../../../config/config"
import {
  HardSkillChart,
  HardSkillList,
  HardSkillRecommended,
  MyHardSkill,
} from "./common_type.ts"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { message } from "antd"
import { useEffect, useState } from "react"
import { errorMessage } from "../../../../common/utils/utils.tsx"

const fetchMyHardSkill = () => {
  const response: Promise<ApiResponse<MyHardSkill[]>> = instanceV2.get(
    "greencv/hard-skills",
  )
  return response
}

const fetchHardSkillRecommendation = () => {
  const response: Promise<ApiResponse<HardSkillRecommended[]>> = instanceV2.get(
    "greencv/hard-skills/recommended",
  )
  return response
}

const fetchHardSkillList = () => {
  const response: Promise<ApiResponse<HardSkillList[]>> = instanceV2.get(
    "/greencv/other-skills",
  )
  return response
}

const addOtherSkill = (name: string) => {
  return instance.post(`skills/`, { skills: name })
}
const fetchChartHardSkill = () => {
  const response: Promise<ApiResponse<HardSkillChart[]>> = instanceV2.get(
    "greencv/hard-skills/chart",
  )
  return response
}

const removeMyHardSkill = (hardSkillID: string) => {
  return instanceV2.delete(`greencv/hard-skills/${hardSkillID}`)
}

const addMyHardSkill = (hardSkillID: string) => {
  return instanceV2.patch(`greencv/hard-skills/`, {
    technical_skill_id: hardSkillID,
  })
}

const useHardSkill = () => {
  const [search, setSearch] = useState("")
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const queryClient = useQueryClient()
  const handleFetchError = (error: any) => {
    errorMessage(
      `An error occurred while fetching technical skill: ${error}`,
      error,
    )
  }

  const handleUpdateSuccess = () => {
    message.success("Technical Skill successfully updated")
  }

  const handleUpdateError = (error: any) => {
    errorMessage(
      `An error occurred while updating a technical skill: ${error}`,
      error,
    )
  }

  const {
    data: hardskills,
    isLoading: isFetchingHardSkill,
    isError: isFetchErrorHardSkill,
    error: fetchErrorHardSkill,
  } = useQuery(["fetch-my-hard-skills"], () => fetchMyHardSkill(), {
    refetchOnWindowFocus: false,
    onError: handleFetchError,
  })

  useEffect(() => {
    // setListHardSkill()
  }, [])

  const {
    data: hardskillList,
    isLoading: isFetchingHardSkillList,
    isError: isFetchErrorHardSkillList,
    error: fetchErrorHardSkillList,
  } = useQuery(["fetch-hard-skills-list"], () => fetchHardSkillList(), {
    refetchOnWindowFocus: false,
    onError: handleFetchError,
  })

  const {
    data: hardskillChart,
    isLoading: isFetchingHardSkillChart,
    isError: isFetchErrorChart,
    error: fetchErrorChart,
  } = useQuery(["fetch-chart-hard-skills"], () => fetchChartHardSkill(), {
    refetchOnWindowFocus: false,
    onError: handleFetchError,
  })

  const {
    data: hardskillRecomendation,
    isLoading: isFetchingHardSkillRecomendation,
  } = useQuery(
    ["fetch-recommendation-hard-skills"],
    () => fetchHardSkillRecommendation(),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
    },
  )

  const { mutate: removeMyHardSkillMutate } = useMutation(
    (hardSkillID: string) => removeMyHardSkill(hardSkillID),
    {
      onSuccess: () => {
        message.open({
          key: "delete-technical-skill",
          content: "Deleting...",
          type: "loading",
        })
        queryClient.invalidateQueries(["fetch-my-hard-skills"])
        queryClient.invalidateQueries(["fetch-recommendation-hard-skills"])
        queryClient.invalidateQueries(["fetch-chart-hard-skills"])
        setTimeout(() => {
          handleUpdateSuccess()
        }, 2300)
      },
      onError: handleUpdateError,
      // enabled: isLoggedIn,
    },
  )

  const { mutate: addMyHardSkillMutate } = useMutation(
    (hardSkillID: string) => addMyHardSkill(hardSkillID),
    {
      onSuccess: () => {
        message.open({
          key: "add-technical-skill",
          content: "adding...",
          type: "loading",
        })
        queryClient.invalidateQueries(["fetch-my-hard-skills"])
        queryClient.invalidateQueries(["fetch-recommendation-hard-skills"])
        queryClient.invalidateQueries(["fetch-chart-hard-skills"])
        setTimeout(() => {
          handleUpdateSuccess()
        }, 2300)
      },
      onError: handleUpdateError,
      // enabled: isLoggedIn,
    },
  )

  const { mutate: addOtherSkillMutate } = useMutation(
    (name: any) => addOtherSkill(name),
    {
      onSuccess: (data) => {
        const stringID: string = data?.data?.data[0]?.id
        message.open({
          key: "add-hard-skill",
          content: "adding...",
          type: "loading",
        })
        queryClient.invalidateQueries(["fetch-my-hard-skills"])
        queryClient.invalidateQueries(["fetch-recommendation-hard-skills"])
        queryClient.invalidateQueries(["fetch-chart-hard-skills"])
        queryClient.invalidateQueries(["fetch-hard-skills-list"])
        setTimeout(() => {
          handleUpdateSuccess()
          // @ts-ignore
          addMyHardSkillMutate(stringID)
        }, 2300)
      },
      onError: handleUpdateError,
      // enabled: isLoggedIn,
    },
  )

  const handleRemoveMyHardSkill = (hardSkillID: string) => {
    removeMyHardSkillMutate(hardSkillID)
  }

  const handleAddMyHardSkill = (hardSkillID: string) => {
    addMyHardSkillMutate(hardSkillID)
    setIsDropdownOpen(false)
  }

  const handleAddOtherSkill = (e: any) => {
    addOtherSkillMutate(e)
    setIsDropdownOpen(false)
    setSearch("")
  }

  const handleSearch = (value: any) => {
    setSearch(value)
  }
  const handleClear = () => {
    setSearch("")
  }

  return {
    hardskillResp: {
      hardskills: hardskills?.data?.data,
      isFetchingHardSkill,
      isFetchErrorHardSkill,
      fetchErrorHardSkill,
      handleRemoveMyHardSkill,
      handleAddMyHardSkill,
    },
    hardskillRecomendedResp: {
      hardskillRecommendations: hardskillRecomendation?.data?.data,
      isFetchingHardSkillRecomendation,
    },
    hardskillListResp: {
      otherskill: hardskillList?.data?.data,
      isFetchingHardSkillList,
      isFetchErrorHardSkillList,
      fetchErrorHardSkillList,
      isDropdownOpen,
      setIsDropdownOpen,
      handleAddOtherSkill,
      search,
      handleSearch,
      handleClear,
    },
    hardskillChartResp: {
      hardskillCharts: hardskillChart?.data?.data,
      isFetchingHardSkillChart,
      isFetchErrorChart,
      fetchErrorChart,
    },
  }
}

export default useHardSkill
