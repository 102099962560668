/* eslint-disable */
import React from "react"
import { Skill, Level, School, LevelsData } from "../types/common"
import { Avatar, Button, Collapse, Image, Popover, Progress } from "antd"
import LevelInfoPopover from "./LevelInfoPopover"
import {
  ShoppingCartOutlined,
  InfoCircleOutlined,
  CheckOutlined,
  FieldTimeOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons"
import FiveCircleRate from "../../../common/components/FiveCircleRate"
import {
  ComingSoon,
  withAuthentication,
  LessonDuration,
} from "../../../common/components"
import { TrainerPopoverContent } from "./TrainerPopoverContent"
import { isHTML } from "../utils/util_funcs"
import { useNavigate } from "react-router-dom"
import { formatNumber } from "../../../utils/formatNumber"

interface EmptyLevel {
  id: string
  image: string
  name: string
  proficiency: number
}

interface Props {
  skillData: Skill
  level?: Level
  emptyLevel?: EmptyLevel
  levelsData: LevelsData
  image: string
  levelNum: number
  type?: "default" | "empty"
  loadingAdd: boolean
  handleAdd: any
}

const AuthenticatedButton = withAuthentication(Button)

const helperActionStatus = (
  status: number,
  dataLevel: Level,
  dataSchool: School,
  loading: boolean,
  action: any,
  nameSkill: string,
  price: number,
  redirect: () => void,
  allData: Level[],
) => {
  switch (status) {
    case 1:
      return (
        <>
          <span className="flex items-center mt-5 gap-3 w-36 flex-wrap sm:flex-nowrap">
            <CheckOutlined />
            Added to cart
          </span>
        </>
      )

    case 2:
      return (
        <>
          <span className="flex items-center mt-5 gap-3 w-36 flex-wrap sm:flex-nowrap">
            <FieldTimeOutlined />
            Coming Next
          </span>
        </>
      )
    case 3:
      return (
        <Button
          className="bg-white w-36 mt-5"
          style={{ border: "1px solid #86C769" }}
          onClick={redirect}
        >
          Go to course
        </Button>
      )

    default:
      return (
        <AuthenticatedButton
          className="bg-white w-36 mt-5"
          style={{ border: "1px solid #86C769" }}
          icon={<ShoppingCartOutlined />}
          onClick={() =>
            action([dataLevel], dataSchool, nameSkill, price, allData)
          }
          loading={loading}
        >
          Add to Cart
        </AuthenticatedButton>
      )
  }
}

export const SkillLevel: React.FC<Props> = ({
  levelNum,
  skillData,
  type = "default",
  level,
  emptyLevel,
  levelsData,
  image,
  loadingAdd,
  handleAdd,
}) => {
  const navigate = useNavigate()
  if (type === "empty" && !level && emptyLevel) {
    return (
      <div className="rounded-[10px] p-4 border-solid border mb-7 border-dirtyBlue  bg-[#FCFDFE] grid grid-cols-12 gap-3">
        <div className="col-span-12 lg:col-span-9">
          <div className="flex items-center gap-7">
            <div className="px-6 py-4 flex items-center relative bg-secondaryYellow rounded-2xl">
              <div className="absolute top-1 right-2">
                <LevelInfoPopover>
                  <InfoCircleOutlined className="text-[10px] cursor-pointer" />
                </LevelInfoPopover>
              </div>
              <Image src={image} preview={false} width={47} />
            </div>
            <div className="flex flex-col justify-center">
              <FiveCircleRate
                disabled={true}
                defaultValue={emptyLevel?.proficiency}
                count={5}
                circleSize={15}
              />
              <div className="footnote">LEVEL {levelNum}</div>
              {
                <div className="text-xs font-bold">
                  This level is currently in the Outer Space.
                </div>
              }
            </div>
          </div>
        </div>
        <div
          className={`col-span-12 lg:col-span-3 flex flex-col items-center 
          justify-center`}
        >
          <ComingSoon />
        </div>
      </div>
    )
  }
  return (
    <div className="rounded-[10px] p-4 border-solid border mb-7 border-dirtyBlue  bg-[#FCFDFE] grid grid-cols-12 gap-3">
      <div className="col-span-12 lg:col-span-9">
        <div className="flex items-center gap-7">
          <div className="px-6 py-4 flex items-center relative bg-secondaryYellow rounded-2xl">
            <div className="absolute top-1 right-2">
              <LevelInfoPopover>
                <InfoCircleOutlined className="text-[10px]" />
              </LevelInfoPopover>
            </div>
            <Image
              src={image}
              preview={false}
              width={47}
              className="object-contain"
            />
          </div>
          <div className="flex flex-col justify-center">
            <FiveCircleRate
              disabled={true}
              defaultValue={level?.proficiency}
              count={5}
              circleSize={15}
            />
            <div className="footnote">LEVEL {levelNum}</div>
            <div className="">
              {level?.text_content_metadata && level?.video_metadata && (
                <div className="mt-1">
                  <LessonDuration
                    textDuration={level?.text_content_metadata}
                    videoDuration={level?.video_metadata}
                  />
                </div>
              )}
              {!level?.available_for_sale && (
                <div className="text-xs font-bold">
                  This level is currently in the Outer Space.
                </div>
              )}
            </div>
          </div>
        </div>
        {level?.available_for_sale && (
          <Collapse
            defaultActiveKey={["1"]}
            expandIcon={(panelProps) => (
              <>
                <span className="text-secondary hover:scale-110 duration-150 transition-transform">
                  {panelProps.isActive ? "View Less" : "View More"}
                </span>
              </>
            )}
            ghost
          >
            <Collapse.Panel header={undefined} key="1">
              {isHTML(level?.learning_outcome) ? (
                <div
                  className="-mt-6"
                  dangerouslySetInnerHTML={{
                    __html: level?.learning_outcome,
                  }}
                />
              ) : (
                <p className="-mt-4">{level?.learning_outcome}</p>
              )}
              <ul>
                {level?.module_titles?.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </Collapse.Panel>
          </Collapse>
        )}
      </div>
      <div
        className={`col-span-12 lg:col-span-3 flex flex-col items-center ${
          level?.available_for_sale || level?.instructor
            ? "justify-betweens"
            : "justify-center"
        }`}
      >
        {level?.available_for_sale ? (
          <>
            <div className=" flex flex-col w-full">
              {level?.user_track ? (
                <div className="w-full">
                  <Progress
                    percent={(level?.user_track?.current_module / 5) * 100}
                    strokeColor={{
                      "0%": "#108ee9",
                      "100%": "#87d068",
                    }}
                  />
                </div>
              ) : (
                <div className=" flex gap-3 ">
                  <strong>Price: </strong>{" "}
                  <span className=" uppercase">
                    {levelsData?.currency?.logo ? (
                      <img
                        className=" w-4 h-4 rounded-full object-cover"
                        style={{ border: "0.5px solid #36505E" }}
                        src={levelsData?.currency?.logo}
                      />
                    ) : (
                      <DollarCircleOutlined className="text-[1.1rem]" />
                    )}
                    {/* {levelsData?.currency?.code ?? null} */}
                  </span>
                  <div className=" flex flex-col">
                    {levelsData?.pre_discount_price > 0 && (
                      <span className="text-danger line-through text-xs">
                        {formatNumber(
                          levelsData.pre_discount_price *
                            levelsData?.currency?.exchange,
                        )}
                      </span>
                    )}
                    <span
                      className={`text-base font-semibold ${
                        levelsData?.pre_discount_price > 0 ? " text-base" : ""
                      }`}
                    >
                      {formatNumber(
                        levelsData?.price_per_level *
                          levelsData.currency.exchange,
                      )}
                    </span>
                  </div>
                </div>
              )}
              {helperActionStatus(
                level?.status_transaction,
                level,
                levelsData?.school,
                loadingAdd,
                handleAdd,
                skillData?.name,
                levelsData?.price_per_level,
                () =>
                  navigate(
                    `/dashboard/launchpad/single-mylaunchpad/${levelsData?.id}`,
                  ),
                levelsData?.levels,
              )}
            </div>
            <Popover
              content={
                level?.instructor && (
                  <TrainerPopoverContent instructor={level?.instructor} />
                )
              }
            >
              <Avatar
                src={level?.instructor?.avatar}
                className="w-16 h-16 mx-auto shadow-md mt-6"
              />
            </Popover>
          </>
        ) : (
          <>
            <ComingSoon />
            {level?.instructor && (
              <Popover
                content={
                  level?.instructor && (
                    <TrainerPopoverContent instructor={level?.instructor} />
                  )
                }
              >
                <Avatar
                  src={level?.instructor?.avatar}
                  className="w-16 h-16 mx-auto shadow-md mt-6"
                />
              </Popover>
            )}
          </>
        )}
      </div>
    </div>
  )
}
