import { ApiResponseGeneric } from "../../../../common/types/ApiResponse"
import instance from "../../../../config/config"

interface ProfileStatus {
  requested: boolean
  status: "REJECTED" | "PENDING" | "APPROVED" | "-"
  reason?: string
}

interface ProfileStatusResponse {
  mentor: ProfileStatus
  expert: ProfileStatus
  founder: ProfileStatus
}

export const profilesStatus = () => {
  return instance.get<ApiResponseGeneric<ProfileStatusResponse>>(
    `self-requests/status`,
  )
}
