import { Button, Carousel } from "antd"
import useTailwindScreenSizes from "../../../hooks/useTailwindScreenSizes"
// eslint-disable-next-line
import { Perk as PerkType, usePerks } from "../hook/usePerks"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { PerkCard } from "./PerkCard"

function divideIntoGroups<T>(array: T[], groupSize: number): T[][] {
  let result: T[][] = []
  // Create a copy of the array to ensure immutability
  let arrayCopy = [...array]
  while (arrayCopy.length > 0) {
    result.push(arrayCopy.splice(0, groupSize))
  }
  return result
}

const PerkSection = () => {
  const { screenSize } = useTailwindScreenSizes()
  const perkGroupLength = screenSize === "md" || screenSize === "sm" ? 1 : 4

  const perksQuery = usePerks()
  const navigate = useNavigate()

  const filteredAndSortedProducts = useMemo(() => {
    return divideIntoGroups<PerkType>(
      perksQuery?.data?.data?.data ?? [],
      perkGroupLength,
    )
  }, [perksQuery?.data?.data?.data, screenSize])
  if (filteredAndSortedProducts?.length > 0)
    return (
      <div className="m-auto min-w-[87%] py-20  bg-white px-6 flex flex-col">
        <h3 className="text-center font-semibold text-[40px]">
          Do more - perks and benefits
        </h3>

        <div className="basic-card mt-10 bg-white-custom pb-5 flex-grow">
          <Carousel className="pb-6" dots={{ className: "" }} arrows autoplay>
            {filteredAndSortedProducts?.map((perks, idx) => (
              <div
                key={idx}
                className="!grid !grid-cols-12 gap-4 p-3 py-6 pb-8"
              >
                {perks?.map((perk, idx) => (
                  <PerkCard
                    unauthenticated
                    className="col-span-full lg:col-span-3"
                    key={perk.id + idx}
                    perk={perk}
                  />
                ))}
              </div>
            ))}
          </Carousel>
        </div>

        <div className="flex justify-center">
          <Button
            onClick={() => {
              navigate("/register")
            }}
            className="bg-primary text-white mt-10 "
          >
            Get started now
          </Button>
        </div>
      </div>
    )
}

export default PerkSection
