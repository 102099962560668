import React from "react"
import { Empty, Collapse, Skeleton } from "antd"
import {
  CaretRightOutlined,
  HeartOutlined,
  HeartFilled,
} from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { withAuthentication } from "../../../common/components"
import { Skill, FavoriteSkill } from "../types/common"

const { Panel } = Collapse
const AuthenticatedHeart = withAuthentication(HeartOutlined)

interface SingleSkillTypicalActivitiesProps {
  skillData: Skill
  skillDataIsLoading: boolean
  favoriteSkills: FavoriteSkill[]
  removeSkillFromFavorite: (item: string) => void
  addSkillToFavorite: (item: string) => void
}

const panelStyle = {
  marginBottom: 24,
  background: "#FCFDFE",
  borderRadius: "5px",
  boxShadow: "0px",
  border: "6px solid #D0DDE5;",
}
export const SingleSkillTypicalActivities: React.FC<
  SingleSkillTypicalActivitiesProps
> = ({
  skillData,

  skillDataIsLoading,
  favoriteSkills,
  removeSkillFromFavorite,
  addSkillToFavorite,
}) => {
  const navigate = useNavigate()
  const [visible, setVisible] = React.useState(10)

  return (
    <div className="p-2 mt-9">
      <Collapse
        bordered={false}
        size="large"
        defaultActiveKey={["1", "2", "3"]}
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        style={{ background: "#ffffff" }}
      >
        <Panel
          header={<div className="text-xl font-bold">Similar Skills</div>}
          key="1"
          className="!border-none !shadow-none !bg-[#FCFDFE]"
          style={panelStyle}
        >
          {skillDataIsLoading ? (
            <Skeleton active title={false} paragraph={{ rows: 4 }} />
          ) : (
            <>
              <ul className="list-none pl-5">
                {skillData?.related_skills
                  ?.filter((_, i) => i < visible)
                  ?.map((item, i) => (
                    <li className="relative my-3" key={i}>
                      <div className="flex gap-5  ">
                        {favoriteSkills?.find(
                          (v) => v.green_skill_id === item?.id,
                        ) ? (
                          <HeartFilled
                            className="text-primary"
                            onClick={() => removeSkillFromFavorite(item?.id)}
                          />
                        ) : (
                          <AuthenticatedHeart
                            className=""
                            onClick={() => {
                              addSkillToFavorite(item?.id)
                            }}
                          />
                        )}
                        <div
                          className="hover:text-secondary  cursor-pointer truncate"
                          onClick={() =>
                            navigate(`/skill/green-skills/${item?.id}`)
                          }
                        >
                          {item?.name}
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
              {skillData?.related_skills?.length > visible && (
                <div
                  className="text-secondary mt-5 cursor-pointer"
                  onClick={() => setVisible((prev) => prev + 5)}
                >
                  View More
                </div>
              )}
            </>
          )}
        </Panel>
        <Panel
          header={<div className="text-xl font-bold">Typical Activities</div>}
          key="2"
          className="!border-none !shadow-none"
          style={panelStyle}
        >
          {skillData?.typical_activities ? (
            <div
              className="text-base"
              dangerouslySetInnerHTML={{
                __html: skillData?.typical_activities,
              }}
            ></div>
          ) : (
            <Empty description="No Data" />
          )}
        </Panel>
        <Panel
          header={<div className="text-xl font-bold">Success Factors</div>}
          key="3"
          className="!border-none !shadow-none"
          style={panelStyle}
        >
          {skillData?.success_factor ? (
            <div
              className="text-base"
              dangerouslySetInnerHTML={{
                __html: skillData?.success_factor,
              }}
            ></div>
          ) : (
            <Empty description="No Data" />
          )}
        </Panel>
      </Collapse>
    </div>
  )
  // return (
  //   <div className='flex flex-col gap-y-2 p-2'>
  //     <div className='text-xl text-backdrop mt-3 font-bold'>
  //       Typical Activities
  //     </div>
  //     {data?.data?.typical_activities ? (
  //       <div
  //         className='text-base'
  //         dangerouslySetInnerHTML={{
  //           __html: data?.data?.typical_activities,
  //         }}></div>
  //     ) : (
  //       <Empty description='No Data' />
  //     )}

  //     <div className='text-xl text-backdrop mt-3 font-bold'>
  //       Success Factors
  //     </div>
  //     {data?.data?.success_factor ? (
  //       <div
  //         className='text-base'
  //         dangerouslySetInnerHTML={{
  //           __html: data?.data?.success_factor,
  //         }}></div>
  //     ) : (
  //       <Empty description='No Data' />
  //     )}
  //   </div>
  // );
}
