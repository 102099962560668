import {
  Drawer,
  message,
  // Select,
  Skeleton,
} from "antd"
import React, { useEffect, useState } from "react"
import { useThisWeekDiscussionStore } from "../../store/thisWeekDiscussionStore"
import {
  CommentOutlined,
  LikeOutlined,
  LikeFilled,
  ShareAltOutlined,
  // CaretDownOutlined,
  CloseOutlined,
} from "@ant-design/icons"
import CommentV2 from "./Comment-v2"
import { addRemoveResponse, getThisWeeksPost } from "../apis/this-weeks-post"
import { useMutation, useQuery, useQueryClient } from "react-query"
import ErrorMessageBuilder from "../../../../common/components/ErrorMessageBuilder"
import useInfiniteComments from "../hooks/useInfiniteComments"
import AddCommentTwd from "./AddCommentTwd"

const ThisWeeksDiscussion = () => {
  const { isOpen, closeDrawer } = useThisWeekDiscussionStore()

  const { data, isLoading } = useQuery(
    ["this-weeks-discussion"],
    () => getThisWeeksPost("ongoing"),

    {
      refetchOnMount: true,
      onError: () => {
        message.open({
          key: "this-weeks-discussion",
          duration: 5,
          content: React.createElement(ErrorMessageBuilder, {
            message:
              "We encountered an issue while attempting to fetch this week's discussion. If the problem continues, please refresh the page.",
            includeReportAt: true,
          }),
          type: "error",
        })
      },
      retry: 2,
    },
  )
  const weeksDiscussionData = data?.data?.data?.[0]
  const [isLiked, setIsLiked] = useState(weeksDiscussionData?.is_liked)

  useEffect(() => {
    setIsLiked(weeksDiscussionData?.is_liked)
  }, [weeksDiscussionData?.is_liked])

  const reactToComment = useMutation(addRemoveResponse)
  const queryClient = useQueryClient()

  const handleReaction = async (isLiking: boolean) => {
    setIsLiked(isLiking)
    await reactToComment.mutateAsync(weeksDiscussionData?.id ?? "", {
      onSuccess: () => {
        queryClient.invalidateQueries(["this-weeks-discussion"])
        if (isLiking) message.success("👍Discussion Liked!")
      },
      onError: (error: any) => {
        setIsLiked(!isLiking)
        message.error({
          content:
            error?.response?.data?.message ||
            "Something went wrong whilst commenting, try again!",
        })
        console.error(error)
      },
    })
  }

  const { comments, lastCardElementRef } = useInfiniteComments(
    weeksDiscussionData?.id,
  )

  return (
    <Drawer closable={false} open={isOpen} width={600} onClose={closeDrawer}>
      <div className="flex flex-wrap items-center justify-between ">
        <h3 className="text-xl font-bold">This Week's Discussion</h3>
        <CloseOutlined onClick={closeDrawer} />
      </div>
      {isLoading ? (
        <Skeleton paragraph={{ rows: 7 }} className="mt-7" />
      ) : (
        <>
          <div className="mt-4 space-y-3">
            <h4 className="text-lg font-semibold">
              Q: {weeksDiscussionData?.body?.title}
            </h4>
            <div>{weeksDiscussionData?.body?.description}</div>
            <div>Leave a comment below and let us know!</div>
            <div className="text-lg text-primary">
              {weeksDiscussionData?.hashtags}
            </div>
          </div>

          <div className="flex items-center gap-3 mt-4">
            <div
              className="bg-bg py-[10px] px-[20px] flex rounded-[10px] cursor-pointer hover:scale-110 transition-all duration-300 gap-2 items-center"
              onClick={() => handleReaction(!isLiked)}
            >
              {isLiked ? (
                <LikeFilled className="transition-all duration-200 cursor-pointer hover:text-primary hover:scale-110" />
              ) : (
                <LikeOutlined className="transition-all duration-200 cursor-pointer hover:text-primary hover:scale-110" />
              )}
              <span>{weeksDiscussionData?.total_responses}</span>
            </div>
            <div className="bg-bg py-[10px] px-[20px] flex rounded-[10px] cursor-pointer hover:scale-110 transition-all duration-300 gap-2 items-center">
              <CommentOutlined />
              <span>{weeksDiscussionData?.total_comments}</span>
            </div>
            <div className="bg-bg py-[10px] px-[20px] flex rounded-[10px] cursor-pointer hover:scale-110 transition-all duration-300 gap-2 items-center">
              <ShareAltOutlined />
            </div>
          </div>
          <div className="mt-6">
            <AddCommentTwd discussion_id={weeksDiscussionData?.id ?? ""} />
          </div>
          {/* <div className="flex items-center gap-2 mt-4">
            <label className="text-gray-500">Sort by:</label>
            <Select
              variant={"borderless"}
              className="rounded min-w-[100px]"
              //   {...field}
              suffixIcon={<CaretDownOutlined className="text-backdrop" />}
            >
              <Select.Option value="newest">Newest</Select.Option>
              <Select.Option value="oldest">Oldest</Select.Option>
              <Select.Option value="popular">Most Popular</Select.Option>
            </Select>
          </div> */}

          <div className="mt-6 space-y-6">
            {comments?.map((comment, idx) => (
              <CommentV2
                observ={lastCardElementRef}
                key={idx}
                comment={comment}
              />
            ))}
          </div>
        </>
      )}
    </Drawer>
  )
}

export default ThisWeeksDiscussion
