import { Link } from "react-router-dom"

const GreenSkillsLink = () => (
  <Link
    className="text-secondary hover:text-cyan-700 hover:scale-105"
    to="/skill/green-skills"
  >
    Green Skills
  </Link>
)

const JobRolesLink = () => (
  <Link
    className="text-secondary hover:text-cyan-700 hover:scale-105"
    to="/job/roles"
  >
    Job Roles
  </Link>
)

const SkillTrajectoryLink = () => (
  <Link
    className="text-secondary hover:text-cyan-700 hover:scale-105"
    to="/dashboard/skill-trajectory-plan/skill-roadmap"
  >
    Skills Trajectory Plan
  </Link>
)

const CompaniesLink = () => (
  <Link
    className="text-secondary hover:text-cyan-700 hover:scale-105"
    to="/job/companies"
  >
    Companies
  </Link>
)

const MyJobsLink = () => (
  <Link
    className="text-secondary hover:text-cyan-700 hover:scale-105"
    to="/job/my"
  >
    My Jobs
  </Link>
)

const CVBuilderLink = () => (
  <Link
    className="text-secondary hover:text-cyan-700 hover:scale-105"
    to="/dashboard/launchpad/cv-builder"
  >
    CV Builder
  </Link>
)

export const joinTableCols = [
  {
    key: "1",
    description: {
      heading: "Joining Bonus",
      subheading: "Welcome to your Universe of Opportunities!",
    },
    points: {
      heading: "100 points",
      subheading: "Earn once",
    },
  },
]

export const skillTableCols = [
  {
    key: "1",
    description: {
      heading: "Favourite a Green Skill",
      subheading: (
        <>
          Explore <GreenSkillsLink />
        </>
      ),
    },
    points: {
      heading: "5 points per skill",
      subheading: "Earn 3 times per day",
    },
  },
  {
    key: "2",
    description: {
      heading: "Get a Green Skills course",
      subheading: (
        <>
          Explore <GreenSkillsLink /> or <JobRolesLink />
        </>
      ),
    },
    points: {
      heading: "50 points per level",
      subheading: "Unlimited",
    },
  },
  {
    key: "3",
    description: {
      heading: "Pass Green Skill Test",
      subheading: (
        <>
          Go to <SkillTrajectoryLink />
        </>
      ),
    },
    points: {
      heading: "Points based on the test score (%)",
      subheading: "Earn per passed test",
    },
  },
]

export const jobTableCols = [
  // {
  //   key: "5",
  //   description: {
  //     heading: "Pass Green Skill Test",
  //     subheading: "Tests for level 2 are available in Premium plans",
  //   },
  //   points: {
  //     heading: "Points based on the test score (%)",
  //     subheading: "Earn per passed test",
  //   },
  // },
  {
    key: "6",
    description: {
      heading: "Set your Job Greenification Goal",
      subheading: (
        <>
          Explore <JobRolesLink />
        </>
      ),
    },
    points: {
      heading: "50 points",
      subheading: "Earn once every 3 months",
    },
  },
  {
    key: "7",
    description: {
      heading: "Follow Employers to see recommended jobs",
      subheading: (
        <>
          Explore <CompaniesLink />
        </>
      ),
    },
    points: {
      heading: "5 points",
      subheading: "Earn 3 times per day",
    },
  },
  {
    key: "8",
    description: {
      heading: "Make your Green CV discoverable to recruiters",
      subheading: (
        <>
          Go to <MyJobsLink />
        </>
      ),
    },
    points: {
      heading: "100 points",
      subheading: "Earn once",
    },
  },
]

export const greenCvTableCols = [
  {
    key: "9",
    description: {
      heading: "Add Profile Description in Green CV Builder",
      subheading: (
        <>
          Go to <CVBuilderLink />
        </>
      ),
    },
    points: {
      heading: "30 points",
      subheading: "Earn once",
    },
  },
  {
    key: "10",
    description: {
      heading: "Add Profile Tagline",
      subheading: (
        <>
          Go to <CVBuilderLink />
        </>
      ),
    },
    points: {
      heading: "20 points",
      subheading: "Earn once",
    },
  },
  {
    key: "11",
    description: {
      heading: "Add Education in Green CV Builder",
      subheading: (
        <>
          Go to <CVBuilderLink />
        </>
      ),
    },
    points: {
      heading: "10 points per Education",
      subheading: "Earn up to 5 times",
    },
  },
  {
    key: "12",
    description: {
      heading: "Add Experience in Green CV Builder",
      subheading: (
        <>
          Go to <CVBuilderLink />
        </>
      ),
    },
    points: {
      heading: "10 points per experience",
      subheading: "Earn up to 20 times",
    },
  },
  {
    key: "13",
    description: {
      heading: "Add Social Media Links in Green CV Builder",
      subheading: (
        <>
          Go to <CVBuilderLink />
        </>
      ),
    },
    points: {
      heading: "5 points per link",
      subheading: "Earn once per link",
    },
  },
  {
    key: "14",
    description: {
      heading: "Add Skills when adding experience or education",
      subheading: (
        <>
          Go to <CVBuilderLink />
        </>
      ),
    },
    points: {
      heading: "5 points per skill",
      subheading: "Earn up to 100 times",
    },
  },
  {
    key: "14",
    description: {
      heading: "Add Certifications in Green CV Builder",
      subheading: (
        <>
          Go to <CVBuilderLink />
        </>
      ),
    },
    points: {
      heading: "15 points per certification",
      subheading: "Earn up to 50 times",
    },
  },
]

export const tableMap = {
  Intro: joinTableCols,
  Skills: skillTableCols,
  Jobs: jobTableCols,
  "Green CV": greenCvTableCols,
}

export const tableMapArr = [
  ["Intro", joinTableCols],
  ["Skills", skillTableCols],
  ["Jobs", jobTableCols],
  ["Green CV", greenCvTableCols],
]
