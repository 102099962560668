import { useQuery } from "react-query"
import { Account } from "../../missionControl/hooks/cv-builder/useProfile"
import { instanceV2 } from "../../../config/config"
import { AxiosResponse } from "axios"
import { useLocation, useParams } from "react-router-dom"
import React from "react"
import { message } from "antd"
import ErrorMessageBuilder from "../../../common/components/ErrorMessageBuilder"

export const useCv = () => {
  const { pathname } = useLocation()
  const { id } = useParams()

  const {
    data: account,
    isLoading: loadingAccount,
    isError,
  } = useQuery(
    `account-cv-${id}`,
    () =>
      instanceV2.get<AxiosResponse<Account>>(`/accounts/cv/id/${id}`, {
        params: {
          anonymised: pathname.includes("/cv-anonymised") ? "1" : "0",
        },
      }),
    {
      onError: () => {
        message.open({
          key: "expert-account-cv",
          duration: 5,
          content: React.createElement(ErrorMessageBuilder, {
            message:
              "We encountered an issue while attempting to fetching user's CV details. If the problem continues, please refresh the page.",
            includeReportAt: true,
          }),
          type: "error",
        })
      },
      retry: 2,
    },
  )
  return { account: account?.data.data, loadingAccount, isError }
}
