import React from "react"
import { JobRole } from "../../types/jobRole"
import { Moon } from "../../../../assets/images"

interface Props {
  data: JobRole
}
export const TabJobDetail: React.FC<Props> = ({ data }) => {
  return data?.job_detail ? (
    <div dangerouslySetInnerHTML={{ __html: data?.job_detail }}></div>
  ) : (
    <div className="flex gap-3 items-center bg-white p-3 rounded-xl shadow-md">
      <img src={Moon} alt="cookies" />
      <div>{"Nothing to display"}</div>
    </div>
  )
}
