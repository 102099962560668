/* eslint-disable no-unused-vars */
import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query"
import { MyArticleListQueryType } from "../types/query"
import {
  ApiResponseGeneric,
  ApiResponsePaginated,
} from "../../../../common/types/ApiResponse"
import { Article, MyArticleResponseTypes } from "../types/response"
import { AxiosError, AxiosResponse } from "axios"
import { message } from "antd"
import instance from "../../../../config/config"
import React from "react"

const QUERYNAMELIST: string = "my-artilce-list"
const QUERYNAMEDETAIL: string = "my-artilce-detail"

export const useFetchMyArticleList = (
  queryParam: MyArticleListQueryType,
): UseQueryResult<ApiResponsePaginated<MyArticleResponseTypes>, AxiosError> => {
  const fetchArticle = async () => {
    try {
      return await instance.get<MyArticleResponseTypes>("/articles", {
        params: {
          ...queryParam,
        },
      })
    } catch (error: any) {
      message.error(
        `An error occurred while fetching ${QUERYNAMELIST}: + ${error.message}`,
      )
    }
  }
  return useQuery([QUERYNAMELIST, queryParam], fetchArticle, {
    onError: (error: AxiosError) => {
      message.error(
        `An error occurred while fetching ${QUERYNAMELIST}: + ${error.message}`,
      )
    },
  })
}

export const useFetchDetailArticle = (
  slug: string,
): UseQueryResult<ApiResponseGeneric<Article>, AxiosError> => {
  const fetchDetailArticle = async () => {
    try {
      const response: AxiosResponse<ApiResponseGeneric<Article>> =
        await instance.get(`/articles/${slug}`)
      return response?.data
    } catch (error: any) {
      message.error(
        `An error occurred while fetching ${QUERYNAMEDETAIL}: + ${error.message}`,
      )
      throw error
    }
  }

  const queryResult = useQuery([QUERYNAMEDETAIL, slug], fetchDetailArticle, {
    onError: (error: AxiosError) => {
      message.error(
        `An error occurred while fetching ${QUERYNAMEDETAIL}: + ${error.message}`,
      )
    },
  })

  return queryResult
}

export const usePostArtcile = () => {
  const queryClient = useQueryClient()

  const saveArticle = React.useCallback(
    async (data: MyArticleResponseTypes) => {
      return instance.post("/articles", data)
    },
    [],
  )

  return useMutation(
    async (data: MyArticleResponseTypes) => saveArticle(data),
    {
      onSuccess: (data: any) => {
        message.success(data?.data?.data)
        setTimeout(() => {
          queryClient.invalidateQueries([QUERYNAMELIST])
          queryClient.invalidateQueries([QUERYNAMEDETAIL])
        }, 1000)
      },
      onError: (error: any) => {
        message.error(
          "An error occurred while save your article: " +
            error.response?.data?.data,
          10,
        )
      },
    },
  )
}

export const useEditArticle = () => {
  const queryClient = useQueryClient()

  const addJobPost = async ({ data, slug }: { data: any; slug: string }) => {
    try {
      return await instance.patch(`/articles/${slug}`, data)
    } catch (error) {
      message.error("An error occurred while creating your job post.")
    }
  }
  return useMutation(addJobPost, {
    onSuccess: (data: any) => {
      message.success(data?.data?.data)
      setTimeout(() => {
        // queryClient.invalidateQueries([QUERYNAMELIST])
        queryClient.invalidateQueries([QUERYNAMEDETAIL])
      }, 1000)
    },
    onError: (error: any) => {
      message.error(
        "An error occurred while save your article: " +
          error.response?.data?.data,
        10,
      )
    },
  })
}

export const usePostImageArtcile = () => {
  // const queryClient = useQueryClient()

  const addImage = async ({ data, slug }: { data: FormData; slug: string }) => {
    try {
      return await instance.post(`/articles/image/${slug}`, data)
    } catch (error) {
      message.error("An error occurred while creating your job post.")
    }
  }
  return useMutation(addImage, {
    onSuccess: (data: any) => {
      message.success(data?.data?.data)
      setTimeout(() => {
        // queryClient.invalidateQueries([QUERYNAMELIST])
        // queryClient.invalidateQueries([QUERYNAMEDETAIL])
      }, 1000)
    },
    onError: (error: any) => {
      message.error(
        "An error occurred while save your article: " +
          error.response?.data?.data,
        10,
      )
    },
  })
}
export const useDeleteImageArtcile = () => {
  // const queryClient = useQueryClient()

  const addImage = async ({ slug }: { slug: string }) => {
    try {
      return await instance.delete(`/articles/image/${slug}`)
    } catch (error) {
      message.error("An error occurred while delete article image.")
    }
  }
  return useMutation(addImage, {
    onSuccess: (data: any) => {
      message.success(data?.data?.data)
      setTimeout(() => {
        // queryClient.invalidateQueries([QUERYNAMEDETAIL])
      }, 1000)
    },
    onError: (error: any) => {
      message.error(
        "An error occurred while delete your article image: " +
          error.response?.data?.data,
        10,
      )
    },
  })
}
