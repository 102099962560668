import { create } from "zustand"
import { Day } from "../types/response"

type ModalState = {
  isOpen: boolean
  openModal: () => void
  closeModal: () => void

  isOpenModalOverride: boolean
  openModalOverride: () => void
  closeModalOverride: () => void
}

export const storeModalSetAvailability = create<ModalState>((set) => ({
  isOpen: false,
  openModal: () => set(() => ({ isOpen: true })),
  closeModal: () => set(() => ({ isOpen: false })),

  isOpenModalOverride: false,
  openModalOverride: () => set(() => ({ isOpenModalOverride: true })),
  closeModalOverride: () => set(() => ({ isOpenModalOverride: false })),
}))

export interface AvailabilityState {
  availabilityData: Day[]
  setAvailabilityData: (data: Day[]) => void
  timezone: string
  setTimezone: (timezone: string) => void
  label: string
  setLabel: (label: string) => void
  isLoadingSave: boolean
  setLoadingSave: (loading: boolean) => void
  isMentorHasAvailability: boolean
  setIsMentorHasAvailability: (isMentorHasAvailability: boolean) => void
}

export const useAvailabilityStore = create<AvailabilityState>((set) => ({
  availabilityData: [],
  setAvailabilityData: (data) => set({ availabilityData: data }),
  timezone: "",
  setTimezone: (timezone) => set({ timezone }),
  label: "Working Days",
  setLabel: (label) => set({ label }),
  isLoadingSave: false,
  setLoadingSave: (loading) => set({ isLoadingSave: loading }),
  isMentorHasAvailability: true,
  setIsMentorHasAvailability: (isMentorHasAvailability) =>
    set({ isMentorHasAvailability }),
}))
