import {
  CloseOutlined,
  DotChartOutlined,
  PlusOutlined,
} from "@ant-design/icons"
import { Image, Select, Skeleton, Spin } from "antd"
import { Moon } from "../../../../../assets/images"
import { ChartHardkill } from "./CVSkillCharts.tsx"
import useHardSkill from "../../../hooks/cv-builder/useHardSkill.ts"
import { LoadingOutlined } from "@ant-design/icons"
import React from "react"

const CVHardSkill = () => {
  const {
    hardskillResp,
    hardskillRecomendedResp,
    hardskillListResp,
    hardskillChartResp,
  } = useHardSkill()

  const dropDownContent = () => {
    const filteredHardSkill = hardskillListResp?.otherskill?.filter(
      (item: any) =>
        item?.name
          ?.toLowerCase()
          ?.includes(hardskillListResp?.search?.toLowerCase()),
    )
    const filteredHardSkillAdded = hardskillResp?.hardskills?.filter(
      (item: any) =>
        item?.name
          ?.toLowerCase()
          ?.includes(hardskillListResp?.search?.toLowerCase()),
    )

    return (
      <div className={`min-h-auto max-h-64 overflow-y-scroll text-backdrop`}>
        <div>
          {filteredHardSkill?.length === 0 &&
            filteredHardSkillAdded?.length === 0 && (
              <div className={`bg-secondaryYellow`}>
                Not found! Press enter to create!
              </div>
            )}

          {filteredHardSkill && filteredHardSkill?.length > 0
            ? filteredHardSkill?.map((item: any) => {
                const isDisable = hardskillResp?.hardskills?.some(
                  (otherItem) => otherItem?.id === item?.id,
                )
                return (
                  <div
                    onClick={() =>
                      isDisable
                        ? ""
                        : hardskillResp.handleAddMyHardSkill(item?.id)
                    }
                    key={item.id}
                    className={`flex items-center justify-between p-1 px-3 hover:bg-gray-50 cursor-pointer ${
                      isDisable ? "text-gray-300 cursor-not-allowed" : ""
                    }`}
                  >
                    <div className={`w-10/12`}>
                      <span>{item.name}</span>
                    </div>
                  </div>
                )
              })
            : filteredHardSkillAdded?.map((item: any) => {
                const isDisable = hardskillResp?.hardskills?.some(
                  (otherItem) => otherItem?.id === item?.id,
                )
                return (
                  <div
                    onClick={() =>
                      isDisable
                        ? ""
                        : hardskillResp.handleAddMyHardSkill(item?.id)
                    }
                    key={item.id}
                    className={`flex items-center justify-between p-1 px-3 hover:bg-gray-50 cursor-pointer ${
                      isDisable ? "text-gray-300 cursor-not-allowed" : ""
                    }`}
                  >
                    <div className={`w-10/12`}>
                      <span>{item.name}</span>
                    </div>
                  </div>
                )
              })}
        </div>
      </div>
    )
  }

  // @ts-ignore
  return hardskillResp?.isFetchingHardSkill ||
    hardskillRecomendedResp?.isFetchingHardSkillRecomendation ||
    hardskillListResp.isFetchingHardSkillList ||
    hardskillChartResp?.isFetchingHardSkillChart ? (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  ) : (
    <div className="grid lg:grid-cols-12 grid-cols-6 gap-5">
      <div className="w-full col-span-6">
        <div className="p-5 shadow-md rounded-xl w-full bg-white sticky top-0">
          <h2>Other Technical Skills</h2>
          <div className={``}>
            {hardskillChartResp?.isFetchingHardSkillChart ? (
              <div className="flex items-center justify-center mt-10 h-80">
                <Skeleton.Node className={``} active={true} fullSize={true}>
                  <DotChartOutlined
                    style={{
                      fontSize: 40,
                      color: "#bfbfbf",
                    }}
                  />
                </Skeleton.Node>
              </div>
            ) : !hardskillChartResp?.hardskillCharts ? (
              <div className="flex items-center justify-center">
                <div>
                  <Image preview={false} src={Moon} width={120} />
                  <p>My ideal roles are...</p>
                </div>
              </div>
            ) : (
              <div className={`mt-1 mb-2`}>
                <ChartHardkill data={hardskillChartResp?.hardskillCharts} />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="w-[calc(100%-2px)] col-span-6">
        <div className="p-5 shadow-md mb-10 rounded-xl w-full bg-[#FAFAFA]">
          <h3>Technical Skills (non-green)</h3>

          <p>Showcase what skills you already have</p>

          {hardskillResp?.isFetchingHardSkill ||
          hardskillRecomendedResp?.isFetchingHardSkillRecomendation ? (
            <Skeleton active={true} />
          ) : (
            <div className={`relative`}>
              {/*<CustomInputDropdownNew*/}
              {/*  from="hq"*/}
              {/*  defaultValue={"Select"}*/}
              {/*  data={hardskillListResp?.otherskill || []}*/}
              {/*  handleChangeFilter={hardskillResp.handleAddMyHardSkill}*/}
              {/*  selected={"Choose Technical Skill"}*/}
              {/*  handleScroll={handleScrollCountry}*/}
              {/*  isLoading={isLoadingFetchingCountriesNextPage}*/}
              {/*  isLoadingData={isLoadingCountries}*/}
              {/*  search={searchCountry}*/}
              {/*  setSearch={setSearchCountry}*/}
              {/*  needSearch={false}*/}
              {/*  isDropdownOpen={hardskillListResp?.isDropdownOpen}*/}
              {/*  setIsDropdownOpen={hardskillListResp?.setIsDropdownOpen}*/}
              {/*/>*/}

              <Select
                showSearch
                className="w-full job-history"
                mode="multiple"
                value={[]}
                allowClear
                loading={hardskillListResp?.isFetchingHardSkillList}
                filterOption={false}
                labelInValue
                dropdownRender={() => dropDownContent()}
                // @ts-ignore
                // options={hardskillListResp?.otherskill?.filter((item: any) => item?.name?.toLowerCase()?.includes(hardskillListResp?.search?.toLowerCase()))
                //                           ?.map((item: any) => (
                // 	                          {
                // 		                          label: item.name,
                // 		                          value: item.id,
                // 		                          disabled: item?.disabled,
                // 	                          }))}
                notFoundContent={<div>Not found! press enter to create!</div>}
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    const searchTerm = hardskillListResp?.search
                    const isTermExist = hardskillResp?.hardskills?.some(
                      (item) =>
                        item?.name?.toLowerCase() === searchTerm?.toLowerCase(),
                    )
                    if (!isTermExist) {
                      hardskillListResp?.handleAddOtherSkill(
                        hardskillListResp?.search,
                      )
                    }
                  }
                }}
                onDropdownVisibleChange={hardskillListResp?.handleClear}
                placeholder="Type to search"
                onSearch={hardskillListResp?.handleSearch}
                onClear={hardskillListResp?.handleClear}
                onSelect={hardskillResp.handleAddMyHardSkill}
              />
            </div>
          )}

          <p>My Skills</p>

          <div className={`min-h-auto max-h-44 overflow-y-scroll`}>
            {hardskillResp?.isFetchingHardSkill ||
            hardskillRecomendedResp?.isFetchingHardSkillRecomendation ? (
              <Skeleton active={true} />
            ) : (
              hardskillResp?.hardskills?.map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    className="bg-secondaryYellow py-2 flex items-center justify-between rounded-xl px-5 mb-3"
                    style={{
                      border: "1px solid #D0DDE5",
                    }}
                  >
                    <div className="flex items-center gap-6">
                      <div>
                        <p className={`m-0 p-0`}>{item?.name}</p>
                      </div>
                    </div>
                    <div className="flex items-center gap-x-2">
                      <div
                        className="cursor-pointer hover:text-red-500"
                        onClick={() =>
                          hardskillResp?.handleRemoveMyHardSkill(item?.id)
                        }
                      >
                        <CloseOutlined className="text-xl" />{" "}
                      </div>
                    </div>
                  </div>
                )
              })
            )}
          </div>

          <p>Recommended (based on your Career Aspirations)</p>

          <div className={`min-h-auto max-h-44 overflow-y-scroll`}>
            {hardskillResp?.isFetchingHardSkill ||
            hardskillRecomendedResp?.isFetchingHardSkillRecomendation ? (
              <Skeleton active={true} />
            ) : (
              hardskillRecomendedResp?.hardskillRecommendations?.map(
                (item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className="bg-[#F0F2F5] py-2 flex items-center justify-between rounded-xl px-5 mb-3"
                      style={{
                        border: "1px solid #D0DDE5",
                      }}
                    >
                      <div className="flex items-center gap-6">
                        <div>
                          <p className={`m-0`}>{item?.name}</p>
                        </div>
                      </div>
                      <div className="flex items-center gap-x-2">
                        <div
                          className="cursor-pointer hover:text-primary"
                          onClick={() =>
                            hardskillResp?.handleAddMyHardSkill(item?.id)
                          }
                        >
                          <PlusOutlined className="text-xl" />{" "}
                        </div>
                      </div>
                    </div>
                  )
                },
              )
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CVHardSkill
