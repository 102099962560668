import React, { useState } from "react"
import { Team } from "../../../assets/images"
import { ProfileInterface } from "../hook/useAboutUs"
import about_us from "../../../assets/json/about_us.json"

const About = () => {
  const data = about_us
  return (
    <main className="w-full pt-5 lg:pt-10  text-backdrop">
      <section className=" text-center my-5">
        <h2 className="p-5 lg:px-32 font-normal text-[40px] font-swanky uppercase leading-7">
          We are skilledin green
        </h2>
        <h1 className="px-5 lg:px-32 uppercase font-rammetto text-[64px] font-normal leading-[71px]">
          get to know us
        </h1>
        <img
          className="hidden lg:inline w-[890px] h-[525px] object-contain mb-3 -mt-14"
          src={Team}
        />
        <p className="px-5 lg:px-32 font-kodchasan font-normal text-xl mt-3 mb-5">
          Welcome to SkilledIn Green, the premier online destination dedicated
          to advancing the Green Economy and fostering sustainable practices. At
          SkilledIn Green, we believe in the power of connection and education
          to drive positive change. Our platform is the bridge between ambitious
          professionals and the leading voices in sustainability.
        </p>
        <h2 className="px-5 lg:px-32 font-normal text-[60px] font-swanky uppercase leading-[3rem] my-16">
          who did we build the platform for
        </h2>
        <p className="px-5 lg:px-32 font-kodchasan font-normal text-xl mb-28">
          Whether you're a <strong>recruiter</strong> looking for top-tier
          talent, a <strong>training provider</strong> aiming to share your
          expertise, a <strong>mentor</strong> wanting to give back with Climate
          Career Coaching, or a <strong>professional</strong> eager to make a
          difference, SkilledIn is your ally. We offer a comprehensive suite of
          tools and resources designed to enhance skills, knowledge, and
          opportunities in the realm of sustainability.
        </p>
        <div className="px-5 lg:px-32 p-36 bg-[#D8EAC6]">
          <h2 className=" font-rammetto font-normal text-[40px] leading-[77px]">
            Every job should be a green job.
            <br /> We’re here to make it happen
          </h2>
        </div>
      </section>
      <section className="px-5 lg:px-32 text-center mt-28 mb-5">
        <h2 className="font-normal text-[60px] font-swanky uppercase leading-[3rem] my-16">
          the team
        </h2>
        <p className=" font-kodchasan font-normal text-xl mb-8">
          We are a fully-remote international team passionate about what we do
          and the impact we create. Our approach is user-centric and
          planet-focused. User feedback is our king and we are on the quest to
          continuously improving the way we serve our community .
        </p>
        <div className=" flex flex-wrap gap-x-5 gap-y-20 justify-center">
          {data
            .filter((item: any) => item.role === "member")
            .map((item: any, i: number) => (
              <Card key={i} profile={item} />
            ))}
        </div>
      </section>
      <section className=" text-center mt-32">
        <h2 className="px-5 lg:px-32 font-normal text-[60px] font-swanky uppercase leading-[3rem]">
          the advisory board
        </h2>
        <p className="px-5 lg:px-32 font-kodchasan font-normal text-xl">
          Our Advisory Board Members strongly support Skilledin Green’s mission
          and help us shape our strategic decisions. Every step of the way, we
          get expert decision support rooted in the domain of sustainability
          careers and education. Our esteemed Advisory Board Members cover
          diverse backgrounds, including Startups & VC, Industry and Academia.
        </p>
        <div className="px-5 lg:px-32 flex flex-wrap gap-x-5 gap-y-20 justify-center mb-16">
          {data
            .filter((item: any) => item.role === "advisory")
            .map((item: any, i: number) => (
              <Card key={i} profile={item} />
            ))}
        </div>
        <div className="px-5 lg:px-32 lg:py-5  bg-[#D8EAC6]">
          <p className=" text-2xl font-swanky mb-16">
            Together, we can build a world where economic growth and
            environmental stewardship go hand in hand.
          </p>
          <h2 className=" font-rammetto font-normal text-[40px] leading-[77px] mb-24">
            Join us in our mission to cultivate a greener future, one connection
            at a time.
          </h2>
        </div>
      </section>
    </main>
  )
}
// }

interface CardProps {
  profile: ProfileInterface
}

const Card: React.FC<CardProps> = ({ profile }) => {
  const [isHover, setIsHover] = useState(false)
  return (
    <div
      className=" relative "
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <img className="w-[297px] h-[417px] object-cover" src={profile.image} />
      <div
        className={`w-[297px] h-[417px] absolute top-0 left-0 bg-backdrop bg-opacity-90 text-white p-8 overflow-auto ${
          isHover ? "visible" : "invisible"
        }`}
      >
        <p className=" font-kodchasan font-normal text-lg">{profile.about}</p>
      </div>

      <div
        className={`w-[254px] py-3 bg-white-custom flex flex-col justify-center items-center absolute ${
          profile.role === "member" ? " -bottom-[35px]" : " -bottom-[18px]"
        } left-[21.5px]`}
      >
        <h2
          className={`font-bold ${
            profile.role === "member" ? " text-2xl" : "text-xl"
          } font-kodchasan capitalize`}
        >
          {profile.name}
        </h2>
        {profile.role === "member" && (
          <h2 className=" font-normal text-lg font-kodchasan capitalizez-20">
            {profile.position}
          </h2>
        )}
      </div>
    </div>
  )
}

export default About
