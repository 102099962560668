import {
  Button,
  Checkbox,
  Divider,
  Empty,
  Input,
  message,
  Modal,
  Switch,
  Tag,
  TimePicker,
} from "antd"
import dayjs, { Dayjs } from "dayjs"
import React, { useState } from "react"
import {
  storeModalSetAvailability,
  useAvailabilityStore,
} from "../stores/storeModalSetAvailability"
import { useListMentorAvailablity } from "../hooks/useMentorAvailability"
import { Day } from "../types/response"
import TimezoneSelect from "../../../../../common/components/TimezoneSelect"
import {
  PlusOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  CaretRightOutlined,
  CaretLeftOutlined,
  CheckOutlined,
} from "@ant-design/icons"
import { ConnectAccount, InstalledApp } from "./ConnectAccount"
import { OAuthProvider } from "../../../../../utils/connect-account"
import utc from "dayjs/plugin/utc"
import localeData from "dayjs/plugin/localeData"
import { useOverrideAvailablity } from "../hooks/useMentorOverride"

dayjs.extend(utc)
dayjs.extend(localeData)

interface ModalSetAvailabiltyProps {}

const ModalSetAvailabilty: React.FC<ModalSetAvailabiltyProps> = () => {
  const {
    isOpen,
    openModal,
    closeModal,
    isOpenModalOverride,
    openModalOverride,
    closeModalOverride,
  } = storeModalSetAvailability()

  const { isLoadingSave, availabilityData } = useAvailabilityStore()

  const format = "hh:mm a"

  const {
    timezone,
    setTimezone,
    label,
    handleSaveAvailabilityDesc,
    setLabel,
    handleSwitchChange,
    handleTimeChange,
    addTimeSlot,
    removeTimeSlot,
    listData,
  } = useListMentorAvailablity()

  console.log("listData", listData)

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]

  const formatTime = (time: number) =>
    `${String(time).padStart(4, "0").slice(0, 2)}:${String(time)
      .padStart(4, "0")
      .slice(2, 4)} PM`

  const {
    currentDate,
    firstDayOfMonth,
    daysInMonth,
    dayNames,
    handleMonthChange,
    handleDateClick,
    selectedTimes,
    allDayStates,
    handleAddTimeSlot,
    handleAllDayChange,
    handleTimeChangeOverride,
    handleSave,
    year,
    month,
    selectedDates,
    handleDeleteTimeSlot,
    handleRemoveOverrideDate,
  } = useOverrideAvailablity()

  const isSelectedDate = (date: string) =>
    selectedDates.some((d) => d.toISOString().split("T")[0] === date)

  console.log("selectedDates", selectedDates)

  return (
    <div>
      <Modal
        title={`${
          isOpenModalOverride
            ? `Setup override date of ${label}`
            : `Setup Availability of ${label}`
        }`}
        width={1000}
        footer={
          isOpenModalOverride ? (
            <div className="flex items-center gap-x-2 justify-end">
              <Button
                type="primary"
                onClick={handleSave}
                disabled={selectedDates?.length === 0}
              >
                Save
              </Button>
              <Button onClick={() => closeModalOverride()}>Cancel</Button>
            </div>
          ) : null
        }
        open={isOpen}
        onOk={() => openModal()}
        onCancel={() => closeModal()}
      >
        {isOpenModalOverride ? (
          <div>
            <div className="py-4 grid grid-cols-4">
              <div className="col-span-2 px-3 bg-white-custom rounded-lg">
                <div className="col-span-1">
                  <div className="flex justify-between items-center mb-4">
                    <Button
                      className="rounded-full p-2"
                      onClick={() => handleMonthChange("prev")}
                    >
                      <CaretLeftOutlined />
                    </Button>
                    <h5>
                      {currentDate.toLocaleString("default", {
                        month: "long",
                        year: "numeric",
                      })}
                    </h5>
                    <Button
                      className="rounded-full p-2"
                      onClick={() => handleMonthChange("next")}
                    >
                      <CaretRightOutlined />
                    </Button>
                  </div>
                  <div className="grid grid-cols-7 gap-2 bg-white-custom p-2 rounded-md">
                    {dayNames.map((dayName) => (
                      <div
                        key={dayName}
                        className="text-lg font-bold text-center"
                      >
                        {dayName}
                      </div>
                    ))}
                    {Array.from({ length: firstDayOfMonth }).map((_, i) => (
                      <div
                        key={`empty-${i}`}
                        className="text-lg flex items-center justify-center px-3 py-2"
                      ></div>
                    ))}
                    {Array.from({ length: daysInMonth }, (_, i) => {
                      const day = i + 1
                      const date = `${year}-${String(month + 1).padStart(
                        2,
                        "0",
                      )}-${String(day).padStart(2, "0")}`
                      const isSelected = isSelectedDate(date)

                      return (
                        <Button
                          key={date}
                          onClick={() => handleDateClick(date)}
                          className={`text-lg flex items-center justify-center px-3 py-4 border rounded cursor-pointer hover:bg-backdrop hover:text-white shadow
                    ${
                      isSelected
                        ? "bg-backdrop text-white font-semibold"
                        : "bg-white"
                    }
                  `}
                          style={{
                            borderBottom: `5px solid ${
                              isSelected ? "#E65E61" : "#919191"
                            }`,
                          }}
                        >
                          {isSelected ? <CheckOutlined /> : day}
                        </Button>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className="col-span-2 px-3 bg-white rounded-lg h-72 overflow-y-auto">
                {selectedDates.length > 0 ? (
                  selectedDates.map((date) => {
                    const dateString = date.toISOString().split("T")[0]
                    const dayName = dayjs(date).format("dddd")
                    const isAllDay = allDayStates[dateString] || false
                    return (
                      <div key={dateString} className="mb-6">
                        <div className="flex items-center justify-between">
                          <h4>
                            {dayName}, {dateString}
                          </h4>
                          {/* <Checkbox
                            onChange={handleAllDayChange}
                            checked={allDay}
                          >
                            All Day
                          </Checkbox> */}
                          <Checkbox
                            onChange={(e) =>
                              handleAllDayChange(dateString, e.target.checked)
                            }
                            checked={isAllDay}
                          >
                            All Day
                          </Checkbox>
                        </div>
                        {(selectedTimes[dateString] || []).map(
                          (slot, index) => (
                            <div
                              key={index}
                              className="flex items-center justify-between my-1"
                            >
                              <div className="flex items-center">
                                <TimePicker
                                  value={dayjs(slot.start_date)}
                                  format="HH:mm"
                                  use12Hours
                                  disabled={isAllDay}
                                  className="mr-2"
                                  onChange={(time) =>
                                    handleTimeChangeOverride(
                                      dateString,
                                      index,
                                      "start",
                                      time,
                                    )
                                  }
                                />
                                <span>-</span>
                                <TimePicker
                                  value={dayjs(slot.end_date)}
                                  format="HH:mm"
                                  use12Hours
                                  disabled={isAllDay}
                                  className="ml-2"
                                  onChange={(time) =>
                                    handleTimeChangeOverride(
                                      dateString,
                                      index,
                                      "end",
                                      time,
                                    )
                                  }
                                />
                              </div>
                              <div className="flex items-center gap-x-3">
                                {index ===
                                  (selectedTimes[dateString] || []).length -
                                    1 &&
                                  !isAllDay && (
                                    <PlusCircleOutlined
                                      className="text-lg"
                                      onClick={() =>
                                        handleAddTimeSlot(dateString)
                                      }
                                    />
                                  )}
                                {(selectedTimes[dateString] || []).length > 1 &&
                                  !isAllDay && (
                                    <DeleteOutlined
                                      className="text-lg"
                                      onClick={() =>
                                        handleDeleteTimeSlot(dateString, index)
                                      }
                                    />
                                  )}
                              </div>
                            </div>
                          ),
                        )}
                      </div>
                    )
                  })
                ) : (
                  <div className="mt-10">
                    <Empty description="Please select a date first!" />
                  </div>
                )}
              </div>
            </div>
            <Divider />
            {/* OVERRIDE DATE LIST */}
            <div className="mt-4">
              <h4>Override Date</h4>
              <div className="h-56 overflow-y-auto">
                {listData?.day_overrides &&
                  Object.keys(listData.day_overrides).map((date) => {
                    const dayName = dayjs(date).format("dddd")
                    return (
                      <div key={date} className="my-2">
                        <div className="flex items-center justify-between py-1 px-4 rounded-lg bg-white-custom">
                          <div>
                            <h5>
                              {dayName}, {date}
                            </h5>
                            <div className="flex items-center gap-x-2 py-1">
                              {listData.day_overrides![date].map(
                                (timeSlot, index) => (
                                  <div
                                    key={index}
                                    className="flex items-center gap-x-2 my-1"
                                  >
                                    <Tag className="flex items-center gap-x-2 bg-primary-bg">
                                      <span>
                                        {dayjs(timeSlot.start_date).format(
                                          format,
                                        )}
                                      </span>
                                      -
                                      <span>
                                        {dayjs(timeSlot.end_date).format(
                                          format,
                                        )}
                                      </span>
                                    </Tag>
                                  </div>
                                ),
                              )}
                            </div>
                          </div>
                          <div>
                            <DeleteOutlined
                              className="hover:text-red-400 cursor-pointer text-lg"
                              onClick={() => handleRemoveOverrideDate(date)}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        ) : (
          <div className="py-4 grid grid-cols-5">
            <div className="col-span-2 px-3 bg-white-custom rounded-lg">
              <h4>Connect your account </h4>

              <section className="space-y-4">
                {!listData?.connected_with_google ? (
                  <ConnectAccount provider={OAuthProvider.GOOGLE} />
                ) : (
                  <InstalledApp provider={OAuthProvider.GOOGLE} />
                )}

                {!listData?.connected_with_microsoft ? (
                  <ConnectAccount provider={OAuthProvider.MICROSOFT} />
                ) : (
                  <InstalledApp provider={OAuthProvider.MICROSOFT} />
                )}
              </section>
            </div>
            <div className="col-span-3 py-1  px-5 overflow-y-auto h-[650px]">
              <div className="flex items-center justify-between">
                <h4>
                  Availability time{" "}
                  <span className="font-normal text-[10px]">
                    {isLoadingSave
                      ? "Saving changes..."
                      : isLoadingSave && "saved"}
                  </span>
                </h4>

                <Button
                  type="dashed"
                  className="flex items-center"
                  onClick={() => openModalOverride()}
                >
                  <PlusCircleOutlined /> Add Override date
                </Button>
              </div>
              <p className="text-gray-500">Mon - Fri, 09:00 - 16:00</p>
              <div
                className="my-3 flex items-center  gap-x-3 pb-3"
                style={{ borderBottom: "1px solid #dedede" }}
              >
                <div className="">
                  <Input
                    className=""
                    value={label}
                    onChange={(e) => setLabel(e.target.value)}
                  />
                </div>
                <div className="">
                  <TimezoneSelect value={timezone} onChange={setTimezone} />
                </div>
                <Button
                  loading={isLoadingSave}
                  type="primary"
                  onClick={() => handleSaveAvailabilityDesc()}
                >
                  Save Changes
                </Button>
              </div>
              {availabilityData?.map((dayData: Day, dayIndex: number) => (
                <div
                  key={dayData.id}
                  className="py-1"
                  style={{ borderBottom: "1px solid #dedede" }}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <Switch
                        className="mr-2"
                        checked={dayData.enabled}
                        disabled={isLoadingSave}
                        onChange={(checked) =>
                          handleSwitchChange(dayIndex, checked)
                        }
                      />
                      <span>{daysOfWeek[dayData.day]}</span>
                    </div>
                    <div className="flex items-center justify-between gap-x-3">
                      <div className="flex items-center justify-between my-1">
                        <div className="flex items-center">
                          <TimePicker
                            value={dayjs(
                              formatTime(dayData.start_time),
                              format,
                            )}
                            format={format}
                            use12Hours
                            disabled={isLoadingSave}
                            className="mr-2"
                            onChange={(time) =>
                              handleTimeChange(
                                dayData?.id,
                                "main",
                                "start_time",
                                time,
                              )
                            }
                          />
                          <span>-</span>
                          <TimePicker
                            value={dayjs(formatTime(dayData.end_time), format)}
                            format={format}
                            use12Hours
                            disabled={isLoadingSave}
                            className="ml-2"
                            onChange={(time) =>
                              handleTimeChange(
                                dayData?.id,
                                "main",
                                "end_time",
                                time,
                              )
                            }
                          />
                        </div>
                      </div>
                      <PlusOutlined
                        className="my-1"
                        onClick={() => addTimeSlot(dayData?.id)}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div></div>
                    {dayData?.extend_times?.map((slot, slotIndex) => (
                      <div
                        key={slotIndex}
                        className="flex items-center justify-end gap-x-3 my-1"
                      >
                        <div className="flex items-center">
                          <TimePicker
                            value={dayjs(formatTime(slot.start_time), format)}
                            format={format}
                            use12Hours
                            disabled={isLoadingSave}
                            className="mr-2"
                            onChange={(time) =>
                              handleTimeChange(
                                dayData.id,
                                slot.id!,
                                "start_time",
                                time,
                              )
                            }
                          />
                          <span>-</span>
                          <TimePicker
                            value={dayjs(formatTime(slot.end_time), format)}
                            format={format}
                            use12Hours
                            disabled={isLoadingSave}
                            className="ml-2"
                            onChange={(time) =>
                              handleTimeChange(
                                dayData.id,
                                slot.id!,
                                "end_time",
                                time,
                              )
                            }
                          />
                        </div>
                        <DeleteOutlined
                          className=""
                          onClick={() => removeTimeSlot(dayData?.id, slot?.id!)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}

export default ModalSetAvailabilty
