import React from "react"
import { Space, Progress, Image, Button } from "antd"
import { Link } from "react-router-dom"
import { useLevelUp } from "../hooks/useLevelUp"
import { BasicContainerWithTitle } from "../../../common/components/BasicBoxContainers/BasicContainerWithTitle"

interface LevelUpProps {}

const LevelUp = React.forwardRef<HTMLDivElement, LevelUpProps>((_, ref) => {
  const levelUps = useLevelUp()
  const levelUpsData = levelUps.data?.data?.data

  return (
    <>
      <BasicContainerWithTitle
        ref={ref}
        type="small"
        title="Level Up"
        titleSize="h3"
        extraTitle={
          <Link to="/dashboard/galactic-achievements/solar-trophies">
            <Button>Galactic Achievements</Button>
          </Link>
        }
      >
        <div className="flex items-center justify-start gap-4 mt-4">
          <Image
            src={levelUpsData?.image_url}
            preview={false}
            width={42}
            height={42}
          />
          <div className="flex flex-col gap-[3px] ">
            <div className="capitalize font-semibold text-[14px]">
              {levelUpsData?.title}
            </div>
            <div className="capitalize text-[12px]">
              {levelUpsData?.subtitle}
            </div>
          </div>
        </div>
        <Progress
          className="mt-3"
          percent={
            levelUpsData?.total_orbit_honors
              ? (levelUpsData?.total_user_honors /
                  levelUpsData?.total_orbit_honors) *
                100
              : 0
          }
          strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
          showInfo={false}
        />
        <div className="mt-3 flex item-center justify-start gap-x-[120px] ">
          <Space className="" size={3} direction="vertical">
            <div className="text-backdrop font-medium">TerraPoints</div>
            <div className="text-backdrop text-xl font-bold">
              {levelUpsData?.tera_point}
            </div>
          </Space>
          <Space className="" size={3} direction="vertical">
            <div className="text-backdrop font-medium">Orbit Honors</div>
            <div className="text-backdrop text-xl font-extrabold">
              {levelUpsData?.total_user_honors ?? 0}
            </div>
          </Space>
        </div>

        <div className="mt-3">
          Collect{" "}
          <span className="font-semibold">
            {levelUpsData?.total_left_honors}
          </span>{" "}
          more Honors on this Orbit to reach the next one
        </div>
      </BasicContainerWithTitle>
    </>
  )
})

export default LevelUp
