import React, { useContext } from "react"
import { z } from "zod"
import useCommunityModalsStore from "../../store/useCommunityModalsStore"
import { Controller, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { Button, Input, message, Modal } from "antd"
import {
  TwitterOutlined,
  LinkedinFilled,
  LinkOutlined,
} from "@ant-design/icons"
import { RichText } from "../../../../common/components/RichText"
import {
  useUpdateAccountBasic,
  useUpdateSocials,
} from "../../../missionControl/hooks/useUpdateAccountBasic"
import ErrorMessageBuilder from "../../../../common/components/ErrorMessageBuilder"
import { useQueryClient } from "react-query"
import { AccountContext } from "../../../../common/layout/PageLayout"

export const schema = z.object({
  linkedin: z.string().url(),
  twitter: z.string().url(),
  link: z.string().url(),
  about_me_expert: z.string(),
})
export type TEditBio = z.infer<typeof schema>

const ModalEditExpertBio = () => {
  const editExpertBioModal = useCommunityModalsStore(
    (state) => state.editExpertBioModal,
  )
  const account = useContext(AccountContext)
  const accountData = account?.data?.data
  const updateInfoMutation = useUpdateAccountBasic()
  const updateSocialsMutation = useUpdateSocials()
  const queryClient = useQueryClient()

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, errors },
  } = useForm<TEditBio>({
    resolver: zodResolver(schema),
    defaultValues: {
      linkedin: accountData?.linkedin ?? "",
      twitter: accountData?.twitter ?? "",
      link: accountData?.link ?? "",
      about_me_expert: accountData?.about_me_expert || "",
    },
  })

  React.useEffect(() => {
    reset({
      linkedin: accountData?.linkedin ?? "",
      twitter: accountData?.twitter ?? "",
      link: accountData?.link ?? "",
      about_me_expert: accountData?.about_me_expert || "",
    })
  }, [accountData])

  const onSubmit = async (data: TEditBio) => {
    message.loading({
      content: "Updating Expert Information",
      key: "update-expert-info",
    })
    await updateInfoMutation.mutateAsync(
      {
        about_me_expert: data.about_me_expert,
        first_name: accountData?.first_name,
        last_name: accountData?.last_name,
        country: accountData?.country,
        nick_name: accountData?.nick_name,
      },
      {
        onSuccess: async () => {
          await updateSocialsMutation.mutateAsync(
            {
              linkedin: data.linkedin,
              twitter: data.twitter,
              link: data.link,
              email: accountData?.email,
            },
            {
              onSuccess: async () => {
                await queryClient.invalidateQueries(["account"])
                message.success({
                  content: "Expert information updated successfully",
                  key: "update-expert-info",
                })

                editExpertBioModal.close()
              },
            },
          )
        },
        onError: () => {
          message.open({
            key: "update-expert-info",
            duration: 5,
            content: React.createElement(ErrorMessageBuilder, {
              message:
                "We encountered an issue while attempting to update your expert information. If the problem continues, please refresh the page.",
              includeReportAt: true,
            }),
            type: "error",
          })
        },
      },
    )
  }

  return (
    <Modal
      centered
      width={666}
      open={editExpertBioModal.isOpen}
      onCancel={() => editExpertBioModal.close()}
      onOk={() => {}}
      footer={null}
    >
      <div className="space-y-4 mt-6">
        <div className="text-center">
          <h2 className="text-2xl font-bold">Expert Bio</h2>
        </div>

        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-3">
            <TwitterOutlined className="text-xl" />
            <Controller
              name="twitter"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  id="twitter"
                  className="font-default flex-grow rounded-md"
                  placeholder="https://"
                />
              )}
            />
          </div>
          {errors?.twitter?.message && (
            <div className="text-sm text-danger">
              {errors?.twitter?.message}
            </div>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-3">
            <LinkOutlined className="text-xl" />
            <Controller
              name="linkedin"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  id="linkedin"
                  className="font-default flex-grow rounded-md"
                  placeholder="https://"
                />
              )}
            />
          </div>
          {errors?.linkedin?.message && (
            <div className="text-sm text-danger">
              {errors?.linkedin?.message}
            </div>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-3">
            <LinkedinFilled className="text-xl" />
            <Controller
              name="link"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  id="link"
                  className="font-default flex-grow rounded-md"
                  placeholder="https://"
                />
              )}
            />
          </div>
          {errors?.link?.message && (
            <div className="text-sm text-danger">{errors?.link?.message}</div>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <div className="text-base font-medium">About Me</div>
          <div className=" !h-[250px]">
            <Controller
              name="about_me_expert"
              control={control}
              render={({ field }) => (
                <RichText
                  {...field}
                  className="!h-[70%]"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike", "blockquote"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["link"],
                    ],
                  }}
                  formats={[
                    "header",
                    "font",
                    "size",
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "blockquote",
                    "list",
                    "bullet",
                    "indent",
                  ]}
                />
              )}
            />
          </div>
          {errors?.about_me_expert?.message && (
            <div className="text-sm text-danger">
              {errors?.about_me_expert?.message}
            </div>
          )}
        </div>
        <div className="flex justify-end mt-5">
          <Button
            className="rounded-md"
            block
            onClick={handleSubmit(onSubmit)}
            type="primary"
            loading={
              updateInfoMutation.isLoading || updateSocialsMutation.isLoading
            }
            disabled={!isDirty}
          >
            Create
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalEditExpertBio
