import React, { useEffect, useState } from "react"
import { PageContent, PageHeader } from "../../../../common/layout"
import {
  HomeOutlined,
  LikeFilled,
  LikeOutlined,
  CommentOutlined,
  ShareAltOutlined,
} from "@ant-design/icons"
import { useRoleStore } from "../../users/stores/useRoleStore"
import { Divider, message } from "antd"
import { useParams } from "react-router-dom"
import { fetchCommunityPostDetail } from "../apis/feed-post"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { CommunityPostBody } from "../components/CommunityPost"
import { addRemoveResponse } from "../apis/this-weeks-post"
import useInfiniteComments from "../hooks/useInfiniteComments"
import AddCommentTwd from "../components/AddCommentTwd"
import CommentV2 from "../components/Comment-v2"
import { LoadingOutlined } from "@ant-design/icons"
import ProfileRightSidebar from "../../users/components/ProfileRightSidebar"

const Post = () => {
  const { role } = useRoleStore()

  return (
    <PageContent
      header={
        <PageHeader
          title={"My Profile"}
          showBack
          goBackTo="/community/my"
          titleClassName="!text-2xl !font-bold"
          breadcrumbItems={[
            {
              path: "/",
              title: <HomeOutlined className="text-gray-400" />,
            },
            {
              title: "My Profile",
            },
            {
              title: role,
            },
          ]}
        ></PageHeader>
      }
      content={<Content />}
      contentRightSidebar={<ProfileRightSidebar />}
    />
  )
}

const Content = () => {
  const params = useParams()
  const postQuery = useQuery(["post-detail"], () =>
    fetchCommunityPostDetail(params.id!),
  )

  const postData = postQuery?.data?.data?.data
  const [isLiked, setIsLiked] = useState(postData?.is_liked)

  useEffect(() => {
    setIsLiked(postData?.is_liked)
  }, [postData?.is_liked])

  const reactToComment = useMutation(addRemoveResponse)
  const queryClient = useQueryClient()

  const handleReaction = async (isLiking: boolean) => {
    setIsLiked(isLiking)
    await reactToComment.mutateAsync(postData?.id ?? "", {
      onSuccess: () => {
        queryClient.invalidateQueries(["this-weeks-discussion"])
        if (isLiking) message.success("👍Discussion Liked!")
      },
      onError: (error: any) => {
        setIsLiked(!isLiking)
        message.error({
          content:
            error?.response?.data?.message ||
            "Something went wrong whilst commenting, try again!",
        })
        console.error(error)
      },
    })
  }

  const { comments, lastCardElementRef } = useInfiniteComments(postData?.id)

  return (
    <div className="shadow-md bg-[#FDFDFD] rounded-[10px] px-8 py-6">
      <div className="font-semibold text-xl">Post Discussion</div>
      <Divider />
      {postQuery.isLoading ? (
        <div className="h-[300px] w-full flex justify-center items-center">
          <LoadingOutlined className="text-xl text-primary" />
        </div>
      ) : (
        <>
          <div className="">
            {postData && <CommunityPostBody post={postData} />}
          </div>
          <div className="text-lg text-primary">{postData?.hashtags}</div>
          <div className="flex items-center gap-3 mt-4">
            <div
              className="bg-bg py-[10px] px-[20px] flex rounded-[10px] cursor-pointer hover:scale-110 transition-all duration-300 gap-2 items-center"
              onClick={() => handleReaction(!isLiked)}
            >
              {isLiked ? (
                <LikeFilled className="transition-all duration-200 cursor-pointer hover:text-primary hover:scale-110" />
              ) : (
                <LikeOutlined className="transition-all duration-200 cursor-pointer hover:text-primary hover:scale-110" />
              )}
              <span>{postData?.total_responses}</span>
            </div>
            <div className="bg-bg py-[10px] px-[20px] flex rounded-[10px] cursor-pointer hover:scale-110 transition-all duration-300 gap-2 items-center">
              <CommentOutlined />
              <span>{postData?.total_comments}</span>
            </div>
            <div className="bg-bg py-[10px] px-[20px] flex rounded-[10px] cursor-pointer hover:scale-110 transition-all duration-300 gap-2 items-center">
              <ShareAltOutlined />
            </div>
          </div>
          <div className="mt-6">
            <AddCommentTwd discussion_id={postData?.id ?? ""} />
          </div>

          <div className="mt-6 space-y-6">
            {comments?.map((comment, idx) => (
              <CommentV2
                observ={lastCardElementRef}
                key={idx}
                comment={comment}
              />
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default Post
