import React from "react"
import { Button, Card, Input } from "antd"
import { useBasicUpdate } from "../../../hooks/cv-builder/useBasicUpdate"
import { Controller } from "react-hook-form"
import { RichText } from "../../../../../common/components/RichText"
import { Account } from "../../../hooks/cv-builder/useProfile"
import { assignAvatar } from "../../../../../assets/avatar_images"
import { Common } from "../../../hooks/cv-builder/useJobHistory"
import { SearchLocation } from "../../../../../common/components/SearchLocation"

interface Props {
  handleChangeState: (index: number) => void
  data: Account | undefined
  countries: {
    data: Common[] | undefined
    loading: boolean
  }
}

const b = "https://stgreenskills001.blob.core.windows.net/default-avatars/"
export const CardUpdateBasic: React.FC<Props> = ({
  handleChangeState,
  data,
}) => {
  const {
    control,
    errors,
    watch,
    handleSubmit,
    onSubmit,
    imageUrl,
    handleImageChange,
    handleRemoveImage,
    loadingImage,
    loadingUpdate,
    setValue,
  } = useBasicUpdate(data, handleChangeState)
  return (
    <form className="w-full p-5 rounded-xl bg-white shadow-md">
      <div className="flex gap-5 flex-wrap lg:flex-nowrap">
        <Card className=" shadow-md w-full flex-grow rounded-lg basis-1/2 bg-[#FAFAFA]">
          <div className="flex items-center flex-col mb-1">
            <img
              className="w-24 h-24 rounded-full object-contain shadow-md p-1"
              src={
                imageUrl ||
                assignAvatar(`${data?.first_name} ${data?.last_name}`)
              }
              alt="profile"
            />
            <div className="flex gap-3 text-xs my-2">
              <label
                htmlFor="image-profile"
                className=" text-secondary cursor-pointer"
              >
                Replace
              </label>
              {!imageUrl?.startsWith(b) && (
                <div
                  className="text-danger cursor-pointer"
                  onClick={() => {
                    if (!loadingImage) handleRemoveImage()
                  }}
                >
                  Remove
                </div>
              )}
              <input
                disabled={loadingImage}
                type="file"
                accept="image/png, image/gif, image/jpeg"
                id="image-profile"
                className=" hidden"
                value={""}
                onChange={(e) => {
                  handleImageChange(e)
                }}
              />
            </div>
          </div>
          <div className="flex gap-3">
            <Controller
              name="first_name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  status={errors?.first_name ? "error" : ""}
                  className="my-1 font-default rounded basis-1/2"
                  placeholder="first name"
                />
              )}
            />
            <Controller
              name="last_name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  status={errors?.last_name ? "error" : ""}
                  className="my-1 font-default rounded basis-1/2"
                  placeholder="last name"
                />
              )}
            />
          </div>
          <div className="flex flex-wrap lg:flex-nowrap gap-3">
            <Controller
              name="nick_name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  status={errors?.nick_name ? "error" : ""}
                  className="my-1 font-default rounded flex-grow basis-1/2"
                  placeholder="nickname"
                />
              )}
            />
            <Controller
              name="address"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <SearchLocation
                  className="my-1 max-w-full font-default basis-1/2 job-history"
                  status={errors?.country ? "error" : ""}
                  defaultValue={data?.address}
                  onHandleSelect={(e) => {
                    setValue("country", e.address.country)
                    setValue("address", e.address.freeformAddress)
                    setValue("location_detailed", JSON.stringify(e.position))
                  }}
                  {...field}
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="profile_headline"
              control={control}
              rules={{ maxLength: 256 }}
              render={({ field }) => (
                <Input.TextArea
                  {...field}
                  status={errors?.profile_headline ? "error" : ""}
                  className="my-1 font-default rounded "
                  placeholder="Profile Headline"
                  rows={5}
                  style={{ resize: "none" }}
                />
              )}
            />

            <div className="text-right text-xs">{`${
              watch("profile_headline")?.length || 0
            }/256`}</div>
          </div>
        </Card>
        <Card className="shadow-md w-full flex-grow rounded-lg basis-1/2 bg-[#FAFAFA]">
          <h3 className="capitalize">Professional Summary</h3>
          <div className="my-2">
            This information will be displayed as your summary in Green CV
          </div>
          <Controller
            name="about_me"
            control={control}
            render={({ field }) => (
              <RichText {...field} className="w-full h-52" />
            )}
          />
        </Card>
      </div>
      <div className="mt-5 flex justify-end gap-5">
        <Button type="primary" ghost onClick={() => handleChangeState(0)}>
          Cancel
        </Button>
        <Button
          type="primary"
          disabled={loadingUpdate}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </div>
    </form>
  )
}
