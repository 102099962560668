import { Controller } from "react-hook-form"
import { Input, Button, Form, Divider, Upload, Select } from "antd"
import {
  SendOutlined,
  DeleteOutlined,
  PlusOutlined,
  PictureOutlined,
} from "@ant-design/icons"
import NewPostIcons from "../NewPostIcons"
import { deleteMedia, uploadMedia } from "../../apis/feed-post"
import clsx from "clsx"
import { beforeUpload } from "../../../../../common/components/FormImageUploader"
import { RcFile } from "antd/es/upload"
import { useHowToForm } from "../../hooks/useHowToForm"
import React from "react"
// import { howtoCategories } from "../../data"

const MAX_HOW_TOS = 10

const HowTo = () => {
  const {
    handleSubmit,
    control,
    formState,
    fields,
    append,
    remove,
    isHovered,
    setIsHovered,
    createPost,
    closeModal,
    onSubmit,
    trigger,
  } = useHowToForm()
  const [page, setPage] = React.useState<1 | 2>(1)

  const handleNext = async () => {
    const result = await trigger(["title", "howto_category"])
    if (result) {
      setPage(2)
    }
  }

  return (
    <Form className="relative my-6" layout="vertical">
      {page === 1 && (
        <>
          <Form.Item
            label="Choose the Category"
            required
            validateStatus={formState?.errors?.howto_category ? "error" : ""}
            help={formState?.errors.howto_category?.message}
          >
            <Controller
              name="howto_category"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Select Category"
                  labelRender={(props) => props.label}
                  options={[]}
                  fieldNames={{ value: "name", label: "name" }}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label="Title of How-tos you would like to share"
            required
            validateStatus={formState?.errors?.title ? "error" : ""}
            help={formState?.errors?.title?.message}
            className=""
          >
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="e.g. How to achieve net-zero emissions?"
                />
              )}
            />
          </Form.Item>
          <Controller
            name={`howto_image_url` as const}
            control={control}
            defaultValue={undefined}
            render={({ field: { onChange, value } }) => {
              return value ? (
                <div
                  className={clsx("w-full relative h-[250px] rounded-lg")}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  style={{ borderRadius: "20px" }}
                >
                  <img
                    src={value}
                    alt="avatar"
                    className="absolute top-0 left-0 object-contain w-full h-full"
                    style={{ borderRadius: "20px" }}
                  />
                  <div
                    className={clsx(
                      "absolute inset-0 top-0 left-0 z-10 flex items-center justify-center w-full h-full gap-2 bg-black opacity-0 transtion-all duration-300",
                      {
                        "hover:opacity-50 ": isHovered,
                      },
                    )}
                    style={{ borderRadius: "20px" }}
                  >
                    <DeleteOutlined
                      className="z-50 text-2xl text-white"
                      onClick={async (e) => {
                        e.stopPropagation()
                        await deleteMedia(value)
                        onChange(undefined)
                      }}
                    />
                  </div>
                </div>
              ) : (
                <Upload.Dragger
                  name="image"
                  listType="picture"
                  className=" rounded-lg"
                  style={{ borderRadius: "20px" }}
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  customRequest={async ({ file }) => {
                    const response = await uploadMedia(file as RcFile)
                    onChange(response.data?.data)
                  }}
                  onRemove={async () => {
                    await deleteMedia(value ?? "")
                    onChange(undefined)
                  }}
                  maxCount={1}
                >
                  <>
                    <PictureOutlined className="text-[159px]" />
                    <div className="text-xl">Upload Thumbnail</div>
                    <div className="mt-1 text-lg text-center">
                      The image will representing all steps (optional)
                    </div>
                  </>
                </Upload.Dragger>
              )
            }}
          />
          <div className="flex items-center justify-between w-full mt-5">
            <NewPostIcons />{" "}
            <div className="flex items-center gap-4">
              <Button onClick={closeModal}>Cancel</Button>{" "}
              <Button onClick={handleNext}>Next</Button>
            </div>
          </div>
        </>
      )}

      {page === 2 && (
        <>
          {fields.map((field, index) => (
            <div
              key={field.id}
              className={clsx("", {
                "mt-6": index > 0,
                "mb-6": index === MAX_HOW_TOS - 1,
              })}
            >
              <h5 className="text-base font-normal">Step #{index + 1}</h5>
              <div className="flex w-full gap-4 mt-2">
                <Controller
                  name={`howto_items.${index}.image_url` as const}
                  control={control}
                  defaultValue={undefined}
                  render={({ field: { onChange, value } }) => {
                    return value ? (
                      <div
                        className={clsx(
                          "relative w-[254px] aspect-square rounded-lg",
                        )}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        style={{ borderRadius: "20px" }}
                      >
                        <img
                          src={value}
                          alt="avatar"
                          className="absolute top-0 left-0 object-contain w-full h-full"
                          style={{ borderRadius: "20px" }}
                        />
                        <div
                          className={clsx(
                            "absolute inset-0 top-0 left-0 z-10 flex items-center justify-center w-full h-full gap-2 bg-black opacity-0 transtion-all duration-300",
                            {
                              "hover:opacity-50 ": isHovered,
                            },
                          )}
                          style={{ borderRadius: "20px" }}
                        >
                          <DeleteOutlined
                            className="z-50 text-2xl text-white"
                            onClick={async (e) => {
                              e.stopPropagation()
                              await deleteMedia(value)
                              onChange(undefined)
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <Upload.Dragger
                        name="image"
                        listType="picture"
                        className="w-[254px] aspect-square rounded-lg"
                        style={{ borderRadius: "20px" }}
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        customRequest={async ({ file }) => {
                          const response = await uploadMedia(file as RcFile)
                          onChange(response.data?.data)
                        }}
                        onRemove={async () => {
                          await deleteMedia(value ?? "")
                          onChange(undefined)
                        }}
                        maxCount={1}
                      >
                        <>
                          <PictureOutlined className="text-[100px]" />
                          <p className="mt-1">{"Upload"}</p>
                        </>
                      </Upload.Dragger>
                    )
                  }}
                />

                <div className="flex-grow flex flex-col justify-start min-h-full">
                  <Form.Item
                    label="Subtitle"
                    validateStatus={
                      formState?.errors?.howto_items?.[index]?.name
                        ? "error"
                        : ""
                    }
                    help={
                      formState?.errors?.howto_items?.[index]?.name?.message
                    }
                    className=""
                  >
                    <div className="flex items-center gap-2">
                      <Controller
                        name={`howto_items.${index}.name` as const}
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            placeholder="Subtitle"
                            status={
                              formState?.errors?.howto_items?.[index]?.name
                                ? "error"
                                : undefined
                            }
                          />
                        )}
                      />
                      {index !== 0 && (
                        <Button danger onClick={() => remove(index)}>
                          <DeleteOutlined />
                        </Button>
                      )}
                    </div>
                  </Form.Item>
                  <Form.Item
                    label="Description"
                    className="flex-grow h-full"
                    rootClassName="flex-grow"
                    validateStatus={
                      formState?.errors?.howto_items?.[index]?.description
                        ? "error"
                        : ""
                    }
                    help={
                      formState?.errors?.howto_items?.[index]?.description
                        ?.message
                    }
                  >
                    <Controller
                      name={`howto_items.${index}.description` as const}
                      control={control}
                      render={({ field }) => (
                        <Input.TextArea
                          {...field}
                          placeholder="Description"
                          styles={{
                            textarea: {
                              resize: "none",
                            },
                          }}
                          rows={7}
                          className=" resize-none  flex-grow"
                          status={
                            formState?.errors?.howto_items?.[index]?.description
                              ? "error"
                              : undefined
                          }
                        />
                      )}
                    />
                  </Form.Item>
                </div>
              </div>
              {index !== fields.length - 1 && <Divider />}
            </div>
          ))}

          {fields.length < MAX_HOW_TOS && (
            <div className="flex justify-center w-full my-4">
              <button
                className="p-2 !border-gray-300 ring-0 flex items-center gap-2 w-full text-base rounded-[10px] justify-center text-backdrop cursor-pointer "
                onClick={() =>
                  append({
                    id: fields.length + 1,
                    name: "",
                    description: "",
                    image_url: undefined,
                  })
                }
              >
                <PlusOutlined className="" />
                <span>Add Step</span>
              </button>
            </div>
          )}
          <div className="flex items-center justify-between w-full mt-5">
            <NewPostIcons />{" "}
            <div className="flex items-center gap-4">
              <Button onClick={() => setPage(1)}>Back</Button>
              <Button
                className="text-white bg-primary"
                loading={createPost.isLoading}
                icon={<SendOutlined />}
                disabled={createPost.isLoading || !formState.isDirty}
                onClick={handleSubmit(onSubmit)}
              >
                Post
              </Button>
            </div>
          </div>
        </>
      )}
    </Form>
  )
}

export default HowTo
