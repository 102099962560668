import React, { useState } from "react"
import { ChatIcon } from "../../../../assets/images"
import {
  CaretDownOutlined,
  UsergroupAddOutlined,
  LoadingOutlined,
} from "@ant-design/icons"
import { Button, message } from "antd"
import clsx from "clsx"
import { useThisWeekDiscussionStore } from "../../store/thisWeekDiscussionStore"
import { useQuery } from "react-query"
import { getThisWeeksPost } from "../apis/this-weeks-post"
import ErrorMessageBuilder from "../../../../common/components/ErrorMessageBuilder"

const ThisWeeksPost: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { openDrawer } = useThisWeekDiscussionStore()
  const { data, isLoading } = useQuery(
    ["this-weeks-discussion"],
    () => getThisWeeksPost("ongoing"),
    {
      onError: () => {
        message.open({
          key: "this-weeks-discussion",
          duration: 5,
          content: React.createElement(ErrorMessageBuilder, {
            message:
              "We encountered an issue while attempting to fetch this week's discussion. If the problem continues, please refresh the page.",
            includeReportAt: true,
          }),
          type: "error",
        })
      },
      retry: 2,
    },
  )
  const weeksDiscussionData = data?.data?.data?.[0]

  return (
    <div
      className={clsx(
        "w-full p-5 mx-auto my-6 transition-all duration-300  rounded-lg shadow",
        {
          "bg-white text-backdrop": isExpanded,
          "bg-backdrop text-white": !isExpanded,
        },
      )}
    >
      <div className="flex items-center justify-between">
        <div className="flex gap-4">
          <div className="grid p-4 rounded bg-secondaryYellow place-content-center">
            <img src={ChatIcon} alt={"chat-icon"} className="w-8 h-8" />
          </div>
          <div className="">
            <h1 className="text-lg font-bold ">This Week's Discussion</h1>
            <div className="text-base">
              {isLoading ? (
                <LoadingOutlined />
              ) : weeksDiscussionData ? (
                <div className="">{weeksDiscussionData?.body?.title}</div>
              ) : (
                <div className={" "}>
                  <p className={" m-0 p-0"}>No data</p>
                </div>
              )}
            </div>
          </div>
        </div>
        {weeksDiscussionData && (
          <div className="flex items-center h-full gap-3 text-2xl">
            <CaretDownOutlined
              onClick={() => setIsExpanded(!isExpanded)}
              className={clsx(
                "transition-all duration-300 cursor-pointer hover:scale-105",
                {
                  "rotate-180": !isExpanded,
                },
              )}
            />
          </div>
        )}
      </div>
      <div
        className={clsx(" overflow-hidden transition-all duration-300 ", {
          "h-0": !isExpanded,
          "h-fit mt-4": isExpanded,
        })}
      >
        <div>{weeksDiscussionData?.body?.description}</div>
        <div className="mt-2">{weeksDiscussionData?.hashtags}</div>
        <div className="flex items-center justify-between mt-6 ">
          <div className="flex items-center rounded-full bg-secondaryYellow w-fit">
            <div className="p-2 py-1 text-white rounded-full bg-brown">
              <UsergroupAddOutlined />
            </div>
            <div className="px-2 ">
              {weeksDiscussionData?.total_comments &&
              weeksDiscussionData?.total_comments >= 10
                ? "10+"
                : weeksDiscussionData?.total_comments}{" "}
              Response
            </div>
          </div>
          <Button className="text-white bg-primary" onClick={openDrawer}>
            Add Comment
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ThisWeeksPost
