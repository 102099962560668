import React from "react"
import { useForm } from "react-hook-form"
import { Button } from "antd"
import RadioButton from "../../../../common/components/RadioButton"
import { Job } from "../../types/jobBoard"

interface Props {
  detailData: Job
  helper: {
    hasEmptyStringValue: (obj: any) => boolean
  }
  action: {
    handlePassedStrength: () => void
    handleScreening: (val: any) => void
  }
  loading: boolean
}

export const DetailQuestion: React.FC<Props> = ({
  detailData,
  helper,
  action,
  loading,
}) => {
  const { register, handleSubmit, setValue, watch } = useForm()
  return (
    <form className="">
      {detailData?.screening_questions?.map((v: any, i: number) => (
        <div key={i} className="bg-[#FFF8F5] my-3">
          <div className=" bg-[#E2EBFA] p-5 first-letter:capitalize font-semibold">
            {v.name}
          </div>
          <div className=" p-5">
            <div className=" first-letter:capitalize font-semibold mb-3">
              {v.question}
            </div>
            {
              // @ts-ignore
              <RadioButton
                direction="column"
                className="bg-[#F5F5F5] px-4 py-2"
                // @ts-ignore
                handleChange={(res: any) => {
                  setValue(v.id, res)
                }}
              >
                {v.choices?.map((vc: any, ic: number) => (
                  // @ts-ignore
                  <div name={vc.name} className=" capitalize" key={ic}>
                    {vc.name}
                  </div>
                ))}
              </RadioButton>
            }
            <input {...register(v.id, {})} className=" hidden" />
          </div>
        </div>
      ))}
      <div className=" text-end mt-5">
        <Button
          loading={loading}
          disabled={helper.hasEmptyStringValue(watch())}
          onClick={handleSubmit(action.handleScreening)}
        >
          Next
        </Button>
      </div>
    </form>
  )
}
