/* eslint-disable */

import { NotLaunched } from "../../../../assets/images"
import { Badge } from "../../types/notifs"
import { Image } from "antd"

export const BadgeContent: React.FC<{ data: Badge | undefined }> = ({
  data,
}) => (
  <div className="flex flex-col items-center">
    <div className="text-center text-[18px] font-semibold">{data?.title}</div>
    <Image
      src={data?.image_url ?? NotLaunched}
      width={96}
      preview={false}
      className="mt-5"
    />
    <div className="text-center text-[14px] mt-5">{data?.subtitle}</div>
  </div>
)
