import { useCallback, useEffect, useRef, useState } from "react"
import { PublicArticleResponseTypes } from "../types/article_response"
import {
  useFetchDetailPublishArticle,
  useFetchPublishLoginArticleList,
  useFetchPublishCategoryGreenSkillArticleList,
  useFetchPublishExpertArticleList,
  // useFetchPublishCategoryGreenSkillArticleList,
  useFetchPublishGreenSkillArticleList,
  useLikeArtcile,
  useUnLikeArtcile,
  useFetchPublishArticleList,
  useFollowExpert,
  useUnFollowExpert,
  useFetchDetailPublishAsLoginArticle,
  usePostReqExpert,
} from "../apis/rest"
import { debounce } from "../../../utils/debounce"
import { message } from "antd"
import { useMsal } from "@azure/msal-react"
import { useForm } from "react-hook-form"
import { useLanguage } from "../../missionControl/hooks/cv-builder/useLanguage"

export const usePublishArticle = () => {
  const [dataList, setDataList] = useState<PublicArticleResponseTypes[]>([])
  const [name, setName] = useState<string | undefined>(undefined)
  const [limit, setLimit] = useState<number | undefined>(10)
  const [page, setPage] = useState<number | undefined>(1)
  const [selectedGreenSkillIds, setSelectedGreenSkillIds] = useState<any[]>([])
  const [selectedExpertIds, setSelectedExpertIds] = useState<any[]>([])
  const [selectedCategoryGreenSkillIds, setSelectedCategoryGreenSkillIds] =
    useState<any[]>([])
  const [totalData, setTotalData] = useState<number>(0)
  const [noMoreData, setNoMoreData] = useState<string | undefined>(undefined)

  const [dataOptionGS, setDataOptionGS] = useState<any[]>([])
  const [dataOptionGSCategory, setDataOptionGSCategory] = useState<any[]>([])
  const [dataOptionExpert, setDataOptionExpert] = useState<any[]>([])
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [modalViewChange, setModalViewChange] = useState<boolean>(false)
  const [isSignInModal, setIsSignInModal] = useState<boolean>(false)
  const [loadingReqExpert, setLoadingReqExpert] = useState<boolean>(false)

  const restPublishGSArticleResponse = useFetchPublishGreenSkillArticleList()
  const restPublishCategoryGSArticleResponse =
    useFetchPublishCategoryGreenSkillArticleList()
  const restPublishExpertArticleResponse = useFetchPublishExpertArticleList()
  const restLikeArticle = useLikeArtcile()
  const restUnLikeArticle = useUnLikeArtcile()

  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()

  useEffect(() => {
    if (!restPublishGSArticleResponse.isLoading) {
      setDataOptionGS(restPublishGSArticleResponse?.data?.data?.data ?? [])
    }
  }, [restPublishGSArticleResponse])

  useEffect(() => {
    if (!restPublishCategoryGSArticleResponse.isLoading) {
      setDataOptionGSCategory(
        restPublishCategoryGSArticleResponse?.data?.data?.data ?? [],
      )
    }
  }, [restPublishCategoryGSArticleResponse])

  useEffect(() => {
    if (!restPublishExpertArticleResponse.isLoading) {
      setDataOptionExpert(
        restPublishExpertArticleResponse?.data?.data?.data ?? [],
      )
    }
  }, [restPublishExpertArticleResponse])

  const restPublishArticleResponse = activeAccount
    ? useFetchPublishLoginArticleList({
        search: name,
        limit,
        page,
        green_skill_ids: selectedGreenSkillIds
          ?.map((item) => item.id)
          .join(","),
        green_skill_category_ids: selectedCategoryGreenSkillIds
          ?.map((item) => item.id)
          .join(","),
        expert_id: selectedExpertIds?.map((item) => item.id).join(","),
      })
    : useFetchPublishArticleList({
        search: name,
        limit,
        page,
        green_skill_ids: selectedGreenSkillIds
          ?.map((item) => item.id)
          .join(","),
        green_skill_category_ids: selectedCategoryGreenSkillIds
          ?.map((item) => item.id)
          .join(","),
        expert_id: selectedExpertIds?.map((item) => item.id).join(","),
      })

  useEffect(() => {
    if (!restPublishArticleResponse.isLoading) {
      if (page === 1) {
        setDataList(restPublishArticleResponse.data?.data?.data?.list ?? [])
        setTotalData(
          restPublishArticleResponse.data?.data?.data?.total_data ?? 0,
        )
      } else {
        setDataList((prev) => [
          ...prev,
          ...(restPublishArticleResponse.data?.data?.data?.list ?? []),
        ])
        setTotalData(
          restPublishArticleResponse.data?.data?.data?.total_data ?? 0,
        )
      }
    }
    //
  }, [restPublishArticleResponse.data?.data?.data?.list])

  const handleSearch = debounce((e: any) => {
    setName(e.target.value)
    setPage(1)
  }, 1000)

  const handleSelectGS = (item: string) => {
    if (selectedGreenSkillIds.includes(item)) {
      setSelectedGreenSkillIds(
        selectedGreenSkillIds.filter((selectedItem) => selectedItem !== item),
      )
    } else {
      setSelectedGreenSkillIds([...selectedGreenSkillIds, item])
    }
    setPage(1)
  }

  const handleRemoveSelectedGS = (id: string) => {
    const updatedSelectedSector = selectedGreenSkillIds.filter(
      (selectedGreenSkillIds) => selectedGreenSkillIds?.id !== id,
    )
    setSelectedGreenSkillIds(updatedSelectedSector)
    setPage(1)
    setNoMoreData(undefined)
  }
  const handleSelectExpert = (item: string) => {
    if (selectedExpertIds.includes(item)) {
      setSelectedExpertIds(
        selectedExpertIds.filter((selectedItem) => selectedItem !== item),
      )
    } else {
      setSelectedExpertIds([...selectedExpertIds, item])
    }
    setPage(1)
  }

  const handleRemoveSelectedExpert = (id: string) => {
    const updatedSelectedSector = selectedExpertIds.filter(
      (selectedExpertIds) => selectedExpertIds?.id !== id,
    )
    setSelectedExpertIds(updatedSelectedSector)
    setNoMoreData(undefined)
    setPage(1)
  }

  const handleSelectGSCategory = (item: string) => {
    if (selectedCategoryGreenSkillIds.includes(item)) {
      setSelectedCategoryGreenSkillIds(
        selectedCategoryGreenSkillIds.filter(
          (selectedItem) => selectedItem !== item,
        ),
      )
    } else {
      setSelectedCategoryGreenSkillIds([...selectedCategoryGreenSkillIds, item])
    }

    setPage(1)
  }

  const handleRemoveSelectedGSCategory = (id: string) => {
    const updatedSelectedSector = selectedCategoryGreenSkillIds.filter(
      (selectedCategoryGreenSkillIds) =>
        selectedCategoryGreenSkillIds?.id !== id,
    )
    setSelectedCategoryGreenSkillIds(updatedSelectedSector)
    setNoMoreData(undefined)
    setPage(1)
  }

  const observer = useRef<IntersectionObserver | null>(null)
  const lastCardElementRef = useCallback(
    (node: Element | null) => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          dataList.length &&
          dataList?.length < totalData &&
          !restPublishArticleResponse.isLoading &&
          !noMoreData
        ) {
          setPage((prev) => prev! + 1)
        } else {
          setNoMoreData("No more data to load.")
        }
      })
      if (node) observer.current.observe(node)
    },
    //
    [dataList?.length],
  )

  useEffect(() => {
    if (!restPublishArticleResponse.isLoading) {
      if (page === 1) {
        setTotalData(
          restPublishArticleResponse?.data?.data?.data?.total_data ?? 0,
        )
      }
    }
    //
  }, [restPublishArticleResponse])

  const handleLikeArticle = async (slug: string) => {
    try {
      await restLikeArticle.mutateAsync({
        slug: slug,
      })
    } catch (error: any) {
      message.error(
        "An error occurred while save your article: " +
          error.response?.data?.data,
      )
    }
  }
  const handleUnLikeArticle = async (slug: string) => {
    try {
      await restUnLikeArticle.mutateAsync({
        slug: slug,
      })
    } catch (error: any) {
      message.error(
        "An error occurred while save your article: " +
          error.response?.data?.data,
      )
    }
  }

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      article_link: "",
      english_proficiency: 0,
      justification: "",
      linkedin_link: "",
    },
  })

  const handleSelectEnglishProf = (data: number) => {
    setValue("english_proficiency", data)
  }

  const reqExpertMutation = usePostReqExpert()
  const handleRequestExpert = async (data: any) => {
    setLoadingReqExpert(true)

    setTimeout(() => {
      setLoadingReqExpert(false)
    }, 1000)

    const res = await reqExpertMutation.mutateAsync(data)

    if (res?.data?.code === 200) {
      setTimeout(() => {
        setModalViewChange(false)
        setLoadingReqExpert(false)
        setOpenModal(false)
        setValue("english_proficiency", 0)
        setValue("article_link", "")
        setValue("justification", "")
        setValue("linkedin_link", "")
      }, 1000)
    }
  }

  const { proficiencies } = useLanguage()

  return {
    publishArticles: {
      dataList,
      isLoading: restPublishArticleResponse.isLoading,
      noMoreData,
    },
    queryArticleResponse: {
      handleSearch,
      setLimit,
      limit,
      page,
      setPage,
      handleSelectGS,
      handleRemoveSelectedGS,
      handleSelectGSCategory,
      handleRemoveSelectedGSCategory,
      handleSelectExpert,
      handleRemoveSelectedExpert,
      selectedGreenSkillIds,
      selectedCategoryGreenSkillIds,
      selectedExpertIds,
      lastCardElementRef,
      handleLikeArticle,
      handleUnLikeArticle,
      isSignInModal,
      setIsSignInModal,
    },
    filterData: {
      greenSkills: {
        data: dataOptionGS,
        isLoading: restPublishGSArticleResponse.isLoading,
      },
      categoryGS: {
        data: dataOptionGSCategory,
        isLoading: restPublishCategoryGSArticleResponse.isLoading,
      },
      expert: {
        data: dataOptionExpert,
        isLoading: restPublishExpertArticleResponse.isLoading,
      },
    },
    reqExpert: {
      openModal,
      setOpenModal,
      handleSubmit,
      control,
      setValue,
      watch,
      isDirty,
      errors,
      handleSelectEnglishProf,
      handleRequestExpert,
      modalViewChange,
      setModalViewChange,
      loadingReqExpert,
      proficiencies,
    },
  }
}

export const useDetailPublicArticle = (slug?: string) => {
  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()

  const { queryArticleResponse } = usePublishArticle()

  const restFollowExpert = useFollowExpert()
  const restUnfollowExpert = useUnFollowExpert()
  const articleDetailResponse = activeAccount
    ? useFetchDetailPublishAsLoginArticle(slug!)
    : useFetchDetailPublishArticle(slug!)

  const handleFollowExpert = async (expertID: string) => {
    try {
      await restFollowExpert.mutateAsync({
        expertID: expertID,
      })
    } catch (error: any) {
      message.error(
        "An error occurred while save your article: " +
          error.response?.data?.data,
      )
    }
  }
  const handleUnFollowExpert = async (expertID: string) => {
    try {
      await restUnfollowExpert.mutateAsync({
        expertID: expertID,
      })
    } catch (error: any) {
      message.error(
        "An error occurred while save your article: " +
          error.response?.data?.data,
      )
    }
  }

  return {
    articleResponse: {
      data: articleDetailResponse?.data?.data,
      isLoading: articleDetailResponse.isLoading,
    },
    queryArticleResponse,
    expertQuery: {
      handleFollowExpert,
      handleUnFollowExpert,
    },
  }
}
