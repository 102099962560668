import { useEffect, useState } from "react"
import instance from "../../../config/config"
import { useMutation, useQuery, useQueryClient } from "react-query"
import ErrorMessageBuilder from "../../../common/components/ErrorMessageBuilder"

export const usePrivacySetting = () => {
  const queryClient = useQueryClient()
  const [statusPrivate, setStatusPrivate] = useState({
    full_name: true,
    profile_picture: true,
    social_media: true,
    email: true,
    discover: false,
  })

  const { mutate: update, isLoading: loadingUpdate } = useMutation({
    mutationFn: (val) => {
      return instance.post(`/accounts/privacy-setting`, val)
    },
    mutationKey: "account-privacy-update",
  })

  const updatePrivacy = (updatedStatus) => {
    update(
      {
        full_name: updatedStatus.full_name,
        profile_picture: updatedStatus.profile_picture,
        social_media: updatedStatus.social_media,
        email: updatedStatus.email,
        id: privacy?.data?.data?.id,
        discover: updatedStatus.discover,
      },
      {
        onSuccess: () => {
          // queryClient.invalidateQueries(["account-privacy"]);
          queryClient.invalidateQueries(["fetch-leaderboards"])
        },
      },
    )
  }
  const changeStatus = (field, status) => {
    setStatusPrivate((prev) => {
      const updatedStatus = { ...prev, [field]: status }
      updatePrivacy(updatedStatus) // call updatePrivacy in callback
      return updatedStatus
    })
  }

  const { data: privacy, isLoading: loadingPrivacy } = useQuery(
    "account-privacy",
    () => instance.get("/accounts/privacy-setting"),
    {
      onError: () => {
        message.open({
          key: "account-privacy",
          duration: 5,
          content: React.createElement(ErrorMessageBuilder, {
            message:
              "We encountered an issue while attempting to fetch your privacy settings. If the problem continues, please refresh the page.",
            includeReportAt: true,
          }),
          type: "error",
        })
      },
      retry: 2,
    },
  )

  useEffect(() => {
    if (privacy?.data?.data) {
      setStatusPrivate((prev) => ({
        ...prev,
        id: privacy?.data?.data?.id,
        user_id: privacy?.data?.data?.user_id,
        full_name: privacy?.data?.data?.full_name,
        profile_picture: privacy?.data?.data?.profile_picture,
        social_media: privacy?.data?.data?.social_media,
        email: privacy?.data?.data?.email,
        discover: privacy?.data?.data?.discover,
      }))
    }
  }, [privacy?.data?.data])

  return {
    statusPrivate,
    changeStatus,
    loadingPrivacy,
    loadingUpdate,
  }
}
