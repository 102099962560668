import React from "react"
import { Controller } from "react-hook-form"
import { Input, Button, Form, Radio, Tooltip } from "antd"
import { PlusOutlined, DeleteOutlined, SendOutlined } from "@ant-design/icons"
import NewPostIcons from "../NewPostIcons"
import { useMultipleChoiceForm } from "../../hooks/useMultipleChoiceForm"

const MultipleChoice: React.FC = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    fields,
    append,
    removeOption,
    createPost,
    closeModal,
    onSubmit,
  } = useMultipleChoiceForm()

  return (
    <Form layout="vertical" onFinish={handleSubmit(onSubmit)} className="my-6">
      <Form.Item
        label="Question"
        name="question"
        validateStatus={errors.question ? "error" : undefined}
        help={errors.question?.message}
      >
        <Controller
          name="question"
          control={control}
          render={({ field }) => (
            <Input {...field} placeholder="Enter your question here" />
          )}
        />
      </Form.Item>

      {fields.map((field, index) => (
        <Form.Item
          key={field.id}
          label={`Option ${index + 1}`}
          validateStatus={errors.options?.[index] ? "error" : undefined}
          help={errors.options?.[index]?.text?.message}
        >
          <div className="flex items-center gap-4">
            <Controller
              name={`options.${index}.text` as const}
              control={control}
              defaultValue={field.text}
              render={({ field }) => (
                <Input
                  className="flex-grow"
                  {...field}
                  placeholder={`Option ${index + 1}`}
                />
              )}
            />

            <Controller
              name="correctOption"
              control={control}
              render={({ field }) => (
                <Radio.Group {...field}>
                  <Tooltip title="Correct Answer">
                    <Radio value={index + 1} />
                  </Tooltip>
                </Radio.Group>
              )}
            />

            {index >= 2 && (
              <Button
                type="dashed"
                className="!rounded-lg"
                danger
                onClick={() => removeOption(index)}
              >
                <DeleteOutlined />
              </Button>
            )}
          </div>
        </Form.Item>
      ))}

      {fields.length < 6 && (
        <Form.Item className="flex justify-center w-full my-4">
          <button
            type="button"
            className="p-4 mx-auto text-white rounded-full cursor-pointer bg-primary"
            onClick={() =>
              append({
                id: fields.length + 1,
                text: "",
              })
            }
          >
            <PlusOutlined className="text-xl" />
          </button>
        </Form.Item>
      )}

      <div className="flex items-center justify-between w-full">
        <NewPostIcons />
        <div className="flex items-center gap-4">
          <Button onClick={closeModal}>Cancel</Button>
          <Button
            className="text-white bg-primary"
            loading={createPost.isLoading}
            icon={<SendOutlined />}
            disabled={createPost.isLoading || !isDirty}
            onClick={handleSubmit(onSubmit)}
          >
            Post
          </Button>
        </div>
      </div>
    </Form>
  )
}

export default MultipleChoice
