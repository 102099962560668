import React from "react"
import { avatarImgs } from "../../../../assets/avatar_images"
import { Divider } from "antd"
import {
  FieldTimeOutlined,
  FundOutlined,
  CopyOutlined,
} from "@ant-design/icons"
import { MentorMenteeSession } from "../../mentor/profile/types/response"
import dayjs from "dayjs"
import { GoogleMeetLogo } from "../../../../assets/images"
import { openInNewTab } from "../../../../utils/util_funcs"

const ScheduleCard = ({ schedule }: { schedule?: MentorMenteeSession }) => {
  // const cancelBookingMutation = useMutation(
  //   ({ bookingId, data }: { bookingId: string; data: { reason: string } }) =>
  //     cancelBooking(bookingId, data),
  // )
  return (
    <div className="flex ring-1 ring-grey bg-white rounded-[10px] p-4 gap-3 items-center justify-between">
      <div className="flex gap-3 items-center ">
        <img
          // @ts-ignore
          src={schedule?.mentee_data?.avatar_url ?? avatarImgs[0]}
          className="h-[86px] object-contain rounded-full"
        />
        <div className="flex flex-col">
          <div className="flex items-center gap-[10px]">
            <div className="text-base font-semibold">
              <div className="flex items-center gap-x-2">
                <p className="m-0">
                  {dayjs(schedule?.datetime_string).format(
                    "dddd, MMMM D, YYYY, h:mm A",
                  )}
                </p>
                {/* <p className="m-0">
                  ({dayjs(schedule?.datetime_string).fromNow()})
                </p> */}
              </div>
            </div>
            <Divider type="vertical" className="!border-darkGrey !mx-0" />
            <FieldTimeOutlined />
            <div className="">
              {dayjs(schedule?.datetime_string).format("h:mm A")} -{" "}
              {dayjs(schedule?.datetime_string)
                .add(30, "minutes")
                .format("h:mm A")}
            </div>
          </div>
          <div className="flex items-center gap-[10px]">
            <div className="text-xs">{schedule?.mentee_data?.name}</div>
            <Divider type="vertical" className="!border-darkGrey !mx-0" />
            <div className="flex items-center gap-2">
              <div className="flex items-center gap-[10px]">
                <FundOutlined />
                <div className="flex items-center gap-2">
                  <div className="text-xs">Career Growth</div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-[10px] mt-2">
            <div
              onClick={() => openInNewTab(schedule?.meeting_url!)}
              className="cursor-pointer flex items-center justify-between "
            >
              <img
                src={GoogleMeetLogo}
                alt="google-meet-logo"
                className=""
                width={110}
              />
            </div>
            <Divider type="vertical" className="!border-darkGrey !mx-0" />
            <div className="">
              Link Room: {schedule?.meeting_url}{" "}
              <CopyOutlined
                onClick={() =>
                  navigator.clipboard.writeText(schedule?.meeting_url ?? "")
                }
                className="cursor-pointer hover:scale-105"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex items-center justify-end gap-2">
        <Button danger type="primary">
          Cancel
        </Button>
      </div> */}
    </div>
  )
}

export default ScheduleCard
