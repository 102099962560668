/* eslint-disable */

import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import instance, { instanceV2 } from "../../../../config/config"
import { message } from "antd"
import { AxiosResponse } from "axios"
import { PaginatedResponse } from "../../../../common/types/ApiResponse"
import { debounce } from "../../../../utils/debounce"
import dayjs from "dayjs"
import { Education, OtherInstitution } from "./useEducation"
import { errorMessage } from "../../../../common/utils/utils"

export const useEducationUpdate = (
  dataEdu: Education,
  index: number,
  callback: (index: number, data: Education) => void,
) => {
  const {
    handleSubmit,
    control,
    setValue: setValueForm,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      institution_name: dataEdu?.institution?.name,
      field_of_study: dataEdu.field_of_study,
      online: dataEdu.online,
      degree: dataEdu.degree,
      start_at: dataEdu.start_at ? dayjs(dataEdu.start_at) : null,
      end_at: dataEdu.end_at ? dayjs(dataEdu.end_at) : null,
    },
  })

  const [dataTemp, setDataTemp] = useState<OtherInstitution | null>(null)
  const queryClient = useQueryClient()

  const {
    imageUrl,
    handleImageChange,
    handleRemoveImage,
    loadingRemove,
    loadingUpload,
  } = useImageOtherInstitution(dataTemp)

  const {
    handleSearch,
    data,
    value,
    isOpen,
    setIsOpen,
    addNewInstitution,
    setValue,
    isLoading,
  } = useOtherInstitution()

  const handleAddInstitution = (val: string) => {
    addNewInstitution(val).then((res) => {
      if (res) {
        setDataTemp(res)
        setIsOpen(false)
        // setValue("");
        setValueForm("institution_name", res.name)
      }
    })
  }
  const handleSelectInstitution = (val: OtherInstitution) => {
    setDataTemp(val)
    setIsOpen(false)
    setValue("")
    setValueForm("institution_name", val.name)
  }

  const handleSearchInstitution = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setValueForm("institution_name", value)
    handleSearch(e)
    setIsOpen(true)
  }

  const { mutateAsync: edu, isLoading: loadingUpdate } = useMutation({
    mutationFn: async (val) => {
      try {
        const res = await instanceV2.post(`/accounts/educations`, val)
        return res
      } catch (error) {
        throw error
      }
    },
    mutationKey: "account-education",
  })

  const { mutateAsync: eduUpdate, isLoading: loadingUpdateEdu } = useMutation({
    mutationFn: async (val: any) => {
      try {
        const res = await instanceV2.patch(
          `/accounts/educations/id/${val.id}`,
          val,
        )
        return res
      } catch (error) {
        throw error
      }
    },
    mutationKey: "account-educations-update",
  })

  const onSubmit = async (val: any) => {
    message.open({
      key: "update-education",
      content: "updating...",
      type: "loading",
    })
    const form = {
      ...val,
      id: dataEdu?.id,
      institution_id: dataTemp?.id,
      start_at: dayjs(val.start_at).format(),
      end_at: !val.end_at ? null : dayjs(val.end_at).format(),
    }
    try {
      if (!dataTemp) {
        const responseInstitution = await addNewInstitution(
          val.institution_name,
        )
        form.institution_id = responseInstitution?.id
      }
      let response: AxiosResponse<any, any>
      if (form?.id) {
        response = await eduUpdate(form)
      } else {
        response = await edu(form)
      }
      if (response) {
        const finalDataJob: Education = {
          id: form?.id || response.data?.data,
          institution: {
            id: dataTemp?.id || form.institution_id,
            name: dataTemp?.name || form.institution_name,
            image: imageUrl,
          },
          field_of_study: form.field_of_study,
          degree: form.degree,
          online: form.online,
          start_at: form.start_at,
          end_at: form.end_at,
          isEdit: false,
        }
        queryClient.invalidateQueries(["account-cv"]).then(() => {
          callback(index, finalDataJob)
          message.destroy()
        })
      }
    } catch (error: any) {
      message.open({
        key: "update-education",
        content: error?.response?.data?.data || "Internal server error",
        type: "error",
      })
    }
  }
  useEffect(() => {
    if (dataEdu) {
      setDataTemp(dataEdu.institution)
    }
  }, [dataEdu])
  return {
    control,
    errors,
    handleSubmit,
    watch,
    onSubmit,
    loadingUpdateEdu: loadingUpdateEdu || loadingUpdate,
    dataTemp,
    image: {
      imageUrl,
      handleImageChange,
      handleRemoveImage,
      loadingRemove,
      loadingUpload,
    },
    otherInstitution: {
      handleSearch: handleSearchInstitution,
      data,
      value,
      isOpen,
      setIsOpen,
      handleAddInstitution,
      handleSelectInstitution,
      isLoading,
    },
  }
}

const useOtherInstitution = () => {
  const [value, setValue] = useState<string>("")
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [dataComp, setDataComp] = useState<OtherInstitution[] | undefined>(
    undefined,
  )

  const { mutate, data, isLoading } = useMutation({
    mutationKey: "other-institutions",
    mutationFn: (params: any) => {
      return instance.get<AxiosResponse<PaginatedResponse<OtherInstitution>>>(
        `/commons/other-institutions`,
        { params },
      )
    },
  })

  const handleSearch = debounce((val: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = val.target
    setValue(value)
    mutate(
      { name: value, page: 1, limit: 5 },
      {
        onError: (err: any) => {
          errorMessage(err?.response?.data?.data || "something wrong", err)
        },
      },
    )
  }, 500)

  const { mutateAsync: addNew } = useMutation({
    mutationKey: "other-institutions",
    mutationFn: async (val: string) => {
      try {
        const res = await instance.post<AxiosResponse<OtherInstitution>>(
          `/commons/other-institutions`,
          { name: val },
        )
        return res.data.data
      } catch (error) {
        throw error
      }
    },
  })
  const addNewInstitution = async (val: string) => {
    message.open({
      key: "create-institutions",
      content: "updating...",
      type: "loading",
    })
    try {
      const response = await addNew(val)
      if (response) {
        message.destroy()
      }
      return response
    } catch (error: any) {
      message.open({
        key: "create-institutions",
        content: error?.response?.data?.data || "Internal server error",
        type: "error",
      })
    }
  }

  useEffect(() => {
    if (!isLoading) {
      setDataComp(data?.data?.data?.list)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return {
    data: dataComp,
    handleSearch,
    value,
    setValue,
    isOpen,
    setIsOpen,
    addNewInstitution,
    isLoading,
  }
}

const useImageOtherInstitution = (data: OtherInstitution | null) => {
  const [imageUrl, setImageUrl] = useState<string>("")

  const { mutate: uploadImage, isLoading: loadingUpload } = useMutation({
    mutationFn: (val: FormData) => {
      return instance.patch(`/commons/other-institutions/id/${data?.id}`, val)
    },
    mutationKey: "updload-image-other-institutions",
  })

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event
    if (target.files && target.files.length > 0) {
      const file = target.files[0]
      const imageUrl = URL.createObjectURL(file)
      setImageUrl(imageUrl)
      message.open({
        key: "update",
        content: "uploading...",
        type: "loading",
      })
      const handleUpload = () => {
        const formData = new FormData()
        formData.append("file", file)
        uploadImage(formData, {
          onSuccess: (res) => {
            message.destroy()
          },
          onError: (err: any) => {
            message.open({
              key: "update",
              content: err?.response?.data?.data || "something wrong",
              type: "error",
            })
          },
        })
      }
      handleUpload()
    }
  }
  const { mutate: removeImage, isLoading: loadingRemove } = useMutation({
    mutationFn: (id: string) => {
      return instance.delete(`/commons/other-institutions/id/${id}`)
    },
    mutationKey: "delete-image",
  })

  const handleRemoveImage = () => {
    message.open({ key: "remove", content: "removing...", type: "loading" })
    if (data) {
      removeImage(data.id, {
        onSuccess: (res) => {
          setImageUrl("")
          message.destroy()
        },
        onError: (err: any) => {
          message.open({
            key: "remove",
            content: err?.response?.data?.data || "something wrong",
            type: "error",
          })
        },
      })
    }
  }
  useEffect(() => {
    if (data) {
      setImageUrl(data?.image || "")
    }
  }, [data])

  return {
    imageUrl,
    handleImageChange,
    handleRemoveImage,
    loadingRemove,
    loadingUpload,
  }
}
