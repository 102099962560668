import React from "react"
import { Image } from "antd"
import { memo } from "react"

interface BadgeCardOrbitPopoverProps {
  name: string
  required_orbit_badges?: number
  image_url: string
  description?: string
  is_earned: boolean
}

const BadgeCardOrbitPopover: React.FC<BadgeCardOrbitPopoverProps> = memo(
  ({
    name,
    required_orbit_badges,
    image_url,
    description,
    is_earned,
  }: BadgeCardOrbitPopoverProps) => {
    return (
      <div className="flex flex-col min-w-[169px] h-fit items-center justify-center p-3 mb-auto">
        <div className="font-semibold  text-backdrop capitalize">{name}</div>
        {required_orbit_badges && (
          <div className="font-semibold mb-2 text-backdrop capitalize">
            x {required_orbit_badges}
          </div>
        )}
        <Image
          preview={false}
          src={image_url}
          height={91}
          className={` ${
            !is_earned
              ? "inset-0 bg-opacity-20 opacity-20 object-contain"
              : "object-contain"
          }`}
        />
        {description && (
          <div className="font-thin text-backdrop mt-3 text-center">
            {description}
          </div>
        )}
        {/* <div className='font-semibold text-backdrop mt-3 self-start'>
          progress:{" "}
          <div className='font-semibold text-sm inline-flex text-backdrop'>
            {data?.progress?.current}/{data?.progress?.target}
          </div>
        </div> */}
      </div>
    )
  },
)

export default BadgeCardOrbitPopover
