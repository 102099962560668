import { Select, Spin } from "antd"
import React, { useEffect, useState } from "react"
import { useInfiniteQuery } from "react-query"
import { Item } from "../../../../skillGalaxy/types/common"
import { useSelectedLocationItemIdStore } from "../stores/storeSelectedItem"

interface InfiniteLocationSelectProps {
  useDataHook: (searchParam: string) => ReturnType<typeof useInfiniteQuery>
  onSelect?: (value: string, option: Item | undefined | null) => void
  selectedValue?: Item
  emptyDescription?: string
  className?: string
  placeholder?: string
  style?: React.CSSProperties
  notFoundContent?: React.ReactNode
  fieldNames?: { label: string; value: string }
  onClearValue?: null | undefined
  disabled?: boolean
}

const { Option } = Select

export const InfiniteLocationSelect: React.FC<InfiniteLocationSelectProps> = ({
  placeholder,
  useDataHook,
}) => {
  const setSelectedLocationItemId = useSelectedLocationItemIdStore(
    (state) => state.setSelectedLocationItemId,
  )
  const selectedLocationITemId = useSelectedLocationItemIdStore(
    (state) => state.selectedLocationItemId,
  )
  const [searchParam, setSearchParam] = useState<string>("")
  const [filteredOptions, setFilteredOptions] = useState<Item[]>([])

  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    useDataHook(searchParam)

  useEffect(() => {
    if (data?.pages) {
      setFilteredOptions(
        () => data?.pages?.flatMap((page: any) => page.data.data.list ?? []),
      )
    }
  }, [data?.pages])

  const handleChange = (selectedItemId: string) => {
    const country = filteredOptions.find(
      (option) => option.id === selectedItemId,
    )
    if (country) {
      setSelectedLocationItemId(country.name)
    }
  }

  const handleSearch = (value: string) => {
    setSearchParam(value)
  }

  return (
    <Select
      showSearch
      placeholder={placeholder}
      onChange={handleChange}
      style={{ width: "100%" }}
      value={selectedLocationITemId || placeholder}
      onSearch={handleSearch}
      filterOption={false}
      notFoundContent={isFetching ? <Spin size="small" /> : null}
      onPopupScroll={(e) => {
        const target = e.target as HTMLDivElement
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
          fetchNextPage()
        }
      }}
    >
      {filteredOptions.map((country) => (
        <Option key={country.id} value={country.id}>
          {country.name}
        </Option>
      ))}
      {isFetchingNextPage && (
        <Option key="loading" disabled>
          <Spin size="small" />
        </Option>
      )}
      {!hasNextPage && (
        <Option key="end" disabled>
          End of List
        </Option>
      )}
    </Select>
  )
}
