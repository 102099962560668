import React from "react"
import { Article } from "../../../modules/community/expert/types/response"
import { LikeTwoTone, LikeOutlined } from "@ant-design/icons"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"

const ArticleHeader = ({
  article,
  category,
  observ,
}: {
  article: Article
  category: string | undefined
  observ: any
}) => {
  const navigate = useNavigate()
  return (
    <div
      ref={observ}
      className="rounded-[10px] p-3 bg-white shadow flex gap-6 cursor-pointer"
      onClick={() => navigate(`/expert/my/article/${article?.slug}`)}
    >
      <img
        className="w-[200px] object-contain rounded"
        src={article?.img ? article?.img : "https://placehold.co/400"}
      />
      <div className="flex flex-col justify-between">
        {category && (
          <div className="uppercase text-brown tracking-[34%] ">{category}</div>
        )}
        {article?.green_skills && article?.green_skills?.length > 0 ? (
          <div className="flex flex-wrap gap-2">
            {article?.green_skills?.map((skill, index) => (
              <div
                key={index}
                className="rounded-full ring-1 text-xs ring-backdrop bg-[#F5F5F5] py-[2px] px-[7px]"
              >
                {skill?.name}
              </div>
            ))}
          </div>
        ) : (
          <div>No green skills found for this article</div>
        )}
        <div className="text-2xl font-semibold line-clamp-1">
          {article?.title}
        </div>
        <div className="flex gap-2 items-center">
          <div
            className="flex items-center gap-2 bg-[#FFF2E8] py-1 px-4 rounded-md"
            style={{ border: "1px solid lightgray" }}
          >
            <div>
              {article?.likes ? (
                <LikeTwoTone twoToneColor={"green"} />
              ) : (
                <LikeOutlined />
              )}
            </div>
            <p className="m-0">{article?.likes}</p>
          </div>
          <div className="flex items-center gap-2 text-[#919191]">
            <p>{dayjs(article?.updated_at).format("D MMMM YYYY")}</p>
            {/* <Divider type="vertical" /> */}
            <span className="text-xl">•</span>
            <p>{0} read</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArticleHeader
