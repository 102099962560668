import { Skeleton } from "antd"
import React from "react"

interface CardLoadingPublicArticleProps {
  index: number
}

const CardLoadingPublicArticle: React.FC<
  CardLoadingPublicArticleProps
> = () => {
  return (
    <div className="bg-white space-y-4 rounded-xl shadow-custom-sm py-5 px-4 hover:shadow-custom cursor-pointer">
      <div className="flex gap-3">
        <div className="w-[220px] h-[200px]  rounded-xl flex items-center">
          <Skeleton.Image active className="!w-[100%] !h-[100%] !rounded-xl" />
        </div>

        <div className="space-y-2 w-full">
          <div className="flex items-center justify-between  text-xs ">
            <div className="grid grid-cols-12 items-center gap-2 flex-wrap w-full">
              {Array.from({ length: 3 }, (_: any, index: number) => (
                <Skeleton.Button
                  key={index}
                  active={true}
                  size={"small"}
                  shape={"default"}
                  className="col-span-1"
                  block={true}
                />
              ))}
            </div>
            <div className="text-right">
              <div
                className="flex items-center gap-2 bg-[#FFF2E8] py-1 px-4 rounded-md"
                style={{ border: "1px solid lightgray" }}
              >
                <Skeleton.Button
                  active={true}
                  size={"small"}
                  shape={"default"}
                  block={true}
                />
              </div>
            </div>
          </div>

          <div>
            <Skeleton.Button
              active={true}
              size={"small"}
              shape={"default"}
              block={true}
            />
          </div>

          <div className="flex items-center gap-3">
            <Skeleton avatar active paragraph={{ rows: 1 }} />
          </div>

          <div className="grid grid-cols-12 items-center gap-2 flex-wrap">
            {Array.from({ length: 3 }, (_: any, index: number) => (
              <Skeleton.Button
                key={index}
                active={true}
                size={"small"}
                shape={"default"}
                className="col-span-2"
                block={true}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardLoadingPublicArticle
