import React from "react"
import { Header } from "../components/cv-view/Header"
import { Layout, Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { Content } from "../components/cv-view/Content"
import { useProfile } from "../hooks/cv-builder/useProfile"

const CvView = () => {
  const { account, loadingAccount } = useProfile()
  return (
    <Layout className="h-screen">
      <Header data={account} />
      <Layout className=" mt-[5rem]">
        {loadingAccount ? (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          </div>
        ) : (
          <Layout className={`h-full relative px-3 `}>
            <Layout.Content className="">
              <Content data={account} />
            </Layout.Content>
          </Layout>
        )}
      </Layout>
    </Layout>
  )
}

export default CvView
