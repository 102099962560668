import React from "react"
import { FlyingRocketMan } from "../../../../assets/images"
import { Button } from "antd"
import { profileDescriptions } from "../constants/data"
import { Role } from "../stores/useRoleStore"

interface ProfileInReviewProps {
  role: Role
  onClick: () => void
}

const ProfileInReview: React.FC<ProfileInReviewProps> = ({ role, onClick }) => {
  return (
    <div className="text-center justify-center items-center flex flex-col gap-3">
      <img
        className="w-[122px] object-contain"
        src={FlyingRocketMan}
        alt={"rocket"}
      />
      <h4 className="text-xl font-semibold tracking-[-0.42px] capitalize">
        {role} Application
      </h4>
      <div className="">{profileDescriptions[role].rolePendingDescpLonger}</div>
      <Button className="rounded-md" block onClick={onClick} type="primary">
        Back to My Profile
      </Button>
    </div>
  )
}

export default ProfileInReview
