import { Image, Skeleton } from "antd"
import React from "react"
import { SocialMediaLinks } from "../../../../jobGalaxy/components/SocialMediaLink"
import { Moon, PinMapGray } from "../../../../../assets/images"
import { CheckCircleFilled, EditFilled } from "@ant-design/icons"
import DetailByRole from "../../../public-expert/components/DetailByRole"
import { MentorArea, MentorDomain, MentorResponse } from "../types/response"
import { useProfileView } from "../../../../../stores"
import FormBasicProfile from "./FormBasicProfile"
import ListAvailability from "./ListAvailability"

interface MentorProfileContentProps {
  detail?: MentorResponse | undefined
  loadingMentor?: boolean

  areaData?: MentorArea[] | undefined
  loadingArea?: boolean

  domainData?: MentorDomain[] | undefined
  loadingDomain?: boolean
  isMyProfile?: boolean
  loadingAccount?: boolean
  handler?: any
}

const MentorProfileContent: React.FC<MentorProfileContentProps> = ({
  detail,
  loadingMentor,
  areaData,
  loadingArea,
  domainData,
  loadingDomain,
  handler,
  isMyProfile,
}) => {
  const reFormatSosmed = (sosmed: any) => {
    const data = {
      linkedin_url: sosmed?.linkedin,
    }

    return data
  }

  const mentorChecklist = [
    {
      title: "Complete your Mentor Profile",
      status: detail?.is_profile_complete,
    },
    {
      title: "Add Mentorship Areas",
      status: detail?.is_mentor_area_exist,
    },
    {
      title: "Add Domains of Expertise ",
      status: detail?.is_mentor_domain_exist,
    },
    {
      title: "Set your availability",
      status: detail?.is_set_availability,
    },
  ]

  const { isOpen, showEdit, closeEdit } = useProfileView()

  return (
    <div>
      {isOpen ? (
        <div className="bg-white px-4 py-6 rounded-xl shadow-custom-sm relative ">
          <FormBasicProfile handler={handler} closeEdit={closeEdit} />
        </div>
      ) : (
        <div className="bg-white px-4 py-6 rounded-xl shadow-custom-sm relative grid grid-cols-3 gap-5">
          {loadingMentor ? (
            <div className="col-span-2 bg-white-custom shadow-custom px-4 py-6 rounded-xl">
              <Skeleton active />{" "}
            </div>
          ) : (
            <div className="col-span-2 bg-white-custom shadow-custom px-4 py-6 rounded-xl relative">
              {isMyProfile && (
                <EditFilled
                  className="absolute right-7 hover:scale-110 transition-all hover:text-primary cursor-pointer"
                  onClick={showEdit}
                />
              )}
              <div className="flex gap-5">
                {detail?.profile_picture ? (
                  <Image
                    className="object-cover rounded-full"
                    src={detail?.profile_picture}
                    alt="profile"
                    width={100}
                    height={100}
                    preview={false}
                  />
                ) : (
                  <Image
                    className="object-cover rounded-full"
                    src={detail?.avatar}
                    alt="profile"
                    width={100}
                    height={100}
                    preview={false}
                  />
                )}

                <div className="space-y-3">
                  <h3 className="capitalize">
                    {detail?.first_name} {detail?.last_name}
                  </h3>

                  <div className="my-1">
                    <PinMapGray />
                    <span className="ml-2">{detail?.country}</span>
                  </div>
                  <div className="my-1 flex gap-5 items-center">
                    <small className="px-2 bg-primary text-white z-40 rounded">
                      Mentor
                    </small>
                    <div className="">
                      {SocialMediaLinks(reFormatSosmed(detail))}
                    </div>
                  </div>
                </div>
              </div>

              {detail?.about_me && detail?.about_me !== "<p><br></p>" && (
                <div
                  className="mt-2"
                  dangerouslySetInnerHTML={{
                    __html: detail?.about_me,
                  }}
                ></div>
              )}
            </div>
          )}

          {isMyProfile ? (
            <div className="cols-span-1 rounded-lg shadow-custom py-7 px-5 relative w-full h-56 bg-secondaryBlue">
              {loadingMentor ? (
                <div className="">
                  <Skeleton active />{" "}
                </div>
              ) : (
                <div>
                  <h3>Mentor Checklist</h3>

                  <div className="space-y-2">
                    {mentorChecklist.map((item: any, index: number) => {
                      return (
                        <div className="flex items-center gap-2" key={index}>
                          <CheckCircleFilled
                            className={`${
                              item?.status ? "text-primary" : "text-darkGrey"
                            }`}
                          />
                          <span>{item.title}</span>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="cols-span-1 rounded-lg shadow-custom py-2 px-5 relative w-full h-56 bg-white-custom overflow-y-auto">
              <h3 className="sticky -top-2 py-2 bg-white-custom w-full z-50">
                Calendar
              </h3>

              <ListAvailability />

              {/* <div className="text-center">
                <Image
                  className="object-cover rounded-full"
                  src={Moon}
                  alt="profile"
                  width={100}
                  height={100}
                  preview={false}
                />
                <p>Mentor is currently not available </p>
              </div> */}
            </div>
          )}
        </div>
      )}

      <DetailByRole
        showMentor={true}
        data={detail}
        areaData={areaData}
        loadingArea={loadingArea}
        domainData={domainData}
        loadingDomain={loadingDomain}
        handler={handler}
        isMyProfile={isMyProfile}
      />
    </div>
  )
}

export default MentorProfileContent
