import {
  DollarCircleOutlined,
  FundOutlined,
  InteractionOutlined,
} from "@ant-design/icons"
import { useMsal } from "@azure/msal-react"
import { Skeleton, Tooltip, Typography } from "antd"
import { memo } from "react"
import { useNavigate } from "react-router-dom"
import { BasicContainer } from "../../../common/components/BasicBoxContainers/BasicContainer.tsx"
import { formatNumber } from "../../../utils/formatNumber.ts"
import useFavoriteSkills from "../../missionControl/hooks/useFavoriteSkills.tsx"
import { Item } from "../types/common.ts"
import { TagFavoriteSkill } from "./TagFavoriteSkill"

type Skill = Item & {
  ranking: number
  transferability_score: number
  currency: {
    code: string
    created_at: string
    exchange: number
    id: string
    is_active: boolean
    is_deletable: boolean
    is_tax: boolean
    name: string
    tax: number
    updated_at: string
    logo?: string
  }
  image: string
  price_per_level?: number
  pre_discount_price: number
  price?: number
  is_favorite: boolean
  availability: "coming soon" | "start today"
}

interface SkillCardProps {
  className?: string
  observ?: (node: Element | null) => void
  group_name: string
  onFavoriteClick?: () => void
  skill: Skill
}

function SkillCard({
  className,
  observ,
  group_name,
  skill,
  onFavoriteClick,
}: SkillCardProps) {
  const { accounts } = useMsal()
  const isLoggedIn = accounts?.length > 0
  const {
    isRemovingSkillFromFavorite,
    removeSkillFromFavorite,
    isAddingSkillToFavorite,
    addSkillToFavorite,
  } = useFavoriteSkills(isLoggedIn)

  const navigate = useNavigate()
  const actualPrice = skill?.price || skill?.price_per_level
  return (
    <BasicContainer
      ref={observ}
      // onClick={() => navigate(`/skill/green-skills/${id}`)}
      key={skill?.id}
      className={`!bg-white cursor-pointer hover:shadow-lg  transition-transform duration-300 flex flex-col justify-between  ${className}`}
    >
      <div className="">
        <div className="z-10 flex items-center justify-between ">
          <TagFavoriteSkill
            isFav={skill?.is_favorite}
            skill_id={skill?.id}
            isLoading={
              onFavoriteClick
                ? false
                : isRemovingSkillFromFavorite || isAddingSkillToFavorite
            }
            onClick={() => {
              onFavoriteClick?.()
              skill?.is_favorite
                ? removeSkillFromFavorite(skill?.id)
                : addSkillToFavorite(skill?.id)
            }}
          />
          <div className="text-sm text-backdrop font-light uppercase mb-1">
            {skill?.availability}
          </div>
        </div>
        <img
          alt="example"
          onClick={() => navigate(`/skill/green-skills/${skill?.id}`)}
          src={skill?.image}
          className="object-contain h-[167px] w-full mt-5"
        />

        <div className="text-[10px] leading-[18px]  tracking-widest text-brown font-light uppercase">
          {group_name ?? "N/A"}
        </div>
      </div>
      <div
        className="text-backdrop font-bold text-lg mt-[12px]"
        onClick={() => navigate(`/skill/green-skills/${skill?.id}`)}
      >
        <Typography.Paragraph
          className="text-backdrop font-bold text-lg -mx-2"
          ellipsis={{ rows: 3 }}
        >
          {skill?.name}
        </Typography.Paragraph>
      </div>
      <div className="flex justify-between w-full mt-auto -mx-2">
        <div className="flex gap-x-3 items-center">
          <Tooltip title="Price Per Level">
            {skill?.currency?.logo ? (
              <img
                className=" w-4 h-4 rounded-full object-cover"
                style={{ border: "0.5px solid #36505E" }}
                src={skill?.currency?.logo}
              />
            ) : (
              <DollarCircleOutlined className="text-[1.1rem]" />
            )}
          </Tooltip>
          <div className="flex gap-2 items-center">
            <span className=" uppercase">{skill?.currency?.code ?? null} </span>
            <div className=" flex flex-col">
              {skill?.pre_discount_price > 0 && (
                <span className="text-danger line-through text-xs">
                  {formatNumber(
                    skill.pre_discount_price * skill?.currency?.exchange,
                  )}
                </span>
              )}
              <span
                className={`text-base font-semibold ${
                  skill?.pre_discount_price > 0 ? " text-base" : ""
                }`}
              >
                {actualPrice
                  ? formatNumber(actualPrice * skill?.currency?.exchange)
                  : "n/a"}
              </span>
            </div>
          </div>
        </div>
        <div className="flex gap-x-3 items-center">
          <Tooltip title="Transferability Score">
            <InteractionOutlined className="text-xl" />
          </Tooltip>
          <div className="text-sm">{skill?.transferability_score}</div>
        </div>
        <div className="flex gap-x-3 items-center">
          <Tooltip title="Skill Ranking">
            <FundOutlined className="text-xl" />
          </Tooltip>
          <div className="text-sm">{Math.round(skill?.ranking ?? 0)}%</div>
        </div>
      </div>
    </BasicContainer>
  )
}

export const SkillCardLoading = () => {
  return (
    <BasicContainer
      className={`!bg-white cursor-pointer hover:shadow-lg col-span-12 md:col-span-6 lg:col-span-4 transition-transform duration-300 flex flex-col justify-between`}
    >
      <div className="flex justify-between items-center">
        <Skeleton.Button active />
        <Skeleton.Button active />
      </div>
      <div className="flex w-full items-center justify-center mt-5">
        <Skeleton.Image
          className="object-contain h-[167px] "
          active
          style={{ height: 167, width: 167, objectFit: "contain" }}
        />
      </div>
      <div className="mt-4">
        <Skeleton active paragraph={{ rows: 0 }} />
      </div>
      <div className="flex justify-between w-full mt-auto -mx-2">
        <div className="flex gap-x-3 items-center">
          <DollarCircleOutlined className="text-xl" />
          <Skeleton.Button active />
        </div>
        <div className="flex gap-x-3 items-center">
          <InteractionOutlined className="text-xl" />
          <Skeleton.Button active />
        </div>
        <div className="flex gap-x-3 items-center">
          <FundOutlined className="text-xl" />
          <Skeleton.Button active />
        </div>
      </div>
    </BasicContainer>
  )
}

export default memo(SkillCard)
