import React from "react"
import { Controller } from "react-hook-form"
import { Input, Button, Form, Radio } from "antd"
import { PlusOutlined, DeleteOutlined, SendOutlined } from "@ant-design/icons"
import NewPostIcons from "../NewPostIcons"
import { usePollForm } from "../../hooks/usePollForm"

const PollForm: React.FC = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    fields,
    append,
    remove,
    createPost,
    closeModal,
    onSubmit,
  } = usePollForm()

  return (
    <Form layout="vertical" onFinish={handleSubmit(onSubmit)} className="my-6">
      <Form.Item
        label="Question"
        name="poll_question"
        validateStatus={errors.poll_question ? "error" : undefined}
        help={errors.poll_question?.message}
      >
        <Controller
          name="poll_question"
          control={control}
          render={({ field }) => (
            <Input {...field} placeholder="Enter your question here" />
          )}
        />
      </Form.Item>

      <Form.Item
        label="Duration"
        validateStatus={errors.poll_duration ? "error" : ""}
        help={errors.poll_duration?.message}
      >
        <Controller
          name="poll_duration"
          control={control}
          render={({ field }) => (
            <Radio.Group {...field}>
              <Radio value="1d">1 day</Radio>
              <Radio value="3d">3 days</Radio>
              <Radio value="1w">1 Week</Radio>
              <Radio value="2w">2 Weeks</Radio>
            </Radio.Group>
          )}
        />
      </Form.Item>

      {fields.map((field, index) => (
        <Form.Item
          key={field.id}
          label={`Option ${index + 1}`}
          validateStatus={errors.poll_options?.[index] ? "error" : undefined}
          help={errors.poll_options?.[index]?.text?.message}
        >
          <div className="flex items-center gap-4">
            <Controller
              name={`poll_options.${index}.text` as const}
              control={control}
              defaultValue={field.text}
              render={({ field }) => (
                <Input
                  className="flex-grow"
                  {...field}
                  placeholder={`Option ${index + 1}`}
                />
              )}
            />
            {index >= 2 && (
              <Button type="dashed" danger onClick={() => remove(index)}>
                <DeleteOutlined /> Delete
              </Button>
            )}
          </div>
        </Form.Item>
      ))}

      {fields.length < 6 && (
        <Form.Item className="flex justify-center w-full my-4">
          <button
            type="button"
            className="p-4 mx-auto text-white rounded-full cursor-pointer bg-primary"
            onClick={() =>
              append({
                id: fields.length + 1,
                text: "",
              })
            }
          >
            <PlusOutlined className="text-xl" />
          </button>
        </Form.Item>
      )}

      <div className="flex items-center justify-between w-full">
        <NewPostIcons />
        <div className="flex items-center gap-4">
          <Button onClick={closeModal}>Cancel</Button>
          <Button
            className="text-white bg-primary"
            loading={createPost.isLoading}
            icon={<SendOutlined />}
            disabled={createPost.isLoading || !isDirty}
            onClick={handleSubmit(onSubmit)}
          >
            Post
          </Button>
        </div>
      </div>
    </Form>
  )
}

export default PollForm
