import { useForm } from "react-hook-form"

export interface ClimateSolutionDataStartup {
  name: string
  stage_id: string
  location_id: string
  website: string
  short_description: string
  long_description: string
  file: any
}

export const useFormCompany = () => {
  const {
    handleSubmit,
    control,
    watch,
    // reset,
    setValue,
    formState: { errors },
  } = useForm<ClimateSolutionDataStartup>({
    defaultValues: {
      name: "",
      stage_id: "",
      location_id: "",
      website: "",
      short_description: "",
      long_description: "",
      file: "",
    },
  })

  return {
    handleSubmit,
    control,
    watch,
    setValue,
    errors,
  }
}

export interface ClimateSolutionDataProduct {
  solutions_id: string[]
  name: string
  intended_user: string
  description: string
}
export const useFormProduct = () => {
  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ClimateSolutionDataProduct>({
    defaultValues: {
      solutions_id: [],
      name: "",
      intended_user: "",
      description: "",
    },
  })

  return {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    errors,
  }
}
