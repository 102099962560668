import React from "react"
import { useParams } from "react-router-dom"
import { PageContent } from "../../../common/layout"
import { useCertificate } from "../../jobGalaxy/hooks/useCertificate.ts"
import {
  ContentCertificateShared,
  HeaderCertificateShared,
} from "../components/CertificateSharedComponent.tsx"
import MetaDecorator from "../../../common/components/MetaDecorator.jsx"

const CertificateShared = () => {
  const { skill_id, user_id, skill_name } = useParams()

  const { levels, isLoadingLevels } = useCertificate(skill_id, user_id)
  const levelData = levels && levels?.data && levels?.data[0]

  // SEO
  const blogTitle = `${levelData?.talent?.first_name} ${levelData?.talent?.last_name} Certificate`
  const blogDescription = "This is my progress CV"
  const blogImage = `/green_skills_logo.png`

  return (
    <>
      <MetaDecorator
        imageUrl={blogImage}
        description={blogDescription}
        title={blogTitle}
        imageAlt={"cv-image"}
      />

      <PageContent
        header={
          <HeaderCertificateShared
            dataSkill={skill_name}
            schoolData={levelData}
          />
        }
        content={
          <div>
            <ContentCertificateShared
              dataSkill={skill_name}
              isLoadingLevels={isLoadingLevels}
              schoolData={levelData}
            />
          </div>
        }
      />
    </>
  )
}

export default CertificateShared
