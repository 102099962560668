import React from "react"
import { PageContent } from "../../../../common/layout"
import { HomeOutlined } from "@ant-design/icons"
import {
  MyJobRequestContentRightSection,
  MyJobRequestContentSection,
  MyJobRequestContentTopSection,
} from "../../components/jobRequest/MyJobRequestSection"
import { useMsal } from "@azure/msal-react"
import useSavedJobs from "../../hooks/useSavedJobs"
import useRecruiterRequest from "../../hooks/useRecruiterRequest"

const MyJobRequest: React.FC = () => {
  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()

  const { listJobSaved } = useSavedJobs(activeAccount?.localAccountId)

  const { listRequest, isLoadingRequest, handleResponseRequest } =
    useRecruiterRequest(activeAccount?.localAccountId)

  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      path: "/job/roles",
      title: "Jobs Galaxy",
    },
    {
      path: "/job/my",
      title: "My Jobs",
    },
    {
      path: "/job/my/request",
      title: "Recruiters Request",
    },
  ]

  return (
    <div>
      <PageContent
        header={<MyJobRequestContentTopSection breadcrumbsItems={items} />}
        content={
          <MyJobRequestContentSection
            listRequest={listRequest}
            isLoadingRequest={isLoadingRequest}
            handleResponseRequest={handleResponseRequest}
          />
        }
        contentRightSidebar={
          <MyJobRequestContentRightSection data={listJobSaved} />
        }
      />
    </div>
  )
}

export default MyJobRequest
