import { Button, Dropdown, message, Popconfirm } from "antd"
import React from "react"
import {
  EllipsisOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons"
import { IStartupProduct } from "../types"
import { deleteStartupProduct } from "../apis/founders"
import { useMutation, useQueryClient } from "react-query"
import useCommunityModalsStore from "../../store/useCommunityModalsStore"
import { showError } from "../../../../common/utils/utils"

const ClimateSolutionsProductCard = ({
  product,
  startup_id,
}: {
  product: IStartupProduct
  startup_id: string
}) => {
  const deleteMutation = useMutation(() =>
    deleteStartupProduct(startup_id, product?.id),
  )
  const addEditCompanyProductModal = useCommunityModalsStore(
    (state) => state.addEditCompanyProductModal,
  )

  const handleEdit = () => {
    addEditCompanyProductModal.open(product ?? null)
  }

  const queryClient = useQueryClient()

  const handleDelete = async () => {
    message.loading({
      key: "delete-product",
      content: "Deleting...",
      duration: 0,
    })
    await deleteMutation.mutateAsync(undefined, {
      onSuccess: () => {
        message.success({
          key: "delete-product",
          content: "Product deleted successfully",
        })
        queryClient.invalidateQueries(["startup-products"])
      },
      onError: () => {
        showError({
          message: "Something went wrong. Please try again.",
          key: "delete-product",
        })
      },
    })
  }

  return (
    <div className="space-y-4 bg-white-custom ring-1 ring-dirtyBlue px-4 py-3 rounded-xl">
      <div className="flex items-center justify-between">
        <div className="text-base font-semibold">{product?.name}</div>
        <Dropdown
          className=" capitalize"
          menu={{
            items: [
              {
                key: "1",
                label: "Edit",
                icon: <EditOutlined />,
                onClick: handleEdit,
              },
              {
                key: "2",
                label: (
                  <Popconfirm
                    title="Are you sure to delete this product?"
                    onConfirm={handleDelete}
                    okText="Delete"
                    okButtonProps={{
                      className: "!bg-danger !text-white",
                      loading: deleteMutation.isLoading,
                    }}
                    cancelText="No"
                  >
                    <div>Delete</div>
                  </Popconfirm>
                ),
                icon: <DeleteOutlined />,
                onClick: () => {},
              },
            ],
          }}
        >
          <Button icon={<EllipsisOutlined />}></Button>
        </Dropdown>
      </div>
      <div className="flex items-center gap-4">
        <div className="">Solutions:</div>
        <div className="flex flex-wrap gap-2 items-center">
          {product?.solutions?.map((solution: any, index: number) => (
            <div
              key={index}
              className="py-[2px] px-2 rounded-full text-xs text-center bg-[#F5F5F5] text-backdrop ring-1 ring-backdrop"
            >
              {solution?.name}
            </div>
          ))}
        </div>
      </div>
      <div className="flex items-center gap-4">
        <div className="">Best for:</div>
        <div className="">{product?.intended_user}</div>
      </div>
      <div
        className="mt-4"
        dangerouslySetInnerHTML={{ __html: product?.description }}
      />
    </div>
  )
}

export default ClimateSolutionsProductCard
