import React from "react"
import { Role, useRoleStore } from "../../users/stores/useRoleStore"
import useProfilesStatus from "../../users/hooks/useProfilesStatus"
import RoleNavigator from "../../users/components/RoleNavigator"
import ProfileRoleDescription from "../../users/components/ProfileRoleDescription"
import { profileDescriptions } from "../../users/constants/data"
import { useBecomeAFounder } from "../../users/hooks/useFounder"
// import ProfileAnalytics from "./ProfileAnalytics"
import { Shortcut } from "../../feed/components"
import useOnApplied from "../hooks/useOnApplied"
import GalaxyAchievements from "./GalaxyAchievements"
import CurrentTrajectory from "./CurrentTrajectory"
import SavedSkills from "./SavedSkills"
import MentorChecklist from "./MentorChecklist"

const ProfileRightSidebar = () => {
  const { role } = useRoleStore()
  const is_profile = useProfilesStatus()

  const becomeFounderMutation = useBecomeAFounder()

  const onApplied = useOnApplied()

  return (
    <div className="sticky top-10 max-h-[80vh] overflow-y-auto scrollbar-hide p-1 space-y-5">
      <RoleNavigator />
      {role !== Role.Professional && (
        <ProfileRoleDescription
          title={profileDescriptions[role].title}
          description={profileDescriptions[role].description}
          actionButtonProps={{
            children: profileDescriptions[role].buttonTitle,
            onClick: () => onApplied(),
            loading: becomeFounderMutation.isLoading,
          }}
          role={role}
          isRole={is_profile[role]?.status === "APPROVED"}
          isRolePending={is_profile[role]?.status === "PENDING"}
        />
      )}
      {role === Role.Mentor && <MentorChecklist />}
      {/* <ProfileAnalytics /> */}
      <Shortcut />
      <GalaxyAchievements />
      <CurrentTrajectory />
      <SavedSkills />
    </div>
  )
}

export default ProfileRightSidebar
