import { useQuery } from "react-query"
import { instanceV2 } from "../../../../config/config"
import { ApiResponse } from "../../../../common/types/ApiResponse"
import { useParams } from "react-router-dom"
import { BasicSoftSkill } from "../../types/common"
import { useMsal } from "@azure/msal-react"
import { errorMessage } from "../../../../common/utils/utils"

export const useJobBoardSoftSkill = () => {
  const { id } = useParams()
  const { instance: user } = useMsal()
  const activeAccount = user.getActiveAccount()

  const { data, isLoading, isFetching, isError } = useQuery<
    ApiResponse<BasicSoftSkill[]>
  >(
    ["jobboard-soft-skill", id],
    () => instanceV2.get(`job-boards/${id}/soft-skills/charts`),
    {
      onError: (err: any) =>
        errorMessage(err?.response?.data?.data || "internal server error", err),
      enabled: !!id && !!activeAccount?.localAccountId,
    },
  )
  return {
    data: data?.data?.data,
    isLoading: isLoading || isFetching,
    isError,
  }
}
