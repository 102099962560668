import { Image, Skeleton } from "antd"
import React from "react"
import { MentorResponse } from "../types/response"
import { Moon } from "../../../../../assets/images"
import ListMentorFilter from "./ListMentorFilter"
import MentorCard from "./MentorCard"

interface ListMentorContentProps {
  mentorData?: any
  queryMentor?: any
  referenceData?: any
}

const ListMentorContent: React.FC<ListMentorContentProps> = ({
  mentorData,
  queryMentor,
  referenceData,
}) => {
  return (
    <div className="space-y-5">
      <div>
        <ListMentorFilter
          queryMentor={queryMentor}
          referenceData={referenceData}
        />
      </div>

      <div
        className={`${
          !mentorData?.listLoading && !mentorData?.listData
            ? ""
            : "grid md:grid-cols-3 gap-5 mx-3 md:mx-0"
        } `}
      >
        {mentorData?.listLoading ? (
          Array.from({ length: 6 }, (_: any, index: number) => (
            <div
              key={index}
              className="py-3 px-4 bg-white-custom rounded-xl shadow-custom-sm space-y-3 hover:scale-[1.02] transition-all cursor-pointer"
            >
              <div className="flex items-center justify-center flex-col gap-y-3 w-full ">
                <Skeleton.Avatar size={120} active={true} />
                <div className="flex flex-col items-center gap-2 justify-center w-full ">
                  <Skeleton.Input block active={true} />
                  <Skeleton.Input block active={true} />
                </div>

                <div className="px-5 py-3 pt-5 bg-primary-bg w-full rounded-xl">
                  <Skeleton paragraph={{ rows: 2 }} active={true} />
                </div>
              </div>
            </div>
          ))
        ) : mentorData?.listData?.length === 0 || !mentorData?.listData ? (
          <div className="w-full mx-auto rounded-lg bg-white flex justify-center text-center  py-6 col-span-3">
            <div>
              <Image src={Moon} alt="moon image" preview={false} width={100} />
              <p className="my-4">No Data</p>
            </div>
          </div>
        ) : (
          mentorData?.listData?.map(
            (mentorItem: MentorResponse, index: number) => (
              <MentorCard key={index} mentorItem={mentorItem} />
            ),
          )
        )}
      </div>
    </div>
  )
}

export default ListMentorContent
