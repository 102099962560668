/* eslint-disable */

import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import instance, { instanceV2 } from "../../../../config/config"
import { message } from "antd"
import { AxiosResponse } from "axios"
import { PaginatedResponse } from "../../../../common/types/ApiResponse"
import { JobHistory, OtherCompany } from "./useJobHistory"
import { debounce } from "../../../../utils/debounce"
import dayjs from "dayjs"
import { errorMessage } from "../../../../common/utils/utils"

export const useJobHistoryUpdate = (
  dataJob: JobHistory,
  index: number,
  callback: (index: number, data: JobHistory) => void,
) => {
  const {
    handleSubmit,
    control,
    setValue: setValueForm,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      company_name: dataJob?.company?.name,
      employment_type_id: dataJob.employment_type.name
        ? {
            label: dataJob.employment_type.name,
            value: dataJob.employment_type.id,
          }
        : null,
      country_id: dataJob.country.name
        ? {
            label: dataJob.country.name,
            value: dataJob.country.id,
          }
        : null,
      current_role: dataJob.current_role,
      start_at: dataJob.start_at ? dayjs(dataJob.start_at) : null,
      end_at: dataJob.end_at ? dayjs(dataJob.end_at) : null,
      is_remote: dataJob.remote,
      job_title: dataJob.job_title,
      description: dataJob.description,
    },
  })

  const [dataTemp, setDataTemp] = useState<OtherCompany | null>(null)
  const queryClient = useQueryClient()

  const {
    imageUrl,
    handleImageChange,
    handleRemoveImage,
    loadingRemove,
    loadingUpload,
  } = useImageOtherCompany(dataTemp)

  const {
    handleSearch,
    data,
    value,
    isOpen,
    setIsOpen,
    addNewCompany,
    setValue,
    isLoading,
  } = useOtherCompany()
  const handleAddCompany = (val: string) => {
    addNewCompany(val).then((res) => {
      if (res) {
        setDataTemp(res)
        setIsOpen(false)
        // setValue("");
        setValueForm("company_name", res.name)
      }
    })
  }
  const handleSelectCompany = (val: OtherCompany) => {
    setDataTemp(val)
    setIsOpen(false)
    setValue("")
    setValueForm("company_name", val.name)
  }

  const handleSearchCompany = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setValueForm("company_name", value)
    handleSearch(e)
    setIsOpen(true)
  }

  const { mutateAsync: jobHis, isLoading: loadingJobHis } = useMutation({
    mutationFn: async (val) => {
      try {
        const res = await instanceV2.post(`/accounts/job-history`, val)
        return res
      } catch (error) {
        throw error
      }
    },
    mutationKey: "account-job-history",
  })

  const { mutateAsync: jobHisUpdate, isLoading: loadingUpdateJobHis } =
    useMutation({
      mutationFn: async (val: any) => {
        try {
          const res = await instanceV2.patch(
            `/accounts/job-history/id/${val.id}`,
            val,
          )
          return res
        } catch (error) {
          throw error
        }
      },
      mutationKey: "account-job-history-update",
    })

  const onSubmit = async (val: any) => {
    message.open({
      key: "update-jh",
      content: "updating...",
      type: "loading",
    })
    const form = {
      ...val,
      id: dataJob?.id,
      company_id: dataTemp?.id,
      start_at: dayjs(val.start_at).format(),
      end_at: val.current_role ? null : dayjs(val.end_at).format(),
      country_id: val.country_id.value,
      employment_type_id: val.employment_type_id.value,
      remote: val.is_remote,
    }

    try {
      if (!dataTemp) {
        const responseCompany = await addNewCompany(val.company_name)
        form.company_id = responseCompany?.id
      }
      let response: AxiosResponse<any, any>
      if (form?.id) {
        response = await jobHisUpdate(form)
      } else {
        response = await jobHis(form)
      }
      if (response) {
        const finalDataJob: JobHistory = {
          id: form?.id || response.data?.data,
          company: {
            id: dataTemp?.id || form.company_id,
            name: dataTemp?.name || form.company_name,
            image: imageUrl,
          },
          employment_type: {
            id: val.employment_type_id.value,
            name: val.employment_type_id.label,
          },
          country: {
            name: val.country_id.label,
            id: val.country_id.value,
          },
          current_role: form.current_role,
          start_at: form.start_at,
          end_at: form.end_at,
          remote: form.is_remote,
          job_title: form.job_title,
          description: form.description,
          isEdit: false,
        }
        queryClient.invalidateQueries(["account-cv"]).then(() => {
          callback(index, finalDataJob)
          message.destroy()
        })
      }
    } catch (error: any) {
      message.open({
        key: "update-jh",
        content: error?.response?.data?.data || "Internal server error",
        type: "error",
      })
    }
  }
  useEffect(() => {
    if (dataJob) {
      setDataTemp(dataJob.company)
    }
  }, [dataJob])
  return {
    control,
    errors,
    handleSubmit,
    watch,
    onSubmit,
    loadingUpdateJobHis: loadingUpdateJobHis || loadingJobHis,
    dataTemp,
    image: {
      imageUrl,
      handleImageChange,
      handleRemoveImage,
      loadingRemove,
      loadingUpload,
    },
    otherCompany: {
      handleSearch: handleSearchCompany,
      data,
      value,
      isOpen,
      setIsOpen,
      handleAddCompany,
      handleSelectCompany,
      isLoading,
    },
  }
}

const useOtherCompany = () => {
  const [value, setValue] = useState<string>("")
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [dataComp, setDataComp] = useState<OtherCompany[] | undefined>(
    undefined,
  )

  const { mutate, data, isLoading } = useMutation({
    mutationKey: "other-company",
    mutationFn: (params: any) => {
      return instance.get<AxiosResponse<PaginatedResponse<OtherCompany>>>(
        `/commons/other-companies`,
        { params },
      )
    },
  })

  const handleSearch = debounce((val: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = val.target
    setValue(value)
    mutate(
      { name: value, page: 1, limit: 5 },
      {
        onError: (err: any) => {
          errorMessage(err?.response?.data?.data || "something wrong", err)
        },
      },
    )
  }, 500)

  const { mutateAsync: addNew } = useMutation({
    mutationKey: "other-company",
    mutationFn: async (val: string) => {
      try {
        const res = await instance.post<AxiosResponse<OtherCompany>>(
          `/commons/other-companies`,
          { name: val },
        )
        return res.data.data
      } catch (error) {
        throw error
      }
    },
  })
  const addNewCompany = async (val: string) => {
    message.open({
      key: "create-company",
      content: "updating...",
      type: "loading",
    })
    try {
      const response = await addNew(val)
      if (response) {
        message.destroy()
      }
      return response
    } catch (error: any) {
      message.open({
        key: "create-company",
        content: error?.response?.data?.data || "Internal server error",
        type: "error",
      })
    }
  }
  useEffect(() => {
    if (!isLoading) {
      setDataComp(data?.data?.data?.list)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return {
    data: dataComp,
    handleSearch,
    value,
    setValue,
    isOpen,
    setIsOpen,
    addNewCompany,
    isLoading,
  }
}

const useImageOtherCompany = (data: OtherCompany | null) => {
  const [imageUrl, setImageUrl] = useState<string>("")

  const { mutate: uploadImage, isLoading: loadingUpload } = useMutation({
    mutationFn: (val: FormData) => {
      return instance.patch(`/commons/other-companies/id/${data?.id}`, val)
    },
    mutationKey: "updload-image-other-company",
  })

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event
    if (target.files && target.files.length > 0) {
      const file = target.files[0]
      const imageUrl = URL.createObjectURL(file)
      setImageUrl(imageUrl)
      message.open({
        key: "update",
        content: "uploading...",
        type: "loading",
      })
      const handleUpload = () => {
        const formData = new FormData()
        formData.append("file", file)
        uploadImage(formData, {
          onSuccess: (res) => {
            message.destroy()
          },
          onError: (err: any) => {
            message.open({
              key: "update",
              content: err?.response?.data?.data || "something wrong",
              type: "error",
            })
          },
        })
      }
      handleUpload()
    }
  }
  const { mutate: removeImage, isLoading: loadingRemove } = useMutation({
    mutationFn: (id: string) => {
      return instance.delete(`/commons/other-companies/id/${id}`)
    },
    mutationKey: "delete-image",
  })

  const handleRemoveImage = () => {
    message.open({ key: "remove", content: "removing...", type: "loading" })
    if (data) {
      removeImage(data.id, {
        onSuccess: (res) => {
          setImageUrl("")
          message.destroy()
        },
        onError: (err: any) => {
          message.open({
            key: "remove",
            content: err?.response?.data?.data || "something wrong",
            type: "error",
          })
        },
      })
    }
  }
  useEffect(() => {
    if (data) {
      setImageUrl(data?.image || "")
    }
  }, [data])

  return {
    imageUrl,
    handleImageChange,
    handleRemoveImage,
    loadingRemove,
    loadingUpload,
  }
}
