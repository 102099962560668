import { MsalProvider } from "@azure/msal-react"
import "./App.css"
import PageLayout from "./common/layout/PageLayout"
import GeneralRoute from "./routes"
import { ConfigProvider } from "antd"
import { QueryClient, QueryClientProvider } from "react-query"
import "react-date-range/dist/styles.css" // main style file
import "react-date-range/dist/theme/default.css" // theme css file
import OtherRoute from "./routes/learnings"
import { useLocation } from "react-router-dom"
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js"
import { reactPlugin } from "./ApplicationInsightsService"
import { HelmetProvider } from "react-helmet-async"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import TagManager from "react-gtm-module"
import { PublicClientApplication } from "@azure/msal-browser"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

const helmetContext = {}

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? "",
)

const tagManagerArgs = {
  gtmId: "GTM-TTWV6S4G",
}

if (process.env.REACT_APP_DEV === "prod") {
  TagManager.initialize(tagManagerArgs)
}

function App({ instance }: { instance: PublicClientApplication }) {
  const location = useLocation()
  return (
    // @ts-ignore
    <HelmetProvider context={helmetContext}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <MsalProvider instance={instance}>
          <QueryClientProvider client={queryClient}>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#86C769",
                  fontFamily: "Kodchasan",
                  borderRadius: 0,
                  colorText: "#36505E",
                  fontSizeHeading1: 24,
                  fontSizeHeading2: 20,
                  fontSizeHeading3: 18,
                  fontSizeHeading4: 16,
                },
                components: {
                  Menu: {
                    colorText: "#FFFFFF",
                  },
                  Dropdown: {
                    borderRadiusLG: 8,
                    borderRadiusSM: 4,
                    borderRadiusOuter: 4,
                    borderRadiusXS: 2,
                  },
                  Popover: {
                    borderRadiusOuter: 4,
                    borderRadiusXS: 2,
                  },
                  Pagination: {
                    colorPrimary: "#36505E",
                  },
                  Tag: {
                    // colorBgContainer: "#86C769",
                    // colorBgBase: "#86C769",
                    // colorPrimaryBg: "#86C769",
                    borderRadiusSM: 3,
                    colorText: "#36505E",
                    colorBorder: "#36505E",
                  },
                  Tabs: {
                    colorBgContainer: "#D4F5C5",
                    colorBgTextActive: "#D4F5C5",
                    colorTextBase: "#36505E",
                    colorText: "#36505E",
                    colorTextHeading: "#36505E",
                    margin: 0,
                    marginXL: 0,
                    marginXS: 0,
                    marginXXS: 0,
                    marginXXL: 0,
                    controlHeightLG: 60,
                  },
                  Empty: {
                    colorTextDescription: "#36505E",
                    colorTextLabel: "#36505E",
                    colorTextBase: "#36505E",
                  },
                  Button: {
                    colorText: "#36505E",
                  },
                  Input: {
                    colorPrimary: "#009ACE",
                    colorPrimaryActive: "#009ACE",
                    colorPrimaryHover: "#009ACE",
                    controlOutlineWidth: 0,
                    colorText: "#36505E",
                  },
                  Select: {
                    colorPrimary: "#86C769",
                    // colorPrimaryActive: "#86C769",
                    // colorPrimaryHover: "#86C769",
                    controlOutlineWidth: 0,
                    colorText: "#36505E",
                  },
                  Tooltip: {
                    borderRadius: 6,
                    borderRadiusOuter: 4,
                    borderRadiusXS: 2,
                  },
                  Modal: {
                    colorText: "#36505E",
                  },
                  Tour: {
                    borderRadius: 6,
                    borderRadiusOuter: 4,
                    borderRadiusXS: 2,
                  },
                },
              }}
            >
              <Elements stripe={stripePromise}>
                {location.pathname.includes("/learnings") ? (
                  <PageLayout isOtherRoute={true}>
                    <OtherRoute />
                  </PageLayout>
                ) : (
                  <>
                    <PageLayout isOtherRoute={false}>
                      <GeneralRoute />
                    </PageLayout>
                  </>
                )}
              </Elements>
            </ConfigProvider>
          </QueryClientProvider>
        </MsalProvider>
      </AppInsightsContext.Provider>
    </HelmetProvider>
  )
}

export default App
