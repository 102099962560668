/* eslint-disable */

import { useQuery } from "react-query"
import instance from "../../../config/config"
import { ApiResponse } from "../../../common/types/ApiResponse"
import { errorMessage } from "../../../common/utils/utils"

export type LevelUp = {
  id: string
  image_url: string
  subtitle: string
  tera_point: number
  title: string
  total_left_honors: number
  total_orbit_honors: number
  total_user_honors: number
}

/**
 * @deprecated
 */
export const usePoints = () => {
  console.warn("usePoints is deprecated")
  const {
    data: point,
    isLoading: loadingPoint,
    isError: errorPoint,
  } = useQuery("points", () => instance.get("/points"), {
    retry: false,
    refetchOnWindowFocus: false,
  })
  return { point, loadingPoint, errorPoint }
}

const fetchLevelUp = () => {
  const response: Promise<ApiResponse<LevelUp>> = instance.get(
    "/achievements/level-ups",
  )
  return response
}

export const useLevelUp = () => {
  const handleFetchError = (error: any) => {
    errorMessage(
      `An error occurred while fetching roadmap skills: ${error}`,
      error,
    )
  }
  return useQuery("levelUp", fetchLevelUp, {
    refetchOnWindowFocus: false,
    onError: handleFetchError,
    retry: () =>
      !(
        typeof instance?.defaults?.headers?.common?.["Authorization"] ===
        "string"
      ),
  })
}
