import React, { useEffect } from "react"
import { useCountdown } from "../../../../utils/countdown"
import { Workshop } from "../../types/workshops"
import { useQueryClient } from "react-query"

interface Props {
  workshop: Workshop
}

export const Countdown = (props: Props) => {
  const { workshop } = props
  const queryClient = useQueryClient()
  const [days, hours, minutes, seconds] = useCountdown(
    workshop.release_date ||
      workshop.livestream_date ||
      new Date().toISOString(),
  )

  //   console.log("inul", new Date().toISOString())

  useEffect(() => {
    if (
      !workshop.media &&
      workshop.mode === "pre-recorded" &&
      days + hours + minutes + seconds <= 0
    ) {
      queryClient.refetchQueries(["workshop"])
    }
  }, [days, hours, minutes, seconds, workshop])

  return (
    days + hours + minutes + seconds > 0 && (
      <div className="flex gap-x-2 mt-2">
        <div>starts in</div>
        <div>{`${days}d ${hours}h ${minutes}m ${seconds}s`}</div>
      </div>
    )
  )
}
