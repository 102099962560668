import React from "react"
import ExpertProfileDisplay from "./ExpertProfileDisplay"
import ExpertProfileSkills from "./ExpertProfileSkills"
import ExpertProfileEdit from "./ExpertProfileEdit"
import { Skeleton } from "antd"

interface ExpertProfileContentProps {
  editAction: any
  myArticles: any
}

const ExpertProfileContent: React.FC<ExpertProfileContentProps> = ({
  editAction,
  myArticles,
}) => {
  return (
    <div className="space-y-4">
      {!editAction?.isEditView ? (
        <div>
          <ExpertProfileDisplay editAction={editAction} />
        </div>
      ) : (
        <ExpertProfileEdit editAction={editAction} />
      )}

      <div>
        {myArticles?.isLoading ? (
          <div className="bg-white-custom rounded-xl px-4 py-6 grid grid-cols-12 gap-x-7 gap-y-4 grid-flow-row">
            {Array.from({ length: 6 }, (_: any, index: number) => (
              <div
                key={index}
                className="bg-white px-4 py-6 rounded-xl shadow-custom-sm col-span-12 md:col-span-6"
              >
                <Skeleton.Button
                  key={index}
                  active={true}
                  size={"small"}
                  shape={"default"}
                  // className="col-span-6"
                  block={true}
                />
                <Skeleton active paragraph={{ rows: 3 }} className="mt-5" />
              </div>
            ))}
          </div>
        ) : (
          <ExpertProfileSkills myArticles={myArticles} />
        )}
      </div>
    </div>
  )
}

export default ExpertProfileContent
