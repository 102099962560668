import { useState } from "react"

export const useLaunchpad = () => {
  const wordCloud = (val = []) =>
    val?.reduce((acc, cur) => {
      // Menggabungkan semua objek dari array cur ke dalam satu array
      const merged = [].concat(...cur)
      // Loop setiap objek dan hitung total kemunculannya
      merged.forEach((obj) => {
        const index = acc.findIndex((x) => x.id === obj.id)
        if (index === -1) {
          acc.push({ ...obj, total: 1 })
        } else {
          acc[index].total++
        }
      })
      return acc
    }, [])

  const getPeriode = (val) => {
    const year =
      val !== 0
        ? Math.floor(val / 12)
          ? `${Math.floor(val / 12)} year`
          : ""
        : ""
    const month = val % 12 ? `${Math.floor(val % 12)} month` : "0 month"
    return `${year} ${month}`
  }

  // state drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  return {
    isDrawerOpen,
    setIsDrawerOpen,
    wordCloud,
    getPeriode,
  }
}
