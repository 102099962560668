import { Image, Skeleton, Tag, Typography } from "antd"
import { FollowedImage, JobGreenImage } from "../../../../assets/images"
import { useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import useListCompanies from "../../hooks/useListCompanies"
import { useMsal } from "@azure/msal-react"
import useCareerAspiration from "../../../../hooks/useCareerAspiration"
import {
  ITourContext,
  TourContext,
} from "../../../../common/components/tour/TourProvider"

const { Title } = Typography

interface MyJobContentRightSectionProps {
  account: any
}

export const RecommendedSetting: React.FC<
  MyJobContentRightSectionProps
> = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState<string>("tab1")
  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()
  const { greencareerResp } = useCareerAspiration()

  const { listCompanies, isListCompaniesLoading, setIsFilterByUserFollower } =
    useListCompanies(activeAccount?.localAccountId)

  const handleTabChange = (key: string) => {
    setActiveTab(key)
  }

  useEffect(() => {
    setIsFilterByUserFollower(true)
  }, [setIsFilterByUserFollower])

  const {
    refs: { refJobRecommendationSettings },
  } = useContext(TourContext) as ITourContext

  const contentByCareerAspirations = () => {
    return (
      <div>
        <p>
          You set {greencareerResp?.greencareers?.length} Career Aspirations
        </p>
        <div>
          <ol>
            {greencareerResp?.greencareers?.map((item: any, index: number) => {
              return (
                <li key={index} className="-ml-5">
                  <p
                    onClick={() =>
                      navigate(`/job/roles/${item?.slug}`, {
                        state: { id: item?.id },
                      })
                    }
                    className="p-0 m-0 mb-4 ml-1 cursor-pointer hover:font-bold"
                  >
                    {item?.name}
                  </p>
                </li>
              )
            })}
          </ol>
        </div>
      </div>
    )
  }

  const contentByFollowedCompanies = () => {
    return (
      <div>
        <p>
          You follow {listCompanies?.list?.length}{" "}
          <span
            onClick={() =>
              navigate(`/job/companies`, {
                state: { isFollowed: true },
              })
            }
            className="cursor-pointer text-secondary hover:font-bold"
          >
            companies
          </span>
        </p>

        <div className="mb-3">
          {listCompanies?.list?.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className="flex items-center gap-4 mt-6"
                onClick={() => navigate(`/job/companies/${item?.id}`)}
              >
                <Image
                  src={item?.logo}
                  preview={false}
                  alt={`logo-reqruiter`}
                  width={35}
                />
                <p className="p-0 m-0 cursor-pointer hover:font-bold">
                  {item?.name}
                </p>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return isListCompaniesLoading ? (
    <div className="p-3 px-4 shadow-lg bg-white-custom rounded-xl">
      <Skeleton active={true} />
    </div>
  ) : (
    <div
      ref={refJobRecommendationSettings}
      className="p-3 px-4 shadow-xl bg-white-custom rounded-xl"
    >
      <Title level={3}>Job Recommendation Settings</Title>
      <p className="mt-5">Recommended jobs are based on your:</p>
      <div className="py-4 -mt-5">
        <div className="flex space-x-2">
          <Tag
            onClick={() => handleTabChange("tab1")}
            className={`cursor-pointer hover:bg-secondaryYellow py-1 px-3 rounded-xl flex !justify-start items-center gap-2 ${
              activeTab === "tab1" ? "bg-secondaryYellow" : "bg-white"
            }`}
          >
            <Image
              src={JobGreenImage}
              preview={false}
              alt={`logo-reqruiter`}
              width={25}
            />
            Career Aspirations
          </Tag>
          <Tag
            onClick={() => handleTabChange("tab2")}
            className={`cursor-pointer hover:bg-secondaryYellow py-1 px-3 rounded-xl flex !justify-start items-center gap-2 ${
              activeTab === "tab2" ? "bg-secondaryYellow" : "bg-white"
            }`}
          >
            <Image
              src={FollowedImage}
              preview={false}
              alt={`logo-reqruiter`}
              width={25}
            />
            Followed Employers
          </Tag>
        </div>
        <div className="mt-4 bg-white shadow-lg rounded-xl">
          {activeTab === "tab1" && (
            <div className="px-4 py-1 bg-white rounded-xl">
              {contentByCareerAspirations()}
            </div>
          )}
          {activeTab === "tab2" && (
            <div className="px-4 py-1 bg-white rounded-xl">
              {contentByFollowedCompanies()}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
