import React from "react"
import success_payment from "../../../assets/json/success_payment.json"
import { Button } from "antd"
import { useNavigate } from "react-router-dom"
import Lottie from "lottie-react"

const ContentStatusPayment = () => {
  const navigate = useNavigate()
  return (
    <div className="px-5 py-20 text-center bg-white">
      <p className=" text-lg font-semibold">Congratulations!</p>
      <div className="mb-3">
        <p className="my-2 font-semibold">
          You successfully purchased courses for Green Skills.
        </p>
        <p className="my-2 font-semibold">
          Start learning by visiting your Skill Roadmap.
        </p>
      </div>
      <div className=" h-28 w-28 mx-auto my-10">
        <Lottie className="h-28 w-28" animationData={success_payment} />
      </div>
      <p>
        <strong>Note:</strong> it can take up to 5 minutes for the courses to
        show in your Roadmap.
      </p>
      <Button
        type="primary"
        className="my-3"
        onClick={() =>
          navigate("/dashboard/skill-trajectory-plan/skill-roadmap")
        }
      >
        Build My Skills Roadmap
      </Button>
    </div>
  )
}

export default ContentStatusPayment
