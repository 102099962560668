import React from "react"
import { useCv } from "../hook/useCv"
import { Content } from "../../missionControl/components/cv-view/Content"
import { Result, Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"

interface Props {
  isAnonymised?: boolean
}

export const CVFull: React.FC<Props> = ({ isAnonymised }) => {
  const { account, loadingAccount, isError } = useCv()
  return loadingAccount ? (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  ) : isError ? (
    <Result
      status="500"
      title="500"
      subTitle="Sorry, the data you search does not exist."
    />
  ) : (
    <div className="mt-5">
      <Content data={account} isAnonymised={isAnonymised} />
    </div>
  )
}
