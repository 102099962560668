import React from "react"
import { FitCart } from "./FitCart"

interface Props {
  data: { name: string; percent: number; color: string }[]
  maxAngle: number
}
export const Fit: React.FC<Props> = ({ data, maxAngle }) => {
  let dataBullet = []
  for (let i = 0; i < data.length; i++) {
    dataBullet.push(data[i])
  }
  return (
    <div className="relative">
      <div className=" absolute -top-[40px] md:-left-[120px]">
        <FitCart data={data.reverse()} maxAngle={maxAngle} />
      </div>
      <div className="absolute top-[25px] left-[190] md:left-[200px]">
        {dataBullet?.map((item, i) => (
          <div
            key={i}
            className={`flex gap-3 ${
              i !== 0 && i !== data.length - 1 ? "my-5" : "my-0"
            }`}
          >
            <div
              className={`w-5 h-5 rounded-full `}
              style={{ backgroundColor: item.color }}
            ></div>
            <div>{item.name}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
