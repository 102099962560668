import React from "react"
import { Card } from "antd"
import { EditOutlined } from "@ant-design/icons"
import { PinMapGray } from "../../../../../assets/images"
import { Account } from "../../../hooks/cv-builder/useProfile"

interface Props {
  handleUpdate?: (index: number) => void
  data: Account | undefined
}

export const CardViewBasic: React.FC<Props> = ({ handleUpdate, data }) => {
  const origin = "https://media.licdn.com/"
  return (
    <Card className="shadow-md w-full rounded-xl relative">
      <div className="flex gap-5 lg:flex-nowrap flex-wrap">
        <img
          className="w-24 h-24 rounded-full object-contain shadow-md"
          src={
            data?.profile_picture?.startsWith(origin)
              ? data?.profile_picture || data?.avatar
              : `${
                  data?.profile_picture || data?.avatar
                }?${new Date().getTime()}`
          }
          alt="profile"
          // style={{ border: "1px solid #F0F2F5" }}
        />

        <div>
          <h3 className="capitalize">{`${data?.first_name} ${data?.last_name}`}</h3>
          <div className="my-1">{data?.profile_headline || "-"}</div>
          <div className="flex gap-5 lg:flex-nowrap flex-wrap">
            <div>{`@${data?.nick_name}`}</div>
            <div>
              <PinMapGray />
              <span className="ml-2">{data?.country}</span>
            </div>
          </div>
        </div>
      </div>

      {data?.about_me && data.about_me !== "<p><br></p>" && (
        <div
          className="mt-2"
          dangerouslySetInnerHTML={{
            __html: data?.about_me,
          }}
        ></div>
      )}
      <EditOutlined
        className=" absolute right-5 top-5"
        onClick={() => handleUpdate!(0)}
      />
    </Card>
  )
}
