import React from "react"
import { Button, Checkbox } from "antd"
import { LinkedinOutlined } from "@ant-design/icons"
import { useHeader } from "../../../common/layout/hooks/useCustomHeader"
// import dayjs from "dayjs";
import { useNavigate } from "react-router-dom"
import { openInNewTab } from "../../../utils/util_funcs"
import { AuthLayout } from "../AuthLayout"
import { SearchLocation } from "../../../common/components/SearchLocation"
import { Location } from "../../../hooks/useSearchLocation"

const Register = () => {
  const navigate = useNavigate()
  const { handleRegisterRedirect } = useHeader()
  const [isChecked, setIsChecked] = React.useState(false)

  const [selectedCountry, setSelectedCountry] = React.useState<Location | null>(
    null,
  )

  return (
    <AuthLayout>
      <div className="px-10">
        <h1 className="mt-7 !font-semibold">Create Talent Account</h1>
        <div className=" lg:w-[75%] w-full mt-[50px] basic-card-small !shadow-custom-lg bg-custom-white">
          <>
            <SearchLocation onHandleSelect={(e) => setSelectedCountry(e)} />
            <Checkbox
              checked={isChecked}
              className="my-5 "
              onChange={(e) => setIsChecked(e.target.checked)}
            >
              I accept the{" "}
              <span
                onClick={() =>
                  openInNewTab("https://green.skilledin.io/terms-of-use/")
                }
                className="text-secondary cursor-pointer inline-block hover:text-blue-400 duration-300 transition-colors"
              >
                Terms of Use
              </span>{" "}
              and{" "}
              <span
                onClick={() =>
                  openInNewTab("https://green.skilledin.io/privacy-policy/")
                }
                className="text-secondary cursor-pointer inline-block hover:text-blue-400 duration-300 transition-colors"
              >
                Privacy Policy
              </span>
            </Checkbox>
            <Button
              className="w-full text-left"
              onClick={() => handleRegisterRedirect(selectedCountry)}
              // if not checked, or no country selected, disable button
              disabled={!isChecked || !selectedCountry}
            >
              <LinkedinOutlined />
              Continue with LinkedIn
            </Button>
            <div className="mt-3 flex items-center justify-end">
              Already Registered?
              <span
                className="text-[#009ACE] ml-1 cursor-pointer"
                onClick={() => navigate("/login")}
              >
                Sign In
              </span>
            </div>
          </>
        </div>
      </div>
    </AuthLayout>
  )
}

export default Register
