import React from "react"
import { Role } from "../stores/useRoleStore"
import { Moon } from "../../../../assets/images"
import { Button } from "antd"

interface ProfileDoesNotExistProps {
  hasApplied: boolean
  onApplied: () => void
  description: string
  role: Role
  subtitle: string
  isBtnLoading: boolean
}

const ProfileDoesNotExist = ({
  hasApplied,
  onApplied,
  description,
  subtitle,
  isBtnLoading,
}: ProfileDoesNotExistProps) => {
  return (
    <div className="flex flex-col items-center justify-center gap-5 text-center p-7">
      <img
        src={Moon}
        className="h-[116px] object-contain aspect-square"
        alt="empty"
      />
      <div className=" font-semibold text-base">{subtitle}</div>
      <div className="">{description}</div>
      {hasApplied ? (
        <div className="ring-primary ring-1 text-primary bg-white px-4 py-1 rounded">
          Applied
        </div>
      ) : (
        <Button
          loading={isBtnLoading}
          className="bg-primary text-white"
          onClick={onApplied}
        >
          Apply Now
        </Button>
      )}
    </div>
  )
}

export default ProfileDoesNotExist
