export enum NotifyPayloadType {
  Badge = "BADGE",
  Orbit = "ORBIT",
}

export enum NotificationType {
  Alert = "ALERT",
  Modal = "MODAL",
}

export interface Orbit {
  id?: string
  title?: string
  subtitle?: string
  image_url?: string
  is_earned?: string
}

export interface Badge {
  id?: string
  title?: string
  subtitle?: string
  image_url?: string
}

export interface Honor {
  id?: string
  image_url?: string
  is_earned?: boolean
}

export interface ExpandNotificationData {
  type?: NotifyPayloadType
  orbit?: Orbit
  badge?: Badge
  honors?: Honor[]
}

export interface NotifyData<T> {
  title?: string
  description?: string
  type?: NotificationType
  data?: T
}

export interface PayloadItem {
  title: string
  description: string
  type: NotificationType
}
