import React from "react"
import { Account } from "../../hooks/cv-builder/useProfile"
import Card from "antd/es/card/Card"
import { Moon } from "../../../../assets/images"
import { UpOutlined, DownOutlined, FileTextOutlined } from "@ant-design/icons"
import { Tag } from "antd"
import FiveCircleRateTS from "../../../../common/components/FiveCircleRateTS"
import { useNavigate } from "react-router-dom"
import { useMsal } from "@azure/msal-react"

interface Props {
  data: Account | undefined
  isAnonymised?: boolean
}

export const CardGreenSkill: React.FC<Props> = ({ data, isAnonymised }) => {
  const [active, setActive] = React.useState<boolean>(false)
  const navigate = useNavigate()
  const { instance: user } = useMsal()
  const activeAccount = user.getActiveAccount()

  return (
    <div>
      <Card className="shadow-md rounded-xl bg-[#FAFAFA]">
        {data?.green_skill_proficient_in?.find(
          (item) => item.total_completed_level > 0,
        ) ? (
          data?.green_skill_proficient_in
            ?.filter((item) => item.total_completed_level > 0)
            .map((a, i) => (
              <div
                key={i}
                className="px-3 py-1 my-2 bg-[#F0F2F5] rounded "
                style={{ border: "1px solid #D0DDE5" }}
              >
                <div className="flex md:gap-5 md:items-center justify-between gap-5  md:flex-row flex-col ">
                  <FiveCircleRateTS
                    count={5}
                    defaultValue={a.total_completed_level}
                    circleSize={16}
                    disabled
                  />
                  <div className="md:text-left w-full">{a?.name}</div>
                </div>
                {!isAnonymised && (
                  <div className=" w-28 my-2 text-white">
                    {activeAccount ? (
                      a.is_request_verified && a.is_signature_exist ? (
                        <Tag
                          onClick={() =>
                            navigate("/learnings/preview/certificate", {
                              state: {
                                skillData: a,
                              },
                            })
                          }
                          className={` py-1 cursor-pointer text-center w-full rounded-full bg-secondary text-white px-4`}
                          style={{ border: 0 }}
                        >
                          <FileTextOutlined /> Verification
                        </Tag>
                      ) : (
                        a.is_signature_exist && (
                          <Tag
                            onClick={() =>
                              navigate("/learnings/preview/certificate", {
                                state: {
                                  skillData: a,
                                },
                              })
                            }
                            className={`py-1 cursor-pointer w-full text-center rounded-full bg-backdrop text-white px-4`}
                          >
                            <FileTextOutlined /> Cerificate
                          </Tag>
                        )
                      )
                    ) : a.is_request_verified && a.is_signature_exist ? (
                      <Tag
                        onClick={() =>
                          navigate(
                            `/learnings/preview/certificate/${a.id}/${data.id}/${data?.first_name} ${data?.last_name}`,
                          )
                        }
                        className={` py-1 cursor-pointer text-center w-full rounded-full bg-secondary text-white px-4`}
                        style={{ border: 0 }}
                      >
                        <FileTextOutlined /> Verification
                      </Tag>
                    ) : (
                      a.is_signature_exist && (
                        <Tag
                          onClick={() =>
                            navigate(
                              `/learnings/preview/certificate/${a.id}/${data.id}/${data?.first_name} ${data?.last_name}`,
                            )
                          }
                          className={`py-1 cursor-pointer w-full text-center rounded-full bg-backdrop text-white px-4`}
                        >
                          <FileTextOutlined /> Cerificate
                        </Tag>
                      )
                    )}
                  </div>
                )}
              </div>
            ))
        ) : (
          <div className="">
            <h2 className="capitalize">green skills</h2>
            <div className=" first-letter:capitalize my-5">skilled in</div>
            <div className="flex gap-3 items-center">
              <img src={Moon} alt="cookies" />
              <div>Learning in progress</div>
            </div>
          </div>
        )}
        {data?.green_skill_interest_in && (
          <div className="mt-5">
            <div className="flex justify-between items-center">
              <div className="mb-2">Interested in learning</div>
              {active ? (
                <div
                  className="flex gap-2 items-center cursor-pointer text-secondary"
                  onClick={() => setActive(!active)}
                >
                  <UpOutlined className="w-3 h-3" />
                  <small className="">hide details</small>
                </div>
              ) : (
                <div
                  className="flex gap-2 items-center cursor-pointer text-secondary"
                  onClick={() => setActive(!active)}
                >
                  <DownOutlined className="w-3 h-3" />
                  <small className="">show details</small>
                </div>
              )}
            </div>
            <div className="flex flex-wrap ">
              {" "}
              {active &&
                data?.green_skill_interest_in?.map((item, index) => (
                  <Tag
                    key={index}
                    color="gold"
                    className="mb-2 overflow-hidden max-w-[235px] sm:w-auto"
                  >
                    <div className="truncate">{item.name}</div>
                  </Tag>
                ))}
            </div>
          </div>
        )}
      </Card>
    </div>
  )
}
