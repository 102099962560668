import React from "react"
import { PageContent } from "../../../../common/layout"
import {
  BoardContentSection,
  BoardRightSection,
  BoardTopSection,
} from "../../components/jobBoards/JobBoardSection"

import { HomeOutlined } from "@ant-design/icons"
import useListJobBoards from "../../hooks/jobBoards/useListJobBoard"
import { useMsal } from "@azure/msal-react"

const JobBoard: React.FC = () => {
  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()
  const userID: string = activeAccount?.localAccountId!
  const { jobBoardResp, filterHandler, filterResp } = useListJobBoards(userID)

  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      path: "/job/roles",
      title: "Jobs Galaxy",
    },
    {
      path: "/job/board",
      title: "Job Board",
    },
  ]

  return (
    <>
      <PageContent
        content={
          <BoardContentSection
            jobBoardResp={jobBoardResp}
            filterHandler={filterHandler}
          />
        }
        header={<BoardTopSection breadcrumbsItems={items} />}
        contentRightSidebar={
          <BoardRightSection
            filterHandler={filterHandler}
            filterResp={filterResp}
          />
        }
      />
    </>
  )
}

export default JobBoard
