import React from "react"
import { Button, Modal, Image } from "antd"
import {
  useFirebaseNotifications,
  useGetNotifsFromDb,
} from "../../modules/notifications/hooks"
import {
  ModalPopupNotification,
  NotificationInstructions,
} from "../../modules/notifications/components"
import { onMessageListener } from "../../modules/notifications/hooks/useFirebaseNotifications"
import { Link } from "react-router-dom"
import { Terrapoints } from "../../assets/images"
import instance from "../../config/config"
import {
  NotifyData,
  NotifyPayloadType,
  ExpandNotificationData,
  NotificationType,
} from "../../modules/notifications/types/notifs"
import { MessagePayload } from "firebase/messaging"

interface Props {
  isNewUser: "true" | "false"
  children: React.ReactNode
}

const newUserOrbitNotificationData: ExpandNotificationData = {
  type: NotifyPayloadType.Orbit,
  orbit: {
    id: "1",
    title: "Orbit 1",
    subtitle: "Galaxy Explorer",
    image_url:
      process.env.REACT_APP_BLOB_STORAGE_URL + "/gamification/orbit-1.png",
  },
  honors: [
    {
      id: "1",
      image_url:
        process.env.REACT_APP_BLOB_STORAGE_URL +
        "/gamification/green-job-honor-green-pioneer.png",
      is_earned: false,
    },
    {
      id: "2",
      image_url:
        process.env.REACT_APP_BLOB_STORAGE_URL +
        "/gamification/gaya-sentinel.png",
      is_earned: false,
    },
    {
      id: "3",
      image_url:
        process.env.REACT_APP_BLOB_STORAGE_URL +
        "/gamification/green-skill-honor-trailblazer.png",
      is_earned: false,
    },
    {
      id: "4",
      image_url:
        process.env.REACT_APP_BLOB_STORAGE_URL +
        "/gamification/green-skill-honor-astroseed.png",
      is_earned: false,
    },
  ],
}

export const WithNotifications = ({ isNewUser, children }: Props) => {
  const {
    contextHolder,
    popUpModalData,
    popUpModalOpen,
    setPopUpModalData,
    setPopUpModalOpen,
    notifModalShow,
    setNotifModalShow,
    api,
  } = useFirebaseNotifications()
  const requestPermission = () => {
    Notification.requestPermission().then((permission) => {
      if (permission !== "granted") {
        setNotifModalShow(true)
      } else {
        setNotifModalShow(false)
      }
    })
  }

  React.useEffect(() => {
    let timer: NodeJS.Timeout
    if (isNewUser === "true") {
      timer = setTimeout(() => {
        setPopUpModalData({
          title: "Mission Start!",
          description: "Your mission! Collect these honors on Orbit 1",
          type: NotificationType?.Modal,
          data: JSON.stringify(newUserOrbitNotificationData),
        })
        setPopUpModalOpen(true)
        sessionStorage?.setItem("newUser", "false")
      }, 10000)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [isNewUser, setPopUpModalData, setPopUpModalOpen])

  const notifications = useGetNotifsFromDb()

  onMessageListener().then(async (payload: MessagePayload) => {
    // refecth notifications
    notifications.refetch()
    // console.log(payload);
    try {
      const item: NotifyData<ExpandNotificationData | undefined> = JSON.parse(
        payload?.data?.item ?? "{}",
      )
      if (item?.type === NotificationType.Alert) {
        api.open({
          placement: "bottomRight",
          message: null,
          duration: 6,
          description: (
            <>
              <div
                style={{
                  backgroundColor: "#86C769",
                  borderRadius: "5px 0px 0px 5px",
                  width: "6px",
                  height: "100%",
                  position: "absolute",
                  top: "0",
                  left: "0",
                }}
              ></div>
              <div className="flex items-center gap-x-3">
                <Image src={Terrapoints} preview={false} width={64} />
                <div className="flex flex-col gap-y-1">
                  <div className="text-base font-semibold">
                    {item?.title ?? "Notification"}
                  </div>
                  <div className="text-base">
                    {item?.description ?? "Not Available"}
                  </div>
                  <Link
                    className="text-secondary hover:text-cyan-700"
                    to="/dashboard/galactic-achievements/recharge-station"
                  >
                    How do I earn points?
                  </Link>
                </div>
              </div>
            </>
          ),
          className: "custom-class",
          style: {
            width: 451,
            padding: "20px",
            paddingLeft: "20px",
            borderRadius: 5,
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            backgroundColor: "#ffffff",
            position: "relative",
          },
        })
      } else if (item?.type === NotificationType.Modal && item?.data) {
        if (
          item?.data?.type === NotifyPayloadType.Orbit &&
          item?.data?.orbit?.id
        ) {
          await instance.patch(`/notifications/${item?.data?.orbit?.id}`)
        } else if (
          item?.data?.type === NotifyPayloadType.Badge &&
          item?.data?.badge?.id
        ) {
          await instance.patch(`/notifications/${item?.data?.badge?.id}`)
        }
        setPopUpModalData(item as NotifyData<string>)
        setPopUpModalOpen(true)
      }
    } catch (e) {
      alert(e)
    }
  })
  return (
    <>
      {children}
      {contextHolder}
      {popUpModalOpen && popUpModalData && (
        <ModalPopupNotification
          notificationData={popUpModalData}
          open={popUpModalOpen}
          onCancel={() => {
            setPopUpModalOpen(false)
            setPopUpModalData(null)
          }}
        />
      )}{" "}
      <Modal
        title="Notification Permission"
        centered
        open={notifModalShow && false} // not showing this modal for now
        closable={false}
        footer={[
          <Button key="submit" type="primary" onClick={requestPermission}>
            Allow Notifications
          </Button>,
        ]}
      >
        <p>
          {Notification.permission === "denied"
            ? "It seems like notifications are blocked in your browser settings. Please allow notifications to continue using this application."
            : "Please allow notifications to continue using this application."}
        </p>
        {Notification.permission === "denied" && <NotificationInstructions />}
      </Modal>
    </>
  )
}
