import React from "react"
import { usePrivacySetting } from "../hooks/usePrivacySetting"
import { Skeleton, Switch, Typography } from "antd"
// import { InfoCircleOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { JobAplication, TalentFinder } from "../../../assets/images"

export const PrivacySettingContent: React.FC = () => {
  const privacy = usePrivacySetting()

  return (
    <>
      <div className="bg-white py-5 px-5 rounded-xl shadow-md">
        {privacy.loadingPrivacy ? (
          <Skeleton active title={false} paragraph={{ rows: 10 }} />
        ) : (
          <>
            <div className="mb-10">
              <h3>My data visible to Talent Community</h3>
              <Typography.Paragraph className="my-3">
                Control what other users with Talent Account like yours can see
                about you on the platform. The settings affect all places where
                your profile is visible, including Leaderboard, Community, and
                Learning
              </Typography.Paragraph>
              {/* <div className="my-5 grid grid-cols-12 gap-5">
                <div className="col-span-2"></div>
                <div className="col-span-2">
                  <div className="flex items-start justify-center">
                    <div className="font-semibold mr-1">Private</div>
                    <InfoCircleOutlined className="w-3 h-3" />
                  </div>
                </div>
                <div className="col-span-8"></div>
              </div> */}
              <div className="mt-10 my-5 grid grid-cols-12 gap-5">
                <div className="col-span-2 capitalize">Profile picture</div>
                <div className="col-span-2 text-center">
                  <Switch
                    checked={privacy.statusPrivate.profile_picture}
                    onChange={() =>
                      privacy.changeStatus(
                        "profile_picture",
                        !privacy.statusPrivate.profile_picture,
                      )
                    }
                    loading={privacy.loadingUpdate}
                    size="small"
                  />
                </div>
                <div className="col-span-8">
                  Community will see{" "}
                  <strong>
                    {privacy.statusPrivate.profile_picture
                      ? "profile picture"
                      : "default avatar"}
                  </strong>
                </div>
              </div>
              <div className="my-5 grid grid-cols-12 gap-5">
                <div className="col-span-2 capitalize">Full name</div>
                <div className="col-span-2 text-center">
                  <Switch
                    checked={privacy.statusPrivate.full_name}
                    size="small"
                    loading={privacy.loadingUpdate}
                    onChange={() =>
                      privacy.changeStatus(
                        "full_name",
                        !privacy.statusPrivate.full_name,
                      )
                    }
                  />
                </div>
                <div className="col-span-8">
                  Community will see your{" "}
                  <strong>
                    {privacy.statusPrivate.full_name ? "Full Name" : "username"}
                  </strong>
                </div>
              </div>
              <div className="my-5 grid grid-cols-12 gap-5">
                <div className="col-span-2 capitalize">Social Media</div>
                <div className="col-span-2 text-center">
                  <Switch
                    checked={privacy.statusPrivate.social_media}
                    size="small"
                    loading={privacy.loadingUpdate}
                    onChange={() =>
                      privacy.changeStatus(
                        "social_media",
                        !privacy.statusPrivate.social_media,
                      )
                    }
                  />
                </div>
                <div className="col-span-8">
                  Community members will{" "}
                  {!privacy.statusPrivate.social_media && <strong>not</strong>}{" "}
                  see your social media links
                </div>
              </div>
              <div className="my-5 grid grid-cols-12 gap-5">
                <div className="col-span-2 capitalize">Email</div>
                <div className="col-span-2 text-center">
                  <Switch
                    checked={privacy.statusPrivate.email}
                    loading={privacy.loadingUpdate}
                    onChange={() =>
                      privacy.changeStatus(
                        "email",
                        !privacy.statusPrivate.email,
                      )
                    }
                    size="small"
                  />
                </div>
                <div className="col-span-8">
                  Community members will{" "}
                  {!privacy.statusPrivate.email && <strong>not</strong>} see
                  your email
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="bg-white px-5 rounded-xl shadow-md">
        <div className="my-6">
          <h3>My data visible to Recruiters</h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-7 mt-3">
            <div className="flex flex-col gap-3 bg-white rounded-xl shadow-custom px-5 py-3">
              <div className="flex items-center gap-2">
                <img
                  src={JobAplication}
                  alt="job aplication"
                  className="w-8 h-8 object-cover"
                />
                <span>Job Application</span>
              </div>
              <Typography.Paragraph>
                Recruiters will not be able to see your Personal Identifiable
                Information when you apply for a job. They will see your
                experience, education, skills, location, and profile description
                provided by you in Green CV. Your full name, profile picture,
                and email address will only be shared with the recruiters after
                they shortlist you for the applied job.
              </Typography.Paragraph>
            </div>
            <div className="flex flex-col gap-3 bg-white rounded-xl shadow-custom px-5 py-3">
              <div className="flex items-center gap-2">
                <img
                  src={TalentFinder}
                  alt="talent finder"
                  className="w-8 h-8 object-cover"
                />
                <span>Talent Finder</span>
              </div>
              <Typography.Paragraph>
                You can make your Green CV discoverable to recruiters in the
                Talent Database. They can find your CV even if you haven't
                applied for a job. Your personal information (email, picture,
                and full name) will remain hidden. You have control over
                approving requests to share your information, and you can see
                the requesting company.
              </Typography.Paragraph>
              <Typography.Paragraph>
                To turn on visibility of Green CV in Talent Database, visit
                <Link to={"/job/my"}> My Jobs</Link>
              </Typography.Paragraph>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white py-5 px-5 rounded-xl shadow-md">
        <div className="">
          <h3>My data visible to Instructors</h3>
          <Typography.Paragraph className="mt-2">
            With us, your privacy is at the highest level. When you take a
            course, Instructors teaching it will only see your performance data,
            but not personal identifiable information.
          </Typography.Paragraph>
          <Typography.Paragraph>
            In cases where your email or full name is required, you will be
            asked for consent before sharing it with the Instructors.
          </Typography.Paragraph>
        </div>
      </div>
    </>
  )
}
