import React from "react"
import { Account } from "../../hooks/cv-builder/useProfile"
import { EmptyData } from "./EmptyData"
import { Card } from "antd"
import { ChartHardkill } from "../cv-builder/skill/CVSkillCharts"

interface Props {
  data: Account | undefined
}

export const CardHardSkill: React.FC<Props> = ({ data }) => {
  return (
    <div>
      {data?.hard_skill ? (
        <Card className="shadow-md rounded-xl bg-[#FAFAFA]">
          <h2 className=" capitalize">other technical skills</h2>
          <div className="mt-5 h-60">
            <ChartHardkill data={data?.hard_skill} />
          </div>
        </Card>
      ) : (
        <EmptyData title="other technical skills" />
      )}
    </div>
  )
}
