import React from "react"
import { useParams } from "react-router-dom"
import { HomeOutlined } from "@ant-design/icons"
import { PageContent } from "../../../common/layout"
import {
  SingleSchoolContentSection,
  SingleSchoolRightSection,
  SingleSchoolTopSection,
} from "../components/SingleSchoolCompany"
import useDetailSchool from "../hooks/useDetailSchool"

const SingleSchool = () => {
  const { schoolId } = useParams()

  const { detailSchool, isDetailSchoolLoading } = useDetailSchool(schoolId)
  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      path: "/skill/green-skills",
      title: "Skills Galaxy",
    },
    {
      path: "/skill/schools",
      title: "Schools",
    },
    {
      path: "/skill/schools/1",
      title: `${detailSchool?.name}`,
    },
  ]
  return (
    <PageContent
      header={
        <SingleSchoolTopSection
          breadcrumbsItems={items}
          detailSchool={detailSchool}
          isLoading={isDetailSchoolLoading}
        />
      }
      content={
        <SingleSchoolContentSection
          detailSchool={detailSchool}
          isLoading={isDetailSchoolLoading}
        />
      }
      contentRightSidebar={
        <SingleSchoolRightSection
          detailSchool={detailSchool}
          isLoading={isDetailSchoolLoading}
        />
      }
    />
  )
}

export default SingleSchool
