import React from "react"
import { PageContent, PageHeader } from "../../../common/layout"
import {
  HomeOutlined,
  SearchOutlined,
  LoadingOutlined,
} from "@ant-design/icons"
import { Empty, Input, Select, Spin, Switch } from "antd"
import { WorkshopCard } from "../components/ProgramCard"
import { useInfinite } from "../../../hooks/useInfinite"
import { Workshop } from "../types/workshops"

const Workshops = () => {
  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "Workshops",
    },
  ]

  const {
    data,
    lastCardElementRef,
    handleChageParams,
    handleSearch,
    isLoading,
    params,
  } = useInfinite<Workshop>({
    key: "workshops-and-programs",
    path: "/workshops-and-programs",
    queryParams: {
      page: 1,
      limit: 10,
      title: "",
      filter: undefined,
      my_workshop: "0",
      currency_code: localStorage.getItem("currency_code") || "sgd",
    },
    searchBy: "title",
  })

  return (
    <PageContent
      header={
        <PageHeader title={"Workshops"} breadcrumbItems={items}>
          <div className="flex gap-4 flex-wrap items-center">
            <div
              className="flex flex-grow min-w-[70px]"
              style={{ borderBottom: "1px solid #D0DDE5" }}
            >
              <SearchOutlined />
              <Input
                bordered={false}
                onFocus={(event) => event.target.select()}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="search"
              />
            </div>

            <div className="bg-grey-select min-w-[122px]">
              <Select
                placeholder="Mode"
                filterOption={false}
                options={[
                  { label: "Watch now", value: "watch-now" },
                  { label: "Upcoming", value: "coming-soon" },
                  // { label: "Pre Recorded", value: "pre-recorded" },
                ]}
                onSelect={(value) => {
                  handleChageParams({ filter: value, page: 1 })
                }}
                className="w-full"
                allowClear
                onClear={() =>
                  handleChageParams({ filter: undefined, page: 1 })
                }
              />
            </div>
            <div className="flex items-center gap-2 min-w-[122px]">
              <Switch
                checked={params.my_workshop === "1"}
                onChange={() => {
                  handleChageParams({
                    my_workshop: params.my_workshop === "1" ? "0" : "1",
                    page: 1,
                  })
                }}
              />
              <div className="">My Workshops only</div>
            </div>
          </div>
        </PageHeader>
      }
      content={
        <div className="">
          {isLoading && data.length === 0 ? (
            <div className=" absolute left-[calc(50%-1.125rem)] top-[60%] text-4xl text-primary">
              <LoadingOutlined />
            </div>
          ) : data.length < 1 ? (
            <div className="h-40">
              <Empty />
            </div>
          ) : (
            <Spin
              spinning={isLoading && data.length > 0}
              indicator={<LoadingOutlined spin />}
              size="small"
            >
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {data?.map((workshop, i) => (
                  <WorkshopCard
                    key={workshop.id}
                    workshop={workshop}
                    observ={
                      data.length === i + 1 ? lastCardElementRef : undefined
                    }
                  />
                ))}
              </div>
            </Spin>
          )}
        </div>
      }
    />
  )
}

export default Workshops
