import React, { useEffect, useState } from "react"
import { useLocation, Link } from "react-router-dom"
import { Result, Button } from "antd"

const ErrorPage: React.FC = () => {
  const location = useLocation()
  const [title, setTitle] = useState<string>("Error")
  const [description, setDescription] = useState<string>(
    "Sorry, something went wrong.",
  )

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const titleParam = params.get("title")
    const descriptionParam = params.get("description")

    if (titleParam) {
      setTitle(titleParam)
    }
    if (descriptionParam) {
      setDescription(descriptionParam)
    }
  }, [location.search])

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <Result
        status="404"
        title={title}
        subTitle={description}
        extra={
          <Button type="primary">
            <Link to="/">Back Home</Link>
          </Button>
        }
        className="max-w-md mx-auto"
      />
    </div>
  )
}

export default ErrorPage
