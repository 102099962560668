import React, { useState } from "react"
import { Tabs, Skeleton, Empty } from "antd"
import {
  UserProfile,
  useSustainabilityScores,
} from "../hooks/useSustainabilityScores"
import UserCard from "../../../common/components/UserCard"
import { LevelUp, useLevelUp } from "../hooks/useLevelUp"
import { BasicContainerWithTitle } from "../../../common/components/BasicBoxContainers/BasicContainerWithTitle"

interface UserListProps {
  selectedTab: TLevelKey
  levelUpsData: LevelUp | undefined
}

const UserList = ({ selectedTab, levelUpsData }: UserListProps) => {
  const scoresQuery = useSustainabilityScores(selectedTab)
  const userList = scoresQuery?.data?.data?.data || []

  if (scoresQuery?.isLoading) {
    return (
      <div className="p-5">
        <Skeleton active paragraph={{ rows: 8 }} />
      </div>
    )
  }

  if (scoresQuery?.isError) {
    return <Empty description="Set up your location" />
  }

  let renderedUserList: (UserProfile | string)[] = userList?.sort((a, b) => {
    if (a?.rank !== null && b?.rank !== null) {
      return a.rank - b.rank
    } else {
      return 0
    }
  })

  if (userList.length > 5) {
    renderedUserList = [
      ...userList.slice(0, 5),
      "...",
      userList[userList.length - 1],
    ]
  }

  return (
    <ul className="w-full  basic-card-small bg-white  m-0 p-0 !py-4 list-none mt-2">
      {renderedUserList?.map((user, i) => {
        if (user === "..." && typeof user === "string") {
          return (
            <li className="my-1 px-2" key={user + i}>
              {user}
            </li>
          )
        }
        if (typeof user !== "string") {
          return (
            <li className="my-1 px-2" key={`${user?.email}-${i}`}>
              <div className="flex justify-between w-full items-center">
                <div className="mr-6">{user?.rank ?? 0}.</div>
                <UserCard
                  // @ts-ignore
                  className=""
                  user={user}
                  levelUpsData={levelUpsData}
                />
                <div className="text-[#BFBFBF] text-base ml-auto ">
                  {user?.point}
                </div>
              </div>
            </li>
          )
        }
      })}
    </ul>
  )
}

interface SustainabilityScoreProps {}

type TLevelKey = "orbit" | "global" | "local"

export const SustainabilityScore = React.forwardRef<
  HTMLDivElement,
  SustainabilityScoreProps
>((_, ref) => {
  const [selectedTab, setSelectedTab] = useState<TLevelKey>("orbit")

  const onChange = (key: string) => {
    setSelectedTab(key as TLevelKey)
  }

  const levelUps = useLevelUp()
  const levelUpsData = levelUps.data?.data?.data

  const items = [
    {
      key: "orbit",
      label: `Orbit`,
      children: (
        <UserList selectedTab={selectedTab} levelUpsData={levelUpsData} />
      ),
    },
    {
      key: "global",
      label: `Global`,
      children: (
        <UserList selectedTab={selectedTab} levelUpsData={levelUpsData} />
      ),
    },
    {
      key: "local",
      label: `Local`,
      children: (
        <UserList selectedTab={selectedTab} levelUpsData={levelUpsData} />
      ),
    },
  ]

  return (
    <BasicContainerWithTitle
      ref={ref}
      title="Sustainability Score"
      titleSize="h3"
      type="small"
    >
      <Tabs
        className="custom-tabs custom-tabs-yellow"
        defaultActiveKey="orbit"
        activeKey={selectedTab}
        items={items}
        onChange={onChange}
      />
    </BasicContainerWithTitle>
  )
})

export default SustainabilityScore
