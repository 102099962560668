import React from "react"
import { OtherCompany } from "../../../../assets/images"
import {
  EnvironmentOutlined,
  HeartFilled,
  HeartOutlined,
  UserOutlined,
} from "@ant-design/icons"
import { Tag } from "antd"
import { Company } from "../../types/company"

interface Props {
  data: Company
  observ: (node: Element | null) => void
  isFollow: boolean
  handleFollowCompany: (val: any) => void
  handleUnfollowCompany: (val: any) => void
  loadingFollowUnfollow: boolean
  onNavigate?: any
}

export const Card: React.FC<Props> = ({
  data,
  observ,
  isFollow,
  handleFollowCompany,
  handleUnfollowCompany,
  loadingFollowUnfollow,
  onNavigate,
}) => {
  return (
    <div
      ref={observ}
      className=" shadow-md rounded-xl px-3 py-5 hover:shadow-lg cursor-pointer bg-white"
      onClick={onNavigate}
    >
      <div className=" flex justify-between items-center">
        <Tag
          bordered={false}
          onClick={(e) => {
            if (!loadingFollowUnfollow) {
              if (isFollow) {
                handleUnfollowCompany(data?.id)
              } else {
                handleFollowCompany({
                  id: data?.id,
                  name: data.name,
                })
              }
            }
            e.stopPropagation()
          }}
          color={isFollow ? undefined : "volcano"}
          className={`${
            isFollow ? "bg-primary-bg" : "text-backdrop"
          } max-w-full overflow-clip group hover:scale-105 duration-200 transition-all cursor-pointer`}
          icon={
            isFollow ? (
              <HeartFilled key={"favorite"} className="!text-primary" />
            ) : (
              <HeartOutlined className="hover:text-primary" key="favorite" />
            )
          }
        >
          {isFollow ? "Following" : "Follow"}
        </Tag>
        <div className=" text-xs uppercase tracking-widest">{`${
          data?.jobs && data?.jobs.length > 0 ? data?.jobs.length : "no"
        } new jobs`}</div>
      </div>
      <h4 className=" text-center capitalize my-3">
        {data?.name || "unknown"}
      </h4>
      <div className="flex justify-center text-center">
        <img
          className="w-16 h-16 object-contain"
          src={data?.logo || OtherCompany}
          alt="school"
        />
      </div>
      <div className="text-center my-3">
        <Tag closable={false} className="">
          {data?.sector || "unknown"}
        </Tag>
      </div>
      <div className=" text-[#919191] flex justify-center gap-5">
        <div className=" flex gap-2 items-baseline ">
          <EnvironmentOutlined /> <div>{data?.headquarter || "unknown"}</div>
        </div>
        <div className=" flex gap-2 items-baseline ">
          <UserOutlined /> <div>{helperSize(data?.size || "")}</div>
        </div>
      </div>
    </div>
  )
}
const helperSize = (size: string): string => {
  if (size.includes("employees")) {
    return size.replace(" employees", "")
  } else {
    return "1"
  }
}
