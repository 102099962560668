import instance from "../../../../config/config"
import { ApiResponsePaginatedInfinite } from "../../../../common/types/ApiResponse"
import { ICommunityPost } from "../types/commonn"

export const userPostsList = (user_id: string, limit: number, page: number) => {
  return instance.get<ApiResponsePaginatedInfinite<ICommunityPost>>(
    `community/posts/my/${user_id}`,
    {
      params: {
        limit,
        page,
      },
    },
  )
}
