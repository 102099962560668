import { Switch, TimePicker, Tooltip } from "antd"
import dayjs, { Dayjs } from "dayjs"
import React from "react"
import {
  Control,
  Controller,
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form"
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons"
import { CalendarSettingsForm } from "./ModalCalendarSettings"
import { MentorAvailabilityResponse } from "../../mentor/profile/types/response"
import { v4 as uuidv4 } from "uuid"

interface CalendarAvailabilitySettingsDayProps {
  control: Control<FieldValues, CalendarSettingsForm>
  day: MentorAvailabilityResponse["days"][number]
  index: number
  watch: UseFormWatch<CalendarSettingsForm>
  setValue: UseFormSetValue<CalendarSettingsForm>
  getValues: UseFormGetValues<CalendarSettingsForm>
}

const CalendarAvailabilitySettingsDay: React.FC<
  CalendarAvailabilitySettingsDayProps
> = ({ day, control, index, watch, setValue, getValues }) => {
  const addExtendTime = (dayId: string) => {
    const days = watch("days")
    const dayIndex = days.findIndex((day) => day.id === dayId)

    if (dayIndex !== -1) {
      const extendTimes = days[dayIndex].extend_times || []
      const newExtendTime = { id: uuidv4(), start_time: 0, end_time: 0 }
      setValue(
        `days.${dayIndex}.extend_times`,
        [...extendTimes, newExtendTime],
        { shouldDirty: true },
      )
    }
  }

  const removeExtendTime = (dayId: string, extendTimeId: string) => {
    const days = watch("days")
    const dayIndex = days.findIndex((day) => day.id === dayId)

    if (dayIndex !== -1) {
      const extendTimes = days[dayIndex].extend_times || []
      const updatedExtendTimes = extendTimes.filter(
        (time) => time.id !== extendTimeId,
      )
      setValue(`days.${dayIndex}.extend_times`, updatedExtendTimes, {
        shouldDirty: true,
      })
    }
  }

  const updateExtendTime = (
    dayId: string,
    extendTimeId: string,
    timeType: "start_time" | "end_time",
    timeValue: number,
  ) => {
    const days = watch("days")
    const dayIndex = days.findIndex((day) => day.id === dayId)

    const extendTimes = days[dayIndex].extend_times || []
    const updatedExtendTimes = extendTimes.map((time) => {
      if (time.id === extendTimeId) {
        return timeType === "start_time"
          ? { ...time, start_time: timeValue, end_time: timeValue }
          : { ...time, [timeType]: timeValue }
      }
      return time
    })
    setValue(`days.${dayIndex}.extend_times`, updatedExtendTimes, {
      shouldDirty: true,
    })
  }

  return (
    <div className="ring-1 ring-[#9CAFB9] p-4 bg-[#F6F6F6] rounded-md ">
      <div className="flex justify-between items-center">
        <div className="text-lg font-semibold">{daysOfWeek[day?.day]} </div>
        <Controller
          name={`days.${index}.enabled`}
          control={control}
          render={({ field }) => <Switch {...field} checked={day?.enabled} />}
        />
      </div>
      <div className="max-h-[200px] overflow-y-auto scrollbar-hide">
        <div className="flex items-center justify-between gap-x-3">
          <div className="flex items-center justify-between my-1 mt-4">
            <div className="flex items-center">
              <TimePicker
                value={dayjs(formatTime(day?.start_time), format)}
                format={format}
                disabled={!day?.enabled}
                use12Hours
                allowClear={false}
                className="mr-2"
                onChange={(time) => {
                  const convertedTime = convertTimeToValue(time)
                  setValue(`days.${index}.start_time`, convertedTime, {
                    shouldDirty: true,
                  })
                  setValue(`days.${index}.end_time`, convertedTime)
                }}
              />
              <span>-</span>
              <TimePicker
                value={dayjs(formatTime(day?.end_time), format)}
                format={format}
                disabled={!day?.enabled}
                disabledTime={() => {
                  const startTime = dayjs(getValues(`days.${index}.start_time`))
                  if (!startTime.isValid()) return {}
                  return {
                    disabledHours: () =>
                      Array.from({ length: 24 }, (_, hour) => hour).filter(
                        (hour) => hour < startTime.hour(),
                      ),
                    disabledMinutes: (selectedHour) => {
                      if (selectedHour === startTime.hour()) {
                        return Array.from(
                          { length: 60 },
                          (_, minute) => minute,
                        ).filter((minute) => minute < startTime.minute())
                      }
                      return []
                    },
                  }
                }}
                use12Hours
                allowClear={false}
                className="ml-2"
                onChange={(time) => {
                  const convertedTime = convertTimeToValue(time)
                  setValue(`days.${index}.end_time`, convertedTime),
                    { shouldDirty: true }
                }}
              />
            </div>
          </div>
          <Tooltip title="Add a time slot for when you're available on this day.">
            <PlusOutlined
              className="my-1 mt-4 cursor-pointer"
              onClick={() => addExtendTime(day?.id)}
            />
          </Tooltip>
        </div>
        {day?.extend_times?.map((slot, indexE) => (
          <div
            key={indexE}
            className="flex items-center justify-between gap-x-3"
          >
            <div className="flex items-center justify-between my-1 mt-4">
              <div className="flex items-center">
                <TimePicker
                  value={dayjs(formatTime(slot?.start_time), format)}
                  format={format}
                  disabled={!day?.enabled}
                  needConfirm={false}
                  use12Hours
                  allowClear={false}
                  className="mr-2"
                  onChange={(time) =>
                    updateExtendTime(
                      day?.id,
                      slot?.id!,
                      "start_time",
                      convertTimeToValue(time),
                    )
                  }
                />
                <span>-</span>
                <TimePicker
                  value={dayjs(formatTime(slot?.end_time), format)}
                  format={format}
                  disabled={!day?.enabled}
                  needConfirm={false}
                  allowClear={false}
                  disabledTime={() => {
                    const startTime = dayjs(
                      getValues(
                        `days.${index}.extend_times.${indexE}.start_time`,
                      ),
                    )
                    if (!startTime.isValid()) return {}
                    return {
                      disabledHours: () =>
                        Array.from({ length: 24 }, (_, hour) => hour).filter(
                          (hour) => hour < startTime.hour(),
                        ),
                      disabledMinutes: (selectedHour) => {
                        if (selectedHour === startTime.hour()) {
                          return Array.from(
                            { length: 60 },
                            (_, minute) => minute,
                          ).filter((minute) => minute < startTime.minute())
                        }
                        return []
                      },
                    }
                  }}
                  use12Hours
                  className="ml-2"
                  onChange={(time) =>
                    updateExtendTime(
                      day?.id,
                      slot?.id!,
                      "end_time",
                      convertTimeToValue(time),
                    )
                  }
                />
              </div>
            </div>
            <DeleteOutlined
              className=""
              onClick={() => removeExtendTime(day?.id, slot?.id!)}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

const formatTime = (time: number) =>
  `${String(time).padStart(4, "0").slice(0, 2)}:${String(time)
    .padStart(4, "0")
    .slice(2, 4)} PM`
const format = "hh:mm a"
const convertTimeToValue = (time: Dayjs) =>
  time ? time.hour() * 100 + time.minute() : 0

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]

export default CalendarAvailabilitySettingsDay
