/* eslint-disable */

import { useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import { instanceV2 } from "../../../../config/config"
import { message } from "antd"
import { AxiosResponse } from "axios"
import { Language } from "./useLanguage"

export const useLanguageUpdate = (
  dataLang: Language,
  index: number,
  callback: (index: number, data: Language) => void,
) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      proficiency_id: dataLang.proficiency.name
        ? {
            label: dataLang.proficiency.name,
            value: dataLang.proficiency.id,
            title: dataLang.proficiency.level,
          }
        : null,
      language_id: dataLang.language.name
        ? {
            label: dataLang.language.name,
            value: dataLang.language.id,
          }
        : null,
    },
  })

  const queryClient = useQueryClient()

  const { mutateAsync: addLang, isLoading: loadingAddLang } = useMutation({
    mutationFn: async (val) => {
      try {
        const res = await instanceV2.post(`/accounts/languages`, val)
        return res
      } catch (error) {
        throw error
      }
    },
    mutationKey: "account-language",
  })

  const { mutateAsync: updateLang, isLoading: loadingUpdateLang } = useMutation(
    {
      mutationFn: async (val: any) => {
        try {
          const res = await instanceV2.patch(
            `/accounts/languages/id/${val.id}`,
            val,
          )
          return res
        } catch (error) {
          throw error
        }
      },
      mutationKey: "account-languages-update",
    },
  )

  const onSubmit = async (val: any) => {
    message.open({
      key: "update-language",
      content: "updating...",
      type: "loading",
    })
    const form = {
      ...val,
      id: dataLang?.id,
      proficiency_id: val.proficiency_id.value,
      language_id: val.language_id.value,
    }
    try {
      let response: AxiosResponse<any, any>
      if (form?.id) {
        response = await updateLang(form)
      } else {
        response = await addLang(form)
      }
      if (response) {
        const finalDataJob: Language = {
          id: form.id || response.data?.data,

          language: {
            id: val.language_id.value,
            name: val.language_id.label,
          },
          proficiency: {
            name: val.proficiency_id.label,
            id: val.proficiency_id.value,
            level: val.proficiency_id.title,
          },
          isEdit: false,
        }
        queryClient.invalidateQueries(["account-cv"]).then(() => {
          callback(index, finalDataJob)
          message.destroy()
        })
      }
    } catch (error: any) {
      message.open({
        key: "update-language",
        content: error?.response?.data?.data || "Internal server error",
        type: "error",
      })
    }
  }
  return {
    control,
    errors,
    handleSubmit,
    watch,
    onSubmit,
    loading: loadingAddLang || loadingUpdateLang,
  }
}
