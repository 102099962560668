import React, { useRef, useState } from "react"
import useLoading from "../../../hooks/Loading"
import { useNavigate, useParams } from "react-router-dom"
import {
  RocketOutlined,
  CloseSquareFilled,
  TrophyOutlined,
  BookOutlined,
  BulbOutlined,
  ArrowLeftOutlined,
  TabletOutlined,
  EyeInvisibleOutlined,
  CheckCircleOutlined,
  FilePdfOutlined,
  DownloadOutlined,
  EyeOutlined,
  CloseOutlined,
} from "@ant-design/icons"
import Lottie from "lottie-react"
import spinner_loading_one from "../../../assets/json/spinner_loading_one.json"
import { useLearning } from "../hooks/useLearning"
import { useMsal } from "@azure/msal-react"
import { BitmovinPlayer } from "../../../common/components/BitmovinPlayer"
import { Button, Drawer, Popover, Tag } from "antd"

interface LearningV2Props {}

const LearningV2: React.FC<LearningV2Props> = () => {
  const navigate = useNavigate()
  const { isCustomLoading, setIsCustomLoading } = useLoading(true, 2000)
  const {
    skill_id: id,
    skill_name,
    level_name,
    level_id: level,
    module_id: index,
  } = useParams()

  const currentModule =
    parseInt(index!) == 5 ? parseInt(index!) - 2 : parseInt(index!) - 1

  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()

  const [currentSection, setCurrentSection] = useState<string>("introduction")
  const [typeDrawer, setTypeDrawer] = useState<string>("")
  const [activeTab, setActiveTab] = useState<number>(currentModule)
  const [activeTabLesson, setActiveTabLesson] = useState<number>(0)
  const [showPDF, setShowPDF] = useState<boolean>(false)

  const { modules, update } = useLearning(
    id,
    activeAccount?.localAccountId,
    level,
  )

  const moduleData = modules?.data?.detail?.modules
  const ass = { assessments: modules?.data?.detail?.assessments }
  if (moduleData?.length < 5) {
    moduleData?.push(ass)
  }

  const [open, setOpen] = useState(false)

  const showDrawer = (type: string) => {
    setOpen(true)
    setTypeDrawer(type)
  }

  const onClose = () => {
    setOpen(false)
  }

  const myRef = useRef<HTMLDivElement>(null)
  const scrollToRef = (element_id: string) => {
    const element = document.querySelector(element_id)
    if (element) {
      element.scrollIntoView({ behavior: "smooth" })
      setCurrentSection(element_id.substring(1))
    }
  }

  const sidebarMenu = () => (
    <div className="absolute flex flex-col items-center bg-white left-10 w-[4.5rem] shadow-custom-sm rounded-lg ">
      {activeTab === 0 ? (
        ""
      ) : (
        <div
          className={`rounded-t-lg mx-auto text-2xl flex flex-col items-center justify-center hover:bg-primary cursor-pointer shadow w-full h-16 ${
            currentSection === "recap" ? "bg-primary" : ""
          }`}
          onClick={() => scrollToRef("#recap")}
        >
          <CheckCircleOutlined />
          <span className="text-xs" style={{ fontSize: "0.60rem" }}>
            Recap
          </span>
        </div>
      )}
      <div
        className={`mx-auto text-2xl flex flex-col items-center justify-center hover:bg-primary cursor-pointer shadow w-full h-16 ${
          currentSection === "introduction" ? "bg-primary" : ""
        }`}
        onClick={() => scrollToRef("#introduction")}
      >
        <RocketOutlined />
        <span className="text-xs" style={{ fontSize: "0.60rem" }}>
          Introduction
        </span>
      </div>

      <div
        className={`mx-auto text-2xl flex flex-col items-center justify-center hover:bg-primary cursor-pointer shadow w-full h-16 ${
          currentSection === "lesson" ? "bg-primary" : ""
        }`}
        onClick={() => scrollToRef("#lesson")}
      >
        <TabletOutlined />
        <span className="text-xs" style={{ fontSize: "0.60rem" }}>
          Lessons
        </span>
      </div>
      <div
        className={`mx-auto text-2xl flex flex-col items-center justify-center hover:bg-primary cursor-pointer shadow w-full h-16 ${
          currentSection === "resources" ? "bg-primary" : ""
        }`}
        onClick={() => {
          showDrawer("resources")
        }}
      >
        <BulbOutlined />
        <span className="text-xs" style={{ fontSize: "0.60rem" }}>
          Resources
        </span>
      </div>
      <div
        className={`mx-auto text-2xl flex flex-col items-center justify-center hover:bg-primary cursor-pointer shadow w-full h-16 ${
          currentSection === "glossary" ? "bg-primary" : ""
        }`}
        onClick={() => {
          showDrawer("glossary")
        }}
      >
        <BookOutlined />
        <span className="text-xs" style={{ fontSize: "0.60rem" }}>
          Glosary
        </span>
      </div>
      <div
        className={`mx-auto text-2xl flex flex-col items-center justify-center hover:bg-primary cursor-pointer shadow w-full h-16 ${
          currentSection === "reflection" ? "bg-primary" : ""
        }`}
        onClick={() => scrollToRef("#reflection")}
      >
        <TrophyOutlined />
        <span className="text-xs" style={{ fontSize: "0.60rem" }}>
          Reflections
        </span>
      </div>
      <div
        className={`rounded-b-lg mx-auto text-2xl flex flex-col items-center justify-center hover:bg-primary cursor-pointer shadow w-full h-16 `}
        onClick={() => navigate(-1)}
      >
        <CloseSquareFilled />
        <span className="text-xs" style={{ fontSize: "0.60rem" }}>
          Exit Course
        </span>
      </div>
    </div>
  )

  const downloadPDF = (url: string, filename: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const a = document.createElement("a")
        a.href = url
        a.setAttribute("download", filename)
        a.style.display = "none"
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      })
      .catch((error) => console.error("Error downloading PDF:", error))
  }

  const optionalButton = (itemPDF: any) => {
    return (
      <div className={`flex items-center gap-2`}>
        <Button
          size={"small"}
          onClick={() => downloadPDF(itemPDF.url, itemPDF.name)}
        >
          <DownloadOutlined /> Download
        </Button>
        <Button size={"small"} onClick={() => setShowPDF(!showPDF)}>
          {showPDF ? (
            <>
              <EyeInvisibleOutlined /> Hide Preview{" "}
            </>
          ) : (
            <>
              <EyeOutlined /> Preview{" "}
            </>
          )}
        </Button>
      </div>
    )
  }

  const [openPop, setOpenPop] = useState(false)
  const handleOpenChange = (newOpen: boolean) => {
    setOpenPop(newOpen)
  }

  const handleChangeTab = (idx: any) => {
    setIsCustomLoading(true)
    setTimeout(() => {
      setActiveTab(idx)
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
      setIsCustomLoading(false)
    }, 1000)
  }

  const handleChangeNextTab = (idx: any) => {
    setIsCustomLoading(true)

    setTimeout(() => {
      if (idx < modules?.data?.detail?.modules?.length) {
        setActiveTab(idx)
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        })

        if (parseInt(idx) > currentModule) {
          navigate(
            `/learnings/modules/${id}/${skill_name}/${level_name}/${level}/${
              idx + 1
            }`,
          )

          const data = {
            current_module: idx + 1,
          }

          update(data)
        }
      }
      setIsCustomLoading(false)
    }, 1000)
  }

  return isCustomLoading ? (
    <div className="flex justify-center flex-col items-center h-screen">
      <Lottie className="w-40" animationData={spinner_loading_one} />
      Loading...
    </div>
  ) : (
    <div className="relative bg-white pb-20 md:pb-16">
      {/* SIDEBAR MODULE MENU */}
      <div className="sticky top-24 z-50 hidden lg:inline-block">
        {sidebarMenu()}
      </div>

      {/* CONTENT */}
      <div className="mx-[3%] lg:mx-[15%] space-y-9 pt-5 lg:pt-0">
        {activeTab === 0 ? null : (
          <section
            className="leading-6 learning shadow-custom-sm rounded-lg"
            id="recap"
            ref={myRef}
          >
            <div className="bg-secondaryBlue py-3 top-24 sticky z-40 rounded-lg">
              <div className="top-24 sticky bg-secondaryBlue py-3 px-4 leading-7">
                <div className="flex items-center gap-3">
                  <h1>
                    <CheckCircleOutlined />
                  </h1>
                  <h3 className="">
                    <span className="capitalize font-extrabold">Recap</span>
                  </h3>
                </div>
                <h3>What you learnt so far</h3>

                <div className="mt-2">
                  <div
                    className=" text-[16px] custom-container text-backdrop"
                    dangerouslySetInnerHTML={{
                      __html: `
			            <style>
			              .custom-container a {
			                color: #009ACE !important;
			              }
			            </style>
            ${moduleData && moduleData[activeTab]?.overview?.recap}
          `,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </section>
        )}

        <section
          className="leading-6 learning shadow-custom-sm rounded-lg"
          id="introduction"
        >
          <div className="bg-white-custom py-3 top-24 sticky z-40 rounded-lg">
            <div className="top-24 sticky bg-white-custom py-3 px-4 leading-7 rounded-lg">
              <div className="flex items-center gap-3">
                <h1>
                  <RocketOutlined />
                </h1>
                <h3 className="">
                  <span className="capitalize font-extrabold">
                    {moduleData && moduleData[activeTab]?.name}
                  </span>
                </h3>
              </div>
              <h3>{moduleData && moduleData[activeTab]?.overview?.title}</h3>

              <div className="mt-2">
                <div
                  className=" text-[16px] custom-container text-backdrop"
                  dangerouslySetInnerHTML={{
                    __html: `
			            <style>
			              .custom-container a {
			                color: #009ACE !important;
			              }
			            </style>
            ${moduleData && moduleData[activeTab]?.overview?.introduction}
          `,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="leading-6 learning shadow-custom-sm rounded-lg"
          id="lesson"
        >
          <div className="bg-white-custom py-3 top-24 sticky z-40 rounded-lg">
            <div className="top-24 sticky bg-white-custom py-3 px-4 leading-7 rounded-lg">
              <div className="flex items-center flex-wrap md:flex-nowrap gap-x-3">
                <h1>
                  <TabletOutlined />
                </h1>
                <h3 className="">
                  <span className="capitalize font-extrabold">Lessons</span>
                </h3>

                <div className="sticky -top-8 z-40 bg-transparent py-2">
                  {moduleData &&
                    moduleData[activeTab]?.lessons?.map(
                      (itemLesson: any, index: number) => (
                        <span
                          key={index}
                          onClick={() => setActiveTabLesson(index)}
                          style={
                            activeTabLesson === index
                              ? {
                                  backgroundColor: "#FFEFA0",
                                  border: "1px solid #36505E",
                                }
                              : {
                                  backgroundColor: "#FFF",
                                  border: "1px solid #36505E",
                                }
                          }
                          className={`border py-1 px-5 rounded-full mr-1 cursor-pointer`}
                        >
                          {index + 1}
                        </span>
                      ),
                    )}
                </div>
              </div>
              <div className="mt-2">
                <div className=" text-[16px] custom-container text-backdrop">
                  {/* <div className="top-5 bg-white py-8 mx-auto w-4/6 "> */}
                  <div className="leading-6 space-y-2  ">
                    {moduleData &&
                      moduleData[activeTab]?.lessons?.map(
                        (itemLesson: any, index: number) => {
                          return (
                            <div
                              className={
                                activeTabLesson === index
                                  ? "w-full overflow-y-hidden px-2"
                                  : "hidden"
                              }
                              key={index}
                            >
                              {itemLesson?.video === null ? null : (
                                <div className="">
                                  <BitmovinPlayer
                                    dash={itemLesson?.video?.dash}
                                  />
                                </div>
                              )}

                              {itemLesson?.pdf !== null &&
                              itemLesson?.pdf?.length > 0 ? (
                                <div className={`mt-3`}>
                                  <h3 className={`!mb-3`}>Lesson Resources</h3>
                                  <div className={``}>
                                    {itemLesson?.pdf?.map(
                                      (itemPDF: any, index: number) => {
                                        return (
                                          <>
                                            <div className={`flex mb-2`}>
                                              <Popover
                                                content={optionalButton(
                                                  itemPDF,
                                                )}
                                                placement={`right`}
                                                overlayStyle={{
                                                  backgroundColor:
                                                    "transparent !important",
                                                }}
                                                overlayInnerStyle={{
                                                  backgroundColor:
                                                    "transparent !important",
                                                }}
                                                overlayClassName={`!bg-transparent`}
                                                color={"transparent"}
                                              >
                                                <Tag
                                                  key={index}
                                                  // onClick={() => openBlankTab(itemPDF?.url)}
                                                  className={`cursor-pointer hover:drop-shadow rounded-full bg-secondaryYellow border-none py-1 px-3 text-yellow-700 flex items-center whitespace-break-spaces w-auto`}
                                                >
                                                  <FilePdfOutlined />
                                                  <span>{itemPDF?.name}d</span>
                                                </Tag>
                                              </Popover>
                                            </div>
                                            {showPDF && (
                                              <div className={`mt-3`}>
                                                <div
                                                  style={{
                                                    width: "100%",
                                                    height: "350px",
                                                  }}
                                                >
                                                  <iframe
                                                    src={itemPDF?.url}
                                                    title="PDF Preview"
                                                    width="100%"
                                                    height="100%"
                                                    style={{
                                                      border: "none",
                                                    }}
                                                  />
                                                </div>
                                                );
                                              </div>
                                            )}
                                          </>
                                        )
                                      },
                                    )}
                                  </div>
                                </div>
                              ) : null}
                              <div
                                className="pt-2 text-[16px] text-backdrop custom-container leading-6 mt-3 text-justify break-words"
                                dangerouslySetInnerHTML={{
                                  __html: `
			            
            ${itemLesson?.description}
          `,
                                }}
                              ></div>
                            </div>
                          )
                        },
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="leading-6 learning shadow-custom-sm rounded-lg"
          id="reflection"
          ref={myRef}
        >
          <div className="bg-secondaryBlue py-3 top-24 sticky z-40 rounded-lg">
            <div className="top-24 sticky bg-secondaryBlue py-3 px-4 leading-7 rounded-lg">
              <div className="flex items-center gap-3">
                <h1>
                  <TrophyOutlined />
                </h1>
                <h3 className="">
                  <span className="capitalize font-extrabold">Reflection</span>
                </h3>
              </div>
              <div className="mt-2">
                <div
                  className=" text-[16px] custom-container text-backdrop"
                  dangerouslySetInnerHTML={{
                    __html: `
			            <style>
			              .custom-container a {
			                color: #009ACE !important;
			              }
			            </style>
            ${
              moduleData &&
              moduleData[activeTab]?.conceptual_supports?.reflection
            }
          `,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div>
        <div className="fixed bottom-0 z-50 bg-backdrop w-full flex items-center justify-between px-3 md:px-5 lg:px-14 py-2 flex-wrap md:flex-nowrap">
          <div className="flex items-center gap-4 w-full md:w-auto">
            <div className="flex items-center justify-center gap-3 w-full">
              <Button
                className="!rounded !bg-transparent !text-white"
                // size='small'
                onClick={() => {
                  showDrawer("resources")
                }}
                disabled={activeTab === 0}
              >
                <BulbOutlined /> Resources
              </Button>
              <Button
                className="!rounded !bg-transparent !text-white"
                // size='small'
                onClick={() => {
                  showDrawer("glossary")
                }}
                disabled={activeTab === 4}
              >
                <BookOutlined /> Glossary
              </Button>
              <Button
                className="!rounded !bg-white !text-black inline-block md:hidden"
                // size='small'
                block={true}
                onClick={() => {
                  navigate(-1)
                }}
                disabled={activeTab === 0}
              >
                <ArrowLeftOutlined /> Exit
              </Button>
            </div>
          </div>
          <div className="hidden md:flex items-center gap-4">
            <div>
              <Popover
                content={
                  <div className="flex flex-col gap-y-3">
                    {moduleData?.map((item: any, idx: number) => {
                      return (
                        <div className="grid grid-cols-2 gap-6" key={idx}>
                          <div
                            className={` ${
                              currentModule >= idx
                                ? "cursor-pointer "
                                : "cursor-not-allowed  "
                            }  `}
                            key={idx}
                            onClick={() => {
                              if (currentModule >= idx) {
                                handleChangeTab(idx)
                              }
                            }}
                          >
                            <div
                              className="flex text-left items-center gap-2"
                              // eslint-disable-next-line react/no-unknown-property
                              // disbled={true}
                              onClick={() =>
                                currentModule >= idx && idx === 4
                                  ? navigate(
                                      `/learnings/assessment/${modules?.green_skill_id}/${index}`,
                                    )
                                  : ""
                              }
                            >
                              <CheckCircleOutlined
                                className={`${
                                  activeTab == idx ? "text-[#D4F5C5]" : ""
                                }`}
                              />
                              <p
                                className="m-0 capitalize"
                                style={{ border: "0 1px solid #d4d4d4" }}
                              >
                                {idx === 4
                                  ? "Assessment"
                                  : item?.name?.split(" ")[0]}{" "}
                                {item?.name?.split(" ")[1]}
                              </p>
                            </div>
                          </div>
                          <p className="m-0">{item?.overview?.title}</p>
                        </div>
                      )
                    })}
                  </div>
                }
                trigger="click"
                open={openPop}
                onOpenChange={handleOpenChange}
              >
                <p className="m-0 text-white cursor-pointer hover:scale-105 transition-all">
                  Modules
                </p>
              </Popover>
            </div>
          </div>
          <div className="flex items-center gap-4 w-full md:w-auto">
            <div className="flex items-center justify-center gap-3 w-full mt-3 md:mt-0">
              <Button
                className="!rounded !bg-white !text-black hidden md:inline-block"
                // size='small'
                onClick={() => {
                  navigate(-1)
                }}
                disabled={activeTab === 0}
              >
                <ArrowLeftOutlined /> Exit Course
              </Button>
              {/* <Button
                className="!rounded w-full"
                // size='small'
                type="primary"
                onClick={() => {
                  setActiveTab(activeTab + 1)
                  setActiveTabLesson(0)
                }}
                disabled={activeTab === 4}
              >
                Next Module
              </Button> */}

              {activeTab < 3 ? (
                <Button
                  onClick={() => handleChangeNextTab(activeTab + 1)}
                  type="primary"
                  className="!rounded w-full"
                >
                  Next Module
                </Button>
              ) : (
                <Button
                  onClick={() =>
                    navigate(
                      `/learnings/assessment/${id}/${skill_name}/${level_name}/${modules
                        ?.data?.id}/${currentModule + 1}`,
                    )
                  }
                  type="primary"
                  className="!rounded w-full"
                >
                  Go to Test
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>

      <Drawer
        placement="left"
        size={"default"}
        onClose={onClose}
        open={open}
        closeIcon={
          <div className="!right-5 top-3 absolute !text-right !w-full ">
            <CloseOutlined />
          </div>
        }
        className="!bg-secondaryBlue"
      >
        <section className="leading-6 my-4 top-28 mt-10 learning" id="glossary">
          <div className="text-center">
            <h1>
              {typeDrawer === "glossary" ? <BookOutlined /> : <BulbOutlined />}
            </h1>
            <h3 className="font-extrabold">
              {typeDrawer === "glossary" ? "Glossary" : "Resources"}
            </h3>
            {/* <h5 className="">Common Terms</h5> */}
          </div>

          <div className="py-3">
            <div
              className=" text-[16px] custom-container text-backdrop"
              dangerouslySetInnerHTML={{
                __html: `
			            <style>
			              .custom-container a {
			                color: #009ACE !important;
			              }
			            </style>
            ${
              typeDrawer === "glossary"
                ? moduleData &&
                  moduleData[activeTab]?.conceptual_supports?.glossary
                : moduleData &&
                  moduleData[activeTab]?.conceptual_supports?.resources
            }
          `,
              }}
            ></div>
          </div>
        </section>
      </Drawer>
    </div>
  )
}

export default LearningV2
