import React from "react"
import { useLocation } from "react-router-dom"
import { PageContent } from "../../../common/layout"
import {
  ContentCertificate,
  HeaderCertificate,
} from "../components/CertificateComponent.tsx"
import { useMsal } from "@azure/msal-react"
import { useCertificate } from "../../jobGalaxy/hooks/useCertificate.ts"
import MetaDecorator from "../../../common/components/MetaDecorator.jsx"
const Certificate = () => {
  const location = useLocation()

  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()

  const { levels, isLoadingLevels } = useCertificate(
    location?.state?.skillData?.id,
    activeAccount?.localAccountId,
  )
  const levelData = levels?.data[0]

  // SEO
  const blogTitle = `${levelData?.talent?.first_name} ${levelData?.talent?.last_name} Certificate`
  const blogDescription = "This is my progress CV"
  const blogImage = `/green_skills_logo.png`
  const url = `${window.location.href}/${location?.state?.skillData
    ?.id}/${activeAccount?.localAccountId}/${encodeURIComponent(
    location?.state?.skillData?.name,
  )}`

  return (
    <>
      <MetaDecorator
        imageUrl={blogImage}
        description={blogDescription}
        title={blogTitle}
        imageAlt={"cv-image"}
      />

      <PageContent
        header={
          <HeaderCertificate
            dataSkill={location?.state?.skillData}
            isLoadingLevels={isLoadingLevels}
            schoolData={levelData}
            url={url}
          />
        }
        content={
          <ContentCertificate
            dataSkill={location?.state?.skillData}
            isLoadingLevels={isLoadingLevels}
            schoolData={levelData}
          />
        }
      />
    </>
  )
}

export default Certificate
