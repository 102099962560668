import React from "react"
import { Breadcrumbs, CustomPagination } from "../../../../common/components"
import { Empty, Input, Skeleton, Typography } from "antd"
import CardOfJobBoard from "./JobBoardCard"
import { SearchOutlined } from "@ant-design/icons"
import FilterSidebar from "./JobBoardFilter"

const { Title } = Typography

interface BoardTopSectionProps {
  breadcrumbsItems: Array<{ path: string; title: React.ReactNode }>
}

export const BoardTopSection: React.FC<BoardTopSectionProps> = ({
  breadcrumbsItems,
}) => {
  return (
    <div className="bg-white  py-4 px-8">
      <Breadcrumbs items={breadcrumbsItems} />

      <div className="text-backdrop mt-2">
        <Title>Get Hired!</Title>
      </div>
    </div>
  )
}

interface BoardContentSectionProps {
  jobBoardResp: any
  filterHandler: any
}

export const BoardContentSection: React.FC<BoardContentSectionProps> = ({
  jobBoardResp,
  filterHandler,
}) => {
  const numIterations = 2

  return (
    <div>
      <div className="px-6 py-7 bg-white-custom rounded-xl mb-4 shadow-md">
        <div className="flex" style={{ borderBottom: "1px solid #D0DDE5" }}>
          <SearchOutlined />
          <Input
            bordered={false}
            placeholder="search"
            onChange={(event) => jobBoardResp?.handleSearchJobBoard(event)}
          />
        </div>
      </div>

      {jobBoardResp?.isListJobBoardsLoading ? (
        <div className="bg-white py-3 px-5 h-auto overflow-hidden rounded-xl">
          {Array.from({ length: numIterations }).map((_, index) => (
            <div
              key={index}
              className="shadow-lg rounded-xl bg-white p-3 col-span-12 sm:col-span-6 lg:col-span-3 hover:shadow-xl mb-3 cursor-pointer transition-all w-full overflow-hidden"
              style={{ borderRadius: "3px" }}
            >
              <Skeleton active paragraph={{ rows: 2 }} />
            </div>
          ))}
        </div>
      ) : jobBoardResp?.listJobBoards?.list === null ||
        jobBoardResp?.listJobBoards?.list === undefined ||
        jobBoardResp?.listJobBoards?.list?.length === 0 ? (
        <div className="bg-white py-10 px-5 rounded-xl flex justify-center col-span-12 w-full  mx-auto md:mx-0 mt-8 md:mt-0">
          <Empty description="No jobs available to show." />
        </div>
      ) : (
        <div className="bg-white py-3 px-5 rounded-xl">
          {jobBoardResp?.listJobBoards?.list?.map(
            (item: any, index: number) => (
              <CardOfJobBoard
                key={index}
                item={item}
                index={index}
                handleSaveJob={filterHandler?.handleSaveJob}
                handleUnSaveJob={filterHandler?.handleUnSaveJob}
              />
            ),
          )}
        </div>
      )}

      <div className="">
        {jobBoardResp?.isLoading ? (
          <Skeleton active paragraph={{ rows: 1 }} />
        ) : (
          <CustomPagination
            selectedPage={1}
            setSelectedPage={jobBoardResp?.setPage}
            total_data={jobBoardResp?.listJobBoards?.total_data}
            // pageSize={limit}
            itemsPerPage={jobBoardResp?.limit}
            setItemsPerPage={jobBoardResp?.setLimit}
          />
        )}
      </div>
    </div>
  )
}

interface BoardRightSectionProps {
  filterHandler: any
  filterResp: any
}

export const BoardRightSection: React.FC<BoardRightSectionProps> = ({
  filterHandler,
  filterResp,
}) => {
  return (
    <FilterSidebar
      filterHandler={filterHandler}
      filterResp={filterResp}
      from="jobBoard"
    />
  )
}
