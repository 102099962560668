import { useInfiniteQuery } from "react-query"
import instance from "../../../config/config"
import { errorMessage } from "../../../common/utils/utils"

const fetchListCompnaySectorsInfinity = ({ pageParam = 1, search }) => {
  return instance.get("/references/company-sectors", {
    params: {
      page: pageParam,
      limit: 10,
      name: search,
    },
  })
}
export const useListCompanySectorsInfinity = (searchParam) => {
  const handleFetchError = (error) => {
    errorMessage(`An error occurred while fetching company-sectors: ${error}`)
  }

  return useInfiniteQuery(
    ["company-sectors-infinity", searchParam],
    ({ pageParam }) =>
      fetchListCompnaySectorsInfinity({ pageParam, search: searchParam }),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
      getNextPageParam: (lastPage) =>
        lastPage?.data?.data?.pagination?.page <
        lastPage?.data?.data?.pagination?.total_page
          ? lastPage?.data?.data?.pagination?.page + 1
          : undefined,
    },
  )
}
