import React from "react"
import { TMediaPostBody } from "../../types/commonn"
import { Carousel } from "antd"

const Media: React.FC<{ body: TMediaPostBody }> = ({ body }) => {
  return (
    <div className="w-full max-w-xl mx-auto mt-4 aspect-video">
      <Carousel autoplay>
        {body?.map((image) => (
          <div key={image.name}>
            <img
              src={image.url}
              alt={image.name}
              className="w-full object-contain rounded-lg"
            />
          </div>
        ))}
      </Carousel>
    </div>
  )
}

export default Media
