import { initializeApp } from "firebase/app"
import { isSupported } from "firebase/analytics"
import {
  Messaging,
  getMessaging,
  getToken,
  isSupported as isSupportedMessage,
} from "firebase/messaging"
import { Dispatch, SetStateAction } from "react"
import instance from "./config"
import { isSafari } from "@firebase/util"

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

let firebaseApp
let messaging: Messaging | null

const initializeFirebase = async () => {
  const analyticsSupported = await isSupported()
  const messagingSupported = await isSupportedMessage()

  if (!isSafari() && analyticsSupported && messagingSupported) {
    firebaseApp = initializeApp(firebaseConfig)
    // getAnalytics(firebaseApp);
    messaging = getMessaging(firebaseApp)
  }
}

initializeFirebase()

export { messaging }

export const requestPermission = async (
  setFCMToken: Dispatch<SetStateAction<String | null>>,
) => {
  if (!messaging) return
  console.log("Requesting User Permission......")
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification User Permission Granted.")
      if (messaging) {
        return getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        })
          .then(async (currentToken) => {
            if (
              currentToken &&
              !!instance?.defaults?.headers?.common?.["Authorization"]
            ) {
              await instance.patch("/accounts/fcm-token", {
                fcm_token: currentToken,
              })
              setFCMToken(currentToken)
            } else {
              console.log("Failed to generate the app registration token.")
            }
          })
          .catch((err) => {
            console.log(
              "An error occurred when requesting to receive the token.",
              err,
            )
          })
      }
    } else {
      console.log("User Permission Denied.")
    }
  })
}
