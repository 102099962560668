import { useState } from "react"
import { useQuery } from "react-query"
import instance from "../../../config/config"
import { useParams } from "react-router-dom"
import qs from "qs"
import { useMsal } from "@azure/msal-react"

export const useJobRequired = () => {
  const { greenSkillId: id } = useParams()
  const { accounts } = useMsal()
  const fetchJobRequiredSkill = (_, params) => {
    const { ...queryParams } = params
    return instance.get(`jobs/${accounts[0]?.localAccountId || "none"}`, {
      params: queryParams,
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    })
  }

  const [params, setParams] = useState({
    limit: 100,
  })

  const handleLoadMore = () => {
    setParams((prev) => ({
      ...prev,
      limit: prev.limit + 5,
    }))
  }
  const data = useQuery(
    ["fetch-job-required-skill", params],
    () =>
      fetchJobRequiredSkill(undefined, {
        ...params,
        green_skill_id: id,
      }),
    { refetchOnWindowFocus: false },
  )
  return { data, handleLoadMore }
}
