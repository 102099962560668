import { useCallback } from "react"
import useCommunityModalsStore from "../../store/useCommunityModalsStore"
import { useBecomeAFounder } from "./useFounder"
import { Role, useRoleStore } from "../stores/useRoleStore"

const useOnApplied = () => {
  const { role } = useRoleStore()
  const becomeAnExpertModal = useCommunityModalsStore(
    (state) => state.becomeAnExpertModal,
  )
  const mentorApplicationModal = useCommunityModalsStore(
    (state) => state.mentorApplicationModal,
  )
  const becomeFounderMutation = useBecomeAFounder()

  const onApplied = useCallback(async () => {
    switch (role) {
      case Role.Expert:
        return becomeAnExpertModal.open()
      case Role.Founder:
        return await becomeFounderMutation.mutateAsync()
      case Role.Mentor:
        return mentorApplicationModal.open()
      default:
        throw new Error("Unknown role")
    }
  }, [role, becomeAnExpertModal, becomeFounderMutation, mentorApplicationModal])

  return onApplied
}

export default useOnApplied
