export const FOUNDER_ENDPOINTS = {
  LIST: `public-startups/founders`,
  DETAIL: (id: string) => `public-startups/founders/${id}`,
  STATUS: `startups/founders/status`,
  PROFILE: `public-startups/founders/profile`,
  INVITE: `startups/founders/invite`,
  EDIT: `startups/founders`,
  CREATE: `startups/founders`,
}

export const FOUNDER_REFERENCE_ENDPOINTS = {
  LOCATION: `references/startups/locations`,
  STAGE: `references/startups/stages`,
  SOLUTION: `references/startups/solutions`,
  USERS: `accounts/all`,
}
