import { Button } from "antd"
import React from "react"
import { CalendarOutlined } from "@ant-design/icons"
import { Moon } from "../../../../assets/images"
import ScheduleCard from "./ScheduleCard"
import useCommunityModalsStore from "../../store/useCommunityModalsStore"
import { useQuery } from "react-query"
import { getMentorBookings } from "../apis/mentors"

const MySchedule = () => {
  // const { bookingData, handleSubmitCancelBooking, loadingCancelBooking } =
  //   useListMyBookings()

  const bookingQuery = useQuery(["booking-data"], getMentorBookings)
  const bookingData = bookingQuery.data?.data?.data

  const sortedBookingData = bookingData?.list?.slice().sort((a, b) => {
    return (
      new Date(a.datetime_string).getTime() -
      new Date(b.datetime_string).getTime()
    )
  })

  console.log(bookingData, sortedBookingData)

  const calendarSettingsModal = useCommunityModalsStore(
    (state) => state.calendarSettingsModal,
  )
  const isNoSchedule = false
  // const isNoSchedule = sortedBookingData?.length === 0
  return (
    <div>
      <div className="flex items-center justify-between mt-4">
        <h4 className="!font-semibold !leading-[38px] text-xl">My Schedule</h4>
        <Button
          icon={<CalendarOutlined />}
          onClick={() => calendarSettingsModal.open()}
          type="primary"
        >
          Calendar Settings
        </Button>
      </div>
      {isNoSchedule ? (
        <div className="flex flex-col items-center justify-center gap-5 text-center p-7">
          <img
            src={Moon}
            className="h-[116px] object-contain aspect-square"
            alt="empty"
          />
          <div className="text-base">
            Adjust your <span className="text-primary">calendar settings</span>{" "}
            to allow mentees to schedule meetings with you
          </div>
        </div>
      ) : (
        <div className="mt-4 space-y-5">
          {sortedBookingData?.map((booking, index) => (
            <ScheduleCard key={index} schedule={booking} />
          ))}
        </div>
      )}
    </div>
  )
}

export default MySchedule
