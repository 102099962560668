import { useContext } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { instanceV2 } from "../../../../config/config"
import { AccountContext } from "../../../../common/layout/PageLayout"
import { AxiosResponse } from "axios"
import { Customer } from "./types"
import { errorMessage } from "../../../../common/utils/utils"
import { message } from "antd"

export const useCustomer = () => {
  const account = useContext(AccountContext)
  const queryClient = useQueryClient()

  // create customer
  const { isError, isLoading: isLoadingCreate } = useQuery(
    "billing-customer-create",
    () => instanceV2.post("/billing/customers"),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["account"])
      },
      onError: (error: any) => {
        errorMessage(
          error?.response?.data?.data || "Internal server error",
          error,
        )
      },
      enabled: !!account?.data?.data && !account?.data?.data?.customer_id,
    },
  )

  const { data, isLoading } = useQuery(
    "billing-customer",
    () =>
      instanceV2.get<AxiosResponse<Customer>>(
        `/billing/customers/id/${account?.data?.data?.customer_id}`,
      ),
    {
      retry: false,
      enabled: !!account?.data?.data?.customer_id,
    },
  )

  const { mutateAsync: updateCustomer, isLoading: loadingUpdateCustomer } =
    useMutation({
      mutationFn: async (val: any) => {
        return await instanceV2.patch(`/billing/customers`, val)
      },
      mutationKey: "update-customer",
    })

  const handleUpdate = async (val: any) => {
    message.open({
      key: "update-customer",
      content: "updating...",
      type: "loading",
    })
    try {
      const response = await updateCustomer({
        name: val.name,
        email: val.email,
        // address: { country: val.country },
      })
      if (response) {
        queryClient.invalidateQueries(["billing-customer"]).then(() => {
          message.open({
            key: "update-customer",
            content: "billing updated",
            type: "success",
          })
        })
      }
    } catch (error: any) {
      message.open({
        key: "update-customer",
        content: error?.response?.data?.data || "Internal server error",
        type: "error",
      })
    }
  }

  const handleUpdateDefaultPayment = async (id_payment: string) => {
    message.open({
      key: "update-customer",
      content: "updating...",
      type: "loading",
    })
    try {
      const response = await updateCustomer({
        payment_method: id_payment,
      })
      if (response) {
        queryClient.invalidateQueries(["billing-customer"]).then(() => {
          message.open({
            key: "update-customer",
            content: "billing updated",
            type: "success",
          })
        })
      }
    } catch (error: any) {
      message.open({
        key: "update-customer",
        content: error?.response?.data?.data || "Internal server error",
        type: "error",
      })
    }
  }

  return {
    isErrorCreate: isError,
    data: data?.data?.data,
    isLoading: isLoading || isLoadingCreate,
    update: {
      handleAction: handleUpdate,
      isLoading: loadingUpdateCustomer,
      handleUpdateDefaultPayment,
    },
  }
}
