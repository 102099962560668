import React from "react"
import { PlusOutlined } from "@ant-design/icons"
import { AddPlus } from "../../../../assets/img"
import { Card, Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { Moon } from "../../../../assets/images"
import { useEducation } from "../../hooks/cv-builder/useEducation"
import { Edu } from "./education/Edu"

export const Education = () => {
  const {
    tempData,
    updateTempData,
    handleAddUp,
    handleAddDown,
    loadingEdu,
    onRemove,
    loadingremoveEdu,
    onRemoveTemp,
  } = useEducation()
  return (
    <div className="mb-5">
      <div className="flex items-center justify-between mb-5 px-6 py-2 rounded-xl bg-secondaryYellow shadow-md">
        <h2 className=" capitalize">education</h2>
        <div
          className="flex gap-3 items-center text-white bg-primary py-1 px-6 cursor-pointer"
          onClick={handleAddUp}
        >
          <PlusOutlined />
          <div className="hidden lg:block">New Education</div>
        </div>
      </div>
      {loadingEdu ? (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      ) : (
        <div>
          {tempData.length > 0 ? (
            tempData?.map((item: any, index: number) => (
              <Edu
                key={index}
                data={item}
                index={index}
                updateTempData={updateTempData}
                onRemove={onRemove}
                loadingremoveEdu={loadingremoveEdu}
                onRemoveTemp={onRemoveTemp}
              />
            ))
          ) : (
            <div>
              <Card className="shadow text-center mb-5 rounded-lg">
                <div className="mb-5">No education...</div>
                <img src={Moon} alt="cookies" />
              </Card>
            </div>
          )}
          <div className="flex justify-center ">
            <AddPlus
              className="cursor-pointer w-14 h-14"
              onClick={handleAddDown}
            />
          </div>
        </div>
      )}
    </div>
  )
}
