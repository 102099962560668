import React from "react"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import useCommunityModalsStore from "../../store/useCommunityModalsStore"
import { Button, Form, Input, message, Modal, Select } from "antd"
import FormImageUploader from "../../../../common/components/FormImageUploader"
import ImgCrop from "antd-img-crop"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useFounderReference } from "../../founder/hooks/useFounderReference"
import { LinkOutlined, CloseOutlined } from "@ant-design/icons"
import { useFetchDetailAPIV2 } from "../../../../hooks/generic_rest"
import {
  FOUNDER_ENDPOINTS,
  FOUNDER_REFERENCE_ENDPOINTS,
} from "../../founder/apis/endpoint"
import {
  FounderResponse,
  UserProfileResponse,
} from "../../founder/types/response"
import { useFetchList } from "../../public-expert/apis/rest"
import { LIST_FOUNDER_QUERY_NAME } from "../../founder/hooks/useFounder"
import FounderItem from "../../public-expert/components/FounderItem"
import { useMutation, useQueryClient } from "react-query"
import useFounderAndStartupData from "../hooks/useFounderAndStartup"
import clsx from "clsx"
import { showError } from "../../../../common/utils/utils"
import { updateStartup } from "../apis/founders"

const schema = z.object({
  name: z.string().min(1, { message: "Name is required." }),
  stage_id: z.string().min(1, { message: "Stage is required." }),
  location_id: z.string().min(1, { message: "Location is required." }),
  website: z.string().url().optional(),
  short_description: z
    .string()
    .min(1, { message: "Short description is required." })
    .max(250, {
      message: "Short description cannot be more than 250 characters",
    })
    .optional(),
  long_description: z.string().optional(),
  file: z.any().optional(),
  founders_id: z
    .object({
      id: z.string().min(1, { message: "Founder is required." }),
      name: z.string().min(1, { message: "Founder is required." }),
    })
    .array(),
})

export type TFormUpdateCompany = z.infer<typeof schema>

const ModalEditCompanyInfo = () => {
  const editCompanyInfoModal = useCommunityModalsStore(
    (state) => state.editCompanyInfoModal,
  )

  const { startupQuery } = useFounderAndStartupData()
  const startupData = startupQuery?.data?.data?.data

  const { stageData, locationData } = useFounderReference()
  const queryClient = useQueryClient()

  const { data: listUser, isLoading: listLoadingUser } = useFetchDetailAPIV2<
    UserProfileResponse[]
  >(`${FOUNDER_REFERENCE_ENDPOINTS.USERS}`, {}, "list-user-account")

  const { data: foundersData, isLoading: foundersListLoading } =
    useFetchList<FounderResponse>(`${FOUNDER_ENDPOINTS.LIST}`, {
      queryName: LIST_FOUNDER_QUERY_NAME,
    })

  const founderIds = new Set(
    foundersData?.list?.map((founder: FounderResponse) => founder?.id),
  )

  const userNotFounders = listUser?.filter(
    (item: UserProfileResponse) => !founderIds.has(item?.id),
  )

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm<TFormUpdateCompany>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: "",
      stage_id: undefined,
      location_id: undefined,
      website: "",
      short_description: "",
      long_description: "",
      file: "",
      founders_id: [],
    },
  })

  React.useEffect(() => {
    if (startupData) {
      reset({
        name: startupData?.name,
        stage_id: startupData?.stage_id,
        location_id: startupData?.location?.id,
        website: startupData?.website,
        short_description: startupData?.short_description,
        long_description: startupData?.long_description,
        file: [startupData?.logo],
        founders_id: [],
      })
    }
  }, [foundersData, startupData])

  const updateCompanyInfoMutation = useMutation(
    ({ id, data }: { id: string; data: TFormUpdateCompany }) =>
      updateStartup(id, data),
  )

  const { fields, append, remove } = useFieldArray({
    control,
    name: "founders_id" as const,
    keyName: "key",
  })

  const onSubmit = async (data: TFormUpdateCompany) => {
    message.loading({
      content: "Submitting company info...",
      key: "edit-company",
    })

    await updateCompanyInfoMutation.mutateAsync(
      {
        data: {
          ...data,
          file:
            data?.file?.[0] === startupData?.logo ? undefined : data?.file?.[0],
        },
        id: startupData?.id || "",
      },
      {
        onSuccess: async () => {
          message.success({
            content: "Company info updated successfully",
            key: "edit-company",
          })
          await queryClient.invalidateQueries(["detail-founder"])
          await queryClient.invalidateQueries(["startup"])
          editCompanyInfoModal.close()
        },
        onError: () => {
          showError({
            message: "Failed to update company info",
            key: "edit-company",
          })
        },
      },
    )
  }

  return (
    <Modal
      centered
      width={815}
      open={editCompanyInfoModal.isOpen}
      onCancel={() => editCompanyInfoModal.close()}
      onOk={() => {}}
      footer={null}
    >
      <div className="text-center">
        <h2 className="text-2xl font-bold">My Company</h2>
      </div>
      <Form>
        <div className="mt-5">
          <div className="text-lg font-semibold leading-[38px]">
            Company Information
          </div>
          <div className="mt-5 flex gap-5 items-start">
            <Form.Item>
              <Controller
                name={"file"}
                control={control}
                defaultValue={undefined}
                render={({ field: { onChange, value } }) => (
                  <ImgCrop modalWidth={800} aspect={10 / 10} rotationSlider>
                    <FormImageUploader
                      value={value}
                      onChange={onChange}
                      description="Company Logo"
                      className="w-full shadow-lg aspect-square"
                      height={150}
                    />
                  </ImgCrop>
                )}
              />
            </Form.Item>
            <div className="flex-grow ring-1 ring-dirtyBlue bg-white-custom rounded-xl p-4 space-y-4">
              <Form.Item
                className=""
                validateStatus={errors.name ? "error" : ""}
                help={errors.name?.message}
              >
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      status={errors?.name ? "error" : ""}
                      className=""
                      placeholder="Company Name"
                    />
                  )}
                />
              </Form.Item>
              <Form.Item
                className=""
                validateStatus={errors.stage_id ? "error" : ""}
                help={errors.stage_id?.message}
              >
                <Controller
                  name="stage_id"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="w-full"
                      placeholder="Stage"
                      showSearch
                      status={errors?.stage_id ? "error" : ""}
                      options={stageData?.listStage?.map((val) => ({
                        label: val.name,
                        value: val.id,
                      }))}
                      labelInValue={true}
                      onSelect={(value, option) => {
                        field.onChange(option.value)
                      }}
                      filterOption={(input, option) => {
                        if (option && option.value) {
                          return (
                            option.value
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          )
                        }
                        return false
                      }}
                    />
                  )}
                />
              </Form.Item>
              <Form.Item
                className=""
                validateStatus={errors.location_id ? "error" : ""}
                help={errors.location_id?.message}
              >
                <Controller
                  name="location_id"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="w-full"
                      placeholder="HQ Location"
                      showSearch
                      status={errors?.location_id ? "error" : ""}
                      options={locationData?.listLocations?.list?.map(
                        (val) => ({
                          label: val.name,
                          value: val.id,
                        }),
                      )}
                      labelInValue={true}
                      onSelect={(value, option) => {
                        field.onChange(option.value)
                      }}
                      filterOption={(input, option) => {
                        if (option && option.value) {
                          return (
                            option.value
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          )
                        }
                        return false
                      }}
                    />
                  )}
                />
              </Form.Item>
              <Form.Item
                className=""
                validateStatus={errors.website ? "error" : ""}
                help={errors.website?.message}
              >
                <Controller
                  name="website"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      status={errors?.website ? "error" : ""}
                      className=""
                      addonBefore={<LinkOutlined />}
                      placeholder="Company Website"
                    />
                  )}
                />
              </Form.Item>
              <Form.Item
                className=""
                validateStatus={errors.short_description ? "error" : ""}
                help={errors.short_description?.message}
              >
                <Controller
                  name="short_description"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      status={errors?.short_description ? "error" : ""}
                      maxLength={255}
                      max={255}
                      showCount={true}
                      className=""
                      placeholder="short description (255 char max) - will be visible in directory"
                    />
                  )}
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div className="text-lg font-semibold leading-[38px]">
            Co-founders
          </div>
          <div className="mt-5 grid grid-cols-2 items-start gap-4">
            <div className="flex flex-col ">
              <div className="">
                You can only invite existing Skilledin users
              </div>
              <Select
                className="my-1 font-default basis-full job-history w-full"
                placeholder="Search for a user"
                showSearch
                loading={listLoadingUser || foundersListLoading}
                onSelect={(value, option) => {
                  if (!fields?.find((item) => item?.id === value?.key)) {
                    append({ name: option?.label, id: value?.key })
                  }
                }}
                options={userNotFounders?.map((val) => ({
                  label: `${val?.first_name} ${val?.last_name}`,
                  value: val?.id,
                }))}
                labelInValue={true}
                filterOption={(input: any, option: any) => {
                  if (option && option.label) {
                    return (
                      option?.label
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    )
                  }
                  return false
                }}
              />
              <div className="py-4 space-y-3">
                {fields?.map((item, index) => (
                  <div
                    key={index}
                    className="w-full flex justify-between items-center "
                  >
                    <div className="w-[90%] line-clamp-1 font-medium">
                      {item?.name}
                    </div>
                    <CloseOutlined
                      className="cursor-pointer"
                      onClick={() => remove(index)}
                    />
                  </div>
                ))}
              </div>
              <div className="">
                Once invited and accepted, they will have the same edit access
                as you do. Please wait for their accept.
              </div>
            </div>
            <div className="flex flex-col text-center bg-white rounded-xl px-7 ring-1 ring-dirtyBlue py-5">
              <div className="text-lg font-semibold">Founding Team</div>
              <div
                className={clsx("grid gap-4 mt-4", {
                  "grid-cols-1": startupData?.founders?.length === 1,
                  "grid-cols-2":
                    startupData?.founders?.length &&
                    startupData?.founders?.length > 1,
                })}
              >
                {startupData?.founders?.map((item, index) => (
                  <FounderItem key={index} founder={item} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <Button
          className="rounded-md mt-5"
          block
          onClick={handleSubmit(onSubmit)}
          type="primary"
          loading={
            updateCompanyInfoMutation?.isLoading ||
            listLoadingUser ||
            foundersListLoading
          }
          disabled={!isDirty}
        >
          Save
        </Button>
      </Form>
    </Modal>
  )
}

export default ModalEditCompanyInfo
