import { useEffect, useState } from "react"
import { StartupResponse } from "../../founder/types/response"
import { useFetchDetail, useFetchList } from "../../../../hooks/generic_rest"
import { STARTUP_ENDPOINTS } from "../apis/endpoint"
import { debounce } from "../../../../utils/debounce"
import { useFounderReference } from "../../founder/hooks/useFounderReference"

const LIST_STARTUP_QUERY_NAME: string = "list-startup"
const DETAIL_STARTUP_QUERY_NAME: string = "detail-startup"

export const useListSolution = () => {
  const [queryParams, setQueryParams] = useState<any>({
    page: 1,
    limit: 10,
    name: "",
    location_id: "",
    solution_id: "",
    solution_name: "",
    stage_id: "",
    stage_name: "",
    location: [],
    stage: [],
    solution: [],
  })

  const {
    data: listData,
    isLoading: listLoading,
    isError: listError,
    refetch: refetchListData,
  } = useFetchList<StartupResponse>(`${STARTUP_ENDPOINTS.LIST}`, {
    queryParams,
    queryName: LIST_STARTUP_QUERY_NAME,
  })

  const handleSearch = debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setQueryParams({
        ...queryParams,
        name: event.target.value!,
      })
    },
    500,
  )

  const handleFilterByLocation = (opt: any) => {
    const currentLocationIds = queryParams.location_id
      ? queryParams.location_id.split(",")
      : []

    if (!currentLocationIds.includes(opt.value)) {
      const newLocationIds = [...currentLocationIds, opt.value]
      const newLocationIdString = newLocationIds.join(",")

      setQueryParams({
        ...queryParams,
        location_id: newLocationIdString,
        location: [...queryParams.location, opt],
      })
    }
  }

  const handleRemoveLocation = (opt: any) => {
    const currentLocationIds = queryParams.location_id.split(",")
    const updatedLocationIds = currentLocationIds.filter(
      (id: string) => id !== opt.value,
    )
    const newLocationIdString = updatedLocationIds.join(",")

    setQueryParams({
      ...queryParams,
      location_id: newLocationIdString,
      location: queryParams.location.filter(
        (location: any) => location.value !== opt.value,
      ),
    })
  }

  const handleFilterByStage = (opt: any) => {
    const currentStageIds = queryParams.stage_id
      ? queryParams.stage_id.split(",")
      : []

    if (!currentStageIds.includes(opt.value)) {
      const newStageIds = [...currentStageIds, opt.value]
      const newStageIdString = newStageIds.join(",")

      setQueryParams({
        ...queryParams,
        stage_id: newStageIdString,
        stage: [...queryParams.stage, opt],
      })
    }
  }

  const handleRemoveStage = (opt: any) => {
    const currentStageIds = queryParams.stage_id.split(",")
    const updatedStageIds = currentStageIds.filter(
      (id: string) => id !== opt.value,
    )
    const newStageIdString = updatedStageIds.join(",")

    setQueryParams({
      ...queryParams,
      stage_id: newStageIdString,
      stage: queryParams.stage.filter(
        (stage: any) => stage.value !== opt.value,
      ),
    })
  }
  const handleFilterBySolution = (opt: any) => {
    const currentSolutionIds = queryParams.solution_id
      ? queryParams.solution_id.split(",")
      : []

    if (!currentSolutionIds.includes(opt.value)) {
      const newSolutionIds = [...currentSolutionIds, opt.value]
      const newSolutionIdString = newSolutionIds.join(",")

      setQueryParams({
        ...queryParams,
        solution_id: newSolutionIdString,
        solution: [...queryParams.solution, opt],
      })
    }
  }

  const handleRemoveSolution = (opt: any) => {
    const currentSolutionIds = queryParams.solution_id.split(",")
    const updatedSolutionIds = currentSolutionIds.filter(
      (id: string) => id !== opt.value,
    )
    const newSolutionIdString = updatedSolutionIds.join(",")

    setQueryParams({
      ...queryParams,
      solution_id: newSolutionIdString,
      solution: queryParams.solution.filter(
        (solution: any) => solution.value !== opt.value,
      ),
    })
  }

  useEffect(() => {
    refetchListData()
  }, [queryParams])

  // REFERENCE DATA
  const { locationData, stageData, solutionData } = useFounderReference()

  return {
    startupData: {
      listData: listData?.list,
      listLoading,
      listError,
    },
    queryStartup: {
      handleSearch,
      handleFilterByLocation,
      handleRemoveLocation,

      handleFilterBySolution,
      handleRemoveSolution,

      handleFilterByStage,
      handleRemoveStage,

      location: queryParams?.location,
      stage_name: queryParams?.stage_name,
      solution_name: queryParams?.solution_name,

      locationSelected: queryParams?.location?.filter(
        (id: string) => id !== "",
      ),
      stageSelected: queryParams?.stage?.filter((id: string) => id !== ""),
      solutionSelected: queryParams?.solution?.filter(
        (id: string) => id !== "",
      ),
    },
    referenceData: {
      locationData,
      stageData,
      solutionData,
    },
  }
}

export const useDetailSolution = (id: string) => {
  const [showMoreDesc, setShowMoreDesc] = useState<boolean>(false)

  const {
    data: detailStartup,
    isLoading: detailLoadingStartup,
    isError: detailErrorStartup,
  } = useFetchDetail<StartupResponse>(`${STARTUP_ENDPOINTS.DETAIL(id)}`, {
    queryName: DETAIL_STARTUP_QUERY_NAME,
  })

  return {
    detail: {
      detailStartup,
      detailLoadingStartup,
      detailErrorStartup,
    },
    layout: {
      showMoreDesc,
      setShowMoreDesc,
    },
  }
}
