export const useCalculateProgress = (detailJobs, userLevel) => {
  const matchedData =
    userLevel && !userLevel.error
      ? userLevel?.data?.map((item1) => {
          const item2 = detailJobs?.green_skills?.find(
            (item) => item?.id === item1?.green_skill_id,
          )

          return {
            proficiency: item1?.level_data?.proficiency,
            level: item2 ? item2?.level : null,
          }
        })
      : []

  const persentasePerIndeks = []

  matchedData?.forEach((obj) => {
    if (obj.level) {
      const persentase = (obj.proficiency / obj.level) * 100
      persentasePerIndeks.push(persentase)
    }
  })

  let totalLevel = 0
  let levelCompleted = 0

  if (userLevel?.data?.length > 0) {
    matchedData?.forEach((obj) => {
      totalLevel += obj.level || 0
      levelCompleted += obj.proficiency || 0
    })
  } else {
    const item2 = detailJobs?.green_skills?.map((item) => item?.level)
    if (item2) {
      totalLevel = item2.reduce((total, current) => total + current, 0)
    }
  }

  const overalPresentase =
    totalLevel > 0 ? (levelCompleted / totalLevel) * 100 : 0

  return {
    persentasePerIndeks,
    overalPresentase,
    matchedData,
    totalLevel,
    levelCompleted,
  }
}
