import React from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  HomeOutlined,
  ClockCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons"
import { Breadcrumbs } from "../../../../common/components"
import { JobBoard } from "../../types/jobBoard"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"

interface Props {
  data: JobBoard | undefined
}
export const DetailHeader: React.FC<Props> = ({ data }) => {
  const navigate = useNavigate()
  dayjs.extend(relativeTime)
  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      path: "/job/board",
      title: "Jobs Galaxy",
    },
    {
      path: "/job/board",
      title: "Job Board",
    },
    {
      title: <div className=" capitalize">{data?.job?.name || "Unknown"}</div>,
    },
  ]
  return (
    <div className="bg-white py-4 px-6">
      <Breadcrumbs items={items} />

      <div className="my-3 flex items-center gap-5">
        <ArrowLeftOutlined
          className=" cursor-pointer"
          onClick={() => navigate("/job/board")}
        />
        <h1 className="font-bold capitalize">{data?.job?.name || "unknown"}</h1>
      </div>
      <div className=" flex gap-5 mt-5 text-darkGrey ">
        <Link
          className="text-secondary hover:text-secondary capitalize"
          to={`/job/companies/${data?.company?.id}`}
        >
          {data?.company?.name || "unknown"}
        </Link>
        <div className=" flex gap-2 text-xs items-center">
          <ClockCircleOutlined />
          <div>{dayjs(data?.job?.published_at).fromNow()}</div>
        </div>
      </div>
    </div>
  )
}
