import React, { useContext } from "react"
import { CartContext } from "../../../common/layout/PageLayout"
import { Checkbox, Empty, Skeleton } from "antd"
import { Link } from "react-router-dom"
import { translatelevelName } from "../helper"
import { Moon } from "../../../assets/images"
import { formatNumber } from "../../../utils/formatNumber"

export const CartList = ({ handleChange }) => {
  const { carts, isLoading } = useContext(CartContext)
  return (
    <table className=" w-full" style={{ borderSpacing: "0px" }}>
      <thead className=" bg-[#FFEFA0] ">
        <tr>
          <td className="w-10 p-3"></td>
          <td className=" p-3 font-semibold">Skill Name</td>
          <td className=" p-3 w-48 font-semibold">Skill Level</td>
          <td className=" p-3 w-20 font-semibold">Price</td>
        </tr>
      </thead>
      <tbody className="bg-white">
        {isLoading ? (
          <tr>
            <td className="p-5" colSpan={4}>
              <Skeleton active />
            </td>
          </tr>
        ) : carts?.data?.data?.products?.length > 0 ? (
          carts?.data?.data?.products?.map((item, i) => (
            <tr key={i}>
              <td className="p-3 w-10">
                <Checkbox onChange={() => handleChange(item)} />
              </td>
              <td className="p-3 flex gap-3">
                <div className=" bg-[#FFEFA0] p-2 rounded-full w-10 h-10 flex">
                  <img
                    src={item.image_url}
                    alt="level"
                    className=" object-contain w-full h-full"
                  />
                </div>
                <p className=" w-72 truncate">{item.product_name}</p>
              </td>
              <td className="p-3 w-48">
                {translatelevelName(item.proficient)}
              </td>
              <td className="p-3  w-40">
                <span className=" uppercase">
                  {carts?.data?.data?.currency?.code || "sgd"}
                </span>{" "}
                <span>
                  {formatNumber(
                    item?.price * carts?.data?.data?.currency?.exchange,
                  )}
                </span>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td className="p-5" colSpan={4}>
              <Empty
                image={Moon}
                imageStyle={{ height: 40 }}
                description={
                  <Link
                    to="/skill/green-skills"
                    className="text-[#009ACE] hover:text-[#6bc9e8]"
                  >
                    Explore skills to learn
                  </Link>
                }
              />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
