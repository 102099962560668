export enum WorkshopMode {
  ON_DEMAND = "on-demand",
  LIVE_STREAM = "live-stream",
  PRE_RECORDED = "pre-recorded",
}

type SocialURL = {
  facebook_url: string
  instagram_url: string
  linkedin_url: string
  twitter_url: string
  website_url: string
}

type School = {
  _id: string
  admin_signature: string
  contract_expired_at: string | null
  courses: any | null
  created_at: string | null
  description: string
  is_signature_exist: boolean
  location: string
  logo: string
  name: string
  owner_email: string
  owner_id: string | null
  sharing_profit: number
  social_url: SocialURL
  status: string
  stripe_account_id: string
  total_course: number
  updated_at: string | null
}

type Trainer = {
  avatar: string
  email: string
  id: string
  name: string
}

type Media = {
  status: string
  dash: string
  encoding_id: string
  blob_path: string
  duration: number
}

type User = {
  id: string
  name: string
  avatar: string
  location: string
}

export type Discussion = {
  id: string
  participant_id: string
  user: User
  content: string
  created_at: string
  updated_at: string | null
}

type Document = {
  url: string
  name: string
  created_at: string
  file_name: string
}

type Participant = {
  id: string
  participant_id: string
  user: User
  created_at: string
  updated_at: string | null
}

export type Workshop = {
  cover_image: string
  created_at: string
  description: string
  discussions: Discussion[] | null
  documents: Document[] | null
  id: string
  is_paid: boolean
  is_registered: boolean
  is_replayable: boolean
  livestream_date: string
  material: string
  media: Media | null
  mode: WorkshopMode
  participants: Participant[] | null
  price: number
  published_at: string
  release_date: string | null
  replay_price: number
  school: School
  status: string
  stripe_product_id: string
  stripe_product_price_id: string
  stripe_product_reply_price_id: string
  title: string
  total_discussions: number
  total_participants: number
  trainer: Trainer
  trainer_id: string
  type: string
  updated_at: string
  currency: {
    code: string
    created_at: string
    exchange: number
    id: string
    is_active: boolean
    is_deletable: boolean
    is_tax: boolean
    name: string
    tax: number
    updated_at: string
    logo?: string
  }
}

export type AddWorkshopToCartPayload = {
  image_url: string
  price: number
  price_id_stripe: string
  product_id: string
  product_id_stripe: string
  product_name: string
  product_owner_id: string
  product_owner_id_stripe: string
  sharing_profit: number
  proficient: number
  user_id: string
  is_workshop: boolean
}
