import React, { useContext } from "react"
import { Shortcut } from "./Shortcut"
import { MySkills } from "./MySkills"
import SustainabilityScore from "../../../missionControl/components/SustainabilityScore"
import {
  ITourContext,
  TourContext,
} from "../../../../common/components/tour/TourProvider"
import ThisWeeksDiscussionWidget from "./ThisWeeksDiscussionWidget"

export const CommunityRightSidebar = () => {
  const {
    refs: { refCommunityShortcuts, refSustainabilityScore },
  } = useContext(TourContext) as ITourContext
  return (
    <div className="grid gap-5 sticky top-10 max-h-[80vh] overflow-y-auto scrollbar-hide">
      <ThisWeeksDiscussionWidget />
      <Shortcut ref={refCommunityShortcuts} />
      <MySkills />
      <SustainabilityScore ref={refSustainabilityScore} />
    </div>
  )
}
