import React from "react"
import { Moon } from "../../../../assets/images"
import { Link } from "react-router-dom"

interface Props {
  data?: { id: string; name: string }[]
}

export const DetailSimiliarJob: React.FC<Props> = ({ data }) => {
  return (
    <div className=" p-5 rounded-xl shadow-md bg-[#fafafa] w-full">
      <h3 className="capitalize mb-3">Similar Jobs</h3>
      <div>
        {data && data?.length > 0 ? (
          data?.map((item, i) => (
            <div className="flex gap-5 items-baseline" key={i}>
              <div className=" relative">
                <div
                  className="w-1 h-1 rounded-full absolute -top-[6px]"
                  style={{ border: "1px solid gray" }}
                ></div>
              </div>
              <Link
                to={`/job/board/${item.id}`}
                className=" text-secondary hover:text-secondary capitalize"
              >
                {item.name}
              </Link>
            </div>
          ))
        ) : (
          <div className="flex gap-3 items-center">
            <img src={Moon} alt="cookies" />
            <div>{"Nothing to display"}</div>
          </div>
        )}
      </div>
    </div>
  )
}
