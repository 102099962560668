import React from "react"
import { Button, Checkbox, Divider } from "antd"
import { CheckOutlined, FieldTimeOutlined } from "@ant-design/icons"
import { useSingleSkillCart } from "../hooks/useSingleSkillCart"
import { Skill } from "../../skillGalaxy/types/common"
const dummy = [
  {
    checked: false,
    disabled: true,
    proficiency: 1,
    available_for_sale: false,
  },
  {
    checked: false,
    disabled: true,
    proficiency: 2,
    available_for_sale: false,
  },
  {
    checked: false,
    disabled: true,
    proficiency: 3,
    available_for_sale: false,
  },
  {
    checked: false,
    disabled: true,
    proficiency: 4,
    available_for_sale: false,
  },
  {
    checked: false,
    disabled: true,
    proficiency: 5,
    available_for_sale: false,
  },
]

interface Props {
  skill: Skill
  levels?: any
  data: any
}

const PopContent: React.FC<Props> = ({ skill, levels = dummy, data }) => {
  const result = useSingleSkillCart(levels, skill, data)
  return (
    <div className="p-2 w-[250px]">
      <div className="truncate ">{skill?.name}</div>
      {result?.finalLevels?.map((v, i) => (
        <div
          key={i}
          className={`${
            v?.disabled ? "text-[#919191]" : ""
          } my-1 flex justify-between`}
        >
          <div className="flex items-end gap-3">
            <Checkbox
              checked={v.checked}
              disabled={v.disabled}
              onChange={() => result.handleCheck(v.id)}
            />
            <div>{`Level ${v.proficiency}`}</div>
          </div>
          <div>
            {!v.available_for_sale
              ? "Coming soon"
              : handleStatus(v.status_transaction, v.price_per_level)}
          </div>
        </div>
      ))}
      <Divider className="my-2" />
      <div className="flex justify-between mb-2">
        <div>Total:</div>
        <div>{`$${result?.finalLevels
          ?.filter((item) => item.checked && !item.disabled)
          .reduce((a, b) => a + b.price_per_level, 0)}`}</div>
      </div>
      <Button
        className=" w-full"
        type="primary"
        disabled={
          result?.finalLevels?.filter((item) => item.checked && !item.disabled)
            .length === 0
        }
        loading={result.isLoading}
        onClick={result.handlePurchased}
      >
        Add selection to cart
      </Button>
    </div>
  )
}

export default PopContent

const handleStatus = (status: number, price = 0) => {
  switch (status) {
    case 1:
      return (
        <span>
          <CheckOutlined
            className="mr-2 bg-primary bg-opacity-40  text-primary rounded-full p-0.5 w-2 h-2"
            style={{ border: "1px solid #86C769" }}
          />
          Added to cart
        </span>
      )
    case 2:
      return (
        <span>
          <FieldTimeOutlined className="mr-2" />
          Coming next
        </span>
      )
    case 3:
      return (
        <span>
          <CheckOutlined
            className="mr-2 bg-primary bg-opacity-40  text-primary rounded-full p-0.5 w-2 h-2"
            style={{ border: "1px solid #86C769" }}
          />
          Purchased
        </span>
      )
    default:
      return <div>{`$${price.toFixed(2)}`}</div>
  }
}
