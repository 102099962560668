import { LessonDuration } from "../../../../common/components"
import FiveCircleRate from "../../../../common/components/FiveCircleRate"
// eslint-disable-next-line
import { TArticlePostBody } from "../types/commonn"

export const ArticlePostBody: React.FC<{ article: TArticlePostBody }> = ({
  article,
}) => {
  return (
    <div className="mt-4">
      <h3 className="font-semibold">{article.name}</h3>
      <div className="flex gap-4 items-center flex-wrap md:justify-start justify-center">
        <img
          src={article?.image}
          alt={article?.name}
          className="w-20 h-20 object-contain"
        />
        <div className="flex flex-col justify-center">
          <FiveCircleRate
            disabled={true}
            defaultValue={article?.proficiency}
            count={5}
            circleSize={15}
          />
          <div className="footnote">LEVEL {article?.proficiency}</div>

          <div className="mt-1">
            <LessonDuration
              textDuration={article?.text_content_metadata}
              videoDuration={article?.video_content_metadata}
            />
          </div>
        </div>
      </div>
      <div className="mt-4">{article?.description}</div>
    </div>
  )
}
