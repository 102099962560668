/* eslint-disable */

import React from "react"
import { Image, Tooltip } from "antd"
import {
  NotLaunched,
  ImageAstroseed,
  ImageGreenNebula,
  ImageStartMaster,
  ImageTerraformer,
  ImageSolarExplorer,
} from "../../assets/images"
import { RoadmapLevel } from "../../modules/missionControl/hooks/useRoadmapSkills"
import FiveCircleRateTS from "./FiveCircleRateTS"
import { Level } from "../../modules/skillGalaxy/types/common"
import { isNotNullOrUndefined } from "../../utils/util_funcs"

interface CurrentTrajectorySkillProps {
  skillName: string
  skillImage: string
  proficiency: number
  onClick: () => void
  levels: RoadmapLevel[]
}

const LEVEL_NAMES = [
  "Astroseed",
  "Solar Explorer",
  "Terraformer",
  "Green Nebula",
  "Star Master",
  "Not Launched",
]

const IMAGE_NAMES = [
  ImageAstroseed,
  ImageSolarExplorer,
  ImageTerraformer,
  ImageGreenNebula,
  ImageStartMaster,
  NotLaunched,
]

const getIndexForLevelAndCurrentModule = (
  currentLevel: number | undefined,
  currentModule: number | undefined,
) => {
  if (currentLevel === 1 && currentModule !== 0) {
    return 0
  } else if (currentLevel === 2) {
    return 1
  } else if (currentLevel === 3) {
    return 2
  } else if (currentLevel === 4) {
    return 3
  } else if (currentLevel === 5) {
    return 4
  } else {
    return 5
  }
}

function countIsAssessmentPassed(levels: RoadmapLevel[]): number {
  if (!levels) {
    return 0
  }
  return levels?.reduce((count, level) => {
    if (level.user_track?.is_assessment_passed) {
      return count + 1
    }
    return count
  }, 0)
}

export const getInfoOnCurrentLevelOfSkillForUser = (
  levels: RoadmapLevel[] | Level[],
) => {
  let currentLevel: number | undefined = 1
  let currentModule: number | undefined = 0
  const overalUserTrack = levels?.find(
    (item) => !item?.user_track?.is_assessment_passed,
  )

  if (!overalUserTrack) {
    const lastLevel = levels?.findLast(
      (item) => item?.user_track?.is_assessment_passed,
    )
    currentLevel = lastLevel?.user_track?.current_level
      ? lastLevel?.user_track?.current_level + 1
      : lastLevel?.user_track?.current_level

    currentModule = lastLevel?.user_track?.current_module
  } else {
    currentLevel = overalUserTrack?.user_track?.current_level
    currentModule = overalUserTrack?.user_track?.current_module
  }

  const index = getIndexForLevelAndCurrentModule(currentLevel, currentModule)

  const currentName = LEVEL_NAMES[index]
  const image = IMAGE_NAMES[index]

  const progress =
    currentModule && currentModule !== 0 ? (currentModule / 5) * 100 : 0

  return {
    isNothingBought:
      currentLevel === undefined ||
      currentModule === undefined ||
      currentLevel === 0 ||
      currentModule === 0,
    currentName,
    progress,
    image,
    currentLevel,
    passedCount: countIsAssessmentPassed(levels),
  }
}

export const CurrentTrajectorySkill: React.FC<CurrentTrajectorySkillProps> = ({
  skillName,
  skillImage,
  proficiency,
  onClick,
  levels,
}) => {
  const { currentName, currentLevel, image, passedCount } =
    getInfoOnCurrentLevelOfSkillForUser(levels)

  return (
    <div
      className="py-5 px-6 cursor-pointer  bg-white rounded-lg shadow-md hover:shadow-lg flex flex-col justify-between"
      onClick={onClick}
    >
      <div className="flex flex-col items-center justify-center">
        <Image src={skillImage} preview={false} height={91} />
        <div
          className="text-center mt-1"
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {skillName}
        </div>
      </div>

      <Tooltip
        color="#CEDEC6"
        title={
          <div className="flex gap-3 items-center">
            <Image src={image} width={27} preview={false} />

            <div className="flex flex-col ">
              <div className="text-backdrop">Level {currentLevel}</div>
              <div className="font-semibold text-backdrop">{currentName}</div>
            </div>
          </div>
        }
      >
        <div className="flex items-center justify-between">
          <div className="text-sm font-normal text-darkGrey">
            Your Skill Proficiency
          </div>
          <FiveCircleRateTS
            defaultValue={passedCount}
            secondValue={
              isNotNullOrUndefined(
                levels?.[passedCount]?.user_track
                  ? levels?.[passedCount]?.user_track?.current_module
                  : levels[passedCount]?.user_track?.current_module,
              ) &&
              // @ts-ignore
              (levels?.[passedCount]?.user_track
                ? levels?.[passedCount]?.user_track?.current_module
                : levels[passedCount]?.user_track?.current_module) > 0
            }
            disabled
            circleSize={15}
          />
        </div>
      </Tooltip>
    </div>
  )
}
