import axios, {
  AxiosInstance,
  AxiosError,
  InternalAxiosRequestConfig,
} from "axios"
import { loginRequest, scopes } from "../authConfig"
import { msalInstance } from "../index"
import {
  BrowserAuthError,
  InteractionRequiredAuthError,
} from "@azure/msal-browser"

const instance = axios.create({
  baseURL: process.env.REACT_APP_API,
  // baseURL: "http://localhost:3001/api/v1/",
})

const instanceV2 = axios.create({
  baseURL: process.env.REACT_APP_API_V2,
  // baseURL: "http://localhost:3001/api/v2/",
})

let interactionInProgress = false

const handleResponseError = async function (
  error: AxiosError,
  instance: AxiosInstance,
) {
  // const account = msalInstance.getAllAccounts()[0]
  // if (account && error?.response?.status === 401) {
  //   try {
  //     const tokenResponse = await msalInstance.acquireTokenSilent({
  //       scopes: scopes?.read,
  //       account: account,
  //     })
  //     config.headers["Authorization"] = "Bearer " + tokenResponse.idToken
  //   } catch (error) {
  //     if (error instanceof InteractionRequiredAuthError) {
  //       await msalInstance.loginRedirect(loginRequest as any)
  //     } else {
  //       await msalInstance.loginRedirect(loginRequest as any)
  //     }
  //     console.log("My error", error)
  //   }
  // }
  //   if (!interactionInProgress) {
  //     interactionInProgress = true
  //     await msalInstance
  //       .loginRedirect()
  //       .then(() => {
  //         interactionInProgress = false
  //         return Promise.resolve()
  //       })
  //       .catch(() => {
  //         interactionInProgress = false
  //       })
  //   }
  // }
  return Promise.reject(error)
}

instance.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    return handleResponseError(error, instance)
  },
)

instanceV2.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    return handleResponseError(error, instanceV2)
  },
)

const handleRequestConfig = async function (
  config: InternalAxiosRequestConfig,
  instance: AxiosInstance,
) {
  const account = msalInstance.getAllAccounts()[0]

  if (account) {
    try {
      interactionInProgress = true
      const tokenResponse = await msalInstance.acquireTokenSilent({
        scopes: scopes?.read,
        account: account,
      })
      config.headers["Authorization"] = "Bearer " + tokenResponse.idToken
    } catch (error) {
      // If acquireTokenSilent fails due to token being expired, user needs to be redirected to login again
      if (
        error instanceof InteractionRequiredAuthError ||
        error instanceof BrowserAuthError
      ) {
        if (!interactionInProgress) {
          // redirect("/login")
          await msalInstance.logoutRedirect()
        }
      }
      console.log("Error with acquiring access token", error)
    } finally {
      interactionInProgress = false
    }
  }
  return config
}
instance.interceptors.request.use(
  async (config) => {
    const account = msalInstance.getAllAccounts()[0]
    const currentTimeStamp = Math.floor(Date.now() / 1000)
    const tokenExpiry = account?.idTokenClaims?.exp as number
    const hasTokenExpired = tokenExpiry < currentTimeStamp

    if (!!config.headers["Authorization"] && !hasTokenExpired) {
      return config
    }
    if (hasTokenExpired) console.log("Token has expired")
    return handleRequestConfig(config, instance)
  },
  (error) => {
    return Promise.reject(error)
  },
)

instanceV2.interceptors.request.use(
  async (config) => {
    const account = msalInstance.getAllAccounts()[0]
    const currentTimeStamp = Math.floor(Date.now() / 1000)
    const tokenExpiry = account?.idTokenClaims?.exp as number
    const hasTokenExpired = tokenExpiry < currentTimeStamp

    if (!!config.headers["Authorization"] && !hasTokenExpired) {
      return config
    }
    return handleRequestConfig(config, instanceV2)
  },
  (error) => {
    console.error(error)
    return Promise.reject(error)
  },
)

export { instance, instanceV2 }

export default instance
