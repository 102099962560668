import React, { useEffect, useState } from "react"
import { PaymentMethod as PaymentMethodTypes } from "../../hooks/billing/types"
import { Dropdown, Input, Modal, Select, Tag, message } from "antd"
import dayjs from "dayjs"
import { PlusOutlined } from "@ant-design/icons"
import { Controller, useForm } from "react-hook-form"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { AxiosResponse } from "axios"

interface Props {
  data?: PaymentMethodTypes[]
  defaultPayment?: string
  handleDefault: (id_payment: string) => Promise<void>
  update: {
    handleOpenUpdate: (data: PaymentMethodTypes) => void
    handleCloseUpdate: () => void
    stateUpdate: {
      data?: PaymentMethodTypes | undefined
      isOpen: boolean
    }
    handleUpdate: (val: any) => Promise<void>
    isLoading: boolean
  }
  remove: {
    handleDelete: (val: any) => Promise<void>
    isLoading: boolean
  }
  countries: {
    data:
      | {
          id: string
          name: string
          code: string
        }[]
      | undefined
    isLoading: boolean
  }
  add: {
    setStateAdd: React.Dispatch<
      React.SetStateAction<{
        isOpen: boolean
      }>
    >
    stateAdd: {
      isOpen: boolean
    }
    handleAdd: (
      val: any,
      callbackSuccess: () => void,
      callbackError: (error: any) => void,
    ) => Promise<AxiosResponse<any, any> | undefined>
  }
}

export const PaymentMethod: React.FC<Props> = ({
  data,
  defaultPayment,
  handleDefault,
  update,
  countries,
  remove,
  add,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm()

  const {
    handleSubmit: handleSubmitAdd,
    control: controlAdd,
    reset: resetAdd,
    formState: { errors: errorsAdd },
  } = useForm()

  const elements = useElements()
  const stripe = useStripe()

  const [errCard, setErrCard] = useState(false)
  const [loadingAdd, setLoadingAdd] = useState(false)

  const addAction = async (val: any) => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }
    setLoadingAdd(true)
    message.open({ key: "add", content: "Please wait...", type: "loading" })

    const result = await stripe.createPaymentMethod({
      //`Elements` instance that was used to create the Payment Element
      elements,
      params: {
        billing_details: {
          name: val?.name,
          email: val?.email,
          address: { country: val.country?.value },
        },
      },
    })
    if (result.error) {
      setLoadingAdd(false)
      message.open({
        key: "add",
        content: result.error.message,
        type: "error",
      })
      // Show error to your customer (for example, payment details incomplete)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.

      add.handleAdd(
        result.paymentMethod?.id,
        function () {
          add.setStateAdd((prev) => ({ ...prev, isOpen: false }))
          elements.getElement("card")?.clear()
          setLoadingAdd(false)
          message.open({ key: "add", content: "success", type: "success" })

          setErrCard(false)
          resetAdd()
        },
        function (error: any) {
          add.setStateAdd((prev) => ({ ...prev, isOpen: false }))
          elements.getElement("card")?.clear()
          setLoadingAdd(false)
          message.open({ key: "add", content: error, type: "error" })

          setErrCard(false)
          resetAdd()
        },
      )
    }
  }

  useEffect(() => {
    if (update.stateUpdate.data && update.stateUpdate.isOpen) {
      setValue("name", update.stateUpdate.data?.billing_details?.name)
      setValue("email", update.stateUpdate.data?.billing_details?.email)
      setValue(
        "country",
        countries.data
          ?.map((item) => ({ value: item.code, label: item.name }))
          .find(
            (item) =>
              item.value ===
              update.stateUpdate.data?.billing_details?.address?.country,
          ),
      )
    }
  }, [update.stateUpdate])
  return (
    <div className="rounded-xl shadow-md bg-white p-5 w-full">
      <div className="">
        <h3>Payment Methods</h3>
        <div className="mt-2">
          {data && data.length > 0
            ? data?.map((item, index) => {
                return (
                  <div key={index} className="flex justify-between  mb-2">
                    <div className="flex gap-5">
                      <div className="w-20 truncate capitalize">
                        {item.card?.brand}
                      </div>
                      <div className="">{"*****" + item.card?.last4}</div>
                      <div className="w-10">
                        {item.id === defaultPayment && (
                          <Tag color="blue">Default</Tag>
                        )}
                      </div>
                    </div>
                    <div className="flex gap-5">
                      <div>
                        {"Expires " +
                          dayjs(
                            `01-${item.card.exp_month}-${item.card.exp_year}`,
                          ).format("MMM YYYY")}
                      </div>
                      <Dropdown
                        menu={{
                          items: [
                            {
                              key: "2",
                              label: (
                                <div
                                  className=""
                                  onClick={() => update.handleOpenUpdate(item)}
                                >
                                  Edit
                                </div>
                              ),
                            },
                            {
                              key: "3",
                              label: (
                                <div
                                  className=""
                                  onClick={() => {
                                    if (item.id !== defaultPayment)
                                      handleDefault(item.id)
                                  }}
                                >
                                  Set as default
                                </div>
                              ),
                              disabled: item.id === defaultPayment,
                            },
                            {
                              key: "1",
                              label: (
                                <div
                                  className={`${
                                    data.length > 1 &&
                                    item.id === defaultPayment
                                      ? ""
                                      : "text-danger"
                                  }`}
                                  onClick={() => {
                                    if (
                                      data.length === 1 ||
                                      item.id !== defaultPayment
                                    ) {
                                      remove.handleDelete(item.id)
                                    }
                                  }}
                                >
                                  Delete
                                </div>
                              ),
                              disabled:
                                data.length > 1 && item.id === defaultPayment,
                            },
                          ],
                          selectable: true,
                        }}
                        arrow={{ pointAtCenter: true }}
                        placement="bottomRight"
                      >
                        <strong className="-mt-1 cursor-pointer">...</strong>
                      </Dropdown>
                    </div>
                  </div>
                )
              })
            : "No payment method"}
        </div>
      </div>
      <div
        className=" mt-5 cursor-pointer w-fit"
        onClick={() => add.setStateAdd((prev) => ({ ...prev, isOpen: true }))}
      >
        {" "}
        <PlusOutlined /> Add payment method
      </div>
      <Modal
        title="Update payment method"
        centered
        open={update.stateUpdate.isOpen && !!update.stateUpdate.data}
        onCancel={update.handleCloseUpdate}
        onOk={handleSubmit(update.handleUpdate)}
        okButtonProps={{ disabled: update.isLoading }}
      >
        <div>
          <div className="flex gap-4 items-center py-1">
            <label htmlFor="name" className="w-16">
              Name
            </label>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  id="name"
                  status={errors?.name ? "error" : ""}
                  className="my-1 font-default rounded"
                  placeholder="john doe"
                />
              )}
            />
          </div>
          <div className="flex gap-4 items-center py-1">
            <label htmlFor="email" className="w-16">
              Email
            </label>
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  id="email"
                  status={errors?.email ? "error" : ""}
                  className="my-1 font-default rounded"
                  placeholder="johndoe@example.com"
                />
              )}
            />
          </div>
          <div className="flex gap-4 items-center py-1">
            <label htmlFor="country" className="w-16">
              Country
            </label>
            <Controller
              name="country"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  id="country"
                  loading={countries.isLoading}
                  className="my-1 font-default rounded job-history w-full"
                  placeholder="select country"
                  showSearch
                  status={errors?.country ? "error" : ""}
                  options={countries?.data?.map((val) => ({
                    value: val.code,
                    label: val.name,
                  }))}
                  labelInValue={true}
                  filterOption={(input, option) => {
                    if (option && option.label) {
                      return (
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      )
                    }
                    return false
                  }}
                />
              )}
            />
          </div>
        </div>
      </Modal>
      <Modal
        title="Add payment method"
        centered
        open={add.stateAdd.isOpen}
        onCancel={() => add.setStateAdd((prev) => ({ ...prev, isOpen: false }))}
        onOk={handleSubmitAdd(addAction)}
        okButtonProps={{ disabled: loadingAdd }}
      >
        <div>
          <div className="flex gap-4 items-center py-1 ">
            <label htmlFor="card" className="w-16">
              Card
            </label>
            <div
              className="w-full p-2 rounded"
              style={
                !errCard
                  ? { border: "1px solid #d9d9d9" }
                  : { border: "1px solid #E65E61" }
              }
            >
              <CardElement
                options={{ hidePostalCode: true }}
                onChange={(a) => {
                  if (!a.empty) {
                    setErrCard(!a.complete)
                  }
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center py-1">
            <label htmlFor="name" className="w-16">
              Name
            </label>
            <Controller
              name="name"
              control={controlAdd}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  id="name"
                  status={errorsAdd?.name ? "error" : ""}
                  className="my-1 font-default rounded"
                  placeholder="john doe"
                />
              )}
            />
          </div>
          <div className="flex gap-4 items-center py-1">
            <label htmlFor="email" className="w-16">
              Email
            </label>
            <Controller
              name="email"
              control={controlAdd}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  id="email"
                  status={errorsAdd?.email ? "error" : ""}
                  className="my-1 font-default rounded"
                  placeholder="johndoe@example.com"
                />
              )}
            />
          </div>
          <div className="flex gap-4 items-center py-1">
            <label htmlFor="country" className="w-16">
              Country
            </label>
            <Controller
              name="country"
              control={controlAdd}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  id="country"
                  loading={countries.isLoading}
                  className="my-1 font-default rounded job-history w-full"
                  placeholder="select country"
                  showSearch
                  status={errorsAdd?.country ? "error" : ""}
                  options={countries?.data?.map((val) => ({
                    value: val.code,
                    label: val.name,
                  }))}
                  labelInValue={true}
                  filterOption={(input, option) => {
                    if (option && option.label) {
                      return (
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      )
                    }
                    return false
                  }}
                />
              )}
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}
