import React, { useCallback, useMemo } from "react"
import useExpertList from "../hook/useExpertList"
import { Carousel } from "antd"
import ExpertSectionCard, {
  ExpertSectionCardLoading,
} from "./ExpertSectionCard"

interface ExpertSectionProps {}

const ExpertSection: React.FC<ExpertSectionProps> = () => {
  const { expertResponse } = useExpertList()

  const corauselDisplay = useCallback(
    (displayNumber: number) => {
      const totalSlides = Math.ceil(
        expertResponse?.data?.length / displayNumber,
      )
      return (
        <Carousel autoplay>
          {expertResponse.isLoading ? (
            <div className="p-3 py-10">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {[1, 2, 3, 4, 5, 6].map((i) => (
                  <ExpertSectionCardLoading key={i} />
                ))}
              </div>
            </div>
          ) : (
            [...Array(totalSlides)].map((_, slideIndex) => {
              const startIndex = slideIndex * displayNumber
              return (
                <div className="p-3 py-10" key={slideIndex}>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {expertResponse?.data
                      ?.slice(startIndex, startIndex + displayNumber)
                      .map((expert, idx) => {
                        return (
                          <ExpertSectionCard
                            key={expert?.id ? expert?.id + idx : idx}
                            expert={expert}
                          />
                        )
                      })}
                  </div>
                </div>
              )
            })
          )}
        </Carousel>
      )
    },
    [expertResponse],
  )

  const mdCarousel = useMemo(() => corauselDisplay(6), [expertResponse])
  const smCarousel = useMemo(() => corauselDisplay(2), [expertResponse])

  return (
    <div className="bg-white text-backdrop">
      <div className="m-auto w-[87%] py-20">
        <h2 className="font-semibold text-[40px] text-center leading-tight">
          Expand your Knowledge with Experts
        </h2>
        <div className="mt-10 hidden md:inline">{mdCarousel}</div>
        <div className="mt-10 inline md:hidden">{smCarousel}</div>
      </div>
    </div>
  )
}

export default ExpertSection
