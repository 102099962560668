import { message } from "antd"
import React, { useContext } from "react"
import { AccountContext } from "../../../../common/layout/PageLayout"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { CloseOutlined } from "@ant-design/icons"
import { fetchMentorDetails, updateAdvanceMentorProfile } from "../apis/mentors"
import { Item } from "../../../skillGalaxy/types/common"
import { showError } from "../../../../common/utils/utils"
import { useGreenSkillCategoriessInfinity } from "../../../../hooks/useInfinityGSCategory"
import { InfiniteAutoComplete } from "../../../../common/components/InfiniteAutoComplete"
import useInifiniteMentorshipAreas from "../hooks/useInfiniteMentorshipAreas"

const MyExpertise = () => {
  const updateMentorMutation = useMutation(updateAdvanceMentorProfile)
  const account = useContext(AccountContext)
  const accountData = account?.data?.data
  const mentorDetailsQuery = useQuery(
    ["mentor-details", accountData?.id],
    () => fetchMentorDetails(accountData?.id!),
    {
      enabled: !!accountData?.id,
    },
  )
  const mentorDetailsData = mentorDetailsQuery?.data?.data?.data
  // const areasQuery = useQuery(["mentorship-areas"], () => fetchMentorAreas())
  // const areasData = areasQuery?.data?.data?.data
  // const domainQuery = useGreenSkillCategoriessInfinity()
  // const domainData = domainQuery?.data?.data?.data

  // const filteredAreas = areasData?.filter(
  //   (area) =>
  //     !mentorDetailsData?.mentor_areas.find((item) => item?.id === area?.id),
  // )
  // const filteredDomains = domainData?.filter(
  //   (domain) =>
  //     !mentorDetailsData?.mentor_domains.find(
  //       (item) => item?.id === domain?.id,
  //     ),
  // )

  const queryClient = useQueryClient()

  const createFormData = (
    id: string,
    label_action: "add" | "remove",
    field_name: "mentor_areas" | "mentor_domains",
  ) => {
    const formData = new FormData()
    formData.append("id_field_array", id)
    formData.append("label_action", label_action)
    formData.append("field_name", field_name)
    return formData
  }

  const handleUpdate = (
    id: string,
    action: "add" | "remove",
    field: "mentor_areas" | "mentor_domains",
    messageKey: string,
    successMessage: string,
    errorMessage: string,
  ) => {
    message.open({
      type: "loading",
      content: `Updating ${field.replace("_", " ")}...`,
      key: messageKey,
    })

    const formData = createFormData(id, action, field)

    updateMentorMutation.mutate(formData, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["mentor-details", accountData?.id])
        message.success({
          content: successMessage,
          key: messageKey,
        })
      },
      onError: () => {
        showError({
          key: messageKey,
          message: errorMessage,
        })
      },
    })
  }

  const handleAddDomain = (option: Item) => {
    if (
      mentorDetailsData?.mentor_domains?.find((item) => item?.id === option?.id)
    ) {
      message.warning("Domain already added")
      return
    }
    handleUpdate(
      option?.id,
      "add",
      "mentor_domains",
      "update-mentor-domains",
      "Domains updated successfully",
      "Failed to update domains",
    )
  }

  const handleRemoveDomain = (id: string) =>
    handleUpdate(
      id,
      "remove",
      "mentor_domains",
      "update-mentor-domains",
      "Domains updated successfully",
      "Failed to update domains",
    )
  const handleAddArea = (option: Item) => {
    if (
      mentorDetailsData?.mentor_areas?.find((item) => item?.id === option?.id)
    ) {
      message.warning("Area already added")
      return
    }
    handleUpdate(
      option?.id,
      "add",
      "mentor_areas",
      "update-mentor-areas",
      "Areas updated successfully",
      "Failed to update areas",
    )
  }
  const handleRemoveArea = (id: string) =>
    handleUpdate(
      id,
      "remove",
      "mentor_areas",
      "update-mentor-areas",
      "Areas updated successfully",
      "Failed to update areas",
    )

  return (
    <div>
      <div className="bg-white rounded-[10px] ring-1 ring-grey px-[17px] py-[30px] mt-4">
        <div className="flex justify-between items-center">
          <div className="uppercase text-[#966F02] tracking-widest">
            Mentorship Areas
          </div>
          <InfiniteAutoComplete
            useDataHook={useInifiniteMentorshipAreas}
            // disabled={field.value.length > 2 ? true : false}
            placeholder="Select Areas"
            // className="!w-full"
            selectedValue={undefined}
            onSelect={(value, option) => {
              handleAddArea(option as Item)
            }}
          />
        </div>
        <div className="mt-8  flex items-center gap-3">
          {mentorDetailsData?.mentor_areas?.map((item, index) => (
            <div
              className="rounded-lg  flex bg-backdrop text-white items-center gap-4 w-auto text-xs px-[15px] py-2"
              key={index}
            >
              <p className="m-0 line-clamp-1">{item?.name}</p>
              <CloseOutlined
                className="text-xs cursor-pointer"
                onClick={() => handleRemoveArea(item?.id)}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="bg-white rounded-[10px] ring-1 ring-grey px-[17px] py-[30px] mt-6">
        <div className="flex justify-between items-center">
          <div className="uppercase text-[#966F02] tracking-widest">
            Domain Expertise
          </div>
          {/* <Select
            options={filteredDomains}
            value={undefined}
            loading={
              mentorDetailsQuery?.isLoading ||
              domainQuery?.isLoading ||
              updateMentorMutation?.isLoading
            }
            disabled={updateMentorMutation?.isLoading}
            placeholder="Select Domains"
            fieldNames={{ label: "name", value: "id" }}
            onChange={(value, option) => handleAddDomain(option as Item)}
          /> */}
          <InfiniteAutoComplete
            useDataHook={useGreenSkillCategoriessInfinity}
            // disabled={field.value.length > 2 ? true : false}
            placeholder="Select Domain"
            // className="!w-full"
            selectedValue={undefined}
            onSelect={(value, option) => {
              handleAddDomain(option as Item)
            }}
          />
        </div>
        <div className="mt-8  flex items-center gap-3 flex-wrap">
          {mentorDetailsData?.mentor_domains?.map((item, index) => (
            <div
              className="rounded-lg  flex ring-1  ring-dirtyBlue  bg-[#F3F3F6]  items-center gap-4 w-auto text-xs px-[15px] py-2"
              key={index}
            >
              <p className="m-0 line-clamp-1 flex-grow">{item?.name}</p>
              <CloseOutlined
                className="text-xs cursor-pointer"
                onClick={() => handleRemoveDomain(item?.id)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default MyExpertise
