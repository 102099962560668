import { Button, Input } from "antd"
import React from "react"
import { Controller, useForm } from "react-hook-form"

interface AddCommentProps {
  onSubmit: (data: any) => Promise<void>
  isSubmissionLoading: boolean
  submittedSuccess: boolean
}

export const AddComment: React.FC<AddCommentProps> = ({
  onSubmit,
  isSubmissionLoading,
  submittedSuccess,
}) => {
  const {
    control,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm()

  React.useEffect(() => {
    if (submittedSuccess) resetField("body")
  }, [submittedSuccess])

  return (
    <div className="mt-6">
      <div className="flex items-center gap-4 ">
        {/* <Avatar
          src={
            account?.data?.data?.profile_picture ?? account?.data?.data?.avatar
          }
          className="object-cover h-full"
        /> */}
        <div className="flex flex-col flex-grow ">
          <Controller
            name="body"
            control={control}
            rules={{
              required: "Comment is required!",
            }}
            render={({ field }) => (
              <Input.TextArea
                {...field}
                placeholder="Type your comment here..."
                style={{
                  resize: "none",
                  height: 40,
                }}
                styles={{
                  textarea: {
                    backgroundColor: "#F6F6F6",
                  },
                }}
                className="flex-grow text-base border-none rounded-lg resize-none ring-1 ring-gray-300 focus:border-none "
              />
            )}
          />
        </div>
        <Button
          onClick={handleSubmit(onSubmit)}
          loading={isSubmissionLoading}
          className={`bg-primary text-white rounded-lg !min-h-[40px] h-full text-base px-6 duration-300 transition-all `}
        >
          Comment
        </Button>
      </div>
      {errors.body && (
        <span className="pl-2 text-sm text-danger">
          {errors?.body?.message as string}
        </span>
      )}
    </div>
  )
}
