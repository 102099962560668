import { Button, Input, Modal } from "antd"
import React from "react"
import { storeModalBecomeMentor } from "../stores/storeModalBecomeMentor"
import { Controller } from "react-hook-form"
import { useGreenSkillCategoriessInfinity } from "../../../../../hooks/useInfinityGSCategory"
import { useAddMentor } from "../hooks/useMentor"
import TextArea from "antd/es/input/TextArea"
import { useCountriesInfinity } from "../../../../../hooks/useCountries"
import { CloseOutlined } from "@ant-design/icons"
import { InfiniteDomainSelect } from "./InfiniteDomainSelect"
import {
  useSelectedAreaItemsStore,
  useSelectedDomainItemsStore,
} from "../stores/storeSelectedItem"
import { useInfinityMentorShipArea } from "../../../../../hooks/useInfinityMentorShipArea"
import { InfiniteAreaSelect } from "./InfiniteAreaSelect"
import { InfiniteLocationSelect } from "./InfiniteLocationSelect"

interface ModalBecomeMentorProps {}

const ModalBecomeMentor: React.FC<ModalBecomeMentorProps> = () => {
  const { isOpen, openModal, closeModal } = storeModalBecomeMentor()
  const { addAction } = useAddMentor()

  const selectedDomainItems = useSelectedDomainItemsStore(
    (state) => state.selectedDomainItems,
  )
  const selectedAreaItems = useSelectedAreaItemsStore(
    (state) => state.selectedAreaItems,
  )

  return (
    <div>
      <Modal
        title={
          <div className="flex items-center justify-center">
            <h1>Mentor Application</h1>
          </div>
        }
        centered
        open={isOpen}
        onOk={() => openModal()}
        onCancel={() => closeModal()}
        footer={
          <div className="flex justify-end mt-10">
            <Button
              className="rounded-lg"
              block
              onClick={addAction?.handleSubmit(
                addAction?.handleCreateRequestMentor,
              )}
              type="primary"
              // disabled={
              //   addAction?.disableButton || false
              // }
            >
              Apply to be a Mentor
            </Button>
          </div>
        }
      >
        <div className="space-y-6 mt-9 ">
          <Controller
            name="linkedin_link"
            control={addAction?.control}
            rules={{ required: "LinkedIn Profile is required" }}
            render={({ field }) => (
              <Input
                {...field}
                id="linkedin_link"
                className="font-default"
                placeholder="LinkedIn Profile"
              />
            )}
          />
          {addAction?.errors.linkedin_link && (
            <span className="text-xs text-red-400">
              {addAction?.errors.linkedin_link.message}
            </span>
          )}

          <Controller
            name="monthly_availability"
            control={addAction?.control}
            rules={{ required: "Monthly Availability is required" }}
            render={({ field }) => (
              <Input
                {...field}
                id="monthly_availability"
                className="font-default"
                placeholder="Monthly Availability (hours)"
                type="number"
                min={0}
              />
            )}
          />
          {addAction?.errors.monthly_availability && (
            <span className="text-xs text-red-400">
              {addAction?.errors.monthly_availability.message}
            </span>
          )}
          <div>
            <Controller
              name="domain_expertises_id"
              rules={{ required: "Domain Expertise is required" }}
              control={addAction?.control}
              render={({ field }) => {
                return (
                  <InfiniteDomainSelect
                    {...field}
                    useDataHook={useGreenSkillCategoriessInfinity}
                    placeholder="Domain Expertise"
                    className="!w-full"
                  />
                )
              }}
            />
            {addAction?.errors.domain_expertises_id && (
              <span className="text-xs text-red-400">
                {addAction?.errors.domain_expertises_id.message}
              </span>
            )}

            {selectedDomainItems?.length > 0 && (
              <div className="flex gap-2 mt-3">
                <div className="flex flex-wrap items-center gap-2">
                  {selectedDomainItems?.map((domain: any) => (
                    <div
                      id={domain}
                      key={domain}
                      className="flex items-center px-2 rounded shadow-md bg-secondaryYellow gap-x-1"
                      style={{ border: "1px solid #966F02" }}
                    >
                      {domain.name}
                      <CloseOutlined
                        onClick={() => addAction?.handleRemoveDomain(domain.id)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div>
            <Controller
              name="mentorship_areas_id"
              control={addAction?.control}
              rules={{ required: "Mentorship Area is required" }}
              render={({ field }) => {
                return (
                  <InfiniteAreaSelect
                    {...field}
                    useDataHook={useInfinityMentorShipArea}
                    placeholder="Mentorship Area"
                    className="!w-full"
                  />
                )
              }}
            />
            {addAction?.errors.mentorship_areas_id && (
              <span className="text-xs text-red-400">
                {addAction?.errors.mentorship_areas_id.message}
              </span>
            )}

            {selectedAreaItems?.length > 0 && (
              <div className="flex gap-2 mt-3">
                <div className="flex flex-wrap items-center gap-2">
                  {selectedAreaItems?.map((area: any) => (
                    <div
                      id={area}
                      key={area}
                      className="flex items-center px-2 rounded shadow-md bg-secondaryYellow gap-x-1"
                      style={{ border: "1px solid #966F02" }}
                    >
                      {area.name}
                      <CloseOutlined
                        onClick={() => addAction?.handleRemoveArea(area.id)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div>
            <Controller
              name="location"
              control={addAction?.control}
              rules={{ required: "Location is required" }}
              render={({ field }) => {
                return (
                  <InfiniteLocationSelect
                    {...field}
                    useDataHook={useCountriesInfinity}
                    placeholder="Location"
                    className="!w-full"
                  />
                )
              }}
            />
            {addAction?.errors.location && (
              <span className="text-xs text-red-400">
                {addAction?.errors.location.message}
              </span>
            )}
          </div>

          <Controller
            name="motivation_letter"
            control={addAction?.control}
            // rules={{ required: 'Motivation letter is required' }}
            render={({ field }) => (
              <TextArea
                {...field}
                id="motivation_letter"
                className="font-default"
                placeholder="Motivation to become a mentor"
                autoSize={{ minRows: 3, maxRows: 4 }}
              />
            )}
          />
          {/* {addAction?.errors.motivation_letter && <span className="text-xs text-red-400">{addAction?.errors.motivation_letter.message}</span>} */}
        </div>
      </Modal>
    </div>
  )
}

export default ModalBecomeMentor
