import { Skeleton } from "antd"
import React from "react"

interface CardLoadingProps {
  index: number
}

const CardLoading: React.FC<CardLoadingProps> = ({ index }) => {
  return (
    <div className="py-4 px-6 bg-white rounded-xl shadow-custom-sm" key={index}>
      <div className="uppercase tracking-widest text-[#966F02] text-xs w-2/12">
        <Skeleton.Button
          active={true}
          size={"small"}
          shape={"default"}
          block={true}
        />
      </div>
      <div className="flex items-center w-full mt-5">
        <div className="items-center grid grid-cols-12 w-full ">
          <div className="col-span-12 md:col-span-2 w-10/12 flex flex-wrap gap-1">
            <Skeleton active paragraph={{ rows: 1 }} />
          </div>
          <div className="col-span-9 -mt-1">
            <div>
              <Skeleton active paragraph={{ rows: 1 }} />
            </div>
          </div>
          <div className="col-span-1 flex justify-end ">
            <div className="flex items-center gap-2">
              <Skeleton.Button
                active={true}
                size={"small"}
                shape={"default"}
                block={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardLoading
