import React from "react"
import { format, parseISO } from "date-fns"
import {
  useMentorScheduleStore,
  useStoreMySchedule,
} from "../stores/storeMentorSchedule"
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CheckOutlined,
  CalendarOutlined,
  GlobalOutlined,
} from "@ant-design/icons"
import { Button, Divider, Empty, Image } from "antd"
import TimezoneSelect from "../../../../../common/components/TimezoneSelect"
import { GoogleCalendarLogo } from "../../../../../assets/images"
import { Input } from "antd"
import { useListMentorSchedule } from "../hooks/useMentorSchedule"
import { useParams } from "react-router-dom"
import dayjs from "dayjs"

const { TextArea } = Input

const CalendarAvailabilty: React.FC = () => {
  const { id } = useParams()
  const {
    handleDateClick,
    mentorLoading,
    dayNames,
    handleMonthChange,
    firstDayOfMonth,
    daysInMonth,
    slotsDates,
    year,
    month,
    handleFormatToggle,
  } = useListMentorSchedule(id!)

  const {
    rescheduleReason,
    setRescheduleReason,
    timeReschedule,
    setTimeReschedule,
    from,
  } = useStoreMySchedule()

  const {
    MentorScheduleData,
    selectedDate,
    is24HourFormat,
    currentDate,
    selectedDay,
    selectedTimeSlot,
    setSelectedTimeSlot,
    selectedSchedule,
    timezone,
    setTimezone,
    meetingNotes,
    setMeetingNotes,
    requiredMeetingNote,
  } = useMentorScheduleStore()

  const renderTimeSlots = () => {
    if (!selectedDate || !MentorScheduleData?.slots?.[selectedDate])
      return (
        <div className="flex items-center justify-center pt-10">
          <Empty description="Slots currently not available, please select other date" />
        </div>
      )
    return MentorScheduleData?.slots?.[selectedDate].map((slot, index) => (
      <div
        key={index}
        onClick={() => {
          if (from === "reschedule") {
            setTimeReschedule(slot.time)
          } else {
            setSelectedTimeSlot(slot.time)
          }
        }}
        aria-disabled={mentorLoading}
        className={`p-2 border rounded my-2 cursor-pointer flex items-center justify-between
          ${
            selectedTimeSlot === slot.time || timeReschedule === slot.time
              ? "bg-backdrop text-white"
              : "hover:bg-backdrop hover:text-white"
          }`}
      >
        {format(parseISO(slot.time), is24HourFormat ? "HH:mm" : "hh:mm a")}
        {selectedTimeSlot === slot.time || timeReschedule === slot.time ? (
          <CheckOutlined />
        ) : (
          ""
        )}
      </div>
    ))
  }

  return (
    <div>
      <div className="grid grid-cols-2 gap-9 mt-10">
        <div className="col-span-1">
          <div className="flex justify-between items-center mb-4">
            <Button
              className="rounded-full p-2"
              onClick={() => handleMonthChange("prev")}
              disabled={mentorLoading}
            >
              <CaretLeftOutlined />
            </Button>
            <h5>
              {currentDate.toLocaleString("default", {
                month: "long",
                year: "numeric",
              })}
            </h5>
            <Button
              className="rounded-full p-2"
              onClick={() => handleMonthChange("next")}
              disabled={mentorLoading}
            >
              <CaretRightOutlined />
            </Button>
          </div>
          <div className="grid grid-cols-7 gap-2 bg-white-custom p-2 rounded-md">
            {dayNames.map((dayName) => (
              <div key={dayName} className="text-lg font-bold text-center">
                {dayName}
              </div>
            ))}
            {Array.from({ length: firstDayOfMonth }).map((_, i) => (
              <div
                key={`empty-${i}`}
                className="text-lg flex items-center justify-center px-3 py-2"
              ></div>
            ))}
            {Array.from({ length: daysInMonth }, (_, i) => {
              const day = i + 1
              const date = `${year}-${String(month + 1).padStart(
                2,
                "0",
              )}-${String(day).padStart(2, "0")}`
              const hasSlots = slotsDates.includes(date)

              return (
                <Button
                  key={date}
                  onClick={() => {
                    handleDateClick(date)
                    setTimeReschedule("")
                  }}
                  disabled={mentorLoading}
                  className={`text-lg flex items-center justify-center px-3 py-4 border rounded cursor-pointer hover:bg-backdrop hover:text-white shadow
                ${hasSlots ? "bg-primary" : "bg-white"}
                ${
                  selectedDate === date
                    ? "bg-backdrop text-backdrop font-semibold"
                    : ""
                }`}
                  style={
                    selectedDate === date
                      ? { borderBottom: "5px solid #E65E61" }
                      : { borderBottom: "5px solid #919191" }
                  }
                >
                  {selectedDate === date ? <CheckOutlined /> : day}
                </Button>
              )
            })}
          </div>
        </div>
        <div>
          <div className="w-full col-span-1 h-72 overflow-y-auto pr-3">
            <h5 className="font-semibold mb-2 sticky top-0">
              Available Slots at {selectedDay}
            </h5>
            <div className="flex items-center justify-between w-full sticky top-0 bg-white py-1 z-10 ">
              <div className="w-full flex items-center gap-x-2">
                <TimezoneSelect
                  disabled={mentorLoading}
                  value={timezone}
                  onChange={setTimezone}
                />
              </div>
              <div className="shadow">
                <span
                  onClick={() => handleFormatToggle()}
                  className={`px-3 cursor-pointer py-1 rounded hover:bg-backdrop hover:text-white ${
                    is24HourFormat ? "bg-white" : "bg-white-custom"
                  }`}
                >
                  12h
                </span>
                <span
                  onClick={() => handleFormatToggle()}
                  className={`px-3 cursor-pointer py-1 rounded hover:bg-backdrop hover:text-white ${
                    is24HourFormat ? "bg-white-custom" : "bg-white"
                  }`}
                >
                  24h
                </span>
              </div>
            </div>
            {renderTimeSlots()}
          </div>
        </div>
      </div>

      <Divider className="mb-2" />

      <div>
        {requiredMeetingNote && (
          <span className="text-xs text-red-500">
            Please, provide a note about your mentorship needs to the mentor
          </span>
        )}
        <TextArea
          value={from === "reschedule" ? rescheduleReason : meetingNotes}
          onChange={(e) =>
            from === "reschedule"
              ? setRescheduleReason(e.target.value)
              : setMeetingNotes(e.target.value)
          }
          placeholder={`${
            from === "reschedule"
              ? "Reschedule reason (Optional)"
              : "Provide a bit of context to help mentor prepare for the session"
          }`}
          className={`rounded-lg my-2 ${
            meetingNotes === "" && requiredMeetingNote
              ? "border border-red-500"
              : ""
          }`}
          autoSize={{ minRows: 3, maxRows: 3 }}
          disabled={mentorLoading}
        />
      </div>

      {(selectedSchedule || timeReschedule) && (
        <div className="my-2 py-2 px-3 rounded-lg bg-primary-bg flex items-center gap-x-2 flex-wrap">
          <b>
            {from === "reschedule" ? "Reschedule" : "Request"} Appointment at:
          </b>
          <div className="flex items-center">
            <p>
              <CalendarOutlined />{" "}
              {selectedSchedule ||
                dayjs(timeReschedule).format("dddd, DD MMMM YYYY")}
            </p>
            <Divider type="vertical" />
            <p>
              <GlobalOutlined /> {timezone}
            </p>
            <Divider type="vertical" />
            <p>
              <Image
                preview={false}
                width={18}
                className={"w-2 h-2"}
                src={GoogleCalendarLogo}
                alt={"google-calendar-logo"}
              />{" "}
              Google Calendar
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

export default CalendarAvailabilty
