import React, { useEffect, useRef, memo } from "react"
import G6 from "@antv/g6"
// import { useGreenSkillSectors } from "../hooks/useSkills";
import { useNavigate } from "react-router-dom"

export const DecisionBubblesSkill = memo(({ greenSkillSectors }) => {
  // const greenSkillSectorsQuery = useGreenSkillSectors();
  const containerRef = useRef(null)
  const graphRef = useRef(null)
  const navigate = useNavigate()

  const mapNodeSize = (nodes, propertyName, visualRange) => {
    let minp = 9999999999
    let maxp = -9999999999
    nodes.forEach((node) => {
      minp = node[propertyName] < minp ? node[propertyName] : minp
      maxp = node[propertyName] > maxp ? node[propertyName] : maxp
    })
    const rangepLength = maxp - minp
    const rangevLength = visualRange[1] - visualRange[0]
    const updatedNodes = nodes.map((node) => {
      return {
        ...node,
        size:
          ((node[propertyName] - minp) / rangepLength) * rangevLength +
          visualRange[0],
      }
    })
    return updatedNodes
  }
  const lightColors = [
    "#8FE9FF",
    "#87EAEF",
    "#FFC9E3",
    "#A7C2FF",
    "#FFA1E3",
    "#FFE269",
    "#BFCFEE",
    "#FFA0C5",
    "#D5FF86",
  ]
  const darkColors = [
    "#7DA8FF",
    "#44E6C1",
    "#FF68A7",
    "#7F86FF",
    "#AE6CFF",
    "#FF5A34",
    "#5D7092",
    "#FF6565",
    "#6BFFDE",
  ]
  const uLightColors = [
    "#CFF6FF",
    "#BCFCFF",
    "#FFECF5",
    "#ECFBFF",
    "#EAD9FF",
    "#FFF8DA",
    "#DCE2EE",
    "#FFE7F0",
    "#EEFFCE",
  ]
  const uDarkColors = [
    "#CADBFF",
    "#A9FFEB",
    "#FFC4DD",
    "#CACDFF",
    "#FFD4F2",
    "#FFD3C9",
    "#EBF2FF",
    "#FFCBCB",
    "#CAFFF3",
  ]
  const gColors = []
  const unlightColorMap = new Map()
  lightColors.forEach((lcolor, i) => {
    gColors.push("l(0) 0:" + lcolor + " 1:" + darkColors[i])
    unlightColorMap?.set(
      gColors[i],
      "l(0) 0:" + uLightColors[i] + " 1:" + uDarkColors[i],
    )
  })

  function calculateCircleSize(label) {
    const maxLength = 10 // maximum length of label
    const minLength = 3 // minimum length of label
    const labelLength = label.length
    let circleSize = 0
    if (labelLength > maxLength) {
      circleSize = 100 // maximum circle size
    } else if (labelLength < minLength) {
      circleSize = 50 // minimum circle size
    } else {
      circleSize = (labelLength - minLength) * 10 + 50 // calculate circle size based on label length
    }
    return circleSize
  }

  function truncateLabel(label, maxLength) {
    if (label.length > maxLength) {
      return label.slice(0, maxLength) + "..."
    } else {
      return label
    }
  }

  useEffect(() => {
    const container = containerRef?.current
    const width = container?.scrollWidth
    const height = container?.scrollHeight || 500
    const LIMIT_OVERFLOW_WIDTH = width
    const LIMIT_OVERFLOW_HEIGHT = height
    let showNodes = []
    let showEdges = []
    let curShowNodes = []
    let curShowEdges = []
    let nodes = []
    let edges = []
    let nodeMap = new Map()
    let edgesMap = new Map()
    let curShowNodesMap = new Map()
    let highlighting = false
    let currentFocus

    const layoutCfg = {
      type: "force",
      nodeSize: (d) => {
        return d.size / 2 + 5
      },
      nodeStrength: 2500,
      collideStrength: 0.8,
      alphaDecay: 0.01,
      preventOverlap: true,
      onTick: () => {
        const nodeItems = graphRef?.current?.getNodes()
        const height = graphRef?.current?.get("height")
        const width = graphRef?.current?.get("width")
        const padding = 10
        nodeItems.forEach((item) => {
          const model = item.getModel()
          let x = model.x
          let y = model.y
          const size = model.size

          // Keep nodes within the container bounds
          if (x < padding + size / 2) {
            x = padding + size / 2
          }
          if (x > width - padding - size / 2) {
            x = width - padding - size / 2
          }
          if (y < padding + size / 2) {
            y = padding + size / 2
          }
          if (y > height - padding - size / 2) {
            y = height - padding - size / 2
          }

          graphRef?.current?.updateItem(item, { x, y })
        })
      },
    }

    if (!graphRef.current && container) {
      graphRef.current = new G6.Graph({
        container: container,
        width: width,
        height: height,
        layout: layoutCfg,
        linkCenter: true,
        modes: {
          default: ["drag-canvas"],
        },
        defaultNode: {
          type: "bubble",
          size: 95,
          labelCfg: {
            position: "center",
            style: {
              fontStyle: "bold",
              fontSize: 20,
              fill: "#36505E",
            },
          },
        },
        defaultEdge: {
          color: "#888",
          type: "animate-line", //'animate-line'
        },
      })
    }

    function translate(x, y) {
      let moveX = x
      let moveY = y

      const group = graphRef?.current?.get("group")
      const bbox = group.getBBox()
      const leftTopPoint = graphRef?.current?.getCanvasByPoint(
        bbox.minX,
        bbox.minY,
      )
      const rightBottomPoint = graphRef?.current?.getCanvasByPoint(
        bbox.maxX,
        bbox.maxY,
      )

      if (x < 0 && leftTopPoint.x - x > LIMIT_OVERFLOW_WIDTH) {
        moveX = 0
      }
      if (x > 0 && rightBottomPoint.x - x < width - LIMIT_OVERFLOW_WIDTH) {
        moveX = 0
      }

      if (y < 0 && leftTopPoint.y - y > LIMIT_OVERFLOW_HEIGHT) {
        moveY = 0
      }
      if (y > 0 && rightBottomPoint.y - y < height - LIMIT_OVERFLOW_HEIGHT) {
        moveY = 0
      }
      graphRef?.current?.translate(-moveX, -moveY)
    }
    G6?.registerBehavior("double-finger-drag-canvas", {
      getEvents: function getEvents() {
        return {
          wheel: "onWheel",
        }
      },

      onWheel: (ev) => {
        if (ev.ctrlKey) {
          const canvas = graphRef?.current?.get("canvas")
          const point = canvas?.getPointByClient(ev.clientX, ev.clientY)
          let ratio = graphRef?.current?.getZoom()
          if (ev.wheelDelta > 0) {
            ratio = ratio + ratio * 0.05
          } else {
            ratio = ratio - ratio * 0.05
          }
          graphRef?.current?.zoomTo(ratio, {
            x: point.x,
            y: point.y,
          })
        } else {
          const x = ev.deltaX || ev.movementX
          const y = ev.deltaY || ev.movementY || (-ev.wheelDelta * 125) / 3
          translate(x, y)
        }
        ev.preventDefault()
      },
    })
    G6.registerNode(
      "bubble",
      {
        drawShape(cfg, group) {
          const self = this
          const r = cfg.size / 2
          // a circle by path
          const path = [
            ["M", -r, 0],
            ["C", -r, r / 2, -r / 2, r, 0, r],
            ["C", r / 2, r, r, r / 2, r, 0],
            ["C", r, -r / 2, r / 2, -r, 0, -r],
            ["C", -r / 2, -r, -r, -r / 2, -r, 0],
            ["Z"],
          ]
          const keyShape = group.addShape("path", {
            attrs: {
              x: 0,
              y: 0,
              path,
              fill: cfg.color || "steelblue",
            },
            // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
            name: "path-shape",
          })

          const mask = group.addShape("path", {
            attrs: {
              x: 0,
              y: 0,
              path,
              opacity: 0.25,
              fill: cfg.color || "steelblue",
              shadowColor: cfg.color.split(" ")[2].substr(2),
              shadowBlur: 40,
              shadowOffsetX: 0,
              shadowOffsetY: 30,
            },
            // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
            name: "mask-shape",
          })

          const spNum = 10 // split points number
          const directions = [],
            rs = []
          self.changeDirections(spNum, directions)
          for (let i = 0; i < spNum; i++) {
            const rr = r + directions[i] * ((Math.random() * r) / 1000) // +-r/6, the sign according to the directions
            if (rs[i] < 0.97 * r) rs[i] = 0.97 * r
            else if (rs[i] > 1.03 * r) rs[i] = 1.03 * r
            rs.push(rr)
          }
          keyShape.animate(
            () => {
              const path = self.getBubblePath(r, spNum, directions, rs)
              return { path }
            },
            {
              repeat: true,
              duration: 10000,
            },
          )

          const directions2 = [],
            rs2 = []
          self.changeDirections(spNum, directions2)
          for (let i = 0; i < spNum; i++) {
            const rr = r + directions2[i] * ((Math.random() * r) / 1000) // +-r/6, the sign according to the directions
            if (rs2[i] < 0.97 * r) rs2[i] = 0.97 * r
            else if (rs2[i] > 1.03 * r) rs2[i] = 1.03 * r
            rs2.push(rr)
          }
          mask.animate(
            () => {
              const path = self.getBubblePath(r, spNum, directions2, rs2)
              return { path }
            },
            {
              repeat: true,
              duration: 10000,
            },
          )
          return keyShape
        },
        changeDirections(num, directions) {
          for (let i = 0; i < num; i++) {
            if (!directions[i]) {
              const rand = Math.random()
              const dire = rand > 0.5 ? 1 : -1
              directions.push(dire)
            } else {
              directions[i] = -1 * directions[i]
            }
          }
          return directions
        },
        getBubblePath(r, spNum, directions, rs) {
          const path = []
          const cpNum = spNum * 2 // control points number
          const unitAngle = (Math.PI * 2) / spNum // base angle for split points
          let angleSum = 0
          const sps = []
          const cps = []
          for (let i = 0; i < spNum; i++) {
            const speed = 0.001 * Math.random()
            rs[i] = rs[i] + directions[i] * speed * r // +-r/6, the sign according to the directions
            if (rs[i] < 0.97 * r) {
              rs[i] = 0.97 * r
              directions[i] = -1 * directions[i]
            } else if (rs[i] > 1.03 * r) {
              rs[i] = 1.03 * r
              directions[i] = -1 * directions[i]
            }
            const spX = rs[i] * Math.cos(angleSum)
            const spY = rs[i] * Math.sin(angleSum)
            sps.push({ x: spX, y: spY })
            for (let j = 0; j < 2; j++) {
              const cpAngleRand = unitAngle / 3
              const cpR = rs[i] / Math.cos(cpAngleRand)
              const sign = j === 0 ? -1 : 1
              const x = cpR * Math.cos(angleSum + sign * cpAngleRand)
              const y = cpR * Math.sin(angleSum + sign * cpAngleRand)
              cps.push({ x, y })
            }
            angleSum += unitAngle
          }
          path.push(["M", sps[0].x, sps[0].y])
          for (let i = 1; i < spNum; i++) {
            path.push([
              "C",
              cps[2 * i - 1].x,
              cps[2 * i - 1].y,
              cps[2 * i].x,
              cps[2 * i].y,
              sps[i].x,
              sps[i].y,
            ])
          }
          path.push([
            "C",
            cps[cpNum - 1].x,
            cps[cpNum - 1].y,
            cps[0].x,
            cps[0].y,
            sps[0].x,
            sps[0].y,
          ])
          path.push(["Z"])
          return path
        },
        setState(name, value, item) {
          const shape = item.get("keyShape")
          if (name === "dark") {
            if (value) {
              if (shape.attr("fill") !== "#fff") {
                shape.oriFill = shape.attr("fill")
                const uColor = unlightColorMap.get(shape.attr("fill"))
                shape.attr("fill", uColor)
              } else {
                shape.attr("opacity", 0.2)
              }
            } else {
              if (shape.attr("fill") !== "#fff") {
                shape.attr("fill", shape.oriFill || shape.attr("fill"))
              } else {
                shape.attr("opacity", 1)
              }
            }
          }
        },
      },
      "single-node",
    )

    G6.registerNode(
      "animate-circle",
      {
        setState(name, value, item) {
          const shape = item.get("keyShape")
          const label = shape.get("parent").get("children")[1]
          if (name === "disappearing" && value) {
            shape.animate(
              (ratio) => {
                return {
                  opacity: 1 - ratio,
                  r: shape.attr("r") * (1 - ratio),
                }
              },
              {
                duration: 200,
              },
            )
            label.animate(
              (ratio) => {
                return {
                  opacity: 1 - ratio,
                }
              },
              {
                duration: 500,
              },
            )
          } else if (name === "appearing" && value) {
            const r = item.getModel().size / 2
            shape.animate(
              (ratio) => {
                return {
                  opacity: ratio,
                  r: r * ratio,
                  fill: shape.attr("fill"),
                }
              },
              {
                duration: 300,
              },
            )
            label.animate(
              {
                onFrame(ratio) {
                  return {
                    opacity: ratio,
                  }
                },
              },
              {
                duration: 300,
              },
            )
          } else if (name === "dark") {
            if (value) {
              if (shape.attr("fill") !== "#fff") {
                shape.oriFill = shape.attr("fill")
                const uColor = unlightColorMap.get(shape.attr("fill"))
                shape.attr("fill", uColor)
              } else {
                shape.attr("opacity", 0.2)
                label.attr("fill", "#A3B1BF")
              }
            } else {
              if (shape.attr("fill") !== "#fff") {
                shape.attr("fill", shape.oriFill || shape.attr("fill"))
              } else {
                shape.attr("opacity", 1)
                label.attr("fill", "#697B8C")
              }
            }
          }
        },
      },
      "circle",
    )

    G6.registerEdge(
      "animate-line",
      {
        drawShape(cfg, group) {
          const self = this
          let shapeStyle = self.getShapeStyle(cfg)
          shapeStyle = Object.assign(shapeStyle, {
            opacity: 0,
            strokeOpacity: 0,
          })
          const keyShape = group.addShape("path", {
            attrs: shapeStyle,
            // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
            name: "path-shape",
          })
          return keyShape
        },
        afterDraw(cfg, group) {
          const shape = group.get("children")[0]
          shape.animate(
            (ratio) => {
              const opacity = ratio * cfg.style.opacity
              const strokeOpacity = ratio * cfg.style.strokeOpacity
              return {
                opacity: ratio || opacity,
                strokeOpacity: ratio || strokeOpacity,
              }
            },
            {
              duration: 300,
            },
          )
        },
        setState(name, value, item) {
          const shape = item.get("keyShape")
          if (name === "disappearing" && value) {
            shape.animate(
              (ratio) => {
                return {
                  opacity: 1 - ratio,
                  strokeOpacity: 1 - ratio,
                }
              },
              {
                duration: 200,
              },
            )
          } else if (name === "dark") {
            if (value) shape.attr("opacity", 0.2)
            else shape.attr("opacity", 1)
          }
        },
      },
      "line",
    )
    graphRef?.current?.get("canvas")?.set("localRefresh", false)

    function refreshDragedNodePosition(e) {
      const model = e.item.get("model")
      model.fx = e.x
      model.fy = e.y
    }
    graphRef?.current?.on("node:dragstart", (e) => {
      graphRef?.current?.layout()
      refreshDragedNodePosition(e)
    })
    graphRef?.current?.on("node:drag", (e) => {
      refreshDragedNodePosition(e)
    })
    graphRef?.current?.on("node:dragend", (e) => {
      e.item.get("model").fx = null
      e.item.get("model").fy = null
    })
    graphRef?.current?.on("node:mouseenter", (e) => {
      const item = e.item
      const model = item.getModel()
      if (model.level === 0) {
        return
      }
      highlighting = true
      // setHighlighting(true);
      graphRef?.current?.setAutoPaint(false)
      const nodeItems = graphRef?.current?.getNodes()
      const edgeItems = graphRef?.current?.getEdges()
      nodeItems.forEach((node) => {
        graphRef?.current?.setItemState(node, "dark", true)
        node.getModel().light = false
      })
      graphRef?.current?.setItemState(item, "dark", false)
      model.light = true
      const tags = model.tags
      const findTagsMap = new Map()
      let mid = 0

      let fTag = ""
      // if the model is F node, find the leaves of it
      if (!model.isLeaf && model.level !== 0) {
        fTag = model.tag
        nodeItems.forEach((item) => {
          const itemModel = item.getModel()
          if (!itemModel.isLeaf) return
          const modelTags = itemModel.tags
          modelTags.forEach((mt) => {
            const mts = mt.split("-")
            if (mts[1] === fTag) {
              graphRef?.current?.setItemState(item, "dark", false)
              itemModel.light = true
            }
          })
        })
      }

      // find the tags
      tags.forEach((t) => {
        const ts = t.split("-")
        findTagsMap?.set(ts[0], mid)
        mid++
        if (ts[1]) {
          findTagsMap?.set(ts[1], mid)
          mid++
        }
      })
      // find the nodes with tag === tags[?]
      nodeItems.forEach((item) => {
        const node = item.getModel()
        if (findTagsMap.get(node.tag) !== undefined) {
          graphRef?.current?.setItemState(item, "dark", false)
          node.light = true
        }
      })
      edgeItems.forEach((item) => {
        const source = item.getSource().getModel()
        const target = item.getTarget().getModel()
        if (source.light && target.light) {
          graphRef?.current?.setItemState(item, "dark", false)
        } else {
          graphRef?.current?.setItemState(item, "dark", true)
        }
      })
      graphRef?.current?.paint()
      graphRef?.current?.setAutoPaint(true)
    })

    graphRef?.current?.on("node:mouseleave", () => {
      if (highlighting) {
        const nodeItems = graphRef?.current?.getNodes()
        const edgeItems = graphRef?.current?.getEdges()
        // setHighlighting(false);
        highlighting = false
        nodeItems.forEach((item) => {
          graphRef?.current?.setItemState(item, "dark", false)
        })
        edgeItems.forEach((item) => {
          graphRef?.current?.setItemState(item, "dark", false)
        })
      }
    })

    graphRef?.current?.on("node:click", (e) => {
      curShowNodes = []
      curShowEdges = []
      const item = e.item
      const model = item.getModel()

      if (model.isLeaf) {
        navigate(`/skill/green-skills/${model?.id}`)
      }

      if (!model.isLeaf && model.level !== 0) {
        return
      }
      // if clicked a root, hide unrelated items and show the related items
      if (model.level === 0) {
        const layoutController = graphRef?.current?.get("layoutController")
        const forceLayout = layoutController.layoutMethods[0]
        forceLayout?.forceSimulation?.stop()

        // Light the level 0 nodes
        showNodes.forEach((snode) => {
          const item = graphRef?.current?.findById(snode.id)
          graphRef?.current?.setItemState(item, "dark", false)
          if (snode.x < 0.5 * width) {
            snode.x = 300
          } else {
            snode.x = width - 300
          }
        })
        model.x = width / 2
        model.y = height / 2

        // Animatively hide the items which are going to disappear
        if (curShowEdges.length) {
          curShowEdges.forEach((csedge) => {
            const item = graphRef?.current?.findById(csedge.id)
            item && graphRef?.current?.setItemState(item, "disappearing", true)
          })
        }
        curShowNodes.forEach((csnode) => {
          const item = graphRef?.current?.findById(csnode.id)
          item && graphRef?.current?.setItemState(item, "disappearing", true)
        })
        graphRef?.current?.positionsAnimate()

        // Reset curShowNodes and curShowEdges
        // setCurShowNodes([]);
        // setCurShowEdges([]);
        curShowNodes = []
        curShowEdges = []

        // Click on the same node which is the current focus node, hide the small nodes, change the layout parameters to roots view
        if (currentFocus && currentFocus.id === model.id) {
          // setCurrentFocus(undefined);
          currentFocus = undefined
          layoutController.layoutCfg.nodeStrength = 2500
          layoutController.layoutCfg.collideStrength = 0.8
          layoutController.layoutCfg.alphaDecay = 0.01
        } else {
          // Click on other focus node, hide the current small nodes and show the related nodes
          // setCurrentFocus({ ...model });
          currentFocus = model

          // Change data after the original items disappear
          layoutController.layoutCfg.nodeStrength = () => -80
          layoutController.layoutCfg.collideStrength = 0.2
          layoutController.layoutCfg.linkDistance = (d) => {
            if (d.source.level !== 0) return 120
            const length = 250
            return length
          }
          layoutController.layoutCfg.edgeStrength = () => 2

          const tag = model.tag
          const findTags = []
          // setCurShowNodesMap(new Map());
          curShowNodesMap = new Map()

          // Find the nodes which are the descendants of the clicked model
          nodes.forEach((node) => {
            if (!node.tags) return
            const tags = node.tags
            const tlength = tags.length
            let isChild = false
            const parents = []
            for (let i = 0; i < tlength; i++) {
              const ts = tags[i].split("-")
              if (ts[0] === tag) {
                isChild = true
              }
              parents.push({ ...nodeMap.get(ts[0]) })
            }
            if (isChild) {
              const randomAngle = Math.random() * 2 * Math.PI
              node.x = model.x + (Math.cos(randomAngle) * model.size) / 2 + 10
              node.y = model.y + (Math.sin(randomAngle) * model.size) / 2 + 10

              if (!node.style) node.style = {}
              node.style.lineWidth = 0
              node.style.opacity = 1
              if (node.isLeaf) {
                node.type = "animate-circle"
                let color = "l(0)"
                const parentsNum = parents.length
                parents.forEach((parent, i) => {
                  const parentColor = parent.color?.split(" ")[1]?.substr(2)
                  color += ` ${i / (parentsNum - 1)}:${parentColor}`
                })
                if (parentsNum === 1) {
                  color = model?.color?.split(" ")[1]?.substr(2)
                }
                node.color = color
                node.style.fill = color
                node.style.fill = "#fff"
                node.style.lineWidth = 1
                node.labelCfg = {
                  style: {
                    fontSize: 12,
                    lineHeight: 19,
                    fill: "#36505E",
                  },
                  position: "center",
                  wordWrap: true,
                }
              } else if (node.level !== 0) {
                node.type = "circle"
                node.size = 95
                if (!node.style) node.style = {}
                node.color = model.color
                node.style.fill = model.color
                node.labelCfg = {
                  style: {
                    fill: "#fff",
                    fontSize: 14,
                  },
                  position: "center",
                  wordWrap: true,
                  maxWidth: 80,
                }
              }
              curShowNodes?.push(node)
              curShowNodesMap?.set(node.id, node)
              // setCurShowNodes((nodes) => [...nodes, { ...node }]);
              // setCurShowNodesMap(new Map(curShowNodesMap.set(node.id, node)));

              // Add the edge connecting from model to node which exists in edges
              const edgeId = `${model.id}-${node.id}`
              const edge = edgesMap.get(edgeId)
              if (edge) {
                edge.color = model.color
                curShowEdges.push(edge)
                // setCurShowEdges((edges) => [...edges, { ...edge }]);
              }

              tags.forEach((t) => {
                const ts = t.split("-")
                if (ts[0] !== tag) {
                  findTags.push(ts[0])
                }
                if (ts[1]) {
                  findTags.push(ts[1])
                }
              })
            }
          })

          // Find the nodes which are the ancestors of the current curShowNodes
          nodes.forEach((node) => {
            const findTagsLength = findTags.length
            for (let i = 0; i < findTagsLength; i++) {
              if (
                node.tag === findTags[i] &&
                curShowNodesMap.get(node.id) === undefined
              ) {
                curShowNodes?.push(node)
                curShowNodesMap?.set(node.id, node)
                // setCurShowNodes((nodes) => [...nodes, { ...node }]);
                // setCurShowNodesMap(new Map(curShowNodesMap.set(node.id, node)));
                return
              }
            }
          })

          // Find the edges whose target end source are in the curShowNodes
          curShowNodes.forEach((nu, i) => {
            const lu = nu.level
            curShowNodes.forEach((nv, j) => {
              if (j <= i) return
              const lv = nv.level
              let edgeId
              if (lu < lv) {
                edgeId = `${nu.id}-${nv.id}`
              } else {
                edgeId = `${nv.id}-${nu.id}`
              }
              let color = model.color
              if (nu.isLeaf) {
                if (nv.level === 0 && nv.tag !== model.tag) color = "#DFE5EB"
                else if (!nv.isLeaf && nv.tags[0] !== model.tag) {
                  color = "#DFE5EB"
                }
              } else if (nv.isLeaf) {
                if (nu.level === 0 && nu.tag !== model.tag) color = "#DFE5EB"
                else if (!nu.isLeaf && nu.tags[0] !== model.tag) {
                  color = "#DFE5EB"
                }
              }
              const edge = edgesMap.get(edgeId)
              if (edge) {
                edge.color = color
                curShowEdges.push(edge)
                // setCurShowEdges((edges) => [...edges, { ...edge }]);
              }
            })
          })
        }
        setTimeout(() => {
          graphRef?.current?.changeData({
            nodes: showNodes.concat(curShowNodes),
            edges: showEdges.concat(curShowEdges),
          })
          const nodeItems = graphRef?.current?.getNodes()
          const edgeItems = graphRef?.current?.getEdges()
          edgeItems.forEach((item) => {
            graphRef?.current?.clearItemStates(item)
          })
          nodeItems.forEach((item) => {
            graphRef?.current?.clearItemStates(item)
            graphRef?.current?.setItemState(item, "appearing", true)
          })
        }, 400)
      }
    })

    graphRef?.current?.on("canvas:click", () => {
      // setCurrentFocus(undefined);
      currentFocus = undefined
      const forceLayout =
        graphRef?.current?.get("layoutController").layoutMethods[0]
      forceLayout.forceSimulation.stop()
      const nodeItems = graphRef?.current?.getNodes()
      const edgeItems = graphRef?.current?.getEdges()
      if (highlighting) {
        // setHighlighting(false);
        highlighting = false
        nodeItems.forEach((item) => {
          graphRef?.current?.setItemState(item, "dark", false)
        })
        edgeItems.forEach((item) => {
          graphRef?.current?.setItemState(item, "dark", false)
        })
      } else {
        nodeItems.forEach((item) => {
          const model = item.getModel()
          if (model.level === 0) {
            graphRef?.current?.setItemState(item, "dark", false)
          } else {
            graphRef?.current?.setItemState(item, "disappearing", true)
          }
        })
        edgeItems.forEach((item) => {
          graphRef?.current?.setItemState(item, "disappearing", true)
        })
        // setCurShowNodes([]);
        // setShowEdges([]);
        curShowNodes = []
        curShowEdges = []
        setTimeout(() => {
          const layoutController = graphRef?.current?.get("layoutController")
          layoutController.layoutCfg.nodeStrength = 2500
          layoutController.layoutCfg.collideStrength = 0.8
          layoutController.layoutCfg.alphaDecay = 0.01

          graphRef?.current?.changeData({
            nodes: showNodes,
            edges: showEdges,
          })
        }, 400)
      }
      return () => {
        if (graphRef.current) {
          graphRef.current.destroy()
          graphRef.current = null
        }
      }
    })

    if (greenSkillSectors) {
      greenSkillSectors?.forEach((skillGroup) => {
        const groupNode = {
          id: skillGroup?.id,
          label: truncateLabel(skillGroup?.name?.replace(/\s+/g, "\n"), 40),
          level: 0,
          tag: skillGroup?.name,
          childrenNum: skillGroup?.total_skills,
          size:
            skillGroup?.total_skills < 8 ? 100 : skillGroup?.total_skills * 14,
          isLeaf: false,
        }

        // nodes_temp.push(groupNode);
        nodes.push(groupNode)

        skillGroup?.skills?.forEach((skill) => {
          const skillNode = {
            id: skill?.id,
            label: truncateLabel(skill?.name?.replace(/\s+/g, "\n"), 20),
            tags: [skillGroup?.name],
            level: 1,
            size: calculateCircleSize(skill?.name),
            isLeaf: true,
          }
          // nodes_temp.push({ ...skillNode });
          nodes.push(skillNode)

          edges.push({
            source: skillGroup?.id,
            target: skill?.id,
          })
        })
      })

      // find the roots and update nodes
      // nodes_temp?.forEach((node) => {
      nodes?.forEach((node) => {
        if (node.level === 0) {
          const updatedNode = {
            ...node,
            color: gColors[showNodes.length % gColors.length],
            style: {
              fill: gColors[showNodes.length % gColors.length],
              lineWidth: 0,
            },
            labelCfg: {
              style: {
                fontSize: 20,
                fill: "#36505E",
              },
            },
            x: Math.random() * 800,
            y: Math.random() * 800,
          }
          showNodes.push(updatedNode)
          // updatedShowNodes.push({ ...updatedNode });
        }
        if (!node.isLeaf) {
          const num = node.childrenNum ? `\n(${node.childrenNum})` : ""
          node.label = `${node.name}${num}`
        }
        // updatedNodeMap.set(node.id, node);
        // updatedNodes.push(node);
        nodeMap?.set(node.id, node)
        nodes?.push(node)
      })
      mapNodeSize(showNodes, "childrenNum", [120, 180])

      // map the color to F nodes, same as their parent
      nodes?.forEach((node) => {
        // updatedNodes?.forEach((node) => {
        if (node.level !== 0 && !node.isLeaf) {
          // const parent = updatedNodeMap?.get(node?.tags[0]);
          const parent = nodeMap?.get(node?.tags[0])
          const updatedNode = {
            ...node,
            color: parent.color,
            style: {
              fill: parent.color,
            },
          }
          // updatedNodes.push(updatedNode);
          nodes.push(updatedNode)
        }
      })

      // edges_temp?.forEach((edge) => {
      edges?.forEach((edge) => {
        const updatedEdge = {
          ...edge,
          id: `${edge.source}-${edge?.target}`,
          style: {
            lineWidth: 0.5,
            opacity: 1,
            strokeOpacity: 1,
          },
        }
        // updatedEdgesMap.set(updatedEdge.id, updatedEdge);
        // updatedEdges.push(updatedEdge);
        edgesMap?.set(updatedEdge.id, updatedEdge)
        edges.push(updatedEdge)
      })

      graphRef?.current?.data({
        nodes: showNodes,
        edges: showEdges,
      })
      // graphRef?.current?.data({
      //   nodes: [...updatedShowNodes],
      //   edges: [...updatedShowEdges],
      // });

      // loadData();
    } else {
      nodes = []
      edges = []
    }
    graphRef?.current?.render()
  }, [greenSkillSectors?.length])

  return (
    <div
      className="py-20"
      ref={containerRef}
      style={{ width: "100%", height: "500px" }}
    ></div>
  )
})
