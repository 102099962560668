import { useEffect, useState } from "react"
import instance from "../../../config/config"
import { useMutation } from "react-query"
import { useHeader } from "../../../common/layout/hooks/useCustomHeader"

export const useAccountSetting = () => {
  const { handleLogoutRedirect } = useHeader()
  // state drawer
  const [isActive, setIsActive] = useState(false)
  const [isConfirm, setIsConfirm] = useState("")

  useEffect(() => {
    if (isConfirm === "DELETE ACCOUNT") {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }, [isConfirm])

  const { mutate: purge, isLoading: loadingPurge } = useMutation({
    mutationFn: (val) => {
      return instance.post(`/purge-user-data`, val)
    },
    mutationKey: "account-purge",
  })
  const purgeAccount = (val) => {
    purge(val, {
      onSuccess: () => {
        handleLogoutRedirect()
      },
    })
  }

  return {
    isActive,
    setIsActive,
    isConfirm,
    setIsConfirm,
    purgeAccount,
    loadingPurge,
  }
}
