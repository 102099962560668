import React, { useEffect, useRef } from "react"
import { Button, Card, DatePicker, Divider, Input, Switch, Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { Controller } from "react-hook-form"
import { OtherInstitution } from "../../../../../assets/images"
import dayjs from "dayjs"
import { Certification } from "../../../hooks/cv-builder/useCertification"
import { useCertificationUpdate } from "../../../hooks/cv-builder/useCertificationUpdate"

interface Props {
  data: Certification
  index: number
  updateTempData: (index: number, data: Certification) => void
}

export const CertifEdit: React.FC<Props> = ({
  data,
  index,
  updateTempData,
}) => {
  const {
    control,
    errors,
    otherOrganisation,
    dataTemp,
    image,
    handleSubmit,
    watch,
    onSubmit,
    loadingUpdateCertif,
  } = useCertificationUpdate(data, index, updateTempData)

  const ref = useRef<HTMLDivElement>(null)
  const handleClikInside = (e: MouseEvent) => {
    if (ref.current?.contains(e.target as Node)) {
      otherOrganisation.setIsOpen(true)
    } else {
      otherOrganisation.setIsOpen(false)
    }
  }
  useEffect(() => {
    document.addEventListener("click", handleClikInside)
    return () => {
      document.removeEventListener("click", handleClikInside)
    }
  }, [])
  return (
    <form className="w-full p-5 rounded-xl bg-white shadow-md mb-5">
      <div className="flex gap-5 flex-wrap lg:flex-nowrap">
        <Card className=" shadow-md w-full rounded-xl flex-grow basis-1/3 text-center  bg-[#FAFAFA]">
          <Controller
            name="organisation_name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <div ref={ref} className=" relative">
                <Input
                  {...field}
                  bordered={false}
                  autoComplete={"off"}
                  onChange={otherOrganisation.handleSearch}
                  onKeyDown={(e) => {
                    if (e.key === "Enter")
                      otherOrganisation.handleAddOrganisation(
                        e.currentTarget.value,
                      )
                  }}
                  status={errors?.organisation_name ? "error" : ""}
                  className="my-1 text-center basis-1/2 text-lg font-bold leading-[38px] capitalize"
                  placeholder="Organisation Name"
                />
                {errors?.organisation_name && (
                  <div className=" text-xs w-full text-center text-danger">
                    *required
                  </div>
                )}

                {otherOrganisation.isOpen && otherOrganisation.value && (
                  <div
                    className=" absolute z-50 bg-[#FFFFFF] w-60 p-3 rounded-lg shadow-md"
                    style={{
                      left: "50%",
                      transform: "translate(-50%, 0)",
                    }}
                  >
                    <div
                      className="capitalize truncate text-left cursor-pointer my-1"
                      onClick={() =>
                        otherOrganisation.handleAddOrganisation(
                          otherOrganisation.value,
                        )
                      }
                    >
                      {otherOrganisation.value}
                    </div>

                    <Divider className="m-0 p-0" />
                    {otherOrganisation?.isLoading &&
                    !otherOrganisation?.data?.length ? (
                      <div className="p-1 mt-2 text-center">
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 24,
                              }}
                              spin
                            />
                          }
                        />
                      </div>
                    ) : otherOrganisation?.data?.length ? (
                      otherOrganisation?.data?.map((item, index) => (
                        <div
                          key={index}
                          className=" capitalize truncate cursor-pointer my-1 flex items-center gap-3"
                          onClick={() =>
                            otherOrganisation?.handleSelectOrganisation(item)
                          }
                        >
                          <img
                            src={item.image || OtherInstitution}
                            alt="company"
                            className="w-8 h-8 p-1 rounded-full shadow object-contain"
                          />
                          {item.name}
                        </div>
                      ))
                    ) : (
                      <div className="text-center text-gray-400 my-1">
                        click enter to create a new company
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          />
          <img
            src={image?.imageUrl || OtherInstitution}
            alt="institution"
            className="w-20 h-20 p-2 rounded-full shadow object-contain mt-3"
          />
          {dataTemp?.id && (
            <div className="flex gap-3 text-xs my-2 justify-center">
              <label
                htmlFor="image-profile"
                className=" text-secondary cursor-pointer"
              >
                Replace
              </label>
              {image?.imageUrl && (
                <div
                  className="text-danger cursor-pointer"
                  onClick={() => {
                    if (!image.loadingUpload || !image.loadingRemove)
                      image.handleRemoveImage()
                  }}
                >
                  Remove
                </div>
              )}
              <input
                type="file"
                disabled={image.loadingUpload || image.loadingRemove}
                onChange={image.handleImageChange}
                accept="image/png, image/gif, image/jpeg"
                id="image-profile"
                className=" hidden"
                value={""}
              />
            </div>
          )}
        </Card>
        <Card className="shadow-md w-full rounded-xl flex-grow basis-2/3  bg-[#FAFAFA]">
          <Controller
            name="certification_name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                {...field}
                status={errors.certification_name ? "error" : ""}
                className="my-2 font-default rounded"
                placeholder="Certification Name"
              />
            )}
          />
          <Controller
            name="credentials_url"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <Input
                {...field}
                status={errors.credentials_url ? "error" : ""}
                className="my-2 font-default rounded"
                placeholder="link to online certificate"
              />
            )}
          />
          <Controller
            name="has_expiry_date"
            control={control}
            render={({ field }) => (
              <div className="flex gap-3 items-center">
                <Switch {...field} defaultChecked={field.value} size="small" />
                <div>Does not expire</div>
              </div>
            )}
          />
          <div className="flex gap-3 my-2 flex-wrap lg:flex-nowrap">
            <Controller
              name="start_at"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  disabledDate={(current) => {
                    const end = watch(`end_at`)
                    const targetDate = dayjs(end || Date.now()).startOf("day")
                    const today = dayjs().startOf("day")
                    return current.isAfter(today) || current.isAfter(targetDate)
                  }}
                  placeholder="Start date"
                  format="DD MMM YYYY"
                  status={errors?.start_at ? "error" : ""}
                  className="w-full rounded"
                />
              )}
            />

            <Controller
              name="end_at"
              control={control}
              rules={
                !watch(`has_expiry_date`)
                  ? {
                      required: true,
                    }
                  : {
                      required: false,
                    }
              }
              render={({ field }) => (
                <DatePicker
                  {...field}
                  disabledDate={(current) => {
                    const start = watch(`start_at`)
                    const targetDate = dayjs(start || Date.now()).startOf("day")
                    return start
                      ? current.isBefore(targetDate.add(1, "day"))
                      : false
                  }}
                  placeholder="End date"
                  format="DD MMM YYYY"
                  status={errors?.end_at ? "error" : ""}
                  className={`${
                    watch("has_expiry_date") ? "invisible" : "visible"
                  } w-full rounded`}
                />
              )}
            />
          </div>
        </Card>
      </div>
      <div className="mt-5 flex justify-end gap-5">
        <Button
          type="primary"
          ghost
          onClick={() => updateTempData(index, { ...data, isEdit: false })}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={loadingUpdateCertif}
        >
          Save
        </Button>
      </div>
    </form>
  )
}
