/* eslint-disable */

import React, { FC, ComponentType } from "react"
import { useMsal } from "@azure/msal-react"
import { useModalStore } from "../../../stores"

type ClickableProps = {
  onClick?: (e?: any) => void
}
/**
 * HOC that wraps a component with an onClick handler that checks if the user is logged in.
 * If the user is logged in, the onClick handler is called.
 * If the user is not logged in, the modal is opened.
 * @param Component - The component to wrap
 * @returns A component that can be clicked on to open the modal if the user is not logged in.
 *
 * @example
 * ```tsx
 * const MyComponent = ({ onClick }) => {
 *  return (
 *   <button onClick={onClick}>Click Me</button>
 * )
 * }
 *
 * const MyComponentWithAuth = withAuthentication(MyComponent);
 *
 * const MyPage = () => {
 * return (
 * <MyComponentWithAuth onClick={() => console.log("clicked")} />
 * )
 * }
 * ```
 */
export const withAuthentication = <P extends ClickableProps>(
  Component: ComponentType<P>,
): FC<P> => {
  return (props: P) => {
    const { instance } = useMsal()
    const accounts = instance.getAllAccounts()
    const isLoggedIn = accounts.length > 0
    const openModal = useModalStore((state) => state.openModal)
    const handleClick = () => {
      if (isLoggedIn) {
        props?.onClick?.()
      } else {
        openModal()
      }
    }

    return <Component {...props} onClick={handleClick} />
  }
}
