import { ApiResponseGeneric } from "../../../../common/types/ApiResponse"
import instance from "../../../../config/config"
import { TAddProduct } from "../components/ModalAddClimateSolution"
import { TFormAddCompany } from "../components/ModalAddCompany"
import { TFormUpdateCompany } from "../components/ModalEditCompanyInfo"
import { IFounder, IStartup, IStartupProduct } from "../types"

export const becomeAFounder = () => {
  return instance.post(`/startups/founders`)
}

export const getFounderProfile = () => {
  return instance.get<ApiResponseGeneric<IFounder>>(
    `/startups/founders/profile`,
  )
}

export const updateFounderProfile = (data: any) => {
  return instance.patch(`/startups/founders`, data)
}

export const getStartupFounders = () => {
  return instance.get(`/startups/founders`)
}

export const getStartup = (id: string) => {
  return instance.get<ApiResponseGeneric<IStartup>>(`/startups/${id}`)
}

export const createStartup = (data: TFormAddCompany) => {
  const formData = new FormData()

  Object.keys(data).forEach((key) => {
    if (
      data[key as keyof TFormAddCompany] !== undefined &&
      data[key as keyof TFormAddCompany] !== null
    ) {
      formData.append(key, data[key as keyof TFormAddCompany])
    }
  })
  return instance.post(`/startups`, formData)
}

export const updateStartup = (id: string, data: TFormUpdateCompany) => {
  const formData = new FormData()
  Object.keys(data).forEach((key) => {
    if ((key as keyof TFormUpdateCompany) === "founders_id") {
      formData.append(
        "founders_id",
        data["founders_id"]?.map((item) => item?.id)?.join(",") || "",
      )
    } else if (
      data[key as keyof TFormUpdateCompany] !== undefined &&
      data[key as keyof TFormUpdateCompany] !== null
    ) {
      formData.append(key, data[key as keyof TFormUpdateCompany])
    }
  })
  return instance.patch(`/startups/${id}`, formData)
}

export const getStartupProducts = (id: string) => {
  return instance.get<ApiResponseGeneric<IStartupProduct[] | null>>(
    `/startups/${id}/products`,
  )
}

export const addStartupProduct = (id: string, data: TAddProduct) => {
  const formData = new FormData()

  Object.keys(data).forEach((key) => {
    if (
      data[key as keyof TAddProduct] !== undefined &&
      data[key as keyof TAddProduct] !== null
    ) {
      if ((key as keyof TAddProduct) === "solutions_id") {
        formData.append(
          "solutions_id",
          data["solutions_id"]?.map((item) => item?.id)?.join(",") || "",
        )
      } else {
        formData.append(
          key,
          data[key as keyof Omit<TAddProduct, "solutions_id">],
        )
      }
    }
  })

  return instance.post(`/startups//${id}/products`, formData)
}

export const updateStartupProduct = (id: string, data: any) => {
  return instance.patch(`/startups/${id}/products`, data)
}

export const deleteStartupProduct = (id: string, product_id: string) => {
  return instance.delete(`/startups/${id}/products/${product_id}`)
}
