import React from "react"
import { Button } from "antd"
import {
  SyncOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  FilePdfOutlined,
} from "@ant-design/icons"
import dayjs from "dayjs"
import { SkillVerification } from "../../../hooks/cv-builder/useSkillVerification"

interface Props {
  data: SkillVerification
  index: number
  actionUpdate: (index: number, data: SkillVerification) => void
  actionRemove: (id: string) => Promise<void>
  loadingRemove: boolean
  actionPublish: (id: string, index: number) => Promise<void>
  loadingPublish: boolean
}

export const CardVerifiedView: React.FC<Props> = ({
  data,
  index,
  actionUpdate,
  actionRemove,
  loadingRemove,
  actionPublish,
  loadingPublish,
}) => {
  const [current, setCurrent] = React.useState<boolean>(false)
  const handleStatus = (status: string) => {
    switch (status) {
      case "DRAFT":
        return (
          <>
            <div className=" mb-3">
              <SyncOutlined />{" "}
              <span className=" text-darkGrey">not submitted</span>
            </div>
            <Button
              type="primary"
              className=" w-full mb-3"
              onClick={() => {
                setCurrent(true)
                actionPublish(data.id, index).then(() => setCurrent(false))
              }}
              disabled={current && loadingPublish}
            >
              Submit
            </Button>
            <Button
              type="primary"
              ghost
              className=" w-full"
              onClick={() => actionUpdate(index, { ...data, isEdit: true })}
            >
              Edit
            </Button>
          </>
        )
      case "ON_REVIEW":
        return (
          <>
            <div className=" mb-3">
              <SyncOutlined /> <span className=" text-darkGrey">in review</span>
            </div>
            <Button type="primary" disabled className=" w-full mb-3">
              Submitted
            </Button>
          </>
        )
      case "REJECTED":
        return (
          <>
            <div className=" mb-3">
              <CloseCircleOutlined className=" text-danger" />{" "}
              <span className=" text-darkGrey">rejected</span>
            </div>
            <Button
              disabled={loadingRemove}
              type="primary"
              ghost
              className=" w-full mb-3"
              onClick={() => actionRemove(data?.id)}
            >
              Remove
            </Button>
            <div className=" text-darkGrey">
              {dayjs().format("DD MMM YYYY")}
            </div>
          </>
        )
      case "ACCEPTED":
        return (
          <>
            <div className=" mb-3">
              <CheckCircleOutlined className=" text-primary" />{" "}
              <span className=" text-darkGrey">verified</span>
            </div>
            <div className=" text-darkGrey">
              {dayjs().format("DD MMM YYYY")}
            </div>
          </>
        )
      default:
        return (
          <>
            <div className=" mb-3">
              <SyncOutlined />{" "}
              <span className=" text-darkGrey">not submitted</span>
            </div>

            <Button
              type="primary"
              ghost
              className=" w-full"
              onClick={() => actionUpdate(index, { ...data, isEdit: true })}
            >
              Edit
            </Button>
          </>
        )
    }
  }
  return (
    <div
      className=" shadow-md rounded-lg flex gap-5 p-5 mb-5 bg-[#FAFAFA]"
      style={{ border: "0.5px solid #E5E7EB" }}
    >
      <div className=" w-full gap-5">
        <h3>{data?.green_skill?.name || "Unknown"}</h3>
        {data?.document_link && (
          <a
            href={data?.document_link}
            target="_blank"
            rel="noreferrer"
            className="my-3 text-backdrop hover:text-backdrop"
          >
            <FilePdfOutlined className="" />{" "}
            <span className="">{data?.document_name}</span>
          </a>
        )}
        <div
          className="my-3"
          dangerouslySetInnerHTML={{
            __html: data?.description || "No description",
          }}
        />
      </div>
      <div className=" w-40">{handleStatus(data?.status || "")}</div>
    </div>
  )
}
