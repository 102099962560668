import React from "react"
import { Account } from "../../hooks/cv-builder/useProfile"
import { Card } from "antd"
import { useLocation } from "react-router-dom"
import { EnvironmentOutlined } from "@ant-design/icons"

interface Props {
  data: Account | undefined
}

export const CardBasicInfo: React.FC<Props> = ({ data }) => {
  const { pathname } = useLocation()
  return (
    <div className="flex gap-5 mt-1 md:flex-nowrap flex-wrap">
      <Card className="w-full shadow-md rounded-xl bg-[#FAFAFA]">
        <div className="flex gap-5 items-center justify-center md:justify-start flex-col md:flex-row md:flex-nowrap flex-wrap">
          <img
            className=" w-32 h-32 rounded-full shadow-md p-1 object-contain"
            src={
              data?.profile_picture
                ? `${data?.profile_picture}?${new Date().getTime()}`
                : data?.avatar
            }
            alt="profile"
            onError={(e: any) => {
              e.target.onerror = null
              e.target.src = data?.avatar
            }}
          />

          <div className="md:w-[calc(100%-10rem)]">
            <h1 className=" capitalize mb-3 font-bold text-center md:text-left">{`${data?.first_name}${
              pathname.includes("/cv-anonymised/") ? "*" : " "
            }${data?.last_name}`}</h1>
            {data?.profile_headline && (
              <div className="mb-3 md:text-left text-center">
                {data?.profile_headline}
              </div>
            )}
            <div className="justify-center md:justify-start text-darkGrey flex items-center">
              <EnvironmentOutlined />
              <span className="ml-2">{data?.country}</span>
            </div>
          </div>
        </div>

        {data?.about_me && data.about_me !== "<p><br></p>" && (
          <div
            className="mt-2"
            dangerouslySetInnerHTML={{
              __html: data?.about_me,
            }}
          ></div>
        )}
      </Card>
      {data?.career_aspiration && (
        <Card className=" md:w-2/3 flex-grow md:flex-grow-0 shadow-md rounded-xl bg-[#FAFAFA]">
          <h2 className="mb-3">Green Jobs I am interested in</h2>
          <div>
            {data?.career_aspiration.map((item, i) => (
              <div
                key={i}
                className="flex h-14 gap-3 py-3 px-2 my-2 rounded items-center bg-white"
                style={{ border: "1px solid #D0DDE5" }}
              >
                <div className="w-6 flex items-center justify-center bg-primary-bg rounded-full">
                  {i + 1}
                </div>

                <div className=" w-full capitalize">{item.name}</div>
              </div>
            ))}
          </div>
        </Card>
      )}
    </div>
  )
}
