import React from "react"
import { Header } from "../components"
import { HomeOutlined } from "@ant-design/icons"
import { PageContent } from "../../../common/layout"
import { PrivacySettingContent } from "../components/PrivacySettingContent"

const PrivacySetting = () => {
  return (
    <PageContent
      header={
        <Header
          title={"Privacy Settings"}
          breadcrumbs={[
            {
              path: "/dashboard/launchpad",
              title: <HomeOutlined className="text-gray-400" />,
            },
            {
              title: "Privacy Settings",
            },
          ]}
        />
      }
      content={<PrivacySettingContent />}
    />
  )
}

export default PrivacySetting
