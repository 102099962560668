import { useMutation, useQuery, useQueryClient } from "react-query"
import instance from "../../../../config/config"
import { handlingError } from "../../../../utils/handling"
import { useMemo, useState } from "react"
import { message } from "antd"
import { errorMessage } from "../../../../common/utils/utils"

const useListJobBoards = (userID: string) => {
  const [search, setSearch] = useState("")
  const [searchDebounce, setSearchDebounce] = useState("")
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)

  // countries
  const [selectedHQ, setSelectedHQ] = useState<any[]>([])
  const idCountries = selectedHQ?.map((item) => item.id)
  const countriesID = idCountries?.join(",")

  // greenskills
  const [selectedGS, setSelectedGS] = useState<any[]>([])
  const idGreenSkills = selectedGS?.map((item) => item.id)
  const greenSkillID = idGreenSkills?.join(",")

  // teams or function
  const [selectedTeams, setSelectedTeams] = useState<any[]>([])
  const idTeams = selectedTeams?.map((item) => item.id)
  const teamsName = idTeams?.join(",")

  // types
  const [selectedTypes, setSelectedTypes] = useState<any[]>([])
  const idTypes = selectedTypes?.map((item) => item.id)
  const typesName = idTypes?.join(",")

  // sectors
  const [selectedSectors, setSelectedSectors] = useState<any[]>([])
  const idSectors = selectedSectors?.map((item) => item.name)
  const sectorsName = idSectors?.join(",")

  // remote
  const [remote, setRemote] = useState("")

  const queryKey = useMemo(
    () => [
      "job-boards-list",
      {
        limit,
        page,
        name: searchDebounce,
        location: countriesID,
        green_skills: greenSkillID,
        types: typesName,
        teams: teamsName,
        sectors: sectorsName,
        remote,
        user_id: userID,
      },
    ],
    [
      limit,
      page,
      searchDebounce,
      countriesID,
      greenSkillID,
      typesName,
      teamsName,
      sectorsName,
      remote,
      userID,
    ],
  )

  const fetchListJobBoardCountries = async () => {
    try {
      const { data } = await instance.get(`/job-boards/references/countries`, {
        params: {
          // name: searchDebounce,
        },
      })
      return data
    } catch (error: any) {
      return handlingError(error?.response?.data?.message, error)
    }
  }

  const { data: countriesFilter, isLoading: isCountriesFiltertLoading } =
    useQuery("job-boards-countries", fetchListJobBoardCountries)

  const fetchListJobBoardGS = async () => {
    try {
      const { data } = await instance.get(
        `/job-boards/references/green-skills`,
        {
          params: {
            // name: searchDebounce,
          },
        },
      )
      return data
    } catch (error: any) {
      return handlingError(error?.response?.data?.message, error)
    }
  }

  const { data: gsFilter, isLoading: isGSFiltertLoading } = useQuery(
    "job-boards-gs",
    fetchListJobBoardGS,
  )

  const fetchListJobBoardTeams = async () => {
    try {
      const { data } = await instance.get(`/job-boards/references/teams`, {
        params: {
          // name: searchDebounce,
        },
      })
      return data
    } catch (error: any) {
      return handlingError(error?.response?.data?.message, error)
    }
  }

  const { data: teamsFilter, isLoading: isTeamsFiltertLoading } = useQuery(
    "job-boards-teams",
    fetchListJobBoardTeams,
  )

  const fetchListJobBoardTypes = async () => {
    try {
      const { data } = await instance.get(`/job-boards/references/types`, {
        params: {
          // name: searchDebounce,
        },
      })
      return data
    } catch (error: any) {
      return handlingError(error?.response?.data?.message, error)
    }
  }

  const { data: typesFilter, isLoading: isTypesFiltertLoading } = useQuery(
    "job-boards-types",
    fetchListJobBoardTypes,
  )

  const fetchListJobBoardSectors = async () => {
    try {
      const { data } = await instance.get(`/job-boards/references/sectors`, {
        params: {
          // name: searchDebounce,
        },
      })
      return data
    } catch (error: any) {
      return handlingError(error?.response?.data?.message, error)
    }
  }

  const { data: sectorsFilter, isLoading: isSectorsFiltertLoading } = useQuery(
    "job-boards-sectors",
    fetchListJobBoardSectors,
  )

  const fetchListJobBoards = async () => {
    try {
      const { data } = await instance.get(`/job-boards`, {
        params: {
          limit,
          page,
          name: searchDebounce,
          location: countriesID,
          green_skills: greenSkillID,
          types: typesName,
          teams: teamsName,
          sectors: sectorsName,
          user_id: userID,
          remote,
        },
      })
      return data
    } catch (error: any) {
      return handlingError(error?.response?.data?.message, error)
    }
  }

  const { data, isLoading } = useQuery(queryKey, fetchListJobBoards)
  const listJobBoards = data?.data
  const isListJobBoardsLoading = isLoading

  const handleSearchJobBoard = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    setTimeout(() => {
      setSearchDebounce(e.target.value)
    }, 2000)
  }

  // countries infinity
  const handleFilterByHQ = (value: string, option: any) => {
    const itemSelected = {
      id: option.value,
      name: option.label,
    }

    setSelectedHQ([...selectedHQ, itemSelected])
  }

  const removeSelectedHQ = (id: string) => {
    setSelectedHQ(selectedHQ.filter((item) => item.id !== id))
  }

  // greenskill infinity
  const handleFilterByGS = (value: string, option: any) => {
    const itemSelected = {
      id: option.value,
      name: option.label,
    }
    setSelectedGS([...selectedGS, itemSelected])
  }

  const removeSelectedGS = (id: string) => {
    setSelectedGS(selectedGS.filter((item) => item.id !== id))
  }

  // teams or function
  const handleFilterByTeam = (value: string, option: any) => {
    const itemSelected = {
      id: option.value.replace(/ /g, "%20"),
      name: option.label,
    }
    setSelectedTeams([...selectedTeams, itemSelected])
  }

  const removeSelectedTeam = (id: string) => {
    setSelectedTeams(selectedTeams.filter((item) => item.id !== id))
  }

  // teams or function
  const handleFilterByType = (value: string, option: any) => {
    const itemSelected = {
      id: option.value,
      name: option.label,
    }
    setSelectedTypes([...selectedTypes, itemSelected])
  }

  const removeSelectedType = (id: string) => {
    setSelectedTypes(selectedTypes.filter((item) => item.id !== id))
  }

  // teams or function
  const handleFilterBySector = (value: string, option: any) => {
    const itemSelected = {
      id: option.value,
      name: option.label,
    }
    setSelectedSectors([...selectedSectors, itemSelected])
  }

  const removeSelectedSector = (id: string) => {
    setSelectedSectors(selectedSectors.filter((item) => item.id !== id))
  }

  // remote filter
  const handleFilterByRemote = (event: any) => {
    if (event.target.checked) {
      setRemote(event.target.checked)
    } else {
      setRemote("")
    }
  }

  const saveJob = async (newData: any) => {
    try {
      const response = await instance.post("/job-boards/saved", newData)
      return response.data
    } catch (error) {
      throw new Error("Failed to save job")
    }
  }

  const unSaveJob = async (jobID: string) => {
    try {
      const response = await instance.delete(
        `/job-boards/saved/${userID}/${jobID}`,
      )
      return response.data
    } catch (error) {
      throw new Error("Failed to save job")
    }
  }

  const queryClient = useQueryClient()

  const addDataMutationSaveJob = useMutation(saveJob, {
    onSuccess: () => {
      queryClient.invalidateQueries("job-boards-list")
      queryClient.invalidateQueries("company-detail")
    },
  })
  const addDataMutationUnsaveJob = useMutation(unSaveJob, {
    onSuccess: () => {
      queryClient.invalidateQueries("job-boards-list")
      queryClient.invalidateQueries("company-detail")
    },
  })

  const handleSaveJob = async (newData: any) => {
    const data = {
      job_id: newData.id,
      job_name: newData.name,
      user_id: userID,
    }

    try {
      await addDataMutationSaveJob.mutateAsync(data)
      message.success("Success save job")
    } catch (error) {
      errorMessage("Failed to save job")
    }
  }

  const handleUnSaveJob = async (jobID: string) => {
    try {
      await addDataMutationUnsaveJob.mutateAsync(jobID)
      message.success("Success unsave job")
    } catch (error) {
      errorMessage("Failed to unsave job")
    }
  }

  const optionLocation = countriesFilter?.data?.map((item: any) => ({
    value: item.id,
    label: item.name,
  }))
  const optionGS = gsFilter?.data?.map((item: any) => ({
    value: item.id,
    label: item.name,
  }))
  const optionTeams = teamsFilter?.data?.map((item: any) => ({
    value: item,
    label: item,
  }))
  const optionTypes = typesFilter?.data?.map((item: any) => ({
    value: item.id,
    label: item.name,
  }))
  const optionSectors = sectorsFilter?.data?.map((item: any) => ({
    value: item.id,
    label: item.name,
  }))

  return {
    jobBoardResp: {
      listJobBoards,
      isListJobBoardsLoading,
      setPage,
      setLimit,
      page,
      limit,
      handleSearchJobBoard,
      search,
      searchDebounce,
      setSearch,
    },
    filterHandler: {
      handleFilterByHQ,
      selectedHQ,
      removeSelectedHQ,
      handleFilterByGS,
      selectedGS,
      removeSelectedGS,
      handleFilterByTeam,
      selectedTeams,
      removeSelectedTeam,
      handleFilterByType,
      selectedTypes,
      removeSelectedType,
      handleFilterByRemote,
      selectedSectors,
      removeSelectedSector,
      handleFilterBySector,
      handleSaveJob,
      handleUnSaveJob,
    },

    filterResp: {
      optionLocation,
      isCountriesFiltertLoading,
      optionGS,
      isGSFiltertLoading,
      optionTeams,
      isTeamsFiltertLoading,
      optionTypes,
      isTypesFiltertLoading,
      optionSectors,
      isSectorsFiltertLoading,
    },
  }
}

export default useListJobBoards
