import { useState } from "react"
import { ClimateSolutionDataProduct, useFormProduct } from "./useFormFounder"
import { useFounderReference } from "../../founder/hooks/useFounderReference"
import { SubmitHandler } from "react-hook-form"
import { useWrite } from "../../../../hooks/generic_rest"
import { STARTUP_PRODUCT_ENDPOINTS } from "../../solution/apis/endpoint"
import { message } from "antd"
import { useQueryClient } from "react-query"
import { DETAIL_FOUNDER_QUERY_NAME } from "../../founder/hooks/useFounder"

const useProduct = (startup_id?: string) => {
  const [showFormProduct, setShowFormProduct] = useState<boolean>(false)

  const queryClient = useQueryClient()

  const formData = new FormData()

  const { handleSubmit, control, reset, watch, setValue, errors } =
    useFormProduct()

  const handleAddProduct = () => {
    setShowFormProduct(true)
  }

  const handleDeleteProduct = () => {
    setShowFormProduct(false)

    reset()
  }

  const { solutionData } = useFounderReference()

  interface SolutionData {
    label: string
    value: string
    key: string
  }

  const onSelectChangeSolution = (value: SolutionData[]) => {
    const solutions_id = value?.map((item) => item?.value)
    setValue("solutions_id", solutions_id)
  }

  const { mutate: saveCreateProduct } = useWrite<any, any>(
    STARTUP_PRODUCT_ENDPOINTS.CREATE(startup_id!),
    "post",
    () => {
      message.success("Data created successfully")
      setShowFormProduct(false)

      setTimeout(() => {
        queryClient.invalidateQueries([DETAIL_FOUNDER_QUERY_NAME])
      }, 1000)
    },
    () => message.error("An error occurred"),
  )

  const onSubmit: SubmitHandler<ClimateSolutionDataProduct> = (
    data: ClimateSolutionDataProduct,
  ) => {
    formData.append("name", data.name)
    formData.append("intended_user", data.intended_user)
    formData.append("description", data.description)
    formData.append("solutions_id", data.solutions_id.join(","))

    saveCreateProduct(formData)
  }

  const handleSave = () => {
    handleSubmit(onSubmit)()
  }

  return {
    editProductHandlers: {
      handleSubmit,
      control,
      watch,
      setValue,
      errors,

      handleAddProduct,
      handleDeleteProduct,
      showFormProduct,
      solutionData,
      onSelectChangeSolution,
      handleSave,
    },
  }
}

export default useProduct
