import { Image, Popover, Typography } from "antd"
import {
  JobApplicantImage,
  RecruiterImage,
  SavedJobImage,
} from "../../../../assets/images/index"
import { useNavigate } from "react-router-dom"
import { useContext } from "react"
import {
  ITourContext,
  TourContext,
} from "../../../../common/components/tour/TourProvider"

const { Title } = Typography

interface OrbitingProps {
  statusPrivate: any
  countPendingRequest: number
  listJobSaved: any
  listJobHistory: any
  handleNavigateToRequest: () => void
}

export const Orbiting: React.FC<OrbitingProps> = ({
  statusPrivate,
  countPendingRequest,
  listJobSaved,
  listJobHistory,
  handleNavigateToRequest,
}) => {
  const navigate = useNavigate()

  const {
    refs: { refRecruiterRequests, refSavedJobs, refJobAppHistory },
  } = useContext(TourContext) as ITourContext

  return (
    <div className="px-5 py-5 shadow-lg bg-white-custom rounded-xl">
      <Title level={3}>Orbiting around new opportunities</Title>

      <div className="mt-5">
        <div
          ref={refRecruiterRequests}
          className="flex items-center gap-3 px-3 py-2 mt-5 bg-white shadow-lg rounded-xl"
        >
          <Image
            src={RecruiterImage}
            preview={false}
            alt={`logo-reqruiter`}
            width={45}
          />

          <div className="">
            <Popover
              content={
                statusPrivate?.discover
                  ? "Check recruiters request"
                  : "Your CV is not discoverable"
              }
            >
              <p
                onClick={() => handleNavigateToRequest()}
                className="p-0 m-0 text-base font-bold capitalize cursor-pointer text-secondary hover:font-medium"
              >
                Recruiters’ requests
              </p>
            </Popover>
            <div className="flex items-center gap-7">
              <p className="p-0 m-0 text-xs">
                Respond to{" "}
                <span className="font-medium">{countPendingRequest}</span>{" "}
                pending requests
              </p>
            </div>
          </div>
        </div>
        <div
          ref={refSavedJobs}
          className="flex items-center gap-3 px-3 py-2 mt-5 bg-white shadow-lg rounded-xl"
        >
          <Image
            src={SavedJobImage}
            preview={false}
            alt={`logo-reqruiter`}
            width={45}
          />

          <div className="">
            <p
              onClick={() => navigate("history")}
              className="p-0 m-0 text-base font-bold capitalize cursor-pointer text-secondary hover:font-medium"
            >
              Saved jobs
            </p>
            <div className="flex items-center gap-7">
              <p className="p-0 m-0 text-xs">
                You have
                <span className="font-medium">
                  {" "}
                  {listJobSaved?.list?.length ?? 0}
                </span>{" "}
                saved jobs which are still active{" "}
              </p>
            </div>
          </div>
        </div>
        <div
          ref={refJobAppHistory}
          className="flex items-center gap-3 px-3 py-2 mt-5 bg-white shadow-lg rounded-xl"
        >
          <Image
            src={JobApplicantImage}
            preview={false}
            alt={`logo-reqruiter`}
            width={45}
          />

          <div className="flex-shrink">
            <p
              onClick={() => navigate("history")}
              className="p-0 m-0 text-base font-bold capitalize cursor-pointer text-secondary hover:font-medium"
            >
              Job applications history
            </p>
            <div className="flex items-center gap-7">
              <p className="p-0 m-0 text-xs">
                You applied to
                <span className="font-medium">
                  {" "}
                  {listJobHistory?.length ?? 0}
                </span>{" "}
                jobs in the past 6 months
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
