import React from "react"
import { PageHeader } from "../../../common/layout"
import { HomeOutlined, CloseOutlined } from "@ant-design/icons"
import { Segmented, Select, Tag } from "antd"
import { CustomSearch } from "../../../common/components"
import { Category } from "../types/common"
import { ApiResponsePaginated2 } from "../../../common/types/ApiResponse"
import { InfiniteData } from "react-query"
import { ParamName } from "../hooks/useSkillsPage"

interface SkillListHeaderProps {
  totalSkills: number
  setParam: (paramName: ParamName, value: string) => void
  viewType?: string
  sort_rank?: string
  price?: string
  availability?: string
  isLoading?: boolean
  data: InfiniteData<ApiResponsePaginated2<Category>> | undefined
  limitCategories?: number
  selectedCategories: string[]
  setPage: (value: React.SetStateAction<number>) => void
  setSelectedCategories: (value: React.SetStateAction<string[]>) => void
}

const SkillListHeader = ({
  totalSkills,
  setParam,
  viewType,
  sort_rank,
  price,
  availability,
  isLoading,
  data,
  limitCategories,
  selectedCategories,
  setPage,
  setSelectedCategories,
}: SkillListHeaderProps) => {
  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "Skills Galaxy",
    },
    {
      title: "Green Skills",
    },
    {
      path: "/skill/green-skills",
      title: "Skills Library",
    },
  ]

  const handleChangeCategories = (group: Category, checked: boolean) => {
    if (checked) {
      setSelectedCategories((selectedGrpId) => [...selectedGrpId, group?.id])
    } else {
      setSelectedCategories((selectedGrpId) =>
        selectedGrpId.filter(
          (selectedGrpName) => selectedGrpName !== group?.id,
        ),
      )
    }
  }
  return (
    <PageHeader
      title={"Green Skills Library"}
      subtitle={`${totalSkills} skills`}
      breadcrumbItems={items}
    >
      <div className="mt-1">
        <Segmented
          disabled
          // value={viewType}
          value={"cards"}
          className="segmented-skilledin"
          options={[
            { label: "Bubbles", value: "bubbles" },
            { label: "Cards", value: "cards" },
          ]}
          onChange={(value) => {
            setParam("viewType", value.toString())
          }}
        />
      </div>
      <div className="flex flex-wrap lg:flex-nowrap gap-5 items-center mt-4">
        {viewType !== "bubbles" && (
          <div className="min-w-full flex-grow md:w-2/5 md:min-w-[518px]">
            <CustomSearch
              className="md:w-full"
              withEnterButton={false}
              defaultValueNull={false}
              setGreenSkillSearch={(value) => {
                setPage(1)
                setParam("search", value ?? "")
              }}
            />
          </div>
        )}
        <Select
          className={`min-w-full md:min-w-[150px] ${
            sort_rank === "" ? "" : "custom-filter-select"
          }`}
          allowClear
          clearIcon={
            <div className="bg-backdrop text-white  font-semibold">
              <CloseOutlined />
            </div>
          }
          onClear={() => {
            setPage(1)
            setParam("sort_rank", "")
          }}
          onChange={() => {
            setPage(1)
          }}
          onSelect={(value) => {
            setParam("price", "")
            setParam("sort_rank", value)
          }}
          placeholder="Ranking"
          value={sort_rank === "" ? undefined : sort_rank}
          options={[
            { value: "asc", label: "Ascending" },
            { value: "desc", label: "Descending" },
          ]}
        />
        <Select
          className={`min-w-full md:min-w-[150px] ${
            price === "" ? "" : "custom-filter-select"
          }`}
          allowClear
          clearIcon={
            <div className="bg-backdrop text-white  font-semibold">
              <CloseOutlined />
            </div>
          }
          onClear={() => {
            setPage(1)
            setParam("price", "")
          }}
          placeholder="Price Filter"
          // disabled
          onChange={() => {
            setPage(1)
          }}
          onSelect={(value) => {
            setParam("price", value)
            setParam("sort_rank", "")
          }}
          value={price === "" ? undefined : price}
          options={[
            {
              value: "high-to-low",
              label: "High to Low",
            },
            {
              value: "low-to-high",
              label: "Low to High",
            },
            { value: "<40", label: "<40" },
            { value: "41-80", label: "41-80" },
            { value: "81-120", label: "81-120" },
            { value: "121-150", label: "121-150" },
            { value: ">150", label: ">150" },
          ]}
        />
        <Select
          className={`min-w-full md:min-w-[150px] !text-white ${
            availability === "" ? "" : "custom-filter-select"
          }`}
          // disabled
          allowClear
          clearIcon={
            <div className="bg-backdrop text-white  font-semibold">
              <CloseOutlined />
            </div>
          }
          onClear={() => {
            setPage(1)
            setParam("availability", "")
          }}
          placeholder="Availability"
          onChange={() => {
            setPage(1)
          }}
          onSelect={(value) => {
            setParam("availability", value)
          }}
          value={availability === "" ? undefined : availability}
          options={[
            {
              value: "coming-soon",
              label: "Coming Soon",
            },
            {
              value: "start-today",
              label: "Start Today",
            },
          ]}
        />
      </div>

      <div className="mt-4 relative w-full">
        {isLoading ? (
          <div className="w-full flex gap-3 flex-wrap">
            {Array.from({ length: 12 }).map(() => (
              <div
                key={Math.random()}
                className="w-20 h-8 rounded-full animate-pulse bg-slate-200"
              ></div>
            ))}
          </div>
        ) : (
          <div className="w-full flex gap-3 flex-wrap">
            <Tag.CheckableTag
              checked={selectedCategories?.length === 0}
              onClick={() => {
                setPage(1)
                setSelectedCategories([])
              }}
              className="border-gray-300 border-[0.5px] custom-selected-tag active:text-white rounded-tag"
            >
              All
            </Tag.CheckableTag>
            {data?.pages
              ?.flatMap((page) => page?.data?.list?.slice(0, limitCategories))
              ?.map((group) => (
                <Tag.CheckableTag
                  className="border-gray-300 border-[0.5px] custom-selected-tag active:text-white rounded-tag truncate w-auto"
                  key={group?.id}
                  checked={selectedCategories?.includes(group?.id)}
                  onChange={(checked) => handleChangeCategories(group, checked)}
                >
                  {group?.name}
                </Tag.CheckableTag>
              ))}
          </div>
        )}
      </div>
    </PageHeader>
  )
}

export default SkillListHeader
