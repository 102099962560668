import { useMsal } from "@azure/msal-react"
import { useParams } from "react-router-dom"
import useSingleCompany from "../../hooks/companies/useSingleCompany"
import { HomeOutlined } from "@ant-design/icons"
import { PageContent } from "../../../../common/layout"
import {
  DetailCompanyContentSection,
  DetailCompanyRightSection,
  DetailCompanyTopSection,
} from "../../components/companies/DetailCompanySection"
import useListJobBoards from "../../hooks/jobBoards/useListJobBoard"

const DetailCompany = () => {
  const { id } = useParams()
  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()

  const { companyResp, filterHandler, filterResp } = useSingleCompany(
    id!,
    activeAccount?.localAccountId!,
  )

  const { filterHandler: filterHandlerJobBoard } = useListJobBoards(
    activeAccount?.localAccountId!,
  )

  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "Jobs Galaxy",
    },
    {
      path: "/job/companies",
      title: "Companies",
    },
    {
      path: `/job/companies/${id}`,
      title: companyResp?.detailCompany?.name,
    },
  ]

  return (
    <PageContent
      content={
        <DetailCompanyContentSection
          companyResp={companyResp}
          filterHandlerJobBoard={filterHandlerJobBoard}
        />
      }
      header={
        <DetailCompanyTopSection
          companyResp={companyResp}
          breadcrumbsItems={items}
          filterHandler={filterHandler}
        />
      }
      contentRightSidebar={
        <DetailCompanyRightSection
          filterHandler={filterHandler}
          filterResp={filterResp}
        />
      }
    />
  )
}

export default DetailCompany
