import React, { useContext, useMemo } from "react"
import { Avatar, Badge, Dropdown, Select } from "antd"
import { NotificationBellDropdown } from "../../../modules/notifications/components"
import {
  SearchOutlined,
  ShoppingCartOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
} from "@ant-design/icons"
import { AccountContext, CartContext } from "../PageLayout"
import { useNavigate } from "react-router-dom"
import { userActions } from "./menuItems"
import { AuthenticatedTemplate } from "@azure/msal-react"
import { useHeader } from "../hooks/useCustomHeader"
import { useQueryClient } from "react-query"
import { DollarOutlined } from "@ant-design/icons"

interface AuthenticatedActionsProps {
  onOpenDrawerItem: React.MouseEventHandler<HTMLDivElement> | undefined
  currencies?: {
    code: string
    exchange: number
    logo?: string | undefined
  }[]
  currency: string | null
  setCurrency: React.Dispatch<React.SetStateAction<string | null>>
}

const AuthenticatedActions: React.FC<AuthenticatedActionsProps> = ({
  onOpenDrawerItem,
  currency,
  setCurrency,
  currencies,
}) => {
  const navigate = useNavigate()
  const cartsContext = useContext(CartContext)
  const account = useContext(AccountContext)
  const { handleLogoutRedirect } = useHeader()
  const origin = "https://media.licdn.com/"
  const queryClient = useQueryClient()

  const extraMenuItem = useMemo(
    () => ({
      key: "6",
      label: <div onClick={handleLogoutRedirect}>Logout</div>,
      icon: (
        <LogoutOutlined
          className="!text-base !mr-4"
          onClick={handleLogoutRedirect}
        />
      ),
    }),
    [],
  )

  return (
    <AuthenticatedTemplate>
      <div className="w-full flex gap-x-5 items-center justify-end">
        <SearchOutlined className="text-base hidden" />
        <NotificationBellDropdown />

        <Badge
          count={cartsContext?.carts?.data?.data?.products?.length || 0}
          size="small"
          offset={[3, 0]}
        >
          <ShoppingCartOutlined
            className="text-xl cursor-pointer pb-0.5"
            onClick={() => navigate("/cart")}
          />
        </Badge>
        {currencies && (
          <div className="no-bg-border">
            <Select
              onSelect={(e) => {
                localStorage.setItem("currency_code", e)
                setCurrency(e)
                queryClient.invalidateQueries(["carts"])
                queryClient.invalidateQueries(["job-role-skill"])
                queryClient.invalidateQueries(["fetch-green-skill-levels"])
                queryClient.invalidateQueries(["workshops"])
                queryClient.invalidateQueries(["fetch-green-skills"])
              }}
              className=" w-[6rem] rounded-md bg-transparent"
              popupClassName="m-0 p-0"
              defaultValue={currency || "sgd"}
              options={currencies?.map((item) => ({
                label: (
                  <div className=" flex gap-2 items-center">
                    {item?.logo ? (
                      <img
                        className=" w-4 h-4 rounded-full object-cover"
                        style={{ border: "0.5px solid #36505E" }}
                        src={item?.logo}
                      />
                    ) : (
                      <DollarOutlined className=" text-[1.1rem]" />
                    )}
                    <div className=" uppercase">{item.code}</div>
                  </div>
                ),
                value: item.code,
              }))}
            />
          </div>
        )}
        <Dropdown
          menu={{
            items: Array.isArray(userActions)
              ? [...userActions, extraMenuItem]
              : [],
            selectable: true,
          }}
          placement="bottomRight"
          arrow
        >
          {account?.data?.data?.profile_picture ? (
            <img
              src={
                account?.data?.data?.profile_picture?.startsWith(origin)
                  ? account?.data?.data?.profile_picture ||
                    account?.data?.data?.avatar
                  : `${
                      account?.data?.data?.profile_picture ||
                      account?.data?.data?.avatar
                    }?${new Date().getTime()}`
              }
              alt="profile"
              className="h-[33px] w-[33px] rounded-full object-contain bg-white  cursor-pointer"
              style={{ border: "1px solid #D4F5C5" }}
              onError={(e: any) => {
                e.target.onerror = null
                e.target.src = account?.data?.data?.avatar
              }}
            />
          ) : (
            <Avatar
              size={35}
              src={account?.data?.data?.avatar}
              className="cursor-pointer"
              style={{ border: "1px solid #D4F5C5" }}
            />
          )}
        </Dropdown>

        <div className="lg:hidden flex gap-3">
          <div
            className="lg:hidden text-xl cursor-pointer gap-3 mb-1"
            onClick={onOpenDrawerItem}
          >
            <MenuUnfoldOutlined />
          </div>
        </div>
      </div>
    </AuthenticatedTemplate>
  )
}

export default AuthenticatedActions
