import React from "react"
import {
  Header,
  Content as ContentBuilder,
  Sidebar,
} from "../components/cv-builder"
import {
  CareerAspiration,
  Scales,
  DoubleGear,
  Lamp,
  Compass,
} from "../../../assets/images"
import { Layout, Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { useMenuHeader } from "../hooks/cv-builder/useMenuHeader"
import { useMenuSkill } from "../hooks/cv-builder/useMenuSkill"
import { useProfile } from "../hooks/cv-builder/useProfile"

const Profile = () => {
  const { active, handleSelectMenu } = useMenuHeader(menu)
  const { active: activeMenuSkill, handleSelectMenu: handleSelectMenuSkill } =
    useMenuSkill(menuSkills)
  const { account, loadingAccount } = useProfile()

  return (
    <Layout className="h-screen">
      <Header active={active} handleSelectMenu={handleSelectMenu} menu={menu} />
      <Layout className=" mt-[5rem]">
        {loadingAccount ? (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          </div>
        ) : (
          <>
            <Sidebar
              active={active}
              activeSubMenu={activeMenuSkill}
              subMenu={menuSkills}
              handleSelectSubMenu={handleSelectMenuSkill}
              data={account}
            />
            <Layout
              className={`h-full md:ml-[18.5rem] mt-5 md:mt-0 px-5 relative w-full md:w-[calc(100%-18.5rem)] md:pr-5 md:pl-3`}
            >
              <Layout.Content className="">
                {/* overflow-auto */}
                <ContentBuilder
                  active={active}
                  activeSubMenu={activeMenuSkill}
                  data={account}
                />
              </Layout.Content>
            </Layout>
          </>
        )}
      </Layout>
    </Layout>
  )
}

export default Profile

const menu = [
  { key: "about_me", label: "about me" },
  { key: "experience", label: "experience" },
  { key: "education", label: "education" },
  { key: "skills", label: "skills" },
  { key: "certification", label: "certification" },
]

const menuSkills = [
  {
    key: "green_career_aspirations",
    label: "green career aspirations",
    icon: <CareerAspiration />,
  },
  { key: "soft_skills", label: "soft skills", icon: <Scales /> },
  { key: "hard_skills", label: "hard skills", icon: <DoubleGear /> },
  { key: "green_skills", label: "green skills", icon: <Lamp /> },
  { key: "languages", label: "languages", icon: <Compass /> },
]
