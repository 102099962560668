import { HeartFilled } from "@ant-design/icons"
import { Image, Spin } from "antd"
import React from "react"
// import { useNavigate } from "react-router-dom";
import { Moon } from "../../../assets/images"
import SkillCard from "../../skillGalaxy/components/SkillCard"
import { FavoriteSkillInfinite } from "../../skillGalaxy/types/common"
import {
  useFavoriteSkillsPaginated,
  useRemoveFavoriteSkill,
} from "../hooks/useFavoriteSkillsPaginated"

export default function FavoriteSkills() {
  const [dataList, setDataList] = React.useState<FavoriteSkillInfinite[]>([])
  const [page, setPage] = React.useState<number>(1)
  const [total, setTotal] = React.useState<number>(0)

  const observer = React.useRef<IntersectionObserver | null>(null)
  const favoriteSkillQuery = useFavoriteSkillsPaginated({
    page,
    limit: 10,
  })
  const removeSkillFromFavoriteMutate = useRemoveFavoriteSkill()

  const lastCardElementRef = React.useCallback(
    (node: Element | null) => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          dataList.length &&
          dataList?.length < total
        ) {
          setPage((prev) => prev + 1)
        }
      })
      if (node) observer.current.observe(node)
    },

    [dataList?.length],
  )

  React.useEffect(() => {
    if (page === 1) {
      setDataList(favoriteSkillQuery?.data?.data?.data?.list || [])
      // setDataList(favoriteSkills?.data?.data || []);
      setTotal(favoriteSkillQuery?.data?.data?.data?.total_data || 0)
    } else {
      setDataList((prev: any) => [
        ...prev,
        ...(favoriteSkillQuery?.data?.data?.data?.list || []),
      ])
      setTotal(favoriteSkillQuery?.data?.data?.data?.total_data || 0)
    }
  }, [favoriteSkillQuery?.data?.data?.data?.list])

  return (
    <div className="mt-4">
      <Spin spinning={favoriteSkillQuery.isLoading} tip="loading">
        {dataList?.length === 0 ? (
          <div className="mt-4 p-5 flex justify-center flex-col">
            <Image
              src={Moon}
              preview={false}
              className="!h-[96px] object-contain mx-auto rounded-full "
            />
            <div className="mt-4 text-center">
              Your saving Green Skill will appear here
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-12 gap-6 mt-3">
            {dataList
              ?.filter((skill) => skill.is_favorite)
              ?.map((skill, index) => {
                const actionsList = []
                actionsList.push(
                  <Spin
                    spinning={
                      favoriteSkillQuery.isLoading ||
                      removeSkillFromFavoriteMutate.isLoading
                    }
                  >
                    <HeartFilled
                      key={"favorite"}
                      className="!text-primary"
                      onClick={() =>
                        removeSkillFromFavoriteMutate.mutate(skill?.id)
                      }
                    />
                  </Spin>,
                )

                if (index === dataList.length - 1) {
                  return (
                    <SkillCard
                      key={skill?.id}
                      group_name={skill?.category?.name ?? "N/A"}
                      skill={skill}
                      className={"col-span-12 md:col-span-6 lg:col-span-4"}
                      observ={lastCardElementRef}
                      onFavoriteClick={() =>
                        setDataList((prev) => {
                          return prev.map((item) => {
                            if (item?.id === skill?.id) {
                              return {
                                ...item,
                                is_favorite: true,
                              }
                            }
                            return item
                          })
                        })
                      }
                    />
                  )
                }

                return (
                  <SkillCard
                    key={skill?.id}
                    group_name={skill?.category?.name ?? "N/A"}
                    skill={skill}
                    onFavoriteClick={() =>
                      setDataList((prev) => {
                        return prev.map((item) => {
                          if (item?.id === skill?.id) {
                            return {
                              ...item,
                              is_favorite: !item?.is_favorite,
                            }
                          }
                          return item
                        })
                      })
                    }
                    className={"col-span-12 md:col-span-6 lg:col-span-4"}
                  />
                )
              })}
          </div>
        )}
      </Spin>
    </div>
  )
}
