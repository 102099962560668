import React from "react"
import { HomeOutlined } from "@ant-design/icons"
import { PageContent, PageHeader } from "../../../../common/layout"
import ListAllCommunityContent from "../components/ListAllCommunityContent"
import { useListMember } from "../hooks/useMember"

interface ListAllCommunityUserProps {}

const ListAllCommunityUser: React.FC<ListAllCommunityUserProps> = () => {
  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      path: "/community/member",
      title: "Community",
    },
    {
      title: "All Members",
    },
  ]

  const { memberData, queryMember, referenceData } = useListMember()

  return (
    <PageContent
      header={
        <PageHeader
          breadcrumbItems={items}
          title={"Sustainability Community"}
        />
      }
      content={
        <ListAllCommunityContent
          memberData={memberData}
          referenceData={referenceData}
          queryMember={queryMember}
        />
      }
    />
  )
}

export default ListAllCommunityUser
