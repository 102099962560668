import React from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Button, Divider, Image, Progress, Skeleton } from "antd"
import { useMsal } from "@azure/msal-react"
import { useSingleMyLaunchpad } from "../hooks/useSingleMyLaunchpad"
import { Moon } from "../../../assets/images"
import FiveCircleRateTS from "../../../common/components/FiveCircleRateTS"
import { DotChartOutlined } from "@ant-design/icons"
import { QuizResultChart } from "../components/QuizResultChart"
import useCareerAspiration from "../../../hooks/useCareerAspiration"

const QuizResult = () => {
  const location = useLocation()
  const score = location?.state?.score
  const { id, skill_name, level_name } = useParams()
  const navigate = useNavigate()
  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()

  const {
    // jobCareerPlan,
    isLoadingJobCareerPlan,
    levels,
    greenskillResp,
  } = useSingleMyLaunchpad(id, activeAccount?.localAccountId)

  const { greencareerResp } = useCareerAspiration()

  const jobIncludesGS = greencareerResp?.greencareers?.filter((job) =>
    job?.green_skills?.some((skill) => skill?.id === id),
  )

  function countIsAssessmentPassed(data) {
    if (!data || !data.levels) {
      return 0
    }
    let count = 0
    for (const level of data.levels) {
      if (level.user_track && level.user_track.is_assessment_passed) {
        count++
      }
    }
    return count
  }

  const passedCount = countIsAssessmentPassed(levels?.data && levels?.data[0])

  // const greenSkillData = jobCareerPlan?.job?.green_skills?.find((item) => item?.id === id);

  function nameMatchLevel(level) {
    if (level === "novice") {
      return 1
    } else if (level === "developing") {
      return 2
    } else if (level === "proficient") {
      return 3
    } else if (level === "skilled") {
      return 4
    } else {
      return 5
    }
  }

  return (
    <div className="bg-white lg:bg-transparent -mt-16">
      <div className={`p-3 lg:w-2/3 m-auto mt-16`}>
        <div
          className={`grid grid-cols-1 md:grid-cols-12 gap-y-6 md:gap-4 lg:gap-8 `}
        >
          <div
            className={`col-span-7 bg-white px-4 py-8 shadow-custom rounded-lg flex items-center flex-col relative order-1 md:-order-none`}
          >
            <h2>
              {score >= 80 ? "Congratulations!" : "You didn't make it...."}
            </h2>
            <p className={`pb-0 mb-0 text-xs`}>
              {score >= 80
                ? `You just got skilled in level ${nameMatchLevel(
                    level_name,
                  )} of`
                : `Review the materials for level ${nameMatchLevel(
                    level_name,
                  )} of`}
            </p>
            <h3>{skill_name}</h3>

            {greenskillResp?.isFetchingGreenSkill ? (
              <div className={`mt-6`}>
                <Skeleton.Image active={true} />
              </div>
            ) : (
              <Image
                src={greenskillResp?.greenskill?.image}
                alt={`skill-image`}
                preview={false}
                className={`my-5`}
                width={100}
              />
            )}

            <p className="m-3 p-3">
              {score >= 80
                ? "Download the most recent Certificate of Completion from your Roadmap"
                : "Better luck next time!"}
            </p>

            <div className={`absolute md:bottom-10 bottom-2`}>
              {score >= 80 ? (
                <div className={`flex items-center gap-5`}>
                  <Button
                    type="default"
                    className="mt-3 border-primary"
                    onClick={() =>
                      navigate(`/dashboard/skill-trajectory-plan/skill-roadmap`)
                    }
                  >
                    My Skill Roadmap
                  </Button>
                  <Button
                    type="primary"
                    className="mt-3"
                    onClick={() =>
                      navigate(
                        `/dashboard/launchpad/single-mylaunchpad/${id}`,
                        {
                          state: { is_next_level: true },
                        },
                      )
                    }
                  >
                    Start next level
                  </Button>
                </div>
              ) : (
                <Button
                  type="primary"
                  className="mt-3"
                  onClick={() =>
                    navigate(`/dashboard/launchpad/single-mylaunchpad/${id}`, {
                      state: { is_next_level: false },
                    })
                  }
                >
                  Back to Skill Learning
                </Button>
              )}
            </div>
          </div>

          <div
            className={`col-span-5 bg-[#FAFAFA] px-4 py-8 shadow-custom rounded-lg`}
          >
            <h3 className={`text-base text-center`}>Assessment Results</h3>
            <div className="mt-10 mx-auto text-center hidden md:flex justify-center">
              {isLoadingJobCareerPlan ? (
                <Skeleton.Node active={true}>
                  <DotChartOutlined
                    style={{
                      fontSize: 40,
                      color: "#bfbfbf",
                    }}
                  />
                </Skeleton.Node>
              ) : (
                <QuizResultChart score={score} />
              )}
            </div>

            <div className={`mt-10 w-2/3 mx-auto`}>
              <p>
                Your test score:
                <span
                  className={`${score >= 80 ? "text-primary" : "text-danger"}`}
                >
                  {" "}
                  {score.toFixed()}%
                </span>
              </p>
              <p>Passing score: 80%</p>
              <div className="hidden md:inline-block">
                <div className={`flex items-center gap-4 -mt-3`}>
                  <p className={`text-xs`}>Your current skill level</p>
                  {/*<FiveCircleRate circleSize={16} defaultValue={greenSkillData?.level_passed}/>*/}

                  <FiveCircleRateTS
                    defaultValue={passedCount}
                    secondValue={
                      (levels?.data &&
                      levels?.data[0]?.levels[passedCount]?.user_track
                        ?.is_assessment_passed
                        ? levels?.data &&
                          levels?.data[0]?.levels[passedCount]?.user_track
                            ?.current_module
                        : levels?.data &&
                          levels?.data[0]?.levels[passedCount]?.user_track
                            ?.current_module) > 0
                    }
                    disabled
                    circleSize={15}
                    // color={`#ff9`}
                  />
                </div>
              </div>
            </div>

            <div className={`mt-6 -mb-6 hidden md:inline-block`}>
              <Divider className={`-mb-1`} />
              {score >= 80 ? (
                <p>
                  You earned{" "}
                  <span className="p-0 m-0 font-bold">
                    {" "}
                    {score} Terrapoints{" "}
                  </span>{" "}
                  for passing the test
                </p>
              ) : (
                <p>
                  Passing score is required to earn
                  <span className="font-bold p-0 m-0"> Terrapoints</span>
                </p>
              )}
            </div>
          </div>
        </div>
        <div
          className={`p-4 shadow-custom w-full bg-[#FAFAFA] mt-8 rounded-lg`}
        >
          <p className={`font-bold`}>{`${
            jobIncludesGS && jobIncludesGS?.length > 0
              ? `This skill brings you closer to your Career Aspirations:`
              : `This skill is not a part of your Career Aspirations`
          }`}</p>

          {greencareerResp?.isFetchingGreenCareer ? (
            <Skeleton active={true} />
          ) : jobIncludesGS && jobIncludesGS?.length > 0 ? (
            <div>
              {jobIncludesGS?.map((item, index) => {
                return (
                  <div
                    className={`px-5 py-2 flex items-center justify-between rounded-lg bg-white mb-2`}
                    style={{ border: "2px solid #D0DDE5" }}
                    key={index}
                  >
                    <span>{item?.name}</span>
                    <Progress
                      strokeColor={
                        Math.round(
                          (item?.green_skills?.reduce(
                            (acc, cur) =>
                              acc +
                              (cur?.total_completed_level >
                              cur?.total_required_level
                                ? cur?.total_required_level
                                : cur?.total_completed_level),
                            0,
                          ) /
                            item?.total_green_skill_levels) *
                            100,
                        ) === 100
                          ? "86C769"
                          : "#FAC61D"
                      }
                      type="circle"
                      percent={(
                        (item?.total_passed_levels /
                          item?.total_green_skill_levels) *
                        100
                      ).toFixed()}
                      size={30}
                    />
                  </div>
                )
              })}
            </div>
          ) : (
            <div className={`flex items-center gap-6 mt-7`}>
              <Image preview={false} src={Moon} alt={"moon"} />
              <div>
                <p>Review your Career Goals in CV Builder</p>
                <Button
                  onClick={() => navigate(`/dashboard/launchpad/cv-builder`)}
                  type={`default`}
                  className={`border-primary`}
                >
                  CV Builder
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default QuizResult
