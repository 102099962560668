/* eslint-disable  */
import { message } from "antd"
import { useMutation, useQueryClient } from "react-query"
import { deletePostComment, updatePostComment } from "../apis/feed-comment"
import { CollectionType, reactToPostorComment } from "../apis/feed-reaction"
import { AccountContext } from "../../../../common/layout/PageLayout"
import { useForm } from "react-hook-form"
import React from "react"
import { ICommunityComment } from "../types/commonn"
import { updateCommentStore } from "../../store/updateCommentStore"

export const usePostComment = (comment: ICommunityComment) => {
  const queryClient = useQueryClient()
  const reactToComment = useMutation(reactToPostorComment)
  const account = React.useContext(AccountContext)
  const deleteComment = useMutation(deletePostComment)
  const updateComment = useMutation(updatePostComment)
  const { closeModal } = updateCommentStore()

  const [liked, setLiked] = React.useState<{
    liked: boolean
    likesCount: number
  }>({
    liked: comment?.is_liked,
    likesCount: 0,
  })

  const isUserComment = account?.data?.data?.id === comment?.user?.id

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      body: (comment?.body as string) ?? "",
    },
  })

  const handleReaction = async (isLiking: boolean) => {
    setLiked({
      liked: isLiking,
      likesCount: liked.likesCount + (isLiking ? 1 : -1),
    })

    await reactToComment.mutateAsync(
      {
        collection_id: comment.id,
        collection: CollectionType.COMMENT,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([
            "community-comment-list",
            comment.post_id,
          ])
          queryClient.invalidateQueries(["this-weeks-discussion"])
        },
        onError: (error: any) => {
          setLiked({
            liked: !isLiking,
            likesCount: liked.likesCount + (isLiking ? -1 : 1),
          })
          message.error({
            content:
              error?.response?.data?.message ||
              "Something went wrong whilst commenting, try again!",
          })
          console.error(error)
        },
      },
    )
  }

  // Usage
  const handleLike = () => handleReaction(true)
  const handleDislike = () => handleReaction(false)

  const handleDelete = async (
    e: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
  ) => {
    e?.stopPropagation()
    await deleteComment.mutateAsync(
      {
        id: comment?.post_id,
        commentId: comment?.id,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([
            "community-comment-list",
            comment?.post_id,
          ])
          queryClient.invalidateQueries(["this-weeks-discussion"])
          message.success("Comment deleted successfully")
        },
        onError: (error: any) => {
          message.error({
            content:
              error?.response?.data?.message ||
              "Something went wrong whilst deleting comment, try again!",
          })
          console.error(error)
        },
      },
    )
  }

  const onSubmit = async (data: any) => {
    await updateComment.mutateAsync(
      {
        id: comment?.post_id,
        commentId: comment?.id,
        comment: data,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([
            "community-comment-list",
            comment?.post_id,
          ])
          queryClient.invalidateQueries(["this-weeks-discussion"])
          message.success("Comment updated successfully")
          closeModal()
        },
        onError: (error: any) => {
          message.error({
            content:
              error?.response?.data?.message ||
              "Something went wrong whilst updating comment, try again!",
          })
          console.error(error)
        },
      },
    )
  }
  return {
    control,
    handleSubmit,
    errors,
    liked,
    isUserComment,
    handleLike,
    handleDislike,
    handleDelete,
    onSubmit,
    updateComment,
  }
}
