import { Button, Skeleton, Upload } from "antd"
import ImgCrop from "antd-img-crop"
import React from "react"
import "./custom.css"
import { Controller } from "react-hook-form"
import TextArea from "antd/es/input/TextArea"
import { RichText } from "../../../../common/components/RichText"
import { useNavigate } from "react-router-dom"

interface ArticleDetailContentProps {
  articleResponse: any
  addAction: any
  uploadImage: any
}

const ArticleDetailContent: React.FC<ArticleDetailContentProps> = ({
  articleResponse,
  addAction,
  uploadImage,
}) => {
  const navigate = useNavigate()
  return (
    <div className="space-y-5 w-full">
      <div className="bg-white rounded-xl shadow-custom-sm p-5 h-64">
        <div className="flex gap-3 relative">
          <div className="article rounded">
            {articleResponse?.isLoading || addAction.uploadImageLoading ? (
              <div
                className="!h-[220px] !w-[220px] mb-3 flex items-center flex-col justify-center rounded-xl"
                style={{ border: "1px solid lightgray" }}
              >
                <Skeleton.Image
                  active={true}
                  className="text-3xl !h-full !w-full "
                />
              </div>
            ) : (
              <div className="!w-[220px]">
                <ImgCrop modalWidth={800} aspect={10 / 10} rotationSlider>
                  <Upload
                    listType="picture-card"
                    fileList={uploadImage.file ? [uploadImage.file] : []}
                    beforeUpload={uploadImage.beforeUpload}
                    onChange={uploadImage.onChange}
                    onPreview={uploadImage.onPreview}
                    accept="image/*"
                    // style={{ width: "10% !important " }}
                  >
                    {uploadImage.file ? null : (
                      <div className="!h-full !w-full flex items-center flex-col justify-center ">
                        + Upload{" "}
                      </div>
                    )}
                  </Upload>
                </ImgCrop>
              </div>
            )}
          </div>
          <div className="mt-2 -mb-3 w-full">
            <div className="w-full h-[210px] relative">
              <div className="absolute top-0">
                <div className="flex items-center justify-between  text-xs -mt-4">
                  {articleResponse?.isLoading ? (
                    <Skeleton.Input
                      active={true}
                      className="!w-full !rounded-lg my-1"
                      size={"small"}
                    />
                  ) : (
                    <div className="flex items-center gap-2">
                      <p className="tracking-widest uppercase text-[#966F02] text-[10px]">
                        {articleResponse?.data?.green_skill_category?.name}
                      </p>
                    </div>
                  )}
                </div>

                <div className="flex items-center gap-2 flex-wrap h-8 overflow-hidden">
                  {articleResponse?.isLoading
                    ? Array.from({ length: 3 }, (_: any, index: number) => (
                        <Skeleton.Input
                          active={true}
                          key={index}
                          className="!w-1/3 !rounded-lg my-1 mr-4"
                          size={"small"}
                        />
                      ))
                    : articleResponse?.data?.green_skills?.map(
                        (skill: any, index: number) => {
                          return (
                            <div
                              key={index}
                              className="rounded-full px-3 py-1 bg-[#F5F5F5]"
                              style={{ border: "1px solid lightgray" }}
                              onClick={() =>
                                navigate(`/skill/green-skills/${skill?.id}`)
                              }
                            >
                              <p className="m-0 text-xs">{skill?.name}</p>
                            </div>
                          )
                        },
                      )}
                </div>
              </div>

              <div className="absolute top-[39%] bottom-[30%] !w-full">
                {articleResponse?.isLoading ? (
                  <>
                    <Skeleton.Input
                      active={true}
                      className="!w-full !rounded-lg my-1"
                      size={"small"}
                    />
                    <Skeleton.Input
                      active={true}
                      className="!w-full !rounded-lg my-1"
                      size={"small"}
                    />
                  </>
                ) : (
                  <Controller
                    name="title"
                    control={addAction?.control}
                    render={({ field }) => (
                      <TextArea
                        {...field}
                        id="title"
                        className="font-default rounded-lg border-none -ml-2 text-2xl font-bold -mt-3 mb-4 !w-full"
                        placeholder="Title"
                        value={field.value}
                        autoSize={{ minRows: 2, maxRows: 2 }}
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                )}
              </div>

              <div className="absolute bottom-0 w-full">
                {articleResponse?.isLoading ? (
                  <div className="flex items-center gap-3 mt-4 mb-2 justify-end">
                    <Skeleton.Button active />
                    <Skeleton.Button active />
                  </div>
                ) : (
                  <div className="flex items-center justify-end gap-3 mt-4 mb-2 ">
                    {articleResponse?.data?.status === "draft" && (
                      <Button
                        onClick={() =>
                          addAction?.handleSubmit(
                            addAction?.handleEditArticle,
                          )()
                        }
                      >
                        Save as draft
                      </Button>
                    )}
                    <Button
                      onClick={() =>
                        addAction?.handleSubmit(
                          addAction?.handlePublishArticle,
                        )()
                      }
                      // block
                      type="primary"
                    >
                      {articleResponse?.data?.status === "draft"
                        ? "Publish"
                        : "Update"}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {articleResponse?.isLoading
        ? Array.from({ length: 3 }, (_: any, index: number) => (
            <div
              key={index}
              className="bg-white-custom h-64 rounded-xl shadow-custom-sm p-5 space-y-3 w-full"
            >
              <Skeleton.Input
                active={true}
                className="!w-full !rounded-lg"
                size={"large"}
              />

              <Skeleton.Input
                active={true}
                className="!w-full !rounded-lg !h-40"
                size={"large"}
              />
            </div>
          ))
        : addAction?.sectionData?.map((section: any, index: number) => {
            return (
              <div
                key={index}
                className="bg-white-custom h-64 rounded-xl shadow-custom-sm p-5 space-y-3"
              >
                <div id={section?.section_title}>
                  <Controller
                    name="sections.section_title"
                    control={addAction?.control}
                    render={({ field }) => (
                      <TextArea
                        {...field}
                        id="sections.section_title"
                        className="font-default rounded-lg"
                        placeholder="Section Title"
                        value={section?.section_title}
                        autoSize={{ minRows: 1, maxRows: 1 }}
                        onChange={(e) => {
                          const updatedSectionData = [...addAction.sectionData]
                          updatedSectionData[index].section_title =
                            e.target.value
                          addAction.handleSectionDataChange(updatedSectionData)
                        }}
                      />
                    )}
                  />
                </div>
                <div>
                  <Controller
                    name="sections.content"
                    control={addAction?.control}
                    render={() => (
                      <RichText
                        value={section?.content}
                        onChange={(e) => {
                          const updatedSectionData = [...addAction.sectionData]
                          updatedSectionData[index].content = e
                          addAction.handleSectionDataChange(updatedSectionData)
                        }}
                        className="h-32"
                      />
                    )}
                  />
                </div>
              </div>
            )
          })}
      <div className="absolute bottom-16 w-[90%] z-40 md:w-[62.666667%] 2xl:w-[64%] bg-secondaryBlue py-2 px-3 text-end rounded-xl shadow-custom">
        <Button onClick={() => addAction?.addNewSection()} type="primary">
          Add Sections
        </Button>
      </div>
      <p className="bottom-2" ref={addAction.messagesEndRef}></p>
    </div>
  )
}

export default ArticleDetailContent
