import { Button, Divider, message, Modal, Select, Tooltip } from "antd"
import React from "react"
import useCommunityModalsStore from "../../store/useCommunityModalsStore"
import { QuestionCircleOutlined } from "@ant-design/icons"
import { GoogleCalendarLogo } from "../../../../assets/images"
import { timezones } from "../../../../constants/timezones"
import { useMutation, useQuery, useQueryClient } from "react-query"
import {
  connectAccount,
  getMentorAvailabilityList,
  updateAvailability,
} from "../apis/mentors"
import { showError } from "../../../../common/utils/utils"
import clsx from "clsx"
import { OAuthProvider } from "../../../../utils/connect-account"
import { Controller, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import CalendarAvailabilitySettingsDay from "./CalendarAvailabilitySettingsDay"
import CalendarOverrideSettings from "./CalendarOverrideSettings"
import dayjs from "dayjs"

const calendarSettingsFormSchema = z.object({
  timezone: z.string().optional(),
  days: z
    .object({
      id: z.string(),
      day: z.number(),
      enabled: z.boolean(),
      start_time: z.number(),
      end_time: z.number(),
      extend_times: z
        .object({
          id: z.string(),
          start_time: z.number(),
          end_time: z.number(),
        })
        .array()
        .optional()
        ?.nullable(),
    })
    .array(),
  day_overrides: z.record(
    z.string(),
    z
      .object({
        start_date: z.string(),
        end_date: z.string(),
      })
      .array(),
  ),
})

export type CalendarSettingsForm = z.infer<typeof calendarSettingsFormSchema>

const ModalCalendarSettings = () => {
  const calendarSettingsModal = useCommunityModalsStore(
    (state) => state.calendarSettingsModal,
  )
  const [editMode, setEditMode] = React.useState<boolean>(false)

  const availabilityQuery = useQuery(
    ["fetch-avalibility"],
    () => getMentorAvailabilityList(),
    {
      onError: () =>
        showError({
          key: "fetch-avalibility",
          message: "Error fetching availability",
        }),
    },
  )
  const availabilityData = availabilityQuery?.data?.data?.data
  const queryClient = useQueryClient()

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useForm<CalendarSettingsForm>({
    resolver: zodResolver(calendarSettingsFormSchema),
    mode: "onChange",
    defaultValues: {
      timezone: availabilityData?.timezone,
      days: availabilityData?.days,
      day_overrides: {},
    },
  })

  const days = watch("days")
  React.useEffect(() => {
    reset({
      timezone: availabilityData?.timezone,
      days: availabilityData?.days,
      day_overrides: availabilityData?.day_overrides || {},
    })
  }, [availabilityData])

  const updateAvailabilityMutation = useMutation((data: any) =>
    updateAvailability(data),
  )

  const onSubmit = async (data: CalendarSettingsForm) => {
    message.loading({
      content: "Updating availability...",
      key: "update-availability",
    })
    await updateAvailabilityMutation.mutateAsync(data, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["fetch-avalibility"])
        message.success({
          content: "Availability updated successfully",
          duration: 2,
          key: "update-availability",
        })
        setEditMode(false)
      },
      onError: () =>
        showError({
          key: "update-availability",
          message: "Something went wrong while updating availability",
        }),
    })
  }

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]

  console.log(errors)

  const items = [
    {
      name: "Connected Calendars",
      description:
        "Connect your calendar to conduct mentorship over video calls",
      children: (
        <div className="flex items-center justify-center gap-3 mt-4">
          {availabilityData?.connected_with_google && (
            <img className="w-[34px] object-contain" src={GoogleCalendarLogo} />
          )}
          {availabilityData?.connected_with_microsoft && (
            <img
              className="w-[34px] object-contain"
              src={`https://stgreenskills001.blob.core.windows.net/assets/outlock-calendar-logo.svg`}
            />
          )}
        </div>
      ),
    },
    {
      name: "Timezone",
      description: "Please, set up your mentorship hours' timezone",
      children: (
        <div className="mt-4">
          <div className="text-base">{availabilityData?.timezone}</div>
        </div>
      ),
    },
    {
      name: "Availability",
      description:
        "Set days and times when you are available for mentorship calls.",
      children: (
        <div className="mt-4">
          {availabilityData?.days
            ?.filter((item) => item?.enabled)
            ?.map((day, index) => (
              <>
                <div className="flex justify-between text-base" key={index}>
                  <div className="">{daysOfWeek[day?.day]}</div>
                  <div className="flex flex-col items-start">
                    <div className="">{formatTime(day?.start_time)}</div>
                    <div className="">{formatTime(day?.end_time)}</div>
                  </div>
                </div>
                <Divider className="!border-darkGrey !mt-0" />
              </>
            ))}
        </div>
      ),
    },
    {
      name: "Override dates",
      description: "Add specific dates, when you are not available",
      children: (
        <div className="mt-4">
          {Object.keys(availabilityData?.day_overrides ?? {})?.map(
            (day, index) => (
              <>
                <div className="flex justify-between text-base" key={index}>
                  <div className="">{dayjs(day).format("MMM D, YYYY")}</div>
                  <div className="flex flex-col gap-2">
                    {availabilityData?.day_overrides?.[day]?.map(
                      (timeRange, index) => (
                        <div className="flex gap-1 items-start" key={index}>
                          <div className="">
                            {dayjs(timeRange?.start_date).format("hh:mm A")}
                          </div>
                          <div className="">-</div>
                          <div className="">
                            {dayjs(timeRange?.end_date).format("hh:mm A")}
                          </div>
                        </div>
                      ),
                    )}
                  </div>
                </div>
                <Divider className="!border-darkGrey !mt-0" />
              </>
            ),
          )}
        </div>
      ),
    },
  ]

  return (
    <Modal
      centered
      closable={false}
      className="py-4"
      closeIcon={null}
      width={722}
      styles={{
        header: {
          background: "transparent",
          fontSize: "21px",
          fontWeight: "700",
        },
      }}
      open={calendarSettingsModal.isOpen}
      title={
        <span className="text-[21px] font-semibold flex items-center justify-between gap-4">
          {"Calendar Settings"}
          <div className="flex items-center gap-3">
            {editMode && (
              <Button
                type="default"
                className=""
                onClick={() => setEditMode(false)}
              >
                {"Go Back"}
              </Button>
            )}
            <Button
              type="primary"
              className=""
              disabled={editMode && !isDirty}
              onClick={
                editMode
                  ? () => handleSubmit(onSubmit)()
                  : () => setEditMode(true)
              }
            >
              {editMode ? "Save Changes" : "Edit My Calendar"}
            </Button>
          </div>
        </span>
      }
      onCancel={() => calendarSettingsModal.close()}
      onOk={() => {}}
      footer={null}
    >
      <form className="grid grid-cols-2 gap-10 mt-10">
        {editMode ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-1 ">
              <div className="col-span-full text-lg font-semibold flex items-center gap-3">
                Connected Calendars{" "}
                <Tooltip title="Connect third-party calendars to keep track of your schedule in your chosen calendar.">
                  <QuestionCircleOutlined className="cursor-pointer" />
                </Tooltip>
              </div>
              <div className="ring-1 ring-[#9CAFB9] p-4 bg-[#F6F6F6] rounded-t-md mt-4">
                <div className="flex justify-between items-center">
                  <img
                    className="w-[34px] object-contain"
                    src={GoogleCalendarLogo}
                  />
                  <Button
                    className={clsx({
                      "!border-primary !text-primary":
                        availabilityData?.connected_with_google,
                    })}
                    onClick={
                      availabilityData?.connected_with_google
                        ? undefined
                        : () => connectAccount(OAuthProvider.GOOGLE)
                    }
                  >
                    {availabilityData?.connected_with_google
                      ? "Connected"
                      : "Connect"}
                  </Button>
                </div>
                <div className="text-lg font-semibold mt-4">
                  Google Calendar
                </div>
                <div className="mt-4 text-base">
                  Sync with Google Calendar to manage your availability with
                  ease
                </div>
              </div>
              <div className="ring-1 ring-[#9CAFB9] p-4 bg-[#F6F6F6] rounded-b-md">
                <div className="flex justify-between items-center">
                  <img
                    className="w-[34px] object-contain"
                    src={`https://stgreenskills001.blob.core.windows.net/assets/outlock-calendar-logo.svg`}
                  />
                  <Button
                    className={clsx({
                      "!border-primary !text-primary":
                        availabilityData?.connected_with_microsoft,
                    })}
                    onClick={
                      availabilityData?.connected_with_microsoft
                        ? undefined
                        : () => connectAccount(OAuthProvider.MICROSOFT)
                    }
                  >
                    {availabilityData?.connected_with_microsoft
                      ? "Connected"
                      : "Connect"}
                  </Button>
                </div>
                <div className="text-lg font-semibold mt-4">
                  Microsoft Outlook Calendar
                </div>
                <div className="mt-4 text-base">
                  Connect with Microsoft Outlook Calendar to stay organized
                </div>
              </div>
            </div>
            <div className="">
              <div className="text-lg font-semibold ">Timezone</div>
              <Controller
                name="timezone"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    allowClear={false}
                    placeholder="Select Timezone"
                    className="w-full rounded-md mt-4"
                    showSearch
                    options={timezones.map((item) => ({ value: item }))}
                    filterOption={(input, option) =>
                      option?.value
                        ? option?.value
                            ?.toLowerCase()
                            ?.indexOf(input?.toLowerCase()) >= 0
                        : false
                    }
                  />
                )}
              />
            </div>
            <div className="col-span-2">
              <div className=" text-lg font-semibold">Availability</div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mt-4">
                {days?.map((day, index) => (
                  <CalendarAvailabilitySettingsDay
                    key={index}
                    // @ts-ignore
                    control={control}
                    // @ts-ignore
                    day={day}
                    index={index}
                    watch={watch}
                    setValue={setValue}
                    getValues={getValues}
                  />
                ))}
              </div>
            </div>
            <div className="col-span-full">
              <div className="col-span-full text-lg font-semibold">
                Override Dates
              </div>
              <CalendarOverrideSettings
                // @ts-ignore
                control={control}
                setValue={setValue}
                watch={watch}
                getValues={getValues}
              />
            </div>
          </>
        ) : (
          <>
            <div className="col-span-full grid grid-cols-1 md:grid-cols-2 gap-10">
              {items?.map((item, index) => (
                <div
                  key={index}
                  className="ring-1 ring-[#9CAFB9] p-4 bg-[#F6F6F6] rounded-md max-h-[300px] scrollbar-hide overflow-y-auto"
                >
                  <div className="flex items-center justify-between w-full mt-auto">
                    <div className="text-lg font-semibold">{item?.name}</div>
                  </div>
                  {!item?.children && (
                    <div className="mt-6 text-center">{item?.description}</div>
                  )}
                  {item?.children && item?.children}
                </div>
              ))}
            </div>
          </>
        )}
      </form>
    </Modal>
  )
}

const formatTime = (time: number) =>
  `${String(time).padStart(4, "0").slice(0, 2)}:${String(time)
    .padStart(4, "0")
    .slice(2, 4)} PM`

export default ModalCalendarSettings
