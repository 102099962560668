import { Button, Divider, Skeleton, Typography } from "antd"
import { useContext } from "react"
import { AccountContext, CartContext } from "../../../common/layout/PageLayout"
import { formatNumber } from "../../../utils/formatNumber"

export const CartSummary = ({
  checkout,
  handleRemove,
  isLoadingRemove,
  handleProceed,
  isLoadingProceed,
}) => {
  const { carts, isLoading } = useContext(CartContext)
  const account = useContext(AccountContext)

  return (
    <div className="col-span-full md:col-span-1 bg-white p-5">
      <Typography.Title level={3}>Green Cart Summary</Typography.Title>
      {isLoading ? (
        <Skeleton active className=" mt-5" />
      ) : (
        <>
          {carts?.data?.data?.active_order?.link && (
            <div className="mt-5 mb-7">
              <div className=" font-semibold">
                Oops! You didn’t pay for your past order
              </div>
              <Divider className="my-2" />
              <div>
                You have an active unpaid order. Pay for the past order, or
                cancel it to proceed with the current one.
              </div>
              <div className="flex gap-5 my-5">
                <Button
                  danger
                  className="w-full"
                  onClick={() =>
                    handleRemove(carts?.data?.data?.active_order?.id)
                  }
                  loading={isLoadingRemove}
                >
                  Cancel past order
                </Button>
                <Button type="primary" className="w-full">
                  <a
                    className=" hover:text-white"
                    href={carts?.data?.data?.active_order?.link}
                  >
                    Pay for past order
                  </a>
                </Button>
              </div>
            </div>
          )}
          <div className="my-5">
            <div className=" font-semibold">Current Order</div>
            <Divider className="my-2" />
            <div className=" flex justify-between">
              <div>Order Total:</div>
              <div>
                <span className=" uppercase">
                  {carts?.data?.data?.currency?.code || "sgd"}
                </span>{" "}
                <span>
                  {formatNumber(
                    checkout?.reduce(
                      (c, a) =>
                        c + a.price * carts?.data?.data?.currency?.exchange ||
                        1,
                      0,
                    ),
                  )}
                </span>
              </div>
            </div>
          </div>
          <div>
            <Button
              type="primary"
              className="w-full"
              disabled={
                !account?.data?.data || carts?.data?.data?.active_order?.link
                  ? true
                  : checkout?.reduce((c, a) => c + a.price, 0) !== 0
                    ? false
                    : true
              }
              loading={isLoadingProceed}
              onClick={() => handleProceed(checkout)}
            >
              Proceed to payment
            </Button>
          </div>
        </>
      )}
    </div>
  )
}
