import React, { useContext, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { instanceV2 } from "../../../../config/config"
import { AccountContext } from "../../../../common/layout/PageLayout"
import { AxiosResponse } from "axios"
import { PaymentMethod } from "./types"
import { message } from "antd"
import ErrorMessageBuilder from "../../../../common/components/ErrorMessageBuilder"

interface ResponseInvoice {
  data: PaymentMethod[]
  has_more: false
  total_count: number
}

export const usePaymentMethod = () => {
  const account = useContext(AccountContext)
  const queryClient = useQueryClient()
  const [stateUpdate, setStateUpdate] = useState<{
    data?: PaymentMethod
    isOpen: boolean
  }>({ data: undefined, isOpen: false })

  const [stateAdd, setStateAdd] = useState({ isOpen: false })

  const { data, isLoading } = useQuery(
    "billing-payment-methods",
    () =>
      instanceV2.get<AxiosResponse<ResponseInvoice>>(
        "/billing/payment-methods",
        {
          params: { customer_id: account?.data?.data?.customer_id },
        },
      ),
    {
      enabled: !!account?.data?.data?.customer_id,
      onError: () => {
        message.open({
          key: "billing-payment-methods",
          duration: 5,
          content: React.createElement(ErrorMessageBuilder, {
            message:
              "We encountered an issue while attempting to fetch your billing payment methods. If the problem continues, please refresh the page.",
            includeReportAt: true,
          }),
          type: "error",
        })
      },
      retry: 2,
    },
  )
  const handleOpenUpdate = (data: PaymentMethod) => {
    setStateUpdate({ data, isOpen: true })
  }
  const handleCloseUpdate = () => {
    setStateUpdate({ data: undefined, isOpen: false })
  }

  const { mutateAsync: updatePayment, isLoading: loadingUpdatePayment } =
    useMutation({
      mutationFn: async (val: any) => {
        const res = await instanceV2.patch(
          `/billing/payment-methods/id/${val?.id}`,
          val?.body,
        )
        return res
      },
      mutationKey: "update-payment",
    })

  const handleUpdate = async (val: any) => {
    message.open({
      key: "update-payment",
      content: "updating...",
      type: "loading",
    })
    try {
      const response = await updatePayment({
        body: {
          name: val.name,
          email: val.email,
          address: { country: val.country?.value },
        },
        id: stateUpdate.data?.id,
      })
      if (response) {
        queryClient.invalidateQueries(["billing-payment-methods"]).then(() => {
          setStateUpdate({ data: undefined, isOpen: false })
          message.open({
            key: "update-payment",
            content: "billing updated",
            type: "success",
          })
        })
      }
    } catch (error: any) {
      message.open({
        key: "update-payment",
        content: error?.response?.data?.data || "Internal server error",
        type: "error",
      })
    }
  }

  const { mutateAsync: deletePayment, isLoading: loadingDeletePayment } =
    useMutation({
      mutationFn: async (val: any) => {
        const res = await instanceV2.delete(
          `/billing/payment-methods/id/${val}`,
        )
        return res
      },
      mutationKey: "delete-payment",
    })

  const handleDelete = async (val: any) => {
    message.open({
      key: "delete-payment",
      content: "updating...",
      type: "loading",
    })
    try {
      const response = await deletePayment(val)
      if (response) {
        queryClient.invalidateQueries(["billing-payment-methods"]).then(() => {
          message.open({
            key: "delete-payment",
            content: "billing updated",
            type: "success",
          })
        })
      }
    } catch (error: any) {
      message.open({
        key: "delete-payment",
        content: error?.response?.data?.data || "Internal server error",
        type: "error",
      })
    }
  }

  const { mutateAsync: addPayment } = useMutation({
    mutationFn: async (val: any) => {
      const res = await instanceV2.post(`/billing/payment-methods/id/${val}`)
      return res
    },
    mutationKey: "add-payment",
  })

  const handleAdd = async (
    val: any,
    callbackSuccess: () => void,
    callbackError: (error: any) => void,
  ) => {
    try {
      const response = await addPayment(val)
      if (response) {
        queryClient.invalidateQueries(["billing-payment-methods"]).then(() => {
          queryClient
            .invalidateQueries(["billing-customer"])
            .then(() => callbackSuccess())
        })
      }
      return response
    } catch (error: any) {
      callbackError(error?.response?.data?.data || "Internal server error")
    }
  }

  return {
    data: data?.data?.data,
    isLoading,
    update: {
      handleOpenUpdate,
      handleCloseUpdate,
      stateUpdate,
      handleUpdate,
      isLoading: loadingUpdatePayment,
    },
    delete: {
      handleDelete,
      isLoading: loadingDeletePayment,
    },
    add: {
      setStateAdd,
      stateAdd,
      handleAdd,
    },
  }
}
