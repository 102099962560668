import {
  ApplicationInsights,
  ITelemetryItem,
} from "@microsoft/applicationinsights-web"
import { ReactPlugin } from "@microsoft/applicationinsights-react-js"

const reactPlugin = new ReactPlugin()

let appInsights

try {
  if (process.env.REACT_APP_DEV === "prod") {
    appInsights = new ApplicationInsights({
      config: {
        connectionString:
          process.env.REACT_APP_CONNECT_STRING_APP_INSIGHTS ?? "",
        extensions: [reactPlugin],
        enableAutoRouteTracking: true,
        disableAjaxTracking: false,
        autoTrackPageVisitTime: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: false,
        enableResponseHeaderTracking: false,
      },
    })
    appInsights.loadAppInsights()

    appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
      env.tags = env.tags || []
      env.tags["ai.cloud.role"] = "testTag"
    })
  }
} catch (error) {
  console.error("Error initializing Application Insights:", error)
}

export { reactPlugin, appInsights }
