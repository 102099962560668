import React from "react"
import { EduEdit } from "./EduEdit"
import { EduView } from "./EduView"
import { Education } from "../../../hooks/cv-builder/useEducation"

interface Props {
  data: Education
  index: number
  updateTempData: (index: number, data: Education) => void
  onRemove: (val: string) => Promise<void>
  loadingremoveEdu: boolean
  onRemoveTemp: (val: number) => void
}
export const Edu: React.FC<Props> = ({
  data,

  index,
  updateTempData,
  onRemove,
  loadingremoveEdu,
  onRemoveTemp,
}) => {
  return data.isEdit ? (
    <EduEdit data={data} index={index} updateTempData={updateTempData} />
  ) : (
    <EduView
      data={data}
      index={index}
      updateTempData={updateTempData}
      onRemove={onRemove}
      loadingremoveEdu={loadingremoveEdu}
      onRemoveTemp={onRemoveTemp}
    />
  )
}
