import React, { useState } from "react"
import useCommunityModalsStore from "../../store/useCommunityModalsStore"
import { Controller, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { Button, Form, Input, message, Modal } from "antd"
import ProfileInReview from "./ProfileInReview"
import { Role } from "../stores/useRoleStore"
import { useCountriesInfinity } from "../../../../hooks/useCountries"
import { useGreenSkillCategoriessInfinity } from "../../../../hooks/useInfinityGSCategory"
import { useInfinityMentorShipArea } from "../../../../hooks/useInfinityMentorShipArea"
import { InfiniteAutoComplete } from "../../../../common/components/InfiniteAutoComplete"
import { CloseOutlined } from "@ant-design/icons"
import { useMutation, useQueryClient } from "react-query"
import instance from "../../../../config/config"
import { showError } from "../../../../common/utils/utils"

export const schema = z.object({
  linkedin_link: z.string().url(),
  monthly_availability: z
    .string()
    .regex(/^[1-9]\d*$/, {
      message: "The field must be a positive number.",
    })
    .refine((val) => !/\s/.test(val), {
      message: "The field must not contain spaces.",
    }),
  location: z.object({
    id: z.string().min(1, { message: "Skill category is required." }),
    name: z.string().min(1, { message: "Skill category is required." }),
  }),
  mentorship_areas_id: z
    .object({
      id: z.string().min(1, { message: "Skill category is required." }),
      name: z.string().min(1, { message: "Skill category is required." }),
    })
    .array(),
  domain_expertises_id: z
    .object({
      id: z.string().min(1, { message: "Skill category is required." }),
      name: z.string().min(1, { message: "Skill category is required." }),
    })
    .array(),
  motivation_letter: z.string().min(1, { message: "Motivation is required." }),
})

type TMentorApplication = z.infer<typeof schema>

type CreateMentorData = {
  linkedin_link: string
  monthly_availability: number
  location: string
  mentorship_areas_id: string[]
  domain_expertises_id: string[]
  motivation_letter: string
}

const ModalMentorApplication = ({ is_pending }: { is_pending: boolean }) => {
  const mentorApplicationModal = useCommunityModalsStore(
    (state) => state.mentorApplicationModal,
  )
  const [isSubmittedSuccess, setIsSubmitSuccess] = useState(false)
  const queryClient = useQueryClient()

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { isDirty, errors },
  } = useForm<TMentorApplication>({
    resolver: zodResolver(schema),
    defaultValues: {
      linkedin_link: "",
      monthly_availability: undefined,
      location: undefined,
      mentorship_areas_id: [],
      motivation_letter: "",
    },
  })

  const selectedDomains = getValues("domain_expertises_id")
  const selectedAreas = getValues("mentorship_areas_id")

  const requestToBecomeMentor = useMutation((data: CreateMentorData) =>
    instance.post(`mentor-requests`, data),
  )

  const handleRemoveDomain = (id: string) => {
    const updatedSelectedSector = selectedDomains.filter(
      (selectedGreenSkillIds) => selectedGreenSkillIds?.id !== id,
    )
    setValue("domain_expertises_id", updatedSelectedSector)
  }

  const handleRemoveArea = (id: string) => {
    const updatedSelectedSector = selectedAreas.filter(
      (selectedGreenSkillIds) => selectedGreenSkillIds?.id !== id,
    )
    setValue("mentorship_areas_id", updatedSelectedSector)
  }

  const onSubmit = async (data: TMentorApplication) => {
    message.loading({
      content: "Submitting application...",
      key: "mentorApplication",
    })

    await requestToBecomeMentor.mutateAsync(
      {
        ...data,
        monthly_availability: parseInt(data.monthly_availability),
        domain_expertises_id: selectedDomains.map((item) => item.id),
        mentorship_areas_id: selectedAreas.map((item) => item.id),
        location: data.location.id,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(["profile-status"])
          message.success({
            content: "Mentorship Application submitted successfully",
            key: "mentorApplication",
          })
          mentorApplicationModal.close()
        },
        onError: () => {
          showError({
            message:
              "Something went wrong whilst submitting your mentorship application. Please try again.",
            key: "mentorApplication",
          })
        },
      },
    )

    setIsSubmitSuccess(true)
  }
  return (
    <Modal
      centered
      width={666}
      open={mentorApplicationModal.isOpen}
      onCancel={() => mentorApplicationModal.close()}
      onOk={() => {}}
      footer={null}
    >
      {!isSubmittedSuccess && !is_pending ? (
        <>
          <div className="text-center">
            <h2 className="text-2xl font-bold">Mentor Application</h2>
          </div>

          <Form className="relative my-6" layout="vertical">
            <Form.Item
              validateStatus={errors.linkedin_link ? "error" : ""}
              help={errors.linkedin_link?.message}
            >
              <Controller
                name="linkedin_link"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    id="linkedin_link"
                    className="font-default"
                    placeholder="LinkedIn Profile"
                  />
                )}
              />
            </Form.Item>
            <Form.Item
              validateStatus={errors.monthly_availability ? "error" : ""}
              help={errors.monthly_availability?.message}
            >
              <Controller
                name="monthly_availability"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    id="monthly_availability"
                    className="font-default"
                    placeholder="Monthly Availability (hours)"
                    type="number"
                    min={0}
                  />
                )}
              />
            </Form.Item>
            <Form.Item
              validateStatus={errors.location ? "error" : ""}
              help={errors.location?.message}
            >
              <Controller
                name="location"
                control={control}
                render={({ field }) => {
                  return (
                    <InfiniteAutoComplete
                      {...field}
                      useDataHook={useCountriesInfinity}
                      className="!w-full"
                      selectedValue={field?.value}
                      placeholder="Select Location"
                      onSelect={(value, option) => {
                        field.onChange(option)
                      }}
                    />
                  )
                }}
              />
            </Form.Item>
            <Form.Item
              validateStatus={errors.domain_expertises_id ? "error" : ""}
              help={errors.domain_expertises_id?.message}
            >
              <Controller
                name="domain_expertises_id"
                control={control}
                render={({ field }) => {
                  return (
                    <InfiniteAutoComplete
                      useDataHook={useGreenSkillCategoriessInfinity}
                      placeholder="Select Domain"
                      className="!w-full"
                      selectedValue={undefined}
                      onSelect={(value, option) => {
                        if (selectedDomains) {
                          field.onChange([...selectedDomains, option])
                        } else {
                          field.onChange([option])
                        }
                      }}
                    />
                  )
                }}
              />
              {watch("domain_expertises_id")?.length > 0 && (
                <div className="flex gap-2 mt-3">
                  <div className="flex flex-wrap items-center gap-2">
                    {watch("domain_expertises_id")?.map((domain) => (
                      <div
                        key={domain?.id}
                        className="flex items-center px-2 rounded shadow-md bg-secondaryYellow gap-x-1"
                        style={{ border: "1px solid #966F02" }}
                      >
                        {domain?.name}
                        <CloseOutlined
                          onClick={() => handleRemoveDomain(domain?.id)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </Form.Item>
            <Form.Item
              validateStatus={errors.location ? "error" : ""}
              help={errors.location?.message}
            >
              <Controller
                name="mentorship_areas_id"
                control={control}
                rules={{ required: "Mentorship Area is required" }}
                render={({ field }) => {
                  return (
                    <InfiniteAutoComplete
                      useDataHook={useInfinityMentorShipArea}
                      placeholder="Select Mentorship Area"
                      className="!w-full"
                      selectedValue={undefined}
                      onSelect={(value, option) => {
                        if (selectedAreas) {
                          field.onChange([...selectedAreas, option])
                        } else {
                          field.onChange([option])
                        }
                      }}
                    />
                  )
                }}
              />
              {watch("mentorship_areas_id")?.length > 0 && (
                <div className="flex gap-2 mt-3">
                  <div className="flex flex-wrap items-center gap-2">
                    {watch("mentorship_areas_id")?.map((area) => (
                      <div
                        key={area?.id}
                        className="flex items-center px-2 rounded shadow-md bg-secondaryYellow gap-x-1"
                        style={{ border: "1px solid #966F02" }}
                      >
                        {area?.name}
                        <CloseOutlined
                          onClick={() => handleRemoveArea(area?.id)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </Form.Item>

            <Form.Item
              validateStatus={errors.location ? "error" : ""}
              help={errors.location?.message}
            >
              <Controller
                name="motivation_letter"
                control={control}
                render={({ field }) => (
                  <Input.TextArea
                    {...field}
                    id="motivation_letter"
                    className="font-default"
                    placeholder="Motivation to become a mentor"
                    autoSize={{ minRows: 3, maxRows: 4 }}
                  />
                )}
              />
            </Form.Item>

            <Button
              className="rounded-md"
              block
              onClick={handleSubmit(onSubmit)}
              type="primary"
              loading={requestToBecomeMentor.isLoading}
              disabled={!isDirty}
            >
              Apply to be an Expert
            </Button>
          </Form>
        </>
      ) : (
        <ProfileInReview
          role={Role.Mentor}
          onClick={() => mentorApplicationModal.close()}
        />
      )}
    </Modal>
  )
}

export default ModalMentorApplication
