import React from "react"
import { HomeOutlined, LoadingOutlined } from "@ant-design/icons"
import { Card, Spin } from "antd"
import { Breadcrumbs } from "../../../../common/components"
import { Filter } from "../../components/companies/Filter"
import { Card as CardCompany } from "../../components/companies/Card"
import { useCompanies } from "../../hooks/companies/useCompanies"
import { Moon } from "../../../../assets/images"
import { useLocation, useNavigate } from "react-router-dom"

export const ListCompanies = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const {
    dataList,
    isLoading,
    lastCardElementRef,
    dataFollow,
    handleFollowCompany,
    handleUnfollowCompany,
    loadingFollowUnfollow,
    handleAddSector,
    handleRemoveSector,
    handleAddHQ,
    handleRemoveHQ,
    handleAddSize,
    handleRemoveSize,
    handleIsFollow,
    handleSearch,
    params,
  } = useCompanies(state?.isFollowed)

  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "Jobs Galaxy",
    },
    {
      title: "Companies",
    },
  ]
  return (
    <div>
      <div className="bg-white py-4 px-6">
        <Breadcrumbs items={items} />

        <div className="my-3">
          <h1 className="font-bold">Discover Companies</h1>
        </div>
      </div>
      <div className="px-6">
        <div className="my-5">
          <Filter
            handleSearch={handleSearch}
            handleAddSector={handleAddSector}
            handleRemoveSector={handleRemoveSector}
            handleAddHQ={handleAddHQ}
            handleRemoveHQ={handleRemoveHQ}
            handleAddSize={handleAddSize}
            handleRemoveSize={handleRemoveSize}
            handleIsFollow={handleIsFollow}
            isFollow={params?.is_follow}
            selectedSector={params?.sector}
            selectedHQ={params?.hq}
            selectedSize={params?.size}
          />
        </div>
        {dataList.length === 0 && isLoading ? (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          </div>
        ) : dataList.length > 0 ? (
          <Spin
            spinning={isLoading}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
          >
            <Card className="rounded-xl shadow-md bg-[#FAFAFA] p-0">
              <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-5 auto-rows-max">
                {dataList.map((item, i) => (
                  <CardCompany
                    observ={lastCardElementRef}
                    data={item}
                    key={i}
                    isFollow={dataFollow?.includes(item.id)}
                    handleFollowCompany={handleFollowCompany}
                    handleUnfollowCompany={handleUnfollowCompany}
                    loadingFollowUnfollow={loadingFollowUnfollow}
                    onNavigate={() => navigate(`/job/companies/${item.id}`)}
                  />
                ))}
              </div>
            </Card>
          </Spin>
        ) : (
          <Spin
            spinning={isLoading}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
          >
            <div className="flex gap-3 items-center bg-white p-3 rounded-xl shadow-md">
              <img src={Moon} alt="cookies" />
              <div>{"Nothing to display"}</div>
            </div>
          </Spin>
        )}
      </div>
    </div>
  )
}
