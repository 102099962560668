import { useQuery } from "react-query"
import { handlingError } from "../../../utils/handling"
import { useMemo } from "react"
import instance from "../../../config/config"
import ErrorMessageBuilder from "../ErrorMessageBuilder"

const useFastJob = () => {
  const queryKeyRequest = useMemo(
    () => [
      "job-request",
      {
        status: "accepted",
      },
    ],
    [],
  )

  const fetchListRequest = async () => {
    try {
      const { data } = await instance.get(`/job-boards/invited/list/`, {
        params: {
          // status: "accepted",
        },
      })
      return data
    } catch (error) {
      return handlingError(error?.response?.data?.message, error)
    }
  }

  const { data: listRequest, isLoading: isLoadingRequest } = useQuery(
    queryKeyRequest,
    fetchListRequest,
    {
      onError: () => {
        message.open({
          key: "job-request",
          duration: 5,
          content: React.createElement(ErrorMessageBuilder, {
            message:
              "We encountered an issue while attempting to fetch fast jobs. If the problem continues, please refresh the page.",
            includeReportAt: true,
          }),
          type: "error",
        })
      },
      retry: 2,
    },
  )
  return {
    listRequest,
    isLoadingRequest,
  }
}

export default useFastJob
