import { Skeleton } from "antd"
// @ts-ignore
import { DateRange } from "react-date-range"
import { useEcoCommitment } from "../hooks/useEcoCommitment"
import "./customStyle.css"
import { BasicContainer } from "../../../common/components/BasicBoxContainers/BasicContainer"
import React from "react"

interface EcoCommitmentStreakProps {}

const EcoCommitmentStreak = React.forwardRef<
  HTMLDivElement,
  EcoCommitmentStreakProps
>((_, ref) => {
  const { dataEcoCommitment, state, ranges, loadingAccount, loadingLogs } =
    useEcoCommitment()

  return (
    <BasicContainer ref={ref} type="small">
      <h3 className="">Eco Commitment Streak</h3>
      {loadingAccount || loadingLogs ? (
        <Skeleton active />
      ) : (
        <>
          <div className="flex items-center justify-around -mb-10">
            <div>
              <p className="text-base">Current</p>
              <div className="flex items-center gap-2 -mt-4">
                <span className="font-bold text-lg">
                  {dataEcoCommitment?.consecutive_days}
                </span>
                <span className="text-xs">Days</span>
              </div>
            </div>
            <div>
              <p className="text-base">Record</p>
              <div className="flex items-center gap-2 -mt-4">
                <span className="font-bold text-lg">
                  {dataEcoCommitment?.longest_consecutive_days}
                </span>
                <span className="text-xs">Days</span>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-4 w-full">
            <DateRange
              editableDateInputs={false}
              moveRangeOnFirstSelection={false}
              // @ts-ignore
              ranges={[...ranges, ...state.slice(1)]}
              showSelectionPreview={false}
              showDateDisplay={false}
              className="mt-10 rounded-xl w-full"
              rangeColors={["#86C769"]}
              onRangeFocusChange={() => {}}
              onPreviewChange={() => {}}
              hoverable={false}
            />
          </div>
        </>
      )}
    </BasicContainer>
  )
})

export default EcoCommitmentStreak
