import { Button, Card, Input } from "antd"
import { SearchOutlined, CloseOutlined } from "@ant-design/icons"
import React from "react"
import { InfiniteAutoComplete } from "../../../../common/components/InfiniteAutoComplete"
import { useGreenSkillsInfinity } from "../../../../hooks/useInfiniteGS"
import ModalAddArticle from "./ModalAddArticle"

interface ListArticleFilterProps {
  queryArticleResponse: any
  totalArticle?: number
  addAction: any
}

const ListArticleFilter: React.FC<ListArticleFilterProps> = ({
  queryArticleResponse,
  totalArticle,
  addAction,
}) => {
  return (
    <div>
      <Card className="bg-white shadow-md rounded-xl py-1">
        <div className="flex justify-between items-center mb-5">
          <div className="flex items-center gap-3">
            <Button
              className={`rounded-full px-2 ${
                queryArticleResponse?.status === "publish"
                  ? "bg-secondaryYellow text-[#966F02]"
                  : ""
              }`}
              onClick={() =>
                queryArticleResponse?.handleFilterMyArticle("publish")
              }
            >
              Published
            </Button>
            <Button
              className={`rounded-full px-2 ${
                queryArticleResponse?.status === "draft"
                  ? "bg-secondaryYellow text-[#966F02]"
                  : ""
              }`}
              onClick={() =>
                queryArticleResponse?.handleFilterMyArticle("draft")
              }
            >
              Drafts
            </Button>
          </div>
          <div className="flex items-center">
            <Button
              className=""
              type={"primary"}
              onClick={() => addAction?.setOpenModal(true)}
            >
              New Article
            </Button>
          </div>
        </div>
        <div className="bg-[#FAFAFA]  py-6 px-3 mb-5 shadow-custom-sm rounded-xl">
          <div className="flex justify-between items-center">
            <div className="flex flex-col md:flex-row md:gap-6 w-full">
              <div className="flex w-full md:w-10/12">
                <div
                  className="flex w-full"
                  style={{ borderBottom: "1px solid #D0DDE5" }}
                >
                  <SearchOutlined />
                  <Input
                    onChange={(e: any) => queryArticleResponse?.handleSearch(e)}
                    bordered={false}
                    placeholder="search by title"
                  />
                </div>
              </div>
              <div className="!bg-grey-select w-full md:w-2/6 relative  mt-4 md:mt-0 flex items-center gap-3 md:gap-7">
                <div className="w-full !bg-grey-select">
                  <InfiniteAutoComplete
                    useDataHook={useGreenSkillsInfinity}
                    placeholder="Green skill"
                    className="!w-full"
                    onSelect={(value, option) => {
                      queryArticleResponse?.handleSelectSector(option)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {queryArticleResponse?.selectedGreenSkillIds &&
            queryArticleResponse?.selectedGreenSkillIds?.length > 0 && (
              <div className="mt-6 flex flex-col md:flex-row gap-2 md:w-full ">
                <div className="md:mr-4">Skill:</div>
                <div className="flex flex-wrap items-center gap-2">
                  {queryArticleResponse?.selectedGreenSkillIds?.map(
                    (sector: any, index: number) => (
                      <div
                        className="rounded flex bg-white items-center gap-2 shadow-custom-sm w-auto text-sm px-2 py-1"
                        key={index}
                      >
                        <p className="m-0">{sector?.name}</p>
                        <CloseOutlined
                          className="hover:text-danger"
                          onClick={() =>
                            queryArticleResponse?.handleRemoveSelectedSector(
                              sector.id,
                            )
                          }
                        />
                      </div>
                    ),
                  )}
                </div>
              </div>
            )}
        </div>
        <div>
          <p className="m-0">{totalArticle} articles</p>
        </div>
      </Card>

      <ModalAddArticle addAction={addAction} />
    </div>
  )
}

export default ListArticleFilter
