import { useCallback, useEffect, useRef, useState } from "react"
import { useQuery } from "react-query"
import instance from "../../../../config/config"
import { debounce } from "../../../../utils/debounce"
import {
  ApiResponse,
  PaginatedResponse,
} from "../../../../common/types/ApiResponse"
import { useMsal } from "@azure/msal-react"
import { JobRole } from "../../types/jobRole"
import { errorMessage } from "../../../../common/utils/utils"

export const useJobRoleList = () => {
  const { instance: account } = useMsal()
  const activeAccount = account.getActiveAccount()
  const [params, setParams] = useState<{
    page: number
    limit: number
    search: string
    sector?: { label: string; value: string }[]
  }>({
    page: 1,
    limit: 20,
    search: "",
    sector: undefined,
  })
  const [dataList, setDataList] = useState<JobRole[]>([])
  const [total, setTotal] = useState<number>(0)

  const { data, isLoading } = useQuery<ApiResponse<PaginatedResponse<JobRole>>>(
    ["job-lists", params],
    () =>
      instance.get(`/jobs/${activeAccount?.localAccountId || "none"}`, {
        params: {
          ...params,
          sector: params.sector?.map((item) => item.value).join(",") || "",
        },
      }),
    {
      onError: (err: any) =>
        errorMessage(err?.response?.data?.data || "internal server error", err),
    },
  )

  const handleChageParams = (newParams: any) => {
    setParams((prev) => ({ ...prev, ...newParams }))
  }

  const handleAddSector = (value: { label: string; value: string }) => {
    if (!params.sector) {
      setParams((prev) => ({ ...prev, sector: [value], page: 1 }))
    } else {
      const newsector = [...params.sector, value]
      setParams((prev) => ({ ...prev, sector: newsector, page: 1 }))
    }
  }
  const handleRemoveSector = (value: string) => {
    const newsector = params?.sector?.filter((loc) => loc.value !== value)
    setParams((prev) => ({ ...prev, sector: newsector, page: 1 }))
  }

  const handleSearch = debounce((value: string) => {
    setParams((prev) => ({ ...prev, search: value, page: 1 }))
  }, 1000)

  //   handle
  const observer = useRef<IntersectionObserver | null>(null)
  const lastCardElementRef = useCallback(
    (node: Element | null) => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          dataList.length &&
          dataList?.length < total &&
          !isLoading
        ) {
          handleChageParams({ page: params.page + 1 })
        }
      })
      if (node) observer.current.observe(node)
    },

    [dataList?.length],
  )

  useEffect(() => {
    if (!isLoading) {
      if (params.page === 1) {
        setDataList(data?.data.data?.list || [])
        setTotal(data?.data.data.total_data || 0)
      } else if (
        dataList?.[dataList?.length - 1]?.id !==
        data?.data.data.list?.[data?.data.data.list?.length - 1]?.id
      ) {
        setDataList((prev) => [...prev, ...(data?.data.data.list || [])])
        setTotal(data?.data.data.total_data || 0)
      }
    }
  }, [data?.data.data.list])
  return {
    dataList,
    isLoading,
    handleSearch,
    lastCardElementRef,
    params,
    setParams,
    handleAddSector,
    handleRemoveSector,
  }
}
