import { create } from "zustand"

export type TabDetailUserByRoleState = {
  activeTab: string
  setActiveTab: (tabName: string) => void
}

export const useTabStoreDetailUserByRole = create<TabDetailUserByRoleState>(
  (set) => ({
    activeTab: "",
    setActiveTab: (tabName) => set({ activeTab: tabName }),
  }),
)
