import React, { useMemo } from "react"
import { CirclePacking, Radar, Rose, WordCloud } from "@ant-design/plots"

interface ChartProps {
  data: any // Replace 'any' with the actual type of your data
  legend?: boolean
}

export const ChartGreenCareerAspiration: React.FC<ChartProps> = ({ data }) => {
  const getRandomColorInRange = () => {
    const randomBetween = (min: number, max: number) =>
      min + Math.floor(Math.random() * (max - min + 1))
    const randomColor = (min: number, max: number) =>
      `rgb(${randomBetween(min, max)}, ${randomBetween(
        min,
        max,
      )}, ${randomBetween(min, max)})`

    return randomColor(191, 252)
  }

  const config = useMemo(() => {
    const namedColors: Record<string, string> = {}
    return {
      autoFit: false,
      padding: [10, 10, 10, 10],
      data: data || [],
      color: ({ name }: { name: string }) => {
        if (!namedColors[name]) {
          namedColors[name] = getRandomColorInRange()
        }
        return namedColors[name]
      },
      legend: false,
      forceFit: true, // Use forceFit to adjust chart size to prevent overlapping
      circleStyle: {
        lineWidth: 1,
        stroke: "#fff",
      },
      outerRadius: 0.8,
      label: {
        formatter: (item: any) => {
          // Customize label content here
          const maxLength = 6 * item.value // Set your desired maximum length
          const content = item.data.name || "" // Assuming the name field contains the label content

          if (content.length > maxLength) {
            const truncatedContent = content.substring(0, maxLength) + "..."
            return truncatedContent
          } else {
            return content
          }
        },
      },
    }
  }, [data])

  // @ts-ignore
  return <CirclePacking {...config} />
}

export const ChartSoftSkill: React.FC<ChartProps> = ({ data, legend }) => {
  const config = useMemo(() => {
    return {
      data: data || [],
      xField: "name",
      yField: "proficiency",
      seriesField: "name",
      label: false,
      radius: 0.7,
      state: {
        active: {
          style: {
            lineWidth: 1,
            fillOpacity: 0,
          },
        },
      },
      legend: {
        position: legend ? "right" : "none",
        visible: legend || false,
      },

      interactions: [
        {
          type: "element-active",
        },
      ],
      color: ({ name }: { name: string }) => {
        const greenNames = [
          "Creative Thinking",
          "Decision Making",
          "Problem Solving",
          "Sense Making",
          "Transdisciplinary Thinking",
        ]

        const yellowNames = [
          "Building Inclusivity",
          "Collaboration",
          "Communication",
          "Customer Orientation",
          "Developing People",
          "Influence",
        ]

        const blueNames = [
          "Adaptability",
          "Digital Fluency",
          "Global Perspective",
          "Learning Agility",
          "Self Management",
        ]

        if (greenNames.includes(name)) {
          return "#86C769"
        }

        if (yellowNames.includes(name)) {
          return "#FAC61D"
        }

        if (blueNames.includes(name)) {
          return "#009ACE"
        }

        return "gray"
      },
    }
  }, [data])

  // @ts-ignore
  return <Rose {...config} />
}

export const ChartHardkill: React.FC<ChartProps> = ({ data }) => {
  const config = useMemo(() => {
    return {
      data: data || [],
      wordField: "name",
      weightField: "value",
      colorField: "name",
      wordStyle: {
        fontFamily: "Sen",
        fontSize: [8, 29],
        rotation: 0,
      },
      random: () => 0.5,
    }
  }, [data])

  // @ts-ignore
  return <WordCloud {...config} />
}

export const RadarSoftSkill: React.FC<ChartProps> = ({ data }) => {
  const config = useMemo(() => {
    return {
      data: data || [],
      xField: "name",
      yField: "proficiency",
      xAxis: {
        tickLine: null,
      },
      yAxis: {
        label: false,
        grid: {
          alternateColor: "rgba(0, 0, 0, 0.04)",
        },
        max: 3,
        min: 0,
      },
      meta: {
        proficiency: {
          min: 0,
        },
      },
      radius: 0.6,
      point: {
        size: 0,
      },
    }
  }, [data])

  // @ts-ignore
  return <Radar {...config} />
}
