import { Button, Form, Modal } from "antd"
import React from "react"
import useCommunityModalsStore from "../../store/useCommunityModalsStore"
import { Controller, useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { InfiniteAutoComplete } from "../../../../common/components/InfiniteAutoComplete"
import { useGreenSkillCategoriessInfinity } from "../../../../hooks/useInfinityGSCategory"
import { CloseOutlined } from "@ant-design/icons"
import useExpertProfile from "../hooks/useExpertProfile"

export const schema = z.object({
  green_skill_category: z
    .object({
      id: z.string().min(1, { message: "Skill category is required." }),
      name: z.string().min(1, { message: "Skill category is required." }),
    })
    .array(),
})

export type TAddNewArticle = z.infer<typeof schema>

const ModalAddNewSkill = () => {
  const addNewSkillModal = useCommunityModalsStore(
    (state) => state.addNewSkillModal,
  )
  const expertProfileQuery = useExpertProfile()
  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    formState: { isDirty, errors },
  } = useForm<TAddNewArticle>({
    resolver: zodResolver(schema),
    defaultValues: {
      green_skill_category: [],
    },
  })

  const handleRemoveSelectedGS = (id: string) => {
    const updatedSelectedSector = getValues("green_skill_category").filter(
      (selectedGreenSkillIds) => selectedGreenSkillIds?.id !== id,
    )
    setValue("green_skill_category", updatedSelectedSector)
  }

  const onSubmit = () => {}

  return (
    <Modal
      centered
      onCancel={() => addNewSkillModal.close()}
      open={addNewSkillModal.isOpen}
      footer={null}
    >
      <div className="text-center">
        <h2 className="text-2xl font-bold">Request Domains</h2>
      </div>
      <h5 className="mt-4">Approved Domains</h5>
      <div className="mt-2 flex flex-col md:flex-row gap-4 md:w-full">
        {expertProfileQuery?.data?.data?.data?.knowledge_domain?.map(
          (sector, index) => (
            <div
              className="rounded-lg ring-1  ring-dirtyBlue flex bg-[#F3F3F6] items-center gap-4 w-auto text-xs px-[15px] py-2"
              key={index}
            >
              <p className="m-0 line-clamp-1">{sector?.name}</p>
            </div>
          ),
        )}
      </div>
      <Form className="relative my-6" layout="vertical">
        <Form.Item
          className=""
          validateStatus={errors.green_skill_category ? "error" : ""}
          help={errors.green_skill_category?.message}
        >
          <Controller
            name="green_skill_category"
            control={control}
            render={({ field }) => (
              <InfiniteAutoComplete
                {...field}
                useDataHook={useGreenSkillCategoriessInfinity}
                disabled={field.value.length > 2 ? true : false}
                placeholder="Select Domain"
                className="!w-full"
                selectedValue={undefined}
                onSelect={(value, option) => {
                  field.onChange([...getValues("green_skill_category"), option])
                }}
              />
            )}
          />
        </Form.Item>
      </Form>
      {getValues("green_skill_category") && (
        <div className="mt-6 flex flex-col md:flex-row gap-4 md:w-full ">
          <div className="flex flex-wrap items-center gap-2">
            {watch("green_skill_category")?.map((sector, index) => (
              <div
                className="rounded-lg ring-1  ring-dirtyBlue flex bg-[#F3F3F6] items-center gap-4 w-auto text-xs px-[15px] py-2"
                key={index}
              >
                <p className="m-0 line-clamp-1">{sector?.name}</p>
                <CloseOutlined
                  className="text-xs cursor-pointer"
                  onClick={() => handleRemoveSelectedGS(sector.id)}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="flex justify-end mt-5">
        <Button
          className="rounded-md"
          block
          onClick={handleSubmit(onSubmit)}
          type="primary"
          loading={false}
          disabled={!isDirty}
        >
          Request
        </Button>
      </div>
    </Modal>
  )
}

export default ModalAddNewSkill
