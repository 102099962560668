import React from "react"
import { HomeOutlined } from "@ant-design/icons"
import { PageContent, PageHeader } from "../../../common/layout"
import Lottie from "lottie-react"
import PrivacyPolicyContent from "../components/PrivacyPolicyContent"
import { usePrivacyPolicy } from "../hooks/usePrivacyPlocy"
import success_payment from "../../../assets/json/success_payment.json"
import dayjs from "dayjs"

interface PrivacyPolicyProps {}

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = () => {
  const { privacyPolicyResponse } = usePrivacyPolicy()

  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "Privacy Policy",
    },
  ]

  return (
    <PageContent
      header={
        <PageHeader
          breadcrumbItems={items}
          title={"Privacy Policy"}
          // eslint-disable-next-line
          children={`Last Updated on ${dayjs(
            privacyPolicyResponse?.data?.created_at,
          ).format("DD MMM YYYY")}`}
          needButton={true}
          // eslint-disable-next-line
          buttonComponent={
            <div className="hidden md:block right-0">
              <Lottie
                animationData={success_payment}
                style={{ width: 100, height: 100 }}
              />
            </div>
          }
        />
      }
      content={<PrivacyPolicyContent data={privacyPolicyResponse} />}
    />
  )
}

export default PrivacyPolicy
