import React, { useContext } from "react"
import { PageContent, PageHeader } from "../../../../../common/layout"
import { AccountContext } from "../../../../../common/layout/PageLayout"
import UserProfilePicture from "../../../../../common/components/UserProfilePicture"
import {
  HomeOutlined,
  LinkedinOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons"
import { Divider, Tabs, TabsProps } from "antd"
import { Link } from "react-router-dom"
import { Role, useRoleStore } from "../../stores/useRoleStore"
import clsx from "clsx"
import ProfileDoesNotExist from "../../components/ProfileDoesNotExist"
import {
  profileDescriptions,
  profileDoesNotExistDescps,
  roleTabs,
} from "../../constants/data"
import useProfilesStatus from "../../hooks/useProfilesStatus"
import ModalBecomeAnExpert from "../../components/ModalBecomeAnExpert"
import ModalNewArticle from "../../components/ModalNewArticle"
import ModalEditExpertBio from "../../components/ModalEditExpertBio"
import ModalAddNewSkill from "../../components/ModalAddNewSkill"
import ModalEditCompanyInfo from "../../components/ModalEditCompanyInfo"
import { useBecomeAFounder } from "../../hooks/useFounder"
import ProfileRightSidebar from "../../components/ProfileRightSidebar"
import ModalAddCompany from "../../components/ModalAddCompany"
import ModalAddClimateSolution from "../../components/ModalAddClimateSolution"
import ModalEditFounderBio from "../../components/ModalEditFounderBio"
import useOnApplied from "../../hooks/useOnApplied"
import ModalMentorApplication from "../../components/ModalMentorApplication"
import ModalEditMentor from "../../components/ModalEditMentor"
import ModalCalendarSettings from "../../components/ModalCalendarSettings"

const MyCommunityProfile = () => {
  const { role } = useRoleStore()
  const is_profile = useProfilesStatus()

  return (
    <>
      <PageContent
        header={
          <PageHeader
            title={"My Profile"}
            titleClassName="!text-2xl !font-bold"
            breadcrumbItems={[
              {
                path: "/",
                title: <HomeOutlined className="text-gray-400" />,
              },
              {
                title: "My Profile",
              },
              {
                title: role,
              },
            ]}
          ></PageHeader>
        }
        content={<Content />}
        contentRightSidebar={<ProfileRightSidebar />}
      />
      <ModalBecomeAnExpert
        is_pending={is_profile[role]?.status === "PENDING"}
      />
      {role === Role.Expert && is_profile["Expert"]?.status === "APPROVED" && (
        <>
          <ModalEditExpertBio />
          <ModalNewArticle />
          <ModalAddNewSkill />
        </>
      )}

      {role === Role.Founder &&
        is_profile["Founder"]?.status === "APPROVED" && (
          <>
            <ModalEditCompanyInfo />
            <ModalAddCompany />
            <ModalAddClimateSolution />
            <ModalEditFounderBio />
          </>
        )}
      <ModalMentorApplication
        is_pending={is_profile["Mentor"]?.status === "PENDING"}
      />
      {role === Role.Mentor && is_profile["Mentor"]?.status === "APPROVED" && (
        <>
          <ModalEditMentor />
          <ModalCalendarSettings />
        </>
      )}
    </>
  )
}

const Content = () => {
  const account = useContext(AccountContext)
  const accountData = account?.data?.data
  const { role } = useRoleStore()

  const is_profile = useProfilesStatus()
  const becomeFounderMutation = useBecomeAFounder()

  const renderTabBar: TabsProps["renderTabBar"] = (props) => (
    <div
      className={clsx(
        "p-3 flex justify-between rounded-xl items-center gap-5",
        is_profile[role]?.status !== "APPROVED"
          ? "pointer-events-none bg-[#D9D9D9]"
          : "bg-secondaryBlue ",
      )}
    >
      {roleTabs[role]?.map((item) => (
        <div
          key={item?.key}
          onClick={(e) =>
            is_profile[role]?.status === "APPROVED"
              ? props?.onTabClick(item?.key, e)
              : undefined
          }
          className={clsx(
            "font-normal flex items-center gap-2 flex-grow justify-center px-4 py-2 cursor-pointer hover:scale-105 rounded-xl transition-all",
            {
              "hover:bg-white": props.activeKey !== item?.key,
              "bg-white text-backdrop": props.activeKey === item?.key,
            },
          )}
        >
          {React.createElement(item.customIcon, {
            className: "text-base",
          })}
          {item?.label}
        </div>
      ))}
    </div>
  )

  const onApplied = useOnApplied()

  return (
    <>
      <section className="relative p-5 bg-white rounded-xl">
        <div className="absolute top-0 left-0 w-full h-[300px] object-cover rounded-t-xl z-0">
          <img
            className="absolute top-0 left-0 w-full h-[300px] object-cover rounded-t-xl z-0"
            src={profileDescriptions[role]?.coverImage}
          />
          <UserProfilePicture
            size={180}
            className="absolute -bottom-[40px] left-[10px] z-10 shadow cursor-none"
          />
          <Link
            to={"/dashboard/launchpad/cv-builder"}
            className="px-4 py-2 bg-white absolute z-10 right-3 bottom-3 font-medium transition-all duration-300  hover:scale-105"
          >
            View Green CV
          </Link>
        </div>

        <div className="mt-[330px] relative">
          <div className="flex justify-start items-center gap-[10px]">
            <h1 className="text-3xl font-bold tracking-tight">
              {accountData?.first_name} {accountData?.last_name}
            </h1>
            <Divider type="vertical" className="!border-darkGrey !mx-0" />
            <div className=" text-base">{accountData?.nick_name}</div>
            <Divider type="vertical" className="!border-darkGrey !mx-0" />
            <a href={accountData?.linkedin ?? "#"} className=" text-base">
              <LinkedinOutlined className=" text-base" />
            </a>
            <Divider type="vertical" className="!border-darkGrey !mx-0" />
            <div className=" text-base flex gap-1">
              <EnvironmentOutlined className=" " />
              {accountData?.country}
            </div>
          </div>

          <div
            className="text-base tracking-wide mt-2"
            dangerouslySetInnerHTML={{
              __html: accountData?.profile_headline ?? "",
            }}
          />
        </div>
      </section>

      <section className="basic-card !p-8 relative bg-white min-h-[50vh] mt-7">
        <Tabs
          className="mt-2 custom-tabs"
          renderTabBar={renderTabBar}
          items={
            is_profile[role]?.status === "APPROVED"
              ? roleTabs[role]
              : roleTabs[role]?.map((item) => ({ ...item, children: null }))
          }
        />
        {is_profile[role]?.status !== "APPROVED" && (
          <ProfileDoesNotExist
            hasApplied={false}
            subtitle={profileDoesNotExistDescps[role].subtitle}
            description={profileDoesNotExistDescps[role].description}
            isBtnLoading={becomeFounderMutation?.isLoading}
            onApplied={onApplied}
            role={role}
          />
        )}
      </section>
    </>
  )
}

export default MyCommunityProfile
