import { Popover } from "antd"
import React, { useState } from "react"
import ImageAstroseed from "../../../assets/images/astroseed.png"
import ImageGreenNebula from "../../../assets/images/green_nebula.png"
import ImageStartMaster from "../../../assets/images/start_master.png"
import ImageTerraformer from "../../../assets/images/teraformer.png"
import ImageSolarExplorer from "../../../assets/images/solar_explorer.png"
import ImageNotLaunched from "../../../assets/images/not_launched.png"
import FiveCircleRate from "../../../common/components/FiveCircleRate"

const staticData = [
  {
    id: 1,
    name: "Not Launched",
    level: 0,
    description: "You haven’t started learning this skill yet.",
  },
  {
    id: 2,
    name: "Astroseed",
    level: 1,
    description:
      "You know the basic terminology of a skill and familiar with main concepts.",
  },
  {
    id: 3,
    name: "Solar Explorer",
    level: 2,
    description:
      "You are still developing the skill, but can apply some basic concepts on-the-job.",
  },
  {
    id: 4,
    name: "Terraformer",
    level: 3,
    description:
      "You have a solid level of knowledge and can perform tasks independently.",
  },
  {
    id: 5,
    name: "Green Nebula",
    level: 4,
    description:
      "You have advanced understanding of the area and a level of expertise.",
  },
  {
    id: 6,
    name: "Star Master",
    level: 5,
    description:
      "You are an expert in the area, and may be able to train and mentor others. ",
  },
]

const LevelInfoPopover = ({ children }) => {
  const [open, setOpen] = useState(false)
  return (
    <Popover
      trigger="hover"
      open={open}
      width={10}
      onOpenChange={(open) => setOpen(open)}
      placement="right"
      // color='#E2EBFA'
      content={
        <div className="text-backdrop mt-4 px-3 cursor-pointer w-[22rem] max-w-96 -pt-5">
          <div className="">
            <h2>Proficiency Levels</h2>
            <p>
              Each skill has 5 levels of proficiency. See descriptions below for
              more information.
            </p>
            <div>
              {staticData?.map((item, index) => (
                <div
                  className="grid grid-cols-12"
                  style={{ border: "solid 1px gray" }}
                  key={index}
                >
                  <div className="col-span-3 bg-[#D4F5C5] h-18 flex flex-col justify-center text-center">
                    {index === 0 ? (
                      <img
                        src={ImageNotLaunched}
                        alt="gambar"
                        className="w-16 mx-auto"
                      />
                    ) : index === 1 ? (
                      <img
                        src={ImageAstroseed}
                        alt="gambar"
                        className="w-12 mx-auto"
                      />
                    ) : index === 2 ? (
                      <img
                        src={ImageSolarExplorer}
                        alt="gambar"
                        className="w-12 mx-auto"
                      />
                    ) : index === 3 ? (
                      <img
                        src={ImageTerraformer}
                        alt="gambar"
                        className="w-12 mx-auto"
                      />
                    ) : index === 4 ? (
                      <img
                        src={ImageGreenNebula}
                        alt="gambar"
                        className="w-12 mx-auto"
                      />
                    ) : (
                      <img
                        src={ImageStartMaster}
                        alt="gambar"
                        className="w-14 mx-auto my-5"
                      />
                    )}
                  </div>
                  <div className="col-span-9 py-1 px-3">
                    <div className="flex items-center justify-between">
                      <p className="p-0 m-0 font-bold">{item?.name}</p>
                      <FiveCircleRate
                        defaultValue={item?.level}
                        circleSize={13}
                        disabled={true}
                      />
                    </div>
                    <span className="text-xs p-0 m-0">{item?.description}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      }
    >
      {children}
    </Popover>
  )
}

export default LevelInfoPopover
