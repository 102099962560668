import instance from "../../../../config/config"
import {
  ApiResponse,
  ApiResponsePaginatedInfinite,
} from "../../../../common/types/ApiResponse"
import { IAddCommunityPost, ICommunityComment } from "../types/commonn"

export const postCommentList = (id: string, limit: number, page: number) => {
  return instance.get<ApiResponsePaginatedInfinite<ICommunityComment>>(
    `/community/posts/${id}/comments`,
    {
      params: {
        limit,
        page,
      },
    },
  )
}

export const addPostComment = ({
  id,
  comment,
}: {
  id: string
  comment: IAddCommunityPost
}) => {
  return instance.post<ApiResponse<ICommunityComment>>(
    `/community/posts/${id}/comments`,
    comment,
  )
}

export const updatePostComment = ({
  id,
  commentId,
  comment,
}: {
  id: string
  commentId: string
  comment: IAddCommunityPost
}) => {
  return instance.put<ApiResponse<ICommunityComment>>(
    `/community/posts/${id}/comments/${commentId}`,
    comment,
  )
}

export const deletePostComment = ({
  id,
  commentId,
}: {
  id: string
  commentId: string
}) => {
  return instance.delete<ApiResponse<null>>(
    `/community/posts/${id}/comments/${commentId}`,
  )
}
