import React, { useContext } from "react"
import { HomeOutlined } from "@ant-design/icons"
import { PageContent, PageHeader } from "../../../common/layout"
import ListArticleContent from "../components/article/ListArticleContent"
import { usePublishArticle } from "../hooks/usePublishArticle"
import { Button } from "antd"
import ModalBecomeAnExpert from "../components/article/ModalBecomeAnExpert"
import {
  ITourContext,
  TourContext,
} from "../../../common/components/tour/TourProvider"
import { withAuthentication } from "../../../common/components"

interface ListPublicArticlesProps {}

const AuthenticatedButton = withAuthentication(Button)

const ListPublicArticles: React.FC<ListPublicArticlesProps> = () => {
  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "Expert Articles",
    },
  ]

  const { publishArticles, queryArticleResponse, filterData, reqExpert } =
    usePublishArticle()

  const {
    refs: { refBecomeAnExpert },
  } = useContext(TourContext) as ITourContext

  return (
    <>
      <PageContent
        header={
          <PageHeader
            breadcrumbItems={items}
            title={"Expert Articles"}
            needButton={true}
            buttonComponent={
              <AuthenticatedButton
                type={"primary"}
                ref={refBecomeAnExpert}
                onClick={() => {
                  reqExpert?.setOpenModal(true)
                }}
                className="rounded"
              >
                Become an Expert
              </AuthenticatedButton>
            }
          />
        }
        content={
          <ListArticleContent
            myArticles={publishArticles}
            queryArticleResponse={queryArticleResponse}
            filterData={filterData}
          />
        }
      />
      <ModalBecomeAnExpert reqExpert={reqExpert} />
    </>
  )
}

export default ListPublicArticles
