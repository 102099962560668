import React from "react"
import { useForm, Controller } from "react-hook-form"
import { Button, Input, message } from "antd"
import { addDiscussionToWorkshop } from "../../apis/workhsop"
import { useMutation } from "react-query"
import { useQueryClient } from "react-query"
import { useDiscussionStore } from "../../store/useDiscusionStore"
const NewWorkshopDiscussion = ({
  item_id,
  refetchListData,
}: {
  item_id: string
  refetchListData: any
}) => {
  const {
    control,
    handleSubmit,
    resetField,
    watch,
    formState: { errors },
  } = useForm()

  const { setIsAddingNewData } = useDiscussionStore()

  const [isTextAreaFocused, setIsTextAreaFocused] = React.useState(false)
  const createDiscussion = useMutation(
    ({ item_id, content }: { item_id: string; content: string }) =>
      addDiscussionToWorkshop(item_id, content),
  )
  const queryClient = useQueryClient()

  const onSubmit = async (data: any) => {
    setIsAddingNewData(true)
    message.loading({
      content: "Adding to discussion...",
      key: "create-discussiont",
    })
    await createDiscussion.mutateAsync(
      { item_id, content: data?.content },
      {
        onSuccess: () => {
          refetchListData()
          queryClient.invalidateQueries(["workshop-disccusion", item_id])
          message.success({
            content: "Discussion Added!",
            key: "create-discussion",
          })
          resetField("content")
          setIsTextAreaFocused(false)
        },
        onError: (error: any) => {
          message.error({
            content: error.response?.data?.message || "Something went wrong!",
            key: "create-discussion",
          })
        },
      },
    )
  }

  const contentValue = watch("content", "")

  return (
    <form
      className={`flex flex-col shadow-xl gap-4 basic-card duration-300 mt-4 py-4 transition-all ${
        isTextAreaFocused ? "h-36" : "h-36"
      } bg-bg`}
    >
      <Controller
        name="content"
        control={control}
        rules={{
          required: "Comment is required!",
          maxLength: 1000,
          minLength: 10,
        }}
        render={({ field }) => (
          <Input.TextArea
            {...field}
            placeholder="Add your comment..."
            onFocus={() => setIsTextAreaFocused(true)}
            showCount
            style={{ resize: "none" }}
            styles={{
              textarea: {
                backgroundColor: "#F0F2F5",
                color: "",
              },
            }}
            maxLength={1000}
            className="border-none resize-none focus:border-none flex-grow "
          />
        )}
      />

      <div
        className={`flex items-center  gap-4 justify-end mt-4 duration-500 transition-all ${
          isTextAreaFocused ? "h-auto" : "h-auto"
        }`}
      >
        <Button
          type="dashed"
          className={` ${isTextAreaFocused ? "block" : "hidden"}`}
          onClick={() => {
            resetField("content")
            setIsTextAreaFocused(false)
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          loading={createDiscussion.isLoading}
          disabled={contentValue.length < 10 || createDiscussion.isLoading}
          className={`bg-primary text-white ${
            isTextAreaFocused ? "block" : "hidden"
          }`}
        >
          Post
        </Button>
      </div>
    </form>
  )
}

export default NewWorkshopDiscussion
