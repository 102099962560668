import { Result } from "antd"

interface PageContentProps {
  header: React.ReactNode
  content: React.ReactNode
  contentWithoutPadding?: boolean
  extraContent?: React.ReactNode
  extraContentBottom?: React.ReactNode
  contentRightSidebar?: React.ReactNode
  hideRightSidebarOnMobile?: boolean
  rootClassName?: string
  contentRootClassName?: string
  rightSidebarRootClassName?: string
  isError?: boolean
  errorTitle?: string
  errorSubtitle?: string
}

export function PageContent({
  header,
  content,
  contentWithoutPadding = false,
  extraContent,
  extraContentBottom,
  contentRightSidebar,
  hideRightSidebarOnMobile,
  rootClassName,
  contentRootClassName,
  rightSidebarRootClassName,
  isError = false,
  errorTitle = "",
  errorSubtitle = "",
}: PageContentProps) {
  const rightSidebarClasses = hideRightSidebarOnMobile ? "hidden lg:block" : ""
  const colspanLeftSidebar = contentRightSidebar
    ? "lg:col-span-2 col-span-3"
    : "col-span-3"
  return (
    <div>
      {header}
      {extraContent && <div className="px-2 py-6 lg:px-6 ">{extraContent}</div>}
      <div
        className={`${
          !contentWithoutPadding ? "p-2 lg:p-6" : ""
        } grid grid-cols-3 gap-5 ${rootClassName ?? ""}`}
      >
        <div
          className={`${colspanLeftSidebar ?? ""} flex flex-col gap-4 ${
            contentRootClassName ?? ""
          }`}
        >
          {isError ? (
            <Result
              status="500"
              title={errorTitle ?? "500"}
              subTitle={
                errorSubtitle ??
                "Sorry, something went wrong with that request, please refresh the page and try again."
              }
            />
          ) : (
            content
          )}
        </div>
        {contentRightSidebar && (
          <div
            className={`relative lg:col-span-1 col-span-3 ${rightSidebarClasses} ${
              rightSidebarRootClassName ?? ""
            }`}
          >
            {contentRightSidebar}
          </div>
        )}
      </div>
      {extraContentBottom && (
        <div className="px-2 lg:px-6">{extraContentBottom}</div>
      )}
    </div>
  )
}
