import { useState, useMemo, useRef, useCallback, useEffect } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import { useInfiniteQuery } from "react-query"
import { debounce } from "../../../utils/debounce"
import instance from "../../../config/config"
import {
  useGreenSkillSectors,
  useGreenSkills,
  useGetTotalGreenSkills,
} from "./useSkills"
import { msalInstance } from "../../.."
import { Category, Skill } from "../types/common"
import { ApiResponsePaginated2 } from "../../../common/types/ApiResponse"

const defaultParams = {
  search: "",
  sort_rank: "",
  limit: "20",
  viewType: "cards",
  price: "",
  availability: "",
}

export interface Params {
  search?: string
  sort_rank?: string
  limit?: string
  viewType?: string
  price?: string
  availability?: string
}

export type ParamName = keyof Params

const defaultParamsWithoutSpecified = (paramName: ParamName) => {
  const params = { ...defaultParams }
  delete params[paramName]
  return params
}

const fetchGreenSkillsCategories = async ({
  pageParam = 1,
  searchParam = "",
}) => {
  const response = await instance.get<ApiResponsePaginated2<Category>>(
    `green-skills/categories?page=${pageParam}&limit=20&search=${searchParam}`,
  )
  return response.data
}

export const useSkillsPage = () => {
  const [total, setTotal] = useState<number>(0)
  const [dataList, setDataList] = useState<Skill[]>([])
  const [page, setPage] = useState<number>(1)
  const observer = useRef<IntersectionObserver | null>(null)
  const totalGreenSkillsQuery = useGetTotalGreenSkills()
  const { pathname } = useLocation()

  // get the user
  const user = msalInstance.getActiveAccount()

  const [searchParams, setSearchParams] = useSearchParams()

  searchParams.forEach((value, key) => {
    if (
      value === "" ||
      value === undefined ||
      value === null ||
      value === "All"
    ) {
      searchParams.delete(key)
    }
  })

  const getParam = (paramName: ParamName, defaultValue: string) => {
    return searchParams.get(paramName) || defaultValue
  }

  const setParam = (paramName: ParamName, value: string) => {
    if (paramName === "search") {
      setSearchParams({
        ...defaultParamsWithoutSpecified(paramName),
        [paramName]: value,
      })
    }
    searchParams.set(paramName, value)
    setSearchParams(searchParams)
  }

  if (pathname === "/skill/green-skills") {
    if (
      !searchParams.has("search") &&
      !searchParams.has("sort_rank") &&
      !searchParams.has("limit") &&
      !searchParams.has("viewType")
    ) {
      setSearchParams({ ...defaultParams })
    }
  }

  const viewType = getParam("viewType", "cards") ?? `cards`
  const search = getParam("search", "")
  const sort_rank = getParam("sort_rank", "") ?? ""
  const limit = Number(getParam("limit", "20"))
  const availability = getParam("availability", "")
  const price = getParam("price", "") ?? ""

  const [selectedCategories, setSelectedCategories] = useState<string[]>([])
  const [limitCategories, setLimitCategories] = useState(20)
  const [categoriesSearch, setCategoriesSearch] = useState("")

  const greenSkillSectorsQuery = useGreenSkillSectors(
    {},
    viewType === "bubbles",
  )

  const greenSkillSectors = useMemo(() => {
    return greenSkillSectorsQuery?.data?.data?.data
  }, [greenSkillSectorsQuery?.data?.data?.data?.length])

  const { data, isLoading, isError, fetchNextPage, hasNextPage, refetch } =
    useInfiniteQuery(
      ["green-skills-categories", categoriesSearch],
      ({ pageParam = 1 }) => {
        return fetchGreenSkillsCategories({
          pageParam,
          searchParam: categoriesSearch,
        })
      },
      {
        getNextPageParam: (lastPage) => {
          const { pagination } = lastPage?.data
          const nextPage = pagination.page + 1
          return nextPage <= pagination.total_page ? nextPage : undefined
        },
        refetchOnWindowFocus: false,
        enabled: viewType === "cards",
      },
    )

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const target = e.currentTarget
    if (
      target.scrollTop + target.clientHeight === target.scrollHeight &&
      hasNextPage
    ) {
      setLimitCategories((prevLimit) => prevLimit + 10)
      fetchNextPage()
    }
  }

  const handleSearch = debounce((value: string) => {
    setCategoriesSearch(value)
    setLimitCategories(20)
    refetch()
  }, 1000)

  const greenSkillsQuery = useGreenSkills({
    limit,
    page,
    search,
    sort_rank,
    categories:
      selectedCategories?.length === 0 ? undefined : selectedCategories,
    filter_availability: availability,
    filter_price_range: price === "" ? undefined : price,
    talent_id: user?.idTokenClaims?.sub ?? undefined,
    currency_code: localStorage.getItem("currency_code") ?? "sgd",
  })

  const lastCardElementRef = useCallback(
    (node: Element | null) => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          dataList.length &&
          dataList?.length < total &&
          !isLoading
        ) {
          setPage((prev) => prev + 1)
        }
      })
      if (node) observer.current.observe(node)
    },

    [dataList?.length],
  )

  useEffect(() => {
    if (page === 1) {
      setDataList(greenSkillsQuery?.data?.data?.data?.list || [])
      setTotal(greenSkillsQuery?.data?.data?.data?.total_data || 0)
    } else {
      setDataList((prev: any) => [
        ...prev,
        ...(greenSkillsQuery?.data?.data?.data?.list || []),
      ])
      setTotal(greenSkillsQuery?.data?.data?.data?.total_data || 0)
    }
  }, [greenSkillsQuery?.data?.data?.data?.list])

  return {
    viewType,
    search,
    page,
    setPage,
    sort_rank,
    limit,
    selectedCategories,
    limitCategories,
    categoriesSearch,
    greenSkillSectors,
    data,
    isLoading,
    isError,
    hasNextPage,
    handleScroll,
    handleSearch,
    greenSkillsQuery,
    setSelectedCategories,
    setParam,
    availability,
    price,
    lastCardElementRef,
    dataList,
    setDataList,
    totalSkills: totalGreenSkillsQuery?.data?.data?.data?.total_data || 0,
  }
}
