import { useQuery } from "react-query"
import instance from "../../../config/config"
import { AxiosResponse } from "axios"

export const useCountries = () => {
  const { data, isLoading } = useQuery("countries", () =>
    instance.get<AxiosResponse<{ id: string; name: string; code: string }[]>>(
      "/commons/countries",
    ),
  )
  return {
    data: data?.data?.data,
    isLoading,
  }
}
