import React from "react"
import { Card } from "antd"
import { Link } from "react-router-dom"
import {
  LinkedinFilled,
  MailOutlined,
  TwitterOutlined,
  LinkOutlined,
  EditOutlined,
} from "@ant-design/icons"
import { Account } from "../../../hooks/cv-builder/useProfile"

interface Props {
  handleUpdate: (index: number) => void
  data: Account | undefined
}

export const CardViewSocial: React.FC<Props> = ({ handleUpdate, data }) => {
  return (
    <Card className="shadow-md w-full rounded-xl relative">
      <h3 className="">Contact and Social Media</h3>
      <div className="py-1">
        Control who can see your contact information in{" "}
        <Link className=" text-secondary" to="/account/privacy">
          privacy settings
        </Link>
      </div>
      <div className="flex gap-4 items-center py-1">
        <MailOutlined />
        <div>{data?.email || "-"}</div>
      </div>
      <div className="flex gap-4 items-center py-1">
        <LinkedinFilled />
        <div>{data?.linkedin || "-"}</div>
      </div>
      <div className="flex gap-4 items-center py-1">
        <TwitterOutlined />
        <div>{data?.twitter || "-"}</div>
      </div>
      <div className="flex gap-4 items-center py-1">
        <LinkOutlined />
        <div>{data?.link || "-"}</div>
      </div>
      <EditOutlined
        className=" absolute right-5 top-5"
        onClick={() => handleUpdate(1)}
      />
    </Card>
  )
}
