import React from "react"
import { HomeOutlined } from "@ant-design/icons"
import { useMyArticle } from "../hooks/useMyArticle"
import { PageContent, PageHeader } from "../../../../common/layout"
import ListArticleContent from "../components/ListArticleContent"

interface ListArticlesProps {}

const ListArticles: React.FC<ListArticlesProps> = () => {
  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "Expert",
    },
    {
      title: "My Articles",
    },
  ]

  const { myArticles, queryArticleResponse, addAction } = useMyArticle()
  return (
    <PageContent
      header={
        <PageHeader breadcrumbItems={items} title={"My Expert Articles"} />
      }
      content={
        <ListArticleContent
          myArticles={myArticles}
          queryArticleResponse={queryArticleResponse}
          addAction={addAction}
        />
      }
    />
  )
}

export default ListArticles
