import { Button, Divider, Drawer, Empty, Image, Skeleton, Tag } from "antd"
import React from "react"
import {
  storeModalSchedule,
  useStoreMySchedule,
} from "../stores/storeMentorSchedule"
import { CalendarOutlined, CloseOutlined } from "@ant-design/icons"
import { useListMyBookings } from "../hooks/useMentorSchedule"
import { GoogleMeetLogo } from "../../../../../assets/images"
import { openInNewTab } from "../../../../../utils/util_funcs"
import dayjs from "dayjs"
import { useProfile } from "../../../../missionControl/hooks/cv-builder/useProfile"
import { MentorMenteeSession } from "../types/response"
import { Input } from "antd"
import ModalSchedule from "./ModalSchedule"

const { TextArea } = Input

interface DrawerMyScheduleProps {}

const DrawerMySchedule: React.FC<DrawerMyScheduleProps> = () => {
  const {
    open,
    loading,
    setOpen,
    cancelBookingId,
    setCancelBookingId,
    cancelReason,
    setCancelReason,
    setRescheduleBookingId,
    mentorId,
    setMentorId,
    setFrom,
  } = useStoreMySchedule()

  const { bookingData, handleSubmitCancelBooking, loadingCancelBooking } =
    useListMyBookings()

  const { account } = useProfile()

  const getLabelForId = (
    session: MentorMenteeSession,
    id: string | undefined,
  ): string => {
    if (session.mentor_data.id === id) {
      return "as mentor"
    } else if (session.mentee_data.id === id) {
      return "as mentee"
    } else {
      return "no match"
    }
  }

  const { openModal } = storeModalSchedule()

  const handleCancelBooking = (id: string) => {
    setCancelBookingId(id)
  }
  const handleRescheduleBooking = (id: string, mentor_id: string) => {
    setRescheduleBookingId(id)
    setMentorId(mentor_id)
    setFrom("reschedule")
    openModal()
  }

  const sortedBookingData = bookingData?.slice().sort((a, b) => {
    return (
      new Date(a.datetime_string).getTime() -
      new Date(b.datetime_string).getTime()
    )
  })

  return (
    <Drawer
      closable
      destroyOnClose
      title={
        <div className="flex items-center gap-x-2 -ml-6">
          <CalendarOutlined className="text-xl" />
          <h2>My Schedule List</h2>
        </div>
      }
      placement="right"
      open={open}
      onClose={() => setOpen(false)}
      width={600}
      closeIcon={
        <div className="absolute right-5 top-6">
          <CloseOutlined />
        </div>
      }
      className="!bg-white-custom !z-50 pb-16"
    >
      {mentorId && <ModalSchedule />}
      {loading ? (
        <div>
          {Array.from({ length: 5 }).map((_, index) => (
            <div
              key={index}
              className="py-3 shadow bg-white p-4 my-3 rounded-lg"
            >
              <Skeleton active paragraph={{ rows: 3 }} />
            </div>
          ))}
        </div>
      ) : (
        <div>
          <div className="">
            {sortedBookingData?.length === 0 ? (
              <div className="text-center p-4">
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No Schedule found"
                />
              </div>
            ) : (
              sortedBookingData?.map((booking, index) => {
                const statusPerson = getLabelForId(booking, account?.id)
                return (
                  <div
                    key={index}
                    className="rounded-lg px-4 py-2 shadow space-y-3 bg-white my-3"
                    style={{ border: "2px solid #FEFEFD" }}
                  >
                    <h3>{booking?.summary}</h3>
                    <Divider />
                    <div>
                      <span className="italic text-gray-400">
                        Clik to open meet
                      </span>
                      <div
                        onClick={() => openInNewTab(booking?.meeting_url)}
                        className="cursor-pointer flex items-center justify-between"
                      >
                        <Image
                          src={GoogleMeetLogo}
                          alt="google-meet-logo"
                          className=""
                          width={140}
                          preview={false}
                        />
                        <p className="m-0">{booking?.meeting_url}</p>
                      </div>
                    </div>

                    <Divider />

                    <div>
                      <span className="italic text-gray-400">
                        Additional notes
                      </span>
                      <p className="m-0">{booking?.additional_notes}</p>
                    </div>

                    <Divider />

                    <div>
                      <span className="italic text-gray-400">Date</span>
                      <div className="flex items-center gap-x-2">
                        <p className="m-0">
                          {dayjs(booking?.datetime_string).format(
                            "dddd, MMMM D, YYYY, h:mm A",
                          )}
                        </p>
                        <p className="m-0">
                          ({dayjs(booking?.datetime_string).fromNow()})
                        </p>
                      </div>
                    </div>

                    <Divider />

                    {cancelBookingId === booking?.id ? (
                      <div>
                        <p className="font-bold">
                          Are you sure to cancel this schedule?
                        </p>
                        <TextArea
                          value={cancelReason}
                          onChange={(e) => setCancelReason(e.target.value)}
                          placeholder="Add cancel reason (optional)"
                          autoSize={{ minRows: 2, maxRows: 3 }}
                          className="rounded-lg"
                        />
                      </div>
                    ) : null}

                    <div className="flex items-center justify-between">
                      <Tag
                        className={`${
                          statusPerson === "as mentor"
                            ? "bg-secondaryYellow text-backdrop"
                            : "bg-backdrop text-white"
                        } `}
                      >
                        {statusPerson}
                      </Tag>

                      {cancelBookingId === booking?.id ? (
                        <div className="flex items-center gap-x-2">
                          <Button
                            disabled={loadingCancelBooking}
                            type="default"
                            onClick={() => {
                              setCancelBookingId("")
                              setCancelReason("")
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            disabled={loadingCancelBooking}
                            type="primary"
                            onClick={() => handleSubmitCancelBooking()}
                          >
                            Confirm
                          </Button>
                        </div>
                      ) : (
                        statusPerson !== "as mentor" && (
                          <div className="flex items-center gap-x-2">
                            <Button
                              disabled={loadingCancelBooking}
                              onClick={() => {
                                setMentorId(booking?.mentor_id)
                                setFrom("reschedule")
                                setTimeout(() => {
                                  handleRescheduleBooking(
                                    booking?.id,
                                    booking?.mentor_id,
                                  )
                                }, 10)
                              }}
                              type="primary"
                            >
                              Reschedule
                            </Button>
                            <Button
                              disabled={loadingCancelBooking}
                              type="default"
                              onClick={() => handleCancelBooking(booking?.id)}
                            >
                              Cancel
                            </Button>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )
              })
            )}
          </div>
        </div>
      )}
    </Drawer>
  )
}

export default DrawerMySchedule
