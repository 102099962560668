export function isHTML(str: string): boolean {
  const htmlRegExp = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/
  return htmlRegExp.test(str)
}

export const getHumanReadableDuration = (
  contentType: "text" | "video",
  contentMetadata: any,
) => {
  const metadata = {
    duration_in_seconds: 0,
    duration_breakdown: ["0m"],
    human_readable_duration: { hours: 0, minutes: 0, seconds: 0 } as any,
  }

  if (contentType === "text") {
    contentMetadata = contentMetadata.text_content_metadata
  } else if (contentType === "video") {
    contentMetadata = contentMetadata.video_metadata
  }

  if (contentMetadata) {
    metadata.duration_in_seconds = contentMetadata.duration_in_seconds ?? 0

    // Enhanced regular expression to handle mixed units and multiple occurrences
    const durationMatch =
      contentMetadata.human_readable_duration?.matchAll(/(\d+)([hms])/g)

    if (durationMatch) {
      // @ts-ignore
      for (const match of durationMatch) {
        const value = parseInt(match[1])
        switch (match[2]) {
          case "h":
            metadata.human_readable_duration.hours += value
            break
          case "m":
            metadata.human_readable_duration.minutes += value
            break
          case "s":
            metadata.human_readable_duration.seconds += value
            break
          default:
            // Unknown unit, ignore
            break
        }
      }
    }
  }

  // Handle zero values and format human readable duration
  const totalSeconds =
    metadata.human_readable_duration.hours * 3600 +
    metadata.human_readable_duration.minutes * 60 +
    metadata.human_readable_duration.seconds

  if (totalSeconds === 0) {
    metadata.human_readable_duration = "0m"
  } else {
    const formattedDuration = []
    if (metadata.human_readable_duration.hours > 0) {
      formattedDuration.push(`${metadata.human_readable_duration.hours}h`)
    }
    if (metadata.human_readable_duration.minutes > 0) {
      formattedDuration.push(`${metadata.human_readable_duration.minutes}m`)
    }
    metadata.human_readable_duration = formattedDuration.join(" ")
  }

  return metadata
}
export function parseHumanReadableDuration(duration: string) {
  const regex = /(?:\s*(\d+)\s*h)?\s*(\d*)\s*m?\s*(\d*)\s*s?/
  const match = duration?.match(regex)

  if (!match) {
    return { hours: 0, minutes: 0, seconds: 0 }
  }

  const hours = match[1] ? parseInt(match[1]) : 0
  const minutes = match[2] ? parseInt(match[2]) : 0
  const seconds = match[3] ? parseInt(match[3]) : 0

  return { hours, minutes, seconds }
}

export function addHumanReadableDurations(durations: string[]) {
  let totalHours = 0
  let totalMinutes = 0
  let totalSeconds = 0

  for (const duration of durations) {
    if (!duration) {
      continue
    }
    const parsedDuration = parseHumanReadableDuration(duration)
    totalHours += parsedDuration?.hours
    totalMinutes += parsedDuration?.minutes
    totalSeconds += parsedDuration?.seconds
  }

  const resultHours = Math.floor(totalMinutes / 60) + totalHours
  const resultMinutes = (totalMinutes % 60) + Math.floor(totalSeconds / 60)
  // const resultSeconds = totalSeconds % 60;

  return { hours: resultHours, minutes: resultMinutes }
}
