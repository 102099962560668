import React, { memo, useState } from "react"
import { Avatar, Divider, Popover, Tag, message, Image } from "antd"
import {
  UserOutlined,
  MailOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  LinkOutlined,
} from "@ant-design/icons"
import { ReactComponent as Location } from "../../assets/images/pin.svg"
import { avatarImgs } from "../../assets/avatar_images"
import { Link, useNavigate } from "react-router-dom"
import FiveCircleRate from "./FiveCircleRate"

export const truncateText = (text = "", maxLength) => {
  if (text?.length > maxLength) {
    return text.slice(0, maxLength) + "..."
  }
  return text
}

const maxNameLength = 20
const maxOccupationLength = 20

const UserCard = memo(({ className, user }) => {
  return (
    <Popover
      color="#36505E"
      placement="left"
      content={
        <div>
          <Content
            userName={
              user?.first_name === "unknown"
                ? user?.nickname
                : `${user?.first_name} ${user?.last_name}`
            }
            profileHeadline={user?.profile_headline}
            profilePicture={user?.avatar}
            location={user?.location}
            email={user?.email}
            linkedin={user?.linkedin}
            twitter={user?.twitter}
            personalLink={user?.link}
            skillLevelPassed={user?.skill_level_passed}
            favoriteSkills={user?.favorite_skills}
            point={user?.point}
            imgOrbit={user?.orbit_image}
            orbit={user?.orbit}
          />
        </div>
      }
      trigger="hover"
    >
      <div className={`flex gap-x-4 items-center ${className ?? ""}`}>
        <div>
          <Avatar
            style={{ border: "1px solid #D4F5C5" }}
            className=" object-cover"
            src={user?.avatar || avatarImgs[0]}
            icon={<UserOutlined className="text-base" />}
          />
        </div>
        <div className="flex flex-col gap-x-4">
          <div className="text-backdrop text-sm ">
            {truncateText(user?.nickname, maxNameLength)}
          </div>
          <div className="text-[#BFBFBF] text-xs ">
            {truncateText(user?.location, maxOccupationLength)}
          </div>
        </div>
      </div>
    </Popover>
  )
})

const Content = ({
  profilePicture,
  profileHeadline,
  userName,
  fullName,
  location,
  email,
  linkedin,
  twitter,
  personalLink,
  skillLevelPassed,
  favoriteSkills,
  point,
  imgOrbit,
  orbit,
}) => {
  const [showAll, setShowAll] = useState(false)

  const displayedSkills = showAll ? favoriteSkills : favoriteSkills?.slice(0, 3)

  const navigate = useNavigate()

  return (
    <div className="p-1 text-white w-80">
      <div className="flex gap-5">
        <div>
          <Image
            src={profilePicture || avatarImgs[0]}
            alt="profile"
            className="h-[60px] w-[60px] rounded-full object-contain bg-white"
            style={{ border: "1px solid #D4F5C5" }}
            width={65}
          />
        </div>

        <div className=" col-span-3 flex flex-col justify-between">
          <div className={`space-y-2`}>
            <div className="font-bold">{fullName || userName}</div>
            <div className="flex gap-1 my-1">
              <Location fill="#fafafa" />
              <span className="text-xs">{location}</span>
            </div>
            <p className="w-full m-0 p-0 text-xs">{profileHeadline}</p>
          </div>
        </div>
      </div>

      <div className={`mt-2`}>
        <div className={`flex gap-1 items-center`}>
          <Image src={imgOrbit} width={23} />
          <p className={`text-xs m-0 p-0`}>Orbit {orbit}</p>
        </div>
        <span className={`text-primary text-xs`}>{`${point}`}</span>{" "}
        <span className={`text-xs m-0 p-0`}> terrapoints</span>
      </div>

      <div className="mt-8">
        {skillLevelPassed
          ?.filter((item) =>
            item?.levels?.some(
              (level) => level?.user_track?.is_assessment_passed,
            ),
          )
          ?.map((item, i) => (
            <div
              className="flex items-center justify-between space-y-2"
              key={i}
            >
              <div className=" max-w-[190px]">{item?.name}</div>
              <div className="flex items-center gap-1">
                <FiveCircleRate
                  defaultValue={
                    item?.levels?.filter(
                      (level) => level?.user_track?.is_assessment_passed,
                    )?.length
                  }
                  disabled={true}
                  circleSize={15}
                  strokeColor={"#fff"}
                />
              </div>
            </div>
          ))}
      </div>

      <div className="w-full overflow-hidden">
        <p className="text-xs">
          INTERESTED IN LEARNING ({favoriteSkills?.length})
        </p>

        <div className="flex flex-wrap w-full">
          {displayedSkills?.map((skill, i) => (
            <Popover key={skill?.id} content={skill?.name}>
              <Tag
                key={i}
                onClick={() => navigate(`/skill/green-skills/${skill?.id}`)}
                className="rounded py-1 px-2 mb-2 cursor-pointer"
                color="#FFEFA0"
                style={{
                  border: "1px solid gray",
                  color: "#36505E",
                }}
              >
                {skill?.name?.length > 48
                  ? skill?.name?.slice(0, 48) + "..."
                  : skill?.name}
              </Tag>
            </Popover>
          ))}
        </div>
        <p
          onClick={() => setShowAll(!showAll)}
          className="text-right cursor-pointer hover:font-bold underline"
        >
          {showAll ? "Show Less" : "Show All"}
        </p>
      </div>

      <Divider className="border-white my-3" />
      <div className="flex justify-around">
        {email ? (
          <MailOutlined
            onClick={() => {
              message.info("email copied!")
              navigator.clipboard.writeText(`${email}`)
            }}
          />
        ) : (
          <MailOutlined className=" text-darkGrey" />
        )}
        {linkedin ? (
          <Link
            to={linkedin}
            target="_blank"
            className=" text-white hover:text-slate-300"
          >
            <LinkedinOutlined />
          </Link>
        ) : (
          <LinkedinOutlined className=" text-darkGrey" />
        )}
        {twitter ? (
          <Link
            to={twitter}
            target="_blank"
            className=" text-white hover:text-slate-300"
          >
            <TwitterOutlined />
          </Link>
        ) : (
          <TwitterOutlined className=" text-darkGrey" />
        )}
        {personalLink ? (
          <Link
            to={personalLink}
            target="_blank"
            className=" text-white hover:text-slate-300"
          >
            <LinkOutlined />
          </Link>
        ) : (
          <LinkOutlined className=" text-darkGrey" />
        )}
      </div>
    </div>
  )
}
export default UserCard
