/* eslint-disable */

import { Button, Image, Skeleton, Spin, Typography } from "antd"
import React from "react"
import { useNavigate } from "react-router-dom"
import { ClockCircleOutlined } from "@ant-design/icons"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import useFastJob from "./hocs/useFastJob"
import { DefaultCompanyLogo, Moon, SavedJob } from "../../assets/images"
import useRecruiterRequest from "../../modules/jobGalaxy/hooks/useRecruiterRequest"
import { useMsal } from "@azure/msal-react"

const { Title } = Typography

interface FastJobTableProps {
  userId?: string
  from?: string
}

const FastJobTable = React.forwardRef<HTMLDivElement, FastJobTableProps>(
  ({ from }, ref) => {
    const navigate = useNavigate()
    dayjs.extend(relativeTime)

    const { listRequest, isLoadingRequest } = useFastJob()

    const { instance: account } = useMsal()
    const activeAccount = account.getActiveAccount()
    const { listRequest: req, isLoadingRequest: reqLoading } =
      useRecruiterRequest(activeAccount?.localAccountId)

    return (
      <div className={`!shadow-lg rounded-xl`} ref={ref}>
        <div className="px-4 py-3 pt-8 bg-backdrop rounded-xl">
          {req?.data?.list?.length > 0 && (
            <div className="px-4 py-2 mb-5 rounded-full bg-primary-bg">
              <p className="m-0">
                You have{" "}
                {reqLoading ? <Spin size="small" /> : req?.data?.list?.length}{" "}
                new{" "}
                <span
                  onClick={() => navigate("/job/my/request")}
                  className="font-bold cursor-pointer text-secondary"
                >
                  Recruiter Connect{" "}
                </span>
                requests.{" "}
              </p>
            </div>
          )}
          <div className={`flex items-center gap-4 justify-between`}>
            <div className={`flex items-center gap-4`}>
              <div className={``}>
                <Title className={`!text-white`} level={3}>
                  Fast Track Job Application
                </Title>
                {listRequest?.data?.list === null ? (
                  ""
                ) : (
                  <p className={`text-white p-0 m-0 text-xs`}>
                    Recruiters think you are a great fit for these jobs. Apply
                    to get automatically shortlisted{" "}
                  </p>
                )}
              </div>
            </div>
            {from === "jobBoard" ? null : (
              <div>
                <Button
                  onClick={() => navigate(`/job/my`)}
                  className={`bg-white`}
                >
                  My Jobs
                </Button>
              </div>
            )}
          </div>

          {isLoadingRequest ? (
            <div className="p-6">
              <Skeleton active />
            </div>
          ) : listRequest?.data?.list === null ? (
            <div className="flex flex-col items-center justify-center p-6 text-white">
              <Image preview={false} alt={`no image`} src={Moon} />
              <p>No new fast track job invites </p>
            </div>
          ) : (
            <div
              className={`pl-1 pr-4 mt-8 ${
                listRequest?.data?.list?.length > 2
                  ? "h-44 overflow-y-scroll"
                  : ""
              } `}
            >
              {listRequest?.data?.list?.map((item: any, index: number) => (
                <div
                  className="py-3 mb-4 bg-white rounded-xl px-7"
                  key={index}
                  style={
                    listRequest?.data?.list?.length - 1 !== index
                      ? {
                          borderBottom: "1px solid #DEDEDE",
                        }
                      : {}
                  }
                >
                  <div className="flex items-center gap-5">
                    {item?.tenant?.logo ? (
                      <Image
                        src={item?.tenant?.logo ? item?.tenant?.logo : ""}
                        preview={false}
                        alt={`logo-${item?.tenant?.name}`}
                        // height={80}
                        width={50}
                      />
                    ) : (
                      <div style={{ height: "80px" }}>
                        <DefaultCompanyLogo />
                      </div>
                    )}

                    <div className="space-y-2">
                      <p className="p-0 m-0 text-base">
                        <span
                          className="cursor-pointer text-secondary hover:font-bold"
                          onClick={() =>
                            navigate(`/job/companies/${item?.tenant?.id}`)
                          }
                        >
                          {item?.tenant?.name}{" "}
                        </span>
                        invited you to apply for
                        <span
                          className="cursor-pointer text-secondary hover:font-bold"
                          onClick={() =>
                            navigate(`/job/board/${item?.job?.id}`)
                          }
                        >
                          {" "}
                          {item?.job?.name}
                        </span>{" "}
                        job
                      </p>
                      <div className="flex items-center text-gray-400 gap-7">
                        <div className="flex items-center gap-2">
                          <ClockCircleOutlined />
                          <p className="p-0 m-0 text-xs">
                            {dayjs(item?.job?.invited_at).fromNow()}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  },
)

export default FastJobTable
