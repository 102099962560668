import React from "react"
import { Card } from "antd"
import {
  SolutionOutlined,
  CalendarOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons"
import { OtherInstitution } from "../../../../../assets/images"
import dayjs from "dayjs"
import { Education } from "../../../hooks/cv-builder/useEducation"

interface Props {
  data: Education
  index: number
  updateTempData: (index: number, data: Education) => void
  onRemove: (val: string) => Promise<void>
  loadingremoveEdu: boolean
  onRemoveTemp: (val: number) => void
}

export const EduView: React.FC<Props> = ({
  data,
  index,
  updateTempData,
  onRemove,
  loadingremoveEdu,
  onRemoveTemp,
}) => {
  return (
    <Card className="rounded-xl shadow-md mb-5 relative">
      <div className="flex gap-5 lg:flex-nowrap flex-wrap">
        <img
          src={
            data?.institution?.image
              ? data?.institution?.image.startsWith("blob")
                ? data?.institution?.image
                : `${data?.institution?.image}?${new Date().getTime()}`
              : OtherInstitution
          }
          alt="company"
          className="w-24 h-24 p-1 rounded-full shadow-md object-contain"
        />
        <div>
          <h3 className=" capitalize">{data.institution.name || "-"}</h3>
          {data.degree && (
            <div className="flex gap-4 items-center my-1">
              <div className="">
                <SolutionOutlined />
                <span className=" text-sm ml-2 capitalize">{`${data.degree}, ${data.field_of_study}`}</span>
              </div>
            </div>
          )}
          {data.start_at && (
            <div className="flex gap-4 items-center lg:flex-nowrap flex-wrap">
              <div className="">
                <CalendarOutlined />
                <span className=" text-sm ml-2">{`${dayjs(data.start_at).format(
                  "MMM YYYY",
                )} - ${
                  data.end_at
                    ? dayjs(data.end_at).format("MMM YYYY")
                    : "Present"
                }`}</span>
              </div>
              <small className=" text-slate-400">
                {data.online ? "online" : "offline"}
              </small>
            </div>
          )}
        </div>
      </div>

      <div className="absolute right-5 top-5 flex gap-3">
        <EditOutlined
          className="cursor-pointer"
          onClick={() => updateTempData(index, { ...data, isEdit: true })}
        />
        <DeleteOutlined
          className=" cursor-pointer"
          onClick={() => {
            if (!loadingremoveEdu) {
              if (data.id) {
                onRemove(data.id)
              } else {
                onRemoveTemp(index)
              }
            }
          }}
        />
      </div>
    </Card>
  )
}

// const getPeriode = (val: any) => {
//   const year =
//     val !== 0
//       ? Math.floor(val / 12)
//         ? `${Math.floor(val / 12)} year`
//         : ""
//       : "";
//   const month = val % 12 ? `${Math.floor(val % 12)} month` : "0 month";
//   return `${year} ${month}`;
// };
