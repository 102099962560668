import { useCallback, useEffect, useRef, useState } from "react"
import { MyArticleResponseTypes } from "../types/response"
import {
  useDeleteImageArtcile,
  useEditArticle,
  useFetchDetailArticle,
  useFetchMyArticleList,
  usePostArtcile,
  usePostImageArtcile,
} from "../apis/rest_article"
import { debounce } from "../../../../utils/debounce"
import { useForm } from "react-hook-form"
import { useGreenSkillByCategory } from "../../../../hooks/useGSByCategory"
import { useQueryClient } from "react-query"
// eslint-disable-next-line no-unused-vars
import { UploadFile, UploadProps, message } from "antd"
import { useNavigate } from "react-router-dom"

export const useMyArticle = (optionalParam?: any) => {
  const [dataList, setDataList] = useState<MyArticleResponseTypes[]>([])
  const [name, setName] = useState<string | undefined>(undefined)
  const [limit, setLimit] = useState<number | undefined>(10)
  const [page, setPage] = useState<number | undefined>(1)
  const [status, setStatus] = useState<string | undefined>("publish")
  const [selectedGreenSkillIds, setSelectedGreenSkillIds] = useState<any[]>([])
  const [totalData, setTotalData] = useState<number>(0)
  const [noMoreData, setNoMoreData] = useState<string | undefined>(undefined)

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [gsSelected, setGsSelected] = useState<any[]>([])
  const [gsListByID, setGSListByID] = useState<any>()

  const [gsCategoryID, setGSCategoryID] = useState<string | undefined>("")
  const [gsCategoryName, setGSCategoryName] = useState<string | undefined>("")
  const [slug, setSlug] = useState<string>("")
  const [imageUrl, setImageUrl] = useState<string>("")

  const [sectionData, setSectionData] = useState<any[]>([
    { content: "", section_title: "" },
  ])

  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const restMyArticleResponse = useFetchMyArticleList({
    search: name,
    limit,
    page,
    status,
    green_skill_ids: selectedGreenSkillIds?.map((item) => item.id).join(","),
  })

  useEffect(() => {
    if (!restMyArticleResponse.isLoading) {
      if (page === 1) {
        setDataList(restMyArticleResponse.data?.data?.data?.list ?? [])
        setTotalData(restMyArticleResponse.data?.data?.data?.total_data ?? 0)
      } else {
        setDataList((prev) => [
          ...prev,
          ...(restMyArticleResponse.data?.data?.data?.list ?? []),
        ])
        setTotalData(restMyArticleResponse.data?.data?.data?.total_data ?? 0)
      }
    }
    //
  }, [restMyArticleResponse.data?.data?.data?.list])

  const handleSearch = debounce((e: any) => {
    setName(e.target.value)
    setPage(1)
  }, 1000)

  const handleFilterMyArticle = (e: string) => {
    setStatus(e)
    setPage(1)
  }

  const handleSelectSector = (item: string) => {
    if (selectedGreenSkillIds.includes(item)) {
      setSelectedGreenSkillIds(
        selectedGreenSkillIds.filter((selectedItem) => selectedItem !== item),
      )
    } else {
      setSelectedGreenSkillIds([...selectedGreenSkillIds, item])
    }
  }

  const handleRemoveSelectedSector = (id: string) => {
    const updatedSelectedSector = selectedGreenSkillIds.filter(
      (selectedGreenSkillIds) => selectedGreenSkillIds?.id !== id,
    )
    setSelectedGreenSkillIds(updatedSelectedSector)
  }
  const handleRemoveSelectedGS = (id: string) => {
    const updatedSelectedSector = gsSelected.filter(
      (selectedGreenSkillIds) => selectedGreenSkillIds?.id !== id,
    )
    setGsSelected(updatedSelectedSector)
  }

  const observer = useRef<IntersectionObserver | null>(null)
  const lastCardElementRef = useCallback(
    (node: Element | null) => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          dataList.length &&
          dataList?.length < totalData &&
          !restMyArticleResponse.isLoading
        ) {
          setPage((prev) => prev! + 1)
        } else {
          setNoMoreData("No more data to load.")
        }
      })
      if (node) observer.current.observe(node)
    },
    //
    [dataList?.length],
  )

  useEffect(() => {
    if (!restMyArticleResponse.isLoading) {
      if (page === 1) {
        setTotalData(restMyArticleResponse?.data?.data?.data?.total_data ?? 0)
      }
    }
    //
  }, [restMyArticleResponse])

  const req: MyArticleResponseTypes = {}
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      green_skill_category: {
        id: "",
        name: "",
      },
      green_skills: [
        {
          id: "",
          name: "",
        },
      ],
      img: "",
      sections: [
        {
          content: "",
          section_title: "",
        },
      ],
      status: "",
      title: "",
    },
  })

  const saveArticleMutation = usePostArtcile()
  const editArticleMutation = useEditArticle()

  const handleCreateArticle = async (data: any) => {
    try {
      const res = await saveArticleMutation.mutateAsync(data)
      setOpenModal(false)

      navigate(`/expert/my/article/${res?.data?.data}`)
    } catch (error: any) {
      message.error(
        "An error occurred while save your article: " +
          error.response?.data?.data,
        10,
      )
    }
  }
  const handleEditArticle = async (data: any) => {
    data.status = "draft"
    try {
      await editArticleMutation.mutateAsync({
        data: data,
        slug: slug,
      })
    } catch (error: any) {
      message.error(
        "An error occurred while save your article: " +
          error.response?.data?.data,
        10,
      )
    }
  }
  const handleSaveChangesArticle = async (data: any) => {
    data.status = "publish"
    try {
      await editArticleMutation.mutateAsync({
        data: data,
        slug: slug,
      })
    } catch (error: any) {
      message.error(
        "An error occurred while save your article: " +
          error.response?.data?.data,
        10,
      )
    }
  }

  const handlePublishArticle = async (data: any) => {
    data.status = "publish"
    try {
      await editArticleMutation.mutateAsync({
        data: data,
        slug: slug,
      })
      setTimeout(() => {
        navigate(`/expert/my/article`)
      }, 1500)
    } catch (error: any) {
      message.error(
        "An error occurred while save your article: " +
          error.response?.data?.data,
        10,
      )
    }
  }

  const {
    data: listGS,
    // isLoading: isLoadingGSByID,
    refetch,
  } = useGreenSkillByCategory(gsCategoryID!)

  const handleSelectGSCategory = (item: any) => {
    const data = {
      id: item?.id,
      name: item?.name,
    }
    setValue("green_skill_category", data)
    setGSCategoryID(item?.id)
    setGSCategoryName(item?.name)
    refetch()

    setGsSelected([])
  }

  const fetchGS = () => {
    queryClient.invalidateQueries("gs-by-category")
    refetch()
  }

  useEffect(() => {
    if (listGS?.data?.data) {
      setGSListByID(listGS?.data?.data)
    }
  }, [listGS?.data?.data, gsCategoryID])

  const handleSelectGS = (id: string, item: any) => {
    const data = {
      id: item?.value,
      name: item?.children,
    }
    if (gsSelected?.length <= 2) {
      if (gsSelected.includes(data)) {
        setGsSelected(
          gsSelected.filter((selectedItem) => selectedItem !== data),
        )
      } else {
        setGsSelected([...gsSelected, data])
      }
    } else {
      message.warning("You can only select up to 3 green skills")
    }
  }

  useEffect(() => {
    setValue("green_skills", gsSelected)
    setValue("sections", sectionData)
  }, [gsSelected, sectionData])

  const handleSectionDataChange = (newSectionData: any[]) => {
    setSectionData(newSectionData)
  }

  useEffect(() => {
    if (optionalParam) {
      setSectionData(optionalParam?.sections)
      setGsSelected(optionalParam?.green_skills)
      setValue("title", optionalParam?.title)
      setValue("img", optionalParam?.img)
      setValue("green_skill_category", optionalParam?.green_skill_category)
      setSlug(optionalParam?.slug!)
      setImageUrl(optionalParam?.img || "")
    }
  }, [optionalParam])

  const messagesEndRef = useRef<HTMLDivElement | null>(null)
  const addNewSection = () => {
    const updatedSectionData = [...sectionData]
    updatedSectionData.push({ content: "", section_title: "title" })
    setSectionData(updatedSectionData)

    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  const uploadImageMutation = usePostImageArtcile()
  const deleteImageMutation = useDeleteImageArtcile()

  const uploadImage = async (data: FormData, slug: string) => {
    try {
      await uploadImageMutation.mutateAsync({
        data: data,
        slug: slug,
      })
    } catch (error: any) {
      message.error(
        "An error occurred while save your article: " +
          error.response?.data?.data,
        10,
      )
    }
  }

  const deleteImage = async (slug: string) => {
    try {
      await deleteImageMutation.mutateAsync({
        slug: slug,
      })
    } catch (error: any) {
      message.error(
        "An error occurred while save your article: " +
          error.response?.data?.data,
        10,
      )
    }
  }

  useEffect(() => {
    if (optionalParam) {
      setImageUrl(optionalParam?.img || "")
    }
  }, [optionalParam])

  return {
    myArticles: {
      dataList,
      isLoading: restMyArticleResponse.isLoading,
      noMoreData,
    },
    queryArticleResponse: {
      handleSearch,
      setLimit,
      limit,
      page,
      setPage,
      status,
      handleFilterMyArticle,
      handleSelectSector,
      selectedGreenSkillIds,
      handleRemoveSelectedSector,
      lastCardElementRef,
    },
    addAction: {
      handleRemoveSelectedGS,
      req,
      handleSubmit,
      control,
      isDirty,
      handleSelectGSCategory,
      openModal,
      setOpenModal,
      handleCreateArticle,
      gsSelected,
      handleSelectGS,
      gsListByID,
      gsCategoryName,
      fetchGS,
      handleSectionDataChange,
      sectionData,
      addNewSection,
      handleEditArticle,
      handleSaveChangesArticle,
      handlePublishArticle,
      imageUrl,
      uploadImage,
      deleteImage,
      uploadImageLoading: uploadImageMutation.isLoading,
      messagesEndRef,
      watch,
    },
  }
}

export const useDetailArticle = (slug: string) => {
  const articleDetailResponse = useFetchDetailArticle(slug!)

  const { addAction } = useMyArticle(articleDetailResponse?.data?.data)

  // IMAGE
  const [file, setFile] = useState<UploadFile | null>(null)

  const beforeUpload = (newFile: UploadFile) => {
    setFile(newFile)
    const formData = new FormData()
    formData.append("file", newFile as unknown as File)
    addAction.uploadImage(formData, articleDetailResponse?.data?.data?.slug!)

    return true
  }

  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFile(newFileList.length > 0 ? newFileList[0] : null)
    if (newFileList.length === 0) {
      addAction.deleteImage(articleDetailResponse?.data?.data?.slug!)
    }
  }

  useEffect(() => {
    if (
      !articleDetailResponse.isLoading &&
      !addAction.uploadImageLoading &&
      articleDetailResponse?.data?.data?.img
    ) {
      setFile({
        uid: "-1",
        name: "image.png",
        status: "done",
        url: articleDetailResponse?.data?.data?.img,
      })
    } else {
      setFile(null)
    }
  }, [
    articleDetailResponse.isLoading,
    addAction.uploadImageLoading,
    articleDetailResponse?.data?.data?.img,
  ])

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj as any)
        reader.onload = () => resolve(reader.result as string)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow?.document.write(image.outerHTML)
  }

  return {
    articleResponse: {
      data: articleDetailResponse?.data?.data,
      isLoading: articleDetailResponse.isLoading,
    },
    addAction,
    uploadImage: {
      file,
      onChange,
      onPreview,
      beforeUpload,
    },
  }
}
