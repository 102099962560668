/* eslint-disable */

import { useCallback, useEffect, useRef, useState } from "react"
import { useQuery } from "react-query"
import instance from "../../../../config/config"
import { debounce } from "../../../../utils/debounce"
import {
  ApiResponse,
  PaginatedResponse,
} from "../../../../common/types/ApiResponse"
import { School } from "../../types/common"
import { Common } from "../../../missionControl/hooks/cv-builder/useJobHistory"
import { errorMessage } from "../../../../common/utils/utils"

export const useListSchool = () => {
  const [params, setParams] = useState({
    page: 1,
    limit: 20,
    name: "",
    location: "",
  })
  const [dataList, setDataList] = useState<School[]>([])
  const [total, setTotal] = useState<number>(0)

  const { data, isLoading } = useQuery<ApiResponse<PaginatedResponse<School>>>(
    ["school-lists", params],
    () =>
      instance.get(`/schools`, {
        params,
      }),
    {
      onError: (err: any) =>
        errorMessage(err?.response?.data?.data || "internal server error", err),
    },
  )

  const handleChageParams = (newParams: any) => {
    setParams((prev) => ({ ...prev, ...newParams }))
  }

  const handleAddLocation = (value: string) => {
    if (params.location === "") {
      setParams((prev) => ({ ...prev, location: value }))
    } else {
      const location = params.location.split(",")
      const newLocation = [...location, value]
      setParams((prev) => ({ ...prev, location: newLocation.join(",") }))
    }
  }
  const handleRemoveLocation = (value: string) => {
    const location = params.location.split(",")
    const newLocation = location.filter((loc) => loc !== value)
    setParams((prev) => ({ ...prev, location: newLocation.join(",") }))
  }

  const handleSearch = debounce((value: string) => {
    setParams((prev) => ({ ...prev, name: value, page: 1 }))
  }, 1000)

  //   handle
  const observer = useRef<IntersectionObserver | null>(null)
  const lastCardElementRef = useCallback(
    (node: Element | null) => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          dataList.length &&
          dataList?.length < total &&
          !isLoading
        ) {
          handleChageParams({ page: params.page + 1 })
        }
      })
      if (node) observer.current.observe(node)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataList?.length],
  )

  useEffect(() => {
    if (!isLoading) {
      if (params.page === 1) {
        setDataList(data?.data.data?.list || [])
        setTotal(data?.data.data.total_data || 0)
      } else if (
        dataList?.[dataList?.length - 1]?.id !==
        data?.data.data.list?.[data?.data.data.list?.length - 1]?.id
      ) {
        setDataList((prev) => [...prev, ...(data?.data.data.list || [])])
        setTotal(data?.data.data.total_data || 0)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data.data.list])
  return {
    dataList,
    isLoading,
    handleSearch,
    lastCardElementRef,
    params,
    setParams,
    handleAddLocation,
    handleRemoveLocation,
  }
}

export const useListSchoolLocation = () => {
  const { data, isLoading } = useQuery<ApiResponse<Common[]>>(
    ["school-lists-location"],
    () => instance.get(`/schools/location/list`, {}),
    {
      onError: (err: any) =>
        errorMessage(err?.response?.data?.data || "internal server error", err),
    },
  )
  return {
    data: data?.data?.data,
    isLoading,
  }
}
