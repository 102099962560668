import React from "react"
import { Card, Input } from "antd"
import { SearchOutlined, CloseOutlined } from "@ant-design/icons"

import AutoCompleteNonInfinity from "../../../../common/components/AutoCompleteNonInfinity"

interface ListPublicArticleFilterProps {
  queryArticleResponse: any
  filterData: any
}

const ListPublicArticleFilter: React.FC<ListPublicArticleFilterProps> = ({
  queryArticleResponse,
  filterData,
}) => {
  return (
    <div>
      <Card className="bg-white-custom shadow-md rounded-xl py-1">
        <div className="flex justify-between items-center">
          <div className="flex flex-col md:flex-row md:gap-6 w-full">
            <div className="flex w-full md:w-8/12">
              <div
                className="flex w-full"
                style={{ borderBottom: "1px solid #D0DDE5" }}
              >
                <SearchOutlined />
                <Input
                  onChange={(e: any) => queryArticleResponse?.handleSearch(e)}
                  bordered={false}
                  placeholder="search"
                />
              </div>
            </div>
            <div className="!bg-grey-select w-full md:w-2/6 relative  mt-4 md:mt-0 flex items-center gap-3 md:gap-7">
              <div className="w-full !bg-grey-select">
                <AutoCompleteNonInfinity
                  options={filterData?.categoryGS?.data}
                  isLoading={filterData?.categoryGS?.isLoading}
                  placeholder="Domain"
                  className="!w-full"
                  onSelect={(value, option) => {
                    queryArticleResponse?.handleSelectGSCategory(option)
                  }}
                />
              </div>
            </div>
            <div className="!bg-grey-select w-full md:w-2/6 relative  mt-4 md:mt-0 flex items-center gap-3 md:gap-7">
              <div className="w-full !bg-grey-select">
                <AutoCompleteNonInfinity
                  options={filterData?.greenSkills?.data}
                  isLoading={filterData?.greenSkills?.isLoading}
                  placeholder="Green Skill"
                  className="!w-full"
                  onSelect={(value, option) => {
                    queryArticleResponse?.handleSelectGS(option)
                  }}
                />
              </div>
            </div>
            <div className="!bg-grey-select w-full md:w-1/6 relative  mt-4 md:mt-0 flex items-center gap-3 md:gap-7">
              <div className="w-full !bg-grey-select">
                <AutoCompleteNonInfinity
                  options={filterData?.expert?.data}
                  isLoading={filterData?.expert?.isLoading}
                  placeholder="Expert"
                  className="!w-full"
                  onSelect={(value, option) => {
                    queryArticleResponse?.handleSelectExpert(option)
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-x-6 ">
          <div
            className={`col-span-6 ${
              queryArticleResponse?.selectedCategoryGreenSkillIds &&
              queryArticleResponse?.selectedCategoryGreenSkillIds?.length > 0
                ? ""
                : "hidden"
            }`}
          >
            {queryArticleResponse?.selectedCategoryGreenSkillIds &&
              queryArticleResponse?.selectedCategoryGreenSkillIds?.length >
                0 && (
                <div className="mt-6 flex flex-col md:flex-row gap-2 md:w-full ">
                  <div className="md:mr-4">Domain:</div>
                  <div className="flex flex-wrap items-center gap-2">
                    {queryArticleResponse?.selectedCategoryGreenSkillIds?.map(
                      (sector: any, index: number) => (
                        <div
                          className="rounded flex bg-white items-center gap-2 shadow-custom-sm w-auto text-sm px-2 py-1"
                          key={index}
                        >
                          <p className="m-0 truncate">{sector?.name}</p>
                          <CloseOutlined
                            className="hover:text-danger"
                            onClick={() =>
                              queryArticleResponse?.handleRemoveSelectedGSCategory(
                                sector.id,
                              )
                            }
                          />
                        </div>
                      ),
                    )}
                  </div>
                </div>
              )}
          </div>

          <div
            className={`col-span-6 ${
              queryArticleResponse?.selectedGreenSkillIds &&
              queryArticleResponse?.selectedGreenSkillIds?.length === 0
                ? "hidden"
                : ""
            }`}
          >
            {queryArticleResponse?.selectedGreenSkillIds &&
              queryArticleResponse?.selectedGreenSkillIds?.length > 0 && (
                <div className="mt-6 flex flex-col md:flex-row gap-2 md:w-full ">
                  <div className="md:mr-4">Skill:</div>
                  <div className="flex flex-wrap items-center gap-2">
                    {queryArticleResponse?.selectedGreenSkillIds?.map(
                      (sector: any, index: number) => (
                        <div
                          className="rounded flex bg-white items-center gap-2 shadow-custom-sm w-auto text-sm px-2 py-1"
                          key={index}
                        >
                          <p className="m-0 truncate">{sector?.name}</p>
                          <CloseOutlined
                            className="hover:text-danger"
                            onClick={() =>
                              queryArticleResponse?.handleRemoveSelectedGS(
                                sector.id,
                              )
                            }
                          />
                        </div>
                      ),
                    )}
                  </div>
                </div>
              )}
          </div>
          <div className="col-span-6">
            {queryArticleResponse?.selectedExpertIds &&
              queryArticleResponse?.selectedExpertIds?.length > 0 && (
                <div className="mt-6 flex flex-col md:flex-row gap-2 md:w-full ">
                  <div className="md:mr-4">Expert:</div>
                  <div className="flex flex-wrap items-center gap-2">
                    {queryArticleResponse?.selectedExpertIds?.map(
                      (sector: any, index: number) => (
                        <div
                          className="rounded flex bg-white items-center gap-2 shadow-custom-sm w-auto text-sm px-2 py-1"
                          key={index}
                        >
                          <p className="m-0 truncate">{sector?.name}</p>
                          <CloseOutlined
                            className="hover:text-danger"
                            onClick={() =>
                              queryArticleResponse?.handleRemoveSelectedExpert(
                                sector.id,
                              )
                            }
                          />
                        </div>
                      ),
                    )}
                  </div>
                </div>
              )}
          </div>
        </div>
      </Card>
    </div>
  )
}

export default ListPublicArticleFilter
