import { Collapse, Empty, Image, Skeleton, theme } from "antd"
import React from "react"
import ImageJobRole from "../../../assets/images/job_roles.png"
import { useNavigate } from "react-router-dom"
import { CaretRightOutlined } from "@ant-design/icons"

const JobRolesTab = ({ isLoading, dataJobRequiredSkill }) => {
  const navigate = useNavigate()

  const { token } = theme?.useToken()
  const panelStyle = {
    marginBottom: 24,
    background: "#E2EBFA",
    borderRadius: token?.borderRadiusLG,
    border: "none",
  }

  const { Panel } = Collapse

  const formatedData =
    dataJobRequiredSkill &&
    dataJobRequiredSkill?.list?.reduce((acc, item) => {
      const sectorId = item?.job_sector?.id
      if (!acc[sectorId]) {
        acc[sectorId] = {
          sector: item?.job_sector,
          jobs: [],
        }
      }
      acc[sectorId]?.jobs.push({
        name: item?.name,
        slug: item?.slug,
      })
      return acc
    }, {})

  const defaultKeys = Array.from(
    {
      length:
        typeof formatedData === "object" && formatedData !== null
          ? Object?.keys(formatedData ?? {})?.length
          : 0,
    },
    (_, i) => (i + 1).toString(),
  )

  return (
    <div className="mt-9 w-full">
      {isLoading ? (
        <div className="col-span-12 md:col-span-6 flex items-start gap-4 w-full">
          <Skeleton active avatar paragraph={{ rows: 4 }} />
        </div>
      ) : formatedData ? (
        <Collapse
          bordered={false}
          defaultActiveKey={defaultKeys}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          expandIconPosition="right"
          className="site-collapse-custom-collapse"
        >
          {Object.entries(formatedData).map((value, index) => {
            return (
              <Panel
                header={
                  <div className="flex items-center gap-3">
                    <Image
                      src={ImageJobRole}
                      width={40}
                      height={40}
                      preview={false}
                      className="object-cover flex-shrink-0 w-96"
                      alt=""
                    />
                    <div className="flex flex-col">
                      <p className="p-0 m-0 font-semibold">
                        {value[1]?.sector?.name}
                      </p>
                      <p className="p-0 m-0 text-xs">
                        {value[1]?.sector?.description}
                      </p>
                    </div>
                  </div>
                }
                key={index + 1}
                style={panelStyle}
              >
                {value[1]?.jobs?.map((job, jobIndex) => {
                  "formatted name", job
                  return (
                    <p
                      className="pt-2 cursor-pointer transform-gpu hover:text-secondary p-0 m-0 leading-5 capitalize"
                      key={jobIndex}
                      onClick={() => navigate(`/job/roles/${job.slug}`)}
                    >
                      {job.name}
                    </p>
                  )
                })}
              </Panel>
            )
          })}
        </Collapse>
      ) : (
        <div className="col-span-12">
          <Empty />
        </div>
      )}
    </div>
  )
}

export default JobRolesTab
