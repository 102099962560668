import { Button } from "antd"
import clsx from "clsx"
import React from "react"
import { useInfinite } from "../../../../hooks/useInfinite"
import { MyArticleResponseTypes } from "../../expert/types/response"
import { Moon } from "../../../../assets/images"
import useCommunityModalsStore from "../../store/useCommunityModalsStore"
import { LoadingOutlined } from "@ant-design/icons"
import ArticleHeader from "../../../../common/components/articles/ArticleHeader"

// interface QueryParamsType {
//   search?: string
//   green_skill_ids?: string
//   page?: number
//   limit?: number
//   status?: "draft" | "publish"
// }

const MyArticles = () => {
  const newArticleModal = useCommunityModalsStore(
    (state) => state.newArticleModal,
  )

  const {
    data,
    lastCardElementRef,
    isLoading,
    params,
    handleChageParams,
    setDataList,
  } = useInfinite<MyArticleResponseTypes>({
    key: "my-artilce-list",
    path: "/articles",
    queryParams: {
      search: undefined,
      green_skill_ids: undefined,
      page: undefined,
      limit: undefined,
      status: "publish",
    },
  })

  return (
    <div>
      <div className="p-5">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-4">
            <div
              onClick={() => {
                setDataList([])
                handleChageParams({ status: "publish" })
              }}
              className={clsx(
                "px-4 py-1 cursor-pointer transition-all duration-300 rounded-[10px]",
                {
                  "bg-secondaryYellow text-brown": params.status === "publish",
                  "bg-white ring-grey ring-1 text-darkGrey": !(
                    params.status === "publish"
                  ),
                },
              )}
            >
              Published
            </div>
            <div
              onClick={() => {
                setDataList([])
                handleChageParams({ status: "draft" })
              }}
              className={clsx(
                "px-4 py-1 cursor-pointer transition-all duration-300 rounded-[10px]",
                {
                  "bg-secondaryYellow text-brown": !(
                    params.status === "publish"
                  ),
                  "bg-white ring-grey ring-1 text-darkGrey":
                    params.status === "publish",
                },
              )}
            >
              Drafts
            </div>
          </div>
          <Button
            className=""
            type="primary"
            onClick={() => newArticleModal.open()}
          >
            New Article
          </Button>
        </div>
        <div className="mt-2 space-y-4">
          {/* ring-1 ring-grey p-7 rounded-xl bg-white mt-2 h-full */}
          {isLoading ? (
            <div className="flex items-center justify-center gap-5 flex-col">
              <LoadingOutlined />
              <div className="text-base font-semibold ">
                Loading Articles...
              </div>
            </div>
          ) : data?.length !== 0 ? (
            data?.map(
              (cat) =>
                cat?.green_skill_category?.article?.map((article, index) => (
                  <ArticleHeader
                    key={index}
                    article={article}
                    observ={
                      index === data?.length - 1
                        ? lastCardElementRef
                        : undefined
                    }
                    category={cat?.green_skill_category?.name}
                  />
                )),
            )
          ) : (
            <div className="flex items-center text-center justify-center gap-5 flex-col">
              <img
                src={Moon}
                className="h-[116px] object-contain aspect-square"
                alt="empty"
              />
              <div className="text-base font-semibold ">
                Create Your First Expert Article!
              </div>
              <div className="">
                Welcome! We're excited to have you share your expertise. Writing
                your first article by clicking the “new article” button is a
                great way to establish your contribution and valuable knowledge.
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MyArticles
