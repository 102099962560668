/* eslint-disable */

import { ApiResponse } from "../common/types/ApiResponse.ts"
import { MyJobRoles } from "../modules/missionControl/hooks/common_type.ts"
import { instanceV2 } from "../config/config.ts"
import { useQuery } from "react-query"
import { errorMessage } from "../common/utils/utils.tsx"

const fetchGreenCareer = () => {
  const response: Promise<ApiResponse<MyJobRoles[]>> = instanceV2.get(
    "/launchpad/career-aspirations",
  )
  return response
}

const useCareerAspiration = () => {
  const handleFetchError = (error: any) => {
    errorMessage(
      `An error occurred while fetching green career aspiration: ${error}`,
      error,
    )
  }
  const {
    data: greencareers,
    isLoading: isFetchingGreenCareer,
    isError: isFetchErrorGreenCareer,
    error: fetchErrorGreenCareer,
    refetch: referchMyCareerAspiration,
  } = useQuery(["fetch-my-career-aspirations"], () => fetchGreenCareer(), {
    refetchOnWindowFocus: false,
    onError: handleFetchError,
  })

  return {
    greencareerResp: {
      greencareers: greencareers?.data?.data,
      isFetchingGreenCareer,
      isFetchErrorGreenCareer,
      fetchErrorGreenCareer,
      referchMyCareerAspiration,
    },
  }
}

export default useCareerAspiration
