import { InfoCircleOutlined } from "@ant-design/icons"
import { Avatar, Image, Skeleton, Space } from "antd"
import React, { useState } from "react"
import { PinMapGray } from "../../../../assets/images"
import { SocialMediaLinks } from "../../../jobGalaxy/components/SocialMediaLink"
import DetailByRole from "../../public-expert/components/DetailByRole"
import DrawerCV from "../../public-expert/components/DrawerCV"
import useClimateSolution from "../../public-expert/hooks/useClimateSolution"
import useProduct from "../../public-expert/hooks/useProduct"
import { FounderResponse } from "../types/response"

interface FounderProfileContentProps {
  detail?: FounderResponse
  loading: boolean
  cv: any
  article: any
  id: string
  username: string
  tab: string
  activeAccount: any
  handler: any
}

const FounderProfileContent: React.FC<FounderProfileContentProps> = ({
  detail,
  loading,
  cv,
  article: data,
  id,
  username,
  tab,
  activeAccount,
}) => {
  const { editCompanyHandlers } = useClimateSolution(
    id,
    username,
    activeAccount,
    tab,
  )
  const { editProductHandlers } = useProduct(detail?.startup_id!)

  const reFormatSosmed = (sosmed: any) => {
    const data = {
      linkedin_url: sosmed?.linkedin,
    }

    return data
  }

  const [open, setOpen] = useState(false)

  const onClose = () => {
    setOpen(false)
  }

  const onOpen = () => {
    setOpen(true)
  }

  return loading ? (
    <div className="bg-white-custom px-4 py-6 rounded-xl shadow-custom-sm relative">
      <Skeleton avatar paragraph={{ rows: 4 }} active />{" "}
    </div>
  ) : (
    <div>
      <div className="bg-white px-4 py-6 rounded-xl shadow-custom-sm relative grid grid-cols-3 gap-5">
        <div className="col-span-2 bg-white-custom shadow-custom px-4 py-6 rounded-xl">
          <div className="flex gap-5">
            {detail?.avatar ? (
              <Image
                className="object-cover rounded-full"
                src={detail?.avatar}
                alt="profile"
                width={100}
                height={100}
                preview={false}
              />
            ) : (
              <Image
                className="object-cover rounded-full"
                src={detail?.avatar}
                alt="profile"
                width={100}
                height={100}
                preview={false}
              />
            )}

            <div className="space-y-3">
              <h3 className="capitalize">
                {detail?.first_name} {detail?.last_name}
              </h3>
              <div className="my-1 flex gap-5 items-center">
                <small className="px-2 bg-primary text-white -mt-2 z-40 rounded">
                  Founder
                </small>
                <div className="-mt-1">
                  {SocialMediaLinks(reFormatSosmed(detail))}
                </div>
              </div>

              <div>
                <PinMapGray />
                <span className="ml-2">{detail?.country}</span>
              </div>
            </div>
          </div>

          {detail?.about_me && detail?.about_me !== "<p><br></p>" && (
            <div
              className="mt-2"
              dangerouslySetInnerHTML={{
                __html: detail?.about_me,
              }}
            ></div>
          )}
        </div>

        <div className="cols-span-1 rounded-lg shadow-custom bg-white-custom py-7 px-5 relative w-full h-56">
          {data?.listLoading ? (
            <Skeleton active paragraph={{ rows: 4 }} />
          ) : (
            <div>
              <div className="w-full">
                <div className="flex items-center justify-start gap-6">
                  <div className="flex items-center gap-3">
                    <Image
                      src={data?.listData?.level_up_data?.image_url}
                      preview={false}
                      width={35}
                      height={35}
                    />
                    <div className="flex flex-col gap-x-2">
                      <div className="capitalize font-semibold text-[14px]">
                        {data?.listData?.level_up_data?.title}
                      </div>
                      <div className="capitalize text-[12px]">
                        {data?.listData?.level_up_data?.subtitle}
                      </div>
                    </div>
                  </div>

                  <Space className="" size={3} direction="vertical">
                    <div className="text-backdrop font-medium text-[12px]">
                      TerraPoints
                    </div>
                    <div className="text-backdrop text-xl font-bold">
                      {data?.listData?.level_up_data?.tera_point}
                    </div>
                  </Space>
                  <Space className="" size={3} direction="vertical">
                    <div className="text-backdrop font-medium text-[12px]">
                      Orbit Honors
                    </div>
                    <div className="text-backdrop text-xl font-extrabold">
                      {data?.listData?.level_up_data?.total_user_honors ?? 0}
                    </div>
                  </Space>
                </div>
              </div>

              <div
                className="grid bg-white rounded-[15px] px-5 py-4 grid-cols-1 md:grid-cols-6 mt-3 shadow-custom-sm md:absolute bottom-5 w-full md:w-[89%] transition-all cursor-pointer group"
                onClick={() => onOpen()}
              >
                <div className="absolute inset-0 bg-primary-bg opacity-0 group-hover:opacity-95 transition-opacity flex justify-center items-center rounded-[15px]">
                  <p className="text-backdrop text-xl font-semibold">
                    View full CV
                  </p>
                </div>
                <div className="col-span-1 md:col-span-2 relative group">
                  {data?.listData?.cv_data?.profile_picture ? (
                    <img
                      src={`https://stgreenskills001.blob.core.windows.net/default-avatars/7.png`}
                      alt="profile"
                      className="h-[72.5px] w-[72.5px] rounded-full object-contain bg-white"
                      style={{ border: "1px solid #D4F5C5" }}
                      onError={(e: any) => {
                        e.target.onerror = null
                        e.target.src = data?.listData?.cv_data?.avatar
                      }}
                    />
                  ) : (
                    <Avatar
                      src={
                        "https://stgreenskills001.blob.core.windows.net/default-avatars/7.png"
                      }
                      className=""
                      size={82.5}
                    />
                  )}
                </div>
                <div className="col-span-4">
                  <div className="flex items-center justify-between">
                    <h3>Green CV</h3>
                    <InfoCircleOutlined />
                  </div>
                  <div className="text-xs">
                    <div className="text-xs">
                      <b>
                        {data?.listData?.cv_data?.total_career_aspiration ?? 0}
                      </b>{" "}
                      Career Aspirations
                    </div>
                    <div className="text-xs">
                      <b>
                        {data?.listData?.cv_data
                          ?.total_proficient_green_skill ?? 0}
                      </b>{" "}
                      Green Skills I am proficient in
                    </div>
                    <div className="text-xs">
                      <b>
                        {data?.listData?.cv_data?.total_interested_green_skill}
                      </b>{" "}
                      Green Skills I am interested in
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {!data?.listLoading && (
        <DetailByRole
          data={data}
          detailPreview={detail}
          editCompanyHandlers={editCompanyHandlers}
          editProductHandlers={editProductHandlers}
        />
      )}

      <DrawerCV open={open} onClose={onClose} cv={cv} />
    </div>
  )
}

export default FounderProfileContent
