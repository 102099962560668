import { Route, Routes } from "react-router-dom"
import ListArticles from "./pages/ListArticles"
import ExpertProfile from "./pages/ExpertProfile"
import DetailArticle from "./pages/DetailArticle"
import NotFound from "../../auth/page/NotFound"

interface IndexProps {}

const Index: React.FC<IndexProps> = () => {
  return (
    <>
      <Routes>
        {/* PART OF EXPERT | ARTICLE FOR LOGIN USER */}
        <Route path="/my/article" element={<ListArticles />} />
        <Route path="/my/article/:slug" element={<DetailArticle />} />

        <Route path="/profile" element={<ExpertProfile />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  )
}

export default Index
