import React from "react"
import { CertifView } from "./CertifView"
import { Certification } from "../../../hooks/cv-builder/useCertification"
import { CertifEdit } from "./CertifEdit"

interface Props {
  data: Certification
  index: number
  updateTempData: (index: number, data: Certification) => void
  onRemove: (val: string) => Promise<void>
  loadingremoveCertif: boolean
  onRemoveTemp: (val: number) => void
}
export const Certif: React.FC<Props> = ({
  data,
  index,
  updateTempData,
  onRemove,
  loadingremoveCertif,
  onRemoveTemp,
}) => {
  return data.isEdit ? (
    <CertifEdit data={data} index={index} updateTempData={updateTempData} />
  ) : (
    <CertifView
      data={data}
      index={index}
      updateTempData={updateTempData}
      onRemove={onRemove}
      loadingremoveCertif={loadingremoveCertif}
      onRemoveTemp={onRemoveTemp}
    />
  )
}
