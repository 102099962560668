import React from "react"
import { HomeOutlined } from "@ant-design/icons"
import { PageHeader } from "../../../common/layout"
import { Typography } from "antd"

export const HeaderStatusPayment = () => {
  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "Payment Status",
    },
  ]
  return (
    <PageHeader breadcrumbItems={items}>
      <Typography.Text className=" text-lg font-semibold">
        Payment Status
      </Typography.Text>
    </PageHeader>
  )
}
