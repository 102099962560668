import React from "react"
import { SchoolTag } from "../../../../common/components"
import { Avatar, Button, Popover } from "antd"
import { TrainerPopoverContent } from "../TrainerPopoverContent"
import { Instructor, LevelsData, Skill } from "../../types/common"
import { ShoppingCartOutlined } from "@ant-design/icons"
import { LessonDuration } from "../../../../common/components"
import SkillProficiencyCard from "../../../../common/components/SkillProficiencyCard"
import { getInfoOnCurrentLevelOfSkillForUser } from "../../../../common/components/CurrentTrajectorySkill"
import {
  isNotNullOrUndefined,
  isNotNullOrZero,
} from "../../../../utils/util_funcs"
import SkeletonAvatar from "antd/es/skeleton/Avatar"
import { SingleSkillAddCartPopover } from "../SingleSkillAddCartPopover"
import { BitmovinPlayer } from "../../../../common/components/BitmovinPlayer"
import { withAuthentication } from "../../../../common/components"
import { useIsAuthenticated } from "@azure/msal-react"
import { addHumanReadableDurations } from "../../utils/util_funcs"
import { formatNumber } from "../../../../utils/formatNumber"
interface Props {
  isLoggedIn: boolean
  isLoading: boolean
  levelsData: LevelsData
  skillData: Skill
}

const BitmovinPlayerWithAuth = withAuthentication(BitmovinPlayer)

export const SidebarV2: React.FC<Props> = ({
  levelsData,
  skillData,
  isLoading,
}) => {
  const isLoggedIn = useIsAuthenticated()
  const uniqueTrainersMap = new Map<string, Instructor>()
  for (const level of levelsData?.levels || []) {
    const instructor = level?.instructor
    if (instructor && !uniqueTrainersMap.has(instructor.id)) {
      uniqueTrainersMap.set(instructor.id, instructor)
    }
  }
  const uniqueTrainers: Instructor[] = Array.from(uniqueTrainersMap.values())
  const { passedCount, image, isNothingBought } =
    getInfoOnCurrentLevelOfSkillForUser(levelsData?.levels)

  const levelProgress = levelsData?.levels?.[passedCount]?.user_track
    ?.is_assessment_passed
    ? levelsData?.levels[passedCount]?.user_track?.current_module
    : levelsData?.levels[passedCount]?.user_track?.current_module

  const isNextLevelExist = !!levelsData?.levels?.[passedCount]

  const progress =
    levelsData?.levels?.[isNextLevelExist ? passedCount : passedCount - 1]
      ?.user_track?.current_module ?? 0

  const moduleProgress = (progress / 5) * 100

  const text_content_metadata = addHumanReadableDurations(
    levelsData?.levels?.map(
      (level) => level?.text_content_metadata?.human_readable_duration ?? "",
    ) ?? [],
  )

  const video_content_metadata = addHumanReadableDurations(
    levelsData?.levels?.map(
      (level) => level?.video_metadata?.human_readable_duration ?? "",
    ) ?? [],
  )

  return (
    <div className="h-full relative">
      <div className="top-6 sticky  px-4 py-6 bg-secondaryBlue rounded-md">
        <div className="text-sm uppercase font-normal">
          {isLoading
            ? "Loading..."
            : levelsData?.school
              ? "Taught By"
              : "Coming Soon"}
        </div>
        <div className="flex justify-between mt-7">
          {isLoading ? (
            <SchoolTag
              schoolImage={levelsData?.school?.logo}
              schoolName={levelsData?.school?.name}
              isLoading={isLoading}
            />
          ) : (
            levelsData?.school?.name && (
              <SchoolTag
                schoolImage={levelsData?.school?.logo}
                schoolName={levelsData?.school?.name}
                isLoading={isLoading}
              />
            )
          )}
          {uniqueTrainers ? (
            <Avatar.Group
              maxCount={2}
              maxStyle={{
                color: "#f56a00",
                backgroundColor: "#fde3cf",
              }}
            >
              {uniqueTrainers?.map((trainer, index: number) => (
                <Popover
                  content={<TrainerPopoverContent instructor={trainer} />}
                  placement="bottomLeft"
                  key={index}
                >
                  <Avatar
                    size={33}
                    src={trainer?.avatar ? trainer.avatar : undefined}
                  >
                    {!trainer?.avatar &&
                      `${trainer?.first_name}  ${trainer?.last_name}`}
                  </Avatar>
                </Popover>
              ))}
            </Avatar.Group>
          ) : (
            "-"
          )}
        </div>
        <div className="w-full mt-7 flex justify-center">
          {isLoading ? (
            <SkeletonAvatar
              active
              shape="square"
              size={"large"}
              className="w-full h-[172px] object-contain"
            />
          ) : !isNothingBought && isLoggedIn ? (
            <SkillProficiencyCard
              imgSrc={image}
              passedLevel={passedCount}
              levelProgress={levelProgress ?? 1}
              moduleProgress={moduleProgress}
              rootClassName="bg-white w-full"
              levelName={`Level ${passedCount + 1}`}
            />
          ) : levelsData?.introduction_video?.dash ? (
            // isLoggedIn ? (
            //   <BitmovinPlayer
            //     dash={levelsData?.introduction_video?.dash}
            //     hls={undefined}
            //     poster={undefined}
            //   />
            // ) : (
            //   <BitmovinPlayer
            //     dash={levelsData?.introduction_video?.dash}
            //     hls={undefined}
            //     poster={undefined}
            //   />
            // )
            <BitmovinPlayerWithAuth
              dash={levelsData?.introduction_video?.dash}
              disabled={!isLoggedIn}
            />
          ) : (
            <img
              src={skillData?.image}
              height={172}
              alt="Skill"
              className="object-contain"
            />
          )}
        </div>
        {isNotNullOrZero(levelsData?.price_per_level) && (
          <div className="mt-7">
            <div className="">Price Per Level</div>
            <div className="flex items-baseline gap-2 mt-2">
              {levelsData?.pre_discount_price > 0 && (
                <div
                  className="text-sm line-through "
                  style={{ textDecorationColor: "red" }}
                >
                  {formatNumber(
                    levelsData?.pre_discount_price *
                      levelsData.currency.exchange,
                  )}
                </div>
              )}
              <div className="text-4xl font-bold">
                <span className=" uppercase">
                  {" "}
                  {levelsData?.currency?.code}{" "}
                </span>
                {formatNumber(
                  levelsData?.price_per_level * levelsData.currency.exchange,
                )}
              </div>
            </div>
          </div>
        )}
        <Popover
          // placement={`top`}
          trigger={"hover"}
          content={
            <SingleSkillAddCartPopover
              skill={skillData}
              levelData={levelsData}
            />
          }
          arrow={false}
        >
          <Button
            className="bg-primary text-white w-full mt-7"
            style={{ border: "1px solid #86C769" }}
            icon={<ShoppingCartOutlined />}
            // onClick={() => setIsOpenPopover(true)}
          >
            Add all to Cart
          </Button>
        </Popover>

        {isNotNullOrUndefined(text_content_metadata) &&
          isNotNullOrUndefined(video_content_metadata) && (
            <LessonDuration
              inputDurationType="object"
              textDurationObject={text_content_metadata}
              videoDurationObject={video_content_metadata}
              rootClassName="justify-between mt-5"
            />
          )}
      </div>
    </div>
  )
}
