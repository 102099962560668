import React, { useCallback, useMemo } from "react"
import { ExpertResponse, GreenSkill } from "../types/response"
import { Image, Skeleton, Tag } from "antd"
import { useNavigate } from "react-router-dom"
import { useModalStore } from "../../../stores"
import { SocialMediaLinks } from "../../jobGalaxy/components/SocialMediaLink"
import { EnvironmentOutlined } from "@ant-design/icons"

interface ExpertSectionCardProps {
  expert: ExpertResponse
}

function removeHtmlTags(str: string) {
  return str?.replace(/<\/?[^>]+(>|$)/g, "")
}

const ExpertSectionCard = React.memo(({ expert }: ExpertSectionCardProps) => {
  const links = useMemo(
    () => ({
      email: expert?.email,
      website: expert?.article_link,
      linkedin: expert?.linkedin_link,
      twitter: expert?.twitter,
    }),
    [expert],
  )

  const reFormatSosmed = useCallback((sosmed: any) => {
    const data = {
      // mail_url: sosmed?.email,
      website_url: sosmed?.link,
      linkedin_url: sosmed?.linkedin,
      twitter_url: sosmed?.twitter,
    }

    return data
  }, [])

  const navigate = useNavigate()
  const openModal = useModalStore((state) => state.openModal)

  const result = useMemo(() => {
    const filteredData: { [id: string]: GreenSkill } = {}

    expert?.green_skills?.forEach((entry) => {
      const id = entry.id as string
      if (!(id in filteredData)) {
        filteredData[id] = entry
      }
    })

    return Object.values(filteredData)
  }, [expert])

  return (
    <div
      className="bg-white-custom shadow-lg hover:shadow-xl rounded-xl p-4 cursor-pointer hover:scale-[1.02] transition-all duration-300 ease-in-out"
      onClick={() => navigate(`/community/public-expert/${expert?.username}`)}
    >
      <div className="flex gap-3">
        <div className="flex items-center justify-center  w-[137px] h-[92px]">
          {expert?.profile_picture ? (
            <Image
              src={expert?.profile_picture}
              onError={(e) => {
                const target = e.target as HTMLImageElement
                target.src = expert?.avatar || ""
              }}
              alt="moon image"
              preview={false}
              width={"100%"}
              className="object-cover rounded-lg bg-[#EF7574]"
            />
          ) : (
            <Image
              src={
                "https://stgreenskills001.blob.core.windows.net/default-avatars/7.png"
              }
              alt="moon image"
              preview={false}
              width={"100%"}
              className="object-cover "
            />
          )}
        </div>
        <div className="w-full relative h-[93px]">
          <h3 className="font-semibold -mt-1 absolute top-0">
            {expert?.first_name} {expert?.last_name}
          </h3>

          <div className="flex items-center gap-2 absolute top-[36%]">
            <EnvironmentOutlined className="text-[12px]" />
            <p className="text-[12px] m-0">{expert?.country}</p>
          </div>

          <div className="flex items-center justify-between w-full absolute bottom-2">
            <Tag
              className={`bg-[#FFF2E8] cursor-pointer`}
              onClick={() => {
                openModal()
              }}
            >
              Follow Expert
            </Tag>

            <div className="flex items-center gap-3">
              {SocialMediaLinks(reFormatSosmed(links))}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3">
        <p className="leading-5 line-clamp-3 m-0 text-[14px] text-darkGrey font-light h-[4.5em]">
          {removeHtmlTags(
            expert?.about_me_expert !== "<p><br></p>"
              ? expert?.about_me_expert ||
                  "The user has not written an About Me section."
              : "The user has not written an About Me section.",
          )}
        </p>
      </div>

      <div
        className={`mt-3  overflow-hidden ${
          expert?.green_skills && expert.green_skills.length > 1
            ? "!h-[57px] bottom-5"
            : "bottom-0"
        }`}
      >
        {result?.map((skill: GreenSkill, index: number) => {
          return (
            <Tag
              key={index}
              className="rounded-full py px-2 mb-2 cursor-pointer"
              color="#FAFAFA"
              style={{
                border: "1px solid gray",
                color: "#36505E",
              }}
            >
              {skill?.name}
            </Tag>
          )
        })}
      </div>
    </div>
  )
})

export const ExpertSectionCardLoading: React.FC = () => {
  return (
    <div className="bg-white-custom shadow-lg hover:shadow-xl rounded-xl p-4 cursor-pointer hover:scale-[1.02] transition-all duration-300 ease-in-out">
      <div className="flex items-center gap-3">
        <Skeleton.Image active className="object-cover rounded-lg " />
        <Skeleton paragraph={{ rows: 2 }} active />
      </div>
    </div>
  )
}

export default ExpertSectionCard
