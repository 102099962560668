import React, { useEffect, useRef } from "react"
import {
  Button,
  Card,
  DatePicker,
  Divider,
  Input,
  Select,
  Switch,
  Spin,
} from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { useJobHistoryUpdate } from "../../../hooks/cv-builder/useJobHistoryUpdate"
import { Controller } from "react-hook-form"
import { RichText } from "../../../../../common/components/RichText"
import { Common, JobHistory } from "../../../hooks/cv-builder/useJobHistory"
import { OtherCompany } from "../../../../../assets/images"
import dayjs from "dayjs"

interface Props {
  data: JobHistory
  countries: {
    data: Common[] | undefined
    loading: boolean
  }
  job_types: {
    data: Common[] | undefined
    loading: boolean
  }
  index: number
  updateTempData: (index: number, data: JobHistory) => void
}

export const JobEdit: React.FC<Props> = ({
  data,
  countries,
  job_types,
  index,
  updateTempData,
}) => {
  const {
    control,
    errors,
    otherCompany,
    dataTemp,
    image,
    handleSubmit,
    watch,
    onSubmit,
    loadingUpdateJobHis,
  } = useJobHistoryUpdate(data, index, updateTempData)

  const ref = useRef<HTMLDivElement>(null)
  const handleClikInside = (e: MouseEvent) => {
    if (ref.current?.contains(e.target as Node)) {
      otherCompany.setIsOpen(true)
    } else {
      otherCompany.setIsOpen(false)
    }
  }
  useEffect(() => {
    document.addEventListener("click", handleClikInside)
    return () => {
      document.removeEventListener("click", handleClikInside)
    }
  }, [])
  return (
    <form className="w-full p-5 rounded-xl bg-white shadow-md mb-5">
      <div className="flex gap-5 flex-wrap lg:flex-nowrap">
        <Card className=" shadow-md w-full rounded-xl flex-grow basis-1/2 bg-[#FAFAFA]">
          <div className=" flex flex-col items-center">
            <Controller
              name="company_name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <div ref={ref} className=" relative">
                  <Input
                    {...field}
                    bordered={false}
                    autoComplete={"off"}
                    onChange={otherCompany.handleSearch}
                    onKeyDown={(e) => {
                      if (e.key === "Enter")
                        otherCompany.handleAddCompany(e.currentTarget.value)
                    }}
                    status={errors?.company_name ? "error" : ""}
                    className="my-1 text-center  basis-1/2 text-lg font-bold leading-[38px] capitalize"
                    placeholder="Company Name"
                  />
                  {errors?.company_name && (
                    <div className=" text-xs w-full text-center text-danger">
                      *required
                    </div>
                  )}
                  {otherCompany.isOpen && otherCompany.value && (
                    <div
                      className=" absolute z-50 bg-[#FFFFFF] w-60 p-3 rounded-lg shadow-md"
                      style={{
                        left: "50%",
                        transform: "translate(-50%, 0)",
                      }}
                    >
                      <div
                        className="capitalize truncate cursor-pointer my-1"
                        onClick={() =>
                          otherCompany.handleAddCompany(otherCompany.value)
                        }
                      >
                        {otherCompany.value}
                      </div>

                      <Divider className="m-0 p-0" />
                      {otherCompany?.isLoading &&
                      !otherCompany?.data?.length ? (
                        <div className="p-1 mt-2 text-center">
                          <Spin
                            indicator={
                              <LoadingOutlined
                                style={{
                                  fontSize: 24,
                                }}
                                spin
                              />
                            }
                          />
                        </div>
                      ) : otherCompany?.data?.length ? (
                        otherCompany?.data?.map((item, index) => (
                          <div
                            key={index}
                            className=" capitalize truncate cursor-pointer my-1 flex items-center gap-3"
                            onClick={() =>
                              otherCompany?.handleSelectCompany(item)
                            }
                          >
                            <img
                              src={item.image || OtherCompany}
                              alt="company"
                              className="w-10 h-10 rounded-full shadow-md object-contain"
                            />
                            {item.name}
                          </div>
                        ))
                      ) : (
                        <div className="text-center text-gray-400 my-1">
                          click enter to create a new company
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            />
            <img
              src={image?.imageUrl || OtherCompany}
              alt="company"
              className="w-20 h-20 p-1 rounded-full shadow object-contain"
            />
            {dataTemp?.id ? (
              <div className="flex gap-3 text-xs my-2">
                <label
                  htmlFor="image-profile"
                  className=" text-secondary cursor-pointer"
                >
                  Replace
                </label>
                {image?.imageUrl && (
                  <div
                    className="text-danger cursor-pointer"
                    onClick={() => {
                      if (!image.loadingUpload || !image.loadingRemove)
                        image.handleRemoveImage()
                    }}
                  >
                    Remove
                  </div>
                )}
                <input
                  type="file"
                  disabled={image.loadingUpload || image.loadingRemove}
                  onChange={image.handleImageChange}
                  accept="image/png, image/gif, image/jpeg"
                  id="image-profile"
                  className=" hidden"
                  value={""}
                />
              </div>
            ) : (
              <div className="my-4"></div>
            )}
          </div>
          <div className="flex gap-3 my-3 flex-wrap md:flex-nowrap ">
            <Controller
              name="country_id"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  className="my-1 font-default w-full job-history"
                  placeholder="Employment country"
                  showSearch
                  status={errors?.country_id ? "error" : ""}
                  options={countries?.data?.map((val) => ({
                    label: val.name,
                    value: val.id,
                  }))}
                  labelInValue={true}
                  filterOption={(input, option) => {
                    if (option && option.label) {
                      return (
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      )
                    }
                    return false
                  }}
                />
              )}
            />
            <Controller
              name="employment_type_id"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  className="my-1 font-default w-full job-history"
                  placeholder="Employment type"
                  showSearch
                  status={errors?.employment_type_id ? "error" : ""}
                  options={job_types?.data?.map((val) => ({
                    label: val.name,
                    value: val.id,
                  }))}
                  labelInValue={true}
                  filterOption={(input, option) => {
                    if (option && option.label) {
                      return (
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      )
                    }
                    return false
                  }}
                />
              )}
            />
          </div>
          <Controller
            name="current_role"
            control={control}
            render={({ field }) => (
              <div className="flex gap-3 items-center">
                <Switch {...field} defaultChecked={field.value} size="small" />
                <div>Current role</div>
              </div>
            )}
          />
          <div className="flex gap-3 my-3">
            <Controller
              name="start_at"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  disabledDate={(current) => {
                    const end = watch(`end_at`)
                    const targetDate = dayjs(end || Date.now()).startOf("day")
                    const today = dayjs().startOf("day")
                    return current.isAfter(today) || current.isAfter(targetDate)
                  }}
                  placeholder="Start date"
                  format="DD MMM YYYY"
                  status={errors?.start_at ? "error" : ""}
                  className="w-full rounded"
                />
              )}
            />

            <Controller
              name="end_at"
              control={control}
              rules={
                !watch(`current_role`)
                  ? {
                      required: true,
                    }
                  : {
                      required: false,
                    }
              }
              render={({ field }) => (
                <DatePicker
                  {...field}
                  disabledDate={(current) => {
                    const start = watch(`start_at`)
                    const targetDate = dayjs(start || Date.now()).startOf("day")
                    return start
                      ? current.isBefore(targetDate.add(1, "day"))
                      : false
                  }}
                  placeholder="End date"
                  format="DD MMM YYYY"
                  status={errors?.end_at ? "error" : ""}
                  className={`${
                    watch(`current_role`) ? "invisible" : "visible"
                  } w-full rounded`}
                />
              )}
            />
          </div>
          <Controller
            name="is_remote"
            control={control}
            render={({ field }) => (
              <div className="flex gap-3 items-center">
                <Switch {...field} defaultChecked={field.value} size="small" />
                <div>Remote</div>
              </div>
            )}
          />
        </Card>
        <Card className="shadow-md w-full rounded-xl flex-grow basis-1/2 bg-[#FAFAFA]">
          <Controller
            name="job_title"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <div>
                <Input
                  bordered={false}
                  {...field}
                  autoComplete={"off"}
                  status={errors?.job_title ? "error" : ""}
                  className="basis-1/2 text-lg font-bold leading-[38px] capitalize"
                  placeholder="Job title"
                />
                {errors?.job_title && (
                  <small className=" text-danger">*required</small>
                )}
              </div>
            )}
          />

          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <RichText {...field} className="mt-3 w-full h-52" />
            )}
          />
        </Card>
      </div>
      <div className="mt-5 flex justify-end gap-5">
        <Button
          type="primary"
          ghost
          onClick={() => updateTempData(index, { ...data, isEdit: false })}
        >
          Cancel
        </Button>

        <Button
          type="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={loadingUpdateJobHis}
        >
          Save
        </Button>
      </div>
    </form>
  )
}
