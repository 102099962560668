import React, { useContext } from "react"
import { useQuery } from "react-query"
import { instanceV2 } from "../../../../config/config"
import { AccountContext } from "../../../../common/layout/PageLayout"
import { Invoice } from "./types"
import { AxiosResponse } from "axios"
import { message } from "antd"
import ErrorMessageBuilder from "../../../../common/components/ErrorMessageBuilder"

interface ResponseInvoice {
  data: Invoice[]
  has_more: false
  total_count: number
}

export const useInvoice = () => {
  const account = useContext(AccountContext)
  const { data, isLoading } = useQuery(
    "billing-invoice",
    () =>
      instanceV2.get<AxiosResponse<ResponseInvoice>>("/billing/invoices", {
        params: { customer_id: account?.data?.data?.customer_id },
      }),
    {
      enabled: !!account?.data?.data?.customer_id,
      onError: () => {
        message.open({
          key: "billing-invoice",
          duration: 5,
          content: React.createElement(ErrorMessageBuilder, {
            message:
              "We encountered an issue while attempting to fetch your billing invoice. If the problem continues, please refresh the page.",
            includeReportAt: true,
          }),
          type: "error",
        })
      },
      retry: 2,
    },
  )
  return {
    data: data?.data?.data,
    isLoading,
  }
}
