import React from "react"
import { BasicContainerWithTitle } from "../../../../common/components"
import { Carousel, Typography } from "antd"
import { useFavoriteSkillsPaginated } from "../../../missionControl/hooks/useFavoriteSkillsPaginated"
import { TagFavoriteSkill } from "../../../skillGalaxy/components"
import { useNavigate } from "react-router-dom"

const SavedSkills = () => {
  const favoriteSkillQuery = useFavoriteSkillsPaginated({
    page: 1,
    limit: 5,
  })
  const navigate = useNavigate()
  return (
    <BasicContainerWithTitle
      title="Interested in Skills"
      isLoading={favoriteSkillQuery?.isLoading}
      extraTitle={<span className="text-primary">see more</span>}
    >
      <Carousel arrows autoplay dots={false} className="p-3">
        {favoriteSkillQuery?.data?.data?.data?.list?.map((skill) => (
          <div key={skill.id} className="p-2">
            <div
              onClick={() => navigate(`/skill/green-skills/${skill?.id}`)}
              className="text-center flex flex-col justify-center items-center ring-1 p-4 ring-[#D9D9D9] bg-white rounded-[10px] cursor-pointer hover:shadow-lg  transition-transform duration-300"
            >
              <div className="z-10 flex items-center justify-between w-full">
                <TagFavoriteSkill
                  isFav={skill?.is_favorite}
                  skill_id={skill?.id}
                  onClick={() => {}}
                  isLoading={false}
                />

                <div className="text-sm text-backdrop font-light uppercase mb-1">
                  <span className=" uppercase">
                    {skill?.currency?.code ?? null}{" "}
                  </span>
                  {skill?.price}
                </div>
              </div>
              <img
                alt="example"
                onClick={() => navigate(`/skill/green-skills/${skill?.id}`)}
                src={skill?.image}
                className="object-contain h-[93px] w-full mt-5"
              />
              <div className="text-[10px] leading-[18px]  tracking-widest text-brown font-light uppercase">
                {skill?.category?.name ?? "N/A"}
              </div>
              <Typography.Paragraph
                className="text-backdrop font-bold text-base -mx-2"
                ellipsis={{ rows: 3 }}
              >
                {skill?.name}
              </Typography.Paragraph>
            </div>
          </div>
        ))}
      </Carousel>
    </BasicContainerWithTitle>
  )
}

export default SavedSkills
