import { Avatar, AvatarProps, Skeleton } from "antd"
import React from "react"
import { truncateText } from "../../utils/util_funcs"
import { avatarImgs } from "../../assets/avatar_images"
import { UserOutlined } from "@ant-design/icons"
import clsx from "clsx"

interface IBasicUserInfo {
  className?: string
  avatar?: string | undefined
  avatarProps?: AvatarProps
  name?: string
  nameProps?: React.HTMLAttributes<HTMLDivElement>
  location?: string
  locationProps?: React.HTMLAttributes<HTMLDivElement>
  isLoading?: boolean
}

const maxNameLength = 20
const maxOccupationLength = 20

export const BasicUserInfo: React.FC<IBasicUserInfo> = ({
  className,
  avatar,
  avatarProps,
  name,
  nameProps,
  location,
  locationProps,
  isLoading,
}) => {
  if (isLoading) {
    return (
      <div className={`flex gap-x-4 items-center ${className ?? ""}`}>
        <Skeleton.Avatar active />
        <div className="flex flex-col gap-x-4 animate-pulse">
          <div className="w-full h-2" />
          <div className="w-full h-2" />
        </div>
      </div>
    )
  }

  return (
    <div className={`flex gap-x-4 items-center ${className ?? ""}`}>
      <div>
        <Avatar
          {...avatarProps}
          style={{ border: "1px solid #D4F5C5" }}
          className=" object-cover"
          src={avatar ?? avatarImgs[0]}
          icon={<UserOutlined className="text-base" />}
        />
      </div>
      <div className="flex flex-col gap-x-4">
        <div
          {...nameProps}
          className={clsx("text-backdrop text-sm ", nameProps?.className)}
        >
          {truncateText(name ?? "", maxNameLength)}
        </div>
        <div
          {...locationProps}
          className={clsx("text-[#BFBFBF] text-xs ", locationProps?.className)}
        >
          {truncateText(location ?? "", maxOccupationLength)}
        </div>
      </div>
    </div>
  )
}
