import React from "react"
import { Card } from "antd"
import { Account } from "../../hooks/cv-builder/useProfile"
import { RadarSoftSkill } from "../cv-builder/skill/CVSkillCharts"

interface Props {
  data: Account | undefined
  label?: boolean
}

export const CardSoftSkill: React.FC<Props> = ({ data }) => {
  return (
    <Card className="shadow-md bg-[#FAFAFA] rounded-xl">
      <h2 className=" capitalize">Critical Core Skills</h2>

      {/* <ChartSoftSkill data={data?.soft_skill} legend={true} /> */}
      <RadarSoftSkill data={data?.soft_skill} />
    </Card>
  )
}

// interface ChartProps {
//   data: any;
// }
// const ChartSoftSkill: React.FC<ChartProps> = ({ data }) => {
//   const config = useMemo(() => {
//     return {
//       data: data || [],
//       xField: "name",
//       yField: "proficiency",
//       seriesField: "name",
//       label: false,
//       radius: 0.9,
//       state: {
//         active: {
//           style: {
//             lineWidth: 1,
//             fillOpacity: 0,
//           },
//         },
//       },
//       // legend: false,
//       interactions: [
//         {
//           type: "element-active",
//         },
//       ],
//       color: ({ name }: { name: string }) => {
//         const greenNames = [
//           "Creative Thinking",
//           "Decision Making",
//           "Problem Solving",
//           "Sense Making",
//           "Transdisciplinary Thinking",
//         ];

//         const yellowNames = [
//           "Building Inclusivity",
//           "Collaboration",
//           "Communication",
//           "Customer Orientation",
//           "Developing People",
//           "Influence",
//         ];

//         const blueNames = [
//           "Adaptability",
//           "Digital Fluency",
//           "Global Perspective",
//           "Learning Agility",
//           "Self Management",
//         ];

//         if (greenNames.includes(name)) {
//           return "#86C769";
//         }

//         if (yellowNames.includes(name)) {
//           return "#FAC61D";
//         }

//         if (blueNames.includes(name)) {
//           return "#009ACE";
//         }

//         return "gray";
//       },
//     };
//   }, [data]);

//   // @ts-ignore
//   return <Rose {...config} />;
// };
