import React, { useContext } from "react"
import { BasicContainerWithTitle } from "../../../../common/components"
import { AccountContext } from "../../../../common/layout/PageLayout"
import { useQuery } from "react-query"
import { fetchMentorDetails } from "../apis/mentors"
import { CheckCircleFilled } from "@ant-design/icons"

const MentorChecklist = () => {
  const account = useContext(AccountContext)
  const accountData = account?.data?.data
  const mentorDetails = useQuery(["mentor-details", accountData?.id], () =>
    fetchMentorDetails(accountData?.id ?? ""),
  )
  const mentorDetailsData = mentorDetails?.data?.data?.data
  const mentorChecklist = [
    {
      title: "Complete your Mentor Profile",
      status: mentorDetailsData?.is_profile_complete,
    },
    {
      title: "Add Mentorship Areas",
      status: mentorDetailsData?.is_mentor_area_exist,
    },
    {
      title: "Add Domains of Expertise ",
      status: mentorDetailsData?.is_mentor_domain_exist,
    },
    {
      title: "Set your availability",
      status: mentorDetailsData?.is_set_availability,
    },
  ]
  return (
    <BasicContainerWithTitle
      title="Checklist"
      isLoading={mentorDetails.isLoading}
    >
      <div className="space-y-2 mt-3">
        {mentorChecklist.map((item, index) => {
          return (
            <div className="flex items-center gap-2" key={index}>
              <CheckCircleFilled
                className={`${item?.status ? "text-primary" : "text-darkGrey"}`}
              />
              <span>{item.title}</span>
            </div>
          )
        })}
      </div>
    </BasicContainerWithTitle>
  )
}

export default MentorChecklist
