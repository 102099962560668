import { useQuery } from "react-query"
import instance from "../../../config/config"
import { ApiResponse } from "../../../common/types/ApiResponse"

type FavoriteSkill = {
  id: string | null
  name: string | null
  currency: string | null
  data_source: string | null
}

export type UserProfile = {
  user_id: string | null
  rank: number | null
  point: number | null
  location: string | null
  first_name: string | null
  last_name: string | null
  nickname: string | null
  avatar: string | null
  profile_headline: string | null
  email: string | null
  linkedin: string | null
  twitter: string | null
  link: string | null
  skill_level_passed: number | null
  favorite_skills: FavoriteSkill[] | null
  orbit: number | null
  orbit_image: string | null
}

type TLevelKey = "orbit" | "global" | "local"

const fetchLeaderboardsByCategory = (category: TLevelKey) => {
  const response: Promise<ApiResponse<UserProfile[]>> = instance.get(
    `/leaderboards/${category}`,
  )
  return response
}

export const useSustainabilityScores = (category: TLevelKey) => {
  return useQuery(
    ["fetch-leaderboards", category],
    () => fetchLeaderboardsByCategory(category),
    {
      refetchOnWindowFocus: false,
      retry: () =>
        !(
          typeof instance?.defaults?.headers?.common?.["Authorization"] ===
          "string"
        ),
    },
  )
}
