import { message } from "antd"
import { AxiosResponse } from "axios"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import instance, { instanceV2 } from "../../../../config/config"
import { useFetchDetail, useFetchList } from "../../../../hooks/generic_rest"
import { debounce } from "../../../../utils/debounce"
import { ExpertResponse } from "../../../home/types/response"
import {
  Account,
  useProfile,
} from "../../../missionControl/hooks/cv-builder/useProfile"
import {
  useFollowExpert,
  useUnFollowExpert,
} from "../../../skillGalaxy/apis/rest"
import { useFounderReference } from "../../founder/hooks/useFounderReference"
import { EXPERT_ENDPOINTS } from "../apis/endpoint"
import ErrorMessageBuilder from "../../../../common/components/ErrorMessageBuilder"

export const LIST_EXPERT_QUERY_NAME: string = "list-public-experts"
export const useListExpert = () => {
  const { account, loadingAccount } = useProfile()
  interface LabelValue {
    label: string
    value: string
  }
  interface QueryParams {
    page: number
    limit: number
    name: string
    location_id: string
    domain_id: string
    domain: LabelValue[]
    user_login_id: string | undefined
  }

  const initialQueryParams: QueryParams = {
    page: 1,
    limit: 10,
    name: "",
    location_id: "",
    domain_id: "",
    domain: [],
    user_login_id: undefined,
  }

  const [queryParams, setQueryParams] =
    useState<QueryParams>(initialQueryParams)
  const [dataList, setDataList] = useState<ExpertResponse[]>([])
  const [total, setTotal] = useState<number>(0)
  const [loadingCustom, setLoadingCustom] = useState<boolean>(false)

  const query = useQueryClient()

  const {
    data: listData,
    isLoading: listLoading,
    isError: listError,
    refetch: refetchListData,
  } = useFetchList<ExpertResponse>(`${EXPERT_ENDPOINTS.LIST}`, {
    queryParams,
    queryName: LIST_EXPERT_QUERY_NAME,
    enabled: false,
  })

  useEffect(() => {
    if (!listLoading) {
      if (queryParams.page === 1) {
        setDataList(listData?.list || [])
        setTotal(listData?.total_data || 0)
      } else if (
        dataList?.[dataList?.length - 1]?.id !==
        listData?.list?.[listData?.list?.length - 1]?.id
      ) {
        setDataList((prev) => [...prev, ...(listData?.list || [])])
        setTotal(listData?.total_data || 0)
      }
    }
  }, [listData?.list])

  useEffect(() => {
    setLoadingCustom(true)
    if (queryParams.user_login_id) {
      refetchListData()
        .then(() => {
          console.log("Refetch complete")
          setLoadingCustom(false)
        })
        .catch((error) => {
          setLoadingCustom(false)
          console.error("Refetch error:", error)
        })
    }
  }, [queryParams, refetchListData])

  useEffect(() => {
    if (account) {
      setQueryParams({
        ...queryParams,
        user_login_id: account?.id,
      })
    }
  }, [account, loadingAccount])

  const handleSearch = debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setQueryParams({
        ...queryParams,
        name: event.target.value!,
        user_login_id: account?.id,
      })
    },
    500,
  )

  const handleFilterByLocation = (value: any) => {
    const currentLocationIds = queryParams.location_id
      ? queryParams.location_id.split(",")
      : []
    if (!currentLocationIds.includes(value.label)) {
      const newLocationIds = [...currentLocationIds, value.label]
      const newLocationIdString = newLocationIds.join(",")
      setQueryParams({
        ...queryParams,
        location_id: newLocationIdString,
        user_login_id: account?.id,
      })
    }
  }

  const handleRemoveLocation = (value: string) => {
    const currentLocationIds = queryParams.location_id.split(",")
    const updatedLocationIds = currentLocationIds.filter((id) => id !== value)
    const newLocationIdString = updatedLocationIds.join(",")
    setQueryParams({
      ...queryParams,
      location_id: newLocationIdString,
      user_login_id: account?.id,
    })
  }

  const handleFilterByDomain = (value: LabelValue) => {
    const currentDomainIds = queryParams.domain_id
      ? queryParams.domain_id.split(",")
      : []

    if (!currentDomainIds.includes(value.value)) {
      const newDomainIds = [...currentDomainIds, value.value]
      const newDomainIdString = newDomainIds.join(",")

      setQueryParams({
        ...queryParams,
        domain_id: newDomainIdString,
        domain: [...queryParams.domain, value],
        user_login_id: account?.id,
      })
    }
  }

  const handleRemoveDomain = (value: any) => {
    const currentDomainIds = queryParams.domain_id.split(",")
    const updatedDomainIds = currentDomainIds.filter((id) => id !== value.value)
    const newDomainIdString = updatedDomainIds.join(",")
    setQueryParams({
      ...queryParams,
      domain_id: newDomainIdString,
      domain: queryParams.domain.filter(
        (domain) => domain.value !== value.value,
      ),
      user_login_id: account?.id,
    })
  }

  // REFERENCE DATA
  const { locationData } = useFounderReference()

  const restFollowExpert = useFollowExpert()
  const restUnfollowExpert = useUnFollowExpert()

  const handleFollowExpert = async (expertID: string) => {
    try {
      await restFollowExpert.mutateAsync({
        expertID: expertID,
      })
      query.invalidateQueries(LIST_EXPERT_QUERY_NAME)
    } catch (error: any) {
      message.error(
        "An error occurred while save your article: " +
          error.response?.data?.data,
      )
    }
  }
  const handleUnFollowExpert = async (expertID: string) => {
    try {
      await restUnfollowExpert.mutateAsync({
        expertID: expertID,
      })
      query.invalidateQueries(LIST_EXPERT_QUERY_NAME)
    } catch (error: any) {
      message.error(
        "An error occurred while save your article: " +
          error.response?.data?.data,
      )
    }
  }

  // infinity scroll
  const observer = useRef<IntersectionObserver>()
  const lastElementRef = useCallback(
    (node: any) => {
      if (listLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && total !== dataList.length) {
          setQueryParams((prev) => ({
            ...prev,
            page: prev.page + 1,
          }))
        }
      })
      if (node) observer.current.observe(node)
    },
    [listLoading, total, dataList.length],
  )

  return {
    expertData: {
      listData: dataList,
      listLoading,
      loadingCustom,
      listError,
    },
    queryExpert: {
      queryParams,
      setQueryParams,
      handleSearch,
      handleFilterByLocation,
      handleRemoveLocation,
      handleFilterByDomain,
      handleRemoveDomain,
      handleFollowExpert,
      handleUnFollowExpert,
      locationSelected: queryParams?.location_id
        ?.split(",")
        ?.filter((id: string) => id !== ""),
      domainSelected: queryParams?.domain,
      lastElementRef,
    },
    referenceData: {
      locationData,
    },
  }
}

export const DETAIL_EXPERT_QUERY_NAME: string = "detail-expert"

export const useDetailExpert = (
  id: string,
  username: string,
  activeAccount: any,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  tab: number,
) => {
  interface QueryParams {
    user_login_id: string | undefined
  }

  const initialQueryParams: QueryParams = {
    user_login_id: undefined,
  }

  const [queryParams, setQueryParams] =
    useState<QueryParams>(initialQueryParams)

  const { account: acc, loadingAccount: loadAcc } = useProfile()

  const query = useQueryClient()
  const {
    data: detailExpert,
    isLoading: detailLoadingExpert,
    isError: detailErrorExpert,
    refetch,
  } = useFetchDetail<ExpertResponse>(`${EXPERT_ENDPOINTS.DETAIL(id)}`, {
    queryParams,
    queryName: DETAIL_EXPERT_QUERY_NAME,
    enabled: false,
  })

  useEffect(() => {
    if (queryParams.user_login_id) {
      refetch()
        .then(() => {
          console.log("Refetch complete")
        })
        .catch((error) => {
          console.error("Refetch error:", error)
        })
    }
  }, [queryParams, refetch])

  useEffect(() => {
    if (acc) {
      setQueryParams({
        ...queryParams,
        user_login_id: acc?.id,
      })
    }
  }, [acc, loadAcc])

  const {
    data: listData,
    isLoading: listLoading,
    isError: listError,
  } = useQuery(
    ["expertProfile"],
    async () => {
      const endpoint = activeAccount
        ? `/publish/articles/user/${username}`
        : `/publish/articles/user/${username}`

      const response = await instance.get(endpoint)
      if (!response) {
        throw new Error("Network response was not ok")
      }
      return response
    },
    {
      onError: () => {
        message.open({
          key: "expertProfile",
          duration: 5,
          content: React.createElement(ErrorMessageBuilder, {
            message:
              "We encountered an issue while attempting to fetch the expert's profile. If the problem continues, please refresh the page.",
            includeReportAt: true,
          }),
          type: "error",
        })
      },
      retry: 2,
      enabled: !!detailExpert,
    },
  )

  // CV
  const {
    data: account,
    isLoading: loadingAccount,
    isError,
  } = useQuery(
    "expert-account-cv",
    () =>
      instanceV2.get<AxiosResponse<Account>>(`/accounts/cv/id/${id}`, {
        params: {
          anonymised: 0,
        },
      }),
    {
      onError: () => {
        message.open({
          key: "expert-account-cv",
          duration: 5,
          content: React.createElement(ErrorMessageBuilder, {
            message:
              "We encountered an issue while attempting to fetch expert's account details. If the problem continues, please refresh the page.",
            includeReportAt: true,
          }),
          type: "error",
        })
      },
      retry: 2,
      enabled: !!username || !!id,
    },
  )

  const restFollowExpert = useFollowExpert()
  const restUnfollowExpert = useUnFollowExpert()
  const handleFollowExpert = async (expertID: string) => {
    try {
      await restFollowExpert.mutateAsync({
        expertID: expertID,
      })
      query.invalidateQueries(DETAIL_EXPERT_QUERY_NAME)
    } catch (error: any) {
      message.error(
        "An error occurred while save your article: " +
          error.response?.data?.data,
      )
    }
  }
  const handleUnFollowExpert = async (expertID: string) => {
    try {
      await restUnfollowExpert.mutateAsync({
        expertID: expertID,
      })
      query.invalidateQueries(DETAIL_EXPERT_QUERY_NAME)
    } catch (error: any) {
      message.error(
        "An error occurred while save your article: " +
          error.response?.data?.data,
      )
    }
  }

  console.log("detailExpert 1212", detailExpert)

  return {
    detail: {
      detailExpert,
      detailLoadingExpert,
      detailErrorExpert,
      handleFollowExpert,
      handleUnFollowExpert,
    },
    cv: {
      account: account?.data.data,
      loadingAccount,
      isError,
    },
    article: {
      listData: listData?.data.data,
      listLoading,
      listError,
    },
  }
}
