import { useInfiniteQuery, useQuery } from "react-query"
import instance from "../config/config"
import { errorMessage } from "../common/utils/utils"
import ErrorMessageBuilder from "../common/components/ErrorMessageBuilder"

export const useCountries = () => {
  const { data: countries } = useQuery(
    "countries",
    () => instance.get("/commons/countries"),
    {
      onError: () => {
        message.open({
          key: "countries",
          duration: 5,
          content: React.createElement(ErrorMessageBuilder, {
            message:
              "We encountered an issue while attempting to fetch countries. If the problem continues, please refresh the page.",
            includeReportAt: true,
          }),
          type: "error",
        })
      },
      retry: 2,
    },
  )
  return { countries }
}

const fetchCountriesInfinity = ({ pageParam = 1, search }) => {
  return instance.get("/references/countries", {
    params: {
      page: pageParam,
      limit: 10,
      name: search,
    },
  })
}
export const useCountriesInfinity = (searchParam) => {
  const handleFetchError = (error) => {
    errorMessage(`An error occurred while fetching countries: ${error}`, error)
  }

  return useInfiniteQuery(
    ["countries-infinity", searchParam],
    ({ pageParam }) =>
      fetchCountriesInfinity({ pageParam, search: searchParam }),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
      getNextPageParam: (lastPage) =>
        lastPage?.data?.data?.pagination?.page <
        lastPage?.data?.data?.pagination?.total_page
          ? lastPage?.data?.data?.pagination?.page + 1
          : undefined,
    },
  )
}
