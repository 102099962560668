/* eslint-disable */

import { useQuery, useMutation, useQueryClient } from "react-query"
import { message } from "antd"
import instance from "../../../config/config"
import { ApiResponse } from "../../../common/types/ApiResponse"
import { FavoriteSkill } from "../../skillGalaxy/types/common"
import { useNavigate } from "react-router-dom"
import { errorMessage } from "../../../common/utils/utils"

const fetchFavoriteSkills = () => {
  const response: Promise<ApiResponse<FavoriteSkill[]>> = instance.get(
    "green-skills/favorites?with=detail",
  )
  return response
}

type SkillData = {
  green_skill_id: string
}

const useFavoriteSkills = (isLoggedIn: boolean) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const addSkillToFavorite = (skillData: SkillData) => {
    message.open({
      key: "add-skill-favorite",
      content: "Favoriting...",
      type: "loading",
    })
    return instance.post("green-skills/favorites", skillData)
  }

  const updateFavoriteSkill = ({
    skill_id,
    skillData,
  }: {
    skill_id: string
    skillData: any
  }) => {
    return instance.patch(`green-skills/favorites/${skill_id}`, skillData)
  }

  const removeSkillFromFavorite = (skill_id: string) => {
    message.open({
      key: "remove-skill-favorite",
      content: "Unfavoriting...",
      type: "loading",
    })
    return instance.delete(`green-skills/favorites/${skill_id}`)
  }

  const handleFetchError = (error: any) => {
    errorMessage(
      `An error occurred while fetching favorite skills: ${error}`,
      error,
    )
  }

  const handleAddSuccess = () => {
    message.open({
      key: "add-skill-favorite",
      content: (
        <span>
          Skill succesfully added to favorites. To view favorites visit{" "}
          <span
            className="font-semibold cursor-pointer text-secondary"
            onClick={() =>
              navigate("/dashboard/skill-trajectory-plan/skill-roadmap")
            }
          >
            Skill Trajectory.
          </span>
        </span>
      ),
      type: "success",
      onClick(e) {
        navigate("/dashboard/skill-trajectory-plan/skill-roadmap")
      },
      duration: 5,
    })
  }

  const handleAddError = (error: any) => {
    message.open({
      key: "add-skill-favorite",
      content: error?.response?.data?.data,
      type: "error",
    })
  }

  const handleUpdateSuccess = () => {
    message.success("Favorite skill successfully updated")
  }

  const handleUpdateError = (error: any) => {
    errorMessage(
      `An error occurred while updating a favorite skill: ${error}`,
      error,
    )
  }

  const handleRemoveSuccess = () => {
    message.open({
      key: "remove-skill-favorite",
      content: (
        <span>
          Skill succesfully removed from favorites. To view favorites visit{" "}
          <span
            className="font-semibold cursor-pointer text-secondary"
            onClick={() =>
              navigate("/dashboard/skill-trajectory-plan/skill-roadmap")
            }
          >
            Skill Trajectory.
          </span>
        </span>
      ),
      type: "success",
      onClick(e) {
        navigate("/dashboard/skill-trajectory-plan/skill-roadmap")
      },
      duration: 5,
    })
  }

  const handleRemoveError = (error: any) => {
    message.open({
      key: "remove-skill-favorite",
      content: error?.response?.data?.data,
      type: "error",
    })
  }

  const {
    data: favoriteSkills,
    isLoading: isFetchingFavorite,
    isError: isFetchError,
    error: fetchError,
  } = useQuery(["fetch-favorite"], () => fetchFavoriteSkills(), {
    refetchOnWindowFocus: false,
    onError: handleFetchError,
    enabled: isLoggedIn,
  })

  const {
    mutate: addSkillToFavoriteMutate,
    isLoading: isAddingSkillToFavorite,
    isError: isAddError,
    error: addError,
  } = useMutation(
    (skill_id: string) => addSkillToFavorite({ green_skill_id: skill_id }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["fetch-favorite"])
        queryClient.invalidateQueries(["fetch-green-skills"])

        handleAddSuccess()
      },
      onError: handleAddError,
      // enabled: isLoggedIn,
    },
  )

  const {
    mutate: updateFavoriteSkillMutate,
    isLoading: isUpdatingFavoriteSkill,
    isError: isUpdateError,
    error: updateError,
  } = useMutation(
    ({ skill_id, skillData }: { skill_id: string; skillData: any }) =>
      updateFavoriteSkill({ skill_id, skillData }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["fetch-favorite"])
        queryClient.invalidateQueries(["fetch-green-skills"])
        handleUpdateSuccess()
      },
      onError: handleUpdateError,
      // enabled: isLoggedIn,
    },
  )

  const {
    mutate: removeSkillFromFavoriteMutate,
    isLoading: isRemovingSkillFromFavorite,
    isError: isRemoveError,
    error: removeError,
  } = useMutation((skill_id: string) => removeSkillFromFavorite(skill_id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["fetch-favorite"])
      queryClient.invalidateQueries(["fetch-green-skills"])
      handleRemoveSuccess()
    },
    onError: handleRemoveError,
    // enabled: isLoggedIn,
  })

  return {
    favoriteSkills,
    isFetchingFavorite,
    isFetchError,
    fetchError,
    addSkillToFavorite: addSkillToFavoriteMutate,
    isAddingSkillToFavorite,
    isAddError,
    addError,
    updateFavoriteSkill: updateFavoriteSkillMutate,
    isUpdatingFavoriteSkill,
    isUpdateError,
    updateError,
    removeSkillFromFavorite: removeSkillFromFavoriteMutate,
    isRemovingSkillFromFavorite,
    isRemoveError,
    removeError,
  }
}

export default useFavoriteSkills
