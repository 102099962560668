/* eslint-disable */

import { useQuery, useMutation, useQueryClient } from "react-query"
import instance from "../../../config/config"
import { message } from "antd"
import { ApiResponse } from "../../../common/types/ApiResponse"
import type { UserTrack } from "../../skillGalaxy/types/common"
import { errorMessage } from "../../../common/utils/utils"

export type RoadmapLevel = {
  id: string
  name: string
  proficiency: number
  user_track: UserTrack | null
}

export type GetRoadmap = {
  id: string
  image: string
  name: string
  user_id: string
  levels: RoadmapLevel[]
  is_signature_exist: boolean
  is_request_verified: boolean
  total_available_level: number
  total_completed_level: number
  total_purchased_level: number
}

const fetchRoadMap = (): Promise<ApiResponse<GetRoadmap[]>> => {
  return instance.get("green-skills/roadmaps?level_passed=0")
}

/**
 * @deprecated This function is deprecated and should not be used. No longer are skills added to roadmap by users.
 */
const addSkillToRoadmap = (skillData: any) => {
  //  (skillData);
  return instance.post("green-skills/roadmaps", skillData)
}

/**
 * @deprecated This function is deprecated and should not be used. Not updated from FE anymore.
 */
const updateRoadmapSkill = ({
  skill_id,
  skillData,
}: {
  skill_id: string
  skillData: any
}) => {
  return instance.patch(`green-skills/roadmaps/${skill_id}`, skillData)
}

const updateRoadmapSkillOrder = (skillData: string[]) => {
  return instance.patch("green-skills/roadmaps", {
    green_skill_id: skillData,
  })
}

const removeSkillFromRoadmap = (skill_id: string) => {
  return instance.delete(`green-skills/roadmaps/${skill_id}`)
}

const useRoadmapSkills = () => {
  const queryClient = useQueryClient()

  const handleFetchError = (error: any) => {
    errorMessage(
      `An error occurred while fetching roadmap skills: ${error}`,
      error,
    )
  }

  const handleAddSuccess = () => {
    message.success("Skill successfully added to the roadmap")
  }

  const handleAddError = (error: any) => {
    errorMessage(
      `An error occurred while adding a skill to the roadmap: ${error}`,
      error,
    )
  }

  const handleUpdateSuccess = () => {
    message.success("Roadmap skill successfully updated")
  }

  const handleUpdateError = (error: any) => {
    message.open({
      key: "update-skill",
      content: `An error occurred while updating a skill: ${error}`,
      type: "error",
    })
  }

  const handleRemoveSuccess = () => {
    message.success("Skill successfully removed from the roadmap")
  }

  const handleRemoveError = (error: any) => {
    errorMessage(
      `An error occurred while removing a skill from the roadmap: ${error}`,
      error,
    )
  }

  const {
    data: roadmapSkills,
    isLoading: isFetchingRoadmap,
    isError: isFetchError,
    error: fetchError,
  } = useQuery(["fetch-roadmap"], () => fetchRoadMap(), {
    refetchOnWindowFocus: false,
    onError: handleFetchError,
    retry: () =>
      !(
        typeof instance?.defaults?.headers?.common?.["Authorization"] ===
        "string"
      ),
  })

  const {
    mutate: addSkillToRoadmapMutate,
    isLoading: isAddingSkillToRoadmap,
    isError: isAddError,
    error: addError,
  } = useMutation(
    (skill_id) => addSkillToRoadmap({ green_skill_id: skill_id }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["fetch-roadmap"])
        handleAddSuccess()
      },
      onError: handleAddError,
      retry: () =>
        !(
          typeof instance?.defaults?.headers?.common?.["Authorization"] ===
          "string"
        ),
    },
  )

  const {
    mutate: updateRoadmapSkillMutate,
    isLoading: isUpdatingRoadmapSkill,
    isError: isUpdateError,
    error: updateError,
  } = useMutation(
    ({ skill_id, skillData }: { skill_id: string; skillData: any }) =>
      updateRoadmapSkill({ skill_id, skillData }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["fetch-roadmap"])
        handleUpdateSuccess()
      },
      onError: handleUpdateError,
      retry: () =>
        !(
          typeof instance?.defaults?.headers?.common?.["Authorization"] ===
          "string"
        ),
    },
  )

  const {
    mutate: updateRoadmapSkillOrderMutate,
    isLoading: isUpdatingRoadmapSkillOrder,
    isError: isUpdateOrderError,
    error: updateOrderError,
  } = useMutation(
    (skillData: string[]) => {
      message.open({
        key: "update-skill-order",
        content: "Updating...",
        type: "loading",
      })
      return updateRoadmapSkillOrder(skillData)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["fetch-roadmap"])
        message.open({
          key: "update-skill-order",
          content: "Order updated",
          type: "success",
        })
      },
      onError: handleUpdateError,
      retry: () =>
        !(
          typeof instance?.defaults?.headers?.common?.["Authorization"] ===
          "string"
        ),
    },
  )

  const {
    mutate: removeSkillFromRoadmapMutate,
    isLoading: isRemovingSkillFromRoadmap,
    isError: isRemoveError,
    error: removeError,
  } = useMutation((skill_id: string) => removeSkillFromRoadmap(skill_id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["fetch-roadmap"])
      handleRemoveSuccess()
    },
    onError: handleRemoveError,
    retry: () =>
      !(
        typeof instance?.defaults?.headers?.common?.["Authorization"] ===
        "string"
      ),
  })

  return {
    roadmapSkills,
    isFetchingRoadmap,
    isFetchError,
    fetchError,
    addSkillToRoadmap: addSkillToRoadmapMutate,
    isAddingSkillToRoadmap,
    isAddError,
    addError,
    updateRoadmapSkill: updateRoadmapSkillMutate,
    isUpdatingRoadmapSkill,
    isUpdateError,
    updateError,
    removeSkillFromRoadmap: removeSkillFromRoadmapMutate,
    isRemovingSkillFromRoadmap,
    isRemoveError,
    removeError,
    updateRoadmapSkillOrder: updateRoadmapSkillOrderMutate,
    isUpdatingRoadmapSkillOrder,
    isUpdateOrderError,
    updateOrderError,
  }
}

export default useRoadmapSkills
