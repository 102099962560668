import { Image, Skeleton, Tag } from "antd"
import React from "react"
import { Moon } from "../../../../assets/images"
import { LikeTwoTone, LikeOutlined } from "@ant-design/icons"
import dayjs from "dayjs"
import CardLoadingPublicArticle from "./CardLoadingPublicArticle"
import { SocialMediaLinks } from "../../../jobGalaxy/components/SocialMediaLink"
import { EnvironmentOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { useProfile } from "../../../missionControl/hooks/cv-builder/useProfile"
import { useModalStore } from "../../../../stores"

interface DetailPublicArticleContentProps {
  articleResponse: any
  expertQuery: any
  queryArticleResponse: any
}

const DetailPublicArticleContent: React.FC<DetailPublicArticleContentProps> = ({
  articleResponse: article,
  expertQuery,
  queryArticleResponse,
}) => {
  const reFormatSosmed = (sosmed: any) => {
    const data = {
      // mail_url: sosmed?.email,
      website_url: sosmed?.link,
      linkedin_url: sosmed?.linkedin,
      twitter_url: sosmed?.twitter,
    }

    return data
  }

  const navigate = useNavigate()
  const { account } = useProfile()

  const openModal = useModalStore((state) => state.openModal)

  return (
    <div>
      {article?.isLoading ? (
        <CardLoadingPublicArticle index={1} key={1} />
      ) : (
        <div className="space-y-4 rounded-xl shadow-custom-sm py-5 px-4 hover:shadow-custom cursor-pointer bg-white">
          <div className="flex gap-3 flex-wrap md:flex-nowrap">
            <div className="bg-red-200 w-full md:w-[220px] h-[180px] rounded-xl flex items-center">
              <Image
                src={article?.data?.img || Moon}
                alt="moon image"
                preview={false}
                width={"100%"}
                height={"100%"}
                className="object-cover rounded-xl"
              />
            </div>

            <div className="w-full h-[290px] md:h-[180px] relative">
              <div className="absolute top-0 w-full">
                <div className="flex items-center justify-between  text-xs -mt-2 w-full">
                  <div className="flex items-center gap-2">
                    <p className="tracking-widest uppercase text-[#966F02] text-[10px]">
                      {article?.data?.green_skill_category?.name}
                    </p>
                  </div>
                  <div className="inline md:hidden absolute top-[86%] w-full">
                    <h1 className="w-full">{article?.data?.title} </h1>
                  </div>
                </div>

                <div className="hidden md:flex items-center gap-2 flex-wrap">
                  {article?.data?.green_skills?.map(
                    (skill: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className="rounded-full px-3 py-1 bg-[#F5F5F5]"
                          style={{ border: "1px solid lightgray" }}
                          onClick={() =>
                            navigate(`/skill/green-skills/${skill?.id}`)
                          }
                        >
                          <p className="m-0 text-xs">{skill?.name}</p>
                        </div>
                      )
                    },
                  )}
                </div>
              </div>

              <div className="hidden md:inline absolute top-[46%] bottom-[40%]">
                <h1 className="">{article?.data?.title} </h1>
              </div>

              <div className="flex items-center gap-x-10 absolute bottom-24  md:bottom-0 -mb-2 flex-wrap md:flex-nowrap">
                <div
                  className="text-right"
                  onClick={(e) => {
                    e.stopPropagation()
                    if (account) {
                      if (article?.data?.is_like) {
                        queryArticleResponse?.handleUnLikeArticle(
                          article?.data?.slug,
                        ) // Call unliking function
                      } else {
                        queryArticleResponse?.handleLikeArticle(
                          article?.data?.slug,
                        ) // Call liking function
                      }
                    } else {
                      openModal()
                    }
                  }}
                >
                  <div
                    className="flex items-center gap-2 bg-[#FFF2E8] py-1 px-4 rounded-md"
                    style={{ border: "1px solid lightgray" }}
                  >
                    <div>
                      {article?.data?.is_like === true ? (
                        <LikeTwoTone twoToneColor={"green"} />
                      ) : (
                        <LikeOutlined />
                      )}
                    </div>
                    <p className="m-0">{article?.data?.likes}</p>
                  </div>
                </div>
                <div className="flex items-center gap-2 text-[#919191]">
                  <p>
                    {dayjs(article?.data?.updated_at).format("D MMMM YYYY")}
                  </p>
                  {/* <Divider type="vertical" /> */}
                  <span className="text-xl">•</span>
                  <p>{article?.data?.read_time} read</p>
                </div>
              </div>

              <div className="flex md:hidden items-center gap-2 flex-wrap absolute bottom-0">
                {article?.data?.green_skills?.map(
                  (skill: any, index: number) => {
                    return (
                      <div
                        key={index}
                        className="rounded-full px-3 py-1 bg-[#F5F5F5]"
                        style={{ border: "1px solid lightgray" }}
                        onClick={() =>
                          navigate(`/skill/green-skills/${skill?.id}`)
                        }
                      >
                        <p className="m-0 text-xs">{skill?.name}</p>
                      </div>
                    )
                  },
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
        <div className="mt-5 space-y-5 col-span-2">
          {article?.isLoading
            ? Array.from({ length: 3 }, (_: any, index: number) => (
                <div
                  key={index}
                  className="bg-white-custom h-64 rounded-xl shadow-custom-sm p-5 space-y-3 w-full"
                >
                  <Skeleton.Input
                    active={true}
                    className="!w-full !rounded-lg"
                    size={"large"}
                  />

                  <Skeleton.Input
                    active={true}
                    className="!w-full !rounded-lg !h-40"
                    size={"large"}
                  />
                </div>
              ))
            : article?.data?.sections?.map((section: any, index: number) => {
                return (
                  <div
                    key={index}
                    className="bg-white p-5 rounded-xl shadow-custom-sm"
                  >
                    <h3 className="text-md font-bold">
                      {section?.section_title}
                    </h3>
                    <div
                      className="mt-2 text-[16px] leading-7 !font-thin"
                      dangerouslySetInnerHTML={{
                        __html: section?.content,
                      }}
                    ></div>
                  </div>
                )
              })}
        </div>

        <div className="col-span-2 md:col-span-1 mt-5">
          <div className="space-y-4">
            <div className="bg-white-custom py-3 px-4 shadow-custom-sm rounded-xl">
              {article?.isLoading ? (
                <Skeleton.Input
                  active={true}
                  className="!w-full !rounded-lg my-1"
                  size={"small"}
                />
              ) : (
                <p className="tracking-widest uppercase text-xs text-yellow-600">
                  Contents
                </p>
              )}

              {article?.isLoading ? (
                <Skeleton className="mt-3" active paragraph={{ rows: 1 }} />
              ) : (
                <ul className="-ml-5">
                  {article?.data?.sections?.map(
                    (section: any, index: number) => {
                      return (
                        <li className="rounded-full" key={index}>
                          {section?.section_title}
                        </li>
                      )
                    },
                  )}
                </ul>
              )}
            </div>
            <div className="bg-white-custom py-6 px-4 shadow-custom-sm rounded-xl">
              {article?.isLoading ? (
                <Skeleton avatar paragraph={{ rows: 4 }} active />
              ) : (
                <>
                  <div className="flex gap-3">
                    <div className="flex items-center justify-center  w-[137px] h-[93px]">
                      {article?.data?.expert_data?.profile_picture ? (
                        <Image
                          src={article?.data?.expert_data?.profile_picture}
                          alt="moon image"
                          preview={false}
                          width={"100%"}
                          className="object-cover "
                        />
                      ) : (
                        <Image
                          src={
                            "https://stgreenskills001.blob.core.windows.net/default-avatars/7.png"
                          }
                          alt="moon image"
                          preview={false}
                          width={"100%"}
                          className="object-cover "
                        />
                      )}
                    </div>
                    <div className="w-full relative h-[93px]">
                      <h3 className="font-semibold -mt-3 absolute top-0">
                        {article?.data?.expert_data?.first_name}{" "}
                        {article?.data?.expert_data?.last_name}
                      </h3>

                      <div className="flex items-center gap-2 absolute top-[36%]">
                        <EnvironmentOutlined className="text-[12px]" />
                        <p className="text-[12px] m-0">
                          {article?.data?.expert_data?.country}
                        </p>
                      </div>

                      <div className="flex items-center justify-between w-full absolute bottom-0">
                        <Tag
                          className={`${
                            article?.data?.expert_data?.is_follow
                              ? "bg-secondaryYellow"
                              : ""
                          } cursor-pointer`}
                          onClick={() => {
                            if (account) {
                              if (article?.data?.expert_data?.is_follow) {
                                expertQuery?.handleUnFollowExpert(
                                  article?.data?.expert_data?.id,
                                )
                              } else {
                                expertQuery?.handleFollowExpert(
                                  article?.data?.expert_data?.id,
                                )
                              }
                            } else {
                              openModal()
                            }
                          }}
                        >
                          {article?.data?.expert_data?.is_follow
                            ? "Unfollow"
                            : "Follow"}{" "}
                          Expert
                        </Tag>

                        <div className="flex items-center gap-3">
                          {SocialMediaLinks(
                            reFormatSosmed(article?.data?.expert_data?.links),
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="mt-2"
                    dangerouslySetInnerHTML={{
                      __html: article?.data?.expert_data?.about_me_expert,
                    }}
                  ></div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailPublicArticleContent
