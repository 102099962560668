import { useState } from "react"

export const useMenuSkill = (
  props: {
    key: string
    label: string
    icon: JSX.Element
  }[],
) => {
  const [active, setActive] = useState<{
    key: string
    label: string
    icon: JSX.Element
  }>(props[0])

  const handleSelectMenu = (val: {
    key: string
    label: string
    icon: JSX.Element
  }) => {
    setActive(val)
  }

  return { active, handleSelectMenu }
}
