/* eslint-disable */

import { useQuery } from "react-query"
import { ApiResponse } from "../../../common/types/ApiResponse"
import { instanceV2 } from "../../../config/config"

type CvLaunchpadData = {
  avatar: string
  first_name: string
  last_name: string
  id: string
  nick_name: string
  profile_picture: string
  profile_strength: number
  total_career_aspiration: number
  total_interested_green_skill: number
  total_proficient_green_skill: number
}
const fetchCvLaunchpad = async (): Promise<ApiResponse<CvLaunchpadData>> => {
  return instanceV2.get("/launchpad/greencv")
}

export const useCvLaunchpad = () => {
  return useQuery(["fetch-cv-launchpad"], () => fetchCvLaunchpad(), {
    refetchOnWindowFocus: false,
  })
}
