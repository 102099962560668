import React from "react"
import { Button, Card, Input } from "antd"
import { Link } from "react-router-dom"
import {
  LinkedinFilled,
  MailOutlined,
  TwitterOutlined,
  LinkOutlined,
} from "@ant-design/icons"
import { Controller } from "react-hook-form"
import { useSocialUpdate } from "../../../hooks/cv-builder/useSocialUpdate"
import { Account } from "../../../hooks/cv-builder/useProfile"

interface Props {
  handleChangeState: (index: number) => void
  data: Account | undefined
}

export const CardUpdateSocial: React.FC<Props> = ({
  handleChangeState,
  data,
}) => {
  const { control, errors, handleSubmit, onSubmit, loadingUpdate } =
    useSocialUpdate(data, handleChangeState)
  return (
    <Card className="shadow w-full rounded-xl relative bg-[#FAFAFA]">
      <form>
        <h3 className="">Contact and Social Media</h3>
        <div className="py-1">
          Control who can see your contact information in{" "}
          <Link className=" text-secondary" to="/account/privacy">
            privacy settings
          </Link>
        </div>
        <div className="flex gap-4 items-center py-1">
          <MailOutlined className=" text-base" />
          <Controller
            name="email"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                {...field}
                status={errors.email ? "error" : ""}
                className="my-1 font-default rounded"
                placeholder="email@gmail.com"
              />
            )}
          />
        </div>
        <div className="flex gap-4 items-center py-1">
          <LinkedinFilled className=" text-base" />
          <Controller
            name="linkedin"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                className="my-1 font-default rounded"
                placeholder="https://linkedin.com/in/profile"
              />
            )}
          />
        </div>
        <div className="flex gap-4 items-center py-1">
          <TwitterOutlined className=" text-base" />
          <Controller
            name="twitter"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                className="my-1 font-default rounded"
                placeholder="https://twitter.com/username"
              />
            )}
          />
        </div>
        <div className="flex gap-4 items-center py-1">
          <LinkOutlined className=" text-base" />
          <Controller
            name="link"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                className="my-1 font-default rounded"
                placeholder="portfolio or personal website"
              />
            )}
          />
        </div>
        <div className="mt-5 flex justify-end gap-5">
          <Button type="primary" ghost onClick={() => handleChangeState(1)}>
            Cancel
          </Button>
          <Button
            disabled={loadingUpdate}
            type="primary"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </div>
      </form>
    </Card>
  )
}
