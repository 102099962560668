import React, { useContext } from "react"
import { AccountContext } from "../layout/PageLayout"
import { Skeleton } from "antd"
import { avatarImgs } from "../../assets/avatar_images"
import clsx from "clsx"

const UserProfilePicture = ({
  onClick,
  className,
  size = 33,
}: {
  className?: string
  onClick?: () => void
  size?: number
}) => {
  const account = useContext(AccountContext)
  const sizeClass = `h-[${size}px] w-[${size}px]`

  return account?.data?.data?.profile_picture || account?.data?.data?.avatar ? (
    <div
      style={{ height: size, width: size }}
      className={clsx("rounded-full", className)}
    >
      <img
        onClick={onClick}
        src={
          account?.data?.data?.profile_picture || account?.data?.data?.avatar
            ? `${
                account?.data?.data?.profile_picture ||
                account?.data?.data?.avatar
              }?${new Date().getTime()}`
            : avatarImgs[0]
        }
        alt="profile"
        className={clsx(
          "rounded-full object-contain w-full h-full cursor-pointer",
          // sizeClass,
        )}
        style={{ border: "1px solid #D4F5C5" }}
        onError={(e: any) => {
          e.target.onerror = null
          e.target.src = account?.data?.data?.avatar ?? avatarImgs[0]
        }}
      />
    </div>
  ) : (
    <Skeleton.Avatar
      active
      className={clsx(sizeClass)}
      style={{ height: size, width: size }}
    />
  )
}

export default UserProfilePicture
