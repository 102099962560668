import { useEffect } from "react"
import {
  useFetchDetail,
  useFetchList,
  useWrite,
} from "../../../../../hooks/generic_rest"
import { MENTOR_ENDPOINTS } from "../apis/endpoint"
import { MentorMenteeSession, MentorScheduleResponse } from "../types/response"
import {
  useMentorScheduleStore,
  useStoreMySchedule,
} from "../stores/storeMentorSchedule"
import { format, parseISO } from "date-fns"
import { message } from "antd"
import {
  getCurrentMonthDateRange,
  getTimezone,
} from "../../../../../utils/getTimeZome"

export const MENTOR_SCHEDULE_QUERY_NAME = "mentor-schedules"
export const MY_BOOKINGS_QUERY_NAME = "my-bookings"
export const MY_BOOKINGS_MANTEE_QUERY_NAME = "my-bookings-mantee"

export const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

export const useListMentorSchedule = (mentorId: string) => {
  const {
    MentorScheduleData,
    setMentorScheduleData,
    setIsMentorScheduleLoading,
    setSelectedDate,
    is24HourFormat,
    setIs24HourFormat,
    currentDate,
    setCurrentDate,
    setSelectedDay,
    selectedTimeSlot,
    setSelectedSchedule,
    setTimezone,
    setSelectedTimeSlot,
    meetingNotes,
    timezone,
    setMeetingNotes,
  } = useMentorScheduleStore()

  const {
    data: mentorData,
    isLoading: mentorLoading,
    refetch: refetchMentorScheduleData,
  } = useFetchDetail<MentorScheduleResponse>(
    `${MENTOR_ENDPOINTS.MENTOR_SCHEDULE(mentorId)}`,
    {
      queryName: MENTOR_SCHEDULE_QUERY_NAME,
      enabled: true,
      queryParams: {
        timezone: getTimezone(),
        date_range: getCurrentMonthDateRange(),
      },
    },
  )

  useEffect(() => {
    refetchMentorScheduleData()
  }, [mentorId])

  useEffect(() => {
    if (mentorData) {
      setMentorScheduleData(mentorData)
      setIsMentorScheduleLoading(mentorLoading)
    }
  }, [mentorData, mentorLoading])

  const handleDateClick = (date: string) => {
    setSelectedDate(date)
    const dayOfWeek = new Date(date).getDay()
    setSelectedDay(dayNames[dayOfWeek])
    setSelectedSchedule(null)
    setSelectedTimeSlot(null)
  }

  useEffect(() => {
    setSelectedDate(format(new Date(), "yyyy-MM-dd"))
    setSelectedDay(dayNames[new Date().getDay()])
  }, [setSelectedDate, setSelectedDay])

  useEffect(() => {
    if (selectedTimeSlot) {
      const timeSlotSelect = parseISO(selectedTimeSlot || "")
      setSelectedSchedule(
        format(timeSlotSelect, "EEEE, MMMM do, yyyy, hh:mm a"),
      )
    }
  }, [selectedTimeSlot, setSelectedSchedule])

  const handleFormatToggle = () => {
    setIs24HourFormat(!is24HourFormat)
  }

  const getDaysInMonth = (year: number, month: number) => {
    return new Date(year, month + 1, 0).getDate()
  }

  const handleMonthChange = (direction: "prev" | "next") => {
    const newDate = new Date(currentDate)
    newDate.setMonth(currentDate.getMonth() + (direction === "prev" ? -1 : 1))
    setCurrentDate(newDate)
  }

  const year = currentDate.getFullYear()
  const month = currentDate.getMonth()
  const daysInMonth = getDaysInMonth(year, month)
  const slotsDates = Object.keys(MentorScheduleData?.slots || {})
  const firstDayOfMonth = new Date(year, month, 1).getDay()

  useEffect(() => {
    const detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    setTimezone(detectedTimezone)
  }, [])

  const { mutate: saveRequest, isLoading: isLoadingSaveRequest } = useWrite<
    any,
    any
  >(
    MENTOR_ENDPOINTS.MENTOR_BOOKING,
    "post",
    () => {
      message.open({
        key: "sending-request-success",
        content: "Request sent successfully",
        type: "success",
      })
      refetchMentorScheduleData()
      setSelectedSchedule(null)
      setSelectedTimeSlot(null)
      setSelectedDate(null)
      setMeetingNotes("")
    },
    () =>
      message.open({
        key: "sending-request-error",
        content: "Something went wrong",
        type: "error",
      }),
  )

  const handleSubmitRequest = () => {
    message.open({
      key: "sending-request-success",
      content: "Sending request...",
      type: "success",
    })
    const dataToSave = {
      booking_notes: meetingNotes,
      booking_time: selectedTimeSlot,
      mentor_id: mentorId,
      timezone: timezone,
    }

    saveRequest(dataToSave)
  }

  return {
    mentorSchedule: MentorScheduleData,
    mentorLoading,
    handleDateClick,
    dayNames,
    handleMonthChange,
    firstDayOfMonth,
    daysInMonth,
    slotsDates,
    year,
    month,
    handleFormatToggle,
    handleSubmitRequest,
    isLoadingSaveRequest,
  }
}

export const useListMyBookings = () => {
  const { bookingData, appendBookingData } = useMentorScheduleStore()
  const {
    cancelBookingId,
    cancelReason,
    setCancelBookingId,
    setCancelReason,

    rescheduleReason,
    setRescheduleReason,
    rescheduleBookingId,
    setRescheduleBookingId,
    timeReschedule,

    mentorId,
  } = useStoreMySchedule()

  const {
    data: myBookingData,
    isLoading: myBookingLoading,
    refetch: refetchMyBookingData,
  } = useFetchList<MentorMenteeSession>(
    `${MENTOR_ENDPOINTS.MY_BOOKING_MENTOR}`,
    {
      queryName: MY_BOOKINGS_QUERY_NAME,
      enabled: true,
      queryParams: {
        limit: 10,
        page: 1,
      },
    },
  )

  const {
    data: myBookingDataAsMentor,
    isLoading: myBookingAsMentorLoading,
    refetch: refetchMyBookingDataAsMentor,
  } = useFetchList<MentorMenteeSession>(
    `${MENTOR_ENDPOINTS.MY_BOOKING_MANTEE}`,
    {
      queryName: MY_BOOKINGS_MANTEE_QUERY_NAME,
      enabled: true,
      queryParams: {
        limit: 10,
        page: 1,
        type: "upcoming",
        timezone: "Asia/Jakarta",
        start_date: "2024-07-01",
        end_date: "2024-07-30",
      },
    },
  )

  useEffect(() => {
    if (myBookingData) {
      appendBookingData(myBookingData?.list)
    }
    if (myBookingDataAsMentor) {
      appendBookingData(myBookingDataAsMentor?.list)
    }
  }, [myBookingData, myBookingDataAsMentor, myBookingAsMentorLoading])

  // CANCEL
  const { mutate: submitCancelBooking, isLoading: loadingCancelBooking } =
    useWrite<any, any>(
      MENTOR_ENDPOINTS.CANCEL_BOOKING(cancelBookingId),
      "post",
      () => {
        message.open({
          key: "cancel-booking-schedule-success",
          content: "Cancel sent successfully",
          type: "success",
        })
        refetchMyBookingData()
        setCancelBookingId("")
        setCancelReason("")
        refetchMyBookingDataAsMentor()
      },
      () =>
        message.open({
          key: "cancel-booking-schedule-success-error",
          content: "Something went wrong",
          type: "error",
        }),
    )

  const handleSubmitCancelBooking = () => {
    message.open({
      key: "cancel-booking-schedule-success",
      content: "Canceling booking..",
      type: "success",
    })
    const dataToSave = {
      reason: cancelReason,
    }
    submitCancelBooking(dataToSave)
  }

  // RESCHEDULE
  const {
    mutate: submitRescheduleBooking,
    isLoading: loadingRescheduleBooking,
  } = useWrite<any, any>(
    MENTOR_ENDPOINTS.RESCHEDULE_BOOKING(rescheduleBookingId),
    "post",
    () => {
      message.open({
        key: "reschedule-booking-schedule-success",
        content: "Reschedule sent successfully",
        type: "success",
      })
      refetchMyBookingData()
      setRescheduleBookingId("")
      setRescheduleReason("")
    },
    () =>
      message.open({
        key: "reschedule-booking-schedule-success-error",
        content: "Something went wrong",
        type: "error",
      }),
  )

  const handleSubmitRescheduleBooking = () => {
    message.open({
      key: "reschedule-booking-schedule-success",
      content: "Rescheduleing booking..",
      type: "success",
    })
    const dataToSave = {
      reason: rescheduleReason,
      time: timeReschedule,
      mentor_id: mentorId,
    }
    console.log("dataToSave", dataToSave)
    submitRescheduleBooking(dataToSave)
  }

  return {
    bookingData,
    handleSubmitCancelBooking,
    loadingCancelBooking,

    handleSubmitRescheduleBooking,
    loadingRescheduleBooking,
  }
}
