/* eslint-disable */

import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Account } from "./useProfile"
import { useMutation, useQueryClient } from "react-query"
import instance, { instanceV2 } from "../../../../config/config"
import { message } from "antd"

export const useBasicUpdate = (
  data: Account | undefined,
  callback: (index: number) => void,
) => {
  const {
    handleSubmit,
    control,
    watch,
    // reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: data?.first_name,
      last_name: data?.last_name,
      profile_headline: data?.profile_headline,
      nick_name: data?.nick_name,
      country: data?.country,
      about_me: data?.about_me,
      about_me_expert: data?.about_me_expert,
      address: data?.address,
      location_detailed: data?.location_detailed,
    },
  })

  const queryClient = useQueryClient()

  const [imageUrl, setImageUrl] = useState<string>("")
  const [imageFile, setImageFile] = useState<string | Blob>("")

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event
    if (target.files && target.files.length > 0) {
      const file = target.files[0]
      const imageUrl = URL.createObjectURL(file)
      setImageFile(file)
      message.open({
        key: "update",
        content: "uploading...",
        type: "loading",
      })
      const handleUpload = () => {
        const formData = new FormData()
        formData.append("file", file)
        uploadImage(formData, {
          onSuccess: (res) => {
            if (res?.data?.data) {
              setImageUrl(`${res?.data?.data}?${new Date().getTime()}`)
            } else {
              setImageUrl("")
            }
            message.destroy()
          },
          onError: (err: any) => {
            message.open({
              key: "update",
              content: err?.response?.data?.data || "something wrong",
              type: "error",
            })
          },
        })
      }
      handleUpload()
    }
  }

  const handleRemoveImage = () => {
    setImageFile("")
    message.open({ key: "remove", content: "removing...", type: "loading" })
    removeImage("run", {
      onSuccess: (res) => {
        setImageUrl(res.data.data)
        message.destroy()
      },
      onError: (err: any) => {
        message.open({
          key: "remove",
          content: err?.response?.data?.data || "something wrong",
          type: "error",
        })
      },
    })
  }

  const { mutate: uploadImage, isLoading: loadingUpload } = useMutation({
    mutationFn: (val: FormData) => {
      return instance.post(`/accounts/upload`, val)
    },
    mutationKey: "updload-image",
  })

  const { mutate: removeImage, isLoading: loadingRemove } = useMutation({
    mutationFn: (val: any) => {
      return instanceV2.delete(`/accounts/image`)
    },
    mutationKey: "delete-image",
  })

  const { mutateAsync: info, isLoading: loadingUpdate } = useMutation({
    mutationFn: async (val) => {
      try {
        const res = await instanceV2.post(`/accounts/basic`, val)
        return res
      } catch (error) {
        throw error
      }
    },
    mutationKey: "account-info",
  })

  const onSubmit = async (val: any) => {
    message.open({
      key: "update-info",
      content: "updating...",
      type: "loading",
    })
    try {
      const response = await info(val)
      if (response) {
        queryClient.invalidateQueries(["account-cv"]).then(() => {
          callback(0)
          message.destroy()
        })
      }
    } catch (error: any) {
      message.open({
        key: "update-info",
        content: error?.response?.data?.data || "Internal server error",
        type: "error",
      })
    }
  }

  useEffect(() => {
    if (data?.profile_picture || data?.avatar) {
      const origin = "https://media.licdn.com/"

      if (!data?.profile_picture?.startsWith(origin)) {
        setImageUrl(
          `${data?.profile_picture || data?.avatar}?${new Date().getTime()}`,
        )
      } else {
        setImageUrl(data?.profile_picture || data?.avatar)
      }
    }

    if (data) {
      setValue("first_name", data?.first_name)
      setValue("last_name", data?.last_name)
      setValue("profile_headline", data?.profile_headline)
      setValue("nick_name", data?.nick_name)
      setValue("country", data?.country)
      setValue("location_detailed", data?.location_detailed)
      setValue("address", data?.address)
      setValue("about_me", data?.about_me)
      setValue("about_me_expert", data?.about_me_expert)
    }
  }, [data])

  return {
    control,
    watch,
    errors,
    handleSubmit,
    onSubmit,
    handleImageChange,
    imageUrl,
    handleRemoveImage,
    imageFile,
    loadingImage: loadingUpload || loadingRemove,
    loadingUpdate,
    setValue,
  }
}
