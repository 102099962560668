import React from "react"
import { PageContent } from "../../../../common/layout"
import { HomeOutlined } from "@ant-design/icons"
import {
  MyJobContentRightSection,
  MyJobContentSection,
  MyJobContentTopSection,
} from "../../components/myJob/MyJobSection"
import useRecomendationsJobs from "../../hooks/useRecomendationJobs"
import { useMsal } from "@azure/msal-react"
import useHistoryJobs from "../../hooks/useHistoryJobs"
import useSavedJobs from "../../hooks/useSavedJobs"
import { usePrivacySetting } from "../../../account/hooks/usePrivacySetting"
import useRecruiterRequest from "../../hooks/useRecruiterRequest"

const MyJobs: React.FC = () => {
  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()

  const { listJobRecomendation, isListJobRecomendationLoading } =
    useRecomendationsJobs(activeAccount?.localAccountId)

  const { statusPrivate, changeStatus } = usePrivacySetting()

  const { listJobHistory } = useHistoryJobs(activeAccount?.localAccountId)
  const { listJobSaved } = useSavedJobs(activeAccount?.localAccountId)
  const { listRequest, isLoadingRequest } = useRecruiterRequest(
    activeAccount?.localAccountId,
  )

  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      path: "/job/roles",
      title: "Jobs Galaxy",
    },
    {
      path: "/job/my",
      title: "My Jobs",
    },
  ]

  return (
    <div>
      <PageContent
        header={<MyJobContentTopSection breadcrumbsItems={items} />}
        content={
          <MyJobContentSection
            listJobRecomendation={listJobRecomendation}
            isListJobRecomendationLoading={isListJobRecomendationLoading}
            userId={activeAccount?.localAccountId}
            statusPrivate={statusPrivate}
          />
        }
        contentRightSidebar={
          <MyJobContentRightSection
            listJobHistory={listJobHistory}
            listJobSaved={listJobSaved}
            statusPrivate={statusPrivate}
            changeStatus={changeStatus}
            listRequest={listRequest}
            isLoadingRequest={isLoadingRequest}
          />
        }
      />
    </div>
  )
}

export default MyJobs
