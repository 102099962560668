import React from "react"
import { UnauthenticatedTemplate } from "@azure/msal-react"
import { useNavigate } from "react-router-dom"
import { Image } from "antd"
import { GreenSkillsLogo } from "../../../assets/images"

const HeaderLogo = () => {
  const navigate = useNavigate()
  return (
    <div
      className="flex items-center gap-3 cursor-pointer"
      onClick={() => navigate("/")}
    >
      <UnauthenticatedTemplate>
        <Image
          className=" h-full"
          width={115}
          preview={false}
          src={GreenSkillsLogo}
          alt="logo"
        />
      </UnauthenticatedTemplate>
    </div>
  )
}

export default HeaderLogo
