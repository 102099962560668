import React from "react"
import { PageContent } from "../../../common/layout"
import { HeaderCart } from "../components/HeaderCart"
import { CartList } from "../components/CartList"
import { CartSummary } from "../components/CartSummary"
import { useCart } from "../hook"
import { Divider, Modal } from "antd"
import { translatelevelName } from "../helper"

const Cart = () => {
  const {
    checkout,
    handleChange,
    handleRemoveOrder,
    loadingRemoveOrder,
    handleProceed,
    loadingProceed,
    modal,
  } = useCart()
  return (
    <PageContent
      header={<HeaderCart />}
      contentRightSidebar={
        <CartSummary
          checkout={checkout}
          handleRemove={handleRemoveOrder}
          isLoadingRemove={loadingRemoveOrder}
          handleProceed={handleProceed}
          isLoadingProceed={loadingProceed}
        />
      }
      content={
        <>
          <div className="col-span-full md:col-span-2">
            <CartList handleChange={handleChange} />
          </div>

          <Modal
            title="We found duplicates"
            open={modal.isOpenModal}
            closable={false}
            centered
            cancelButtonProps={{ style: { display: "none" } }}
            okText={<div>Pay for the rest of your order</div>}
            onOk={() =>
              handleProceed(
                checkout.filter((v) =>
                  modal.duplicate.some((d) => v.product_id !== d.product_id),
                ),
              )
            }
          >
            <div className="mt-5 font-[500]">
              You already bought these courses, so we removed them from your
              cart
            </div>
            <Divider className="my-1" />
            {modal.duplicate?.map((v, i) => (
              <div key={i} className="flex justify-between items-center">
                <div className="p-3 flex gap-3 items-center">
                  <div className=" bg-[#FFEFA0] p-2 rounded-full w-7 h-7 flex">
                    <img src={v.image_url} alt="level" />
                  </div>
                  <p className=" w-56 truncate">{v.product_name}</p>
                </div>
                <div className="p-3 w-48">
                  {translatelevelName(v.proficient)}
                </div>
              </div>
            ))}
          </Modal>
        </>
      }
    />
  )
}

export default Cart
