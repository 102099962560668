import { Modal } from "antd"
import { useContext } from "react"
import { BasicUserInfo } from "../../../../common/components"
import { AccountContext } from "../../../../common/layout/PageLayout"
import { avatarImgs } from "../../../../assets/avatar_images"
import { CommunityModalType, useNewPostStore } from "../../store/newPostStore"
import Default from "./new-post-forms/Default"
import PollForm from "./new-post-forms/PollForm"
import HowTo from "./new-post-forms/HowTo"
import MultipleChoice from "./new-post-forms/MultipleChoice"
import Event from "./new-post-forms/Event"
import Occasion from "./new-post-forms/Occasion"
import Media from "./new-post-forms/Media"

const getTypeForm = (type: CommunityModalType | null) => {
  switch (type) {
    case "poll":
      return <PollForm />
    case "how-tos":
      return <HowTo />
    case "multiple-choice":
      return <MultipleChoice />
    case "event":
      return <Event />
    case "occasion":
      return <Occasion />
    case "media":
      return <Media />
    case "default":
    default:
      return <Default />
  }
}

const NewCommunityPostModal = () => {
  const account = useContext(AccountContext)
  const { isOpen, closeModal, type } = useNewPostStore()

  return (
    <Modal
      open={isOpen}
      onCancel={closeModal}
      footer={null}
      classNames={{
        body: "!rounded-xl",
        content: "!rounded-xl",
      }}
      width={720}
    >
      <div className="">
        <BasicUserInfo
          name={`${account?.data?.data?.first_name} ${account?.data?.data?.last_name}`}
          nameProps={{ className: "!text-xl " }}
          avatarProps={{ size: 56 }}
          avatar={
            account?.data?.data?.profile_picture ??
            account?.data?.data?.avatar ??
            avatarImgs[0]
          }
          location={account?.data?.data?.country}
        />
      </div>
      {getTypeForm(type)}
    </Modal>
  )
}

export default NewCommunityPostModal
