import React from "react"
import { DetailFit } from "./DetailFit"
import { DetailCareerAspiration } from "./DetailCareerAspiration"

export const DetailFloat: React.FC<{ id: string }> = ({ id }) => {
  return (
    <div className=" w-[calc(400px-2.5rem)] absolute min-h-[400px] top-[3.75rem] right-[1.25rem]">
      <DetailFit id={id} />
      <div className="mt-5">
        <DetailCareerAspiration />
      </div>
    </div>
  )
}
