/* eslint-disable */

import { LockOutlined } from "@ant-design/icons"
import { Image } from "antd"
import { Orbit as OrbitType } from "../hooks/useAchievements"

export default function Orbit({ orbit }: { orbit: OrbitType }) {
  return (
    <div
      className={
        orbit?.is_earned
          ? "relative flex flex-col border-2 bg-white border-stone-300 min-w-[169px] items-center justify-center p-3"
          : "inset-0 bg-opacity-20 opacity-20 relative flex flex-col border-2 min-w-[169px] items-center justify-center p-3"
      }
      style={{ border: "2px solid #D1D5DB" }}
    >
      <div className="font-semibold text-lg text-backdrop capitalize">
        {orbit?.title}
      </div>
      <div className="text-sm text-backdrop capitalize">{orbit?.subtitle}</div>
      <Image
        preview={false}
        className="object-contain"
        src={orbit?.image_url}
        height={91}
        width={96}
      />
      <div className="font-semibold text-sm mt-4 text-backdrop">
        {orbit?.progress}
      </div>
      {!orbit?.is_earned && (
        // <Tooltip title={orbit?.title}>
        <div className="absolute inset-0 bg-white bg-opacity-50 flex items-center justify-center cursor-pointer ">
          <LockOutlined className="text-black text-5xl hover:scale-105" />
        </div>
        // </Tooltip>
      )}
    </div>
  )
}
