import React, { useContext } from "react"
import { Button, Drawer, Image, Skeleton, Typography } from "antd"
import { Breadcrumbs } from "../../../../common/components"
import { useNavigate } from "react-router-dom"
import { useLaunchpad } from "../../../missionControl/hooks/useLaunchpad"
import { CVDetailLauchpad } from "../../../missionControl/components/CVDetailLauchpad"
import { useProfile } from "../../../missionControl/hooks/useProfile"
import FastJobTable from "../../../../common/components/FastJobTable"
import { CVMyJob } from "./CVMyJob"
import JobRecommendedComponent from "../../../missionControl/components/JobRecommendedComponent"
import { Orbiting } from "./Orbiting"
import { RecommendedSetting } from "./RecommendedSetting"
import {
  ITourContext,
  TourContext,
} from "../../../../common/components/tour/TourProvider"
import { Moon, SavedJob } from "../../../../assets/images"

const { Title } = Typography

interface MyJobContentTopSectionProps {
  breadcrumbsItems: any[] // Update with the actual type of breadcrumbsItems
}

interface MyJobContentSectionProps {
  listJobRecomendation: any // Update with the actual type of data
  isListJobRecomendationLoading: boolean
  userId?: string
  statusPrivate: any // Update with the actual type of statusPrivate
}

export const MyJobContentTopSection: React.FC<MyJobContentTopSectionProps> = ({
  breadcrumbsItems,
}) => {
  return (
    <div>
      <div>
        <div className="px-8 py-4 bg-white">
          <Breadcrumbs items={breadcrumbsItems} />

          <div className="mt-2 text-backdrop">
            <Title>My Jobs</Title>
          </div>
        </div>
      </div>
    </div>
  )
}

export const MyJobContentSection: React.FC<MyJobContentSectionProps> = ({
  listJobRecomendation,
  isListJobRecomendationLoading,
  userId,
  statusPrivate,
}) => {
  const navigate = useNavigate()

  const {
    refs: { refFastTrackJob },
  } = useContext(TourContext) as ITourContext

  return (
    <>
      {statusPrivate?.discover ? (
        <div className="mb-4" ref={refFastTrackJob}>
          <FastJobTable userId={userId} from={"jobBoard"} />
        </div>
      ) : null}

      {isListJobRecomendationLoading ? (
        <div className="p-4 pb-5 mb-4 rounded-lg shadow-custom">
          <div className={`bg-white p-4 rounded-xl `}>
            <Skeleton active={true} />
          </div>{" "}
        </div>
      ) : !statusPrivate?.discover ? (
        <div className={`!shadow-lg rounded-xl`}>
          <div className="px-4 py-3 pt-8 bg-backdrop rounded-xl">
            <div className={`flex items-center gap-4 justify-between`}>
              <div className={`flex items-center gap-4`}>
                <Image
                  src={SavedJob}
                  alt="icon-save-job"
                  width={40}
                  preview={false}
                  className={`-mt-2`}
                />
                <div className={``}>
                  <Title className={`!text-white`} level={3}>
                    Fast Track Job Application
                  </Title>
                </div>
              </div>
            </div>
            <div className=" flex justify-center items-center my-5 gap-5 flex-col">
              <Image src={Moon} width={80} preview={false} />
              <p className={`p-0 m-0 text-white`}>
                Make you Green CV discoverable to Companies on Skilledin Green
                platform!{" "}
              </p>
            </div>
          </div>
        </div>
      ) : listJobRecomendation === null ? null : (
        <div className="pb-5 mb-4 rounded-lg shadow-custom">
          <div className="flex items-center justify-between w-full px-5 py-4">
            <h4 className="">Recommended Jobs</h4>
            <Button
              onClick={() => navigate("/job/roles")}
              className="bg-white border-primary"
            >
              Browse other jobs
            </Button>
          </div>
          <JobRecommendedComponent
            listJobRecomendation={listJobRecomendation}
          />
        </div>
      )}
    </>
  )
}

interface MyJobContentRightSectionProps {
  listJobHistory: any // Update with the actual type of listJobHistory
  listJobSaved: any // Update with the actual type of listJobSaved
  statusPrivate: any // Update with the actual type of statusPrivate
  changeStatus: (key: string, value: boolean) => void
  listRequest: any // Update with the actual type of listRequest
  isLoadingRequest: boolean
}

export const MyJobContentRightSection: React.FC<
  MyJobContentRightSectionProps
> = ({
  listJobHistory,
  listJobSaved,
  statusPrivate,
  changeStatus,
  listRequest,
}) => {
  const navigate = useNavigate()
  const { isDrawerOpen, setIsDrawerOpen } = useLaunchpad()

  const { account } = useProfile()

  const handleNavigateToRequest = () => {
    if (statusPrivate?.discover) {
      navigate("request")
    }
  }

  const filteredArray = listRequest?.data?.list?.filter(
    (obj: any) => obj?.request_status === "pending",
  )
  const countPendingRequest = filteredArray?.length

  return (
    <div className="flex flex-col gap-7">
      <div>
        <Orbiting
          countPendingRequest={countPendingRequest}
          handleNavigateToRequest={handleNavigateToRequest}
          listJobHistory={listJobHistory}
          listJobSaved={listJobSaved}
          statusPrivate={statusPrivate}
        />
      </div>
      <div>
        <CVMyJob statusPrivate={statusPrivate} changeStatus={changeStatus} />
      </div>
      <div>
        <RecommendedSetting account={account} />
      </div>

      <Drawer
        placement="right"
        closable={false}
        onClose={() => setIsDrawerOpen(false)}
        open={isDrawerOpen}
        className=" bg-slate-600"
        bodyStyle={{ backgroundColor: "#E2EBFA" }}
        width={"70%"}
      >
        <CVDetailLauchpad />
      </Drawer>
    </div>
  )
}
