import React from "react"
import { CardBasicInfo } from "./CardBasicInfo"
import { Account } from "../../hooks/cv-builder/useProfile"
import { CardJobHistory } from "./CardJobHistory"
import { CardEducation } from "./CardEducation"
import { CardGreenSkill } from "./CardGreenSkill"
import { CardHardSkill } from "./CardHardSkill"
import { CardLanguage } from "./CardLanguage"
import { CardCertification } from "./CardCertification"
import { CardSoftSkill } from "./CardSoftSkill"

interface Props {
  data: Account | undefined
  isAnonymised?: boolean
}

export const Content: React.FC<Props> = ({ data, isAnonymised }) => {
  return (
    <div className="px-2">
      <CardBasicInfo data={data} />
      <div className="flex md:flex-nowrap flex-wrap gap-5 my-5">
        <div className="md:w-2/5 flex-grow md:flex-grow-0">
          <div className="flex items-center justify-between mb-5 px-6 shadow-md rounded-md bg-secondaryYellow ">
            <h3 className=" capitalize">skills & competencies</h3>
          </div>
          <div className="my-5">
            <CardGreenSkill data={data} isAnonymised={isAnonymised} />
          </div>
          <div className="my-5">
            <CardHardSkill data={data} />
          </div>
          {checkSoftSkill(data) && (
            <div className="my-5">
              <CardSoftSkill data={data} />
            </div>
          )}
          {data?.certification && (
            <div className="my-5">
              <CardCertification data={data} />
            </div>
          )}
          {data?.languages && (
            <div className="my-5">
              <CardLanguage data={data} />
            </div>
          )}
        </div>
        <div className="md:w-3/5 flex-grow md:flex-grow-0">
          {/* <div className="my-3"> */}
          <CardJobHistory data={data} />
          {/* </div> */}
          <div className="my-3">
            <CardEducation data={data} />
          </div>
        </div>
      </div>
    </div>
  )
}

const checkSoftSkill = (data: Account | undefined): boolean => {
  const temp = data?.soft_skill?.find((item) => item?.proficiency > 0)
  return !!temp
}
