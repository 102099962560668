import React, { useContext } from "react"
import { Button } from "antd"
import { RoadMapTable } from "../components/RoadMapTable"
import FavoriteSkills from "../components/FavoriteSkills"
import { HomeOutlined } from "@ant-design/icons"
import { PageHeader, PageContent } from "../../../common/layout"
import FiveCircleRate from "../../../common/components/FiveCircleRate"
import { BasicContainerWithTitle } from "../../../common/components/BasicBoxContainers/BasicContainerWithTitle"
import { useNavigate } from "react-router-dom"
import { SecondThirdSkill } from "../components/launchpad/SecondThirdSkill"
import useRoadmapSkills, { GetRoadmap } from "../hooks/useRoadmapSkills"
import { getInfoOnCurrentLevelOfSkillForUser } from "../components/launchpad/util_funcs"
import { isNotNullOrUndefined } from "../../../utils/util_funcs"
import { ApiResponse } from "../../../common/types/ApiResponse"
import {
  ITourContext,
  TourContext,
} from "../../../common/components/tour/TourProvider"

export default function Roadmap() {
  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "Mission Control",
    },
    {
      title: "Skills Trajectory Plan",
    },
    {
      path: "/dashboard/skill-trajectory-plan/skill-roadmap",
      title: "Skill Roadmap",
    },
  ]

  const { roadmapSkills, isFetchingRoadmap, updateRoadmapSkillOrder } =
    useRoadmapSkills()

  const {
    refs: { refRoadmapCurrentTrajectory, refFavSkills },
  } = useContext(TourContext) as ITourContext

  const skills = React.useMemo(() => {
    return new Array(3).fill(0).map((_, index) => {
      const skill = roadmapSkills?.data?.data?.[index]
      const lastLevel = roadmapSkills?.data?.data?.[index]?.levels
      const { passedCount } = getInfoOnCurrentLevelOfSkillForUser(
        lastLevel ?? [],
      )
      return (
        <SecondThirdSkill
          key={skill?.id}
          className="!max-w-[100%] mt-[25px]"
          isLoading={isFetchingRoadmap}
          isUndefined={skill === undefined}
          skillName={skill?.name}
          skillImage={skill?.image}
          skillId={skill?.id}
          indexToLearn={lastLevel?.length ? lastLevel?.length - 1 : 1}
          defaultValue={
            skill?.is_request_verified
              ? skill?.total_completed_level
              : passedCount
          }
          isVerified={skill?.is_request_verified}
          secondValue={
            isNotNullOrUndefined(
              lastLevel?.[passedCount]?.user_track
                ? lastLevel?.[passedCount]?.user_track?.current_module
                : lastLevel?.[passedCount]?.user_track?.current_module,
            ) &&
            // @ts-ignore
            (lastLevel?.[passedCount]?.user_track
              ? lastLevel?.[passedCount]?.user_track?.current_module
              : lastLevel?.[passedCount]?.user_track?.current_module) > 0
          }
        />
      )
    })
  }, [roadmapSkills, isFetchingRoadmap])

  return (
    <PageContent
      content={
        <Content
          roadmapSkills={roadmapSkills}
          isFetchingRoadmap={isFetchingRoadmap}
          updateRoadmapSkillOrder={updateRoadmapSkillOrder}
        />
      }
      header={
        <PageHeader
          breadcrumbItems={items}
          title={"Green Skills Roadmap"}
          subtitle={"Launch your career to a new trajectory"}
          isHeaderForAccountInfo
        />
      }
      contentRightSidebar={
        <BasicContainerWithTitle
          ref={refRoadmapCurrentTrajectory}
          type="small"
          title="Current Trajectory"
        >
          <div className="mt-4 ">{skills}</div>
        </BasicContainerWithTitle>
      }
      extraContentBottom={
        <BasicContainerWithTitle
          // ref={refFavSkills}
          titleRef={refFavSkills}
          title="Interested in Learning"
        >
          <FavoriteSkills />
        </BasicContainerWithTitle>
      }
    />
  )
}

interface ContentProps {
  roadmapSkills: ApiResponse<GetRoadmap[]> | undefined
  isFetchingRoadmap: boolean
  updateRoadmapSkillOrder: (newOrder: string[]) => void
}
const Content: React.FC<ContentProps> = ({
  roadmapSkills,
  isFetchingRoadmap,
  updateRoadmapSkillOrder,
}) => {
  const navigate = useNavigate()
  const {
    refs: { refSkillRoadmap },
  } = useContext(TourContext) as ITourContext

  return (
    <>
      <BasicContainerWithTitle
        type="small"
        titleRef={refSkillRoadmap}
        title="Skill Roadmap"
        className="!flex-grow"
        extraTitle={
          <Button
            onClick={() =>
              navigate(
                "/skill/green-skills?search=&price=low-to-high&availability=start-today&sort_rank=&viewType=cards&page=1&limit=20",
              )
            }
            className="text-white bg-primary"
          >
            Browse Green Skills
          </Button>
        }
      >
        <div className="my-2 ">
          <div className="mt-5 text-backdrop">
            Courses for the skill levels you bought appear in this Roadmap.
            Focus on learning 3 skills at a time. Simply put the skills in order
            you'd like to learn them, and we'll be adding top 3 to your Current
            Skill Trajectory.
          </div>
          <div className="mt-5 text-backdrop">
            Missing purchased courses? Contact{" "}
            <a
              href="mailto: accounts@skilledin.io"
              className="font-semibold text-secondary hover:font-bold hover:text-secondary"
            >
              support.
            </a>
          </div>
          <div className="flex items-center gap-6 mt-5">
            <div className="flex items-center gap-4 jusitfy-start">
              <FiveCircleRate count={1} disabled circleSize={15} />
              <div className="text-backdrop">Purchased by you</div>
            </div>
            <div className="flex items-center gap-4 jusitfy-start">
              <FiveCircleRate
                defaultValue={1}
                count={1}
                circleSize={15}
                disabled
              />
              <div className="text-backdrop">Skill level passed</div>
            </div>
          </div>
          <RoadMapTable
            roadmapSkills={roadmapSkills}
            isFetchingRoadmap={isFetchingRoadmap}
            updateRoadmapSkillOrder={updateRoadmapSkillOrder}
          />
        </div>
      </BasicContainerWithTitle>
    </>
  )
}
