import { useMutation, useQueryClient } from "react-query"
import instance from "../../../config/config"
import { Modal, message } from "antd"
import { useContext } from "react"
import { AccountContext } from "../../../common/layout/PageLayout"

export const useCart = () => {
  const account = useContext(AccountContext)
  const queryClient = useQueryClient()

  const { mutate: add, isLoading } = useMutation({
    mutationFn: (val) => {
      return instance.post(`transactions/carts`, val)
    },
    mutationKey: "add-cart",
  })
  const handleAdd = (
    products = [],
    data,
    nameSkill,
    price,
    allProducts = [],
  ) => {
    if (products.length === 0) return message.error("Please select level")

    const handleProcess = (products) => {
      message.open({
        key: "adding-to-cart",
        content: "adding to cart...",
        type: "loading",
      })
      const finalData = products?.map((item) => ({
        user_id: account?.data?.data?.id,
        product_owner_id: data?.id,
        product_owner_id_stripe: data?.stripe_account_id,
        product_id: item.id,
        product_name: `${nameSkill} Level ${item?.proficiency}`,
        product_id_stripe: item?.stripe?.product_id,
        price_id_stripe: item?.stripe?.price_id,
        price: price,
        sharing_profit: data?.sharing_profit,
        image_url: item?.image_url,
        proficient: item?.proficiency,
      }))
      add(finalData, {
        onSuccess: async () => {
          await queryClient.invalidateQueries(["fetch-green-skill-levels"])
          await queryClient.invalidateQueries(["carts"])
          message.open({
            key: "adding-to-cart",
            content: "success add to cart",
            type: "success",
          })
        },
        onError: (err) => {
          message.open({
            key: "adding-to-cart",
            content: err?.response?.data?.data,
            type: "success",
          })
        },
      })
    }
    const result = allProducts.filter((a) => {
      if (a.status_transaction === 0) {
        return !products.some((b) => b.proficiency <= a.proficiency)
      } else {
        return false
      }
    })
    if (result.length > 0) {
      Modal.confirm({
        centered: true,
        icon: null,
        className: "square-modal",
        content: (
          <div className="mb-5">
            <div>
              You won't be able to access this level course, unless you pass all
              previous levels of the skill. Add previous levels to cart to
              proceed.
            </div>
          </div>
        ),
        cancelText: "Cancel",
        cancelButtonProps: {
          className: " rounded-none text-darkGrey",
          danger: true,
        },
        okText: "Add previous levels",
        okButtonProps: {
          className: "rounded-none bg-primary hover:bg-[#85bd6c]  text-white",
          type: "ghost",
        },
        onOk: () => {
          products = [...result, ...products]
          handleProcess(products)
        },
      })
    } else {
      handleProcess(products)
    }
  }

  return { handleAdd, isLoading }
}
