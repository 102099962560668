import { Tag } from "antd"
import React from "react"

interface BarSectionProps {}

const BarSection: React.FC<BarSectionProps> = () => {
  return (
    <div className="bg-backdrop py-2">
      <div className="m-auto w-[87%] flex flex-wrap sm:flex-nowrap items-center justify-between">
        <div>
          <p className="text-white text-[20px] leading-7">
            Build your Sustainability Career with Skilledin Green
          </p>
        </div>
        <div className="flex items-center gap-y-3 gap-x-1 md:gap-x-6 mb-4 md:mb-0 flex-wrap md:flex-nowrap ">
          <a href="#upskill">
            <Tag color="white" className="rounded-full !text-backdrop px-3">
              UpSkill
            </Tag>
          </a>
          <a href="#skill">
            <Tag color="white" className="rounded-full !text-backdrop px-3">
              Green Skills
            </Tag>
          </a>
          <a href="#cv">
            <Tag color="white" className="rounded-full !text-backdrop px-3">
              Build Green CV
            </Tag>
          </a>
          <a href="#hiring">
            <Tag color="white" className="rounded-full !text-backdrop px-3">
              Get Hired
            </Tag>
          </a>
          <a href="#expert">
            <Tag color="white" className="rounded-full !text-backdrop px-3">
              Expert Community
            </Tag>
          </a>
          <a href="#perk">
            <Tag color="white" className="rounded-full !text-backdrop px-3">
              Perks Pack
            </Tag>
          </a>
        </div>
      </div>
    </div>
  )
}

export default BarSection
