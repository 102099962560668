import React, { useState } from "react"
import { Avatar, Image, Skeleton, Space, Tag } from "antd"
import { useModalStore } from "../../../../stores"
import { SocialMediaLinks } from "../../../jobGalaxy/components/SocialMediaLink"
import { HeartOutlined } from "@ant-design/icons"
import { InfoCircleOutlined } from "@ant-design/icons"

import { useProfile } from "../../../missionControl/hooks/cv-builder/useProfile"
import DrawerCV from "./DrawerCV"
import DetailByRole from "./DetailByRole"
import AvatarCustom from "../../../../common/components/AvatarCustom"

interface DetailPublicExpertProfileContentContentProps {
  // handler: any
  data: any
  cv: any
  id: string
  tab: string
  username: string
  detailPreview: any
}

const DetailPublicExpertProfileContentContent: React.FC<
  DetailPublicExpertProfileContentContentProps
> = ({ data, cv, detailPreview }) => {
  const openModal = useModalStore((state) => state.openModal)
  const reFormatSosmed = (sosmed: any) => {
    const data = {
      // mail_url: sosmed?.email,
      website_url: sosmed?.link,
      linkedin_url: sosmed?.linkedin,
      twitter_url: sosmed?.twitter,
    }

    return data
  }

  function convertToPlainText(htmlString: string): string {
    console.log("htmlString", htmlString)
    const div = document.createElement("div")
    div.innerHTML = htmlString
    const plainText = div.textContent || div.innerText || ""
    return plainText.trim() === "" ? "-" : plainText.trim()
  }

  const { account } = useProfile()

  const [open, setOpen] = useState(false)

  const onClose = () => {
    setOpen(false)
  }

  const onOpen = () => {
    setOpen(true)
  }

  return detailPreview?.detailLoadingExpert ? (
    <div className="bg-white p-4 rounded-lg">
      <Skeleton active />
    </div>
  ) : (
    <div>
      <div className="grid grid-cols-1 p-4 bg-white rounded-lg md:grid-cols-12 gap-7">
        <div className="col-span-8 px-5 rounded-lg shadow-custom bg-white-custom py-7">
          {data?.listLoading ? (
            <Skeleton active paragraph={{ rows: 4 }} />
          ) : (
            <div>
              <h3 className="font-semibold">
                {detailPreview?.detailExpert?.first_name}{" "}
                {detailPreview?.detailExpert?.last_name}
              </h3>
              <div className="relative flex flex-wrap mt-5 gap-x-4 gap-y-2 md:flex-nowrap">
                <div className="w-[140px] h-[123px]">
                  <AvatarCustom
                    url={detailPreview?.detailExpert?.profile_picture}
                    display_name={
                      detailPreview?.detailExpert?.first_name +
                      " " +
                      detailPreview?.detailExpert?.last_name
                    }
                  />
                </div>
                <div className="h-[102px] relative w-full">
                  <p className="absolute top-0 md:m-0 line-clamp-3">
                    {detailPreview?.detailExpert?.is_expert
                      ? convertToPlainText(
                          detailPreview?.detailExpert?.about_me_expert,
                        )
                      : convertToPlainText(
                          detailPreview?.detailExpert?.about_me,
                        )}
                  </p>

                  <div className="absolute bottom-0 flex flex-wrap items-center justify-between w-full">
                    {detailPreview?.detailExpert?.is_expert && (
                      <Tag
                        className={`flex items-center ${
                          detailPreview?.detailExpert?.is_follow
                            ? "bg-secondaryYellow"
                            : "bg-[#FFF2E8]"
                        } cursor-pointer`}
                        onClick={() => {
                          if (account) {
                            if (detailPreview?.detailExpert?.is_follow) {
                              detailPreview?.handleUnFollowExpert(
                                detailPreview?.detailExpert?.user_id,
                              )
                            } else {
                              detailPreview?.handleFollowExpert(
                                detailPreview?.detailExpert?.user_id,
                              )
                            }
                          } else {
                            openModal()
                          }
                        }}
                      >
                        <HeartOutlined className="mr-2" />
                        {detailPreview?.detailExpert?.is_follow
                          ? "Unfollow"
                          : "Follow"}{" "}
                        Expert
                      </Tag>
                    )}

                    {detailPreview?.detailExpert?.is_expert && <div></div>}

                    <div className="flex items-center gap-3">
                      {SocialMediaLinks(
                        reFormatSosmed(detailPreview?.detailExpert),
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="relative w-full col-span-8 px-5 rounded-lg shadow-custom bg-white-custom py-7 md:col-span-4">
          {data?.listLoading ? (
            <Skeleton active paragraph={{ rows: 4 }} />
          ) : (
            <div>
              <div className="w-full">
                <div className="flex items-center justify-start gap-6">
                  <div className="flex items-center gap-3">
                    <Image
                      src={data?.listData?.level_up_data?.image_url}
                      preview={false}
                      width={35}
                      height={35}
                    />
                    <div className="flex flex-col gap-x-2">
                      <div className="capitalize font-semibold text-[14px]">
                        {data?.listData?.level_up_data?.title}
                      </div>
                      <div className="capitalize text-[12px]">
                        {data?.listData?.level_up_data?.subtitle}
                      </div>
                    </div>
                  </div>

                  <Space className="" size={3} direction="vertical">
                    <div className="text-backdrop font-medium text-[12px]">
                      TerraPoints
                    </div>
                    <div className="text-xl font-bold text-backdrop">
                      {data?.listData?.level_up_data?.tera_point}
                    </div>
                  </Space>
                  <Space className="" size={3} direction="vertical">
                    <div className="text-backdrop font-medium text-[12px]">
                      Orbit Honors
                    </div>
                    <div className="text-xl font-extrabold text-backdrop">
                      {data?.listData?.level_up_data?.total_user_honors ?? 0}
                    </div>
                  </Space>
                </div>
              </div>

              <div
                className="grid bg-white rounded-[15px] px-5 py-4 grid-cols-1 md:grid-cols-6 mt-3 shadow-custom-sm md:absolute bottom-5 w-full md:w-[89%] transition-all cursor-pointer group"
                onClick={() => onOpen()}
              >
                <div className="absolute inset-0 bg-primary-bg opacity-0 group-hover:opacity-95 transition-opacity flex justify-center items-center rounded-[15px]">
                  <p className="text-xl font-semibold text-backdrop">
                    View full CV
                  </p>
                </div>
                <div className="relative col-span-1 md:col-span-2 group">
                  {data?.listData?.cv_data?.profile_picture ? (
                    <img
                      src={`https://stgreenskills001.blob.core.windows.net/default-avatars/7.png`}
                      alt="profile"
                      className="h-[72.5px] w-[72.5px] rounded-full object-contain bg-white"
                      style={{ border: "1px solid #D4F5C5" }}
                      onError={(e: any) => {
                        e.target.onerror = null
                        e.target.src = data?.listData?.cv_data?.avatar
                      }}
                    />
                  ) : (
                    <Avatar
                      src={
                        "https://stgreenskills001.blob.core.windows.net/default-avatars/7.png"
                      }
                      className=""
                      size={82.5}
                    />
                  )}
                </div>
                <div className="col-span-4">
                  <div className="flex items-center justify-between">
                    <h3>Green CV</h3>
                    <InfoCircleOutlined />
                  </div>
                  <div className="text-xs">
                    <div className="text-xs">
                      <b>
                        {data?.listData?.cv_data?.total_career_aspiration ?? 0}
                      </b>{" "}
                      Career Aspirations
                    </div>
                    <div className="text-xs">
                      <b>
                        {data?.listData?.cv_data
                          ?.total_proficient_green_skill ?? 0}
                      </b>{" "}
                      Green Skills I am proficient in
                    </div>
                    <div className="text-xs">
                      <b>
                        {data?.listData?.cv_data?.total_interested_green_skill}
                      </b>{" "}
                      Green Skills I am interested in
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <DetailByRole
        data={data}
        detailPreview={detailPreview?.detailExpert?.detailFounder}
      />

      <DrawerCV open={open} onClose={onClose} cv={cv} />
    </div>
  )
}

export default DetailPublicExpertProfileContentContent
