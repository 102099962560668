import { HomeOutlined, LockOutlined } from "@ant-design/icons"
import { useMsal } from "@azure/msal-react"
import { useLocation, useParams } from "react-router-dom"
import {
  Astroseed,
  GreenNebula,
  SolarExplorer,
  StartMaster,
  Teraformer,
} from "../../../assets/images"
import { PageContent } from "../../../common/layout"
import useLoading from "../../../hooks/Loading"
import LevelTabs from "../components/LevelTabs.tsx"
import {
  SingleMyLaunchpadContentSection,
  SingleMyLaunchpadRightSection,
  SingleMyLaunchpadTopSection,
} from "../components/SingleLaunchpadSection.tsx"
import { useSingleMyLaunchpad } from "../hooks/useSingleMyLaunchpad"
import { Image } from "antd"
import useCareerAspiration from "../../../hooks/useCareerAspiration.ts"

export const SingleMyLaunchpad: React.FC = () => {
  const { lastProgressLevel } = useParams<{
    lastProgressLevel?: string
    is_next_level?: string
  }>()
  const location = useLocation()

  const currentLevel = lastProgressLevel
    ? parseInt(lastProgressLevel, 10)
    : undefined

  const isUnlocked = location?.state?.is_next_level

  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()
  const { green_skill_id } = useParams<{ green_skill_id: string }>()

  const { isCustomLoading } = useLoading(true, 2000)

  const {
    skill,
    highestCurrentLevel,
    activeTab,
    handleChangeTabs,
    jobCareerPlan,
    isCustomLoadingHook,
    levels: dataLevels,
  } = useSingleMyLaunchpad(
    green_skill_id,
    activeAccount?.localAccountId,
    "",
    currentLevel,
    isUnlocked,
  )
  const { greencareerResp } = useCareerAspiration()

  const jobIncludesGS = greencareerResp?.greencareers?.filter(
    (job: any) =>
      job?.green_skills?.some((skill: any) => skill?.id === green_skill_id),
  )

  let totalLevel: any
  totalLevel = jobCareerPlan?.job?.green_skills?.reduce(
    (accumulator: any, currentItem: { level: any }) => {
      return accumulator + currentItem.level
    },
    0,
  )
  const totalLevelPassed = jobCareerPlan?.job?.green_skills?.reduce(
    (accumulator: any, currentItem: { level_passed: any }) => {
      return accumulator + currentItem.level_passed
    },
    0,
  )
  const percentPassed = (totalLevelPassed / totalLevel) * 100

  const items = [
    {
      path: "/dashboard",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      path: "/dashboard/launchpad",
      title: "Mission Control",
    },
    {
      path: "/dashboard/launchpad",
      title: "Launchpad",
    },
    {
      path: `/dashboard/launchpad/single-mylaunchpad/${green_skill_id}`,
      title: `${skill?.green_skills?.name}`,
    },
  ]

  const levels = skill && skill?.levels[0]?.levels
  const userLevel =
    levels &&
    Math.max(
      ...levels?.map((level: { proficiency: any }) => level?.proficiency),
    )
  const isPassed =
    levels &&
    userLevel &&
    levels[userLevel - 1]?.user_track?.is_assessment_passed
  let imgSrc, level_name

  function countIsAssessmentPassed(data: any): number {
    if (!data || !data.levels) {
      return 0
    }
    let count = 0
    for (const level of data.levels[0]?.levels) {
      if (level.user_track && level.user_track.is_assessment_passed) {
        count++
      }
    }
    return count
  }

  const passedCount: number = countIsAssessmentPassed(skill)

  const isNextLevelExist = !!skill?.levels[0]?.levels[passedCount]

  if (isPassed) {
    switch (passedCount) {
      case 1:
        imgSrc = isNextLevelExist ? SolarExplorer : Astroseed
        level_name = "Astroseed"
        break
      case 2:
        imgSrc = isNextLevelExist ? Teraformer : SolarExplorer
        level_name = "Solar Explorer"
        break
      case 3:
        imgSrc = isNextLevelExist ? GreenNebula : Teraformer
        level_name = "Terraformer"
        break
      case 4:
        imgSrc = isNextLevelExist ? StartMaster : GreenNebula
        level_name = "Green Nebula"
        break
      case 5:
        imgSrc = isNextLevelExist ? StartMaster : StartMaster
        level_name = "Start Master"
        break
      default:
        imgSrc = isNextLevelExist ? Astroseed : Astroseed
        level_name = "Astroseed"
    }
  } else {
    switch (passedCount) {
      case 1:
        imgSrc = isNextLevelExist ? SolarExplorer : Astroseed
        level_name = "Astroseed"
        break
      case 2:
        imgSrc = isNextLevelExist ? Teraformer : Astroseed
        level_name = "Astroseed"
        break
      case 3:
        imgSrc = isNextLevelExist ? GreenNebula : SolarExplorer
        level_name = "Solar Explorer"
        break
      case 4:
        imgSrc = isNextLevelExist ? StartMaster : Teraformer
        level_name = "Terraformer"
        break
      case 5:
        imgSrc = isNextLevelExist ? StartMaster : GreenNebula
        level_name = "Green Nebula"
        break
      default:
        imgSrc = Astroseed
        level_name = "Start Master"
    }
  }

  const isPreviousLevelPassed =
    skill?.levels[0]?.levels[highestCurrentLevel - 1] &&
    skill?.levels[0]?.levels[highestCurrentLevel - 1]?.user_track
      ?.is_assessment_passed

  const tabs = [
    {
      label: "Astroseed",
      allow: true,
      component: (
        <LevelTabs
          levelDatas={skill?.levels[0]}
          skillID={green_skill_id}
          activeTab={activeTab}
          isCustomLoadingHook={isCustomLoadingHook}
          account={activeAccount?.localAccountId}
          skillName={skill?.green_skills?.name}
          levelName={level_name}
          isPreviousLevelPassed={isPreviousLevelPassed}
          dataLevelAvailable={dataLevels}
        />
      ),

      icon: <Image src={Astroseed} width={20} preview={false} />,
    },
    {
      label: "Solar Explorer",
      allow: isPreviousLevelPassed
        ? skill?.levels[0]?.levels[1] &&
          skill?.levels[0]?.levels[0]?.user_track?.is_assessment_passed
        : skill?.levels[0]?.levels[1] &&
          !skill?.levels[0]?.levels[0]?.user_track?.is_assessment_passed,
      component: (
        <LevelTabs
          levelDatas={skill?.levels[0]}
          skillID={green_skill_id}
          activeTab={activeTab}
          isCustomLoadingHook={isCustomLoadingHook}
          account={activeAccount?.localAccountId}
          skillName={skill?.green_skills?.name}
          levelName={level_name}
          isPreviousLevelPassed={isPreviousLevelPassed}
          dataLevelAvailable={dataLevels}
        />
      ),

      icon:
        skill?.levels[0]?.levels[0] &&
        skill?.levels[0]?.levels[0]?.user_track?.is_assessment_passed ? (
          <Image src={SolarExplorer} width={20} preview={false} />
        ) : (
          <LockOutlined />
        ),
    },
    {
      label: "Terraformer",
      allow: isPreviousLevelPassed
        ? skill?.levels[0]?.levels[2] &&
          skill?.levels[0]?.levels[1]?.user_track?.is_assessment_passed
        : skill?.levels[0]?.levels[1] &&
          !skill?.levels[0]?.levels[0]?.user_track?.is_assessment_passed,
      component: (
        <LevelTabs
          levelDatas={skill?.levels[0]}
          skillID={green_skill_id}
          activeTab={activeTab}
          isCustomLoadingHook={isCustomLoadingHook}
          account={activeAccount?.localAccountId}
          skillName={skill?.green_skills?.name}
          levelName={level_name}
          isPreviousLevelPassed={isPreviousLevelPassed}
          dataLevelAvailable={dataLevels}
        />
      ),

      icon:
        skill?.levels[0]?.levels[1] &&
        skill?.levels[0]?.levels[1]?.user_track?.is_assessment_passed ? (
          <Image src={Teraformer} width={20} preview={false} />
        ) : (
          <LockOutlined />
        ),
    },
    {
      label: "Green Nebula",
      allow: isPreviousLevelPassed
        ? skill?.levels[0]?.levels[3] &&
          skill?.levels[0]?.levels[2]?.user_track?.is_assessment_passed
        : skill?.levels[0]?.levels[1] &&
          !skill?.levels[0]?.levels[0]?.user_track?.is_assessment_passed,
      component: (
        <LevelTabs
          levelDatas={skill?.levels[0]}
          skillID={green_skill_id}
          activeTab={activeTab}
          isCustomLoadingHook={isCustomLoadingHook}
          account={activeAccount?.localAccountId}
          skillName={skill?.green_skills?.name}
          levelName={level_name}
          isPreviousLevelPassed={isPreviousLevelPassed}
          dataLevelAvailable={dataLevels}
        />
      ),

      icon:
        skill?.levels[0]?.levels[2] &&
        skill?.levels[0]?.levels[2]?.user_track?.is_assessment_passed ? (
          <Image src={GreenNebula} width={20} preview={false} />
        ) : (
          <LockOutlined />
        ),
    },
    {
      label: "Star Master",
      allow: isPreviousLevelPassed
        ? skill?.levels[0]?.levels[4] &&
          skill?.levels[0]?.levels[3]?.user_track?.is_assessment_passed
        : skill?.levels[0]?.levels[1] &&
          !skill?.levels[0]?.levels[0]?.user_track?.is_assessment_passed,
      component: (
        <LevelTabs
          levelDatas={skill?.levels[0]}
          skillID={green_skill_id}
          activeTab={activeTab}
          isCustomLoadingHook={isCustomLoadingHook}
          account={activeAccount?.localAccountId}
          skillName={skill?.green_skills?.name}
          levelName={level_name}
          isPreviousLevelPassed={isPreviousLevelPassed}
          dataLevelAvailable={dataLevels}
        />
      ),
      icon:
        skill?.levels[0]?.levels[3] &&
        skill?.levels[0]?.levels[3]?.user_track?.is_assessment_passed ? (
          <Image src={StartMaster} width={20} preview={false} />
        ) : (
          <LockOutlined />
        ),
    },
  ]

  return (
    <div className="">
      <PageContent
        header={
          <SingleMyLaunchpadTopSection
            breadcrumbsItems={items}
            imgSrc={imgSrc}
            passedCount={passedCount}
            level_name={level_name}
            skill={skill}
            isCustomLoading={isCustomLoading}
            highestCurrentLevel={highestCurrentLevel}
            tabs={tabs}
            activeTab={activeTab}
            handleChangeTabs={handleChangeTabs}
            userLevel={userLevel}
            isNextLevelExist={isNextLevelExist}
            levels={dataLevels}
          />
        }
        content={
          <SingleMyLaunchpadContentSection
            tabs={tabs}
            activeTab={activeTab}
            isCustomLoading={isCustomLoading}
            highestCurrentLevel={highestCurrentLevel}
            handleChangeTabs={handleChangeTabs}
            userLevel={userLevel}
            passedCount={passedCount}
          />
        }
        contentRightSidebar={
          <SingleMyLaunchpadRightSection
            percentPassed={percentPassed}
            jobCareerPlan={jobCareerPlan}
            isCustomLoading={isCustomLoading}
            levels={dataLevels}
            skill={skill?.green_skills}
            jobIncludesGS={jobIncludesGS}
          />
        }
      />
    </div>
  )
}
export default SingleMyLaunchpad
