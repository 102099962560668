import React from "react"
import { Moon } from "../../../../assets/images"

interface Props {
  data: { id: string; name: string; level: number }[]
}
export const TechnicalSkill: React.FC<Props> = ({ data }) => {
  return (
    <div className=" bg-[#fafafa] rounded-xl shadow-md p-5 w-full">
      <h3 className="mb-3">Technical Skills</h3>
      {data?.length > 0 ? (
        <div className=" flex flex-wrap gap-5">
          {data?.map((item, i) => (
            <div className="flex gap-1 items-center" key={i}>
              <div
                className="w-4 h-4 flex items-center justify-center rounded-sm"
                style={{ border: "1px solid #36505E" }}
              >
                {item.level}
              </div>
              <div>{item.name}</div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex gap-3 items-center">
          <img src={Moon} alt="cookies" />
          <div>{"Nothing to display"}</div>
        </div>
      )}
    </div>
  )
}
