import { create } from "zustand"

export type DisplayStateClimateEditForm = {
  isDisplayed: boolean
  toggleDisplay: () => void
}

export const useDisplayStateClimateEditForm =
  create<DisplayStateClimateEditForm>((set) => ({
    isDisplayed: true,
    toggleDisplay: () => set((state) => ({ isDisplayed: !state.isDisplayed })),
  }))
