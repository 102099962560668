import { Badge, Button, Switch } from "antd"
import {
  InstalledAppType,
  OAuthProvider,
} from "../../../../../utils/connect-account"
import { GoogleLogo, MicrosoftleLogo } from "../../../../../assets/images"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { useListMentorAvailablity } from "../hooks/useMentorAvailability"
import { InstalledAppResp } from "../types/response"
import { MENTOR_ENDPOINTS } from "../apis/endpoint"
import instance from "../../../../../config/config"
import { handlingError } from "../../../../../utils/handling"

export const ConnectAccount = (v: { provider: OAuthProvider }) => {
  const createConnectUrl = async (provider: OAuthProvider) => {
    try {
      const { data } = await instance.post(
        MENTOR_ENDPOINTS.OAUTH_WEB_CONNECT(provider),
      )
      // return data
      console.log("createConnectUrl", data)
      window.location.href = data?.data!
    } catch (error: any) {
      return handlingError(error?.response?.data?.message, error)
    }
  }

  const connectAccount = async (provider: OAuthProvider) => {
    console.log("provider", provider)
    if (provider) {
      createConnectUrl(provider)
    }
  }

  return (
    <>
      <div className="relative">
        <div className="bg-gray-600 w-full h-full absolute top-0 left-0 opacity-40 rounded-md z-10"></div>
        <div className="bg-gray-700 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-12 py-4 rounded-md z-20 bg-opacity-60">
          <Button
            className="flex items-center "
            // disabled={createConnectUrl.isLoading}
            onClick={() => connectAccount(v.provider)}
          >
            {/* {createConnectUrl.isLoading ? (
              <Spin className="w-4 animate-spin mr-1" />
            ) : ( */}
            <img
              className={
                v.provider == OAuthProvider.GOOGLE
                  ? "w-6 h-6 mr-2"
                  : "w-[20px] h-[20px] mr-2"
              }
              src={
                v.provider == OAuthProvider.GOOGLE
                  ? GoogleLogo
                  : MicrosoftleLogo
              }
              alt={v.provider + "-logo"}
            />
            {/* )} */}
            <span>
              Connect With <span className="capitalize">{v.provider}</span>
            </span>
          </Button>
        </div>
        <section className="bg-gray-100 relative rounded-md divide-y divide-dashed">
          <div className="flex flex-row gap-4 p-4">
            <img
              className="h-8 w-8 mt-2"
              src={
                v.provider == OAuthProvider.GOOGLE
                  ? "https://stgreenskills001.blob.core.windows.net/assets/google-calendar-logo.svg"
                  : "https://stgreenskills001.blob.core.windows.net/assets/outlock-calendar-logo.svg"
              }
              alt={v.provider + "-calendar-logo"}
            />
            <div className="relative overflow-auto">
              <h5 className="text-md font-semibold">
                {v.provider == OAuthProvider.GOOGLE ? "Google" : "Outlook"}{" "}
                Calendar
              </h5>
              <p className="text-xs text-gray-600 truncate overflow-hidden ...">
                {v.provider == OAuthProvider.GOOGLE
                  ? `Google Calendar is a time management and scheduling service
              developed by Google. `
                  : ` Microsoft Office 365 is a suite of apps that helps you stay
                connected with others and get things done.`}
              </p>
            </div>
          </div>
          <div className="flex flex-row gap-4 p-4">
            <img
              className={
                v.provider == OAuthProvider.GOOGLE ? "h-10 w-10" : "h-8 w-8"
              }
              src={
                v.provider == OAuthProvider.GOOGLE
                  ? "https://stgreenskills001.blob.core.windows.net/assets/google-meet-logo.webp"
                  : "https://stgreenskills001.blob.core.windows.net/assets/microsoft-team-logo.svg"
              }
              alt={v.provider + "-conferece-logo"}
            />
            <div className="relative overflow-auto">
              <h5 className="text-md font-semibold">
                {v.provider == OAuthProvider.GOOGLE
                  ? "Google Meet"
                  : "Microsoft 365/Teams (Requires work/school account)"}
              </h5>
              <p className="text-xs text-gray-600 truncate overflow-hidden ...">
                {v.provider == OAuthProvider.GOOGLE
                  ? ` Google Meet is Google's web-based video conferencing platform, designed to compete with major conferencing platforms.`
                  : `Microsoft Teams is a business communication platform and
                collaborative workspace included in Microsoft 365.`}
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export const InstalledApp = ({ provider }: { provider: OAuthProvider }) => {
  // const { availability, setUserAvailability } = useUserStore()
  // const { updateAvailability } = useAPI()
  const { listData } = useListMentorAvailablity()

  // const saveChanges = useMutation(updateAvailability, {
  //   onSuccess: (resp) => {
  //     const availabilityData = resp?.data
  //     setUserAvailability(availabilityData)
  //   },
  //   onError: (error) => handleError(error),
  // })

  // const onCalendarStateChange = (state: boolean, id: string) => {
  //   if (!id) return
  //   saveChanges.mutate(JSON.stringify({
  //     apps: [{
  //       "id": id,
  //       "type": "calendar",
  //       "must_sync": state
  //     }]
  //   }))
  // }

  const renderAppDetails = (
    index: number,
    app: InstalledAppResp,
    type: InstalledAppType,
  ) => (
    <div className="flex flex-row gap-4 p-3" key={index}>
      <img
        className={
          type === InstalledAppType.CALENDAR ? "h-8 w-8 mt-2" : "h-10 w-10"
        }
        src={app.logo}
        alt={`${provider}-${app.name}-logo`}
      />
      <div className="relative overflow-auto">
        <h5 className="text-md font-semibold">
          {app.name}
          {app.is_default && type === InstalledAppType.CONFERENCING && (
            <Badge className="bg-green-200 text-green-800 hover:bg-green-200 hover:text-green-800 py-1 ml-2 rounded-sm">
              <span className="text-xs">Default</span>
            </Badge>
          )}
        </h5>
        <p className="text-xs text-gray-600 line-clamp-2">{app.description}</p>
        {type === InstalledAppType.CALENDAR && (
          <div className="flex flex-row items-center justify-between gap-2 mt-4">
            {/* <Switch defaultChecked={app.must_sync} onCheckedChange={(checked) => onCalendarStateChange(checked, app.id)} /> */}
            <div className="flex gap-x-3">
              <Switch className="" />
              <span className="text-xs">{app.email} (Calendar)</span>
            </div>
            {app.is_default && (
              <Badge className="bg-gray-200 text-black hover:bg-gray-200 hover:text-black py-1 rounded-sm text-xs">
                <ArrowLeftOutlined className="h-4 w-4 mr-2" />
                Adding events to
              </Badge>
            )}
          </div>
        )}
      </div>
    </div>
  )

  return (
    <section className="bg-gray-100 relative rounded-md divide-y divide-dashed">
      {(listData?.installed_apps?.calendars || [])
        .filter((app: InstalledAppResp) => app.provider === provider)
        .map((app: InstalledAppResp, index: number) =>
          renderAppDetails(index, app, InstalledAppType.CALENDAR),
        )}
      {(listData?.installed_apps?.conferencing || [])
        .filter((app) => app.provider === provider)
        .map((app, index) =>
          renderAppDetails(index, app, InstalledAppType.CONFERENCING),
        )}
    </section>
  )
}
