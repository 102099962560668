/* eslint-disable no-unused-vars */
import instance from "../../../../config/config"
import { ApiResponse } from "../../../../common/types/ApiResponse"

export enum CollectionType {
  POST = "posts",
  COMMENT = "comments",
}

export const reactToPostorComment = ({
  collection,
  collection_id,
}: {
  collection: CollectionType
  collection_id: string
}) => {
  return instance.post<ApiResponse<null>>(
    `/community/${collection}/${collection_id}/responses`,
  )
}

export const getReactions = (
  collection: CollectionType,
  collection_id: string,
) => {
  return instance.get<ApiResponse<any>>(
    `/community/${collection}/${collection_id}/responses`,
  )
}
