import React from "react"
import CVGreenCareer from "./skill/CVGreenCareer"
// import CVSoftSkill from "./skill/CVSoftSkill";
import CVHardSkill from "./skill/CVHardSkill.tsx"
import CVGreenSkill from "./skill/CVGreenSkill.tsx"
import { Languages } from "./Languages"
import CVSoftSkillV2 from "./skill/CVSoftSkillV2.tsx"

interface Props {
  active: { key: string; label: string; icon: JSX.Element }
}

export const Skills: React.FC<Props> = ({ active }) => {
  switch (active.key) {
    case "green_career_aspirations":
      return (
        <div>
          <CVGreenCareer />
        </div>
      )
    case "soft_skills":
      return <CVSoftSkillV2 />
    case "hard_skills":
      return (
        <div>
          <CVHardSkill />
        </div>
      )
    case "green_skills":
      return (
        <div>
          <CVGreenSkill />
        </div>
      )
    case "languages":
      return <Languages />
    default:
      return <div>green career aspirations</div>
  }
}
