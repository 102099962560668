import React, { useContext } from "react"
import { CheckOutlined } from "@ant-design/icons"
import { AccountContext } from "../../../../common/layout/PageLayout"
import { Moon } from "../../../../assets/images"
interface Props {
  data: { id: string; name: string }[] | undefined
}
export const DetailTechnicalSkill: React.FC<Props> = ({ data }) => {
  const account = useContext(AccountContext)
  return (
    <div className=" bg-[#fafafa] rounded-xl shadow-md p-5 w-full">
      <h3 className="mb-3">Technical Skills</h3>
      {data && data?.length > 0 ? (
        <div className=" flex flex-wrap gap-5">
          {data?.map((item, i) => (
            <div className="flex gap-1 items-center" key={i}>
              {account?.data?.data?.hard_skill?.find(
                (e: any) => e.name === item.name,
              ) && (
                <div
                  className="w-3 h-3 flex items-center justify-center rounded-full bg-primary"
                  //   style={{ border: "1px solid #36505E" }}
                >
                  <CheckOutlined className="text-[6px] text-white" />
                </div>
              )}
              <div
                className={`${
                  account?.data?.data?.hard_skill?.find(
                    (e: any) => e.name === item.name,
                  )
                    ? " font-semibold"
                    : ""
                } first-letter:capitalize`}
              >
                {item.name}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex gap-3 items-center">
          <img src={Moon} alt="cookies" />
          <div>{"Nothing to display"}</div>
        </div>
      )}
    </div>
  )
}
