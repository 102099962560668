import { useMemo, useState } from "react"
import { handlingError } from "../../../../utils/handling"
import { useMutation, useQuery, useQueryClient } from "react-query"
import instance from "../../../../config/config"
import { message } from "antd"
import { errorMessage } from "../../../../common/utils/utils"

const useSingleCompany = (id: string, userID: string) => {
  // countries
  const [selectedHQ, setSelectedHQ] = useState<any[]>([])
  const idCountries = selectedHQ?.map((item) => item.id)
  const countriesID = idCountries?.join(",")

  // greenskills
  const [selectedGS, setSelectedGS] = useState<any[]>([])
  const idGreenSkills = selectedGS?.map((item) => item.id)
  const greenSkillID = idGreenSkills?.join(",")

  // teams or function
  const [selectedTeams, setSelectedTeams] = useState<any[]>([])
  const idTeams = selectedTeams?.map((item) => item.id)
  const teamsName = idTeams?.join(",")

  // types
  const [selectedTypes, setSelectedTypes] = useState<any[]>([])
  const idTypes = selectedTypes?.map((item) => item.id)
  const typesName = idTypes?.join(",")

  // remote
  const [remote, setRemote] = useState("")

  const queryKey = useMemo(
    () => [
      "company-detail",
      {
        location: countriesID,
        green_skills: greenSkillID,
        types: typesName,
        teams: teamsName,
        user_id: userID,
        remote,
      },
    ],
    [countriesID, greenSkillID, typesName, teamsName, userID, remote],
  )

  const fetchDetailCompany = async () => {
    try {
      const { data } = await instance.get(`/companies/${id}`, {
        params: {
          location: countriesID,
          green_skills: greenSkillID,
          types: typesName,
          teams: teamsName,
          user_id: userID,
          remote,
        },
      })
      return data
    } catch (error: any) {
      return handlingError(error?.response?.data?.message, error)
    }
  }

  const { data, isLoading } = useQuery(queryKey, fetchDetailCompany)
  const detailCompany = data?.data
  const isDetailCompanyLoading = isLoading

  const fetchListCompanyCountries = async () => {
    try {
      const { data } = await instance.get(
        `/companies/${id}/references/countries`,
        {
          params: {
            // name: searchDebounce,
          },
        },
      )
      return data
    } catch (error: any) {
      return handlingError(error?.response?.data?.message, error)
    }
  }

  const { data: countriesFilter, isLoading: isCountriesFiltertLoading } =
    useQuery("company-countries", fetchListCompanyCountries)

  const fetchListCompanyGS = async () => {
    try {
      const { data } = await instance.get(
        `/companies/${id}/references/green-skills`,
        {
          params: {
            // name: searchDebounce,
          },
        },
      )
      return data
    } catch (error: any) {
      return handlingError(error?.response?.data?.message, error)
    }
  }

  const { data: gsFilter, isLoading: isGSFiltertLoading } = useQuery(
    "company-gs",
    fetchListCompanyGS,
  )

  const fetchListCompanyTeams = async () => {
    try {
      const { data } = await instance.get(`/companies/${id}/references/teams`, {
        params: {
          // name: searchDebounce,
        },
      })
      return data
    } catch (error: any) {
      return handlingError(error?.response?.data?.message, error)
    }
  }

  const { data: teamsFilter, isLoading: isTeamsFiltertLoading } = useQuery(
    "company-teams",
    fetchListCompanyTeams,
  )

  const fetchListCompanyTypes = async () => {
    try {
      const { data } = await instance.get(`/companies/${id}/references/types`, {
        params: {
          // name: searchDebounce,
        },
      })
      return data
    } catch (error: any) {
      return handlingError(error?.response?.data?.message, error)
    }
  }

  // remote filter
  const handleFilterByRemote = (event: any) => {
    if (event.target.checked) {
      setRemote(event.target.checked)
    } else {
      setRemote("")
    }
  }

  // teams or function
  const handleFilterByTeam = (value: string, option: any) => {
    const itemSelected = {
      id: option.value.replace(/ /g, "%20"),
      name: option.label,
    }
    setSelectedTeams([...selectedTeams, itemSelected])
  }

  // countries infinity
  const handleFilterByHQ = (value: string, option: any) => {
    const itemSelected = {
      id: option.value,
      name: option.label,
    }

    setSelectedHQ([...selectedHQ, itemSelected])
  }

  const removeSelectedHQ = (id: string) => {
    setSelectedHQ(selectedHQ.filter((item) => item.id !== id))
  }

  const removeSelectedTeam = (id: string) => {
    setSelectedTeams(selectedTeams.filter((item) => item.id !== id))
  }

  // teams or function
  const handleFilterByType = (value: string, option: any) => {
    const itemSelected = {
      id: option.value,
      name: option.label,
    }
    setSelectedTypes([...selectedTypes, itemSelected])
  }

  const removeSelectedType = (id: string) => {
    setSelectedTypes(selectedTypes.filter((item) => item.id !== id))
  }

  const { data: typesFilter, isLoading: isTypesFiltertLoading } = useQuery(
    "company-types",
    fetchListCompanyTypes,
  )

  const handleFilterByGS = (value: string, option: any) => {
    const itemSelected = {
      id: option.value,
      name: option.label,
    }
    setSelectedGS([...selectedGS, itemSelected])
  }

  const removeSelectedGS = (id: string) => {
    setSelectedGS(selectedGS.filter((item) => item.id !== id))
  }

  const saveCompay = async (newData: any) => {
    try {
      const response = await instance.post("/companies/favourites", newData)
      return response.data
    } catch (error) {
      throw new Error("Failed to save company")
    }
  }
  const unSaveCompay = async (companyID: string) => {
    try {
      const response = await instance.delete(
        `/companies/favourites/${userID}/${companyID}`,
      )
      return response.data
    } catch (error) {
      throw new Error("Failed to save company")
    }
  }

  const queryClient = useQueryClient()

  const addDataMutationSaveCompany = useMutation(saveCompay, {
    onSuccess: () => {
      queryClient.invalidateQueries("company-detail")
    },
  })
  const addDataMutationUnsaveCompany = useMutation(unSaveCompay, {
    onSuccess: () => {
      queryClient.invalidateQueries("company-detail")
    },
  })

  const handleSaveCompany = async (newData: any) => {
    const data = {
      company_id: newData.id,
      company_name: newData.name,
      user_id: userID,
    }

    try {
      await addDataMutationSaveCompany.mutateAsync(data)
      message.success("Success follow company")
    } catch (error) {
      errorMessage("Failed to follow company")
    }
  }

  const handleUnSaveCompany = async (companyID: string) => {
    try {
      await addDataMutationUnsaveCompany.mutateAsync(companyID)
      message.success("Success unfollow company")
    } catch (error) {
      errorMessage("Failed to unfollow company")
    }
  }

  const optionLocation = countriesFilter?.data?.map((item: any) => ({
    value: item.id,
    label: item.name,
  }))
  const optionGS = gsFilter?.data?.map((item: any) => ({
    value: item.id,
    label: item.name,
  }))
  const optionTeams = teamsFilter?.data?.map((item: any) => ({
    value: item,
    label: item,
  }))
  const optionTypes = typesFilter?.data?.map((item: any) => ({
    value: item.id,
    label: item.name,
  }))

  return {
    companyResp: {
      detailCompany,
      isDetailCompanyLoading,
    },
    filterHandler: {
      handleFilterByHQ,
      selectedHQ,
      removeSelectedHQ,
      handleFilterByGS,
      selectedGS,
      removeSelectedGS,
      handleFilterByTeam,
      selectedTeams,
      removeSelectedTeam,
      handleFilterByType,
      selectedTypes,
      removeSelectedType,
      handleFilterByRemote,
      handleSaveCompany,
      handleUnSaveCompany,
    },

    filterResp: {
      optionLocation,
      isCountriesFiltertLoading,
      optionGS,
      isGSFiltertLoading,
      optionTeams,
      isTeamsFiltertLoading,
      optionTypes,
      isTypesFiltertLoading,
    },
  }
}

export default useSingleCompany
