import { Button, Drawer, Spin } from "antd"
import React from "react"
import { CardVerifiedUpdate } from "./CardVerifiedUpdate"
import { CardVerifiedView } from "./CardVerifiedView"
import { SkillVerification } from "../../../hooks/cv-builder/useSkillVerification"
import { PlusOutlined } from "@ant-design/icons"
import { GSProficient } from "../../../hooks/cv-builder/common_type"

interface Props {
  data: {
    data: SkillVerification[]
    isLoading: boolean
  }
  actions: {
    handleAddDown: () => void
    updateData: (index: number, data: SkillVerification) => void
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    onRemove: (id: string) => Promise<void>
    loadingRemove: boolean
    onPublish: (id: string, index: number) => Promise<void>
    loadingsubmitPublish: boolean
  }
  gsProficient: GSProficient[] | undefined
}

export const GreenSkillVerification: React.FC<Props> = ({
  data,
  actions,
  gsProficient,
}) => {
  return (
    <Drawer
      closeIcon={null}
      onClose={() => actions.setIsOpen(false)}
      open={actions.isOpen}
      width={"60%"}
      headerStyle={{ border: "0px", display: "none" }}
    >
      <div className="">
        <Spin spinning={data.isLoading}>
          {data?.data?.map((item, index) =>
            item.isEdit ? (
              <CardVerifiedUpdate
                key={index}
                data={item}
                index={index}
                actionUpdate={actions.updateData}
                dataAll={data?.data}
                gsProficient={gsProficient}
              />
            ) : (
              <CardVerifiedView
                key={index}
                data={item}
                index={index}
                actionUpdate={actions.updateData}
                actionRemove={actions.onRemove}
                loadingRemove={actions.loadingRemove}
                actionPublish={actions.onPublish}
                loadingPublish={actions.loadingsubmitPublish}
              />
            ),
          )}
          <div
            className=" shadow-md rounded-lg gap-5 py-3 px-5 mb-5 bg-secondaryYellow flex justify-between"
            style={{ border: "0.5px solid #E5E7EB" }}
          >
            <h3>Verify your existing Green Skills</h3>
            <Button type="primary" onClick={actions.handleAddDown}>
              <PlusOutlined /> New Skill
            </Button>
          </div>
        </Spin>
      </div>
    </Drawer>
  )
}
