import { Switch } from "antd"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"

import React from "react"
import { usePrivacySetting } from "../../../account/hooks/usePrivacySetting"
import FastJobTable from "../../../../common/components/FastJobTable"
import { useMsal } from "@azure/msal-react"
import { SavedJob } from "../../../../assets/images"

const MyJobs = () => {
  const { statusPrivate, changeStatus, loadingPrivacy } = usePrivacySetting()
  const { accounts } = useMsal()
  const activeAccount = accounts?.[0]

  return (
    <div>
      <div className="py-5 flex gap-4">
        <img
          src={SavedJob}
          alt="icon-save-job"
          className={`-mt-7 w-[40px] object-contain`}
        />
        <div className="">
          <h4>Active Job seeker?</h4>
          <p className={`p-0 m-0`}>
            Make you Green CV discoverable to Companies on Skilledin Green
            platform!{" "}
          </p>
          <div className="flex items-center gap-3 mt-4 mb-3">
            <Switch
              checkedChildren={<CheckOutlined />}
              disabled={loadingPrivacy}
              unCheckedChildren={<CloseOutlined />}
              defaultChecked={true}
              onChange={() =>
                changeStatus("discover", !statusPrivate?.discover)
              }
              checked={statusPrivate?.discover}
            />
            {statusPrivate?.discover ? (
              <span>
                Your CV is <b>discoverable</b> by recruiters
              </span>
            ) : (
              <span>
                Your CV is <b>not discoverable</b> by recruiters
              </span>
            )}
          </div>
        </div>
      </div>
      <div className={`shadow-custom rounded-lg relative`}>
        {!statusPrivate?.discover && (
          <div className="inset-0 absolute top-0 left-0 rounded-lg bg-gray-200/60 pointer-events-none z-20"></div>
        )}
        <FastJobTable userId={activeAccount?.localAccountId} />
      </div>
    </div>
  )
}

export default MyJobs
