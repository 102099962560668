import { message } from "antd"
import dayjs, { Dayjs } from "dayjs"
import { useState, useEffect } from "react"
import { useWrite, useWriteV2 } from "../../../../../hooks/generic_rest"
import { MENTOR_ENDPOINTS } from "../apis/endpoint"
import { DayOverride, TimeSlot } from "../types/response"
import { useQueryClient } from "react-query"
import { LIST_MENTOR_AVAILABLITY_QUERY_NAME } from "./useMentorAvailability"

export const useOverrideAvailablity = () => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date())
  const [selectedDates, setSelectedDates] = useState<Date[]>([])
  const [allDay, setAllDay] = useState<boolean>(false)
  const [allDayStates, setAllDayStates] = useState<{ [key: string]: boolean }>(
    {},
  )
  const [selectedTimes, setSelectedTimes] = useState<{
    [key: string]: TimeSlot[]
  }>({})
  const [showDateRange, setShowDateRange] = useState<boolean>(false)
  const [dayOverrides, setDayOverrides] = useState<any>({})
  const [daySelected, setDaySelected] = useState<string>("")
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth()
  const firstDayOfMonth = new Date(year, month, 1).getDay()
  const daysInMonth = new Date(year, month + 1, 0).getDate()
  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

  const queryClient = useQueryClient()

  const { mutate: saveAvailability } = useWrite<any, any>(
    MENTOR_ENDPOINTS.EDIT_AVAILABILITY,
    "patch",
    () => {
      message.success("Availability updated successfully!")
      setSelectedDates([])
      setSelectedTimes({})
      setAllDayStates({})

      queryClient.invalidateQueries(LIST_MENTOR_AVAILABLITY_QUERY_NAME)
    },
    () => message.error("Something went wrong while updating availability."),
  )

  const removeOverrideDate = useWriteV2<any, any>(
    ({ date }) => MENTOR_ENDPOINTS.DELETE_OVERRIDE_DATE(date),
    "delete",
    () => {
      queryClient.invalidateQueries(LIST_MENTOR_AVAILABLITY_QUERY_NAME)
    },
    () => {
      message.open({
        key: "update-time-available",
        content: "Something went wrong",
        type: "error",
      })
    },
  )

  useEffect(() => {
    const initialTimeSlots = {
      [dayjs().toISOString().split("T")[0]]: [
        {
          start_date: dayjs().toISOString(),
          end_date: dayjs().add(1, "hour").toISOString(),
        },
      ],
    }
    setSelectedTimes(initialTimeSlots)
  }, [])

  const handleMonthChange = (direction: "prev" | "next"): void => {
    const newDate = new Date(currentDate)
    if (direction === "prev") {
      newDate.setMonth(newDate.getMonth() - 1)
    } else {
      newDate.setMonth(newDate.getMonth() + 1)
    }
    setCurrentDate(newDate)
  }

  const handleDateClick = (date: string): void => {
    const dayName = dayjs(date).format("dddd")
    setDaySelected(dayName)
    const newDate = new Date(date)
    setSelectedDates((prevDates) => {
      const dateExists = prevDates.some(
        (d) => d.toISOString().split("T")[0] === date,
      )
      const newDates = dateExists
        ? prevDates.filter((d) => d.toISOString().split("T")[0] !== date)
        : [...prevDates, newDate]
      if (!dateExists) {
        setSelectedTimes((prevTimes) => ({
          ...prevTimes,
          [date]: [
            {
              start_date: dayjs(newDate).toISOString(),
              end_date: dayjs(newDate).add(1, "hour").toISOString(),
            },
          ],
        }))

        setAllDayStates((prevAllDayStates) => ({
          ...prevAllDayStates,
          [date]: false,
        }))
      }
      return newDates
    })
    setShowDateRange(true)
    setAllDay(false)
  }

  const handleAddTimeSlot = (date: string): void => {
    setSelectedTimes((prevTimes) => {
      const times = prevTimes[date] || []
      if (times.length >= 3) {
        message.info("You can only add up to 3 time slots!")
        return prevTimes
      }
      return {
        ...prevTimes,
        [date]: [
          ...times,
          {
            start_date: dayjs().toISOString(),
            end_date: dayjs().add(1, "hour").toISOString(),
          },
        ],
      }
    })
  }

  const handleDeleteTimeSlot = (date: string, index: number): void => {
    setSelectedTimes((prevTimes) => {
      const times = prevTimes[date].filter((_, i) => i !== index)
      return {
        ...prevTimes,
        [date]: times,
      }
    })
  }

  const handleAllDayChange = (date: string, checked: boolean): void => {
    setAllDayStates((prevAllDayStates) => ({
      ...prevAllDayStates,
      [date]: checked,
    }))
    if (checked) {
      setSelectedTimes((prevTimes) => {
        const newTimes = { ...prevTimes }
        newTimes[date] = [
          {
            start_date: dayjs(date).startOf("day").toISOString(),
            end_date: dayjs(date).endOf("day").toISOString(),
          },
        ]
        return newTimes
      })
    } else {
      setSelectedTimes((prevTimes) => {
        const newTimes = { ...prevTimes }
        newTimes[date] = [
          {
            start_date: dayjs(date).toISOString(),
            end_date: dayjs(date).add(1, "hour").toISOString(),
          },
        ]
        return newTimes
      })
    }
  }

  const handleTimeChangeOverride = (
    date: string,
    index: number,
    field: "start" | "end",
    time: Dayjs | null,
  ): void => {
    setSelectedTimes((prevTimes) => {
      const times = prevTimes[date].map((slot, i) => {
        if (i === index) {
          return { ...slot, [field]: time }
        }
        return slot
      })
      return {
        ...prevTimes,
        [date]: times,
      }
    })
  }

  const formatDate = (date: Date): string => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    return `${year}-${month}-${day}`
  }

  // const replaceTimeInDate = (date: Date, time: Dayjs | null): Date => {
  //   if (!time) return date
  //   const dateString = formatDate(date)
  //   const hours = String(time.hour()).padStart(2, "0")
  //   const minutes = String(time.minute()).padStart(2, "0")
  //   return new Date(`${dateString}T${hours}:${minutes}:00.000Z`)
  // }

  const handleSave = (): void => {
    if (!selectedDates.length) {
      message.error("Please select a date first!")
      return
    }

    const items: DayOverride = {}

    if (allDay) {
      selectedDates.forEach((date) => {
        const formattedDate = formatDate(date)
        items[formattedDate] = [
          {
            start_date: `${formattedDate}T00:00:00.000Z`,
            end_date: `${formattedDate}T00:00:00.000Z`,
          },
        ]
      })
    } else {
      selectedDates.forEach((date) => {
        const formattedDate = formatDate(date)
        const times = selectedTimes[formattedDate] || []
        items[formattedDate] = times.map((time) => ({
          start_date: time.start_date,
          end_date: time.end_date,
        }))
      })
    }

    setDayOverrides({ ...dayOverrides, ...items })

    saveAvailability({ day_overrides: items })
  }

  const handleRemoveOverrideDate = (date: string) => {
    console.log("data", date)
    removeOverrideDate.mutate({ date })
  }

  return {
    currentDate,
    firstDayOfMonth,
    daysInMonth,
    dayNames,
    handleMonthChange,
    handleDateClick,
    showDateRange,
    selectedTimes,
    allDay,
    allDayStates,
    handleAddTimeSlot,
    handleDeleteTimeSlot,
    handleAllDayChange,
    handleTimeChangeOverride,
    handleSave,
    daySelected,
    year,
    month,
    selectedDates,
    handleRemoveOverrideDate,
  }
}
