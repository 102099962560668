export const translatelevelName = (val) => {
  if (typeof val != "number") return undefined
  switch (val) {
    case 1:
      return "1 - Astroseed"
    case 2:
      return "2 - Solar Explorer"
    case 3:
      return "3 - Terraformer"
    case 4:
      return "4 - Green Nebula"
    case 5:
      return "5 - Star Master"
    default:
      return undefined
  }
}
