import React from "react"

import { Controller } from "react-hook-form"
import { LinkOutlined } from "@ant-design/icons"
import ImgCrop from "antd-img-crop"
import { PlusOutlined } from "@ant-design/icons"
import TextArea from "antd/es/input/TextArea"
import { Input, Select, Upload } from "antd"

interface CompanySectionProps {
  editCompanyHandlers?: any
}

const CompanySection: React.FC<CompanySectionProps> = ({
  editCompanyHandlers,
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 mt-4 gap-6">
      <div className="shadow-custom-sm p-2 md:py-3 md:px-6 rounded-xl">
        <h3>Company Information</h3>
        <div className="space-y-3 mt-3">
          <Controller
            name="name"
            control={editCompanyHandlers?.control}
            // rules={{ required: true }}
            render={({ field }) => (
              <Input
                {...field}
                status={editCompanyHandlers?.errors?.name ? "error" : ""}
                className="my-1 font-default rounded basis-1/2"
                placeholder="Company Name"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
          <Controller
            name="stage_id"
            control={editCompanyHandlers?.control}
            // rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                className="my-1 font-default basis-full job-history w-full"
                placeholder="Stage"
                showSearch
                status={editCompanyHandlers?.errors?.stage_id ? "error" : ""}
                options={editCompanyHandlers?.referenceData?.stageData?.listStage?.map(
                  (val: any) => ({
                    label: val.name,
                    value: val.id,
                  }),
                )}
                labelInValue={true}
                onChange={(option) => {
                  const { label, value } = option
                  editCompanyHandlers?.onSelectChangeStage(label, value)
                }}
                // defaultValue={''}
                filterOption={(input: any, option: any) => {
                  if (option && option.value) {
                    return (
                      option.value.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    )
                  }
                  return false
                }}
              />
            )}
          />
          <Controller
            name="location_id"
            control={editCompanyHandlers?.control}
            // rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                className="my-1 font-default basis-full job-history w-full"
                placeholder="HQ Location"
                showSearch
                status={editCompanyHandlers?.errors?.location_id ? "error" : ""}
                options={editCompanyHandlers?.referenceData?.locationData?.listLocations?.list?.map(
                  (val: any) => ({
                    label: val.name,
                    value: val.id,
                  }),
                )}
                onChange={(option) => {
                  const { label, value } = option
                  editCompanyHandlers?.onSelectChangeLocation(label, value)
                }}
                labelInValue={true}
                // defaultValue={field.value}
                filterOption={(input: any, option: any) => {
                  if (option && option?.label) {
                    return (
                      option?.value
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    )
                  }
                  return false
                }}
              />
            )}
          />
          <Controller
            name="website"
            control={editCompanyHandlers?.control}
            // rules={{ required: true }}
            render={({ field }) => (
              <Input
                {...field}
                status={editCompanyHandlers?.errors?.website ? "error" : ""}
                className="my-1 font-default rounded basis-1/2"
                placeholder="company website"
                value={field.value}
                addonBefore={<LinkOutlined />}
              />
            )}
          />
          <Controller
            name="short_description"
            control={editCompanyHandlers?.control}
            // rules={{ required: true }}
            render={({ field }) => (
              <Input
                {...field}
                status={
                  editCompanyHandlers?.errors?.short_description ? "error" : ""
                }
                className="my-1 font-default rounded basis-1/2"
                placeholder="short description (255 char max) - will be visible in directory"
                value={field.value}
                maxLength={255}
                max={255}
                showCount={true}
              />
            )}
          />
        </div>
      </div>
      <div className="shadow-custom-sm p-2 md:py-3 md:px-6 rounded-xl text-center">
        <Controller
          name=""
          control={editCompanyHandlers?.control}
          // rules={{ required: true }}
          render={({ field }) => (
            <ImgCrop modalWidth={800} aspect={10 / 10} rotationSlider>
              <Upload
                {...field}
                listType="picture-card"
                fileList={
                  editCompanyHandlers?.uploadImage?.file
                    ? [editCompanyHandlers?.uploadImage?.file]
                    : []
                }
                beforeUpload={editCompanyHandlers?.uploadImage?.beforeUpload}
                onChange={editCompanyHandlers?.uploadImage?.onChange}
                onPreview={editCompanyHandlers?.uploadImage?.onPreview}
                accept="image/*"
                // style={{ width: "10% !important " }}
              >
                {editCompanyHandlers?.uploadImage?.file ? null : (
                  <div className="!h-full !w-full flex items-center flex-col justify-center text-gray-400 bg-white">
                    <PlusOutlined />
                    Upload
                  </div>
                )}
              </Upload>
            </ImgCrop>
          )}
        />

        <Controller
          name="long_description"
          control={editCompanyHandlers?.control}
          // rules={{ required: true }}
          render={({ field }) => (
            <TextArea
              {...field}
              status={
                editCompanyHandlers?.errors?.long_description ? "error" : ""
              }
              className="my-1 font-default rounded basis-1/2"
              placeholder="1-paragraph description (will be visible on your solution page"
              value={field.value}
              draggable={false}
              autoSize={{ minRows: 6, maxRows: 6 }}
            />
          )}
        />
      </div>
    </div>
  )
}

export default CompanySection
