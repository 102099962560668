import { useQuery } from "react-query"
import instance from "../../../../config/config"
import { ApiResponse } from "../../../../common/types/ApiResponse"
import { useParams } from "react-router-dom"
import { JobBoard } from "../../types/jobBoard"
import { useEffect, useState } from "react"
import { useFollowUnFollowCompany } from "../companies/useCompanyFollowUnFollow"
import { useMsal } from "@azure/msal-react"
import { BasicSoftSkill } from "../../types/common"
import { errorMessage } from "../../../../common/utils/utils"

export const useJobBoardDetail = () => {
  const { id } = useParams()
  const { instance: user } = useMsal()
  const activeAccount = user.getActiveAccount()
  const [isFollow, setIsFollow] = useState<boolean>(false)
  const [defaultSoftSkill, setDefaultSoftSkill] = useState<BasicSoftSkill[]>([])

  const { handleFollow, handleUnfollow, loading } = useFollowUnFollowCompany()

  const { data, isLoading, isFetching, isError } = useQuery<
    ApiResponse<JobBoard>
  >(
    ["jobboard-detail", id],
    () =>
      instance.get(
        `job-boards/id/${id}/${activeAccount?.localAccountId || "none"}`,
      ),
    {
      onError: (err: any) =>
        errorMessage(err?.response?.data?.data || "internal server error", err),
    },
  )

  const handleFollowCompany = (val: any) => {
    handleFollow(val).then((res) => {
      if (res) {
        setIsFollow((prev) => !prev)
      }
    })
  }
  const handleUnfollowCompany = (val: any) => {
    handleUnfollow(val).then((res) => {
      if (res) {
        setIsFollow((prev) => !prev)
      }
    })
  }

  useEffect(() => {
    if (data?.data?.data?.job?.critical_skills) {
      setDefaultSoftSkill(
        data?.data?.data?.job?.critical_skills.map((item) => ({
          owner: "Job",
          name: item.name,
          proficiency: (function (val: string): number {
            switch (val) {
              case "Basic":
                return 1
              case "Intermediate":
                return 2
              case "Advanced":
                return 3
              default:
                return 0
            }
          })(item.proficiency.name),
        })),
      )
    }
  }, [data?.data?.data])

  useEffect(() => {
    if (data?.data?.data) setIsFollow(data?.data?.data?.company?.is_favorite)
  }, [data?.data?.data])
  return {
    data: data?.data?.data,
    defaultSoftSkill,
    isLoading: isLoading || isFetching,
    isError,
    isFollow,
    followHandler: {
      handleFollowCompany,
      handleUnfollowCompany,
      loading,
    },
  }
}
