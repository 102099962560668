import { message } from "antd"
import {
  BrowserAuthError,
  InteractionRequiredAuthError,
  AuthError,
} from "@azure/msal-browser"
import clsx from "clsx"
import React from "react"
import ErrorMessageBuilder from "../components/ErrorMessageBuilder"

export type TitleSize = "h1" | "h2" | "h3" | "h4" | "h5" | "h6"

export const getTitleAccordingToSize = (
  titleSize: TitleSize,
  title: string,
  titleRef?: React.RefObject<HTMLDivElement | HTMLHeadingElement>,
  className?: string,
) => {
  switch (titleSize) {
    case "h1":
      return (
        <h1
          ref={titleRef}
          className={clsx("first-letter:uppercase", className)}
        >
          {title}
        </h1>
      )
    case "h2":
      return (
        <h2
          ref={titleRef}
          className={clsx("first-letter:uppercase", className)}
        >
          {title}
        </h2>
      )
    case "h3":
      return (
        <h3
          ref={titleRef}
          className={clsx("first-letter:uppercase", className)}
        >
          {title}
        </h3>
      )
    case "h4":
      return (
        <h4
          ref={titleRef}
          className={clsx("first-letter:uppercase", className)}
        >
          {title}
        </h4>
      )
    case "h5":
      return (
        <h5
          ref={titleRef}
          className={clsx("first-letter:uppercase", className)}
        >
          {title}
        </h5>
      )
    case "h6":
      return (
        <h6
          ref={titleRef}
          className={clsx("first-letter:uppercase", className)}
        >
          {title}
        </h6>
      )
    default:
      return (
        <h2
          ref={titleRef}
          className={clsx("first-letter:uppercase", className)}
        >
          {title}
        </h2>
      )
  }
}

export const errorMessage = (
  messageString: string | undefined,
  error?: any,
  key: string = "error-message",
) => {
  if (
    !(
      error instanceof BrowserAuthError ||
      error instanceof InteractionRequiredAuthError ||
      error instanceof AuthError
    )
  ) {
    message.error({ content: messageString, key })
  }
}

interface ErrorMessageOptions {
  key?: string
  duration?: number
  message: string
  includeReportAt?: boolean
}

export const showError = ({
  key = "default-error-key",
  duration = 5,
  message: errorMessage,
  includeReportAt = true,
}: ErrorMessageOptions) => {
  message.open({
    key,
    duration,
    content: React.createElement(ErrorMessageBuilder, {
      message: errorMessage,
      includeReportAt,
    }),
    type: "error",
  })
}
