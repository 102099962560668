import { Skeleton } from "antd"
import clsx from "clsx"

const AvatarCustomV2 = ({
  onClick,
  className,
  size = 33,
  src,
}: {
  className?: string
  onClick?: () => void
  size?: number
  src?: string
}) => {
  const sizeClass = `h-[${size}px] w-[${size}px]`

  return src ? (
    <div
      style={{ height: size, width: size }}
      className={clsx("rounded-full", className)}
    >
      <img
        onClick={onClick}
        src={src}
        alt="avatar"
        className={clsx(
          "rounded-full object-contain w-full h-full cursor-pointer",
          // sizeClass,
        )}
        style={{ border: "1px solid #D4F5C5" }}
        onError={(e: any) => {
          e.target.onerror = null
          e.target.src = "default-avatar-url" // replace with your default avatar url
        }}
      />
    </div>
  ) : (
    <Skeleton.Avatar
      active
      className={clsx(sizeClass)}
      style={{ height: size, width: size }}
    />
  )
}

export default AvatarCustomV2
