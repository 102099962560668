import {
  LinkedinFilled,
  MediumOutlined,
  TwitterOutlined,
} from "@ant-design/icons"
import { PinMapGray } from "../../../assets/images"
import { Instructor } from "../types/common"

interface Props {
  instructor: Instructor
}

export const TrainerPopoverContent: React.FC<Props> = ({ instructor }) => {
  return (
    <div className=" w-96 ">
      <div className="flex gap-3 items-center mb-5">
        <img
          className="h-16 rounded-full object-contain"
          src={instructor?.avatar}
          alt="avatar"
        />
        <div className="">
          <h4 className="m-0 p-0 first-letter:capitalize">
            {`${instructor?.first_name} ${instructor?.last_name}`}
          </h4>
          <div className="m-0 p-0 first-letter:capitalize">
            {instructor?.title}
          </div>
          <div className="mt-2 p-0 flex items-center gap-1 first-letter:capitalize">
            <PinMapGray />
            {instructor?.country}
          </div>
        </div>
      </div>
      <div className="mb-2 line-clamp-none">{instructor?.about}</div>
      <div className="flex gap-3 justify-end">
        {instructor?.linkedin_url && (
          <a
            target="_blank"
            href={
              instructor?.linkedin_url?.startsWith("http")
                ? instructor?.linkedin_url
                : `http://${instructor?.linkedin_url}`
            }
            rel="noreferrer"
          >
            <LinkedinFilled />
          </a>
        )}
        {instructor?.twitter_url && (
          <a
            target="_blank"
            href={
              instructor?.twitter_url?.startsWith("http")
                ? instructor?.twitter_url
                : `http://${instructor?.twitter_url}`
            }
            rel="noreferrer"
          >
            <TwitterOutlined />
          </a>
        )}
        {instructor?.medium_url && (
          <a
            target="_blank"
            href={
              instructor?.medium_url?.startsWith("http")
                ? instructor?.medium_url
                : `http://${instructor?.medium_url}`
            }
            rel="noreferrer"
          >
            <MediumOutlined />
          </a>
        )}
      </div>
    </div>
  )
}
