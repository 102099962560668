import UserProfilePicture from "../../../../common/components/UserProfilePicture"
import { useNewPostStore } from "../../store/newPostStore"
import { Link } from "react-router-dom"
// import {
//   PictureOutlined,
//   NotificationOutlined,
//   BulbOutlined,
// } from "@ant-design/icons"

const NewCommunityPostV2 = () => {
  const { openModal } = useNewPostStore()

  return (
    // ring-1 ring-gray-3003
    <div className="shadow-sm basic-card bg-white outline-gray-300 outline outline-1">
      <div className="flex gap-4">
        <Link to={`/community/my`}>
          <UserProfilePicture size={50} className="cursor-pointer" />
        </Link>
        <div
          onClick={() => openModal()}
          className="flex-grow flex items-center px-4 py-2 text-base font-medium bg-lightGrey cursor-pointer rounded-xl "
        >
          Start a post, share your green thoughts
        </div>
      </div>
      {/* <div className="flex items-center justify-between px-8 mt-2 ml-10">
        <div
          // onClick={() => openModal("media")}
          className="flex gap-2 px-2 transition-all duration-150 cursor-pointer bg-darkGrey/10 rounded-xl"
        >
          <PictureOutlined className="text-xl" />
          <p className="font-medium ">Media</p>
        </div>
        <div
          // onClick={() => openModal("occasion")}
          className="flex gap-2 px-2 transition-all duration-150 cursor-pointer bg-darkGrey/10 rounded-xl"
        >
          <NotificationOutlined className="text-xl" />
          <p className="font-medium ">Occasion</p>
        </div>
        <div
          onClick={() => openModal("how-tos")}
          className="flex gap-2 px-2 transition-all duration-150 cursor-pointer bg-darkGrey/10 rounded-xl"
        >
          <BulbOutlined className="text-xl" />
          <p className="font-medium ">Event</p>
        </div>
      </div> */}
    </div>
  )
}

export default NewCommunityPostV2
