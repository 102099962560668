import { Button, Progress } from "antd"
import React from "react"
import useCommunityModalsStore from "../../store/useCommunityModalsStore"
import { GreenSkillArticle } from "../apis/experts"
import useExpertProfile from "../hooks/useExpertProfile"
import { Moon } from "../../../../assets/images"

const ExpertSkills = () => {
  const addNewSkillModal = useCommunityModalsStore(
    (state) => state.addNewSkillModal,
  )

  const expertProfileQuery = useExpertProfile()

  const data = transformArticles(
    expertProfileQuery.data?.data?.data?.articles ?? [],
  )

  return (
    <div className="mt-6">
      <div className="flex justify-between items-center">
        <h4 className="!text-xl !font-semibold">Expert Skills</h4>
        <Button
          onClick={() => addNewSkillModal.open()}
          className="bg-primary text-white"
        >
          Request New Domain
        </Button>
      </div>
      <div className="">
        {data?.length > 0 ? (
          <div className="mt-4">
            {data?.map((category) => (
              <div key={category.category_name} className="">
                <h5 className="tracking-widest uppercase text-brown font-normal text-xs">
                  {category.category_name}
                </h5>
                <div className="grid grid-cols-2 gap-4">
                  {category?.green_skills?.length > 0 ? (
                    category?.green_skills?.map((skill) => (
                      <div
                        key={skill?.name}
                        className="col-span-1 flex flex-col justify-center"
                      >
                        <div className="font-medium ">{skill?.name}</div>
                        <Progress
                          className="w-full"
                          percent={((skill?.count ?? 0) / 5) * 100}
                          showInfo={false}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="text-xs text-gray-400 col-span-1 mt-3">
                      You haven't written any articles on this domain.
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center gap-5 text-center p-7">
            <img
              src={Moon}
              className="h-[116px] object-contain aspect-square"
              alt="empty"
            />
            <div className="">
              Start creating expert articles to earns Green Skill proficiency
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

interface Category {
  category_name: string
  green_skills: {
    name: string
    count: number
  }[]
}
function transformArticles(articles: GreenSkillArticle[]): Category[] {
  const categoryMap: { [key: string]: Category } = {}

  articles.forEach((article) => {
    const categoryName = article?.green_skill_category?.name
    if (article?.green_skills && article?.green_skills?.length > 0) {
      if (!categoryMap[categoryName]) {
        categoryMap[categoryName] = {
          category_name: categoryName,
          green_skills: [],
        }
      }

      const category = categoryMap[categoryName]

      article?.green_skills?.forEach((skill) => {
        const skillEntry = category?.green_skills?.find(
          (s) => s?.name === skill?.name,
        )

        if (skillEntry) {
          skillEntry.count++
        } else {
          category?.green_skills?.push({
            name: skill?.name,
            count: 1,
          })
        }
      })
    } else {
      if (!categoryMap[categoryName]) {
        categoryMap[categoryName] = {
          category_name: categoryName,
          green_skills: [],
        }
      }
    }
  })

  return Object.values(categoryMap)
}

export default ExpertSkills
