/* eslint-disable */

import React from "react"
import { TitleSize } from "../../utils/utils.tsx"
import { BasicContainerWithTitle } from "./BasicContainerWithTitle.tsx"
import { Input } from "antd"
import { TextAreaProps } from "antd/es/input/TextArea"

const { TextArea } = Input

interface Props extends TextAreaProps {
  containerTitle: string
  isLoading?: boolean
  titleSize?: TitleSize
  children?: React.ReactNode | React.ReactElement
  containerClassName?: string
  subtitle?: string | React.ReactNode
  showError?: boolean
  isError?: boolean
  errorMessage?: string
}

export const BasicAutoIncreaseTextArea: React.FC<Props> = ({
  containerTitle,
  isLoading,
  children,
  titleSize = "h3",
  containerClassName,
  subtitle,
  showError,
  isError,
  errorMessage,
  ...rest
}) => {
  return (
    <BasicContainerWithTitle
      title={containerTitle}
      isLoading={isLoading}
      titleSize={titleSize}
      className={`mt-6 ${containerClassName}`}
    >
      {subtitle && <div className="mt-4">{subtitle}</div>}
      <TextArea
        className="mt-4 rounded-md"
        placeholder={rest?.placeholder ? rest?.placeholder : "Enter text here"}
        autoSize={{ minRows: 1, maxRows: 6 }}
        {...rest}
      />
      {showError && isError && (
        <div className="text-danger mt-2 text-xs">{errorMessage}</div>
      )}
      {children}
    </BasicContainerWithTitle>
  )
}
