import React from "react"
import { Button, Empty, Image, Skeleton, Typography } from "antd"
import { Breadcrumbs } from "../../../../common/components"
import { CloseCircleOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { DefaultCompanyLogoImg } from "../../../../assets/images"

const { Title } = Typography

interface MyJobRequestContentTopSectionProps {
  breadcrumbsItems: Array<{ path: string; title: React.ReactNode }>
}

export const MyJobRequestContentTopSection: React.FC<
  MyJobRequestContentTopSectionProps
> = ({ breadcrumbsItems }) => {
  const navigate = useNavigate()

  return (
    <div>
      <div>
        <div className="bg-white-custom py-4 px-8">
          <Breadcrumbs items={breadcrumbsItems} />

          <div className="text-backdrop mt-2 mb-4">
            <Title>
              Share details with Recruiters to get Fast Tracked job
              opportunities
            </Title>
          </div>

          <span
            onClick={() => navigate(-1)}
            className="!text-secondary cursor-pointer footnote"
          >
            {"<< back"}
          </span>
        </div>
      </div>
    </div>
  )
}

interface MyJobRequestContentSectionProps {
  listRequest: {
    data: {
      list: Array<{
        company: {
          logo: string
          name: string
          id: string
        }
        recruiter_name: string
        id: string
        request_status: string
      }>
    }
  }
  isLoadingRequest: boolean
  handleResponseRequest: (status: string, id: string) => void
}

export const MyJobRequestContentSection: React.FC<
  MyJobRequestContentSectionProps
> = ({ listRequest, isLoadingRequest, handleResponseRequest }) => {
  const navigate = useNavigate()

  return (
    <>
      <div className="shadow-custom rounded-xl bg-white">
        <div className="rounded-xl bg-white-custom py-5 px-4 flex items-center justify-between">
          <Title level={3}>Recruiters Request</Title>
        </div>
        {isLoadingRequest ? (
          <div className="bg-white-custom px-6 py-3">
            <Skeleton active />
          </div>
        ) : listRequest?.data?.list === null ? (
          <div className="p-6 bg-white-custom rounded-xl">
            <Empty />
          </div>
        ) : (
          <div className="px-6 rounded-xl mb-2 relative">
            {listRequest?.data?.list?.map((item, index) => (
              <div
                className="shadow-custom mb-4 px-3 py-2 rounded-xl flex items-center justify-between"
                key={index}
              >
                <div className="flex items-center gap-4">
                  {item?.company?.logo ? (
                    <Image
                      src={item?.company?.logo ? item?.company?.logo : ""}
                      preview={false}
                      alt={`logo-${item?.company?.name}`}
                      width={50}
                    />
                  ) : (
                    <Image
                      src={DefaultCompanyLogoImg}
                      preview={false}
                      alt={`logo-${item?.company?.name}`}
                      width={50}
                    />
                  )}

                  <div className="space-y-2">
                    <p className="m-0 p-0 text-base capitalize ">
                      Share full details CV with{" "}
                      <span className="font-bold">{item?.recruiter_name}</span>{" "}
                      from{" "}
                      <span
                        className="text-secondary hover:font-bold cursor-pointer"
                        onClick={() =>
                          navigate(`/job/companies/${item?.company?.id}`)
                        }
                      >
                        {item?.company?.name}
                      </span>{" "}
                    </p>
                  </div>
                </div>
                {item?.request_status === "pending" ? (
                  <div className="flex items-center gap-5">
                    <div
                      onClick={() =>
                        handleResponseRequest("rejected", item?.id)
                      }
                      className="cursor-pointer"
                    >
                      <CloseCircleOutlined className="text-xl text-red-400" />
                    </div>
                    <Button
                      onClick={() =>
                        handleResponseRequest("accepted", item?.id)
                      }
                      className={`border-primary`}
                      type="default"
                    >
                      Approve
                    </Button>
                  </div>
                ) : (
                  <p className="">
                    {item?.request_status === "accepted"
                      ? "Accepted"
                      : "Rejected"}
                  </p>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

interface MyJobRequestContentRightSectionProps {
  data: any
}

export const MyJobRequestContentRightSection: React.FC<
  MyJobRequestContentRightSectionProps
> = () => {
  return (
    <div className="bg-white-custom shadow-custom rounded-xl flex flex-col gap-4">
      <div className="py-5 px-5">
        <div className="text-backdrop text-lg font-bold mb-7">
          Your CV is Discoverable
        </div>
        <div className={"bg-white shadow-custom rounded-xl px-2 py-3 mb-5 "}>
          <p>
            Recruiters can see your CV when they search for Talent, but they
            won’t see your personal details, such as your name, profile picture,
            or contact information.
          </p>
        </div>
        <div className={"bg-white shadow-custom rounded-xl px-2 py-3 "}>
          <p>
            When recruiters want to get these details, they will send a
            “Connect” request to you. If you approve the request - they will be
            able to access your Green CV in full and invite you to apply for
            jobs.
          </p>
        </div>
      </div>
    </div>
  )
}
