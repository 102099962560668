import React, { useEffect, useState } from "react"
import CardLoading from "../../expert/components/CardLoading"
import ContentTabExpert from "./ContentTabExpert"
import ContentTabFounderPreview from "./ContentTabFounderPreview"
import ContentTabFounderEditForm from "./ContentTabFounderEditForm"
import {
  useDisplayStateClimateEditForm,
  useTabStoreDetailUserByRole,
} from "../../../../stores"
import ContentTabMentor from "../../mentor/profile/components/ContentTabMentor"
import { MentorArea, MentorDomain } from "../../mentor/profile/types/response"
import { useLocation } from "react-router-dom"

interface DetailByRoleProps {
  data?: any
  editCompanyHandlers?: any
  editProductHandlers?: any
  detailPreview?: any
  showMentor?: boolean
  showConsultant?: boolean

  areaData?: MentorArea[] | undefined
  loadingArea?: boolean

  domainData?: MentorDomain[] | undefined
  loadingDomain?: boolean
  handler?: any
  isMyProfile?: boolean
}

const DetailByRole: React.FC<DetailByRoleProps> = ({
  data,
  editCompanyHandlers,
  editProductHandlers,
  detailPreview,
  showMentor,
  showConsultant,

  areaData,
  loadingArea,
  domainData,
  loadingDomain,
  handler,
  isMyProfile,
}) => {
  const { isDisplayed, toggleDisplay } = useDisplayStateClimateEditForm()
  const { activeTab, setActiveTab } = useTabStoreDetailUserByRole()
  const [showDetailByRole, setShowDetailByRole] = useState(true)

  const location = useLocation()
  const url = location.pathname // Get the current URL path

  useEffect(() => {
    const hasExpert = url.includes("expert")
    const hasFounder = url.includes("founder")
    const hasMentor = url.includes("mentor")

    if (hasExpert) {
      setActiveTab("expert")
    } else if (hasFounder) {
      setActiveTab("founder")
    } else if (hasMentor) {
      setActiveTab("mentor")
    } else {
      setShowDetailByRole(false)
    }
  }, [])

  return (showDetailByRole && data?.listArticleLoading) ||
    loadingArea ||
    loadingDomain ? (
    <div className="space-y-3 mt-4">
      {Array.from({ length: 3 }, (_: any, index: number) => (
        <CardLoading index={index} key={index} />
      ))}
    </div>
  ) : detailPreview?.is_founder ||
    data?.listData?.user_data?.is_expert ||
    showMentor ? (
    <div className="rounded-xl bg-white-custom-sm bg-white-custom g p-4 mt-5  shadow-custom">
      {isDisplayed ? (
        <div className="flex gap-5 !border-none">
          {showConsultant && (
            <div
              className={`rounded-full py-1 px-4 border-2 border-gray-400 cursor-pointer ${
                activeTab === "consultan"
                  ? "bg-secondaryYellow text-[#966F02]"
                  : "bg-white"
              }`}
              style={{ border: "1px solid #36505E" }}
              onClick={() => setActiveTab("consultant")}
            >
              Consultant
            </div>
          )}
          {showMentor && (
            <div
              className={`rounded-full py-1 px-4 border-2 border-gray-400 cursor-pointer ${
                activeTab === "mentor"
                  ? "bg-secondaryYellow text-[#966F02]"
                  : "bg-white"
              }`}
              style={{ border: "1px solid #36505E" }}
              onClick={() => setActiveTab("mentor")}
            >
              Mentor
            </div>
          )}
          {detailPreview?.is_founder && (
            <div
              className={`rounded-full py-1 px-4 border-2 border-gray-400 cursor-pointer ${
                activeTab === "founder"
                  ? "bg-secondaryYellow text-[#966F02]"
                  : "bg-white"
              }`}
              style={{ border: "1px solid #36505E" }}
              onClick={() => setActiveTab("founder")}
            >
              Founder
            </div>
          )}

          {(detailPreview?.is_expert ||
            data?.listData?.user_data?.is_expert) && (
            <div
              className={`rounded-full py-1 px-4 border-2 border-gray-400 cursor-pointer ${
                activeTab === "expert"
                  ? "bg-secondaryYellow text-[#966F02]"
                  : "bg-white"
              }`}
              style={{ border: "1px solid #36505E" }}
              onClick={() => setActiveTab("expert")}
            >
              Expert
            </div>
          )}
        </div>
      ) : null}

      <div className="mt-5">
        {activeTab === "consultant" ? (
          <div>Content of Tab Consultant</div>
        ) : activeTab === "mentor" ? (
          <ContentTabMentor
            data={data}
            areaData={areaData}
            loadingArea={loadingArea}
            domainData={domainData}
            loadingDomain={loadingDomain}
            handler={handler}
            isMyProfile={isMyProfile}
          />
        ) : activeTab === "founder" ? (
          isDisplayed ? (
            <ContentTabFounderPreview
              toggleDisplay={toggleDisplay}
              detailPreview={detailPreview}
            />
          ) : (
            <ContentTabFounderEditForm
              toggleDisplay={toggleDisplay}
              editCompanyHandlers={editCompanyHandlers}
              editProductHandlers={editProductHandlers}
            />
          )
        ) : activeTab === "expert" ? (
          <ContentTabExpert data={data} />
        ) : null}
      </div>
    </div>
  ) : null
}

export default DetailByRole
