import { useContext } from "react"
import { HomeOutlined } from "@ant-design/icons"
import PageSidebar from "../components/PageSidebar"
import { PageContent, PageHeader } from "../../../common/layout"
import { HonorsSection } from "../components"
import { useProfile } from "../hooks/useProfile"
import Orbit from "../components/Orbit"
import OrbitPopover from "../components/OrbitPopover"
import { Link } from "react-router-dom"
import { Skeleton, Table } from "antd"
import { useAchievements } from "../hooks/useAchievements"
import { terraHistCols } from "../utils/terraHistCols"
import {
  ITourContext,
  TourContext,
} from "../../../common/components/tour/TourProvider"

export default function SolarTrophies() {
  const { account, loadingAccount, errorAccount } = useProfile()
  const achievements = useAchievements()

  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "Mission Control",
    },
    {
      title: "Galactic Achievement",
    },
    {
      path: "/dashboard/solar-trophies",
      title: "Solar Trophies",
    },
  ]

  const {
    refs: {
      refGalacticAchievments,
      refEcoCommnitmentHonors,
      refGsSkillsHonors,
      refGreenJobHonors,
      refTerraPointsHistory,
    },
  } = useContext(TourContext) as ITourContext

  const Content = () => {
    return (
      <>
        <div ref={refGalacticAchievments} className="bg-white ">
          <div className="flex items-center flex-wrap sm:flex-nowrap justify-between w-full px-5 py-4 bg-secondaryYellow">
            <h4 className="">My Galactic achievements</h4>
            <div className="text-secondary "></div>
            <div className="">Collect stamps to unlock new levels </div>
          </div>

          {achievements.isLoading ? (
            <div className="grid grid-cols-12 gap-3 px-8 py-4 mt-4 text-backdrop">
              {[...Array(4)].map((_, i) => (
                <div className="col-span-3" key={i}>
                  <Skeleton active loading={true} paragraph={{ rows: 4 }} />
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-wrap items-center sm:justify-between justify-center w-full px-5 py-4 mt-4 gap-y-4">
              {achievements?.data?.data?.data?.orbits?.map((orbit, id) => (
                <OrbitPopover
                  key={id}
                  id={orbit?.id}
                  enabled={!achievements.isLoading && !!achievements.data}
                >
                  <Orbit orbit={orbit} />
                </OrbitPopover>
              ))}
            </div>
          )}
        </div>
        {achievements.data?.data?.data?.eco_commitment_honors && (
          <HonorsSection
            titleRef={refEcoCommnitmentHonors}
            title="Eco Commitment Honors"
            groupCode="eco-commitment-honors"
            data={achievements.data?.data?.data?.eco_commitment_honors}
            isLoading={achievements.isLoading}
          />
        )}
        {achievements.data?.data?.data?.green_skill_honors && (
          <HonorsSection
            titleRef={refGsSkillsHonors}
            title="Green Skills Honors"
            groupCode="green-skills-honors"
            data={achievements.data?.data?.data?.green_skill_honors}
            isLoading={achievements.isLoading}
          />
        )}
        {achievements.data?.data?.data?.green_job_honors && (
          <HonorsSection
            ref={refGreenJobHonors}
            title="Green Jobs Honors"
            groupCode="green-jobs-honors"
            data={achievements.data?.data?.data?.green_job_honors}
            isLoading={achievements.isLoading}
          />
        )}
        <div className="bg-white " ref={refTerraPointsHistory}>
          <div className="flex items-center justify-between w-full px-5 py-4 bg-primary-bg">
            <h4 className="">TerraPoints History</h4>
            <div className="text-secondary "></div>
            <Link
              to="/dashboard/galactic-achievements/recharge-station"
              className="text-secondary"
            >
              How do I earn points?
            </Link>
          </div>

          <Table
            dataSource={achievements?.data?.data?.data?.tera_point_histories}
            pagination={false}
            rowKey="id"
            showHeader={false}
            className={"points-table-hidden-header"}
            columns={terraHistCols}
            bordered
          />
        </div>
      </>
    )
  }

  return (
    <PageContent
      header={
        <PageHeader
          title={`Level Up, ${account?.data?.data?.first_name} ${account?.data?.data?.last_name}!`}
          isHeaderForAccountInfo
          subtitle={undefined}
          className=""
          breadcrumbItems={items}
          isLoading={loadingAccount}
          isError={errorAccount}
        />
      }
      content={<Content />}
      contentRightSidebar={<PageSidebar />}
      rightSidebarRootClassName="flex flex-col gap-4"
    />
  )
}
