import React from "react"
import { HomeOutlined } from "@ant-design/icons"
import { PageContent, PageHeader } from "../../../../common/layout"
import ExpertProfileContent from "../components/ExpertProfileContent"
import { useExpertProfile } from "../hooks/useExpertProfile"
interface ExpertProfileProps {}

const ExpertProfile: React.FC<ExpertProfileProps> = () => {
  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "Expert",
    },
    {
      title: "Profiles",
    },
  ]

  const { editAction, myArticles } = useExpertProfile()

  return (
    <PageContent
      header={<PageHeader breadcrumbItems={items} title="Expert Profile" />}
      content={
        <ExpertProfileContent editAction={editAction} myArticles={myArticles} />
      }
    />
  )
}

export default ExpertProfile
