import { useQuery } from "react-query"
import instance from "../../../../config/config"
import { ApiResponse } from "../../../../common/types/ApiResponse"
import { useLocation } from "react-router-dom"
import { useMsal } from "@azure/msal-react"
import { LevelsData } from "../../../skillGalaxy/types/common"
import { errorMessage } from "../../../../common/utils/utils"

export const useJobRoleSkill = (idJobRole: string) => {
  const location = useLocation()
  const id = location?.state?.id || idJobRole
  const { instance: user } = useMsal()
  const activeAccount = user.getActiveAccount()
  const currencyCode = localStorage.getItem("currency_code")
  const { data, isLoading, isFetching, isError } = useQuery<
    ApiResponse<
      {
        id: string
        level: number
        level_passed: number
        name: string
        detail: LevelsData
      }[]
    >
  >(
    ["job-role-skill", currencyCode],
    () =>
      instance.get(
        `/jobs/skills/${id}/${
          activeAccount?.localAccountId || "none"
        }?currency_code=${currencyCode}`,
      ),
    {
      onError: (err: any) =>
        errorMessage(err?.response?.data?.data || "internal server error", err),
      enabled: !!id,
    },
  )
  return {
    data: data?.data?.data,
    isLoading: isLoading || isFetching,
    isError,
  }
}
