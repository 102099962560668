import React from "react"
import CardSolution from "./CardSolution"
import { Image, Skeleton } from "antd"
import ListSolutionFilter from "./ListSolutionFilter"
import { Moon } from "../../../../assets/images"

interface ListSolutionContentProps {
  startupData?: any
  queryStartup?: any
  referenceData?: any
}

const ListSolutionContent: React.FC<ListSolutionContentProps> = ({
  startupData,
  queryStartup,
  referenceData,
}) => {
  return (
    <div className="space-y-5">
      <div>
        <ListSolutionFilter
          querySolution={queryStartup}
          referenceData={referenceData}
        />
      </div>

      <div className="space-y-4">
        {startupData?.listLoading ? (
          Array.from({ length: 2 }).map((_, index) => (
            <div
              key={index}
              className="grid grid-cols-3 px-5 py-3 rounded-xl shadow-custom-sm bg-white-custom gap-x-6 gap-y-4"
            >
              <div className="flex flex-col items-center w-full col-span-1 gap-4 px-10">
                <Skeleton.Input active size="small" block />
                <Skeleton.Avatar size={70} active />
                <Skeleton active />
              </div>
              <div className="col-span-1">
                <Skeleton active paragraph={{ rows: 7 }} />
              </div>
              <div className="col-span-1">
                <Skeleton active paragraph={{ rows: 7 }} />
              </div>
            </div>
          ))
        ) : startupData?.listData?.length > 0 ? (
          startupData?.listData?.map((startupData: any, index: number) => (
            <CardSolution key={index} startupItem={startupData} />
          ))
        ) : (
          <div className="w-full mx-auto rounded-lg bg-white flex justify-center text-center  py-6 col-span-3">
            <div>
              <Image src={Moon} alt="moon image" preview={false} width={100} />
              <p className="my-4">No Data</p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ListSolutionContent
