import { Button, Empty, Progress, Skeleton, Spin } from "antd"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import {
  BasicContainerWithTitle,
  Breadcrumbs,
} from "../../../common/components"
import SkillProficiencyCard from "../../../common/components/SkillProficiencyCard.tsx"
import InfoSkillCard from "./InfoSkillCard.tsx"
import { Moon } from "../../../assets/images/index.js"

interface SingleMyLaunchpadTopSectionProps {
  breadcrumbsItems: any[]
  imgSrc: string
  passedCount: number
  level_name: string
  skill: any
  isCustomLoading: boolean
  highestCurrentLevel: number
  tabs: any[]
  activeTab: number
  handleChangeTabs: (tab: any, index: number) => void
  userLevel: number | undefined
  isNextLevelExist: boolean
  levels: any
}

interface SingleMyLaunchpadContentSectionProps {
  tabs: any[]
  activeTab: number
  isCustomLoading: boolean
  highestCurrentLevel: number
  handleChangeTabs: (tab: any, index: number) => void
  passedCount: number
  userLevel: number | undefined
}

interface SingleMyLaunchpadRightSectionProps {
  percentPassed: number
  isCustomLoading: boolean
  jobCareerPlan: any
  levels: any
  skill: any
  jobIncludesGS: any
}

const SingleMyLaunchpadTopSection: React.FC<
  SingleMyLaunchpadTopSectionProps
> = ({
  breadcrumbsItems,
  imgSrc,
  passedCount,
  skill,
  isCustomLoading,
  isNextLevelExist,
  level_name,
  levels: dataLevel,
}) => {
  const navigate = useNavigate()

  const allLevel: any =
    dataLevel && dataLevel?.data && dataLevel?.data[0]?.levels
  const companyData: any =
    dataLevel && dataLevel?.data && dataLevel?.data[0]?.school

  interface Trainer {
    id: string
    display_name: string
  }

  const uniqueTrainersMap = new Map<string, Trainer>()
  for (const level of allLevel || []) {
    const instructor = level?.instructor
    if (instructor && !uniqueTrainersMap.has(instructor.id)) {
      uniqueTrainersMap.set(instructor.id, instructor)
    }
  }

  const uniqueTrainers: Trainer[] = Array.from(uniqueTrainersMap.values())

  useEffect(() => {
    if (skill) {
      skill.level = allLevel
    }
  }, [skill, allLevel])

  return (
    <>
      <div className="">
        <div className="bg-white py-1 px-4  lg:py-4 lg:px-8 relative">
          <div className="hidden lg:inline">
            <Breadcrumbs items={breadcrumbsItems} />
          </div>
          <div className="text-backdrop">
            <div className="mt-3">
              <div className="">
                <span
                  onClick={() => navigate(-1)}
                  className="!text-secondary cursor-pointer footnote"
                >
                  {"<< back"}
                </span>
                <div className="flex items-center gap-3 mt-3">
                  {isCustomLoading ? (
                    <Skeleton active paragraph={{ rows: 1 }} />
                  ) : (
                    <h1 className="text-2xl m-0 p-0">
                      {skill?.green_skills?.name}
                    </h1>
                  )}
                </div>

                <div className="inline lg:hidden my-4">
                  <InfoSkillCard
                    isCustomLoading={isCustomLoading}
                    companyData={companyData}
                    uniqueTrainers={uniqueTrainers}
                    dataLevel={dataLevel}
                    skill={skill}
                  />
                </div>

                {isCustomLoading ? (
                  <Skeleton active paragraph={{ rows: 4 }} />
                ) : (
                  <div className={`flex justify-between mt-4`}>
                    <p className="leading-6 p-0 m-0 lg:w-8/12 w-full">
                      {" "}
                      {skill?.green_skills?.description}
                    </p>

                    <div className="col-span-2 lg:flex flex-col items-center justify-end gap-3 hidden">
                      {isCustomLoading ? (
                        <Skeleton.Image active />
                      ) : (
                        <>
                          <SkillProficiencyCard
                            imgSrc={imgSrc}
                            passedLevel={passedCount}
                            levelProgress={
                              skill?.levels[0]?.levels[passedCount]?.user_track
                                ?.is_assessment_passed
                                ? skill?.levels[0]?.levels[passedCount]
                                    ?.user_track?.current_module
                                : skill?.levels[0]?.levels[passedCount]
                                    ?.user_track?.current_module
                            }
                            moduleProgress={
                              (skill?.levels[0]?.levels[
                                isNextLevelExist ? passedCount : passedCount - 1
                              ]?.user_track?.current_module /
                                5) *
                              100
                            }
                            isNextLevelExist={isNextLevelExist}
                            levelName={level_name}
                          />
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const SingleMyLaunchpadContentSection: React.FC<
  SingleMyLaunchpadContentSectionProps
> = ({ tabs, activeTab, isCustomLoading, handleChangeTabs }) => {
  return (
    <div className={`relative`}>
      {/* TABS */}
      <div className="bg-secondaryBlue rounded-2xl lg:flex gap-1 md:gap-3 absolute py-3 w-[100%] md:w-[90%] xl:w-[86%] justify-center left-1/2 transform -translate-x-1/2 hidden">
        {isCustomLoading ? (
          <Spin />
        ) : (
          tabs?.map((item: any, index) => {
            return (
              <div
                key={index}
                className={`px-2 md:px-5 py-2 relative cursor-pointer flex items-center gap-1 md:gap-2 ${
                  index === activeTab
                    ? "bg-white rounded-2xl font-bold text-primary"
                    : "text-backdrop"
                }`}
                onClick={() => {
                  handleChangeTabs(item, index)
                }}
              >
                {item?.icon}
                <p className={`text-backdrop m-0 !capitalize`}>
                  Level {index + 1}
                </p>
              </div>
            )
          })
        )}
      </div>

      {/*CONTENT TAB*/}
      <div className={`lg:mt-7`}>
        <div className="bg-white py-7 lg:px-8 px-4">
          <div className="bg-secondaryBlue rounded-xl md:rounded-2xl lg:hidden flex  justify-center gap-x-3 md:gap-3 py-2 overflow-auto pl-40 md:pl-0">
            {isCustomLoading ? (
              <Spin />
            ) : (
              tabs?.map((item: any, index) => {
                return (
                  <div
                    key={index}
                    className={`px-2 relative cursor-pointer flex items-center gap-1 md:gap-2 ${
                      index === activeTab
                        ? "bg-white rounded-2xl font-bold text-primary"
                        : "text-backdrop tetx-"
                    }`}
                    onClick={() => {
                      handleChangeTabs(item, index)
                    }}
                  >
                    {item?.icon}
                    <p
                      className={`text-backdrop m-0 ${
                        index === activeTab
                          ? "text-primary text-[10px]"
                          : "text-xs"
                      } w-10`}
                    >
                      Level {index + 1}
                    </p>
                  </div>
                )
              })
            )}
          </div>

          {tabs[activeTab]?.component}
        </div>
      </div>
    </div>
  )
}

const SingleMyLaunchpadRightSection: React.FC<
  SingleMyLaunchpadRightSectionProps
> = ({ isCustomLoading, levels: dataLevel, skill, jobIncludesGS }) => {
  const allLevel: any =
    dataLevel && dataLevel?.data && dataLevel?.data[0]?.levels
  const companyData: any =
    dataLevel && dataLevel?.data && dataLevel?.data[0]?.school

  interface Trainer {
    id: string
    display_name: string
  }

  const uniqueTrainersMap = new Map<string, Trainer>()
  for (const level of allLevel || []) {
    const instructor = level?.instructor
    if (instructor && !uniqueTrainersMap.has(instructor.id)) {
      uniqueTrainersMap.set(instructor.id, instructor)
    }
  }

  const uniqueTrainers: Trainer[] = Array.from(uniqueTrainersMap.values())

  useEffect(() => {
    if (skill) {
      skill.level = allLevel
    }
  }, [skill, allLevel])

  const navigate = useNavigate()

  return (
    <>
      <div className={`space-y-8`}>
        <div className="hidden lg:inline">
          <InfoSkillCard
            isCustomLoading={isCustomLoading}
            companyData={companyData}
            uniqueTrainers={uniqueTrainers}
            dataLevel={dataLevel}
            skill={skill}
          />
        </div>

        <BasicContainerWithTitle
          title="Career Aspirations"
          extraTitle={
            <Button
              onClick={() => navigate(`/dashboard/launchpad/cv-builder`)}
              type={`default`}
              className={`border-primary`}
            >
              CV Builder
            </Button>
          }
        >
          <div className="mt-4">
            {jobIncludesGS && jobIncludesGS?.length > 0 ? (
              jobIncludesGS?.map((item: any, index: number) => {
                return (
                  <div
                    className={`px-5 py-2 flex items-center justify-between rounded-lg bg-white mb-2`}
                    style={{ border: "2px solid #D0DDE5" }}
                    key={index}
                  >
                    <p className={`p-0 m-0 truncate w-10/12`}>{item?.name}</p>
                    <Progress
                      type="circle"
                      percent={parseInt(
                        (
                          (item?.total_passed_levels /
                            item?.total_green_skill_levels) *
                          100
                        ).toFixed(),
                      )}
                      size={30}
                    />
                  </div>
                )
              })
            ) : (
              <Empty
                description="No related career aspirations to show"
                image={Moon}
              />
            )}
          </div>
        </BasicContainerWithTitle>
      </div>
    </>
  )
}

export {
  SingleMyLaunchpadContentSection,
  SingleMyLaunchpadRightSection,
  SingleMyLaunchpadTopSection,
}
