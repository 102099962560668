import { useForm, useFieldArray } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation, useQueryClient } from "react-query"
import { message } from "antd"
import {
  MultipleChoiceFormData,
  multipleChoiceSchema,
} from "../components/new-post-forms/schemas"
import {
  IAddCommunityMultipleChoice,
  TPostBody,
  TPostKind,
} from "../types/commonn"
import { useNewPostStore } from "../../store/newPostStore"
import { createCommunityPost } from "../apis/feed-post"
import React from "react"
import ErrorMessageBuilder from "../../../../common/components/ErrorMessageBuilder"

const convertToMultipleChoice = (
  data: MultipleChoiceFormData,
): IAddCommunityMultipleChoice => {
  return {
    multi_choice: {
      question: data.question,
      choices: data.options.map((option) => ({
        is_correct: option.id === data.correctOption,
        name: option.text,
      })),
    },
  }
}

export const useMultipleChoiceForm = () => {
  const { closeModal } = useNewPostStore()
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm<MultipleChoiceFormData>({
    resolver: zodResolver(multipleChoiceSchema),
    defaultValues: {
      question: "",
      options: [
        { id: 1, text: "" },
        { id: 2, text: "" },
      ],
      correctOption: 1,
    },
  })

  const createPost = useMutation(
    ({ post, kind }: { post: TPostBody; kind?: TPostKind }) =>
      createCommunityPost(post, kind),
  )
  const queryClient = useQueryClient()

  const onSubmit = async (data: MultipleChoiceFormData) => {
    message.loading({
      content: "Creating multiple-choice post...",
      key: "create-post",
    })

    await createPost.mutateAsync(
      {
        post: convertToMultipleChoice(data),
        kind: "multiple_choice",
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("community-post-list")
          message.success({
            content: "Post created successfully!",
            key: "create-post",
          })
          closeModal()
        },
        onError: (error: any) => {
          message.open({
            key: "create-event-post",
            duration: 5,
            content: React.createElement(ErrorMessageBuilder, {
              message: `We encountered an issue while attempting to create your multiple choice post. If the problem continues, please refresh the page. Error ${error.response?.data?.message}`,
              includeReportAt: true,
            }),
            type: "error",
          })
        },
      },
    )
  }

  const { fields, append, remove } = useFieldArray({
    control,
    name: "options",
  })

  const removeOption = (index: number) => {
    if (Number(watch("correctOption")) === index + 1) {
      setValue("correctOption", index)
    }
    remove(index)
  }

  return {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isDirty },
    fields,
    append,
    remove,
    removeOption,
    createPost,
    closeModal,
    onSubmit,
  }
}
