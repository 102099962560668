/* eslint-disable */

import { ApiResponse } from "../../../../common/types/ApiResponse.ts"
import { instance, instanceV2 } from "../../../../config/config"
import {
  CourseData,
  GSInterest,
  GSProficient,
  GSRecommendation,
} from "./common_type.ts"
import { useQuery, useQueryClient } from "react-query"
import { useEffect, useState } from "react"
import useFavoriteSkills from "../useFavoriteSkills.tsx"
import { errorMessage } from "../../../../common/utils/utils.tsx"

const fetchGSInterest = () => {
  const response: Promise<ApiResponse<GSInterest[]>> = instanceV2.get(
    "greencv/green-skills/interested",
  )
  return response
}

const fetchGSRecommendation = () => {
  const response: Promise<ApiResponse<GSRecommendation[]>> = instanceV2.get(
    "greencv/green-skills/recommended",
  )
  return response
}

const fetchGSProficient = () => {
  const response: Promise<ApiResponse<GSProficient[]>> = instanceV2.get(
    "greencv/green-skills/proficient",
  )
  return response
}

const fetchGSBulkBody = (IdGreenSkills: any, userId: string) => {
  const response: Promise<ApiResponse<CourseData[]>> = instance.post(
    "/green-skills/levels/bulk/body",
    {
      skills_id: IdGreenSkills?.length === 0 ? null : IdGreenSkills?.join(","),
      user_id: userId,
    },
  )
  return response
}

const useGreenSkill = (userId: string, isLoggedIn: boolean) => {
  const queryClient = useQueryClient()
  const [IdGreenSkills, setIdGreenSkills] = useState<string[]>([])

  const handleFetchError = (error: any) => {
    errorMessage(
      `An error occurred while fetching technical skill: ${error}`,
      error,
    )
  }

  const {
    // data     : gsInterest,
    isLoading: isFetchingGSInterest,
    isError: isFetchErrorGSInterest,
    error: fetchErrorGSInterest,
  } = useQuery(["fetch-greenskill-interest"], () => fetchGSInterest(), {
    refetchOnWindowFocus: false,
    onError: handleFetchError,
  })

  const {
    data: gsProficient,
    isLoading: isFetchingGSProficient,
    isError: isFetchErrorGSProficient,
    error: fetchErrorGSProficient,
  } = useQuery(["fetch-greenskill-proficient"], () => fetchGSProficient(), {
    refetchOnWindowFocus: false,
    onError: handleFetchError,
  })

  const {
    data: gsRecommended,
    isLoading: isFetchingGSRecommended,
    isError: isFetchErrorGSRecommended,
    error: fetchErrorGSRecommended,
  } = useQuery(
    ["fetch-greenskill-recommended"],
    () => fetchGSRecommendation(),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
    },
  )

  useEffect(() => {
    if (gsRecommended) {
      const greenSkillsIds = gsRecommended?.data?.data?.map(
        (skill: any) => skill?.id,
      )
      setIdGreenSkills(greenSkillsIds)
    }
  }, [isFetchingGSRecommended, gsRecommended])

  const {
    data: gsBulkBody,
    isLoading: isFetchingGSBulkBody,
    isError: isFetchErrorGSBulkBody,
    error: fetchErrorGSBulkBody,
  } = useQuery(
    ["fetch-greenskill-bulk-body"],
    () => fetchGSBulkBody(IdGreenSkills, userId),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
      enabled: !isFetchingGSRecommended && IdGreenSkills?.length > 0,
    },
  )

  const { removeSkillFromFavorite, addSkillToFavorite, favoriteSkills } =
    useFavoriteSkills(isLoggedIn)

  const handleAddFavoriteSkill = (id: string) => {
    addSkillToFavorite(id)
    setTimeout(() => {
      queryClient.invalidateQueries(["fetch-greenskill-recommended"])
      queryClient.invalidateQueries(["fetch-greenskill-interest"])
    }, 1000)
  }

  const handleRemoveFavoriteSkill = (id: string) => {
    removeSkillFromFavorite(id)
    setTimeout(() => {
      queryClient.invalidateQueries(["fetch-greenskill-recommended"])
      queryClient.invalidateQueries(["fetch-greenskill-interest"])
    }, 1000)
  }

  return {
    gsInterestResp: {
      gsInterest: favoriteSkills?.data?.data,
      isFetchingGSInterest,
      isFetchErrorGSInterest,
      fetchErrorGSInterest,
    },
    gsProficientResp: {
      gsProficient: gsProficient?.data?.data,
      isFetchingGSProficient,
      isFetchErrorGSProficient,
      fetchErrorGSProficient,
    },
    gsRecommendedResp: {
      gsRecommended: gsRecommended?.data?.data,
      isFetchingGSRecommended,
      isFetchErrorGSRecommended,
      fetchErrorGSRecommended,
    },

    gsBulkBodyResp: {
      gsBulk: gsBulkBody?.data?.data,
      isFetchingGSBulkBody,
      isFetchErrorGSBulkBody,
      fetchErrorGSBulkBody,
    },

    handleAddFavoriteSkill,
    handleRemoveFavoriteSkill,
  }
}

export default useGreenSkill
