import React from "react"
import { BasicContainerWithTitle } from "../../../../common/components"
import { useDiscussionHook } from "../../hooks/useDiscussionHook"
import SingleDiscussion, { SingleDiscussionLoading } from "./SingleDiscussion"
import NewWorkshopDiscussion from "./NewWorkshopDiscussion"
import { Button } from "antd"
interface DiscussionPanelProps {
  item_id: string
}

export const DiscussionPanel: React.FC<DiscussionPanelProps> = ({
  item_id,
}) => {
  const {
    listWrapperRef,
    // firstElementRef,
    dataList,
    listLoading,
    refetchListData,
    LoadMoreData,
    total,
  } = useDiscussionHook(item_id)

  return (
    <BasicContainerWithTitle
      titleSize="h3"
      className="mt-4"
      title={"Discussions"}
    >
      <div
        className="h-72 overflow-y-auto px-1 py-4 space-y-4 w-full"
        ref={listWrapperRef}
      >
        <div className="flex items-start justify-center">
          {total > dataList?.length && (
            <Button
              className="text-center"
              type="text"
              size="small"
              onClick={() => LoadMoreData()}
            >
              Click to Load Previous
            </Button>
          )}
        </div>
        {listLoading
          ? new Array(3)
              .fill(null)
              .map((_, num) => <SingleDiscussionLoading key={num} />)
          : dataList
              ?.slice()
              .sort(
                (a, b) =>
                  new Date(a.created_at).getTime() -
                  new Date(b.created_at).getTime(),
              )
              .map((discussion, idx) => (
                <div key={discussion.id}>
                  <SingleDiscussion
                    isLoading={listLoading}
                    discussion={discussion}
                  />
                </div>
              ))}
      </div>

      <NewWorkshopDiscussion
        item_id={item_id}
        refetchListData={refetchListData}
      />
    </BasicContainerWithTitle>
  )
}
