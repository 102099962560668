/* eslint-disable no-useless-concat */
// import CVSkillContainer from "./CVSkillContainer.tsx";
import { Button, Image, Popover, Skeleton, Spin, Tag } from "antd"
import FiveCircleRateTS from "../../../../../common/components/FiveCircleRateTS.tsx"
import {
  HeartFilled,
  HeartOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons"
import useGreenSkill from "../../../hooks/cv-builder/useGreenSkill.ts"
import { useNavigate } from "react-router-dom"
import { useMsal } from "@azure/msal-react"
import PopContent from "../../../../jobGalaxy/components/PopContent.tsx"
import { LoadingOutlined } from "@ant-design/icons"
import { GreenSkillVerification } from "./GreenSkillVerification.tsx"
import { useSkillVerification } from "../../../hooks/cv-builder/useSkillVerification.ts"

const CVGreenSkill = () => {
  const navigate = useNavigate()
  const { instance, accounts } = useMsal()
  const isLoggedIn = accounts?.length > 0
  const activeAccount = instance.getActiveAccount()

  const {
    gsInterestResp,
    gsProficientResp,
    gsRecommendedResp,
    gsBulkBodyResp,

    handleAddFavoriteSkill,
    handleRemoveFavoriteSkill,
  } = useGreenSkill(activeAccount?.localAccountId!, isLoggedIn)

  const { data, actions } = useSkillVerification()

  return (
    // <CVSkillContainer>
    gsInterestResp.isFetchingGSInterest ||
      gsProficientResp.isFetchingGSProficient ||
      gsRecommendedResp.isFetchingGSRecommended ? (
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </div>
    ) : (
      <div className="grid lg:grid-cols-12 grid-cols-6 gap-5">
        <div className="w-full col-span-6">
          <div className="p-5 shadow-md rounded-xl w-full bg-white sticky top-0">
            <h2>Green Skills</h2>

            <p>Proficient in </p>

            <div>
              {gsProficientResp?.isFetchingGSProficient ? (
                <Skeleton active={true} />
              ) : (
                <div className="mx-4">
                  <table
                    cellSpacing={0}
                    cellPadding={0}
                    className="w-full  overflow-x-auto lg:overflow-hidden"
                    border={0}
                    style={{
                      border: "none !important",
                    }}
                  >
                    <tbody>
                      {gsProficientResp?.gsProficient?.map(
                        (item: any, index: number) => (
                          <tr
                            key={index}
                            className="border-b py-5 hover:bg-gray-50"
                            style={{
                              borderBottom: "1px solid" + " red",
                            }}
                          >
                            <td
                              style={{
                                borderBottom: "solid 1px" + " #F0F0F0",
                              }}
                            >
                              <div className="w-[4.2rem]">
                                <Image
                                  preview={false}
                                  src={item?.image}
                                  alt={"skill-photo"}
                                  width={50}
                                  className={`object-cover`}
                                />
                              </div>
                            </td>
                            <td
                              className={`w-[22rem] xl:w-[30rem] py-2`}
                              style={{
                                borderBottom: "solid 1px" + " #F0F0F0",
                              }}
                            >
                              <p className={`font-bold`}>{item?.name}</p>
                            </td>
                            <td
                              className={`w-20`}
                              style={{
                                borderBottom: "solid 1px" + " #F0F0F0",
                              }}
                            >
                              <div className={`flex gap-7 items-center`}>
                                <FiveCircleRateTS
                                  disabled={true}
                                  defaultValue={item?.total_completed_level}
                                  count={
                                    item?.is_request_verified
                                      ? item?.total_completed_level
                                      : item?.total_purchased_level
                                  }
                                  circleSize={15}
                                  color={
                                    item?.total_completed_level
                                      ? "#fff"
                                      : "#D4F5C5"
                                  }
                                />
                              </div>
                            </td>
                            <td
                              className={`w-`}
                              style={{
                                borderBottom: "solid 1px" + " #F0F0F0",
                              }}
                            >
                              {item?.is_signature_exist &&
                              item?.is_request_verified ? (
                                <Tag
                                  onClick={() =>
                                    navigate("/learnings/preview/certificate", {
                                      state: {
                                        skillData: item,
                                      },
                                    })
                                  }
                                  className={`cursor-pointer  w-24 text-center rounded-full bg-secondary text-white px-4`}
                                  style={{ border: 0 }}
                                >
                                  Verification
                                </Tag>
                              ) : (
                                item?.is_signature_exist &&
                                item?.total_completed_level > 0 && (
                                  <Tag
                                    onClick={() =>
                                      navigate(
                                        "/learnings/preview/certificate",
                                        {
                                          state: {
                                            skillData: item,
                                          },
                                        },
                                      )
                                    }
                                    className={`cursor-pointer  w-24 text-center rounded-full bg-backdrop text-white px-4`}
                                  >
                                    Certificate
                                  </Tag>
                                )
                              )}
                            </td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            <p className={`mt-10`}>Interested in learning</p>
            <div>
              {gsInterestResp?.isFetchingGSInterest ? (
                <Skeleton active={true} />
              ) : (
                gsInterestResp?.gsInterest?.map((item: any, index: number) => {
                  return (
                    <div
                      className={`flex items-center gap-12 md:gap-14 py-2 px-5 hover:bg-gray-50`}
                      key={index}
                      style={{
                        borderBottom: "solid 1px" + " #F0F0F0",
                      }}
                    >
                      <div className={`w-[14px]`}>
                        <Image
                          preview={false}
                          src={item?.detail?.image}
                          alt={"skill-photo"}
                          width={50}
                          className={`object-cover`}
                        />
                      </div>
                      <div>
                        <p className={`font-bold`}>{item?.detail?.name}</p>
                      </div>
                    </div>
                  )
                })
              )}
            </div>
          </div>
        </div>

        <div className="w-[calc(100%-2px)] col-span-6">
          <div className="shadow-md p-5 mb-10 rounded-xl w-full bg-[#FAFAFA]">
            <h3>Green Skills</h3>
            <div className=" my-5 flex justify-between p-3 bg-secondaryBlue items-center rounded-md">
              <div>Verify existing skills</div>
              <Button onClick={() => actions.setIsOpen(true)}>
                Verification
              </Button>
            </div>
            <p>
              When you <b>favourite a skill</b>, it will show in your Interested
              in learning list.
            </p>
            <p>
              You will see skills that you are <b>Proficient in</b>, after
              passing the tests on the levels of Green Skills you buy
            </p>

            <div className={`mt-10`}>
              <p>Recommended (based on your Career Aspirations)</p>
              <div className={`min-h-auto max-h-72 overflow-y-scroll`}>
                {gsRecommendedResp?.isFetchingGSRecommended ? (
                  <Skeleton
                    className={``}
                    paragraph={{ rows: 7 }}
                    active={true}
                  />
                ) : (
                  gsRecommendedResp?.gsRecommended?.map(
                    (item: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className="min-h-[50px] py-4 lg:py-0 bg-[#F0F2F5] flex items-center flex-wrap lg:flex-nowrap justify-between rounded-xl px-5 mb-5 gap-3"
                          style={{
                            border: "1px solid #D0DDE5",
                          }}
                        >
                          <div className="flex items-center gap-6">
                            <div
                              className={`flex flex-wrap lg:flex-nowrap items-center gap-x-4 gap-y-2`}
                            >
                              <FiveCircleRateTS
                                defaultValue={item?.total_required_level}
                                circleSize={16}
                              />
                              <p className={`flex-grow`}>{item?.name}</p>
                            </div>
                          </div>
                          <div className="flex items-center gap-x-2">
                            <div className="cursor-pointer flex items-center  gap-2">
                              {item?.is_favorite ? (
                                <HeartFilled
                                  onClick={() => {
                                    item?.is_favorite
                                      ? handleRemoveFavoriteSkill(item?.id)
                                      : handleAddFavoriteSkill(item?.id)
                                  }}
                                  className="text-xl text-primary hover:drop-shadow"
                                />
                              ) : (
                                <HeartOutlined
                                  onClick={() => {
                                    item?.is_favorite
                                      ? handleRemoveFavoriteSkill(item?.id)
                                      : handleAddFavoriteSkill(item?.id)
                                  }}
                                  className="text-xl hover:text-primary"
                                />
                              )}

                              <Popover
                                placement={`left`}
                                content={
                                  <PopContent
                                    skill={item}
                                    levels={
                                      gsBulkBodyResp?.gsBulk &&
                                      gsBulkBodyResp?.gsBulk?.find(
                                        (itemBulk: any) =>
                                          itemBulk?.id === item?.id,
                                      )?.levels
                                    }
                                    data={
                                      gsBulkBodyResp?.gsBulk &&
                                      gsBulkBodyResp?.gsBulk?.find(
                                        (itemBulk: any) =>
                                          itemBulk?.id === item?.id,
                                      )
                                    }
                                  />
                                }
                                trigger={"hover"}
                                arrow={false}
                              >
                                <ShoppingCartOutlined className="text-xl hover:text-primary" />
                              </Popover>
                            </div>
                          </div>
                        </div>
                      )
                    },
                  )
                )}
              </div>
            </div>
            <div className={`!mt-12`}>
              <Button
                block={true}
                type={"primary"}
                onClick={() => navigate(`/skill/green-skills`)}
              >
                Browse all Green Skills
              </Button>
            </div>
          </div>
        </div>
        <GreenSkillVerification
          data={data}
          actions={actions}
          gsProficient={gsProficientResp.gsProficient}
        />
      </div>
    )
    // </CVSkillContainer>
  )
}

export default CVGreenSkill
