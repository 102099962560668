import React from "react"
import { Select } from "antd"

const { Option } = Select

interface Timezone {
  zone: string
  gmt: string
  name: string
}

interface TimezoneSelectProps {
  value: string
  onChange: (value: string) => void
  disabled?: boolean
}

const TimezoneSelect: React.FC<TimezoneSelectProps> = ({
  value,
  onChange,
  disabled,
}) => {
  return (
    <div>
      <Select
        // style={{ width: 200 }}
        disabled={disabled}
        className="w-full"
        placeholder="Select a timezone"
        showSearch
        value={value}
        onChange={onChange}
        filterSort={(optionA, optionB) =>
          (optionA?.zone ?? "")
            .toLowerCase()
            .localeCompare((optionB?.zone ?? "").toLowerCase())
        }
      >
        {timezones.map((timezone, index) => (
          <Option key={index} value={timezone.zone}>
            {`${timezone.name} ${timezone.gmt}`}
          </Option>
        ))}
      </Select>
    </div>
  )
}

export default TimezoneSelect

const timezones: Timezone[] = [
  {
    zone: "Pacific/Midway",
    gmt: "(GMT-11:00)",
    name: "Midway Island",
  },
  {
    zone: "US/Samoa",
    gmt: "(GMT-11:00)",
    name: "Samoa",
  },
  {
    zone: "US/Hawaii",
    gmt: "(GMT-10:00)",
    name: "Hawaii",
  },
  {
    zone: "US/Alaska",
    gmt: "(GMT-09:00)",
    name: "Alaska",
  },
  {
    zone: "US/Pacific",
    gmt: "(GMT-08:00)",
    name: "Pacific Time (US &amp; Canada)",
  },
  {
    zone: "America/Tijuana",
    gmt: "(GMT-08:00)",
    name: "Tijuana",
  },
  {
    zone: "US/Arizona",
    gmt: "(GMT-07:00)",
    name: "Arizona",
  },
  {
    zone: "US/Mountain",
    gmt: "(GMT-07:00)",
    name: "Mountain Time (US &amp; Canada)",
  },
  {
    zone: "America/Chihuahua",
    gmt: "(GMT-07:00)",
    name: "Chihuahua",
  },
  {
    zone: "America/Mazatlan",
    gmt: "(GMT-07:00)",
    name: "Mazatlan",
  },
  {
    zone: "America/Mexico_City",
    gmt: "(GMT-06:00)",
    name: "Mexico City",
  },
  {
    zone: "America/Monterrey",
    gmt: "(GMT-06:00)",
    name: "Monterrey",
  },
  {
    zone: "Canada/Saskatchewan",
    gmt: "(GMT-06:00)",
    name: "Saskatchewan",
  },
  {
    zone: "US/Central",
    gmt: "(GMT-06:00)",
    name: "Central Time (US &amp; Canada)",
  },
  {
    zone: "US/Eastern",
    gmt: "(GMT-05:00)",
    name: "Eastern Time (US &amp; Canada)",
  },
  {
    zone: "US/East-Indiana",
    gmt: "(GMT-05:00)",
    name: "Indiana (East)",
  },
  {
    zone: "America/Bogota",
    gmt: "(GMT-05:00)",
    name: "Bogota",
  },
  {
    zone: "America/Lima",
    gmt: "(GMT-05:00)",
    name: "Lima",
  },
  {
    zone: "America/Caracas",
    gmt: "(GMT-04:30)",
    name: "Caracas",
  },
  {
    zone: "Canada/Atlantic",
    gmt: "(GMT-04:00)",
    name: "Atlantic Time (Canada)",
  },
  {
    zone: "America/La_Paz",
    gmt: "(GMT-04:00)",
    name: "La_Paz",
  },
  {
    zone: "America/Santiago",
    gmt: "(GMT-04:00)",
    name: "Santiago",
  },
  {
    zone: "Canada/Newfoundland",
    gmt: "(GMT-03:30)",
    name: "Newfoundland",
  },
  {
    zone: "America/Buenos_Aires",
    gmt: "(GMT-03:00)",
    name: "Buenos Aires",
  },
  {
    zone: "Greenland",
    gmt: "(GMT-03:00)",
    name: "Greenland",
  },
  {
    zone: "Atlantic/Stanley",
    gmt: "(GMT-02:00)",
    name: "Stanley",
  },
  {
    zone: "Atlantic/Azores",
    gmt: "(GMT-01:00)",
    name: "Azores",
  },
  {
    zone: "Atlantic/Cape_Verde",
    gmt: "(GMT-01:00)",
    name: "Cape Verde Is.",
  },
  {
    zone: "Africa/Casablanca",
    gmt: "(GMT)",
    name: "Casablanca",
  },
  {
    zone: "Europe/Dublin",
    gmt: "(GMT)",
    name: "Dublin",
  },
  {
    zone: "Europe/Lisbon",
    gmt: "(GMT)",
    name: "Libson",
  },
  {
    zone: "Europe/London",
    gmt: "(GMT)",
    name: "London",
  },
  {
    zone: "Africa/Monrovia",
    gmt: "(GMT)",
    name: "Monrovia",
  },
  {
    zone: "Europe/Amsterdam",
    gmt: "(GMT+01:00)",
    name: "Amsterdam",
  },
  {
    zone: "Europe/Belgrade",
    gmt: "(GMT+01:00)",
    name: "Belgrade",
  },
  {
    zone: "Europe/Berlin",
    gmt: "(GMT+01:00)",
    name: "Berlin",
  },
  {
    zone: "Europe/Bratislava",
    gmt: "(GMT+01:00)",
    name: "Bratislava",
  },
  {
    zone: "Europe/Brussels",
    gmt: "(GMT+01:00)",
    name: "Brussels",
  },
  {
    zone: "Europe/Budapest",
    gmt: "(GMT+01:00)",
    name: "Budapest",
  },
  {
    zone: "Europe/Copenhagen",
    gmt: "(GMT+01:00)",
    name: "Copenhagen",
  },
  {
    zone: "Europe/Ljubljana",
    gmt: "(GMT+01:00)",
    name: "Ljubljana",
  },
  {
    zone: "Europe/Madrid",
    gmt: "(GMT+01:00)",
    name: "Madrid",
  },
  {
    zone: "Europe/Paris",
    gmt: "(GMT+01:00)",
    name: "Paris",
  },
  {
    zone: "Europe/Prague",
    gmt: "(GMT+01:00)",
    name: "Prague",
  },
  {
    zone: "Europe/Rome",
    gmt: "(GMT+01:00)",
    name: "Rome",
  },
  {
    zone: "Europe/Sarajevo",
    gmt: "(GMT+01:00)",
    name: "Sarajevo",
  },
  {
    zone: "Europe/Skopje",
    gmt: "(GMT+01:00)",
    name: "Skopje",
  },
  {
    zone: "Europe/Stockholm",
    gmt: "(GMT+01:00)",
    name: "Stockholm",
  },
  {
    zone: "Europe/Vienna",
    gmt: "(GMT+01:00)",
    name: "Vienna",
  },
  {
    zone: "Europe/Warsaw",
    gmt: "(GMT+01:00)",
    name: "Warsaw",
  },
  {
    zone: "Europe/Zagreb",
    gmt: "(GMT+01:00)",
    name: "Zagreb",
  },
  {
    zone: "Europe/Athens",
    gmt: "(GMT+02:00)",
    name: "Athens",
  },
  {
    zone: "Europe/Bucharest",
    gmt: "(GMT+02:00)",
    name: "Bucharest",
  },
  {
    zone: "Africa/Cairo",
    gmt: "(GMT+02:00)",
    name: "Cairo",
  },
  {
    zone: "Africa/Harare",
    gmt: "(GMT+02:00)",
    name: "Harere",
  },
  {
    zone: "Europe/Helsinki",
    gmt: "(GMT+02:00)",
    name: "Helsinki",
  },
  {
    zone: "Europe/Istanbul",
    gmt: "(GMT+02:00)",
    name: "Istanbul",
  },
  {
    zone: "Asia/Jerusalem",
    gmt: "(GMT+02:00)",
    name: "Jerusalem",
  },
  {
    zone: "Europe/Kiev",
    gmt: "(GMT+02:00)",
    name: "Kiev",
  },
  {
    zone: "Europe/Minsk",
    gmt: "(GMT+02:00)",
    name: "Minsk",
  },
  {
    zone: "Europe/Riga",
    gmt: "(GMT+02:00)",
    name: "Riga",
  },
  {
    zone: "Europe/Sofia",
    gmt: "(GMT+02:00)",
    name: "Sofia",
  },
  {
    zone: "Europe/Tallinn",
    gmt: "(GMT+02:00)",
    name: "Tallinn",
  },
  {
    zone: "Europe/Vilnius",
    gmt: "(GMT+02:00)",
    name: "Vilnius",
  },
  {
    zone: "Asia/Baghdad",
    gmt: "(GMT+03:00)",
    name: "Baghdad",
  },
  {
    zone: "Asia/Kuwait",
    gmt: "(GMT+03:00)",
    name: "Kuwait",
  },
  {
    zone: "Africa/Nairobi",
    gmt: "(GMT+03:00)",
    name: "Nairobi",
  },
  {
    zone: "Asia/Riyadh",
    gmt: "(GMT+03:00)",
    name: "Riyadh",
  },
  {
    zone: "Asia/Tehran",
    gmt: "(GMT+03:30)",
    name: "Tehran",
  },
  {
    zone: "Europe/Moscow",
    gmt: "(GMT+04:00)",
    name: "Moscow",
  },
  {
    zone: "Asia/Baku",
    gmt: "(GMT+04:00)",
    name: "Baku",
  },
  {
    zone: "Europe/Volgograd",
    gmt: "(GMT+04:00)",
    name: "Volgograd",
  },
  {
    zone: "Asia/Muscat",
    gmt: "(GMT+04:00)",
    name: "Muscat",
  },
  {
    zone: "Asia/Tbilisi",
    gmt: "(GMT+04:00)",
    name: "Tbilisi",
  },
  {
    zone: "Asia/Yerevan",
    gmt: "(GMT+04:00)",
    name: "Yerevan",
  },
  {
    zone: "Asia/Kabul",
    gmt: "(GMT+04:30)",
    name: "Kabul",
  },
  {
    zone: "Asia/Karachi",
    gmt: "(GMT+05:00)",
    name: "Karachi",
  },
  {
    zone: "Asia/Tashkent",
    gmt: "(GMT+05:00)",
    name: "Tashkent",
  },
  {
    zone: "Asia/Kolkata",
    gmt: "(GMT+05:30)",
    name: "Kolkata",
  },
  {
    zone: "Asia/Kathmandu",
    gmt: "(GMT+05:45)",
    name: "Kathmandu",
  },
  {
    zone: "Asia/Yekaterinburg",
    gmt: "(GMT+06:00)",
    name: "Yekaterinburg",
  },
  {
    zone: "Asia/Almaty",
    gmt: "(GMT+06:00)",
    name: "Almaty",
  },
  {
    zone: "Asia/Dhaka",
    gmt: "(GMT+06:00)",
    name: "Dhaka",
  },
  {
    zone: "Asia/Novosibirsk",
    gmt: "(GMT+07:00)",
    name: "Novosibirsk",
  },
  {
    zone: "Asia/Bangkok",
    gmt: "(GMT+07:00)",
    name: "Bangkok",
  },
  {
    zone: "Asia/Jakarta",
    gmt: "(GMT+07:00)",
    name: "Jakarta",
  },
  {
    zone: "Asia/Krasnoyarsk",
    gmt: "(GMT+08:00)",
    name: "Krasnoyarsk",
  },
  {
    zone: "Asia/Chongqing",
    gmt: "(GMT+08:00)",
    name: "Chongqing",
  },
  {
    zone: "Asia/Hong_Kong",
    gmt: "(GMT+08:00)",
    name: "Hong Kong",
  },
  {
    zone: "Asia/Kuala_Lumpur",
    gmt: "(GMT+08:00)",
    name: "Kuala Lumpur",
  },
  {
    zone: "Australia/Perth",
    gmt: "(GMT+08:00)",
    name: "Perth",
  },
  {
    zone: "Asia/Singapore",
    gmt: "(GMT+08:00)",
    name: "Singapore",
  },
  {
    zone: "Asia/Taipei",
    gmt: "(GMT+08:00)",
    name: "Taipei",
  },
  {
    zone: "Asia/Ulaanbaatar",
    gmt: "(GMT+08:00)",
    name: "Ulaan Bataar",
  },
  {
    zone: "Asia/Urumqi",
    gmt: "(GMT+08:00)",
    name: "Urumqi",
  },
  {
    zone: "Asia/Irkutsk",
    gmt: "(GMT+09:00)",
    name: "Irkutsk",
  },
  {
    zone: "Asia/Seoul",
    gmt: "(GMT+09:00)",
    name: "Seoul",
  },
  {
    zone: "Asia/Tokyo",
    gmt: "(GMT+09:00)",
    name: "Tokyo",
  },
  {
    zone: "Australia/Adelaide",
    gmt: "(GMT+09:30)",
    name: "Adelaide",
  },
  {
    zone: "Australia/Darwin",
    gmt: "(GMT+09:30)",
    name: "Darwin",
  },
  {
    zone: "Asia/Yakutsk",
    gmt: "(GMT+10:00)",
    name: "Yakutsk",
  },
  {
    zone: "Australia/Brisbane",
    gmt: "(GMT+10:00)",
    name: "Brisbane",
  },
  {
    zone: "Australia/Canberra",
    gmt: "(GMT+10:00)",
    name: "Canberra",
  },
  {
    zone: "Pacific/Guam",
    gmt: "(GMT+10:00)",
    name: "Guam",
  },
  {
    zone: "Australia/Hobart",
    gmt: "(GMT+10:00)",
    name: "Hobart",
  },
  {
    zone: "Australia/Melbourne",
    gmt: "(GMT+10:00)",
    name: "Melbourne",
  },
  {
    zone: "Pacific/Port_Moresby",
    gmt: "(GMT+10:00)",
    name: "Port Moresby",
  },
  {
    zone: "Australia/Sydney",
    gmt: "(GMT+10:00)",
    name: "Sydney",
  },
  {
    zone: "Asia/Vladivostok",
    gmt: "(GMT+11:00)",
    name: "Vladivostok",
  },
  {
    zone: "Asia/Magadan",
    gmt: "(GMT+12:00)",
    name: "Magadan",
  },
  {
    zone: "Pacific/Auckland",
    gmt: "(GMT+12:00)",
    name: "Auckland",
  },
  {
    zone: "Pacific/Fiji",
    gmt: "(GMT+12:00)",
    name: "Fiji",
  },
]
