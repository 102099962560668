/* eslint-disable */

import { useQuery } from "react-query"
import { instanceV2 } from "../../../config/config"
import { Item } from "../../skillGalaxy/types/common"
import { ApiResponse } from "../../../common/types/ApiResponse"
import { errorMessage } from "../../../common/utils/utils"

export type Trajectory = {
  id: string
  name: string
  image: string
  total_levels: number
  total_available_levels: number
  total_purchased_levels: number
  total_completed_levels: number
  current_level_progress: number
  learning_progress: number
  description: string
  group: Item
}

const fetchTrajectory = async (): Promise<ApiResponse<Trajectory[]>> => {
  return instanceV2.get("/launchpad/trajectories")
}

export const useTrajectory = () => {
  const handleFetchError = (error: any) => {
    errorMessage(`An error occurred while fetching trajectory: ${error}`, error)
  }
  return useQuery(["trajectory"], fetchTrajectory, {
    refetchOnWindowFocus: false,
    onError: handleFetchError,
  })
}
