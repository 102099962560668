import React from "react"
import { Button, Empty, Popover, Progress, Skeleton } from "antd"
import { useNavigate } from "react-router-dom"
import FiveCircleRateTS from "../../../common/components/FiveCircleRateTS.tsx"
import { CloseOutlined } from "@ant-design/icons"

interface CareerProps {
  greencareerResp: any
}

const CareerAspirationComponent: React.FC<CareerProps> = ({
  greencareerResp,
}) => {
  const navigate = useNavigate()

  if (
    !greencareerResp?.isFetchingGreenCareer &&
    (!greencareerResp?.greencareers ||
      greencareerResp?.greencareers.length === 0)
  ) {
    return <Empty description="No career aspirations to show" />
  }

  const courseToGreenifyContent = (item: any) => {
    return (
      <div className={`text-white w-96`}>
        <p className={`m-0 text-white mb-2`}>Courses to Greenify the Job:</p>
        {item?.green_skills?.map((skill: any, index: number) => (
          <div className={`flex items-center gap-2`} key={index}>
            <div className={`w-64`}>
              <p className={`text-[#919191] m-0 truncate`}>{skill?.name}</p>
            </div>
            <FiveCircleRateTS
              defaultValue={skill?.total_required_level}
              disabled
              circleSize={17}
            />
          </div>
        ))}
      </div>
    )
  }

  return greencareerResp?.isFetchingGreenCareer ? (
    <div className={`rounded-xl mb-3 px-4 py-4 mx-5 bg-white`}>
      <Skeleton active={true} />
    </div>
  ) : (
    greencareerResp?.greencareers?.map((item: any, index: number) => {
      return (
        <div
          key={index}
          className={`rounded-xl mb-3 px-4 py-4 mx-5 bg-white ${
            item?.total_green_skills > 0 && "cursor-pointer"
          }`}
          style={{ border: "2px solid #D0DDE5" }}
          onClick={() => {
            if (item?.total_green_skills > 0) {
              navigate(`/job/roles/${item?.slug}`, {
                state: { id: item?.id },
              })
            }
          }}
        >
          <div
            className={`flex items-center flex-wrap md:flex-nowrap gap-y-4 justify-between`}
          >
            <div className={`flex items-center gap-5 w-8/12`}>
              <p
                className={`bg-primary-bg text-center w-5 h-5 rounded-full m-0`}
              >
                {index + 1}
              </p>
              <p className={`truncate m-0`}>{item?.name}</p>
            </div>
            <div className={`flex items-center gap-6`}>
              {item?.total_green_skills > 0 && (
                <>
                  <Popover
                    placement={"bottom"}
                    content={
                      <p className={`m-0 text-white`}>
                        Green Skills for this job
                      </p>
                    }
                    color={"#36505E"}
                  >
                    <p
                      className={`cursor-pointer bg-secondaryYellow text-center w-7 h-6 text-[#966F02] font-bold rounded m-0`}
                    >
                      {item?.total_green_skills}
                    </p>
                  </Popover>
                  <Popover
                    placement={"bottom"}
                    content={courseToGreenifyContent(item)}
                    color={"#36505E"}
                  >
                    <p
                      className={`cursor-pointer bg-secondaryYellow text-center w-7 h-6 text-[#966F02] font-bold rounded m-0`}
                    >
                      {item?.total_green_skill_levels}
                    </p>
                  </Popover>
                  <Popover
                    placement={"bottom"}
                    content={
                      <p className={`m-0 text-white`}>
                        Courses you passed to
                        <br />
                        Greenify the Job
                      </p>
                    }
                    color={"#36505E"}
                  >
                    <p
                      className={`cursor-pointer bg-secondaryYellow text-center w-7 h-6 text-[#966F02] font-bold rounded m-0`}
                    >
                      {item?.green_skills?.reduce(
                        (acc: any, cur: any) =>
                          acc +
                          (cur?.total_completed_level >
                          cur?.total_required_level
                            ? cur?.total_required_level
                            : cur?.total_completed_level),
                        0,
                      )}
                    </p>
                  </Popover>
                </>
              )}

              {index > 0 ? (
                item?.is_from_user && !item.green_skill ? (
                  <CloseOutlined
                    className={`text-red-500 w-8 text-xl justify-center`}
                  />
                ) : (
                  <Progress
                    type="circle"
                    strokeColor={
                      Math.round(
                        (item?.green_skills?.reduce(
                          (acc: any, cur: any) =>
                            acc +
                            (cur?.total_completed_level >
                            cur?.total_required_level
                              ? cur?.total_required_level
                              : cur?.total_completed_level),
                          0,
                        ) /
                          item?.total_green_skill_levels) *
                          100,
                      ) === 100
                        ? "86C769"
                        : "#FAC61D"
                    }
                    percent={
                      Math.round(
                        (item?.green_skills?.reduce(
                          (acc: any, cur: any) =>
                            acc +
                            (cur?.total_completed_level >
                            cur?.total_required_level
                              ? cur?.total_required_level
                              : cur?.total_completed_level),
                          0,
                        ) /
                          item?.total_green_skill_levels) *
                          100,
                      )
                      //   parseInt(
                      //   (
                      //     (item?.total_passed_levels /
                      //       item?.total_green_skill_levels) *
                      //     100
                      //   ).toFixed()
                      // )
                    }
                    size={30}
                  />
                )
              ) : null}
            </div>
          </div>
          {/*DESCRIPTIONS*/}
          {index === 0 ? (
            <div className={`flex justify-between mt-4`}>
              <div className={`w-9/12`}>
                <p className={`line-clamp-5 m-0`}>{item?.description}</p>
              </div>
              <div className={`flex flex-col items-center gap-3`}>
                {
                  <Progress
                    strokeColor={
                      Math.round(
                        (item?.green_skills?.reduce(
                          (acc: any, cur: any) =>
                            acc +
                            (cur?.total_completed_level >
                            cur?.total_required_level
                              ? cur?.total_required_level
                              : cur?.total_completed_level),
                          0,
                        ) /
                          item?.total_green_skill_levels) *
                          100,
                      ) === 100
                        ? "86C769"
                        : "#FAC61D"
                    }
                    type="circle"
                    percent={parseInt(
                      (
                        (item?.total_passed_levels /
                          item?.total_green_skill_levels) *
                        100
                      ).toFixed(),
                    )}
                    size={70}
                  />
                }
                <Button
                  type={`primary`}
                  onClick={() =>
                    navigate(`/job/roles/${item?.slug}`, {
                      state: { id: item?.id },
                    })
                  }
                >
                  Job Role Details
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      )
    })
  )
}

export default CareerAspirationComponent
