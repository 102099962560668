/* eslint-disable */
import { instance, instanceV2 } from "../../../config/config"
import { useQuery } from "react-query"
import qs from "qs"
import { ApiResponse } from "../../../common/types/ApiResponse"
import { Skill, LevelsData } from "../types/common"

const fetchGreenSkills = (params: GetGSParams) => {
  const { categories, search, filter_price_range, ...queryParams } = params
  const removedSearchIfNull = search ? { ...queryParams, search } : queryParams
  const removedFilterPriceRangeIfNull = filter_price_range
    ? { ...removedSearchIfNull, filter_price_range }
    : removedSearchIfNull
  if (categories === undefined) {
    return instanceV2.get("green-skills", {
      params: removedFilterPriceRangeIfNull,
      paramsSerializer: () => {
        return qs.stringify(removedFilterPriceRangeIfNull)
      },
    })
  }
  const categoriesQuery = encodeURIComponent(categories?.join(","))
  return instanceV2.get(`green-skills?categories=${categoriesQuery}`, {
    // use paramsSerializer to format queryParams
    params: removedFilterPriceRangeIfNull,
    paramsSerializer: () => {
      return qs.stringify(removedFilterPriceRangeIfNull)
    },
  })
}

const fetchTotalSkills = () => {
  return instanceV2.get("green-skills")
}

const fetchSingleGreenSkill = (id: string): Promise<ApiResponse<Skill>> => {
  return instance.get(`green-skills/${id}?with=related`)
}

const fetchGreenSkillsBubbles = () => {
  return instance.get("green-skills/bubbles")
}

const fetchGreenSkillsLevels = (
  id: string,
  userId = "none",
  currency_code = "sgp",
): Promise<ApiResponse<LevelsData>> => {
  return instance.get(
    `green-skills/levels/detail/${id}/${userId}?currency_code=${currency_code}`,
  )
}
const fetchJobRequiredSkill = (userId: string, params: object) => {
  const { ...queryParams } = params
  return instance.get(`jobs/${userId}`, {
    params: queryParams,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

type GetGSParams = {
  categories?: string[]
  search?: string
  filter_price_range?: string
  limit?: number
  page?: number
  sort_rank?: string
  filter_availability?: string
  talent_id?: string
  currency_code?: string
}

export const useGreenSkills = (params: GetGSParams = {}) => {
  return useQuery(
    ["fetch-green-skills", params],
    () => fetchGreenSkills(params),
    { refetchOnWindowFocus: false },
  )
}

export const useGetSingleSkill = (id: string) => {
  return useQuery(
    ["fetch-single-green-skill", id],
    () => fetchSingleGreenSkill(id),
    { refetchOnWindowFocus: false },
  )
}

export const useGreenSkillSectors = (params = {}, enabled = true) => {
  return useQuery(
    ["fetch-green-skill-sectors", params],
    () => fetchGreenSkillsBubbles(),
    { refetchOnWindowFocus: false, enabled },
  )
}

export const useGreenSkillLevels = (id: string, userId: string) => {
  const currency_code = localStorage.getItem("currency_code") ?? "sgd"
  return useQuery(
    ["fetch-green-skill-levels", id, userId, currency_code],
    () => fetchGreenSkillsLevels(id, userId, currency_code),
    { refetchOnWindowFocus: false, retry: 5, retryDelay: 1000 },
  )
}

export const useJobRequiredSkill = (id: string, params = {}) => {
  return useQuery(
    ["fetch-job-required-skill", id, params],
    () => fetchJobRequiredSkill(id, params),
    { refetchOnWindowFocus: false },
  )
}

export const useGetTotalGreenSkills = () => {
  return useQuery(["fetch-total-green-skills"], fetchTotalSkills, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })
}
