import React from "react"
import { Button } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { useJobRoleSkill } from "../../hooks/jobRoles/useJobRoleSkill"
import { useJobRoleCart } from "../../hooks/jobRoles/useJobRoleCart"
import { formatNumber } from "../../../../utils/formatNumber"

export const DetailFullCart: React.FC<{ id: string }> = ({ id }) => {
  const { data: dataSkill } = useJobRoleSkill(id)
  const { requiredLevels, isLoading, handlePurchased } =
    useJobRoleCart(dataSkill)
  return (
    <div>
      <h3 className=" font-semibold">Greenify this Job Role</h3>
      <p className="my-1">Add all missing Green Skills to Cart</p>
      <div className="flex justify-between my-5">
        <h4>Total:</h4>
        <h4>
          {isLoading ? (
            <LoadingOutlined />
          ) : (
            <div>
              <span className=" uppercase">
                {localStorage.getItem("currency_code") || "sgd"}
              </span>{" "}
              <span>
                {formatNumber(
                  requiredLevels.reduce(
                    (a, b) => a + b.price_per_level * (b.rate || 0),
                    0,
                  ),
                )}
              </span>
            </div>
          )}
        </h4>
      </div>
      <Button
        type="primary"
        className="w-full mb-3"
        onClick={() => {
          if (requiredLevels.length > 0) {
            handlePurchased()
          }
        }}
        loading={isLoading}
      >
        Get missing Green Skills
      </Button>
    </div>
  )
}
