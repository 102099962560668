import React from "react"
import { PoweroffOutlined, MenuOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { Dropdown } from "antd"

interface Props {
  active: { key: string; label: string }
  handleSelectMenu: (item: { key: string; label: string }) => void
  menu: { key: string; label: string }[]
}

export const Header: React.FC<Props> = ({ active, handleSelectMenu, menu }) => {
  const navigate = useNavigate()
  return (
    <div>
      <div className=" bg-[#E2EBFA] h-16 fixed z-10 top-0 left-0 w-full flex justify-between items-center px-5 shadow-[0_1px_7px_0_rgba(0,0,0,0.3)]">
        <div className="flex items-center gap-6">
          <h1 className=" font-bold text-sm md:text-[1.5rem]">
            Green CV Builder
          </h1>
          <Dropdown
            className="md:hidden capitalize"
            menu={{
              items: menu.map((item) => {
                return {
                  key: item.key,
                  label: item.label,
                  onClick: () => handleSelectMenu(item),
                }
              }),
              selectedKeys: [active.key],
              selectable: true,
            }}
          >
            <MenuOutlined className="cursor-pointer hover:scale-110 duration-300 " />
          </Dropdown>
        </div>
        <div className="hidden md:flex md:gap-2 lg:gap-8">
          {menu.map((item) => (
            <div
              onClick={() => handleSelectMenu(item)}
              key={item.key}
              className={`py-1 px-2 rounded-xl cursor-pointer capitalize ${
                item.key === active.key ? "bg-secondaryYellow" : "bg-white"
              }`}
              style={
                item.key === active.key
                  ? { border: "1px solid #966F02" }
                  : { border: "1px solid #36505E" }
              }
            >
              {item.label}
            </div>
          ))}
        </div>
        <div
          className="flex gap-3 items-center text-white bg-backdrop py-2 px-4 rounded-sm cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <PoweroffOutlined />
          <div className="hidden lg:block">Exit CV Builder</div>
        </div>
      </div>
    </div>
  )
}
