import { NotLaunched } from "../../../../assets/images"
import { ExpandNotificationData, NotifyData } from "../../types/notifs"
import { Image } from "antd"

interface FooterProps {
  notificationData: NotifyData<string>
  parsedNotificationData: ExpandNotificationData
}
const boldTextInNotifDescription = "New mission!"
const boldTextInNotifDescription2 = "Your mission!"

export const OrbitFooter: React.FC<FooterProps> = ({
  notificationData,
  parsedNotificationData,
}) => {
  let boldedText = ""

  if (notificationData?.description?.includes(boldTextInNotifDescription)) {
    boldedText = boldTextInNotifDescription
  }

  if (notificationData?.description?.includes(boldTextInNotifDescription2)) {
    boldedText = boldTextInNotifDescription2
  }
  return (
    <>
      <div className="text-[12px] text-center">
        <span className="font-semibold">{boldedText}</span>
        {notificationData?.description
          ?.replace(boldTextInNotifDescription, "")
          ?.replace(boldTextInNotifDescription2, "")}
      </div>
      <div className="flex justify-center items-center flex-row gap-2 mt-4">
        {parsedNotificationData?.honors?.map((honor, index) => (
          <Image
            key={honor?.id ?? index}
            preview={false}
            width={55}
            src={honor?.image_url ?? NotLaunched}
            alt={honor?.id}
            className={`object-contain ${
              honor?.is_earned ? "opacity-100" : "opacity-50"
            }`}
          />
        ))}
      </div>
    </>
  )
}
