import { ApiResponseGeneric } from "../../../../common/types/ApiResponse"
import instance from "../../../../config/config"
import { ExpertResponse } from "../../../home/types/response"
import { Item } from "../../../skillGalaxy/types/common"

export interface GreenSkillArticle {
  id: string
  user_id: string
  green_skills: Item[]
  green_skill_category: Item
  status: "publish" | "draft"
}

export interface Expert extends ExpertResponse {
  knowledge_domain: Item[]
  total_follower: number
  articles: GreenSkillArticle[]
  is_expert: boolean
}

export const fetchExpertDetails = (id: string, queryParams?: any) => {
  return instance.get<ApiResponseGeneric<Expert>>(`user-experts/all/${id}`, {
    params: queryParams,
  })
}
