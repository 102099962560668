import {
  Astroseed,
  GreenNebula,
  SolarExplorer,
  StartMaster,
  Teraformer,
} from "../../../assets/images"
import { Image, Tooltip } from "antd"
import React from "react"

export function renderLevelImage(level: any) {
  let imageSrc
  switch (level.proficiency) {
    case 1:
      imageSrc = Astroseed
      break
    case 2:
      imageSrc = SolarExplorer
      break
    case 3:
      imageSrc = Teraformer
      break
    case 4:
      imageSrc = GreenNebula
      break
    case 5:
      imageSrc = StartMaster
      break
    default:
      imageSrc = "N/A"
  }

  return (
    <div
      className={`rounded-xl w-12 h-12 bg-secondaryYellow p-2 drop-shadow-xl`}
    >
      {imageSrc !== "N/A" ? (
        <Tooltip title={`level ${level?.proficiency}`}>
          <Image src={imageSrc} alt={`level-icon`} width={30} preview={false} />
        </Tooltip>
      ) : (
        "N/A"
      )}
    </div>
  )
}
