import { Select } from "antd"
import React from "react"
import {
  FounderResponse,
  UserProfileResponse,
} from "../../founder/types/response"
import FounderItem from "./FounderItem"

interface FounderSectionProps {
  editCompanyHandlers: any
}

const FounderSection: React.FC<FounderSectionProps> = ({
  editCompanyHandlers,
}) => {
  const founderIds = new Set(
    editCompanyHandlers?.founderData?.listData?.map(
      (founder: FounderResponse) => founder?.id,
    ),
  )

  const userNotFounders = editCompanyHandlers?.referenceData?.listUser?.filter(
    (item: UserProfileResponse) => !founderIds.has(item?.id),
  )

  return (
    <div className="shadow-custom-sm p-2 md:py-3 md:px-6 rounded-xl">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="col-span-1 space-y-3">
          <p className="m-0">You can only invite existing Skilledin users</p>
          <Select
            className="my-1 font-default basis-full job-history w-full"
            placeholder="Search for a user"
            showSearch
            onSelect={(value: any) => {
              editCompanyHandlers?.handleSelectInviteFounder(value)
            }}
            status={editCompanyHandlers?.errors?.stage ? "error" : ""}
            options={userNotFounders?.map((val: any) => ({
              label: `${val?.first_name} ${val?.last_name}`,
              value: val?.id,
            }))}
            labelInValue={true}
            filterOption={(input: any, option: any) => {
              if (option && option.label) {
                return (
                  option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                )
              }
              return false
            }}
          />

          <p className="m-0">
            Once invited, they will have the same edit access as you do.
          </p>
        </div>
        <div
          className="col-span-2 px-6 py-3 bg-white rounded-xl"
          style={{ border: "2px solid #D0DDE5" }}
        >
          <h3 className="text-center">Founding Team</h3>
          <div
            className={`grid ${
              editCompanyHandlers?.founderEditData?.length === 1
                ? "grid-cols-1"
                : editCompanyHandlers?.founderEditData?.length === 2
                  ? "grid-cols-2"
                  : "grid-cols-3"
            }`}
          >
            {editCompanyHandlers?.founderEditData?.map(
              (founder: any, index: number) => {
                return <FounderItem key={index} founder={founder} />
              },
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FounderSection
