import { NotLaunched } from "../../../../assets/images"
import { Badge, NotifyData } from "../../types/notifs"
import { Image } from "antd"

interface ContentProps {
  notificationData: NotifyData<string>
  data: Badge | undefined
}

export const OrbitContent: React.FC<ContentProps> = ({
  notificationData,
  data,
}) => (
  <div className="flex flex-col items-center">
    <div className="text-center text-[18px] font-semibold">
      {notificationData?.title}
    </div>

    <Image
      src={data?.image_url ?? NotLaunched}
      width={96}
      preview={false}
      className="mt-5"
    />

    <div className="text-center text-[14px] mt-5">
      {data?.title} : <span className="font-semibold">{data?.subtitle}</span>
    </div>
  </div>
)
