import React from "react"
import { Route, Routes } from "react-router-dom"
// import { SkillsPage } from "./pages/SkillsPage";
import { loader as skillLoader } from "./pages/SingleSkill"
import { useQueryClient } from "react-query"
import ListPublicArticles from "./pages/ListPublicArticles"
import SinglePublicArticle from "./pages/SinglePublicArticle"
import NotFound from "../auth/page/NotFound"
// const SkillsPage = React.lazy(() => import("./pages/SkillsPage"))
import SkillsPage from "./pages/SkillsPage"
// const SingleSkill = React.lazy(() => import("./pages/SingleSkill"))
import SingleSkill from "./pages/SingleSkill"
// const Workshops = React.lazy(() => import("./pages/Workshops"))
import Workshops from "./pages/Workshops"
// const SingleWorkshop = React.lazy(() => import("./pages/SingleWorkshop"))
import SingleWorkshop from "./pages/SingleWorkshop"
// const ListShools = React.lazy(() =>
//   import("./pages/v2/ListSchools").then((module) => ({
//     default: module.ListSchool,
//   })),
// )
import { ListSchool as ListShools } from "./pages/v2/ListSchools.tsx"
// const SingleSchool = React.lazy(() => import("./pages/SingleSchool"))
import SingleSchool from "./pages/SingleSchool"
const Index = () => {
  const queryClient = useQueryClient()
  return (
    <Routes>
      <Route path="/green-skills" element={<SkillsPage />} />
      <Route
        path="/green-skills/:greenSkillId"
        element={<SingleSkill />}
        loader={skillLoader(queryClient)}
      />
      <Route path="/schools" element={<ListShools />} />
      <Route path="/workshops" element={<Workshops />} />
      <Route path="/workshops/:item_id" element={<SingleWorkshop />} />
      <Route
        path="/schools/:schoolId"
        element={<SingleSchool />}
        loader={skillLoader(queryClient)}
      />

      <Route path="/articles" element={<ListPublicArticles />} />
      <Route
        path="/articles/:slug"
        element={<SinglePublicArticle />}
        loader={skillLoader(queryClient)}
      />

      {/* note! each route must be contain this route error to handle error after parent route*/}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default Index
