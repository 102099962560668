import { create } from "zustand"
import { ICommunityComment } from "../feed/types/commonn"

type ModalState = {
  isOpen: boolean
  comment: ICommunityComment | null
  openModal: (comment?: ICommunityComment) => void
  closeModal: () => void
}

export const updateCommentStore = create<ModalState>((set) => ({
  isOpen: false,
  type: null,
  comment: null,
  openModal: (comment) => set(() => ({ isOpen: true, comment })),
  closeModal: () => set(() => ({ isOpen: false, comment: null })),
}))
