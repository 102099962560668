import React, { useContext } from "react"
import {
  LinkedinFilled,
  TwitterOutlined,
  LinkOutlined,
  EditOutlined,
} from "@ant-design/icons"
import { AccountContext } from "../../../../common/layout/PageLayout"
import { openInNewTab } from "../../../../utils/util_funcs"
import clsx from "clsx"
import useCommunityModalsStore from "../../store/useCommunityModalsStore"
import { fetchMentorDetails } from "../apis/mentors"
import { useQuery } from "react-query"

const MentorBio = () => {
  const account = useContext(AccountContext)
  const accountData = account?.data?.data
  const editMentorBioModal = useCommunityModalsStore(
    (state) => state.editMentorBioModal,
  )
  const mentorDetails = useQuery(["mentor-details", accountData?.id], () =>
    fetchMentorDetails(accountData?.id ?? ""),
  )
  const mentorDetailsData = mentorDetails?.data?.data?.data
  return (
    <div className="mt-6">
      <div className="flex justify-between items-start">
        <div className="flex flex-col gap-2">
          <div className="text-xl font-semibold">My Founder Bio</div>
          <div className="flex gap-3 items-center">
            <LinkedinFilled
              onClick={
                accountData?.linkedin
                  ? () => openInNewTab(accountData?.linkedin)
                  : undefined
              }
              className={clsx(
                "text-lg rounded-full p-1 ring-backdrop ring-1 ",
                {
                  "cursor-not-allowed": !accountData?.linkedin,
                  "hover:scale-105 duration-300 transition-all":
                    accountData?.linkedin,
                  "text-darkGrey": !accountData?.linkedin,
                  "cursor-pointer": accountData?.linkedin,
                },
              )}
            />
            <TwitterOutlined
              onClick={
                accountData?.twitter
                  ? () => openInNewTab(accountData?.twitter)
                  : undefined
              }
              className={clsx(
                "text-lg rounded-full p-1 ring-backdrop ring-1 ",
                {
                  "cursor-not-allowed": !accountData?.twitter,
                  "hover:scale-105 duration-300 transition-all":
                    accountData?.twitter,
                  "text-darkGrey": !accountData?.twitter,
                  "cursor-pointer": accountData?.twitter,
                },
              )}
            />
            <LinkOutlined
              onClick={
                accountData?.link
                  ? () => openInNewTab(accountData?.link)
                  : undefined
              }
              className={clsx(
                "text-lg rounded-full p-1 ring-backdrop ring-1 ",
                {
                  "cursor-not-allowed": !accountData?.link,
                  "hover:scale-105 duration-300 transition-all":
                    accountData?.link,
                  "text-darkGrey": !accountData?.link,
                  "cursor-pointer": accountData?.link,
                },
              )}
            />
          </div>
        </div>
        <EditOutlined
          onClick={() => editMentorBioModal.open()}
          className="text-xl cursor-pointer hover:scale-105 duration-300 transition-all"
        />
      </div>
      <h4 className="font-semibold !text-xl mt-4">About Me</h4>
      {mentorDetailsData?.about_me ? (
        <div
          dangerouslySetInnerHTML={{
            __html: mentorDetailsData?.about_me,
          }}
        />
      ) : (
        <p className="text-darkGrey">
          You haven't added an expert bio yet. Press edit to add your bio.
        </p>
      )}
    </div>
  )
}

export default MentorBio
