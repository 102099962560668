import { create } from "zustand"

type ViewState = {
  isOpen: boolean
  showEdit: () => void
  closeEdit: () => void
}

export const useProfileView = create<ViewState>((set) => ({
  isOpen: false,
  showEdit: () => set(() => ({ isOpen: true })),
  closeEdit: () => set(() => ({ isOpen: false })),
}))
