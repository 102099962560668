import React from "react"
import { useMutation, useQueryClient } from "react-query"
import { addCommentToDiscussion } from "../apis/this-weeks-post"
import { message } from "antd"
import ErrorMessageBuilder from "../../../../common/components/ErrorMessageBuilder"
import { AddComment } from "./AddComment"

const AddCommentTwd = ({ discussion_id }: { discussion_id: string }) => {
  const addComment = useMutation(addCommentToDiscussion)
  const queryClient = useQueryClient()

  const onSubmit = async (data: any) => {
    message.loading({
      content: "Commenting on Discussion....",
      key: "create-comment",
    })
    await addComment.mutateAsync(
      {
        discussion_id: discussion_id ?? "",
        comment: data,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([
            "community-comment-list",
            discussion_id,
          ])
          message.success({
            content: "Commented!",
            key: "create-comment",
          })
        },
        onError: () => {
          message.open({
            key: "create-comment",
            duration: 5,
            content: React.createElement(ErrorMessageBuilder, {
              message:
                "We encountered an issue while attempting to create this comment. If the problem continues, please refresh the page.",
              includeReportAt: true,
            }),
            type: "error",
          })
        },
      },
    )
  }
  return (
    <AddComment
      isSubmissionLoading={addComment.isLoading}
      submittedSuccess={addComment.isSuccess}
      onSubmit={onSubmit}
    />
  )
}

export default AddCommentTwd
