import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { PublicClientApplication, EventType } from "@azure/msal-browser"
import { msalConfig } from "./authConfig"
import { BrowserRouter } from "react-router-dom"
import instance, { instanceV2 } from "./config/config"
import { message } from "antd"

export const msalInstance = new PublicClientApplication(msalConfig)

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_FAILURE) {
    message
      .error({
        content:
          "Account not found, you will automatically direct into register page...",
        duration: 2,
      })
      .then(() => {
        window.location.href = "/register"
      })
  }
  if (
    (event?.eventType === EventType.LOGIN_SUCCESS ||
      event?.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event?.eventType === EventType.SSO_SILENT_SUCCESS) &&
    //  @ts-ignore
    event?.payload?.account
  ) {
    instance.defaults.headers.common["Authorization"] =
      //  @ts-ignore
      `Bearer ${event?.payload?.idToken}`
    instanceV2.defaults.headers.common["Authorization"] =
      //  @ts-ignore
      `Bearer ${event?.payload?.idToken}`
    //  @ts-ignore
    msalInstance.setActiveAccount(event?.payload?.account)

    const handleRecordLogsLogin = () =>
      instance.post("/accounts/login-logs", {
        //  @ts-ignore
        user_id: event?.payload?.account.localAccountId,
      })
    handleRecordLogsLogin()
  }
  if (
    event.eventType === EventType.LOGIN_SUCCESS &&
    event?.payload &&
    //  @ts-ignore
    event?.payload?.idTokenClaims?.newUser
  ) {
    message.success({
      icon: <span className="mr-1">🎉</span>,
      content:
        "Welcome newbie to our green skills universe. Your journey is going to be a long one, our job is to make it fun",
      duration: 10,
    })
    const handleRegist = () => instance.get("/accounts")
    handleRegist()
    if (
      sessionStorage?.getItem("newUser") === "false" ||
      sessionStorage?.getItem("newUser") === undefined
    ) {
      sessionStorage?.setItem("newUser", "true")
      if (
        sessionStorage?.getItem("showNewUserCommunityPost") === "false" ||
        sessionStorage?.getItem("showNewUserCommunityPost") === undefined
      ) {
        sessionStorage?.setItem("showNewUserCommunityPost", "true")
      }
    }
  } else {
    sessionStorage?.setItem("newUser", "false")
  }
})
//  @ts-ignore
const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App instance={msalInstance} />
  </BrowserRouter>,
  // {/* </React.StrictMode> */}
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
