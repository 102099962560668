/* eslint-disable */

import { useContext, useEffect, useState } from "react"
import { AccountContext } from "../../../common/layout/PageLayout"
import { Modal, message } from "antd"
import { useMutation, useQueryClient } from "react-query"
import instance from "../../../config/config"
import { useModalStore } from "../../../stores"
import { Level, LevelsData } from "../../skillGalaxy/types/common"

export type FinalLevel = Level & {
  id: string
  proficiency: number
  description: string[]
  disabled: boolean
  checked: boolean
  price_per_level: number
  sharing_profit: number
  skill_name: string
  owner_id: string
  owner_stripe_id: string
  currency_code: string
  rate: number
}

export type AddFunctionParam = {
  user_id: string
  product_owner_id: string
  product_owner_id_stripe: string
  product_id: string
  product_name: string
  product_id_stripe: string | undefined
  price_id_stripe: string | undefined
  price: number
  sharing_profit: number
  image_url: string
  proficient: number
}

export const useSingleSkillCart = (
  levels: Level[],
  skill: any,
  data: LevelsData,
) => {
  const account = useContext(AccountContext)
  const openModal = useModalStore((state) => state.openModal)
  const queryClient = useQueryClient()
  const [finalLevels, setFinalLevels] = useState<FinalLevel[]>([])

  useEffect(() => {
    if (levels?.length) {
      const a = levels?.map((v) => ({
        ...v,
        disabled: v.status_transaction !== 0 || !v.available_for_sale,
        checked: v.proficiency <= skill?.level && v.available_for_sale,
        price_per_level: data?.price_per_level,
        sharing_profit: data?.school?.sharing_profit,
        skill_name: skill?.name,
        owner_id: data?.school?.id,
        owner_stripe_id: data?.school?.stripe_account_id,
        currency_code: data?.currency?.code,
        rate: data?.currency?.exchange,
      }))
      setFinalLevels(a)
    }
  }, [levels, data?.price_per_level])

  const handleCheck = (id: string) => {
    setFinalLevels(
      (prev: any) =>
        prev?.map((item: any) => {
          if (item?.id === id) {
            return { ...item, checked: !item.checked }
          } else {
            return item
          }
        }),
    )
  }

  const { mutate: add, isLoading } = useMutation({
    mutationFn: (val: AddFunctionParam[]) => {
      return instance.post(`transactions/carts`, val)
    },
    mutationKey: "add-cart",
  })

  const handlePurchased = () => {
    if (!account?.data?.data) return openModal()

    let wantBuy = finalLevels?.filter(
      (item) => item.checked === true && item.status_transaction === 0,
    )
    let lvNeeded = finalLevels.filter((a) => {
      if (a.status_transaction === 0) {
        return !wantBuy.some((b) => b.proficiency <= a.proficiency)
      } else {
        return false
      }
    })

    const handleProcess = (products: FinalLevel[]) => {
      message.open({
        key: "adding-to-cart",
        content: "adding to cart...",
        type: "loading",
      })
      const finalData = products?.map((item) => ({
        user_id: account?.data?.data?.id ?? "",
        product_owner_id: item?.owner_id,
        product_owner_id_stripe: item?.owner_stripe_id,
        product_id: item.id,
        product_name: item?.skill_name,
        product_id_stripe: item?.stripe?.product_id,
        price_id_stripe: item?.stripe?.price_id,
        price: item?.price_per_level,
        sharing_profit: item?.sharing_profit,
        image_url: item?.image_url,
        proficient: item?.proficiency,
      }))
      add(finalData, {
        onSuccess: async () => {
          await queryClient.invalidateQueries(["bulk-skill-levels"])
          await queryClient.invalidateQueries(["carts"])
          message.open({
            key: "adding-to-cart",
            content: "success add to cart",
            type: "success",
          })
        },
        onError: (err: any) => {
          message.open({
            key: "adding-to-cart",
            content: err?.response?.data?.data,
            type: "success",
          })
        },
      })
    }

    if (lvNeeded.length > 0) {
      Modal.confirm({
        centered: true,
        icon: null,
        className: "square-modal",
        content: (
          <div className="mb-5">
            <div>
              You won't be able to access this level course, unless you pass all
              previous levels of the skill. Add previous levels to cart to
              proceed.
            </div>
          </div>
        ),
        cancelText: "Cancel",
        cancelButtonProps: {
          className: " rounded-none text-darkGrey",
          danger: true,
        },
        okText: "Add previous levels",
        okButtonProps: {
          className: "rounded-none bg-primary hover:bg-[#85bd6c]  text-white",
        },
        onOk: () => {
          wantBuy = [...lvNeeded, ...wantBuy]
          handleProcess(wantBuy)
        },
      })
    } else {
      handleProcess(wantBuy)
    }
  }

  return { finalLevels, handleCheck, handlePurchased, isLoading }
}
