// eslint-disable-next-line no-unused-vars
import { UseQueryResult, useQuery } from "react-query"
// eslint-disable-next-line no-unused-vars
import { ApiResponse2Generic } from "../../../common/types/ApiResponse"
// eslint-disable-next-line no-unused-vars
import { AxiosError, AxiosResponse } from "axios"
import instance from "../../../config/config"
import { message } from "antd"

export const QUERYPRIVACYPOLICYNAME = "privacy-policy"

export const useFetchPrivacyPolicy = (
  queryParam: PrivacyPolicyParams,
): UseQueryResult<ApiResponse2Generic<PrivacyResponse>, AxiosError> => {
  const fetchData = async () => {
    try {
      const response: AxiosResponse<ApiResponse2Generic<PrivacyResponse>> =
        await instance.get(`policies/talent/${queryParam.query}`)
      return response?.data
    } catch (error: any) {
      message.error(
        `An error occurred while fetching ${QUERYPRIVACYPOLICYNAME}: + ${error.message}`,
      )
      throw error
    }
  }

  const queryResult = useQuery([QUERYPRIVACYPOLICYNAME], fetchData, {
    onError: (error: AxiosError) => {
      message.error(
        `An error occurred while fetching ${QUERYPRIVACYPOLICYNAME}: + ${error.message}`,
      )
    },
  })

  return queryResult
}
