import { Image, Tag } from "antd"
import React from "react"
import { Moon } from "../../assets/images"

interface Props {
  label?: string
  icon?: string
  backgroundColor?: string
  color?: string
}
export const ComingSoon: React.FC<Props> = ({
  label = "Coming Soon",
  icon,
  backgroundColor,
  color,
}) => {
  return (
    <Tag
      className={`space-x-3 border-none rounded-full py-[6px] px-4 bg-backdrop text-white`}
      style={{ backgroundColor, color }}
    >
      <Image width={22} preview={false} src={icon ?? Moon} alt={`moon-image`} />
      <span className="ml-3 font-bold text-[14px] leading-4 mt-2">{label}</span>
    </Tag>
  )
}
