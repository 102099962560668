import React from "react"
import { Checkbox, Select } from "antd"

interface FilterSidebarProps {
  filterHandler: any
  filterResp: any
  from: string
}

const FilterSidebar: React.FC<FilterSidebarProps> = ({
  filterHandler,
  filterResp,
  from,
}) => {
  return (
    <div className="bg-white-custom px-5 py-6 top-4 sticky shadow-md rounded-xl">
      <div className="space-y-4">
        <div className="rounded-xl bg-white shadow-custom-sm p-5 overflow-hidden">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-base font-semibold p-0 m-0">Location</p>
              <div className="mt-1">
                <Checkbox
                  onChange={(e: any) => filterHandler?.handleFilterByRemote(e)}
                >
                  Remote only
                </Checkbox>
              </div>
            </div>
            <div className="bg-grey-select w-1/2">
              <Select
                className="w-full job-history bg-gray-400 rounded-md text-backdrop"
                loading={filterResp?.isLoadingCountries}
                filterOption={true}
                labelInValue
                options={filterResp?.optionLocation}
                placeholder="Select"
                onChange={filterHandler?.handleFilterByHQ}
              />
            </div>
          </div>
          {filterHandler?.selectedHQ?.length > 0 && (
            <div className="mt-6 ">
              <div className="mb-2 flex flex-wrap gap-3 items-centerbg-red-300">
                {filterHandler?.selectedHQ?.map((gs: any, index: number) => (
                  <div
                    color="white"
                    className="text-backdrop shadow-custom rounded-md py-1 px-2"
                    style={{ color: "#36505E" }}
                    key={index}
                  >
                    {gs?.name}{" "}
                    <span
                      className="cursor-pointer hover:text-red-400 px-1"
                      onClick={() => filterHandler?.removeSelectedHQ(gs?.id)}
                    >
                      X
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {from === "jobBoard" && (
          <div className="rounded-xl bg-white shadow-custom-sm p-5 overflow-hidden">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-base font-semibold p-0 m-0">
                  Company Sector
                </p>
                <p className="text-xs p-0 m-0">select up to 5</p>
              </div>
              <div className="bg-grey-select w-1/2">
                <Select
                  className="w-full job-history bg-gray-400 rounded-md text-backdrop"
                  // loading={filterResp?.isLoadingGreenSkills}
                  filterOption={true}
                  labelInValue
                  options={filterResp?.optionSectors}
                  placeholder="Select"
                  onChange={filterHandler?.handleFilterBySector}
                />
              </div>
            </div>
            {filterHandler?.selectedSectors?.length > 0 && (
              <div className="mt-6 ">
                <div className="mb-2 flex flex-wrap gap-3 items-centerbg-red-300">
                  {filterHandler?.selectedSectors?.map(
                    (gs: any, index: number) => (
                      <div
                        color="white"
                        className="text-backdrop shadow-custom rounded-md py-1 px-2"
                        style={{ color: "#36505E" }}
                        key={index}
                      >
                        {gs?.name}{" "}
                        <span
                          className="cursor-pointer hover:text-red-400 px-1"
                          onClick={() =>
                            filterHandler?.removeSelectedSector(gs?.id)
                          }
                        >
                          X
                        </span>
                      </div>
                    ),
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        <div className="rounded-xl bg-white shadow-custom-sm p-5 overflow-hidden">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-base font-semibold p-0 m-0">Team/Function</p>
            </div>
            <div className="bg-grey-select w-1/2">
              <Select
                className="w-full job-history bg-gray-400 rounded-md text-backdrop"
                // loading={filterResp?.isLoadingGreenSkills}
                filterOption={true}
                labelInValue
                options={filterResp?.optionTeams}
                placeholder="Select"
                onChange={filterHandler?.handleFilterByTeam}
              />
            </div>
          </div>
          {filterHandler?.selectedTeams?.length > 0 && (
            <div className="mt-6 ">
              <div className="mb-2 flex flex-wrap gap-3 items-centerbg-red-300">
                {filterHandler?.selectedTeams?.map(
                  (team: any, index: number) => (
                    <div
                      color="white"
                      className="text-backdrop shadow-custom rounded-md py-1 px-2"
                      style={{ color: "#36505E" }}
                      key={index}
                    >
                      {team?.name}{" "}
                      <span
                        className="cursor-pointer hover:text-red-400 px-1"
                        onClick={() =>
                          filterHandler?.removeSelectedTeam(team?.id)
                        }
                      >
                        X
                      </span>
                    </div>
                  ),
                )}
              </div>
            </div>
          )}
        </div>

        <div className="rounded-xl bg-white shadow-custom-sm p-5 overflow-hidden">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-base font-semibold p-0 m-0">Job Type</p>
            </div>
            <div className="bg-grey-select w-1/2">
              <Select
                className="w-full job-history bg-gray-400 rounded-md text-backdrop"
                loading={filterResp?.isListJobTypesLoading}
                filterOption={true}
                labelInValue
                options={filterResp?.optionTypes}
                placeholder="Select"
                onChange={filterHandler?.handleFilterByType}
              />
            </div>
          </div>
          {filterHandler?.selectedTypes?.length > 0 && (
            <div className="mt-6 ">
              <div className="mb-2 flex flex-wrap gap-3 items-centerbg-red-300">
                {filterHandler?.selectedTypes?.map(
                  (type: any, index: number) => (
                    <div
                      color="white"
                      className="text-backdrop shadow-custom rounded-md py-1 px-2"
                      style={{ color: "#36505E" }}
                      key={index}
                    >
                      {type?.name}{" "}
                      <span
                        className="cursor-pointer hover:text-red-400 px-1"
                        onClick={() =>
                          filterHandler?.removeSelectedType(type?.id)
                        }
                      >
                        X
                      </span>
                    </div>
                  ),
                )}
              </div>
            </div>
          )}
        </div>

        <div className="rounded-xl bg-white shadow-custom-sm p-5 overflow-hidden">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-base font-semibold p-0 m-0">Green Skills</p>
            </div>
            <div className="bg-grey-select w-1/2">
              <Select
                className="w-full job-history bg-gray-400 rounded-md text-backdrop"
                // loading={filterResp?.isLoadingGreenSkills}
                filterOption={true}
                labelInValue
                options={filterResp?.optionGS}
                placeholder={"Select"}
                onChange={filterHandler?.handleFilterByGS}
              />
            </div>
          </div>
          {filterHandler?.selectedGS?.length > 0 && (
            <div className="mt-6 ">
              <div className="mb-2 flex flex-wrap gap-3 items-centerbg-red-300">
                {filterHandler?.selectedGS?.map((gs: any, index: number) => (
                  <div
                    color="white"
                    className="text-backdrop shadow-custom rounded-md py-1 px-2"
                    style={{ color: "#36505E" }}
                    key={index}
                  >
                    {gs?.name}{" "}
                    <span
                      className="cursor-pointer hover:text-red-400 px-1"
                      onClick={() => filterHandler?.removeSelectedGS(gs?.id)}
                    >
                      X
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default FilterSidebar
