import instance from "../../../config/config"
import { message } from "antd"
// eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  UseQueryResult,
  // eslint-disable-next-line no-unused-vars
  useMutation,
  // eslint-disable-next-line no-unused-vars
  useQuery,
  // eslint-disable-next-line no-unused-vars
  useQueryClient,
} from "react-query"
import {
  // eslint-disable-next-line no-unused-vars
  ApiResponseGeneric,
  // eslint-disable-next-line no-unused-vars
  ApiResponseGenericWithArray,
  // eslint-disable-next-line no-unused-vars
  ApiResponsePaginated,
} from "../../../common/types/ApiResponse"
// eslint-disable-next-line no-unused-vars
import { PublishArticleListQueryType } from "../types/article_query"
// eslint-disable-next-line no-unused-vars
import { AxiosError, AxiosResponse } from "axios"
import {
  GreenSkill,
  GreenSkillCategory,
  PublicArticleResponseTypes,
  PublicExpertResponseTypes,
} from "../types/article_response"

const QUERYNAMELISTLOGOUT: string = "publish-artilce-list-logout"
const QUERYNAMELIST: string = "publish-artilce-list"
const QUERYNAMEGSARTICLELIST: string = "publish-gs-artilce-list"
const QUERYNAMEGSCATEGORYARTICLELIST: string =
  "publish-category-gs-artilce-list"
const QUERYNAMEEXPERTARTICLELIST: string = "publish-expert-artilce-list"
const QUERYNAMEDETAIL: string = "publish-artilce-detail"
const QUERYNAMEDETAILLOGIN: string = "publish-artilce-detail-login"

export const useFetchPublishArticleList = (
  queryParam: PublishArticleListQueryType,
): UseQueryResult<
  ApiResponsePaginated<PublicArticleResponseTypes>,
  AxiosError
> => {
  const fetchData = async () => {
    try {
      return await instance.get<PublicArticleResponseTypes>(
        "/publish/articles",
        {
          params: {
            ...queryParam,
          },
        },
      )
    } catch (error: any) {
      message.error(
        `An error occurred while fetching ${QUERYNAMELISTLOGOUT}: + ${error.message}`,
      )
    }
  }
  return useQuery([QUERYNAMELISTLOGOUT, queryParam], fetchData, {
    onError: (error: AxiosError) => {
      message.error(
        `An error occurred while fetching ${QUERYNAMELISTLOGOUT}: + ${error.message}`,
      )
    },
  })
}

export const useFetchPublishLoginArticleList = (
  queryParam: PublishArticleListQueryType,
): UseQueryResult<
  ApiResponsePaginated<PublicArticleResponseTypes>,
  AxiosError
> => {
  const fetchData = async () => {
    try {
      return await instance.get<PublicArticleResponseTypes>(
        "publish/articles",
        {
          params: {
            ...queryParam,
          },
        },
      )
    } catch (error: any) {
      message.error(
        `An error occurred while fetching ${QUERYNAMELIST}: + ${error.message}`,
      )
    }
  }
  return useQuery([QUERYNAMELIST, queryParam], fetchData, {
    onError: (error: AxiosError) => {
      message.error(
        `An error occurred while fetching ${QUERYNAMELIST}: + ${error.message}`,
      )
    },
  })
}

export const useFetchDetailPublishArticle = (
  slug: string,
): UseQueryResult<
  ApiResponseGeneric<PublicArticleResponseTypes>,
  AxiosError
> => {
  const fetchDetailArticle = async () => {
    try {
      const response: AxiosResponse<
        ApiResponseGeneric<PublicArticleResponseTypes>
      > = await instance.get(`/publish/articles/${slug}`)
      return response?.data
    } catch (error: any) {
      message.error(
        `An error occurred while fetching ${QUERYNAMEDETAIL}: + ${error.message}`,
      )
      throw error
    }
  }

  const queryResult = useQuery([QUERYNAMEDETAIL, slug], fetchDetailArticle, {
    onError: (error: AxiosError) => {
      message.error(
        `An error occurred while fetching ${QUERYNAMEDETAIL}: + ${error.message}`,
      )
    },
  })

  return queryResult
}
export const useFetchDetailPublishAsLoginArticle = (
  slug: string,
): UseQueryResult<
  ApiResponseGeneric<PublicArticleResponseTypes>,
  AxiosError
> => {
  const fetchDetailArticle = async () => {
    try {
      const response: AxiosResponse<
        ApiResponseGeneric<PublicArticleResponseTypes>
      > = await instance.get(`/publish/articles/${slug}`)
      return response?.data
    } catch (error: any) {
      message.error(
        `An error occurred while fetching ${QUERYNAMEDETAILLOGIN}: + ${error.message}`,
      )
      throw error
    }
  }

  const queryResult = useQuery(
    [QUERYNAMEDETAILLOGIN, slug],
    fetchDetailArticle,
    {
      onError: (error: AxiosError) => {
        message.error(
          `An error occurred while fetching ${QUERYNAMEDETAILLOGIN}: + ${error.message}`,
        )
      },
    },
  )

  return queryResult
}

export const useFetchPublishGreenSkillArticleList = (): UseQueryResult<
  ApiResponseGenericWithArray<GreenSkill>,
  AxiosError
> => {
  const fetchData = async () => {
    try {
      return await instance.get<GreenSkill>(
        "/publish/articles/green-skills",
        {},
      )
    } catch (error: any) {
      message.error(
        `An error occurred while fetching ${QUERYNAMEGSARTICLELIST}: + ${error.message}`,
      )
    }
  }
  return useQuery([QUERYNAMEGSARTICLELIST], fetchData, {
    onError: (error: AxiosError) => {
      message.error(
        `An error occurred while fetching ${QUERYNAMEGSARTICLELIST}: + ${error.message}`,
      )
    },
  })
}
export const useFetchPublishCategoryGreenSkillArticleList = (): UseQueryResult<
  ApiResponseGenericWithArray<GreenSkillCategory>,
  AxiosError
> => {
  const fetchData = async () => {
    try {
      return await instance.get<GreenSkillCategory>(
        "/publish/articles/category-green-skills",
        {},
      )
    } catch (error: any) {
      message.error(
        `An error occurred while fetching ${QUERYNAMEGSCATEGORYARTICLELIST}: + ${error.message}`,
      )
    }
  }
  return useQuery([QUERYNAMEGSCATEGORYARTICLELIST], fetchData, {
    onError: (error: AxiosError) => {
      message.error(
        `An error occurred while fetching ${QUERYNAMEGSCATEGORYARTICLELIST}: + ${error.message}`,
      )
    },
  })
}

export const useFetchPublishExpertArticleList = (): UseQueryResult<
  ApiResponseGenericWithArray<PublicExpertResponseTypes>,
  AxiosError
> => {
  const fetchData = async () => {
    try {
      return await instance.get<PublicExpertResponseTypes>(
        "/publish/articles/experts",
        {},
      )
    } catch (error: any) {
      message.error(
        `An error occurred while fetching ${QUERYNAMEEXPERTARTICLELIST}: + ${error.message}`,
      )
    }
  }
  return useQuery([QUERYNAMEEXPERTARTICLELIST], fetchData, {
    onError: (error: AxiosError) => {
      message.error(
        `An error occurred while fetching ${QUERYNAMEEXPERTARTICLELIST}: + ${error.message}`,
      )
    },
  })
}

export const useLikeArtcile = () => {
  const queryClient = useQueryClient()

  const addData = async ({ slug }: { slug: string }) => {
    try {
      return await instance.post(`/articles/${slug}/like`)
    } catch (error) {
      message.error("An error occurred while like article.")
    }
  }
  return useMutation(addData, {
    onSuccess: (data: any) => {
      message.success(data?.data?.data)
      setTimeout(() => {
        queryClient.invalidateQueries([QUERYNAMELIST])
        queryClient.invalidateQueries([QUERYNAMEDETAILLOGIN])
      }, 1000)
    },
    onError: (error: any) => {
      message.error(
        "An error occurred while like article " + error.response?.data?.data,
        10,
      )
    },
  })
}

export const useUnLikeArtcile = () => {
  const queryClient = useQueryClient()

  const addData = async ({ slug }: { slug: string }) => {
    try {
      return await instance.delete(`/articles/${slug}/like`)
    } catch (error) {
      message.error("An error occurred while like article.")
    }
  }
  return useMutation(addData, {
    onSuccess: (data: any) => {
      message.success(data?.data?.data)
      setTimeout(() => {
        queryClient.invalidateQueries([QUERYNAMELIST])
        queryClient.invalidateQueries([QUERYNAMEDETAILLOGIN])
      }, 1000)
    },
    onError: (error: any) => {
      message.error(
        "An error occurred while like article " + error.response?.data?.data,
        10,
      )
    },
  })
}

// FOLLOW EXPERT
export const useFollowExpert = () => {
  const queryClient = useQueryClient()

  const addData = async ({ expertID }: { expertID: string }) => {
    try {
      return await instance.post(`/user-experts/${expertID}/follow`)
    } catch (error) {
      message.error("An error occurred while follow expert.")
    }
  }
  return useMutation(addData, {
    onSuccess: (data: any) => {
      message.success(data?.data?.data)
      setTimeout(() => {
        queryClient.invalidateQueries([QUERYNAMEDETAILLOGIN])
      }, 1000)
    },
    onError: (error: any) => {
      message.error(
        "An error occurred while follow expert " + error.response?.data?.data,
        10,
      )
    },
  })
}

export const useUnFollowExpert = () => {
  const queryClient = useQueryClient()

  const addData = async ({ expertID }: { expertID: string }) => {
    try {
      return await instance.delete(`/user-experts/${expertID}/follow`)
    } catch (error) {
      message.error("An error occurred while unfollow expert.")
    }
  }
  return useMutation(addData, {
    onSuccess: (data: any) => {
      message.success(data?.data?.data)
      setTimeout(() => {
        queryClient.invalidateQueries([QUERYNAMEDETAILLOGIN])
      }, 1000)
    },
    onError: (error: any) => {
      message.error(
        "An error occurred while unfollow expert " + error.response?.data?.data,
        10,
      )
    },
  })
}

export const usePostReqExpert = () => {
  const queryClient = useQueryClient()

  const addData = async (data: any) => {
    try {
      const res = await instance.post(`/user-experts/requests`, data)
      message.success(res?.data?.data)
      return res
    } catch (err: any) {
      message.error(
        `An error occurred while fetching ${QUERYNAMEDETAILLOGIN}: + ${err?.response?.data?.data}`,
      )
      throw err
    }
  }
  return useMutation(addData, {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries([QUERYNAMEDETAILLOGIN])
      }, 1000)
    },
    onError: (error: any) => {
      return error
    },
  })
}
