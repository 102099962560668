import { LoadingOutlined } from "@ant-design/icons"
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react"
import { Layout, Spin } from "antd"
import React, { createContext, useEffect, useRef, useState } from "react"
import { useQuery } from "react-query"
import { useLocation } from "react-router-dom"
import { signUpExpertRequest } from "../../authConfig"
import instance, { instanceV2 } from "../../config/config"
import { SignInToAccessModal } from "../components"
import { isException } from "../helper/ListPathWithoutPageLayout"
import { errorMessage } from "../utils/utils"
import CustomFooter from "./navigation/CustomFooter"
import CustomHeader from "./navigation/CustomHeader"
import CustomSidebar from "./navigation/CustomSidebar"
import { MobileMenu } from "./navigation/MobileMenu"

import TourProvider from "../components/tour/TourProvider"
import FloatingButtons from "./components/FloatingButtons"
import { ApiResponse } from "../types/ApiResponse"
import { CartData, UserProfileAccount } from "../types/common"

const { Content } = Layout

type TAccountContext = {
  data: { data: UserProfileAccount } | undefined
  isLoading: boolean
}

type TCartContext = {
  carts: ApiResponse<CartData> | undefined
  isLoading: boolean
}

export const AccountContext = createContext<TAccountContext | null>(null)
export const CartContext = createContext<TCartContext | null>(null)

export default function PageLayout({
  children,
  isOtherRoute,
}: {
  children: React.ReactNode
  isOtherRoute: boolean
}) {
  // const currentUrl = window.location.href
  const [openSidebar, setOpenSidebar] = useState(false)
  const { inProgress, instance: msalInstance } = useMsal()
  const user = msalInstance.getAllAccounts()[0]
  const contentDivRef = useRef<HTMLDivElement>(null)
  const contentDivRefLoggedOut = useRef<HTMLDivElement>(null)
  const location = useLocation()
  const isLearningPage = location.pathname.startsWith("/learnings")

  useEffect(() => {
    const getToken = async () => {
      const urlParams = new URLSearchParams(window.location.hash.substring(1)) // parse the URL fragment
      const idToken = urlParams.get("id_token")

      if (idToken) {
        try {
          await msalInstance.loginRedirect({
            extraQueryParameters: {
              id_token_hint: idToken,
            },
            ...signUpExpertRequest,
          })
        } catch (error) {
          console.log("Error during expert login", error)
        }
      }
    }
    getToken()
  }, [])

  useEffect(() => {
    if (contentDivRef?.current?.scrollTop) {
      contentDivRef.current.scrollTop = 0
    }

    if (contentDivRefLoggedOut?.current?.scrollTop) {
      contentDivRefLoggedOut.current.scrollTop = 0
    }
  }, [location])

  const [openDrawerItemMenu, setOpenDrawerItemMenu] = useState(false)

  const onCloseDrawerITem = () => {
    setOpenDrawerItemMenu(false)
  }

  const onOpenDrawerItem = () => {
    setOpenDrawerItemMenu(true)
  }

  const { data: account, isLoading: accountLoading } = useQuery<
    ApiResponse<UserProfileAccount>
  >("account", () => instanceV2.get("/accounts/detail"), {
    // retry: false,
    enabled: !!user && !isException(location.pathname),
  })

  const { data: carts, isLoading: cartLoading } = useQuery<
    ApiResponse<CartData>
  >(
    ["carts", localStorage.getItem("currency_code")],
    () =>
      instance.get(
        `transactions/carts?currency_code=${
          localStorage.getItem("currency_code") || "sgd"
        }`,
      ),
    {
      retry: false,
      enabled:
        !!instance?.defaults?.headers?.common?.["Authorization"] && !!user,
      // @ts-ignore
      onError: (err) => errorMessage(err?.response?.data?.data, err),
    },
  )

  const { data: currencies } = useQuery<
    ApiResponse<{ code: string; exchange: number }[]>
  >(["currencies"], () => instance.get("/references/currency-lms"), {
    retry: false,
  })

  if (inProgress !== "none") {
    return (
      <div className="absolute z-50 flex items-center justify-center w-screen h-screen bg-slate-200">
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 50,
              }}
              spin
            />
          }
        />
      </div>
    )
  }
  const isAuthRoute = ["/login", "/register", "/register-expert"].includes(
    location.pathname,
  )
  const shouldShowHeader = !isAuthRoute

  const handleOpenSidebar = (value: boolean) => {
    setOpenSidebar(value)
  }
  return (
    <AccountContext.Provider
      value={{ data: account?.data, isLoading: accountLoading }}
    >
      <TourProvider>
        <CartContext.Provider value={{ carts, isLoading: cartLoading }}>
          {!isException(location.pathname) ? (
            <Layout>
              <FloatingButtons />
              <div className="relative ">
                {shouldShowHeader && !isLearningPage && (
                  <CustomHeader
                    onOpenDrawerItem={onOpenDrawerItem}
                    isOpenSidebar={openSidebar}
                    isOtherRoute={isOtherRoute}
                    currencies={currencies?.data?.data}
                  />
                )}
              </div>
              <Layout className="h-screen overflow-hidden -mt-2 md:-mt-0 !bg-[#F0F2F5]">
                <AuthenticatedTemplate>
                  {!isOtherRoute && (
                    <div className="fixed hidden lg:block">
                      <CustomSidebar
                        openSidebar={openSidebar}
                        handleOpenSidebar={handleOpenSidebar}
                      />
                    </div>
                  )}
                  <Layout
                    className={`h-full relative !bg-[#F0F2F5]  ${
                      openSidebar
                        ? `${isOtherRoute ? "ml-0" : "ml-0 lg:ml-[15.7rem]"} `
                        : isOtherRoute
                          ? "ml-0"
                          : "ml-0 lg:ml-[4.2rem]"
                    } `}
                  >
                    <Content
                      ref={contentDivRef}
                      className="flex flex-col justify-between h-full overflow-auto"
                      style={{
                        margin: 0,
                        marginTop:
                          !shouldShowHeader || isLearningPage ? 0 : "65px",
                      }}
                    >
                      <div className="">{children}</div>
                      {!isLearningPage && (
                        <div className="mt-5">
                          {/* {!currentUrl.includes("learnings/modules") ||
                        (!currentUrl.includes("learnings/assessment") && (
                        ))} */}
                          <CustomFooter isLogged={true} />
                        </div>
                      )}
                    </Content>
                  </Layout>
                </AuthenticatedTemplate>

                <UnauthenticatedTemplate>
                  <Layout className="bg-[#F0F2F5] h-screen">
                    <Content
                      ref={contentDivRefLoggedOut}
                      className="justify-between overflow-auto"
                      style={{
                        margin: 0,
                        marginTop: !shouldShowHeader ? 0 : "65px",
                      }}
                    >
                      <div className="min-h-screen">
                        <div className="h-auto">{children}</div>
                      </div>
                      <div className="relative bottom-0 z-20 w-full">
                        <CustomFooter isLogged={false} />
                      </div>
                    </Content>
                  </Layout>
                </UnauthenticatedTemplate>
              </Layout>
              <SignInToAccessModal />
            </Layout>
          ) : (
            <>{children}</>
          )}
          <MobileMenu open={openDrawerItemMenu} onClose={onCloseDrawerITem} />
        </CartContext.Provider>
      </TourProvider>
    </AccountContext.Provider>
  )
}
