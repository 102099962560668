export enum OAuthProvider {
  GOOGLE = "google",
  MICROSOFT = "microsoft",
}

export enum InstalledAppType {
  CALENDAR = "calendar",
  CONFERENCING = "conferencing",
}

export enum GlobalStateKey {
  DisplayNoAvailabilityModal = "display_no_availability_modal",
  UpdateAvailabilityData = "update_availability_data",
}

export interface App {
  id: string
  provider: string
  name: string
  logo: string
  description: string
  is_default: boolean
  must_sync: boolean
  email?: string
}
