import { useState, useEffect } from "react"

function useLoading(initialValue = true, duration = 2000) {
  const [isCustomLoading, setIsCustomLoading] = useState(initialValue)

  useEffect(() => {
    const fakeLoadingTimer = setTimeout(() => {
      setIsCustomLoading(false)
    }, duration)

    return () => {
      clearTimeout(fakeLoadingTimer)
    }
  }, [duration])

  return { isCustomLoading, setIsCustomLoading }
}

export default useLoading
