import { Button } from "antd"
import React from "react"

import CompanySection from "./CompanySection"
import FounderSection from "./FounderSection"
import ProductSection from "./ProductSection"
import { ProductResponse } from "../../founder/types/response"
import ProductPreview from "./ProductPreview"

interface ContentTabFounderEditFormProps {
  toggleDisplay?: any
  editCompanyHandlers?: any
  editProductHandlers?: any
}

const ContentTabFounderEditForm: React.FC<ContentTabFounderEditFormProps> = ({
  toggleDisplay,
  editCompanyHandlers,
  editProductHandlers,
}) => {
  return (
    <div className="-mt-5">
      <div className="flex items-center justify-between flex-wrap md:flex-nowrap gap-y-3">
        <h2>My Climate Solutions</h2>

        <div className="flex items-center gap-4">
          <Button onClick={toggleDisplay} className="border-primary">
            Cancel
          </Button>
          <Button
            onClick={() => editCompanyHandlers?.handleSave()}
            className=""
            type="primary"
          >
            Save
          </Button>
        </div>
      </div>

      <div className="space-y-3">
        <CompanySection editCompanyHandlers={editCompanyHandlers} />
        <h3>Co-Founders</h3>
        <FounderSection editCompanyHandlers={editCompanyHandlers} />

        <div className="space-y-3">
          <div className="flex items-center justify-between">
            <h3>Products</h3>
            <Button
              className="border-primary"
              onClick={() => editProductHandlers.handleAddProduct()}
            >
              Add New Product
            </Button>
          </div>

          {editProductHandlers?.showFormProduct && (
            <ProductSection editProductHandlers={editProductHandlers} />
          )}

          {editCompanyHandlers?.productEditData?.map(
            (val: ProductResponse, index: number) => (
              <ProductPreview key={index} data={val} />
            ),
          )}
        </div>
      </div>
    </div>
  )
}

export default ContentTabFounderEditForm
