import { Tag } from "antd"
import React from "react"
import { LoadingOutlined } from "@ant-design/icons"

interface SchoolTagProps {
  schoolImage: string
  schoolName: string
  isLoading: boolean
}

export const SchoolTag: React.FC<SchoolTagProps> = ({
  schoolImage,
  schoolName,
  isLoading,
}) => {
  return (
    <div>
      <Tag
        icon={isLoading ? <LoadingOutlined /> : null}
        className={`flex items-center gap-2 rounded-full px-2 border-primary bg-white py-1`}
      >
        {schoolImage && !isLoading && (
          <img
            src={schoolImage}
            alt={`School-Logo`}
            className="object-contain"
            height={25}
          />
        )}
        <p className={`m-0 text-sm font-semibold`}>
          {isLoading ? "Loading..." : schoolName ?? "Coming Soon"}
        </p>
      </Tag>
    </div>
  )
}
