import LevelUp from "./LevelUp"
import { CVLaunchpad } from "./CVLaunchpad"
import EcoCommitmentStreak from "./EcoCommitmentStreak"
import { SustainabilityScore } from "./SustainabilityScore"
import ArticleWidget from "./ArticleWidget"
import { useContext } from "react"
import {
  ITourContext,
  TourContext,
} from "../../../common/components/tour/TourProvider"

export default function PageSidebar() {
  const {
    refs: {
      refGreenCV,
      refExpertArticle,
      refLevelUp,
      refEcoCommitmentStreak,
      refSustainabilityScore,
    },
  } = useContext(TourContext) as ITourContext
  return (
    <>
      <CVLaunchpad ref={refGreenCV} />
      <ArticleWidget ref={refExpertArticle} />
      <LevelUp ref={refLevelUp} />
      <EcoCommitmentStreak ref={refEcoCommitmentStreak} />
      <SustainabilityScore ref={refSustainabilityScore} />
    </>
  )
}
