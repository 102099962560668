import React from "react"
import { Fit } from "../commons/Fit"
import { useJobRoleFit } from "../../hooks/jobRoles/useJobRoleFit"
import { DetailFullCart } from "./DetailFullCart"

export const DetailFit: React.FC<{ id: string }> = ({ id }) => {
  const { data: dataFit, isLoading, isError } = useJobRoleFit(id)

  const defaultData = [
    {
      name: "Hard Skills",
      percent: 0,
      color: "#009ACE",
    },
    {
      name: "Green Skills",
      percent: 0,
      color: "#86C769",
    },
    {
      name: "Soft Skills",
      percent: 0,
      color: "#FAC61D",
    },
  ]

  return (
    !isLoading &&
    !isError && (
      <div className=" bg-backdrop text-white rounded-xl p-5">
        <h3 className=" font-semibold">Your skills that fit this Job Role</h3>
        <div className=" h-[180px]">
          <Fit
            data={dataFit || defaultData}
            maxAngle={
              dataFit && dataFit.length > 0
                ? (dataFit?.reduce(
                    (a: number, b: any) => (a >= b.percent ? a : b.percent),
                    0,
                  ) /
                    100) *
                  360
                : 0
            }
          />
        </div>
        <DetailFullCart id={id} />
      </div>
    )
  )
}
