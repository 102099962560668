import React, { useCallback, useEffect, useRef, useState } from "react"
import { useQuery } from "react-query"
import ErrorMessageBuilder from "../../../../common/components/ErrorMessageBuilder"
import { message } from "antd"
import { postCommentList } from "../apis/feed-comment"
import { ICommunityComment } from "../types/commonn"

const useInfiniteComments = (
  post_id: string | undefined,
  enabled: boolean = true,
) => {
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  })
  const [dataList, setDataList] = useState<ICommunityComment[]>([])
  const [total, setTotal] = useState<number>(0)
  const observer = useRef<IntersectionObserver | null>(null)

  const handleChangeParams = (newParams: any) => {
    setParams((prev) => ({ ...prev, ...newParams }))
  }

  const commentsQuery = useQuery(
    ["community-comment-list", post_id],
    () => postCommentList(post_id ?? "", params.limit, params.page),
    {
      enabled: enabled && !!post_id,
      onError: () => {
        message.open({
          key: "community-comment-list",
          duration: 5,
          content: React.createElement(ErrorMessageBuilder, {
            message: `We encountered an issue while attempting to fetch comments for post with id ${post_id}. If the problem continues, please refresh the page.`,
            includeReportAt: true,
          }),
          type: "error",
        })
      },
      retry: 2,
    },
  )

  const lastCardElementRef = useCallback(
    (node: Element | null) => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          dataList.length &&
          dataList?.length < total &&
          !commentsQuery.isLoading
        ) {
          handleChangeParams({ page: params.page + 1 })
        }
      })
      if (node) observer.current.observe(node)
    },

    [dataList?.length],
  )

  useEffect(() => {
    if (!commentsQuery.isLoading) {
      if (params.page === 1) {
        setDataList(commentsQuery?.data?.data.data?.list || [])
        setTotal(commentsQuery?.data?.data.data.total_data || 0)
      } else if (
        dataList?.[dataList?.length - 1]?.id !==
        commentsQuery?.data?.data.data.list?.[
          commentsQuery?.data?.data.data.list?.length - 1
        ]?.id
      ) {
        setDataList((prev) => [
          ...prev,
          ...(commentsQuery?.data?.data.data.list || []),
        ])
        setTotal(commentsQuery?.data?.data.data.total_data || 0)
      }
    }
  }, [commentsQuery?.data?.data.data.list])
  return {
    commentsQuery,
    lastCardElementRef,
    comments: dataList,
  }
}

export default useInfiniteComments
