import { Image, Tag } from "antd"
import React from "react"
import {
  HeartOutlined,
  EnvironmentOutlined,
  DollarOutlined,
  FileDoneOutlined,
  CarOutlined,
  ClockCircleOutlined,
  HeartFilled,
} from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"
import { DefaultCompanyLogoImg } from "../../../../assets/images"
import relativeTime from "dayjs/plugin/relativeTime"
import { useProfile } from "../../../missionControl/hooks/useProfile"
import { useModalStore } from "../../../../stores"

interface CardOfJobBoardProps {
  item: any
  index: number
  handleSaveJob: (job: any) => void
  handleUnSaveJob: (jobId: number) => void
}

const CardOfJobBoard: React.FC<CardOfJobBoardProps> = ({
  item,
  index,
  handleSaveJob,
  handleUnSaveJob,
}) => {
  const navigate = useNavigate()
  dayjs.extend(relativeTime)
  const { account } = useProfile()

  const openModal = useModalStore((state) => state.openModal)

  return (
    <div
      className="bg-white-custom shadow-custom rounded-xl mb-4 py-4 px-8 cursor-pointer transition-all"
      key={index}
      onClick={(e) => {
        e.stopPropagation()
        navigate(`/job/board/${item?.job?.id}`)
      }}
    >
      <div className="flex justify-between flex-wrap md:flex-nowrap gap-y-5">
        <div className="flex items-center gap-5 ">
          {item?.company?.logo ? (
            <Image
              src={item?.company?.logo ? item?.company?.logo : ""}
              preview={false}
              alt={`logo-${item?.company?.name}`}
              // height={80}
              width={70}
            />
          ) : (
            <Image
              src={DefaultCompanyLogoImg}
              preview={false}
              alt={`logo-${item?.company?.name}`}
              // height={80}
              width={70}
            />
          )}

          <div className="space-y-2">
            <p className="m-0 p-0 font-bold text-base capitalize">
              {item?.job?.name}
            </p>
            <div className="flex items-center gap-x-7 gap-y-2 flex-wrap md:flex-nowrap">
              <p className="m-0 p-0 text-secondary capitalize">
                {item?.company?.name}
              </p>
              {/* <Tag className='bg-secondaryYellow text-brown border-brown border-[0.5px]'>
                {item?.company?.sector ? item?.company?.sector : "N/A"}
              </Tag> */}
            </div>
            <p className="m-0 p-0">{item?.job?.team}</p>
          </div>
        </div>
        <div className="p-0 m-0 flex gap-7 items-start flex-wrap sm:flex-nowrap">
          <Tag
            bordered={false}
            onClick={(e) => {
              if (account) {
                if (item?.job?.is_saved) {
                  handleUnSaveJob(item?.job?.id)
                } else {
                  handleSaveJob(item?.job)
                }
              } else {
                openModal()
              }
              e.stopPropagation()
            }}
            color={item?.job?.is_saved ? undefined : "volcano"}
            className={`${
              item?.job?.is_saved ? "bg-primary-bg" : "text-backdrop"
            } !border-gray-300 rounded max-w-full overflow-clip group hover:scale-105 duration-200 transition-all cursor-pointer`}
            icon={
              item?.job?.is_saved ? (
                <HeartFilled key={"favorite"} className="!text-primary" />
              ) : (
                <HeartOutlined className="hover:text-primary" key="favorite" />
              )
            }
          >
            {item?.job?.is_saved ? "Saved" : "Save"}
          </Tag>
        </div>
      </div>

      <div className="text-xs text-[#919191] flex items-center justify-between mt-1 -mb-2 flex-wrap md:flex-nowrap">
        <div className="flex items-center gap-x-6 flex-wrap md:flex-nowrap">
          <div className="flex items-center gap-2">
            <EnvironmentOutlined />
            <p className="whitespace-nowrap">{item?.job?.location}</p>
          </div>
          {item?.job?.salary_currency !== "-" ||
          item?.job?.salary_from !== 0 ||
          item?.job?.salary_up_to !== 0 ? (
            <div className="flex items-center gap-2">
              <DollarOutlined />
              <p className="whitespace-nowrap">
                {`${item?.job?.salary_currency} ${item?.job?.salary_from} - ${item?.job?.salary_up_to}`}
              </p>
            </div>
          ) : null}
          <div className="flex items-center gap-2">
            <FileDoneOutlined />
            <p className="whitespace-nowrap">{item?.job?.job_type}</p>
          </div>
          <div className="flex items-center gap-2">
            <CarOutlined />
            <p className="whitespace-nowrap">
              {item?.job?.remote ? "remote" : "on-site"}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <ClockCircleOutlined />
          <p>{dayjs(item?.job?.published_at).fromNow()}</p>
        </div>
      </div>
    </div>
  )
}

export default CardOfJobBoard
