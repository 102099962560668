import React from "react"
import { JobEdit } from "./JobEdit"
import { JobView } from "./JobView"
import { Common, JobHistory } from "../../../hooks/cv-builder/useJobHistory"

interface Props {
  data: JobHistory
  countries: {
    data: Common[] | undefined
    loading: boolean
  }
  job_types: {
    data: Common[] | undefined
    loading: boolean
  }
  index: number
  updateTempData: (index: number, data: JobHistory) => void
  onRemove: (val: string) => Promise<void>
  loadingremoveJobHis: boolean
  onRemoveTemp: (val: number) => void
}
export const Job: React.FC<Props> = ({
  data,
  countries,
  job_types,
  index,
  updateTempData,
  onRemove,
  loadingremoveJobHis,
  onRemoveTemp,
}) => {
  return data.isEdit ? (
    <JobEdit
      data={data}
      countries={countries}
      job_types={job_types}
      index={index}
      updateTempData={updateTempData}
    />
  ) : (
    <JobView
      data={data}
      index={index}
      updateTempData={updateTempData}
      onRemove={onRemove}
      loadingremoveJobHis={loadingremoveJobHis}
      onRemoveTemp={onRemoveTemp}
    />
  )
}
