/* eslint-disable */

import { useQuery } from "react-query"
// @ts-ignore
import instance from "../../../config/config"
import { ApiResponse } from "./../../../common/types/ApiResponse"

export interface EcoCommitmentHonor {
  description: string
  id: string
  image_url: string
  is_earned: boolean
  name: string
}

export interface GreenSkillHonor {
  description: string
  id: string
  image_url: string
  is_earned: boolean
  name: string
  total_earned: number
}

export interface GreenJobHonor extends GreenSkillHonor {}

export interface Orbit {
  id: string
  image_url: string
  is_earned: boolean
  progress: string
  subtitle: string
  title: string
}

export interface TeraPointHistory {
  created_at: string
  id: string
  name: string
  point: number
}

export type SolorTrophiesElement =
  | EcoCommitmentHonor
  | GreenSkillHonor
  | GreenJobHonor

export interface Data {
  eco_commitment_honors: EcoCommitmentHonor[]
  green_skill_honors: GreenSkillHonor[]
  orbits: Orbit[]
  tera_point_histories: TeraPointHistory[]
  green_job_honors: GreenJobHonor[]
}

const fetchAchievements = async () => {
  const response: ApiResponse<Data> = await instance.get("/achievements")
  return response
}

export const useAchievements = () => {
  return useQuery("achievements", fetchAchievements)
}
