import instance from "../../../config/config"
import { useMutation, useQuery } from "react-query"
import { AxiosResponse } from "axios"
import { message } from "antd"
import React from "react"
import ErrorMessageBuilder from "../../../common/components/ErrorMessageBuilder"

export type SettingEmail = {
  id: string
  user_id: string
  green_skills_courses: boolean
  marketing_communications: boolean
  new_expert_articles: boolean
  new_features_and_functionalities: boolean
  new_perks: boolean
  new_suggested_jobs: boolean
  new_workshops_and_programs: boolean
  recruiter_invited_to_apply_for_a_job: boolean
  recruiter_invited_to_connect: boolean
  replies_to_my_post: boolean
  skilledin_green_news: boolean
}

export const useSettingEmail = () => {
  const { data, isLoading } = useQuery(
    "setting-email",
    () => instance.get<AxiosResponse<SettingEmail>>("/account-settings"),
    {
      onError: () => {
        message.open({
          key: "setting-email",
          duration: 5,
          content: React.createElement(ErrorMessageBuilder, {
            message:
              "We encountered an issue while attempting to fetch your account settings. If the problem continues, please refresh the page.",
            includeReportAt: true,
          }),
          type: "error",
        })
      },
      retry: 2,
    },
  )

  const { mutateAsync: update, isLoading: loadingUpdate } = useMutation({
    mutationFn: (val: SettingEmail) => {
      return instance.patch(`/account-settings`, val)
    },
    mutationKey: "account-settings-update",
  })

  const updatePrivacy = async (updatedStatus: SettingEmail) => {
    try {
      await update(updatedStatus)
    } catch (error) {
      message.error("Failed to update privacy email settings")
    }
  }

  const changeStatus = (field: keyof SettingEmail, status: boolean) => {
    let dataFinal = data?.data?.data
    if (dataFinal) {
      if (field !== "id" && field !== "user_id") {
        dataFinal[field] = status
      }
      updatePrivacy(dataFinal)
    }
  }
  return {
    data: data?.data?.data,
    isLoading: isLoading || loadingUpdate,
    changeStatus,
  }
}
