import { useFetchList } from "../../../../../hooks/generic_rest"

import { MentorArea, MentorDomain } from "../types/response"
import { MENTOR_REFERENCE_ENDPOINTS } from "../apis/endpoint"

const LIST_AREA_REF_MENTOR_QUERY_NAME = "list-area-ref-mentor"
const LIST_DOMAIN_REF_MENTOR_QUERY_NAME = "list-domain-ref-mentor"

const queryParams = {}
export const useMentorReference = () => {
  const {
    data: listAreas,
    isLoading: listLoadingAreas,
    isError: listErrorAreas,
  } = useFetchList<MentorArea>(`${MENTOR_REFERENCE_ENDPOINTS.LIST_AREAS}`, {
    queryParams,
    queryName: LIST_AREA_REF_MENTOR_QUERY_NAME,
  })

  const {
    data: listDomain,
    isLoading: listLoadingDomain,
    isError: listErrorDomain,
  } = useFetchList<MentorDomain>(`${MENTOR_REFERENCE_ENDPOINTS.LIST_DOMAINS}`, {
    queryParams,
    queryName: LIST_DOMAIN_REF_MENTOR_QUERY_NAME,
  })

  return {
    areaData: {
      listAreas: listAreas?.list,
      listLoadingAreas,
      listErrorAreas,
    },
    domainData: {
      listDomain: listDomain?.list,
      listLoadingDomain,
      listErrorDomain,
    },
  }
}
