import React from "react"
import { EditOutlined, GlobalOutlined } from "@ant-design/icons"
import { Image } from "antd"
import { Moon } from "../../../../assets/images"
import { PinMapGray } from "../../../../assets/images"
import FounderItem from "./FounderItem"
import ProductPreview from "./ProductPreview"
import { openInNewTab } from "../../../../utils/util_funcs"
import { FounderResponse } from "../../founder/types/response"
import { useProfile } from "../../../missionControl/hooks/useProfile"

interface ContentTabFounderPreviewProps {
  toggleDisplay?: any
  detailPreview?: any
}

const ContentTabFounderPreview: React.FC<ContentTabFounderPreviewProps> = ({
  toggleDisplay,
  detailPreview,
}) => {
  const { account } = useProfile()

  return (
    <div className="relative">
      <h2>My Climate Solutions</h2>

      {account?.data?.data?.id === detailPreview?.id && (
        <EditOutlined
          className=" absolute right-2 top-2"
          onClick={toggleDisplay}
        />
      )}

      {detailPreview?.startup?.products?.length > 0 ? (
        <div>
          <div className="flex items-center w-full gap-5 mt-5">
            <div>
              <Image
                className="object-cover rounded-full"
                src={detailPreview?.startup?.logo}
                alt="profile"
                width={100}
                height={100}
                preview={false}
              />
            </div>
            <div className="flex flex-col gap-y-3">
              <div className="flex items-center justify-between w-full">
                <h3>{detailPreview?.startup?.name}</h3>

                <div className="shadow-custom flex items-center justify-between px-4 py-2 gap-x-6 rounded-xl w-1/3">
                  <p className="m-0 text-[#966F02] text-xs uppercase tracking-widest">
                    {detailPreview?.startup?.stage?.name}
                  </p>

                  <div className="flex items-center gap-3">
                    <div className="flex items-center">
                      <PinMapGray />
                      <span className="ml-2">
                        {detailPreview?.startup?.location?.name}
                      </span>
                    </div>

                    <div
                      className="flex items-center gap-1 md:gap-3 bg-[#FFF2E8] px-2 rounded hover:scale-105 transition-all cursor-pointer"
                      style={{ border: "1px solid #36505E" }}
                      onClick={() =>
                        openInNewTab(`${detailPreview?.startup?.website}`)
                      }
                    >
                      <GlobalOutlined />
                      <p className="m-0 text-[10px] md:text-xs">
                        My Climate Solution
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <p className="m-0 line-clamp-2">
                {detailPreview?.startup?.long_description}
              </p>
            </div>
          </div>

          {/* FOUNDER SECTION */}
          <div className="mt-5 bg-white py-2 px-5 rounded-xl">
            <h3 className="text-center">Founding Team</h3>
            <div
              className={`grid ${
                detailPreview?.startup?.founders?.length === 1
                  ? "grid-cols-1"
                  : detailPreview?.startup?.founders?.length === 2
                    ? "grid-cols-2"
                    : "grid-cols-3"
              }`}
            >
              {detailPreview?.startup?.founders?.map(
                (item: FounderResponse, index: number) => (
                  <FounderItem key={index} founder={item} />
                ),
              )}
            </div>
          </div>

          {/* PRODUCT SECTION */}
          <div className="grid grid-cols-1 gap-3 mt-7">
            {detailPreview?.startup?.products?.map(
              (data: any, index: number) => (
                <ProductPreview key={index} data={data} />
              ),
            )}
          </div>
        </div>
      ) : (
        <div className="w-full text-center  py-6">
          <Image src={Moon} alt="moon image" preview={false} width={100} />
          <p className="my-8">
            Add your company and the products that solve Climate problems
          </p>
        </div>
      )}
    </div>
  )
}

export default ContentTabFounderPreview
