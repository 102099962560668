/* eslint-disable no-useless-catch */
import { useEffect, useState } from "react"
import { AxiosResponse } from "axios"
import { useMutation, useQuery } from "react-query"
import instance from "../../../../config/config"
import { message } from "antd"

export type SkillVerification = {
  id: string
  created_at: string
  description: string
  document_link: string
  document_name: string
  green_skill: {
    id: string
    name: string
  }
  green_skill_id: string
  status: string
  verified_at?: string
  submitted_at?: string
  isEdit?: boolean
}

export const useSkillVerification = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [tempData, setTempData] = useState<SkillVerification[]>([])
  // const queryClient = useQueryClient()
  const handleAddDown = () => {
    const temp: SkillVerification = {
      id: "",
      created_at: "",
      description: "",
      document_link: "",
      document_name: "",
      green_skill: {
        id: "",
        name: "",
      },
      green_skill_id: "",
      status: "",
      isEdit: true,
    }
    setTempData((prev) => [...prev, temp])
  }

  const updateTempData = (index: number, data: SkillVerification) => {
    const temp = [...tempData]
    temp[index] = data
    setTempData(temp)
  }

  const { data: skillVerif, isFetching: loadingskillVerif } = useQuery(
    "skill-verification",
    () => instance.get<AxiosResponse<SkillVerification[]>>("/verified-skills"),
  )

  const { mutateAsync: remove, isLoading: loadingRemove } = useMutation({
    mutationFn: async (val: any) => {
      try {
        const res = await instance.delete(`/verified-skills/${val}`)
        return res
      } catch (error) {
        throw error
      }
    },
    mutationKey: "skill-verification-remove",
  })

  const onRemove = async (id: string) => {
    message.open({
      key: "remove-skill-verification",
      content: "updating...",
      type: "loading",
      duration: 0,
    })
    try {
      const response = await remove(id)

      if (response) {
        const temp = tempData.filter((item) => item.id !== id)
        setTempData(temp)
        message.destroy()
      }
    } catch (error: any) {
      message.open({
        key: "remove-skill-verification",
        content:
          JSON.stringify(error?.response?.data?.data) ||
          "Internal server error",
        type: "error",
      })
    }
  }

  const { mutateAsync: submitPublish, isLoading: loadingsubmitPublish } =
    useMutation({
      mutationFn: async (val: any) => {
        try {
          const res = await instance.patch(`/verified-skills/publish/${val}`)
          return res
        } catch (error) {
          throw error
        }
      },
      mutationKey: "skill-verification-pusblish",
    })

  const onPublish = async (id: string, index: number) => {
    message.open({
      key: "publish-skill-verification",
      content: "updating...",
      type: "loading",
      duration: 0,
    })
    try {
      const response = await submitPublish(id)

      if (response) {
        const finalData: SkillVerification = {
          id: response.data?.data?.id,
          created_at: response.data?.data?.created_at,
          description: response.data?.data?.description,
          document_link: response.data?.data?.document_link,
          document_name: response.data?.data?.document_name,
          green_skill: {
            id: response.data?.data?.green_skill.id,
            name: response.data?.data?.green_skill.name,
          },
          green_skill_id: response.data?.data?.green_skill_id,
          status: response.data?.data?.status,
          verified_at: response.data?.data?.verified_at,
          submitted_at: response.data?.data?.submitted_at,
          isEdit: false,
        }
        message.destroy()
        const temp = [...tempData]
        temp[index] = finalData
        setTempData(temp)
      }
    } catch (error: any) {
      message.open({
        key: "publish-skill-verification",
        content:
          JSON.stringify(error?.response?.data?.data) ||
          "Internal server error",
        type: "error",
      })
    }
  }

  useEffect(() => {
    if (skillVerif?.data?.data) {
      setTempData(skillVerif?.data?.data || [])
    }
  }, [skillVerif?.data?.data])
  return {
    data: {
      data: tempData,
      isLoading: loadingskillVerif,
    },
    actions: {
      handleAddDown,
      updateData: updateTempData,
      isOpen,
      setIsOpen,
      onRemove,
      loadingRemove,
      onPublish,
      loadingsubmitPublish,
    },
  }
}
