import React from "react"
import { HomeOutlined } from "@ant-design/icons"
import { PageContent, PageHeader } from "../../../../common/layout"

import { useParams } from "react-router-dom"
import DetailPublicExpertProfileContentContent from "../components/PublicExpertProfileContent"
import { useMsal } from "@azure/msal-react"
import { useDetailExpert } from "../hooks/useExpert"
import { Skeleton } from "antd"

interface DetailPublicExpertProfileProps {}

const DetailPublicExpertProfile: React.FC<
  DetailPublicExpertProfileProps
> = () => {
  const { id, username, tab } = useParams()
  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()

  const {
    detail: detailPreview,
    cv,
    article,
  } = useDetailExpert(id!, username!, activeAccount, parseInt(tab!))

  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      path: "/community/member",
      title: "Community",
    },
    {
      path: "/community/public-expert",
      title: "Expert",
    },
    {
      title: article?.listData?.cv_data?.nickname || "unknown",
    },
  ]

  return (
    <PageContent
      header={<PageHeader breadcrumbItems={items} />}
      content={
        detailPreview?.detailLoadingExpert ? (
          <div className="bg-white p-4 rounded-lg">
            <Skeleton active />
          </div>
        ) : (
          <DetailPublicExpertProfileContentContent
            // handler={handler}
            data={article}
            cv={cv}
            id={id!}
            tab={tab!}
            username={username!}
            detailPreview={detailPreview}
          />
        )
      }
    />
  )
}

export default DetailPublicExpertProfile
