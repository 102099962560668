import { Button, Tag } from "antd"
import { WifiOutlined, UserOutlined } from "@ant-design/icons"
import React from "react"
import { TEventPostBody } from "../../types/commonn"
import { openInNewTab } from "../../../../../utils/util_funcs"
import { ClockCircleOutlined, CalendarOutlined } from "@ant-design/icons"
interface EventProps {
  body: TEventPostBody
}

const Event: React.FC<EventProps> = ({ body }) => {
  const formattedStartTime = body?.start_time
    ? new Date(body?.start_time * 1000).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })
    : ""
  const formattedEndTime = body?.end_time
    ? new Date(body?.end_time * 1000).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })
    : ""

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-4 bg-secondaryBlue text-backdrop rounded-lg shadow-md overflow-hidden">
      <div className="flex flex-col gap-2 justify-start items-start">
        <h5 className="text-sm font-medium">Event Announcement</h5>
        <h2 className="text-3xl font-bold">{body?.name}</h2>
        {body?.type === "online" ? (
          <Tag
            icon={<WifiOutlined />}
            className="bg-transparent  border-backdrop w-fit"
          >
            Online
          </Tag>
        ) : (
          <Tag
            icon={<UserOutlined />}
            className="bg-transparent  border-backdrop w-fit"
          >
            In Person
          </Tag>
        )}
        <p className="text-gray-500 line-clamp-4 !my-1">
          {body?.description}
        </p>
        <Button
          onClick={() => openInNewTab(body?.external_event_link)}
          className="bg-backdrop text-white rounded-lg"
        >
          Join Event
        </Button>
      </div>
      <div className=" flex flex-col justify-start items-start space-y-2">
        {body.start_date && (
          <div>
            <h4 className="text-base font-medium">Start</h4>
            <div className="flex items-center mt-1">
              <CalendarOutlined className="mr-2" />
              <span>{body.start_date}</span>
            </div>
            {body.start_time && (
              <div className="flex items-center mt-1">
                <ClockCircleOutlined className="mr-2" />
                <span>{formattedStartTime}</span>
              </div>
            )}
          </div>
        )}
        {body.end_date && (
          <div>
            <h4 className="text-base font-medium">End</h4>
            <div className="flex items-center mt-1">
              <CalendarOutlined className="mr-2" />
              <span>{body.end_date}</span>
            </div>
            {body.end_time && (
              <div className="flex items-center mt-1">
                <ClockCircleOutlined className="mr-2" />
                <span>{formattedEndTime}</span>
              </div>
            )}
          </div>
        )}
        {body.timezone && (
          <div className="flex items-center mt-4">
            <span className="">Timezone:</span>
            <span className="ml-1">{body.timezone}</span>
          </div>
        )}
      </div>
      {body?.speakers && body?.speakers.length > 0 && (
        <div className="grid grid-cols-1 gap-2 px-4 py-2 md:p-4">
          {body?.speakers.map((speaker, index) => (
            <div key={index} className="flex items-center space-x-2">
              <img
                className="w-10 h-10 rounded-full object-cover"
                src={speaker?.image} // Use speaker image URL
                alt={`Speaker ${index + 1}`}
              />
              <p className="text-gray-700 font-medium">{speaker?.name}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Event
