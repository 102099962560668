// eslint-disable-next-line
import { TCoursePostBody } from "../types/commonn"

export const CoursePostBody: React.FC<{ course: TCoursePostBody }> = ({
  course,
}) => {
  return (
    <div className="mt-4">
      <h3 className="font-semibold">{course.title}</h3>
      <div className="flex gap-4 items-start md:justify-start justify-center flex-wrap">
        <img
          src={course?.image}
          alt={course?.title}
          className="w-20 h-20 object-contain"
        />
        <p>{course.description}</p>
      </div>
    </div>
  )
}
