import React from "react"
import { Card, Input, Select, Switch, Tag } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { useCompanySize } from "../../hooks/commons/useCompanySize"
import { useCompanySector } from "../../hooks/commons/useCompanySector"
import { useCompanyLocation } from "../../hooks/companies/useCompanyLocationList"

interface Props {
  handleSearch: (...args: any[]) => void
  handleAddSector: (value: { label: string; value: string }) => void
  handleRemoveSector: (value: string) => void
  handleAddHQ: (value: { label: string; value: string }) => void
  handleRemoveHQ: (value: string) => void
  handleAddSize: (value: { label: string; value: string }) => void
  handleRemoveSize: (value: string) => void
  handleIsFollow: () => void
  isFollow: boolean
  selectedSector?: { label: string; value: string }[]
  selectedHQ?: { label: string; value: string }[]
  selectedSize?: { label: string; value: string }[]
}

export const Filter: React.FC<Props> = ({
  handleSearch,
  handleAddSector,
  handleAddSize,
  handleAddHQ,
  handleIsFollow,
  isFollow,
  selectedSector,
  selectedHQ,
  selectedSize,
  handleRemoveSector,
  handleRemoveHQ,
  handleRemoveSize,
}) => {
  const sector = useCompanySector()
  const companySize = useCompanySize()
  const location = useCompanyLocation()
  return (
    <Card className="bg-[#FAFAFAFA] shadow-md rounded-xl">
      <div className="flex gap-3 justify-between flex-wrap md:flex-nowrap">
        <div
          className="flex md:w-1/3"
          style={{ borderBottom: "1px solid #D0DDE5" }}
        >
          <SearchOutlined />
          <Input
            bordered={false}
            placeholder="search by company name"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div className="w-2/3 flex gap-3  justify-between flex-wrap md:flex-nowrap">
          <div className="bg-grey-select w-full">
            <Select
              className="w-full"
              allowClear
              placeholder="Company HQ"
              notFoundContent={"No company hq found"}
              showSearch
              options={location?.data?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              filterOption={(input, option) => {
                if (option && option.label) {
                  return (
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  )
                }
                return false
              }}
              // @ts-ignore
              onSelect={(_, opts) => handleAddHQ(opts)}
              value={null}
            />
          </div>
          <div className="bg-grey-select w-full">
            <Select
              className="w-full"
              allowClear
              placeholder="Company Size"
              notFoundContent={"No size found"}
              showSearch
              options={companySize?.data?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              filterOption={(input, option) => {
                if (option && option.label) {
                  return (
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  )
                }
                return false
              }}
              // @ts-ignore
              onSelect={(_, opts) => handleAddSize(opts)}
              value={null}
            />
          </div>
          <div className="bg-grey-select w-full">
            <Select
              className="w-full"
              onPopupScroll={sector.handleScroll}
              placeholder="Sector"
              onDropdownVisibleChange={sector.handleClear}
              notFoundContent={"No sector found"}
              showSearch
              filterOption={false}
              onSearch={sector.handleSearch}
              loading={sector.isLoading}
              options={sector.dataList.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              // @ts-ignore
              onSelect={(_, opts) => handleAddSector(opts)}
              value={null}
            />
          </div>
          <div className=" flex items-center gap-2 w-full">
            <Switch
              size="small"
              defaultChecked={isFollow}
              onChange={handleIsFollow}
            />
            <div className="">Companies I follow</div>
          </div>
        </div>
      </div>
      <div className="flex gap-5">
        {selectedHQ && selectedHQ?.length > 0 && (
          <div className="mt-3 flex gap-2">
            <div className=" font-semibold">Company HQ:</div>
            <div>
              {selectedHQ.map((hq) => (
                <Tag
                  id={hq.value}
                  key={hq.value}
                  closable
                  className=" me-2"
                  onClose={() => handleRemoveHQ(hq.value)}
                >
                  {hq.label}
                </Tag>
              ))}
            </div>
          </div>
        )}
        {selectedSize && selectedSize?.length > 0 && (
          <div className="mt-3 flex gap-2">
            <div className=" font-semibold">Company Size:</div>
            <div>
              {selectedSize.map((size) => (
                <Tag
                  id={size.value}
                  key={size.value}
                  closable
                  className=" me-2"
                  onClose={() => handleRemoveSize(size.value)}
                >
                  {size.label}
                </Tag>
              ))}
            </div>
          </div>
        )}

        {selectedSector && selectedSector?.length > 0 && (
          <div className="mt-3 flex gap-2">
            <div className=" font-semibold">Sector:</div>
            <div>
              {selectedSector.map((sector) => (
                <Tag
                  id={sector.value}
                  key={sector.value}
                  className=" me-2"
                  closable
                  onClose={() => handleRemoveSector(sector.value)}
                >
                  {sector.label}
                </Tag>
              ))}
            </div>
          </div>
        )}
      </div>
    </Card>
  )
}
