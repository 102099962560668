import React from "react"
import Icon from "@ant-design/icons"
import type { GetProps } from "antd"

type CustomIconComponentProps = GetProps<typeof Icon>

const withIcon = (SvgComponent: React.FC<React.SVGProps<SVGSVGElement>>) => {
  return (props: Partial<CustomIconComponentProps>) => (
    <Icon component={SvgComponent} {...props} />
  )
}

export default withIcon
