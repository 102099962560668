export const ProgressBar = ({ percent }) => {
  return (
    <div className="h-2 w-full bg-gray-300 rounded-full overflow-hidden">
      <div
        className="h-full bg-blue-500 rounded-full"
        style={{ width: `${percent}%` }}
      ></div>
    </div>
  )
}
