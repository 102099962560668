import { useQuery } from "react-query"
import { message } from "antd"
import ErrorMessageBuilder from "../../../../common/components/ErrorMessageBuilder"
import React, { useCallback, useContext, useRef, useState } from "react"
import { ICommunityPost } from "../types/commonn"
import { userPostsList } from "../apis/user-profile"
import { AccountContext } from "../../../../common/layout/PageLayout"
import { useMsal } from "@azure/msal-react"

const useMyPosts = () => {
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  })
  const [dataList, setDataList] = useState<ICommunityPost[]>([])
  const [total, setTotal] = useState<number>(0)
  const observer = useRef<IntersectionObserver | null>(null)
  const msalInstance = useMsal()
  const account = useContext(AccountContext)

  const handleChangeParams = (newParams: any) => {
    setParams((prev) => ({ ...prev, ...newParams }))
  }

  const myPostsQuery = useQuery(
    ["my-post-list"],
    () =>
      userPostsList(
        account?.data?.data?.id ??
          msalInstance.accounts?.[0]?.idTokenClaims?.sub ??
          "",
        params.limit,
        params.page,
      ),
    {
      onSuccess: (data) => {
        if (params.page === 1) {
          setDataList(data?.data?.data?.list || [])
          setTotal(data?.data?.data?.total_data || 0)
        } else {
          setDataList((prev) => [...prev, ...(data?.data?.data?.list || [])])
          setTotal(data?.data?.data?.total_data || 0)
        }
      },
      onError: () => {
        message.open({
          key: "my-posts-list",
          duration: 5,
          content: React.createElement(ErrorMessageBuilder, {
            message:
              "We encountered an issue while attempting to fetch posts for the feed. If the problem continues, please refresh the page.",
            includeReportAt: true,
          }),
          type: "error",
        })
      },
      retry: 2,
    },
  )

  const lastCardElementRef = useCallback(
    (node: Element | null) => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          dataList.length &&
          dataList?.length < total &&
          !myPostsQuery.isLoading
        ) {
          handleChangeParams({ page: params.page + 1 })
        }
      })
      if (node) observer.current.observe(node)
    },

    [dataList?.length],
  )

  return {
    myPostsQuery,
    lastCardElementRef,
    myPosts: dataList,
  }
}

export default useMyPosts
