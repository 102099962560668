import { useMemo } from "react"
import { handlingError } from "../../../utils/handling"
import instance from "../../../config/config"
import { useQuery } from "react-query"

const useDetailSchool = (id) => {
  const queryKey = useMemo(() => ["school-detail", {}], [])

  const fetchDetailSchool = async () => {
    try {
      const { data } = await instance.get(`/schools/${id}`, {
        params: {},
      })
      return data
    } catch (error) {
      return handlingError(error?.response?.data?.message, error)
    }
  }

  const { data, isLoading } = useQuery(queryKey, fetchDetailSchool)
  const detailSchool = data?.data?.data
  const isDetailSchoolLoading = isLoading

  return {
    detailSchool,
    isDetailSchoolLoading,
  }
}

export default useDetailSchool
