/* eslint-disable */

import {
  AddFunctionParam,
  FinalLevel,
} from "../../jobGalaxy/hooks/useSingleSkillCart"
import { useModalStore } from "../../../stores"
import { useMutation, useQueryClient } from "react-query"
import { AccountContext } from "../../../common/layout/PageLayout"
import { LevelsData, Skill } from "../types/common"
import React, { useContext } from "react"
import instance from "../../../config/config"
import { Modal, message } from "antd"

export const useSkillAddCartPopover = (skill: Skill, levelData: LevelsData) => {
  const [finalLevels, setFinalLevels] = React.useState<FinalLevel[]>([])
  const account = useContext(AccountContext)
  const openModal = useModalStore((state) => state.openModal)
  const queryClient = useQueryClient()

  React.useEffect(() => {
    if (levelData?.levels?.length) {
      const a = levelData?.levels?.map((v) => ({
        ...v,
        disabled: v.status_transaction !== 0 || !v.available_for_sale,
        checked: v.available_for_sale,
        price_per_level: levelData?.price_per_level,
        sharing_profit: levelData?.school?.sharing_profit,
        skill_name: skill?.name,
        owner_id: levelData?.school?.id,
        owner_stripe_id: levelData?.school?.stripe_account_id,
        currency_code: levelData?.currency?.code,
        rate: levelData?.currency?.exchange,
      }))
      setFinalLevels(a)
    }
  }, [
    levelData?.levels,
    levelData?.price_per_level,
    levelData?.school?.id,
    levelData?.school?.sharing_profit,
    levelData?.school?.stripe_account_id,
    skill?.name,
  ])

  const handleCheck = (id: string) => {
    setFinalLevels(
      (prev) =>
        prev?.map((item) => {
          if (item?.id === id) {
            return { ...item, checked: !item.checked }
          } else {
            return item
          }
        }),
    )
  }

  const { mutate: add, isLoading } = useMutation({
    mutationFn: (val: AddFunctionParam[]) => {
      return instance.post(`transactions/carts`, val)
    },
    mutationKey: "add-cart",
  })

  const handlePurchased = () => {
    if (!account?.data?.data) return openModal()

    let wantBuy = finalLevels?.filter(
      (item) => item.checked === true && item.status_transaction === 0,
    )
    let lvNeeded = finalLevels.filter((a) => {
      if (a.status_transaction === 0) {
        return !wantBuy.some((b) => b.proficiency <= a.proficiency)
      } else {
        return false
      }
    })

    const handleProcess = (products: FinalLevel[]) => {
      message.open({
        key: "adding-to-cart",
        content: "adding to cart...",
        type: "loading",
      })
      const finalData = products?.map((item) => ({
        user_id: account?.data?.data?.id ?? "",
        product_owner_id: item?.owner_id,
        product_owner_id_stripe: item?.owner_stripe_id,
        product_id: item.id,
        product_name: item?.skill_name,
        product_id_stripe: item?.stripe?.product_id,
        price_id_stripe: item?.stripe?.price_id,
        price: item?.price_per_level,
        sharing_profit: item?.sharing_profit,
        image_url: item?.image_url,
        proficient: item?.proficiency,
      }))
      add(finalData, {
        onSuccess: async () => {
          await queryClient.invalidateQueries(["bulk-skill-levels"])
          await queryClient.invalidateQueries(["carts"])
          message.open({
            key: "adding-to-cart",
            content: "success add to cart",
            type: "success",
          })
        },
        onError: (err: any) => {
          message.open({
            key: "adding-to-cart",
            content: err?.response?.data?.data,
            type: "success",
          })
        },
      })
    }

    if (lvNeeded.length > 0) {
      Modal.confirm({
        centered: true,
        icon: null,
        className: "square-modal",
        content: (
          <div className="mb-5">
            <div>
              You won't be able to access this level course, unless you pass all
              previous levels of the skill. Add previous levels to cart to
              proceed.
            </div>
          </div>
        ),
        cancelText: "Cancel",
        cancelButtonProps: {
          className: " rounded-none text-darkGrey",
          danger: true,
        },
        okText: "Add previous levels",
        okButtonProps: {
          className: "rounded-none bg-primary hover:bg-[#85bd6c]  text-white",
          //   type: "ghost",
        },
        onOk: () => {
          wantBuy = [...lvNeeded, ...wantBuy]
          handleProcess(wantBuy)
        },
      })
    } else {
      handleProcess(wantBuy)
    }
  }
  return { finalLevels, handleCheck, handlePurchased, isLoading }
}
