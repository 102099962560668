import { DatePicker } from "antd"
import dayjs from "dayjs"
import React from "react"
import { Control, Controller } from "react-hook-form"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)

interface RHFDatePickerFieldProps {
  control: Control<any>
  name: string
  placeholder?: string
  isUtc?: boolean
  showTime?: boolean
  format?: string
  returnString?: boolean
}

const RHFDatePickerField: React.FC<RHFDatePickerFieldProps> = (props) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      rules={{
        required: "This field is required",
      }}
      render={({ field, fieldState }) => {
        return (
          <>
            <DatePicker
              placeholder={props.placeholder}
              status={fieldState.error ? "error" : undefined}
              showTime={props?.showTime}
              ref={field.ref}
              name={field.name}
              onBlur={field.onBlur}
              format={props?.format}
              value={field.value ? dayjs(field.value) : null}
              onChange={(date) => {
                if (props.isUtc) {
                  field.onChange(date ? date.utc().valueOf() : null)
                } else if (props?.returnString) {
                  field.onChange(date ? date.toString() : null)
                } else {
                  field.onChange(date ? date.valueOf() : null)
                }
              }}
            />
            <br />
            {fieldState.error ? (
              <span style={{ color: "red" }}>{fieldState.error?.message}</span>
            ) : null}
          </>
        )
      }}
    />
  )
}

export default RHFDatePickerField
