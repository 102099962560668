import { LikeOutlined } from "@ant-design/icons"
import { Divider, Image } from "antd"
import React from "react"
import { useNavigate } from "react-router-dom"
import { Moon } from "../../../../assets/images"
import CardLoading from "../../expert/components/CardLoading"
import { Article, MyArticleResponseTypes } from "../../expert/types/response"

interface ContentTabExpertProps {
  data: any
}

const ContentTabExpert: React.FC<ContentTabExpertProps> = ({ data }) => {
  const navigate = useNavigate()
  return (
    <div>
      <div className="mt-6 space-y-6">
        {data?.listDataLoading ? (
          Array.from({ length: 3 }, (_: any, index: number) => (
            <CardLoading index={index} key={index} />
          ))
        ) : !data?.listData?.articles ||
          data?.listData?.articles?.length === 0 ? (
          <div className="w-full py-6 text-center bg-white rounded-xl shadow-custom-sm">
            <p className="my-8">no article</p>
            <Image src={Moon} alt="moon image" preview={false} width={100} />
          </div>
        ) : (
          data?.listData?.articles?.map(
            (article: MyArticleResponseTypes, index: number) => {
              const itemArticle = article?.green_skill_category?.article?.map(
                (item: Article) => item,
              )
              return (
                <div
                  className="px-6 py-2 bg-white-custom rounded-xl shadow-custom-sm"
                  key={index}
                >
                  <p className="uppercase tracking-widest text-[#966F02] text-xs mb-7">
                    {article?.green_skill_category?.name}
                  </p>
                  {itemArticle?.map(
                    (articleItem: Article, articleIndex: number) => (
                      <div
                        className=""
                        // style={{ borderBottom: "1px solid #DADADA" }}
                        key={articleIndex}
                      >
                        <div className="flex items-center w-full">
                          <div className="grid items-center w-full grid-cols-12 ">
                            <div className="flex flex-wrap w-10/12 col-span-12 gap-1 md:col-span-2">
                              {articleItem?.green_skills?.map((skill) => (
                                <span
                                  style={{
                                    border: "1px solid #36505E",
                                  }}
                                  className="rounded-lg truncate  m-0 px-2 bg-[#F5F5F5]"
                                  key={skill.id}
                                  onClick={() =>
                                    navigate(`/skill/green-skills/${skill?.id}`)
                                  }
                                >
                                  {skill.name}
                                </span>
                              ))}
                            </div>
                            <div
                              className="col-span-9 -mt-1 cursor-pointer hover:font-bold"
                              onClick={() =>
                                navigate(`/skill/articles/${articleItem?.slug}`)
                              }
                            >
                              <div>
                                <p>{articleItem.title}</p>
                              </div>
                            </div>
                            {articleItem?.status !== "draft" && (
                              <div className="flex justify-end col-span-1 ">
                                <div className="flex items-center gap-2">
                                  <div>
                                    <LikeOutlined />
                                  </div>
                                  <p className="m-0">{articleItem.likes}</p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        {itemArticle?.length > 1 && (
                          <Divider className="my-3" />
                        )}
                      </div>
                    ),
                  )}
                </div>
              )
            },
          )
        )}
      </div>
    </div>
  )
}

export default ContentTabExpert
