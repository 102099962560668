import { Image, Rate, Select, SelectProps } from "antd"
import React, { useEffect, useState } from "react"
import { Moon } from "../../../../../assets/images"
import {
  CloseOutlined,
  StarFilled,
  EnvironmentOutlined,
} from "@ant-design/icons"
import { MentorArea, MentorDomain, MentorResponse } from "../types/response"
import AvatarCustom from "../../../../../common/components/AvatarCustom"

interface ContentTabMentorProps {
  data: MentorResponse
  areaData?: MentorArea[] | undefined
  loadingArea?: boolean
  domainData?: MentorDomain[] | undefined
  loadingDomain?: boolean
  handler?: any
  isMyProfile: boolean | undefined
}

const ContentTabMentor: React.FC<ContentTabMentorProps> = ({
  data,
  areaData,
  domainData,
  handler,
  isMyProfile,
}) => {
  // console.log("data?.mentor_areas", areaData)
  interface ItemProps {
    label: string
    value: string
  }

  const [valueAreaSelected, setValueAreaSelected] = useState<
    { label: string; value: string }[]
  >([])
  const [valueDomainSelected, setValueDomainSelected] = useState<
    { label: string; value: string }[]
  >([])

  useEffect(() => {
    if (data?.mentor_areas) {
      setValueAreaSelected(
        data?.mentor_areas.map((area: MentorArea) => ({
          label: area.name,
          value: area.id,
        })),
      )
    }
    if (data?.mentor_domains) {
      setValueDomainSelected(
        data?.mentor_domains.map((domain: MentorDomain) => ({
          label: domain.name,
          value: domain.id,
        })),
      )
    }
  }, [data?.mentor_areas, data?.mentor_domains])

  const optionsArea: ItemProps[] =
    areaData?.map((area: MentorArea) => ({
      label: area?.name,
      value: area?.id,
    })) || []
  const optionsDomain: ItemProps[] =
    domainData?.map((area: MentorDomain) => ({
      label: area?.name,
      value: area?.id,
    })) || []

  const filteredDomainOptions = optionsDomain.filter(
    (o) => !valueDomainSelected.some((selected) => selected.label === o.label),
  )
  const filteredAreaOptions = optionsArea.filter(
    (o) => !valueAreaSelected.some((selected) => selected.label === o.label),
  )

  const sharedAreaProps: SelectProps = {
    style: { width: "50%" },
    options: filteredAreaOptions,
    placeholder: "Add new area",
    maxTagCount: "responsive",
    value: "Add new area",
    onChange: (selected: string, val: any) => {
      setValueAreaSelected([...valueAreaSelected, val])
      handler.handleAddArea(val.value)
    },
  }
  const sharedDomainProps: SelectProps = {
    style: { width: "50%" },
    options: filteredDomainOptions,
    placeholder: "Add new domain",
    maxTagCount: "responsive",
    value: "Add new domain",
    onChange: (selected: string, val: any) => {
      setValueDomainSelected([...valueDomainSelected, val])
      handler.handleAddDomain(val.value)
    },
  }

  const handleTagAreaClose = (item: ItemProps) => {
    handler.handleRemoveArea(item.value)
  }
  const handleTagDomainClose = (item: ItemProps) => {
    handler.handleRemoveDomain(item.value)
  }

  const dummyData = [
    { id: 1, display_name: "Muhammad Nas", country: "Indonesia" },
    { id: 2, display_name: "John Doe", country: "United States" },
    { id: 3, display_name: "Jane Doe", country: "Canada" },
    { id: 4, display_name: "Alice", country: "United Kingdom" },
    { id: 5, display_name: "Bob", country: "Australia" },
  ]

  return (
    <div className="">
      {/* <div className="flex items-center mb-4 gap-7 mt-7">
        <p className="m-0">Rating</p>
        <Rate
          allowHalf
          defaultValue={2.5}
          disabled
          className="text-primary"
          character={<StarFilled className="" />}
        />
        <p className="m-0">3.5</p>
        <p className="m-0">(based on 13 reviews)</p>
      </div>
      <div className="flex items-center gap-5 mb-4">
        <p className="m-0">Mentees</p>
        <p className="m-0">32 mentees</p>|<p className="m-0">210 sessions</p>
      </div> */}
      <div className="grid grid-cols-2 gap-4 mb-5">
        <div className="col-span-1">
          <div className="h-64 px-6 py-4 bg-white rounded-xl shadow-custom">
            <div className="flex items-center justify-between">
              <p className="text-[#966F02] tracking-widest">MENTORSHIP AREAS</p>
              {isMyProfile && <Select {...sharedAreaProps} />}
            </div>

            <div className={`${isMyProfile ? "mt-10" : "mt-3"}`}>
              {valueAreaSelected.length > 0 ? (
                <div className="flex flex-wrap gap-2">
                  {valueAreaSelected?.map((tag, index) => (
                    <div
                      key={index}
                      className="flex items-center px-4 text-sm text-white border-gray-200 rounded-full bg-backdrop gap-x-2"
                    >
                      {tag.label}
                      {isMyProfile && (
                        <CloseOutlined
                          onClick={() => handleTagAreaClose(tag)}
                          className="text-white "
                        />
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex flex-col items-center gap-3 mt-4">
                  <Image preview={false} src={Moon} alt="no mentorship area" />
                  <p>What areas would you like to mentor in?</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="h-64 px-6 py-4 bg-white rounded-xl shadow-custom">
            <div className="flex items-center justify-between">
              <p className="text-[#966F02] tracking-widest">DOMAIN EXPERTISE</p>
              {isMyProfile && <Select {...sharedDomainProps} />}
            </div>
            <div className={`${isMyProfile ? "mt-10" : "mt-3"}`}>
              {valueDomainSelected.length > 0 ? (
                <div className="flex flex-wrap gap-2">
                  {valueDomainSelected?.map((tag, index) => (
                    <div
                      key={index}
                      className="flex items-center px-4 text-sm bg-gray-100 border-gray-200 rounded-full gap-x-2"
                      style={{ border: "2px solid #d2d2d2" }}
                    >
                      {tag.label}
                      {isMyProfile && (
                        <CloseOutlined
                          onClick={() => handleTagDomainClose(tag)}
                          className=""
                        />
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex flex-col items-center gap-3 mt-4">
                  <Image preview={false} src={Moon} alt="no mentorship area" />
                  <p>What Sustainability areas are you experienced in?</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        <h3>Mentees</h3>

        <div className="flex flex-wrap gap-4">
          {dummyData.map((item) => (
            <div key={item.id} className="p-3 mb-3 w-[180px]">
              <AvatarCustom display_name={item.display_name} />
              <div className="mt-5">
                <p className="text-center text-lg font-semibold">
                  {item.display_name}
                </p>
                <div className="flex items-center gap-2 justify-center">
                  <EnvironmentOutlined />
                  {item.country}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  )
}

export default ContentTabMentor
