import { Tag } from "antd"
import React from "react"
import { Link } from "react-router-dom"

interface HeaderItem {
  label: string
  description: string
  icon: any
  tags: string[]
  link: string
  coming_soon?: boolean
}

interface HeaderItemsContainerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  items: HeaderItem[] | undefined
  show: boolean
}

export const HeaderItemsContainer: React.FC<HeaderItemsContainerProps> = ({
  items,
  show,
  ...rest
}) => {
  return (
    <div
      className="fixed z-10 w-[550px] min-h-[200px] top-10 flex justify-center "
      {...rest}
    >
      <div
        className={`grid grid-cols-4 gap-5 w-full z-[200] rounded-xl mt-8  bg-white shadow-custom-sm   transition-all ease-in-out duration-300 ${
          show ? `py-5 px-4  h-full opacity-100` : " p-0  opacity-0"
        }`}
      >
        {items?.map((item) => (
          <Link
            to={item.link}
            style={{ pointerEvents: item?.coming_soon ? "none" : "auto" }}
            key={item.label}
            className={`col-span-2 flex items-center gap-3 group  rounded-xl transition-all ease-in-out ${
              show
                ? "opacity-100 hover:bg-gray-400/20 p-2 duration-300 delay-200 "
                : "opacity-0 duration-300 "
            }`}
          >
            <img
              src={item.icon}
              className={`group-hover:scale-110 duration-300 transition-all h-10 w-10 ${
                show ? "block" : "hidden"
              }`}
            />
            <div className={`${show ? "" : "h-0 hidden"}`}>
              <div className="flex justify-between items-center gap-2">
                <a className="font-bold">{item.label}</a>
                {item?.coming_soon && (
                  <Tag className="text-backdrop bg-bg">Coming Soon!</Tag>
                )}
              </div>
              <div className="text-darkGrey text-xs">{item.description}</div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  )
}
