/* eslint-disable */

import { useEffect, useMemo, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { ApiResponse } from "../../../common/types/ApiResponse"
import instance from "../../../config/config"
import useLoading from "../../../hooks/Loading"
import { useCalculateProgress } from "../../jobGalaxy/hooks/useCalculateProgress"
// import { ApiResponse } from "../../../common/types/ApiResponse";
import type { GreenSkillData } from "../types/common"
// import { message } from "antd";
import { Skill } from "../../skillGalaxy/types/common.ts"

const fetchSingleGreenSkill = (id: string): Promise<ApiResponse<Skill>> => {
  return instance.get(`green-skills/${id}?with=related`)
}

const fetchDetailLearnSingleSkill = async (user_id: string, id: string) => {
  try {
    const { data }: ApiResponse<GreenSkillData> = await instance.get(
      `/paid-skill/${user_id}/${id}`,
    )
    return data
  } catch (error: any) {
    throw error?.response?.data?.message
  }
}
export const useSingleMyLaunchpad = (
  id: string | undefined,
  user_id: string | undefined,
  levelID?: string,
  currentLevel?: any,
  isUnlocked?: boolean,
) => {
  const { isCustomLoading: isCustomLoadingHook, setIsCustomLoading } =
    useLoading(true, 2000)
  const queryKeySingle = useMemo(() => ["skill-levels"], [])

  const { data, isLoading } = useQuery(
    queryKeySingle,
    () => fetchDetailLearnSingleSkill(user_id ?? "", id ?? ""),
    {
      retry: false,
      enabled: !!id && !!user_id,
    },
  )

  const skill = useMemo(() => data?.data, [data])
  // const skill = {
  // 	"id"          : "b966352b-b4cf-49e7-b172-bc022056a426",
  // 	"green_skills": {
  // 		"id"                 : "b966352b-b4cf-49e7-b172-bc022056a426",
  // 		"name"               : "Smart Grid Implementation and Integration",
  // 		"description"        : "Apply knowledge of smart grid architecture, Supervisory Control and Data Acquisition (SCADA) systems, Intelligent Electronic Devices (IED) and communications technology used for the smart grid. Develop and implement an integrated smart grid system using various distributed energy sources and energy management systems. nas",
  // 		"learning_objectives": null,
  // 		"level"              : [
  // 			{
  // 				"id"                   : "7d50fa71-1b8b-4cbb-a70f-53e40089cfeb",
  // 				"proficiency"          : 1,
  // 				"name"                 : "novice",
  // 				"description"          : [
  // 					"1.1. Define the concepts and technologies involved in smart grid implementation and integration.",
  // 					"1.2. Explain the benefits of using smart grid technology.",
  // 					"1.3. Identify the major components of a smart grid system, such as SCADA systems, IEDs, and communications technology."
  // 				],
  // 				"available_for_sale"   : true,
  // 				"purchased_by_user"    : true,
  // 				"image_url"            : "https://stgreenskills001.blob.core.windows.net/course-levels/novice.png",
  // 				"instructor"           : {
  // 					"id"          : "60eb785a-4ed2-43f1-a66a-dab224b3bf17",
  // 					"display_name": "aasumitro",
  // 					"first_name"  : "Adhi",
  // 					"last_name"   : "Sumitro",
  // 					"avatar"      : "https://stgreenskills001.blob.core.windows.net/greenskills-trainer-imgs-test/60eb785a-4ed2-43f1-a66a-dab224b3bf17.jpg",
  // 					"about"       : "Adhi Sumitro, with a solid experience of 5 years as an instructor, has demonstrated his reliability and trustworthiness in the field.",
  // 					"title"       : "Backend Engineer at Believer School",
  // 					"country"     : "Indonesia",
  // 					"job_title"   : "Backend Engineer",
  // 					"linkedin_url": "https://linkedin.com/in/aasumitro",
  // 					"twitter_url" : "https://twitter.com/aasumitro",
  // 					"medium_url"  : "https://medium.com/aasumitro"
  // 				},
  // 				"stripe"               : {
  // 					"price_id"  : "price_1OJu1jKxzrLLJBZVWFAQg79w",
  // 					"product_id": "prod_OSYL6nTHdPaY62"
  // 				},
  // 				"status_transaction"   : 3,
  // 				"learning_outcome"     : "this actually a learning outcomes so instructor have to update this and this data will be displayed on certificate",
  // 				"module_titles"        : [
  // 					"This module 1 Title",
  // 					"This module 2 Title",
  // 					"This module 3 title",
  // 					"This module 4 title"
  // 				],
  // 				"user_track"           : {
  // 					"certificate_ref_number": "SKILZYFMOJPPCCMO",
  // 					"current_level"         : 1,
  // 					"current_module"        : 5,
  // 					"is_assessment_passed"  : true,
  // 					"score_assessment"      : 90,
  // 					"updated_at"            : "2023-12-08T01:26:53.286Z"
  // 				},
  // 				"video_metadata"       : {
  // 					"duration_breakdown"     : [
  // 						"13m"
  // 					],
  // 					"duration_in_seconds"    : 799.5919670000001,
  // 					"human_readable_duration": "13m"
  // 				},
  // 				"text_content_metadata": {
  // 					"average_reading_speed"  : "200wpm",
  // 					"duration_breakdown"     : [
  // 						"2m"
  // 					],
  // 					"duration_in_seconds"    : 120,
  // 					"human_readable_duration": "2m",
  // 					"total_words"            : 450
  // 				}
  // 			},
  // 			{
  // 				"id"                   : "e6949a18-9473-4c46-ad93-1a438909083f",
  // 				"proficiency"          : 2,
  // 				"name"                 : "developing",
  // 				"description"          : [
  // 					"2.1. Describe the types of data collected by SCADA systems in a smart grid.",
  // 					"2.2. Explain the role of IEDs in the control and automation of smart grid systems.",
  // 					"2.3. Identify the different types of energy sources that can be integrated into a smart grid system."
  // 				],
  // 				"available_for_sale"   : true,
  // 				"purchased_by_user"    : true,
  // 				"image_url"            : "https://stgreenskills001.blob.core.windows.net/course-levels/developing.png",
  // 				"instructor"           : {
  // 					"id"          : "eba75d12-d0f1-4220-b32f-bee720447963",
  // 					"display_name": "aas_inst",
  // 					"first_name"  : "aas",
  // 					"last_name"   : "instructor",
  // 					"avatar"      : "https://stgreenskills001.blob.core.windows.net/greenskills-trainer-imgs-test/eba75d12-d0f1-4220-b32f-bee720447963.jpg",
  // 					"about"       : "Lorem ipsum is instructor",
  // 					"title"       : "Instructor at Believer School",
  // 					"country"     : "Indonesia",
  // 					"job_title"   : "Instructor",
  // 					"linkedin_url": "https://linkedin.com/in/lorem",
  // 					"twitter_url" : "https://twitter.com/lorem",
  // 					"medium_url"  : "https://medium.com/lorem"
  // 				},
  // 				"stripe"               : {
  // 					"price_id"  : "price_1OJu1kKxzrLLJBZVODMiVOCX",
  // 					"product_id": "prod_OXVtjNUF4TAOe5"
  // 				},
  // 				"status_transaction"   : 3,
  // 				"learning_outcome"     : "<p>mamen trying to update the second level</p>",
  // 				"module_titles"        : [
  // 					"test update lorem ipsum",
  // 					"test update lorem ipsum module2",
  // 					"test update lorem ipsum module3",
  // 					"test update lorem ipsum module4"
  // 				],
  // 				"user_track"           : {
  // 					"certificate_ref_number": "SKILZADKYDA0JU6T",
  // 					"current_level"         : 2,
  // 					"current_module"        : 5,
  // 					"is_assessment_passed"  : true,
  // 					"score_assessment"      : 90,
  // 					"updated_at"            : "2023-12-13T06:51:44.052Z"
  // 				},
  // 				"video_metadata"       : {
  // 					"duration_breakdown"     : [
  // 						"0m"
  // 					],
  // 					"duration_in_seconds"    : 0,
  // 					"human_readable_duration": "0m"
  // 				},
  // 				"text_content_metadata": {
  // 					"average_reading_speed"  : "200wpm",
  // 					"duration_breakdown"     : [
  // 						"0m"
  // 					],
  // 					"duration_in_seconds"    : 0,
  // 					"human_readable_duration": "0m",
  // 					"total_words"            : 99
  // 				}
  // 			},
  // 			{
  // 				"id"                   : "58d3dcdc-67c1-4e31-9b7a-5ce86a2dd0bc",
  // 				"proficiency"          : 3,
  // 				"name"                 : "proficient",
  // 				"description"          : [
  // 					"3.1. Evaluate the effectiveness of different communication technologies used in smart grid systems.",
  // 					"3.2. Apply knowledge of smart grid architecture to design an integrated smart grid system.",
  // 					"3.3. Develop a plan for implementing a smart grid system in a specific location."
  // 				],
  // 				"available_for_sale"   : true,
  // 				"purchased_by_user"    : false,
  // 				"image_url"            : "https://stgreenskills001.blob.core.windows.net/course-levels/proficient.png",
  // 				"instructor"           : {
  // 					"id"          : "60eb785a-4ed2-43f1-a66a-dab224b3bf17",
  // 					"display_name": "aasumitro",
  // 					"first_name"  : "Adhi",
  // 					"last_name"   : "Sumitro",
  // 					"avatar"      : "https://stgreenskills001.blob.core.windows.net/greenskills-trainer-imgs-test/60eb785a-4ed2-43f1-a66a-dab224b3bf17.jpg",
  // 					"about"       : "Adhi Sumitro, with a solid experience of 5 years as an instructor, has demonstrated his reliability and trustworthiness in the field.",
  // 					"title"       : "Backend Engineer at Believer School",
  // 					"country"     : "Indonesia",
  // 					"job_title"   : "Backend Engineer",
  // 					"linkedin_url": "https://linkedin.com/in/aasumitro",
  // 					"twitter_url" : "https://twitter.com/aasumitro",
  // 					"medium_url"  : "https://medium.com/aasumitro"
  // 				},
  // 				"stripe"               : {
  // 					"price_id"  : "price_1OJu1lKxzrLLJBZVVa6OJ1yS",
  // 					"product_id": "prod_P0JD2tdpQMYXN4"
  // 				},
  // 				"status_transaction"   : 1,
  // 				"learning_outcome"     : "<p>lorem ipsum</p>",
  // 				"module_titles"        : [
  // 					"lorem lorem lorem",
  // 					"lorem level 3",
  // 					"lorem level 3",
  // 					"lorem level 3"
  // 				],
  // 				"user_track"           : null,
  // 				"video_metadata"       : {
  // 					"duration_breakdown"     : [
  // 						"0m"
  // 					],
  // 					"duration_in_seconds"    : 0,
  // 					"human_readable_duration": "0m"
  // 				},
  // 				"text_content_metadata": {
  // 					"average_reading_speed"  : "200wpm",
  // 					"duration_breakdown"     : [
  // 						"0m"
  // 					],
  // 					"duration_in_seconds"    : 0,
  // 					"human_readable_duration": "0m",
  // 					"total_words"            : 70
  // 				}
  // 			},
  // 			{
  // 				"id"                   : "3b0193c0-970d-4e50-857f-850cba3b9ae6",
  // 				"proficiency"          : 4,
  // 				"name"                 : "skilled",
  // 				"description"          : [
  // 					"4.1. Analyze the efficiency and performance of a smart grid system.",
  // 					"4.2. Design and implement solutions to address challenges in smart grid integration.",
  // 					"4.3. Evaluate the economic and environmental impact of a smart grid system."
  // 				],
  // 				"available_for_sale"   : false,
  // 				"purchased_by_user"    : false,
  // 				"image_url"            : "https://stgreenskills001.blob.core.windows.net/course-levels/skilled.png",
  // 				"instructor"           : null,
  // 				"stripe"               : null,
  // 				"status_transaction"   : 0,
  // 				"learning_outcome"     : "coming soon",
  // 				"module_titles"        : "coming soon",
  // 				"user_track"           : null,
  // 				"video_metadata"       : null,
  // 				"text_content_metadata": null
  // 			},
  // 			{
  // 				"id"                   : "21448ee0-af46-41f8-bc0b-634475ed9b45",
  // 				"proficiency"          : 5,
  // 				"name"                 : "expert",
  // 				"description"          : [
  // 					"5.1. Lead the development and implementation of a smart grid system on a large scale.",
  // 					"5.2. Coach and mentor others in the field of smart grid implementation and integration.",
  // 					"5.3. Apply advanced knowledge of smart grid technologies to address complex challenges in the industry.",
  // 					"5.4. Develop innovative strategies to optimize the performance of a smart grid system.",
  // 					"5.5. Collaborate with industry leaders to advance the field of smart grid technology."
  // 				],
  // 				"available_for_sale"   : false,
  // 				"purchased_by_user"    : false,
  // 				"image_url"            : "https://stgreenskills001.blob.core.windows.net/course-levels/expert.png",
  // 				"instructor"           : null,
  // 				"stripe"               : null,
  // 				"status_transaction"   : 0,
  // 				"learning_outcome"     : "coming soon",
  // 				"module_titles"        : "coming soon",
  // 				"user_track"           : null,
  // 				"video_metadata"       : null,
  // 				"text_content_metadata": null
  // 			}
  // 		]
  // 	},
  // 	"levels"      : [
  // 		{
  // 			"green_skill_id"       : "b966352b-b4cf-49e7-b172-bc022056a426",
  // 			"id"                   : "cd19df72-ccfc-4113-bfab-9d6168a2b7b8",
  // 			"is_signature_exist"   : true,
  // 			"levels"               : [
  // 				{
  // 					"description"          : [
  // 						"1.1. Define the concepts and technologies involved in smart grid implementation and integration.",
  // 						"1.2. Explain the benefits of using smart grid technology.",
  // 						"1.3. Identify the major components of a smart grid system, such as SCADA systems, IEDs, and communications technology."
  // 					],
  // 					"id"                   : "7d50fa71-1b8b-4cbb-a70f-53e40089cfeb",
  // 					"learning_outcome"     : "this actually a learning outcomes so instructor have to update this and this data will be displayed on certificate",
  // 					"name"                 : "novice",
  // 					"paid_on"              : "2023-11-29T09:44:13.194Z",
  // 					"proficiency"          : 1,
  // 					"text_content_metadata": null,
  // 					"user_track"           : {
  // 						"current_level"       : 1,
  // 						"current_module"      : 0,
  // 						"is_assessment_passed": false,
  // 						"score_assessment"    : 0
  // 					},
  // 					"video_metadata"       : {
  // 						"duration_breakdown"     : [
  // 							"13m"
  // 						],
  // 						"duration_in_seconds"    : 799.5919670000001,
  // 						"human_readable_duration": "13m"
  // 					}
  // 				},
  // 				{
  // 					"description"          : [
  // 						"2.1. Describe the types of data collected by SCADA systems in a smart grid.",
  // 						"2.2. Explain the role of IEDs in the control and automation of smart grid systems.",
  // 						"2.3. Identify the different types of energy sources that can be integrated into a smart grid system."
  // 					],
  // 					"id"                   : "e6949a18-9473-4c46-ad93-1a438909083f",
  // 					"learning_outcome"     : "<p>mamen trying to update the second level <strong>lalallalaal</strong> <em>hoohoho</em></p>",
  // 					"name"                 : "developing",
  // 					"paid_on"              : "2023-08-29T12:17:30.662Z",
  // 					"proficiency"          : 2,
  // 					"text_content_metadata": null,
  // 					"user_track"           : {
  // 						"current_level"       : 2,
  // 						"current_module"      : 0,
  // 						"is_assessment_passed": false,
  // 						"score_assessment"    : 0
  // 					}
  // 				},
  // 				{
  // 					"description"          : [
  // 						"2.1. Describe the types of data collected by SCADA systems in a smart grid.",
  // 						"2.2. Explain the role of IEDs in the control and automation of smart grid systems.",
  // 						"2.3. Identify the different types of energy sources that can be integrated into a smart grid system."
  // 					],
  // 					"id"                   : "e6949a18-9473-4c46-ad93-1a438909083f",
  // 					"learning_outcome"     : "<p>mamen trying to update the second level <strong>lalallalaal</strong> <em>hoohoho</em></p>",
  // 					"name"                 : "developing",
  // 					"paid_on"              : "2023-08-29T12:17:30.662Z",
  // 					"proficiency"          : 3,
  // 					"text_content_metadata": null,
  // 					"user_track"           : {
  // 						"current_level"       : 3,
  // 						"current_module"      : 0,
  // 						"is_assessment_passed": false,
  // 						"score_assessment"    : 0
  // 					}
  // 				},
  // 			],
  // 			"signature_url"        : "https://stgreenskills001.blob.core.windows.net/greenskills-trainer-imgs-test/824c1d06-035e-45d1-8e34-a083d3263ed1",
  // 			"total_available_level": 3,
  // 			"total_completed_level": 2,
  // 			"total_purchased_level": 2
  // 		}
  // 	]
  // }
  const isLoadingSkill = useMemo(() => isLoading, [isLoading])

  let highestCurrentLevel: number | undefined = 0
  let highestCurrentModule: number | undefined = 0

  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    let lastIndex = -1

    const levels = skill?.levels?.[0]?.levels

    for (let i = levels ? levels.length - 1 : -1; i >= 0; i--) {
      if (levels && levels[i]?.user_track?.current_module! > 0) {
        lastIndex = i
        break
      }
    }

    if (lastIndex === -1) {
      lastIndex = 0
    }

    if (isUnlocked) {
      setActiveTab((lastIndex || 0) + 1)
    } else {
      setActiveTab(lastIndex || 0)
    }
  }, [isUnlocked, skill])

  skill?.levels?.forEach((skill: any) => {
    skill?.levels?.forEach((level: any, index: any) => {
      const currentLevel = level?.user_track?.current_level
      const currentModule = level?.user_track?.current_module

      if (
        currentLevel !== undefined &&
        currentModule !== undefined &&
        highestCurrentLevel
      ) {
        if (index === 0) {
          highestCurrentLevel = currentLevel
          highestCurrentModule = currentModule
        } else if (index > 0) {
          if (currentModule > 0) {
            highestCurrentLevel = currentLevel
            highestCurrentModule = currentModule
          } else if (
            currentModule === highestCurrentModule &&
            currentLevel > highestCurrentLevel
          ) {
            highestCurrentLevel = currentLevel
          }
        }
      }
    })
  })

  // Job Plan
  const queryKeyJobPlan = useMemo(() => ["job-plan"], [])

  const fetchJobPlan = async () => {
    try {
      const { data } = await instance.get(`/jobs/plan/${user_id}`)
      return data
    } catch (error) {
      return
    }
  }

  const { data: jobPlan, isLoading: isLoadingJobPlan } = useQuery(
    queryKeyJobPlan,
    fetchJobPlan,
    {
      // enabled: !!skill,
    },
  )

  const jobCareerPlan = useMemo(() => jobPlan?.data, [jobPlan])
  const isLoadingJobCareerPlan = useMemo(
    () => isLoadingJobPlan,
    [isLoadingJobPlan],
  )
  // POST

  const queryClient = useQueryClient()

  const createData = async (data: any) => {
    try {
      const level_id = await skill?.levels[activeTab]?.id
      const response = await instance.post(
        `/learn/assessment/${id}/levels/${level_id}`,
        data,
      )
      return response.data
    } catch (error: any) {
      // handlingError(error?.response?.data?.message, () => {});
      throw new Error(error)
    }
  }

  const mutation = useMutation(createData, {
    onSuccess: () => {
      queryClient.invalidateQueries(["skill-levels"])
    },
  })

  const create = async (data: any) => {
    try {
      await mutation.mutateAsync(data)
    } catch (error) {
      console.error(error)
    }
  }

  const updateData = async (data: any) => {
    try {
      const response = await instance.patch(
        `/paid-skill/user-track/${user_id}/${levelID}`,
        data,
      )
      return response.data
    } catch (error: any) {
      throw new Error(error)
    }
  }
  const mutationUpdateAssesment = useMutation(updateData, {
    onSuccess: () => {
      queryClient.invalidateQueries(["skill-levels"])
      queryClient.invalidateQueries(["paid-module-levels"])
    },
  })

  const update = async (data: any) => {
    try {
      await mutationUpdateAssesment.mutateAsync(data)
    } catch (error) {
      console.error(error)
    }
  }

  const userAssessment = async (data: any) => {
    try {
      const response = await instance.post(
        `/paid-skill/assesment/${user_id}/${levelID}`,
        data,
      )
      return response.data
    } catch (error: any) {
      throw new Error(error)
    }
  }
  const mutationUserAssesment = useMutation(userAssessment, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries(["skill-levels"])
      queryClient.invalidateQueries(["paid-module-levels"])
      return data
    },
  })

  const addAsessment = async (data: any) => {
    try {
      const response = await mutationUserAssesment.mutateAsync(data)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const handleChangeTabs = (tab: any, index: any) => {
    function change() {
      setIsCustomLoading(true)
      setActiveTab(index)
      setTimeout(() => {
        setIsCustomLoading(false)
      }, 1000)
    }

    tab.isLocked ? setActiveTab(activeTab) : change()
  }

  // calculate
  const { overalPresentase } = useCalculateProgress(
    jobCareerPlan?.job,
    // greenSkillUserLevels
  )

  const queryKeySkillsLevels = useMemo(
    () => [
      "bulk-skill-levels",
      {
        skills_id: id,
      },
    ],
    [id],
  )

  const fetchBulk = async () => {
    try {
      const { data } = await instance.get(`/green-skills/levels/bulk`, {
        params: {
          skills_id: id,
          user_id: user_id,
        },
      })
      return data
    } catch (error) {
      return error
      // return handlingError(error?.response?.data?.message, error);
    }
  }

  const { data: levels, isLoading: isLoadingLevels } = useQuery(
    queryKeySkillsLevels,
    fetchBulk,
  )

  //   const handleFetchError = (error: any) => {
  //     message.error(
  //       `An error occurred while fetching green career aspiration: ${error}`
  //     );
  //   };

  const {
    data: greenskill,
    isLoading: isFetchingGreenSkill,
    isError: isFetchErrorGreenSkill,
    error: fetchErrorGreenSkill,
  } = useQuery(["fetch-single-green-skill"], () => fetchSingleGreenSkill(id!), {
    refetchOnWindowFocus: false,
    // onError: handleFetchError,
  })

  return {
    skill,
    isLoadingSkill,
    highestCurrentLevel,
    highestCurrentModule,

    create,
    activeTab,
    setActiveTab,
    handleChangeTabs,
    overalPresentase,
    jobCareerPlan,
    isLoadingJobCareerPlan,
    update,
    addAsessment,
    isCustomLoadingHook,

    levels: {
      data: levels?.data,
      loading: isLoadingLevels,
    },

    greenskillResp: {
      greenskill: greenskill?.data?.data,
      isFetchingGreenSkill,
      isFetchErrorGreenSkill,
      fetchErrorGreenSkill,
    },
  }
}
