import { Spin } from "antd"
// import { lazy, Suspense } from "react"
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import NewUserCommunityPost from "../common/components/NewUserCommunityPost"
import { WithNotifications } from "../common/layout"
import { useProfile } from "../modules/missionControl/hooks/cv-builder/useProfile"
import PrivacyPolicy from "../modules/privacyPolicy/pages/PrivacyPolicy"
import TermOfUse from "../modules/privacyPolicy/pages/TermOfUse"
import { useMsal } from "@azure/msal-react"
import { isMobileSafari, isIOS } from "react-device-detect"
import ErrorPage from "../common/components/ErrorPage"

// const LandingPage = lazy(() => import("../modules/home/page/LandingPage"))
import LandingPage from "../modules/home/page/LandingPage.tsx"
// const MissionControl = lazy(() => import("../modules/missionControl"))
import MissionControl from "../modules/missionControl"
// const SkillGalaxy = lazy(() => import("../modules/skillGalaxy"))
import SkillGalaxy from "../modules/skillGalaxy"
// const JobGalaxy = lazy(() => import("../modules/jobGalaxy"))
import JobGalaxy from "../modules/jobGalaxy"
// const Expert = lazy(() => import("../modules/community/expert"))
import Expert from "../modules/community/expert"
// const Account = lazy(() => import("../modules/account"))
import Account from "../modules/account"
// const Transactions = lazy(() => import("../modules/transactions"))
import Transactions from "../modules/transactions"
// const TalentMarketPlace = lazy(() =>
//   import("../modules/home/page/CVFull").then((module) => ({
//     default: module.CVFull,
//   })),
// )
import { CVFull as TalentMarketPlace } from "../modules/home/page/CVFull.tsx"
// const Perks = lazy(() => import("../modules/perks"))
import Perks from "../modules/perks"
// const Register = lazy(() => import("../modules/auth/page/Register"))
import Register from "../modules/auth/page/Register.tsx"
// const ExpertRegister = lazy(() => import("../modules/auth/page/ExpertRegister"))
import ExpertRegister from "../modules/auth/page/ExpertRegister.tsx"
// const Login = lazy(() => import("../modules/auth/page/Login"))
import Login from "../modules/auth/page/Login.tsx"
// const RegisterRecruiter = lazy(
//   () => import("../modules/auth/page/RegisterRecruiter"),
// )
import RegisterRecruiter from "../modules/auth/page/RegisterRecruiter"
// const Community = lazy(() => import("../modules/community"))
import Community from "../modules/community"
// const NotFound = lazy(() => import("../modules/auth/page/NotFound"))
import NotFound from "../modules/auth/page/NotFound.tsx"
// const About = lazy(() => import("../modules/home/page/About"))
import About from "../modules/home/page/About"

interface ProtectRoutesProps {
  children: React.ReactNode
}

export const ProtectRoutes: React.FC<ProtectRoutesProps> = ({ children }) => {
  const msalContext = useMsal()
  const isAuthenticated = msalContext?.accounts?.length > 0

  if (isMobileSafari || isIOS) {
    return isAuthenticated ? children : <Navigate to="/login" />
  }
  const isNewUser = sessionStorage?.getItem?.("newUser") as
    | "true"
    | "false"
    | null
  const showNewUserCommunityPost = sessionStorage?.getItem?.(
    "showNewUserCommunityPost",
  )
  const location = useLocation()
  const isCommunityFeedPage = location.pathname === "/community/feed"
  const isOpenNewUserCommPostModal =
    isNewUser && showNewUserCommunityPost && isCommunityFeedPage
  return isAuthenticated ? (
    <WithNotifications isNewUser={isNewUser ?? "false"}>
      {children}
      <NewUserCommunityPost
        // @ts-ignore
        open={isOpenNewUserCommPostModal}
        onCancel={() =>
          sessionStorage?.setItem("showNewUserCommunityPost", "false")
        }
      />
    </WithNotifications>
  ) : (
    <Navigate to="/login" />
  )
}

interface PreventRoutesProps {
  children: React.ReactNode
}

export const PreventRoutes: React.FC<PreventRoutesProps> = ({ children }) => {
  const { accounts } = useMsal()
  const isAuthenticated = accounts?.length > 0

  return isAuthenticated ? <Navigate to="/community" /> : children
}

const Index = () => {
  const { account, loadingAccount } = useProfile()
  return (
    // <Suspense
    //   fallback={
    //     <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
    //       <Spin indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />
    //     </div>
    //   }
    // >
    <Routes>
      <Route
        path="/"
        element={
          <PreventRoutes>
            <LandingPage />
          </PreventRoutes>
        }
      />
      <Route
        path="/about"
        element={
          <PreventRoutes>
            <About />
          </PreventRoutes>
        }
      />
      <Route
        path="/login"
        element={
          <PreventRoutes>
            <Login />
          </PreventRoutes>
        }
      />
      {/* <Route
          path="/login-as"
          element={
            <PreventRoutes>
              <LoginFlow />
            </PreventRoutes>
          }
        /> */}
      <Route
        path="/register"
        element={
          <PreventRoutes>
            <Register />
          </PreventRoutes>
        }
      />
      <Route
        path="/register-expert"
        element={
          <PreventRoutes>
            <ExpertRegister />
          </PreventRoutes>
        }
      />
      <Route
        path="/register-recruitment"
        element={
          <PreventRoutes>
            <RegisterRecruiter />
          </PreventRoutes>
        }
      />

      <Route
        path="/account/*"
        element={
          <ProtectRoutes>
            <Account />
          </ProtectRoutes>
        }
      />

      <Route
        path="/talent-marketplace/cv-full/:id"
        element={<TalentMarketPlace />}
      />
      <Route
        path="/talent-marketplace/cv-anonymised/:id"
        element={<TalentMarketPlace isAnonymised />}
      />
      <Route path="/job-posts" element={<>/job-posts</>} />
      <Route path="/job-posts" element={<>/job-posts</>} />

      <Route
        path="/dashboard/*"
        element={
          <ProtectRoutes>
            <MissionControl />
          </ProtectRoutes>
        }
      />
      <Route
        path="/cart/*"
        element={
          <ProtectRoutes>
            <Transactions />
          </ProtectRoutes>
        }
      />
      <Route
        path="/skill/*"
        element={
          // <ProtectRoutes>
          <SkillGalaxy />
          // </ProtectRoutes>
        }
      />
      <Route
        path="/job/*"
        element={
          // <ProtectRoutes>
          <JobGalaxy />
          // </ProtectRoutes>
        }
      />

      <Route
        path="/community/*"
        element={
          // <ProtectRoutes>
          <Community />
          // </ProtectRoutes>
        }
      />
      {/* @ts-ignore */}
      {account?.is_expert && (
        <Route
          path="/expert/*"
          element={
            <ProtectRoutes>
              <Expert />
            </ProtectRoutes>
          }
        />
      )}

      <Route path="/rewards-and-perks" element={<Perks />} />

      <Route path="/error" element={<ErrorPage />} />

      <Route path="/privacy-policy/*" element={<PrivacyPolicy />} />
      <Route path="/term-of-use/*" element={<TermOfUse />} />

      {!loadingAccount ? (
        <Route path="*" element={<NotFound />} />
      ) : (
        <Route
          path="*"
          element={
            <div className="absolute top-1/2 left-1/2">
              <Spin size="large" />
            </div>
          }
        />
      )}
    </Routes>
    // </Suspense>
  )
}

export default Index
