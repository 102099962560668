import React from "react"
import { Button, Card, Select } from "antd"
import { Controller } from "react-hook-form"
import {
  Common,
  Language,
  Proficiency,
} from "../../../hooks/cv-builder/useLanguage"
import { useLanguageUpdate } from "../../../hooks/cv-builder/useLanguageUpdate"

interface Props {
  data: Language
  proficiency: {
    data: Proficiency[] | undefined
    loading: boolean
  }
  commonLanguage: {
    data: Common[] | undefined
    loading: boolean
  }
  index: number
  updateTempData: (index: number, data: Language) => void
  tempData: Language[]
}

export const LanguageEdit: React.FC<Props> = ({
  data,
  proficiency,
  commonLanguage,
  index,
  updateTempData,
  tempData,
}) => {
  const { control, errors, handleSubmit, onSubmit, loading } =
    useLanguageUpdate(data, index, updateTempData)

  return (
    <form className="w-full p-5 rounded-xl bg-white shadow-md mb-5">
      <div className="flex gap-5 flex-wrap lg:flex-nowrap">
        <Card className="shadow-md w-full rounded-xl flex-grow basis-1/2 bg-[#FAFAFA]">
          <label className="" htmlFor="language_id">
            <h3>Language</h3>
          </label>
          <Controller
            name="language_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                id="language_id"
                className="my-1 font-default w-full job-history"
                placeholder="select"
                showSearch
                status={errors?.language_id ? "error" : ""}
                options={commonLanguage?.data?.map((val) => ({
                  label: val.name,
                  value: val.id,
                  disabled: tempData.find(
                    (item) => item.language.id === val.id,
                  ),
                }))}
                labelInValue={true}
                filterOption={(input, option) => {
                  if (option && option.label) {
                    return (
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    )
                  }
                  return false
                }}
              />
            )}
          />
        </Card>
        <Card className=" shadow-md w-full rounded-xl flex-grow basis-1/2 bg-[#FAFAFA]">
          <label className="font-semibold" htmlFor="proficiency_id">
            <h3>Proficiency</h3>
          </label>
          <Controller
            name="proficiency_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                id="proficiency_id"
                className="my-1 font-default w-full job-history"
                placeholder="select"
                showSearch
                status={errors?.proficiency_id ? "error" : ""}
                options={proficiency?.data?.map((val) => ({
                  label: val.name,
                  value: val.id,
                  title: val.level,
                }))}
                labelInValue={true}
                filterOption={(input, option) => {
                  if (option && option.label) {
                    return (
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    )
                  }
                  return false
                }}
              />
            )}
          />
        </Card>
      </div>
      <div className="mt-5 flex justify-end gap-5">
        <Button
          type="primary"
          ghost
          onClick={() => updateTempData(index, { ...data, isEdit: false })}
        >
          Cancel
        </Button>

        <Button
          type="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={loading}
        >
          Save
        </Button>
      </div>
    </form>
  )
}
