import { Image, Progress } from "antd"
import React from "react"
import { useNavigate } from "react-router-dom"
import { Moon } from "../../../../assets/images"
// eslint-disable-next-line no-unused-vars
import { Article, MyArticleResponseTypes } from "../types/response"

interface ExpertProfileSkillsProps {
  myArticles: any
}

const ExpertProfileSkills: React.FC<ExpertProfileSkillsProps> = ({
  myArticles,
}) => {
  const navigate = useNavigate()

  return (
    <div className="bg-white-custom px-4 py-6 rounded-xl shadow-custom-sm">
      <h4>My Expert Skills</h4>

      {myArticles?.dataList?.length === 0 ? (
        <div className="flex justify-center mt-5">
          <div className="text-center">
            <p className="">
              Creating 5{" "}
              <span
                onClick={() => navigate("/expert/my/article")}
                className="text-secondary cursor-pointer hover:font-bold"
              >
                expert articles
              </span>{" "}
              in a skill earns you this skill Expert badge
            </p>
            <Image
              preview={false}
              className=""
              src={Moon}
              alt="profile"
              width={100}
            />
          </div>
        </div>
      ) : (
        <div className="mt-5 grid grid-cols-12 gap-x-7 gap-y-4 grid-flow-row">
          {myArticles?.dataList?.map(
            (article: MyArticleResponseTypes, index: number) => {
              const itemArticle = article?.green_skill_category?.article?.map(
                (item: Article) => item,
              )
              const uniqueSkillIds = new Set()
              return (
                <div
                  key={index}
                  className="mb-3 col-span-12 md:col-span-6 shadow-custom-sm rounded-xl p-4 grid-flow-col"
                >
                  <p className="tracking-widest uppercase text-[#966F02] text-xs">
                    {article?.green_skill_category?.name}
                  </p>
                  <div className="grid grid-rows-12 gap-x-16  mb-3">
                    {itemArticle?.map(
                      (articleItem: any, articleIndex: number) =>
                        articleItem?.green_skills?.map(
                          (skill: any, i: number) => {
                            // Check if the skill ID is already processed
                            if (!uniqueSkillIds.has(skill.id)) {
                              // If not, add the ID to the set
                              uniqueSkillIds.add(skill.id)

                              return (
                                <div
                                  key={`${articleIndex}-${i}`} // Use a combination of indices to create a unique key
                                  className="row-span-6 hover:bg-secondaryBlue cursor-pointer hover:px-3 transition-all hover:rounded-xl"
                                  onClick={() =>
                                    navigate(`/skill/green-skills/${skill?.id}`)
                                  }
                                >
                                  <div className="flex justify-between">
                                    <p className="font-semibold">
                                      {skill?.name}
                                    </p>
                                    <p className="text-xs text-[#966F02]">
                                      {articleItem?.total_article_by_skill!} of
                                      5 Articles
                                    </p>
                                  </div>
                                  <Progress
                                    percent={
                                      (articleItem?.total_article_by_skill! /
                                        5) *
                                      100
                                    }
                                  />
                                </div>
                              )
                            } else {
                              // Skill ID already processed, return null or some placeholder
                              return null
                            }
                          },
                        ),
                    )}
                  </div>
                </div>
              )
            },
          )}
        </div>
      )}
    </div>
  )
}

export default ExpertProfileSkills
