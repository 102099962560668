import React from "react"
import { Button } from "antd"
import { Moon } from "../../../../assets/images"
import useCommunityModalsStore from "../../store/useCommunityModalsStore"
import ClimateSolutionsProductCard from "./ClimateSolutionsProductCard"
import useFounderAndStartupData from "../hooks/useFounderAndStartup"
import { useQuery } from "react-query"
import { getStartupProducts } from "../apis/founders"
import { showError } from "../../../../common/utils/utils"
import { LoadingOutlined } from "@ant-design/icons"

const ClimateSolutions = () => {
  const { startupQuery } = useFounderAndStartupData()

  const { data, isLoading } = useQuery(
    ["startup-products", startupQuery?.data?.data?.data?.id],
    () => getStartupProducts(startupQuery?.data?.data?.data?.id!),
    {
      enabled: !!startupQuery?.data?.data?.data?.id,
      onError: () => {
        showError({
          message: "Failed to fetch products",
          key: "startup-products",
        })
      },
    },
  )

  const doProductsExist =
    data?.data?.data?.length && data?.data?.data?.length > 0

  const addEditCompanyProductModal = useCommunityModalsStore(
    (state) => state.addEditCompanyProductModal,
  )

  if (isLoading) {
    return (
      <div className="h-[250px] flex items-center justify-center">
        <LoadingOutlined className="text-xl" />
      </div>
    )
  }
  return (
    <div>
      <div className="flex items-center justify-between mt-4">
        <h4 className="!font-semibold !leading-[38px] text-xl">
          My Climate Solutions
        </h4>
        <Button
          onClick={() => addEditCompanyProductModal.open(null)}
          type="primary"
        >
          add products
        </Button>
      </div>
      {doProductsExist ? (
        <div className="space-y-6 mt-6">
          {data?.data?.data?.map((product: any, index: number) => (
            <ClimateSolutionsProductCard
              startup_id={startupQuery?.data?.data?.data?.id!}
              key={index}
              product={product}
            />
          ))}
        </div>
      ) : (
        <>
          <div className="flex flex-col items-center justify-center gap-5 text-center p-7">
            <img
              src={Moon}
              className="h-[116px] object-contain aspect-square"
              alt="empty"
            />
            <div className="text-base leading-6">
              Use this space to showcase the{" "}
              <span className="font-semibold text-primary cursor-pointer">
                innovative climate solutions
              </span>{" "}
              your startup offers
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default ClimateSolutions
