/* eslint-disable */

import { Image } from "antd"
import React, { memo } from "react"
import { Astro } from "../../../assets/images"

interface Props {
  name: string
  image_url: string
  description: string
  isLocked: boolean
  groupCode:
    | "eco-commitment-honors"
    | "green-skills-honors"
    | "green-jobs-honors"
}

const Badge: React.FC<Props> = memo(
  ({ name, image_url, description, isLocked, groupCode }: Props) => {
    return (
      <div className="relative">
        <div className="flex flex-col gap-3 items-center justify-center bg-white p-2">
          <div className="font-bold text-backdrop h-4 text-base capitalize text-center mb-3">
            {name}
          </div>
          <Image
            preview={false}
            fallback={Astro}
            src={image_url}
            height={90}
            // width={104}
            placeholder={groupCode}
            className={`${
              isLocked
                ? "inset-0 bg-opacity-20 opacity-20 hover:scale-110 transition-transform duration-300 object-contain"
                : " hover:scale-110 transition-transform duration-300 object-contain"
            }`}
          />
          {true ? (
            <div className="text-backdrop text-sm font-thin z-50 text-center">
              {description}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    )
  },
)

export default Badge
