import React, { useMemo } from "react"
import instance from "../../../config/config"
import { useQuery } from "react-query"
import { FeatureSkill } from "../types/response"
import { message } from "antd"
import ErrorMessageBuilder from "../../../common/components/ErrorMessageBuilder"

const useLandingPage = () => {
  const queryKey = useMemo(() => ["school-with-course", {}], [])

  const fetchSchoolWithCourse = async () => {
    const { data } = await instance.get<{ data: FeatureSkill[] }>(
      `/schools/feature-skill`,
      {
        params: {},
      },
    )
    return data
  }

  const { data: courseSkill, isLoading } = useQuery(
    queryKey,
    fetchSchoolWithCourse,
    {
      onError: () => {
        message.open({
          key: "school-with-course",
          duration: 5,
          content: React.createElement(ErrorMessageBuilder, {
            message:
              "We encountered an issue while attempting to fetch featured skills. If the problem continues, please refresh the page.",
            includeReportAt: true,
          }),
          type: "error",
        })
      },
      retry: 2,
    },
  )
  return { courseSkill, isLoading }
}

export default useLandingPage
