/* eslint-disable */

import { Card } from "antd"
import React from "react"
import { BulbOutlined } from "@ant-design/icons"
import { OtherCompany } from "../../../../assets/images"
import { School } from "../../types/common"
import { useNavigate } from "react-router-dom"

interface Props {
  data: School
  observ?: any
}

export const CardSchool: React.FC<Props> = ({ data, observ }) => {
  const navigate = useNavigate()
  return (
    <Card
      ref={observ}
      className=" shadow-md rounded-xl text-center hover:shadow-lg cursor-pointer"
      onClick={() => navigate(`/skill/schools/${data?.id}`)}
    >
      <h4 className=" capitalize">{data.name || "unknown"}</h4>
      <img
        className=" w-44 h-28 object-contain"
        src={data?.logo || OtherCompany}
        alt="school"
      />
      <div className="flex justify-between">
        <div>{data.location}</div>
        <div className="flex gap-2 items-center">
          <BulbOutlined />
          <div>{`${data?.total_assigned_skill || 0} skills`}</div>
        </div>
      </div>
    </Card>
  )
}
