import React from "react"
import { Tag } from "antd"
import { HeartOutlined, HeartFilled, LoadingOutlined } from "@ant-design/icons"
import { withAuthentication } from "../../../common/components"

interface TagFavoriteSkillProps {
  skill_id?: string
  isFav: boolean | undefined
  isLoading: boolean
  onClick: React.MouseEventHandler<HTMLSpanElement> | undefined
}

export const TagFavoriteSkill = withAuthentication(
  React.memo(({ isFav, isLoading, onClick }: TagFavoriteSkillProps) => {
    return (
      <Tag
        bordered={false}
        onClick={onClick}
        color={isFav ? undefined : "volcano"}
        className={`${
          isFav ? "bg-primary-bg" : "text-backdrop"
        } max-w-full overflow-clip group hover:scale-105 duration-200 transition-all cursor-pointer`}
        icon={
          isLoading ? (
            <LoadingOutlined />
          ) : isFav ? (
            <HeartFilled key={"favorite"} className="!text-primary" />
          ) : (
            <HeartOutlined className="hover:text-primary" key="favorite" />
          )
        }
      >
        {isFav ? "Saved Skill" : "Save for later"}
      </Tag>
    )
  }),
)
