import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { useTrajectory } from "../hooks/useTrajectory"
import { BasicContainerWithTitle } from "../../../common/components/BasicBoxContainers/BasicContainerWithTitle"
import { Button } from "antd"
import { FirstSkill } from "./launchpad/FirstSkill"
import { SecondThirdSkill } from "./launchpad/SecondThirdSkill"
import { OtherSkill } from "./launchpad/OtherSkills"
import {
  ITourContext,
  TourContext,
} from "../../../common/components/tour/TourProvider"

interface CurrentTrajectoryProps {}

export const CurrentTrajectory: React.FC<CurrentTrajectoryProps> = () => {
  const { data, isLoading } = useTrajectory()
  const trajectorySkills = data?.data?.data

  const navigate = useNavigate()
  const areSkillsGreaterThanThree = (trajectorySkills ?? [])?.length > 3

  const {
    refs: { refCurrentTrajectory },
  } = useContext(TourContext) as ITourContext

  return (
    <BasicContainerWithTitle
      className="-mb-6"
      ref={refCurrentTrajectory}
      title="Current Trajectory"
      titleSize="h3"
      extraTitle={
        <Button
          onClick={() =>
            navigate("/dashboard/skill-trajectory-plan/skill-roadmap")
          }
        >
          My Learning Roadmap
        </Button>
      }
    >
      <div
        className={
          "flex flex-wrap lg:flex-nowrap gap-[25px] justify-center items-center md:justify-start md:items-start mt-5 max-w-full"
        }
      >
        {areSkillsGreaterThanThree ? (
          <SecondThirdSkill
            defaultValue={trajectorySkills?.[0]?.total_completed_levels ?? 0}
            secondValue={
              trajectorySkills?.[0] &&
              trajectorySkills?.[0]?.current_level_progress >=
                trajectorySkills?.[0]?.total_completed_levels &&
              trajectorySkills?.[0]?.learning_progress > 0
            }
            showAddSkillButton={false}
            isLoading={isLoading}
            isUndefined={trajectorySkills?.[0] === undefined}
            indexToLearn={
              trajectorySkills?.[0]?.current_level_progress
                ? trajectorySkills?.[0]?.current_level_progress - 1
                : 1
            }
            skillId={trajectorySkills?.[0]?.id}
            skillName={trajectorySkills?.[0]?.name}
            skillImage={trajectorySkills?.[0]?.image}
          />
        ) : (
          <FirstSkill skill={trajectorySkills?.[0]} isLoading={isLoading} />
        )}
        <SecondThirdSkill
          defaultValue={trajectorySkills?.[1]?.total_completed_levels ?? 0}
          secondValue={
            trajectorySkills?.[1] &&
            trajectorySkills?.[1]?.current_level_progress >=
              trajectorySkills?.[1]?.total_completed_levels &&
            trajectorySkills?.[1]?.learning_progress > 0
          }
          showAddSkillButton={trajectorySkills?.length === 1}
          isLoading={isLoading}
          isUndefined={trajectorySkills?.[1] === undefined}
          indexToLearn={
            trajectorySkills?.[1]?.current_level_progress
              ? trajectorySkills?.[1]?.current_level_progress - 1
              : 1
          }
          skillId={trajectorySkills?.[1]?.id}
          skillName={trajectorySkills?.[1]?.name}
          skillImage={trajectorySkills?.[1]?.image}
        />
        <SecondThirdSkill
          defaultValue={trajectorySkills?.[2]?.total_completed_levels ?? 0}
          secondValue={
            trajectorySkills?.[2] &&
            trajectorySkills?.[2]?.current_level_progress >=
              trajectorySkills?.[2]?.total_completed_levels &&
            trajectorySkills?.[2]?.learning_progress > 0
          }
          showAddSkillButton={trajectorySkills?.length === 2}
          isLoading={isLoading}
          isUndefined={trajectorySkills?.[2] === undefined}
          indexToLearn={
            trajectorySkills?.[2]?.current_level_progress
              ? trajectorySkills?.[2]?.current_level_progress - 1
              : 1
          }
          skillId={trajectorySkills?.[2]?.id}
          skillName={trajectorySkills?.[2]?.name}
          skillImage={trajectorySkills?.[2]?.image}
        />
        {areSkillsGreaterThanThree && (
          <div className="flex flex-col flex-grow max-w-full gap-4 ">
            <OtherSkill skill={trajectorySkills?.[3]} isLoading={isLoading} />
            {trajectorySkills?.[4] && (
              <OtherSkill skill={trajectorySkills?.[4]} isLoading={isLoading} />
            )}
          </div>
        )}
      </div>
    </BasicContainerWithTitle>
  )
}
