import { useMutation, useQuery } from "react-query"
import instance, { instanceV2 } from "../../../config/config"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { message } from "antd"
import { msalInstance } from "../../.."
import { errorMessage } from "../../../common/utils/utils"

export const useProfile = () => {
  const user = msalInstance.getAllAccounts()[0]

  const [imageUrl, setImageUrl] = useState("")
  const [imageFile, setImageFile] = useState(null)

  const {
    data: account,
    isLoading: loadingAccount,
    isError: errorAccount,
  } = useQuery("account-v1", () => instanceV2.get("/accounts/detail"), {
    retry: false,
    enabled: !!user,
  })

  // image
  const { mutate: uploadImage } = useMutation({
    mutationFn: (val) => {
      return instance.post(`/accounts/upload`, val)
    },
    mutationKey: "updload-image",
  })
  const handleImageChange = (event) => {
    const file = event.target.files[0]
    const imageUrl = URL.createObjectURL(file)
    setImageUrl(imageUrl)
    setImageFile(file)
  }
  useEffect(() => {
    if (account?.data?.data?.profile_picture) {
      setImageUrl(`${account?.data?.data?.profile_picture}`)
    }
  }, [account?.data?.data?.profile_picture])

  //   handle update info profile
  const {
    mutate: info,
    isLoading: loadingInfo,
    isSuccess: successInfo,
  } = useMutation({
    mutationFn: (val) => {
      return instance.post(`/accounts/info`, val)
    },
    mutationKey: "account-info",
  })
  const handleUpdateNoUpload = (val) => {
    info(val, {
      onSuccess: () => {
        message.success("Success update info profile")
        // queryClient.invalidateQueries(["account"]);
      },
      onError: (error) =>
        errorMessage(
          error?.response?.data?.data || "Internal server error",
          error,
        ),
    })
  }
  const handleUpdateWithUpload = (val) => {
    const formData = new FormData()
    formData.append("file", imageFile)
    uploadImage(formData, {
      onSuccess: (res) => {
        info(
          {
            ...val,
            profile_picture: res.data.data,
          },
          {
            onSuccess: () => {
              setImageFile(null)
              message.success("Success update info profile")
              // queryClient.invalidateQueries(["account"]);
            },
          },
        )
      },
      onError: (err) => {
        errorMessage(err?.response?.data?.data || "something wrong", error)
      },
    })
  }
  const updateInfo = (val) => {
    if (imageFile) {
      handleUpdateWithUpload(val)
    } else {
      handleUpdateNoUpload(val)
    }
  }

  //   handle update about profile
  const {
    mutate: about,
    isLoading: loadingAbout,
    isSuccess: successAbout,
  } = useMutation({
    mutationFn: (val) => {
      return instance.post(`/accounts/about-me`, val)
    },
    mutationKey: "account-about",
  })
  const updateAbout = (val) => {
    about(val, {
      onSuccess: () => {
        message.success("Success update info profile")
        // queryClient.invalidateQueries(["account"]);
      },
      onError: (error) =>
        errorMessage(error?.response?.data?.data || "something wrong", error),
    })
  }

  //   handle update contact profile
  const {
    mutate: contact,
    isLoading: loadingContact,
    isSuccess: successContact,
  } = useMutation({
    mutationFn: (val) => {
      return instance.post(`/accounts/socials`, val)
    },
    mutationKey: "account-socials",
  })
  const updateContact = (val) => {
    contact(
      {
        country_id: val.country,
        links: {
          email: val.email,
          linkedin: val.linkedin,
          twitter: val.twitter,
          link: val.link,
        },
      },
      {
        onSuccess: () => {
          message.success("Success update info profile")
          // queryClient.invalidateQueries(["account"]);
        },
        onError: (error) =>
          errorMessage(error?.response?.data?.data || "something wrong", error),
      },
    )
  }

  //   handle update languages profile
  const {
    mutate: languages,
    isLoading: loadingLanguages,
    isSuccess: successLanguages,
  } = useMutation({
    mutationFn: (val) => {
      return instance.post(`/accounts/languages`, val)
    },
    mutationKey: "account-languages",
  })
  const updateLanguages = (val) => {
    languages(
      {
        languages: val.languages.map((item) => ({
          id: item.name,
          proficiency_id: item.proficiency,
        })),
      },
      {
        onSuccess: () => {
          message.success("Success update info profile")
          // queryClient.invalidateQueries(["account"]);
        },
        onError: (error) =>
          errorMessage(error?.response?.data?.data || "something wrong", error),
      },
    )
  }

  //   handle update edu profile
  const [skillsEdu, setSkillsEdu] = useState([])
  const [updatedDataEdu, setUpdatedDataEdu] = useState([])
  const {
    mutate: education,
    isLoading: loadingEducation,
    isSuccess: successEdu,
    data: dataSuccessEdu,
  } = useMutation({
    mutationFn: (val) => {
      return instance.post(`/accounts/educations`, val)
    },
    mutationKey: "account-educations",
  })
  const updateEducation = (val) => {
    let form = val.edu.map((item, i) => ({
      ...item,
      id: item.edu_id,
      online:
        item.online === "true" ? true : item.online === "false" ? false : null,
      date: {
        start_at: item.start_at ? dayjs(item.start_at).format() : null,
        end_at: item.end_at ? dayjs(item.end_at).format() : null,
      },
      skills: skillsEdu[i],
    }))

    const deleteEdu =
      (updatedDataEdu?.length > 0
        ? updatedDataEdu
        : account?.data?.data?.education
      )
        ?.filter((el) => !form.some((dataNow) => dataNow.id === el.id))
        .map((item) => ({ id: item.id, is_removed: true })) || []

    form = [...form, ...deleteEdu]

    education(form, {
      onSuccess: () => {
        message.success("Success update info profile")
        // queryClient.invalidateQueries(["account"]);
      },
      onError: (error) =>
        errorMessage(error?.response?.data?.data || "something wrong", error),
    })
  }
  useEffect(() => {
    setUpdatedDataEdu(dataSuccessEdu?.data?.data || [])
  }, [dataSuccessEdu])

  // history
  const [skillsHistory, setSkillsHistory] = useState([])
  const [updatedDataHistory, setUpdatedDataHistory] = useState([])
  const {
    mutate: history,
    isLoading: loadingHistory,
    isSuccess: successHistory,
    data: dataSuccessHistory,
  } = useMutation({
    mutationFn: (val) => {
      return instance.post(`/accounts/employment-history`, val)
    },
    mutationKey: "account-history",
  })
  const updateHistory = (val) => {
    let form = val.history.map((item, i) => ({
      ...item,
      id: item.history_id,
      remote:
        item.remote === "true" ? true : item.remote === "false" ? false : null,
      current_role:
        item.current_role === "true"
          ? true
          : item.current_role === "false"
            ? false
            : null,
      date: {
        start_at: item.start_at ? dayjs(item.start_at).format() : null,
        end_at:
          item.current_role === "false"
            ? item.end_at
              ? dayjs(item.end_at).format()
              : null
            : null,
      },
      skills: skillsHistory[i],
    }))

    const deleteHistory =
      (updatedDataHistory?.length > 0
        ? updatedDataHistory
        : account?.data?.data?.employment_history
      )
        ?.filter((el) => !form.some((dataNow) => dataNow.id === el.id))
        .map((item) => ({ id: item.id, is_removed: true })) || []

    form = [...form, ...deleteHistory]

    history(form, {
      onSuccess: () => {
        message.success("Success update info profile")
        // queryClient.invalidateQueries(["account"]);
      },
      onError: (error) =>
        errorMessage(error?.response?.data?.data || "something wrong", error),
    })
  }
  useEffect(() => {
    setUpdatedDataHistory(dataSuccessHistory?.data?.data || [])
  }, [dataSuccessHistory])

  // certifications
  const [skillsCerfification, setSkillsCerfification] = useState([])
  const [updatedDataCertification, setUpdatedDataCertification] = useState([])
  const {
    mutate: certification,
    isLoading: loadingCertification,
    isSuccess: successCertification,
    data: dataSuccessCertification,
  } = useMutation({
    mutationFn: (val) => {
      return instance.post(`/accounts/certifications`, val)
    },
    mutationKey: "account-certifications",
  })
  const updateCertification = (val) => {
    let form = val.certif.map((item, i) => ({
      ...item,
      id: item.certif_id,
      has_expiry_date:
        item.has_expiry_date === "true"
          ? true
          : item.has_expiry_date === "false"
            ? false
            : null,
      date: {
        start_at: item.start_at ? dayjs(item.start_at).format() : null,
        end_at:
          item.has_expiry_date !== "false"
            ? item.end_at
              ? dayjs(item.end_at).format()
              : null
            : null,
      },
      skills: skillsCerfification[i],
    }))

    const deleteCertif =
      (updatedDataCertification?.length > 0
        ? updatedDataCertification
        : account?.data?.data?.certification
      )
        ?.filter((el) => !form.some((dataNow) => dataNow.id === el.id))
        .map((item) => ({ id: item.id, is_removed: true })) || []

    form = [...form, ...deleteCertif]
    certification(form, {
      onSuccess: () => {
        message.success("Success update info profile")
        // queryClient.invalidateQueries(["account"]);
      },
      onError: (error) =>
        errorMessage(error?.response?.data?.data || "something wrong", error),
    })
  }

  useEffect(() => {
    setUpdatedDataCertification(dataSuccessCertification?.data?.data || [])
  }, [dataSuccessCertification])

  return {
    account,
    loadingAccount,
    errorAccount,
    updateInfo,
    loadingInfo,
    successInfo,
    updateAbout,
    loadingAbout,
    successAbout,
    updateContact,
    loadingContact,
    successContact,
    updateLanguages,
    loadingLanguages,
    successLanguages,
    updateEducation,
    loadingEducation,
    successEdu,
    dataSuccessEdu: dataSuccessEdu?.data?.data,
    skillsEdu,
    setSkillsEdu,
    skillsHistory,
    setSkillsHistory,
    loadingHistory,
    successHistory,
    dataSuccessHistory: dataSuccessHistory?.data?.data,
    updateHistory,
    skillsCerfification,
    setSkillsCerfification,
    successCertification,
    dataSuccessCertification: dataSuccessCertification?.data?.data,
    updateCertification,
    loadingCertification,
    imageUrl,
    handleImageChange,
  }
}
