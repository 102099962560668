import { useMemo } from "react"
import { useQuery } from "react-query"
import instance from "../../../config/config"

export const useCertificate = (
  id: string | undefined,
  userID: string | undefined,
) => {
  const queryKeySkillsLevels = useMemo(
    () => [
      "bulk-skill-levels",
      {
        skills_id: id,
      },
    ],
    [id],
  )

  const fetchBulk = async () => {
    try {
      const { data } = await instance.get(`/green-skills/levels/bulk`, {
        params: {
          skills_id: id,
          user_id: userID,
        },
      })
      return data
    } catch (error) {
      return error
      // return handlingError(error?.response?.data?.message, error);
    }
  }

  const { data: levels, isLoading: isLoadingLevels } = useQuery(
    queryKeySkillsLevels,
    fetchBulk,
  )

  return {
    levels,
    isLoadingLevels,
  }
}
