import React from "react"
import { Card, Input, Select, Tag } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { Common } from "../../../missionControl/hooks/cv-builder/useJobHistory"

interface Props {
  handleSearch: (...args: any[]) => void
  defaultValue: string
  locations: Common[] | undefined
  loadingLocation: boolean
  handleAddLocation: (value: string) => void
  selectedLocation: string
  handleRemoveLocation: (value: string) => void
}

export const FilterSchool: React.FC<Props> = ({
  handleSearch,
  defaultValue,
  locations,
  loadingLocation,
  handleAddLocation,
  selectedLocation,
  handleRemoveLocation,
}) => {
  return (
    <Card className="bg-[#FAFAFAFA] shadow-md rounded-xl">
      <div className="flex gap-3">
        <div
          className="flex w-2/3"
          style={{ borderBottom: "1px solid #D0DDE5" }}
        >
          <SearchOutlined />
          <Input
            bordered={false}
            defaultValue={defaultValue}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search for a school"
          />
        </div>
        <div className="bg-grey-select">
          <Select
            showSearch
            placeholder="Filter by Location"
            optionFilterProp="children"
            options={locations?.map((location) => ({
              label: location.name,
              value: location.id,
              // disabled: true,
              disabled: !!selectedLocation
                ?.split(",")
                .find((item) => item === location.name),
            }))}
            loading={loadingLocation}
            // @ts-ignore
            onSelect={handleAddLocation}
            value={null}
            filterOption={(input, option) => {
              if (option && option.label) {
                return (
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                )
              }
              return false
            }}
          />
        </div>
      </div>
      {selectedLocation && (
        <div className="mt-3 flex gap-2">
          <div>Location:</div>
          <div>
            {selectedLocation.split(",").map((location, i) => (
              <Tag
                key={i}
                closable
                className=" me-2"
                onClose={() => handleRemoveLocation(location)}
              >
                {location}
              </Tag>
            ))}
          </div>
        </div>
      )}
    </Card>
  )
}
