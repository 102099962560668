import { Button, Card, Image, Input, Select } from "antd"
import React from "react"
import { useBasicUpdate } from "../../../missionControl/hooks/cv-builder/useBasicUpdate"
import { useBasic } from "../../../missionControl/hooks/cv-builder/useBasic"
import { Controller } from "react-hook-form"
// import { assignAvatar } from "../../../assets/avatar_images"
import { useProfile } from "../../../missionControl/hooks/cv-builder/useProfile"
import {
  MailOutlined,
  TwitterOutlined,
  LinkedinFilled,
  LinkOutlined,
} from "@ant-design/icons"
import { useSocialUpdate } from "../../../missionControl/hooks/cv-builder/useSocialUpdate"
import { RichText } from "../../../../common/components/RichText"

interface ExpertProfileEditProps {
  editAction: any
}

const ExpertProfileEdit: React.FC<ExpertProfileEditProps> = ({
  editAction,
}) => {
  const { account: data } = useProfile()

  const b = "https://stgreenskills001.blob.core.windows.net/default-avatars/"

  const {
    // isUpdate,
    handleUpdate,
    // dataBasic,
    // dataSocial,
    countries,
  } = useBasic(data)

  const {
    control,
    errors,
    // watch,
    handleSubmit,
    onSubmit,
    imageUrl,
    handleImageChange,
    handleRemoveImage,
    loadingImage,
    loadingUpdate,
  } = useBasicUpdate(data, handleUpdate)

  const {
    control: controlSocial,
    errors: errorsSocial,
    handleSubmit: handleSubmitSocial,
    onSubmit: onSubmitSocial,
    loadingUpdate: loadingUpdateSocial,
  } = useSocialUpdate(data, () => {})

  const UpdateHandler = () => {
    handleSubmit(onSubmit)()
    handleSubmitSocial(onSubmitSocial)()
    setTimeout(() => {
      editAction?.setIsEditView(false)
    }, 1100)
  }

  return (
    <div className="bg-white px-4 py-6 rounded-xl shadow-custom-sm relative grid grid-cols-12 gap-6">
      <div className="w-full rounded-lg col-span-5 space-y-5">
        <Card className="shadow w-full rounded-xl relative bg-[#FAFAFA]">
          <div className="flex items-center flex-col mb-1">
            <Image
              width={120}
              height={120}
              className="object-cover"
              // preview={false}
              src={imageUrl}
              alt="profile"
            />
            <div className="flex gap-3 text-xs my-2">
              <label
                htmlFor="image-profile"
                className=" text-secondary cursor-pointer"
              >
                Replace
              </label>
              {!imageUrl?.startsWith(b) && (
                <div
                  className="text-danger cursor-pointer"
                  onClick={() => {
                    if (!loadingImage) handleRemoveImage()
                  }}
                >
                  Remove
                </div>
              )}
              <input
                disabled={loadingImage}
                type="file"
                accept="image/png, image/gif, image/jpeg"
                id="image-profile"
                className=" hidden"
                value={""}
                onChange={(e) => {
                  handleImageChange(e)
                }}
              />
            </div>
          </div>
          <div className="flex gap-3">
            <Controller
              name="first_name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  status={errors?.first_name ? "error" : ""}
                  className="my-1 font-default rounded basis-1/2"
                  placeholder="first name"
                  value={field.value}
                />
              )}
            />
            <Controller
              name="last_name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  status={errors?.last_name ? "error" : ""}
                  className="my-1 font-default rounded basis-1/2"
                  placeholder="last name"
                  value={field.value || data?.last_name}
                />
              )}
            />
          </div>
          <div className="flex gap-3">
            <Controller
              name="country"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  className="my-1 font-default basis-full job-history"
                  placeholder="Location"
                  showSearch
                  status={errors?.country ? "error" : ""}
                  options={countries?.data?.map((val) => ({
                    label: val.name,
                    value: val.name,
                  }))}
                  labelInValue={true}
                  defaultValue={field.value || data?.country}
                  filterOption={(input, option) => {
                    if (option && option.label) {
                      return (
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      )
                    }
                    return false
                  }}
                />
              )}
            />
          </div>
        </Card>

        <Card className="shadow w-full rounded-xl relative bg-[#FAFAFA]">
          <div className="flex gap-4 items-center py-1">
            <MailOutlined className=" text-base" />
            <Controller
              name="email"
              control={controlSocial}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  status={errorsSocial.email ? "error" : ""}
                  className="my-1 font-default rounded"
                  placeholder="email@gmail.com"
                  value={field.value || data?.email}
                />
              )}
            />
          </div>
          <div className="flex gap-4 items-center py-1">
            <LinkedinFilled className=" text-base" />
            <Controller
              name="linkedin"
              control={controlSocial}
              render={({ field }) => (
                <Input
                  {...field}
                  className="my-1 font-default rounded"
                  placeholder="https://linkedin.com/in/profile"
                  value={field.value || data?.linkedin}
                />
              )}
            />
          </div>
          <div className="flex gap-4 items-center py-1">
            <TwitterOutlined className=" text-base" />
            <Controller
              name="twitter"
              control={controlSocial}
              render={({ field }) => (
                <Input
                  {...field}
                  className="my-1 font-default rounded"
                  placeholder="https://twitter.com/username"
                  value={field.value || data?.twitter}
                />
              )}
            />
          </div>
          <div className="flex gap-4 items-center py-1">
            <LinkOutlined className=" text-base" />
            <Controller
              name="link"
              control={controlSocial}
              render={({ field }) => (
                <Input
                  {...field}
                  className="my-1 font-default rounded"
                  placeholder="portfolio or personal website"
                  value={field.value || data?.link}
                />
              )}
            />
          </div>
        </Card>
      </div>

      <div className="col-span-7">
        <Card className="shadow w-full rounded-xl relative bg-[#FAFAFA] h-full">
          <h5 className="font-semibold">About Me</h5>

          <div className="mt-5 !h-[26rem]">
            <Controller
              name="about_me_expert"
              control={control}
              render={({ field }) => (
                <RichText {...field} className="!h-[100%]" />
              )}
            />
          </div>
        </Card>
      </div>

      <div className="flex items-center gap-3 justify-end col-span-12 mt-1">
        <Button onClick={() => editAction?.setIsEditView(false)}>Cancel</Button>
        <Button
          disabled={loadingUpdate || loadingUpdateSocial}
          onClick={UpdateHandler}
          type="primary"
        >
          Save
        </Button>
      </div>
    </div>
  )
}

export default ExpertProfileEdit
