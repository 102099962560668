import { Image, Popover, Tag } from "antd"
import { EnvironmentOutlined } from "@ant-design/icons"
import React from "react"
import { useNavigate } from "react-router-dom"
import { HeartOutlined, HeartFilled } from "@ant-design/icons"
import { useModalStore } from "../../../../stores"
import { useProfile } from "../../../missionControl/hooks/useProfile"
import { SocialMediaLinks } from "../../../jobGalaxy/components/SocialMediaLink"
import { GreenSkill } from "../../expert/types/response"
import { ExpertResponse } from "../../../home/types/response"
import AvatarCustom from "../../../../common/components/AvatarCustom"
import { Moon } from "../../../../assets/images"

interface ExpertItemProps {
  expert?: ExpertResponse
  queryExpert?: any
}

const ExpertItem: React.FC<ExpertItemProps> = ({ expert, queryExpert }) => {
  const { account } = useProfile()
  const navigate = useNavigate()

  const openModal = useModalStore((state) => state.openModal)

  const renderedSolutions =
    expert?.green_skills_category!?.length > 0 ? (
      expert?.green_skills_category?.slice(0, 2).map((gs: GreenSkill) => {
        return (
          <Tag
            key={gs.id}
            className="flex items-center bg-white px-2 py-0.5 rounded-md text-xs break-words"
            style={{ border: "1px solid #36505E" }}
          >
            {gs.name}
          </Tag>
        )
      })
    ) : (
      <div className=" flex flex-col items-center justify-center gap-1">
        <Image src={Moon} width={35} />
        <small>No data</small>
      </div>
    )

  const popoverContent = expert?.green_skills_category!?.length > 2 && (
    <div className="w-[200px]">
      <div className="flex flex-wrap gap-y-2">
        {expert?.green_skills_category?.slice(2).map((gs: GreenSkill) => (
          <Tag
            key={gs.id}
            className="flex items-center bg-white px-2 rounded-md text-xs max-w-[200px] break-words truncate"
            style={{ border: "1px solid #36505E" }}
          >
            <div className=" truncate">{gs.name}</div>
          </Tag>
        ))}
      </div>
    </div>
  )

  function removeHtmlTags(htmlString: string) {
    return htmlString.replace(/<[^>]*>/g, "")
  }

  const sosmed = {
    email: expert?.email,
    link: expert?.website,
    linkedin: expert?.linkedin,
    twitter: expert?.twitter,
  }

  const reFormatSosmed = () => {
    const data = {
      // mail_url: sosmed?.email,
      website_url: sosmed?.link,
      linkedin_url: sosmed?.linkedin,
      twitter_url: sosmed?.twitter,
    }

    return data
  }

  return (
    <div
      className="py-3 px-4 bg-white-custom rounded-xl shadow-custom-sm space-y-3 hover:scale-[1.02] transition-all cursor-pointer"
      onClick={() =>
        navigate(
          `/community/public-expert/${expert?.user_id}/${expert?.username}/3`,
        )
      }
    >
      <div className="flex items-center justify-center flex-col gap-y-3 h-full">
        <div>
          <AvatarCustom
            url={expert?.profile_picture}
            display_name={expert?.first_name + " " + expert?.last_name}
            label={"Expert"}
          />
        </div>

        <div className="flex flex-col items-center gap-2 justify-center">
          <h3>
            {expert?.first_name} {expert?.last_name}
          </h3>
          <div className="-mt-2 mb-2 flex items-center gap-2 justify-center">
            <EnvironmentOutlined /> {expert?.country}
          </div>
          <p className="m-0 leading-5 line-clamp-2 text-[14px] h-10">
            {removeHtmlTags(expert?.about_me_expert || "-")}
          </p>
        </div>

        <div className="px-5 py-3 h-full bg-primary-bg w-full rounded-xl">
          <div className="flex items-center justify-between">
            <Tag
              className={`${
                expert?.is_follow ? "bg-white" : "bg-[#FFF2E8] "
              } cursor-pointer flex items-center gap-x-2`}
              onClick={(e) => {
                e.stopPropagation()
                if (account) {
                  if (expert?.is_follow) {
                    queryExpert?.handleUnFollowExpert(expert?.user_id!)
                  } else [queryExpert?.handleFollowExpert(expert?.user_id!)]
                } else {
                  openModal()
                }
              }}
            >
              {expert?.is_follow ? (
                <HeartFilled color="red" />
              ) : (
                <HeartOutlined />
              )}

              {expert?.is_follow ? "Following Expert" : "Follow Expert"}
            </Tag>
            <div className="flex items-center gap-3">
              {SocialMediaLinks(reFormatSosmed())}
            </div>
          </div>

          {expert?.green_skills_category!?.length > 0 && (
            <div className="text-center">
              <h4>Expertise in</h4>
            </div>
          )}

          <div className="flex flex-wrap items-center gap-y-1 justify-center">
            {renderedSolutions}

            {popoverContent && (
              <Popover placement="right" title={""} content={popoverContent}>
                <Tag
                  className="flex items-center bg-white px-2 py-0.5 rounded-md text-xs break-words hover:scale-105 transition-all cursor-pointer "
                  style={{ border: "1px solid #36505E" }}
                >
                  {`+ ${expert?.green_skills_category!?.length - 2}`}
                </Tag>
              </Popover>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExpertItem
