import instance from "../../../../config/config"
import {
  ApiResponse,
  ApiResponseGeneric,
  ApiResponsePaginatedInfinite,
} from "../../../../common/types/ApiResponse"
import {
  IAddCommunityMedia,
  IAddCommunityPost,
  ICommunityPost,
  TPostBody,
} from "../types/commonn"

export const fetchCommunityPostList = (limit: number, page: number) => {
  return instance.get<ApiResponsePaginatedInfinite<ICommunityPost>>(
    "/community/posts",
    {
      params: {
        limit,
        page,
      },
    },
  )
}

export const fetchCommunityPostDetail = (id: string) => {
  return instance.get<ApiResponseGeneric<ICommunityPost>>(
    `/community/posts/${id}`,
  )
}
export const addPostView = (id: string) => {
  return instance.post(`/community/posts/${id}/views`)
}

export const createCommunityPost = (
  post: TPostBody,
  kind?:
    | "default"
    | "media"
    | "event"
    | "poll"
    | "occasion"
    | "how_to"
    | "multiple_choice",
) => {
  if (kind === "media") {
    const formData = new FormData()
    for (const key in (post as IAddCommunityMedia).files) {
      // @ts-ignore
      formData.append("files", (post as IAddCommunityMedia)?.files?.[key])
    }
    formData.append("kind", kind)
    return instance.post<ApiResponse<ICommunityPost>>(
      "/community/posts",
      formData,
    )
  }
  if (["multiple_choice", "how_to"].includes(kind ?? "")) {
    return instance.post<ApiResponse<ICommunityPost>>("/community/posts", {
      ...post,
      kind,
    })
  } else {
    const formData = new FormData()

    for (const key in post) {
      // @ts-ignore
      if (post[key]) {
        // @ts-ignore
        formData.append(key, post[key])
      }
    }

    if (kind) formData.append("kind", kind)

    return instance.post<ApiResponse<ICommunityPost>>(
      "/community/posts",
      formData,
    )
  }
}

export const uploadMedia = (image: File) => {
  const formData = new FormData()

  formData.append("file", image)
  return instance.post<ApiResponseGeneric<string>>(
    "/community/media/upload",
    formData,
  )
}

export const deleteMedia = (url: string) => {
  const formData = new FormData()

  formData.append("file_url", url)
  return instance.delete("/community/media/delete", { data: { file_url: url } })
}

export const updateCommunityPost = ({
  id,
  post,
}: {
  id: string
  post: IAddCommunityPost
}) => {
  return instance.put<ApiResponse<ICommunityPost>>(
    `/community/posts/${id}`,
    post,
  )
}

export const deleteCommunityPost = (id: string) => {
  return instance.delete<ApiResponse<null>>(`/community/posts/${id}`)
}
