import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import instance from "../../../config/config"
import { msalInstance } from "../../.."
import { ApiResponse } from "../../../common/types/ApiResponse"

type UserActivity = {
  id: string | null
  user_id: string | null
  login_dates: string[] | null
  last_login_date: string | null
  consecutive_days: number | null
  last_consecutive_days: number | null
  longest_consecutive_days: number | null
}

type Range = {
  startDate: null | Date
  endDate: null | Date
  key: string
}

const fetchLoginLogs = async (id: string) => {
  const response: ApiResponse<UserActivity> = await instance.get(
    `accounts/login-logs/${id}`,
  )
  return response?.data
}

const fetchAccount = async () => {
  const response = await instance.get("/accounts/detail")
  return response?.data?.data?.id
}

export const useEcoCommitment = () => {
  const user = msalInstance.getAllAccounts()[0]

  const [dataEcoCommitment, setDataEcoCommitment] =
    useState<UserActivity | null>(null)
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ])
  const [ranges, setRanges] = useState<Range[]>([])

  const { data: accountId, isLoading: loadingAccount } = useQuery(
    "accountId",
    fetchAccount,
    {
      enabled: !!user,
    },
  )
  const { data: loginLogs, isLoading: loadingLogs } = useQuery(
    ["loginLogs", accountId],
    () => fetchLoginLogs(accountId),
    { enabled: !loadingAccount && !!accountId },
  )

  useEffect(() => {
    if (loginLogs) {
      const loginDates = loginLogs?.data?.login_dates
      const formattedDates = loginDates?.map((date) => {
        const formattedDate = new Date(date)
        const year = formattedDate?.getFullYear()
        const month = String(formattedDate?.getMonth() + 1).padStart(2, "0")
        const day = String(formattedDate?.getDate()).padStart(2, "0")
        return `${year}-${month}-${day}`
      })

      const selectedDates = formattedDates?.map((date) => new Date(date))

      const ranges = []

      let range: Range = {
        startDate: null,
        endDate: null,
        key: "selection",
      }

      if (selectedDates) {
        for (let i = 0; i < selectedDates?.length; i++) {
          if (range.startDate === null) {
            range.startDate = selectedDates[i]
            range.endDate = selectedDates[i]
          } else {
            // @ts-ignore
            const diffTime = Math.abs(selectedDates[i] - range.endDate)
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

            if (diffDays === 1) {
              range.endDate = selectedDates[i]
            } else {
              ranges.push(range)

              range = {
                startDate: selectedDates[i],
                endDate: selectedDates[i],
                key: "selection",
              }
            }
          }
        }
      }

      ranges.push(range)
      setDataEcoCommitment(loginLogs?.data)
      setState([
        {
          startDate: new Date(),
          endDate: null,
          key: "selection",
        },
      ])
      setRanges([...ranges])
    }
  }, [loginLogs])

  return { dataEcoCommitment, state, ranges, loadingAccount, loadingLogs }
}
