import { message } from "antd"
import { ApiResponseGeneric } from "../common/types/ApiResponse"
import instance from "../config/config"
import { useQuery } from "react-query"
import { Item } from "./useReferences"

export interface TCategorySkills {
  id: number
  name: string
  total_skill: number
  skills: Item[]
}
export const useGreenSkillByCategory = (id: string | undefined) => {
  const handleFetchError = (error: any) => {
    message.error({
      content: `An error occurred while fetching job sector: ${error?.data}`,
      key: id,
    })
  }

  const fetchById = async () => {
    if (id !== undefined && id !== "") {
      return instance.get<ApiResponseGeneric<TCategorySkills>>(
        `green-skills/categories/${id}`,
      )
    }

    return { data: null }
  }

  return useQuery(["gs-by-category", id], async () => fetchById(), {
    refetchOnWindowFocus: false,
    onError: handleFetchError,
    enabled: !!id,
  })
}
