import React from "react"
import { Button, Spin } from "antd"
import { useDataExport } from "../hooks/useDataExport"
import dayjs from "dayjs"
import { DownloadOutlined, ClockCircleOutlined } from "@ant-design/icons"
import {
  BasicContainer,
  BasicContainerWithTitle,
  BasicTwoBoxContainer,
} from "../../../common/components"

export function DataExport() {
  const {
    requestExport,
    requestLoad,
    exportDataFiles,
    isLoadingExportDataFiles,
  } = useDataExport()

  const handleExportRequest = () => {
    const storedExportDataFile = JSON.parse(
      localStorage.getItem("exportDataFiles"),
    )
    if (storedExportDataFile?.data) {
      localStorage.setItem(
        "exportDataFiles",
        JSON.stringify({
          ...storedExportDataFile,
          newRequest: true,
        }),
      )
    } else {
      localStorage.setItem(
        "exportDataFiles",
        JSON.stringify({
          data: null,
          newRequest: true,
        }),
      )
    }
    requestExport()
  }

  return (
    <BasicTwoBoxContainer
      className=""
      leftBox={
        <BasicContainerWithTitle title="Request History" className="h-full">
          <Spin spinning={isLoadingExportDataFiles}>
            <div className="mt-6 flex flex-col items-start gap-4 w-full">
              {exportDataFiles?.data?.data?.map((item, index) => {
                return (
                  <div
                    className="flex items-center justify-between w-full "
                    key={index}
                  >
                    <div className=" text-backdrop">
                      {dayjs(item?.created_at).format("D MMM YYYY")}
                    </div>
                    {renderItemAccordingToStatus(item?.status, item?.file_url)}
                  </div>
                )
              })}
            </div>
          </Spin>
        </BasicContainerWithTitle>
      }
      rightBox={
        <BasicContainer>
          <div className="">
            You may request a copy of all data you have created on our platform.
            Click the “Request Data Export” button to start the process. When
            you data is ready, you will find a download link in Request History.
          </div>
          <Button
            loading={requestLoad}
            type="default"
            className="mt-4"
            onClick={handleExportRequest}
          >
            Request Data Export
          </Button>
        </BasicContainer>
      }
    />
  )
}

const renderItemAccordingToStatus = (status, file_url) => {
  switch (status) {
    case "in progress":
      return (
        <div className="flex gap-x-2">
          <ClockCircleOutlined />
          <div className=" text-backdrop">In Progress</div>
        </div>
      )
    case "completed":
      return (
        <Button
          icon={<DownloadOutlined />}
          type="primary"
          className="bg-primary text-white"
          href={file_url}
        >
          Download
        </Button>
      )
    case "expired":
      return <div className=" ">expired</div>
    default:
      return <div className=" text-backdrop">Refresh the page.</div>
  }
}
