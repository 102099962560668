import { useForm, useFieldArray } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation, useQueryClient } from "react-query"
import { message } from "antd"
import { useNewPostStore } from "../../store/newPostStore"
import { PollFormData, pollSchema } from "../components/new-post-forms/schemas"
import { TPostBody, TPostKind } from "../types/commonn"
import { createCommunityPost } from "../apis/feed-post"
import React from "react"
import ErrorMessageBuilder from "../../../../common/components/ErrorMessageBuilder"

export const usePollForm = () => {
  const { closeModal } = useNewPostStore()
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<PollFormData>({
    resolver: zodResolver(pollSchema),
    defaultValues: {
      poll_question: "",
      poll_options: [
        { id: 1, text: "" },
        { id: 2, text: "" },
      ],
      poll_duration: "1d",
    },
  })

  const createPost = useMutation(
    ({ post, kind }: { post: TPostBody; kind?: TPostKind }) =>
      createCommunityPost(post, kind),
  )
  const queryClient = useQueryClient()

  const onSubmit = async (data: PollFormData) => {
    message.loading({
      content: "Creating poll post...",
      key: "create-post",
    })

    await createPost.mutateAsync(
      {
        post: {
          ...data,
          poll_options: data?.poll_options?.map((item) => item.text),
        },
        kind: "poll",
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("community-post-list")
          message.success({
            content: "Post created successfully!",
            key: "create-post",
          })
          closeModal()
        },
        onError: (error: any) => {
          message.open({
            key: "create-event-post",
            duration: 5,
            content: React.createElement(ErrorMessageBuilder, {
              message: `We encountered an issue while attempting to create your poll post. If the problem continues, please refresh the page. Error ${error.response?.data?.message}`,
              includeReportAt: true,
            }),
            type: "error",
          })
        },
      },
    )
  }

  const { fields, append, remove } = useFieldArray({
    control,
    name: "poll_options",
  })

  return {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    fields,
    append,
    remove,
    createPost,
    closeModal,
    onSubmit,
  }
}
