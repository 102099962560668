/* eslint-disable */
import React from "react"
import { Button } from "antd"
import { LinkedinOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { useHeader } from "../../../common/layout/hooks/useCustomHeader"
import { AuthLayout } from "../AuthLayout"

const Login = () => {
  const { handleLoginRedirect } = useHeader()
  const navigate = useNavigate()

  return (
    <AuthLayout>
      <div className="px-10">
        <h1 className="mt-7 !font-semibold">Login</h1>
        <div className=" lg:w-[75%] w-full mt-[50px] basic-card-small !shadow-custom-lg bg-custom-white">
          <>
            <Button
              className="w-full text-left !text-backdrop bg-primary-bg"
              onClick={handleLoginRedirect}
            >
              <LinkedinOutlined />
              Continue with LinkedIn
            </Button>
            <div className="mt-4 w-full flex items-center justify-end flex-wrap sm:flex-nowrap ">
              Don't have an account?
              <span
                className="text-[#009ACE] ml-1 cursor-pointer"
                onClick={() => navigate("/register")}
              >
                Sign Up
              </span>
            </div>
          </>
        </div>
      </div>
    </AuthLayout>
    // <div className="bg-[#E2EBFA] m-auto h-screen flex justify-center items-center">
    //   <div className="w-full h-full flex justify-center items-center">
    //     <div className={` ${isMobile ? "w-9/12 -mt-96" : " w-1/3 -mt-10"}`}>
    //       <div className=" text-xl font-bold text-center mb-5">
    //         Talent Login
    //       </div>
    //       {isMobile ? (
    //         <div className="text-backdrop font-semibold text-base text-center w-full">
    //           You are currently using a mobile device. Please use a desktop or a
    //           laptop to access the Talent Marketplace.
    //         </div>
    //       ) : (
    //         <>
    //           <Button
    //             className="w-full text-left"
    //             onClick={handleLoginRedirect}
    //           >
    //             <LinkedinOutlined />
    //             Continue with LinkedIn
    //           </Button>
    //           <div className="mt-3">
    //             Don’t have an account?
    //             <span
    //               className="text-[#009ACE] ml-1 cursor-pointer"
    //               onClick={() => navigate("/register")}
    //             >
    //               Register
    //             </span>
    //           </div>
    //         </>
    //       )}
    //     </div>
    //   </div>
    // </div>
  )
}

export default Login
