import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation, useQueryClient } from "react-query"
import { message } from "antd"
import { useNewPostStore } from "../../store/newPostStore"
import {
  EventFormData,
  eventSchema,
} from "../components/new-post-forms/schemas"
import { IAddCommunityEvent, TPostBody, TPostKind } from "../types/commonn"
import { createCommunityPost } from "../apis/feed-post"
import {
  convertToDateString,
  removeUndefinedKeys,
} from "../../../../utils/util_funcs"
import React from "react"
import ErrorMessageBuilder from "../../../../common/components/ErrorMessageBuilder"
import { timezones } from "../../../../constants/timezones"

export const useEventForm = () => {
  const { closeModal } = useNewPostStore()

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<EventFormData>({
    resolver: zodResolver(eventSchema),
    defaultValues: {
      external_event_link: "",
      event_type: "in_person",
      event_name: "",
      image: [],
    },
  })

  const createPost = useMutation(
    ({ post, kind }: { post: TPostBody; kind?: TPostKind }) =>
      createCommunityPost(post, kind),
  )
  const queryClient = useQueryClient()

  const onSubmit = async (data: EventFormData) => {
    const updatedEventEndDateTime = data?.event_end_date
      ? convertToDateString(data?.event_end_date)
      : undefined
    const updatedEvenStartDateTime = convertToDateString(data?.event_start_date)

    const updatedData: IAddCommunityEvent = {
      ...data,
      event_start_date: updatedEvenStartDateTime?.date ?? "",
      event_start_time: updatedEvenStartDateTime?.time ?? 0,
      event_end_date: updatedEventEndDateTime?.date,
      event_end_time: updatedEventEndDateTime?.time,
      image: data?.image?.[0],
    }

    message.loading({
      content: "Creating event post...",
      key: "create-event-post",
    })

    await createPost.mutateAsync(
      // @ts-ignore
      { post: removeUndefinedKeys(updatedData), kind: "event" },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("community-post-list")
          message.success({
            content: "Post created successfully!",
            key: "create-event-post",
          })
          closeModal()
        },
        onError: () => {
          message.open({
            key: "create-event-post",
            duration: 5,
            content: React.createElement(ErrorMessageBuilder, {
              message:
                "We encountered an issue while attempting to create your event post. If the problem continues, please refresh the page.",
              includeReportAt: true,
            }),
            type: "error",
          })
        },
      },
    )
  }

  return {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    timezones,
    createPost,
    closeModal,
    onSubmit,
  }
}
