import { EnvironmentOutlined } from "@ant-design/icons"
import { ProductResponse, StartupResponse } from "../../founder/types/response"
import { Image } from "antd"
import { Earth } from "../../../../assets/images"
import { useNavigate } from "react-router-dom"

interface CardSolutionProps {
  startupItem: StartupResponse
}

const CardSolution: React.FC<CardSolutionProps> = ({ startupItem }) => {
  const navigate = useNavigate()

  return (
    <div
      className="rounded-xl shadow-custom-sm bg-white-custom px-5 py-3 grid grid-cols-3 gap-x-6 gap-y-4 cursor-pointer hover:scale-[1.01] transition-all"
      onClick={() => navigate(`/community/solution/${startupItem?.id}`)}
    >
      <div className="col-span-1">
        <div className="flex flex-col items-center justify-center gap-y-3 mb-5">
          <h3>{startupItem?.name}</h3>

          <div>
            <Image
              className="object-cover rounded-full"
              src={startupItem?.logo}
              alt="profile"
              width={100}
              height={100}
              preview={false}
            />
          </div>

          <div className="-mt-2 mb-2 flex items-center gap-2 justify-center">
            <EnvironmentOutlined /> Indonesia
          </div>

          <p className="m-0 text-[#966F02] text-xs uppercase tracking-widest ">
            {startupItem?.stage?.name}
          </p>
        </div>
        <p className="m-0 leading-5 line-clamp-2 text-[14px] h-10">
          {startupItem?.long_description}
        </p>
      </div>
      {startupItem?.products
        ?.slice(0, 2)
        .map((data: ProductResponse, index: number) => {
          return (
            <div
              className={`${
                startupItem?.products?.length === 1
                  ? "col-span-2"
                  : "col-span-1"
              }`}
              key={index}
            >
              <div className="bg-primary-bg px-5 py-2 rounded-xl h-full pt-5">
                <div
                  className="flex items-center gap-1 md:gap-3  bg-[#FFF2E8] px-2 rounded hover:scale-105 transition-all cursor-pointer w-[164px]"
                  style={{ border: "1px solid #36505E" }}
                >
                  <Earth />
                  <p className="m-0 text-[10px] md:text-xs">
                    My Climate Solution
                  </p>
                </div>

                <h4>{data?.name}</h4>

                <div className="flex items-center gap-3 ">
                  <p className="text-xs m-0 mt-2">Best for:</p>
                  <div className="flex flex-wrap items-center gap-x-1 gap-y-1 justify-center mt-2">
                    {data?.intended_user}
                  </div>
                </div>

                <div className="flex items-center gap-3 ">
                  <div className="flex flex-wrap items-center gap-x-1 gap-y-1 justify-center mt-2">
                    {data?.solutions?.map((solution: any, index: number) => (
                      <div
                        key={index}
                        className="flex items-center bg-white px-2 rounded-full text-xs break-words"
                        style={{ border: "1px solid #36505E" }}
                      >
                        {solution?.name}
                      </div>
                    ))}
                  </div>
                </div>

                <p>{data?.description}</p>
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default CardSolution
