import React from "react"
import { useParams } from "react-router-dom"
import { useDetailArticle } from "../hooks/useMyArticle"
import { PageContent, PageHeader } from "../../../../common/layout"
import { HomeOutlined } from "@ant-design/icons"
import ArticleDetailContent from "../components/ArticleDetailContent"
import ArticleDetailRightContent from "../components/ArticleDetailRightContent"

interface DetailArticleProps {}

const DetailArticle: React.FC<DetailArticleProps> = () => {
  const { slug } = useParams()

  const { articleResponse, addAction, uploadImage } = useDetailArticle(slug!)

  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "Expert",
    },
    {
      path: "/expert/my/article",
      title: "My Articles",
    },
    {
      title: articleResponse?.data?.title ?? "Unknown",
    },
  ]

  return (
    <PageContent
      header={<PageHeader breadcrumbItems={items} showBack={true} />}
      content={
        <ArticleDetailContent
          articleResponse={articleResponse}
          addAction={addAction}
          uploadImage={uploadImage}
        />
      }
      contentRightSidebar={
        <ArticleDetailRightContent
          articleResponse={articleResponse}
          addAction={addAction}
        />
      }
    />
  )
}

export default DetailArticle
