import React from "react"
import { Route, Routes } from "react-router-dom"
import MyJobRequest from "./pages/v2/MyJobRequest"
import NotFound from "../auth/page/NotFound"
import { ProtectRoutes } from "../../routes"
// const SingleJobRoles = React.lazy(() =>
//   import("./pages/v2/DetailJobRoles").then((module) => ({
//     default: module.DetailJobRoles,
//   })),
// )
import { DetailJobRoles as SingleJobRoles } from "./pages/v2/DetailJobRoles.tsx"
// const ListCompanies = React.lazy(() =>
//   import("./pages/v2/ListCompanies").then((module) => ({
//     default: module.ListCompanies,
//   })),
// )
import { ListCompanies } from "./pages/v2/ListCompanies.tsx"
// const MyJobs = React.lazy(() => import("./pages/v2/MyJob"))
import MyJobs from "./pages/v2/MyJob.tsx"
// const MyJobHistory = React.lazy(() => import("./pages/v2/MyJobHistory"))
import MyJobHistory from "./pages/v2/MyJobHistory.tsx"
// const JobBoard = React.lazy(() => import("./pages/v2/JobBoard"))
import JobBoard from "./pages/v2/JobBoard.tsx"
// const DetailCompany = React.lazy(() => import("./pages/v2/DetailCompany"))
import DetailCompany from "./pages/v2/DetailCompany.tsx"
// const SingleJobBoard = React.lazy(() =>
//   import("./pages/v2/DetailJobBoards").then((module) => ({
//     default: module.DetailJobBoards,
//   })),
// )
import { DetailJobBoards as SingleJobBoard } from "./pages/v2/DetailJobBoards.tsx"
// const SingleJobBoard = React.lazy(() => import("./pages/SingleJobBoard"));
// const ListJobRoles = React.lazy(() =>
//   import("./pages/v2/ListJobRoles").then((module) => ({
//     default: module.ListJobRoles,
//   })),
// )
import { ListJobRoles } from "./pages/v2/ListJobRoles.tsx"
const Index = () => {
  return (
    <>
      <Routes>
        {/* PART OF COMPANIES */}
        <Route
          path="/my"
          element={
            <ProtectRoutes>
              <MyJobs />
            </ProtectRoutes>
          }
        />
        <Route
          path="/my/history"
          element={
            <ProtectRoutes>
              <MyJobHistory />
            </ProtectRoutes>
          }
        />
        <Route
          path="/my"
          element={
            <ProtectRoutes>
              <MyJobs />
            </ProtectRoutes>
          }
        />
        <Route
          path="/my/request"
          element={
            <ProtectRoutes>
              <MyJobRequest />
            </ProtectRoutes>
          }
        />

        {/* PART OF JOB ROLES */}
        <Route path="/roles" element={<ListJobRoles />} />
        <Route path="/roles/:id" element={<SingleJobRoles />} />

        {/* PART OF COMPANIES */}
        <Route path="/board" element={<JobBoard />} />
        <Route path="/board/:id" element={<SingleJobBoard />} />

        {/* PART OF COMPANIES */}
        <Route path="/companies" element={<ListCompanies />} />
        <Route path="/companies/:id" element={<DetailCompany />} />

        {/* note! each route must be contain this route error to handle error after parent route*/}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  )
}

export default Index
