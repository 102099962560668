import {
  TwitterOutlined,
  LinkedinOutlined,
  MediumOutlined,
  FacebookOutlined,
  InstagramOutlined,
  GithubOutlined,
  LinkOutlined,
  MailOutlined,
} from "@ant-design/icons"
import { ensureHttps } from "../../../common/utils/ensureHttps"

export const SocialMediaLinks = (detailCompany) => {
  const links = [
    detailCompany?.twitter_url && (
      <a
        href={ensureHttps(detailCompany?.twitter_url)}
        target="_blank"
        rel="noreferrer"
        className="cursor-pointer hover:text-primary text-sm"
      >
        <TwitterOutlined />
      </a>
    ),
    detailCompany?.linkedin_url && (
      <a
        href={ensureHttps(detailCompany?.linkedin_url)}
        target="_blank"
        rel="noreferrer"
        className="cursor-pointer hover:text-primary text-sm"
      >
        <LinkedinOutlined />
      </a>
    ),
    detailCompany?.medium_url && (
      <a
        href={detailCompany?.medium_url}
        target="_blank"
        rel="noreferrer"
        className="cursor-pointer hover:text-primary text-sm"
      >
        <MediumOutlined />
      </a>
    ),
    detailCompany?.facebook_url && (
      <a
        href={ensureHttps(detailCompany?.facebook_url)}
        target="_blank"
        rel="noreferrer"
        className="cursor-pointer hover:text-primary text-sm"
      >
        <FacebookOutlined />
      </a>
    ),
    detailCompany?.instagram_url && (
      <a
        href={ensureHttps(detailCompany?.instagram_url)}
        target="_blank"
        rel="noreferrer"
        className="cursor-pointer hover:text-primary text-sm"
      >
        <InstagramOutlined />
      </a>
    ),
    detailCompany?.github_url && (
      <a
        href={ensureHttps(detailCompany?.github_url)}
        target="_blank"
        rel="noreferrer"
        className="cursor-pointer hover:text-primary text-sm"
      >
        <GithubOutlined />
      </a>
    ),
    detailCompany?.website_url && (
      <a
        href={ensureHttps(detailCompany?.website_url)}
        target="_blank"
        rel="noreferrer"
        className="cursor-pointer hover:text-primary text-sm"
      >
        <LinkOutlined />
      </a>
    ),
    detailCompany?.mail_url && (
      <a
        href={detailCompany?.mail_url}
        target="_blank"
        rel="noreferrer"
        className="cursor-pointer hover:text-primary text-sm"
      >
        <MailOutlined />
      </a>
    ),
  ]
  return links.filter((link) => link)
}
