import { Occasions } from "../types/commonn"

export const occasions: Occasions = {
  "new-job": {
    type: "Job",
    name: "New Job Announcement",
    description: "Announcing the starting of a new job or position.",
  },
  "career-switch": {
    type: "Job",
    name: "Career Switch",
    description:
      "Deciding on a change in career to a different industry or field.",
  },
  "project-launch": {
    type: "Project",
    name: "Project Launch",
    description: "Announcing the launch of a new sustainability project.",
  },
  "project-completion": {
    type: "Project",
    name: "Project Completion",
    description:
      "Celebrating the successful completion of a sustainability project.",
  },
  "event-summary": {
    type: "Event",
    name: "Event Summary",
    description: "Sharing the highlights and outcomes of a recent event.",
  },
  "research-sharing": {
    type: "Research",
    name: "Research Sharing",
    description:
      "Sharing new research findings in the field of green economy or sustainability.",
  },
  "success-story": {
    type: "Story",
    name: "Success Story",
    description:
      "Sharing a success story of a person or organization in the field of green economy or sustainability.",
  },
  "general-update": {
    type: "Update",
    name: "General Update",
    description:
      "Sharing updates or news related to green economy or sustainability.",
  },
}
