import React from "react"
import { Button, Empty, Skeleton } from "antd"
import { CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons"
import {
  storeModalSchedule,
  useMentorScheduleStore,
} from "../stores/storeMentorSchedule"
import ModalSchedule from "./ModalSchedule"
import { dayNames } from "../hooks/useMentorSchedule"

interface Appointment {
  date: string
  time: string
  duration: string
  date_time: string
  date_only: string
}
const ListAvailability: React.FC = () => {
  const {
    MentorScheduleData,
    isMentorScheduleLoading,

    setSelectedDate,
    setSelectedDay,
    setSelectedTimeSlot,
  } = useMentorScheduleStore()

  const formatDate = (isoString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      month: "long",
      day: "numeric",
    }
    const date = new Date(isoString)
    return date.toLocaleDateString("en-US", options)
  }

  const formatTime = (isoString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }
    const date = new Date(isoString)

    return date.toLocaleTimeString("en-US", options)
  }

  const formatDateOnly = (isoString: string): string => {
    const date = new Date(isoString)
    return date.toISOString().split("T")[0]
  }

  const appointments: Appointment[] = Object.entries(
    MentorScheduleData?.slots,
  ).flatMap(([date, times]) =>
    times.map((timeSlot) => ({
      date: formatDate(timeSlot.time),
      time: formatTime(timeSlot.time),
      duration: "30min",
      date_time: timeSlot.time,
      date_only: formatDateOnly(timeSlot.time),
    })),
  )

  const { openModal } = storeModalSchedule()

  return (
    <div className="max-w-md mx-auto">
      {isMentorScheduleLoading ? (
        <Skeleton active />
      ) : appointments?.length === 0 ? (
        <Empty />
      ) : (
        appointments?.map((appointment, index) => (
          <div
            key={index}
            className="flex items-center justify-between my-1 rounded-md p-2 bg-white shadow-sm"
          >
            <div className="flex items-center">
              <CalendarOutlined className="mr-2" />
              <span>
                {appointment.date} {appointment.time}
              </span>
            </div>
            <div className="flex items-center">
              <ClockCircleOutlined className="mr-2" />
              <span>{appointment.duration}</span>
            </div>
            <Button
              type="primary"
              onClick={() => {
                setSelectedDate(appointment.date_only)
                setSelectedTimeSlot(appointment.date_time)

                const dayOfWeek = new Date(appointment.date_only).getDay()
                setSelectedDay(dayNames[dayOfWeek])
                setSelectedDay(dayNames[dayOfWeek])

                openModal()
              }}
            >
              Request
            </Button>
          </div>
        ))
      )}

      <ModalSchedule />
    </div>
  )
}

export default ListAvailability
