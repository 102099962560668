import { Button, Card, Input, Select } from "antd"
import React from "react"
import { Controller } from "react-hook-form"
import { RichTextMinimize } from "../../../../../common/components/RichTextMinimize"
import { assignAvatar } from "../../../../../assets/avatar_images"
import { LinkedinOutlined } from "@ant-design/icons"

interface FormBasicProfileProps {
  handler: any
  closeEdit: any
}

const FormBasicProfile: React.FC<FormBasicProfileProps> = ({
  handler,
  closeEdit,
}) => {
  return (
    <div>
      <div className="flex flex-wrap gap-5 lg:flex-nowrap">
        <Card className=" shadow-md w-full flex-grow rounded-lg basis-1/2 bg-white-custom h-[400px]">
          <div className="flex flex-col items-center mb-1">
            <img
              className="object-contain w-24 h-24 p-1 rounded-full shadow-md"
              src={
                handler.basicUpdate.imageUrl ||
                assignAvatar(
                  `${handler?.detailMentor?.first_name} ${handler?.detailMentor?.last_name}`,
                )
              }
              alt="profile"
            />
            <div className="flex gap-3 my-2 text-xs">
              <label
                htmlFor="image-profile"
                className="cursor-pointer  text-secondary"
              >
                Replace
              </label>
              {!handler.basicUpdate.imageUrl?.startsWith(
                "https://stgreenskills001.blob.core.windows.net/default-avatars/",
              ) && (
                <div
                  className="cursor-pointer text-danger"
                  onClick={() => {
                    if (!handler.basicUpdate.loadingImage)
                      handler.basicUpdate.handleRemoveImage()
                  }}
                >
                  Remove
                </div>
              )}
              <input
                disabled={handler.basicUpdate.loadingImage}
                type="file"
                accept="image/png, image/gif, image/jpeg"
                id="image-profile"
                className="hidden "
                value={""}
                onChange={(e) => {
                  handler.basicUpdate.handleImageChange(e)
                }}
              />
            </div>
          </div>
          <div className="flex mb-3 gap-x-3">
            <Controller
              name="first_name"
              control={handler.basicUpdate.control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  status={handler.basicUpdate.errors?.first_name ? "error" : ""}
                  className="my-1 rounded font-default basis-1/2"
                  placeholder="first name"
                />
              )}
            />
            <Controller
              name="last_name"
              control={handler.basicUpdate.control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  status={handler.basicUpdate.errors?.last_name ? "error" : ""}
                  className="my-1 rounded font-default basis-1/2"
                  placeholder="last name"
                />
              )}
            />
          </div>
          <div className="flex flex-wrap gap-3 mb-3 lg:flex-nowrap">
            <Controller
              name="prfoile_headline"
              control={handler.basicUpdate.control}
              // rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  status={
                    handler.basicUpdate.errors?.prfoile_headline ? "error" : ""
                  }
                  className="flex-grow my-1 rounded font-default basis-1/2"
                  placeholder="Job Role"
                />
              )}
            />
            <Controller
              name="country"
              control={handler.basicUpdate.control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  className="max-w-full my-1 font-default basis-1/2 job-history"
                  placeholder="Employment type"
                  showSearch
                  status={handler.basicUpdate.errors?.country ? "error" : ""}
                  options={handler.basicUpdate.countries?.data?.map(
                    (val: any) => ({
                      label: val.name,
                      value: val.name,
                    }),
                  )}
                  labelInValue={true}
                  filterOption={(input, option) => {
                    if (option && option.label) {
                      return (
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      )
                    }
                    return false
                  }}
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="linkedin"
              control={handler.basicUpdate.control}
              rules={{ maxLength: 256 }}
              render={({ field }) => (
                <div className="flex items-center gap-x-2">
                  <LinkedinOutlined className="text-2xl" />
                  <Input
                    {...field}
                    status={handler.basicUpdate.errors?.linkedin ? "error" : ""}
                    className="my-1 rounded font-default "
                    placeholder="https://"
                    style={{ resize: "none" }}
                  />
                </div>
              )}
            />
          </div>
        </Card>
        <Card className="shadow-md w-full flex-grow rounded-lg basis-1/2 bg-white-custom h-[400px]">
          <h4 className="capitalize">About Me</h4>
          <div className="my-2">
            This information will be displayed as your mentor about
          </div>
          <Controller
            name="about_me"
            control={handler.basicUpdate.control}
            render={({ field }) => (
              <RichTextMinimize {...field} className="w-full h-52" />
            )}
          />
        </Card>
      </div>
      <div className="flex items-center justify-end gap-3 mt-3">
        <Button className="border-primary" onClick={closeEdit}>
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={() => handler.basicUpdate.saveEditBasic()}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

export default FormBasicProfile
