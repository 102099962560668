import React, { useState } from "react"
import { useMsal } from "@azure/msal-react"
import { useGetSingleSkill, useGreenSkillLevels } from "../hooks/useSkills"
import { SingleSkillTypicalActivities } from "../components"
import useFavoriteSkills from "../../missionControl/hooks/useFavoriteSkills"
import { PageContent } from "../../../common/layout"
import JobRolesTab from "../components/JobRolesTab"
import SingleSkillPlan from "../components/SingleSkillPlan"
import { Header } from "../components/singleSkill/Header"
import { useJobRequired } from "../hooks/useJobRequired"
import { Tabs } from "antd"
import { useParams } from "react-router-dom"
import { SidebarV2 } from "../components/singleSkill/SidebarV2"
import "../components/custom.css"

export const loader =
  (queryClient) =>
  async ({ params }) => {
    const query = useGetSingleSkill(params.objectiveId)
    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))
    )
  }

export default function SingleSkill() {
  const { accounts } = useMsal()
  const { greenSkillId } = useParams()
  const singleSkillQuery = useGetSingleSkill(greenSkillId)
  const [activeKey, setActiveKey] = useState("1")

  const handleTabChange = (key) => {
    setActiveKey(key)
    if (key === "3") {
      const element = document.getElementById("tabs-component")
      element.scrollIntoView({
        behavior: "smooth",
      })
    }
  }

  const isLoggedIn = accounts?.length > 0
  const {
    favoriteSkills,
    isFetchingFavorite,
    isRemovingSkillFromFavorite,
    removeSkillFromFavorite,
    isAddingSkillToFavorite,
    addSkillToFavorite,
  } = useFavoriteSkills(isLoggedIn)

  const singleSkillLevelQuery = useGreenSkillLevels(
    greenSkillId,
    accounts[0]?.localAccountId,
  )

  const { data: jobRequiredSkillQuery } = useJobRequired()

  const dataSinglePage = singleSkillLevelQuery?.data?.data?.data

  const tabsData = [
    {
      key: "1",
      label: "Courses",
      children: (
        <SingleSkillPlan
          data={dataSinglePage}
          skillData={singleSkillQuery?.data?.data?.data}
          loading={singleSkillLevelQuery?.isLoading}
        />
      ),
    },
    {
      key: "2",
      label: `About the Skill`,
      children: (
        <SingleSkillTypicalActivities
          skillData={singleSkillQuery?.data?.data?.data}
          skillDataIsLoading={singleSkillQuery.isLoading}
          favoriteSkills={favoriteSkills?.data?.data}
          removeSkillFromFavorite={removeSkillFromFavorite}
          addSkillToFavorite={addSkillToFavorite}
        />
      ),
    },
    {
      key: "3",
      label: `Job Roles`,
      children: (
        <JobRolesTab
          isLoading={jobRequiredSkillQuery?.isLoading}
          dataJobRequiredSkill={jobRequiredSkillQuery?.data?.data?.data}
        />
      ),
    },
  ]

  return (
    <div>
      <PageContent
        className=""
        rightSidebarRootClassName="lg:-mt-[22.5rem]"
        header={
          <Header
            singleSkillLevelQuery={singleSkillLevelQuery}
            singleSkillQuery={singleSkillQuery}
            greenSkillId={greenSkillId}
            favoriteSkills={favoriteSkills}
            removeSkillFromFavorite={removeSkillFromFavorite}
            addSkillToFavorite={addSkillToFavorite}
            isFetchingFavorite={isFetchingFavorite}
            isRemovingSkillFromFavorite={isRemovingSkillFromFavorite}
            isAddingSkillToFavorite={isAddingSkillToFavorite}
            isLoggedIn={isLoggedIn}
          />
        }
        contentRightSidebar={
          <SidebarV2
            isLoading={
              singleSkillLevelQuery?.isLoading || singleSkillQuery.isLoading
            }
            levelsData={dataSinglePage}
            skillData={singleSkillQuery?.data?.data?.data}
          />
        }
        content={
          <div className="bg-white p-4 pt-6 " id="tabs-component">
            <Tabs
              defaultActiveKey={activeKey}
              className="custom-tabs"
              items={tabsData}
              activeKey={activeKey}
              onTabClick={(key) => handleTabChange(key)}
            />
          </div>
        }
      />
    </div>
  )
}
