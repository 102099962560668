import React from "react"
import { JobRole } from "../../types/jobRole"
import { Progress, Tooltip } from "antd"

interface Props {
  data: JobRole
}

export const DetailHeader: React.FC<Props> = ({ data }) => {
  return (
    <div className="w-full md:w-[calc(100%-400px)]">
      <div className=" tracking-widest uppercase text-[#966F02]">
        {data?.job_sector?.name || "unknown"}
      </div>
      <h1 className="my-3 font-semibold">{data?.name}</h1>
      <div className="mt-5 mb-8">{data.description}</div>
      <div className="flex gap-x-10 gap-y-5 flex-wrap md:flex-nowrap">
        <div>
          Data Source: <strong>SkillsFuture</strong>
        </div>
        <div className="flex w-[240px] ">
          <div className=" w-[190px]">Skill Spectrum</div>
          <Tooltip title={`${Math.round(data?.rangking_in_percent) || 0}%`}>
            <Progress
              strokeColor={"#86C769"}
              percent={Math.round(data?.rangking_in_percent) || 0}
              showInfo={false}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  )
}
