import React from "react"
import { Skeleton, Tooltip } from "antd"
import {
  HomeOutlined,
  FundOutlined,
  UsergroupAddOutlined,
  InteractionOutlined,
} from "@ant-design/icons"
import { PageHeader } from "../../../../common/layout"
import { useNavigate } from "react-router-dom"
import { TagFavoriteSkill } from "../TagFavoriteSkill"

export const Header = ({
  singleSkillLevelQuery,
  singleSkillQuery,
  greenSkillId,
  favoriteSkills,
  removeSkillFromFavorite,
  addSkillToFavorite,
  isFetchingFavorite,
  isRemovingSkillFromFavorite,
  isAddingSkillToFavorite,
}) => {
  const navigate = useNavigate()
  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "Green Skills",
      path: `/skill/green-skills`,
    },
    {
      title: singleSkillQuery.data?.data?.data?.name,
    },
  ]
  const isFav = favoriteSkills?.data?.data?.some(
    (skillTwo) => skillTwo.green_skill_id === greenSkillId,
  )

  return singleSkillLevelQuery?.isLoading ? (
    <div className="bg-white p-4 px-6 w-full">
      <Skeleton active title={false} paragraph={{ rows: 4 }} />
    </div>
  ) : (
    <>
      <PageHeader className="bg-white" breadcrumbItems={items}>
        <span
          onClick={() => navigate(-1) || navigate("/skill/green-skills")}
          className="!text-secondary cursor-pointer footnote"
        >
          {"<< back"}
        </span>

        <div className="flex flex-col justify-between mt-6 ">
          <div className="flex gap-6 justify-between">
            <div className="flex flex-col gap-2 justify-center items-start">
              <div className="flex gap-4 items-center md:flex-nowrap flex-wrap justify-center md:justify-start">
                {/* <div className='w-[140px] '>
                  <img
                    className='w-full  object-cover'
                    src={singleSkillQuery.data?.data?.data?.image}
                    alt={singleSkillQuery.data?.data?.data?.name}
                  />
                </div> */}
                <div className="flex gap-1 items-start md:items-start flex-col justify-center md:justify-start ">
                  <div className="golden-subtitle">
                    {singleSkillQuery.data?.data?.data?.groups
                      ? singleSkillQuery.data?.data?.data?.groups?.name
                      : "N/A"}
                  </div>
                  <h1 className="">
                    {singleSkillQuery.data?.data?.data?.name}
                  </h1>
                </div>
              </div>
              <p className="text-backdrop text-base w-full lg:w-[65%] text-start md:text-start">
                {singleSkillQuery.data?.data?.data?.description}
              </p>
              <div className="">
                Data Source:{" "}
                <span className="font-bold">
                  {singleSkillQuery.data?.data?.data?.data_source}
                </span>
              </div>
            </div>
            <div></div>
          </div>
          <div className="flex gap-x-4 items-center justify-center md:justify-start mt-6">
            <div className="flex gap-2 items-center">
              <Tooltip placement="bottom" title="people with this skill">
                <UsergroupAddOutlined className="text-backdrop" />
              </Tooltip>
              <div className="text-backdrop">
                {singleSkillQuery.data?.data?.data?.total_students ?? "n/a"}
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <Tooltip placement="bottom" title="transferability score">
                <InteractionOutlined className="text-backdrop" />
              </Tooltip>
              <div className="text-backdrop">
                {singleSkillQuery.data?.data?.data?.transferability_score ??
                  "n/a"}
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <Tooltip placement="bottom" title="ranking">
                <FundOutlined className="text-backdrop" />
              </Tooltip>
              <div className="text-backdrop">
                {singleSkillQuery.data?.data?.data?.rangking
                  ? Math.round(singleSkillQuery.data?.data?.data?.rangking)
                  : "n/a"}
              </div>
            </div>
            <TagFavoriteSkill
              isFav={isFav}
              skill_id={greenSkillId}
              isLoading={
                isFetchingFavorite ||
                isRemovingSkillFromFavorite ||
                isAddingSkillToFavorite
              }
              onClick={() => {
                isFav
                  ? removeSkillFromFavorite(greenSkillId)
                  : addSkillToFavorite(greenSkillId)
              }}
            />
          </div>
        </div>
      </PageHeader>
    </>
  )
}
