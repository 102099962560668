import { Image, Popover } from "antd"
import React from "react"
import { EnvironmentOutlined, CalendarOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { MentorArea, MentorDomain, MentorResponse } from "../types/response"
import AvatarCustom from "../../../../../common/components/AvatarCustom"
import { SocialMediaLinks } from "../../../../jobGalaxy/components/SocialMediaLink"
import { Moon } from "../../../../../assets/images"

interface MentorCardProps {
  mentorItem: MentorResponse
}

const MentorCard: React.FC<MentorCardProps> = ({ mentorItem }) => {
  const navigate = useNavigate()

  function removeHtmlTags(htmlString: string) {
    return htmlString.replace(/<[^>]*>/g, "")
  }

  const reFormatSosmed = () => {
    const data = {
      // mail_url: mentorItem?.email,
      // website_url: mentorItem?.link,
      linkedin_url: mentorItem?.linkedin,
      twitter_url: mentorItem?.twitter,
    }

    return data
  }

  const visibleMentors: MentorArea[] = mentorItem?.mentor_areas?.slice(0, 2)
  const visibleMentorDomain: MentorDomain[] = mentorItem?.mentor_domains?.slice(
    0,
    3,
  )

  const renderedMentors =
    visibleMentors.length > 0 ? (
      visibleMentors.map((area) => (
        <div
          key={area.id}
          className="flex items-center px-2 py-0.5 text-xs text-white break-words rounded-md bg-backdrop"
          style={{ border: "1px solid #36505E" }}
        >
          {area.name}
        </div>
      ))
    ) : (
      <div className=" flex flex-col items-center justify-center gap-1">
        <Image src={Moon} width={35} />
        <small>No data</small>
      </div>
    )

  const popoverContent = mentorItem?.mentor_areas?.length > 2 && (
    <div className="flex flex-col gap-1">
      {mentorItem?.mentor_areas.slice(2).map((area) => (
        <div
          key={area.id}
          className="flex items-center px-2 py-0.5 text-xs break-words bg-white rounded-md"
          style={{ border: "1px solid #36505E" }}
        >
          {area.name}
        </div>
      ))}
    </div>
  )

  return (
    <div
      className="py-3 px-4 bg-white-custom rounded-xl shadow-custom-sm space-y-3 hover:scale-[1.02] transition-all cursor-pointer"
      onClick={() => navigate(`/community/mentor/detail/${mentorItem?.id}`)}
    >
      <div className="flex flex-col items-center justify-center gap-y-3">
        <AvatarCustom
          url={mentorItem?.profile_picture}
          display_name={mentorItem?.first_name + " " + mentorItem?.last_name}
          label="Mentor"
        />

        <div className="flex flex-col items-center justify-center gap-2">
          <h3>
            {mentorItem?.first_name} {mentorItem?.last_name}
          </h3>
          <div className="flex items-center justify-center gap-2 mb-2 -mt-2">
            <EnvironmentOutlined /> {mentorItem?.country}
          </div>
          <p className="m-0 leading-5 line-clamp-2 text-[14px] h-10">
            {removeHtmlTags(
              mentorItem?.about_me ||
                "This user hasn't added a description yet. Come back soon to see what they have to share!",
            )}
          </p>
        </div>

        <div className="w-full px-5 py-3 bg-primary-bg rounded-xl">
          <div className="flex items-center justify-between">
            <div
              className="flex items-center gap-1 md:gap-2 bg-[#FFF2E8] px-2 rounded hover:scale-105 transition-all cursor-pointer"
              style={{ border: "1px solid #36505E" }}
            >
              <CalendarOutlined />
              <p className="m-0 px-2 py-0.5 text-[10px] md:text-xs">
                Availability
              </p>
            </div>
            <div className="flex items-center gap-3">
              {SocialMediaLinks(reFormatSosmed())}
            </div>
          </div>

          <div className="text-center my-2">
            <h4>Mentorship Areas</h4>
          </div>

          <div className="flex flex-wrap items-center justify-center mt-2 gap-x-1 gap-y-1">
            {renderedMentors}

            {popoverContent && (
              <Popover placement="right" title={""} content={popoverContent}>
                <div
                  className="flex items-center px-2 py-0.5 text-xs text-white break-words transition-all rounded-md cursor-pointer bg-backdrop hover:scale-105"
                  style={{ border: "1px solid #36505E" }}
                >
                  {`+${mentorItem?.mentor_areas.length - 2}`}
                </div>
              </Popover>
            )}
          </div>

          <div className="flex flex-wrap items-center justify-center mt-2 gap-x-1 gap-y-1">
            {visibleMentorDomain.map((domain, index) => (
              <React.Fragment key={domain.id}>
                <span className="flex items-center px-2 py-0.5 text-xs break-words rounded-md text-backdrop">
                  {domain.name}
                </span>
                {index !== visibleMentorDomain.length - 1 && (
                  <span className="flex items-center text-xs text-backdrop">
                    |
                  </span>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MentorCard
