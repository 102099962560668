import React from "react"
import { useMutation, useQueryClient } from "react-query"
import { z } from "zod"
import { createStartup } from "../apis/founders"
import useCommunityModalsStore from "../../store/useCommunityModalsStore"
import { Controller, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { Button, Form, Input, message, Modal, Select } from "antd"
import ImgCrop from "antd-img-crop"
import FormImageUploader from "../../../../common/components/FormImageUploader"
import { LinkOutlined } from "@ant-design/icons"
import { useFounderReference } from "../../founder/hooks/useFounderReference"
import { showError } from "../../../../common/utils/utils"

const schema = z.object({
  name: z.string().min(1, { message: "Name is required." }),
  stage_id: z.string().min(1, { message: "Stage is required." }),
  location_id: z.string().min(1, { message: "Location is required." }),
  website: z.string().url().optional(),
  short_description: z
    .string()
    .min(1, { message: "Short description is required." })
    .max(250, {
      message: "Short description cannot be more than 250 characters",
    })
    .optional(),
  long_description: z.string().optional(),
  file: z.any().optional(),
})

export type TFormAddCompany = z.infer<typeof schema>

const ModalAddCompany = () => {
  const addCompanyModal = useCommunityModalsStore(
    (state) => state.addCompanyModal,
  )
  const queryClient = useQueryClient()

  const createStartupMutation = useMutation((data: TFormAddCompany) =>
    createStartup(data),
  )

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<TFormAddCompany>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: "",
      stage_id: undefined,
      location_id: undefined,
      website: "",
      short_description: "",
      long_description: "",
      file: "",
    },
  })

  const { stageData, locationData } = useFounderReference()

  const onSubmit = async (data: TFormAddCompany) => {
    message.loading({
      content: "Submitting Company...",
      key: "create-company",
    })
    await createStartupMutation.mutateAsync(
      {
        ...data,
        long_description: data?.short_description,
        file: data?.file?.[0],
      },
      {
        onSuccess: async () => {
          message.success({
            content: "Company created successfully",
            key: "create-company",
          })
          await queryClient.invalidateQueries(["detail-founder"])
          await queryClient.invalidateQueries(["startup"])
          addCompanyModal.close()
        },
        onError: () => {
          showError({
            message: "Failed to create company",
            key: "create-company",
          })
        },
      },
    )
  }

  return (
    <Modal
      centered
      width={815}
      open={addCompanyModal.isOpen}
      onCancel={() => addCompanyModal.close()}
      onOk={() => {}}
      footer={null}
    >
      <div className="text-center">
        <h2 className="text-2xl font-bold">Create My Company</h2>
      </div>
      <Form>
        <div className="mt-5">
          <div className="text-lg font-semibold leading-[38px]">
            Company Information
          </div>
          <div className="mt-5 flex gap-5 items-start">
            <Form.Item>
              <Controller
                name={"file"}
                control={control}
                defaultValue={undefined}
                render={({ field: { onChange, value } }) => (
                  <ImgCrop modalWidth={800} aspect={10 / 10} rotationSlider>
                    <FormImageUploader
                      value={value}
                      onChange={onChange}
                      description="Company Logo"
                      className="w-full shadow-lg aspect-square"
                      height={150}
                    />
                  </ImgCrop>
                )}
              />
            </Form.Item>
            <div className="flex-grow ring-1 ring-dirtyBlue bg-white-custom rounded-xl p-4 space-y-4">
              <Form.Item
                className=""
                validateStatus={errors.name ? "error" : ""}
                help={errors.name?.message}
              >
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      status={errors?.name ? "error" : ""}
                      className=""
                      placeholder="Company Name"
                    />
                  )}
                />
              </Form.Item>
              <Form.Item
                className=""
                validateStatus={errors.stage_id ? "error" : ""}
                help={errors.stage_id?.message}
              >
                <Controller
                  name="stage_id"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="w-full"
                      placeholder="Stage"
                      showSearch
                      status={errors?.stage_id ? "error" : ""}
                      options={stageData?.listStage?.map((val) => ({
                        label: val.name,
                        value: val.id,
                      }))}
                      labelInValue={true}
                      onSelect={(value, option) => {
                        field.onChange(option.value)
                      }}
                      filterOption={(input, option) => {
                        if (option && option.value) {
                          return (
                            option.value
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          )
                        }
                        return false
                      }}
                    />
                  )}
                />
              </Form.Item>
              <Form.Item
                className=""
                validateStatus={errors.location_id ? "error" : ""}
                help={errors.location_id?.message}
              >
                <Controller
                  name="location_id"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="w-full"
                      placeholder="HQ Location"
                      showSearch
                      status={errors?.location_id ? "error" : ""}
                      options={locationData?.listLocations?.list?.map(
                        (val) => ({
                          label: val.name,
                          value: val.id,
                        }),
                      )}
                      labelInValue={true}
                      onSelect={(value, option) => {
                        field.onChange(option.value)
                      }}
                      filterOption={(input, option) => {
                        if (option && option.value) {
                          return (
                            option.value
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          )
                        }
                        return false
                      }}
                    />
                  )}
                />
              </Form.Item>
              <Form.Item
                className=""
                validateStatus={errors.website ? "error" : ""}
                help={errors.website?.message}
              >
                <Controller
                  name="website"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      status={errors?.website ? "error" : ""}
                      className=""
                      addonBefore={<LinkOutlined />}
                      placeholder="Company Website"
                    />
                  )}
                />
              </Form.Item>
              <Form.Item
                className=""
                validateStatus={errors.short_description ? "error" : ""}
                help={errors.short_description?.message}
              >
                <Controller
                  name="short_description"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      status={errors?.short_description ? "error" : ""}
                      maxLength={255}
                      max={255}
                      showCount={true}
                      className=""
                      placeholder="short description (255 char max) - will be visible in directory"
                    />
                  )}
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <Button
          className="rounded-md mt-5"
          block
          onClick={handleSubmit(onSubmit)}
          type="primary"
          loading={createStartupMutation.isLoading}
          disabled={!isDirty}
        >
          Save
        </Button>
      </Form>
    </Modal>
  )
}

export default ModalAddCompany
