import React from "react"
import { Button, Checkbox } from "antd"
import { LinkedinOutlined } from "@ant-design/icons"
import { useNavigate, useLocation } from "react-router-dom"
import { isMobile } from "react-device-detect"
import { openInNewTab } from "../../../utils/util_funcs"
import { AuthLayout } from "../AuthLayout"
import { useMsal } from "@azure/msal-react"
import { signUpExpertRequest } from "../../../authConfig"
import { Location } from "../../../hooks/useSearchLocation"
import { SearchLocation } from "../../../common/components/SearchLocation"
// import { isValidLink } from "../utils/util_funcs"

const ExpertRegister = () => {
  const navigate = useNavigate()
  const [isChecked, setIsChecked] = React.useState(false)

  const [selectedCountry, setSelectedCountry] = React.useState<Location | null>(
    null,
  )

  const location = useLocation()
  const { instance } = useMsal()
  const queryParams = new URLSearchParams(location.search)

  if (!queryParams.get("inviteLink")) {
    navigate("/")
  }

  const inviteLink = decodeURIComponent(queryParams.get("inviteLink") ?? "")

  const handleLogin = async () => {
    const loginRequest = {
      ...signUpExpertRequest,
      extraQueryParameters: {
        id_token_hint:
          new URL(inviteLink).searchParams.get("id_token_hint") ?? "",
        country: selectedCountry?.address?.country || "",
        streetAddress: selectedCountry?.address?.freeformAddress || "",
        extension_locationDetailed: selectedCountry?.position
          ? JSON.stringify(selectedCountry?.position)
          : "",
      },
    }
    await instance.loginRedirect(loginRequest)
  }

  return (
    <AuthLayout>
      <div className="px-10">
        <h1 className="mt-7 !font-semibold">Create Expert Account</h1>
        <div className=" lg:w-[75%] w-full mt-[50px] basic-card-small !shadow-custom-lg bg-custom-white">
          {isMobile ? (
            <div className="w-full text-base font-semibold text-center text-backdrop">
              You are currently using a mobile device. Please use a desktop or a
              laptop to access the Talent Marketplace.
            </div>
          ) : (
            <>
              <SearchLocation onHandleSelect={(e) => setSelectedCountry(e)} />
              <Checkbox
                checked={isChecked}
                className="my-5 "
                onChange={(e) => setIsChecked(e.target.checked)}
              >
                I accept the{" "}
                <span
                  onClick={() =>
                    openInNewTab("https://green.skilledin.io/terms-of-use/")
                  }
                  className="inline-block transition-colors duration-300 cursor-pointer text-secondary hover:text-blue-400"
                >
                  Terms of Use
                </span>{" "}
                and{" "}
                <span
                  onClick={() =>
                    openInNewTab("https://green.skilledin.io/privacy-policy/")
                  }
                  className="inline-block transition-colors duration-300 cursor-pointer text-secondary hover:text-blue-400"
                >
                  Privacy Policy
                </span>
              </Checkbox>
              <Button
                className="w-full text-left"
                onClick={handleLogin}
                disabled={!isChecked || !selectedCountry}
              >
                <LinkedinOutlined />
                Continue with LinkedIn
              </Button>
              <div className="flex items-center justify-end mt-3">
                Already Registered?
                <span
                  className="text-[#009ACE] ml-1 cursor-pointer"
                  onClick={() => navigate("/login")}
                >
                  Sign In
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </AuthLayout>
  )
}

export default ExpertRegister
