import React from "react"
import { Link } from "react-router-dom"
import {
  TabletOutlined,
  UserOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons"
import { BasicCollapsibleContainer } from "../../../../common/components/BasicBoxContainers/BasicCollapsibleContainer"

const links = [
  {
    to: "/community/feed",
    text: "Feed",
    icon: <TabletOutlined className="text-xl" />,
  },
  {
    to: "/community/my",
    text: "Profile",
    icon: <UserOutlined className="text-xl" />,
  },
  {
    to: "/dashboard/launchpad/cv-builder",
    text: "Green CV",
    icon: <EnvironmentOutlined className="text-xl" />,
  },
  {
    to: "/dashboard/launchpad/cv-builder",
    text: "Green Job",
    icon: <EnvironmentOutlined className="text-xl" />,
  },
]
export const Shortcut = React.forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <BasicCollapsibleContainer
      ref={ref}
      title="Shortcut"
      titleSize="h3"
      className="text-base"
      initialOpen
    >
      <div className="py-2">
        <div className="">
          Mission Control - My{" "}
          <Link className="text-primary" to="/dashboard/launchpad">
            Launchpad
          </Link>
        </div>
        <div className="grid grid-cols-2 gap-8 py-4">
          {links.map((link, index) => (
            <Link
              key={index}
              to={link.to}
              className="flex items-center gap-3 justify-center ring-1 ring-primary rounded-md p-2"
            >
              {link.icon}{" "}
              <span className="font-semibold text-lg text-center">
                {link.text}
              </span>
            </Link>
          ))}
        </div>
      </div>
    </BasicCollapsibleContainer>
  )
})
