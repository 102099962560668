import React from "react"
import { Card } from "antd"
import {
  SolutionOutlined,
  EnvironmentOutlined,
  CalendarOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons"
import { JobHistory } from "../../../hooks/cv-builder/useJobHistory"
import { OtherCompany } from "../../../../../assets/images"
import dayjs from "dayjs"

interface Props {
  data: JobHistory
  index: number
  updateTempData: (index: number, data: JobHistory) => void
  onRemove: (val: string) => Promise<void>
  loadingremoveJobHis: boolean
  onRemoveTemp: (val: number) => void
}

export const JobView: React.FC<Props> = ({
  data,
  index,
  updateTempData,
  onRemove,
  loadingremoveJobHis,
  onRemoveTemp,
}) => {
  return (
    <Card className="rounded-xl mb-5 relative shadow-md">
      <div className="flex gap-5 flex-wrap lg:flex-nowrap">
        <img
          src={
            data?.company?.image
              ? data?.company?.image.startsWith("blob")
                ? data?.company?.image
                : `${data?.company?.image}?${new Date().getTime()}`
              : OtherCompany
          }
          alt="company"
          className="w-24 h-24 p-1 rounded-full shadow-md object-contain"
        />
        <div>
          <h3 className=" capitalize">{data.company.name || "-"}</h3>
          <div className="flex flex-wrap lg:flex-nowrap gap-x-4 gap-y-2 items-center my-1">
            <div className="">
              <SolutionOutlined />
              <span className="ml-2">{data.employment_type.name || "-"}</span>
            </div>
            <div className="">
              <EnvironmentOutlined />
              <span className="ml-2">{data.country.name || "-"}</span>
            </div>
            <small className=" text-slate-400">
              {data.remote ? "remote" : "onsite"}
            </small>
          </div>
          {data.start_at && (
            <div className="flex flex-wrap lg:flex-nowrap gap-x-4 gap-y-2 items-center">
              <div className="">
                <CalendarOutlined />
                <span className="ml-2">{`${dayjs(data.start_at).format(
                  "MMM YYYY",
                )} - ${
                  data.end_at
                    ? dayjs(data.end_at).format("MMM YYYY")
                    : "Present"
                }`}</span>
              </div>
              <small className=" text-slate-400">
                {" "}
                {getPeriode(
                  dayjs(data?.end_at ? data.end_at : Date.now())
                    .startOf("month")
                    .diff(dayjs(data.start_at).startOf("month"), "month"),
                )}
              </small>
            </div>
          )}
        </div>
      </div>
      <h3 className="capitalize mt-3">{data.job_title}</h3>
      {data.description && data.description !== "<p><br></p>" && (
        <div
          dangerouslySetInnerHTML={{ __html: data.description }}
          className=""
        ></div>
      )}
      <div className="absolute right-5 top-5 flex gap-3">
        <EditOutlined
          className="cursor-pointer"
          onClick={() => updateTempData(index, { ...data, isEdit: true })}
        />
        <DeleteOutlined
          className=" cursor-pointer"
          onClick={() => {
            if (!loadingremoveJobHis) {
              if (data.id) {
                onRemove(data.id)
              } else {
                onRemoveTemp(index)
              }
            }
          }}
        />
      </div>
    </Card>
  )
}

const getPeriode = (val: any) => {
  if (val === 0) return "1 month"
  const year = val !== 0 ? Math.floor(val / 12) : 0

  const month = val % 12

  return `${year > 0 ? year + ` ${year > 1 ? "years" : "year"}` : ""} ${
    month > 0 ? month + ` ${month > 1 ? "months" : "month"}` : ""
  }`
}
