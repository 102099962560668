import React from "react"
import FiveCircleRateTS from "../../../../common/components/FiveCircleRateTS"
import { RoadMapTable } from "../../../missionControl/components/RoadMapTable"
import useRoadmapSkills from "../../../missionControl/hooks/useRoadmapSkills"
import { Button } from "antd"
import { useNavigate } from "react-router-dom"
import { GlobalOutlined } from "@ant-design/icons"

const MySkills = () => {
  const { roadmapSkills, isFetchingRoadmap, updateRoadmapSkillOrder } =
    useRoadmapSkills()

  const navigate = useNavigate()
  return (
    <div>
      <div className="flex justify-between items-center mt-5">
        <div className="flex items-center gap-6 ">
          <div className="flex items-center gap-4 jusitfy-start">
            <FiveCircleRateTS count={1} disabled circleSize={15} />
            <div className="text-backdrop">Purchased by you</div>
          </div>
          <div className="flex items-center gap-4 jusitfy-start">
            <FiveCircleRateTS
              defaultValue={1}
              count={1}
              circleSize={15}
              disabled
            />
            <div className="text-backdrop">Skill level passed</div>
          </div>
        </div>
        <Button
          onClick={() =>
            navigate(
              "/skill/green-skills?search=&price=low-to-high&availability=start-today&sort_rank=&viewType=cards&page=1&limit=20",
            )
          }
          icon={<GlobalOutlined />}
          className="text-backdrop ring-backdrop"
        >
          Browse Green Skills
        </Button>
      </div>
      <RoadMapTable
        roadmapSkills={roadmapSkills}
        isFetchingRoadmap={isFetchingRoadmap}
        updateRoadmapSkillOrder={updateRoadmapSkillOrder}
      />
    </div>
  )
}

export default MySkills
