import { Drawer, Result, Spin } from "antd"
import React from "react"
import { LoadingOutlined } from "@ant-design/icons"
import { Content } from "../../../missionControl/components/cv-view/Content"

interface DrawerCVProps {
  open: boolean
  // setOpen: (open: boolean) => void
  onClose: () => void
  cv: any
}

const DrawerCV: React.FC<DrawerCVProps> = ({ open, onClose, cv }) => {
  return (
    <div>
      <Drawer
        title={`Drawer`}
        placement="right"
        size={"large"}
        onClose={onClose}
        open={open}
        width={"90%"}
      >
        {cv?.loadingAccount ? (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          </div>
        ) : cv?.isError ? (
          <Result
            status="500"
            title="500"
            subTitle="Sorry, the data you search does not exist."
          />
        ) : (
          <div className="mt-5">
            <Content data={cv?.account} />
          </div>
        )}
      </Drawer>
    </div>
  )
}

export default DrawerCV
