import React from "react"
import { Moon } from "../../../../assets/images"
import { Card } from "antd"

interface Props {
  title?: string
  sub_tittle?: string
  description?: string
}

export const EmptyData: React.FC<Props> = ({
  title,
  sub_tittle,
  description,
}) => {
  return (
    <Card className=" shadow-md rounded-xl bg-[#FAFAFA]">
      {title && <h2 className="capitalize mb-5">{title}</h2>}
      {sub_tittle && (
        <div className=" first-letter:capitalize mb-5">{sub_tittle}</div>
      )}
      <div className="flex gap-3 items-center">
        <img src={Moon} alt="cookies" />
        <div>{description || "Nothing to display"}</div>
      </div>
    </Card>
  )
}
