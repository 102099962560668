import { useEffect, useState } from "react"
import { useFetchExpertList } from "../apis/rest"
import { ExpertResponse } from "../types/response"

const useExpertList = () => {
  const [listData, setListData] = useState<ExpertResponse[]>([])
  const restExpertList = useFetchExpertList()

  useEffect(() => {
    if (!restExpertList.isLoading) {
      setListData(restExpertList?.data?.data?.list ?? [])
    }
  }, [restExpertList.data])

  return {
    expertResponse: {
      data: listData,
      isLoading: restExpertList.isLoading,
    },
  }
}

export default useExpertList
