import React from "react"
import { HomeOutlined } from "@ant-design/icons"
import { PageHeader } from "../../../common/layout"

export const HeaderCart = () => {
  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "Cart",
    },
  ]
  return (
    <PageHeader breadcrumbItems={items} title={"Green Skill Cart"}>
      <div>One station closer to the Universe of Opportunities</div>
    </PageHeader>
  )
}
