/* eslint-disable */

import {
  ImageAstroseed,
  ImageGreenNebula,
  ImageSolarExplorer,
  ImageStartMaster,
  ImageTerraformer,
  NotLaunched,
} from "../../../../assets/images"
import { Level } from "../../../skillGalaxy/types/common"
import { RoadmapLevel } from "../../hooks/useRoadmapSkills"

const LEVEL_NAMES = [
  "Astroseed",
  "Solar Explorer",
  "Terraformer",
  "Green Nebula",
  "Star Master",
  "Not Launched",
]

const IMAGE_NAMES = [
  ImageAstroseed,
  ImageSolarExplorer,
  ImageTerraformer,
  ImageGreenNebula,
  ImageStartMaster,
  NotLaunched,
]

const getIndexForLevelAndCurrentModule = (
  currentLevel: number | undefined,
  currentModule: number | undefined,
) => {
  if (currentLevel === 1 && currentModule !== 0) {
    return 0
  } else if (currentLevel === 2) {
    return 1
  } else if (currentLevel === 3) {
    return 2
  } else if (currentLevel === 4) {
    return 3
  } else if (currentLevel === 5) {
    return 4
  } else {
    return 5
  }
}

function countIsAssessmentPassed(levels: RoadmapLevel[]): number {
  if (!levels) {
    return 0
  }
  return levels?.reduce((count, level) => {
    if (level.user_track?.is_assessment_passed) {
      return count + 1
    }
    return count
  }, 0)
}

export const getInfoOnCurrentLevelOfSkillForUser = (
  levels: RoadmapLevel[] | Level[],
) => {
  let currentLevel: number | undefined = 1
  let currentModule: number | undefined = 0
  let currentName: string = ""
  let image: any = ""
  if (levels.length > 0) {
    const overalUserTrack = levels?.find(
      (item) => !item?.user_track?.is_assessment_passed,
    )

    if (!overalUserTrack) {
      const lastLevel = levels?.findLast(
        (item) => item?.user_track?.is_assessment_passed,
      )
      currentLevel = lastLevel?.user_track?.current_level
        ? lastLevel?.user_track?.current_level + 1
        : lastLevel?.user_track?.current_level

      currentModule = lastLevel?.user_track?.current_module
    } else {
      currentLevel = overalUserTrack?.user_track?.current_level
      currentModule = overalUserTrack?.user_track?.current_module
    }

    const index = getIndexForLevelAndCurrentModule(currentLevel, currentModule)
    currentName = LEVEL_NAMES[index]
    image = IMAGE_NAMES[index]
  }

  const progress =
    currentModule && currentModule !== 0 ? (currentModule / 5) * 100 : 0

  return {
    isNothingBought:
      currentLevel === undefined ||
      currentModule === undefined ||
      currentLevel === 0 ||
      currentModule === 0,
    currentName,
    progress,
    image,
    currentLevel,
    passedCount: countIsAssessmentPassed(levels),
  }
}
