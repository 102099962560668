import { useFetchDetail } from "../../../../hooks/generic_rest"
import { GreenSkill } from "../../../missionControl/types/common"
import { EXPERT_ENDPOINTS } from "../apis/endpoint"

const LIST_LOCATION_REF_EXPERT_QUERY_NAME = "list-location-ref-expert"
const LIST_DOMAIN_REF_EXPERT_QUERY_NAME = "list-domain-ref-expert"
export const useExpertReference = () => {
  const {
    data: listDomain,
    isLoading: listLoadingDomain,
    isError: listErrorDomain,
  } = useFetchDetail<GreenSkill[]>(
    `${EXPERT_ENDPOINTS.LIST_DOMAIN_REFERENCES}`,
    {
      queryName: LIST_DOMAIN_REF_EXPERT_QUERY_NAME,
    },
  )

  const {
    data: listLocations,
    isLoading: listLoadingLocations,
    isError: listErrorLocations,
  } = useFetchDetail<string[]>(`${EXPERT_ENDPOINTS.LIST_COUNTRY_REFERENCES}`, {
    queryName: LIST_LOCATION_REF_EXPERT_QUERY_NAME,
  })

  return {
    domainData: {
      listDomain: listDomain,
      listLoadingDomain,
      listErrorDomain,
    },
    locationData: {
      listLocations: listLocations,
      listLoadingLocations,
      listErrorLocations,
    },
  }
}
