import React from "react"
import { HomeOutlined } from "@ant-design/icons"
import { PageContent, PageHeader } from "../../../../common/layout"
import FounderProfileContent from "../components/FounderProfileContent"
import { useDetailFounder } from "../hooks/useFounder"
import { useParams } from "react-router-dom"
import { useMsal } from "@azure/msal-react"

interface FounderProfileProps {}

const FounderProfile: React.FC<FounderProfileProps> = () => {
  const { id, username, tab } = useParams()

  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()

  const { detail, cv, article, handler } = useDetailFounder(
    id!,
    username!,
    activeAccount,
    parseInt(tab!),
  )

  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      path: "/community/member",
      title: "Community",
    },
    {
      title: "Founder",
    },
    {
      title: `${detail?.detailFounder?.first_name} ${detail?.detailFounder?.last_name}`,
    },
  ]

  return (
    <PageContent
      header={<PageHeader breadcrumbItems={items} showBack={true} />}
      content={
        <FounderProfileContent
          detail={detail?.detailFounder}
          loading={detail?.detailLoadingFounder}
          cv={cv}
          article={article}
          id={id!}
          username={username!}
          tab={tab!}
          activeAccount={activeAccount}
          handler={handler}
        />
      }
    />
  )
}

export default FounderProfile
