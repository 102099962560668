import React, { useContext } from "react"
import { CheckOutlined, CloseOutlined, HomeOutlined } from "@ant-design/icons"
import PageSidebar from "../components/PageSidebar"
import { useNavigate } from "react-router-dom"
import { PageContent, PageHeader } from "../../../common/layout"
import FastJobTable from "../../../common/components/FastJobTable"
import { useMsal } from "@azure/msal-react"
import { usePrivacySetting } from "../../account/hooks/usePrivacySetting"
import { Button, Image, Skeleton, Switch } from "antd"
import { Moon, PinkRocket } from "../../../assets/images"
import useCareerAspiration from "../../../hooks/useCareerAspiration.ts"
import CareerAspirationComponent from "../components/CareerAspirationComponent.tsx"
import useRecomendationsJobs from "../../jobGalaxy/hooks/useRecomendationJobs.js"
import JobRecommendedComponent from "../components/JobRecommendedComponent.tsx"
import { CurrentTrajectory } from "../components/CurrentTrajectory.tsx"
import {
  ITourContext,
  TourContext,
} from "../../../common/components/tour/TourProvider.tsx"

interface LaunchpadProps {}

const LaunchpadV2: React.FC<LaunchpadProps> = () => {
  const items = React.useMemo(
    () => [
      {
        path: "/",
        title: <HomeOutlined className="text-gray-400" />,
      },
      {
        title: "Mission Control",
      },
      {
        title: "Launchpad",
      },
      {
        path: "/dashboard/launchpad",
        title: "My Launchpad",
      },
    ],
    [],
  )

  return (
    <>
      <PageContent
        header={
          <PageHeader
            isHeaderForAccountInfo
            breadcrumbItems={items}
            title={null}
            isLoading={false}
            isError={false}
            subtitle={null}
          />
        }
        content={<Content />}
        extraContent={<CurrentTrajectory />}
        contentRightSidebar={<PageSidebar />}
        rightSidebarRootClassName="flex flex-col gap-4"
      />
    </>
  )
}

const Content: React.FC = () => {
  const { statusPrivate, changeStatus, loadingPrivacy } = usePrivacySetting()
  const { greencareerResp } = useCareerAspiration()
  const navigate = useNavigate()
  const { accounts } = useMsal()
  const activeAccount = accounts[0]

  const { listJobRecomendation, isListJobRecomendationLoading } =
    useRecomendationsJobs(activeAccount?.localAccountId)

  const {
    refs: { refJobSeeker, refCareerAspirations },
  } = useContext(TourContext) as ITourContext
  return (
    <>
      {loadingPrivacy ? (
        <div
          className={`rounded-lg shadow-custom py-5 px-5 bg-white-custom flex gap-4`}
        >
          <Skeleton active={true} />
        </div>
      ) : statusPrivate?.discover ? (
        <div ref={refJobSeeker} className={`shadow-custom rounded-lg`}>
          <FastJobTable userId={activeAccount?.localAccountId} />
        </div>
      ) : (
        <div
          ref={refJobSeeker}
          className={`rounded-lg shadow-custom py-3 px-5 bg-white-custom flex gap-4`}
        >
          <div className={`mt-2`}>
            <Image
              preview={false}
              alt={"icon-job-seeker"}
              src={PinkRocket}
              width={35}
            />
          </div>
          <div>
            <h4>Active Job seeker?</h4>
            <p className={`p-0 m-0`}>
              Make you Green CV discoverable to Companies on Skilledin Green
              platform!{" "}
            </p>
            <div className="flex items-center gap-3 mt-4 mb-3">
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked={true}
                onChange={() =>
                  changeStatus("discover", !statusPrivate?.discover)
                }
                checked={statusPrivate?.discover}
              />
              {statusPrivate?.discover ? (
                <span>
                  Your CV is <b>discoverable</b> by recruiters
                </span>
              ) : (
                <span>
                  Your CV is <b>not discoverable</b> by recruiters
                </span>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="pb-5 mt-1 shadow-lg bg-white-custom rounded-xl">
        <div className="flex items-center justify-between w-full px-5 py-4">
          <h4 ref={refCareerAspirations} className="">
            Career Aspirations
          </h4>
          <Button
            onClick={() => navigate("/dashboard/launchpad/cv-builder")}
            className="bg-white border-primary"
          >
            Edit in CV Builder
          </Button>
        </div>
        {greencareerResp?.greencareers === null ? (
          <div className={`flex items-center gap-3 mx-4`}>
            <Image preview={false} src={Moon} alt={"empty"} width={40} />
            <p className={`m-0`}>
              Let's do some <b>Career Planning</b> - choose up to 5 Green
              Economy job roles you aspire to land!
            </p>
          </div>
        ) : (
          <CareerAspirationComponent greencareerResp={greencareerResp} />
        )}
      </div>

      {isListJobRecomendationLoading ? (
        <div className="p-4 pb-5 mt-4 mb-4 rounded-lg shadow-custom">
          <div className={`bg-white p-4 rounded-xl `}>
            <Skeleton active={true} />
          </div>{" "}
        </div>
      ) : listJobRecomendation === null ? null : (
        <div className="pb-5 mt-4 mb-4 rounded-lg shadow-custom">
          <div className="flex items-center justify-between w-full px-5 py-4">
            <h4 className="">Recommended Jobs</h4>
            <Button
              onClick={() => navigate("/job/roles")}
              className="bg-white border-primary"
            >
              Browse other jobs
            </Button>
          </div>
          <JobRecommendedComponent
            listJobRecomendation={listJobRecomendation}
          />
        </div>
      )}
    </>
  )
}

export default LaunchpadV2
