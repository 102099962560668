import { Tag } from "antd"
import { useModalStore } from "../../../stores"
// eslint-disable-next-line
import { Perk as PerkType } from "../hook/usePerks"
import { CalendarOutlined } from "@ant-design/icons"
import dayjs from "dayjs"

interface PerkProps {
  perk: PerkType
  extraContent?: React.ReactNode
  className?: string
  unauthenticated?: boolean
}

export const PerkCard: React.FC<PerkProps> = ({
  perk,
  extraContent,
  className,
  unauthenticated,
}) => {
  const { openModal } = useModalStore()

  return (
    <div
      onClick={unauthenticated ? openModal : undefined}
      className={`basic-card bg-white group-hover:scale-105 flex flex-col justify-between ${className}`}
    >
      <div className="flex justify-between flex-wrap gap-2">
        <Tag
          className="text-brown bg-secondaryYellow"
          icon={<CalendarOutlined />}
        >
          {perk?.expire_at
            ? `Ends ${dayjs(perk?.expire_at).format("DD MMM YYYY")}`
            : "Ongoing"}
        </Tag>
        <div className="uppercase text-sm whitespace-nowrap">
          {perk?.company_name}
        </div>
      </div>
      <img
        src={perk.company_logo}
        className="w-[200px] mx-auto h-[200px] object-contain"
      />
      <div className="text-center font-bold text-xl">{perk?.offer_title}</div>
      {extraContent && extraContent}
    </div>
  )
}
