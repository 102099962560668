import React from "react"
import { GreenSkillsLogo } from "../../assets/images"
import Lottie from "lottie-react"
import success_payment from "../../assets/json/success_payment.json"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"

interface LayoutProps {
  children: React.ReactNode
  topBarChildren?: React.ReactNode
}

export const AuthLayout: React.FC<LayoutProps> = (props) => {
  const navigate = useNavigate()
  return (
    <div className=" min-h-screen bg-white relative overflow-y-auto">
      <div className=" p-10 bg-white  -mb-10">
        <img
          src={GreenSkillsLogo}
          onClick={() => navigate("/")}
          alt="Skilledin logo"
          className="w-[111px] h-[34px] object-contain cursor-pointer"
        />

        <div className="golden-subtitle mt-3">talent solution</div>
      </div>
      <div className="w-[calc(100%-20rem)]">{props.topBarChildren}</div>
      <div className="z-20 lg:w-[calc(100%-20rem)] relative w-full">
        {props.children}
      </div>
      <div className="z-10 w-0 hidden lg:block lg:w-80 h-screen bg-blue-100 fixed top-0 right-0">
        <div className="relative h-full w-full hidden lg:block">
          <div className="w-[500px] absolute bottom-32 right-20">
            <Lottie className="" animationData={success_payment} />
          </div>
        </div>
        <div className="fixed bottom-10 flex flex-col items-center w-80">
          <div className="">{`Copyright ©${dayjs().format(
            "YYYY",
          )} Skilledin`}</div>
          <div className=" mt-[14px]">
            <a href="https://green.skilledin.io/privacy-policy/">
              Privacy Policy
            </a>{" "}
            |{" "}
            <a href="https://green.skilledin.io/terms-of-use/">Terms of Use</a>
          </div>
        </div>
      </div>
    </div>
  )
}
