/* eslint-disable */

import { message } from "antd"
import instance, { instanceV2 } from "../../../config/config"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { ApiResponsePaginated } from "../../../common/types/ApiResponse"
import { FavoriteSkillInfinite } from "../../skillGalaxy/types/common"

interface Params {
  limit?: number
  page?: number
}

const fetchFavoriteSkills = (
  params: Params,
): Promise<ApiResponsePaginated<FavoriteSkillInfinite>> => {
  return instanceV2.get("green-skills/favorites", {
    params: params,
  })
}

const removeSkillFromFavorite = (skill_id: string) => {
  message.open({
    key: "remove-skill-favorite",
    content: "Unfavoriting...",
    type: "loading",
  })
  return instance.delete(`green-skills/favorites/${skill_id}`)
}

export const useFavoriteSkillsPaginated = (params: Params) => {
  return useQuery(
    ["favorite-skills", params],
    () => fetchFavoriteSkills(params),
    {
      refetchOnWindowFocus: false,
    },
  )
}

export const useRemoveFavoriteSkill = () => {
  const queryClient = useQueryClient()

  return useMutation((skill_id: string) => removeSkillFromFavorite(skill_id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["fetch-favorite"])
      queryClient.invalidateQueries(["favorite-skills"])
      queryClient.invalidateQueries(["fetch-green-skills"])
      message.open({
        key: "remove-skill-favorite",
        content: "Skill unfavorited",
        type: "success",
      })
    },
    onError: () => {
      message.open({
        key: "remove-skill-favorite",
        content: "Error unfavoriting skill",
        type: "error",
      })
    },
  })
}
