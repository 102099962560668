/* eslint-disable */

import { useEffect, useState } from "react"
import { requestPermission } from "../../../config/fcm"
import { notification } from "antd"
import { NotifyData } from "../types/notifs"
import { MessagePayload, onMessage } from "firebase/messaging"
import { messaging } from "../../../config/fcm"

export const onMessageListener = (): Promise<MessagePayload> =>
  new Promise((resolve) => {
    if (messaging) {
      onMessage(messaging, (payload) => {
        resolve(payload)
      })
    }
  })

// Hook that listens to FB
export const useFirebaseNotifications = () => {
  const [notifModalShow, setNotifModalShow] = useState(false)
  const [popUpModalOpen, setPopUpModalOpen] = useState(false)
  const [popUpModalData, setPopUpModalData] =
    useState<NotifyData<string> | null>(null) // specify type here
  const [fcmToken, setFCMToken] = useState<String | null>(null)
  const [api, contextHolder] = notification.useNotification()

  useEffect(() => {
    if (Notification.permission === "denied") {
      setNotifModalShow(true)
    }
    Notification.requestPermission().then((permission) => {
      if (permission !== "granted") {
        setNotifModalShow(true)
      } else {
        setNotifModalShow(false)
      }
    })
  }, [fcmToken, setNotifModalShow])

  useEffect(() => {
    requestPermission(setFCMToken)
  }, [])

  return {
    contextHolder,
    popUpModalOpen,
    setPopUpModalOpen,
    popUpModalData,
    setPopUpModalData,
    notifModalShow,
    setNotifModalShow,
    fcmToken,
    api,
  }
}
