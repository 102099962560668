import { useMsal } from "@azure/msal-react"
import { loginRequest, registerRequest } from "../../../authConfig"

export const useHeader = () => {
  const { instance } = useMsal()
  // const activeAccount = instance.getActiveAccount();
  const handleLoginRedirect = () => {
    instance
      .loginRedirect(loginRequest)

      .catch((error) => console.log(error))
  }

  const handleRegisterRedirect = (userLocation) => {
    instance
      .loginRedirect({
        ...registerRequest,
        extraQueryParameters: {
          country: userLocation?.address?.country,
          streetAddress: userLocation?.address?.freeformAddress,
          extension_locationDetailed: JSON.stringify(userLocation?.position),
        },
      })

      .catch((error) => console.log(error))
  }

  const handleLogoutRedirect = () => {
    instance.logoutRedirect()
  }

  // const handleLoginPopup = () => {
  //   instance
  //     .loginPopup({
  //       ...loginRequest,
  //       redirectUri: 'http://localhost:3000',
  //     })
  //     .catch((error) => console.log(error))
  // }

  // const handleLogoutPopup = () => {
  //   instance.logoutPopup({
  //     mainWindowRedirectUri: '/', // redirects the top level app after logout
  //   })
  // }

  return {
    handleLogoutRedirect,
    handleLoginRedirect,
    handleRegisterRedirect,
  }
}
