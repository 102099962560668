import React from "react"
import { EditOutlined } from "@ant-design/icons"
import { PinMapGray } from "../../../../assets/images"
import { SocialMediaLinks } from "../../../jobGalaxy/components/SocialMediaLink"
import { Image, Skeleton } from "antd"
// import { useBasic } from "../../missionControl/hooks/cv-builder/useBasic"
import { useProfile } from "../../../missionControl/hooks/cv-builder/useProfile"
import { useProfile as profileV1 } from "../../../missionControl/hooks/useProfile"
// import { useBasic } from "../../missionControl/hooks/cv-builder/useBasic"

interface ExpertProfileDisplayProps {
  editAction: any
}

const ExpertProfileDisplay: React.FC<ExpertProfileDisplayProps> = ({
  editAction,
}) => {
  const { account, loadingAccount } = useProfile()
  const { account: v1Account, loadingAccount: loadingAccountV1 } = profileV1()

  const reFormatSosmed = (sosmed: any) => {
    const data = {
      mail_url: sosmed?.email,
      website_url: sosmed?.link,
      linkedin_url: sosmed?.linkedin,
      twitter_url: sosmed?.twitter,
    }

    return data
  }

  return loadingAccount && loadingAccountV1 ? (
    <div className="bg-white-custom px-4 py-6 rounded-xl shadow-custom-sm relative">
      <Skeleton avatar paragraph={{ rows: 4 }} active />{" "}
    </div>
  ) : (
    <div className="bg-white-custom px-4 py-6 rounded-xl shadow-custom-sm relative">
      <div className="flex gap-5">
        {account?.profile_picture ? (
          <Image
            className="object-cover"
            src={account?.profile_picture}
            alt="profile"
            width={100}
            height={100}
            preview={false}
          />
        ) : (
          <Image
            className="object-cover"
            src={account?.avatar}
            alt="profile"
            width={100}
            height={100}
            preview={false}
          />
        )}

        <div className="space-y-3">
          <h3 className="capitalize">
            {account?.first_name} {account?.last_name}
          </h3>
          <div className="my-1 flex gap-2">
            {SocialMediaLinks(reFormatSosmed(v1Account?.data?.data?.links))}
          </div>

          <div>
            <PinMapGray />
            <span className="ml-2">{account?.country}</span>
          </div>
        </div>
      </div>

      {account?.about_me_expert &&
        account?.about_me_expert !== "<p><br></p>" && (
          <div
            className="mt-2"
            dangerouslySetInnerHTML={{
              __html: account?.about_me_expert,
            }}
          ></div>
        )}
      <EditOutlined
        className=" absolute right-5 top-7"
        onClick={() => editAction?.setIsEditView(true)}
      />
    </div>
  )
}

export default ExpertProfileDisplay
