import React from "react"
import { Empty, Image, Skeleton, Tag, Typography } from "antd"
import { Breadcrumbs } from "../../../../common/components"
import {
  DollarOutlined,
  FileDoneOutlined,
  CarOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { DefaultCompanyLogoImg } from "../../../../assets/images"

const { Title } = Typography

interface MyJobHistoryContentTopSectionProps {
  breadcrumbsItems: any[] // Replace 'any' with the actual type of breadcrumbsItems
}

export const MyJobHistoryContentTopSection: React.FC<
  MyJobHistoryContentTopSectionProps
> = ({ breadcrumbsItems }) => {
  return (
    <div>
      <div>
        <div className="bg-white  py-4 px-8">
          <Breadcrumbs items={breadcrumbsItems} />

          <div className="text-backdrop mt-2">
            <Title>Applied and Saved Jobs</Title>
          </div>
        </div>
      </div>
    </div>
  )
}

interface MyJobHistoryContentSectionProps {
  data: any[] | null // Replace 'any' with the actual type of data
  isListJobHistoryLoading?: boolean
}

export const MyJobHistoryContentSection: React.FC<
  MyJobHistoryContentSectionProps
> = ({ data, isListJobHistoryLoading }) => {
  return (
    <>
      <div className="">
        {!data || data?.length === 0 ? (
          <div className="p-6 bg-white-custom rounded-xl shadow-custom-sm">
            <Title level={3}>Applied Jobs</Title>
            <Empty description="No jobs available." className="mt-6" />
          </div>
        ) : (
          <div className="bg-white-custom px-6 shadow-custom-sm rounded-xl py-6">
            <div className="mb-4">
              <Title level={3}>Applied Jobs</Title>
            </div>

            {isListJobHistoryLoading ? (
              <div className="p-6 bg-white-custom rounded-xl shadow-custom-sm">
                <div className="flex items-center justify-center">
                  <Skeleton active />
                </div>
              </div>
            ) : (
              data?.map((item, index) => (
                <div
                  className="py-3 flex items-center justify-between bg-white rounded-xl shadow-custom-sm px-4 mb-3"
                  key={index}
                >
                  <div className="flex items-center gap-5">
                    {item?.company?.logo ? (
                      <Image
                        src={item?.company?.logo ? item?.company?.logo : ""}
                        preview={false}
                        alt={`logo-${item?.company_name}`}
                        //  height={80}
                        width={50}
                      />
                    ) : (
                      <Image
                        src={DefaultCompanyLogoImg}
                        preview={false}
                        alt={`logo-${item?.company_name}`}
                        //  height={80}
                        width={50}
                      />
                    )}

                    <div className="space-y-2">
                      <p className="m-0 p-0 text-base capitalize">
                        {item?.job?.name}
                      </p>
                      <div className="flex items-center gap-7">
                        <div className="flex gap-2 items-center">
                          <EnvironmentOutlined />
                          <p className="m-0 p-0 capitalize">
                            {item?.job?.location?.name}
                          </p>
                        </div>
                        <div className="flex gap-2 items-center">
                          <DollarOutlined />
                          <p className="m-0 p-0 capitalize">
                            {item?.job?.salary_currency?.code}{" "}
                            {item?.job?.salary_from} - {item?.job?.salary_up_to}
                          </p>
                        </div>
                        <div className="flex gap-2 items-center">
                          <FileDoneOutlined />
                          <p className="m-0 p-0 capitalize">
                            {item?.job?.job_type?.name}
                          </p>
                        </div>
                        <div className="flex gap-2 items-center">
                          <CarOutlined />
                          <p className="m-0 p-0 capitalize">
                            {!item?.job?.remote ? "on-site" : "remote"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Tag
                      className={`rounded-full ${
                        item?.application_status === "in review"
                          ? "bg-[#FFEFA0]"
                          : item?.application_status === "hired"
                            ? "bg-[#D4F5C5]"
                            : item?.application_status === "expired"
                              ? "bg-[#D9D9D9]"
                              : " "
                      }`}
                    >
                      {item?.application_status}
                    </Tag>
                  </div>
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </>
  )
}

interface MyJobHistoryContentRightSectionProps {
  data: any // Replace 'any' with the actual type of data
  isListJobSavedLoading?: boolean
}

export const MyJobHistoryContentRightSection: React.FC<
  MyJobHistoryContentRightSectionProps
> = ({ data, isListJobSavedLoading }) => {
  const navigate = useNavigate()
  return (
    <div className="flex flex-col gap-4">
      <div className="py-5 px-5 bg-white-custom shadow-custom-sm rounded-xl">
        <div className="text-backdrop text-lg font-bold mb-4">Saved Jobs</div>
        <div className="shadow-custom-sm bg-white rounded-xl px-5 py-2">
          <ul className="p-0">
            {isListJobSavedLoading ? (
              <Skeleton active />
            ) : (
              data?.list?.map((item: any, index: number) => (
                <li key={index} className="flex items-center custom-item mt-3">
                  {
                    <div
                      className={`text-secondary font-medium text-base cursor-pointer hover:font-semibold`}
                      onClick={() => navigate(`/job/board/${item?.job_id}`)}
                    >
                      {item?.job_name}
                    </div>
                  }
                </li>
              ))
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}
