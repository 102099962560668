import { Button, Input } from "antd"
import React, { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { useProfile } from "../hooks/useProfile"

const { TextArea } = Input

export const AboutProfile = () => {
  const { handleSubmit, control, reset, formState } = useForm({
    defaultValues: {
      about_me: "",
    },
  })

  const { account, updateAbout, loadingAbout, successAbout } = useProfile()
  useEffect(() => {
    if (account) {
      reset({
        about_me: account?.data?.data?.about_me,
      })
    }
  }, [account?.data?.data])
  useEffect(() => {
    if (successAbout) {
      reset({}, { keepValues: true })
    }
  }, [successAbout])

  return (
    <div className="p-5 bg-white mt-5 font-default">
      <div
        className=" flex justify-between items-center pb-3 mb-3"
        style={{ borderBottom: "1px solid #D9D9D9" }}
      >
        <div className="font-semibold text-md">About Me</div>
        <Button
          className={`font-default rounded-none ${
            formState.isDirty && "hover:bg-[#D4F5C5]"
          } border-[#86C769]`}
          loading={loadingAbout}
          disabled={!formState.isDirty}
          onClick={handleSubmit(updateAbout)}
        >
          Save Changes
        </Button>
      </div>
      <div className="my-5">
        <form onSubmit={handleSubmit(updateAbout)}>
          <div className="">
            <Controller
              name="about_me"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextArea
                  {...field}
                  className="my-1 font-default rounded-none"
                  placeholder="Type here"
                  rows={5}
                />
              )}
            />
          </div>
        </form>
      </div>
    </div>
  )
}
