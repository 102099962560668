import { Avatar } from "antd"
import React, { useState, useEffect } from "react"

interface AvatarCustomProps {
  url?: string
  display_name: string
  label?: string
}

const AvatarCustom: React.FC<AvatarCustomProps> = ({
  url,
  display_name,
  label,
}) => {
  const [randomColor, setRandomColor] = useState<string>("")

  useEffect(() => {
    const getRandomFromArray = (arr: string[]) => {
      return arr[Math.floor(Math.random() * arr.length)]
    }

    const backgroundColors = ["bg-[#ED7574]", "bg-[#4E9BCE]", "bg-[#F2c61f]"]
    const color = getRandomFromArray(backgroundColors)
    setRandomColor(color)
  }, []) // Empty dependency array ensures this effect runs only once on mount

  const getInitials = (name: string) => {
    const words = name.split(" ")
    let initials = ""

    words.forEach((word) => {
      const firstChar = word.charAt(0)
      if (/[a-zA-Z0-9]/.test(firstChar)) {
        initials += firstChar
      }
    })

    return initials.toUpperCase()
  }

  const handleError = () => {
    console.error("Avatar image failed to load")
    return true
  }

  const colorLabel =
    label === "Mentor"
      ? "bg-backdrop"
      : label === "Founder"
        ? "bg-primary"
        : label === "Expert"
          ? "bg-secondary"
          : ""

  return (
    <div className="flex items-center justify-center flex-col">
      <Avatar
        size={{ xs: 24, sm: 32, md: 80, lg: 100, xl: 120, xxl: 100 }}
        src={url}
        className={`${randomColor} object-fill`}
        onError={handleError}
      >
        <span className="m-0 p-0 text-3xl font-semibold">
          {getInitials(display_name)}
        </span>
      </Avatar>
      <small className={`px-2 ${colorLabel} text-white -mt-2 z-40 rounded`}>
        {label}
      </small>
    </div>
  )
}

export default AvatarCustom
