/* eslint-disable */

import { useMutation } from "react-query"
import instance from "../../../../config/config"
import { message } from "antd"
import { useMsal } from "@azure/msal-react"
import { useModalStore } from "../../../../stores"

export const useFollowUnFollowCompany = () => {
  const { instance: account } = useMsal()
  const activeAccount = account.getActiveAccount()
  const openModal = useModalStore((state) => state.openModal)

  const { mutateAsync: follow, isLoading: loadingFollow } = useMutation({
    mutationFn: async (val: any) => {
      try {
        const res = await instance.post("/companies/favourites", val)
        return res
      } catch (error) {
        throw error
      }
    },
    mutationKey: "follow-company",
  })

  const handleFollow = async (val: any) => {
    if (!activeAccount?.localAccountId) {
      openModal()
      return
    }
    message.open({
      key: "follow-company",
      content: "loading...",
      type: "loading",
    })
    const data = {
      company_id: val.id,
      company_name: val.name,
      user_id: activeAccount?.localAccountId,
    }
    try {
      const res = await follow(data)
      if (res) {
        message.open({
          key: "follow-company",
          content: "followed",
          type: "success",
        })
      }
      return res
    } catch (error: any) {
      message.open({
        key: "follow-company",
        content: error?.response?.data?.data || "Internal server error",
        type: "error",
      })
    }
  }

  const { mutateAsync: unfollow, isLoading: loadingUnfollow } = useMutation({
    mutationFn: async (val: any) => {
      try {
        const res = await instance.delete(
          `/companies/favourites/${activeAccount?.localAccountId}/${val}`,
        )
        return res
      } catch (error) {
        throw error
      }
    },
    mutationKey: "unfollow-company",
  })

  const handleUnfollow = async (val: any) => {
    if (!activeAccount?.localAccountId) {
      openModal()
      return
    }
    message.open({
      key: "unfollow-company",
      content: "loading...",
      type: "loading",
    })

    try {
      const res = await unfollow(val)
      if (res) {
        message.open({
          key: "unfollow-company",
          content: "unfollowed",
          type: "success",
        })
      }
      return res
    } catch (error: any) {
      message.open({
        key: "unfollow-company",
        content: error?.response?.data?.data || "Internal server error",
        type: "error",
      })
    }
  }

  return {
    handleFollow,
    handleUnfollow,
    loading: loadingFollow || loadingUnfollow,
  }
}
