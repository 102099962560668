import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react"
import { Divider, Drawer, DrawerProps, Menu, Tag } from "antd"
import React, { useContext, useMemo } from "react"
import HeaderLogo from "./HeaderLogo"
import {
  returnSidebarAuthenticatedMenuItems,
  returnSidebarUnauthMenuItems,
} from "./menuItems"
import { BasicUserInfo } from "../../components"
import { AccountContext } from "../PageLayout"
import { useLocation } from "react-router-dom"

interface MobileMenuProps extends DrawerProps {}

export const MobileMenu: React.FC<MobileMenuProps> = (props) => {
  return (
    <Drawer
      {...props}
      closeIcon
      // eslint-disable-next-line
      // styles={{
      //   header: { border: "none" },
      // }}
      placement="right"
      // width={320}
    >
      <AuthenticatedMobileMenu />
      <UnauthenticatedMobileMenu />
    </Drawer>
  )
}

const UnauthenticatedMobileMenu: React.FC = () => {
  const location = useLocation()
  const { menuItems, activeLink } = useMemo(() => {
    return returnSidebarUnauthMenuItems(location.pathname)
  }, [location.pathname])
  return (
    <UnauthenticatedTemplate>
      <div className="w-full py-4 flex items-center justify-center">
        <HeaderLogo />
      </div>
      <div className="w-full bg-secondaryBlue mt-5 py-2 flex flex-col gap-2 items-center justify-center">
        <div className="">Looking for Talent? Visit</div>
        <a href={process.env.REACT_APP_RECRUITER_SPA_URL}>
          <Tag
            className={`cursor-pointer rounded-full font-semibold bg-secondaryYellow`}
          >
            Recruiter Solution
          </Tag>
        </a>
      </div>
      <Menu
        mode="inline"
        className="mt-4 !bg-white custom  h-full !text-backdrop "
        selectedKeys={activeLink ? [activeLink] : []}
        style={{
          borderRight: 0,
          width: "100%",
        }}
        items={menuItems}
      />
    </UnauthenticatedTemplate>
  )
}

const AuthenticatedMobileMenu: React.FC = () => {
  const account = useContext(AccountContext)
  const accountData = account?.data?.data
  const location = useLocation()
  const { menuItems, activeLink } = useMemo(() => {
    return returnSidebarAuthenticatedMenuItems(
      !!accountData?.is_expert,
      location.pathname,
    )
  }, [location.pathname])
  return (
    <AuthenticatedTemplate>
      <BasicUserInfo
        avatar={accountData?.profile_picture ?? accountData?.avatar}
        avatarProps={{
          size: 55,
        }}
        name={accountData?.first_name ?? "" + accountData?.last_name ?? ""}
        nameProps={{ style: { fontWeight: "700", fontSize: "16px" } }}
        location={accountData?.nick_name}
        locationProps={{
          style: {
            fontWeight: "400",
            fontSize: "14px",
            color: "var(--color-backdrop)",
          },
        }}
      />
      <Divider className="my-4" />
      <Menu
        mode="inline"
        className="custom"
        selectedKeys={activeLink ? [activeLink] : []}
        style={{
          borderRight: 0,
          width: "100%",
        }}
        items={menuItems}
      />
    </AuthenticatedTemplate>
  )
}
