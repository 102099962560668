import { Avatar, Popover } from "antd"
import React from "react"
import { EnvironmentOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { FounderResponse, SolutionResponse } from "../types/response"
import { Earth } from "../../../../assets/images"
import AvatarCustom from "../../../../common/components/AvatarCustom"

interface CardProfileProps {
  founderItem: FounderResponse
}

const CardProfile: React.FC<CardProfileProps> = ({ founderItem }) => {
  const navigate = useNavigate()

  const solutions: SolutionResponse[] =
    founderItem?.startup?.products?.[0]?.solutions || []

  const visibleSolutions: SolutionResponse[] = solutions?.slice(0, 2)

  const renderedSolutions = visibleSolutions.map((solution) => (
    <div
      key={solution.id}
      className="flex items-center bg-white px-2 rounded-full text-xs break-words"
      style={{ border: "1px solid #36505E" }}
    >
      {solution.name}
    </div>
  ))

  const popoverContent = solutions?.length > 2 && (
    <div className="flex flex-col gap-1">
      {solutions.slice(2).map((solution) => (
        <div
          key={solution.id}
          className="flex items-center bg-white px-2 rounded-full text-xs break-words"
          style={{ border: "1px solid #36505E" }}
        >
          {solution.name}
        </div>
      ))}
    </div>
  )

  return (
    <div
      className="py-3 px-4 bg-white-custom rounded-xl shadow-custom-sm space-y-3 hover:scale-[1.02] transition-all cursor-pointer"
      onClick={() =>
        navigate(
          `/community/founder/profile/${founderItem?.id}/${founderItem?.nick_name}/2`,
        )
      }
    >
      <div className="flex items-center justify-center flex-col gap-y-3">
        <div className="flex items-center justify-center flex-col">
          {/* <Avatar
            size={{ xs: 24, sm: 32, md: 80, lg: 100, xl: 120, xxl: 100 }}
            src={founderItem?.avatar}
            className=""
          />
          <small className="px-2 bg-primary text-white -mt-2 z-40 rounded">
            {founderItem?.label}
          </small> */}

          <AvatarCustom
            url={founderItem?.avatar}
            display_name={
              founderItem?.first_name + " " + founderItem?.last_name
            }
            label={"Founder"}
          />
        </div>

        <div className="flex flex-col items-center gap-2 justify-center">
          <h3>
            {founderItem?.first_name} {founderItem?.last_name}
          </h3>
          <div className="-mt-2 mb-2 flex items-center gap-2 justify-center">
            <EnvironmentOutlined /> {founderItem?.country}
          </div>
          <p className="m-0 leading-5 line-clamp-2 text-[14px] h-10">
            {founderItem?.about_me ||
              "This user hasn't added a description yet. Come back soon to see what they have to share!"}
          </p>
        </div>

        <div className="px-5 py-3 bg-primary-bg w-full rounded-xl">
          <div className="flex items-center justify-between">
            <div
              className="flex items-center gap-1 md:gap-3 bg-[#FFF2E8] px-2 rounded hover:scale-105 transition-all cursor-pointer"
              style={{ border: "1px solid #36505E" }}
            >
              <Earth />
              <p className="m-0 text-[10px] md:text-xs">My Climate Solution</p>
            </div>
            <p className="m-0 text-[#966F02] text-xs uppercase tracking-widest ">
              {founderItem?.startup?.stage?.name}
            </p>
          </div>

          <div className="text-center">
            <h4>{founderItem?.startup?.name}</h4>
          </div>

          <div className="">
            <p className="m-0 line-clamp-2 text-[12px] h-10 text-center">
              {founderItem?.startup?.short_description}
            </p>
          </div>

          <div className="flex flex-wrap items-center gap-x-1 gap-y-1 justify-center mt-2">
            {renderedSolutions}

            {popoverContent && (
              <Popover placement="right" title={""} content={popoverContent}>
                <div
                  className="flex items-center bg-white px-2 rounded-full text-xs break-words hover:scale-105 transition-all cursor-pointer"
                  style={{ border: "1px solid #36505E" }}
                >
                  ...
                </div>
              </Popover>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardProfile
