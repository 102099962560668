import React, { useEffect, useState } from "react"
import { JobRole } from "../../types/jobRole"
import {
  HeartOutlined,
  ShoppingCartOutlined,
  HeartFilled,
  LoadingOutlined,
  CheckOutlined,
} from "@ant-design/icons"
import { Popover, Progress } from "antd"
import FiveCircleRateTS from "../../../../common/components/FiveCircleRateTS"
import { TechnicalSkill } from "../commons/TechnicalSkill"
import { useJobRoleSoftSkill } from "../../hooks/jobRoles/useJobRoleSoftSkill"
import { SoftSkill } from "../commons/SoftSkill"
import useFavoriteSkills from "../../../missionControl/hooks/useFavoriteSkills"
import { useMsal } from "@azure/msal-react"
import { useJobRoleSkill } from "../../hooks/jobRoles/useJobRoleSkill"
import { useModalStore } from "../../../../stores"
import { DetailPopContent } from "./DetailPopContent"
import { BasicSoftSkill } from "../../types/common"

interface Props {
  data: JobRole
}
export const TabSkillProfile: React.FC<Props> = ({ data }) => {
  const { instance: user } = useMsal()
  const activeAccount = user.getActiveAccount()
  const {
    favoriteSkills: dataFav,
    addSkillToFavorite: addSkillToFavoriteMutate,
    removeSkillFromFavorite,
  } = useFavoriteSkills(!!activeAccount?.localAccountId)
  const { data: dataSoft, isLoading, isError } = useJobRoleSoftSkill(data.id)
  const { data: dataSkill, isLoading: isLoadingSkill } = useJobRoleSkill(
    data.id,
  )

  const openModal = useModalStore((state) => state.openModal)

  const [defaultSoftSkill, setDefaultSoftSkill] = useState<BasicSoftSkill[]>([])

  useEffect(() => {
    if (data && data?.critical_skills) {
      setDefaultSoftSkill(
        data?.critical_skills?.map((item) => ({
          owner: "Job",
          name: item.name,
          proficiency: item.level,
        })),
      )
    }
  }, [data])

  return (
    !isLoading &&
    !isError && (
      <>
        <div className=" p-5 rounded-xl shadow-md bg-[#fafafa]">
          <h3 className="capitalize">green skills</h3>
          <>
            {data?.green_skills?.map((item, i) => {
              return (
                <div
                  key={i}
                  className="flex md:gap-5 gap-2 md:items-center  md:flex-row flex-col rounded px-3 py-1 my-2 bg-[#F0F2F5] md:h-14"
                  style={{ border: "1px solid #D0DDE5" }}
                >
                  <FiveCircleRateTS
                    count={5}
                    defaultValue={item.level}
                    circleSize={16}
                    disabled
                  />
                  <div className=" flex items-center justify-between w-full flex-wrap md:flex-nowrap">
                    <div className="md:text-left">{item?.name}</div>
                    <div className="flex gap-3 items-center">
                      <div className="w-32 md:w-40 flex items-baseline pt-2">
                        <Progress
                          percent={Math.round(
                            (item.level_passed / item.level) * 100,
                          )}
                          strokeColor={"#86C769"}
                        />
                      </div>
                      {dataFav?.data?.data?.find(
                        (val: any) => val.green_skill_id === item.id,
                      ) ? (
                        <HeartFilled
                          className=" text-primary"
                          onClick={() => {
                            if (!activeAccount?.localAccountId)
                              return openModal()
                            removeSkillFromFavorite(item.id)
                          }}
                        />
                      ) : (
                        <HeartOutlined
                          onClick={() => {
                            if (!activeAccount?.localAccountId)
                              return openModal()
                            addSkillToFavoriteMutate(item.id)
                          }}
                        />
                      )}
                      <div className="bg-primary w-6 h-6 rounded-full flex justify-center items-center text-white">
                        {!isLoadingSkill ? (
                          !dataSkill?.find((val) => val.id === item.id)
                            ?.detail ||
                          dataSkill
                            ?.find((val) => val.id === item.id)
                            ?.detail?.levels?.find(
                              (val) =>
                                val.proficiency <= item.level &&
                                val.status_transaction === 0,
                            ) ? (
                            <Popover
                              content={
                                <DetailPopContent
                                  data={dataSkill?.find(
                                    (val) => val.id === item.id,
                                  )}
                                />
                              }
                            >
                              <ShoppingCartOutlined />
                            </Popover>
                          ) : (
                            <CheckOutlined />
                          )
                        ) : (
                          <LoadingOutlined />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </>
        </div>
        {/* <div className=" mt-5 "> */}
        {data?.other_skills && (
          <div className="w-full mt-5">
            <TechnicalSkill data={data.other_skills} />
          </div>
        )}
        {!isLoading && !isError && (
          <div className="w-full mt-5 overflow-auto md:overflow-hidden">
            <SoftSkill data={dataSoft || defaultSoftSkill} />
          </div>
        )}
        {/* </div> */}
      </>
    )
  )
}
