import { useQuery } from "react-query"
import instance from "../../../../config/config"
import { ApiResponse } from "../../../../common/types/ApiResponse"
import { JobRole } from "../../types/jobRole"
import { useParams } from "react-router-dom"
import { useMsal } from "@azure/msal-react"
import { errorMessage } from "../../../../common/utils/utils"

export const useJobRoleDetail = () => {
  const { id } = useParams()
  const { instance: user } = useMsal()
  const activeAccount = user.getActiveAccount()
  const { data, isLoading, isFetching } = useQuery<ApiResponse<JobRole>>(
    ["jobrole-detail"],
    () => instance.get(`jobs/${id}/${activeAccount?.localAccountId || "none"}`),
    {
      onError: (err: any) =>
        errorMessage(err?.response?.data?.data || "internal server error", err),
    },
  )
  return { data: data?.data?.data, isLoading: isLoading || isFetching }
}
