import React from "react"
import { ClimatePulse } from "../../../../assets/images"

const News = () => {
  return (
    <div className="mt-4">
      <div
        className={`" text-xl font-semibold mb-5 gap-3 flex items-center flex-wrap lg:flex-nowrap gap-3"`}
      >
        Powered by{" "}
        <a
          href="https://www.climatepulse.news/"
          target="_blank"
          rel="noreferrer"
        >
          <img className="h-8 " src={ClimatePulse} />
        </a>
      </div>
      <iframe
        src="https://www.climatepulse.news/pulses/cltf8qmfh000227283mm5l9de9p2---climate-reporting-singapore/embed"
        className="w-full h-screen rounded-md "
        style={{ border: "none" }}
      ></iframe>

      {/* <Empty description="No news yet." /> */}
    </div>
  )
}

export default News
