import React from "react"
import useCommunityModalsStore from "../../store/useCommunityModalsStore"
import { Button, Form, Input, message, Modal, Select } from "antd"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { useFounderReference } from "../../founder/hooks/useFounderReference"
import { CloseOutlined } from "@ant-design/icons"
import { useMutation, useQueryClient } from "react-query"
import { addStartupProduct } from "../apis/founders"
import useFounderAndStartupData from "../hooks/useFounderAndStartup"
import { showError } from "../../../../common/utils/utils"
import { RichText } from "../../../../common/components/RichText"

const schema = z.object({
  solutions_id: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
      }),
    )
    .min(1, { message: "Select at least 1 and maximum 3 solutions." })
    .max(3, { message: "Select at least 1 and maximum 3 solutions." }),
  name: z.string().min(1, { message: "Name is required." }),
  intended_user: z.string().min(1, { message: "Intended user is required." }),
  description: z.string().min(1, { message: "Description is required." }),
})

export type TAddProduct = z.infer<typeof schema>

const ModalAddClimateSolution = () => {
  const addEditCompanyProductModal = useCommunityModalsStore(
    (state) => state.addEditCompanyProductModal,
  )

  const { solutionData } = useFounderReference()

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useForm<TAddProduct>({
    resolver: zodResolver(schema),
    defaultValues: {
      solutions_id: addEditCompanyProductModal?.product?.solutions ?? [],
      name: addEditCompanyProductModal?.product?.name ?? "",
      intended_user: addEditCompanyProductModal?.product?.intended_user ?? "",
      description: addEditCompanyProductModal?.product?.description ?? "",
    },
  })

  const selectedSolutions = watch("solutions_id")
  const { fields, append, remove } = useFieldArray({
    control,
    name: "solutions_id" as const,
    keyName: "key",
  })

  React.useEffect(() => {
    if (addEditCompanyProductModal.isOpen) {
      reset({
        solutions_id: addEditCompanyProductModal?.product?.solutions ?? [],
        name: addEditCompanyProductModal?.product?.name ?? "",
        intended_user: addEditCompanyProductModal?.product?.intended_user ?? "",
        description: addEditCompanyProductModal?.product?.description ?? "",
      })
    }
  }, [addEditCompanyProductModal.isOpen, addEditCompanyProductModal?.product])

  const { startupQuery } = useFounderAndStartupData()
  const startupData = startupQuery?.data?.data?.data
  const queryClient = useQueryClient()

  const addStartupProductMutation = useMutation((data: TAddProduct) =>
    addStartupProduct(startupData?.id!, data),
  )

  const onSubmit = async (data: TAddProduct) => {
    message.loading({
      content: "Creating product...",
      key: "create-product",
    })
    await addStartupProductMutation.mutateAsync(data, {
      onSuccess: async () => {
        message.success({
          content: "Product created successfully",
          key: "create-product",
        })
        await queryClient.invalidateQueries(["startup-products"])
        addEditCompanyProductModal.close()
        reset()
      },
      onError: () => {
        showError({
          message: "Failed to create product",
          key: "create-product",
        })
      },
    })
  }
  return (
    <Modal
      centered
      open={addEditCompanyProductModal.isOpen}
      onCancel={() => addEditCompanyProductModal.close()}
      onOk={() => {}}
      footer={null}
    >
      <Form>
        <div className="mt-5">
          <div className="text-lg font-semibold leading-[38px]">
            Product Information
          </div>
          <div className="mt-5 ">
            <Form.Item
              className=""
              validateStatus={errors.name ? "error" : ""}
              help={errors.name?.message}
            >
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    status={errors?.name ? "error" : ""}
                    className=""
                    placeholder="Solution Name"
                  />
                )}
              />
            </Form.Item>
            <Form.Item
              className=""
              validateStatus={errors.solutions_id ? "error" : ""}
              help={errors.solutions_id?.message}
            >
              <Select
                className="w-full"
                placeholder="Solutions Category (3 max)"
                disabled={fields.length === 3 ? true : false}
                showSearch
                loading={solutionData?.listLoadingSolutions}
                status={errors?.solutions_id ? "error" : ""}
                options={solutionData?.listSolutions?.map((val) => ({
                  label: val.name,
                  value: val.id,
                }))}
                labelInValue={true}
                onSelect={(value, option) => {
                  if (!fields?.find((item) => item?.id === value?.key)) {
                    append({ name: option?.label, id: value?.key })
                  }
                }}
                filterOption={(input, option) => {
                  if (option && option.value) {
                    return (
                      option.value.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    )
                  }
                  return false
                }}
              />
              <div className="flex flex-wrap gap-2 items-center mt-2">
                {selectedSolutions?.map((item, index) => (
                  <div
                    className="bg-[#D8EAC6] px-4 py-1 rounded-lg"
                    key={index}
                  >
                    {item.name}{" "}
                    <CloseOutlined
                      onClick={() => {
                        remove(index)
                      }}
                    />
                  </div>
                ))}
              </div>
            </Form.Item>

            <Form.Item
              className=""
              validateStatus={errors.intended_user ? "error" : ""}
              help={errors.intended_user?.message}
            >
              <Controller
                name="intended_user"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    status={errors?.intended_user ? "error" : ""}
                    className=""
                    placeholder="Intended Users"
                  />
                )}
              />
            </Form.Item>
            <div className="flex flex-col gap-1">
              <div className=" !h-[250px]">
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <RichText
                      placeholder="Tell us about your product"
                      {...field}
                      className="!h-[70%]"
                      modules={{
                        toolbar: [
                          [
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                          ],
                          [{ list: "ordered" }, { list: "bullet" }],
                          ["link"],
                        ],
                      }}
                      formats={[
                        "header",
                        "font",
                        "size",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "blockquote",
                        "list",
                        "bullet",
                        "indent",
                      ]}
                    />
                  )}
                />
              </div>
              {errors?.description?.message && (
                <div className="text-sm text-danger">
                  {errors?.description?.message}
                </div>
              )}
            </div>
          </div>
        </div>

        <Button
          className="rounded-md mt-5"
          block
          onClick={handleSubmit(onSubmit)}
          type="primary"
          loading={false}
          disabled={!isDirty}
        >
          Save
        </Button>
      </Form>
    </Modal>
  )
}

export default ModalAddClimateSolution
