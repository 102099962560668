import { useMemo, useState } from "react"
import { useGetNotifsFromDb, useSetAllNotifsAsRead } from "../hooks"
import { Badge, Dropdown, Empty, Spin, Tabs } from "antd"
import {
  BellOutlined,
  BankOutlined,
  AimOutlined,
  CloseOutlined,
} from "@ant-design/icons"
import type { Notification } from "../hooks/useGetNotifsFromDb"
import { isNotNullOrZero } from "../../../utils/util_funcs"
import { Link } from "react-router-dom"

const categories = ["gamification", "job-galaxy", "community"]

const renderNotification = (notif: Notification) => {
  const notification = JSON.parse(notif?.payload ?? "{}")

  return (
    <div className="flex hover:bg-gray-300/50 px-5 py-1 gap-9 items-center cursor-pointer ">
      <Link
        to={notification?.redirect || "#"}
        className="flex flex-col flex-grow"
      >
        <div className="flex flex-col flex-grow">
          <div className="text-xs text-gray-400">
            {new Date(notif.created_at).toLocaleString()}
          </div>
          <div className="text-sm">{notification?.title}</div>
          <div className="text-xs">{notification?.description}</div>
        </div>
      </Link>
      <div className="flex flex-col items-end">
        {notif?.status === "SENT" && (
          <div className="flex gap-3 items-center">
            <Badge
              className=""
              count="new"
              style={{ backgroundColor: "#52c41a" }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export function NotificationBellDropdown() {
  const notifications = useGetNotifsFromDb()
  const setNotifAsRead = useSetAllNotifsAsRead()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const newNotifs = useMemo(
    () =>
      notifications?.data?.data?.data?.list
        ?.filter((notif: Notification) => notif?.status === "SENT")
        .map((notif: Notification) => notif?.id),
    [notifications],
  )

  const getNotificationsItems = useMemo(() => {
    if (notifications?.isLoading) {
      return categories.map((cat) => ({
        key: cat,
        label: cat,
        children: <Spin className="mt-2" size="large" />,
      }))
    }

    if (!notifications?.data?.data?.data)
      return categories.map((cat) => ({
        key: cat,
        label: cat,
        children: (
          <Empty
            className="mt-2"
            description={`No ${cat} notifications yet.`}
          />
        ),
      }))

    return categories.map((category, index) => {
      const filteredNotifications = notifications?.data?.data?.data?.list
        ?.filter((notif) => notif?.category === category)
        ?.map((notif: Notification) => ({
          key: notif?.id,
          label: category,
          children: renderNotification(notif),
          ...notif,
        }))

      return {
        key: `${index + 1}`,
        icon:
          category === "gamification" ? (
            <AimOutlined className="" />
          ) : (
            <BankOutlined className="" />
          ),
        label: (
          <span className="capitalize">
            {category?.replace("-", " ")}{" "}
            <Badge
              count={
                filteredNotifications?.filter(
                  (notif: Notification) => notif?.status === "SENT",
                )?.length ?? 0
              }
              overflowCount={10}
              size="small"
              offset={[3, 0]}
              className=""
            />
          </span>
        ),
        children: isNotNullOrZero(filteredNotifications?.length) ? (
          <div className="space-y-2 mt-2 overflow-y-auto !h-[400px] scrollbar-hide">
            {filteredNotifications?.map((item) => item.children)}
          </div>
        ) : (
          <Empty
            className="my-36 capitalize"
            description={`No ${category?.replace("-", " ")} notifications yet.`}
          />
        ),
      }
    })
  }, [notifications])

  return (
    <div id="area">
      <Dropdown
        open={isDropdownOpen}
        trigger={["click"]}
        rootClassName=""
        className=""
        arrow={{
          pointAtCenter: true,
        }}
        onOpenChange={(open) => {
          if (!open && newNotifs && newNotifs?.length > 0) {
            setNotifAsRead.mutate(newNotifs)
          }
          setIsDropdownOpen(open)
        }}
        placement="bottomRight"
        dropdownRender={() => {
          return (
            <div
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
                boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
                paddingTop: "10px",
                paddingBottom: "10px",
                width: "350px",
                // height: 500,
              }}
              className=""
            >
              <div className="flex justify-between items-center w-full">
                <div className="font-normal  px-[10px] ">Notifications</div>
                <CloseOutlined
                  className="cursor-pointer  px-[10px] "
                  onClick={() => setIsDropdownOpen(false)}
                />
              </div>
              <Tabs
                defaultActiveKey="1"
                className="mt-2  px-[10px]"
                items={getNotificationsItems}
              />
            </div>
          )
        }}
      >
        <Badge
          count={newNotifs?.length ?? 0}
          overflowCount={10}
          size="small"
          offset={[3, 0]}
          className=" "
        >
          <BellOutlined
            className="text-xl pb-0.5 cursor-pointer"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          />
        </Badge>
      </Dropdown>
    </div>
  )
}
