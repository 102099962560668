import React from "react"
import { HomeOutlined } from "@ant-design/icons"
import { PageContent, PageHeader } from "../../../../../common/layout"
import MentorProfileContent from "../components/MentorProfileContent"
import { useMentorReference } from "../hooks/useMentorReference"
import { useDetailMentor } from "../hooks/useMentor"
import { useParams } from "react-router-dom"
import { Button } from "antd"
import ModalSetAvailabilty from "../components/ModalSetAvailabilty"
import {
  storeModalSetAvailability,
  useAvailabilityStore,
} from "../stores/storeModalSetAvailability"
import { useListMentorAvailablity } from "../hooks/useMentorAvailability"

interface MyProfileMentorProps {}

const MyProfileMentor: React.FC<MyProfileMentorProps> = () => {
  const { id } = useParams()
  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "Mentor",
    },
    {
      title: "My Mentor Profile",
    },
  ]

  const { detail } = useDetailMentor(id!)

  const { areaData, domainData } = useMentorReference()

  const { openModal } = storeModalSetAvailability()
  const { isMentorHasAvailability } = useAvailabilityStore()
  const { handleCreateInitialAvailabilityDesc } = useListMentorAvailablity()

  return (
    <div>
      <PageContent
        header={
          <PageHeader
            title={"Mentor Profile"}
            breadcrumbItems={items}
            showBack={true}
            needButton={true}
            buttonComponent={
              <div>
                <Button
                  onClick={() => {
                    openModal()
                    if (!isMentorHasAvailability) {
                      handleCreateInitialAvailabilityDesc()
                    }
                  }}
                  type="primary"
                >
                  Setup Availability
                </Button>
              </div>
            }
          />
        }
        content={
          <MentorProfileContent
            detail={detail?.detailMentor}
            loadingMentor={detail?.detailLoadingMentor}
            areaData={areaData?.listAreas}
            loadingArea={areaData?.listLoadingAreas}
            domainData={domainData?.listDomain}
            loadingDomain={domainData?.listLoadingDomain}
            handler={detail}
            isMyProfile={true}
          />
        }
      />
      <ModalSetAvailabilty />
    </div>
  )
}

export default MyProfileMentor
