import React from "react"
import { Rate } from "antd"

interface FiveCircleRateProps {
  circleSize?: number
  defaultValue?: number
  disabled?: boolean
  count?: number
  color?: string
  strokeColor?: string
  secondValue?: boolean
}

const FiveCircleRateTS: React.FC<FiveCircleRateProps> = ({
  circleSize = 20,
  defaultValue = 0,
  disabled,
  count,
  strokeColor,
  secondValue,
}) => {
  const renderCharacter = (input: any, size: number) => {
    let fill
    if (defaultValue > input.index) {
      fill = "#D4F5C5" // Circle pertama
    } else if (secondValue && input.index === defaultValue) {
      fill = "#FFEFA0" // Circle setelah defaultValue jika secondValue bernilai true
    } else {
      fill = "#fff" // Circle lainnya
    }

    const stroke = strokeColor ? strokeColor : "#36505E"
    const strokeWidth = 1

    return (
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={size / 2 - strokeWidth}
          fill={fill}
          stroke={stroke}
          strokeWidth={strokeWidth}
        />
      </svg>
    )
  }

  return (
    <Rate
      className="!inline-flex"
      character={(obj) => renderCharacter(obj, circleSize)}
      disabled={disabled}
      defaultValue={defaultValue}
      count={count === 0 ? 5 : count ? count : 5}
    />
  )
}

export default FiveCircleRateTS
