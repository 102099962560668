import React from "react"
import { PoweroffOutlined, LinkOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { Account } from "../../hooks/cv-builder/useProfile"
import { message } from "antd"

interface Props {
  data: Account | undefined
  // active: { key: string; label: string };
  // handleSelectMenu: (item: { key: string; label: string }) => void;
  // menu: { key: string; label: string }[];
}

export const Header: React.FC<Props> = ({ data }) => {
  const navigate = useNavigate()
  return (
    <div>
      <div className=" bg-[#E2EBFA] h-16 fixed top-0 left-0 w-full flex justify-between items-center px-5 shadow-[0_1px_7px_0_rgba(0,0,0,0.3)]">
        <h1 className=" font-bold">My Green CV</h1>
        <div className="flex md:gap-2 lg:gap-8">
          <div
            className="rounded-2xl bg-secondary px-3 py-1.5 flex gap-2 cursor-pointer text-white hover:bg-[#0290bf]"
            onClick={() => {
              message.info("link copied!")
              navigator.clipboard.writeText(
                `${process.env.REACT_APP_URL}/talent-marketplace/cv-full/${data?.id}`,
              )
            }}
          >
            <LinkOutlined />
            <h5>Share Full CV</h5>
          </div>
          <div
            className="rounded-2xl bg-secondary px-3 py-1.5 flex gap-2 cursor-pointer text-white hover:bg-[#0290bf]"
            onClick={() => {
              message.info("link copied!")
              navigator.clipboard.writeText(
                `${process.env.REACT_APP_URL}/talent-marketplace/cv-anonymised/${data?.nick_name}`,
              )
            }}
          >
            <LinkOutlined />
            <h5>Share Anonymised CV</h5>
          </div>
        </div>
        <div
          className="flex gap-3 items-center text-white bg-backdrop py-2 px-4 rounded-sm cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <PoweroffOutlined />
          <div>Exit CV View</div>
        </div>
      </div>
    </div>
  )
}
