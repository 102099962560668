import {
  CarOutlined,
  ClockCircleOutlined,
  DollarOutlined,
  EnvironmentOutlined,
  FileDoneOutlined,
  HeartFilled,
  HeartOutlined,
} from "@ant-design/icons"
import { Tag } from "antd"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import React from "react"
import { useNavigate } from "react-router-dom"

interface CardOfJobCompanyProps {
  item: any
  index: number
  handleSaveJob: (job: any) => void
  handleUnSaveJob: (jobId: number) => void
}

const CardOfJobCompany: React.FC<CardOfJobCompanyProps> = ({
  item,
  index,
  handleSaveJob,
  handleUnSaveJob,
}) => {
  const navigate = useNavigate()
  dayjs.extend(relativeTime)

  return (
    <div
      className="bg-white-custom shadow-custom rounded-xl mb-4 py-4 px-8 cursor-pointer transition-all"
      key={index}
      onClick={(e) => {
        e.stopPropagation()
        navigate(`/job/board/${item?.id}`)
      }}
    >
      <div className="flex justify-between flex-wrap md:flex-nowrap gap-y-5">
        <div className="flex items-center gap-5 ">
          <div className="space-y-1">
            <p className="m-0 p-0 font-bold text-base capitalize">
              {item?.name}
            </p>
            <div className="flex items-center gap-x-7 gap-y-2 flex-wrap md:flex-nowrap">
              <p className="m-0 p-0 text-secondary capitalize">
                {item?.company?.name}
              </p>
            </div>
            <p className="m-0 p-0">{item?.team}</p>
          </div>
        </div>
        <div className="p-0 m-0 flex gap-7 items-start flex-wrap sm:flex-nowrap">
          <Tag
            bordered={false}
            onClick={(e) => {
              if (item?.is_saved) {
                handleUnSaveJob(item?.id)
              } else {
                handleSaveJob(item)
              }
              e.stopPropagation()
            }}
            color={item?.is_saved ? undefined : "volcano"}
            className={`${
              item?.is_saved ? "bg-primary-bg" : "text-backdrop"
            } !border-gray-300 rounded max-w-full overflow-clip group hover:scale-105 duration-200 transition-all cursor-pointer`}
            icon={
              item?.is_saved ? (
                <HeartFilled key={"favorite"} className="!text-primary" />
              ) : (
                <HeartOutlined className="hover:text-primary" key="favorite" />
              )
            }
          >
            {item?.is_saved ? "Saved" : "Save"}
          </Tag>
        </div>
      </div>

      <div className="text-xs text-[#919191] flex items-center justify-between mt-1 -mb-2 flex-wrap md:flex-nowrap">
        <div className="flex items-center gap-x-6 flex-wrap md:flex-nowrap">
          <div className="flex items-center gap-2">
            <EnvironmentOutlined />
            <p className="whitespace-nowrap">{item?.location}</p>
          </div>
          <div className="flex items-center gap-2">
            <DollarOutlined />
            <p className="whitespace-nowrap">
              {`${item?.salary_currency} ${item?.salary_from} - ${item?.salary_up_to}`}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <FileDoneOutlined />
            <p className="whitespace-nowrap">{item?.job_type}</p>
          </div>
          <div className="flex items-center gap-2">
            <CarOutlined />
            <p className="whitespace-nowrap">
              {item?.remote ? "remote" : "on-site"}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <ClockCircleOutlined />
          <p>{dayjs(item?.published_at).fromNow()}</p>
        </div>
      </div>
    </div>
  )
}

export default CardOfJobCompany
