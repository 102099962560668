import {
  LoadingOutlined,
  EditOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons"
import { Button } from "antd"
import { GreenSkillsLogoLoggedIn, Moon } from "../../../../assets/images"
import useCommunityModalsStore from "../../store/useCommunityModalsStore"
import { GlobalOutlined } from "@ant-design/icons"
import FounderItem from "../../public-expert/components/FounderItem"
import { isNullOrUndefinedOrEmptyString } from "../../../../utils/util_funcs"
import useFounderAndStartupData from "../hooks/useFounderAndStartup"
import clsx from "clsx"

const MyCompany = () => {
  const editCompanyInfoModal = useCommunityModalsStore(
    (state) => state.editCompanyInfoModal,
  )
  const addCompanyModal = useCommunityModalsStore(
    (state) => state.addCompanyModal,
  )

  const { founderQuery, startupQuery } = useFounderAndStartupData()

  const founderData = founderQuery?.data?.data?.data
  const startupData = startupQuery?.data?.data?.data

  if (founderQuery?.isLoading || startupQuery?.isLoading) {
    return (
      <div className="h-[250px] flex items-center justify-center">
        <LoadingOutlined className="text-xl" />
      </div>
    )
  }

  const doesStartupExist = !isNullOrUndefinedOrEmptyString(
    founderData?.startup_id,
  )

  return (
    <div>
      {doesStartupExist ? (
        <>
          <div className="flex items-center justify-between mt-4">
            <h4 className="!font-semibold !leading-[38px] text-xl">
              My Company
            </h4>
            <EditOutlined
              onClick={() => editCompanyInfoModal.open()}
              className="text-base"
            />
          </div>
          <div className="flex items-start gap-8">
            <img
              className="w-[125px] object-contain"
              src={GreenSkillsLogoLoggedIn}
            />
            <div className="flex flex-col flex-grow">
              <div className="flex gap-3 mt-4 items-center justify-between">
                <h4 className="font-bold text-lg">{startupData?.name}</h4>
                <div className="flex bg-[#FAFAFA] p-4 items-center justify-center rounded-xl gap-5">
                  <div className="uppercase text-brown ">
                    {startupData?.stage?.name}
                  </div>
                  <div className="flex items-center gap-2">
                    <EnvironmentOutlined />
                    {startupData?.location?.name}
                  </div>
                  <a
                    href={startupData?.website}
                    className="px-2 cursor-pointer flex gap-[10px] items-center rounded-sm ring-1 ring-backdrop bg-[#FFF2E8]"
                  >
                    <GlobalOutlined />
                    Website
                  </a>
                </div>
              </div>
              <div className="mt-4">{startupData?.short_description}</div>
            </div>
          </div>
          <div className="">
            <div className=" mt-5 flex flex-col text-center bg-white rounded-xl px-7 ring-1 ring-dirtyBlue py-5">
              <div className="text-lg font-semibold">Founding Team</div>
              <div
                className={clsx("grid gap-4 mt-4", {
                  "grid-cols-1": startupData?.founders?.length === 1,
                  "grid-cols-2":
                    startupData?.founders?.length &&
                    startupData?.founders?.length > 1,
                })}
              >
                {startupData?.founders?.map((item, index) => (
                  <FounderItem key={index} founder={item} />
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex items-center justify-between mt-4">
            <h4 className="!font-semibold !leading-[38px] text-xl">
              My Company
            </h4>
            <Button onClick={() => addCompanyModal.open()} type="primary">
              add information
            </Button>
          </div>
          <div className="flex flex-col items-center justify-center gap-5 text-center p-7">
            <img
              src={Moon}
              className="h-[116px] object-contain aspect-square"
              alt="empty"
            />
            <div className="text-base leading-6">
              Start provide an overview of{" "}
              <span className="font-semibold text-primary cursor-pointer">
                your company
              </span>
              , including your mission, vision, and the founder's information
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default MyCompany
