import React from "react"
import { HomeOutlined } from "@ant-design/icons"
import { PageContent, PageHeader } from "../../../../common/layout"
import ListSolutionContent from "../components/ListSolutionContent"
import { useListSolution } from "../hooks/useSolution"

interface ListSolutionProps {}

const ListSolution: React.FC<ListSolutionProps> = () => {
  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      path: "/community/member",
      title: "Community",
    },
    {
      title: "Climate Solutions",
    },
  ]

  const { startupData, queryStartup, referenceData } = useListSolution()

  return (
    <PageContent
      header={
        <PageHeader breadcrumbItems={items} title={"Climate Solutions"} />
      }
      content={
        <ListSolutionContent
          startupData={startupData}
          queryStartup={queryStartup}
          referenceData={referenceData}
        />
      }
    />
  )
}

export default ListSolution
