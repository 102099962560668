import React from "react"
import { BasicContainerWithTitle } from "../../../../common/components"
import { Role, useRoleStore } from "../stores/useRoleStore"
import clsx from "clsx"
import { PlusCircleOutlined } from "@ant-design/icons"
import useProfilesStatus from "../hooks/useProfilesStatus"

const RoleNavigator = () => {
  const { role, setRole } = useRoleStore()

  const is_profile = useProfilesStatus()

  return (
    <BasicContainerWithTitle
      title={"Role Navigator"}
      className="!bg-secondaryBlue"
    >
      <div className="mt-5 flex lg:flex-nowrap flex-wrap items-center justify-between gap-2">
        {Object.keys(Role).map((r) => (
          <div
            key={r}
            onClick={() => setRole(r as Role)}
            className={clsx(
              "rounded-full cursor-pointer text-xs hover:scale-105 transition-all duration-300 px-2 py-1 ring-1 ring-darkGrey flex items-center gap-1",
              r === role
                ? "bg-backdrop text-white"
                : is_profile[r as Role]?.status === "APPROVED"
                  ? "bg-white"
                  : "text-darkGrey",
            )}
          >
            {!is_profile[r as Role]?.hasRequest && (
              <PlusCircleOutlined
                className={clsx(r !== role ? "text-darkGrey" : "text-white")}
              />
            )}
            {r}
          </div>
        ))}
      </div>
    </BasicContainerWithTitle>
  )
}

export default RoleNavigator
