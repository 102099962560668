import { Avatar, Button } from "antd"
import React from "react"
import { useNavigate } from "react-router-dom"
import { AvatarCVHome } from "../../../assets/images"
import { openBlankTab } from "../../../utils/openBalnkTabs"
import { CheckCircleOutlined } from "@ant-design/icons"

interface CVSectionProps {}

const CVSection: React.FC<CVSectionProps> = () => {
  const navigate = useNavigate()

  const cvListDesc = [
    {
      id: 1,
      desc: "easily share online",
      icon: <CheckCircleOutlined />,
    },
    {
      id: 2,
      desc: "anonymised (we protect your data)",
      icon: <CheckCircleOutlined />,
    },
    {
      id: 3,
      desc: "green skills auto-updated based on your learning",
      icon: <CheckCircleOutlined />,
    },
    {
      id: 4,
      desc: "showcase skills you want to learn next ",
      icon: <CheckCircleOutlined />,
    },
    {
      id: 5,
      desc: "quick apply for jobs posted on Skilledin Green (coming soon)",
      icon: <CheckCircleOutlined />,
    },
  ]
  return (
    <div className="bg-secondaryBlue text-backdrop">
      <div className="m-auto w-[85%] py-10">
        <h2 className="font-semibold text-[40px] text-center mb-12">
          Build online Green CV
        </h2>
        <div className="grid grid-cols-12">
          <div className="space-y-3 col-span-12 md:col-span-5 mb-8 md:mb-0">
            <div className="bg-white shadow-custom-sm rounded-lg py-1 px-5 w-full md:w-10/12">
              {/* <p className="text-primary-bg">GREEN CV</p> */}
              <div className="flex items-center gap-4 mb-6">
                <div>
                  <Avatar src={AvatarCVHome} size={96} />
                </div>
                <div className="leading-6 mt-3">
                  <p className="text-backdrop p-0 m-0 font-semibold text-lg">
                    Kate Gonzales
                  </p>
                  <p className="text-backdrop p-0 m-0">4 Career Aspirations</p>
                  <p className="text-backdrop p-0 m-0">
                    2 Green Skills I am proficient in
                  </p>
                  <p className="text-backdrop p-0 m-0">
                    4 Green Skills I am interested in
                  </p>
                </div>
              </div>
              <Button
                onClick={() =>
                  openBlankTab("/talent-marketplace/cv-anonymised/greenleaf")
                }
                block
                className="rounded-sm mb-3 bg-backdrop text-white"
                // type="secondary"
              >
                View full CV
              </Button>
            </div>
          </div>

          <div className="flex flex-wrap items-center col-span-12 md:col-span-7  h-auto">
            <div className="">
              {cvListDesc?.map((desc, i) => (
                <div className="flex items-center gap-3 leading-7" key={i}>
                  {desc?.icon}
                  <p className="text-backdrop m-0 p-0">{desc?.desc}</p>
                </div>
              ))}

              <Button
                onClick={() => navigate("/register")}
                type="primary"
                className="mt-5"
              >
                Build your Green CV
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CVSection
