import React, { useEffect, useState } from "react"

interface TimerProps {
  handleSubmit: () => Promise<void>
  handleNext: () => Promise<void>
  initialTimeInSeconds: number
  isLastQuestion: boolean
}

export const Timer: React.FC<TimerProps> = ({
  handleNext,
  handleSubmit,
  initialTimeInSeconds,
  isLastQuestion,
}) => {
  const [time, setTime] = useState(() => {
    return {
      minutes: Math.floor(initialTimeInSeconds / 60),
      seconds: initialTimeInSeconds % 60,
    }
  })

  const formattedTime = `${time?.minutes
    .toString()
    .padStart(2, "0")}:${time?.seconds.toString().padStart(2, "0")}`

  useEffect(() => {
    // Calculate initial time based on the new initialTimeInSeconds
    const newTime = {
      minutes: Math.floor(initialTimeInSeconds / 60),
      seconds: initialTimeInSeconds % 60,
    }

    // Reset the timer with the new initial time
    setTime(newTime)
  }, [initialTimeInSeconds])

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (time.minutes === 0 && time.seconds === 0) {
        clearInterval(countdownInterval)
        // Cek apakah ini pertanyaan terakhir
        if (isLastQuestion) {
          handleSubmit() // Jika ya, jalankan handleSubmit
        } else {
          handleNext() // Jika tidak, jalankan handleNext
        }
      } else {
        if (time.seconds > 0) {
          setTime((prevTime) => ({
            ...prevTime,
            seconds: prevTime.seconds - 1,
          }))
        } else {
          setTime((prevTime) => ({
            minutes: prevTime.minutes - 1,
            seconds: 59,
          }))
        }
      }
    }, 1000)

    return () => clearInterval(countdownInterval)
  }, [time, handleSubmit, isLastQuestion, handleNext])

  return (
    <div className="">
      <div className="w-40 bg-white py-2 px-2">{formattedTime} remaining</div>
    </div>
  )
}
