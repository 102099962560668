import React from "react"
import { HomeOutlined, LoadingOutlined } from "@ant-design/icons"
import { Spin } from "antd"
import { Breadcrumbs } from "../../../../common/components"
import { Moon } from "../../../../assets/images"
import { FilterJobBoard } from "../../components/jobRoles/FilterJobBoard"
import { CardJobBoard } from "../../components/jobRoles/CardJobBoard"
import { useJobRoleList } from "../../hooks/jobRoles/useJobRoleList"
import { useNavigate } from "react-router-dom"

export const ListJobRoles = () => {
  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "Jobs Galaxy",
    },
    {
      title: "Job Roles",
    },
  ]
  const {
    dataList,
    lastCardElementRef,
    isLoading,
    handleSearch,
    handleAddSector,
    params,
    handleRemoveSector,
  } = useJobRoleList()

  const navigate = useNavigate()

  return (
    <div>
      <div className="bg-white py-4 px-6">
        <Breadcrumbs items={items} />

        <div className="my-3">
          <h1 className="font-bold">Green Job Profiles</h1>
        </div>
      </div>

      <div className="px-6">
        <div className="my-5">
          <FilterJobBoard
            handleSearch={handleSearch}
            handleAddSector={handleAddSector}
            selectedSector={params?.sector}
            handleRemoveSector={handleRemoveSector}
          />
        </div>
        {dataList.length === 0 && isLoading ? (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          </div>
        ) : dataList.length > 0 ? (
          <Spin
            spinning={isLoading}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
          >
            <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-5 auto-rows-max mb-5">
              {dataList.map((data, i) => (
                <CardJobBoard
                  data={data}
                  key={i}
                  onClick={() =>
                    navigate(`/job/roles/${data?.slug}`, {
                      state: { id: data?.id },
                    })
                  }
                  observ={
                    dataList.length - 1 === i ? lastCardElementRef : undefined
                  }
                />
              ))}
            </div>
          </Spin>
        ) : (
          <Spin
            spinning={isLoading}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
          >
            <div className="flex gap-3 items-center bg-white p-3 rounded-xl shadow-md">
              <img src={Moon} alt="cookies" />
              <div>{"Nothing to display"}</div>
            </div>
          </Spin>
        )}
      </div>
    </div>
  )
}
