import React from "react"
import { Route, Routes } from "react-router-dom"

// const RewardsAndPerks = React.lazy(() => import("./pages/RewardsAndPerks"))
import RewardsAndPerks from "./pages/RewardsAndPerks.tsx"
const Index = () => {
  return (
    <Routes>
      <Route path="/" element={<RewardsAndPerks />} />
    </Routes>
  )
}

export default Index
