import { message } from "antd"
import { useCallback, useEffect, useRef, useState } from "react"
import { useQueryClient } from "react-query"
import {
  useFetchDetailAPIV2,
  useFetchListAPIV2,
} from "../../../../hooks/generic_rest"
import { debounce } from "../../../../utils/debounce"
import {
  Account,
  useProfile,
} from "../../../missionControl/hooks/cv-builder/useProfile"
import {
  useFollowExpert,
  useUnFollowExpert,
} from "../../../skillGalaxy/apis/rest"
import { useFounderReference } from "../../founder/hooks/useFounderReference"
import { UserProfileResponse } from "../../founder/types/response"
import { MEMBER_ENDPOINTS } from "../apis/endpoint"

export const LIST_MEMBER_QUERY_NAME: string = "list-members"
export const useListMember = () => {
  interface QueryParams {
    page: number
    limit: number
    search: string
    location: string
    profile_type: string
    user_id: string | undefined
  }
  const { account } = useProfile()
  const [total, setTotal] = useState<number>(0)
  const [dataList, setDataList] = useState<UserProfileResponse[]>([])
  const [queryParams, setQueryParams] = useState<QueryParams>({
    page: 1,
    limit: 10,
    search: "",
    location: "",
    profile_type: "",
    user_id: "",
  })

  useEffect(() => {
    setQueryParams({
      ...queryParams,
      user_id: account && account?.id,
    })
  }, [account])

  const invalidateQueries = useQueryClient()

  const {
    data: listData,
    isLoading: listLoading,
    isError: listError,
    refetch: refetchListData,
  } = useFetchListAPIV2<UserProfileResponse>(`${MEMBER_ENDPOINTS.LIST}`, {
    queryParams,
    queryName: LIST_MEMBER_QUERY_NAME,
    enabled: false,
  })

  const handleSearch = debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setQueryParams({
        ...queryParams,
        search: event.target.value!,
        page: 1,
      })
    },
    500,
  )

  const handleFilterByLocation = (opt: any) => {
    const currentLocationIds = queryParams.location
      ? queryParams.location.split(",")
      : []

    if (!currentLocationIds.includes(opt.label)) {
      const newLocationIds = [...currentLocationIds, opt.label]
      const newLocationIdString = newLocationIds.join(",")
      setQueryParams({
        ...queryParams,
        location: newLocationIdString.toLocaleLowerCase(),
        page: 1,
      })
    }
  }

  const handleRemoveLocation = (value: string) => {
    const currentLocationIds = queryParams.location.split(",")
    const updatedLocationIds = currentLocationIds.filter(
      (id: string) => id !== value,
    )
    const newLocationIdString = updatedLocationIds.join(",")
    setQueryParams({
      ...queryParams,
      location: newLocationIdString,
      page: 1,
    })
  }
  const handleFilterByProfileType = (opt: any) => {
    const currentTypeIds = queryParams.profile_type
      ? queryParams.profile_type.split(",")
      : []

    if (!currentTypeIds.includes(opt.value)) {
      const newTypeIds = [...currentTypeIds, opt.value]
      const newTypeIdString = newTypeIds.join(",")
      setQueryParams({
        ...queryParams,
        profile_type: newTypeIdString,
        page: 1,
      })
    }
  }

  const handleRemoveType = (value: string) => {
    const currentTypeIds = queryParams.profile_type.split(",")
    const updatedTypeIds = currentTypeIds.filter((id: string) => id !== value)
    const newTypeIdString = updatedTypeIds.join(",")
    setQueryParams({
      ...queryParams,
      profile_type: newTypeIdString,
      page: 1,
    })
  }

  useEffect(() => {
    refetchListData()
  }, [queryParams])

  // REFERENCE DATA
  const { locationData } = useFounderReference()

  const restFollowExpert = useFollowExpert()
  const restUnfollowExpert = useUnFollowExpert()

  const handleFollowExpert = async (expertID: string) => {
    try {
      await restFollowExpert.mutateAsync({
        expertID: expertID,
      })
      invalidateQueries.invalidateQueries(LIST_MEMBER_QUERY_NAME)
    } catch (error: any) {
      message.error(
        "An error occurred while save your article: " +
          error.response?.data?.data,
      )
    }
  }
  const handleUnFollowExpert = async (expertID: string) => {
    try {
      await restUnfollowExpert.mutateAsync({
        expertID: expertID,
      })
      invalidateQueries.invalidateQueries(LIST_MEMBER_QUERY_NAME)
    } catch (error: any) {
      message.error(
        "An error occurred while save your article: " +
          error.response?.data?.data,
      )
    }
  }

  // infinity scroll
  const observer = useRef<IntersectionObserver>()
  const lastElementRef = useCallback(
    (node: any) => {
      if (listLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && total !== dataList.length) {
          setQueryParams((prev) => ({
            ...prev,
            page: prev.page + 1,
          }))
        }
      })
      if (node) observer.current.observe(node)
    },
    [listLoading, total, dataList.length],
  )

  useEffect(() => {
    if (!listLoading) {
      if (queryParams.page === 1) {
        setDataList(listData?.list || [])
        setTotal(listData?.total_data || 0)
      } else if (
        dataList?.[dataList?.length - 1]?.id !==
        listData?.list?.[listData?.list?.length - 1]?.id
      ) {
        setDataList((prev) => [...prev, ...(listData?.list || [])])
        setTotal(listData?.total_data || 0)
      }
    }
  }, [listData?.list])

  return {
    memberData: {
      listData: dataList,
      listLoading,
      listError,
    },
    queryMember: {
      handleSearch,
      handleFilterByLocation,
      handleRemoveLocation,
      handleFilterByProfileType,
      handleRemoveType,
      handleFollowExpert,
      handleUnFollowExpert,
      locationSelected: queryParams?.location
        ?.split(",")
        ?.filter((id: string) => id !== ""),
      typeSelected: queryParams?.profile_type
        ?.split(",")
        ?.filter((id: string) => id !== ""),
      lastElementRef,
    },
    referenceData: {
      locationData,
    },
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useDetailMember = (id: string, username: string) => {
  const {
    data: detailData,
    isLoading: detailLoading,
    isError: detailError,
  } = useFetchDetailAPIV2<Account>(
    `${MEMBER_ENDPOINTS.DETAIL(id)}`,
    {},
    "common-user-data-detail",
  )

  // CV
  // const {
  //   data: account,
  //   isLoading: loadingAccount,
  //   isError,
  // } = useQuery(
  //   "common-account-cv",
  //   () =>
  //     instanceV2.get<AxiosResponse<Account>>(`/accounts/cv/id/${id}`, {
  //       params: {
  //         anonymised: 0,
  //       },
  //     }),
  //   {
  //     retry: false,
  //     enabled: !!username || !!id,
  //   },
  // )

  // console.log('account?.data.data', account?.data.data)

  return {
    memberData: {
      detailData,
      detailLoading,
      detailError,
    },
    // cv: {
    //   account: account?.data.data,
    //   loadingAccount,
    //   isError,
    // },
  }
}

export const useMemberReference = () => {
  const {
    data: locationData,
    isLoading: locationLoading,
    isError: locationError,
  } = useFetchDetailAPIV2<string[]>(
    `${MEMBER_ENDPOINTS.REF_LIST_LOCATION_BASED_DATA}`,
    {},
    "common-location-reference",
  )

  return {
    locationData: {
      locationData,
      locationLoading,
      locationError,
    },
  }
}
