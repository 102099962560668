import { useContext } from "react"
import { AccountContext } from "../../../../common/layout/PageLayout"
import { Role } from "../stores/useRoleStore"
import { useQuery } from "react-query"
import { profilesStatus } from "../apis/profile-status"

const useProfilesStatus = () => {
  const { data: accountData } = useContext(AccountContext) || {}
  const { data: profileData } =
    useQuery(["profile-status"], profilesStatus, {
      enabled: !!accountData,
    }) || {}

  const getStatus = (role: "expert" | "founder" | "mentor") => ({
    hasRequest: profileData?.data?.data?.[role]?.requested || false,
    status: profileData?.data?.data?.[role]?.status || "REJECTED",
  })

  return {
    [Role.Professional]: { hasRequest: true, status: "APPROVED" },
    [Role.Expert]: getStatus("expert"),
    [Role.Founder]: getStatus("founder"),
    [Role.Mentor]: getStatus("mentor"),
  }
}

export default useProfilesStatus
