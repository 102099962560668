import React from "react"
import { Progress, Switch } from "antd"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { usePrivacySetting } from "../../../account/hooks/usePrivacySetting"
import { Account } from "../../hooks/cv-builder/useProfile"

interface Props {
  active: { key: string; label: string }
  activeSubMenu: {
    key: string
    label: string
    icon: JSX.Element
  }
  subMenu: {
    key: string
    label: string
    icon: JSX.Element
  }[]
  handleSelectSubMenu: (val: {
    key: string
    label: string
    icon: JSX.Element
  }) => void
  data: Account | undefined
}

export const Sidebar: React.FC<Props> = ({
  active,
  subMenu,
  activeSubMenu,
  handleSelectSubMenu,
  data,
}) => {
  const { statusPrivate, changeStatus } = usePrivacySetting()
  return (
    <div className=" flex flex-col w-full px-5 md:w-72 md:fixed  md:pr-0 md:top-[5rem] md:pl-5">
      {active.key === "skills" && (
        <div className="p-5 bg-[#E2EBFA] rounded-xl mb-5">
          <div className=" capitalize font-semibold">skills</div>
          <div>
            {subMenu.map((item, i) => (
              <div
                onClick={() => handleSelectSubMenu(item)}
                key={i}
                className={`flex items-center gap-3 my-2 px-2 py-1.5 ${
                  item.key === activeSubMenu.key
                    ? " bg-secondaryYellow rounded-lg"
                    : ""
                }`}
              >
                {item.icon}
                <div className=" capitalize cursor-pointer">{item.label}</div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="p-5 bg-[#E2EBFA] rounded-xl mb-5">
        <h3 className=" capitalize mb-2">profile strength</h3>
        <Progress
          percent={data?.profile_strenght || 0}
          showInfo={false}
          strokeColor={{ from: "#108ee9", to: "#87d068" }}
        />
      </div>
      <div className="p-5 bg-[#E2EBFA] rounded-lg">
        <h3 className=" capitalize  mb-2">CV Visibility</h3>
        <div className="mt-1 flex items-center gap-2">
          <Switch
            size="small"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            defaultChecked={statusPrivate?.discover}
            onChange={() => changeStatus("discover", !statusPrivate?.discover)}
            checked={statusPrivate?.discover}
          />
          <span className="">{`${
            statusPrivate.discover ? "Discoverable" : "Undiscoverable"
          } by recruiters`}</span>
        </div>
        <div className="mt-3">
          Your identifiable information will be hidden until you approve
          recruiter’s requests.
        </div>
      </div>
    </div>
  )
}
