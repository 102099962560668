/* eslint-disable no-unused-vars */
import React from "react"
import { RichTextMinimize } from "../../../../../common/components/RichTextMinimize"
import { Button, Select } from "antd"
import { SkillVerification } from "../../../hooks/cv-builder/useSkillVerification"
import { useSkillVerificationUpdate } from "../../../hooks/cv-builder/useSkillVerificationUpdate"
import { Controller } from "react-hook-form"
import { FilePdfOutlined } from "@ant-design/icons"
import { useSkillDropdown } from "../../../../skillGalaxy/hooks/useSkillDropdown"
import { GSProficient } from "../../../hooks/cv-builder/common_type"

interface Props {
  data: SkillVerification
  index: number
  actionUpdate: (index: number, data: SkillVerification) => void
  dataAll: SkillVerification[]
  gsProficient: GSProficient[] | undefined
}

export const CardVerifiedUpdate: React.FC<Props> = ({
  data,
  index,
  actionUpdate,
  dataAll,
  gsProficient,
}) => {
  const { form, tempFile, actions } = useSkillVerificationUpdate(
    data,
    index,
    actionUpdate,
  )

  const skillDropdown = useSkillDropdown()
  return (
    <div
      className=" shadow-md rounded-lg p-5 mb-5"
      style={{ border: "0.5px solid #E5E7EB" }}
    >
      <div className=" flex gap-5">
        <div
          className="shadow-md rounded-lg w-full bg-[#FAFAFA] p-5 "
          style={{ border: "0.5px solid #E5E7EB" }}
        >
          <Controller
            name="green_skill_id"
            control={form.control}
            rules={{ required: "This field is required" }}
            render={({ field }) => (
              <Select
                {...field}
                onPopupScroll={skillDropdown.handleScroll}
                placeholder="Select Skill"
                onDropdownVisibleChange={skillDropdown.handleClear}
                notFoundContent={"No green skill found"}
                showSearch
                filterOption={false}
                onSearch={skillDropdown.handleSearch}
                loading={skillDropdown.isLoading}
                options={makeDropdown(
                  skillDropdown.dataList,
                  dataAll
                    ?.filter((item) => item.status !== "REJECTED")
                    ?.map((item) => item.green_skill.id),
                  data?.green_skill.id,
                  gsProficient ? gsProficient?.map((item) => item.id) : [],
                )}
                // @ts-ignore
                // onSelect={(_, opts) => handleAddSector(opts)}
                // value={null}
                className=" w-full job-history"
              ></Select>
            )}
          />

          <div className=" mt-3 text-sm">
            Please note, currently only skill where you can demonstrate the
            highest proficiency (level 5) are eligible for verification
          </div>
          <div className=" flex justify-between mt-5 gap-3 items-center">
            <div className=" w-[calc(100%-7.75rem)]">
              {tempFile?.pdfUrl || data?.document_link ? (
                <a
                  href={tempFile?.pdfUrl || data?.document_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FilePdfOutlined className="" />{" "}
                  <span className="">
                    {tempFile.pdfFile?.name || data?.document_name}
                  </span>
                </a>
              ) : (
                <div className={`${form.isErrorFile ? "text-danger" : ""}`}>
                  Supporting Documents*
                </div>
              )}
            </div>
            <div className="">
              <label
                htmlFor="file-verifier"
                className="py-2 px-3 cursor-pointer w-full"
                style={{ border: "1px solid #86C769" }}
              >
                Attach a file
              </label>
              {/* <Controller
                name="file_verifier"
                control={form.control}
                rules={{ required: !data?.id }}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="file"
                    accept="application/pdf"
                    placeholder="Select Skill"
                    className="hidden"
                    id="file-verifier"
                    onChange={tempFile.handleFileChange}
                  />
                )}
              /> */}

              <input
                name="file_verifier"
                type="file"
                accept="application/pdf"
                id="file-verifier"
                className="hidden"
                onChange={tempFile.handleFileChange}
              />
            </div>
          </div>

          <div className=" mt-5 text-sm">
            *Supporting Documents should show your name and the skill being
            verified. We accept transcripts, certificates of completion and
            official recommendation letters.
          </div>
        </div>
        <div
          className=" shadow-md rounded-lg w-full bg-[#FAFAFA] p-3"
          style={{ border: "0.5px solid #E5E7EB" }}
        >
          <div className={`${form.errors.description ? "text-danger" : ""}`}>
            Justification
          </div>
          <Controller
            name="description"
            control={form.control}
            rules={{ required: "This field is required" }}
            render={({ field }) => (
              <RichTextMinimize {...field} className="mt-3 w-full h-40" />
            )}
          />
        </div>
      </div>
      <div className=" flex mt-5 gap-5 justify-end">
        <Button
          type="primary"
          ghost
          className=" w-28"
          onClick={() => actionUpdate(index, { ...data, isEdit: false })}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          className=" w-28"
          disabled={actions.isLoading}
          onClick={form.handleSubmit(actions.onSubmit)}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

const makeDropdown = (
  data: { id: string; name: string }[],
  used: string[],
  owned: string,
  gsProficient: string[],
): {
  value: string
  label: string
  disabled: boolean
}[] => {
  const finalUsed = [...used.filter((item) => item !== owned), ...gsProficient]
  const temp = []

  for (let i = 0; i < data.length; i++) {
    let isDisabled = false
    for (let j = 0; j < finalUsed.length; j++) {
      if (finalUsed[j] === data[i].id) {
        isDisabled = true
        break // No need to continue the loop if we've found a match
      }
    }
    temp.push({ value: data[i].id, label: data[i].name, disabled: isDisabled })
  }

  return temp
}
