import React from "react"
import { Job } from "../../types/jobBoard"
import { Skeleton } from "antd"

interface Props {
  data: Job | undefined
  isLoading: boolean
}
export const DetailDescription: React.FC<Props> = ({ data, isLoading }) => {
  return (
    <div className=" p-5 rounded-xl shadow-md bg-[#fafafa] w-full">
      <h3 className="mb-3 capitalize">job description</h3>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: data?.description ?? "" }} />
      )}
    </div>
  )
}
