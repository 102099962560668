import React from "react"
import { Controller } from "react-hook-form"
import { Form, Input, Radio, Button, Select } from "antd"
import NewPostIcons from "../NewPostIcons"
import { SendOutlined } from "@ant-design/icons"
import FormImageUploader from "../../../../../common/components/FormImageUploader"
import RHFDatePickerField from "../../../../../common/components/RHFDatepickerField"

import { useEventForm } from "../../hooks/useEventForm"

const Event: React.FC = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    timezones,
    createPost,
    closeModal,
    onSubmit,
  } = useEventForm()

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
      className="relative my-6"
    >
      <Form.Item>
        <Controller
          name={"image"}
          control={control}
          defaultValue={undefined}
          render={({ field: { onChange, value } }) => (
            <FormImageUploader
              value={value}
              onChange={onChange}
              description="Upload Event Image"
              className="w-full shadow-lg aspect-video"
              height={250}
            />
          )}
        />
      </Form.Item>

      <Form.Item
        label="Type"
        validateStatus={errors.event_type ? "error" : ""}
        help={errors.event_type?.message}
      >
        <Controller
          name="event_type"
          control={control}
          render={({ field }) => (
            <Radio.Group {...field}>
              <Radio value="online">Online</Radio>
              <Radio value="in_person">In Person</Radio>
            </Radio.Group>
          )}
        />
      </Form.Item>

      <div className="grid grid-cols-2 gap-4">
        <Form.Item
          label="Name"
          validateStatus={errors.event_name ? "error" : ""}
          help={errors.event_name?.message}
          className="col-span-1"
        >
          <Controller
            name="event_name"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Enter name" />
            )}
          />
        </Form.Item>
        <Form.Item
          label="Link"
          className="col-span-1"
          validateStatus={errors.external_event_link ? "error" : ""}
          help={errors.external_event_link?.message}
        >
          <Controller
            name="external_event_link"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Enter link" />
            )}
          />
        </Form.Item>
      </div>

      <div className="grid grid-cols-3 gap-4">
        <Form.Item
          label="Timezone"
          validateStatus={errors.timezone ? "error" : ""}
          help={errors.timezone?.message}
        >
          <Controller
            name="timezone"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                allowClear
                placeholder="Select Timezone"
                showSearch
                options={timezones.map((item) => ({ value: item }))}
                filterOption={(input, option) =>
                  option?.value
                    ? option?.value
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    : false
                }
              />
            )}
          />
        </Form.Item>
        <Form.Item rootClassName="col-span-1" label="Start Date">
          <RHFDatePickerField
            format="YYYY-MM-DD hh:mm"
            returnString
            showTime
            control={control}
            name="event_start_date"
          />
        </Form.Item>

        <Form.Item rootClassName="col-span-1" label="End Date">
          <RHFDatePickerField
            format="YYYY-MM-DD hh:mm"
            returnString
            showTime
            control={control}
            name="event_end_date"
          />
        </Form.Item>
      </div>

      <Form.Item label="Description">
        <Controller
          name="event_description"
          control={control}
          render={({ field }) => (
            <Input.TextArea
              {...field}
              styles={{
                textarea: {
                  resize: "none",
                },
              }}
              rows={6}
              placeholder="Describe the event."
            />
          )}
        />
      </Form.Item>

      <div className="flex items-center justify-between w-full">
        <NewPostIcons />
        <div className="flex items-center gap-4">
          <Button onClick={closeModal}>Cancel</Button>
          <Button
            className="text-white bg-primary"
            loading={createPost.isLoading}
            icon={<SendOutlined />}
            disabled={createPost.isLoading || !isDirty}
            onClick={handleSubmit(onSubmit)}
          >
            Post
          </Button>
        </div>
      </div>
    </Form>
  )
}

export default Event
