/* eslint-disable */

import React, { useMemo } from "react"
import { Modal, ModalProps } from "antd"
import {
  ExpandNotificationData,
  NotifyData,
  NotifyPayloadType,
} from "../types/notifs"
import {
  OrbitFooter,
  OrbitContent,
  BadgeFooter,
  BadgeContent,
} from "./ModalPopupNotifications"

interface Props extends ModalProps {
  notificationData: NotifyData<string>
}

/**
 * Modal popup for notifications. Creates a modal notification for types: Orbit and Badge
 * @param notificationData
 * @param rest
 *
 */
export function ModalPopupNotification({ notificationData, ...rest }: Props) {
  const parsedNotificationData: ExpandNotificationData = useMemo(
    () => JSON.parse(notificationData?.data ?? "{}"),
    [notificationData],
  )

  const data =
    parsedNotificationData?.type === NotifyPayloadType?.Orbit
      ? parsedNotificationData?.orbit
      : parsedNotificationData?.badge

  const FooterComponent =
    parsedNotificationData?.type === NotifyPayloadType?.Orbit
      ? OrbitFooter
      : BadgeFooter

  const ContentComponent =
    parsedNotificationData?.type === NotifyPayloadType?.Orbit
      ? OrbitContent
      : BadgeContent

  return (
    <Modal
      closable
      width={380}
      centered
      footer={
        <FooterComponent
          notificationData={notificationData}
          parsedNotificationData={parsedNotificationData}
        />
      }
      {...rest}
    >
      <ContentComponent notificationData={notificationData} data={data} />
    </Modal>
  )
}
