import React from "react"
import { BasicContainerWithTitle } from "../../../common/components"
import { Link, useNavigate } from "react-router-dom"
import { Button, Skeleton } from "antd"
import dayjs from "dayjs"
import { usePublishArticle } from "../../skillGalaxy/hooks/usePublishArticle"
// eslint-disable-next-line
import { PublicArticleResponseTypes } from "../../skillGalaxy/types/article_response"

interface ArticleWidgetProps {}

const ArticleWidget = React.forwardRef<HTMLDivElement, ArticleWidgetProps>(
  (_, ref) => {
    const { publishArticles, queryArticleResponse } = usePublishArticle()

    const navigate = useNavigate()

    return (
      <BasicContainerWithTitle
        ref={ref}
        type="small"
        title="Expert Articles"
        titleSize="h3"
        extraTitle={
          <Link to="/skill/articles">
            <Button className="border-primary">See all</Button>
          </Link>
        }
      >
        <div className="h-48 mt-5 overflow-y-auto  space-y-4 w-full">
          {publishArticles.isLoading ? (
            <>
              {publishArticles?.dataList?.map(
                (article: PublicArticleResponseTypes, index: number) => {
                  return (
                    <div
                      key={index}
                      className="hover:bg-gray-100 hover:px-2 transition-all hover:rounded-lg cursor-pointer hover:text-primary hover:font-bold"
                      onClick={() =>
                        navigate(`/skill/articles/${article?.slug}`)
                      }
                    >
                      <p className="text-[16px] m-0">{article?.title} </p>

                      <div className="flex items-center gap-3 text-[#919191]">
                        <p className="m-0">
                          {dayjs(article?.updated_at).format("DD MMM YYYY")}
                        </p>
                        <span className="text-xl">•</span>
                        <p className="m-0">{article?.read_time} read</p>
                      </div>
                    </div>
                  )
                },
              )}
              {Array.from({ length: 3 }, (_: any, index: number) => (
                <div key={index} className="w-full ">
                  <Skeleton.Button
                    block
                    active={true}
                    size="small"
                    className="my-1"
                  />
                  <Skeleton.Button
                    block
                    active={true}
                    size="small"
                    className="my-1"
                  />
                  <div className="flex items-center gap-3 text-[#919191] w-1/2">
                    <Skeleton.Button
                      block
                      active={true}
                      size="small"
                      className="my-1"
                    />
                    <Skeleton.Button
                      block
                      active={true}
                      size="small"
                      className="my-1"
                    />
                  </div>
                </div>
              ))}
            </>
          ) : (
            publishArticles?.dataList?.map(
              (article: PublicArticleResponseTypes, index: number) => {
                return (
                  <div
                    key={index}
                    className="hover:bg-gray-100 hover:px-2 transition-all hover:rounded-lg cursor-pointer hover:text-primary hover:font-bold"
                    onClick={() => navigate(`/skill/articles/${article?.slug}`)}
                    ref={queryArticleResponse?.lastCardElementRef}
                  >
                    <p className="text-[16px] m-0">{article?.title} </p>

                    <div className="flex items-center gap-3 text-[#919191]">
                      <p className="m-0">
                        {dayjs(article?.updated_at).format("DD MMM YYYY")}
                      </p>
                      <span className="text-xl">•</span>
                      <p className="m-0">{article?.read_time} read</p>
                    </div>
                  </div>
                )
              },
            )
          )}
        </div>
      </BasicContainerWithTitle>
    )
  },
)

export default ArticleWidget
