import React from "react"
import { Button, Image, Skeleton } from "antd"
import { Moon } from "../../../../assets/images"
import FiveCircleRateTS from "../../../../common/components/FiveCircleRateTS"
import { BasicContainer } from "../../../../common/components/BasicBoxContainers/BasicContainer"
import type { Trajectory } from "../../hooks/useTrajectory"
import { useNavigate } from "react-router-dom"

interface FirstSkillProps {
  skill: Trajectory | undefined
  isLoading: boolean
  className?: string
}

const LoadingSkeleton = ({ className }: { className?: string }) => (
  <BasicContainer
    className={`!bg-white flex w-full min-h-[240px] flex-col justify-center gap-[30px] ${className}`}
  >
    <div className="flex  gap-[30px]">
      <Skeleton.Image className="!w-[96px] !h-[96px]  shadow-md" />
      <Skeleton active={true} />
    </div>
  </BasicContainer>
)

const EmptySkill = ({ className }: { className?: string }) => {
  const navigate = useNavigate()
  return (
    <BasicContainer
      className={`!bg-white flex gap-[30px] min-h-[240px]  items-center md:flex-nowrap flex-wrap flex-grow ${
        className ?? ""
      }`}
    >
      <Image
        src={Moon}
        preview={false}
        className="!w-[96px] !h-[96px] rounded-full shadow-md mb-1 "
      />
      <div className="flex flex-col gap-[19px]">
        <h3>Start learning your first Green Skill</h3>
        <Button
          onClick={() =>
            navigate(
              "/skill/green-skills?search=&price=low-to-high&availability=start-today&sort_rank=&viewType=cards&page=1&limit=20",
            )
          }
          className="text-white bg-primary"
        >
          Choose Green Skill To Learn
        </Button>
        <div></div>
      </div>
    </BasicContainer>
  )
}

export const FirstSkill: React.FC<FirstSkillProps> = ({
  skill,
  isLoading,
  className,
}) => {
  const navigate = useNavigate()

  if (isLoading) {
    return <LoadingSkeleton className={className} />
  }

  if (skill === undefined) {
    return <EmptySkill className={className} />
  }

  return (
    <BasicContainer
      onClick={() =>
        navigate(`/dashboard/launchpad/single-mylaunchpad/${skill?.id}`, {
          state: {
            lastIndexLearn: skill?.current_level_progress
              ? skill?.current_level_progress - 1
              : 1,
          },
        })
      }
      className={`!bg-white min-h-[240px] w-full  flex flex-col justify-center cursor-pointer md:flex-nowrap flex-wrap hover:shadow-lg  transition-transform duration-300 ${
        className ?? ""
      }`}
    >
      <div className="flex md:flex-nowrap justify-center md:justify-start flex-wrap items-center gap-x-[29px]">
        <img
          src={skill?.image ?? Moon}
          alt="skill"
          className="!h-[96px] object-contain min-w-[96px]"
        />
        <div className="">
          <h3>{skill?.name}</h3>
          <div
            className=""
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {skill?.description}
          </div>
        </div>
      </div>
      <div className="mt-[16px] flex md:flex-nowrap gap-4 flex-wrap items-baseline justify-between">
        <div className="text-[10px] -mb-4 tracking-widest text-brown font-light uppercase">
          {skill?.group?.name ?? "N/A"}
        </div>
        <div className="flex gap-x-[22px] items-center">
          <div className="">Proficiency</div>
          <FiveCircleRateTS
            defaultValue={skill?.total_completed_levels ?? 0}
            secondValue={
              skill?.current_level_progress > skill?.total_completed_levels
            }
            disabled
            circleSize={15}
          />
        </div>
      </div>
    </BasicContainer>
  )
}
