import React, { useEffect, useRef } from "react"
import { Input, InputProps } from "antd"
import { Location, useSearchLocation } from "../../hooks/useSearchLocation"
import { LoadingOutlined } from "@ant-design/icons"

interface Props extends InputProps {
  onHandleSelect?: (e: Location) => void
  className?: string
}

export const SearchLocation: React.FC<Props> = ({
  onHandleSelect,
  className,
  onChange,
  onFocus,
  onBlur,
  value,
  defaultValue,
  ...field
}) => {
  const {
    data,
    handleSearch,
    handleSelect,
    visible,
    setVisible,
    state,
    isLoading,
  } = useSearchLocation()

  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handleClickInside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        wrapperRef.current.contains(event.target as Node)
      ) {
        setVisible(true)
      } else {
        setVisible(false)
      }
    }

    document.addEventListener("click", handleClickInside)
    return () => {
      document.removeEventListener("click", handleClickInside)
    }
  }, [])

  useEffect(() => {
    if (defaultValue) {
      state.setIsSelected(true)
      state.setValue(defaultValue)
    }
  }, [defaultValue])

  return (
    <div ref={wrapperRef} className={`relative ${className} `}>
      {!state?.isSelected ? (
        <Input
          placeholder="Type your location..."
          onChange={(e) => handleSearch(e.target.value)}
          onBlur={() => state.setIsSelected(true)}
          className={` ${className ? " rounded-[4px]" : ""}`}
          {...field}
        />
      ) : (
        <Input
          placeholder="Type your location..."
          value={state?.value}
          onFocus={() => state.setIsSelected(false)}
          className={` ${className ? " rounded-[4px]" : ""}`}
          {...field}
        />
      )}
      {isLoading ? (
        <div
          className=" absolute w-full bg-white z-50 rounded-b-md p-5 flex justify-center"
          style={{ border: "1px solid #d9d9d9", borderTop: "none" }}
        >
          <LoadingOutlined className=" text-primary" />
        </div>
      ) : (
        visible &&
        data &&
        data.length > 0 && (
          <div
            className=" absolute w-full bg-white z-50 rounded-b-md"
            style={{ border: "1px solid #d9d9d9", borderTop: "none" }}
          >
            {data.map((itm, i) => (
              <div
                className=" px-3 py-1 hover:bg-slate-200"
                key={i}
                onClick={() => {
                  handleSelect(itm.address.freeformAddress)
                  setVisible(false)
                  if (onHandleSelect) {
                    onHandleSelect(itm)
                  }
                }}
              >
                {itm.address.freeformAddress}
              </div>
            ))}
          </div>
        )
      )}
    </div>
  )
}
