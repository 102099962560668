import React from "react"
import { getActiveLink, unauthMenuItems } from "./menuItems"
import { MenuUnfoldOutlined, CaretDownOutlined } from "@ant-design/icons"
import { HeaderItemsContainer } from "./HeaderItemsContainer"
import { Button, Select, Tag } from "antd"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { UnauthenticatedTemplate } from "@azure/msal-react"
import { useQueryClient } from "react-query"
import { DollarOutlined } from "@ant-design/icons"

interface UnauthenticationActionsProps {
  onOpenDrawerItem: React.MouseEventHandler<HTMLDivElement> | undefined
  currencies?: {
    code: string
    exchange: number
    logo?: string | undefined
  }[]
  currency: string | null
  setCurrency: React.Dispatch<React.SetStateAction<string | null>>
}

const UnauthenticatedActions: React.FC<UnauthenticationActionsProps> = ({
  onOpenDrawerItem,
  currency,
  currencies,
  setCurrency,
}) => {
  const [itemForHoveredLabel, setItemForHoveredLabel] = React.useState<
    (typeof unauthMenuItems)[number] | null
  >(null)
  const navigate = useNavigate()
  const location = useLocation()
  const activeLink = getActiveLink(unauthMenuItems, location.pathname)
  const queryClient = useQueryClient()

  return (
    <UnauthenticatedTemplate>
      <div className="flex items-center justify-end lg:justify-between md:w-4/5">
        <div
          onMouseEnter={() => setItemForHoveredLabel(itemForHoveredLabel)}
          onMouseLeave={() => setItemForHoveredLabel(null)}
          className="hidden lg:flex gap-14 items-center  mr-2 justify-center w-full"
        >
          {unauthMenuItems
            .filter((item) => item?.children)
            .map((item) => {
              return (
                <div
                  onMouseEnter={() => setItemForHoveredLabel(item)}
                  onMouseLeave={() => setItemForHoveredLabel(null)}
                  key={item.label}
                  className="flex group items-center gap-2 relative"
                >
                  {
                    <CaretDownOutlined
                      className={`${
                        activeLink === item?.key ? "text-primary font-bold" : ""
                      } cursor-pointer group-hover:text-primary duration-300 transition-all`}
                    />
                  }
                  <span
                    className={`${
                      activeLink === item?.key ? "text-primary font-bold" : ""
                    } cursor-pointer`}
                  >
                    {item.label}
                  </span>
                </div>
              )
            })}
          {unauthMenuItems
            .filter(
              (item, idx) => !item?.children || idx === unauthMenuItems.length,
            )
            .map((item) => {
              return (
                <div
                  key={item?.key}
                  className="flex group items-center gap-2 relative"
                >
                  <Link
                    to={item?.link ?? ""}
                    className={`${
                      activeLink === item?.key ? "text-primary font-bold" : ""
                    } cursor-pointer`}
                  >
                    {item.label}
                  </Link>
                  {/* <span></span> */}
                </div>
              )
            })}
          <HeaderItemsContainer
            items={itemForHoveredLabel?.children}
            show={!!itemForHoveredLabel}
            onMouseEnter={() => setItemForHoveredLabel(itemForHoveredLabel)}
            onMouseLeave={() => setItemForHoveredLabel(null)}
          />
        </div>
        <div className="flex gap-5 md:gap-10 items-center">
          <div className="hidden lg:flex items-center gap-2">
            <a href={process.env.REACT_APP_RECRUITER_SPA_URL}>
              <Tag
                className={`cursor-pointer rounded-full font-semibold bg-white`}
              >
                Employer
              </Tag>
            </a>
            <Tag
              className={`rounded-full font-semibold bg-backdrop text-white`}
            >
              Talent
            </Tag>
          </div>
          <div className="flex gap-3">
            <div
              className="flex text-xl lg:hidden cursor-pointer gap-3"
              onClick={onOpenDrawerItem}
            >
              <MenuUnfoldOutlined />
            </div>
            {currencies && (
              <div className="no-bg-border">
                <Select
                  onSelect={(e) => {
                    localStorage.setItem("currency_code", e)
                    setCurrency(e)
                    queryClient.invalidateQueries(["carts"])
                    queryClient.invalidateQueries(["job-role-skill"])
                    queryClient.invalidateQueries(["fetch-green-skill-levels"])
                    queryClient.invalidateQueries(["workshops"])
                    queryClient.invalidateQueries(["fetch-green-skills"])
                  }}
                  className=" w-[6rem] rounded-md bg-transparent"
                  popupClassName="m-0 p-0"
                  defaultValue={currency || "sgd"}
                  options={currencies?.map((item) => ({
                    label: (
                      <div className=" flex gap-2 items-center">
                        {item?.logo ? (
                          <img
                            className=" w-4 h-4 rounded-full object-cover"
                            style={{ border: "0.5px solid #36505E" }}
                            src={item?.logo}
                          />
                        ) : (
                          <DollarOutlined className=" text-[1.1rem]" />
                        )}
                        <div className=" uppercase">{item.code}</div>
                      </div>
                    ),
                    value: item.code,
                  }))}
                />
              </div>
            )}
            <Button type="primary" onClick={() => navigate("/login")}>
              Login
            </Button>
          </div>

          <div className="hidden  items-center gap-10">
            <Button type="primary" onClick={() => navigate("/login")}>
              Login
            </Button>
          </div>
        </div>
      </div>
    </UnauthenticatedTemplate>
  )
}

export default UnauthenticatedActions
