import React from "react"
import { Tabs } from "antd"
import "../../components/custom.css"
import { JobRole } from "../../types/jobRole"
import { TabSkillProfile } from "./TabSkillProfile"
import { TabJobDetail } from "./TabJobDetail"

interface Props {
  data: JobRole
}

export const DetailJobSkill: React.FC<Props> = ({ data }) => {
  const tabs = [
    {
      key: "profile",
      label: "Skills Profile",
      children: <TabSkillProfile data={data} />,
    },
    {
      key: "responsibilities",
      label: "Responsibilities",
      children: <TabJobDetail data={data} />,
    },
  ]
  return (
    <div className="bg-white rounded-xl w-full md:w-[calc(100%+1.25rem-400px)] min-h-[700px] p-5">
      <Tabs
        defaultActiveKey={"profile"}
        className="custom-tabs"
        items={tabs}
        // activeKey={activeTab}
        // onTabClick={(key) => {
        //     setActiveTab(key)
        // }}
      />
    </div>
  )
}
