import React from "react"
import { PageContent } from "../../../../common/layout"
import { HomeOutlined } from "@ant-design/icons"
import {
  MyJobHistoryContentRightSection,
  MyJobHistoryContentSection,
  MyJobHistoryContentTopSection,
} from "../../components/jobHistory/JobHistorySection"
import { useMsal } from "@azure/msal-react"
import useHistoryJobs from "../../hooks/useHistoryJobs"
import useSavedJobs from "../../hooks/useSavedJobs"

const MyJobHistory: React.FC = () => {
  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()

  const { listJobHistory, isListJobHistoryLoading } = useHistoryJobs(
    activeAccount?.localAccountId,
  )
  const { listJobSaved, isListJobSavedLoading } = useSavedJobs(
    activeAccount?.localAccountId,
  )

  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      path: "/job/roles",
      title: "Jobs Galaxy",
    },
    {
      path: "/job/my",
      title: "My Jobs",
    },
    {
      path: "/job/my/history",
      title: "History",
    },
  ]

  return (
    <div>
      <PageContent
        header={
          <MyJobHistoryContentTopSection
            // isLoading={isListJobMyJobHistorysLoading}
            // listData={listJobMyJobHistorys}
            breadcrumbsItems={items}
          />
        }
        content={
          <MyJobHistoryContentSection
            data={listJobHistory}
            isListJobHistoryLoading={isListJobHistoryLoading}
          />
        }
        contentRightSidebar={
          <MyJobHistoryContentRightSection
            data={listJobSaved}
            isListJobSavedLoading={isListJobSavedLoading}
          />
        }
      />
    </div>
  )
}

export default MyJobHistory
