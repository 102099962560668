/* eslint-disable no-unused-vars */
import { AxiosResponse, AxiosError } from "axios"
import { message } from "antd"
import instance from "../../../../config/config"
// eslint-disable next-line no-unused-vars
import { UseQueryResult, useQuery } from "react-query"

interface ApiResponse<T> {
  error: boolean
  code: number
  message: string
  data: T
}

interface Pagination {
  page: number
  total_page: number
  limit: number
}

interface ListData<T> {
  list: T[]
  total_data: number
  pagination: Pagination
}

export const useFetchList = <T>(
  endpoint: string,
  queryParams?: Record<string, any>,
  queryName?: string,
): UseQueryResult<ListData<T>, AxiosError> => {
  const fetchList = async () => {
    try {
      const response: AxiosResponse<ApiResponse<ListData<T>>> =
        await instance.get(endpoint, {
          params: queryParams,
        })
      return response.data.data
    } catch (error: any) {
      message.error(`An error occurred while fetching list: ${error.message}`)
      throw error
    }
  }

  return useQuery<ListData<T>, AxiosError>(
    queryName ?? [endpoint, queryParams],
    fetchList,
    {
      onError: (error: AxiosError) => {
        message.error(`An error occurred while fetching list: ${error.message}`)
      },
    },
  )
}

export const useFetchDetail = <T>(
  endpoint: string,
  queryParams?: Record<string, any>,
  queryName?: string,
  enabled?: boolean,
): UseQueryResult<T, AxiosError> => {
  const fetchDetail = async () => {
    try {
      const response: AxiosResponse<ApiResponse<T>> =
        await instance.get(endpoint)
      return response.data.data
    } catch (error: any) {
      message.error(`An error occurred while fetching detail: ${error.message}`)
      throw error
    }
  }

  return useQuery<T, AxiosError>(queryName ?? endpoint, fetchDetail, {
    enabled: enabled ?? true,
    onError: (error: AxiosError) => {
      message.error(`An error occurred while fetching detail: ${error.message}`)
    },
  })
}

// // FUNC TO FETCH LIST
// export async function fetchList<T>(endpoint: string, queryParams?: Record<string, any>): Promise<ListData<T>> {
//   try {
//     const response: AxiosResponse<ApiResponse<ListData<T>>> = await instance.get(endpoint, {
//       params: queryParams,
//     })
//     return response.data.data
//   } catch (error: any) {
//     message.error(`Error fetching list: ${error.message}`)
//     throw error
//   }
// }

// // FUNC TO FETCH DETAIL
// export async function fetchDetail<T>(endpoint: string, queryParams?: Record<string, any>): Promise<T> {
//   try {
//     const response: AxiosResponse<ApiResponse<T>> = await instance.get(endpoint, {
//       params: queryParams,
//     })
//     return response.data.data
//   } catch (error: any) {
//     message.error(`Error fetching detail: ${error.message}`)
//     throw error
//   }
// }

// // FUNC TO CREATE NEW DATA
// export async function createData<T>(endpoint: string, newData: T): Promise<T> {
//   try {
//     const response: AxiosResponse<ApiResponse<T>> = await instance.post(endpoint, newData)
//     message.success('Data created successfully')
//     return response.data.data
//   } catch (error: any) {
//     message.error(`Error creating data: ${error.message}`)
//     throw error
//   }
// }

// // FUNC TO UPDATE DATA
// export async function updateData<T>(endpoint: string, updatedData: T): Promise<T> {
//   try {
//     const response: AxiosResponse<ApiResponse<T>> = await instance.put(endpoint, updatedData)
//     message.success('Data updated successfully')
//     return response.data.data
//   } catch (error: any) {
//     message.error(`Error updating data: ${error.message}`)
//     throw error
//   }
// }

// // FUNC TO DELETE DATA
// export async function deleteData(endpoint: string): Promise<void> {
//   try {
//     await instance.delete(endpoint)
//     message.success('Data deleted successfully')
//   } catch (error: any) {
//     message.error(`Error deleting data: ${error.message}`)
//     throw error
//   }
// }

// ===============

// export const useFetchList = <T>(
//   endpoint: string,
//   queryParams?: Record<string, any>,
//   queryName?: string // Tambahkan parameter queryName
// ): UseQueryResult<T[], AxiosError> => {
//   const fetchList = async () => {
//     try {
//       const response: AxiosResponse<T[]> = await instance.get(endpoint, {
//         params: queryParams,
//       })
//       return response.data
//     } catch (error: any) {
//       message.error(`An error occurred while fetching list: ${error.message}`)
//       throw error
//     }
//   }

//   return useQuery<T[], AxiosError>(
//     queryName ?? [endpoint, queryParams], // Gunakan queryName jika tersedia, jika tidak, gunakan [endpoint, queryParams]
//     fetchList,
//     {
//       onError: (error: AxiosError) => {
//         message.error(`An error occurred while fetching list: ${error.message}`)
//       },
//     }
//   )
// }

// export const useFetchDetail = <T>(
//   endpoint: string,
//   queryName?: string // Tambahkan parameter queryName
// ): UseQueryResult<T, AxiosError> => {
//   const fetchDetail = async () => {
//     try {
//       const response: AxiosResponse<T> = await instance.get(endpoint)
//       return response.data
//     } catch (error: any) {
//       message.error(`An error occurred while fetching detail: ${error.message}`)
//       throw error
//     }
//   }

//   return useQuery<T, AxiosError>(
//     queryName ?? endpoint, // Gunakan queryName jika tersedia, jika tidak, gunakan endpoint sebagai default
//     fetchDetail,
//     {
//       onError: (error: AxiosError) => {
//         message.error(`An error occurred while fetching detail: ${error.message}`)
//       },
//     }
//   )
// }

// export const useCreateItem = <T>(
//   endpoint: string,
// ): UseMutationResult<AxiosResponse<T>, AxiosError, T, unknown> => {
//   const createItem = async (data: T) => {
//     try {
//       const response: AxiosResponse<T> = await instance.post(endpoint, data)
//       return response.data
//     } catch (error: any) {
//       message.error(`An error occurred while creating item: ${error.message}`)
//       throw error
//     }
//   }

//   return useMutation<AxiosResponse<T>, AxiosError, T, unknown>(createItem, {
//     onError: (error: AxiosError) => {
//       message.error(`An error occurred while creating item: ${error.message}`)
//     },
//   })
// }

// export const useUpdateItem = <T>(
//   endpoint: string,
// ): UseMutationResult<AxiosResponse<T>, AxiosError, T, unknown> => {
//   const updateItem = async (data: T) => {
//     try {
//       const response: AxiosResponse<T> = await instance.put(endpoint, data)
//       return response.data
//     } catch (error: any) {
//       message.error(`An error occurred while updating item: ${error.message}`)
//       throw error
//     }
//   }

//   return useMutation<AxiosResponse<T>, AxiosError, T, unknown>(updateItem, {
//     onError: (error: AxiosError) => {
//       message.error(`An error occurred while updating item: ${error.message}`)
//     },
//   })
// }

// export const useDeleteItem = (
//   endpoint: string,
// ): UseMutationResult<AxiosResponse<void>, AxiosError, string, unknown> => {
//   const deleteItem = async (id: string) => {
//     try {
//       const response: AxiosResponse<void> = await instance.delete(`${endpoint}/${id}`)
//       return response.data
//     } catch (error: any) {
//       message.error(`An error occurred while deleting item: ${error.message}`)
//       throw error
//     }
//   }

//   return useMutation<AxiosResponse<void>, AxiosError, string, unknown>(deleteItem, {
//     onError: (error: AxiosError) => {
//       message.error(`An error occurred while deleting item: ${error.message}`)
//     },
//   })
// }
