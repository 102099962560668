import React from "react"
import { TOccasionPostBody } from "../../types/commonn"

const Occasion: React.FC<{ body: TOccasionPostBody }> = ({ body }) => {
  return (
    <div className="flex flex-col ring-backdrop ring-1  rounded-lg shadow-md overflow-hidden">
      <div className="relative w-full h-48">
        <img
          className="w-full h-full object-cover rounded-t-lg"
          src={body?.image_url}
          alt={body?.text}
        />
        <div className="absolute inset-0 bg-gradient-to-r from-gray-500 to-transparent rounded-t-lg opacity-50 flex flex-col justify-center items-center gap-2">
          <h2 className="text-white z-10 font-semibold text-4xl">Celebrating Occasion</h2>
          <div className="text-4xl">🎉</div>
        </div>
      </div>
      <div className="p-4 flex flex-col space-y-2">
        <h3 className="">
          {body?.text}
        </h3>
        <p className="text-gray-700">{body?.description}</p>
      </div>
    </div>
  )
}

export default Occasion
