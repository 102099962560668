import { useQuery } from "react-query"
import { handlingError } from "../../../utils/handling"
import { useMemo } from "react"
import instance from "../../../config/config"

const useSavedJobs = (userId) => {
  const queryKey = useMemo(
    () => [
      "job-saved-list",
      {
        //   company_ids,
        //   job_role_id,
      },
    ],
    [],
  )

  const fetchListJobSaved = async () => {
    try {
      const { data } = await instance.get(`/job-boards/saved/${userId}`, {
        params: {
          // company_ids,
          // job_role_id,
        },
      })
      return data
    } catch (error) {
      return handlingError(error?.response?.data?.message, error)
    }
  }

  const { data, isLoading } = useQuery(queryKey, fetchListJobSaved)
  const listJobSaved = data?.data
  const isListJobSavedLoading = isLoading

  return {
    listJobSaved,
    isListJobSavedLoading,
  }
}

export default useSavedJobs
