import React from "react"
import { ProductResponse, StartupResponse } from "../../founder/types/response"
import ProductPreview from "../../public-expert/components/ProductPreview"
import { Skeleton } from "antd"

interface SolutionDetailContentProps {
  detail?: StartupResponse
  loading?: boolean
}

const SolutionDetailContent: React.FC<SolutionDetailContentProps> = ({
  detail,
  loading,
}) => {
  return (
    <div className="bg-white p-4 rounded-xl space-y-6">
      {loading
        ? Array.from({ length: 2 }).map((_, index) => (
            <div
              className="shadow-custom px-5 py-4 rounded-xl bg-white-custom"
              key={index}
            >
              <Skeleton key={index} active />
            </div>
          ))
        : detail?.products?.map((product: ProductResponse, index: number) => {
            return <ProductPreview key={index} data={product} />
          })}
    </div>
  )
}

export default SolutionDetailContent
