import React from "react"
import { PlusOutlined } from "@ant-design/icons"
import { AddPlus } from "../../../../assets/img"
import { Card, Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { Moon } from "../../../../assets/images"
import { useLanguage } from "../../hooks/cv-builder/useLanguage"
import { Language } from "./languages/Language"

export const Languages = () => {
  const {
    tempData,
    updateTempData,
    handleAddUp,
    handleAddDown,
    loadingLanguage,
    onRemove,
    loadingremoveLanguage,
    proficiencies,
    commonsLanguages,
    onRemoveTemp,
  } = useLanguage()
  return (
    <div className="mb-5">
      <div className="flex items-center justify-between mb-5 px-6 py-2 rounded-xl bg-secondaryYellow shadow-md">
        <h2 className=" capitalize">languages</h2>
        <div
          className="flex gap-3 items-center text-white bg-primary py-1 px-6 cursor-pointer"
          onClick={handleAddUp}
        >
          <PlusOutlined />
          <div className="hidden lg:block">New Languages</div>
        </div>
      </div>
      {loadingLanguage ? (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      ) : (
        <div>
          {tempData.length > 0 ? (
            tempData?.map((item: any, index: number) => (
              <Language
                key={index}
                data={item}
                proficiency={proficiencies}
                commonLanguages={commonsLanguages}
                index={index}
                updateTempData={updateTempData}
                onRemove={onRemove}
                loadingremoveLanguage={loadingremoveLanguage}
                onRemoveTemp={onRemoveTemp}
                tempData={tempData}
              />
            ))
          ) : (
            <div>
              <Card className="shadow text-center mb-5 rounded-lg">
                <div className="mb-5">Can you speak Martian? </div>
                <img src={Moon} alt="cookies" />
              </Card>
            </div>
          )}
          <div className="flex justify-center ">
            <AddPlus
              className="cursor-pointer w-14 h-14"
              onClick={handleAddDown}
            />
          </div>
        </div>
      )}
    </div>
  )
}
