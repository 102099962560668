import create from "zustand"

interface SelectedDomainItemsState {
  selectedDomainItems: { id: string; name: string }[]
  setSelectedDomainItems: (items: { id: string; name: string }[]) => void
  removeSelectedDomainItem: (id: string) => void
}

export const useSelectedDomainItemsStore = create<SelectedDomainItemsState>(
  (set) => ({
    selectedDomainItems: [],
    setSelectedDomainItems: (items) => set({ selectedDomainItems: items }),
    removeSelectedDomainItem: (id) =>
      set((state) => ({
        selectedDomainItems: state.selectedDomainItems.filter(
          (item) => item.id !== id,
        ),
      })),
  }),
)

interface SelectedAreaItemsState {
  selectedAreaItems: { id: string; name: string }[]
  setSelectedAreaItems: (items: { id: string; name: string }[]) => void
  removeSelectedAreaItem: (id: string) => void
}

export const useSelectedAreaItemsStore = create<SelectedAreaItemsState>(
  (set) => ({
    selectedAreaItems: [],
    setSelectedAreaItems: (items) => set({ selectedAreaItems: items }),
    removeSelectedAreaItem: (id) =>
      set((state) => ({
        selectedAreaItems: state.selectedAreaItems.filter(
          (item) => item.id !== id,
        ),
      })),
  }),
)

interface SelectedLocationItemState {
  setSelectedLocationItemId: (id: string) => void
  selectedLocationItemId: string | null
}

export const useSelectedLocationItemIdStore = create<SelectedLocationItemState>(
  (set) => ({
    selectedLocationItemId: null,
    setSelectedLocationItemId: (id: string) =>
      set({ selectedLocationItemId: id }),
  }),
)
