import React from "react"
import { Moon } from "../../../../assets/images"
import { Divider, Rate } from "antd"
import clsx from "clsx"
import { useQuery } from "react-query"
import { fetchMentorMentees } from "../apis/mentors"
import AvatarCustom from "../../../../common/components/AvatarCustom"

const Mentees = () => {
  const menteesQuery = useQuery([`mentees`], fetchMentorMentees)
  const menteesData = menteesQuery.data?.data?.data
  const doMenteesExist =
    menteesData?.mentees && menteesData?.mentees?.length > 0

  console.log(menteesQuery.data?.data?.data)

  return (
    <div className=" mt-4">
      <h4 className="!font-semibold !leading-[38px] text-xl">My Mentees</h4>
      {doMenteesExist ? (
        <div className="mt-5 p-5 ring-1 ring-grey bg-white rounded-none">
          <div className="flex items-center gap-4">
            <div className="">Rating:</div>
            <Rate disabled defaultValue={2} allowHalf />
            <div className="text-darkGrey">{menteesData?.rating}</div>
            {/* <div className="text-darkGrey">(based on 13 reviews)</div> */}
          </div>
          <div className="flex items-center gap-4">
            <div className="">Mentees:</div>
            <div className="text-darkGrey">
              {menteesData?.total_mentees} mentees
            </div>
            <Divider type="vertical" className="!border-darkGrey !mx-0" />
            <div className="text-darkGrey">
              {menteesData?.total_session} sessions
            </div>
          </div>

          <div className="mt-6">
            <div className="text-lg font-semibold ">Mentees</div>
            <div
              className={clsx("grid gap-4 mt-4", {
                "grid-cols-1": false,
                "grid-cols-2": true,
              })}
            >
              {menteesData?.mentees?.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col gap-2 items-center justify-center"
                >
                  <div className="flex items-center justify-center flex-col">
                    <AvatarCustom
                      url={item?.avatar}
                      display_name={item?.name}
                      label={"Mentee"}
                    />
                  </div>

                  <div className="flex flex-col items-center gap-2 justify-center">
                    <h3>{item?.name}</h3>
                    {/* <div className="-mt-2 mb-2 flex items-center gap-2 justify-center">
                      <EnvironmentOutlined /> {item?.country}
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center gap-5 text-center p-7">
          <img
            src={Moon}
            className="h-[116px] object-contain aspect-square"
            alt="empty"
          />
          <div className="text-base">
            Looks like you don't have any mentees or reviews yet. Make sure your
            profile is complete and highlights your expertise.
          </div>
        </div>
      )}
    </div>
  )
}

export default Mentees
