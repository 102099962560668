/* eslint-disable */

import { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import instance, { instanceV2 } from "../../../../config/config"
import { AxiosResponse } from "axios"
import { message } from "antd"

export type Language = {
  id: string
  language: {
    name: string
    id: string
  }
  proficiency: {
    name: string
    id: string
    level: number
  }
  isEdit?: boolean
}

export type Common = {
  id: string
  name: string
}

export type Proficiency = {
  id: string
  name: string
  level: number
}

export const useLanguage = () => {
  const [tempData, setTempData] = useState<Language[]>([])
  const queryClient = useQueryClient()
  const handleAddDown = () => {
    const temp: Language = {
      id: "",
      language: {
        name: "",
        id: "",
      },
      proficiency: {
        name: "",
        id: "",
        level: 0,
      },
      isEdit: true,
    }
    setTempData((prev) => [...prev, temp])
  }

  const handleAddUp = () => {
    const temp: Language = {
      id: "",
      language: {
        name: "",
        id: "",
      },
      proficiency: {
        name: "",
        id: "",
        level: 0,
      },
      isEdit: true,
    }
    setTempData((prev) => [temp, ...prev])
  }

  const updateTempData = (index: number, data: Language) => {
    const temp = [...tempData]
    temp[index] = data
    setTempData(temp)
  }

  const { data: language, isFetching: loadingLanguage } = useQuery(
    "job-language",
    () => instanceV2.get<AxiosResponse<Language[]>>("/accounts/languages"),
  )

  const onRemoveTemp = (val: number) => {
    setTempData((prev) => prev.filter((_, index) => index !== val))
  }

  useEffect(() => {
    if (language?.data?.data) {
      setTempData(language?.data?.data || [])
    }
  }, [language?.data?.data])

  const { mutateAsync: removeLanguage, isLoading: loadingremoveLanguage } =
    useMutation({
      mutationFn: async (id: string) => {
        try {
          const res = await instanceV2.delete(`/accounts/languages/id/${id}`)
          return res
        } catch (error) {
          throw error
        }
      },
      mutationKey: "account-remove-language",
    })

  const onRemove = async (val: string) => {
    message.open({
      key: "remove-language",
      content: "removing...",
      type: "loading",
    })
    try {
      const response = await removeLanguage(val)
      if (response) {
        queryClient.invalidateQueries(["account-cv"]).then(() => {
          setTempData((prev) => prev.filter((item) => item.id !== val))
          message.destroy()
        })
      }
    } catch (error: any) {
      message.open({
        key: "remove-languages",
        content: error?.response?.data?.data || "Internal server error",
        type: "error",
      })
    }
  }

  const { data: proficiencies, isLoading: loadingProficiencies } = useQuery(
    "proficiencies",
    () => instance.get<AxiosResponse<Proficiency[]>>("/commons/proficiencies"),
  )

  const { data: commonsLanguages, isLoading: loadingCommonsLanguages } =
    useQuery("commons-languages", () =>
      instance.get<AxiosResponse<Common[]>>("/commons/languages"),
    )

  return {
    tempData,
    updateTempData,
    handleAddDown,
    handleAddUp,
    loadingLanguage,
    onRemove,
    onRemoveTemp,
    loadingremoveLanguage,
    proficiencies: {
      data: proficiencies?.data?.data,
      loading: loadingProficiencies,
    },
    commonsLanguages: {
      data: commonsLanguages?.data?.data,
      loading: loadingCommonsLanguages,
    },
  }
}
