import { Link } from "react-router-dom"

// eslint-disable-next-line no-unused-vars
export const BadgeFooter: React.FC<{ onCancel?: (e: MouseEvent) => void }> = ({
  onCancel,
}) => (
  <div className="text-center w-full">
    Visit{" "}
    <Link
      className="text-secondary hover:text-cyan-700 hover:scale-105"
      onClick={(e: any) => {
        onCancel?.(e)
      }}
      to="/dashboard/galactic-achievements/solar-trophies"
    >
      My Galactic Achievements?
    </Link>
  </div>
)
