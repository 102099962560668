import React from "react"
import { HomeOutlined } from "@ant-design/icons"
import { PageContent, PageHeader } from "../../../../common/layout"
import ListArticleContent from "../components/ListArticleContent"
import { useListExpert } from "../hooks/useExpert"

interface ListExpertsProps {}

const ListExperts: React.FC<ListExpertsProps> = () => {
  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      path: "/community/member",
      title: "Community",
    },
    {
      title: "Sustainability Experts",
    },
  ]

  const { expertData, queryExpert, referenceData } = useListExpert()

  return (
    <PageContent
      header={
        <PageHeader breadcrumbItems={items} title={"Sustainability Experts"} />
      }
      content={
        <ListArticleContent
          expertData={expertData}
          queryExpert={queryExpert}
          referenceData={referenceData}
        />
      }
    />
  )
}

export default ListExperts
