/* eslint-disable */

import { useForm } from "react-hook-form"
import { Account } from "./useProfile"
import { instanceV2 } from "../../../../config/config"
import { message } from "antd"
import { useMutation, useQueryClient } from "react-query"
import { useEffect } from "react"

export const useSocialUpdate = (
  data: Account | undefined,
  callback: (index: number) => void,
) => {
  const {
    handleSubmit,
    control,
    // reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: data?.email,
      linkedin: data?.linkedin,
      twitter: data?.twitter,
      link: data?.link,
    },
  })

  useEffect(() => {
    setValue("email", data?.email)
    setValue("linkedin", data?.linkedin)
    setValue("twitter", data?.twitter)
    setValue("link", data?.link)
  }, [data])

  const queryClient = useQueryClient()

  const { mutateAsync: social, isLoading: loadingUpdate } = useMutation({
    mutationFn: async (val: any) => {
      try {
        const res = await instanceV2.post(`/accounts/social`, val)
        return res
      } catch (error) {
        throw error
      }
    },
    mutationKey: "account-info",
  })

  const onSubmit = async (val: any) => {
    message.open({
      key: "update-social",
      content: "updating...",
      type: "loading",
    })
    try {
      const response = await social(val)
      if (response) {
        queryClient.invalidateQueries(["account-cv"]).then(() => {
          callback(1)
          message.destroy()
        })
      }
    } catch (error: any) {
      message.open({
        key: "update-social",
        content: error?.response?.data?.data || "Internal server error",
        type: "error",
      })
    }
  }

  return { control, errors, handleSubmit, onSubmit, loadingUpdate }
}
