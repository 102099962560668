import React from "react"
import { Discussion } from "../../types/workshops"
import { BasicUserInfo } from "../../../../common/components"
import { FieldTimeOutlined } from "@ant-design/icons"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"

dayjs.extend(relativeTime)

interface SingleDiscussionProps {
  discussion: Discussion
  isLoading?: boolean
}

const SingleDiscussion = React.forwardRef<
  HTMLDivElement,
  SingleDiscussionProps
>(({ discussion, isLoading }) => {
  return (
    <div className="basic-card bg-white">
      <div className="flex items-start justify-between">
        <BasicUserInfo
          avatar={discussion?.user?.avatar}
          location={discussion?.user?.location}
          name={discussion?.user?.name}
          isLoading={isLoading}
        />
        <div className="flex gap-2 items-center">
          <FieldTimeOutlined />
          {dayjs(discussion?.created_at)?.fromNow(true)}
        </div>
      </div>
      {isLoading ? (
        <div className="w-full h-2 animate-pulse" />
      ) : (
        <p className="mt-2">{discussion?.content}</p>
      )}
    </div>
  )
})

export const SingleDiscussionLoading = () => {
  return (
    <div className="basic-card bg-white">
      <div className="flex items-start justify-between">
        <BasicUserInfo isLoading />
        <div className="w-full h-2 animate-pulse" />
      </div>
    </div>
  )
}

export default SingleDiscussion
