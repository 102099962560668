import React from "react"
import ListAllCommunityFilter from "./ListAllCommunityFilter"
import { Image, Skeleton } from "antd"
import { Moon } from "../../../../assets/images"
import { UserProfileResponse } from "../../founder/types/response"
import MemberCard from "./MemberCard"

interface ListAllCommunityContentProps {
  memberData: any
  referenceData: any
  queryMember: any
}

const ListAllCommunityContent: React.FC<ListAllCommunityContentProps> = ({
  memberData,
  queryMember,
  referenceData,
}) => {
  return (
    <div className="space-y-5">
      <div>
        <ListAllCommunityFilter
          referenceData={referenceData}
          queryMember={queryMember}
        />
      </div>

      <div
        className={`${
          !memberData?.listLoading && !memberData?.listData
            ? ""
            : "grid md:grid-cols-3 gap-5 mx-3 md:mx-0"
        } `}
      >
        {memberData?.listLoading ? (
          Array.from({ length: 6 }, (_: any, index: number) => (
            <div
              key={index}
              className="py-3 px-4 bg-white-custom rounded-xl shadow-custom-sm space-y-3 hover:scale-[1.02] transition-all cursor-pointer"
            >
              <div className="flex items-center justify-center flex-col gap-y-3 w-full ">
                <Skeleton.Avatar size={120} active={true} />
                <div className="flex flex-col items-center gap-2 justify-center w-full ">
                  <Skeleton.Input block active={true} />
                  <Skeleton.Input block active={true} />
                </div>

                <div className="px-5 py-3 pt-5 bg-primary-bg w-full rounded-xl">
                  <Skeleton paragraph={{ rows: 2 }} active={true} />
                </div>
              </div>
            </div>
          ))
        ) : memberData?.listData?.length === 0 || !memberData?.listData ? (
          <div className="w-full mx-auto rounded-lg bg-white flex justify-center text-center  py-6 col-span-3">
            <div>
              <Image src={Moon} alt="moon image" preview={false} width={100} />
              <p className="my-4">No Data</p>
            </div>
          </div>
        ) : (
          memberData?.listData?.map(
            (memberItem: UserProfileResponse, index: number) => (
              <MemberCard
                key={index}
                memberItem={memberItem}
                queryMember={queryMember}
              />
            ),
          )
        )}
      </div>
    </div>
  )
}

export default ListAllCommunityContent
