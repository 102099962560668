import React from "react"
import dayjs from "dayjs"
import { Card } from "antd"
import { SolutionOutlined, CalendarOutlined } from "@ant-design/icons"
import { EmptyData } from "./EmptyData"
import { Account } from "../../hooks/cv-builder/useProfile"
import { OtherInstitution } from "../../../../assets/images"

interface Props {
  data: Account | undefined
}

export const CardEducation: React.FC<Props> = ({ data }) => {
  return (
    <div>
      <div className="flex items-center justify-between mb-5 px-6  bg-secondaryYellow shadow-md rounded-md">
        <h3 className=" capitalize">education</h3>
      </div>
      {data?.educations ? (
        data?.educations?.map((item, i) => (
          <Card
            className=" mb-5 relative shadow-md bg-[#FAFAFA] rounded-xl"
            key={i}
          >
            <div className="flex gap-5 ">
              <img
                src={item.institution.image || OtherInstitution}
                alt="company"
                className="w-20 h-20 p-2 rounded-full shadow object-contain"
              />
              <div>
                <h3 className=" capitalize">{item.institution.name}</h3>
                <div className="flex gap-4 items-center my-1">
                  <div className="">
                    <SolutionOutlined />
                    <span className=" text-sm ml-2 capitalize">{`${item.degree}, ${item.field_of_study}`}</span>
                  </div>
                </div>
                <div className="flex gap-4 items-center">
                  <div className="">
                    <CalendarOutlined />
                    <span className=" text-sm ml-2">{`${dayjs(
                      item.start_at,
                    ).format("MMM YYYY")} - ${
                      item.end_at
                        ? dayjs(item.end_at).format("MMM YYYY")
                        : "Present"
                    }`}</span>
                  </div>
                  <small className=" text-slate-400">
                    {item.online ? "online" : "offline"}
                  </small>
                </div>
              </div>
            </div>
          </Card>
        ))
      ) : (
        <EmptyData />
      )}
    </div>
  )
}
