import { Button } from "antd"
import Lottie from "lottie-react"
import success_payment from "../../../assets/json/success_payment.json"
import React from "react"
import { useNavigate } from "react-router-dom"

interface HeroSectionProps {}

const HeroSection: React.FC<HeroSectionProps> = () => {
  const navigate = useNavigate()
  return (
    <div className="bg-white text-backdrop">
      <div className="m-auto w-[85%] py-16 flex items-center justify-between flex-wrap text-center md:text-left">
        <div className="space-y-10 md:w-1/2">
          <h1
            className="text-5xl !font-semibold !leading-[3.6rem]"
            style={{ lineHeight: "12.3rem" }}
          >
            Get skilled in Green Economy
          </h1>
          <p className="leading-6  text-[18px]">
            Welcome to your one-stop platform for Green Skills, hot
            Sustainability Careers, and Future-Proofed pathways.
          </p>
          <Button
            onClick={() => navigate("/register")}
            className="hover:shadow-xl cursor-pointer transition-all w-full md:w-auto"
            type="primary"
          >
            START FREE
          </Button>
        </div>
        <div className="flex justify-center w-[240px] md:w-[320px] mx-auto md:mx-0 mt-8 md:mt-0">
          {/* <Image preview={false} src={LandingHeroSection} alt='landing-hero' /> */}
          <Lottie className="" animationData={success_payment} />
        </div>
      </div>
    </div>
  )
}

export default HeroSection
