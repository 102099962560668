import { useMutation } from "react-query"
import { instanceV2 } from "../../../config/config"

export const useUpdateAccountBasic = () => {
  return useMutation({
    mutationFn: (val: any) => instanceV2.post(`/accounts/basic`, val),
    mutationKey: "account-info",
  })
}

export const useUpdateSocials = () => {
  return useMutation({
    mutationFn: (val: any) => instanceV2.post(`/accounts/social`, val),
    mutationKey: "account-info",
  })
}
