import React from "react"
import { Calendar, Switch, TimePicker } from "antd"
import {
  Controller,
  FieldValues,
  Control,
  UseFormWatch,
  UseFormSetValue,
  UseFormGetValues,
} from "react-hook-form"
import dayjs from "dayjs"
import { CalendarSettingsForm } from "./ModalCalendarSettings"
import clsx from "clsx"
// import { v4 as uuidv4 } from "uuid"
import { DeleteOutlined } from "@ant-design/icons"

const defaultStartTime = dayjs().startOf("day").hour(9).minute(0) // 9:00 AM
const defaultEndTime = dayjs().startOf("day").hour(17).minute(0) // 5:00 PM

interface CalendarOverrideSettingsProps {
  control: Control<FieldValues, CalendarSettingsForm>
  watch: UseFormWatch<CalendarSettingsForm>
  setValue: UseFormSetValue<CalendarSettingsForm>
  getValues: UseFormGetValues<CalendarSettingsForm>
}

const CalendarOverrideSettings: React.FC<CalendarOverrideSettingsProps> = ({
  control,
  setValue,
  getValues,
  watch,
}) => {
  const dayOverrides = watch("day_overrides")
  const handleDateSelect = (date: dayjs.Dayjs) => {
    const formattedDate = date.format("YYYY-MM-DD")
    const currentOverrides = getValues("day_overrides")
    if (!currentOverrides[formattedDate]) {
      const newOverrides = {
        ...currentOverrides,
        [formattedDate]: [
          {
            start_date: defaultStartTime.format("YYYY-MM-DDTHH:mm:ssZ"),
            end_date: defaultEndTime.format("YYYY-MM-DDTHH:mm:ssZ"),
          },
        ],
      }
      const sortedOverrides = Object.fromEntries(
        Object.entries(newOverrides).sort(([a], [b]) =>
          dayjs(a).isBefore(dayjs(b)) ? -1 : 1,
        ),
      )

      setValue("day_overrides", sortedOverrides, { shouldDirty: true })
    }
  }

  const removeDate = (date: string) => {
    const currentOverrides = { ...getValues("day_overrides") }
    delete currentOverrides[date]
    setValue("day_overrides", currentOverrides, { shouldDirty: true })
  }

  const handleFullDayToggle = (date: string, checked: boolean) => {
    if (checked) {
      setValue(
        `day_overrides.${date}`,
        [
          {
            start_date: dayjs(date)
              .startOf("day")
              .format("YYYY-MM-DDTHH:mm:ssZ"),
            end_date: dayjs(date).endOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
          },
        ],
        { shouldDirty: true },
      )
    }
  }

  const addMoreTimeRange = (date: string) => {
    const newTimeRange = {
      start_date: defaultStartTime.format("YYYY-MM-DDTHH:mm:ssZ"),
      end_date: defaultEndTime.format("YYYY-MM-DDTHH:mm:ssZ"),
    }
    setValue(
      `day_overrides.${date}`,
      [...getValues(`day_overrides.${date}`), newTimeRange],
      { shouldDirty: true },
    )
  }

  const dateFullCellRender = (date: dayjs.Dayjs) => {
    const formattedDate = date.format("YYYY-MM-DD")

    if (dayOverrides[formattedDate]) {
      return (
        <div className="bg-primary mx-2 text-white rounded-full py-1">
          {date.date()}
        </div>
      )
    }
    return <div>{date.date()}</div>
  }

  return (
    <div>
      <div className=" grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        <div className="col-span-full flex flex-col gap-3 items-center justify-center">
          {/* Calendar */}
          <div className="p-2 rounded-[10px] bg-[#F2F2F2] w-[375px]">
            <div className="flex justify-center">
              <Calendar
                fullscreen={false}
                onSelect={handleDateSelect}
                dateFullCellRender={dateFullCellRender}
                disabledDate={(date) => date.isBefore(dayjs())}
                style={{
                  backgroundColor: "transparent",
                  background: "transparent",
                }}
                className="!bg-transparent custom-calendar"
                rootClassName="!bg-transparent"
              />
            </div>
          </div>
        </div>

        <div className="col-span-full grid grid-cols-1 md:grid-cols-3 gap-6 mt-4">
          {Object.keys(dayOverrides || {}).map((date) => (
            <div
              key={date}
              className="col-span-1 bg-[#F6F6F6] p-4 rounded-md min-h-[240px] flex flex-col gap-4"
            >
              <div className="font-semibold flex justify-between items-center">
                {dayjs(date).format("MMMM D, YYYY")}
                <DeleteOutlined
                  className="cursor-pointer"
                  onClick={() => removeDate(date)}
                />
              </div>

              <div className="flex flex-col gap-2 justify-end">
                {dayOverrides[date].map((timeRange, index) => (
                  <div key={index} className="flex items-center gap-2 mt-auto">
                    <Controller
                      name={`day_overrides.${date}.${index}.start_date`}
                      control={control}
                      render={({ field }) => (
                        <TimePicker
                          use12Hours
                          format={format}
                          allowClear={false}
                          value={dayjs(field.value)}
                          onChange={(time) => {
                            field.onChange(
                              time
                                ? time?.format("YYYY-MM-DDTHH:mm:ssZ")
                                : null,
                            )
                            setValue(
                              `day_overrides.${date}.${index}.end_date`,
                              time?.format("YYYY-MM-DDTHH:mm:ssZ"),
                              { shouldDirty: true },
                            )
                          }}
                        />
                      )}
                    />

                    <Controller
                      name={`day_overrides.${date}.${index}.end_date`}
                      control={control}
                      render={({ field }) => (
                        <TimePicker
                          use12Hours
                          format={format}
                          allowClear={false}
                          value={dayjs(field.value)}
                          onChange={(time) =>
                            field.onChange(
                              time
                                ? time?.format("YYYY-MM-DDTHH:mm:ssZ")
                                : null,
                            )
                          }
                          disabledTime={() => {
                            const startTime = dayjs(
                              getValues(
                                `day_overrides.${date}.${index}.start_date`,
                              ),
                            )
                            if (!startTime.isValid()) return {}
                            return {
                              disabledHours: () =>
                                Array.from(
                                  { length: 24 },
                                  (_, hour) => hour,
                                ).filter((hour) => hour < startTime.hour()),
                              disabledMinutes: (selectedHour) => {
                                if (selectedHour === startTime.hour()) {
                                  return Array.from(
                                    { length: 60 },
                                    (_, minute) => minute,
                                  ).filter(
                                    (minute) => minute < startTime.minute(),
                                  )
                                }
                                return []
                              },
                            }
                          }}
                        />
                      )}
                    />
                  </div>
                ))}
              </div>

              <div className="flex items-center gap-2 justify-end w-full">
                <div className="">Full Day</div>
                <Switch
                  onChange={(checked) => handleFullDayToggle(date, checked)}
                />
              </div>

              <div
                className={clsx(
                  "bg-[#F6F6F6] ring-1 rounded-[5px] ring-[#D9D9D9] flex justify-center mt-auto",
                  {
                    "text-darkGrey cursor-not-allowed":
                      dayOverrides[date]?.length > 2,
                    "cursor-pointer": !(dayOverrides[date]?.length > 2),
                  },
                )}
                onClick={
                  dayOverrides[date]?.length > 2
                    ? undefined
                    : () => addMoreTimeRange(date)
                }
              >
                + add more
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const format = "hh:mm a"

export default CalendarOverrideSettings
