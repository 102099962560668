import React from "react"
import {
  Astroseed,
  GreenNebula,
  Moon,
  SolarExplorer,
  StartMaster,
  Teraformer,
} from "../../../assets/images"
import { Button, Image, Progress, Skeleton, Tag } from "antd"
import { useNavigate } from "react-router-dom"
import {
  ClockCircleOutlined,
  PlayCircleOutlined,
  ReadOutlined,
} from "@ant-design/icons"
import { useLearning } from "../hooks/useLearning"

interface LevelTabsProps {
  levelDatas?: any
  skillID?: string
  skillName?: string
  levelName?: string
  activeTab: number
  isCustomLoadingHook?: boolean
  account?: any
  isPreviousLevelPassed: boolean | undefined
  dataLevelAvailable: any
}

const LevelTabs: React.FC<LevelTabsProps> = ({
  levelDatas,
  skillID,
  skillName,
  levelName,
  isCustomLoadingHook,
  account,
  activeTab,
  dataLevelAvailable,
}) => {
  const bs = levelDatas?.levels[activeTab - 1]?.user_track?.is_assessment_passed

  const data = levelDatas?.levels[activeTab]
  const navigate = useNavigate()
  const levelID = data?.id
  const currentModule = data?.user_track?.current_module

  const { update, modules: moduleData } = useLearning(skillID, account, levelID)

  const handleToLearning = (type: string) => {
    if (type === "start") {
      const data = {
        current_module: 1,
      }
      update(data)
      navigate(
        `/learnings/modules/${skillID}/${skillName}/${levelName}/${levelID}/1/`,
      )
    } else {
      navigate(
        `/learnings/modules/${skillID}/${skillName}/${data?.name}/${levelID}/${currentModule}`,
      )
    }
  }

  const proficiencyMapping: Record<string, any> = {
    1: {
      name: "Astroseed",
      image: Astroseed,
      description:
        "Get acquainted with the core terminology and fundamental concepts of the skill.",
    },
    2: {
      name: "Solar Explorer",
      image: SolarExplorer,
      description:
        "Take your skill to the next level by building on the foundation from Level 1. You will also be able to apply basic concepts on the job.",
    },
    3: {
      name: "Terraformer",
      image: Teraformer,
      description:
        "Attain a robust knowledge base, so that you can perform on-the-job tasks independently or with minimal supervision.",
    },
    4: {
      name: "Green Nebula",
      image: GreenNebula,
      description:
        "Achieve an advanced understanding of the skill area, reaching a level of expertise.",
    },
    5: {
      name: "Star Master",
      image: StartMaster,
      description:
        "Attain true mastery in the area, even potentially becoming a trainer and mentor to others!",
    },
  }

  const lookLevelData =
    dataLevelAvailable &&
    dataLevelAvailable?.data &&
    dataLevelAvailable?.data[0]?.levels[activeTab]

  function getConditionLevelInfo(data: any): {
    label: string
    backgroundColor: string
    color: string
    icon: any
  } {
    if (!lookLevelData?.available_for_sale) {
      return {
        icon: Moon,
        label: "Coming soon",
        backgroundColor: "#36505E",
        color: "#fff",
      }
    } else if (
      lookLevelData?.available_for_sale &&
      !lookLevelData?.purchased_by_user
    ) {
      return {
        icon: null,
        label: "Not Purchased",
        backgroundColor: "#36505E",
        color: "#fff",
      }
    } else if (data?.user_track?.current_module === 0) {
      return {
        icon: null,
        label: "Not Started",
        backgroundColor: "#E65E61",
        color: "#fff",
      }
    } else if (data?.user_track?.is_assessment_passed) {
      return {
        icon: null,
        label: "Completed",
        backgroundColor: "#D4F5C5",
        color: "#36505E",
      }
    } else {
      return {
        icon: null,
        label: "In Progress",
        backgroundColor: "#FFEFA0",
        color: "#36505E",
      }
    }
  }

  function getConditionButtonInfo(data: any): {
    label: string
    backgroundColor: string
    color: string
    isAvailable: boolean
  } {
    if (!data || (!bs && bs !== undefined)) {
      return {
        label: "Start Learnings",
        backgroundColor: "#DEDEDE",
        color: "#a8a8a8",
        isAvailable: false,
      }
    } else if (data?.user_track?.current_module === 0) {
      return {
        label: "Start Learning",
        backgroundColor: "#86C769",
        color: "#FFF",
        isAvailable: true,
      }
    } else if (data?.user_track?.is_assessment_passed) {
      return {
        label: "Review Materials",
        backgroundColor: "#FFF",
        color: "#36505E",
        isAvailable: true,
      }
    } else {
      return {
        label: "Continue Learning",
        backgroundColor: "#86C769",
        color: "#FFF",
        isAvailable: true,
      }
    }
  }

  function getConditionTestResultInfo(data: any): {
    label: string
    color: string
  } {
    if (
      data?.user_track?.current_module === 5 &&
      data?.user_track?.is_assessment_passed
    ) {
      return {
        label: `${data?.user_track?.score_assessment}%`,
        color: "#86C769",
      }
    } else if (
      data?.user_track?.current_module === 5 &&
      !data?.user_track?.is_assessment_passed
    ) {
      return {
        label: `${data?.user_track?.score_assessment}%`,
        color: "#E65E61",
      }
    } else {
      return { label: "test not taken", color: "#36505E" }
    }
  }

  const { icon, label, backgroundColor, color } = getConditionLevelInfo(data)
  const { label: labelIsPassed, color: colorIsPassed } =
    getConditionTestResultInfo(data)
  const {
    label: labelButton,
    backgroundColor: bgButton,
    color: colorTextButton,
    isAvailable,
  } = getConditionButtonInfo(data)

  function parseHumanReadableDuration(duration: any) {
    const regex = /(?:\s*(\d+)\s*h)?\s*(\d+)\s*m/
    const match = duration?.match(regex)

    if (!match) {
      return { hours: 0, minutes: 0 }
    }

    const hours = match[1] ? parseInt(match[1]) : 0
    const minutes = match[2] ? parseInt(match[2]) : 0

    return { hours, minutes }
  }

  function addHumanReadableDurations(duration1: any, duration2: any) {
    const parsedDuration1 = parseHumanReadableDuration(duration1)
    const parsedDuration2 = parseHumanReadableDuration(duration2)

    const totalHours = parsedDuration1.hours + parsedDuration2.hours
    const totalMinutes = parsedDuration1.minutes + parsedDuration2.minutes

    const resultHours = Math.floor(totalMinutes / 60) + totalHours
    const resultMinutes = totalMinutes % 60

    return { hours: resultHours, minutes: resultMinutes }
  }

  const totalLevelDuration = addHumanReadableDurations(
    lookLevelData?.video_metadata?.human_readable_duration,
    lookLevelData?.text_content_metadata?.human_readable_duration,
  )

  // MODULE
  function formatDurationVideo(seconds: any) {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)

    if (hours > 0) {
      return `${hours}h ${minutes}m`
    } else {
      return `${minutes}m`
    }
  }

  return (
    <div className="lg:p-5">
      {isCustomLoadingHook ? (
        <div className={`flex items-center flex-col gap-12`}>
          <Skeleton active paragraph={{ rows: 2 }} />
          <Skeleton active paragraph={{ rows: 2 }} />
          <Skeleton active paragraph={{ rows: 4 }} />
          <Skeleton active paragraph={{ rows: 3 }} />
        </div>
      ) : (
        <>
          <div className="lg:flex items-center gap-5 mb-4">
            <div className={`w-full`}>
              <div className={`flex items-center justify-between`}>
                <p className="leading-6 w-8/12">
                  {proficiencyMapping[activeTab + 1]?.description}
                </p>

                {isCustomLoadingHook ? (
                  <Skeleton.Button active />
                ) : (
                  <Button
                    onClick={
                      labelButton === "Start Learning"
                        ? () => handleToLearning("start")
                        : () => handleToLearning("continue")
                    }
                    disabled={!isAvailable}
                    className={`border-primary ${
                      !isAvailable ? "cursor-not-allowed" : ""
                    }`}
                    style={{
                      backgroundColor: bgButton,
                      color: colorTextButton,
                    }}
                  >
                    {labelButton}
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className={`hidden lg:inline mb-6`}>
            <Tag
              className={`space-x-3 border-none rounded-full py-1 px-4`}
              style={{ backgroundColor, color }}
            >
              {icon != null ? (
                <Image
                  className={`mr-2`}
                  width={20}
                  src={icon}
                  alt={`moon-image`}
                />
              ) : null}{" "}
              {label}
            </Tag>
          </div>
          <div className={`hidden lg:inline space-y-2`}>
            <div className={`grid grid-cols-12 items-center`}>
              <div className={`col-span-2`}>
                <p className={`m-0 p-0`}>Level Progress:</p>
              </div>
              <div className={`col-span-2 mt-2`}>
                <Progress
                  showInfo={false}
                  percent={(data?.user_track?.current_module * 100) / 5}
                  strokeColor={"#86C769"}
                />
              </div>
            </div>
            <div className={`grid grid-cols-12 items-center`}>
              <div className={`col-span-2`}>
                <p className={`m-0 p-0`}>Test result:</p>
              </div>
              <div className={`col-span-2`}>
                <p className={`m-0 p-0`} style={{ color: colorIsPassed }}>
                  {labelIsPassed}
                </p>
              </div>
            </div>
          </div>
          {lookLevelData?.available_for_sale ? (
            <>
              <div className={`hidden lg:inline  mb-8`}>
                <p className={`font-semibold`}>Duration</p>
                <div className={`flex items-center gap-5`}>
                  <div className={`flex items-center gap-2`}>
                    <PlayCircleOutlined />
                    <p className={``}>
                      {lookLevelData?.video_metadata?.human_readable_duration}
                    </p>
                  </div>
                  <div className={`flex items-center gap-2`}>
                    <ReadOutlined />
                    <p className={``}>
                      {
                        lookLevelData?.text_content_metadata
                          ?.human_readable_duration
                      }
                    </p>
                  </div>
                  <div className={`flex items-center gap-2`}>
                    <ClockCircleOutlined />
                    <p className={``}>{`${
                      totalLevelDuration?.hours === 0
                        ? ""
                        : totalLevelDuration?.hours
                    } ${totalLevelDuration?.minutes}m`}</p>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          <div className="hidden lg:inline mb-12">
            {data?.learning_outcome ? (
              <>
                <p className={`font-semibold`}>Learning Objectives</p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.learning_outcome,
                  }}
                ></div>
              </>
            ) : null}
          </div>

          <div
            className={`flex items-center justify-between lg:hidden mt-3 mb-5 `}
          >
            <div className="hidden md:inline">
              {lookLevelData?.available_for_sale ? (
                <>
                  <div className={`my-4 w-full`}>
                    <div className={`flex items-center gap-5`}>
                      <div className={`flex items-center gap-2`}>
                        <PlayCircleOutlined />
                        <p className={``}>
                          {
                            lookLevelData?.video_metadata
                              ?.human_readable_duration
                          }
                        </p>
                      </div>
                      <div className={`flex items-center gap-2`}>
                        <ReadOutlined />
                        <p className={``}>
                          {
                            lookLevelData?.text_content_metadata
                              ?.human_readable_duration
                          }
                        </p>
                      </div>
                      <div className={`flex items-center gap-2`}>
                        <ClockCircleOutlined />
                        <p className={``}>{`${
                          totalLevelDuration?.hours === 0
                            ? ""
                            : totalLevelDuration?.hours
                        } ${totalLevelDuration?.minutes}m`}</p>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>

            <div className="flex items-center gap-0 md:gap-10  !justify-between md:justify-normal w-full md:w-auto">
              <div className="flex items-center gap-3">
                <p className={`m-0 p-0`}>Test result:</p>
                <p className={`m-0 p-0`} style={{ color: colorIsPassed }}>
                  {labelIsPassed}
                </p>
              </div>

              <div className={``}>
                <Tag
                  className={`space-x-3 border-none rounded-full py-1 px-4`}
                  style={{ backgroundColor, color }}
                >
                  {icon != null ? (
                    <Image
                      className={`mr-2`}
                      width={20}
                      src={icon}
                      alt={`moon-image`}
                    />
                  ) : null}{" "}
                  {label}
                </Tag>
              </div>
            </div>
          </div>

          <div className={`space-y-3`}>
            {moduleData?.data?.detail?.modules?.map(
              (item: any, index: number) => {
                const totalDuration = item?.lessons?.reduce(
                  (total: number, moduleItem: any) => {
                    return (
                      total +
                      (moduleItem?.video !== null
                        ? moduleItem?.video?.duration
                        : 0)
                    )
                  },
                  0,
                )

                const videoModuleDuration = formatDurationVideo(totalDuration)

                const totalModuleDuration = addHumanReadableDurations(
                  videoModuleDuration,
                  item?.text_content_metadata?.human_readable_duration,
                )

                return (
                  <div
                    key={index}
                    className={`p-5 rounded-xl bg-gray-100`}
                    style={{
                      border: "1px solid #dedede",
                      backgroundColor:
                        data?.user_track?.current_module === 0
                          ? "#F5F5F5"
                          : index === data?.user_track?.current_module - 1
                            ? "#FFEFA0"
                            : index < data?.user_track?.current_module
                              ? "#D4F5C5"
                              : "#F5F5F5",
                    }}
                  >
                    <div
                      className={`flex items-center justify-between flex-wrap md:flex-nowrap w-full gap-y-2`}
                    >
                      <p
                        className={`w-full text-center md:w-auto md:text-left m-0 text-gray-400 uppercase`}
                      >
                        {item?.name}
                      </p>
                      <Tag
                        className={`bg-white px-2 py-2 flex items-center justify-around rounded-full flex-1 md:flex-none md:gap-10 border-none md:w-auto md:text-left`}
                      >
                        <div className={`flex items-center gap-2`}>
                          <PlayCircleOutlined />
                          <p className={`m-0`}>{videoModuleDuration}</p>
                        </div>
                        <div className={`flex items-center gap-2`}>
                          <ReadOutlined />
                          <p className={`m-0`}>
                            {
                              item?.text_content_metadata
                                ?.human_readable_duration
                            }
                          </p>
                        </div>
                        <div className={`flex items-center gap-2`}>
                          <ClockCircleOutlined />
                          <p className={`m-0`}>{`${
                            totalModuleDuration?.hours === 0
                              ? ""
                              : totalModuleDuration?.hours
                          } ${totalModuleDuration?.minutes}m`}</p>
                        </div>
                      </Tag>
                    </div>
                    <p className={`text-center md:text-left capitalize`}>
                      {item?.overview?.title}
                    </p>
                  </div>
                )
              },
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default LevelTabs
