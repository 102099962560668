import React from "react"
import { BasicContainerWithTitle } from "../../../../common/components"
import { Button, ButtonProps } from "antd"
import { BellOutlined } from "@ant-design/icons"
import { Role } from "../stores/useRoleStore"

interface ProfileRoleDescriptionProps {
  title: string
  description: string
  actionButtonProps: ButtonProps
  isRole: boolean
  role: Role
  isRolePending: boolean
}

const ProfileRoleDescription: React.FC<ProfileRoleDescriptionProps> = ({
  title,
  description,
  actionButtonProps,
  isRole,
  isRolePending,
  role,
}) => {
  return (
    <BasicContainerWithTitle title={title}>
      <div className="mt-3">{description}</div>
      {!isRole && !isRolePending && (
        <Button
          className="bg-primary text-white w-full mt-5"
          {...actionButtonProps}
        >
          {actionButtonProps?.children}
        </Button>
      )}
      {isRolePending && (
        <div className="bg-secondaryYellow text-backdrop p-[10px] rounded-md flex items-center justify-center mt-5 gap-4">
          <BellOutlined className="text-sm" />
          <div className="">Your {role} application is under review</div>
        </div>
      )}
    </BasicContainerWithTitle>
  )
}

export default ProfileRoleDescription
