import HeroSection from "../components/HeroSection"
import CVSection from "../components/CVSection"
// import InterestSection from "../components/InterestSection"
// import FeatureSection from "../components/FeatureSection"
import ExpertSection from "../components/ExpertSection"

import HiringSection from "../components/HiringSection"

import PerkSection from "../components/PerkSection"
import UniverseSection from "../components/UniverseSection"
import BarSection from "../components/BarSection"

const LandingPage = () => {
  return (
    <div className="w-full">
      <HeroSection />
      <BarSection />
      <div id="expert">
        <ExpertSection />
      </div>
      <div id="skill">
        <UniverseSection />
      </div>
      {/* <FeatureSection /> */}
      <div id="hiring">
        <HiringSection />
      </div>
      <div id="cv">
        <CVSection />
      </div>
      <div id="perk">
        <PerkSection />
      </div>
    </div>
  )
}

export default LandingPage
