import React, { useEffect, useState } from "react"
import { useLaunchpad } from "../hooks/useLaunchpad"
import { WordCloud } from "@ant-design/plots"

import {
  ShareAltOutlined,
  // UserAddOutlined,
  // PushpinOutlined,
  // HeartOutlined,
  MailOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  LinkOutlined,
  BankOutlined,
  BookOutlined,
  // InfoCircleOutlined,
} from "@ant-design/icons"
import { Tag, Timeline, Avatar, message, Empty, Divider } from "antd"
import dayjs from "dayjs"
import { useProfile } from "../hooks/useProfile"
import SquareRate from "../../../common/components/SquareRate"
import { PinMap, Moon } from "../../../assets/images"
import useRoadmapSkills from "../hooks/useRoadmapSkills"
import { useMsal } from "@azure/msal-react"
import FiveCircleRate from "../../../common/components/FiveCircleRate"

export const CVDetailLauchpad = () => {
  const { wordCloud, getPeriode } = useLaunchpad()
  const { account } = useProfile()
  const [config, setConfig] = useState({
    data: [],
    wordField: "name",
    weightField: "total",
    colorField: "name",
    wordStyle: {
      fontFamily: "Sen",
      fontSize: [8, 32],
      rotation: 0,
    },
  })
  useEffect(() => {
    const skillsEdu =
      account?.data?.data?.education?.map((item) => item?.skills || []) || []
    const skillsHistory =
      account?.data?.data?.employment_history?.map(
        (item) => item.skills || [],
      ) || []
    const skillCertif =
      account?.data?.data?.certification?.map((item) => item.skills || []) || []
    setConfig((prev) => ({
      ...prev,
      data: wordCloud([...skillsEdu, ...skillCertif, ...skillsHistory]),
    }))
  }, [account?.data?.data])

  const { accounts } = useMsal()
  const isLoggedIn = accounts?.length > 0
  const { roadmapSkills } = useRoadmapSkills(isLoggedIn)

  const countLevelPassed = (levels = []) => {
    const isHaveInProgress = levels?.find(
      (x) => !x?.user_track?.is_assessment_passed,
    )
    if (isHaveInProgress) {
      return isHaveInProgress?.proficiency - 1
    } else {
      return levels[levels.length - 1]?.proficiency
    }
  }

  return (
    <div>
      <div className="p-5 bg-[#36505E] my-3 text-white">
        <div className="text-white mb-3">Green skills CV</div>
        <div className="grid grid-cols-7 gap-3">
          {/* <img src={avatarImgs[0]} alt="profile" className="h-32 w-32 rounded-full" /> */}
          {account?.data?.data?.profile_picture ? (
            <img
              src={`${
                account?.data?.data?.profile_picture
              }?${new Date().getTime()}`}
              alt="profile"
              className="h-[100px] w-[100px] rounded-full object-contain"
              style={{ border: "1px solid #D4F5C5" }}
            />
          ) : (
            <Avatar
              src={account?.data?.data?.avatar}
              size={100}
              className=" self-center"
            />
          )}
          <div className=" col-span-6 ">
            <div>
              <div className="font-bold text-lg">{`${account?.data?.data?.first_name} ${account?.data?.data?.last_name}`}</div>
              <div className=" font-light">
                {account?.data?.data?.about_me ||
                  "It seems you are not skilled in writing about yourself."}
              </div>
            </div>
            <div className="flex justify-between w-60 mt-5">
              <small
                className="flex gap-1 cursor-pointer"
                onClick={() => {
                  message.info("Link Copied!")
                  navigator.clipboard.writeText(
                    `${process.env.REACT_APP_URL}/talent-marketplace/id/${account?.data?.data?.id}`,
                  )
                }}
              >
                <ShareAltOutlined />
                Share
              </small>
              {/* <small>|</small>
              <small className="flex gap-1 cursor-pointer">
                <HeartOutlined />
                Favorite
              </small>
              <small>|</small>
              <small className="flex gap-1 cursor-pointer">
                <UserAddOutlined />
                Add to ATS
              </small> */}
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-3">
        <div>
          <div className=" bg-white">
            <div className="p-5 " style={{ borderBottom: "1px solid #D9D9D9" }}>
              <div className="font-semibold border mb-3">
                Contact and social media
              </div>
              <div className="flex items-center gap-2">
                <img src={PinMap} alt="pin map" className="w-3 h-3" />
                {account?.data?.data?.country || "Unknown"}
              </div>
            </div>
            <div className="p-5 flex justify-around">
              <MailOutlined
                className=" hover:text-primary"
                onClick={() => {
                  window.open(`mailto:${account?.data?.data?.links?.email}`)
                }}
              />
              <a
                href={account?.data?.data?.links?.linkedin}
                target={account?.data?.data?.links?.linkedin && "_blank"}
                rel="noreferrer"
              >
                <LinkedinOutlined className=" hover:text-primary" />
              </a>
              <a
                href={account?.data?.data?.links?.twitter}
                target={account?.data?.data?.links?.twitter && "_blank"}
                rel="noreferrer"
              >
                <TwitterOutlined className=" hover:text-primary" />
              </a>

              <a
                href={account?.data?.data?.links?.link}
                target={account?.data?.data?.links?.link && "_blank"}
                rel="noreferrer"
              >
                <LinkOutlined className=" hover:text-primary" />
              </a>
            </div>
          </div>
          <div className="p-5  bg-white mt-3">
            <div className="font-semibold border mb-3">Green Skills</div>
            <div className="">
              <p className="m-0 p-0 first-letter:capitalize">proficient in</p>
              <Divider className="my-2 mx-0 p-0" />

              <div>
                {roadmapSkills?.data?.data ? (
                  roadmapSkills?.data?.data?.filter((item) =>
                    item.levels.some(
                      (level) => level.user_track.is_assessment_passed,
                    ),
                  ).length !== 0 ? (
                    roadmapSkills?.data?.data
                      ?.filter((item) =>
                        item.levels.some(
                          (level) => level.user_track.is_assessment_passed,
                        ),
                      )
                      .map((item, i) => (
                        <div
                          key={i}
                          className={`flex items-start justify-between ${
                            i !== 0 ? "mt-2" : ""
                          }`}
                        >
                          <div className=" max-w-[190px]">{item?.name}</div>
                          <div className="flex items-center gap-1">
                            <FiveCircleRate
                              defaultValue={countLevelPassed(item?.levels)}
                              disabled={true}
                              circleSize={15}
                            />
                          </div>
                        </div>
                      ))
                  ) : (
                    <Empty
                      image={Moon}
                      imageStyle={{
                        height: "40px",
                        width: "40px",
                        alignContent: "center",
                      }}
                      rootClassName="!flex !flex-col items-center !text-gray-400 !pb-5"
                      description={
                        <div className="!text-xs text-darkGrey">
                          Learning in progress...
                        </div>
                      }
                    />
                  )
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Green Skills will be updated here every time you pass the proficiency test in skill courses"
                  />
                )}
              </div>
            </div>
            <div className="mt-7">
              <p className=" m-0 p-0 first-letter:capitalize">interested in</p>
              <Divider className="my-2 mx-0 p-0" />
              {account?.data?.data?.favorite_skills?.length <= 0 ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              ) : (
                account?.data?.data?.favorite_skills?.map((item, i) => (
                  <Tag key={i} color="orange" className=" truncate">
                    <div className=" truncate max-w-[250px]">{item?.name}</div>
                  </Tag>
                ))
              )}
            </div>
          </div>
          <div className="p-5  bg-white mt-3">
            <div className="font-semibold border mb-3">Languages</div>
            <div className="">
              {account?.data?.data?.languages?.length <= 0 ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              ) : (
                account?.data?.data?.languages?.map((item, i) => (
                  <div key={i} className="grid grid-cols-2 items-center gap-1">
                    <div>{item.name}</div>
                    <SquareRate value={item.proficiency_level} />
                    {/* <Rate
                    allowHalf
                    character={<div style={{ fontSize: 36 }}>■</div>}
                    value={item.proficiency_level}
                    disabled
                  /> */}
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="p-5  bg-white mt-3">
            <div className="font-semibold border mb-3">Other Skills</div>
            <div className="">
              {config?.data?.length <= 0 ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              ) : (
                <WordCloud {...config} style={{ height: "200px" }} />
              )}
            </div>
          </div>
        </div>
        <div className=" col-span-2">
          <div className="p-5  bg-white">
            <div className="font-semibold border mb-3 text-lg">
              <BankOutlined
                className="mr-3 rounded-full p-1"
                style={{ border: "1px solid" }}
              />
              Work Experience
            </div>
            {account?.data?.data?.employment_history?.length <= 0 ? (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
              <Timeline
                style={{
                  padding: "10px",
                }}
                mode={"left"}
                items={account?.data?.data?.employment_history?.map(
                  (item, i) => ({
                    color: "gray",
                    label: (
                      <div>
                        key={i}
                        <div>{`${dayjs(item.date.start_at).format(
                          "MMM YYYY",
                        )} - ${
                          item.date.end_at
                            ? dayjs(item.date.end_at).format("MMM YYYY")
                            : "Present"
                        }`}</div>
                        <div>
                          {getPeriode(
                            dayjs(
                              item?.date?.end_at
                                ? item?.date?.end_at
                                : Date.now(),
                            )
                              .startOf("month")
                              .diff(
                                dayjs(item?.date?.start_at).startOf("month"),
                                "month",
                              ),
                          )}
                        </div>
                      </div>
                    ),
                    children: (
                      <div>
                        <div className=" capitalize text-lg">
                          {item.job_title}
                        </div>
                        <div className=" text-slate-400">
                          {`${item.company_name}, ${item?.country?.name} (${
                            item?.remote ? "remote" : "on-site"
                          })`}
                        </div>
                        <div>{item.description}</div>
                      </div>
                    ),
                  }),
                )}
              />
            )}
          </div>
          <div className="p-5 mt-3 bg-white">
            <div className="font-semibold border mb-3 text-lg">
              <BankOutlined
                className="mr-3 rounded-full p-1"
                style={{ border: "1px solid" }}
              />
              Education
            </div>
            {account?.data?.data?.education?.length <= 0 ? (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
              <Timeline
                style={{
                  padding: "10px",
                }}
                mode={"left"}
                items={account?.data?.data?.education?.map((item, i) => ({
                  color: "gray",
                  label: (
                    <div key={i}>
                      <div>{`${dayjs(item.date.start_at).format(
                        "MMM YYYY",
                      )} - ${
                        item.date.end_at
                          ? dayjs(item.date.end_at).format("MMM YYYY")
                          : "Present"
                      }`}</div>
                      <div>
                        {getPeriode(
                          dayjs(
                            item?.date?.end_at
                              ? item?.date?.end_at
                              : Date.now(),
                          )
                            .startOf("month")
                            .diff(
                              dayjs(item?.date?.start_at).startOf("month"),
                              "month",
                            ),
                        )}
                      </div>
                    </div>
                  ),
                  children: (
                    <div>
                      <div className=" capitalize text-lg">{item.degree}</div>
                      <div className=" text-slate-400">
                        {`${item.educational_educations}, ${
                          item?.country?.name
                        } (${item?.online ? "online" : "offline"})`}
                      </div>
                      {item.skills?.map((el, i) => (
                        <Tag key={i} color="orange" className="mt-1">
                          {el.name}
                        </Tag>
                      ))}
                    </div>
                  ),
                }))}
              />
            )}
          </div>
          <div className="p-5 mt-3 bg-white">
            <div className="font-semibold border mb-3 text-lg">
              <BookOutlined
                className="mr-3 rounded-full p-1"
                style={{ border: "1px solid" }}
              />
              Certifications
            </div>
            <>
              {account?.data?.data?.certification?.length <= 0 ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              ) : (
                account?.data?.data?.certification?.map((item, i) => (
                  <div key={i} className="px-10 mb-3">
                    <div className="flex gap-5">
                      <div className=" capitalize text-lg truncate">
                        {item.certification_name}
                      </div>
                      {item.credentials_url && (
                        <a
                          href={
                            item.credentials_url.startsWith("http")
                              ? item.credentials_url
                              : `https://${item.credentials_url}`
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          <LinkOutlined className=" cursor-pointer  hover:text-primary" />
                        </a>
                      )}
                    </div>
                    <div>{item.issuing_organization}</div>
                    <div>{`Expires: ${
                      item.has_expiry_date
                        ? dayjs(item?.date?.end_at).format("DD MMM YYYY")
                        : "doesn't expire"
                    }`}</div>
                  </div>
                ))
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  )
}
