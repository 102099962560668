import { useQuery } from "react-query"
import { instanceV2 } from "../../../../config/config"
import { ApiResponse } from "../../../../common/types/ApiResponse"
import { useMsal } from "@azure/msal-react"
import { MyJobRoles } from "../../../missionControl/hooks/common_type"
import { errorMessage } from "../../../../common/utils/utils"

export const useJobRoleCareerAspiration = () => {
  const { instance: user } = useMsal()
  const activeAccount = user.getActiveAccount()
  const { data, isLoading, isFetching, isError } = useQuery<
    ApiResponse<MyJobRoles[]>
  >(
    ["job-role-aspiration"],
    () => instanceV2.get(`/launchpad/career-aspirations`),
    {
      onError: (err: any) =>
        errorMessage(err?.response?.data?.data || "internal server error", err),
      enabled: !!activeAccount?.localAccountId,
    },
  )
  return {
    data: data?.data?.data,
    isLoading: isLoading || isFetching,
    isError,
  }
}
