import {
  ApiResponseGeneric,
  ApiResponsePaginatedInfinite,
} from "../../../../common/types/ApiResponse"
import instance from "../../../../config/config"
import { OAuthProvider } from "../../../../utils/connect-account"
import { handlingError } from "../../../../utils/handling"
import { Item } from "../../../skillGalaxy/types/common"
import {
  Mentees,
  MentorAvailabilityResponse,
  MentorMenteeSession,
  MentorResponse,
} from "../../mentor/profile/types/response"

export const fetchMentorDetails = (id: string, queryParams?: any) => {
  return instance.get<ApiResponseGeneric<MentorResponse>>(
    `user-mentors/${id}`,
    {
      params: queryParams,
    },
  )
}

export const updateMentorProfile = (data: any) => {
  return instance.patch(`/user-mentors`, data)
}

export const updateAdvanceMentorProfile = (data: any) => {
  return instance.patch(`/user-mentors/adv-profile`, data)
}

export const fetchMentorDomains = () => {
  return instance.get<ApiResponseGeneric<Item[]>>(
    "user-mentors/reference-domain",
  )
}

export const fetchMentorAreas = () => {
  return instance.get<ApiResponseGeneric<Item[]>>("user-mentors/areas")
}

// mentor availability
export const getMentorAvailabilityList = () => {
  return instance.get<ApiResponseGeneric<MentorAvailabilityResponse>>(
    "mentor-settings/availabilities",
  )
}
export const createAvailability = (data: any) => {
  return instance.post(`/mentor-settings/availabilities`, data)
}

export const updateAvailability = (data: any) => {
  return instance.patch(`/mentor-settings/availabilities`, data)
}

export const getMentorAvailability = (dayId: string, timeId: string) => {
  return instance.get<ApiResponseGeneric<any>>(
    `mentor-settings/availabilities/days/${dayId}/extend-times/${timeId}`,
  )
}

export const extendMentorAvailability = (dayId: string, timeId: string) => {
  return instance.patch(
    `mentor-settings/availabilities/days/${dayId}/extend-times/${timeId}`,
  )
}

// Connecting with Mentor Calendar

const createConnectUrl = async (provider: OAuthProvider) => {
  try {
    const { data } = await instance.post(
      `mentor-settings/oauth/${provider}/connect`,
    )
    // return data
    console.log("createConnectUrl", data)
    window.location.href = data?.data!
  } catch (error: any) {
    return handlingError(error?.response?.data?.message, error)
  }
}

export const connectAccount = async (provider: OAuthProvider) => {
  if (provider) {
    createConnectUrl(provider)
  }
}

export const fetchMentorMentees = async () => {
  return instance.get<ApiResponseGeneric<Mentees>>(`mentor-settings/mentees`)
}

export const getMentorBookings = () => {
  return instance.get<ApiResponsePaginatedInfinite<MentorMenteeSession>>(
    "mentor-settings/schedules",
  )
}

export const cancelBooking = (bookingId: string, data: { reason: string }) => {
  return instance.post<any>(`mentor-schedules/${bookingId}/cancel`, data)
}
