import { useMemo } from "react"
import { useQuery } from "react-query"
import instance from "../../../config/config"
import { handlingError } from "../../../utils/handling"

const useHistoryJobs = (userId) => {
  const queryKey = useMemo(
    () => [
      "job-history-list",
      {
        //   company_ids,
        //   job_role_id,
      },
    ],
    [],
  )

  const fetchListJobHistory = async () => {
    try {
      const { data } = await instance.get(`/job-boards/histories/${userId}`, {
        params: {
          // company_ids,
          // job_role_id,
        },
      })
      return data
    } catch (error) {
      return handlingError(error?.response?.data?.message, error)
    }
  }

  const { data, isLoading } = useQuery(queryKey, fetchListJobHistory)
  const listJobHistory = data?.data
  const isListJobHistoryLoading = isLoading
  return {
    listJobHistory,
    isListJobHistoryLoading,
  }
}

export default useHistoryJobs
