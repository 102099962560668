/* eslint-disable no-unused-vars */
import React from "react"
import ReactQuill, { ReactQuillProps } from "react-quill"
import "react-quill/dist/quill.snow.css"
import "quill/dist/quill.core.css"
import "quill/dist/quill.bubble.css"

const modules = {
  toolbar: [
    // [{ header: [1, 2, false] }, { font: [] }],
    // ["bold", "italic", "underline", "strike", "blockquote"],
    ["bold", "italic", "underline", "strike"],
    [
      { list: "ordered" },
      { list: "bullet" },
      // { indent: "-1" },
      // { indent: "+1" },
    ],
    // ["link", "image", "video"],
    // ["clean"],
    ["link"],
  ],
}

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
]

interface RichTextProps extends ReactQuillProps {
  rest?: any
}

export const RichTextMinimize: React.FC<RichTextProps> = ({ ...rest }) => {
  return (
    <ReactQuill
      style={{ borderRadius: "50px" }}
      className={`w-full rounded  ${rest?.className}`}
      placeholder={rest?.placeholder}
      modules={modules}
      formats={formats}
      // readOnly={disabled}
      // onBlur={onBlur}
      {...rest}
    />
  )
}
