import { useQuery } from "react-query"
import { getFounderProfile, getStartup } from "../apis/founders"
import { showError } from "../../../../common/utils/utils"

const useFounderAndStartupData = () => {
  const founderQuery = useQuery(["detail-founder"], () => getFounderProfile(), {
    onError: () =>
      showError({
        key: "founder-details",
        message:
          "We encountered an issue while attempting to fetch founder account details. If the problem continues, please refresh the page.",
      }),
  })

  const startupQuery = useQuery(
    ["startup"],
    () => getStartup(founderQuery?.data?.data?.data?.startup_id!),
    {
      enabled: !!founderQuery?.data?.data?.data?.startup_id,
      onError: () =>
        showError({
          key: "startup-details",
          message:
            "We encountered an issue while attempting to fetch startup details. If the problem continues, please refresh the page.",
        }),
    },
  )

  return {
    founderQuery,
    startupQuery,
  }
}

export default useFounderAndStartupData
