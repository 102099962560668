import React from "react"
import { Image, Popover, Progress } from "antd"
import FiveCircleRateTS from "./FiveCircleRateTS.tsx"
import { InfoCircleOutlined } from "@ant-design/icons"
import LevelInfoPopover from "../../modules/skillGalaxy/components/LevelInfoPopover.jsx"

interface PropsSkillProficiencyCard {
  imgSrc: string
  passedLevel: number
  levelProgress: number
  moduleProgress: number
  isNextLevelExist?: boolean
  rootClassName?: string
  levelName: string
}

const SkillProficiencyCard: React.FC<PropsSkillProficiencyCard> = ({
  imgSrc,
  passedLevel,
  levelProgress,
  moduleProgress,
  levelName,
  rootClassName,
}) => {
  return (
    <div
      className={`border-2 border-primary rounded-3xl px-10 space-y-1 ${rootClassName} `}
      style={{ border: "2px solid #86C769" }}
    >
      <div className={`flex items-center justify-between`}>
        <p className="">Your Skill Proficiency</p>
        <LevelInfoPopover>
          <InfoCircleOutlined className={`cursor-pointer`} />
        </LevelInfoPopover>
      </div>
      <div className={`flex items-center gap-7`}>
        <div>
          <Popover
            color={`#36505E`}
            content={
              <div className={`bg-backdrop m-0 p-0 text-white`}>
                {levelName}
              </div>
            }
          >
            <Image preview={false} src={imgSrc} width={35} />
          </Popover>
        </div>
        <div>
          <FiveCircleRateTS
            defaultValue={passedLevel}
            secondValue={levelProgress > 0}
            disabled
            circleSize={15}
            // color={`#ff9`}
          />
        </div>
      </div>
      <div>
        <Progress
          percent={moduleProgress}
          showInfo={false}
          strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
        />
      </div>
    </div>
  )
}

export default SkillProficiencyCard
