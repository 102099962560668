import React from "react"
import ListPublicArticleFilter from "./ListPublicArticleFilter"
import ListCardPublicArticle from "./ListCardPublicArticle"
import CardLoadingPublicArticle from "./CardLoadingPublicArticle"
import { Image } from "antd"
import { Moon } from "../../../../assets/images"

interface ListArticleContentProps {
  myArticles: any
  queryArticleResponse: any
  filterData: any
}

const ListArticleContent: React.FC<ListArticleContentProps> = ({
  myArticles,
  queryArticleResponse,
  filterData,
}) => {
  return (
    <div className="space-y-5">
      <div>
        <ListPublicArticleFilter
          queryArticleResponse={queryArticleResponse}
          filterData={filterData}
        />
      </div>
      <div className="py-7 px-4 bg-white-custom rounded-xl shadow-custom space-y-3">
        {myArticles?.isLoading ? (
          <>
            {myArticles?.dataList?.map((article: any, index: number) => (
              <ListCardPublicArticle
                key={index}
                article={article}
                queryArticleResponse={queryArticleResponse}
                noScroll={true}
              />
            ))}
            {Array.from({ length: 3 }, (_: any, index: number) => (
              <CardLoadingPublicArticle index={index} key={index} />
            ))}
          </>
        ) : myArticles?.dataList?.length === 0 ? (
          <div className="w-full text-center  py-6">
            <p className="my-8">Be the first to publish an article</p>
            <Image src={Moon} alt="moon image" preview={false} width={100} />
          </div>
        ) : (
          myArticles?.dataList?.map((article: any, index: number) => {
            return (
              <ListCardPublicArticle
                key={index}
                article={article}
                queryArticleResponse={queryArticleResponse}
              />
            )
          })
        )}
      </div>
      <div className="">
        <p className="text-center mt-10">{myArticles?.noMoreData}</p>
      </div>
    </div>
  )
}

export default ListArticleContent
