import { useQuery } from "react-query"
import instance from "../../../config/config"
import {
  // eslint-disable-next-line
  ApiResponse,
  // eslint-disable-next-line
  ApiResponseGenericWithArray,
} from "../../../common/types/ApiResponse"
import { message } from "antd"
import React from "react"
import ErrorMessageBuilder from "../../../common/components/ErrorMessageBuilder"

export type Perk = {
  app: string
  company_logo: string
  company_name: string
  created_at: string
  expire_at: string | null
  id: string
  offer_desc: string
  offer_link: string
  offer_title: string
  updated_at: string | null
}

const fetchPerksList = (): Promise<ApiResponseGenericWithArray<Perk>> => {
  return instance.get("/perks?app=talent")
}

const fetchPerkDetail = (id: string): Promise<ApiResponse<Perk>> => {
  return instance.get(`/perks/${id}`)
}

export const usePerks = () => {
  return useQuery("perks", fetchPerksList, {
    onError: () => {
      message.open({
        key: "perks",
        duration: 5,
        content: React.createElement(ErrorMessageBuilder, {
          message:
            "We encountered an issue while attempting to fetch SkilledIn Talent perks. If the problem continues, please refresh the page.",
          includeReportAt: true,
        }),
        type: "error",
      })
    },
    retry: 2,
  })
}

export const usePerkDetail = (id: string) => {
  return useQuery(["perks", id], () => fetchPerkDetail(id), {
    onError: () => {
      message.open({
        key: "perks-detail",
        duration: 5,
        content: React.createElement(ErrorMessageBuilder, {
          message:
            "We encountered an issue while attempting to fetch details for the given perk. If the problem continues, please refresh the page.",
          includeReportAt: true,
        }),
        type: "error",
      })
    },
    retry: 2,
  })
}
