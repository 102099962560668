import { useQuery } from "react-query"
import { fetchExpertDetails } from "../apis/experts"
import { message } from "antd"
import React, { useContext } from "react"
import ErrorMessageBuilder from "../../../../common/components/ErrorMessageBuilder"
import { AccountContext } from "../../../../common/layout/PageLayout"

const useExpertProfile = () => {
  const account = useContext(AccountContext)

  return useQuery(
    ["expert-details"],
    () =>
      fetchExpertDetails(account?.data?.data?.id ?? "", {
        user_login_id: account?.data?.data?.id,
      }),
    {
      enabled: !!account?.data?.data?.id,
      onError: () => {
        message.open({
          key: "expert-details",
          duration: 5,
          content: React.createElement(ErrorMessageBuilder, {
            message:
              "We encountered an issue while attempting to fetch expert details. If the problem continues, please refresh the page.",
            includeReportAt: true,
          }),
          type: "error",
        })
      },
      retry: 2,
    },
  )
}

export default useExpertProfile
