/* eslint-disable */

import { useQuery, useMutation, useQueryClient } from "react-query"
import instance from "../../../config/config"
import { ApiResponsePaginated } from "../../../common/types/ApiResponse"

export type Notification = {
  id: string
  user_id: string
  category: "gamification" | "job-galaxy"
  message_id: string
  status: string
  payload: string
  created_at: string
  updated_at: string
}

export const useGetNotifsFromDb = () => {
  return useQuery(
    "notifications",
    async () => {
      const response: Promise<ApiResponsePaginated<Notification>> =
        instance.get("/notifications", {
          params: {
            limit: 25,
          },
        })
      return response
    },
    {
      refetchOnWindowFocus: true,
      retry: () =>
        !(
          typeof instance?.defaults?.headers?.common?.["Authorization"] ===
          "string"
        ),
    },
  )
}

export const useSetNotifAsRead = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (id: string) => instance.patch(`/notifications/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["notifications"])
      },
    },
  )
}

export const useSetAllNotifsAsRead = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (ids: string[]) => {
      const formData = new FormData()
      ids.forEach((item) => {
        formData.append("id", item)
      })
      return instance.patch(`/notifications/mark-reads`, formData)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["notifications"])
      },
    },
  )
}
