/* eslint-disable */

import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import instance, { instanceV2 } from "../../../../config/config"
import { message } from "antd"
import { AxiosResponse } from "axios"
import { PaginatedResponse } from "../../../../common/types/ApiResponse"
import { debounce } from "../../../../utils/debounce"
import dayjs from "dayjs"
import { Certification, OtherOrganisation } from "./useCertification"
import { errorMessage } from "../../../../common/utils/utils"

export const useCertificationUpdate = (
  dataCertif: Certification,
  index: number,
  callback: (index: number, data: Certification) => void,
) => {
  const {
    handleSubmit,
    control,
    setValue: setValueForm,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      organisation_name: dataCertif?.organisation?.name,
      certification_name: dataCertif.certification_name,
      has_expiry_date: dataCertif.has_expiry_date,
      credentials_url: dataCertif.credentials_url,
      start_at: dataCertif.start_at ? dayjs(dataCertif.start_at) : null,
      end_at: dataCertif.end_at ? dayjs(dataCertif.end_at) : null,
    },
  })

  const [dataTemp, setDataTemp] = useState<OtherOrganisation | null>(null)
  const queryClient = useQueryClient()

  const {
    imageUrl,
    handleImageChange,
    handleRemoveImage,
    loadingRemove,
    loadingUpload,
  } = useImageOtherOrganisation(dataTemp)

  const {
    handleSearch,
    data,
    value,
    isOpen,
    setIsOpen,
    addNewOrganisation,
    setValue,
    isLoading,
  } = useOtherOrganisation()

  const handleAddOrganisation = (val: string) => {
    addNewOrganisation(val).then((res) => {
      if (res) {
        setDataTemp(res)
        setIsOpen(false)
        // setValue("");
        setValueForm("organisation_name", res.name)
      }
    })
  }
  const handleSelectOrganisation = (val: OtherOrganisation) => {
    setDataTemp(val)
    setIsOpen(false)
    setValue("")
    setValueForm("organisation_name", val.name)
  }

  const handleSearchOrganisation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setValueForm("organisation_name", value)
    handleSearch(e)
    setIsOpen(true)
  }

  const { mutateAsync: certif, isLoading: loadingCertif } = useMutation({
    mutationFn: async (val) => {
      try {
        const res = await instanceV2.post(`/accounts/certifications`, val)
        return res
      } catch (error) {
        throw error
      }
    },
    mutationKey: "account-certification",
  })

  const { mutateAsync: certifUpdate, isLoading: loadingUpdateCertif } =
    useMutation({
      mutationFn: async (val: any) => {
        try {
          const res = await instanceV2.patch(
            `/accounts/certifications/id/${val.id}`,
            val,
          )
          return res
        } catch (error) {
          throw error
        }
      },
      mutationKey: "account-certification-update",
    })

  const onSubmit = async (val: any) => {
    message.open({
      key: "update-certification",
      content: "updating...",
      type: "loading",
    })
    const form = {
      ...val,
      id: dataCertif?.id,
      organisation_id: dataTemp?.id,
      start_at: dayjs(val.start_at).format(),
      end_at: val.has_expiry_date ? null : dayjs(val.end_at).format(),
    }
    try {
      if (!dataTemp) {
        const responseOrganisation = await addNewOrganisation(
          val.organisation_name,
        )
        form.organisation_id = responseOrganisation?.id
      }
      let response: AxiosResponse<any, any>
      if (form?.id) {
        response = await certifUpdate(form)
      } else {
        response = await certif(form)
      }
      if (response) {
        const finalDataJob: Certification = {
          id: form?.id || response.data?.data,
          organisation: {
            id: dataTemp?.id || form.institution_id,
            name: dataTemp?.name || form.institution_name,
            image: imageUrl,
          },
          certification_name: form.certification_name,
          credentials_url: form.credentials_url,
          has_expiry_date: form.has_expiry_date,
          start_at: form.start_at,
          end_at: form.end_at,
          isEdit: false,
        }
        queryClient.invalidateQueries(["account-cv"]).then(() => {
          callback(index, finalDataJob)
          message.destroy()
        })
      }
    } catch (error: any) {
      message.open({
        key: "update-education",
        content: error?.response?.data?.data || "Internal server error",
        type: "error",
      })
    }
  }
  useEffect(() => {
    if (dataCertif) {
      setDataTemp(dataCertif.organisation)
    }
  }, [dataCertif])
  return {
    control,
    errors,
    handleSubmit,
    watch,
    onSubmit,
    loadingUpdateCertif: loadingUpdateCertif || loadingCertif,
    dataTemp,
    image: {
      imageUrl,
      handleImageChange,
      handleRemoveImage,
      loadingRemove,
      loadingUpload,
    },
    otherOrganisation: {
      handleSearch: handleSearchOrganisation,
      data,
      value,
      isOpen,
      setIsOpen,
      handleAddOrganisation,
      handleSelectOrganisation,
      isLoading,
    },
  }
}

const useOtherOrganisation = () => {
  const [value, setValue] = useState<string>("")
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [dataComp, setDataComp] = useState<OtherOrganisation[] | undefined>(
    undefined,
  )

  const { mutate, data, isLoading } = useMutation({
    mutationKey: "other-organisations",
    mutationFn: (params: any) => {
      return instance.get<AxiosResponse<PaginatedResponse<OtherOrganisation>>>(
        `/commons/other-organisations`,
        { params },
      )
    },
  })

  const handleSearch = debounce((val: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = val.target
    setValue(value)
    mutate(
      { name: value, page: 1, limit: 5 },
      {
        onError: (err: any) => {
          errorMessage(err?.response?.data?.data || "something wrong", err)
        },
      },
    )
  }, 500)

  const { mutateAsync: addNew } = useMutation({
    mutationKey: "other-organisations",
    mutationFn: async (val: string) => {
      try {
        const res = await instance.post<AxiosResponse<OtherOrganisation>>(
          `/commons/other-organisations`,
          { name: val },
        )
        return res.data.data
      } catch (error) {
        throw error
      }
    },
  })
  const addNewOrganisation = async (val: string) => {
    message.open({
      key: "create-organisations",
      content: "updating...",
      type: "loading",
    })
    try {
      const response = await addNew(val)
      if (response) {
        message.destroy()
      }
      return response
    } catch (error: any) {
      message.open({
        key: "create-organisations",
        content: error?.response?.data?.data || "Internal server error",
        type: "error",
      })
    }
  }

  useEffect(() => {
    if (!isLoading) {
      setDataComp(data?.data?.data?.list)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return {
    data: dataComp,
    handleSearch,
    value,
    setValue,
    isOpen,
    setIsOpen,
    addNewOrganisation,
    isLoading,
  }
}

const useImageOtherOrganisation = (data: OtherOrganisation | null) => {
  const [imageUrl, setImageUrl] = useState<string>("")

  const { mutate: uploadImage, isLoading: loadingUpload } = useMutation({
    mutationFn: (val: FormData) => {
      return instance.patch(`/commons/other-organisations/id/${data?.id}`, val)
    },
    mutationKey: "updload-image-other-organisations",
  })

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event
    if (target.files && target.files.length > 0) {
      const file = target.files[0]
      const imageUrl = URL.createObjectURL(file)
      setImageUrl(imageUrl)
      message.open({
        key: "update",
        content: "uploading...",
        type: "loading",
      })
      const handleUpload = () => {
        const formData = new FormData()
        formData.append("file", file)
        uploadImage(formData, {
          onSuccess: (res) => {
            message.destroy()
          },
          onError: (err: any) => {
            message.open({
              key: "update",
              content: err?.response?.data?.data || "something wrong",
              type: "error",
            })
          },
        })
      }
      handleUpload()
    }
  }
  const { mutate: removeImage, isLoading: loadingRemove } = useMutation({
    mutationFn: (id: string) => {
      return instance.delete(`/commons/other-organisations/id/${id}`)
    },
    mutationKey: "delete-image",
  })

  const handleRemoveImage = () => {
    message.open({ key: "remove", content: "removing...", type: "loading" })
    if (data) {
      removeImage(data.id, {
        onSuccess: (res) => {
          setImageUrl("")
          message.destroy()
        },
        onError: (err: any) => {
          message.open({
            key: "remove",
            content: err?.response?.data?.data || "something wrong",
            type: "error",
          })
        },
      })
    }
  }
  useEffect(() => {
    if (data) {
      setImageUrl(data?.image || "")
    }
  }, [data])

  return {
    imageUrl,
    handleImageChange,
    handleRemoveImage,
    loadingRemove,
    loadingUpload,
  }
}
