import { useQuery } from "react-query"
import { instanceV2 } from "../../../../config/config"
import { ApiResponse } from "../../../../common/types/ApiResponse"
import { useLocation } from "react-router-dom"
import { useMsal } from "@azure/msal-react"
import { errorMessage } from "../../../../common/utils/utils"

export const useJobRoleSoftSkill = (idJobRole: string) => {
  const location = useLocation()
  const id = location?.state?.id || idJobRole
  const { instance: user } = useMsal()
  const activeAccount = user.getActiveAccount()
  const { data, isLoading, isFetching, isError } = useQuery<ApiResponse<any>>(
    ["job-role-soft-skill"],
    () => instanceV2.get(`/job-roles/${id}/soft-skills/charts`),
    {
      onError: (err: any) =>
        errorMessage(err?.response?.data?.data || "internal server error", err),
      enabled: !!id && !!activeAccount?.localAccountId,
    },
  )
  return {
    data: data?.data?.data,
    isLoading: isLoading || isFetching,
    isError,
  }
}
