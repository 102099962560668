import { Select } from "antd"
import { Moon } from "../../../../../assets/images"
import { RadarSoftSkill } from "./CVSkillCharts"
import useSoftSkills from "../../../hooks/cv-builder/useSoftSkills.ts"
import { Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"

const CVSoftSkillV2 = () => {
  const { softskillResp, softskillProficiencyResp, softskillChartResp } =
    useSoftSkills()

  return softskillResp.isFetchingSoftSkill ||
    softskillProficiencyResp.isFetchingSoftSkillProficiencies ||
    softskillChartResp.isFetchingSoftSkillChart ? (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  ) : (
    <div className="grid lg:grid-cols-12 grid-cols-6 gap-5">
      <div className="w-full col-span-6">
        <div className="rounded-xl shadow-md w-full bg-white sticky top-0 p-5">
          <h2>Critical Core Skills</h2>
          <p className="p-0 mx-0 my-3">
            Highly transferable soft skills (skills to build skills)
          </p>

          {checkSoftSkill(softskillChartResp?.softskillCharts) ? (
            <RadarSoftSkill data={softskillChartResp?.softskillCharts} />
          ) : (
            <div className="flex gap-3 items-center">
              <img src={Moon} alt="cookies" />
              <div>{"Nothing to display"}</div>
            </div>
          )}
        </div>
      </div>

      <div className="w-[calc(100%-2px)] col-span-6">
        <div className=" p-5 shadow-md mb-10 rounded-xl w-full bg-[#FAFAFA]">
          {softskillResp?.softskills?.map(
            (category: any, categoryIndex: number) => (
              <article key={categoryIndex}>
                <div className="flex items-center gap-3">
                  <div
                    className={`h-5 w-5 rounded-full ${
                      categoryIndex === 0
                        ? "bg-primary"
                        : categoryIndex === 1
                          ? "bg-primaryYellow"
                          : "bg-secondary"
                    } `}
                  ></div>
                  <h3>{category?.name}</h3>
                </div>
                <div className="space-y-1 mb-6">
                  {category?.critical_skills?.map(
                    (skill: any, skillIndex: number) => {
                      return (
                        <div
                          className="flex flex-wrap lg:flex-nowrap items-center justify-between rounded"
                          key={skillIndex}
                        >
                          <p className="flex-grow">{skill?.name}</p>
                          <Select
                            className="lg:w-2/4 w-full flex-grow lg:flex-grow-0 rounded-full job-history"
                            defaultValue={
                              skill?.proficiency
                                ? skill?.proficiency?.name
                                : // eslint-disable-next-line no-useless-concat
                                  "Not" + " Skilled"
                            }
                            onChange={(e) =>
                              softskillProficiencyResp?.handleUpdateProficiency(
                                skill?.id,
                                e,
                              )
                            }
                            options={
                              softskillProficiencyResp?.softskillProficiencies
                            }
                          />
                        </div>
                      )
                    },
                  )}
                </div>
              </article>
            ),
          )}
        </div>
      </div>
    </div>
  )
}
const checkSoftSkill = (data: any): boolean => {
  const temp = data?.find((item: any) => item?.proficiency > 0)
  return !!temp
}

export default CVSoftSkillV2
