import { message } from "antd"
import { AxiosResponse } from "axios"
import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import instance, { instanceV2 } from "../../../../config/config"
import {
  useFetchDetail,
  useFetchList,
  useWrite,
} from "../../../../hooks/generic_rest"
import { debounce } from "../../../../utils/debounce"
import { Account } from "../../../missionControl/hooks/cv-builder/useProfile"
import { FOUNDER_ENDPOINTS } from "../apis/endpoint"
import { ListQueryParams } from "../types/queryParams"
import { FounderResponse, UserFounderStatusResponse } from "../types/response"
import { useFounderReference } from "./useFounderReference"
import ErrorMessageBuilder from "../../../../common/components/ErrorMessageBuilder"

export const LIST_FOUNDER_QUERY_NAME: string = "list-founder"
export const DETAIL_FOUNDER_QUERY_NAME: string = "detail-founder"
export const DETAILEXPERTPROFILEQUERYNAME: string = "list-public-article-test"
export const STATUS_FOUNDER: string = "status-founder-account"

export const useListFounder = () => {
  const [queryParams, setQueryParams] = useState<any>({
    page: 1,
    limit: 10,
    name: "",
    location: "",
    solution_id: "",
    solution_name: "",
    stage_id: "",
    stage_name: "",
    locations: [],
    stage: [],
    solution: [],
  })

  const {
    data: listData,
    isLoading: listLoading,
    isError: listError,
    refetch: refetchListData,
  } = useFetchList<FounderResponse>(`${FOUNDER_ENDPOINTS.LIST}`, {
    queryParams,
    queryName: LIST_FOUNDER_QUERY_NAME,
  })

  const handleSearch = debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setQueryParams({
        ...queryParams,
        name: event.target.value!,
      })
    },
    500,
  )

  // const handleFilterByLocation = (value: any) => {
  //   setQueryParams({
  //     ...queryParams,
  //     location: value?.label,
  //   })
  // }

  // const handleFilterBySolution = (value: SolutionReferenceResponse) => {
  //   setQueryParams({
  //     ...queryParams,
  //     solution_id: value?.id,
  //     solution_name: value?.name,
  //   })
  // }

  // const handleFilterByStage = (value: StageReferenceResponse) => {
  //   setQueryParams({
  //     ...queryParams,
  //     stage_id: value?.id,
  //     stage_name: value?.name,
  //   })
  // }

  const handleFilterByLocation = (opt: any) => {
    const currentLocationIds = queryParams.location
      ? queryParams.location.split(",")
      : []

    if (!currentLocationIds.includes(opt.value)) {
      const newLocationIds = [...currentLocationIds, opt.value]
      const newLocationIdString = newLocationIds.join(",")

      setQueryParams({
        ...queryParams,
        location: newLocationIdString,
        locations: [...queryParams.locations, opt],
      })
    }
  }

  const handleRemoveLocation = (opt: any) => {
    const currentLocationIds = queryParams.location.split(",")
    const updatedLocationIds = currentLocationIds.filter(
      (id: string) => id !== opt.value,
    )
    const newLocationIdString = updatedLocationIds.join(",")

    setQueryParams({
      ...queryParams,
      location: newLocationIdString,
      locations: queryParams.locations.filter(
        (location: any) => location.value !== opt.value,
      ),
    })
  }

  const handleFilterByStage = (opt: any) => {
    const currentStageIds = queryParams.stage_id
      ? queryParams.stage_id.split(",")
      : []

    if (!currentStageIds.includes(opt.value)) {
      const newStageIds = [...currentStageIds, opt.value]
      const newStageIdString = newStageIds.join(",")

      setQueryParams({
        ...queryParams,
        stage_id: newStageIdString,
        stage: [...queryParams.stage, opt],
      })
    }
  }

  const handleRemoveStage = (opt: any) => {
    const currentStageIds = queryParams.stage_id.split(",")
    const updatedStageIds = currentStageIds.filter(
      (id: string) => id !== opt.value,
    )
    const newStageIdString = updatedStageIds.join(",")

    setQueryParams({
      ...queryParams,
      stage_id: newStageIdString,
      stage: queryParams.stage.filter(
        (stage: any) => stage.value !== opt.value,
      ),
    })
  }
  const handleFilterBySolution = (opt: any) => {
    const currentSolutionIds = queryParams.solution_id
      ? queryParams.solution_id.split(",")
      : []

    if (!currentSolutionIds.includes(opt.value)) {
      const newSolutionIds = [...currentSolutionIds, opt.value]
      const newSolutionIdString = newSolutionIds.join(",")

      setQueryParams({
        ...queryParams,
        solution_id: newSolutionIdString,
        solution: [...queryParams.solution, opt],
      })
    }
  }

  const handleRemoveSolution = (opt: any) => {
    const currentSolutionIds = queryParams.solution_id.split(",")
    const updatedSolutionIds = currentSolutionIds.filter(
      (id: string) => id !== opt.value,
    )
    const newSolutionIdString = updatedSolutionIds.join(",")

    setQueryParams({
      ...queryParams,
      solution_id: newSolutionIdString,
      solution: queryParams.solution.filter(
        (solution: any) => solution.value !== opt.value,
      ),
    })
  }

  useEffect(() => {
    refetchListData()
  }, [queryParams])

  // REFERENCE DATA
  const { stageData, solutionData, locationData } = useFounderReference()

  const {
    data: statusFounder,
    isLoading: listLoadingStatusFounder,
    isError: detailErrorStatusFounder,
    refetch: refetchListStatusFounder,
  } = useFetchDetail<UserFounderStatusResponse>(`${FOUNDER_ENDPOINTS.STATUS}`, {
    queryName: STATUS_FOUNDER,
    showErrorMessage: false,
  })

  const { mutate: saveData } = useWrite<any, any>(
    FOUNDER_ENDPOINTS.CREATE,
    "post",
    () => {
      message.success("Be a founder successfully")
      refetchListStatusFounder()
      refetchListData()
    },
    () => message.error("An error occurred"),
  )
  const handleToBeFounder = () => {
    saveData({})
  }

  return {
    founderData: {
      listData: listData?.list,
      listLoading,
      listError,
    },
    queryFounder: {
      handleSearch,
      handleFilterByLocation,
      handleRemoveLocation,

      handleFilterBySolution,
      handleRemoveSolution,

      handleFilterByStage,
      handleRemoveStage,

      location: queryParams?.location,
      stage_name: queryParams?.stage_name,
      solution_name: queryParams?.solution_name,

      statusFounder,
      listLoadingStatusFounder,
      detailErrorStatusFounder,
      handleToBeFounder,

      locationSelected: queryParams?.locations?.filter(
        (id: string) => id !== "",
      ),
      stageSelected: queryParams?.stage?.filter((id: string) => id !== ""),
      solutionSelected: queryParams?.solution?.filter(
        (id: string) => id !== "",
      ),
    },
    referenceData: {
      stageData,
      solutionData,
      locationData,
    },
  }
}

export const useDetailFounder = (
  id: string,
  username: string,
  activeAccount: any,
  tab: number,
) => {
  const {
    data: detailFounder,
    isLoading: detailLoadingFounder,
    isError: detailErrorFounder,
  } = useFetchDetail<FounderResponse>(`${FOUNDER_ENDPOINTS.DETAIL(id)}`, {
    queryName: DETAIL_FOUNDER_QUERY_NAME,
  })

  // EXPERT ARTICLE
  const [activeTab, setActiveTab] = useState<number>(0)
  const [queryParams, setQueryParams] = useState<ListQueryParams>({
    page: 1,
    limit: 10,
    search: "",
  })

  const {
    data: listData,
    isLoading: listLoading,
    isError: listError,
  } = useQuery(
    ["founderProfile"],
    async () => {
      const endpoint = activeAccount
        ? `/publish/articles/user/${username}`
        : `/publish/articles/user/${username}`

      const response = await instance.get(endpoint)
      if (!response) {
        throw new Error("Network response was not ok")
      }
      return response
    },
    {
      onError: () => {
        message.open({
          key: "founderProfile",
          duration: 5,
          content: React.createElement(ErrorMessageBuilder, {
            message:
              "We encountered an issue while attempting to fetch articles posted by the user. If the problem continues, please refresh the page.",
            includeReportAt: true,
          }),
          type: "error",
        })
      },
      retry: 2,
      enabled: !!detailFounder,
    },
  )

  // CV
  const {
    data: account,
    isLoading: loadingAccount,
    isError,
  } = useQuery(
    "founder-account-cv",
    () =>
      instanceV2.get<AxiosResponse<Account>>(`/accounts/cv/id/${id}`, {
        params: {
          anonymised: 0,
        },
      }),

    {
      onError: () => {
        message.open({
          key: "founder-account-cv",
          duration: 5,
          content: React.createElement(ErrorMessageBuilder, {
            message:
              "We encountered an issue while attempting to fetch founder account details. If the problem continues, please refresh the page.",
            includeReportAt: true,
          }),
          type: "error",
        })
      },
      retry: 2,
      enabled: !!username || !!id,
    },
  )

  const handleTabChange = (key: number) => {
    setActiveTab(key)
  }

  useEffect(() => {
    if (tab) {
      setActiveTab(tab)
    }
  }, [tab])

  const handleSearch = debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setQueryParams({
        ...queryParams,
        search: event.target.value!,
      })
    },
    1000,
  )

  return {
    detail: {
      detailFounder,
      detailLoadingFounder,
      detailErrorFounder,
    },
    cv: {
      account: account?.data.data,
      loadingAccount,
      isError,
    },
    article: {
      listData: listData?.data.data,
      listLoading,
      listError,
    },
    handler: {
      handleSearch,
      handleTabChange,
      activeTab,
    },
  }
}
