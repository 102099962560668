/* eslint-disable */

import { ApiResponse } from "../../../../common/types/ApiResponse.ts"
import { instanceV2 } from "../../../../config/config"
import {
  GreenCareerChart,
  MyGreenCareer,
  GreenCareerJobRoles,
} from "./common_type.ts"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { message } from "antd"
import { useState } from "react"
import instance from "../../../../config/config.ts"
import { MyJobRoles } from "../common_type.ts"
import { errorMessage } from "../../../../common/utils/utils.tsx"
// import {debounce} from "../../../../utils/debounce.js";

const fetchGreenCareer = () => {
  const response: Promise<ApiResponse<MyGreenCareer[]>> = instanceV2.get(
    "greencv/career-aspirations",
  )
  return response
}
const fetchGreenCareerJobRoles = () => {
  const response: Promise<ApiResponse<GreenCareerJobRoles[]>> =
    instanceV2.get("greencv/job-roles")
  return response
}

const fetchGreenCareerDetail = () => {
  const response: Promise<ApiResponse<MyJobRoles[]>> = instanceV2.get(
    "/launchpad/career-aspirations",
  )
  return response
}

const addGreenCareerJobRoles = (jobName: string) => {
  const response: Promise<ApiResponse<GreenCareerJobRoles[]>> = instanceV2.post(
    "greencv/job-roles",
    { name: jobName },
  )
  return response
}

const fetchChartGreenCareer = () => {
  const response: Promise<ApiResponse<GreenCareerChart[]>> = instanceV2.get(
    "greencv/career-aspirations/charts",
  )
  return response
}

const unGreenifyGreenCareer = (job_role_id: string) => {
  return instanceV2.delete(`greencv/career-aspirations/${job_role_id}`)
}

const greenifyGreenCareer = (job_role_id: string) => {
  return instanceV2.patch(`greencv/career-aspirations/`, {
    job_role_id: job_role_id,
  })
}

const updateOrderCareer = (job_role_id: string[]) => {
  return instanceV2.patch(`greencv/career-aspirations/reorder`, {
    job_role_id,
  })
}

const useGreenCareer = () => {
  const [search, setSearch] = useState("")
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const queryClient = useQueryClient()
  const handleFetchError = (error: any) => {
    errorMessage(
      `An error occurred while fetching green career aspiration: ${error}`,
      error,
    )
  }

  const handleUpdateSuccess = () => {
    message.success("Green Career successfully updated")
  }

  const handleUpdateError = (error: any) => {
    errorMessage(
      `An error occurred while updating a green career aspiration: ${error}`,
      error,
    )
  }

  const {
    data: greencareersDetail,
    isLoading: isFetchingGreenCareerDetail,
    // isError: isFetchErrorGreenCareerDetail,
    // error: fetchErrorGreenCareerDetail,
  } = useQuery(
    ["fetch-detail-career-aspirations"],
    () => fetchGreenCareerDetail(),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
    },
  )

  const {
    data: greencareers,
    isLoading: isFetchingGreenCareer,
    isError: isFetchErrorGreenCareer,
    error: fetchErrorGreenCareer,
  } = useQuery(["fetch-career-aspirations"], () => fetchGreenCareer(), {
    refetchOnWindowFocus: false,
    onError: handleFetchError,
  })

  const {
    data: greencareerJobRoles,
    isLoading: isFetchingGreenCareerJobRoles,
    isError: isFetchErrorGreenCareerJobRoles,
    error: fetchErrorGreenCareerJobRoles,
  } = useQuery(
    ["fetch-job-roles-career-aspirations"],
    () => fetchGreenCareerJobRoles(),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
    },
  )

  const {
    data: greencareerChart,
    isLoading: isFetchingGreenCareerChart,
    isError: isFetchErrorChart,
    error: fetchErrorChart,
  } = useQuery(
    ["fetch-chart-career-aspirations"],
    () => fetchChartGreenCareer(),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
    },
  )

  const { mutate: removeGreenCareerMutate } = useMutation(
    (job_role_id: string) => unGreenifyGreenCareer(job_role_id),
    {
      onSuccess: () => {
        message.open({
          key: "delete-job-greenify",
          content: "Deleting...",
          type: "loading",
        })

        queryClient.invalidateQueries(["fetch-career-aspirations"])
        queryClient.invalidateQueries(["fetch-detail-career-aspirations"])
        queryClient.invalidateQueries(["fetch-job-roles-career-aspirations"])
        queryClient.invalidateQueries(["fetch-proficiency-career-aspirations"])
        queryClient.invalidateQueries(["fetch-chart-career-aspirations"])
        handleUpdateSuccess()
      },
      onError: handleUpdateError,
      // enabled: isLoggedIn,
    },
  )

  const { mutate: addGreenCareerMutate } = useMutation(
    (job_role_id: string) => greenifyGreenCareer(job_role_id),
    {
      onSuccess: () => {
        message.open({
          key: "Greenify job roles",
          content: "Greenify...",
          type: "loading",
        })
        queryClient.invalidateQueries(["fetch-detail-career-aspirations"])
        queryClient.invalidateQueries(["fetch-career-aspirations"])
        queryClient.invalidateQueries(["fetch-job-roles-career-aspirations"])
        queryClient.invalidateQueries(["fetch-proficiency-career-aspirations"])
        queryClient.invalidateQueries(["fetch-chart-career-aspirations"])

        setTimeout(() => {
          handleUpdateSuccess()
        }, 2300)
      },
      onError: handleUpdateError,
      // enabled: isLoggedIn,
    },
  )

  const { mutate: updateOrderMutate } = useMutation(
    (skillData: any) => updateOrderCareer(skillData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["fetch-career-aspirations"])
        queryClient.invalidateQueries(["fetch-proficiency-career-aspirations"])
        queryClient.invalidateQueries(["fetch-detail-career-aspirations"])
      },
      onError: handleUpdateError,
      retry: () =>
        !(
          typeof instance?.defaults?.headers?.common?.["Authorization"] ===
          "string"
        ),
    },
  )

  const { mutate: addJobRoleMutate } = useMutation(
    (jobName: any) => addGreenCareerJobRoles(jobName),
    {
      onSuccess: (data) => {
        message.open({
          key: "add-job-role",
          content: "adding...",
          type: "loading",
        })
        queryClient.invalidateQueries(["fetch-career-aspirations"])
        queryClient.invalidateQueries(["fetch-job-roles-career-aspirations"])
        setTimeout(() => {
          handleUpdateSuccess()
        }, 2300)
      },
      onError: handleUpdateError,
      // enabled: isLoggedIn,
    },
  )

  const handleUnGreenifyJobRoles = (JobRoleId: string) => {
    removeGreenCareerMutate(JobRoleId)
  }

  const handleGreenifyJobRoles = (jobRoleId: any) => {
    addGreenCareerMutate(jobRoleId)
    handleClear()
  }

  const handleAddJobRole = (e: any) => {
    addJobRoleMutate(e)
    setIsDropdownOpen(false)
    setSearch("")
  }

  const handleSearch = (value: any) => {
    setSearch(value)
  }
  const handleClear = () => {
    setSearch("")
  }

  const handleReorderUp = (index: number) => {
    queryClient.invalidateQueries(["fetch-career-aspirations"])
    queryClient.invalidateQueries(["fetch-detail-career-aspirations"])

    if (greencareers) {
      const greenCareersData = greencareers as { data: { data: any[] } }
      if (index > 0) {
        const newData = [...greenCareersData?.data?.data!]
        const temp = newData[index]
        newData[index] = newData[index - 1]
        newData[index - 1] = temp
        const formData = newData?.map((item) => item.id)
        updateOrderMutate(formData)
      }
    }
  }

  const handleReorderDown = (index: number) => {
    queryClient.invalidateQueries(["fetch-career-aspirations"])
    queryClient.invalidateQueries(["fetch-detail-career-aspirations"])

    if (greencareers) {
      const greenCareersData = greencareers as { data: { data: any[] } }

      if (index < greenCareersData.data.data.length - 1) {
        const newData = [...greenCareersData.data.data]
        const temp = newData[index]
        newData[index] = newData[index + 1]
        newData[index + 1] = temp
        const formData = newData.map((item) => item.id)
        updateOrderMutate(formData)
      }
    }
  }

  return {
    greencareerResp: {
      greencareers: greencareers?.data?.data,
      isFetchingGreenCareer,
      isFetchErrorGreenCareer,
      fetchErrorGreenCareer,
      handleUnGreenifyJobRoles,
      handleGreenifyJobRoles,
      handleReorderUp,
      handleReorderDown,
    },
    greencareersDetailResp: {
      greencareersDetail: greencareersDetail?.data?.data,
      isFetchingGreenCareerDetail,
    },
    greencareerJobRolesResp: {
      jobroles: greencareerJobRoles?.data?.data,
      isFetchingGreenCareerJobRoles,
      isFetchErrorGreenCareerJobRoles,
      fetchErrorGreenCareerJobRoles,
      isDropdownOpen,
      setIsDropdownOpen,
      search,
      handleAddJobRole,
      handleClear,
      handleSearch,
      // handleAddJobRoles
    },
    greencareerChartResp: {
      greencareerCharts: greencareerChart?.data?.data,
      isFetchingGreenCareerChart,
      isFetchErrorChart,
      fetchErrorChart,
    },
  }
}

export default useGreenCareer
