import { Button, Modal, Select } from "antd"
import TextArea from "antd/es/input/TextArea"
import React from "react"
import { Controller } from "react-hook-form"
import { InfiniteAutoComplete } from "../../../../common/components/InfiniteAutoComplete"
import { useGreenSkillCategoriessInfinity } from "../../../../hooks/useInfinityGSCategory"
import { CloseOutlined } from "@ant-design/icons"
import "./custom.css"

interface ModalAddArticleProps {
  addAction: any
}

const ModalAddArticle: React.FC<ModalAddArticleProps> = ({ addAction }) => {
  return (
    <div className="article-modal">
      <Modal
        centered
        open={addAction?.openModal}
        onCancel={() => addAction?.setOpenModal(false)}
        onOk={() => addAction?.setOpenModal(false)}
        footer={
          <div className="flex justify-end mt-10">
            <Button
              className=""
              block
              onClick={addAction?.handleSubmit(addAction?.handleCreateArticle)}
              type="default"
              disabled={
                !addAction?.watch("green_skill_category")?.id ||
                !addAction?.watch("title") ||
                !addAction?.watch("green_skills")?.length
              }
            >
              Create
            </Button>
          </div>
        }
      >
        <div className="mt-9 space-y-4 ">
          <Controller
            name="title"
            control={addAction?.control}
            render={({ field }) => (
              <TextArea
                {...field}
                id="title"
                className="font-default"
                placeholder="Topic"
                autoSize={{ minRows: 1, maxRows: 3 }}
              />
            )}
          />
          <Controller
            name="green_skill_category"
            control={addAction?.control}
            render={({ field }) => (
              <InfiniteAutoComplete
                {...field}
                useDataHook={useGreenSkillCategoriessInfinity}
                placeholder="Select Domain"
                className="!w-full"
                selectedValue={field.value}
                onSelect={(value, option) => {
                  addAction?.handleSelectGSCategory(option)
                }}
              />
            )}
          />

          {addAction?.watch("green_skill_category")?.id &&
            addAction?.watch("title") && (
              <div className="!w-full">
                <Controller
                  name="green_skill"
                  control={addAction?.control}
                  render={(field) => (
                    <Select
                      showSearch
                      filterOption={(inputValue: any, option: any) =>
                        option?.children
                          ?.toLowerCase()
                          ?.indexOf(inputValue.toLowerCase()) >= 0
                      }
                      onFocus={() => addAction.fetchGS()}
                      className="w-full"
                      value={field.field.value}
                      placeholder="Select Green Skills"
                      onChange={(id, data) =>
                        addAction?.handleSelectGS(id, data)
                      }
                    >
                      {addAction?.gsListByID?.skills?.map(
                        (option: any, index: number) => (
                          <Select.Option key={index} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ),
                      )}
                    </Select>
                  )}
                />

                {addAction?.gsSelected && addAction?.gsSelected?.length > 0 && (
                  <div className="mt-6 flex flex-col md:flex-row gap-2 md:w-full ">
                    <div className="flex flex-wrap items-center gap-2">
                      {addAction?.gsSelected?.map(
                        (sector: any, index: number) => (
                          <div
                            className="rounded flex text-[#966F02] bg-secondaryYellow items-center gap-2 shadow-custom-sm w-auto text-sm px-2 py-1"
                            key={index}
                            style={{ border: "1px solid #966F02" }}
                          >
                            <p className="m-0">{sector?.name}</p>
                            <CloseOutlined
                              className="hover:text-danger"
                              onClick={() =>
                                addAction?.handleRemoveSelectedGS(sector.id)
                              }
                            />
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
        </div>
      </Modal>
    </div>
  )
}

export default ModalAddArticle
