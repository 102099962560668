import { Avatar, Image } from "antd"
import React from "react"
import { Moon } from "../../../../assets/images"
import { LikeTwoTone, LikeOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"
import { useProfile } from "../../../missionControl/hooks/cv-builder/useProfile"
import { useModalStore } from "../../../../stores"

interface ListCardPublicArticleProps {
  article: any
  queryArticleResponse: any
  noScroll?: boolean
}

const ListCardPublicArticle: React.FC<ListCardPublicArticleProps> = ({
  article,
  queryArticleResponse,
  noScroll,
}) => {
  const navigate = useNavigate()
  function removeHtmlTags(inputString: string) {
    return inputString.replace(/<\/?[^>]+(>|$)/g, "")
  }

  const { account } = useProfile()

  const openModal = useModalStore((state) => state.openModal)

  return (
    <div
      className="space-y-4 rounded-xl shadow-custom-sm py-5 px-4 hover:shadow-custom cursor-pointer"
      onClick={() => navigate(`/skill/articles/${article?.slug}`)}
      ref={noScroll ? null : queryArticleResponse?.lastCardElementRef}
    >
      <div className="flex gap-3 flex-wrap md:flex-nowrap">
        <div className="bg-red-200 w-full md:w-[220px] h-[200px] rounded-xl flex items-center">
          <Image
            src={article?.img || Moon}
            alt="moon image"
            preview={false}
            width={"100%"}
            height={"100%"}
            className="object-cover rounded-xl"
          />
        </div>

        <div
          className={`space-y-1 w-full relative ${
            article?.green_skills?.length > 1 ? "h-[280px] " : "h-[230px]"
          } md:h-auto`}
        >
          <div className="flex items-center justify-between  text-xs absolute top-36 md:top-0 -mt-3 w-full flex-wrap md:flex-nowrap">
            <div className="flex items-center -gap- md:gap-10 flex-wrap md:flex-nowrap">
              <p className="hidden md:inline tracking-widest uppercase text-[10px] text-[#966F02] m-0">
                {article?.green_skill_category?.name}
              </p>
              <div className="flex items-center gap-3 text-[#919191]">
                <p>{dayjs(article?.updated_at).format("DD MMM YYYY")}</p>
                <span className="text-xl">•</span>
                <p>{article?.read_time} read</p>
              </div>
            </div>
            <div
              className="text-right"
              onClick={(e) => {
                e.stopPropagation()
                if (account) {
                  if (article?.is_like) {
                    queryArticleResponse?.handleUnLikeArticle(article?.slug) // Call unliking function
                  } else {
                    queryArticleResponse?.handleLikeArticle(article?.slug) // Call liking function
                  }
                } else {
                  openModal()
                }
              }}
            >
              <div
                className="flex items-center gap-2 bg-[#FFF2E8] py-1 px-4 rounded-md"
                style={{ border: "1px solid lightgray" }}
              >
                <div>
                  {article?.is_like === true ? (
                    <LikeTwoTone twoToneColor={"green"} />
                  ) : (
                    <LikeOutlined />
                  )}
                </div>
                <p className="m-0">{article?.likes}</p>
              </div>
            </div>
            <div className="md:hidden flex items-center gap-3 ">
              {article?.expert_data?.profile_picture ? (
                <Avatar
                  src={article?.expert_data?.profile_picture}
                  size={"small"}
                />
              ) : (
                <Avatar
                  src={
                    "https://stgreenskills001.blob.core.windows.net/default-avatars/7.png"
                  }
                  size={"small"}
                />
              )}
              <p className="m-0 text-[12px]">
                {article?.expert_data?.first_name}{" "}
                {article?.expert_data?.last_name}
              </p>
            </div>
          </div>

          <div className="absolute top-0 md:top-8 w-full space-y-3">
            <div className="">
              <p className="inline md:hidden tracking-widest uppercase text-[10px] text-[#966F02] m-0">
                {article?.green_skill_category?.name}
              </p>
              <h2 className="m-0 line-clamp-1 md:line-clamp-2">
                {article?.title}{" "}
              </h2>
              <div className="inline md:hidden">
                <p className="line-clamp-2 text-[15px]">
                  {article?.sort_section
                    ? removeHtmlTags(article?.sort_section)
                    : "unknown"}
                </p>
              </div>
            </div>

            <div className="hidden md:flex items-center gap-3 ">
              {article?.expert_data?.profile_picture ? (
                <Avatar
                  src={article?.expert_data?.profile_picture}
                  size={"small"}
                />
              ) : (
                <Avatar
                  src={
                    "https://stgreenskills001.blob.core.windows.net/default-avatars/7.png"
                  }
                  size={"small"}
                />
              )}
              <p className="m-0 text-[12px]">
                {article?.expert_data?.first_name}{" "}
                {article?.expert_data?.last_name}
              </p>
            </div>

            <div className="hidden md:inline">
              <p className="line-clamp-2 text-[15px]">
                {article?.sort_section
                  ? removeHtmlTags(article?.sort_section)
                  : "unknown"}
              </p>
            </div>
          </div>

          <div
            className={`flex items-center gap-x-2  flex-wrap absolute bottom-0 w-full ${
              article?.green_skills?.length > 1 ? "h-[73px]" : "h-auto"
            } overflow-hidden`}
          >
            {article?.green_skills?.map((skill: any, index: number) => {
              return (
                <div
                  key={index}
                  className="rounded-xl md:rounded-full px-0.5 md:px-3 md:py-1 bg-[#F5F5F5]"
                  style={{ border: "1px solid lightgray" }}
                  onClick={(e) => {
                    e.stopPropagation()
                    navigate(`/skill/green-skills/${skill?.id}`)
                  }}
                >
                  <p className="m-0 text-[9px] md:text-[12px]">{skill?.name}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListCardPublicArticle
