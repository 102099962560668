import React from "react"
import { Avatar, Divider, Popconfirm, Typography } from "antd"
import {
  // CommentOutlined,
  // ShareAltOutlined,
  LikeOutlined,
  LikeFilled,
  EditOutlined,
  DeleteFilled,
} from "@ant-design/icons"
import { ICommunityComment } from "../types/commonn"
import dayjs from "dayjs"
import { usePostComment } from "../hooks/usePostComment"
import { updateCommentStore } from "../../store/updateCommentStore"

const CommentV2 = ({
  comment,
  observ,
}: {
  comment: ICommunityComment
  observ?: (node: Element | null) => void
}) => {
  const { liked, isUserComment, handleLike, handleDislike, handleDelete } =
    usePostComment(comment)

  const { openModal } = updateCommentStore()

  return (
    <div className="flex gap-2" ref={observ}>
      <div className="">
        <Avatar src={comment?.user?.avatar} className="object-cover h-8" />
      </div>
      <div className="flex-grow">
        <div className="flex items-center gap-4">
          <div className="">{comment?.user?.username}</div>
          <div className="text-gray-400">
            {dayjs(comment?.created_at)?.fromNow()}
          </div>
          <div className="text-gray-400">{comment?.user?.location}</div>
          {comment?.updated_at && (
            <div className="text-xs text-gray-400 italics">Edited</div>
          )}
        </div>
        <Typography.Paragraph
          ellipsis={{ rows: 6, expandable: true, symbol: "more" }}
          className="py-2"
        >
          {comment?.body}
        </Typography.Paragraph>
        <Divider className="my-2" />
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            {liked?.liked ? (
              <LikeFilled
                className="transition-all duration-200 cursor-pointer hover:text-primary hover:scale-110"
                onClick={handleDislike}
              />
            ) : (
              <LikeOutlined
                onClick={handleLike}
                className="transition-all duration-200 cursor-pointer hover:text-primary hover:scale-110"
              />
            )}
          </div>
          {/* <div className="flex items-center gap-2">
            <CommentOutlined />
            <span>{}</span>
          </div> */}
          {/* <div className="flex items-center gap-2">
            <ShareAltOutlined />
          </div> */}
          {isUserComment && (
            <div className="flex items-center gap-2">
              <EditOutlined
                className=" text-backdrop"
                onClick={() => openModal(comment)}
              />
            </div>
          )}
          {isUserComment && (
            <div className="flex items-center gap-2">
              <Popconfirm
                title="Are you sure to delete this comment?"
                onConfirm={handleDelete}
                okText="Delete"
                okButtonProps={{
                  className: "!bg-danger !text-white",
                }}
                cancelText="No"
              >
                <DeleteFilled className="transition-all duration-300 cursor-pointer  text-backdrop hover:text-danger hover:scale-110" />
              </Popconfirm>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CommentV2
