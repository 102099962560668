import { useMutation, useQueryClient } from "react-query"
import { becomeAFounder } from "../apis/founders"
import { message } from "antd"

export const useBecomeAFounder = () => {
  const queryClient = useQueryClient()
  return useMutation(becomeAFounder, {
    onSuccess: () => {
      queryClient.invalidateQueries(["profile-status"])
      message.success(
        "You have been successfully made a founder! Add your company infromation to appear on the platform.",
      )
    },
    onError: () => {
      message.error("Failed to become a founder, please try again later.")
    },
  })
}
