import { useLaunchpad } from "../hooks/useLaunchpad"
import { useNavigate } from "react-router-dom"
import { ShareAltOutlined, EyeOutlined } from "@ant-design/icons"
import {
  Drawer,
  Dropdown,
  Skeleton,
  Avatar,
  message,
  Progress,
  Button,
  Popover,
  QRCode,
} from "antd"
import { CVDetailLauchpad } from "./CVDetailLauchpad"
import { useProfile } from "../hooks/useProfile"
import { BasicContainer } from "../../../common/components/BasicBoxContainers/BasicContainer"
import { useCvLaunchpad } from "../hooks/useCvLaunchpad"
import React from "react"

const downloadQRCode = () => {
  const canvas = document.getElementById("myqrcode")?.querySelector("canvas")
  if (canvas) {
    const url = canvas.toDataURL()
    const a = document.createElement("a")
    a.download = "QRCode.png"
    a.href = url
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}

interface CvLaunchpadProps {}

export const CVLaunchpad = React.forwardRef<HTMLDivElement, CvLaunchpadProps>(
  (props, ref) => {
    const { isDrawerOpen, setIsDrawerOpen } = useLaunchpad()
    const { account, loadingAccount } = useProfile()
    const cvLaunchpadQuery = useCvLaunchpad()
    const navigate = useNavigate()
    const cvData = cvLaunchpadQuery?.data?.data?.data

    const items = [
      {
        key: "1",
        label: (
          <span>
            <Popover
              className="mb-6"
              overlayInnerStyle={{ padding: 0 }}
              content={
                <div id="myqrcode">
                  <QRCode
                    value={`${process.env.REACT_APP_URL}/talent-marketplace/cv-full/${account?.data?.data?.id}`}
                    bordered={false}
                  />
                  <div className="text-xs text-center mb-2">
                    Your shareable QR Code
                  </div>
                </div>
              }
            >
              Download QR Code
            </Popover>
          </span>
        ),
        onClick: () => downloadQRCode(),
      },
      {
        key: "2",
        label: "Full CV link",
        onClick: () => {
          message.info("Copied to Clipboard")
          navigator.clipboard.writeText(
            `${process.env.REACT_APP_URL}/talent-marketplace/cv-full/${account?.data?.data?.id}`,
          )
        },
      },

      {
        key: "3",
        label: "Anonymised CV link",
        onClick: () => {
          message.info("Copied to Clipboard")
          navigator.clipboard.writeText(
            `${process.env.REACT_APP_URL}/talent-marketplace/cv-anonymised/${account?.data?.data?.nick_name}`,
          )
        },
      },
    ]
    return cvLaunchpadQuery?.isLoading || loadingAccount ? (
      <div className="bg-white p-5">
        <Skeleton active />
      </div>
    ) : (
      <BasicContainer
        ref={ref}
        type="small"
        className="!bg-primary-bg max-w-full"
      >
        <div className="flex justify-between">
          <h3 className="">Green CV</h3>
          <div className="flex items-center flex-grow justify-end gap-x-4">
            <Dropdown
              menu={{
                items,
              }}
              placement="bottom"
              arrow
            >
              <small className="flex gap-2 items-center cursor-pointer">
                <ShareAltOutlined />
                Share
              </small>
            </Dropdown>
            <small
              className="flex gap-2 items-center cursor-pointer"
              onClick={() => navigate("/dashboard/launchpad/cv-view")}
            >
              <EyeOutlined />
              View
            </small>
          </div>
        </div>
        <div className="grid grid-cols-6 bg-white rounded-[15px] px-5 py-4 mt-3 shadow-xl">
          <div className="col-span-full sm:col-span-2 md:col-span-2 ">
            {cvData?.profile_picture ? (
              <img
                src={`${cvData?.profile_picture}`}
                alt="profile"
                className="h-[82.5px] w-[82.5px] rounded-full object-contain bg-white"
                style={{ border: "1px solid #D4F5C5" }}
                onError={(e) => {
                  const target = e.target as HTMLImageElement
                  target.onerror = null
                  target.src = cvData?.avatar
                }}
              />
            ) : (
              <Avatar src={cvData?.avatar} className="" size={82.5} />
            )}
          </div>
          <div className="col-span-full sm:col-span-5 md:col-span-4">
            <h3>{`${cvData?.first_name} ${cvData?.last_name}`}</h3>
            <div className="text-xs">
              <div className="">
                <b>{cvData?.total_career_aspiration ?? 0}</b> Career Aspirations
              </div>
              <div className="">
                <b>{cvData?.total_proficient_green_skill ?? 0}</b> Green Skills
                I am proficient in
              </div>
              <div className="">
                <b>{cvData?.total_interested_green_skill}</b> Green Skills I am
                interested in
              </div>
            </div>
          </div>
          <Progress
            className="col-span-full"
            trailColor="#F5F5F5"
            strokeColor={"#009ACE"}
            showInfo={false}
            percent={cvData?.profile_strength ?? 0}
          />
        </div>
        <Button
          className="bg-backdrop text-white w-full mt-4"
          onClick={() => navigate("/dashboard/launchpad/cv-builder")}
        >
          CV Builder
        </Button>

        <Drawer
          placement="right"
          closable={false}
          onClose={() => setIsDrawerOpen(false)}
          open={isDrawerOpen}
          className=" bg-slate-600"
          bodyStyle={{ backgroundColor: "#E2EBFA" }}
          width={"70%"}
        >
          <CVDetailLauchpad />
        </Drawer>
      </BasicContainer>
    )
  },
)
