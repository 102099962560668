import create from "zustand"
import { Discussion } from "../types/workshops"

interface QueryParams {
  page: number
  limit: number
}

interface DiscussionStore {
  dataList: Discussion[]
  queryParams: QueryParams
  total: number
  setDataList: (dataList: Discussion[]) => void
  setQueryParams: (queryParams: QueryParams) => void
  setTotal: (total: number) => void
  isInitialLoad: boolean
  setIsInitialLoad: (isInitialLoad: boolean) => void
  isAddingNewData: boolean
  setIsAddingNewData: (isAddingNewData: boolean) => void
}

export const useDiscussionStore = create<DiscussionStore>((set) => ({
  dataList: [],
  queryParams: { page: 1, limit: 5 },
  total: 0,
  setDataList: (dataList) => set({ dataList }),
  setQueryParams: (queryParams) => set({ queryParams }),
  setTotal: (total) => set({ total }),
  isInitialLoad: true,
  setIsInitialLoad: (isInitialLoad) => set({ isInitialLoad }),
  isAddingNewData: false,
  setIsAddingNewData: (isAddingNewData) => set({ isAddingNewData }),
}))
