import { Skeleton } from "antd"
import React from "react"
import { Moon } from "../../../../assets/images"
import FiveCircleRateTS from "../../../../common/components/FiveCircleRateTS"
import { Trajectory } from "../../hooks/useTrajectory"
import { useNavigate } from "react-router-dom"

interface OtherSkillProps {
  isLoading: boolean
  skill: Trajectory | undefined
}

export const OtherSkill: React.FC<OtherSkillProps> = ({ isLoading, skill }) => {
  const navigate = useNavigate()
  if (isLoading) {
    return (
      <div className="flex px-2 p-y-[10px] gap-x-4 items-center justify-start flex-grow bg-white ]">
        <Skeleton.Image className="w-[62px] h-[62px] rounded-[7px]" />
        <div className="flex flex-col gap-y-[5px]">
          <Skeleton.Button active={true} size="large" shape="round" />
          <Skeleton.Button active={true} size="large" shape="round" />
        </div>
      </div>
    )
  }
  return (
    <div
      className="flex px-2 py-[5px] gap-x-4 w-full lg:flex-nowrap flex-wrap shadow-md items-center justify-start bg-white rounded-[10px] cursor-pointer hover:shadow-lg  transition-transform duration-300"
      onClick={() =>
        navigate(`/dashboard/launchpad/single-mylaunchpad/${skill?.id}`, {
          state: {
            lastIndexLearn: skill?.current_level_progress
              ? skill?.current_level_progress - 1
              : 1,
          },
        })
      }
    >
      <div className="p-3 w-[62px] rounded-[7px] flex-grow flex items-center justify-center">
        <img
          className="w-[62px] h-[50px] object-contain"
          src={skill?.image ?? Moon}
          alt="skill"
        />
      </div>
      <div className="flex flex-col gap-y-[5px] w-full lg:justify-normal lg:items-start justify-center items-center">
        <div className="font-bold">{skill?.name ?? "N/A"}</div>
        <div className="flex gap-x-5 items-center">
          <div className="-mt-[1px]">Proficiency</div>
          <FiveCircleRateTS
            defaultValue={skill?.total_completed_levels ?? 0}
            secondValue={
              skill &&
              skill?.current_level_progress > skill?.total_completed_levels
            }
            disabled
            circleSize={15}
          />
        </div>
      </div>
    </div>
  )
}
