import { OAuthProvider } from "../../../../../utils/connect-account"

export const MENTOR_ENDPOINTS = {
  LIST: `user-mentors`,
  DETAIL: (id: string) => `user-mentors/${id}`,
  EDIT_BASIC: `user-mentors/`,
  EDIT_ADV: `user-mentors/adv-profile`,
  CREATE: `mentor-requests`,
  REF_LIST_AREA_BASED_DATA: `user-mentors/reference-areas`,
  REF_LIST_DOMAIN_BASED_DATA: `user-mentors/reference-domain`,
  REF_LIST_LOCATION_BASED_DATA: `user-mentors/reference-location`,
  IAM_MENTOR: `user-mentors/iam-mentor`,

  LIST_AVAILABILITY: `mentor-settings/availabilities`,
  CREATE_AVAILABILITY: `mentor-settings/availabilities`,
  EDIT_AVAILABILITY: `mentor-settings/availabilities`,
  AVAILABILITY_EXTEND_TIME: (dayId: string, timeId: string) =>
    `mentor-settings/availabilities/days/${dayId}/extend-times/${timeId}`,
  OAUTH_WEB_CONNECT: (provider: OAuthProvider) =>
    `mentor-settings/oauth/${provider}/connect`,
  MY_BOOKING_MANTEE: `mentor-settings/schedules`,

  MENTOR_SCHEDULE: (mentorId: string) =>
    `mentor-schedules/${mentorId}/availabilities`,
  MENTOR_BOOKING: `mentor-schedules/booking`,
  MY_BOOKING_MENTOR: `mentor-schedules/my-bookings`,
  CANCEL_BOOKING: (bookingId: string) => `mentor-schedules/${bookingId}/cancel`,
  RESCHEDULE_BOOKING: (bookingId: string) =>
    `mentor-schedules/${bookingId}/reschedule`,

  DELETE_OVERRIDE_DATE: (date: string) =>
    `mentor-settings/availabilities/day-overrides/${date}`,
}

export const MENTOR_REFERENCE_ENDPOINTS = {
  LIST_AREAS: `user-mentors/areas`,
  LIST_DOMAINS: `green-skills/categories`,
}
