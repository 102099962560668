import React from "react"
import { CloseOutlined } from "@ant-design/icons"
import { Button, Progress } from "antd"
import { useJobRoleCareerAspiration } from "../../hooks/jobRoles/useJobRoleCareerAspiration"
import { useNavigate } from "react-router-dom"

export const DetailCareerAspiration = () => {
  const { data } = useJobRoleCareerAspiration()
  const navigate = useNavigate()
  return (
    data && (
      <div className=" p-3 rounded-xl shadow-md bg-[#FAFAFA]">
        <div className="mb-5  flex  justify-between items-center">
          <h3 className=" font-semibold">Career Aspirations</h3>
          <Button
            type="primary"
            ghost
            onClick={() => navigate("/dashboard/launchpad/cv-builder")}
          >
            CV Builder
          </Button>
        </div>
        {data?.map((item: any, index: number) => {
          return (
            <div key={index}>
              <div
                className={`min-h-14 flex gap-2 items-center justify-between rounded-xl  px-4 py-4 ${
                  data?.length - 1 !== index ? "mb-3" : "mb-0"
                } bg-white`}
                style={{ border: "1px solid #D0DDE5" }}
              >
                <div className={`flex items-center gap-3 w-full`}>
                  <div
                    className={`bg-primary-bg w-6 h-6 text-center pt-0.5 rounded-full`}
                  >
                    {index + 1}
                  </div>
                  <p
                    className={`m-0 w-[calc(100%-3rem)] first-letter:capitalize `}
                  >
                    {item?.name}
                  </p>
                </div>
                <div>
                  {item?.green_skills === null ? (
                    <CloseOutlined className={`text-red-500 text-lg`} />
                  ) : (
                    <Progress
                      type="circle"
                      strokeColor={
                        Math.round(
                          (item?.green_skills?.reduce(
                            (acc: any, cur: any) =>
                              acc +
                              (cur?.total_completed_level >
                              cur?.total_required_level
                                ? cur?.total_required_level
                                : cur?.total_completed_level),
                            0,
                          ) /
                            item?.total_green_skill_levels) *
                            100,
                        ) === 100
                          ? "86C769"
                          : "#FAC61D"
                      }
                      percent={parseInt(
                        (
                          (item?.total_passed_levels /
                            item?.total_green_skill_levels) *
                          100
                        ).toFixed(),
                      )}
                      size={30}
                    />
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  )
}
