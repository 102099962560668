import React from "react"
import { Route, Routes } from "react-router-dom"
import CvView from "./pages/CvView"

// const Roadmap = React.lazy(() => import("./pages/Roadmap"))
import Roadmap from "./pages/Roadmap.tsx"
// const Profile = React.lazy(() => import("./pages/Profile"))
import Profile from "./pages/Profile.tsx"
// const Launchpad = React.lazy(() => import("./pages/LaunchpadV2"))
import Launchpad from "./pages/LaunchpadV2.tsx"
// const RechargeStation = React.lazy(() => import("./pages/RechargeStation"))
import RechargeStation from "./pages/RechargeStation"
// const SingleMyLaunchpad = React.lazy(() => import("./pages/SingleMyLaunchpad"))
import SingleMyLaunchpad from "./pages/SingleMyLaunchpad.tsx"
// const SolarTrophies = React.lazy(() => import("./pages/SolarTrophies"))
import SolarTrophies from "./pages/SolarTrophies.tsx"
// const NotFound = React.lazy(() => import("../auth/page/NotFound"))
import NotFound from "../auth/page/NotFound.tsx"
// const Learnings = React.lazy(() => import("./pages/Learnings"))

const Index = () => {
  return (
    <Routes>
      <Route
        path="/skill-trajectory-plan/skill-roadmap"
        element={<Roadmap />}
      />
      <Route path="/launchpad/cv-builder" element={<Profile />} />
      <Route path="/launchpad/cv-view" element={<CvView />} />
      <Route
        path="/galactic-achievements/recharge-station"
        element={<RechargeStation />}
      />
      <Route
        path="/galactic-achievements/solar-trophies"
        element={<SolarTrophies />}
      />
      <Route path="/launchpad" element={<Launchpad />} />
      <Route path="/launchpad/cv-builder" element={<Profile />} />
      <Route
        path="/launchpad/single-mylaunchpad/:green_skill_id"
        element={<SingleMyLaunchpad />}
      />
      {/* <Route
        path="/launchpad/single-mylaunchpad/:green_skill_id/module/:level_id"
        element={<Learnings />}
      /> */}

      {/* note! each route must be contain this route error to handle error after parent route*/}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default Index
