import React, { useEffect } from "react"
import {
  Empty,
  // Segmented, Select, Skeleton, Space, Tag
} from "antd"
// import { CustomSearch } from "../../../common/components"
// import { HomeOutlined } from "@ant-design/icons"
import SkillCard, { SkillCardLoading } from "../components/SkillCard"
import {
  PageContent,
  // PageHeader
} from "../../../common/layout"
import { DecisionBubblesSkill } from "../components"
import { useSkillsPage } from "../hooks/useSkillsPage"
import { useLocation } from "react-router-dom"
// import { Category } from "../types/common"
import SkillListHeader from "../components/SkillListHeader"

function SkillsPage() {
  const {
    viewType,
    sort_rank,
    availability,
    price,
    selectedCategories,
    limitCategories,
    greenSkillSectors,
    data,
    isLoading,
    // greenSkillsQuery,
    setSelectedCategories,
    setParam,
    lastCardElementRef,
    dataList,
    setDataList,
    setPage,
    totalSkills,
  } = useSkillsPage()

  const location = useLocation()

  useEffect(() => {
    if (location?.state?.groupId && location?.state?.checked) {
      setSelectedCategories((selectedGrpId) => [
        ...selectedGrpId,
        location?.state?.groupId,
      ])
    }
  }, [
    location?.state?.checked,
    location?.state?.groupId,
    setSelectedCategories,
  ])

  const handleFavoriteClick = (id: string) => {
    setDataList((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, is_favorite: !item.is_favorite } : item,
      ),
    )
  }

  return (
    <PageContent
      header={
        <SkillListHeader
          data={data}
          selectedCategories={selectedCategories}
          setPage={setPage}
          setParam={setParam}
          setSelectedCategories={setSelectedCategories}
          totalSkills={totalSkills}
          availability={availability}
          isLoading={isLoading}
          limitCategories={limitCategories}
          price={price}
          sort_rank={sort_rank}
          viewType={viewType}
        />
      }
      content={
        <>
          {viewType === "cards" ? (
            <>
              {isLoading ? (
                <div className="text-backdrop mt-5 grid grid-cols-12 gap-6">
                  {Array.from({ length: 6 }).map(() => (
                    <SkillCardLoading key={Math.random()} />
                  ))}
                </div>
              ) : dataList?.length === 0 ? (
                <div className=" bg-white mt-4 p-5">
                  <Empty description="No Skills found to be displayed." />
                </div>
              ) : (
                <div className="text-backdrop mt-5 grid grid-cols-12 gap-6">
                  {dataList?.map((skill, index) => (
                    <SkillCard
                      key={skill.id}
                      className="col-span-12 md:col-span-6 lg:col-span-4"
                      group_name={skill.category?.name}
                      onFavoriteClick={() => handleFavoriteClick(skill.id)}
                      skill={skill}
                      observ={
                        dataList?.length === index + 1
                          ? lastCardElementRef
                          : undefined
                      }
                    />
                  ))}
                </div>
              )}
            </>
          ) : (
            <div className="pb-16 mb-16">
              <DecisionBubblesSkill
                // @ts-ignore
                greenSkillSectors={greenSkillSectors}
              />
            </div>
          )}
        </>
      }
    />
  )
}

export default SkillsPage
