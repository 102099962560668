import React from "react"
import { Card, Skeleton, Space } from "antd"
import { EditOutlined } from "@ant-design/icons"
import { PinMapGray } from "../../../../assets/images"
import DrawerCV from "../../public-expert/components/DrawerCV"
import AvatarCustom from "../../../../common/components/AvatarCustom"

interface DetailPublicCommonUserContentProps {
  memberData: any
}

const DetailPublicCommonUserContent: React.FC<
  DetailPublicCommonUserContentProps
> = ({ memberData }) => {
  // console.log('memberData', memberData)
  return memberData?.detailLoading ? (
    <div className="rounded-lg bg-white p-4">
      <Skeleton active />
    </div>
  ) : (
    // <div className="rounded-lg bg-white grid grid-cols-1 md:grid-cols-12 p-4 gap-7">
    <div>
      <Card className="bg-white-custom shadow-md w-full rounded-xl relative col-span-8">
        <div className="flex gap-5 lg:flex-nowrap flex-wrap">
          <AvatarCustom
            url={memberData?.detailData?.profile_picture}
            display_name={
              memberData?.detailData?.first_name +
              " " +
              memberData?.detailData?.last_name
            }
            label={
              memberData?.detailData?.is_founder
                ? "Founder"
                : memberData?.detailData?.is_expert
                  ? "Expert"
                  : ""
            }
          />

          <div>
            <h3 className="capitalize">{`${memberData?.detailData?.first_name} ${memberData?.detailData?.last_name}`}</h3>
            <div className="my-1">
              {memberData?.detailData?.profile_headline || "-"}
            </div>
            <div className="flex gap-5 lg:flex-nowrap flex-wrap">
              <div>{`@${memberData?.detailData?.nick_name}`}</div>
              <div>
                <PinMapGray />
                <span className="ml-2">{memberData?.detailData?.country}</span>
              </div>
            </div>
          </div>
        </div>

        {memberData?.detailData?.about_me &&
          memberData.about_me !== "<p><br></p>" && (
            <div
              className="mt-2"
              dangerouslySetInnerHTML={{
                __html: memberData?.detailData?.about_me,
              }}
            ></div>
          )}
      </Card>

      {/* <div className="rounded-lg shadow-custom bg-white-custom py-7 px-5 col-span-8 md:col-span-4 relative w-full">
        {data?.listLoading ? (
          <Skeleton active paragraph={{ rows: 4 }} />
        ) : (
          <div>
            <div className="w-full">
              <div className="flex items-center justify-start gap-6">
                <div className="flex items-center gap-3">
                  <Image
                    src={data?.listData?.level_up_data?.image_url}
                    preview={false}
                    width={35}
                    height={35}
                  />
                  <div className="flex flex-col gap-x-2">
                    <div className="capitalize font-semibold text-[14px]">
                      {data?.listData?.level_up_data?.title}
                    </div>
                    <div className="capitalize text-[12px]">
                      {data?.listData?.level_up_data?.subtitle}
                    </div>
                  </div>
                </div>

                <Space className="" size={3} direction="vertical">
                  <div className="text-backdrop font-medium text-[12px]">
                    TerraPoints
                  </div>
                  <div className="text-backdrop text-xl font-bold">
                    {data?.listData?.level_up_data?.tera_point}
                  </div>
                </Space>
                <Space className="" size={3} direction="vertical">
                  <div className="text-backdrop font-medium text-[12px]">
                    Orbit Honors
                  </div>
                  <div className="text-backdrop text-xl font-extrabold">
                    {data?.listData?.level_up_data?.total_user_honors ?? 0}
                  </div>
                </Space>
              </div>
            </div>

            <div
              className="grid bg-white rounded-[15px] px-5 py-4 grid-cols-1 md:grid-cols-6 mt-3 shadow-custom-sm md:absolute bottom-5 w-full md:w-[89%] transition-all cursor-pointer group"
              onClick={() => onOpen()}
            >
              <div className="absolute inset-0 bg-primary-bg opacity-0 group-hover:opacity-95 transition-opacity flex justify-center items-center rounded-[15px]">
                <p className="text-backdrop text-xl font-semibold">
                  View full CV
                </p>
              </div>
              <div className="col-span-1 md:col-span-2 relative group">
                {data?.listData?.cv_data?.profile_picture ? (
                  <img
                    src={`https://stgreenskills001.blob.core.windows.net/default-avatars/7.png`}
                    alt="profile"
                    className="h-[72.5px] w-[72.5px] rounded-full object-contain bg-white"
                    style={{ border: "1px solid #D4F5C5" }}
                    onError={(e: any) => {
                      e.target.onerror = null
                      e.target.src = data?.listData?.cv_data?.avatar
                    }}
                  />
                ) : (
                  <Avatar
                    src={
                      "https://stgreenskills001.blob.core.windows.net/default-avatars/7.png"
                    }
                    className=""
                    size={82.5}
                  />
                )}
              </div>
              <div className="col-span-4">
                <div className="flex items-center justify-between">
                  <h3>Green CV</h3>
                  <InfoCircleOutlined />
                </div>
                <div className="text-xs">
                  <div className="text-xs">
                    <b>
                      {data?.listData?.cv_data?.total_career_aspiration ?? 0}
                    </b>{" "}
                    Career Aspirations
                  </div>
                  <div className="text-xs">
                    <b>
                      {data?.listData?.cv_data
                        ?.total_proficient_green_skill ?? 0}
                    </b>{" "}
                    Green Skills I am proficient in
                  </div>
                  <div className="text-xs">
                    <b>
                      {data?.listData?.cv_data?.total_interested_green_skill}
                    </b>{" "}
                    Green Skills I am interested in
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <DrawerCV open={open} onClose={onClose} cv={cv} /> */}
    </div>
  )
}

export default DetailPublicCommonUserContent
