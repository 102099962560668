/* eslint-disable */

import { message } from "antd"
import instance from "../../../config/config"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { ApiResponse } from "../../../common/types/ApiResponse"
import { ExportedData } from "../types/common"
import { errorMessage } from "../../../common/utils/utils"
import React from "react"
import ErrorMessageBuilder from "../../../common/components/ErrorMessageBuilder"

export const useDataExport = () => {
  const queryClient = useQueryClient()
  const { mutate: exportDataRequest, isLoading: requestLoad } = useMutation({
    mutationFn: () => {
      return instance.post("/export-user-data")
    },
    mutationKey: "export-data-request",
  })
  const requestExport = () => {
    exportDataRequest(undefined, {
      onSuccess: () => {
        queryClient.invalidateQueries(["export-data-files"])
        message.success("Successfully requested Data Export.")
      },
      onError: () => {
        errorMessage("Error in requesting Data Export.")
      },
    })
  }

  const { data: exportDataFiles, isLoading: isLoadingExportDataFiles } =
    useQuery(
      ["export-data-files"],
      (): Promise<ApiResponse<ExportedData[]>> =>
        instance.get("/export-user-data"),
      {
        onError: () => {
          message.open({
            key: "export-data-files",
            duration: 5,
            content: React.createElement(ErrorMessageBuilder, {
              message:
                "We encountered an issue while attempting to fetch your export user data. If the problem continues, please refresh the page.",
              includeReportAt: true,
            }),
            type: "error",
          })
        },
        retry: 2,
      },
    )

  return {
    requestExport,
    requestLoad,
    exportDataFiles,
    isLoadingExportDataFiles,
  }
}
