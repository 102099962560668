import { message } from "antd"
import {
  useFetchDetail,
  useWrite,
  useWriteV2,
} from "../../../../../hooks/generic_rest"
import { MENTOR_ENDPOINTS } from "../apis/endpoint"
import { Day, MentorAvailabilityResponse } from "../types/response"
import { useEffect } from "react"
import { useAvailabilityStore } from "../stores/storeModalSetAvailability"

export const LIST_MENTOR_AVAILABLITY_QUERY_NAME = "list-mentor-availability"

export const useListMentorAvailablity = () => {
  const {
    availabilityData,
    setAvailabilityData,
    timezone,
    setTimezone,
    label,
    setLabel,
    setLoadingSave,
    setIsMentorHasAvailability,
  } = useAvailabilityStore()

  const {
    data: listData,
    isLoading: listLoading,
    isError: listError,
    refetch: refetchListData,
  } = useFetchDetail<MentorAvailabilityResponse>(
    `${MENTOR_ENDPOINTS.LIST_AVAILABILITY}`,
    {
      queryName: LIST_MENTOR_AVAILABLITY_QUERY_NAME,
      enabled: true,
      showErrorMessage: false,
    },
  )

  useEffect(() => {
    if (!listLoading && listError) {
      setIsMentorHasAvailability(false)
    } else {
      setIsMentorHasAvailability(true)
    }
  }, [listLoading, listError, setIsMentorHasAvailability])

  const { mutate: craeteInitialAvailability } = useWrite<any, any>(
    MENTOR_ENDPOINTS.CREATE_AVAILABILITY,
    "post",
    () => {
      setLoadingSave(false)
      refetchListData()
    },
    () =>
      message.open({
        key: "create-time-available",
        content: "Something went wrong",
        type: "error",
      }),
  )

  const { mutate: saveAvailability } = useWrite<any, any>(
    MENTOR_ENDPOINTS.EDIT_AVAILABILITY,
    "patch",
    () => {
      setLoadingSave(false)
      refetchListData()
    },
    () =>
      message.open({
        key: "update-time-available",
        content: "Something went wrong",
        type: "error",
      }),
  )

  const removeExtendTimeAvailability = useWriteV2<any, any>(
    ({ dayID, timeID }) =>
      MENTOR_ENDPOINTS.AVAILABILITY_EXTEND_TIME(dayID, timeID),
    "delete",
    () => {
      setLoadingSave(false)
    },
    () => {
      setLoadingSave(false)
      message.open({
        key: "update-time-available",
        content: "Something went wrong",
        type: "error",
      })
    },
  )

  useEffect(() => {
    if (listData && listData.days) {
      setTimezone(listData.timezone)
      setLabel(listData.label)
      setAvailabilityData(listData.days)
    }
  }, [listData, setAvailabilityData, setTimezone, setLabel])

  useEffect(() => {
    const detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    setTimezone(detectedTimezone)
  }, [])

  const handleCreateInitialAvailabilityDesc = () => {
    setLoadingSave(true)
    const dataToSave = {
      label,
      timezone,
    }
    console.log("dataToSave", dataToSave)
    craeteInitialAvailability(dataToSave)
  }

  const handleSaveAvailabilityDesc = () => {
    setLoadingSave(true)
    const dataToSave = {
      label,
      timezone,
    }
    console.log("dataToSave", dataToSave)
    saveAvailability(dataToSave)
  }

  const handleSaveAvailabilityTime = (newData: Day[]) => {
    const dataToSave = {
      days: newData,
    }
    console.log("dataToSave", dataToSave)
    saveAvailability(dataToSave)
  }

  const handleRemoveExtendAvailabilityTime = (
    dayID: string,
    timeID: string,
  ) => {
    console.log("data", dayID, timeID)
    removeExtendTimeAvailability.mutate({ dayID, timeID })
  }

  const handleSwitchChange = (dayIndex: number, checked: boolean) => {
    setLoadingSave(true)
    const newData = availabilityData?.map((day: Day, index: number) =>
      index === dayIndex ? { ...day, enabled: checked } : day,
    )
    setAvailabilityData(newData)
    handleSaveAvailabilityTime(newData)
  }

  const handleTimeChange = (
    dayID: string,
    timeID: string,
    timeType: "start_time" | "end_time",
    time: any,
  ) => {
    setLoadingSave(true)
    const { availabilityData, setAvailabilityData } =
      useAvailabilityStore.getState()

    const clonedAvailabilityData = JSON.parse(JSON.stringify(availabilityData))
    let isValid = true

    const updatedAvailabilityData = clonedAvailabilityData.map((day: Day) => {
      if (day.id === dayID) {
        const timeValue = time ? time.hour() * 100 + time.minute() : 0

        if (timeID === "main") {
          day[timeType] = timeValue
        } else {
          const extendTimes = day.extend_times.map((extendTime) => {
            if (extendTime.id === timeID) {
              extendTime[timeType] = timeValue
            }
            return extendTime
          })
          day.extend_times = extendTimes
        }

        const allTimes = [
          { id: "main", start_time: day.start_time, end_time: day.end_time },
          ...day.extend_times,
        ]

        allTimes.sort((a, b) => a.start_time - b.start_time)

        for (let i = 0; i < allTimes.length; i++) {
          const current = allTimes[i]
          const previous = i > 0 ? allTimes[i - 1] : null

          if (previous && current.start_time <= previous.end_time) {
            isValid = false
            message.warning(
              "Time slot overlaps with the previous slot. Please adjust the time.",
            )
            break
          }
          if (current.start_time >= current.end_time) {
            isValid = false
            message.warning(
              "Start time cannot be equal to or greater than end time. Please adjust the time.",
            )
            break
          }
        }

        if (isValid) {
          const mainTime = allTimes.shift()
          if (mainTime) {
            day.start_time = mainTime.start_time
            day.end_time = mainTime.end_time
          }
          day.extend_times = allTimes
        } else {
          setLoadingSave(false)
          return day
        }
      }
      return day
    })

    if (isValid) {
      setAvailabilityData(updatedAvailabilityData)
      handleSaveAvailabilityTime(updatedAvailabilityData)
    }
  }

  const addTimeSlot = (dayID: string) => {
    setLoadingSave(true)
    const { availabilityData, setAvailabilityData } =
      useAvailabilityStore.getState()

    const updatedAvailabilityData = availabilityData.map((day) => {
      if (day.id === dayID) {
        const extendTimes = day.extend_times || []

        let latestEndTime = day.end_time
        if (extendTimes.length > 0) {
          latestEndTime = extendTimes.reduce((latest, current) => {
            return current.end_time > latest ? current.end_time : latest
          }, latestEndTime)
        }

        const newStartTime = latestEndTime + 30

        extendTimes.push({
          start_time: newStartTime,
          end_time: newStartTime + 100,
        })

        return {
          ...day,
          extend_times: extendTimes,
        }
      }
      return day
    })

    setAvailabilityData(updatedAvailabilityData)
    handleSaveAvailabilityTime(updatedAvailabilityData)
  }

  const removeAvailabilityTime = (dayID: string, timeID: string) => {
    useAvailabilityStore.setState((state) => {
      const updatedAvailabilityData = state.availabilityData.map((day) => {
        if (day.id === dayID) {
          if (day.extend_times) {
            const updatedExtendTimes = day.extend_times.filter(
              (time) => time.id !== timeID,
            )
            return {
              ...day,
              extend_times: updatedExtendTimes,
            }
          }
        }
        return day
      })

      return {
        ...state,
        availabilityData: updatedAvailabilityData,
      }
    })
  }

  const removeTimeSlot = (dayID: string, timeID: string) => {
    setLoadingSave(true)
    removeAvailabilityTime(dayID, timeID)
    handleRemoveExtendAvailabilityTime(dayID, timeID)
  }

  return {
    listData,
    listLoading,
    listError,
    refetchListData,
    handleSaveAvailabilityDesc,
    timezone,
    label,
    saveAvailability,
    handleSaveAvailabilityTime,
    setTimezone,
    setLabel,
    handleSwitchChange,
    handleTimeChange,
    addTimeSlot,
    removeTimeSlot,
    handleCreateInitialAvailabilityDesc,
  }
}
