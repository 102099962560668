import React from "react"
import { Account } from "../../hooks/cv-builder/useProfile"
import { Card } from "antd"
import { CalendarOutlined, LinkOutlined } from "@ant-design/icons"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import { OtherCompany } from "../../../../assets/images"

interface Props {
  data: Account
}

export const CardCertification: React.FC<Props> = ({ data }) => {
  return (
    <Card className="shadow-md bg-[#FAFAFA] rounded-xl">
      <h2 className=" capitalize mb-5">Certifications</h2>
      {data?.certification?.map((item, index) => (
        <div key={index} className="flex gap-5 mb-5 last:mb-0">
          <img
            src={item.organisation.image || OtherCompany}
            alt="company"
            className="w-24 h-24 p-2 rounded-full shadow object-contain"
          />
          <div>
            <h3 className="capitalize">{item.organisation.name}</h3>
            <div className=" my-1">
              <Link
                to={item.credentials_url}
                target="_blank"
                className="mr-3 text-secondary"
              >
                <LinkOutlined className="" />
              </Link>
              <span className=" capitalize">{item.certification_name}</span>
            </div>
            <div className="flex gap-4 items-center">
              <CalendarOutlined />
              <div>
                <span>Expiration</span>
                <span className="ml-2 text-darkGrey">{`${
                  item.has_expiry_date
                    ? dayjs(item.end_at).format("MMM YYYY")
                    : "this certificate does not expire"
                }`}</span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Card>
  )
}
