import React from "react"
import { BasicContainerWithTitle } from "../../../../common/components"
import { Carousel } from "antd"
import useRoadmapSkills from "../../../missionControl/hooks/useRoadmapSkills"
import { useNavigate } from "react-router-dom"
import FiveCircleRateTS from "../../../../common/components/FiveCircleRateTS"
import { isNotNullOrUndefined } from "../../../../utils/util_funcs"
import { getInfoOnCurrentLevelOfSkillForUser } from "../../../missionControl/components/launchpad/util_funcs"

const CurrentTrajectory = () => {
  const { roadmapSkills, isFetchingRoadmap } = useRoadmapSkills()
  const navigate = useNavigate()

  return (
    <BasicContainerWithTitle
      isLoading={isFetchingRoadmap}
      title="Current Trajectory"
      extraTitle={<span className="text-primary cusror-pointer">see more</span>}
    >
      <Carousel arrows autoplay dots={false} className="p-3">
        {roadmapSkills?.data?.data?.map((skill) => {
          const lastLevel = skill?.levels
          const { passedCount } = getInfoOnCurrentLevelOfSkillForUser(
            lastLevel ?? [],
          )

          return (
            <div key={skill.id} className="p-2">
              <div
                onClick={() => navigate(`/skill/green-skills/${skill?.id}`)}
                className="text-center min-h-full flex flex-col justify-center items-center ring-1 p-4 ring-[#D9D9D9] bg-white rounded-[10px] cursor-pointer hover:shadow-lg  transition-transform duration-300"
              >
                <img src={skill?.image} className="w-[107px] object-contain" />
                <div className="">{skill?.name}</div>
                <div className="flex items-center justify-between mt-4 gap-x-4 w-full">
                  <div className="">Proficiency</div>
                  <FiveCircleRateTS
                    defaultValue={skill?.total_completed_level ?? 0}
                    secondValue={
                      isNotNullOrUndefined(
                        lastLevel?.[passedCount]?.user_track
                          ? lastLevel?.[passedCount]?.user_track?.current_module
                          : lastLevel?.[passedCount]?.user_track
                              ?.current_module,
                      ) &&
                      // @ts-ignore
                      (lastLevel?.[passedCount]?.user_track
                        ? lastLevel?.[passedCount]?.user_track?.current_module
                        : lastLevel?.[passedCount]?.user_track
                            ?.current_module) > 0
                    }
                    disabled
                    circleSize={15}
                  />
                </div>
              </div>
            </div>
          )
        })}
      </Carousel>
    </BasicContainerWithTitle>
  )
}

export default CurrentTrajectory
