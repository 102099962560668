import React from "react"
import { Route, Routes } from "react-router-dom"
import NotFound from "../auth/page/NotFound"
import ExperProfilePublic from "./public-expert/pages/DetailPublicExpert"
import Feed from "./feed/pages/Feed"
import ListPublicExperts from "./public-expert/pages/ListPublicExperts"
import ListFounder from "./founder/pages/ListFounder"
import FounderProfile from "./founder/pages/FounderProfile"
import ListSolution from "./solution/pages/ListSolution"
import DetailSolution from "./solution/pages/DetailSolution"
import ListAllCommunityUser from "./users/pages/ListAllCommunityUser"
import MentorList from "./mentor/profile/pages/MentorList"
import MyProfileMentor from "./mentor/profile/pages/MyProfileMentor"
import DetailMentor from "./mentor/profile/pages/DetailMentor"
import CommonUserDetail from "./users/pages/CommonUserDetail"
import CommunityProfile from "./users/pages/Profiles/CommunityProfile"
import MyCommunityProfile from "./users/pages/Profiles/MyCommunityProfile"
import { ProtectRoutes } from "../../routes"
import Post from "./feed/pages/Post"

interface IndexProps {}

const Index: React.FC<IndexProps> = () => {
  return (
    <div>
      <Routes>
        {/* PART OF FEED */}
        <Route path="/" element={<Feed />} />
        <Route path="/feed" element={<Feed />} />
        <Route path="/profile/:id" element={<CommunityProfile />} />
        <Route path="/my" element={<MyCommunityProfile />} />

        {/* COMMUNITYPOST */}
        <Route path="/post/:id" element={<Post />} />

        {/* PART OF EXPERT FOR LOGOUT USER */}
        <Route path="/public-expert" element={<ListPublicExperts />} />
        <Route
          path="/public-expert/:id/:username/:tab"
          element={<ExperProfilePublic />}
        />

        {/* PART OF FOUNDER */}
        <Route path="/founder" element={<ListFounder />} />
        <Route
          path="/founder/profile/:id/:username/:tab"
          element={<FounderProfile />}
        />

        {/* PART OF SOLUTION */}
        <Route path="/solution" element={<ListSolution />} />
        <Route path="/solution/:id" element={<DetailSolution />} />

        {/* ALL USER COMMUNITY */}
        <Route path="/member" element={<ListAllCommunityUser />} />
        <Route
          path="/member/talent/:id/:username"
          element={<CommonUserDetail />}
        />

        {/* PART OF MENTOR */}
        <Route path="/mentor" element={<MentorList />} />
        <Route
          path="/mentor/my-profile/:id/true"
          element={
            <ProtectRoutes>
              <MyProfileMentor />
            </ProtectRoutes>
          }
        />
        <Route path="/mentor/detail/:id" element={<DetailMentor />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  )
}

export default Index
