import { Button, Form, Input, message, Modal, Select } from "antd"
import React from "react"
import useCommunityModalsStore from "../../store/useCommunityModalsStore"
import { z } from "zod"
import { Controller, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useGreenSkillByCategory } from "../../../../hooks/useGSByCategory"
import { CloseOutlined } from "@ant-design/icons"
import { usePostArtcile } from "../../expert/apis/rest_article"
import useExpertProfile from "../hooks/useExpertProfile"

export const schema = z.object({
  title: z.string().trim().min(1, { message: "Title is required." }),
  green_skill_category: z.object({
    id: z.string().min(1, { message: "Skill category is required." }),
    name: z.string().min(1, { message: "Skill category is required." }),
  }),
  green_skill: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .array()
    .min(1, { message: "Select at least 1 and maximum 3 skills." })
    .max(3, { message: "Select at least 1 and maximum 3 skills" }),
})

export type TAddNewArticle = z.infer<typeof schema>

const ModalNewArticle: React.FC = () => {
  const newArticleModal = useCommunityModalsStore(
    (state) => state.newArticleModal,
  )
  const saveArticleMutation = usePostArtcile()

  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    formState: { isDirty, errors },
  } = useForm<TAddNewArticle>({
    resolver: zodResolver(schema),
    defaultValues: {
      title: "",
      green_skill_category: undefined,
      green_skill: [],
    },
  })

  const { data: listGS } = useGreenSkillByCategory(
    watch("green_skill_category")?.id,
  )

  React.useEffect(() => {
    if (!getValues("green_skill_category")?.id) {
      setValue("green_skill", [])
    }
  }, [watch("green_skill_category")?.id])

  const expertProfileQuery = useExpertProfile()

  const onSubmit = async (data: TAddNewArticle) => {
    await saveArticleMutation.mutateAsync(data, {
      onSuccess: () => {
        newArticleModal.close()
      },
      onError: (error: any) => {
        message.error({
          content: error?.response?.data?.message || "Something went wrong!",
        })
      },
    })
  }

  const handleRemoveSelectedGS = (id: string) => {
    const updatedSelectedSector = getValues("green_skill").filter(
      (selectedGreenSkillIds) => selectedGreenSkillIds?.id !== id,
    )
    setValue("green_skill", updatedSelectedSector)
  }

  return (
    <Modal
      centered
      onCancel={() => newArticleModal.close()}
      open={newArticleModal.isOpen}
      footer={null}
    >
      <div className="text-center">
        <h2 className="text-2xl font-bold">New Article</h2>
      </div>
      <Form className="mt-5" layout="vertical">
        <Form.Item
          className=""
          validateStatus={errors.title ? "error" : ""}
          help={errors.title?.message}
        >
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                id="title"
                className="font-default"
                placeholder="Topic"
              />
            )}
          />
        </Form.Item>

        <Form.Item
          className=""
          validateStatus={errors.green_skill_category ? "error" : ""}
          help={errors.green_skill_category?.message}
        >
          <Controller
            name="green_skill_category"
            control={control}
            render={({ field }) => (
              <Select
                onFocus={() => {}}
                className="w-full"
                placeholder="Select Domain"
                onSelect={(value, option) => {
                  field.onChange(option)
                }}
                options={
                  expertProfileQuery.data?.data?.data?.knowledge_domain || []
                }
                fieldNames={{ label: "name", value: "id" }}
              />
            )}
          />
        </Form.Item>
      </Form>

      {watch("green_skill_category")?.id && (
        <div className="!w-full">
          <Form.Item
            className=""
            validateStatus={errors.green_skill ? "error" : ""}
            help={errors.green_skill?.message}
          >
            <Controller
              name="green_skill"
              control={control}
              render={(field) => (
                <Select
                  showSearch
                  disabled={field.field.value?.length >= 3}
                  filterOption={(inputValue: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      ?.indexOf(inputValue.toLowerCase()) >= 0
                  }
                  onFocus={() => {}}
                  className="w-full"
                  placeholder="Select Green Skills"
                  onSelect={(value, option) => {
                    field.field.onChange([...field.field.value, option])
                  }}
                  options={listGS?.data?.data?.skills}
                  fieldNames={{ label: "name", value: "id" }}
                ></Select>
              )}
            />
          </Form.Item>

          {getValues("green_skill_category") && (
            <div className="mt-6 flex flex-col md:flex-row gap-4 md:w-full ">
              <div className="flex flex-wrap items-center gap-2">
                {watch("green_skill")?.map((sector, index) => (
                  <div
                    className="rounded-lg ring-1  ring-dirtyBlue flex bg-[#F3F3F6] items-center gap-4 w-auto text-xs px-[15px] py-2"
                    key={index}
                  >
                    <p className="m-0 line-clamp-1">{sector?.name}</p>
                    <CloseOutlined
                      className="text-xs cursor-pointer"
                      onClick={() => handleRemoveSelectedGS(sector.id)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
      <div className="flex justify-end mt-5">
        <Button
          className="rounded-md"
          block
          onClick={handleSubmit(onSubmit)}
          type="primary"
          loading={saveArticleMutation?.isLoading}
          disabled={!isDirty}
        >
          Create
        </Button>
      </div>
    </Modal>
  )
}

export default ModalNewArticle
