import dayjs from "dayjs"

export const getTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const getCurrentMonthDateRange = () => {
  const startOfMonth = dayjs().startOf("month").format("YYYY-MM-DD")
  const endOfMonth = dayjs().endOf("month").format("YYYY-MM-DD")
  return `${startOfMonth},${endOfMonth}`
}
