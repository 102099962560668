import instance from "../../../../config/config"
import {
  ApiResponse,
  ApiResponseGeneric,
} from "../../../../common/types/ApiResponse"
import { ResponseComment, ThisWeeksDiscussion } from "../types/commonn"

export const getThisWeeksPost = (
  filter: "upcoming" | "ongoing" | "popular",
) => {
  return instance.get<ApiResponseGeneric<ThisWeeksDiscussion[]>>(
    `/community/this-week-topics`,
    {
      params: {
        filter,
      },
    },
  )
}

export const getThisWeeksPostDetail = (discussion_id: string) => {
  return instance.get<ApiResponse<ThisWeeksDiscussion>>(
    `/community/this-week-topics/${discussion_id}`,
  )
}

export const addCommentToDiscussion = ({
  discussion_id,
  comment,
}: {
  discussion_id: string
  comment: { body: string }
}) => {
  return instance.post<ApiResponse<ResponseComment>>(
    `/community/this-week-topics/${discussion_id}/comment`,
    comment,
  )
}

export const addRemoveResponse = (discussion_id: string) => {
  return instance.patch<ApiResponseGeneric<string>>(
    `/community/this-week-topics/${discussion_id}/response`,
  )
}

export const addUniqueView = (discussion_id: string) => {
  return instance.patch<ApiResponseGeneric<string>>(
    `/community/this-week-topics/${discussion_id}/view`,
  )
}
