import React from "react"
import { Card, Input, Select } from "antd"
import { SearchOutlined, CloseOutlined } from "@ant-design/icons"

interface ListMentorFilterProps {
  queryMentor?: any
  referenceData?: any
}

const ListMentorFilter: React.FC<ListMentorFilterProps> = ({
  queryMentor,
  referenceData,
}) => {
  return (
    <div>
      <Card className="bg-white-custom shadow-custom-sm rounded-xl py-1">
        <div className="flex justify-between items-center">
          <div className="flex flex-col md:flex-row md:gap-6 w-full">
            <div className="flex w-full md:w-8/12">
              <div
                className="flex w-full"
                style={{ borderBottom: "1px solid #D0DDE5" }}
              >
                <SearchOutlined />
                <Input
                  onChange={(e: any) => queryMentor?.handleSearch(e)}
                  bordered={false}
                  placeholder="search"
                />
              </div>
            </div>
            <div className="!bg-grey-select w-full md:w-1/6 relative  mt-4 md:mt-0 flex items-center gap-3 md:gap-7">
              <div className="w-full !bg-grey-select">
                <Select
                  className="my-1 font-default basis-full job-history w-full"
                  placeholder="Location"
                  value="Location"
                  showSearch
                  loading={referenceData?.locationLoading}
                  options={referenceData?.locationData?.map((val: any) => ({
                    label: val,
                    value: val,
                  }))}
                  onChange={(value, option) => {
                    queryMentor?.handleFilterByLocation(option)
                  }}
                  labelInValue={true}
                  filterOption={(input: any, option: any) => {
                    if (option && option?.label) {
                      return (
                        option?.label
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      )
                    }
                    return false
                  }}
                />
              </div>
            </div>
            <div className="!bg-grey-select w-full md:w-2/6 relative  mt-4 md:mt-0 flex items-center gap-3 md:gap-7">
              <div className="w-full !bg-grey-select">
                <Select
                  className="my-1 font-default basis-full job-history w-full"
                  placeholder="Mentorship Areas"
                  value="Mentorship Areas"
                  showSearch
                  loading={referenceData?.areaLoading}
                  options={referenceData?.areaData?.map((val: any) => ({
                    label: val.name,
                    value: val.id,
                  }))}
                  onChange={(value, option) => {
                    queryMentor?.handleFilterByArea(option)
                  }}
                  labelInValue={true}
                  filterOption={(input: any, option: any) => {
                    if (option && option?.label) {
                      return (
                        option?.label
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      )
                    }
                    return false
                  }}
                />
              </div>
            </div>
            <div className="!bg-grey-select w-full md:w-2/6 relative  mt-4 md:mt-0 flex items-center gap-3 md:gap-7">
              <div className="w-full !bg-grey-select">
                <Select
                  className="my-1 font-default basis-full job-history w-full"
                  placeholder="Domains of Expertise"
                  value="Domains of Expertise"
                  loading={referenceData?.domainLoading}
                  showSearch
                  options={referenceData?.domainData?.map((val: any) => ({
                    label: val.name,
                    value: val.id,
                  }))}
                  onChange={(value, option) => {
                    queryMentor?.handleFilterByDomain(option)
                  }}
                  labelInValue={true}
                  filterOption={(input: any, option: any) => {
                    if (option && option?.label) {
                      return (
                        option?.label
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      )
                    }
                    return false
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className={`grid ${
            queryMentor?.locationSelected?.length > 0 &&
            queryMentor?.areaSelected?.length > 0
              ? "grid-cols-3"
              : "grid-cols-1"
          } `}
        >
          <div>
            {queryMentor?.locationSelected?.length > 0 && (
              <div className="mt-3 flex gap-2">
                <div className=" font-semibold">Location:</div>
                <div className="flex items-center gap-2 flex-wrap">
                  {queryMentor?.locationSelected?.map((location: any) => (
                    <div
                      id={location}
                      key={location}
                      className="bg-white px-2 shadow-md flex items-center gap-x-1 rounded"
                      style={{ border: "1px solid #dedede" }}
                    >
                      {location.label}
                      <CloseOutlined
                        onClick={() =>
                          queryMentor?.handleRemoveLocation(location)
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div>
            {queryMentor?.areaSelected?.length > 0 && (
              <div className="mt-3 flex gap-2">
                <div className=" font-semibold">MentorAreas::</div>
                <div className="flex items-center gap-2 flex-wrap">
                  {queryMentor?.areaSelected?.map((type: any) => (
                    <div
                      id={type}
                      key={type}
                      className="bg-white px-2 shadow-md flex items-center gap-x-1 rounded"
                      style={{ border: "1px solid #dedede" }}
                    >
                      {type.label}
                      <CloseOutlined
                        onClick={() => queryMentor?.handleRemoveArea(type)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div>
            {queryMentor?.domainSelected?.length > 0 && (
              <div className="mt-3 flex gap-2">
                <div className=" font-semibold">MentorAreas::</div>
                <div className="flex items-center gap-2 flex-wrap">
                  {queryMentor?.domainSelected?.map((type: any) => (
                    <div
                      id={type}
                      key={type}
                      className="bg-white px-2 shadow-md flex items-center gap-x-1 rounded"
                      style={{ border: "1px solid #dedede" }}
                    >
                      {type.label}
                      <CloseOutlined
                        onClick={() => queryMentor?.handleRemoveDomain(type)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  )
}

export default ListMentorFilter
