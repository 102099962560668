// eslint-disable-next-line
import { UseQueryResult, useQuery } from "react-query"
// eslint-disable-next-line
import { ApiResponsePaginated2 } from "../../../common/types/ApiResponse"
// eslint-disable-next-line
import { AxiosError, AxiosResponse } from "axios"
import { message } from "antd"
import instance from "../../../config/config"
import { ExpertResponse } from "../types/response"

export const QUERYNAMEEXPERTLIST = "experts-list"

export const useFetchExpertList = (): UseQueryResult<
  ApiResponsePaginated2<ExpertResponse>,
  AxiosError
> => {
  const fetchData = async () => {
    try {
      const res = await instance.get<ExpertResponse>("user-experts/all", {})

      return res?.data
    } catch (error: any) {
      message.error(
        `An error occurred while fetching ${QUERYNAMEEXPERTLIST}: + ${error.message}`,
      )
    }
  }
  return useQuery([QUERYNAMEEXPERTLIST], fetchData, {
    onError: (error: AxiosError) => {
      message.error(
        `An error occurred while fetching ${QUERYNAMEEXPERTLIST}: + ${error.message}`,
      )
    },
  })
}
