import React from "react"
import FiveCircleRateTS from "../../../../common/components/FiveCircleRateTS"
import { Job } from "../../types/jobBoard"
import { Skeleton } from "antd"

interface Props {
  data: Job | undefined
  isLoading: boolean
}
export const DetailGreenSkill: React.FC<Props> = ({ data, isLoading }) => {
  return (
    <div className=" p-5 rounded-xl shadow-md bg-[#fafafa] w-full">
      <h3 className="mb-3 capitalize">green skills</h3>
      <div>
        {isLoading
          ? [1, 2, 3].map((i) => (
              <div
                key={i}
                className="flex md:gap-5 gap-2 md:items-center  md:flex-row flex-col rounded px-3 py-1 my-2 bg-[#F0F2F5] md:h-14"
                style={{ border: "1px solid #D0DDE5" }}
              >
                <div className="flex gap-2 animate-pulse">
                  <div className="h-4 w-4 rounded-full  bg-slate-400"></div>
                  <div className="h-4 w-4 rounded-full  bg-slate-400"></div>
                  <div className="h-4 w-4 rounded-full  bg-slate-400"></div>
                  <div className="h-4 w-4 rounded-full  bg-slate-400"></div>
                  <div className="h-4 w-4 rounded-full  bg-slate-400"></div>
                </div>
                <Skeleton
                  title={false}
                  style={{ width: "100%" }}
                  active
                  paragraph={{ rows: 1 }}
                />
              </div>
            ))
          : data?.green_skills?.map((item, i) => (
              <div
                key={i}
                className="flex md:gap-5 gap-2 md:items-center  md:flex-row flex-col rounded px-3 py-1 my-2 bg-[#F0F2F5] md:h-14"
                style={{ border: "1px solid #D0DDE5" }}
              >
                <FiveCircleRateTS
                  count={5}
                  defaultValue={item.proficiency}
                  circleSize={16}
                  disabled
                />
                <div className="md:text-left">{item.name}</div>
              </div>
            ))}
      </div>
    </div>
  )
}
