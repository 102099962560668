import { useEffect, useState } from "react"
import { UploadFile, UploadProps, message } from "antd"
import { ClimateSolutionDataStartup, useFormCompany } from "./useFormFounder"
import {
  useDetailFounder,
  useListFounder,
} from "../../founder/hooks/useFounder"
import { useFetchDetailAPIV2, useWrite } from "../../../../hooks/generic_rest"

import { SubmitHandler } from "react-hook-form"
import { STARTUP_ENDPOINTS } from "../../solution/apis/endpoint"
import { useFounderReference } from "../../founder/hooks/useFounderReference"
import {
  FounderResponse,
  ProductResponse,
  UserProfileResponse,
} from "../../founder/types/response"
import {
  FOUNDER_ENDPOINTS,
  FOUNDER_REFERENCE_ENDPOINTS,
} from "../../founder/apis/endpoint"

const useClimateSolution = (
  id?: string,
  username?: string,
  activeAccount?: any,
  tab?: string,
) => {
  // const [showEditFormClimate, setShowEditFormClimate] = useState<boolean>(false)

  // const { isDisplayed, toggleDisplay } = useDisplayStateClimateEditForm()

  //  ('isDisplayed', isDisplayed)

  const [file, setFile] = useState<UploadFile | null>(null)
  const [founderEditData, setFounderEditData] = useState<
    FounderResponse[] | null
  >(null)
  const [productEditData, setProductEditData] = useState<
    ProductResponse[] | null
  >(null)

  const payload = new FormData()

  // const handleShowEditFormClimate = () => {
  //   setShowEditFormClimate(true)
  // }

  // const handleCloseEditFormClimate = () => {
  //   setShowEditFormClimate(false)
  // }

  // REFERENCE DATA
  const { stageData, solutionData, locationData } = useFounderReference()
  const { founderData, queryFounder } = useListFounder()
  const {
    data: listUser,
    isLoading: listLoadingUser,
    isError: detailErrorUser,
    refetch: refetchListUser,
  } = useFetchDetailAPIV2<UserProfileResponse[]>(
    `${FOUNDER_REFERENCE_ENDPOINTS.USERS}`,
    {},
    "list-user-account",
  )

  const { detail } = useDetailFounder(
    id!,
    username!,
    activeAccount,
    parseInt(tab!),
  )

  const { handleSubmit, control, watch, setValue, errors } = useFormCompany()

  const { mutate: saveEditStartup } = useWrite<any, any>(
    STARTUP_ENDPOINTS.EDIT(detail?.detailFounder?.startup?.id!),
    "patch",
    () => message.success("Data created successfully"),
    () => message.error("An error occurred"),
  )

  const onSubmit: SubmitHandler<ClimateSolutionDataStartup> = (
    data: ClimateSolutionDataStartup,
  ) => {
    payload.append("name", data.name)
    payload.append("website", data.website)

    if (data?.stage_id !== detail?.detailFounder?.startup?.stage?.name) {
      payload.append("stage_id", data.stage_id)
    }
    if (data?.location_id !== detail?.detailFounder?.startup?.location?.name) {
      payload.append("location_id", data.location_id)
    }

    payload.append("file", data?.file)

    payload.append("short_description", data.short_description)
    payload.append("long_description", data.long_description)
    // payload.append("logo", file as any)

    saveEditStartup(payload)
  }

  useEffect(() => {
    if (detail) {
      setValue("name", detail?.detailFounder?.startup?.name!)
      setValue("stage_id", detail?.detailFounder?.startup?.stage?.name!)
      setValue("location_id", detail?.detailFounder?.startup?.location?.name!)
      setValue("website", detail?.detailFounder?.startup?.website!)
      setValue(
        "short_description",
        detail?.detailFounder?.startup?.short_description!,
      )
      setValue(
        "long_description",
        detail?.detailFounder?.startup?.long_description!,
      )

      setFile({
        uid: "-1",
        name: "image.png",
        status: "done",
        url: detail?.detailFounder?.startup?.logo!,
      })

      setFounderEditData(detail?.detailFounder?.startup?.founders || [])

      setProductEditData(detail?.detailFounder?.startup?.products || [])
    }
  }, [detail?.detailLoadingFounder])

  const onSelectChangeStage = (label: string, value: string) => {
    setValue("stage_id", value)
  }
  const onSelectChangeLocation = (label: string, value: string) => {
    setValue("location_id", value)
  }

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj as any)
        reader.onload = () => resolve(reader.result as string)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow?.document.write(image.outerHTML)
  }

  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFile(newFileList.length > 0 ? newFileList[0] : null)
    if (newFileList.length === 0) {
      setFile(null)
    }
  }

  const beforeUpload = (newFile: UploadFile) => {
    setFile(newFile)
    setValue("file", newFile)
    return true
  }

  const handleSave = () => {
    handleSubmit(onSubmit)()
  }

  const { mutate: saveEditFounder } = useWrite<any, any>(
    FOUNDER_ENDPOINTS.INVITE,
    "post",
    () => {
      message.success("Invite successfully")
    },
    () => message.error("An error occurred"),
  )

  const handleSelectInviteFounder = (value: any) => {
    const selectedUserData = listUser?.find(
      (item: UserProfileResponse) => item.id === value?.value,
    )

    if (selectedUserData) {
      const formDataFounder = new FormData()
      formDataFounder.append("startup_id", detail?.detailFounder?.startup?.id!)
      formDataFounder.append("founder_id", selectedUserData?.id)

      saveEditFounder(formDataFounder)

      const newFounderData = [...founderEditData!, selectedUserData]

      // set to be co founders
      const newData = newFounderData?.map((user: any) => ({
        ...user,
        label: "co founder",
      }))

      setFounderEditData(newData)

      refetchListUser()
    }
  }

  return {
    // domHandlers: {
    //   isDisplayed,
    //   toggleDisplay
    // },

    editCompanyHandlers: {
      handleSave,
      onSelectChangeStage,
      onSelectChangeLocation,
      control,
      watch,
      setValue,
      errors,
      founderEditData,
      productEditData,
      handleSelectInviteFounder,
      uploadImage: {
        file,
        onChange,
        onPreview,
        beforeUpload,
      },
      referenceData: {
        stageData,
        solutionData,
        locationData,

        listUser,
        listLoadingUser,
        detailErrorUser,
      },
      founderData,
      queryFounder,
    },
  }
}

export default useClimateSolution
