import { DeleteOutlined } from "@ant-design/icons"
import { Button, Input } from "antd"
import React from "react"
import { Controller } from "react-hook-form"
import { RichTextMinimize } from "../../../../common/components/RichTextMinimize"
import SelectComponent from "./SelectComponent"

interface ProductSectionProps {
  editProductHandlers?: any
}

const ProductSection: React.FC<ProductSectionProps> = ({
  editProductHandlers,
}) => {
  // const MAX_COUNT = 3
  return (
    <div className="shadow-custom-sm p-2 md:py-3 md:px-6 rounded-xl">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        <div className="col-span-1 space-y-3">
          <div className="space-y-3">
            <Controller
              name="name"
              control={editProductHandlers?.control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  status={editProductHandlers?.errors?.name ? "error" : ""}
                  className="my-1 font-default rounded basis-1/2"
                  placeholder="Product Name"
                  value={field.value}
                />
              )}
            />
            <Controller
              name="solutions_id"
              control={editProductHandlers?.control}
              rules={{ required: true }}
              render={({ field }) => (
                <SelectComponent
                  field={field}
                  editProductHandlers={editProductHandlers}
                />
              )}
            />

            <Controller
              name="intended_user"
              control={editProductHandlers?.control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  status={
                    editProductHandlers?.errors?.intended_user ? "error" : ""
                  }
                  className="my-1 font-default rounded basis-1/2"
                  placeholder="Intended users (e.g. recruiters)"
                  value={field.value}
                />
              )}
            />
            <div className="flex items-center justify-end gap-3">
              <Button
                type="primary"
                onClick={() => editProductHandlers?.handleSave()}
              >
                Save
              </Button>
              <Button
                className="px-2 bg-[#FFF2E8]"
                onClick={() => editProductHandlers.handleDeleteProduct()}
              >
                <DeleteOutlined />
              </Button>
            </div>
          </div>
        </div>
        <div className="col-span-3 space-y-3">
          <Controller
            name="description"
            control={editProductHandlers?.control}
            rules={{ required: true }}
            render={({ field }) => (
              <RichTextMinimize
                {...field}
                className="h-[8.2rem]"
                placeholder="Describe how your solution works"
                value={field.value}
              />
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default ProductSection
