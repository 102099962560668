import { useMemo } from "react"
import { useQuery } from "react-query"
import instance from "../../../config/config"
import { handlingError } from "../../../utils/handling"

const useRecomendationsJobs = (userId) => {
  const queryKey = useMemo(
    () => [
      "job-recomendation-list",
      {
        //   company_ids,
        //   job_role_id,
      },
    ],
    [],
  )

  const fetchListJobRecomendation = async () => {
    try {
      const { data } = await instance.get(
        `/job-boards/recommendations/${userId}`,
        {
          params: {
            // company_ids,
            // job_role_id,
          },
        },
      )
      return data
    } catch (error) {
      return handlingError(error?.response?.data?.message, error)
    }
  }

  const { data, isLoading } = useQuery(queryKey, fetchListJobRecomendation)
  const listJobRecomendation = data?.data
  const isListJobRecomendationLoading = isLoading

  return {
    listJobRecomendation,
    isListJobRecomendationLoading,
  }
}

export default useRecomendationsJobs
