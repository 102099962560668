/* eslint-disable */

import React from "react"
import { SkillVerification } from "./useSkillVerification"
import { set, useForm } from "react-hook-form"
import { useMutation } from "react-query"
import instance from "../../../../config/config"
import { message } from "antd"
import { AxiosResponse } from "axios"

export const useSkillVerificationUpdate = (
  data: SkillVerification,
  index: number,
  callback: (index: number, data: SkillVerification) => void,
) => {
  const [pdfUrl, setPdfUrl] = React.useState<string>("")
  const [pdfFile, setPdfFile] = React.useState<File>()
  const [isErrorFile, setIsErrorFile] = React.useState<boolean>(false)

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      description: data.description,
      green_skill_id: data?.green_skill.id
        ? {
            label: data.green_skill.name,
            value: data.green_skill.id,
          }
        : null,
    },
  })

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event
    if (target.files && target.files.length > 0) {
      const file = target.files[0]
      const fileUrl = URL.createObjectURL(file)
      setPdfFile(file)
      setPdfUrl(fileUrl)
      setIsErrorFile(false)
    }
  }

  const { mutateAsync: create, isLoading: loadingCreate } = useMutation({
    mutationFn: (val: any) => {
      return instance.post(`/verified-skills`, val)
    },
    mutationKey: "skill-verification-create",
  })

  const { mutateAsync: update, isLoading: loadingUpdate } = useMutation({
    mutationFn: async (val: any) => {
      try {
        const res = await instance.patch(`/verified-skills/${val?.id}`, val.f)
        return res
      } catch (error) {
        throw error
      }
    },
    mutationKey: "skill-verification-update",
  })

  const onSubmit = async (val: any) => {
    try {
      if (!data?.id && !pdfFile) {
        setIsErrorFile(true)
        return
      }
      message.open({
        key: "update-skill-verification",
        content: "updating...",
        type: "loading",
        duration: 0,
      })

      const f = new FormData()
      f.append("description", val.description)
      f.append(
        "green_skill_id",
        val?.green_skill_id?.value || val?.green_skill_id,
      )
      if (pdfFile) {
        f.append("file", pdfFile)
      }

      let response: AxiosResponse<any, any>
      if (data?.id) {
        response = await update({ f, id: data.id })
      } else {
        response = await create(f)
      }
      if (response) {
        const finalData: SkillVerification = {
          id: response.data?.data?.id,
          created_at: response.data?.data?.created_at,
          description: response.data?.data?.description,
          document_link: response.data?.data?.document_link,
          document_name: response.data?.data?.document_name,
          green_skill: {
            id: response.data?.data?.green_skill.id,
            name: response.data?.data?.green_skill.name,
          },
          green_skill_id: response.data?.data?.green_skill_id,
          status: response.data?.data?.status,
          verified_at: response.data?.data?.verified_at,
          submitted_at: response.data?.data?.submitted_at,
          isEdit: false,
        }
        message.destroy()
        callback(index, finalData)
      }
    } catch (error: any) {
      message.open({
        key: "update-skill-verification",
        content:
          JSON.stringify(error?.response?.data?.data) ||
          "Internal server error",
        type: "error",
      })
    }
  }

  return {
    form: {
      control,
      errors,
      handleSubmit,
      watch,
      register,
      setValue,
      isErrorFile,
    },
    tempFile: {
      handleFileChange,
      pdfUrl,
      pdfFile,
    },
    actions: {
      onSubmit,
      isLoading: loadingCreate || loadingUpdate,
    },
  }
}
