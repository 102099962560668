import { Divider } from "antd"
import { ICommunityPost } from "../types/commonn"
import { LikeOutlined, CommentOutlined } from "@ant-design/icons"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import { useNavigate } from "react-router-dom"
import AvatarCustomV2 from "../../../../common/components/AvatarCustomV2"

dayjs.extend(relativeTime)

const PostDiscussionCard = ({
  post,
  observ,
}: {
  post?: ICommunityPost
  observ?: (node: Element | null) => void
}) => {
  const navigate = useNavigate()
  return (
    <div
      ref={observ}
      onClick={() => navigate(`/community/post/${post?.id}`)}
      className="flex items-center justify-between ring-1 ring-gray-300 p-5 rounded-[10px] cursor-pointer hover:scale-105 duration-300 transition-all"
    >
      <div className="flex-grow flex items-center gap-2">
        <AvatarCustomV2 size={40} src={post?.user?.avatar} />
        <div className="">
          <h3 className="line-clamp-1 !text-sm font-semibold">
            {post?.title ? post?.title : "No Title Available"}
          </h3>
          <div className="text-[12px] font-normal flex items-center text-darkGrey">
            <div className="">by {post?.user?.username}</div>
            <Divider type="vertical" className="!border-darkGrey" />
            <div className="">
              Posted {dayjs(post?.created_at)?.fromNow(true)}
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2">
          <LikeOutlined className="transition-all duration-200" />
          <span>{post?.total_responses}</span>
        </div>
        <div className="flex items-center gap-2">
          <CommentOutlined className="transition-all duration-200" />
          <span>{post?.total_comments}</span>
        </div>
      </div>
    </div>
  )
}

export default PostDiscussionCard
