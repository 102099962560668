import React from "react"
import { Tag } from "antd"
import {
  HeartFilled,
  HeartOutlined,
  EnvironmentOutlined,
  UserOutlined,
  LinkOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  FacebookOutlined,
  InstagramOutlined,
  GithubOutlined,
} from "@ant-design/icons"
import { Company } from "../../types/company"
import { Link } from "react-router-dom"
import { DefaultCompanyLogoImg } from "../../../../assets/images"

interface Props {
  data: Company | undefined
  isFollow: boolean
  followHandler: {
    handleFollowCompany: (val: any) => void
    handleUnfollowCompany: (val: any) => void
    loading: boolean
  }
}
export const DetailCompany: React.FC<Props> = ({
  data,
  isFollow,
  followHandler,
}) => {
  return (
    <div className=" p-5 rounded-xl shadow-md bg-[#fafafa] w-full">
      <div className="flex items-center justify-between">
        <img
          className="h-[52px] object-contain"
          src={data?.logo ?? DefaultCompanyLogoImg}
          alt="company"
        />
        <Tag
          bordered={false}
          onClick={(e) => {
            if (!followHandler.loading) {
              if (isFollow) {
                followHandler.handleUnfollowCompany(data?.id)
              } else {
                followHandler.handleFollowCompany({
                  id: data?.id,
                  name: data?.name,
                })
              }
            }
            e.stopPropagation()
          }}
          color={isFollow ? undefined : "volcano"}
          className={`${
            isFollow ? "bg-primary-bg" : "text-backdrop"
          } max-w-full overflow-clip group hover:scale-105 duration-200 transition-all cursor-pointer`}
          icon={
            isFollow ? (
              <HeartFilled key={"favorite"} className="!text-primary" />
            ) : (
              <HeartOutlined className="hover:text-primary" key="favorite" />
            )
          }
        >
          {isFollow ? "Following" : "Follow"}
        </Tag>
      </div>
      <div className="mt-3 text-base">{data?.name}</div>
      <div className="flex items-baseline gap-2 my-3 text-darkGrey">
        <EnvironmentOutlined />
        <div>{data?.headquarter || "unknown"}</div>
      </div>
      <div className="flex items-baseline gap-2 text-darkGrey">
        <UserOutlined />
        <div>{helperSize(data?.size || "")}</div>
      </div>
      <div className="my-3">{data?.description}</div>
      <div className="flex gap-3">
        {data?.website_url && (
          <Link to={data?.website_url} target="_blank">
            <LinkOutlined />
          </Link>
        )}
        {data?.linkedin_url && (
          <Link to={data?.linkedin_url} target="_blank">
            <LinkedinOutlined />
          </Link>
        )}
        {data?.twitter_url && (
          <Link to={data?.twitter_url} target="_blank">
            <TwitterOutlined />
          </Link>
        )}
        {data?.instagram_url && (
          <Link to={data?.instagram_url} target="_blank">
            <InstagramOutlined />
          </Link>
        )}
        {data?.facebook_url && (
          <Link to={data?.facebook_url} target="_blank">
            <FacebookOutlined />
          </Link>
        )}
        {data?.github_url && (
          <Link to={data?.github_url} target="_blank">
            <GithubOutlined />
          </Link>
        )}
      </div>
    </div>
  )
}

const helperSize = (size: string): string => {
  if (size.includes("employees")) {
    return size.replace(" employees", "")
  } else {
    return "1"
  }
}
