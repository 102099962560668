import React, { useState } from "react"
import { HomeOutlined } from "@ant-design/icons"
import { PageContent, PageHeader } from "../../../common/layout"
import { Perk, usePerks } from "../../home/hook/usePerks"
import { PerkCard } from "../../home/components/PerkCard"
import { Button, Image } from "antd"
import { PerkDetailModal } from "../components"
import { LoadingOutlined } from "@ant-design/icons"
import { Moon } from "../../../assets/images"

const RewardsAndPerks = () => {
  const [selectedPerk, setSelectedPerk] = useState<Perk | null>(null)

  return (
    <>
      <PageContent
        header={
          <PageHeader
            breadcrumbItems={items}
            title={"Rewards & Perks"}
            isLoading={false}
          />
        }
        content={<Content setSelectedPerk={setSelectedPerk} />}
      />
      <PerkDetailModal
        perk={selectedPerk}
        open={!!selectedPerk}
        onCancel={() => setSelectedPerk(null)}
      />
    </>
  )
}

const Content = ({
  setSelectedPerk,
}: {
  setSelectedPerk: (perk: Perk) => void
}) => {
  const perksQuery = usePerks()
  return (
    <>
      <div className="p-4 mb-4 bg-white-custom shadow-custom rounded-lg pb-5 mt-4">
        {perksQuery.isLoading ? (
          <div className="flex justify-center items-center h-96">
            <LoadingOutlined className="text-primary text-4xl" />
          </div>
        ) : !perksQuery?.data?.data?.data ? (
          // <div className="w-full mx-auto rounded-lg bg-white flex justify-center text-center  py-6">
          <div className=" flex w-full flex-col justify-center items-center">
            <Image src={Moon} alt="moon image" preview={false} width={100} />
            <p className="my-4">No Data</p>
          </div>
        ) : (
          <div className="grid grid-cols-12 gap-4">
            {perksQuery?.data?.data?.data?.map((perk, idx) => (
              <PerkCard
                key={perk.id + idx}
                perk={perk}
                className="col-span-full lg:col-span-4"
                extraContent={
                  <Button
                    onClick={() => setSelectedPerk(perk)}
                    className="mx-auto bg-primary mt-4 text-white"
                  >
                    View Details
                  </Button>
                }
              />
            ))}
          </div>
        )}
      </div>
    </>
  )
}

const items = [
  {
    path: "/",
    title: <HomeOutlined className="text-gray-400" />,
  },
  {
    title: "Rewards",
  },
]

export default RewardsAndPerks
