import { Card, Select } from "antd"
import { Input } from "antd"
import { SearchOutlined, CloseOutlined } from "@ant-design/icons"
import React from "react"
import { useExpertReference } from "../hooks/useExpertReferences"

interface ListExpertFilterProps {
  queryExpert: any
  referenceData?: any
}

const ListExpertFilter: React.FC<ListExpertFilterProps> = ({ queryExpert }) => {
  const { domainData, locationData } = useExpertReference()

  return (
    <div>
      <Card className="py-1 bg-white-custom shadow-custom-sm rounded-xl">
        <div className="flex items-center justify-between">
          <div className="flex flex-col w-full md:flex-row md:gap-6">
            <div className="flex w-full md:w-8/12">
              <div
                className="flex w-full"
                style={{ borderBottom: "1px solid #D0DDE5" }}
              >
                <SearchOutlined />
                <Input
                  onChange={(e: any) => queryExpert?.handleSearch(e)}
                  bordered={false}
                  placeholder="search"
                />
              </div>
            </div>
            <div className="!bg-grey-select w-full md:w-1/6 relative  mt-4 md:mt-0 flex items-center gap-3 md:gap-7">
              <div className="w-full !bg-grey-select">
                <Select
                  className="w-full my-1 font-default basis-full job-history"
                  placeholder="Location"
                  showSearch
                  options={locationData?.listLocations?.map((val: any) => ({
                    label: val,
                    value: val,
                  }))}
                  value={"Location"}
                  onChange={(value, option) => {
                    queryExpert?.handleFilterByLocation(option)
                  }}
                  labelInValue={true}
                  filterOption={(input: any, option: any) => {
                    if (option && option?.label) {
                      return (
                        option?.label
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      )
                    }
                    return false
                  }}
                />
              </div>
            </div>
            <div className="!bg-grey-select w-full md:w-2/6 relative  mt-4 md:mt-0 flex items-center gap-3 md:gap-7">
              <div className="w-full !bg-grey-select">
                <Select
                  className="w-full my-1 font-default basis-full job-history"
                  placeholder="Knowledge Domain"
                  value="Knowledge Domain"
                  showSearch
                  options={domainData?.listDomain?.map((val: any) => ({
                    label: val.name,
                    value: val.id,
                  }))}
                  onChange={(value, option) => {
                    queryExpert?.handleFilterByDomain(option)
                  }}
                  labelInValue={true}
                  filterOption={(input: any, option: any) => {
                    if (option && option?.label) {
                      return (
                        option?.label
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      )
                    }
                    return false
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`grid ${
            queryExpert?.locationSelected?.length > 0 &&
            queryExpert?.domainSelected?.length > 0
              ? "grid-cols-2"
              : "grid-cols-1"
          } `}
        >
          <div>
            {queryExpert?.locationSelected?.length > 0 && (
              <div className="flex gap-2 mt-3">
                <div className="font-semibold ">Sector:</div>
                <div className="flex flex-wrap items-center gap-2">
                  {queryExpert?.locationSelected?.map((location: string) => (
                    <div
                      id={location}
                      key={location}
                      className="flex items-center px-2 bg-white rounded shadow-md gap-x-1"
                      style={{ border: "1px solid #dedede" }}
                    >
                      {location}
                      <CloseOutlined
                        onClick={() =>
                          queryExpert?.handleRemoveLocation(location)
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div>
            {queryExpert?.domainSelected?.length > 0 && (
              <div className="flex gap-2 mt-3">
                <div className="font-semibold ">Sector:</div>
                <div className="flex flex-wrap items-center gap-2">
                  {queryExpert?.domainSelected?.map((domain: any) => (
                    <div
                      id={domain}
                      key={domain}
                      className="flex items-center px-2 bg-white rounded shadow-md gap-x-1"
                      style={{ border: "1px solid #dedede" }}
                    >
                      {domain?.label}
                      <CloseOutlined
                        onClick={() => queryExpert?.handleRemoveDomain(domain)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  )
}

export default ListExpertFilter
