import { message } from "antd"
import {
  useFetchDetail,
  useFetchList,
  useWrite,
} from "../../../../../hooks/generic_rest"
import { MENTOR_ENDPOINTS } from "../apis/endpoint"
import { MentorArea, MentorDomain, MentorResponse } from "../types/response"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useForm } from "react-hook-form"
import React, { useEffect, useState } from "react"
import instance, { instanceV2 } from "../../../../../config/config"
import { AxiosResponse } from "axios"
import { Common } from "../../../../skillGalaxy/hooks/useSkillDropdown"
import { debounce } from "../../../../../utils/debounce"
import {
  useSelectedAreaItemsStore,
  useSelectedDomainItemsStore,
  useSelectedLocationItemIdStore,
} from "../stores/storeSelectedItem"
import { storeModalBecomeMentor } from "../stores/storeModalBecomeMentor"
import ErrorMessageBuilder from "../../../../../common/components/ErrorMessageBuilder"

export const LIST_MENTOR_QUERY_NAME: string = "list-mentor"
export const MENTOR_REF_LOC_QUERY_NAME: string =
  "list-mentor-location-reference"
export const MENTOR_REF_AREA_QUERY_NAME: string = "list-mentor-area-reference"
export const MENTOR_REF_ALL_AREA_QUERY_NAME: string =
  "list-mentor-all_area-reference"
export const MENTOR_REF_DOMAIN_QUERY_NAME: string =
  "list-mentor-domain-reference"
export const DETAIL_MENTOR_QUERY_NAME: string = "detail-mentor"
export const IAM_MENTOR_QUERY_NAME: string = "iam-mentor"

export const useListMentor = () => {
  const [queryParams, setQueryParams] = useState<any>({
    page: 1,
    limit: 10,
    name: "",
    location_name: "",
    mentor_areas: "",
    domain_areas: "",
    location: [],
    domain: [],
    mentor: [],
  })

  const {
    data: listData,
    isLoading: listLoading,
    isError: listError,
    refetch: refetchListData,
  } = useFetchList<MentorResponse>(`${MENTOR_ENDPOINTS.LIST}`, {
    queryParams,
    queryName: LIST_MENTOR_QUERY_NAME,
    enabled: false,
  })

  const {
    data: mentorStatus,
    isLoading: mentorStatusLoading,
    isError: mentorStatusError,
  } = useFetchDetail<string>(`${MENTOR_ENDPOINTS.IAM_MENTOR}`, {
    queryParams,
    queryName: IAM_MENTOR_QUERY_NAME,
  })

  const [loadingEvent, setLoadingEvent] = useState<boolean>(false)

  useEffect(() => {
    setLoadingEvent(listLoading)
  }, [listLoading])

  const {
    data: listLocation,
    isLoading: listLocationLoading,
    isError: listLocationError,
  } = useFetchDetail<string[]>(
    `${MENTOR_ENDPOINTS.REF_LIST_LOCATION_BASED_DATA}`,
    {
      queryParams,
      queryName: MENTOR_REF_LOC_QUERY_NAME,
    },
  )
  const {
    data: listArea,
    isLoading: listAreaLoading,
    isError: listAreaError,
  } = useFetchDetail<MentorArea[]>(
    `${MENTOR_ENDPOINTS.REF_LIST_AREA_BASED_DATA}`,
    {
      queryParams,
      queryName: MENTOR_REF_AREA_QUERY_NAME,
    },
  )
  const {
    data: listDomain,
    isLoading: listDomainLoading,
    isError: listDomainError,
  } = useFetchDetail<MentorDomain[]>(
    `${MENTOR_ENDPOINTS.REF_LIST_DOMAIN_BASED_DATA}`,
    {
      queryParams,
      queryName: MENTOR_REF_DOMAIN_QUERY_NAME,
    },
  )

  const handleSearch = debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setQueryParams({
        ...queryParams,
        name: event.target.value!,
      })
    },
    500,
  )

  const handleFilterByLocation = (opt: any) => {
    const currentLocationIds = queryParams?.location_name
      ? queryParams?.location_name.split(",")
      : []

    if (!currentLocationIds.includes(opt.value)) {
      const newLocationIds = [...currentLocationIds, opt.value]
      const newLocationIdString = newLocationIds.join(",")

      setQueryParams({
        ...queryParams,
        location_name: newLocationIdString,
        location: [...queryParams?.location, opt],
      })
    }
  }

  const handleRemoveLocation = (opt: any) => {
    const currentLocationIds = queryParams?.location_name.split(",")
    const updatedLocationIds = currentLocationIds.filter(
      (id: string) => id !== opt.value,
    )
    const newLocationIdString = updatedLocationIds.join(",")

    setQueryParams({
      ...queryParams,
      location_name: newLocationIdString,
      location: queryParams?.location.filter(
        (location: any) => location.value !== opt.value,
      ),
    })
  }

  const handleFilterByArea = (opt: any) => {
    const currentAreaIds = queryParams?.mentor_areas
      ? queryParams?.mentor_areas?.split(",")
      : []

    if (!currentAreaIds.includes(opt.value)) {
      const newAreaIds = [...currentAreaIds, opt.value]
      const newAreaIdString = newAreaIds?.join(",")

      setQueryParams({
        ...queryParams,
        mentor_areas: newAreaIdString,
        mentor: [...queryParams?.mentor, opt],
      })
    }
  }

  const handleRemoveArea = (opt: any) => {
    const currentAreaIds = queryParams?.mentor_areas.split(",")
    const updatedAreaIds = currentAreaIds?.filter(
      (id: string) => id !== opt.value,
    )
    const newAreaIdString = updatedAreaIds.join(",")

    setQueryParams({
      ...queryParams,
      mentor_areas: newAreaIdString,
      mentor: queryParams?.mentor?.filter(
        (mentor: any) => mentor?.value !== opt?.value,
      ),
    })
  }

  const handleFilterByDomain = (opt: any) => {
    const currentDomainIds = queryParams?.mentor_domains
      ? queryParams?.mentor_domains.split(",")
      : []

    if (!currentDomainIds.includes(opt.value)) {
      const newDomainIds = [...currentDomainIds, opt?.value]
      const newDomainIdString = newDomainIds.join(",")

      setQueryParams({
        ...queryParams,
        mentor_domains: newDomainIdString,
        domain: [...queryParams?.domain, opt],
      })
    }
  }

  const handleRemoveDomain = (opt: any) => {
    const currentDomainIds = queryParams?.mentor_domains.split(",")
    const updatedDomainIds = currentDomainIds.filter(
      (id: string) => id !== opt.value,
    )
    const newDomainIdString = updatedDomainIds.join(",")

    setQueryParams({
      ...queryParams,
      mentor_domains: newDomainIdString,
      domain: queryParams?.domain.filter(
        (domains: any) => domains.value !== opt.value,
      ),
    })
  }

  useEffect(() => {
    refetchListData()
    setLoadingEvent(true)
    setTimeout(() => {
      setLoadingEvent(false)
    }, 1500)
  }, [queryParams])

  return {
    mentorData: {
      listData: listData?.list,
      listLoading: loadingEvent,
      listError,

      mentorStatus,
      mentorStatusLoading,
      mentorStatusError,
    },
    queryMentor: {
      handleSearch,
      handleFilterByLocation,
      handleRemoveLocation,
      handleFilterByArea,
      handleRemoveArea,
      handleFilterByDomain,
      handleRemoveDomain,

      locationSelected: queryParams?.location?.filter(
        (id: string) => id !== "",
      ),
      areaSelected: queryParams?.mentor?.filter((id: string) => id !== ""),
      domainSelected: queryParams?.domain?.filter((id: string) => id !== ""),
    },
    referenceData: {
      locationData: listLocation,
      locationLoading: listLocationLoading,
      locationError: listLocationError,

      areaData: listArea,
      areaLoading: listAreaLoading,
      areaError: listAreaError,

      domainData: listDomain,
      domainLoading: listDomainLoading,
      domainError: listDomainError,
    },
  }
}

// USE ADD MENTOR
export const useAddMentor = () => {
  const removeSelectedDomainItem = useSelectedDomainItemsStore(
    (state) => state.removeSelectedDomainItem,
  )
  const removeSelectedAreaItem = useSelectedAreaItemsStore(
    (state) => state.removeSelectedAreaItem,
  )

  const selectedDomainItems = useSelectedDomainItemsStore(
    (state) => state.selectedDomainItems,
  )
  const selectedAreaItems = useSelectedAreaItemsStore(
    (state) => state.selectedAreaItems,
  )
  const selectedLocationItemId = useSelectedLocationItemIdStore(
    (state) => state.selectedLocationItemId,
  )

  const [disableButton, setDisableButton] = useState<boolean>(true)

  const queryClient = useQueryClient()

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      domain_expertises_id: [""],
      linkedin_link: "",
      location: "",
      mentorship_areas_id: [""],
      monthly_availability: 0,
      motivation_letter: "",
    },
  })

  useEffect(() => {
    setValue(
      "domain_expertises_id",
      selectedDomainItems.map((item) => item.id),
    )
    setValue(
      "mentorship_areas_id",
      selectedAreaItems.map((item) => item.id),
    )
    setValue("location", selectedLocationItemId ?? "")
  }, [selectedDomainItems, selectedAreaItems, selectedLocationItemId, setValue])

  const { closeModal } = storeModalBecomeMentor()

  const { mutate: saveCreateProduct } = useWrite<any, any>(
    MENTOR_ENDPOINTS.CREATE,
    "post",
    () => {
      message.open({
        key: "request-info-mentor",
        content: "Your request has been sent successfully",
        type: "success",
      })

      closeModal()

      setValue("domain_expertises_id", [""])
      setValue("mentorship_areas_id", [""])
      setValue("location", "")
      setValue("linkedin_link", "")
      setValue("monthly_availability", 0)
      setValue("motivation_letter", "")

      removeSelectedDomainItem("all")

      setTimeout(() => {
        queryClient.invalidateQueries([LIST_MENTOR_QUERY_NAME])
        queryClient.invalidateQueries([IAM_MENTOR_QUERY_NAME])
      }, 1000)
    },
    () =>
      message.open({
        key: "request-info-mentor",
        content: "something wrong",
        type: "error",
      }),
  )

  const handleCreateRequestMentor = (val: any) => {
    message.open({
      key: "request-info-mentor",
      content: "requesting...",
      type: "loading",
    })

    const dataToStore = {
      domain_expertises_id: selectedDomainItems.map((item) => item.id),
      mentorship_areas_id: selectedAreaItems.map((item) => item.id),
      location: selectedLocationItemId,
      linkedin_link: val.linkedin_link,
      monthly_availability: parseInt(val.monthly_availability),
      motivation_letter: val.motivation_letter,
    }

    saveCreateProduct(dataToStore)
  }

  const handleRemoveDomain = (id: string) => {
    removeSelectedDomainItem(id)
  }
  const handleRemoveArea = (id: string) => {
    removeSelectedAreaItem(id)
  }

  return {
    addAction: {
      control,
      setValue,
      watch,
      isDirty,
      handleSubmit,
      handleCreateRequestMentor,
      handleRemoveDomain,
      handleRemoveArea,
      disableButton,
      setDisableButton,
      errors,
    },
  }
}

export const useDetailMentor = (id: string) => {
  const formData = new FormData()

  const queryClient = useQueryClient()

  const {
    data: detailMentor,
    isLoading: detailLoadingMentor,
    isError: detailErrorMentor,
  } = useFetchDetail<MentorResponse>(`${MENTOR_ENDPOINTS.DETAIL(id)}`, {
    queryName: DETAIL_MENTOR_QUERY_NAME,
  })

  const { mutate: editMentorAdvProf } = useWrite<any, any>(
    MENTOR_ENDPOINTS.EDIT_ADV,
    "patch",
    () => {
      message.success("Data updated successfully")

      setTimeout(() => {
        queryClient.invalidateQueries([DETAIL_MENTOR_QUERY_NAME])
      }, 1000)
    },
    () => message.error("An error occurred"),
  )

  const onSubmit: any = (
    id: string,
    labelAction: string,
    fieldName: string,
  ) => {
    formData.append("id_field_array", id)
    formData.append("label_action", labelAction)
    formData.append("field_name", fieldName)

    editMentorAdvProf(formData)
  }

  const handleAddArea = (id: string) => {
    onSubmit(id, "add", "mentor_areas")
  }
  const handleRemoveArea = (id: string) => {
    onSubmit(id, "remove", "mentor_areas")
  }
  const handleAddDomain = (id: string) => {
    onSubmit(id, "add", "mentor_domains")
  }
  const handleRemoveDomain = (id: string) => {
    onSubmit(id, "remove", "mentor_domains")
  }

  // EDIT BASIC USER
  const [imageUrl, setImageUrl] = useState<string>("")
  const [imageFile, setImageFile] = useState<string | Blob>("")
  const {
    handleSubmit,
    control,
    watch,
    // reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: detailMentor?.first_name,
      last_name: detailMentor?.last_name,
      country: { label: detailMentor?.country, value: detailMentor?.country },
      about_me: detailMentor?.about_me,
      linkedin: detailMentor?.linkedin,
      profile_headline: detailMentor?.profile_headline,
    },
  })

  useEffect(() => {
    if (!detailLoadingMentor) {
      setValue("first_name", detailMentor?.first_name)
      setValue("last_name", detailMentor?.last_name)
      setValue("country.label", detailMentor?.country)
      setValue("country.value", detailMentor?.country)
      setValue("about_me", detailMentor?.about_me)
      setValue("linkedin", detailMentor?.linkedin)
      setValue("profile_headline", detailMentor?.profile_headline)
    }
    if (detailMentor?.profile_picture || detailMentor?.avatar) {
      const origin = "https://media.licdn.com/"

      if (!detailMentor?.profile_picture?.startsWith(origin)) {
        setImageUrl(
          `${
            detailMentor?.profile_picture || detailMentor?.avatar
          }?${new Date().getTime()}`,
        )
      } else {
        setImageUrl(detailMentor?.profile_picture || detailMentor?.avatar)
      }
    }
  }, [detailMentor])

  const { mutate: editMentorBasic } = useWrite<any, any>(
    MENTOR_ENDPOINTS.EDIT_BASIC,
    "patch",
    () => {
      message.success("Data updated successfully")

      setTimeout(() => {
        queryClient.invalidateQueries([DETAIL_MENTOR_QUERY_NAME])
      }, 1000)
    },
    () => message.error("An error occurred"),
  )

  const formDataBasic = new FormData()
  const onSubmitBasic: any = (val: any) => {
    message.open({
      key: "update-profile",
      content: "updating...",
      type: "loading",
    })

    formDataBasic.append("first_name", val.first_name)
    formDataBasic.append("last_name", val.last_name)
    formDataBasic.append("country", val.country.value)
    formDataBasic.append("about_me", val.about_me)
    formDataBasic.append("linkedin", val.linkedin)
    formDataBasic.append("profile_headline", val.profile_headline)

    editMentorBasic(formDataBasic)
  }

  const saveEditBasic = () => {
    handleSubmit(onSubmitBasic)()
  }

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event
    if (target.files && target.files.length > 0) {
      const file = target.files[0]
      // const imageUrl = URL.createObjectURL(file)
      setImageFile(file)
      message.open({
        key: "update",
        content: "uploading...",
        type: "loading",
      })
      const handleUpload = () => {
        const formData = new FormData()
        formData.append("file", file)
        uploadImage(formData, {
          onSuccess: (res) => {
            if (res?.data?.data) {
              setImageUrl(`${res?.data?.data}?${new Date().getTime()}`)
            } else {
              setImageUrl("")
            }
            message.destroy()
          },
          onError: (err: any) => {
            message.open({
              key: "update",
              content: err?.response?.data?.data || "something wrong",
              type: "error",
            })
          },
        })
      }
      handleUpload()
    }
  }

  const handleRemoveImage = () => {
    setImageFile("")
    message.open({ key: "remove", content: "removing...", type: "loading" })
    removeImage("run", {
      onSuccess: (res) => {
        setImageUrl(res.data.data)
        message.destroy()
      },
      onError: (err: any) => {
        message.open({
          key: "remove",
          content: err?.response?.data?.data || "something wrong",
          type: "error",
        })
      },
    })
  }

  const { mutate: uploadImage } = useMutation({
    mutationFn: (val: FormData) => {
      return instance.post(`/accounts/upload`, val)
    },
    mutationKey: "updload-image",
  })

  const { mutate: removeImage } = useMutation({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    mutationFn: (_val: string) => {
      return instanceV2.delete(`/accounts/image`)
    },
    mutationKey: "delete-image",
  })

  const { data: countries, isLoading: loadingcountries } = useQuery(
    "countries",
    () => instance.get<AxiosResponse<Common[]>>("/commons/countries"),
    {
      onError: () => {
        message.open({
          key: "countries",
          duration: 5,
          content: React.createElement(ErrorMessageBuilder, {
            message:
              "We encountered an issue while attempting to fetch countries. If the problem continues, please refresh the page.",
            includeReportAt: true,
          }),
          type: "error",
        })
      },
      retry: 2,
    },
  )

  return {
    detail: {
      detailMentor,
      detailLoadingMentor,
      detailErrorMentor,

      handleAddArea,
      handleRemoveArea,
      handleAddDomain,
      handleRemoveDomain,

      basicUpdate: {
        control,
        errors,
        watch,
        handleSubmit,
        onSubmit,
        imageUrl,
        handleImageChange,
        handleRemoveImage,
        imageFile,
        countries: { data: countries?.data?.data, loading: loadingcountries },
        saveEditBasic,
      },
    },
  }
}
