import { Card, Input, Select } from "antd"
import { SearchOutlined, CloseOutlined } from "@ant-design/icons"
import React from "react"
import { useMemberReference } from "../hooks/useMember"
import { capitalizeFirstLetter } from "../../../../utils/camelCase"

interface ListAllCommunityFilterProps {
  referenceData: any
  queryMember: any
}

const ListAllCommunityFilter: React.FC<ListAllCommunityFilterProps> = ({
  queryMember,
}) => {
  const optionUserType = [
    {
      value: "expert",
      label: "Expert",
    },
    {
      value: "founder",
      label: "Founder",
    },
  ]

  const { locationData } = useMemberReference()

  return (
    <div>
      <Card className="bg-white-custom shadow-custom-sm rounded-xl py-1">
        <div className="flex justify-between items-center">
          <div className="flex flex-col md:flex-row md:gap-6 w-full">
            <div className="flex w-full md:w-8/12">
              <div
                className="flex w-full"
                style={{ borderBottom: "1px solid #D0DDE5" }}
              >
                <SearchOutlined />
                <Input
                  onChange={(e: any) => queryMember?.handleSearch(e)}
                  bordered={false}
                  placeholder="search"
                />
              </div>
            </div>

            <div className="!bg-grey-select w-full md:w-1/6 relative  mt-4 md:mt-0 flex items-center gap-3 md:gap-7">
              <div className="w-full !bg-grey-select">
                <Select
                  className="my-1 font-default basis-full job-history w-full"
                  placeholder="Location"
                  value="Location"
                  showSearch
                  options={locationData?.locationData?.map((val: any) => ({
                    label: val,
                    value: val,
                  }))}
                  onChange={(value, option) => {
                    queryMember?.handleFilterByLocation(option)
                  }}
                  labelInValue={true}
                  filterOption={(input: any, option: any) => {
                    if (option && option?.label) {
                      return (
                        option?.label
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      )
                    }
                    return false
                  }}
                />
              </div>
            </div>
            <div className="!bg-grey-select w-full md:w-1/6 relative  mt-4 md:mt-0 flex items-center gap-3 md:gap-7">
              <div className="w-full !bg-grey-select">
                <Select
                  className="my-1 font-default basis-full job-history w-full"
                  placeholder="Profile Type"
                  value="Profile Type"
                  showSearch
                  options={optionUserType?.map((val: any) => ({
                    label: val.label,
                    value: val.value,
                  }))}
                  onChange={(value, option) => {
                    queryMember?.handleFilterByProfileType(option)
                  }}
                  labelInValue={true}
                  filterOption={(input: any, option: any) => {
                    if (option && option?.label) {
                      return (
                        option?.label
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      )
                    }
                    return false
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className={`grid ${
            queryMember?.locationSelected?.length > 0 &&
            queryMember?.typeSelected?.length > 0
              ? "grid-cols-2"
              : "grid-cols-1"
          } `}
        >
          <div>
            {queryMember?.locationSelected?.length > 0 && (
              <div className="mt-3 flex gap-2">
                <div className=" font-semibold">Location:</div>
                <div className="flex items-center gap-2 flex-wrap">
                  {queryMember?.locationSelected?.map((location: string) => (
                    <div
                      id={location}
                      key={location}
                      className="bg-white px-2 shadow-md flex items-center gap-x-1 rounded"
                      style={{ border: "1px solid #dedede" }}
                    >
                      {capitalizeFirstLetter(location)}
                      <CloseOutlined
                        onClick={() =>
                          queryMember?.handleRemoveLocation(location)
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div>
            {queryMember?.typeSelected?.length > 0 && (
              <div className="mt-3 flex gap-2">
                <div className=" font-semibold">Profile Type:</div>
                <div className="flex items-center gap-2 flex-wrap">
                  {queryMember?.typeSelected?.map((type: any) => (
                    <div
                      id={type}
                      key={type}
                      className="bg-white px-2 shadow-md flex items-center gap-x-1 rounded"
                      style={{ border: "1px solid #dedede" }}
                    >
                      {type}
                      <CloseOutlined
                        onClick={() => queryMember?.handleRemoveType(type)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  )
}

export default ListAllCommunityFilter
