/* eslint-disable */
import React, { useEffect } from "react"
import { Account } from "./useProfile"
import { useQuery } from "react-query"
import { AxiosResponse } from "axios"
import { Common } from "./useJobHistory"
import instance from "../../../../config/config"

export const useBasic = (data: Account | undefined) => {
  const [isUpdate, setIsUpdate] = React.useState<boolean[]>([false, false])
  const [dataBasic, setDataBasic] = React.useState<Account | undefined>(
    undefined,
  )
  const [dataSocial, setDataSocial] = React.useState<Account | undefined>(
    undefined,
  )
  const handleUpdate = (index: number) => {
    const temp = [...isUpdate]
    temp[index] = !temp[index]
    setIsUpdate(temp)
  }
  useEffect(() => {
    setDataBasic(data)
  }, [isUpdate[0]])

  const { data: countries, isLoading: loadingcountries } = useQuery(
    "countries",
    () => instance.get<AxiosResponse<Common[]>>("/commons/countries"),
  )

  useEffect(() => {
    setDataSocial(data)
  }, [isUpdate[1]])
  return {
    isUpdate,
    handleUpdate,
    dataBasic,
    dataSocial,
    countries: { data: countries?.data?.data, loading: loadingcountries },
  }
}
