import {
  ApiResponse,
  ApiResponsePaginated,
} from "../../../common/types/ApiResponse"
import { Discussion, Workshop } from "../types/workshops"
import instance from "../../../config/config"

export interface IWorkshop {
  title?: string
  school_id?: string
  mode?: string
  limit?: number
  page?: number
  my_workshop: string
  currency_code?: string
  filter?: string
}

export const workshopAndProgramsList = async (
  data: IWorkshop,
): Promise<ApiResponsePaginated<Workshop>> => {
  return instance.get("workshops-and-programs", {
    params: {
      ...data,
    },
  })
}

export const workshopAndProgramsDetail = async (
  item_id: string,
  currency_code?: string,
): Promise<ApiResponse<Workshop>> => {
  return instance.get(`workshops-and-programs/${item_id}`, {
    params: {
      item_id,
      currency_code,
    },
  })
}

// export const getLiveWorkshopAndDiscussions = async (
//   item_id: string,
// ): Promise<ApiResponsePaginated<Discussion>> => {
//   return instance.get(`workshops-and-programs/${item_id}/discussions`, {
//     params: {
//       item_id,
//     },
//   })
// }

export const getLiveWorkshopAndDiscussions = async (
  item_id: string,
  page: number,
): Promise<ApiResponsePaginated<Discussion>> => {
  return instance.get(`workshops-and-programs/${item_id}/discussions`, {
    params: {
      item_id,
      page,
    },
  })
}

export const addDiscussionToWorkshop = async (
  item_id: string,
  content: string,
): Promise<ApiResponse<any>> => {
  return instance.post(`workshops-and-programs/${item_id}/discussions`, {
    content,
  })
}
export const registerToWorkshop = async (
  item_id: string,
): Promise<ApiResponse<any>> => {
  return instance.post(`workshops-and-programs/${item_id}/register`, {
    params: {
      item_id,
    },
  })
}
