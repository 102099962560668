import { z } from "zod"

export const MAX_HOW_TOS = 10

export const howToSchema = z.object({
  howto_category: z.string(),
  howto_image_url: z.string().optional(),
  title: z.string().trim().min(1, { message: "Title is required." }),
  howto_items: z
    .array(
      z.object({
        id: z.number(),
        name: z.string().trim().min(1, { message: "Name is Required" }),
        description: z
          .string()
          .trim()
          .min(1, { message: "Description is Required" }),
        image_url: z.string().optional(),
      }),
    )
    .min(1)
    .max(MAX_HOW_TOS),
})

export type HowToFormData = z.infer<typeof howToSchema>

export const multipleChoiceSchema = z.object({
  question: z.string().min(2, "Question must be at least 2 characters long"),
  options: z
    .array(
      z.object({
        id: z.number(),
        text: z.string().min(1, "Option must not be empty"),
      }),
    )
    .min(2, "At least two options are required.")
    .max(6, "Maximum 6 options allowed"),
  correctOption: z.number().min(1, "Please select a correct option"),
})

export type MultipleChoiceFormData = z.infer<typeof multipleChoiceSchema>

export const MAX_MEDIA = 10

export const mediaSchema = z.object({
  files: z
    .array(
      z.object({
        // id: z.number({ message: "Something went wrong internally" }),
        image: z
          .any({ message: "Image not present" })
          .refine((files) => files?.length >= 1, {
            message: "Image is required.",
          }),
      }),
    )
    .min(1, "Minimum 1 image is required")
    .max(MAX_MEDIA, "Max 10 image is required"),
})

export type MediaFormData = z.infer<typeof mediaSchema>

export const occasionSchema = z.object({
  // occasion: z.enum(["job", "project", "event", "research", "tips", "update"]),
  occasion_text: z.string(),
  occasion_description: z.string(),
  image: z.any(),
})

export type OccasionFormData = z.infer<typeof occasionSchema>

export const eventSchema = z.object({
  image: z.any().optional(),
  event_type: z.enum(["online", "in_person"]),
  external_event_link: z.string().url().optional(),
  event_name: z.string(),
  timezone: z.string(),
  event_start_date: z.string(),
  event_end_date: z.string().optional(),
  event_description: z.string().optional(),
})

export type EventFormData = z.infer<typeof eventSchema>

export const pollSchema = z.object({
  poll_question: z
    .string()
    .min(2, "Question must be at least 2 characters long"),
  poll_options: z
    .array(
      z.object({
        id: z.number(),
        text: z.string().min(1, "Option must not be empty"),
      }),
    )
    .min(2, "At least two options are required.")
    .max(6, "Maximum 6 options allowed"),
  poll_duration: z.enum(["1d", "3d", "1w", "2w"]),
})

export type PollFormData = z.infer<typeof pollSchema>
