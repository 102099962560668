import React, { useState } from "react"
import HeaderLogo from "./HeaderLogo"
import UnauthenticatedActions from "./UnauthenticatedActions"
import AuthenticatedActions from "./AuthenticatedActions"
import { useMsal } from "@azure/msal-react"

interface CustomHeaderProps {
  onOpenDrawerItem: React.MouseEventHandler<HTMLDivElement> | undefined
  isOpenSidebar: boolean
  isOtherRoute: boolean
  currencies?: {
    code: string
    exchange: number
    logo?: string
  }[]
}

const CustomHeader: React.FC<CustomHeaderProps> = ({
  onOpenDrawerItem,
  isOpenSidebar,
  isOtherRoute,
  currencies,
}) => {
  const { accounts } = useMsal()
  const isAuthenticated = accounts.length > 0
  const [currency, setCurrency] = useState(
    localStorage.getItem("currency_code"),
  )

  return (
    <div
      className={`z-50 h-14 md:h-16 px-5 md:px-10 py-4 md-py-6 flex items-center justify-between bg-secondaryBlue border-b shadow transition-all duration-100 ease-in-out absolute ${
        "top-0"
      }  ${
        isAuthenticated
          ? isOpenSidebar
            ? "ml-0 lg:ml-[15.7rem] w-full lg:w-[calc(100%-15.7rem)]"
            : isOtherRoute
              ? "ml-0"
              : "ml-0 lg:ml-[4.2rem] w-full lg:w-[calc(100%-4.2rem)]"
          : "w-full"
      }`}
    >
      {!isAuthenticated && <HeaderLogo />}

      <UnauthenticatedActions
        onOpenDrawerItem={onOpenDrawerItem}
        currency={currency}
        currencies={currencies}
        setCurrency={setCurrency}
      />
      <AuthenticatedActions
        onOpenDrawerItem={onOpenDrawerItem}
        currency={currency}
        currencies={currencies}
        setCurrency={setCurrency}
      />
    </div>
  )
}

export default CustomHeader
