import { useQuery } from "react-query"
import instance from "../../../../config/config"
import { ApiResponse } from "../../../../common/types/ApiResponse"
import { BasicCommon } from "../../types/common"
import { errorMessage } from "../../../../common/utils/utils"

export const useCompanySize = () => {
  const { data, isLoading } = useQuery<ApiResponse<BasicCommon[]>>(
    ["company-size"],
    () => instance.get(`/references/company-size`),
    {
      onError: (err: any) =>
        errorMessage(err?.response?.data?.data || "internal server error", err),
    },
  )
  return { data: data?.data?.data, isLoading }
}
