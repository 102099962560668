/* eslint-disable */

import { ColumnProps } from "antd/es/table"
import { TeraPointHistory } from "../hooks/useAchievements"
import dayjs from "dayjs"

export const terraHistCols: ColumnProps<TeraPointHistory>[] = [
  {
    title: "Created At",
    dataIndex: "created_at",
    key: "created_at",
    render: (text) => <span>{dayjs(text).format("DD MMM YYYY")}</span>,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text) => <span className="capitalize">{text}</span>,
  },
  {
    title: "Point",
    dataIndex: "point",
    key: "point",
  },
]
