import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation, useQueryClient } from "react-query"
import { message } from "antd"
import { createCommunityPost } from "../apis/feed-post"
import ErrorMessageBuilder from "../../../../common/components/ErrorMessageBuilder"
import {
  OccasionFormData,
  occasionSchema,
} from "../components/new-post-forms/schemas"
import { useNewPostStore } from "../../store/newPostStore"
import { Occasions, TPostBody, TPostKind } from "../types/commonn"
import { occasions } from "../constants/data"

const DEFAULT_IMG_URL = ""

const extractNamesAndDescriptions = (
  occasions: Occasions,
): { name: string; description: string }[] => {
  return Object.values(occasions).map(({ name, description }) => ({
    name,
    description,
  }))
}

const namesAndDescriptions = extractNamesAndDescriptions(occasions)

export const useOccasionForm = () => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm<OccasionFormData>({
    resolver: zodResolver(occasionSchema),
    defaultValues: {
      occasion_description: "",
      image: [],
    },
  })

  const { closeModal } = useNewPostStore()
  const createPost = useMutation(
    ({ post, kind }: { post: TPostBody; kind?: TPostKind }) =>
      createCommunityPost(post, kind),
  )
  const queryClient = useQueryClient()
  const occasion_text = watch("occasion_text")

  useEffect(() => {
    if (occasion_text) {
      const option = namesAndDescriptions.find(
        (val) => occasion_text === val.name,
      )
      if (option) {
        setValue("occasion_description", option.description)
      }
    }
  }, [occasion_text])

  const onSubmit = async (data: OccasionFormData) => {
    message.loading({ content: "Creating post...", key: "create-post" })

    await createPost.mutateAsync(
      {
        post: {
          image: data?.image?.[0],
          occasion_default_image_url: data?.image?.[0]
            ? undefined
            : DEFAULT_IMG_URL,
          occasion_text: data?.occasion_text,
          occasion_description: data?.occasion_description,
        },
        kind: "occasion",
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("community-post-list")
          message.success({
            content: "Post created successfully!",
            key: "create-post",
          })
          closeModal()
        },
        onError: (error: any) => {
          message.open({
            key: "create-event-post",
            duration: 5,
            content: React.createElement(ErrorMessageBuilder, {
              message: `We encountered an issue while attempting to create your occasion post. If the problem continues, please refresh the page. Error ${error.response?.data?.message}`,
              includeReportAt: true,
            }),
            type: "error",
          })
        },
      },
    )
  }

  return {
    handleSubmit,
    control,
    errors,
    isDirty,
    createPost,
    closeModal,
    onSubmit,
    namesAndDescriptions,
  }
}
