import React, { useState } from "react"
import { Spin, Tabs } from "antd"
import { PageContent } from "../../../../common/layout"
import { CommunityPost } from "../components"
import { CommunityRightSidebar } from "../components/CommunityRightSidebar"
import { LoadingOutlined } from "@ant-design/icons"
import { ClimatePulse, Moon } from "../../../../assets/images"
import NewCommunityPostV2 from "../components/NewCommunityPost-v2"
import NewCommunityPostModal from "../components/NewCommunityPostModal"
import ThisWeeksPost from "../components/ThisWeeksPost"
import FeedFilter from "../components/FilterFeed"
import ThisWeeksDiscussion from "../components/ThisWeeksDiscussion"
import UpdateCommentModal from "../components/UpdateCommentModal"
import { updateCommentStore } from "../../store/updateCommentStore"
import { useInfinite } from "../../../../hooks/useInfinite"
import { ICommunityPost } from "../types/commonn"

export const Content: React.FC<{
  setIsNews: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ setIsNews }) => {
  const { data, lastCardElementRef, isLoading } = useInfinite<ICommunityPost>({
    key: "community-post-list",
    path: "/community/posts",
    queryParams: {
      page: 1,
      limit: 10,
    },
  })
  const [activeKey, setActiveKey] = React.useState("feed")

  const tabsData = [
    {
      key: "feed",
      label: "Feed",
      children: (
        <div className="relative mt-6">
          <>
            <NewCommunityPostV2 />
            <FeedFilter />
            <ThisWeeksPost />

            {data?.length === 0 && isLoading ? (
              <div className="mt-20 relative">
                <div className="absolute transform -translate-x-1/2  flex flex-col gap-4 justify-center items-center -translate-y-1/2 top-1/2 left-1/2">
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 36 }} spin />
                    }
                  />
                  <div className="text-center text-backdrop text-xl animate-pulse">
                    Loading Feed
                  </div>
                </div>
              </div>
            ) : data?.length > 0 ? (
              <Spin
                spinning={isLoading}
                indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
              >
                <div className="grid gap-4 mt-4">
                  {data?.map((post, index) => (
                    <CommunityPost
                      className="bg-white"
                      key={post.id}
                      post={post}
                      observ={
                        data.length === index + 1
                          ? lastCardElementRef
                          : undefined
                      }
                    />
                  ))}
                </div>
              </Spin>
            ) : (
              <Spin
                spinning={isLoading}
                indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
              >
                <div className="flex items-center gap-3 p-3 bg-white shadow-md rounded-xl">
                  <img src={Moon} alt="cookies" />
                  <div>{"Nothing to display"}</div>
                </div>
              </Spin>
            )}
          </>
        </div>
      ),
    },
    {
      key: "news",
      label: "News",
      children: (
        <div className="mt-4">
          <div
            className={`" text-xl font-semibold mb-5 gap-3 flex items-center flex-wrap lg:flex-nowrap gap-3"`}
          >
            Powered by{" "}
            <a
              href="https://www.climatepulse.news/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="h-8 " src={ClimatePulse} />
            </a>
          </div>
          <iframe
            src="https://www.climatepulse.news/pulses/cltf8qmfh000227283mm5l9de9p2---climate-reporting-singapore/embed"
            className="w-full h-screen rounded-md "
            style={{ border: "none" }}
          ></iframe>

          {/* <Empty description="No news yet." /> */}
        </div>
      ),
    },
  ]

  return (
    <div className="px-8" id="tabs-component">
      <Tabs
        defaultActiveKey={activeKey}
        className="custom-tabs"
        items={tabsData}
        activeKey={activeKey}
        onTabClick={(key) => {
          setActiveKey(key)
          setIsNews(key === "news")
        }}
      />
    </div>
  )
}

const Feed = () => {
  const [isNews, setIsNews] = useState(false)
  const { comment } = updateCommentStore()

  return (
    <>
      <PageContent
        header={null}
        content={<Content setIsNews={setIsNews} />}
        hideRightSidebarOnMobile
        contentRightSidebar={!isNews && <CommunityRightSidebar />}
      />
      <NewCommunityPostModal />
      <ThisWeeksDiscussion />
      {comment && <UpdateCommentModal />}
    </>
  )
}

export default Feed
