import React, { createContext, useRef, useState } from "react"
import { Tour, TourProps } from "antd"
import { isMobile } from "react-device-detect"
import { Link } from "react-router-dom"

export interface ITourContext {
  refs: {
    refCurrentTrajectory: React.RefObject<HTMLDivElement>
    refJobSeeker: React.RefObject<HTMLDivElement>
    refCareerAspirations: React.RefObject<HTMLDivElement>
    refGreenCV: React.RefObject<HTMLDivElement>
    refExpertArticle: React.RefObject<HTMLDivElement>
    refLevelUp: React.RefObject<HTMLDivElement>
    refEcoCommitmentStreak: React.RefObject<HTMLDivElement>
    refSustainabilityScore: React.RefObject<HTMLDivElement>
    refSkillRoadmap: React.RefObject<HTMLDivElement>
    refRoadmapCurrentTrajectory: React.RefObject<HTMLDivElement>
    refFavSkills: React.RefObject<HTMLDivElement>
    refGalacticAchievments: React.RefObject<HTMLDivElement>
    refEcoCommnitmentHonors: React.RefObject<HTMLDivElement>
    refGsSkillsHonors: React.RefObject<HTMLDivElement>
    refGreenJobHonors: React.RefObject<HTMLDivElement>
    refTerraPointsHistory: React.RefObject<HTMLDivElement>
    refBecomeAnExpert: React.RefObject<HTMLButtonElement | HTMLAnchorElement>
    refFastTrackJob: React.RefObject<HTMLDivElement>
    refRecruiterRequests: React.RefObject<HTMLDivElement>
    refSavedJobs: React.RefObject<HTMLDivElement>
    refJobAppHistory: React.RefObject<HTMLDivElement>
    refJobRecommendationSettings: React.RefObject<HTMLDivElement>
    refBecomeAMentor: React.RefObject<HTMLButtonElement | HTMLAnchorElement>
    refIAmAFounder: React.RefObject<HTMLButtonElement | HTMLAnchorElement>
    refCreateNewPost: React.RefObject<HTMLFormElement>
    refCommunityShortcuts: React.RefObject<HTMLDivElement>
  }
  startTour: (route: Route) => void
  stopTour: () => void
  open: boolean
  route: Route | null
}

export enum Route {
  DashboardGalacticAchievementsSolarTrophies = "/dashboard/galactic-achievements/solar-trophies",
  DashboardSkillTrajectoryPlanSkillRoadmap = "/dashboard/skill-trajectory-plan/skill-roadmap",
  DashboardLaunchpad = "/dashboard/launchpad",
  SkillGreenSkills = "/skill/green-skills",
  SkillWorkshops = "/skill/workshops",
  SkillSchools = "/skill/schools",
  SkillArticles = "/skill/articles",
  JobMy = "/job/my",
  JobRoles = "/job/roles",
  JobBoard = "/job/board",
  JobCompanies = "/job/companies",
  CommunityMember = "/community/member",
  CommunityMentor = "/community/mentor",
  CommunityPublicExpert = "/community/public-expert",
  CommunitySolution = "/community/solution",
  CommunityFounder = "/community/founder",
  Community = "/community",
}

export const TourContext = createContext<ITourContext | null>(null)

export const TourProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const refs = {
    refCurrentTrajectory: useRef(null),
    refJobSeeker: useRef(null),
    refCareerAspirations: useRef(null),
    refGreenCV: useRef(null),
    refExpertArticle: useRef(null),
    refLevelUp: useRef(null),
    refEcoCommitmentStreak: useRef(null),
    refSustainabilityScore: useRef(null),
    // /dashboard/skill-trajectory-plan/skill-roadmap
    refSkillRoadmap: useRef(null),
    refRoadmapCurrentTrajectory: useRef(null),
    refFavSkills: useRef(null),
    // /dashboard/galactic-achievements/solar-trophies
    refGalacticAchievments: useRef(null),
    refEcoCommnitmentHonors: useRef(null),
    refGsSkillsHonors: useRef(null),
    refGreenJobHonors: useRef(null),
    refTerraPointsHistory: useRef(null),
    // /skill/green-skills
    // /skill/workshops
    // /skill/schools
    // /skill/articles
    refBecomeAnExpert: useRef(null),
    // /job/my
    refFastTrackJob: useRef(null),
    refRecruiterRequests: useRef(null),
    refSavedJobs: useRef(null),
    refJobAppHistory: useRef(null),
    refJobRecommendationSettings: useRef(null),
    // /job/roles
    // /job/board
    // /job/companies
    // /community/member
    // /community/mentor
    refBecomeAMentor: useRef(null),
    // /community/public-expert
    // /community/solution
    // /community/founder
    refIAmAFounder: useRef(null),
    // /community
    refCreateNewPost: useRef(null),
    refCommunityShortcuts: useRef(null),
  }

  function handleRoute(route: Route | null): TourProps["steps"] {
    switch (route) {
      case Route.DashboardGalacticAchievementsSolarTrophies:
        return [
          {
            title: "Galactic Achievements",
            description:
              "Check out all the orbits you can visit by collecting points. An orbit can be unlocked by earning certain badges a certain number of time.",
            target: isMobile
              ? undefined
              : () => refs.refGalacticAchievments.current,
            placement: "top",
          },
          {
            title: "Eco Commitment",
            description:
              "Check out all badges you can earn by staying commited to your green career.",
            target: isMobile
              ? undefined
              : () => refs.refEcoCommnitmentHonors.current,
            placement: "right",
          },
          {
            title: "Green Skills",
            description:
              "Check out all badges you can earn by learning and sharing your green skills.",
            target: isMobile ? undefined : () => refs.refGsSkillsHonors.current,
            placement: "right",
          },
          {
            title: "Green Jobs",
            description:
              "Check out all badges you can earn by applying to and working towards getting a green job.",
            target: isMobile ? undefined : () => refs.refGreenJobHonors.current,
            placement: "top",
          },
          {
            title: "Points History",
            description:
              "A table containing a history of all points you have earned. Check out how you can earn points for further details",
            target: isMobile
              ? undefined
              : () => refs.refTerraPointsHistory.current,
          },
        ]
      case Route.DashboardSkillTrajectoryPlanSkillRoadmap:
        return [
          {
            title: "Skills Roadmap",
            description:
              "Find all skills you have bought and view them in this table. You can buy and add up to 10 skills in here. Move skills around in here ",
            target: isMobile ? undefined : () => refs.refSkillRoadmap.current,
            type: "primary",
            placement: "right",
          },
          {
            title: "Current Trajectory",
            description: (
              <div>
                {
                  "View the top 3 lists you're learning from your Skills Roadmap Table. You can also view the top 3 skills you're learning on the"
                }{" "}
                <Link to="/dashboard/launchpad" className="text-secondary">
                  Dashboard
                </Link>
                {"."}
              </div>
            ),
            target: isMobile
              ? undefined
              : () => refs.refRoadmapCurrentTrajectory.current,
            scrollIntoViewOptions: {
              block: "start",
            },
            placement: "left",
          },
          {
            title: "Saved Skills",
            description: "Here you can see all the skills you've saved.",
            target: isMobile ? undefined : () => refs.refFavSkills.current,
            placement: "top",
          },
        ]
      case Route.DashboardLaunchpad:
        return [
          {
            title: "Current Trajectory",
            description:
              "Top 5 Skills you are currently learning. Skills you buy will appear here.",
            target: isMobile
              ? undefined
              : () => refs.refCurrentTrajectory.current,
          },
          {
            title: "Fast tracking job applications",
            description:
              "Turn on CV visibility and fast track job applications.",
            target: isMobile ? undefined : () => refs.refJobSeeker.current,
          },
          {
            title: "Jobs you'd like to get.",
            description:
              "'Career Aspirations' will show all jobs you've added in your CV.",
            target: isMobile
              ? undefined
              : () => refs.refCareerAspirations.current,
            placement: "right",
          },
          {
            title: "Build your Green CV with the CV Builder.",
            description:
              "View, Share and Edit your Green CV to apply to Green Jobs.",
            target: isMobile ? undefined : () => refs.refGreenCV.current,
            placement: "left",
          },
          {
            title: "View all articles written by experts in your field.",
            description: "Quickly view all new articles on the platform.",
            target: isMobile ? undefined : () => refs.refExpertArticle.current,
            placement: "left",
          },
          {
            title: "View your progress within the application.",
            description:
              "Your points and your current level in the app. It's a testament to your engagement and progress. Keep earning points to level up and unlock new achievements!",
            target: isMobile ? undefined : () => refs.refLevelUp.current,
            placement: "left",
          },
          {
            title: "Your commitment to your eco-career.",
            description:
              "A visual tracker of your app checkin, current streak, and record streak. Stay active and beat your best!",
            target: isMobile
              ? undefined
              : () => refs.refEcoCommitmentStreak.current,
            placement: "left",
          },
          {
            title: "Your Position on the Leaderboard",
            description:
              "Highlights your standing on the gamification scoreboard. It showcases your current points, level, and rank among other users. Keep engaging, earn more points, and climb up the leaderboard!",
            target: isMobile
              ? undefined
              : () => refs.refSustainabilityScore.current,
            placement: "left",
          },
          {
            title: "Discover the application.",
            description:
              "Go to any page and click on the tour button to find out more about it.",
          },
        ]
      case Route.SkillGreenSkills:
        return [
          {
            title: "Find and save Green Skills",
            description:
              "Find all green skills that you can learn. Find out more about them by clicking on them. Save them and check them out later.",
            type: "primary",
          },
        ]
      case Route.SkillWorkshops:
        return [
          {
            title: "Find and attend SkilledIn Workshops",
            description:
              "Built to get you up to speed with the most pressing information in your field. Buy and attend workshops from the experts at the forefronts of their fields.",
            type: "primary",
          },
        ]
      case Route.SkillSchools:
        return [
          {
            title: "Find all schools that teach on the SkilledIn Platform.",
            description:
              "Click on a school to find out more about them and the courses/skills that they teach.",
            type: "primary",
          },
        ]
      case Route.SkillArticles:
        return [
          {
            title: "Become an Expert",
            description:
              "If you're an expert in your field and have the writing chops, apply to SkilledIn to become an expert.",
            target: isMobile ? undefined : () => refs.refBecomeAnExpert.current,
          },
          {
            title: "Find and read articles",
            description:
              "Find and read articles written by experts in your field.",
          },
        ]
      case Route.JobMy:
        return [
          {
            title: "Fast tracking job applications",
            description:
              "Turn on CV visibility and let recruiter fast track your job applications.",
            target: isMobile ? undefined : () => refs.refFastTrackJob.current,
          },
          {
            title: "Click to see recruiter requests.",
            description:
              "Find all requests made by recruiters and respond to them.",
            target: isMobile
              ? undefined
              : () => refs.refRecruiterRequests.current,
            placement: "left",
          },
          {
            title: "Click to see all jobs you've saved over time.",
            description:
              "Find all jobs you've saved and want to potentially learn skills for.",
            target: isMobile ? undefined : () => refs.refSavedJobs.current,
            placement: "left",
          },
          {
            title: "Click to see a history of all your job applications.",
            description:
              "Find all the jobs applications that you've applied to or have been invited to apply for.",
            target: isMobile ? undefined : () => refs.refJobAppHistory.current,
            placement: "left",
          },
          {
            title: "Change job recommendation settings.",
            description:
              "Choose what SkilledIn should use to recommend jobs to you. Jobs you'd like to apply to (Career Aspirations) or jobs from specific companies (Followed Employers)",
            target: isMobile
              ? undefined
              : () => refs.refJobRecommendationSettings.current,
            placement: "left",
          },
        ]
      case Route.JobRoles:
        return [
          {
            title: "Find all types of jobs that exist on SkilledIn.",
            description:
              "Search for and find jobs that you aspire as a career path. Click to find skills required for these job roles.",
            type: "primary",
          },
        ]
      case Route.JobBoard:
        return [
          {
            title: "Find and apply to jobs posted by recruiters.",
            description:
              "Find, apply to and save jobs posted by recruiters, find more about each posting by clicking ont them.",
            type: "primary",
          },
        ]
      case Route.JobCompanies:
        return [
          {
            title: "Find and follow companies/recruiters on SkilledIn.",
            description:
              "Follow recruiters that you'd like to be recommended jobs from. Click a company to see details on it.",
            type: "primary",
          },
        ]
      case Route.CommunityMember:
        return [
          {
            title: "Find members that are a part of the SkilledIn Community.",
            description: "Follow experts, view members and connect with them.",
            type: "primary",
          },
        ]
      case Route.CommunityMentor:
        return [
          {
            title: "Become a Mentor",
            description:
              "If you're an expert in your field and love to impart wisdom unto others, apply to SkilledIn to become a mentor. Help others and yourself.",
            target: isMobile ? undefined : () => refs.refBecomeAMentor.current,
          },
          {
            title: "Find and view mentor profiles",
            description:
              "Find mentors you like, view their availability, and get mentored by them.",
            type: "primary",
          },
        ]
      case Route.CommunityPublicExpert:
        return [
          {
            title: "Find and view expert profiles",
            description:
              "Find experts, and follow their profiles to keep up with articles they write.",
            type: "primary",
          },
        ]
      case Route.CommunitySolution:
        return [
          {
            title:
              "Search and find all the best sustainability solutions out there.",
            description:
              "Discover the best sustainability solutions like SkilledIn aiming to make a difference. Click on a solution to find out more about it.",
            type: "primary",
          },
        ]
      case Route.CommunityFounder:
        return [
          {
            title: "Become a Founder",
            description:
              "Are you a founder of a brilliant sustainability solution. Become a SkilledIn Founder to get word out there.",
            target: isMobile ? undefined : () => refs.refIAmAFounder.current,
          },
          {
            title: "Find and view SkilledIn Founder profiles",
            description:
              "Find changemakers hoping to change the world with their sustainability solutions.",
            type: "primary",
          },
        ]
      case Route.Community:
        return [
          {
            title: "Community",
            description:
              "Get in touch with the SkilledIn community, by creating posts, reacting and commenting on posts.",
            type: "primary",
          },
          {
            title: "Create new post",
            description:
              "Share your sustainability thoughts with the community.",
            target: isMobile ? undefined : () => refs.refCreateNewPost.current,
          },
          {
            title: "Shortcuts",
            description: "Navigate to the most important links quickly.",
            target: isMobile
              ? undefined
              : () => refs.refCommunityShortcuts.current,
            placement: "left",
          },
          {
            title: "Your Position on the Leaderboard",
            description:
              "Highlights your standing on the gamification scoreboard. It showcases your current points, level, and rank among other users. Keep engaging, earn more points, and climb up the leaderboard!",
            target: isMobile
              ? undefined
              : () => refs.refSustainabilityScore.current,
            placement: "left",
          },
          {
            title: "Discover the application.",
            description:
              "Go to any page and click on the tour button to find out more about it.",
            type: "primary",
          },
        ]
      default:
        return [
          {
            title: "We haven't gotten to creating the tour for this page.",
            description: "Wait a while or get in touch with us.",
          },
        ]
    }
  }

  const [open, setOpen] = useState<boolean>(false)
  const [steps, setSteps] = useState<TourProps["steps"]>([])
  const [route, setRoute] = useState<Route | null>(null)

  const startTour = (route: Route) => {
    setSteps(handleRoute(route))
    setRoute(route)
    setOpen(true)
  }
  const stopTour = () => {
    setOpen(false)
    setSteps([])
    setRoute(null)
  }

  return (
    <TourContext.Provider
      value={{
        refs,
        startTour,
        stopTour,
        open,
        route,
      }}
    >
      {route && steps && steps?.length > 0 && (
        <Tour
          open={open}
          // disabledInteraction
          onClose={stopTour}
          steps={steps}
          animated
          scrollIntoViewOptions={{ behavior: "smooth" }}
          arrow
          mask={{
            style: { width: "100%", overflow: "hidden", height: "100%" },
          }}
        />
      )}
      {children}
    </TourContext.Provider>
  )
}

export default TourProvider
