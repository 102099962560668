import React, { useContext } from "react"
import {
  LinkOutlined,
  TwitterOutlined,
  LinkedinFilled,
  EditOutlined,
} from "@ant-design/icons"
import useCommunityModalsStore from "../../store/useCommunityModalsStore"
import { AccountContext } from "../../../../common/layout/PageLayout"
import { openInNewTab } from "../../../../utils/util_funcs"
import clsx from "clsx"

const ExpertBio = () => {
  const editExpertBioModal = useCommunityModalsStore(
    (state) => state.editExpertBioModal,
  )
  const account = useContext(AccountContext)
  const accountData = account?.data?.data

  return (
    <div className="mt-6">
      <div className="flex justify-between items-center">
        <div className="flex gap-3 items-center">
          <LinkedinFilled
            onClick={
              accountData?.linkedin
                ? () => openInNewTab(accountData?.linkedin)
                : undefined
            }
            className={clsx("text-xl rounded-full p-2 ring-backdrop ring-1 ", {
              "cursor-not-allowed": !accountData?.linkedin,
              "hover:scale-105 duration-300 transition-all":
                accountData?.linkedin,
              "text-darkGrey": !accountData?.linkedin,
              "cursor-pointer": accountData?.linkedin,
            })}
          />
          <TwitterOutlined
            onClick={
              accountData?.twitter
                ? () => openInNewTab(accountData?.twitter)
                : undefined
            }
            className={clsx("text-xl rounded-full p-2 ring-backdrop ring-1 ", {
              "cursor-not-allowed": !accountData?.twitter,
              "hover:scale-105 duration-300 transition-all":
                accountData?.twitter,
              "text-darkGrey": !accountData?.twitter,
              "cursor-pointer": accountData?.twitter,
            })}
          />
          <LinkOutlined
            onClick={
              accountData?.link
                ? () => openInNewTab(accountData?.link)
                : undefined
            }
            className={clsx("text-xl rounded-full p-2 ring-backdrop ring-1 ", {
              "cursor-not-allowed": !accountData?.link,
              "hover:scale-105 duration-300 transition-all": accountData?.link,
              "text-darkGrey": !accountData?.link,
              "cursor-pointer": accountData?.link,
            })}
          />
        </div>
        <EditOutlined
          onClick={() => editExpertBioModal.open()}
          className="text-xl cursor-pointer hover:scale-105 duration-300 transition-all"
        />
      </div>
      <h4 className="font-semibold !text-xl mt-4">About Me</h4>
      {accountData?.about_me_expert ? (
        <div
          dangerouslySetInnerHTML={{ __html: accountData?.about_me_expert }}
        />
      ) : (
        <p>You haven't added an expert bio yet. Press edit to add your bio.</p>
      )}
    </div>
  )
}

export default ExpertBio
