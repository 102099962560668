import React from "react"
import { HomeOutlined } from "@ant-design/icons"
import { PageContent, PageHeader } from "../../../../common/layout"
import DetailPublicCommonUserContent from "../components/DetailPublicCommonUserContent"
import { useDetailMember } from "../hooks/useMember"
import { useParams } from "react-router-dom"
import ListAllCommunityUser from "./ListAllCommunityUser"

interface CommonUserDetailProps {}

const CommonUserDetail: React.FC<CommonUserDetailProps> = () => {
  const { id, username } = useParams()
  const { memberData } = useDetailMember(id!, username!)
  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      path: "/community/member",
      title: "Community",
    },
    {
      title: "Talent",
      ListAllCommunityUser,
    },
    {
      title:
        `${memberData.detailData?.first_name} ${memberData.detailData?.last_name}` ||
        "unknown",
    },
  ]

  console.log("memberData", memberData, id)

  return (
    <PageContent
      header={<PageHeader breadcrumbItems={items} />}
      content={
        <DetailPublicCommonUserContent
          // handler={handler}
          memberData={memberData}
        />
      }
    />
  )
}

export default CommonUserDetail
