import { useEffect, useRef, useState } from "react"
import { Discussion } from "../types/workshops"
import { useDiscussionStore } from "../store/useDiscusionStore"
import { useFetchList } from "../../../hooks/generic_rest"

export const useDiscussionHook = (item_id: string) => {
  const listWrapperRef = useRef<HTMLDivElement>(null)

  const {
    dataList,
    queryParams,
    total,
    setDataList,
    setQueryParams,
    setTotal,
    isInitialLoad,
    setIsInitialLoad,
    isAddingNewData,
    setIsAddingNewData,
  } = useDiscussionStore()

  const {
    data: listData,
    isLoading: listLoading,
    refetch: refetchListData,
  } = useFetchList<Discussion>(
    `workshops-and-programs/${item_id}/discussions`,
    {
      queryParams,
      queryName: "workshop-discussion",
      enabled: true,
    },
  )

  const LoadMoreData = () => {
    setIsInitialLoad(false)
    const newQueryParams = {
      ...queryParams,
      limit: queryParams.limit + 5,
    }
    setQueryParams(newQueryParams)
  }

  useEffect(() => {
    if (!listLoading) {
      if (queryParams.page === 1) {
        setDataList(listData?.list || [])
        setTotal(listData?.total_data || 0)
      } else if (
        dataList?.[dataList?.length - 1]?.id !==
        listData?.list?.[listData?.list?.length - 1]?.id
      ) {
        const newDataList = [...dataList, ...(listData?.list || [])]
        setDataList(newDataList)
        setTotal(listData?.total_data || 0)
      }
    }
  }, [listData?.list, listLoading, queryParams.limit, setDataList, setTotal])

  useEffect(() => {
    refetchListData()
  }, [queryParams.limit, refetchListData])

  // Scroll to bottom when new data is added, but not on load more
  useEffect(() => {
    if (!listLoading && listWrapperRef.current) {
      if (isInitialLoad || isAddingNewData) {
        listWrapperRef.current.scrollTop = listWrapperRef.current.scrollHeight
        setIsInitialLoad(true) // Set initial load to false after the first load
        setIsAddingNewData(false) // Reset new data flag
      }
    }
  }, [listLoading, dataList, isInitialLoad, isAddingNewData])

  return {
    listWrapperRef,
    total,
    dataList,
    listLoading,
    refetchListData,
    LoadMoreData,
  }
}
