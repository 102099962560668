import React, { useEffect, useState, useContext } from "react"
import { Header, AccountSetting, DataExport } from "../components"
import { HomeOutlined } from "@ant-design/icons"
// import Billing from "../components/Billing"
import { PageContent } from "../../../common/layout"
import { BasicContainer } from "../../../common/components/BasicBoxContainers/BasicContainer"
import { AccountContext } from "../../../common/layout/PageLayout"
import { Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { BillingWrapper } from "../components/billing/BillingWrapper"

const getTitleName = (key) => {
  switch (key) {
    case 1:
      return "Account Setting"
    case 3:
      return "Billing"
    case 4:
      return "Request Data Export"
    default:
      return "Account Setting"
  }
}

const Setting = () => {
  const account = useContext(AccountContext)
  const [active, setActive] = useState(1)
  const url = new URL(window.location.href)
  const queryParams = new URLSearchParams(url.search)
  const query = queryParams.get("q")
  useEffect(() => {
    if (query === "billing") {
      setActive(3)
    } else if (query === "export") {
      setActive(4)
    } else {
      setActive(1)
    }
  }, [query])
  const getContent = (key) => {
    switch (key) {
      case 1:
        return <AccountSetting />
      case 3:
        return <BillingWrapper />
      case 4:
        return (
          <BasicContainer type="small" className="flex-grow">
            <DataExport />
          </BasicContainer>
        )
      default:
        return (
          <BasicContainer type="small" className="flex-grow">
            <AccountSetting />
          </BasicContainer>
        )
    }
  }
  return (
    <PageContent
      header={
        <Header
          title={getTitleName(active)}
          breadcrumbs={[
            {
              path: "/dashboard/launchpad",
              title: <HomeOutlined className="text-gray-400" />,
            },
            {
              title: getTitleName(active),
            },
          ]}
        />
      }
      contentRootClassName={""}
      content={
        account.isLoading ? (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          </div>
        ) : (
          getContent(active)
        )
      }
    />
  )
}

export default Setting
