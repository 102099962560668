import React from "react"
import {
  PlayCircleOutlined,
  ReadOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons"
import {
  VideoMetadata,
  TextContentMetadata,
} from "../../modules/skillGalaxy/types/common"
import { parseHumanReadableDuration } from "../../modules/skillGalaxy/utils/util_funcs"

interface Props {
  textDuration?: TextContentMetadata
  videoDuration?: VideoMetadata
  textDurationObject?: { hours: number; minutes: number }
  videoDurationObject?: { hours: number; minutes: number }
  rootClassName?: string
  inputDurationType?: "object" | "string"
}
export function addHumanReadableDurations(
  duration1: string,
  duration2: string,
) {
  const parsedDuration1 = parseHumanReadableDuration(duration1)
  const parsedDuration2 = parseHumanReadableDuration(duration2)

  const totalHours = parsedDuration1?.hours + parsedDuration2?.hours
  const totalMinutes = parsedDuration1?.minutes + parsedDuration2?.minutes

  const resultHours = Math.floor(totalMinutes / 60) + totalHours
  const resultMinutes = totalMinutes % 60

  return { hours: resultHours, minutes: resultMinutes }
}

const durationToDisplay = (
  duration: { hours: number; minutes: number } | undefined,
) => {
  return `${
    duration?.hours === 0 ? "" : duration?.hours + "h"
  } ${duration?.minutes}m`
}

export const LessonDuration: React.FC<Props> = ({
  textDuration,
  videoDuration,
  rootClassName,
  inputDurationType = "string",
  textDurationObject,
  videoDurationObject,
}) => {
  let totalDuration

  if (
    inputDurationType === "object" &&
    textDurationObject &&
    videoDurationObject
  ) {
    totalDuration = {
      hours: textDurationObject?.hours + videoDurationObject?.hours,
      minutes: textDurationObject?.minutes + videoDurationObject?.minutes,
    }
  } else {
    totalDuration =
      textDuration?.human_readable_duration &&
      videoDuration?.human_readable_duration
        ? addHumanReadableDurations(
            textDuration?.human_readable_duration,
            videoDuration?.human_readable_duration,
          )
        : {
            hours: 0,
            minutes: 0,
          }
  }

  return (
    <div className={`flex items-center gap-x-5 flex-wrap ${rootClassName}`}>
      <div className={`flex items-center gap-2`}>
        <PlayCircleOutlined />
        <span className={``}>
          {videoDuration?.human_readable_duration ??
            durationToDisplay(videoDurationObject)}
        </span>
      </div>
      <div className={`flex items-center gap-2`}>
        <ReadOutlined />
        <span className={``}>
          {textDuration?.human_readable_duration ??
            durationToDisplay(textDurationObject)}
        </span>
      </div>
      <div className={`flex items-center gap-2`}>
        <ClockCircleOutlined />
        <span className={``}>{durationToDisplay(totalDuration)}</span>
      </div>
    </div>
  )
}
