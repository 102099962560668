import React from "react"
import { BasicContainerWithTitle } from "../../../../common/components"
import { useLevelUp } from "../../../missionControl/hooks/useLevelUp"
import { useSingleOrbit } from "../../../missionControl/hooks/useOrbits"

const GalaxyAchievements = () => {
  const levelUp = useLevelUp()
  const levelUpData = levelUp?.data?.data?.data
  const singleOrbit = useSingleOrbit(levelUpData?.id!, {
    enabled: !!levelUpData?.id,
  })
  const singleOrbitData = singleOrbit?.data?.data?.data

  return (
    <BasicContainerWithTitle title={"Galaxy Achievements"}>
      <div className="flex flex-col items-center justify-center gap-3 text-center py-[10px]">
        <div className="capitalize font-semibold text-base">
          {levelUpData?.title}
        </div>
        <div className="capitalize text-base ">{levelUpData?.subtitle}</div>
        <img
          className="w-[150px] object-contain"
          src={levelUpData?.image_url}
          alt="orbit"
        />
      </div>
      <div className="grid grid-cols-4 gap-2 mt-4">
        {singleOrbitData?.honors?.map((honor) => (
          <div
            key={honor?.id}
            className=" flex flex-col items-center text-center justify-center min-h-fit"
          >
            <div className="font-semibold  text-backdrop capitalize text-xs">
              {honor?.name}
            </div>
            <img
              src={honor?.image_url}
              className={`w-[64px] object-contain mt-1`}
            />
          </div>
        ))}
      </div>
      <div className="mt-3">
        Collect{" "}
        <span className="font-semibold">{levelUpData?.total_left_honors}</span>{" "}
        more Honors on this Orbit to reach the next one
      </div>
    </BasicContainerWithTitle>
  )
}

export default GalaxyAchievements
