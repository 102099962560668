import React from "react"
import { useBasic } from "../../hooks/cv-builder/useBasic"
import { CardViewBasic } from "./about-me/CardViewBasic"
import { CardViewSocial } from "./about-me/CardViewSocial"
import { CardUpdateSocial } from "./about-me/CardUpdateSocial"
import { CardUpdateBasic } from "./about-me/CardUpdateBasic"
import { Account } from "../../hooks/cv-builder/useProfile"

interface Props {
  data: Account | undefined
}

export const AboutMe: React.FC<Props> = ({ data }) => {
  const { isUpdate, handleUpdate, dataBasic, dataSocial, countries } =
    useBasic(data)
  return (
    <div className="mb-5">
      <div className="flex gap-5 ">
        {isUpdate[0] ? (
          <CardUpdateBasic
            data={dataBasic}
            handleChangeState={handleUpdate}
            countries={countries}
          />
        ) : (
          <CardViewBasic data={data} handleUpdate={handleUpdate} />
        )}
      </div>
      <div className="flex gap-5 mt-5">
        {isUpdate[1] ? (
          <CardUpdateSocial
            data={dataSocial}
            handleChangeState={handleUpdate}
          />
        ) : (
          <CardViewSocial data={data} handleUpdate={handleUpdate} />
        )}
      </div>
    </div>
  )
}
