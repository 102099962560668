/* eslint-disable  */

import React from "react"
import { LoadingOutlined } from "@ant-design/icons"
import { DetailHeader } from "../../components/jobBoards/DetailHeader"
import { DetailDescription } from "../../components/jobBoards/DetailDescription"
import { DetailApply } from "../../components/jobBoards/DetailApply"
import { DetailGreenSkill } from "../../components/jobBoards/DetailGreenSkill"
import { DetailSoftSkill } from "../../components/jobBoards/DetailSoftSkill"
import { DetailFit } from "../../components/jobBoards/DetailFit"
import { DetailSimiliarJob } from "../../components/jobBoards/DetailSimiliarJob"
import { Result, Spin } from "antd"
import { DetailCompany } from "../../components/jobBoards/DetailCompany"
import { DetailTechnicalSkill } from "../../components/jobBoards/DetailTechnicalSkill"
import { useJobBoardSoftSkill } from "../../hooks/jobBoards/useJobBoardSoftSkill"
import { useJobBoardFit } from "../../hooks/jobBoards/useJobBoardFit"
import { DetailDrawerApply } from "../../components/jobBoards/DetailDrawerApply"
import { useJobBoardDetail } from "../../hooks/jobBoards/useJobBoardDetail"
import { useApply } from "../../hooks/jobBoards/useApply"
import { PageContent } from "../../../../common/layout"

export const DetailJobBoards = () => {
  const {
    data,
    isLoading,
    isError,
    isFollow,
    followHandler,
    defaultSoftSkill,
  } = useJobBoardDetail()
  const {
    data: dataFit,
    isLoading: isLoadingFit,
    isError: isErrorFit,
  } = useJobBoardFit()
  const { data: dataSoftSkill } = useJobBoardSoftSkill()
  const { drawer, stage, user, helper, action, disqualify, applyLoad } =
    useApply(data)

  return (
    <PageContent
      content={
        <div className="space-y-5">
          <DetailDescription data={data?.job} isLoading={isLoading} />
          <DetailGreenSkill data={data?.job} isLoading={isLoading} />
          <DetailTechnicalSkill data={data?.job?.technical_skills} />
          {data && (
            <DetailDrawerApply
              data={data}
              drawer={drawer}
              stage={stage}
              user={user}
              helper={helper}
              action={action}
              disqualify={disqualify}
              applyLoad={applyLoad}
            />
          )}
        </div>
      }
      header={<DetailHeader data={data} />}
      contentRightSidebar={
        <div className=" space-y-5">
          <DetailApply
            data={data?.job}
            action={() => drawer.setIsApply(true)}
          />
          <DetailFit
            dataFit={dataFit}
            isLoading={isLoadingFit}
            isError={isErrorFit}
          />
          <DetailCompany
            data={data?.company}
            isFollow={isFollow}
            followHandler={followHandler}
          />
          <DetailSimiliarJob data={data?.similar_jobs} />
        </div>
      }
      isError={isError}
    />
  )
}
