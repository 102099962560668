import { Collapse } from "antd"
const { Panel } = Collapse

export function NotificationInstructions() {
  return (
    <Collapse accordion>
      <Panel header="Google Chrome / Edge" key="1">
        <ol>
          <li>
            Click the <strong>three dots</strong> in the top-right corner of the
            browser, then select <strong>Settings</strong>.
          </li>
          <li>
            Scroll down and click on <strong>Privacy and security</strong>, then
            click on <strong>Site settings</strong>.
          </li>
          <li>
            Under <strong>Permissions</strong>, click on{" "}
            <strong>Notifications</strong>.
          </li>
          <li>
            Make sure the toggle for{" "}
            <strong>Ask before sending (recommended)</strong> is turned on.
          </li>
        </ol>
      </Panel>
      <Panel header="Mozilla Firefox" key="2">
        <ol>
          <li>
            Click the <strong>three lines</strong> in the top-right corner of
            the browser, then select <strong>Options</strong>.
          </li>
          <li>
            Select <strong>Privacy & Security</strong> from the left-hand menu.
          </li>
          <li>
            Scroll down to the <strong>Permissions</strong> section and click on{" "}
            <strong>Settings</strong> next to <strong>Notifications</strong>.
          </li>
          <li>
            Make sure that{" "}
            <strong>Block new requests asking to allow notifications</strong> is
            unchecked.
          </li>
        </ol>
      </Panel>
      <Panel header="Safari" key="3">
        <ol>
          <li>
            Click on <strong>Safari</strong> in the menu bar at the top of your
            screen, then select <strong>Preferences</strong>.
          </li>
          <li>
            Click on the <strong>Websites</strong> tab and then click on{" "}
            <strong>Notifications</strong> in the left sidebar.
          </li>
          <li>
            Check the box next to{" "}
            <strong>
              Allow websites to ask for permission to send push notifications
            </strong>
            .
          </li>
        </ol>
      </Panel>
    </Collapse>
  )
}
