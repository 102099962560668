import React from "react"
import { Invoice } from "../../hooks/billing/types"
import dayjs from "dayjs"
import { Tag } from "antd"
import { Link } from "react-router-dom"

interface Props {
  data?: Invoice[]
  isLoading: boolean
}
const Invoices: React.FC<Props> = ({ data }) => {
  return (
    <div className="rounded-xl shadow-md bg-[#FAFAFA] p-5">
      <h3>Invoices</h3>

      <div className="mt-2">
        {data && data.length > 0
          ? data?.map((item, index) => {
              return (
                <Link
                  to={item.hosted_invoice_url}
                  target="_blank"
                  className="flex lg:flex-nowrap flex-wrap gap-5 mb-2"
                  key={index}
                >
                  <div className="min-w-[80px]">
                    {dayjs
                      .unix(item.status_transitions.paid_at)
                      .format("MMM DD, YYYY")}
                  </div>
                  <div className="uppercase text-center min-w-[100px]">
                    {`${item.currency} ${(item.amount_paid / 100).toFixed(2)}`}
                  </div>
                  <div className="text-center">
                    <Tag color="green" className=" capitalize">
                      {item.status}
                    </Tag>
                  </div>
                  <div>
                    {item.lines.data.map((item) => item.description).join(", ")}
                  </div>
                </Link>
              )
            })
          : "No invoice history"}
      </div>
    </div>
  )
}

export default Invoices
