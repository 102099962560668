import React from "react"
import { Route, Routes } from "react-router-dom"

// const NotFound = React.lazy(() => import("../auth/page/NotFound"))
import NotFound from "../auth/page/NotFound"
// const Cart = React.lazy(() => import("./page/Cart"))
import Cart from "./page/Cart"
// const StatusPayment = React.lazy(() => import("./page/StatusPayment"))
import StatusPayment from "./page/StatusPayment"
const Index = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Cart />} />
        <Route path="/success" element={<StatusPayment />} />

        {/* note! each route must be contain this route error to handle error after parent route*/}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  )
}

export default Index
