import React from "react"
import { updateCommentStore } from "../../store/updateCommentStore"
import { Input, Modal } from "antd"
import { Controller } from "react-hook-form"
import { usePostComment } from "../hooks/usePostComment"
import { ICommunityComment } from "../types/commonn"

const UpdateCommentModal = () => {
  const { comment, isOpen, closeModal } = updateCommentStore()
  const { control, handleSubmit, errors, onSubmit, updateComment } =
    usePostComment(comment as ICommunityComment)

  return (
    <Modal
      open={isOpen}
      title={"Update Comment"}
      onCancel={closeModal}
      onOk={handleSubmit(onSubmit)}
      okText="Update"
      okButtonProps={{
        loading: updateComment.isLoading,
        disabled: Object.keys(errors).length > 0 ? true : false,
        className: `bg-primary text-white `,
      }}
      classNames={{
        body: "!rounded-xl",
        content: "!rounded-xl",
      }}
      // width={720}
    >
      <div className="flex-grow w-full py-2">
        <Controller
          name="body"
          control={control}
          rules={{
            required: "Comment is required!",
          }}
          render={({ field }) => (
            <Input.TextArea
              {...field}
              placeholder="Type your comment here..."
              style={{
                resize: "none",
                height: 200,
              }}
              styles={{
                textarea: {
                  backgroundColor: "#F0F2F5",
                },
              }}
              className="flex-grow mb-4 border-none resize-none focus:border-none "
            />
          )}
        />
      </div>
    </Modal>
  )
}

export default UpdateCommentModal
