import {
  CheckOutlined,
  ClockCircleOutlined,
  DollarCircleOutlined,
  EnvironmentOutlined,
  FacebookOutlined,
  FileOutlined,
  HomeOutlined,
  InstagramOutlined,
  LinkOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  LoadingOutlined,
} from "@ant-design/icons"
import { Avatar, Button, Image, Result, Tag, message } from "antd"
import dayjs from "dayjs"
import { useContext } from "react"
import { QueryClient, useMutation, useQuery, useQueryClient } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import {
  BasicContainerWithTitle,
  BasicUserInfo,
  SchoolTag,
} from "../../../common/components"
import { BitmovinPlayer } from "../../../common/components/BitmovinPlayer"
import { PageContent, PageHeader } from "../../../common/layout"
import { AccountContext, CartContext } from "../../../common/layout/PageLayout"
import instance from "../../../config/config"
import { openInNewTab } from "../../../utils/util_funcs"
import { registerToWorkshop, workshopAndProgramsDetail } from "../apis/workhsop"
import { DiscussionPanel } from "../components/workshops/DiscussionPanel"
import {
  AddWorkshopToCartPayload,
  Workshop,
  WorkshopMode,
} from "../types/workshops"
import { formatNumber } from "../../../utils/formatNumber"
import { Countdown } from "../components/workshops/Countdown"

const SingleWorkshop = () => {
  const { item_id } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const account = useContext(AccountContext)
  const cartsContext = useContext(CartContext)

  const isAlreadyInCart = cartsContext?.carts?.data?.data?.products?.some(
    (item) => item?.product_id === item_id,
  )

  if (!item_id) navigate("/not-found")

  const workshopQuery = useQuery(
    ["workshop", item_id, localStorage.getItem("currency_code")],
    () =>
      workshopAndProgramsDetail(
        item_id ?? "",
        localStorage.getItem("currency_code") || "sgd",
      ),
  )

  const workshopData = workshopQuery?.data?.data?.data

  // useEffect(() => {
  //   if (workshopData && workshopData.mode === "pre-recorded") {

  //     setCountdown({ days, hours, minutes, seconds })
  //   }
  // }, [workshopData])

  const registerMutation = useMutation((item_id: string) =>
    registerToWorkshop(item_id),
  )

  const addToCartMutation = useMutation({
    mutationFn: (val: AddWorkshopToCartPayload[]) => {
      return instance.post(`transactions/carts`, val)
    },
    mutationKey: "add-cart-workshop",
  })

  const handleRegister = async (priceId: string) => {
    if (workshopData?.is_paid) {
      // check if already added to cart

      message.open({
        content: "Adding paid workshop to cart...",
        key: "register",
        duration: 5,
        type: "loading",
      })
      const dataToPost = [
        {
          image_url:
            workshopData?.cover_image ?? "https://via.placeholder.com/150",
          price:
            WorkshopMode.PRE_RECORDED &&
            workshopData.is_replayable &&
            dayjs.utc().unix() > dayjs(workshopData.release_date).unix()
              ? workshopData.replay_price
              : workshopData.price,
          price_id_stripe: priceId,
          product_id: workshopData?.id,
          product_id_stripe: workshopData?.stripe_product_id,
          product_name: workshopData?.title,
          product_owner_id: workshopData?.school?._id,
          product_owner_id_stripe: workshopData?.school?.stripe_account_id,
          sharing_profit: workshopData?.school?.sharing_profit,
          proficient: 5,
          user_id: account?.data?.data?.id ?? "",

          is_workshop: true,
        },
      ]
      await addToCartMutation.mutateAsync(dataToPost, {
        onSuccess: async () => {
          await queryClient.invalidateQueries(["carts"])
          message.open({
            content: "Workshop added to cart!",
            key: "register",
            duration: 5,
            type: "success",
          })
        },
        onError: () => {},
      })
      return
    }
    message.open({
      content: "Registering...",
      key: "register",
      duration: 5,
      type: "loading",
    })
    await registerMutation.mutateAsync(item_id ?? "", {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["workshop", item_id])
        message.open({
          content: "Successfully registered!",
          key: "register",
          duration: 5,
          type: "success",
        })
      },
      onError: () => {
        message.open({
          content: "Error in registering, please try again",
          key: "register",
          duration: 5,
          type: "error",
        })
      },
    })
  }

  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "Workshops",
    },
    {
      title: <div className=" capitalize">{workshopData?.title}</div>,
    },
  ]

  return workshopQuery.isLoading || workshopQuery.isFetching ? (
    <div className=" absolute left-[calc(50%-1.125rem)] top-1/2 text-4xl text-primary">
      <LoadingOutlined />
    </div>
  ) : !workshopData ? (
    <Result status={404} />
  ) : (
    <PageContent
      header={<PageHeader showBack breadcrumbItems={items} />}
      content={
        <>
          {helperViewMedia(workshopData) ? (
            <div className="h-96 object-contain">
              <BitmovinPlayer
                dash={workshopData?.media?.dash || ""}
                isReplayable={helperPlay(workshopData)}
                startAt={helperPlayAt(workshopData)}
                callback={helperCallback(workshopData, queryClient)}
              />
            </div>
          ) : (
            <div
              style={{
                backgroundImage: workshopData?.cover_image
                  ? `url(${workshopData?.cover_image})`
                  : `url(https://via.placeholder.com/150)`,
                height: "388px",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              className="rounded-lg shadow-lg"
            />
          )}

          {helperViewMedia(workshopData) && (
            <BasicContainerWithTitle titleSize="h2" title="Workshop Materials">
              <div
                className=""
                dangerouslySetInnerHTML={{
                  __html: workshopData?.material ?? "",
                }}
              ></div>
              {workshopData?.documents?.map((doc, idx) => (
                <div
                  key={idx}
                  onClick={() => openInNewTab(doc?.url)}
                  className="rounded-full w-fit cursor-pointer py-1 px-4 mt-4 text-brown bg-secondaryYellow flex items-center gap-2"
                >
                  <FileOutlined />
                  {`${doc.file_name || doc.name}`}
                </div>
              ))}
            </BasicContainerWithTitle>
          )}
          <BasicContainerWithTitle titleSize="h2" title="Descriptions">
            <div
              className=""
              dangerouslySetInnerHTML={{
                __html: workshopData?.description ?? "",
              }}
            ></div>
          </BasicContainerWithTitle>

          <div className="mt-6 basic-card bg-white-custom">
            <div className="flex gap-4 md:flex-nowrap flex-wrap">
              <div className="px-[39px] py-3 rounded-xl bg-white shadow-xl border-2 border-backdrop flex flex-col justify-center">
                <Image
                  src={workshopData?.school?.logo}
                  width={116}
                  preview={false}
                  className="object-contain mx-auto"
                />
                <h3 className="text-center mt-2">
                  {workshopData?.school?.name}
                </h3>
                <div className="text-xs text-zinc-400 mt-2 flex items-center gap-2 justify-center">
                  <EnvironmentOutlined />
                  <span>{workshopData?.school?.location}</span>
                </div>
                <div className="flex items-center gap-4 mt-2 text-[14px] text-darkGrey">
                  <a href={workshopData?.school?.social_url?.website_url}>
                    <LinkOutlined className="text-darkGrey" />
                  </a>
                  <a href={workshopData?.school?.social_url?.linkedin_url}>
                    <LinkedinOutlined className="text-darkGrey" />
                  </a>
                  <a href={workshopData?.school?.social_url?.twitter_url}>
                    <TwitterOutlined className="text-darkGrey" />
                  </a>
                  <a href={workshopData?.school?.social_url?.facebook_url}>
                    <FacebookOutlined className="text-darkGrey" />
                  </a>
                  <a href={workshopData?.school?.social_url?.instagram_url}>
                    <InstagramOutlined className="text-darkGrey" />
                  </a>
                </div>
              </div>
              <p className="text-base">{workshopData?.school?.description}</p>
            </div>
            <p className="mt-6 text-base">Facilitator</p>
            <BasicUserInfo
              className="mt-4"
              avatarProps={{ size: 55 }}
              avatar={workshopData?.trainer?.avatar}
              name={workshopData?.trainer?.name}
              location={workshopData?.trainer?.email}
            />
            <p className="mt-4">{workshopData?.school?.description}</p>
          </div>
        </>
      }
      contentRightSidebar={
        workshopData && (
          <>
            <BasicContainerWithTitle
              titleSize="h3"
              title={workshopData?.title ?? ""}
              isLoading={workshopQuery.isLoading}
            >
              {/* <h3>{workshopData?.title}</h3> */}
              <div className="flex justify-between items-center mt-3">
                <SchoolTag
                  isLoading={workshopQuery.isLoading}
                  schoolImage={workshopData?.school?.logo ?? ""}
                  schoolName={workshopData?.school?.name ?? ""}
                />
                <Avatar
                  src={workshopData?.trainer?.avatar}
                  size={30}
                  className="shadow-xl"
                />
              </div>
              <div className="flex gap-x-2 items-center mt-3">
                {workshopData?.currency?.logo ? (
                  <img
                    className=" w-4 h-4 rounded-full object-cover"
                    src={workshopData?.currency?.logo}
                  />
                ) : (
                  <DollarCircleOutlined className="text-[1.1rem]" />
                )}
                {workshopData.mode === WorkshopMode.PRE_RECORDED &&
                workshopData.is_replayable &&
                dayjs.utc().unix() >=
                  dayjs(workshopData.release_date).unix() ? (
                  <div>
                    {workshopData?.replay_price === 0
                      ? "FREE"
                      : formatNumber(
                          workshopData?.replay_price *
                            (workshopData?.currency?.exchange || 1),
                        )}
                  </div>
                ) : (
                  <div>
                    {workshopData?.price === 0
                      ? "FREE"
                      : formatNumber(
                          workshopData?.price *
                            (workshopData?.currency?.exchange || 1),
                        )}
                  </div>
                )}
              </div>
              {workshopData.mode === WorkshopMode.PRE_RECORDED &&
              dayjs.utc().unix() < dayjs(workshopData.release_date).unix() ? (
                <Countdown workshop={workshopData} />
              ) : workshopData.mode === WorkshopMode.PRE_RECORDED &&
                dayjs.utc().unix() >
                  dayjs(workshopData.release_date).unix() +
                    Math.round(workshopData.media?.duration || 0) &&
                !workshopData.is_replayable ? (
                <Tag className="mt-2">This workshop occured in the past</Tag>
              ) : (
                <div className="flex gap-x-2 mt-2">
                  <ClockCircleOutlined className="text-lg" />
                  <div>watch now</div>
                </div>
              )}
              {workshopData.mode === WorkshopMode.PRE_RECORDED &&
              dayjs.utc().unix() >
                dayjs(workshopData.release_date).unix() +
                  Math.round(workshopData.media?.duration || 0) &&
              !workshopData.is_replayable ? (
                <div></div>
              ) : workshopData?.is_registered ? (
                <div className="text-primary flex items-center gap-2 mt-4 w-full justify-center font-bold">
                  <CheckOutlined /> <div className="">I am registered</div>
                </div>
              ) : workshopData.mode === WorkshopMode.PRE_RECORDED &&
                dayjs.utc().unix() > dayjs(workshopData.release_date).unix() &&
                !workshopData.is_replayable ? (
                <div></div>
              ) : (
                <Button
                  className="text-white bg-primary w-full mt-4"
                  loading={
                    registerMutation.isLoading || addToCartMutation.isLoading
                  }
                  disabled={typeof item_id !== "string" || isAlreadyInCart}
                  onClick={() =>
                    handleRegister(
                      workshopData.mode === WorkshopMode.PRE_RECORDED &&
                        workshopData.is_replayable &&
                        dayjs.utc().unix() >
                          dayjs(workshopData.release_date).unix()
                        ? workshopData.stripe_product_reply_price_id
                        : workshopData.stripe_product_price_id,
                    )
                  }
                >
                  {!workshopData?.is_paid
                    ? "Register"
                    : isAlreadyInCart
                      ? "Already added to cart"
                      : "Register"}
                </Button>
              )}
            </BasicContainerWithTitle>
            {item_id && workshopData?.is_registered && (
              <DiscussionPanel item_id={item_id} />
            )}
          </>
        )
      }
    />
  )
}

const helperViewMedia = (w: Workshop) => {
  if (
    w.is_registered &&
    w.media &&
    w.media.status === "FINISHED" &&
    w.media.dash
  ) {
    if (w.mode === WorkshopMode.ON_DEMAND) {
      return true
    }
    if (
      w.mode === WorkshopMode.PRE_RECORDED &&
      !w.is_replayable &&
      dayjs.utc().unix() <
        dayjs(w.release_date).unix() + Math.round(w.media?.duration || 0)
    ) {
      return true
    }

    if (w.mode === WorkshopMode.PRE_RECORDED && w.is_replayable) {
      return true
    }
    return false
  }
  return false
}

const helperPlay = (w: Workshop) => {
  if (w.mode === WorkshopMode.ON_DEMAND) {
    return true
  }
  if (
    w.mode === WorkshopMode.PRE_RECORDED &&
    dayjs.utc().unix() <
      dayjs(w.release_date).unix() + Math.round(w.media?.duration || 0)
  ) {
    return false
  }
  return true
}

const helperPlayAt = (w: Workshop) => {
  if (
    w.mode === WorkshopMode.PRE_RECORDED &&
    dayjs.utc().unix() <
      dayjs(w.release_date).unix() + Math.round(w.media?.duration || 0)
  ) {
    const tempDiff = dayjs.utc().unix() - dayjs(w.release_date).unix()

    return tempDiff
  }
  return undefined
}

const helperCallback = (w: Workshop, qc: QueryClient) => {
  if (
    w.mode === WorkshopMode.PRE_RECORDED &&
    dayjs.utc().unix() <
      dayjs(w.release_date).unix() + Math.round(w.media?.duration || 0)
  ) {
    return () => {
      qc.refetchQueries(["workshop"])
    }
  }
  return undefined
}

export default SingleWorkshop
