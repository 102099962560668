// import { EventType } from '@azure/msal-browser'
import React, { Suspense } from "react"
import { useMsal } from "@azure/msal-react"
import { Navigate, Route, Routes } from "react-router-dom"
import { Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import Certificate from "../modules/missionControl/pages/Certificate.tsx"
import CertificateShared from "../modules/missionControl/pages/CertificateShared"
// import { b2cPolicies } from '../authConfig'

// const LearningV2 = React.lazy(
//   () => import("../modules/missionControl/pages/LearningV2"),
// )
import LearningV2 from "../modules/missionControl/pages/LearningV2"
// const QuizTest = React.lazy(
//   () => import("../modules/missionControl/pages/QuizTest"),
// )
import QuizTest from "../modules/missionControl/pages/QuizTest"
// const QuizResult = React.lazy(
//   () => import("../modules/missionControl/pages/QuizResult"),
// )
import QuizResult from "../modules/missionControl/pages/QuizResult"

const OtherRoute = () => {
  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()

  // eslint-disable-next-line react/prop-types
  const ProtectRoutes = ({ children }) => {
    return activeAccount ? children : <Navigate to="/" />
  }

  return (
    // <Suspense
    //   fallback={
    //     <div className=" h-screen w-screen flex justify-center items-center">
    //       <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    //     </div>
    //   }
    // >
    <Routes>
      <Route
        path="/learnings/modules/:skill_id/:skill_name/:level_name/:level_id/:module_id"
        element={
          <ProtectRoutes>
            <LearningV2 />
          </ProtectRoutes>
        }
      />
      <Route
        path="/learnings/assessment/:skill_id/:skill_name/:level_name/:level_id/:module_id"
        element={
          <ProtectRoutes>
            <QuizTest />
          </ProtectRoutes>
        }
      />
      <Route
        path="/learnings/result/:id/:skill_name/:level_name/:score/:is_passed"
        element={
          <ProtectRoutes>
            <QuizResult />
          </ProtectRoutes>
        }
      />

      <Route
        path="/learnings/preview/certificate"
        element={
          <ProtectRoutes>
            <Certificate />
          </ProtectRoutes>
        }
      />

      <Route
        path="/learnings/preview/certificate/:skill_id/:user_id/:skill_name"
        element={<CertificateShared />}
      />

      <Route
        path="*"
        element={<>route not register on this web aplication</>}
      />
    </Routes>
    // </Suspense>
  )
}

export default OtherRoute
