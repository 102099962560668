import React from "react"
import { THowToPostBody } from "../../types/commonn"
import clsx from "clsx"
import { BulbOutlined } from "@ant-design/icons"
import { Image } from "antd"

interface HowToProps {
  body: THowToPostBody
  category?: string
  title: string
}

const HowTo: React.FC<HowToProps> = ({ body, category, title }) => {
  return (
    <div className="">
      {category && (
        <div className="flex items-center gap-5 text-primary text-xs tracking-[3.4px] uppercase font-semibold">
          <BulbOutlined className="text-lg" /> {category}
        </div>
      )}
      <h4 className="text-base font-bold mt-2">{title}</h4>
      {body?.image_url && (
        <div
          className="h-[260px] my-5 object-contain bg-cover bg-no-repeat bg-center"
          style={{ backgroundImage: `url(${body?.image_url})` }}
        />
      )}
      <div className="flex flex-wrap justify-center gap-5">
        {body?.items?.map((howTo, index) => (
          <HowToCard key={howTo.id} step={index + 1} {...howTo} />
        ))}
      </div>
    </div>
  )
}

interface HowToCardProps {
  id: string
  step: number
  name: string
  description: string
  image_url: string
}

const HowToCard: React.FC<HowToCardProps> = ({
  step,
  name,
  description,
  image_url,
}) => {
  return (
    <div className="flex flex-wrap lg:flex-nowrap gap-7 items-center bg-white relative min-w-full rounded-lg overflow-hidden shadow-none ring-1 ring-gray-300 transform transition duration-500 p-7">
      {image_url && (
        <div className="w-[216px] object-contain shadow">
          <Image
            className="w-full h-full object-cover"
            src={image_url}
            alt={name}
          />
        </div>
      )}
      <div className="absolute rounded-bl-lg right-0 top-0 font-bold bg-secondary text-white text-base  px-6 py-3">
        Step {step}
      </div>
      <div className={clsx("", image_url ? "w-full" : "w-2/3")}>
        <div className="flex flex-col justify-center items-start gap-2">
          <div className="font-semibold text-base mb-2 ">{name}</div>
        </div>
        <div className="text-gray-700 text-base">{description}</div>
      </div>
    </div>
  )
}
export default HowTo
