import React from "react"
import { Button, Input, message } from "antd"
import { Controller, useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import { IAddCommunityPost } from "../../types/commonn"
import { createCommunityPost } from "../../apis/feed-post"
import NewPostIcons from "../NewPostIcons"
import { SendOutlined } from "@ant-design/icons"
import { useNewPostStore } from "../../../store/newPostStore"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"

const schema = z.object({
  body: z.string().trim().min(1, { message: "Required" }),
})

export type DefaultFormData = z.infer<typeof schema>

const Default = () => {
  const createPost = useMutation(createCommunityPost)
  const queryClient = useQueryClient()
  const { closeModal } = useNewPostStore()

  const {
    control,
    handleSubmit,
    resetField,
    formState: { isDirty, isValid },
  } = useForm<DefaultFormData>({
    resolver: zodResolver(schema),
    defaultValues: { body: "" },
  })

  const onSubmit = async (data: DefaultFormData) => {
    message.loading({ content: "Creating post...", key: "create-post" })

    await createPost.mutateAsync(data as IAddCommunityPost, {
      onSuccess: () => {
        queryClient.invalidateQueries("community-post-list")
        message.success({
          content: "Post created successfully!",
          key: "create-post",
        })
        closeModal()
        resetField("body")
      },
      onError: (error: any) => {
        message.error({
          content: error.response?.data?.message || "Something went wrong!",
          key: "create-post",
        })
      },
    })
  }

  return (
    <>
      <form className={`my-6 `}>
        <Controller
          name="body"
          control={control}
          rules={{
            required: "Body is required!",
            minLength: 4,
          }}
          render={({ field }) => (
            <Input.TextArea
              {...field}
              placeholder="What do you have on your mind today?"
              rows={8}
              style={{ resize: "none" }}
              styles={{
                textarea: {
                  backgroundColor: "",
                  color: "",
                },
              }}
              maxLength={1000}
              className="text-lg border-none resize-none focus:border-none"
            />
          )}
        />
      </form>
      <div className="flex items-center justify-between w-full">
        <NewPostIcons />
        <div className="flex items-center gap-4">
          <Button onClick={closeModal}>Cancel</Button>
          <Button
            className="text-white bg-primary"
            loading={createPost.isLoading}
            icon={<SendOutlined />}
            disabled={createPost.isLoading || !isDirty || !isValid}
            onClick={handleSubmit(onSubmit)}
          >
            Post
          </Button>
        </div>
      </div>
    </>
  )
}

export default Default
