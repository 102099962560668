import React from "react"
import { HomeOutlined, LoadingOutlined } from "@ant-design/icons"
import { Result, Spin } from "antd"
import { Breadcrumbs } from "../../../../common/components"
import { useNavigate } from "react-router-dom"
import { useJobRoleDetail } from "../../hooks/jobRoles/useJobRoleDetail"
import { DetailHeader } from "../../components/jobRoles/DetailHeader"
import { DetailFloat } from "../../components/jobRoles/DetailFloat"
import { DetailJobSkill } from "../../components/jobRoles/DetailJobSkill"
import { DetailFit } from "../../components/jobRoles/DetailFit"
import { DetailCareerAspiration } from "../../components/jobRoles/DetailCareerAspiration"

export const DetailJobRoles = () => {
  const { data, isLoading } = useJobRoleDetail()
  const navigate = useNavigate()
  const items = [
    { path: "/", title: <HomeOutlined className="text-gray-400" /> },
    { title: "Jobs Galaxy" },
    { path: "/job/roles", title: "Job Roles" },
    { title: "Green Job Profiles" },
    {
      title: isLoading ? (
        <LoadingOutlined />
      ) : (
        <div className=" capitalize">{data?.name}</div>
      ),
    },
  ]
  return (
    <div className=" relative">
      <div className="bg-white py-4 px-6">
        <Breadcrumbs items={items} />
        <div
          className=" text-secondary cursor-pointer mt-3 mb-5 inline-block"
          onClick={() => navigate(-1)}
        >{`<< back`}</div>
        {data && !isLoading && (
          <div className="mt-3">
            <DetailHeader data={data} />
          </div>
        )}
      </div>
      {isLoading ? (
        <div className="relative h-[200px]">
          <div className="absolute top-[80%] left-1/2 transform ">
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          </div>
        </div>
      ) : data ? (
        <>
          <div className="m-5">
            <DetailJobSkill data={data} />
          </div>
          <div className="hidden md:inline">
            <DetailFloat id={data?.id} />
          </div>
          <div className="!mx-4">
            <div className="inline md:hidden">
              <DetailFit id={data?.id} />
              <div className="mt-5">
                <DetailCareerAspiration />
              </div>
            </div>
          </div>
        </>
      ) : (
        <Result
          status="500"
          title="500"
          subTitle="Sorry, the data you search does not exist."
        />
      )}
    </div>
  )
}
