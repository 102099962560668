/* eslint-disable */

import { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { instanceV2 } from "../../../../config/config"
import { AxiosResponse } from "axios"
import { message } from "antd"

export type Certification = {
  id: string
  organisation: {
    name: string
    id: string
    image: string
  }
  certification_name: string
  credentials_url: string
  has_expiry_date: boolean
  start_at: string
  end_at: string
  isEdit?: boolean
}

export type OtherOrganisation = {
  name: string
  id: string
  image: string
}

export type Common = {
  id: string
  name: string
}

export const useCertification = () => {
  const [tempData, setTempData] = useState<Certification[]>([])
  const queryClient = useQueryClient()
  const handleAddDown = () => {
    const temp: Certification = {
      id: "",
      organisation: {
        name: "",
        id: "",
        image: "",
      },
      certification_name: "",
      has_expiry_date: false,
      start_at: "",
      end_at: "",
      credentials_url: "",
      isEdit: true,
    }
    setTempData((prev) => [...prev, temp])
  }

  const handleAddUp = () => {
    const temp: Certification = {
      id: "",
      organisation: {
        name: "",
        id: "",
        image: "",
      },
      certification_name: "",
      has_expiry_date: false,
      start_at: "",
      end_at: "",
      credentials_url: "",
      isEdit: true,
    }
    setTempData((prev) => [temp, ...prev])
  }

  const updateTempData = (index: number, data: Certification) => {
    const temp = [...tempData]
    temp[index] = data
    setTempData(temp)
  }

  const { data: certif, isFetching: loadingCertif } = useQuery("certif", () =>
    instanceV2.get<AxiosResponse<Certification[]>>("/accounts/certifications"),
  )

  useEffect(() => {
    if (certif?.data?.data) {
      setTempData(certif?.data?.data || [])
    }
  }, [certif?.data?.data])

  const { mutateAsync: removeCertif, isLoading: loadingremoveCertif } =
    useMutation({
      mutationFn: async (id: string) => {
        try {
          const res = await instanceV2.delete(
            `/accounts/certifications/id/${id}`,
          )
          return res
        } catch (error) {
          throw error
        }
      },
      mutationKey: "account-remove-certification",
    })

  const onRemove = async (val: string) => {
    message.open({
      key: "remove-certification",
      content: "removing...",
      type: "loading",
    })
    try {
      const response = await removeCertif(val)
      if (response) {
        queryClient.invalidateQueries(["account-cv"]).then(() => {
          setTempData((prev) => prev.filter((item) => item.id !== val))
          message.destroy()
        })
      }
    } catch (error: any) {
      message.open({
        key: "remove-certification",
        content: error?.response?.data?.data || "Internal server error",
        type: "error",
      })
    }
  }

  const onRemoveTemp = (val: number) => {
    setTempData((prev) => prev.filter((_, index) => index !== val))
  }

  return {
    tempData,
    updateTempData,
    handleAddDown,
    handleAddUp,
    loadingCertif,
    onRemove,
    loadingremoveCertif,
    onRemoveTemp,
  }
}
