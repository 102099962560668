import React, { useCallback, useState } from "react"
import { ProgressBar } from "../components/ProgressBar"
import { Button, Skeleton } from "antd"
import { useNavigate, useParams } from "react-router-dom"
import { useSingleMyLaunchpad } from "../hooks/useSingleMyLaunchpad"
import { useMsal } from "@azure/msal-react"
import { Timer } from "../components"
import { useLearning } from "../hooks/useLearning"
import useLoading from "../../../hooks/Loading"
import Lottie from "lottie-react"
import spinner_loading_one from "../../../assets/json/spinner_loading_one.json"

const QuizTest = () => {
  const [assessment, setAssessment] = useState({ assessment: [] })

  const navigate = useNavigate()
  const { skill_id, skill_name, level_name, level_id } = useParams()

  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()

  const { addAsessment } = useSingleMyLaunchpad(
    skill_id,
    activeAccount?.localAccountId,
    level_id,
  )
  const { modules, isLoadingModules, update } = useLearning(
    skill_id,
    activeAccount?.localAccountId,
    level_id,
  )

  const [currentIndex, setCurrentIndex] = useState(0)
  const [percent, setPercent] = useState(0)
  const { isCustomLoading, setIsCustomLoading } = useLoading(true, 1200)

  const testsData =
    modules && modules?.data?.detail && modules?.data?.detail?.assessments

  const handleTimerEnd = () => {
    if (!assessment?.assessment[currentIndex]?.answer) {
      const assessmentData = {
        answer: "",
        id: currentTest?.id,
      }

      setAssessment((prevState) => {
        const assessment = prevState?.assessment?.find(
          (item) => item?.id === currentTest?.id,
        )

        if (assessment) {
          const newAssessment = prevState?.assessment?.map((item) => {
            if (item?.id === currentTest?.id) {
              return assessmentData
            } else {
              return item
            }
          })
          return { ...prevState, assessment: newAssessment }
        } else {
          return {
            ...prevState,
            assessment: [...prevState?.assessment, assessmentData],
          }
        }
      })
    }
  }

  const handleNext = () => {
    // if the user not answer
    handleTimerEnd()

    if (currentIndex < testsData?.length - 1) {
      setCurrentIndex(currentIndex + 1)
      const newPercent = ((currentIndex + 1) / (testsData.length - 1)) * 100

      setPercent(newPercent)
    }
  }

  const currentTest = testsData && testsData[currentIndex]

  const handleSubmit = async () => {
    handleTimerEnd()
    setIsCustomLoading(true)
    setTimeout(async () => {
      // setIsCustomLoading(true)
      const response = await addAsessment(assessment)

      const score = response?.data?.data?.score
      const isPassed = response?.data?.data?.passed

      // update user track to 100% if test passed
      if (isPassed) {
        const data = {
          current_module: 5,
        }
        update(data)
      }

      setIsCustomLoading(false)

      navigate(
        `/learnings/result/${skill_id}/${skill_name}/${level_name}/${score}/${isPassed}`,
        { state: { score } },
      )
    }, 1200)
  }

  const handleAnswerChange = useCallback((answer, index) => {
    const assessmentData = {
      answer: answer?.answers[index]?.answer,
      id: answer?.id,
    }
    setAssessment((prevState) => {
      const assessment = prevState?.assessment?.find(
        (item) => item?.id === answer?.id,
      )

      if (assessment) {
        const newAssessment = prevState?.assessment?.map((item) => {
          if (item?.id === answer?.id) {
            return assessmentData
          } else {
            return item
          }
        })
        return { ...prevState, assessment: newAssessment }
      } else {
        return {
          ...prevState,
          assessment: [...prevState?.assessment, assessmentData],
        }
      }
    })
  }, [])

  const isLastIndex = currentIndex === testsData?.length - 1

  return isCustomLoading ? (
    <div className="flex justify-center flex-col items-center h-screen">
      <Lottie className="w-40" animationData={spinner_loading_one} />
      Loading...
    </div>
  ) : (
    <div className="bg-white h-[calc(100vh)] py-10">
      <div className=" bg-white pb-24">
        <div className="pt-3">
          {/* <div
            className="flex items-center gap-2 mb-10 cursor-pointer ml-6"
            onClick={() => navigate(-1)}
          >
            <ArrowLeftOutlined />
            <span className="font-bold">Back to Modules</span>
          </div> */}
          <div className=" lg:w-4/6 mx-4 lg:mx-auto ">
            <ProgressBar percent={percent} />
          </div>
        </div>
        <div className=" lg:w-4/6 mx-4 lg:mx-auto my-6 flex items-center justify-between relative">
          <div>
            {currentTest?.timer_scale_duration?.duration_in_second !== 0 ? (
              <Timer
                handleNext={handleNext}
                handleSubmit={handleSubmit}
                initialTimeInSeconds={
                  currentTest?.timer_scale_duration?.duration_in_second
                }
                isLastQuestion={isLastIndex}
              />
            ) : (
              ""
            )}
          </div>
          <Button className="border-primary" onClick={() => navigate(-1)}>
            Quit & Exit
          </Button>
        </div>
        <div className="bg-blue-100 rounded-lg shadow-custom-sm py-4 relative  lg:w-4/6 mx-4 lg:mx-auto ">
          <div className="px-4">
            {isLoadingModules ? (
              <Skeleton active />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: currentTest?.question,
                }}
                className="leading-8"
              ></div>
            )}

            <div className="mt-12">
              {isLoadingModules ? (
                <Skeleton active />
              ) : (
                currentTest?.answers?.map((answer, index) => {
                  return (
                    <div
                      className={`py-2 px-4 my-2 cursor-pointer hover:bg-yellow-200 rounded-lg ${
                        answer?.answer ===
                        assessment?.assessment[currentIndex]?.answer
                          ? "bg-yellow-200"
                          : "bg-white"
                      }`}
                      style={{
                        border: "1px solid #d2d2d2",
                      }}
                      key={index}
                      onClick={() => handleAnswerChange(currentTest, index)}
                    >
                      {answer?.answer}
                    </div>
                  )
                })
              )}
            </div>
          </div>
        </div>
        <div className="hidden lg:flex justify-end lg:w-4/6 mx-4 lg:mx-auto">
          <div className="text-right mt-7">
            {currentIndex < testsData?.length - 1 ? (
              <Button
                disabled={
                  !(
                    assessment?.assessment[currentIndex] &&
                    assessment?.assessment[currentIndex]?.answer
                  )
                }
                type="primary"
                onClick={handleNext}
              >
                Next Questions
              </Button>
            ) : (
              <Button
                disabled={
                  !(
                    assessment?.assessment[currentIndex] &&
                    assessment?.assessment[currentIndex]?.answer
                  )
                }
                type="primary"
                onClick={handleSubmit}
              >
                Finish
              </Button>
            )}
          </div>
        </div>
        <div>
          <div className="lg:hidden fixed bottom-0 z-50 bg-backdrop w-full">
            <div className="mx-5">
              <div className="text-right py-2 w-full md:w-auto">
                {currentIndex < testsData?.length - 1 ? (
                  <Button
                    disabled={
                      !(
                        assessment?.assessment[currentIndex] &&
                        assessment?.assessment[currentIndex]?.answer
                      )
                    }
                    type="primary"
                    className="!text-white w-full md:w-auto"
                    // block={true}
                    onClick={handleNext}
                  >
                    Next Questions
                  </Button>
                ) : (
                  <Button
                    disabled={
                      !(
                        assessment?.assessment[currentIndex] &&
                        assessment?.assessment[currentIndex]?.answer
                      )
                    }
                    type="primary"
                    className="!text-white w-full md:w-auto"
                    // block={true}
                    onClick={handleSubmit}
                  >
                    Finish
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuizTest
