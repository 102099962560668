import React from "react"
import { PageContent } from "../../../common/layout"
import { HeaderStatusPayment } from "../components/HeaderStatusPayment"
import ContentStatusPayment from "../components/ContentStatusPayment"

const StatusPayment = () => {
  return (
    <PageContent
      header={<HeaderStatusPayment />}
      content={<ContentStatusPayment />}
    />
  )
}

export default StatusPayment
