/* eslint-disable */

import React, { useEffect, useRef, useState } from "react"
import {
  Player,
  PlayerEvent,
} from "bitmovin-player/modules/bitmovinplayer-core"
import EngineBitmovinModule from "bitmovin-player/modules/bitmovinplayer-engine-bitmovin"
import MseRendererModule from "bitmovin-player/modules/bitmovinplayer-mserenderer"
import HlsModule from "bitmovin-player/modules/bitmovinplayer-hls"
import DashModule from "bitmovin-player/modules/bitmovinplayer-dash"
import AbrModule from "bitmovin-player/modules/bitmovinplayer-abr"
import XmlModule from "bitmovin-player/modules/bitmovinplayer-xml"
import ContainerTSModule from "bitmovin-player/modules/bitmovinplayer-container-ts"
import ContainerMp4Module from "bitmovin-player/modules/bitmovinplayer-container-mp4"
import SubtitlesModule from "bitmovin-player/modules/bitmovinplayer-subtitles"
import SubtitlesCEA608Module from "bitmovin-player/modules/bitmovinplayer-subtitles-cea608"
import PolyfillModule from "bitmovin-player/modules/bitmovinplayer-polyfill"
import StyleModule from "bitmovin-player/modules/bitmovinplayer-style"
// @ts-ignore
import { UIFactory } from "bitmovin-player/bitmovinplayer-ui"
import "bitmovin-player/bitmovinplayer-ui.css"
import { useModalStore } from "../../stores"
import { message } from "antd"
import { callback } from "chart.js/dist/helpers/helpers.core"

interface Props {
  dash: string
  hls?: string
  poster?: string
  rest?: React.HTMLAttributes<HTMLDivElement>
  onClick?: () => void
  disabled?: boolean
  isReplayable?: boolean
  startAt?: number
  callback?: () => void
}

export const BitmovinPlayer: React.FC<Props> = ({
  dash,
  hls,
  poster,
  onClick,
  disabled,
  isReplayable = true,
  startAt,
  callback,
  ...rest
}) => {
  const [player, setPlayer] = useState<any>(null)
  const playerConfig = {
    key: process.env.REACT_APP_BITMOVIN_LICENSE_KEY ?? "",
  }
  const playerConfigNotReplay = {
    key: process.env.REACT_APP_BITMOVIN_LICENSE_KEY ?? "",
    playback: {
      autoplay: true,
      muted: true,
      // volume: 10,
      timeShift: false,
      seeking: false,
    },
  }
  const playerSource = {
    dash,
    hls,
    poster,
  }
  const playerDiv = useRef()
  const openModal = useModalStore((state) => state.openModal)

  useEffect(() => {
    function setupPlayer() {
      Player.addModule(EngineBitmovinModule)
      Player.addModule(MseRendererModule)
      Player.addModule(HlsModule)
      Player.addModule(XmlModule)
      Player.addModule(DashModule)
      Player.addModule(AbrModule)
      Player.addModule(ContainerTSModule)
      Player.addModule(ContainerMp4Module)
      Player.addModule(SubtitlesModule)
      Player.addModule(SubtitlesCEA608Module)
      Player.addModule(PolyfillModule)
      Player.addModule(StyleModule)

      if (playerDiv?.current && playerConfig?.key !== undefined) {
        const playerInstance = new Player(
          playerDiv?.current,
          isReplayable ? playerConfig : playerConfigNotReplay,
        )
        UIFactory.buildDefaultUI(playerInstance)
        playerInstance.load(playerSource).then(
          () => {
            setPlayer(playerInstance)
            // setPosterImg(playerInstance.get());
            console.log("Successfully loaded source")
          },
          () => {
            console.log("Error while loading source")
          },
        )
        playerInstance.on(PlayerEvent.PlaybackFinished, () => {
          if (callback) {
            setTimeout(callback, 5000)
          }
        })
      }
    }

    // find child with class bmpui-ui-watermark

    setupPlayer()

    return () => {
      function destroyPlayer() {
        if (player != null) {
          player?.destroy()
          setPlayer(null)
        }
      }

      destroyPlayer()
    }
  }, [])

  useEffect(() => {
    if (player) {
      if (startAt && startAt > 0) {
        player.seek(startAt)
      }
      const watermarksInDom = document.querySelectorAll(".bmpui-ui-watermark")
      if (watermarksInDom.length > 0) {
        watermarksInDom.forEach((watermark) => {
          watermark.setAttribute("data-url", "https://skilledin.io")
        })
      }
    }
  }, [player])

  useEffect(() => {
    const disableClick = (event: any) => {
      event.stopPropagation()
      openModal()
      // event.preventDefault();
    }

    const playWrapper = document.querySelector(
      ".bmpui-ui-hugeplaybacktogglebutton",
    )
    const plyaButton = document.querySelector(".bmpui-ui-playbacktogglebutton")
    const replayButton = document.querySelector(".bmpui-ui-hugereplaybutton")
    const playbackToggleOverlay = document.querySelector(
      ".bmpui-ui-playbacktoggle-overlay",
    )
    const controlBarBottom = document.querySelector(
      ".bmpui-ui-container.bmpui-controlbar-bottom",
    )
    const controlbar = document.querySelector(".bmpui-ui-controlbar")

    const seekbar = document.querySelector(".bmpui-seekbar")
    if (
      playbackToggleOverlay &&
      controlBarBottom &&
      seekbar &&
      playWrapper &&
      plyaButton &&
      replayButton
    ) {
      if (!isReplayable) {
        playWrapper.addEventListener(
          "click",
          (event) => {
            event.stopPropagation()
          },
          true,
        )
        // replayButton.addEventListener(
        //   "click",
        //   (event) => {
        //     message.info("you can't replay this one...")
        //     event.stopPropagation()
        //   },
        //   true,
        // )
        // @ts-ignore
        seekbar?.style.setProperty("display", "none")
        // @ts-ignore
        plyaButton?.style.setProperty("display", "none")
        // @ts-ignore
        replayButton?.style.setProperty("display", "none")
      } else if (disabled) {
        playbackToggleOverlay.addEventListener("click", disableClick, true)
        controlBarBottom.addEventListener("click", disableClick, true)
        seekbar.addEventListener("click", disableClick, true)
        // @ts-ignore
        seekbar?.style.setProperty("display", "none")
        // @ts-ignore
        controlbar?.style.setProperty("display", "none")
      }
    }

    return () => {
      if (
        playbackToggleOverlay &&
        controlBarBottom &&
        seekbar &&
        playWrapper &&
        plyaButton &&
        replayButton
      ) {
        if (!isReplayable) {
          playWrapper.addEventListener(
            "click",
            (event) => {
              event.stopPropagation()
            },
            true,
          )
          replayButton.addEventListener(
            "click",
            (event) => {
              message.info("you can't replay this one...")
              event.stopPropagation()
            },
            true,
          )
          // @ts-ignore
          seekbar?.style.setProperty("display", "none")
          // @ts-ignore
          plyaButton?.style.setProperty("display", "none")
        } else if (disabled) {
          playWrapper.addEventListener(
            "click",
            (event) => {
              event.stopPropagation()
            },
            true,
          )
          // playbackToggleOverlay.addEventListener("click", disableClick, true)
          // controlBarBottom.addEventListener("click", disableClick, true)
          // seekbar.addEventListener("click", disableClick, true)
          // @ts-ignore
          seekbar?.style.setProperty("display", "none")
          // @ts-ignore
          // controlbar?.style.setProperty("display", "none")
        }
      }
    }
  }, [disabled, openModal])
  return (
    // @ts-ignore
    <div id="player" ref={playerDiv} {...rest} onClick={onClick ?? undefined} />
  )
}
