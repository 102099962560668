import { Select, message } from "antd"
import React from "react"

function SelectComponent({ editProductHandlers, field }) {
  return (
    <Select
      {...field}
      mode="multiple"
      maxLength={3}
      maxCount={3}
      maxTagCount={3}
      // maxTagTextLength={3}
      className="my-1 font-default basis-full job-history w-full"
      placeholder="Solution Category (3 max)"
      showSearch
      status={editProductHandlers?.errors?.solutions_id ? "error" : ""}
      options={editProductHandlers?.solutionData?.listSolutions?.map((val) => ({
        label: val.name,
        value: val.id,
      }))}
      labelInValue={true}
      onChange={(option) => {
        if (option?.length < 4) {
          editProductHandlers?.onSelectChangeSolution(option)
          option.pop()
        } else {
          message.warning("You can only select 3 solutions")
        }
      }}
      // defaultValue={''}
      filterOption={(input, option) => {
        if (option && option.value) {
          return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        return false
      }}
    />
  )
}

export default SelectComponent
