import React from "react"
import useMyPosts from "../../feed/hooks/useMyPosts"
import PostDiscussion from "../../feed/components/PostDiscussionCard"
import { LoadingOutlined } from "@ant-design/icons"

const MyDiscussionsList = () => {
  const { lastCardElementRef, myPosts, myPostsQuery } = useMyPosts()
  return (
    <div className="space-y-5 mt-5">
      {myPostsQuery?.isLoading ? (
        <div className="w-full h-[250px] flex items-center justify-center">
          <LoadingOutlined className="text-xl" />
        </div>
      ) : (
        myPosts?.map((post, idx) => (
          <PostDiscussion
            observ={
              idx === myPosts?.length - 1 ? lastCardElementRef : undefined
            }
            post={post}
            key={idx}
          />
        ))
      )}
    </div>
  )
}

export default MyDiscussionsList
