import { DefaultSkillImage } from "../../../../../assets/images"
import { PageContent } from "../../../../../common/layout"

const CommunityProfile = () => {
  return (
    <PageContent
      header={null}
      content={<Content />}
      hideRightSidebarOnMobile
      contentRightSidebar={<></>}
    />
  )
}

const Content = () => {
  return (
    <>
      <section className="relative p-5 bg-white rounded-xl">
        <img
          className="absolute top-0 left-0 w-full h-[300px] object-cover rounded-t-xl z-0"
          src={DefaultSkillImage}
        />
        {/* <img className="" src="" /> */}

        <div className="mt-[330px]">
          <h1 className="text-2xl font-semibold tracking-tight">
            Hassan Zaidi
          </h1>
        </div>
      </section>
    </>
  )
}

export default CommunityProfile
