import { Divider, Input, message, Modal, ModalProps } from "antd"
import { useMutation, useQueryClient } from "react-query"
import { createCommunityPost } from "../../modules/community/feed/apis/feed-post"
import { Controller, useForm } from "react-hook-form"
import { IAddCommunityPost } from "../../modules/community/feed/types/commonn"
import { useContext } from "react"
import { ITourContext, Route, TourContext } from "./tour/TourProvider"
import { useLocation } from "react-router-dom"

const NewUserCommunityPost = ({ ...rest }: ModalProps) => {
  const createPost = useMutation(createCommunityPost)
  const {
    control,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm()
  const queryClient = useQueryClient()

  const { startTour } = useContext(TourContext) as ITourContext
  const location = useLocation()

  const onSubmit = async (data: any) => {
    message.loading({ content: "Creating post...", key: "create-post" })

    await createPost.mutateAsync(data as IAddCommunityPost, {
      onSuccess: () => {
        queryClient.invalidateQueries("community-post-list")
        message.success({
          content: "You successfully created your first post!",
          key: "create-post",
        })
        resetField("body")
      },
      onError: (error: any) => {
        message.error({
          content:
            error.response?.data?.message ||
            "Something went wrong in creating your first post!",
          key: "create-post",
        })
      },
    })
  }
  return (
    <Modal
      {...rest}
      width={600}
      centered
      okButtonProps={{
        onClick: handleSubmit(onSubmit),
        loading: createPost.isLoading,
        disabled: Object.keys(errors).length > 0,
      }}
    >
      <h3 className="text-xl">Create a new post to introduce yourself </h3>
      <p className="my-1">
        Add tags using the <b>#</b> and introduce yourself to the rest of the
        community.
      </p>
      <Divider>OR</Divider>
      <p className="my-1">
        Take the tour by{" "}
        <span
          className="cursor-pointer text-secondary"
          onClick={() => {
            sessionStorage?.setItem("showNewUserCommunityPost", "false")
            startTour(location.pathname as Route)
          }}
        >
          clicking here
        </span>
      </p>
      <Controller
        name="body"
        control={control}
        rules={{
          required: "Body is required!",
          maxLength: 1000,
          minLength: 10,
        }}
        render={({ field }) => (
          <Input.TextArea
            {...field}
            placeholder="Type your new post here..."
            showCount
            style={{ resize: "none" }}
            styles={{
              textarea: {
                backgroundColor: "#F0F2F5",
                paddingLeft: 14,
                paddingRight: 14,
                paddingTop: 14,
                paddingBottom: 14,
                marginBottom: 20,
                color: "",
              },
            }}
            maxLength={1000}
            className="flex-grow my-6 text-base border-none shadow-md resize-none focus:border-none h-60 focus:outline-1"
          />
        )}
      />
    </Modal>
  )
}

export default NewUserCommunityPost
