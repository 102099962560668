import { create } from "zustand"
import { MentorMenteeSession, MentorScheduleResponse } from "../types/response"
import { format } from "date-fns"

type ModalState = {
  isOpen: boolean
  openModal: () => void
  closeModal: () => void
}

export const storeModalSchedule = create<ModalState>((set) => ({
  isOpen: false,
  openModal: () => set(() => ({ isOpen: true })),
  closeModal: () => set(() => ({ isOpen: false })),
}))

export interface MentorScheduleState {
  MentorScheduleData: MentorScheduleResponse
  setMentorScheduleData: (data: MentorScheduleResponse) => void
  isMentorScheduleLoading: boolean
  setIsMentorScheduleLoading: (loading: boolean) => void
  selectedDate: string | null
  setSelectedDate: (date: string | null) => void
  is24HourFormat: boolean
  setIs24HourFormat: (is24Hour: boolean) => void
  currentDate: Date
  setCurrentDate: (date: Date) => void
  selectedDay: string
  setSelectedDay: (day: string) => void
  selectedTimeSlot: string | null
  setSelectedTimeSlot: (timeSlot: string | null) => void
  selectedSchedule: string | null
  setSelectedSchedule: (schedule: string | null) => void
  timezone: string
  setTimezone: (timezone: string) => void
  meetingNotes: string
  setMeetingNotes: (notes: string) => void
  requiredMeetingNote: boolean
  setRequiredMeetingNote: (notes: boolean) => void
  bookingData: MentorMenteeSession[]
  setBookingData: (data: MentorMenteeSession[]) => void
  appendBookingData: (data: MentorMenteeSession[]) => void
}

export const useMentorScheduleStore = create<MentorScheduleState>((set) => ({
  MentorScheduleData: {
    availability: {
      id: "",
      user_id: "",
      label: "",
      timezone: "",
      days: [],
      installed_apps: {
        calendars: [],
        conferencing: [],
      },
      connected_with_google: false,
      connected_with_microsoft: false,
      created_at: "",
      updated_at: null,
    },
    slots: {},
  },
  setMentorScheduleData: (data: MentorScheduleResponse) =>
    set(() => ({ MentorScheduleData: data })),
  isMentorScheduleLoading: true,
  setIsMentorScheduleLoading: (loading: boolean) =>
    set(() => ({ isMentorScheduleLoading: loading })),
  selectedDate: format(new Date(), "yyyy-MM-dd"),
  setSelectedDate: (date: string | null) => set(() => ({ selectedDate: date })),
  is24HourFormat: false,
  setIs24HourFormat: (is24Hour: boolean) =>
    set(() => ({ is24HourFormat: is24Hour })),
  currentDate: new Date(),
  setCurrentDate: (date: Date) => set(() => ({ currentDate: date })),
  selectedDay: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][
    new Date().getDay()
  ],
  setSelectedDay: (day: string) => set(() => ({ selectedDay: day })),
  selectedTimeSlot: null,
  setSelectedTimeSlot: (timeSlot: string | null) =>
    set(() => ({ selectedTimeSlot: timeSlot })),
  selectedSchedule: null,
  setSelectedSchedule: (schedule: string | null) =>
    set(() => ({ selectedSchedule: schedule })),
  timezone: "",
  setTimezone: (timezone) => set({ timezone }),
  meetingNotes: "",
  setMeetingNotes: (notes: string) => set({ meetingNotes: notes }),
  requiredMeetingNote: false,
  setRequiredMeetingNote: (notes: boolean) =>
    set({
      requiredMeetingNote: notes,
    }),
  bookingData: [],
  setBookingData: (data: MentorMenteeSession[]) => set({ bookingData: data }),
  appendBookingData: (data) =>
    set((state) => {
      if (!Array?.isArray(data)) return state // Pastikan `data` adalah array
      const existingIds = new Set(state?.bookingData?.map((item) => item.id))
      const newData = data?.filter((item) => !existingIds?.has(item.id))
      return { bookingData: [...state.bookingData, ...newData] }
    }),
}))

interface StoreStateMySchedule {
  open: boolean
  loading: boolean
  setOpen: (value: boolean) => void
  setLoading: (value: boolean) => void
  openDrawer: () => void

  // cancel
  cancelBookingId: string
  setCancelBookingId: (id: string) => void
  cancelReason: string
  setCancelReason: (reason: string) => void

  // reschedule
  rescheduleBookingId: string
  setRescheduleBookingId: (id: string) => void
  mentorId: string
  setMentorId: (id: string) => void
  timeReschedule: string
  setTimeReschedule: (time: string) => void
  rescheduleReason: string
  setRescheduleReason: (reason: string) => void
  from: string
  setFrom: (from: string) => void
}

export const useStoreMySchedule = create<StoreStateMySchedule>((set) => ({
  open: false,
  loading: true,
  setOpen: (value) => set({ open: value }),
  setLoading: (value) => set({ loading: value }),
  openDrawer: () => {
    set({ open: true, loading: true })
    setTimeout(() => {
      set({ loading: false })
    }, 600)
  },
  cancelBookingId: "",
  setCancelBookingId: (id: string) => set({ cancelBookingId: id }),
  cancelReason: "",
  setCancelReason: (reason: string) => set({ cancelReason: reason }),

  rescheduleBookingId: "",
  setRescheduleBookingId: (id: string) => set({ rescheduleBookingId: id }),
  mentorId: "",
  setMentorId: (id: string) => set({ mentorId: id }),
  timeReschedule: "",
  setTimeReschedule: (time: string) => set({ timeReschedule: time }),
  rescheduleReason: "",
  setRescheduleReason: (reason: string) => set({ rescheduleReason: reason }),
  from: "",
  setFrom: (from: string) => set({ from: from }),
}))
