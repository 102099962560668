import React, { useState } from "react"
import { Popover, Skeleton } from "antd"
import { useSingleOrbit } from "../hooks/useOrbits"
import BadgeCardOrbitPopover from "./BadgeCardOrbitPopover"
import { isMobile } from "react-device-detect"

interface OrbitPopoverProps {
  id: string
  enabled: boolean
  children: React.ReactNode
}

export default function OrbitPopover({
  id,
  enabled,
  children,
}: OrbitPopoverProps) {
  const singleOrbit = useSingleOrbit(id, { enabled: enabled })
  const [open, setOpen] = useState(false)

  if (singleOrbit?.data?.data?.data?.honors) {
    return (
      <Popover
        trigger={isMobile ? "click" : "hover"}
        placement={isMobile ? "bottom" : undefined}
        // placement={() => placement()}
        open={open}
        onOpenChange={(open) => setOpen(open)}
        color="#E2EBFA"
        content={
          singleOrbit.isLoading ? (
            <div className="text-backdrop mt-4 grid grid-cols-12 gap-3 px-8 py-4">
              {[...Array(4)].map((_, i) => (
                <div className="col-span-3" key={i}>
                  <Skeleton active loading={true} paragraph={{ rows: 4 }} />
                </div>
              ))}
            </div>
          ) : (
            <div className="px-2">
              <div className=" text-backdrop text-base ">
                Collect all honors to get to the next orbit.
              </div>
              <div className="md:w-[820px] h-64 overflow-y-auto md:overflow-y-hidden md:h-fit  bg-white mt-1 flex md:flex-row flex-col items-center ">
                {singleOrbit?.data?.data?.data?.honors?.map((honor) => (
                  <BadgeCardOrbitPopover
                    key={honor?.id}
                    name={honor?.name}
                    required_orbit_badges={honor?.required_orbit_badges}
                    image_url={honor?.image_url}
                    description={honor?.description}
                    is_earned={honor?.is_earned}
                  />
                ))}
              </div>
            </div>
          )
        }
      >
        <div onClick={() => setOpen(!open)}>{children}</div>
      </Popover>
    )
  } else {
    return <>{children}</>
  }
}
