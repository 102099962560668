import { useForm, useFieldArray } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { message } from "antd"
import { useNewPostStore } from "../../store/newPostStore"
import {
  MediaFormData,
  mediaSchema,
} from "../components/new-post-forms/schemas"
import { TPostBody, TPostKind } from "../types/commonn"
import { createCommunityPost } from "../apis/feed-post"
import ErrorMessageBuilder from "../../../../common/components/ErrorMessageBuilder"
import React from "react"
import { useMutation, useQueryClient } from "react-query"

const MAX_MEDIA = 10

export const useMediaForm = () => {
  const { handleSubmit, control, formState } = useForm<MediaFormData>({
    resolver: zodResolver(mediaSchema),
    mode: "onTouched",
    defaultValues: {
      files: [
        {
          image: [],
        },
      ],
    },
  })

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "files" as const,
  })
  const queryClient = useQueryClient()

  const { closeModal } = useNewPostStore()
  const createPost = useMutation(
    ({ post, kind }: { post: TPostBody; kind?: TPostKind }) =>
      createCommunityPost(post, kind),
  )

  const onSubmit = async (data: MediaFormData) => {
    message.loading({
      content: "Creating Media post...",
      key: "create-media-post",
    })

    await createPost.mutateAsync(
      {
        post: { files: data?.files?.map((item) => item.image?.[0]) },
        kind: "media",
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("community-post-list")
          message.success({
            content: "Post created successfully!",
            key: "create-media-post",
          })
          closeModal()
        },
        onError: () => {
          message.open({
            key: "create-media-post",
            duration: 5,
            content: React.createElement(ErrorMessageBuilder, {
              message:
                "We encountered an issue while attempting to create your media post. If the problem continues, please refresh the page.",
              includeReportAt: true,
            }),
            type: "error",
          })
        },
      },
    )
  }

  return {
    handleSubmit,
    control,
    formState,
    fields,
    append,
    remove,
    update,
    MAX_MEDIA,
    onSubmit,
    createPost,
    closeModal,
  }
}
