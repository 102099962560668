/* eslint-disable */

import { useQuery } from "react-query"
import instance from "../../../config/config"
// import { UseQueryOptions } from "react-query";
import { ApiResponse } from "../../../common/types/ApiResponse"
import { Orbit } from "./useAchievements"

export interface Honor {
  description: string
  id: string
  image_url: string
  is_earned: boolean
  name: string
  required_orbit_badges: number
  total_left_badges: number
  total_user_badges: number
}

export interface SingleOrbit extends Orbit {
  honors: Honor[]
}

/**
 * @deprecated
 */
const fetchOrbits = () => {
  return instance.get("/orbits")
}

/**
 * @deprecated
 */
const fetchMyOrbits = () => {
  return instance.get("/orbits/my")
}

const fetchSingleOrbit = (id: string) => {
  const response: Promise<ApiResponse<SingleOrbit>> = instance.get(
    `/orbits/${id}`,
  )
  return response
}

/**
 * @deprecated
 */
export const useOrbits = (params: any = {}) => {
  console.warn(
    "The useOrbits hook is deprecated. Use a combination of useAchievments and useSingleOrbit instead.",
  )
  return useQuery(["fetch-orbits", params], () => fetchOrbits(), {
    refetchOnWindowFocus: false,
  })
}
/**
 * @deprecated
 */
export const useMyOrbits = (params = {}) => {
  console.warn(
    "The useMyOrbits hook is deprecated. Use a combination of useAchievments and useSingleOrbit instead.",
  )
  return useQuery(["fetch-my-orbits", params], () => fetchMyOrbits(), {
    refetchOnWindowFocus: false,
  })
}

export const useSingleOrbit = (
  id: string,
  setOptions: { enabled: boolean },
) => {
  return useQuery(["fetch-single-orbit", id], () => fetchSingleOrbit(id), {
    refetchOnWindowFocus: false,
    ...setOptions,
  })
}
