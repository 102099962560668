/* eslint-disable */

import { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import instance, { instanceV2 } from "../../../../config/config"
import { AxiosResponse } from "axios"
import { message } from "antd"

export type JobHistory = {
  id: string
  company: {
    name: string
    id: string
    image: string
  }
  employment_type: {
    name: string
    id: string
  }
  country: {
    name: string
    id: string
  }
  current_role: boolean
  start_at: string
  end_at: string
  remote: boolean
  job_title: string
  description: string
  isEdit?: boolean
}

export type OtherCompany = {
  name: string
  id: string
  image: string
}

export type Common = {
  id: string
  name: string
}

export const useJobHistory = () => {
  const [tempData, setTempData] = useState<JobHistory[]>([])
  const queryClient = useQueryClient()
  const handleAddDown = () => {
    const temp: JobHistory = {
      id: "",
      company: {
        name: "",
        id: "",
        image: "",
      },
      employment_type: {
        name: "",
        id: "",
      },
      country: {
        name: "",
        id: "",
      },
      current_role: false,
      start_at: "",
      end_at: "",
      remote: false,
      job_title: "",
      description: "",
      isEdit: true,
    }
    setTempData((prev) => [...prev, temp])
  }

  const handleAddUp = () => {
    const temp: JobHistory = {
      id: "",
      company: {
        name: "",
        id: "",
        image: "",
      },
      employment_type: {
        name: "",
        id: "",
      },
      country: {
        name: "",
        id: "",
      },
      current_role: false,
      start_at: "",
      end_at: "",
      remote: false,
      job_title: "",
      description: "",
      isEdit: true,
    }
    setTempData((prev) => [temp, ...prev])
  }

  const updateTempData = (index: number, data: JobHistory) => {
    const temp = [...tempData]
    temp[index] = data
    setTempData(temp)
  }

  const { data: jobHis, isFetching: loadingJobHis } = useQuery(
    "job-history",
    () => instanceV2.get<AxiosResponse<JobHistory[]>>("/accounts/job-history"),
  )

  useEffect(() => {
    if (jobHis?.data?.data) {
      setTempData(jobHis?.data?.data || [])
    }
  }, [jobHis?.data?.data])

  const { mutateAsync: removeJobHis, isLoading: loadingremoveJobHis } =
    useMutation({
      mutationFn: async (id: string) => {
        try {
          const res = await instanceV2.delete(`/accounts/job-history/id/${id}`)
          return res
        } catch (error) {
          throw error
        }
      },
      mutationKey: "account-remove-job-history",
    })

  const onRemove = async (val: string) => {
    message.open({
      key: "remove-jobhis",
      content: "removing...",
      type: "loading",
    })
    try {
      const response = await removeJobHis(val)
      if (response) {
        queryClient.invalidateQueries(["account-cv"]).then(() => {
          setTempData((prev) => prev.filter((item) => item.id !== val))
          message.destroy()
        })
      }
    } catch (error: any) {
      message.open({
        key: "remove-jobhis",
        content: error?.response?.data?.data || "Internal server error",
        type: "error",
      })
    }
  }

  const onRemoveTemp = (val: number) => {
    setTempData((prev) => prev.filter((_, index) => index !== val))
  }

  const { data: countries, isLoading: loadingcountries } = useQuery(
    "countries",
    () => instance.get<AxiosResponse<Common[]>>("/commons/countries"),
  )

  const { data: jobTypes, isLoading: loadingjobTypes } = useQuery(
    "jobTypes",
    () => instance.get<AxiosResponse<Common[]>>("/commons/job-types"),
  )
  return {
    tempData,
    updateTempData,
    handleAddDown,
    handleAddUp,
    loadingJobHis,
    onRemove,
    onRemoveTemp,
    loadingremoveJobHis,
    countries: { data: countries?.data?.data, loading: loadingcountries },
    job_types: { data: jobTypes?.data?.data, loading: loadingjobTypes },
  }
}
