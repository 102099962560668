import { Button, Image, Modal, Select } from "antd"
import TextArea from "antd/es/input/TextArea"
import React from "react"
import { Controller } from "react-hook-form"
import "./custom.css"
import { useProfile } from "../../../missionControl/hooks/cv-builder/useProfile"
import { ReqExpert } from "../../../../assets/images"

interface ModalBecomeAnExpertProps {
  reqExpert?: any
}

const ModalBecomeAnExpert: React.FC<ModalBecomeAnExpertProps> = ({
  reqExpert,
}) => {
  const { account } = useProfile()
  return (
    <div>
      <Modal
        centered
        open={reqExpert?.openModal}
        onCancel={() => {
          reqExpert?.setOpenModal(false)
          reqExpert?.setModalViewChange(false)
        }}
        onOk={() => reqExpert?.setOpenModal(false)}
        footer={null}
      >
        {!reqExpert?.modalViewChange ? (
          <>
            <div className="text-center">
              <h2 className="text-2xl font-bold">Become an Expert</h2>
            </div>

            <div className="mt-9 space-y-4 ">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-6">
                  <Controller
                    name="email"
                    control={reqExpert?.control}
                    render={({ field }) => (
                      <TextArea
                        {...field}
                        id="email"
                        disabled
                        className="font-default rounded-md"
                        placeholder="Email"
                        defaultValue={account?.email}
                        autoSize={{ minRows: 1, maxRows: 3 }}
                      />
                    )}
                  />
                </div>
                <div className="col-span-6">
                  <Controller
                    name="linkedin_link"
                    control={reqExpert?.control}
                    render={({ field }) => (
                      <TextArea
                        {...field}
                        id="linkedin_link"
                        className="font-default rounded-md"
                        placeholder="LinkedIn profile"
                        autoSize={{ minRows: 1, maxRows: 3 }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-6">
                  <Controller
                    name="english_proficiency"
                    control={reqExpert?.control}
                    render={(field) => (
                      <Select
                        {...field}
                        id="english_proficiency"
                        // onFocus={() => reqExpert.fetchGS()}
                        className="w-full !rounded-md"
                        // value={field.field.value}
                        placeholder="English Proficiency"
                        // defaultValue={"sd"}
                        onChange={(data) =>
                          reqExpert?.handleSelectEnglishProf(data)
                        }
                        options={reqExpert?.proficiencies?.data?.map(
                          (val: any, index: number) => ({
                            label: val.name,
                            value: index + 1,
                            title: val.level,
                          }),
                        )}
                      />
                    )}
                  />
                </div>

                <div className="col-span-6">
                  <Controller
                    name="article_link"
                    control={reqExpert?.control}
                    render={({ field }) => (
                      <TextArea
                        {...field}
                        id="article_link"
                        className="font-default rounded-md"
                        placeholder="Sample article link"
                        autoSize={{ minRows: 1, maxRows: 3 }}
                      />
                    )}
                  />
                </div>
              </div>

              <h5>What expert knowledge would you like to share and why</h5>

              <div>
                <Controller
                  name="justification"
                  control={reqExpert?.control}
                  rules={{
                    required: "Justification is required",
                    minLength: {
                      value: 10,
                      message: "Justification must be at least 10 characters",
                    },
                  }}
                  render={({ field }) => (
                    <>
                      <TextArea
                        {...field}
                        id="justification"
                        className="font-default rounded-md"
                        placeholder="your reply"
                        autoSize={{ minRows: 4, maxRows: 5 }}
                      />
                      {reqExpert?.errors.justification && (
                        <p className="m-0 text-red-500">
                          {reqExpert?.errors.justification.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>

              <div className="flex justify-end mt-10">
                <Button
                  className="rounded-md"
                  block
                  onClick={reqExpert?.handleSubmit(
                    reqExpert?.handleRequestExpert,
                  )}
                  type="primary"
                  loading={reqExpert?.loadingReqExpert}
                  disabled={
                    !reqExpert?.watch("english_proficiency") ||
                    !reqExpert?.watch("article_link") ||
                    !reqExpert?.watch("linkedin_link") ||
                    !reqExpert?.watch("justification")
                  }
                >
                  Apply
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="text-center mt-4 -mb-4">
              <Image preview={false} src={ReqExpert} width={180} />
              <p>Thank you for your interest!</p>
              <p>
                Check your email for confirmation or additional information
                request within 10 working days
              </p>
            </div>
          </>
        )}
      </Modal>
    </div>
  )
}

export default ModalBecomeAnExpert
