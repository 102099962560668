import { useNavigate } from "react-router-dom"
import { Breadcrumbs } from "../../../../common/components"
import { Image, Skeleton, Tag } from "antd"
import { DefaultCompanyLogoImg } from "../../../../assets/images"
import { SocialMediaLinks } from "../SocialMediaLink"
import {
  HeartOutlined,
  EnvironmentOutlined,
  UserOutlined,
  HeartFilled,
} from "@ant-design/icons"
import FilterSidebar from "../jobBoards/JobBoardFilter"
import CardOfJobCompany from "./JobCard"
import { EmptyData } from "../../../missionControl/components/cv-view/EmptyData"

interface SingleCompanyTopSectionProps {
  companyResp: any
  breadcrumbsItems: any[]
  filterHandler: any
}
export const DetailCompanyTopSection: React.FC<
  SingleCompanyTopSectionProps
> = ({ companyResp, breadcrumbsItems, filterHandler }) => {
  const navigate = useNavigate()

  return (
    <>
      <div>
        <div className="bg-white  py-4 px-8">
          <Breadcrumbs items={breadcrumbsItems} />

          <span
            onClick={() => navigate("/job/companies")}
            className="!text-secondary cursor-pointer footnote"
          >
            {"<< back"}
          </span>

          <div className="text-backdrop -mt-2">
            <div className="flex items-center gap-3 w-full">
              {companyResp?.isDetailCompanyLoading ? (
                <Skeleton active className="mt-4" />
              ) : (
                <div className="flex justify-between w-full align-top flex-wrap md:flex-nowrap">
                  <div className="w-9/12 align-top">
                    <h1 className="text-backdrop text-2xl font-extrabold my-3 ">
                      {companyResp?.detailCompany?.name}
                    </h1>

                    <div>
                      <p className="p-o m-0 leading-5 line-clamp-5">
                        {companyResp?.detailCompany?.description}
                      </p>
                    </div>
                  </div>

                  <div className="mb-3 mt-3 md:mt-0 flex-grow md:flex-shrink">
                    <div className="bg-white-custom shadow-md rounded-xl p-4 w-full space-y-4">
                      {companyResp?.detailCompany?.logo ? (
                        <Image
                          src={
                            companyResp?.detailCompany?.logo
                              ? companyResp?.detailCompany?.logo
                              : ""
                          }
                          preview={false}
                          alt={`logo-${companyResp?.detailCompany?.name}`}
                          height={70}
                          width={100}
                          className="object-contain"
                        />
                      ) : (
                        <Image
                          src={DefaultCompanyLogoImg}
                          preview={false}
                          alt={`logo-${companyResp?.detailCompany?.name}`}
                          height={70}
                          width={100}
                          className="object-contain"
                        />
                      )}

                      <p className="m-0 p-0 uppercase tracking-widest text-xs text-[#966F02]">
                        {companyResp?.detailCompany?.sector || "unknown"}
                      </p>

                      <div className="">
                        <div className="flex items-center gap-3">
                          <div className="flex items-center gap-2 text-xs">
                            <EnvironmentOutlined />
                            <span>
                              {companyResp?.detailCompany?.headquarter ||
                                "unknown"}
                            </span>
                          </div>
                          <div className="flex items-center gap-2 text-xs">
                            <UserOutlined />
                            <span>
                              {companyResp?.detailCompany?.size?.replace(
                                " employees",
                                "",
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center gap-5 mt-7">
                        {SocialMediaLinks(companyResp?.detailCompany)}
                      </div>

                      <div className="flex items-center gap-2 hover:text-primary cursor-pointer">
                        <Tag
                          bordered={false}
                          onClick={(e) => {
                            if (companyResp?.detailCompany?.is_favorite) {
                              filterHandler?.handleUnSaveCompany(
                                companyResp?.detailCompany?.id,
                              )
                            } else {
                              filterHandler?.handleSaveCompany(
                                companyResp?.detailCompany,
                              )
                            }
                            e.stopPropagation()
                          }}
                          color={
                            companyResp?.detailCompany?.is_favorite
                              ? undefined
                              : "volcano"
                          }
                          className={`${
                            companyResp?.detailCompany?.is_favorite
                              ? "bg-primary-bg"
                              : "text-backdrop"
                          } !border-gray-300 rounded max-w-full overflow-clip group hover:scale-105 duration-200 transition-all cursor-pointer w-full text-center`}
                          icon={
                            companyResp?.detailCompany?.is_favorite ? (
                              <HeartFilled
                                key={"favorite"}
                                className="!text-primary"
                              />
                            ) : (
                              <HeartOutlined
                                className="hover:text-primary"
                                key="favorite"
                              />
                            )
                          }
                        >
                          {companyResp?.detailCompany?.is_favorite
                            ? "Followed"
                            : "Follow"}
                        </Tag>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

interface SingleCompanyContentSectionProps {
  companyResp: any
  filterHandlerJobBoard: any
}
export const DetailCompanyContentSection: React.FC<
  SingleCompanyContentSectionProps
> = ({ companyResp, filterHandlerJobBoard }) => {
  const numIterations = 2

  return (
    <div className="">
      {companyResp?.isDetailCompanyLoading ? (
        <div className="bg-white py-3 px-5 h-auto overflow-hidden rounded-xl">
          {Array.from({ length: numIterations }).map((_, index) => (
            <div
              key={index}
              className="shadow-lg rounded-xl bg-white p-3 col-span-12 sm:col-span-6 lg:col-span-3 hover:shadow-xl mb-3 cursor-pointer transition-all w-full overflow-hidden"
              style={{ borderRadius: "3px" }}
            >
              <Skeleton active paragraph={{ rows: 2 }} />
            </div>
          ))}
        </div>
      ) : (
        <div className="bg-white py-5 px-5 rounded-xl">
          {companyResp?.detailCompany?.jobs?.length > 0 ? (
            companyResp?.detailCompany?.jobs?.map(
              (item: any, index: number) => (
                <CardOfJobCompany
                  key={index}
                  item={item}
                  index={index}
                  handleSaveJob={filterHandlerJobBoard?.handleSaveJob}
                  handleUnSaveJob={filterHandlerJobBoard?.handleUnSaveJob}
                />
              ),
            )
          ) : (
            <EmptyData />
          )}
        </div>
      )}
    </div>
  )
}

interface SingleCompanyRightSectionProps {
  filterHandler: any
  filterResp: any
}
export const DetailCompanyRightSection: React.FC<
  SingleCompanyRightSectionProps
> = ({ filterHandler, filterResp }) => {
  return (
    <FilterSidebar
      filterHandler={filterHandler}
      filterResp={filterResp}
      from="company"
    />
  )
}
