import {
  EnvironmentOutlined,
  EyeOutlined,
  HeartFilled,
  HeartOutlined,
} from "@ant-design/icons"
import { Image, Popover, Tag } from "antd"
import React from "react"
import { useNavigate } from "react-router-dom"
import { Earth, Moon } from "../../../../assets/images"
import AvatarCustom from "../../../../common/components/AvatarCustom"
import { useModalStore } from "../../../../stores"
import { SocialMediaLinks } from "../../../jobGalaxy/components/SocialMediaLink"
import { useProfile } from "../../../missionControl/hooks/useProfile"
import { SocialMedia, UserProfileResponse } from "../../founder/types/response"

interface MemberCardProps {
  memberItem: UserProfileResponse
  queryMember: any
}

const MemberCard: React.FC<MemberCardProps> = ({ memberItem, queryMember }) => {
  const navigate = useNavigate()

  function renderBadge(memberItem: any | undefined, count: number): string[] {
    const visibleBadge: any[] = memberItem?.slice(0, count) || []
    const renderedBadge: any =
      visibleBadge.length > 0 ? (
        visibleBadge.map((data) => (
          <div
            key={data.id}
            className={`flex items-center bg-white gap-x-2 px-2 py-0.5 rounded-md text-xs break-words`}
            style={{ border: "1px solid #36505E" }}
          >
            {data.name}
          </div>
        ))
      ) : (
        <div className=" flex flex-col items-center justify-center gap-1">
          <Image src={Moon} width={35} />
          <small>No data</small>
        </div>
      )
    return renderedBadge
  }

  function renderPopoverContent(
    data: any[] | undefined,
    from?: string,
  ): any | undefined {
    if (!data || data.length <= 2) {
      return undefined
    }
    return (
      <div
        className={`flex ${
          from === "career" ? "flex-col w-auto" : "w-44 flex-wrap "
        } gap-1`}
      >
        {data.slice(2).map((data) => (
          <Tag
            key={data.id}
            className={`bg-[#FFF] cursor-pointer flex items-center max-w-[200px] truncate gap-x-2 px-2 py-0.5 rounded-md`}
          >
            <div className="truncate">{data.name}</div>
          </Tag>
        ))}
      </div>
    )
  }

  const { account } = useProfile()

  const openModal = useModalStore((state) => state.openModal)

  const reFormatSosmed = (sosmed: SocialMedia) => {
    const data = {
      linkedin_url: sosmed?.linkedin,
      twitter_url: sosmed?.twitter,
    }

    return data
  }

  function removeHtmlTags(htmlString: string) {
    return htmlString.replace(/<[^>]*>/g, "")
  }

  const redirect = memberItem?.is_founder
    ? `/community/founder/profile/${memberItem?.id}/${memberItem?.username}/2`
    : memberItem?.is_expert
      ? `/community/public-expert/${memberItem?.id}/${memberItem?.username}/3`
      : `talent/${memberItem?.id}/${memberItem?.username}`

  const getAllSolutions = () => {
    if (!memberItem || !memberItem?.is_founder) return []

    return memberItem?.founder_data?.products?.flatMap(
      (product) => product.solutions,
    )
  }

  const solutions = getAllSolutions()

  return (
    <div
      className="py-3 px-4 bg-white-custom rounded-xl shadow-custom-sm space-y-3 hover:scale-[1.02] transition-all cursor-pointer"
      onClick={() => navigate(redirect)}
      ref={queryMember?.lastElementRef}
    >
      <div
        className={`flex flex-col items-center justify-center 
            gap-y-3
        `}
      >
        <div className=" h-32 w-32">
          <AvatarCustom
            url={memberItem?.avatar}
            display_name={memberItem?.first_name + " " + memberItem?.last_name}
            label={
              memberItem?.is_founder
                ? "Founder"
                : memberItem?.is_expert
                  ? "Expert"
                  : ""
            }
          />
        </div>

        <div className="flex flex-col items-center justify-center gap-2">
          <h3>
            {memberItem?.is_expert || memberItem?.is_founder
              ? `${memberItem?.first_name} ${memberItem?.last_name}`
              : `@${memberItem?.username}`}
          </h3>
          <div className="flex items-center justify-center gap-2 mb-2 -mt-2">
            <EnvironmentOutlined /> {memberItem?.location || "Unknown"}
          </div>
          <p className="m-0 leading-5 line-clamp-2 text-[14px] h-10">
            {removeHtmlTags(
              memberItem?.about?.expert ||
                memberItem?.about?.founder ||
                memberItem?.about?.mentor ||
                "This user hasn't added a description yet. Come back soon to see what they have to share!",
            )}
          </p>
        </div>

        <div className="w-full px-5 py-3 bg-primary-bg rounded-xl h-36">
          <div className="w-full">
            {memberItem?.is_founder ? (
              <div className="flex items-center justify-between">
                <Tag
                  className={`bg-[#FFF2E8] cursor-pointer flex items-center gap-x-2 `}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <Earth />
                  <p className="m-0 text-[10px] md:text-xs">
                    My Climate Solution
                  </p>
                </Tag>

                <p className="m-0 text-[#966F02] text-xs uppercase tracking-widest ">
                  {memberItem?.founder_data?.stage?.name || "Unknown"}
                </p>
              </div>
            ) : memberItem?.is_expert ? (
              <div className="flex items-center justify-between">
                <Tag
                  className={`${
                    memberItem?.is_expert_follow ? "bg-white" : "bg-[#FFF2E8] "
                  } cursor-pointer flex items-center gap-x-2`}
                  onClick={(e) => {
                    e.stopPropagation()
                    if (account) {
                      if (memberItem?.is_expert_follow) {
                        queryMember?.handleUnFollowExpert(memberItem?.id!)
                      } else [queryMember?.handleFollowExpert(memberItem?.id!)]
                    } else {
                      openModal()
                    }
                  }}
                >
                  {memberItem?.is_expert_follow ? (
                    <HeartFilled color="red" />
                  ) : (
                    <HeartOutlined />
                  )}

                  {memberItem?.is_expert_follow
                    ? "Following Expert"
                    : "Follow Expert"}
                </Tag>
                <div className="flex items-center gap-3">
                  {SocialMediaLinks(reFormatSosmed(memberItem?.social_media))}
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-between">
                <Tag
                  className={`bg-[#FFF2E8] cursor-pointer flex items-center gap-x-2`}
                  onClick={(e) => {
                    e.stopPropagation()
                    navigate(`/talent-marketplace/cv-full/${memberItem?.id}`)
                  }}
                >
                  <EyeOutlined />
                  My Green CV
                </Tag>
              </div>
            )}
          </div>

          {/* {expert?.green_skills!?.length > 0 && ( */}
          <div className="text-center">
            <h4>
              {memberItem?.is_founder
                ? memberItem?.founder_data?.name || "Unknown"
                : memberItem?.is_expert
                  ? "Expertise in"
                  : "Career Aspirations"}
            </h4>
          </div>
          {/* )} */}

          <div className="">
            {memberItem?.is_founder ? (
              <div>
                <p className="m-0 line-clamp-2 text-[12px] h-10 text-center">
                  {memberItem?.founder_data?.short_desc || "No descriptions"}
                </p>

                {solutions!?.length > 0 && (
                  <div className="flex flex-wrap items-center justify-center mt-2 gap-x-1 gap-y-1">
                    {renderBadge(solutions, 2)}

                    {solutions!?.length > 2 && renderPopoverContent && (
                      <Popover
                        placement="right"
                        title={""}
                        content={renderPopoverContent(solutions)}
                      >
                        <Tag
                          className={`bg-[#FFF] cursor-pointer flex items-center gap-x-2 px-2 py-0.5 rounded-md`}
                        >
                          +{solutions!?.length - 2}
                        </Tag>
                      </Popover>
                    )}
                  </div>
                )}
              </div>
            ) : memberItem?.is_expert ? (
              <div className="flex flex-wrap items-center justify-center mt-2 gap-x-1 gap-y-1">
                {renderBadge(memberItem?.expert_data, 2)}

                {memberItem?.expert_data?.length > 2 &&
                  renderPopoverContent && (
                    <Popover
                      placement="right"
                      title={""}
                      content={renderPopoverContent(memberItem?.expert_data)}
                    >
                      <Tag
                        className={`bg-[#FFF] cursor-pointer flex items-center gap-x-2 px-2 py-0.5 rounded-md text-xs`}
                      >
                        +{memberItem?.expert_data!?.length - 2}
                      </Tag>
                    </Popover>
                  )}
              </div>
            ) : (
              <div>
                <div className="flex flex-wrap items-center justify-center mt-2 gap-x-1 gap-y-1">
                  {renderBadge(memberItem?.career_aspirations!, 2)}

                  {memberItem?.career_aspirations!?.length > 2 &&
                    renderPopoverContent && (
                      <Popover
                        placement="right"
                        title={""}
                        content={renderPopoverContent(
                          memberItem?.career_aspirations!,
                          "career",
                        )}
                      >
                        <Tag
                          className={`bg-[#FFF] cursor-pointer flex items-center gap-x-2 px-2 py-0.5 rounded-md`}
                        >
                          +{memberItem?.career_aspirations!!?.length - 2}
                        </Tag>
                      </Popover>
                    )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MemberCard
