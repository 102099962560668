import React from "react"
import { Button, Input, Spin, Switch } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { useAccountSetting } from "../hooks/useAccountSetting"
import {
  BasicContainerWithTitle,
  BasicTwoBoxContainer,
  BasicContainer,
} from "../../../common/components"
import { useSettingEmail } from "../hooks/useSettingEmail"

export const AccountSetting: React.FC = () => {
  const { isActive, setIsConfirm, loadingPurge, purgeAccount } =
    useAccountSetting()

  const { data, isLoading, changeStatus } = useSettingEmail()

  return (
    <>
      <Spin
        spinning={isLoading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
      >
        <div className="p-5 bg-white-custom shadow-custom rounded-lg">
          <h2 className="first-letter:uppercase">Email Preferences</h2>
          <div className=" grid sm:grid-cols-2  gap-x-5 gap-y-3 my-5">
            <div className="p-5 bg-white-custom shadow-custom rounded-lg">
              <h3 className=" font-semibold">Learning and Benefits</h3>
              <div>
                <div className=" flex gap-3 items-center my-2.5">
                  <Switch
                    checked={data?.green_skills_courses}
                    size="small"
                    onChange={() =>
                      changeStatus(
                        "green_skills_courses",
                        !data?.green_skills_courses,
                      )
                    }
                  />
                  <div className="">Green Skills courses</div>
                </div>
                <div className=" flex gap-3 items-center my-2.5">
                  <Switch
                    checked={data?.new_workshops_and_programs}
                    size="small"
                    onChange={() =>
                      changeStatus(
                        "new_workshops_and_programs",
                        !data?.new_workshops_and_programs,
                      )
                    }
                  />
                  <div className="">New workshops and programs</div>
                </div>
                <div className=" flex gap-3 items-center my-2.5">
                  <Switch
                    checked={data?.new_perks}
                    size="small"
                    onChange={() => {
                      changeStatus("new_perks", !data?.new_perks)
                    }}
                  />
                  <div className="">New perks</div>
                </div>
              </div>
            </div>
            <div className="p-5 bg-white-custom shadow-custom rounded-lg">
              <h3 className=" font-semibold">Community Updates</h3>
              <div>
                <div className=" flex gap-3 items-center my-2.5">
                  <Switch
                    checked={data?.new_expert_articles}
                    size="small"
                    onChange={() => {
                      changeStatus(
                        "new_expert_articles",
                        !data?.new_expert_articles,
                      )
                    }}
                  />
                  <div className="">New expert article</div>
                </div>
                <div className=" flex gap-3 items-center my-2.5">
                  <Switch
                    checked={data?.replies_to_my_post}
                    size="small"
                    onChange={() => {
                      changeStatus(
                        "replies_to_my_post",
                        !data?.replies_to_my_post,
                      )
                    }}
                  />
                  <div className="">Replies to my post</div>
                </div>
              </div>
            </div>
            <div className="p-5 bg-white-custom shadow-custom rounded-lg">
              <h3 className=" font-semibold">Platform News</h3>
              <div>
                <div className=" flex gap-3 items-center my-2.5">
                  <Switch
                    checked={data?.new_features_and_functionalities}
                    disabled
                    size="small"
                    onChange={() => {
                      changeStatus(
                        "new_features_and_functionalities",
                        !data?.new_features_and_functionalities,
                      )
                    }}
                  />
                  <div className="">New features and functionalities</div>
                </div>
                <div className=" flex gap-3 items-center my-2.5">
                  <Switch
                    checked={data?.skilledin_green_news}
                    size="small"
                    onChange={() => {
                      changeStatus(
                        "skilledin_green_news",
                        !data?.skilledin_green_news,
                      )
                    }}
                  />
                  <div className="">Skilledin Green news</div>
                </div>
                <div className=" flex gap-3 items-center my-2.5">
                  <Switch
                    checked={data?.marketing_communications}
                    size="small"
                    onChange={() => {
                      changeStatus(
                        "marketing_communications",
                        !data?.marketing_communications,
                      )
                    }}
                  />
                  <div className="">Marketing communication</div>
                </div>
              </div>
            </div>
            <div className="p-5 bg-white-custom shadow-custom rounded-lg">
              <h3 className=" font-semibold">Jobs Updates</h3>
              <div>
                <div className=" flex gap-3 items-center my-2.5">
                  <Switch
                    checked={data?.recruiter_invited_to_connect}
                    size="small"
                    onChange={() => {
                      changeStatus(
                        "recruiter_invited_to_connect",
                        !data?.recruiter_invited_to_connect,
                      )
                    }}
                  />
                  <div className="">Recruiter invited to connect</div>
                </div>
                <div className=" flex gap-3 items-center my-2.5">
                  <Switch
                    checked={data?.recruiter_invited_to_apply_for_a_job}
                    size="small"
                    onChange={() => {
                      changeStatus(
                        "recruiter_invited_to_apply_for_a_job",
                        !data?.recruiter_invited_to_apply_for_a_job,
                      )
                    }}
                  />
                  <div className="">Recruiter invited to apply for a job</div>
                </div>
                <div className=" flex gap-3 items-center my-2.5">
                  <Switch
                    checked={data?.new_suggested_jobs}
                    size="small"
                    onChange={() => {
                      changeStatus(
                        "new_suggested_jobs",
                        !data?.new_suggested_jobs,
                      )
                    }}
                  />
                  <div className="">New suggested jobs</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
      <BasicContainer type="small" className="flex-grow">
        <BasicTwoBoxContainer
          className=""
          leftBox={
            <BasicContainerWithTitle
              className="h-full"
              title="Danger Zone"
              titleSize="h2"
            >
              <div className="my-4">
                Once you delete your account, please note that we won't be able
                to recover your information. This means any progress you've made
                on the platform will be permanently lost.
              </div>
            </BasicContainerWithTitle>
          }
          rightBox={
            <BasicContainer className="pt-12">
              <div>
                To confirm this action, please type{" "}
                <span className="font-bold text-danger">'DELETE ACCOUNT'</span>{" "}
                below.
              </div>
              <div className="grid grid-cols-3 gap-5 mt-5">
                <Input
                  onChange={(e) => setIsConfirm(e.target.value)}
                  placeholder="DELETE ACCOUNT"
                  className="text-center lg:col-span-1 col-span-full w-full"
                />
                <Button
                  disabled={!isActive}
                  loading={loadingPurge}
                  onClick={() => purgeAccount()}
                  className="lg:col-span-1 w-full border-2 col-span-full border-danger bg-[#FFD6E7] text-danger"
                >
                  Delete Account
                </Button>
              </div>
            </BasicContainer>
          }
        />
      </BasicContainer>
    </>
  )
}
