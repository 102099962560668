import React from "react"
import { BasicContainerWithTitle } from "../../../../common/components"
import { Link } from "react-router-dom"
import { Button } from "antd"
import { Moon } from "../../../../assets/images"
import { Tag } from "antd"
import { DownOutlined, UpOutlined } from "@ant-design/icons"
import FiveCircleRateTS from "../../../../common/components/FiveCircleRateTS"
import { useProfile } from "../../../missionControl/hooks/cv-builder/useProfile"

export const MySkills = () => {
  const [active, setActive] = React.useState<boolean>(false)
  const accountQuery = useProfile()
  const account = accountQuery.account

  return (
    <BasicContainerWithTitle
      type="small"
      title="My Skills"
      titleSize="h3"
      extraTitle={
        <Link to="/dashboard/galactic-achievements/solar-trophies">
          <Button>Galactic Achievements</Button>
        </Link>
      }
    >
      {account?.green_skill_proficient_in?.find(
        (item: any) => item.total_completed_level > 0,
      ) ? (
        account?.green_skill_proficient_in
          ?.filter((item: any) => item.total_completed_level > 0)
          .map((a: any, i: any) => (
            <div
              key={i}
              className="flex md:gap-5 gap-2 md:items-center  md:flex-row flex-col rounded px-3 py-1 my-2 bg-[#F0F2F5] md:h-14"
              style={{ border: "1px solid #D0DDE5" }}
            >
              <FiveCircleRateTS
                count={5}
                defaultValue={a.total_completed_level}
                circleSize={16}
                disabled
              />
              <div className="md:text-left">{a?.name}</div>
            </div>
          ))
      ) : (
        <div className="flex h-full w-full flex-col gap-4 items-center justify-center py-4">
          <img src={Moon} alt="cookies" />
          <div className="">
            Explore{" "}
            <Link
              className="text-primary  transition-all duration-300"
              to={"/skill/green-skills"}
            >
              Green Skills
            </Link>
          </div>
        </div>
      )}
      {account?.green_skill_interest_in && (
        <div className="mt-5">
          <div className="flex justify-between items-center">
            <div className="mb-2">Interested in learning</div>
            {active ? (
              <div
                className="flex gap-2 items-center cursor-pointer text-secondary"
                onClick={() => setActive(!active)}
              >
                <UpOutlined className="w-3 h-3" />
                <small className="">hide details</small>
              </div>
            ) : (
              <div
                className="flex gap-2 items-center cursor-pointer text-secondary"
                onClick={() => setActive(!active)}
              >
                <DownOutlined className="w-3 h-3" />
                <small className="">show details</small>
              </div>
            )}
          </div>
          <div className="flex flex-wrap ">
            {" "}
            {active &&
              account?.green_skill_interest_in?.map((item: any, index: any) => (
                <Tag
                  key={index}
                  color="gold"
                  className="mb-2 overflow-hidden max-w-[235px] sm:w-auto"
                >
                  <div className="truncate">{item.name}</div>
                </Tag>
              ))}
          </div>
        </div>
      )}
    </BasicContainerWithTitle>
  )
}
