import { Link } from "react-router-dom"
import {
  CloudDownloadOutlined,
  SettingOutlined,
  LockOutlined,
  CreditCardOutlined,
  UsergroupAddOutlined,
  DashboardOutlined,
  BulbOutlined,
  BankOutlined,
  StarOutlined,
} from "@ant-design/icons"
import {
  CVIcon,
  HeaderExpertArticles,
  HeaderExperts,
  HeaderFounders,
  HeaderGreenSkills,
  HeaderJobBoards,
  HeaderJobRoles,
  HeaderNews,
  HeaderTalent,
  HeaderWorkshops,
  HeaderConsultants,
} from "../../../assets/images"
import type { MenuProps } from "antd"
import React from "react"
import { matchPath } from "react-router-dom"
// import { msalInstance } from "../../.."

type MenuItem = Required<MenuProps>["items"][number]
export interface SidebarAuthenticatedMenuItem {
  label?: string
  key?: string
  icon?: React.ComponentType<any>
  children?: SidebarAuthenticatedMenuItem[]
  is_link?: boolean
  type?: string
}

export const skillQueryParams = new URLSearchParams({
  search: "",
  price: "low-to-high",
  availability: "start-today",
  sort_rank: "",
  viewType: "cards",
  page: "1",
  limit: "20",
})

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: string,
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem
}

export const returnSidebarAuthenticatedMenuItems = (
  isExpert: boolean,
  currentUrl: string,
  returnExtra: boolean = true,
): { menuItems: MenuItem[]; activeLink: string | undefined } => {
  // check if user is an expert, return menu items accordingly
  const menuArray = !isExpert
    ? authMenuItems.filter((item) => item.key !== "expert")
    : [...authMenuItems]

  const activeLink = getActiveLink(menuArray, currentUrl)

  const returnArr = menuArray.map((menuItem) => {
    if (menuItem?.type === "divider") {
      return menuItem
    }
    if (menuItem?.key && menuItem?.icon) {
      return getItem(
        menuItem.label,
        menuItem.key,
        React.createElement(menuItem.icon, {
          className: `text-backdrop`,
        }),
        menuItem?.children?.map((itemChild) =>
          getItem(
            <Link className={`text-md text-backdrop`} to={itemChild.link}>
              {itemChild.label}
            </Link>,
            itemChild.key,
          ),
        ),
      )
    }
    if (returnExtra) {
      return getItem(
        <Link className={`text-md  text-backdrop`} to={menuItem.key ?? ""}>
          {menuItem.label}
        </Link>,
        menuItem.key ?? "",
      )
    }
  })
  // adding extra item for logout
  // returnArr.push({
  //   key: "logout",
  //   label: "Logout",
  //   onClick: () => msalInstance.logoutRedirect(),
  // })
  // @ts-ignore
  return { menuItems: returnArr, activeLink }
}

export function getActiveLink(
  authMenuItems: SidebarAuthenticatedMenuItem[],
  currentUrl: string,
) {
  let activeLink
  for (let item of authMenuItems) {
    if (item?.children) {
      const temp = item?.children?.find((childItem) =>
        matchPath({ path: childItem?.key ?? "" }, currentUrl),
      )
      if (temp) {
        activeLink = temp?.key
        break
      }
    } else {
      if (matchPath({ path: item?.key ?? "" }, currentUrl)) {
        activeLink = item?.key
        break
      }
    }
  }
  return activeLink
}

export const returnSidebarUnauthMenuItems = (
  currentUrl: string,
): { menuItems: MenuItem[]; activeLink: string | undefined } => {
  // const activeLink = getActiveLink(menuArray, currentUrl)
  const returnArr = unauthMenuItems.map((menuItem) => {
    return getItem(
      menuItem.label,
      menuItem.key,
      React.createElement(menuItem.icon, {
        className: `text-backdrop`,
      }),
      menuItem?.children?.map((itemChild) =>
        getItem(
          <Link className={`text-md text-backdrop`} to={itemChild.link}>
            {itemChild.label}
          </Link>,
          itemChild.key,
        ),
      ),
    )
  })
  const activeLink = getActiveLink(unauthMenuItems, currentUrl)

  return {
    menuItems: returnArr,
    activeLink: activeLink,
  }
}

export const unauthMenuItems = [
  {
    label: "Knowledge",
    key: "knowledge",
    icon: BulbOutlined,
    children: [
      {
        label: "Green Skills",
        description: "Learn job-relevant skills from industry experts",
        icon: HeaderGreenSkills,
        tags: [],
        link: "/skill/green-skills",
        key: "/skill/green-skills/*",
      },
      {
        label: "Workshops",
        description: "Attend short workshops to expand your knowledge",
        icon: HeaderWorkshops,
        tags: ["New!"],
        link: "/skill/workshops",
        key: "/skill/workshops/*",
      },
      {
        label: "Expert Articles",
        description:
          "Thought Leadership pieces from Sustainability Professionals",
        icon: HeaderExpertArticles,
        tags: [],
        link: "/skill/articles",
        key: "/skill/articles/*",
      },
      {
        label: "Climate News Digest",
        description:
          "Delivered to you by ClimatePulse, aggregated and summarized news",
        icon: HeaderNews,
        tags: ["Coming Soon"],
        link: "/climate-news",
        key: "/climate-news",
        coming_soon: true,
      },
    ],
  },
  {
    label: "Jobs",
    icon: DashboardOutlined,
    key: "jobs",
    children: [
      {
        label: "Job Roles",
        description:
          "Discover what skills you need based on your career aspirations",
        icon: HeaderJobRoles,
        tags: [],
        link: "/job/roles",
        key: "/job/roles/*",
      },
      {
        label: "Job Board",
        description: "Apply for jobs that fit best your current skills",
        icon: HeaderJobBoards,
        tags: [],
        link: "/job/board",
        key: "/job/board/*",
      },
    ],
  },
  {
    label: "Community",
    key: "community",
    icon: UsergroupAddOutlined,
    children: [
      {
        label: "Experts",
        description: "Discover who writes Thought Leadership articles",
        icon: HeaderExperts,
        tags: ["New!"],
        link: "/community/public-expert",
        key: "/community/public-expert",
        coming_soon: false,
      },
      {
        label: "Talent",
        description: "Aspiring and experienced sustainability professionals",
        icon: HeaderTalent,
        tags: [],
        link: "/community/member",
        key: "/community/member",
        coming_soon: false,
      },
      {
        label: "Consultants",
        description:
          "Need extra help with strategy? Connect with relevant consultants",
        icon: HeaderConsultants,
        tags: ["Coming soon"],
        link: "/consultants",
        key: "/consultants",
        coming_soon: true,
      },
      {
        label: "Founders",
        description: "Changemakers working on Climate Solutions",
        icon: HeaderFounders,
        tags: ["New!"],
        link: "/community/founder",
        key: "/community/founder",
        coming_soon: false,
      },
    ],
  },
  {
    label: "About",
    key: "about",
    link: "/about",
    icon: BulbOutlined,
  },
]

const authMenuItems = [
  {
    label: "Mission Control",
    key: "mission_control",
    icon: DashboardOutlined,
    children: [
      {
        label: "Launchpad",
        link: "/dashboard/launchpad",
        key: "/dashboard/launchpad",
        is_link: true,
      },
      {
        label: "Skill Trajectory Plan",
        link: "/dashboard/skill-trajectory-plan/skill-roadmap",
        key: "/dashboard/skill-trajectory-plan/*",
        is_link: true,
      },
      {
        label: "Galactic Achievements",
        link: "/dashboard/galactic-achievements/solar-trophies",
        key: "/dashboard/galactic-achievements/*",
        is_link: true,
      },
    ],
  },
  {
    label: "Skill Galaxy",
    key: "skill_galaxy",
    icon: BulbOutlined,
    children: [
      {
        label: "Green Skills",
        link: "/skill/green-skills",
        key: "/skill/green-skills/*",
        is_link: true,
      },
      {
        label: "Workshops",
        link: "/skill/workshops",
        key: "/skill/workshops/*",
        is_link: true,
      },
      {
        label: "Schools",
        link: "/skill/schools",
        key: "/skill/schools/*",
        is_link: true,
      },
      {
        label: "Expert Articles",
        link: "/skill/articles",
        key: "/skill/articles/*",
        is_link: true,
      },
    ],
  },
  {
    label: "Job Galaxy",
    key: "job_galaxy",
    icon: BankOutlined,
    children: [
      {
        label: "My Jobs",
        link: "/job/my",
        key: "/job/my/*",
        is_link: true,
      },
      {
        label: "Job Roles",
        link: "/job/roles",
        key: "/job/roles/*",
        is_link: true,
      },
      {
        label: "Job Board",
        link: "/job/board",
        key: "/job/board/*",
        is_link: true,
      },
      {
        label: "Companies",
        link: "/job/companies",
        key: "/job/companies/*",
        is_link: true,
      },
    ],
  },
  {
    label: "Expert",
    key: "expert",
    icon: StarOutlined,
    children: [
      {
        label: "Expert Profile",
        link: "/expert/profile",
        key: "/expert/*",
        is_link: true,
      },
      {
        label: "My Articles",
        link: "/expert/my/article",
        key: "/expert/my/article/*",
        is_link: true,
      },
    ],
  },
  {
    label: "Community",
    key: "community",
    icon: UsergroupAddOutlined,
    children: [
      {
        label: "Feed",
        link: "/community/feed",
        key: "/community/feed/*",
        is_link: true,
      },
      {
        label: "My Community Profile",
        link: "/community/my",
        key: "/community/my/*",
        is_link: true,
      },
      {
        label: "All Members",
        link: "/community/member",
        key: "/community/member/*",
        is_link: true,
      },
      {
        label: "Mentors",
        link: "/community/mentor",
        key: "/community/mentor/*",
        is_link: true,
      },
      {
        label: "Experts",
        link: "/community/public-expert",
        key: "/community/public-expert/*",
        is_link: true,
      },
      {
        label: "Climate Solutions",
        link: "/community/solution",
        key: "/community/solution",
        is_link: true,
      },
      {
        label: "Founders",
        link: "/community/founder",
        key: "/community/founder/*",
        is_link: true,
      },
    ],
  },
  // {
  //   label: "Mentor",
  //   key: "mentor",
  //   icon: ApiOutlined,
  //   children: [
  //     {
  //       label: "Mentor Profile",
  //       link: "/mentor/profile",
  //       key: "/mentor/*",
  //       is_link: true,
  //     },
  //     {
  //       label: "Calender",
  //       link: "/mentor/calender",
  //       key: "/mentor/*",
  //       is_link: true,
  //     },
  //   ],
  // },
  { type: "divider" },
  { label: "Community Feed", link: "/community", key: "/community" },
  { type: "divider" },
  {
    label: "Account Setting",
    link: "/account?q=account",
    key: "/account",
  },
  { type: "divider" },
]

export const userActions: MenuProps["items"] = [
  {
    key: "1",
    label: <Link to={"/dashboard/launchpad/cv-builder"}>Green CV Builder</Link>,
    icon: <img src={CVIcon} alt="CV icon" className="h-4 w-4 !mr-4" />,
  },
  {
    key: "2",
    label: <Link to={"/account/privacy"}>Privacy Setting</Link>,
    icon: <LockOutlined className="!text-base !mr-4" />,
  },
  {
    key: "3",
    label: <Link to={"/account?q=billing"}>Billing</Link>,
    icon: <CreditCardOutlined className="!text-base !mr-4" />,
  },
  {
    key: "4",
    label: <Link to={"/account?q=export"}>Data Export</Link>,
    icon: <CloudDownloadOutlined className="!text-base !mr-4" />,
  },
  {
    key: "5",
    label: <Link to={"/account?q=account"}>Account Setting</Link>,
    icon: <SettingOutlined className="!text-base !mr-4" />,
  },
  {
    type: "divider",
  },
]
