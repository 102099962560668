import React from "react"
import { Controller } from "react-hook-form"
import { Form, Input, Button, Select } from "antd"
import NewPostIcons from "../NewPostIcons"
import { SendOutlined } from "@ant-design/icons"
import FormImageUploader from "../../../../../common/components/FormImageUploader"
import { useOccasionForm } from "../../hooks/useOccasionForm"

const Occasion = () => {
  const {
    handleSubmit,
    control,
    errors,
    isDirty,
    createPost,
    closeModal,
    onSubmit,
    namesAndDescriptions,
  } = useOccasionForm()

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
      className="relative my-6"
    >
      <Form.Item>
        <Controller
          name={"image"}
          control={control}
          defaultValue={undefined}
          render={({ field: { onChange, value } }) => (
            <FormImageUploader
              value={value}
              onChange={onChange}
              className="w-full shadow-lg aspect-video "
              description="Upload the best image for this occasion (If no image is chosen we'll use a default one)"
              height={250}
            />
          )}
        />
      </Form.Item>
      <Form.Item
        label="Occasion"
        validateStatus={errors.occasion_text ? "error" : ""}
        help={errors.occasion_text?.message}
      >
        <Controller
          name="occasion_text"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              placeholder="Select the Occasion"
              labelRender={(props) => props.label}
              options={namesAndDescriptions}
              fieldNames={{ value: "name", label: "name" }}
            ></Select>
          )}
        />
      </Form.Item>

      <Form.Item
        validateStatus={errors.occasion_description ? "error" : ""}
        help={errors.occasion_description?.message}
        label="Description"
      >
        <Controller
          name="occasion_description"
          control={control}
          render={({ field }) => (
            <Input.TextArea
              {...field}
              styles={{
                textarea: {
                  resize: "none",
                },
              }}
              rows={6}
              placeholder="Describe the event."
            />
          )}
        />
      </Form.Item>

      <div className="flex items-center justify-between w-full">
        <NewPostIcons />
        <div className="flex items-center gap-4">
          <Button onClick={closeModal}>Cancel</Button>
          <Button
            className="text-white bg-primary"
            loading={createPost.isLoading}
            icon={<SendOutlined />}
            disabled={createPost.isLoading || !isDirty}
            onClick={handleSubmit(onSubmit)}
          >
            Post
          </Button>
        </div>
      </div>
    </Form>
  )
}

export default Occasion
