import React, { useMemo } from "react"
import { Radar } from "@ant-design/plots"

interface Props {
  data: any
}
export const SoftSkill: React.FC<Props> = ({ data }) => {
  return (
    <div className=" bg-[#fafafa] rounded-xl shadow-md p-5 w-[400px] md:w-full">
      <h3 className="mb-3">Soft Skills</h3>
      <div className="">
        <RadarSoftSkill data={data} />
      </div>
    </div>
  )
}

const RadarSoftSkill: React.FC<Props> = ({ data }) => {
  const config = useMemo(() => {
    return {
      data: data || [],
      xField: "name",
      yField: "proficiency",
      seriesField: "owner",
      xAxis: {
        line: null,
        tickLine: null,
        grid: {
          line: {
            style: {
              lineDash: null,
            },
          },
        },
      },
      yAxis: {
        label: false,
        grid: {
          alternateColor: "rgba(0, 0, 0, 0.04)",
        },
      },
      meta: {
        proficiency: {
          min: 0,
        },
      },
      area: {},
      point: {
        size: 1,
      },
      radius: 0.8,
    }
  }, [data])

  // @ts-ignore
  return <Radar {...config} />
}
