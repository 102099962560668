import { msalInstance } from "../../../.."
import { instanceV2 } from "../../../../config/config"
import { useQuery } from "react-query"
import { AxiosResponse } from "axios"
import { JobHistory } from "./useJobHistory"
import { Education } from "./useEducation"
import { Language } from "./useLanguage"
import { Certification } from "./useCertification"
import { message } from "antd"

export type Account = {
  id: string
  avatar: string
  profile_picture: string
  first_name: string
  last_name: string
  profile_headline: string
  nick_name: string
  country: string
  address: string
  location_detailed: string
  about_me: string
  about_me_expert: string
  profile_strenght: number
  email: string
  linkedin: string
  twitter: string
  link: string
  career_aspiration: { id: string; name: string }[] | null
  job_histories: JobHistory[] | null
  educations: Education[] | null
  languages: Language[] | null
  certification: Certification[] | null
  soft_skill: { id: string; name: string; proficiency: number }[]
  green_skill_interest_in:
    | {
        id: string
        name: string
        image: string
      }[]
    | null
  green_skill_proficient_in:
    | {
        id: string
        name: string
        image: string
        total_level: number
        total_available_level: number
        total_purchased_level: number
        total_completed_level: number
        certificate_url: string
        signature_url: string
        is_signature_exist: boolean
        is_request_verified: boolean
      }[]
    | null
  hard_skill: { name: string; value: number }[]
}

export const useProfile = () => {
  const user = msalInstance.getAllAccounts()[0]

  const { data: account, isLoading: loadingAccount } = useQuery(
    "account-cv",
    () => instanceV2.get<AxiosResponse<Account>>("/accounts/detail"),

    {
      onError: () => {
        message.open({
          key: "account-cv",
          duration: 5,
          content:
            "We encountered an issue retrieving your account details. To resolve this, please try refreshing the page. If the issue persists, logging out and then logging back in may help. Our apologies for the inconvenience.",
          type: "error",
        })
      },
      retry: 2,
      enabled: !!user,
    },
  )
  return { account: account?.data.data, loadingAccount }
}
