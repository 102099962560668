import React from "react"
import { Card } from "antd"
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"
import { Language } from "../../../hooks/cv-builder/useLanguage"
import SquareRate from "../../../../../common/components/SquareRate"

interface Props {
  data: Language
  index: number
  updateTempData: (index: number, data: Language) => void
  onRemove: (val: string) => Promise<void>
  loadingremoveLanguage: boolean
  onRemoveTemp: (val: number) => void
}

export const LanguageView: React.FC<Props> = ({
  data,
  index,
  updateTempData,
  onRemove,
  loadingremoveLanguage,
  onRemoveTemp,
}) => {
  return (
    <Card className="rounded-xl shadow-md mb-5 relative">
      <div className="">
        <h3 className="">{data.language.name || "-"}</h3>
        {data.proficiency.level ? (
          <div className=" flex flex-wrap gap-3 items-center mt-2">
            <SquareRate value={data.proficiency.level} />
            <div className="">{data.proficiency.name}</div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <div className="absolute right-5 top-5 flex gap-3">
        <EditOutlined
          className="cursor-pointer"
          onClick={() => updateTempData(index, { ...data, isEdit: true })}
        />
        <DeleteOutlined
          className=" cursor-pointer"
          onClick={() => {
            if (!loadingremoveLanguage) {
              if (data.id) {
                onRemove(data.id)
              } else {
                onRemoveTemp(index)
              }
            }
          }}
        />
      </div>
    </Card>
  )
}
