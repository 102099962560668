import React from "react"
import { Skills, AboutMe, JobHistory, Education, Certification } from "./index"
import { Account } from "../../hooks/cv-builder/useProfile"

interface Props {
  active: { key: string; label: string }
  activeSubMenu: {
    key: string
    label: string
    icon: JSX.Element
  }
  data: Account | undefined
}

export const Content: React.FC<Props> = ({ active, activeSubMenu, data }) => {
  switch (active.key) {
    case "about_me":
      return <AboutMe data={data} />
    case "experience":
      return <JobHistory />
    case "education":
      return <Education />
    case "skills":
      return <Skills active={activeSubMenu} />
    case "certification":
      return <Certification />
    default:
      return <div>about me</div>
  }
}
