import React from "react"
import { HomeOutlined, LoadingOutlined } from "@ant-design/icons"
import { Card, Spin } from "antd"
import { Breadcrumbs } from "../../../../common/components"
import { FilterSchool } from "../../components/school/FilterSchool"
import {
  useListSchool,
  useListSchoolLocation,
} from "../../hooks/schools/useListSchool"
import { CardSchool } from "../../components/school/CardSchool"
import { Moon } from "../../../../assets/images"

export const ListSchool = () => {
  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      path: "/skill/green-skills",
      title: "Skills Galaxy",
    },
    {
      path: "/skill/schools",
      title: "Schools",
    },
  ]
  const {
    dataList,
    isLoading,
    handleSearch,
    params,
    handleAddLocation,
    handleRemoveLocation,
    lastCardElementRef,
  } = useListSchool()
  const { data: dataLocation, isLoading: isLoadingLocation } =
    useListSchoolLocation()
  return (
    <div>
      <div className="bg-white py-4 px-6">
        <Breadcrumbs items={items} />

        <div className="my-3">
          <h1 className="font-bold">
            Get upskilled with your favourite educator
          </h1>
        </div>
      </div>

      <div className="px-6">
        <div className="my-5">
          <FilterSchool
            handleSearch={handleSearch}
            defaultValue={params.name}
            locations={dataLocation}
            loadingLocation={isLoadingLocation}
            handleAddLocation={handleAddLocation}
            selectedLocation={params.location}
            handleRemoveLocation={handleRemoveLocation}
          />
        </div>
        {dataList.length === 0 && isLoading ? (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          </div>
        ) : (
          <Card className="rounded-xl shadow-md bg-[#FAFAFA] p-0">
            <Spin
              spinning={isLoading}
              indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
            >
              <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-5 auto-rows-max">
                {dataList.length > 0 ? (
                  dataList.map((data, i) => (
                    <CardSchool
                      data={data}
                      key={i}
                      observ={
                        dataList.length - 1 === i
                          ? lastCardElementRef
                          : undefined
                      }
                    />
                  ))
                ) : (
                  <div className="flex gap-3 items-center">
                    <img src={Moon} alt="cookies" />
                    <div>{"Nothing to display"}</div>
                  </div>
                )}
              </div>
            </Spin>
          </Card>
        )}
      </div>
    </div>
  )
}
