import React from "react"
import { BasicContainer } from "../../../../common/components"
import { RocketFilled, FireOutlined } from "@ant-design/icons"
import { Divider, message, Skeleton } from "antd"
import { useThisWeekDiscussionStore } from "../../store/thisWeekDiscussionStore"
import { useQuery } from "react-query"
import { getThisWeeksPost } from "../apis/this-weeks-post"
import ErrorMessageBuilder from "../../../../common/components/ErrorMessageBuilder"
import { Moon } from "../../../../assets/images"

const ThisWeeksDiscussionWidget = () => {
  const { openDrawer } = useThisWeekDiscussionStore()
  const { data, isLoading } = useQuery(
    ["this-weeks-discussion"],
    () => getThisWeeksPost("ongoing"),
    {
      onError: () => {
        message.open({
          key: "this-weeks-discussion",
          duration: 5,
          content: React.createElement(ErrorMessageBuilder, {
            message:
              "We encountered an issue while attempting to fetch this week's discussion. If the problem continues, please refresh the page.",
            includeReportAt: true,
          }),
          type: "error",
        })
      },
      retry: 2,
    },
  )

  const weeksDiscussionData = data?.data?.data?.[0]

  return (
    <BasicContainer className="!bg-backdrop  text-white duration-300 transition-all !px-0">
      <h4 className="flex items-center gap-2 px-4 text-lg font-semibold text-secondaryYellow">
        <FireOutlined className="text-xl" />
        This Week's Discussion
      </h4>
      <Divider className="!border-gray-300 !my-[14px] " orientation="right" />
      <div className="px-4">
        {isLoading ? (
          <Skeleton active />
        ) : weeksDiscussionData ? (
          <>
            <div className="text-base tracking-wide">
              <h4>{weeksDiscussionData?.body?.title}</h4>
            </div>
            <div>{weeksDiscussionData?.body?.description}</div>
            <div className="text-base font-semibold">
              {weeksDiscussionData?.hashtags}
            </div>
          </>
        ) : (
          <div className={" flex flex-col justify-center items-center gap-3"}>
            <img src={Moon} className={"w-20 h-20"} />
            <p className={" m-0 p-0"}>No data</p>
          </div>
        )}

        {weeksDiscussionData && (
          <button
            onClick={openDrawer}
            className="flex items-center gap-2 px-6 py-2 mt-2 ml-auto text-base font-semibold text-white transition-all duration-300 rounded cursor-pointer hover:scale-110 bg-primary"
          >
            Join <RocketFilled className="text-lg text-white" />
          </button>
        )}
      </div>
    </BasicContainer>
  )
}

export default ThisWeeksDiscussionWidget
