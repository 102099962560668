import { Tooltip } from "antd"
import React from "react"
import {
  TranslationOutlined,
  // BarChartOutlined,
  // CheckSquareOutlined,
  BulbOutlined,
  // PictureOutlined,
  // NotificationOutlined,
  // ProjectOutlined,
} from "@ant-design/icons"
import { useNewPostStore } from "../../store/newPostStore"
import clsx from "clsx"

const NewPostIcons = () => {
  const { type, setType } = useNewPostStore()
  return (
    <div className=" flex gap-4 items-center">
      <Tooltip title="Default">
        <TranslationOutlined
          onClick={() => setType("default")}
          className={clsx("text-xl cursor-pointer", {
            "text-primary": type === "default",
          })}
        />
      </Tooltip>
      {/* <Tooltip title="Media">
        <PictureOutlined
          onClick={() => setType("media")}
          className={clsx("text-xl cursor-pointer", {
            "text-primary": type === "media",
          })}
        />
      </Tooltip> */}
      {/* <Tooltip title="Event">
        <ProjectOutlined
          onClick={() => setType("event")}
          className={clsx(
            "text-xl cursor-pointer duration-300 transition-all",
            {
              "text-primary": type === "event",
            },
          )}
        />
      </Tooltip> */}
      {/* <Tooltip title="Occasion">
        <NotificationOutlined
          onClick={() => setType("occasion")}
          className={clsx(
            "text-xl cursor-pointer duration-300 transition-all",
            {
              "text-primary": type === "occasion",
            },
          )}
        />
      </Tooltip> */}
      <Tooltip title="How-Tos">
        <BulbOutlined
          onClick={() => setType("how-tos")}
          className={clsx(
            "text-xl cursor-pointer duration-300 transition-all",
            {
              "text-primary": type === "how-tos",
            },
          )}
        />
      </Tooltip>
      {/* <Tooltip title="Multiple Choice">
        <CheckSquareOutlined
          onClick={() => setType("multiple-choice")}
          className={clsx(
            "text-xl cursor-pointer duration-300 transition-all",
            {
              "text-primary": type === "multiple-choice",
            },
          )}
        />
      </Tooltip>
      <Tooltip title="How-Tos">
        <BulbOutlined
          onClick={() => setType("how-tos")}
          className={clsx(
            "text-xl cursor-pointer duration-300 transition-all",
            {
              "text-primary": type === "how-tos",
            },
          )}
        />
      </Tooltip>
      <Tooltip title="Poll">
        <BarChartOutlined
          onClick={() => setType("poll")}
          className={clsx(
            "text-xl cursor-pointer duration-300 transition-all",
            {
              "text-primary": type === "poll",
            },
          )}
        />
      </Tooltip> */}
    </div>
  )
}

export default NewPostIcons
