import React from "react"
import { BasicContainer } from "./BasicContainer.tsx"
import { Skeleton } from "antd"
import { getTitleAccordingToSize, TitleSize } from "../../utils/utils.tsx"
import { CaretUpOutlined } from "@ant-design/icons"
import clsx from "clsx"
import { useCollapse } from "react-collapsed"

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  initialOpen: boolean
  titleRef?: React.RefObject<HTMLDivElement | HTMLHeadingElement>
  titleClassName?: string
  isLoading?: boolean
  titleSize?: TitleSize
  children?: React.ReactNode | React.ReactElement
  className?: string
  type?: "small" | "default" | "large"
}

export const BasicCollapsibleContainer = React.forwardRef<
  HTMLDivElement,
  Props
>(
  (
    {
      title,
      isLoading,
      children,
      titleSize = "h2",
      className,
      type = "default",
      titleRef,
      initialOpen = false,
      titleClassName,
      ...rest
    },
    ref,
  ) => {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
      defaultExpanded: initialOpen,
    })

    return (
      <BasicContainer
        {...rest}
        className={clsx(className)}
        ref={ref}
        type={type}
      >
        {isLoading ? (
          <Skeleton
            active
            loading
            title={false}
            paragraph={{ rows: 1, width: "100%" }}
          />
        ) : (
          <div
            {...getToggleProps()}
            className=" cursor-pointer flex flex-wrap items-center justify-between md:flex-nowrap"
          >
            {getTitleAccordingToSize(
              titleSize,
              title,
              titleRef,
              titleClassName,
            )}
            {
              <CaretUpOutlined
                className={clsx(
                  "text-xl transition-all duration-300",
                  isExpanded && "rotate-180",
                )}
              />
            }
          </div>
        )}
        {
          <div
            // className={clsx(
            //   isOpen ? "max-h-96 opacity-100" : "max-h-0 opacity-0",
            // )}
            {...getCollapseProps()}
          >
            {children}
          </div>
        }
      </BasicContainer>
    )
  },
)
