import React from "react"

interface ProductPreviewProps {
  data?: any
}

const ProductPreview: React.FC<ProductPreviewProps> = ({ data }) => {
  return (
    <div className="shadow-custom px-5 py-2 rounded-xl bg-white-custom">
      <h4>{data?.name}</h4>

      <div className="flex items-center gap-3 ">
        <p className="text-xs m-0 mt-2">Solution:</p>
        <div className="flex flex-wrap items-center gap-x-1 gap-y-1 justify-center mt-2">
          {data?.solutions?.map((solution: any, index: number) => (
            <div
              key={index}
              className="flex items-center bg-white px-2 rounded-full text-xs break-words"
              style={{ border: "1px solid #36505E" }}
            >
              {solution?.name}
            </div>
          ))}
        </div>
      </div>
      <div className="flex items-center gap-3 ">
        <p className="text-xs m-0 mt-2">Best for:</p>
        <div className="flex flex-wrap items-center gap-x-1 gap-y-1 justify-center mt-2">
          {data?.intended_user}
        </div>
      </div>

      <div
        dangerouslySetInnerHTML={{
          __html: data?.description,
        }}
      ></div>
    </div>
  )
}

export default ProductPreview
