import React from "react"
import { Card, Input, Select } from "antd"
import { SearchOutlined, CloseOutlined } from "@ant-design/icons"

interface ListFounderFilterProps {
  queryFounder?: any
  referenceData?: any
}

const ListFounderFilter: React.FC<ListFounderFilterProps> = ({
  queryFounder,
  referenceData,
}) => {
  return (
    <div>
      <Card className="bg-white-custom shadow-custom-sm rounded-xl py-1">
        <div className="flex justify-between items-center">
          <div className="flex flex-col md:flex-row md:gap-6 w-full">
            <div className="flex w-full md:w-8/12">
              <div
                className="flex w-full"
                style={{ borderBottom: "1px solid #D0DDE5" }}
              >
                <SearchOutlined />
                <Input
                  onChange={(e: any) => queryFounder?.handleSearch(e)}
                  bordered={false}
                  placeholder="search"
                />
              </div>
            </div>
            <div className="!bg-grey-select w-full md:w-2/6 relative  mt-4 md:mt-0 flex items-center gap-3 md:gap-7">
              <div className="w-full !bg-grey-select">
                <Select
                  className="my-1 font-default basis-full job-history w-full"
                  placeholder="Company Location"
                  value="Company Location"
                  showSearch
                  options={referenceData?.locationData?.listLocations?.list?.map(
                    (val: any) => ({
                      label: val.name,
                      value: val.id,
                    }),
                  )}
                  onChange={(value, option) => {
                    queryFounder?.handleFilterByLocation(option)
                  }}
                  labelInValue={true}
                  filterOption={(input: any, option: any) => {
                    if (option && option?.label) {
                      return (
                        option?.label
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      )
                    }
                    return false
                  }}
                />
              </div>
            </div>
            <div className="!bg-grey-select w-full md:w-1/6 relative  mt-4 md:mt-0 flex items-center gap-3 md:gap-7">
              <div className="w-full !bg-grey-select">
                <Select
                  className="my-1 font-default basis-full job-history w-full"
                  placeholder="Company Stage"
                  value="Company Stage"
                  showSearch
                  options={referenceData?.stageData?.listStage?.map(
                    (val: any) => ({
                      label: val.name,
                      value: val.id,
                    }),
                  )}
                  onChange={(value, option) => {
                    queryFounder?.handleFilterByStage(option)
                  }}
                  labelInValue={true}
                  filterOption={(input: any, option: any) => {
                    if (option && option?.label) {
                      return (
                        option?.label
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      )
                    }
                    return false
                  }}
                />
              </div>
            </div>
            <div className="!bg-grey-select w-full md:w-2/6 relative  mt-4 md:mt-0 flex items-center gap-3 md:gap-7">
              <div className="w-full !bg-grey-select">
                <Select
                  className="my-1 font-default basis-full job-history w-full"
                  placeholder="Solution Category"
                  value="Solution Category"
                  showSearch
                  options={referenceData?.solutionData?.listSolutions?.map(
                    (val: any) => ({
                      label: val.name,
                      value: val.id,
                    }),
                  )}
                  onChange={(value, option) => {
                    queryFounder?.handleFilterBySolution(option)
                  }}
                  labelInValue={true}
                  filterOption={(input: any, option: any) => {
                    if (option && option?.label) {
                      return (
                        option?.label
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      )
                    }
                    return false
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className={`grid ${
            queryFounder?.locationSelected?.length > 0 &&
            queryFounder?.stageSelected?.length > 0
              ? "grid-cols-3"
              : "grid-cols-1"
          } `}
        >
          <div>
            {queryFounder?.locationSelected?.length > 0 && (
              <div className="mt-3 flex gap-2">
                <div className=" font-semibold">Company Location:</div>
                <div className="flex items-center gap-2 flex-wrap">
                  {queryFounder?.locationSelected?.map((location: any) => (
                    <div
                      id={location}
                      key={location}
                      className="bg-white px-2 shadow-md flex items-center gap-x-1 rounded"
                      style={{ border: "1px solid #dedede" }}
                    >
                      {location.label}
                      <CloseOutlined
                        onClick={() =>
                          queryFounder?.handleRemoveLocation(location)
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div>
            {queryFounder?.stageSelected?.length > 0 && (
              <div className="mt-3 flex gap-2">
                <div className=" font-semibold">Company Stage::</div>
                <div className="flex items-center gap-2 flex-wrap">
                  {queryFounder?.stageSelected?.map((type: any) => (
                    <div
                      id={type}
                      key={type}
                      className="bg-white px-2 shadow-md flex items-center gap-x-1 rounded"
                      style={{ border: "1px solid #dedede" }}
                    >
                      {type.label}
                      <CloseOutlined
                        onClick={() => queryFounder?.handleRemoveStage(type)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div>
            {queryFounder?.solutionSelected?.length > 0 && (
              <div className="mt-3 flex gap-2">
                <div className=" font-semibold">Solution Stage:</div>
                <div className="flex items-center gap-2 flex-wrap">
                  {queryFounder?.solutionSelected?.map((type: any) => (
                    <div
                      id={type}
                      key={type}
                      className="bg-white px-2 shadow-md flex items-center gap-x-1 rounded"
                      style={{ border: "1px solid #dedede" }}
                    >
                      {type.label}
                      <CloseOutlined
                        onClick={() => queryFounder?.handleRemoveSolution(type)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  )
}

export default ListFounderFilter
