/* eslint-disable */

import { message } from "antd"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { ApiResponse } from "../../../../common/types/ApiResponse.ts"
import { errorMessage } from "../../../../common/utils/utils.tsx"
import { instanceV2 } from "../../../../config/config"
import {
  ProficiencySoftSkill,
  SoftSkillCategory,
  SoftSkillChart,
} from "./common_type.ts"

const fetchSoftSkills = () => {
  const response: Promise<ApiResponse<SoftSkillCategory[]>> = instanceV2.get(
    "greencv/soft-skills",
  )
  return response
}

const fetchProficiencySoftSkills = () => {
  const response: Promise<ApiResponse<ProficiencySoftSkill[]>> = instanceV2.get(
    "greencv/soft-skills/proficiencies",
  )
  return response
}

const fetchChartSoftSkills = () => {
  const response: Promise<ApiResponse<SoftSkillChart[]>> = instanceV2.get(
    "greencv/soft-skills/charts",
  )
  return response
}

const updateProficiencySoftSkill = ({ skillData }: { skillData: any }) => {
  return instanceV2.patch(`greencv/soft-skills/`, skillData)
}

const removeProficiencySoftSkill = (skill_id: string) => {
  return instanceV2.delete(`greencv/soft-skills/${skill_id}/proficiency`)
}

const useSoftSkills = () => {
  const queryClient = useQueryClient()
  const handleFetchError = (error: any) => {
    errorMessage(
      `An error occurred while fetching soft skills: ${error}`,
      error,
    )
  }

  const handleUpdateSuccess = () => {
    message.success("Soft skill successfully updated")
  }

  const handleUpdateError = (error: any) => {
    errorMessage(
      `An error occurred while updating a soft skill: ${error}`,
      error,
    )
  }

  const {
    data: softskills,
    isLoading: isFetchingSoftSkill,
    isError: isFetchErrorSoftSkill,
    error: fetchErrorSoftSkill,
  } = useQuery(["fetch-soft-skills"], () => fetchSoftSkills(), {
    refetchOnWindowFocus: false,
    onError: handleFetchError,
  })

  const {
    data: softskillProficiencies,
    isLoading: isFetchingSoftSkillProficiencies,
    isError: isFetchErrorProficiencies,
    error: fetchErrorProficiencies,
  } = useQuery(
    ["fetch-proficiency-soft-skills"],
    () => fetchProficiencySoftSkills(),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
    },
  )

  const {
    data: softskillChart,
    isLoading: isFetchingSoftSkillChart,
    isError: isFetchErrorChart,
    error: fetchErrorChart,
  } = useQuery(["fetch-chart-soft-skills"], () => fetchChartSoftSkills(), {
    refetchOnWindowFocus: false,
    onError: handleFetchError,
  })

  const { mutate: updateSoftSkillMutate } = useMutation(
    ({ skillData }: { skillData: any }) =>
      updateProficiencySoftSkill({ skillData }),
    {
      onSuccess: () => {
        message.open({
          key: "update-soft-skill",
          content: "Updating...",
          type: "loading",
        })
        queryClient.invalidateQueries(["fetch-soft-skills"])
        queryClient.invalidateQueries(["fetch-proficiency-soft-skills"])
        queryClient.invalidateQueries(["fetch-chart-soft-skills"])
        setTimeout(() => {
          handleUpdateSuccess()
        }, 2300)
      },
      onError: handleUpdateError,
      // enabled: isLoggedIn,
    },
  )

  const { mutate: removeSoftSkillMutate } = useMutation(
    (skill_id: string) => removeProficiencySoftSkill(skill_id),
    {
      onSuccess: () => {
        message.open({
          key: "update-soft-skill",
          content: "Updating...",
          type: "loading",
        })
        queryClient.invalidateQueries(["fetch-soft-skills"])
        queryClient.invalidateQueries(["fetch-proficiency-soft-skills"])
        queryClient.invalidateQueries(["fetch-chart-soft-skills"])
        setTimeout(() => {
          handleUpdateSuccess()
        }, 2300)
      },
      onError: handleUpdateError,
      // enabled: isLoggedIn,
    },
  )

  // convert proficiency to select format
  const optionsProficiencies = softskillProficiencies?.data?.data?.map(
    (level: ProficiencySoftSkill) => ({
      value: level?.id,
      label: level?.name,
    }),
  )!
  optionsProficiencies?.unshift({
    value: "not_skilled",
    label: "Not Skilled",
  })

  const handleUpdateProficiency = (skillIO: string, proffId: string) => {
    if (proffId !== "not_skilled") {
      const skillData: any = {
        critical_skill_id: skillIO,
        proficiency_id: proffId,
      }
      updateSoftSkillMutate({ skillData })
    } else {
      removeSoftSkillMutate(skillIO)
    }
  }

  return {
    softskillResp: {
      softskills: softskills?.data?.data,
      isFetchingSoftSkill,
      isFetchErrorSoftSkill,
      fetchErrorSoftSkill,
    },
    softskillProficiencyResp: {
      softskillProficiencies: optionsProficiencies,
      isFetchingSoftSkillProficiencies,
      isFetchErrorProficiencies,
      fetchErrorProficiencies,
      handleUpdateProficiency,
    },
    softskillChartResp: {
      softskillCharts: softskillChart?.data?.data,
      isFetchingSoftSkillChart,
      isFetchErrorChart,
      fetchErrorChart,
    },
  }
}

export default useSoftSkills
