import {
  DiscussionsIcon,
  ExpertHeaderImg,
  FounderHeaderImg,
  KeyIcon,
  MentorHeaderImg,
  MyJobsIcon,
  MySkillsIcon,
  NewsIcon,
  ProfessionalHeaderImg,
  ScheduleIcon,
  StarIcon,
} from "../../../../assets/images"
import withIcon from "../../../../common/components/hocs/withIcon"
import ClimateSolutions from "../components/ClimateSolutions"
import ExpertBio from "../components/ExpertBio"
import ExpertSkills from "../components/ExpertSkills"
import FounderBio from "../components/FounderBio"
import Mentees from "../components/Mentees"
import MentorBio from "../components/MentorBio"
import MyArticles from "../components/MyArticles"
import MyCompany from "../components/MyCompany"
import MyDiscussionsList from "../components/MyDiscussionsList"
import MyExpertise from "../components/MyExpertise"
import MyJobs from "../components/MyJobs"
import MySchedule from "../components/MySchedule"
import MySkills from "../components/MySkills"
import News from "../components/News"
import { Role } from "../stores/useRoleStore"
import {
  ReadOutlined,
  CrownOutlined,
  ExperimentOutlined,
  IdcardOutlined,
} from "@ant-design/icons"

export const roleTabs = {
  [Role.Professional]: [
    {
      label: "Discussions",
      key: "discussions",
      customIcon: withIcon(() => <img src={DiscussionsIcon} />),
      children: <MyDiscussionsList />,
    },
    {
      label: "My Skills",
      key: "my_skills",
      customIcon: withIcon(() => <img src={MySkillsIcon} />),
      children: <MySkills />,
    },
    {
      label: "My Jobs",
      key: "my_jobs",
      customIcon: withIcon(() => <img src={MyJobsIcon} />),
      children: <MyJobs />,
    },
    {
      label: "News",
      key: "news",
      customIcon: withIcon(() => <img src={NewsIcon} />),
      children: <News />,
    },
  ],
  [Role.Expert]: [
    {
      label: "My Articles",
      key: "my_articles",
      customIcon: ReadOutlined,
      children: <MyArticles />,
    },
    {
      label: "Expert Skills",
      key: "expert_skills",
      customIcon: withIcon(() => <img src={MySkillsIcon} />),
      children: <ExpertSkills />,
    },
    {
      label: "Expert Bio",
      key: "expert_bio",
      customIcon: IdcardOutlined,
      children: <ExpertBio />,
    },
  ],
  [Role.Mentor]: [
    {
      label: "Schedule",
      key: "schedule",
      customIcon: withIcon(() => <img src={ScheduleIcon} />),
      children: <MySchedule />,
    },
    {
      label: "My Expertise",
      key: "my_expertise",
      customIcon: withIcon(() => <img src={KeyIcon} />),
      children: <MyExpertise />,
    },
    {
      label: "Mentees",
      key: "mentees",
      customIcon: withIcon(() => <img src={StarIcon} />),
      children: <Mentees />,
    },
    {
      label: "Mentor Bio",
      key: "mentor_bio",
      customIcon: IdcardOutlined,
      children: <MentorBio />,
    },
  ],
  [Role.Founder]: [
    {
      label: "My Company",
      key: "my_company",
      customIcon: CrownOutlined,
      children: <MyCompany />,
    },
    {
      label: "Climate Solutions",
      key: "climate_solutions",
      customIcon: ExperimentOutlined,
      children: <ClimateSolutions />,
    },
    {
      label: "Founder Bio",
      key: "founder_bio",
      customIcon: IdcardOutlined,
      children: <FounderBio />,
    },
  ],
}

export const profileDoesNotExistDescps: {
  [key in Role]: { subtitle: string; description: string }
} = {
  [Role.Professional]: { subtitle: "", description: "" },
  [Role.Expert]: {
    subtitle: "Oops. You are not an expert, yet!",
    description:
      "Let's apply for the expert profile if you would like to share your leadership thought by publishing articles of specific Green Skills",
  },
  [Role.Mentor]: {
    subtitle: "Oops. You are not a mentor, yet!",
    description:
      "Let's apply for the mentor profile if you would like to share your knowledge",
  },
  [Role.Founder]: {
    subtitle: "Oops. You are not a founder, yet!",
    description:
      "Let's apply for the founder profile if you would like to share your green company bio.",
  },
}

export const profileDescriptions: {
  [key in Role]: {
    title: string
    description: string
    rolePendingDescp: string
    rolePendingDescpLonger: string
    buttonTitle: string
    coverImage: any
  }
} = {
  [Role.Professional]: {
    title: "",
    description: "",
    rolePendingDescp: "",
    rolePendingDescpLonger: "",
    buttonTitle: "",
    coverImage: ProfessionalHeaderImg,
  },
  [Role.Expert]: {
    title: "Expert Profile",
    description:
      "Grow your leadership thought by publishing articles on specific Green Skills with Expert Profile",
    rolePendingDescp: "Your Expert application is under review",
    rolePendingDescpLonger: `Your application to become an expert is currently under our review. We appreciate your interest and will notify you the result. Thank you for your patience!`,
    buttonTitle: "Become an Expert",
    coverImage: ExpertHeaderImg,
  },
  [Role.Mentor]: {
    title: "Mentor Profile",
    description:
      "Cultivate the next generation of green professionals by sharing your knowledge and experience.",
    rolePendingDescp: "Your Mentor application is under review",
    rolePendingDescpLonger:
      "Your application to become a mentor is currently under our review. We appreciate your interest and will notify you the result. Thank you for your patience!",
    buttonTitle: "Become a Mentor",
    coverImage: MentorHeaderImg,
  },
  [Role.Founder]: {
    title: "Founder Profile",
    description:
      "Founder Profile showcases your company and the climate solutions it offers. ",
    rolePendingDescp: "Your Founder application is under review",
    rolePendingDescpLonger:
      "Your application to become a founder is currently under our review. We appreciate your interest and will notify you the result. Thank you for your patience!",
    buttonTitle: "Become a Founder",
    coverImage: FounderHeaderImg,
  },
}
