import React from "react"
import { Account } from "../../hooks/cv-builder/useProfile"
import { Card } from "antd"
import SquareRate from "../../../../common/components/SquareRate"

interface Props {
  data: Account
}

export const CardLanguage: React.FC<Props> = ({ data }) => {
  return (
    <Card className="shadow-md bg-[#FAFAFA] rounded-xl">
      <h2 className=" capitalize mb-5">Languages</h2>
      {data?.languages?.map((item, index) => (
        <div key={index} className="my-1">
          <div className=" flex gap-3 items-center mt-2">
            <SquareRate value={item.proficiency.level} />
            <div className="">{item.language.name}</div>
          </div>
        </div>
      ))}
    </Card>
  )
}
