import React from "react"
import { EnvironmentOutlined } from "@ant-design/icons"
import { FounderResponse, StartupResponse } from "../../founder/types/response"
import { GlobalOutlined } from "@ant-design/icons"
import { openInNewTab } from "../../../../utils/util_funcs"
import { Image, Skeleton } from "antd"

interface SolutionDetailRightContentProps {
  detail?: StartupResponse
  loading?: boolean
}

const SolutionDetailRightContent: React.FC<SolutionDetailRightContentProps> = ({
  detail,
  loading,
}) => {
  return (
    <div className="bg-white-custom p-4 rounded-xl shadow-custom-sm space-y-4">
      {loading ? (
        <Skeleton active />
      ) : (
        <div className="p-4 shadow-custom rounded-xl space-y-3">
          <p className="m-0 text-[#966F02] text-xs uppercase tracking-widest ">
            {detail?.stage?.name}
          </p>
          <div className="flex items-center gap-2">
            <EnvironmentOutlined /> {detail?.location?.name}
          </div>

          <div
            className="bg-lightYellow text-center w-full flex items-center rounded gap-2 justify-center cursor-pointer"
            onClick={() => openInNewTab(detail?.website!)}
            style={{ border: "1px solid #36505E" }}
          >
            <GlobalOutlined />
            Website
          </div>
        </div>
      )}

      {loading ? (
        <Skeleton active />
      ) : (
        <div className="p-4 shadow-custom rounded-xl space-y-3">
          <small className="px-2 bg-primary text-white -mt-2 z-40 rounded">
            Founding Team
          </small>

          {detail?.founders?.map((founder: FounderResponse, index: number) => {
            return (
              <div className="flex gap-x-3 space-y-3" key={index}>
                <div className="flex items-center gap-x-4">
                  <Image
                    src={founder?.avatar}
                    alt="profile"
                    width={68}
                    height={68}
                    preview={false}
                    className="rounded-full"
                  />
                  <div className="flex flex-col">
                    <h3 className="m-0">
                      {founder?.first_name} {founder?.last_name}
                    </h3>

                    <div className="flex items-center gap-x-2">
                      <EnvironmentOutlined />
                      <p className="m-0">{founder?.country}</p>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default SolutionDetailRightContent
