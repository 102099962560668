import { Avatar, Image, Skeleton } from "antd"
import { GreenSkillsLogo } from "../../../assets/images"
import dayjs from "dayjs"
import React from "react"

export const HeaderCertificateShared = (data: any) => {
  const pasedLevels = data?.schoolData?.levels?.filter(
    (item: any) => item?.user_track && item?.user_track?.is_assessment_passed,
  )
  const lastELevels = pasedLevels && pasedLevels[pasedLevels?.length - 1]

  return (
    <div
      className={`bg-white h-16 flex flex-wrap gap-3 items-center justify-between px-16`}
    >
      <div className={`flex items-center gap-4`}>
        <div>
          <Avatar>N</Avatar>
        </div>
        <div>
          Issued to {data?.schoolData?.talent?.first_name}{" "}
          {data?.schoolData?.talent?.last_name} by{" "}
          {data?.schoolData?.school?.name} on{" "}
          {dayjs(lastELevels?.user_track?.updated_at)
            .locale("en")
            .format("DD" + " MMM YYYY")}
        </div>
      </div>
    </div>
  )
}

export const ContentCertificateShared = (data: any) => {
  const pasedLevels = data?.schoolData?.levels?.filter(
    (item: any) => item?.user_track && item?.user_track?.is_assessment_passed,
  )
  const lastELevels = pasedLevels && pasedLevels[pasedLevels?.length - 1]

  function removeHtmlTags(inputString: string) {
    return inputString.replace(/<\/?[^>]+(>|$)/g, "")
  }

  return (
    <div id="certificate" className={`mx-auto w-[680px] drop-shadow-lg`}>
      <div className="text-backdrop bg-white px-20 py-5 ">
        <div className={`text-center mb-8 mt-10`}>
          {data?.isLoadingLevels ? (
            <Skeleton.Image active={true} />
          ) : (
            <Image
              preview={false}
              src={data?.schoolData?.school?.logo || GreenSkillsLogo}
              width={70}
            />
          )}
        </div>
        <div className={`text-center mb-16`}>
          <h3 className={`mb-6`}>Certificate of Completion</h3>
          <p className={`mb-6`}>This is to certify that</p>
          {data?.isLoadingLevels ? (
            <div className={""}>
              <Skeleton
                className={``}
                active={true}
                paragraph={{ rows: 6, width: 520 }}
              />
            </div>
          ) : (
            <div>
              <h1 className={`capitalize`}>
                {data?.schoolData?.talent?.first_name}{" "}
                {data?.schoolData?.talent?.last_name}
              </h1>
              <p className={"mb-6 mt-6"}>
                Has successfully completed online course delivered by{" "}
                <span>{data?.schoolData?.school?.name}</span> and offered
                through Skilledin Green platform
              </p>
              <h1>{data?.dataSkill}</h1>
            </div>
          )}
        </div>
        <div className={`space-y-4 mb-28`}>
          <p>Completed proficiency levels and associated knowledge</p>

          {data?.isLoadingLevels ? (
            <div className={""}>
              <Skeleton
                className={``}
                active={true}
                paragraph={{ rows: 6, width: 520 }}
              />
            </div>
          ) : (
            <table className="min-w-full bg-white rounded-md overflow-hidden">
              <tbody>
                {pasedLevels?.map((item: any, index: number) => (
                  <tr key={item.id}>
                    <td className="py-1 px-1 capitalize w-28">
                      {dayjs(item?.user_track?.updated_at)
                        .locale("en")
                        .format("DD" + " MMM YYYY")}
                    </td>
                    <td className="py-1 px-1 capitalize w-20">
                      Level {index + 1}
                    </td>
                    <td className="py-1 px-1">
                      {removeHtmlTags(item.learning_outcome)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {data?.isLoadingLevels ? (
          <div className={""}>
            <Skeleton
              className={``}
              active={true}
              paragraph={{ rows: 2, width: 520 }}
            />
          </div>
        ) : (
          <div className={`flex items-center justify-center gap-7 mb-16`}>
            <div className={`text-center mb-5`}>
              <Image
                preview={false}
                src={
                  data?.schoolData?.school?.admin_signature === ""
                    ? GreenSkillsLogo
                    : data?.schoolData?.school?.admin_signature
                }
                width={90}
              />
            </div>
            <div className={`space-y-1 mb-4`}>
              <p className={`font-semibold p-0 m-0`}>
                {data?.schoolData?.school?.admin?.first_name}{" "}
                {data?.schoolData?.school?.admin?.last_name}
              </p>
              <p>
                {data?.schoolData?.school?.admin?.role} at{" "}
                {data?.schoolData?.school?.name}
              </p>
              <p>
                {dayjs(lastELevels?.user_track?.updated_at)
                  .locale("en")
                  .format("DD" + " MMM YYYY")}
              </p>
            </div>
          </div>
        )}
      </div>

      <div
        className={`bg-secondaryBlue flex items-center justify-between px-20 py-2`}
      >
        <div className={`text-center`}>
          <Image preview={false} src={GreenSkillsLogo} width={100} />
          <p className={`m-0`}>green.skilledin.io</p>
        </div>
        {data?.isLoadingLevels ? (
          <div className={""}>
            <Skeleton
              className={``}
              active={true}
              paragraph={{ rows: 1, width: 200 }}
            />
          </div>
        ) : (
          <div className={`space-y-1`}>
            <p>Ref No {lastELevels?.user_track?.certificate_ref_number}</p>
          </div>
        )}
      </div>
    </div>
  )
}
