import React from "react"
import { useNavigate } from "react-router-dom"
import { useSkillsPage } from "../../skillGalaxy/hooks/useSkillsPage"
import { Avatar, Button, Carousel, Image, Popover, Skeleton, Tag } from "antd"
import useLandingPage from "../hook/useLandingPage"
import { useSector } from "../../jobGalaxy/hooks/commons/useSectorList"
import { TrainerPopoverContent } from "../../skillGalaxy/components"
interface UniverseSectionProps {}

const UniverseSection: React.FC<UniverseSectionProps> = () => {
  const { data: skillSectors, isLoading: isSectorSkillLoading } =
    useSkillsPage()
  const navigate = useNavigate()
  const { courseSkill, isLoading } = useLandingPage()

  const { dataList, isLoading: loadingSector } = useSector()
  return (
    <div className="bg-secondaryBlue text-backdrop">
      <div className="m-auto w-[85%] py-20">
        <div className="">
          <h2 className="font-semibold text-[40px] text-center mb-12 leading-tight">
            Build your Green Skills Universe
          </h2>
          <h3 className="text-center">
            Align green skills with your interests
          </h3>
          <p className="text-center">
            Constantly growing and the most comprehensive selection of green
            skills and courses.
          </p>

          <div className="flex flex-wrap items-center mt-5 h-auto">
            <div className="mb-1 h-[9.5rem] md:h-[4.5rem] overflow-hidden text-center">
              {isSectorSkillLoading ? (
                <Skeleton active />
              ) : (
                skillSectors?.pages
                  ?.flatMap((page: any) => page?.data?.list?.slice(0, 10))
                  ?.map((group: any, idx) => (
                    <Tag
                      key={group?.slug + idx}
                      onClick={() =>
                        navigate(`/skill/green-skills`, {
                          state: {
                            groupId: group?.id,
                            checked: true,
                          },
                        })
                      }
                      className="rounded-full py-1 px-2 mb-2 cursor-pointer"
                      color="#FFF"
                      style={{
                        border: "1px solid gray",
                        color: "#36505E",
                      }}
                    >
                      {group?.name}
                    </Tag>
                  ))
              )}
            </div>
          </div>

          <div className="bg-white-custom shadow-custom-sm px-2 py-5 rounded-xl my-10">
            <Carousel>
              {isLoading || loadingSector ? (
                <div className="p-3 mb-10">
                  <div className="flex flex-row gap-x-6 items-center md:flex-nowrap flex-wrap">
                    <Skeleton.Image
                      className=" object-contain"
                      active
                      style={{ width: 200 }}
                    />
                    <Skeleton active />
                  </div>
                </div>
              ) : (
                [...Array(courseSkill?.data?.length)]?.map((_, slideIndex) => {
                  const startIndex = slideIndex * 1
                  return (
                    <div className="py-3 px-5 mb-10" key={slideIndex}>
                      <div className="grid md:grid-cols-1">
                        {courseSkill?.data
                          ?.slice(startIndex, startIndex + 1)
                          .map((expert: any, cardIndex: number) => {
                            return (
                              <div
                                className="bg-white-custom  rounded-xl flex gap-x-6 gap-y-32 mb-3 flex-wrap md:flex-nowrap"
                                key={cardIndex}
                              >
                                <div className="relative bg-secondaryYellow p-2 w-full md:w-[290px] md:h-[240px] rounded-xl shadow-custom-sm overflow-x-hidden pb-4">
                                  <div
                                    className="absolute rounded-md w-auto md:w-2/3 gap-2 px-1 py-0.5 bg-white"
                                    style={{ border: "1px solid" }}
                                  >
                                    <p className="truncate !w-auto m-0 text-xs">
                                      {expert?.course?.groups?.name}
                                    </p>
                                  </div>
                                  <div className="text-center mt-6">
                                    <Image
                                      src={expert?.course?.image}
                                      alt={expert?.course?.name}
                                      preview={false}
                                      className="mt-3 rounded-xl object-cover"
                                      width={"100%"}
                                      height={180}
                                    />
                                  </div>
                                </div>

                                <div className="relative flex items-center w-full justify-between h-[120px] md:h-auto">
                                  <div className="absolute flex items-center justify-between -top-3 md:-top-2 w-full">
                                    {isLoading ? (
                                      <Skeleton.Image active />
                                    ) : expert?.logo ? (
                                      <div
                                        className="rounded-full flex items-center gap-2 px-3"
                                        style={{
                                          border: "1px solid #86C769",
                                        }}
                                      >
                                        <Image
                                          src={expert?.logo}
                                          preview={false}
                                          width={28}
                                          className="object-contain m-0"
                                        />
                                        <p className="m-0 text-xs">
                                          {expert?.name || "Unknown"}
                                        </p>
                                      </div>
                                    ) : null}

                                    <div className="flex items-center top-0 gap-4">
                                      <p className="uppercase text-xs">
                                        Taught By
                                      </p>
                                      <div className="flex items-center">
                                        {expert?.course?.trainers?.map(
                                          (instructor: any, index: number) => (
                                            <Popover
                                              placement="left"
                                              key={index}
                                              content={
                                                <TrainerPopoverContent
                                                  instructor={instructor}
                                                />
                                              }
                                            >
                                              <div
                                                className="flex gap-3 items-center -mr-2"
                                                key={instructor?.id}
                                              >
                                                <div>
                                                  <Avatar
                                                    src={
                                                      instructor?.avatar
                                                        ? instructor?.avatar
                                                        : undefined
                                                    }
                                                    size={32}
                                                  >
                                                    N
                                                  </Avatar>
                                                </div>
                                              </div>
                                            </Popover>
                                          ),
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="absolute -top-28 md:top-10">
                                    <h5 className="font-bold line-clamp-1 md:line-clamp-2">
                                      {expert?.course?.name}
                                    </h5>
                                    <p className="line-clamp-2 md:line-clamp-3">
                                      {expert?.course?.description}
                                    </p>
                                  </div>
                                  <div
                                    className={`absolute hidden md:flex ${
                                      !isLoading &&
                                      expert?.course?.required_jobs
                                        ? "bottom-14"
                                        : "bottom-1"
                                    } `}
                                  >
                                    <Button
                                      type="primary"
                                      className="absolute bottom-0"
                                      onClick={() =>
                                        navigate(
                                          `/skill/green-skills/${expert?.course?.green_skill_id}`,
                                        )
                                      }
                                    >
                                      Skill Details
                                    </Button>
                                  </div>

                                  {!isLoading &&
                                    expert?.course?.required_jobs && (
                                      <div className="absolute bottom-9 md:bottom-0 h-10 overflow-hidden">
                                        <div className="flex flex-wrap gap-x-2">
                                          <span className="">
                                            You need this skill if you are:
                                          </span>
                                          {isLoading ? (
                                            <Skeleton
                                              active
                                              title={false}
                                              paragraph={{ rows: 1 }}
                                            />
                                          ) : (
                                            expert?.course?.required_jobs?.map(
                                              (skill: any, index: number) => (
                                                <span
                                                  onClick={() =>
                                                    navigate(
                                                      `/job/roles/${skill?.slug}`,
                                                    )
                                                  }
                                                  className="text-gray-400 hover:text-gray-600 cursor-pointer"
                                                  key={index}
                                                >
                                                  {skill?.name}
                                                  {index !==
                                                    expert?.course
                                                      ?.required_jobs?.length -
                                                      1 && (
                                                    <span className=""> •</span>
                                                  )}
                                                </span>
                                              ),
                                            )
                                          )}
                                        </div>
                                      </div>
                                    )}

                                  <div className="absolute md:hidden flex bottom-0 w-full">
                                    <Button
                                      type="primary"
                                      className="absolute -bottom-2"
                                      onClick={() =>
                                        navigate(
                                          `/skill/green-skills/${expert?.course?.id}`,
                                        )
                                      }
                                      block={true}
                                    >
                                      Skill Details s
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                      </div>
                    </div>
                  )
                })
              )}
            </Carousel>
          </div>

          <h3 className="text-center" id="upskill">
            Explore green skills by job role
          </h3>
          <p className="text-center">
            See job role description, required skills, and more. Based on
            Singapore’s SkillsFuture framework
          </p>

          <div className="flex flex-wrap items-center mt-5 h-auto">
            <div className="mb-1 h-[9.5rem] md:h-[4.5rem] overflow-hidden text-center">
              {loadingSector ? (
                <Skeleton active />
              ) : (
                dataList?.map((group: any) => (
                  <Tag
                    key={group?.slug}
                    onClick={() =>
                      navigate(`/skill/green-skills`, {
                        state: {
                          groupId: group?.id,
                          checked: true,
                        },
                      })
                    }
                    className="rounded-full py-1 px-2 mb-2 cursor-pointer"
                    color="#FFF"
                    style={{
                      border: "1px solid gray",
                      color: "#36505E",
                    }}
                  >
                    {group?.name}
                  </Tag>
                ))
              )}
            </div>
            <div className="w-full">
              <p
                className="text-right underline cursor-pointer hover:font-bold"
                onClick={() => navigate("/job/roles")}
              >
                All Job Roles
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UniverseSection
