import { create } from "zustand"

export type CommunityModalType =
  | "default"
  | "poll"
  | "event"
  | "media"
  | "how-tos"
  | "occasion"
  | "event"
  | "multiple-choice"

type ModalState = {
  isOpen: boolean
  type: CommunityModalType | null
  openModal: (type?: CommunityModalType) => void
  setType: (type: CommunityModalType) => void
  closeModal: () => void
}

export const useNewPostStore = create<ModalState>((set) => ({
  isOpen: false,
  type: null,
  openModal: (type = "default") => set(() => ({ isOpen: true, type })),
  closeModal: () => set(() => ({ isOpen: false, type: null })),
  setType: (type) => set(() => ({ isOpen: true, type })),
}))
