import { Link, useNavigate } from "react-router-dom"
import {
  ArrowLeftOutlined,
  EnvironmentOutlined,
  UsergroupAddOutlined,
  InteractionOutlined,
  FundOutlined,
} from "@ant-design/icons"
import { Breadcrumbs } from "../../../common/components"
import { Avatar, Button, Empty, Image, Skeleton, Tag, Tooltip } from "antd"
import { ReactComponent as DefaultCompanyLogo } from "../../../assets/images/default-company.svg"
import { SocialMediaLinks } from "../../jobGalaxy/components/SocialMediaLink"

export const SingleSchoolTopSection = ({
  breadcrumbsItems,
  isLoading,
  detailSchool,
}) => {
  return (
    <div>
      <div>
        <div className="bg-white  py-4 px-8">
          <Breadcrumbs items={breadcrumbsItems} />

          <div className="text-backdrop -mt-2">
            <div className="flex items-center gap-3">
              <Link
                to="/skill/schools"
                className="text-gray-500 p-1 cursor-pointer mt-1 text-lg -ml-1"
              >
                <ArrowLeftOutlined />
              </Link>
              {isLoading ? (
                <Skeleton
                  active
                  loading={isLoading}
                  size="large"
                  shape="square"
                  className="mt-10"
                  paragraph={{ rows: 1 }}
                />
              ) : (
                <div className="flex items-center justify-between w-full">
                  <div className="">
                    <h1 className="text-backdrop text-2xl font-extrabold my-3 ">
                      {detailSchool?.name}
                    </h1>
                  </div>

                  <div className="flex items-center flex-col gap-2">
                    {detailSchool?.logo ? (
                      <Image
                        src={detailSchool?.logo ? detailSchool?.logo : ""}
                        preview={false}
                        alt={`logo-${detailSchool?.name}`}
                        height={100}
                        width={100}
                        className="object-contain"
                        // width={80}
                      />
                    ) : (
                      <div style={{ height: "80px" }}>
                        <DefaultCompanyLogo />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="space-y-3 -mt-3">
              <div className="flex items-center gap-2 text-xs">
                <EnvironmentOutlined />
                <span>{detailSchool?.location}</span>
              </div>

              <div>
                <p className="p-o m-0 leading-5">{detailSchool?.description}</p>
              </div>
            </div>
            <div className="flex items-center gap-5 mt-7">
              {SocialMediaLinks(detailSchool)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const SingleSchoolRightSection = ({ detailSchool, isLoading }) => {
  return (
    <div className="bg-white  py-4 px-8 top-4 sticky">
      <h2 className=" text-lg font-bold">Our Skill Instructors</h2>
      <div className="space-y-5 mt-8">
        {isLoading ? (
          <Skeleton active />
        ) : detailSchool?.school_trainers?.length > 0 ? (
          (() => {
            // Create a Set to store unique instructor IDs
            const uniqueInstructorIds = new Set()

            // Filter the instructors list to include only unique instructors
            const uniqueInstructors = detailSchool.school_trainers.filter(
              (instructor) => {
                if (!uniqueInstructorIds.has(instructor.id)) {
                  uniqueInstructorIds.add(instructor.id)
                  return true
                }
                return false
              },
            )

            return uniqueInstructors.map((instructor, index) => (
              <div className="flex gap-3 items-center" key={index}>
                <div>
                  <Avatar
                    src={instructor?.avatar ? instructor?.avatar : undefined}
                    size={52}
                  >
                    N
                  </Avatar>
                </div>
                <div className="flex flex-col gap-1">
                  <span className="font-bold">
                    {instructor?.first_name} {instructor?.last_name}
                  </span>
                  <span>
                    {instructor?.job_title} at {detailSchool?.name}
                  </span>
                </div>
              </div>
            ))
          })()
        ) : (
          <Empty />
        )}
      </div>
    </div>
  )
}

export const SingleSchoolContentSection = ({ detailSchool, isLoading }) => {
  const navigate = useNavigate()

  const tooltipContent = (instructor) => (
    <div className="text-backdrop w-full m-5 pr-4">
      <div className="space-y-5 mt-8 mr-10">
        <div className="flex gap-3 items-center">
          <div>
            <Avatar
              src={instructor?.avatar ? instructor?.avatar : undefined}
              size={65}
            >
              N
            </Avatar>
          </div>
          <div className="flex flex-col gap-1">
            <span className="font-bold">
              {instructor?.first_name} {instructor?.last_name}
            </span>
            <span>
              {instructor?.job_title} at {detailSchool?.name}
            </span>
            <div className="flex items-center gap-2 text-xs">
              <EnvironmentOutlined />
              <span>{instructor?.country}</span>
            </div>
          </div>
        </div>
        <div>
          <p>{instructor?.about}</p>
        </div>
        <div className="flex items-center gap-3 mt-7 justify-end mr-7">
          {SocialMediaLinks(instructor)}
        </div>
      </div>
    </div>
  )
  return isLoading ? (
    <div className="bg-white p-5 h-full">
      <Skeleton active />{" "}
    </div>
  ) : detailSchool?.courses?.length > 0 ? (
    detailSchool?.courses?.map((item, index) => (
      <div className="grid grid-cols-12 mb-3" key={index}>
        <div className="px-6 py-5 bg-white col-span-full md:col-span-9 space-y-3">
          <Tag
            className="overflow-clip max-w-full"
            color="#D5F5C5"
            style={{
              color: "#36505E",
              border: "1px solid #98AFA5",
            }}
          >
            {item?.groups?.name ? item?.groups?.name : "N/A"}
          </Tag>
          <p className="font-bold text-xl">{item?.name}</p>
          <p className="leading-5 line-clamp-4">{item?.description}</p>
          <br />

          <div className="flex gap-x-8 items-center justify-start mt-6">
            <div className="flex gap-2 items-center">
              <Tooltip placement="bottom" title="people with this skill">
                <UsergroupAddOutlined className="text-backdrop" />
              </Tooltip>
              <div className="text-backdrop">n/a</div>
            </div>
            <div className="flex gap-2 items-center">
              <Tooltip placement="bottom" title="transferability score">
                <InteractionOutlined className="text-backdrop" />
              </Tooltip>
              <div className="text-backdrop">
                {Math.round(item?.transferability_score) ?? "n/a"}
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <Tooltip placement="bottom" title="ranking">
                <FundOutlined className="text-backdrop" />
              </Tooltip>
              <div className="text-backdrop">
                {" "}
                {Math.round(item?.rangking) ?? "n/a"}
              </div>
            </div>
          </div>
        </div>
        <div className="relative px-3 py-2 bg-yellow-200 col-span-full md:col-span-3 flex flex-col justify-between">
          <div className="flex flex-col justify-center items-center ">
            <p className="font-bold">Instructors</p>
            {item?.trainers ? (
              <Avatar.Group
                maxCount={2}
                maxStyle={{
                  color: "#f56a00",
                  backgroundColor: "#fde3cf",
                }}
              >
                {Array.from(
                  new Set(item.trainers.map((trainer) => trainer.id)),
                ).map((trainerId) => {
                  const trainer = item.trainers.find((t) => t.id === trainerId)
                  return (
                    <Tooltip
                      key={trainer?.id}
                      title={tooltipContent(trainer)}
                      placement="bottom"
                      color="#E2EBFA"
                      overlayStyle={{ maxWidth: "350px" }}
                    >
                      <Avatar
                        size={47}
                        src={trainer?.avatar ? trainer?.avatar : undefined}
                      >
                        {!trainer?.avatar &&
                          trainer?.first_name + trainer?.last_name}
                      </Avatar>
                    </Tooltip>
                  )
                })}
              </Avatar.Group>
            ) : (
              "-"
            )}
          </div>
          {/* bottom-4 w-[89%] absolute */}
          <div className="">
            {item?.published_level >= 1 ? (
              <p>
                Start: <b>today</b>
              </p>
            ) : (
              <p>
                <b>Coming Soon</b>
              </p>
            )}
            <Button
              onClick={() =>
                navigate(`/skill/green-skills/${item?.green_skill_id}`)
              }
              block
            >
              Details
            </Button>
          </div>
        </div>
      </div>
    ))
  ) : (
    <div className="bg-white p-5 h-full">
      <Empty />
    </div>
  )
}
