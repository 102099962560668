import { create, SetState } from "zustand"
import { IStartupProduct } from "../users/types"

interface ModalState {
  isOpen: boolean
  open: () => void
  close: () => void
}

interface CommunityModalStore {
  newArticleModal: ModalState
  becomeAnExpertModal: ModalState
  editExpertBioModal: ModalState
  addNewSkillModal: ModalState
  addCompanyModal: ModalState
  editCompanyInfoModal: ModalState
  addEditCompanyProductModal: {
    isOpen: boolean
    product: IStartupProduct | null
    open: (product: IStartupProduct | null) => void
    close: () => void
  }
  editFounderBioModal: ModalState
  mentorApplicationModal: ModalState
  editMentorBioModal: ModalState
  calendarSettingsModal: ModalState
}

const createModalState = (
  set: SetState<CommunityModalStore>,
  modalName: keyof CommunityModalStore,
): ModalState => ({
  isOpen: false,
  open: () =>
    set((state) => ({ [modalName]: { ...state[modalName], isOpen: true } })),
  close: () =>
    set((state) => ({ [modalName]: { ...state[modalName], isOpen: false } })),
})

const useCommunityModalsStore = create<CommunityModalStore>((set) => ({
  // Expert modals
  newArticleModal: createModalState(set, "newArticleModal"),
  becomeAnExpertModal: createModalState(set, "becomeAnExpertModal"),
  editExpertBioModal: createModalState(set, "editExpertBioModal"),
  // Founder modals
  addNewSkillModal: createModalState(set, "addNewSkillModal"),
  addCompanyModal: createModalState(set, "addCompanyModal"),
  editCompanyInfoModal: createModalState(set, "editCompanyInfoModal"),
  addEditCompanyProductModal: {
    isOpen: false,
    product: null,
    open: (product) =>
      set((state) => ({
        addEditCompanyProductModal: {
          ...state.addEditCompanyProductModal,
          isOpen: true,
          product,
        },
      })),
    close: () =>
      set((state) => ({
        addEditCompanyProductModal: {
          ...state.addEditCompanyProductModal,
          isOpen: false,
          product: null,
        },
      })),
  },
  editFounderBioModal: createModalState(set, "editFounderBioModal"),
  // Mentor modals
  mentorApplicationModal: createModalState(set, "mentorApplicationModal"),
  editMentorBioModal: createModalState(set, "editMentorBioModal"),
  calendarSettingsModal: createModalState(set, "calendarSettingsModal"),
}))

export default useCommunityModalsStore
