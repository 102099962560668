import React, { useContext } from "react"
import Invoices from "./Invoices"
import { useInvoice } from "../../hooks/billing/useInvoice"
import { CustomerDetail } from "./CustomerDetail"
import { useCustomer } from "../../hooks/billing/useCustomer"
import { Result, Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { AccountContext } from "../../../../common/layout/PageLayout"
import { usePaymentMethod } from "../../hooks/billing/usePaymentMethod"
import { PaymentMethod } from "./PaymentMethod"
import { useCountries } from "../../hooks/useCountries"

export const BillingWrapper = () => {
  const account = useContext(AccountContext)
  const invoices = useInvoice()
  const customer = useCustomer()
  const country = useCountries()
  const paymentMethod = usePaymentMethod()
  return invoices.isLoading ||
    customer.isLoading ||
    (account && account.isLoading) ||
    !account?.data?.data?.customer_id ? (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  ) : customer.isErrorCreate ? (
    <Result
      status="500"
      title="500"
      subTitle="Sorry,something error with our system."
    />
  ) : (
    <div>
      <div className=" rounded-xl shadow-md bg-[#FAFAFA] mb-5 flex lg:flex-nowrap flex-wrap gap-5 p-5">
        <PaymentMethod
          data={paymentMethod.data?.data}
          defaultPayment={
            customer.data?.invoice_settings?.default_payment_method?.id
          }
          handleDefault={customer.update.handleUpdateDefaultPayment}
          update={paymentMethod.update}
          remove={paymentMethod.delete}
          countries={country}
          add={paymentMethod.add}
        />
        <CustomerDetail data={customer?.data} update={customer.update} />
      </div>
      <Invoices data={invoices.data?.data} isLoading={invoices.isLoading} />
    </div>
  )
}
