import React from "react"
import { Card, Input, Select, Tag } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { useSector } from "../../hooks/commons/useSectorList"

interface Props {
  handleSearch: (...args: any[]) => void
  defaultValue?: string
  handleAddSector: (value: { label: string; value: string }) => void
  selectedSector?: { label: string; value: string }[]
  handleRemoveSector: (value: string) => void
}

export const FilterJobBoard: React.FC<Props> = ({
  handleSearch,
  defaultValue,
  handleAddSector,
  selectedSector,
  handleRemoveSector,
}) => {
  const sector = useSector()

  return (
    <Card className="bg-[#FAFAFAFA] shadow-md rounded-xl">
      <div className="flex gap-3 flex-wrap md:flex-nowrap">
        <div
          className="flex w-full md:w-2/3"
          style={{ borderBottom: "1px solid #D0DDE5" }}
        >
          <SearchOutlined />
          <Input
            bordered={false}
            defaultValue={defaultValue}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="search by job role name"
          />
        </div>
        <div className="bg-grey-select w-full md:w-1/2">
          <Select
            onPopupScroll={sector.handleScroll}
            placeholder="Filter by Sector"
            onDropdownVisibleChange={sector.handleClear}
            notFoundContent={"No sector found"}
            showSearch
            filterOption={false}
            onSearch={sector.handleSearch}
            loading={sector.isLoading}
            options={sector.dataList.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            // @ts-ignore
            onSelect={(_, opts) => handleAddSector(opts)}
            value={null}
            className="w-full md:w-1/2"
          ></Select>
        </div>
      </div>
      {selectedSector && selectedSector?.length > 0 && (
        <div className="mt-3 flex gap-2">
          <div>Sector:</div>
          <div>
            {selectedSector.map((sector) => (
              <Tag
                id={sector.value}
                key={sector.value}
                closable
                className=" me-2"
                onClose={() => handleRemoveSector(sector.value)}
              >
                {sector.label}
              </Tag>
            ))}
          </div>
        </div>
      )}
    </Card>
  )
}
