import React from "react"
import { Progress, Tooltip } from "antd"
import { OtherJobRole } from "../../../../assets/images"
import { JobRole } from "../../types/jobRole"
interface Props {
  data: JobRole
  observ?: (node: Element | null) => void
  onClick: () => void
}

export const CardJobBoard: React.FC<Props> = ({ data, observ, onClick }) => {
  return (
    <div
      ref={observ}
      className=" shadow-md rounded-lg py-3 px-5 bg-white cursor-pointer"
      onClick={onClick}
    >
      <div className="flex items-center gap-5 h-full">
        <div className="w-16">
          <img
            className=" w-full h-full object-contain"
            src={data?.job_sector?.image || OtherJobRole}
            alt="job"
          />
        </div>
        <div className="flex gap-3 flex-col justify-between w-full">
          <p className="m-0 p-0 uppercase tracking-widest text-xs text-[#966F02]">
            {data?.job_sector?.name || "unknown"}
          </p>
          <p className="m-0 p-0 capitalize font-semibold">
            {data.name || "unknown"}
          </p>
          <Tooltip
            placement="bottom"
            title={`You have ${helperProgress(
              data.green_skills,
            )}% of recommended Green Skills`}
          >
            <Progress
              // status="active"
              strokeColor={"#86C769"}
              size={"small"}
              percent={helperProgress(data.green_skills)}
              className="w-full"
            />
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

const helperProgress = (
  data?: { id: string; name: string; level: number; level_passed: number }[],
): number => {
  if (!data) return 0
  const totalLevel = data.reduce((acc, cur) => acc + cur.level, 0)
  const totalLevelPassed = data.reduce(
    (acc, cur) =>
      acc + (cur.level_passed > cur.level ? cur.level : cur.level_passed),
    0,
  )
  const a = (totalLevelPassed / totalLevel) * 100

  return Math.round(a)
}
