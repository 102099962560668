import React from "react"
import ListFounderFilter from "./ListFounderFilter"
import CardProfile from "./CardProfile"
import { FounderResponse } from "../types/response"
import { Image, Skeleton } from "antd"
import { Moon } from "../../../../assets/images"

interface ListFounderContentProps {
  founderData?: any
  queryFounder?: any
  referenceData?: any
}

const ListFounderContent: React.FC<ListFounderContentProps> = ({
  founderData,
  queryFounder,
  referenceData,
}) => {
  return (
    <div className="space-y-5">
      <div>
        <ListFounderFilter
          queryFounder={queryFounder}
          referenceData={referenceData}
        />
      </div>

      <div
        className={`${
          !founderData?.listLoading && !founderData?.listData
            ? ""
            : "grid md:grid-cols-3 gap-5 mx-3 md:mx-0"
        } `}
      >
        {founderData?.listLoading ? (
          Array.from({ length: 6 }, (_: any, index: number) => (
            <div
              key={index}
              className="py-3 px-4 bg-white-custom rounded-xl shadow-custom-sm space-y-3 hover:scale-[1.02] transition-all cursor-pointer"
            >
              <div className="flex items-center justify-center flex-col gap-y-3 w-full ">
                <Skeleton.Avatar size={120} active={true} />
                <div className="flex flex-col items-center gap-2 justify-center w-full ">
                  <Skeleton.Input block active={true} />
                  <Skeleton.Input block active={true} />
                </div>

                <div className="px-5 py-3 pt-5 bg-primary-bg w-full rounded-xl">
                  <Skeleton paragraph={{ rows: 2 }} active={true} />
                </div>
              </div>
            </div>
          ))
        ) : founderData?.listData?.length === 0 || !founderData?.listData ? (
          <div className="w-full mx-auto rounded-lg bg-white flex justify-center text-center  py-6">
            <div>
              <Image src={Moon} alt="moon image" preview={false} width={100} />
              <p className="my-4">No Data</p>
            </div>
          </div>
        ) : (
          founderData?.listData?.map(
            (founderItem: FounderResponse, index: number) => (
              <CardProfile key={index} founderItem={founderItem} />
            ),
          )
        )}
      </div>
    </div>
  )
}

export default ListFounderContent
