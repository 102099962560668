import React from "react"
import { HomeOutlined } from "@ant-design/icons"
import { useParams } from "react-router-dom"
import { PageContent, PageHeader } from "../../../../common/layout"
import SolutionDetailContent from "../components/SolutionDetailContent"
import SolutionDetailRightContent from "../components/SolutionDetailRightContent"
import { useDetailSolution } from "../hooks/useSolution"
import { Image, Skeleton } from "antd"

interface DetailSolutionProps {}

const DetailSolution: React.FC<DetailSolutionProps> = () => {
  const { id } = useParams()

  const { detail, layout } = useDetailSolution(id!)

  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      path: "/community/member",
      title: "Community",
    },
    {
      path: "/community/solution",
      title: "Climate Solutions",
    },
    {
      title: detail?.detailStartup?.name ?? "Unknown",
    },
  ]

  return (
    <PageContent
      header={
        <PageHeader
          breadcrumbItems={items}
          showBack={true}
          // eslint-disable-next-line
          children={
            detail?.detailLoadingStartup ? (
              <div className="flex gap-x-5">
                <div>
                  <Skeleton.Avatar size={100} shape="circle" />
                </div>

                <div className="space-y-3 flex flex-col w-full">
                  <Skeleton.Input active={true} block />
                  <Skeleton active={true} />
                </div>
              </div>
            ) : (
              <div className="flex gap-x-5">
                <div>
                  <Image
                    className="object-cover rounded-full"
                    src={detail?.detailStartup?.logo}
                    alt="profile"
                    width={100}
                    height={100}
                    preview={false}
                  />
                </div>

                <div className="space-y-3">
                  <h1>{detail?.detailStartup?.name}</h1>
                  <p
                    className={`m-0 leading-6 ${
                      layout?.showMoreDesc ? "line-clamp-2" : ""
                    }`}
                  >
                    {detail?.detailStartup?.long_description}
                  </p>
                  <span
                    className="text-secondary cursor-pointer"
                    onClick={() =>
                      layout?.setShowMoreDesc(!layout?.showMoreDesc)
                    }
                  >
                    {layout?.showMoreDesc ? "Show more" : "Show less"}
                  </span>
                </div>
              </div>
            )
          }
        />
      }
      content={
        <SolutionDetailContent
          detail={detail?.detailStartup}
          loading={detail?.detailLoadingStartup}
        />
      }
      contentRightSidebar={
        <SolutionDetailRightContent
          detail={detail?.detailStartup}
          loading={detail?.detailLoadingStartup}
        />
      }
    />
  )
}

export default DetailSolution
