import { AutoComplete, Empty, Input, Spin } from "antd"
import React, { useState } from "react"
import { type UseInfiniteQueryResult } from "react-query"
import { type AxiosResponse } from "axios"
// import { type ApiResponsePaginatedInfinite } from "../../types/ApiResponse"
import { type Item } from "../../hooks/useReferences"
import { type ApiResponsePaginatedInfinite } from "../types/ApiResponse"
import { DownOutlined } from "@ant-design/icons"

interface InfiniteAutocompleteProps {
  useDataHook: (
    searchParam: string,
  ) => UseInfiniteQueryResult<
    AxiosResponse<ApiResponsePaginatedInfinite<Item>, any>,
    any
  >
  onSelect?: (value: string, option: Item | undefined | null) => void
  selectedValue?: Item
  emptyDescription?: string
  className?: string
  placeholder?: string
  style?: React.CSSProperties
  notFoundContent?: React.ReactNode
  fieldNames?: { label: string; value: string }
  onClearValue?: null | undefined
  disabled?: boolean
}

export const InfiniteAutoComplete: React.FC<InfiniteAutocompleteProps> = ({
  useDataHook,
  onSelect,
  selectedValue,
  emptyDescription,
  className,
  placeholder,
  style,
  notFoundContent,
  fieldNames,
  onClearValue = undefined,
  disabled,
}) => {
  const [searchParam, setSearchParam] = useState<string>("")
  const [options, setOptions] = useState<Item[] | undefined>([])
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useDataHook(searchParam)

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget
    if (scrollHeight - scrollTop === clientHeight) {
      if (hasNextPage) {
        fetchNextPage()
      }
    }
  }

  React.useEffect(() => {
    if (data?.pages) {
      setOptions(
        () => data?.pages?.flatMap((page) => page.data.data.list ?? []),
      )
    }
  }, [data?.pages])

  React.useEffect(() => {
    setSearchParam(selectedValue?.name ?? "")
  }, [selectedValue])

  return (
    <AutoComplete
      disabled={disabled}
      fieldNames={fieldNames ?? { label: "name", value: "id" }}
      style={style}
      className={className ?? "!w-full cursor-pointer"}
      placeholder={placeholder ?? "Search"}
      options={options ?? []}
      onPopupScroll={handleScroll}
      onSearch={(value) => {
        setSearchParam(value)
      }}
      onClear={() => {
        onSelect && onSelect("", onClearValue)
        setSearchParam("")
      }}
      allowClear
      onSelect={onSelect}
      defaultValue={selectedValue?.name || searchParam}
      value={selectedValue?.name || searchParam}
      suffixIcon={<DownOutlined />}
      notFoundContent={
        isFetchingNextPage || isLoading ? (
          <div className="flex items-center justify-center p-6">
            <Spin />
          </div>
        ) : (
          notFoundContent ?? (
            <Empty
              description={emptyDescription ?? "Sorry, we've got nothing"}
            />
          )
        )
      }
    >
      <Input
        readOnly
        className="read-only cursor-pointer !bg-grey-select"
        suffix={
          <DownOutlined className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none text-xs" />
        }
      />
    </AutoComplete>
  )
}
