import { useQuery, useQueryClient } from "react-query"
import { handlingError } from "../../../utils/handling"
import { useMemo } from "react"
import instance from "../../../config/config"
import { message } from "antd"

const useRecruiterRequest = (userId) => {
  const queryClient = useQueryClient()

  const queryKeyRequest = useMemo(
    () => [
      "job-request-recruiter",
      {
        // status: "pending",
      },
    ],
    [],
  )

  const fetchListRequest = async () => {
    try {
      const { data } = await instance.get(
        `/finders/request-connection/${userId}`,
        {
          params: {
            // status: "pending",
          },
        },
      )
      return data
    } catch (error) {
      return handlingError(error?.response?.data?.message, error)
    }
  }

  const { data: listRequest, isLoading: isLoadingRequest } = useQuery(
    queryKeyRequest,
    fetchListRequest,
  )

  const handleResponseRequest = async (status, id) => {
    try {
      const { data } = await instance.patch(
        `/finders/talents/request-connect/id/${id}`,
        {
          request_status: status,
        },
      )

      queryClient.invalidateQueries(["job-request-recruiter"])
      message.success(`You ${status} request!`)

      return data
    } catch (error) {
      return handlingError(error?.response?.data?.message, error)
    }
  }

  return {
    listRequest,
    isLoadingRequest,
    handleResponseRequest,
  }
}

export default useRecruiterRequest
