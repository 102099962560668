import { useMemo, useState } from "react"
import instance from "../../../config/config"
import { useQuery, useQueryClient } from "react-query"
// import { handlingError } from "../../../utils/handling";
import { useMutation } from "react-query"
import { useCalculateProgress } from "../../jobGalaxy/hooks/useCalculateProgress"

export const useLearning = (id, user_id, levelID) => {
  const queryKeySingle = useMemo(() => ["skill-levels"], [])

  const [activeTab, setActiveTab] = useState(0)

  const fetchDetailLearnSingleSkill = async () => {
    try {
      const { data } = await instance.get(`/paid-skill/${user_id}/${id}`)
      return data
    } catch (error) {
      throw error?.response?.data?.message
    }
  }

  const { data, isLoading } = useQuery(
    queryKeySingle,
    fetchDetailLearnSingleSkill,
    {
      retry: false,
    },
  )

  const skill = useMemo(() => data?.data, [data])
  const isLoadingSkill = useMemo(() => isLoading, [isLoading])

  let highestCurrentLevel = 0
  let highestCurrentModule = 0

  skill?.levels?.forEach((skill) => {
    skill?.levels?.forEach((level, index) => {
      const currentLevel = level?.user_track?.current_level
      const currentModule = level?.user_track?.current_module

      if (index === 0) {
        highestCurrentLevel = currentLevel
        highestCurrentModule = currentModule
      } else if (index > 0) {
        if (currentModule > 0) {
          highestCurrentLevel = currentLevel
          highestCurrentModule = currentModule
        } else if (
          currentModule === highestCurrentModule &&
          currentLevel > highestCurrentLevel
        ) {
          highestCurrentLevel = currentLevel
        }
      }
    })
  })

  // LEVEL---------

  const queryKeyPaidModule = useMemo(
    () => [
      "paid-module-levels",
      {
        user_id,
        id,
        level: levelID,
      },
    ],
    [user_id, id, levelID],
  )

  const fetchPaidModule = async () => {
    try {
      const { data } = await instance.get(
        `/paid-skill/module/${user_id}/${levelID}`,
      )
      return data
    } catch (error) {
      return error
    }
  }

  const { data: levelModules, isLoading: isLoadingModules } = useQuery(
    queryKeyPaidModule,
    fetchPaidModule,
    {
      enabled: !!skill,
    },
  )

  const modules = useMemo(() => levelModules?.data, [levelModules])
  // Job Plan
  const queryKeyJobPlan = useMemo(() => ["job-plan"], [])

  const fetchJobPlan = async () => {
    try {
      const { data } = await instance.get(`/jobs/plan/${user_id}`)
      return data
    } catch (error) {
      return error
    }
  }

  const { data: jobPlan, isLoading: isLoadingJobPlan } = useQuery(
    queryKeyJobPlan,
    fetchJobPlan,
    {
      enabled: !!skill,
    },
  )

  const jobCareerPlan = useMemo(() => jobPlan?.data, [jobPlan])
  const isLoadingJobCareerPlan = useMemo(
    () => isLoadingJobPlan,
    [isLoadingJobPlan],
  )

  // POST

  const queryClient = useQueryClient()

  const createData = async (data) => {
    try {
      const level_id = await skill?.levels[activeTab]?.id
      const response = await instance.post(
        `/learn/assessment/${id}/levels/${level_id}`,
        data,
      )
      return response.data
    } catch (error) {
      // handlingError(error?.response?.data?.message, () => {});
      throw new Error(error)
    }
  }

  const mutation = useMutation(createData, {
    onSuccess: () => {
      queryClient.invalidateQueries(["skill-levels"])
    },
  })

  const create = async (data) => {
    try {
      await mutation.mutateAsync(data)
    } catch (error) {
      console.error(error)
    }
  }

  const updateData = async (data) => {
    try {
      const response = await instance.patch(
        `/paid-skill/user-track/${user_id}/${levelID}`,
        data,
      )
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  }
  const mutationUpdateAssesment = useMutation(updateData, {
    onSuccess: () => {
      queryClient.invalidateQueries(["skill-levels"])
      queryClient.invalidateQueries(["paid-module-levels"])
    },
  })

  const update = async (data) => {
    try {
      await mutationUpdateAssesment.mutateAsync(data)
    } catch (error) {
      console.error(error)
    }
  }

  const userAssessment = async (data) => {
    try {
      const response = await instance.post(
        `/paid-skill/assesment/${user_id}/${levelID}`,
        data,
      )
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  }
  const mutationUserAssesment = useMutation(userAssessment, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["skill-levels"])
      queryClient.invalidateQueries(["paid-module-levels"])
      return data
    },
  })

  const addAsessment = async (data) => {
    try {
      const response = await mutationUserAssesment.mutateAsync(data)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const handleChangeTabs = (tab, index) => {
    tab.isLocked ? setActiveTab(activeTab) : setActiveTab(index)
  }

  // calculate
  const { overalPresentase } = useCalculateProgress(jobCareerPlan?.job)

  return {
    skill,
    isLoadingSkill,
    highestCurrentLevel,
    highestCurrentModule,
    modules,
    isLoadingModules,
    create,
    activeTab,
    setActiveTab,
    handleChangeTabs,
    overalPresentase,
    jobCareerPlan,
    isLoadingJobCareerPlan,
    update,
    addAsessment,
  }
}
