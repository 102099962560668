import { Empty, Image } from "antd"
import { ClockCircleOutlined, StarOutlined } from "@ant-design/icons"
import dayjs from "dayjs"
import { DefaultCompanyLogo } from "../../../assets/images"
import { useNavigate } from "react-router-dom"

interface JRProps {
  listJobRecomendation: any[]
}

const JobRecommendedComponent: React.FC<JRProps> = ({
  listJobRecomendation,
}) => {
  const navigate = useNavigate()

  if (!listJobRecomendation || listJobRecomendation.length === 0) {
    return <Empty description="No recommended jobs." />
  }

  return (
    <div className="px-6 h-80 overflow-y-scroll">
      {listJobRecomendation?.map((item: any, index: number) => (
        <div
          className="bg-white mb-3 rounded-xl shadow-lg"
          key={index}
          style={{ borderBottom: "1px solid #DEDEDE" }}
        >
          <div className="flex items-center gap-5 px-5">
            {item?.company?.logo ? (
              <Image
                src={item?.company?.logo ? item?.company?.logo : ""}
                preview={false}
                alt={`logo-${item?.company_name}`}
                //  height={80}
                width={50}
              />
            ) : (
              <div className={`mt-4`} style={{ height: "80px" }}>
                <DefaultCompanyLogo />
              </div>
            )}

            <div className="space-y-2">
              <p className="m-0 p-0 text-base capitalize">
                <span
                  className="text-secondary hover:font-bold cursor-pointer"
                  onClick={() =>
                    navigate(`/job/companies/${item?.company?.id}`)
                  }
                >
                  {item?.company?.name}{" "}
                </span>
                posted a new
                <span
                  className="text-secondary hover:font-bold cursor-pointer"
                  onClick={() => navigate(`/job/board/${item?.job?.id}`)}
                >
                  {" "}
                  {item?.job?.name}
                </span>{" "}
                job
              </p>
              <div className="flex items-center gap-7">
                <div className="flex gap-2 items-center">
                  <ClockCircleOutlined />
                  <p className="m-0 p-0 capitalize">
                    {dayjs(item?.job?.created_at).format("ddd D MMMM YYYY")}
                  </p>
                </div>
                <div className="flex gap-2 items-center">
                  <StarOutlined />
                  <p className="m-0 p-0 capitalize">{item?.job_from}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default JobRecommendedComponent
