/* eslint-disable react/prop-types */
import React from "react"
import html2canvas from "html2canvas"
import { jsPDF } from "jspdf"
import { FilePdfOutlined } from "@ant-design/icons"
import { Button } from "antd"

const GenericPdfDownloader = ({ rootElementId, downloadFileName, loading }) => {
  const downloadPdfDocument = () => {
    const input = document.getElementById(rootElementId)
    html2canvas(input, { useCORS: true, scale: 4 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 100)
      const pdf = new jsPDF("p", "mm", "a4")
      // pdf.addImage(imgData, 'JPEG', 0, 8, 210, 290);
      pdf.addImage(imgData, "JPEG", 0, 0, 210, 297)
      pdf.save(`${downloadFileName}.pdf`)
    })
  }

  return (
    <Button
      disabled={loading}
      onClick={downloadPdfDocument}
      className={`cursor-pointer hover:drop-shadow-lg bg-gray-200 px-3 py-1 border-none rounded-full flex items-center text-xs hover:text-backdrop`}
      type={`text`}
    >
      <FilePdfOutlined /> Download
    </Button>
  )
}

export default GenericPdfDownloader
