import React, { useState } from "react"

/**
 * RadioButton Component
 *
 * @param {object} props - Component props
 * @param {string} props.defaultVal - The default value for the RadioButton (required)
 * @param {void} props.handleChange - The default value for the RadioButton (required)
 * @param {number} props.size - The size of the RadioButton (optional)
 * @param {string} props.fill - The fill color of the selected RadioButton (optional, default: "#D4F5C5")
 * @param {string} props.stroke - The stroke color of the RadioButton (optional, default: "#36505E")
 * @param {number} props.strokeWidth - The stroke width of the RadioButton (optional, default: 1)
 * @param {string} props.fillNone - The fill color of the unselected RadioButton (optional, default: "none")
 * @param {ReactNode} props.children - The content of the RadioButton
 * @param {string} props.className - The className RadioButton (optional)
 * @param {string} props.direction - The direction content of the RadioButton (optional, default: "row". accept row and column)
 *
 * @returns {JSX.Element} Rendered RadioButton component
 */
const RadioButton = (props) => {
  const [defaultVal, setDefaultVal] = useState(props.defaultVal)
  const renderChildren = React.Children.map(props.children, (child) => {
    if (child.props.name === defaultVal) {
      return (
        <div
          className={`flex gap-2 cursor-pointer items-center ${props.className}`}
        >
          <svg
            width={props?.size || 15}
            height={props?.size || 15}
            viewBox={`0 0 ${props?.size || 15} ${props?.size || 15}`}
          >
            <circle
              cx={props?.size || 15 / 2}
              cy={props?.size || 15 / 2}
              r={(props?.size || 15 / 2) - (props?.strokeWidth || 1)}
              fill={props?.fill || "#D4F5C5"}
              stroke={props?.stroke || "#36505E"}
              strokeWidth={props?.strokeWidth || 1}
            />
          </svg>
          {React.cloneElement(child)}
        </div>
      )
    }
    return (
      <div
        onClick={() => {
          props?.handleChange(child.props.name)
          setDefaultVal(child.props.name)
        }}
        className={`flex gap-2 cursor-pointer items-center ${props.className}`}
      >
        <svg
          width={props?.size || 15}
          height={props?.size || 15}
          viewBox={`0 0 ${props?.size || 15} ${props?.size || 15}`}
        >
          <circle
            cx={props?.size || 15 / 2}
            cy={props?.size || 15 / 2}
            r={(props?.size || 15) / 2 - (props?.strokeWidth || 1)}
            fill={props?.fillNone || "none"}
            stroke={props?.stroke || "#36505E"}
            strokeWidth={props?.strokeWidth || 1}
          />
        </svg>
        {child}
      </div>
    )
  })

  return (
    <div
      className={`flex  ${
        props.direction === "column" ? " flex-col" : "flex-row"
      } gap-5`}
    >
      {renderChildren}
    </div>
  )
}

export default RadioButton
