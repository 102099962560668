/* eslint-disable */

import { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { instanceV2 } from "../../../../config/config"
import { AxiosResponse } from "axios"
import { message } from "antd"

export type Education = {
  id: string
  institution: {
    name: string
    id: string
    image: string
  }
  degree: string
  field_of_study: string
  online: boolean
  start_at: string
  end_at: string
  isEdit?: boolean
}

export type OtherInstitution = {
  name: string
  id: string
  image: string
}

export type Common = {
  id: string
  name: string
}

export const useEducation = () => {
  const [tempData, setTempData] = useState<Education[]>([])
  const queryClient = useQueryClient()
  const handleAddDown = () => {
    const temp: Education = {
      id: "",
      institution: {
        name: "",
        id: "",
        image: "",
      },
      field_of_study: "",
      online: false,
      start_at: "",
      end_at: "",
      degree: "",
      isEdit: true,
    }
    setTempData((prev) => [...prev, temp])
  }

  const handleAddUp = () => {
    const temp: Education = {
      id: "",
      institution: {
        name: "",
        id: "",
        image: "",
      },
      field_of_study: "",
      online: false,
      start_at: "",
      end_at: "",
      degree: "",
      isEdit: true,
    }
    setTempData((prev) => [temp, ...prev])
  }

  const updateTempData = (index: number, data: Education) => {
    const temp = [...tempData]
    temp[index] = data
    setTempData(temp)
  }

  const { data: edu, isFetching: loadingEdu } = useQuery("education", () =>
    instanceV2.get<AxiosResponse<Education[]>>("/accounts/educations"),
  )

  useEffect(() => {
    if (edu?.data?.data) {
      setTempData(edu?.data?.data || [])
    }
  }, [edu?.data?.data])

  const { mutateAsync: removeEdu, isLoading: loadingremoveEdu } = useMutation({
    mutationFn: async (id: string) => {
      try {
        const res = await instanceV2.delete(`/accounts/educations/id/${id}`)
        return res
      } catch (error) {
        throw error
      }
    },
    mutationKey: "account-remove-education",
  })

  const onRemove = async (val: string) => {
    message.open({
      key: "remove-education",
      content: "removing...",
      type: "loading",
    })
    try {
      const response = await removeEdu(val)
      if (response) {
        queryClient.invalidateQueries(["account-cv"]).then(() => {
          setTempData((prev) => prev.filter((item) => item.id !== val))
          message.destroy()
        })
      }
    } catch (error: any) {
      message.open({
        key: "remove-education",
        content: error?.response?.data?.data || "Internal server error",
        type: "error",
      })
    }
  }

  const onRemoveTemp = (val: number) => {
    setTempData((prev) => prev.filter((_, index) => index !== val))
  }

  return {
    tempData,
    updateTempData,
    handleAddDown,
    handleAddUp,
    loadingEdu,
    onRemove,
    loadingremoveEdu,
    onRemoveTemp,
  }
}
