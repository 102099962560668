import React from "react"
import { HomeOutlined } from "@ant-design/icons"
import { useParams } from "react-router-dom"
import { PageContent, PageHeader } from "../../../../../common/layout"
import MentorProfileContent from "../components/MentorProfileContent"
import { useProfile } from "../../../../missionControl/hooks/cv-builder/useProfile"
import { useDetailMentor } from "../hooks/useMentor"
import { useMentorReference } from "../hooks/useMentorReference"
import { useListMentorSchedule } from "../hooks/useMentorSchedule"

interface DetailMentorProps {}

const DetailMentor: React.FC<DetailMentorProps> = () => {
  const { id } = useParams()

  const { detail } = useDetailMentor(id!)

  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "Mentor",
    },
    {
      title:
        `${detail?.detailMentor?.first_name} ${detail?.detailMentor?.last_name}` ||
        "",
    },
  ]

  const { account, loadingAccount } = useProfile()

  const { areaData, domainData } = useMentorReference()

  const isMyProfile = account?.id === id

  return (
    <PageContent
      header={<PageHeader breadcrumbItems={items} showBack={true} />}
      content={
        <MentorProfileContent
          detail={detail?.detailMentor}
          loadingMentor={detail?.detailLoadingMentor}
          areaData={areaData?.listAreas}
          loadingArea={areaData?.listLoadingAreas}
          domainData={domainData?.listDomain}
          loadingDomain={domainData?.listLoadingDomain}
          handler={detail}
          isMyProfile={isMyProfile}
          loadingAccount={loadingAccount}
        />
      }
    />
  )
}

export default DetailMentor
