import { useContext, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import instance from "../../../config/config"
import { message } from "antd"
import { AccountContext } from "../../../common/layout/PageLayout"
import { errorMessage } from "../../../common/utils/utils"

export const useCart = () => {
  const queryClient = useQueryClient()

  const account = useContext(AccountContext)

  const [checkout, setCheckout] = useState([])
  const [duplicate, setDuplicate] = useState([])
  const [isOpenModal, setIsOpenModal] = useState(false)
  const handleChange = (val) => {
    if (checkout.length === 0) {
      setCheckout((prev) => [...prev, val])
    } else if (checkout.some((v) => v.id === val.id)) {
      setCheckout((prev) => prev.filter((item) => item.id !== val.id))
    } else {
      setCheckout((prev) => [...prev, val])
    }
  }

  const { mutate: removeOrder, isLoading: loadingRemoveOrder } = useMutation({
    mutationFn: (id) => {
      return instance.delete(`transactions/orders/${id}`)
    },
    mutationKey: "remove-order",
  })
  const handleRemoveOrder = (id) => {
    removeOrder(id, {
      onSuccess: () => {
        message.success("Success remove active order")
        queryClient.invalidateQueries(["carts"])
      },
      onError: (error) =>
        message.success(error?.response?.data?.data || "Internal server error"),
    })
  }

  const { mutate: proceed, isLoading: loadingProceed } = useMutation({
    mutationFn: (val) => {
      return instance.post(
        `transactions/checkouts?currency_code=${
          localStorage.getItem("currency_code") || "sgd"
        }`,
        val,
      )
    },
    mutationKey: "proceed-payment",
  })
  const handleProceed = (val) => {
    proceed(
      {
        customer_id: account?.data?.data?.customer_id,
        email: account?.data?.data?.email,
        name: `${account?.data?.data?.first_name} ${account?.data?.data?.last_name}`,
        data_products: val,
      },
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries(["carts", "account"])
          window.location.href = res.data.data.link
        },
        onError: (error) => {
          if (
            error?.response?.data?.message === "duplicated data owned product"
          ) {
            setDuplicate(
              checkout.filter((v) =>
                error?.response?.data?.data?.includes(v.product_id),
              ),
            )
            setIsOpenModal(true)
          } else {
            errorMessage(
              error?.response?.data?.data || "Internal server error",
              error,
            )
            setIsOpenModal(false)
          }
        },
      },
    )
  }
  return {
    checkout,
    setCheckout,
    handleChange,
    handleRemoveOrder,
    loadingRemoveOrder,
    handleProceed,
    loadingProceed,
    modal: { setIsOpenModal, isOpenModal, duplicate },
  }
}
