/* eslint-disable */

import { useContext } from "react"
import { useModalStore } from "../../../../stores"
import { Level, LevelsData } from "../../../skillGalaxy/types/common"
import { AccountContext } from "../../../../common/layout/PageLayout"
import { useMutation, useQueryClient } from "react-query"
import instance from "../../../../config/config"
import { errorMessage } from "../../../../common/utils/utils"

type DataTemp = Level & {
  price_per_level: string
  sharing_profit: number
  skill_name: string
  owner_id: string
  owner_stripe_id: string
  gs_skill_id: string
  currency_code: string
  rate: number
}

export const useJobRoleCart = (
  data?: {
    id: string
    level: number
    level_passed: number
    name: string
    detail: LevelsData
  }[],
) => {
  const openModal = useModalStore((state) => state.openModal)
  const account = useContext(AccountContext)
  const queryClient = useQueryClient()

  let finalLevels: DataTemp[] = []

  if (data) {
    for (let i = 0; i < data.length; i++) {
      const currentA = data[i]

      for (let j = 0; j < currentA?.detail?.levels?.length; j++) {
        const currentB: any = currentA?.detail.levels?.[j]
        currentB["price_per_level"] = currentA?.detail?.price_per_level
        currentB["sharing_profit"] = currentA?.detail?.school?.sharing_profit
        currentB["skill_name"] = currentA?.name
        currentB["owner_id"] = currentA?.detail?.school?.id
        currentB["owner_stripe_id"] =
          currentA?.detail?.school?.stripe_account_id
        currentB["gs_skill_id"] = currentA?.id
        currentB["currency_code"] =
          currentA.detail?.currency?.code ||
          localStorage.getItem("currency_code") ||
          "sgd"
        currentB["rate"] = currentA.detail?.currency?.exchange || 1
        finalLevels.push(currentB)
      }
    }
  }

  let requiredLevels: any[] = []

  if (data) {
    for (let i = 0; i < data.length; i++) {
      const curGS = data[i]
      const dataGsReq = finalLevels.filter(
        (v) =>
          v.gs_skill_id === curGS.id &&
          v.proficiency <= curGS.level &&
          v.proficiency > curGS.level_passed &&
          v.available_for_sale &&
          v.status_transaction === 0,
        //    &&
        //   v.gs_skill_id === curGS.id &&
        //   curGS.level_passed > v.proficiency
      )
      requiredLevels.push(...dataGsReq)
    }
  }

  const { mutate: add, isLoading } = useMutation({
    mutationFn: (val: any) => {
      return instance.post(`transactions/carts`, val)
    },
    mutationKey: "add-cart",
  })

  const handlePurchased = () => {
    if (!account?.data?.data) return openModal()
    const finalData = requiredLevels
      ?.filter((v) => v.status_transaction === 0)
      .map((item) => ({
        user_id: account?.data?.data?.id,
        product_owner_id: item?.owner_id,
        product_owner_id_stripe: item?.owner_stripe_id,
        product_id: item.id,
        product_name: item?.skill_name,
        product_id_stripe: item?.stripe?.product_id,
        price_id_stripe: item?.stripe?.price_id,
        price: item?.price_per_level,
        sharing_profit: item?.sharing_profit,
        image_url: item?.image_url,
        proficient: item?.proficiency,
      }))
    add(finalData, {
      onSuccess: () => {
        queryClient.invalidateQueries(["job-role-skill"])
        queryClient.invalidateQueries(["carts"])
      },
      onError: (err: any) => {
        errorMessage(err?.response?.data?.data, err)
      },
    })
  }

  return {
    requiredLevels,
    handlePurchased,
    isLoading,
  }
}
