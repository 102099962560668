import { Skeleton } from "antd"
import React from "react"
import Badge from "./Badge"
import { SolorTrophiesElement } from "../hooks/useAchievements"

interface Props {
  title: string
  groupCode:
    | "eco-commitment-honors"
    | "green-skills-honors"
    | "green-jobs-honors"
  data: SolorTrophiesElement[]
  isLoading: boolean
  titleRef?: React.RefObject<HTMLHeadingElement>
}

export const HonorsSection = React.forwardRef<HTMLDivElement, Props>(
  ({ title, groupCode, data, isLoading, titleRef }, ref) => {
    return (
      <div className="bg-white" ref={ref}>
        <div className="w-full  bg-[#FFEFA0] flex justify-between items-center px-5 py-4">
          <h4 ref={titleRef} className="">
            {title}
          </h4>
        </div>
        {isLoading ? (
          <Skeleton className="mt-4" active paragraph={{ rows: 2 }} />
        ) : (
          <div className="grid grid-cols-12 gap-5 py-6 mt-4 bg-white">
            {data?.map((badge) => (
              <div
                className="col-span-12 sm:col-span-6 md:col-span-3 lg:col-span-3"
                key={badge?.id}
              >
                <Badge
                  key={badge?.id}
                  name={badge?.name}
                  image_url={badge?.image_url}
                  description={badge?.description}
                  groupCode={groupCode}
                  // count={badge?.count}
                  isLocked={!badge?.is_earned}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    )
  },
)
