import React from "react"
import {
  ClockCircleOutlined,
  DollarCircleOutlined,
  CheckOutlined,
} from "@ant-design/icons"
import { Workshop, WorkshopMode } from "../types/workshops"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"
import { formatNumber } from "../../../utils/formatNumber"
import { Tag } from "antd"

interface WorkshopCardProps {
  workshop: Workshop
  observ?: (node: Element | null) => void
}

export const WorkshopCard: React.FC<WorkshopCardProps> = ({
  workshop,
  observ,
}) => {
  const navigate = useNavigate()
  return (
    <div
      ref={observ}
      className="col-span-1 shadow-xl cursor-pointer hover:shadow-2xl"
      onClick={() => navigate(`/skill/workshops/${workshop?.id}`)}
    >
      <div
        className="w-full rounded-t-xl p-4 flex relative justify-center flex-col "
        style={{
          backgroundImage: workshop?.cover_image
            ? `url(${workshop?.cover_image})`
            : `url(https://via.placeholder.com/150)`,
          height: "200px",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>

      <div
        className={`text-primary flex items-center font-bold gap-2 px-5 mt-1 ${
          workshop?.is_registered ? "" : "invisible"
        }`}
      >
        <CheckOutlined /> <div className="">I am registered</div>
      </div>

      <div className="px-5 py-1 pb-6">
        <h4 className="font-semibold !leading-normal truncate capitalize">
          {workshop?.title}
        </h4>
        <div className="golden-subtitle">{workshop?.school.name}</div>
        <div className="flex justify-between items-center mt-3">
          <div className="flex gap-x-2 items-center">
            {workshop?.currency?.logo ? (
              <img
                className=" w-4 h-4 rounded-full object-cover"
                src={workshop?.currency?.logo}
              />
            ) : (
              <DollarCircleOutlined className="text-[1.1rem]" />
            )}
            {workshop.mode === WorkshopMode.PRE_RECORDED &&
            workshop.is_replayable &&
            dayjs.utc().unix() >= dayjs(workshop.release_date).unix() ? (
              <div>
                {workshop?.replay_price === 0
                  ? "FREE"
                  : formatNumber(
                      workshop?.replay_price *
                        (workshop?.currency?.exchange || 1),
                    )}
              </div>
            ) : (
              <div>
                {workshop?.price === 0
                  ? "FREE"
                  : formatNumber(
                      workshop?.price * (workshop?.currency?.exchange || 1),
                    )}
              </div>
            )}
          </div>
          <div>
            {workshop?.mode === WorkshopMode.ON_DEMAND ? (
              <div className="flex gap-x-2">
                <ClockCircleOutlined className="text-lg" />
                <div>watch now</div>
              </div>
            ) : dayjs.utc().unix() >= dayjs(workshop.release_date).unix() &&
              !workshop.is_replayable ? (
              <div>
                <Tag className="">Past workshop</Tag>
              </div>
            ) : dayjs.utc().unix() >= dayjs(workshop.release_date).unix() &&
              workshop.is_replayable ? (
              <div className="flex gap-x-2">
                <ClockCircleOutlined className="text-lg" />
                <div>watch now</div>
              </div>
            ) : (
              <div className="flex gap-x-2">
                <ClockCircleOutlined className="text-lg" />
                {dayjs(
                  workshop?.release_date ?? workshop?.livestream_date,
                ).format("DD MMM YYYY, hh:mm A")}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
