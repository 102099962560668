import React from "react"
import { Skeleton } from "antd"

import {
  ImageAstroseed,
  ImageGreenNebula,
  ImageStartMaster,
  ImageTerraformer,
  ImageSolarExplorer,
} from "../../../assets/images"
import { useCart } from "../hooks/useCart"
import { SkillLevel } from "./SkillLevel"
import { LevelsData, Skill } from "../types/common"

const defaultLevelsData = [
  {
    id: "1",
    image: ImageAstroseed,
    name: "Astroseed",
    proficiency: 1,
  },
  {
    id: "2",
    image: ImageSolarExplorer,
    name: "Solar Explorer",
    proficiency: 2,
  },
  {
    id: "3",
    image: ImageTerraformer,
    name: "Terraformer",
    proficiency: 3,
  },
  {
    id: "4",
    image: ImageGreenNebula,
    name: "Green Nebula",
    proficiency: 4,
  },
  {
    id: "5",
    image: ImageStartMaster,
    name: "Star Master",
    proficiency: 5,
  },
]

const imageLevel = [
  ImageAstroseed,
  ImageSolarExplorer,
  ImageTerraformer,
  ImageGreenNebula,
  ImageStartMaster,
]

export const helperName = (level: number) => {
  switch (level) {
    case 1:
      return "Astroseed"
    case 2:
      return "Solar Explorer"
    case 3:
      return "Terraformer"
    case 4:
      return "Green Nebula"
    case 5:
      return "Star Master"
    default:
      return "Unknown"
  }
}

interface Props {
  data: LevelsData
  loading: boolean
  skillData: Skill
}

const SingleSkillPlan: React.FC<Props> = ({ data, loading, skillData }) => {
  const { handleAdd, isLoading: loadingAdd } = useCart()
  return (
    <div>
      {loading ? (
        <div className="p-5">
          <Skeleton active paragraph={{ rows: 7 }} />
        </div>
      ) : (
        <div className="mt-5">
          {data?.levels
            ? data?.levels?.map((level, index) => (
                <SkillLevel
                  key={index}
                  level={level}
                  levelsData={data}
                  image={imageLevel[level.proficiency - 1]}
                  handleAdd={handleAdd}
                  loadingAdd={loadingAdd}
                  levelNum={index + 1}
                  skillData={skillData}
                />
              ))
            : defaultLevelsData.map((level, index) => (
                <SkillLevel
                  key={index}
                  type="empty"
                  emptyLevel={level}
                  levelsData={data}
                  image={level?.image}
                  handleAdd={handleAdd}
                  loadingAdd={loadingAdd}
                  levelNum={index + 1}
                  skillData={skillData}
                />
              ))}
        </div>
      )}
    </div>
  )
}

export default SingleSkillPlan
