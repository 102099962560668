import {create} from 'zustand';

export enum Role {
  Professional = 'Professional',
  Expert = 'Expert',
  Mentor = 'Mentor',
  Founder = 'Founder',
}

// Define the store's state
type State = {
  role: Role;
  setRole: (role: Role) => void;
};

// Create the Zustand store
export const useRoleStore = create<State>((set) => ({
  role: Role.Professional, // default role
  setRole: (role: Role) => set(() => ({ role })),
}));
