/* eslint-disable */

import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { AxiosError, AxiosResponse } from "axios"
import { message } from "antd"
import instance, { instanceV2 } from "../../../config/config"
import { errorMessage } from "../../../common/utils/utils"
import { debounce } from "../../../utils/debounce"

export type Common = {
  id: string
  name: string
}

interface IParams {
  page: number
  limit?: number
  search?: string | null
}
// interface ErrorJobs {
//   error: boolean
//   code: number
//   message: string
//   data: any
// }

interface ISkillDropdown {
  data: {
    list: Common[]
    total_data: number
    pagination: { total_page: number; page: number; limit: number }
  }
}
export const useSkillDropdown = () => {
  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: 20,
    search: null,
  })

  const [dataList, setDataList] = useState<Common[]>([])
  const [total, setTotal] = useState<number>(0)

  const { data, isLoading } = useQuery(
    ["skills-dropdown", params],
    () => instanceV2.get<ISkillDropdown>(`/green-skills`, { params }),
    {
      onError: (err: any) =>
        errorMessage(err?.response?.data?.data || "internal server error", err),
    },
  )

  const handleClear = () => {
    if (params?.page !== 1 || params?.search !== "") {
      setParams((prevParams) => ({ ...prevParams, search: "", page: 1 }))
    }
  }

  const handleSearch = debounce((value: string) => {
    setParams((prev) => ({ ...prev, search: value, page: 1 }))
  }, 1000)

  const handleScroll = (e: React.UIEvent) => {
    const { target } = e
    if (target instanceof HTMLElement) {
      const maxScrollPosition =
        target.scrollHeight - target.clientHeight - target.clientHeight * 0.1

      if (
        target.scrollTop >= maxScrollPosition &&
        !isLoading &&
        total !== dataList.length
      ) {
        setParams((prev) => ({ ...prev, page: prev.page + 1 }))
      }
    }
  }

  const handleChageParams = (newParams: IParams) => {
    setParams((prev) => ({ ...prev, ...newParams }))
  }

  useEffect(() => {
    if (!isLoading) {
      if (params.page === 1) {
        setDataList(data?.data.data.list || [])
        setTotal(data?.data.data.total_data || 0)
      } else if (
        dataList?.[dataList?.length - 1]?.id !==
        data?.data.data.list?.[data?.data.data.list?.length - 1]?.id
      ) {
        setDataList((prev) => [...prev, ...(data?.data.data.list || [])])
        setTotal(data?.data.data.total_data || 0)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data.data.list])

  return {
    dataList,
    isLoading,
    handleChageParams,
    handleClear,
    handleScroll,
    params,
    handleSearch,
  }
}
