import React, { useEffect, useState, useMemo, useCallback } from "react"
import { Empty, Image, Spin, Tag } from "antd"
import {
  CaretUpFilled,
  CaretDownFilled,
  SafetyCertificateOutlined,
} from "@ant-design/icons"
import FiveCircleRate from "../../../common/components/FiveCircleRate"
import { GetRoadmap } from "../hooks/useRoadmapSkills"
import { debounce } from "../../../utils/debounce"
import { useNavigate } from "react-router-dom"
import { ApiResponse } from "../../../common/types/ApiResponse"

interface RoadMapTableProps {
  roadmapSkills: ApiResponse<GetRoadmap[]> | undefined
  isFetchingRoadmap: boolean
  updateRoadmapSkillOrder: (newOrder: string[]) => void
}

interface SingleRoadmapRowProps {
  record: GetRoadmap
  index: number
  moveRow: (fromIndex: number, toIndex: number) => void
}

const SingleRoadmapRow: React.FC<SingleRoadmapRowProps> = ({
  record,
  moveRow,
  index,
}) => {
  const navigate = useNavigate()
  const isAssessmentPassed = record?.levels?.some(
    (level: any) => level?.user_track?.is_assessment_passed === true,
  )
  return (
    <div className=" border-b-2 ring-1 ring-gray-300 bg-white pl-[18px] py-3 grid grid-cols-12 mt-4 shadow rounded-[10px] content-center justify-center">
      <div className="flex items-center gap-x-2 col-span-2">
        <div className="flex items-center justify-center cursor-pointer">
          <CaretUpFilled
            className="text-gray-300 "
            onClick={() => moveRow(index, index - 1)}
          />
          <CaretDownFilled
            className="text-gray-300 "
            onClick={() => moveRow(index, index + 1)}
          />
        </div>
        <Image
          src={record?.image}
          height={41}
          className="object-contain aspect-square hidden md:block"
          preview={false}
        />
      </div>

      <h4
        onClick={() =>
          navigate(`/dashboard/launchpad/single-mylaunchpad/${record?.id}`, {
            state: { lastIndexLearn: 0 },
          })
        }
        className="col-span-7 truncate cursor-pointer hover:text-secondary"
      >
        {record?.name}
      </h4>

      <div className="flex gap-1 items-center justify-end cursor-pointer col-span-3 gap-x-[18px]  px-3 ">
        <div className="flex gap-1 justify-self-end">
          {record?.is_request_verified ? (
            <FiveCircleRate
              defaultValue={record.total_completed_level}
              count={record.total_completed_level}
              color={"#D4F5C5"}
              disabled={true}
              circleSize={15}
            />
          ) : (
            record?.levels?.map((level, i) => (
              <FiveCircleRate
                key={i}
                defaultValue={level?.user_track?.current_level}
                count={1}
                color={
                  !level?.user_track?.is_assessment_passed ? "#fff" : "#D4F5C5"
                }
                disabled={true}
                circleSize={15}
              />
            ))
          )}
        </div>

        {record?.is_signature_exist && record?.is_request_verified ? (
          <div
            className="cursor-pointer"
            onClick={() =>
              navigate("/learnings/preview/certificate", {
                state: { skillData: record },
              })
            }
          >
            <Tag
              className={`bg-secondary text-white rounded-full w-32 justify-center flex items-center gap-2 px-3 py-1 text-[14px] font-thin`}
              style={{ border: 0 }}
            >
              <SafetyCertificateOutlined /> Verification
            </Tag>
          </div>
        ) : isAssessmentPassed && record?.is_signature_exist ? (
          <div
            className="cursor-pointer"
            onClick={() =>
              navigate("/learnings/preview/certificate", {
                state: { skillData: record },
              })
            }
          >
            <Tag
              className={`bg-backdrop text-white rounded-full flex  w-32 justify-center items-center gap-2 px-3 py-1 text-[14px] font-thin`}
            >
              <SafetyCertificateOutlined /> Certificate
            </Tag>
          </div>
        ) : (
          <div className="  w-32  gap-2 px-3 py-1" />
        )}
      </div>
    </div>
  )
}

export const RoadMapTable: React.FC<RoadMapTableProps> = ({
  roadmapSkills,
  isFetchingRoadmap,
  updateRoadmapSkillOrder,
}) => {
  const [data, setData] = useState<undefined | GetRoadmap[]>(undefined)

  useEffect(() => {
    setData(roadmapSkills?.data?.data)
  }, [roadmapSkills?.data?.data])

  // Debounce function call
  const debouncedUpdate = useMemo(
    () => debounce(updateRoadmapSkillOrder, 300),
    [updateRoadmapSkillOrder],
  )

  const moveRow = useCallback(
    (fromIndex: number, toIndex: number) => {
      if (data === undefined) {
        return
      }
      if (toIndex < 0 || toIndex >= data?.length) {
        return
      }
      // Optimistically update state
      const newData = data ? [...data] : []
      const item = newData.splice(fromIndex, 1)[0]
      newData.splice(toIndex, 0, item)
      // Update state
      setData(newData)
      // Call debounced update function
      debouncedUpdate(newData.map((record) => record?.id))
    },
    [data, debouncedUpdate],
  )

  return (
    <>
      {isFetchingRoadmap ? (
        <div className="flex items-center h-full justify-center">
          <Spin spinning={true} tip="Loading Skills" className="mt-6" />
        </div>
      ) : data && data?.length > 0 ? (
        data?.map((record, index) => (
          <SingleRoadmapRow
            key={index}
            record={record}
            index={index}
            moveRow={moveRow}
          />
        ))
      ) : (
        <Empty description="No skills found" />
      )}
    </>
  )
}
