import { Button, Image, Skeleton } from "antd"
import { Moon } from "../../../../assets/images"
import { BasicContainer } from "../../../../common/components/BasicBoxContainers/BasicContainer"
import FiveCircleRateTS from "../../../../common/components/FiveCircleRateTS"
import { useNavigate } from "react-router-dom"

interface SecondThirdSkillProps {
  showAddSkillButton?: boolean
  isLoading: boolean
  className?: string
  defaultValue: number
  secondValue: boolean | undefined
  isUndefined: boolean
  skillImage: string | undefined
  skillName: string | undefined
  skillId: string | undefined
  indexToLearn: number
  isVerified?: boolean
}

const LoadingSkeleton = ({ className }: { className?: string }) => (
  <BasicContainer
    className={`!bg-white flex flex-col min-h-[240px] gap-[30px]  min-w-[300px]  ${className}`}
  >
    <Skeleton.Image className="!w-[96px] !h-[96px] rounded-full shadow-md" />
    <Skeleton active={true} title={false} paragraph={{ rows: 2 }} />
  </BasicContainer>
)

const EmptySkill = ({
  className,
  showAddSkillButton,
}: {
  className?: string
  showAddSkillButton?: boolean
}) => {
  const navigate = useNavigate()
  return (
    <BasicContainer
      className={`!bg-white flex flex-col items-center flex-grow min-h-[240px] min-w-[300px] ${
        className ?? ""
      }`}
    >
      <Image
        src={Moon}
        preview={false}
        className="!h-[96px] object-contain mx-auto rounded-full "
      />
      <div className="mt-4 text-center">
        Your next Green Skill will appear here
      </div>
      {showAddSkillButton && (
        <Button
          onClick={() =>
            navigate(
              "/skill/green-skills?search=&price=low-to-high&availability=start-today&sort_rank=&viewType=cards&page=1&limit=20",
            )
          }
          className="mt-4 text-white bg-primary"
        >
          Browse Green Skills
        </Button>
      )}
    </BasicContainer>
  )
}

export const SecondThirdSkill: React.FC<SecondThirdSkillProps> = ({
  showAddSkillButton,
  isLoading,
  className,
  defaultValue,
  secondValue,
  isUndefined,
  skillImage,
  skillName,
  skillId,
  indexToLearn,
}) => {
  const navigate = useNavigate()

  if (isLoading) {
    return <LoadingSkeleton className={className} />
  }

  if (isUndefined) {
    return (
      <EmptySkill
        className={className}
        showAddSkillButton={showAddSkillButton}
      />
    )
  }

  return (
    <BasicContainer
      className={`!bg-white cursor-pointer hover:shadow-lg  transition-transform duration-300  flex-grow min-h-[240px] lg:max-w-[240px]  ${
        className ?? ""
      }`}
      onClick={() =>
        navigate(`/dashboard/launchpad/single-mylaunchpad/${skillId}`, {
          state: {
            lastIndexLearn: indexToLearn,
          },
        })
      }
    >
      <div className="flex flex-col items-center justify-center flex-grow ">
        <img
          src={skillImage ?? Moon}
          alt="skill"
          className="!h-[96px] object-contain"
        />
        <div className="mt-4 text-center">{skillName ?? "N/A"}</div>
      </div>
      <div className="flex items-center justify-between mt-4 gap-x-4">
        <div className="">Proficiency</div>
        <FiveCircleRateTS
          defaultValue={defaultValue ?? 0}
          secondValue={secondValue}
          disabled
          circleSize={15}
        />
      </div>
    </BasicContainer>
  )
}
