import { Avatar, Button, Image, Popover, Skeleton, Spin, Tag } from "antd"
import React from "react"
import { TrainerPopoverContent } from "../../skillGalaxy/components"
import PopContent from "../../jobGalaxy/components/PopContent"
import { renderLevelImage } from "./renderImageLevel"
import { ShoppingCartOutlined } from "@ant-design/icons"

interface InfoSkillCardProps {
  isCustomLoading: boolean
  companyData: any
  uniqueTrainers: any
  dataLevel: any
  skill: any
}

const InfoSkillCard: React.FC<InfoSkillCardProps> = ({
  isCustomLoading,
  companyData,
  uniqueTrainers,
  dataLevel,
  skill,
}) => {
  return (
    <div className="bg-secondaryBlue my-3 lg:m-0 lg:drop-shadow-xl rounded-2xl py-3 px-4">
      <div className="flex justify-left md:hidden ">
        {isCustomLoading ? (
          <Skeleton.Button block={true} active />
        ) : (
          <Tag
            className={`flex items-center gap-3 rounded-full px-3 border-primary bg-white py-1`}
          >
            <Image src={companyData?.logo} alt={`logo-greenskill`} width={25} />
            <p className={`m-0 text-sm`}>{companyData?.name}</p>
          </Tag>
        )}
      </div>

      <div className="flex justify-between items-center -mb-3">
        <p>TAUGHT BY</p>

        <div className={`flex items-center justify-between flex-wrap gap-2`}>
          <div className="hidden md:inline">
            {isCustomLoading ? (
              <Skeleton.Button block={true} active />
            ) : (
              <Tag
                className={`flex items-center gap-3 rounded-full px-3 border-primary bg-white py-1`}
              >
                <Image
                  src={companyData?.logo}
                  alt={`logo-greenskill`}
                  width={25}
                />
                <p className={`m-0 text-sm`}>{companyData?.name}</p>
              </Tag>
            )}
          </div>
          {isCustomLoading ? (
            <Spin />
          ) : uniqueTrainers ? (
            <Avatar.Group
              maxCount={2}
              maxStyle={{
                color: "#f56a00",
                backgroundColor: "#fde3cf",
              }}
            >
              {uniqueTrainers?.map((trainer: any, index: number) => (
                <Popover
                  content={<TrainerPopoverContent instructor={trainer} />}
                  placement="bottomLeft"
                  key={index}
                >
                  <Avatar
                    size={33}
                    src={trainer?.avatar ? trainer.avatar : undefined}
                  >
                    {!trainer?.avatar &&
                      `${trainer?.first_name}  ${trainer?.last_name}`}
                  </Avatar>
                </Popover>
              ))}
            </Avatar.Group>
          ) : (
            "-"
          )}
        </div>
      </div>

      <div className={`-mb-2 lg:mb-5 `}>
        {isCustomLoading ? (
          <Skeleton paragraph={{ rows: 1 }} active={true} />
        ) : dataLevel &&
          dataLevel?.data &&
          dataLevel?.data[0]?.levels
            .filter(
              (level: any) =>
                !level.purchased_by_user && level.available_for_sale,
            )
            .map((filteredLevel: any) => renderLevelImage(filteredLevel))
            .length > 0 ? (
          <>
            <p>AVAILABLE LEVELS</p>
            <div className={`flex items-center gap-3`}>
              {dataLevel?.data[0]?.levels
                .filter(
                  (level: any) =>
                    !level.purchased_by_user && level.available_for_sale,
                )
                .map((filteredLevel: any) => renderLevelImage(filteredLevel))}
            </div>

            <div className={`mb-4`}>
              {isCustomLoading ? (
                <Skeleton.Button block={true} active />
              ) : (
                <Popover
                  placement={`left`}
                  content={
                    <PopContent
                      skill={skill}
                      levels={
                        dataLevel &&
                        dataLevel?.data &&
                        dataLevel?.data[0]?.levels
                      }
                      data={dataLevel && dataLevel?.data && dataLevel?.data[0]}
                    />
                  }
                  trigger={"hover"}
                  arrow={false}
                >
                  <Button className={`mt-5`} type={`primary`} block>
                    <ShoppingCartOutlined /> Get missing levels
                  </Button>
                </Popover>
              )}
            </div>
          </>
        ) : null}
      </div>
    </div>
  )
}

export default InfoSkillCard
