import React, { useContext } from "react"
import { HomeOutlined, CalendarOutlined } from "@ant-design/icons"
import { PageContent, PageHeader } from "../../../../../common/layout"
import ListMentorContent from "../components/ListMentorContent"
import { Button } from "antd"
import { useListMentor } from "../hooks/useMentor"
import { useNavigate } from "react-router-dom"
import { useProfile } from "../../../../missionControl/hooks/cv-builder/useProfile"
import { storeModalBecomeMentor } from "../stores/storeModalBecomeMentor"
import ModalBecomeMentor from "../components/ModalBecomeMentor"
import {
  ITourContext,
  TourContext,
} from "../../../../../common/components/tour/TourProvider"
import DrawerMySchedule from "../components/DrawerMySchedule"
import { useStoreMySchedule } from "../stores/storeMentorSchedule"

interface MentorListProps {}

const MentorList: React.FC<MentorListProps> = () => {
  const navigate = useNavigate()
  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      path: "/community/member",
      title: "Community",
    },
    {
      title: "Mentors",
    },
  ]

  const { mentorData, queryMentor, referenceData } = useListMentor()

  const { account } = useProfile()
  const { openModal } = storeModalBecomeMentor()

  const {
    refs: { refBecomeAMentor },
  } = useContext(TourContext) as ITourContext

  const { openDrawer } = useStoreMySchedule()

  return (
    <div>
      <PageContent
        header={
          <PageHeader
            breadcrumbItems={items}
            title={"Career Mentors"}
            buttonComponent={
              <div className="flex items-center gap-x-3">
                <Button
                  className="flex items-center"
                  onClick={() => openDrawer()}
                >
                  <CalendarOutlined />
                  My Schedules
                </Button>
                <Button
                  type={`${
                    mentorData?.mentorStatus === "PENDING"
                      ? "default"
                      : mentorData?.mentorStatus === "APPROVED"
                        ? "primary"
                        : "primary"
                  }`}
                  ref={refBecomeAMentor}
                  disabled={mentorData?.mentorStatus === "PENDING"}
                  className={`${
                    mentorData?.mentorStatus === "APPROVED"
                      ? "bg-white text-backdrop border-primary"
                      : ""
                  }`}
                  onClick={() => {
                    if (mentorData?.mentorStatus === "APPROVED") {
                      navigate(
                        `/community/mentor/my-profile/${account?.id}/true`,
                      )
                    } else if (mentorData?.mentorStatus === "NOT REGISTERED") {
                      openModal()
                      // message.info("Under development")
                    }
                  }}
                >
                  {mentorData?.mentorStatus === "PENDING"
                    ? "Profile Pending"
                    : mentorData?.mentorStatus === "APPROVED"
                      ? "My Mentor Profile"
                      : "Become a Mentor"}
                </Button>
              </div>
            }
            needButton={true}
          />
        }
        content={
          <ListMentorContent
            mentorData={mentorData}
            queryMentor={queryMentor}
            referenceData={referenceData}
          />
        }
      />

      <ModalBecomeMentor />
      <DrawerMySchedule />
    </div>
  )
}

export default MentorList
