import React from "react"
import { Table } from "antd"
import PageSidebar from "../components/PageSidebar"
import { HomeOutlined } from "@ant-design/icons"
import { tableMapArr } from "../utils/rechargeStationTableData"
import { PageContent, PageHeader } from "../../../common/layout"
import { useProfile } from "../hooks/useProfile"

export default function RechargeStation() {
  const { account, loadingAccount, errorAccount } = useProfile()

  const columns = [
    {
      dataIndex: "description",
      width: "50%",
      render: (_, record) => (
        <div className="flex flex-col gap-y-3">
          <div className="text-backdrop text-semibold">
            {record?.description?.heading}
          </div>
          <div className="text-darkGrey font-thin text-sm">
            {record?.description?.subheading}
          </div>
        </div>
      ),
    },
    {
      dataIndex: "points",
      render: (_, record) => (
        <div className="flex flex-col gap-y-3">
          <div className="text-backdrop text-semibold">
            {record?.points?.heading}
          </div>
          <div className="text-darkGrey font-thin text-sm">
            {record?.points?.subheading}
          </div>
        </div>
      ),
    },
  ]

  const shouldHaveTitle = (name) => {
    return name === "Intro" ? (
      <span className="text-lg font-semibold  text-backdrop">
        How do I earn Points?
      </span>
    ) : null
  }

  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "Mission Control",
    },
    {
      title: "Galactic Achievement",
    },
    {
      path: "/dashboard/recharge-station",
      title: "Recharge Station",
    },
  ]

  const Content = () => {
    return (
      <div className="flex flex-col gap-y-2">
        {tableMapArr.map((item, index) => (
          <div key={index}>
            <div className="text-backdrop text-base leading-7 font-semibold">
              {item[0] !== "Intro" && item[0]}
            </div>
            <Table
              dataSource={item[1]}
              pagination={false}
              rowKey="id"
              title={() => shouldHaveTitle(item[0])}
              className={
                item[0] !== "Intro"
                  ? "points-table-hidden-header"
                  : "points-table"
              }
              columns={columns}
              bordered
            />
          </div>
        ))}
      </div>
    )
  }

  return (
    <>
      <PageContent
        content={<Content />}
        contentRightSidebar={<PageSidebar />}
        header={
          <PageHeader
            title={`Level Up, ${account?.data?.data?.first_name} ${account?.data?.data?.last_name}!`}
            isHeaderForAccountInfo
            breadcrumbItems={items}
            isLoading={loadingAccount}
            isError={errorAccount}
          />
        }
      />
    </>
  )
}
