import React, { useMemo } from "react"
import { RadialBar } from "@ant-design/plots"

interface Props {
  data: { name: string; percent: number; color: string }[]
  maxAngle: number
}

export const FitCart: React.FC<Props> = ({ data, maxAngle }) => {
  const config = useMemo(() => {
    return {
      width: 400,
      height: 244,
      autoFit: false,
      appendPadding: [50, 0, 50, 0],
      data: data,
      xField: "name",
      yField: "percent",
      maxAngle: maxAngle,
      tooltip: {
        formatter: (datum: any) => {
          return {
            name: datum.name,
            value: datum.percent.toFixed(2) + "%",
          }
        },
      },
      barBackground: {
        style: {
          fill: "white",
        },
      },
      radius: 0.9,
      innerRadius: 0.3,
      xAxis: false,
      barStyle: {
        lineCap: "round",
      },
      colorField: "name",
      minBarWidth: 14,
      maxBarWidth: 14,
      // @ts-ignore
      color: ({ name }) => {
        return data?.find((d) => d.name === name)?.color
      },
    }
  }, [])
  // @ts-ignore
  return <RadialBar {...config} />
}
