export const STARTUP_ENDPOINTS = {
  LIST: `startups`,
  DETAIL: (id: string) => `startups/${id}`,
  EDIT: (id: string) => `startups/${id}`,
}

export const STARTUP_PRODUCT_ENDPOINTS = {
  READ: (startup_id: string) => `startups/${startup_id}/products`,
  CREATE: (startup_id: string) => `startups/${startup_id}/products`,
  UPDATE: (startup_id: string, product_id: string) =>
    `startups/${startup_id}/products/${product_id}`,
  DELETE: (startup_id: string, product_id: string) =>
    `startups/${startup_id}/products/${product_id}`,
}
export const COMMONUSER_ENDPOINTS = {
  DETAIL: (id: string) => `accounts/basic/${id}`,
}
