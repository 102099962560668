import React from "react"
import { JobBoard } from "../../types/jobBoard"
import { Avatar, Button, Drawer, Progress, Typography } from "antd"
import { Screening } from "../../../../assets/images"
import { avatarImgs } from "../../../../assets/avatar_images"
import { useNavigate } from "react-router-dom"
import { DetailQuestion } from "./DetailQuestion"
import { isMobile } from "react-device-detect"

interface Props {
  data: JobBoard
  drawer: {
    isApply: boolean
    setIsApply: React.Dispatch<React.SetStateAction<boolean>>
  }
  stage: {
    stageApply: number
    setStageApply: React.Dispatch<React.SetStateAction<number>>
  }
  helper: {
    hasEmptyStringValue: (obj: any) => boolean
  }
  user: any
  action: {
    handlePassedStrength: () => void
    handleScreening: (val: any) => void
  }
  disqualify: boolean
  applyLoad: boolean
}
export const DetailDrawerApply: React.FC<Props> = ({
  drawer,
  stage,
  helper,
  user,
  action,
  disqualify,
  applyLoad,
  data,
}) => {
  const navigate = useNavigate()
  return (
    <Drawer
      open={drawer.isApply}
      onClose={() => drawer.setIsApply(false)}
      width={isMobile ? "80%" : "40%"}
      closable={false}
      title={
        <div className=" w-[400px] truncate">
          Applying for <span className=" capitalize">{data?.job?.name}</span>
        </div>
      }
    >
      {stage.stageApply === 1 ? (
        <DetailQuestion
          detailData={data?.job}
          helper={helper}
          action={action}
          loading={applyLoad}
        />
      ) : stage.stageApply === 2 ? (
        <div className="text-center my-5 px-16">
          <h4
            className={`${disqualify ? "text-danger" : ""} font-semibold my-5`}
          >
            {disqualify ? "Their loss!" : "Successfully Submitted"}
          </h4>
          <img
            src={Screening}
            alt="screening"
            className=" w-36 h-36 object-cover my-5"
          />
          <Typography.Paragraph className="my-5">
            {disqualify
              ? "Unfortunately 1 or more screening questions did not meet Recruiter’s requirements."
              : "Your application was sent to the Recruiter. Monitor your email for further communication."}
          </Typography.Paragraph>
          <div className=" flex gap-3 justify-center mt-5">
            <Button className=" w-24" onClick={() => navigate("/job/my")}>
              My Jobs
            </Button>
            <Button
              className=" w-24"
              type="primary"
              onClick={() => navigate("/job/board")}
            >
              Job Board
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className=" bg-[#36505E] p-5 text-white">
            <div className=" font-semibold">Green CV</div>
            <div className=" flex gap-5  my-3 items-start">
              <div className="w-20">
                <Avatar
                  size={50}
                  src={user?.data?.data?.avatar || avatarImgs[0]}
                  className=" cursor-pointer"
                  style={{ border: "1px solid #D4F5C5" }}
                />
              </div>
              <div>
                <div>
                  <strong>{`@${user?.data?.data?.nick_name}`}</strong>
                </div>
                <Typography.Paragraph className="text-white">
                  {user?.data?.data?.profile_headline}
                </Typography.Paragraph>
              </div>
            </div>
            <Progress
              percent={user?.data?.data?.profile_strenght || 0}
              showInfo={false}
              strokeColor={{ "0%": "#D4F5C5", "100%": "#87d068" }}
              trailColor={"#d6d6d4"}
            />
            <div>
              <p>
                Profile strength:{" "}
                <span
                  className={`${
                    user?.data?.data?.profile_strenght >= 80
                      ? "text-primary"
                      : "text-danger"
                  }`}
                >{`${user?.data?.data?.profile_strenght || 0}%`}</span>
              </p>
            </div>
            {user?.data?.data?.profile_strenght < 80 && (
              <div className="mt-7 mb-3">
                Go to CV Builder to add more information.
              </div>
            )}
          </div>
          {user?.data?.data?.profile_strenght >= 80 && (
            <Typography.Paragraph className="mt-10">
              Removing hiring bias - your name and profile picture will be
              hidden until you get shortlisted by the recruiter and will remain
              hidden if your application gets rejected.
            </Typography.Paragraph>
          )}
          <div className=" flex justify-end gap-5 mt-10">
            <Button
              style={{ borderColor: "#86C769" }}
              onClick={() => navigate("/dashboard/launchpad/cv-builder")}
            >
              CV Builder
            </Button>
            <Button
              type="primary"
              loading={applyLoad}
              disabled={user?.data?.data?.profile_strenght < 80}
              onClick={() => action.handlePassedStrength()}
            >
              Next
            </Button>
          </div>
        </>
      )}
    </Drawer>
  )
}
