import React, { useEffect } from "react"
import { useForm, Controller } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { Form, Select, Divider } from "antd"
import { CaretDownOutlined } from "@ant-design/icons"

const { Option } = Select

const filterSchema = z.object({
  sort: z.enum(["newest", "oldest", "popular"]),
})

type FilterFormValues = z.infer<typeof filterSchema>

interface FeedFilterProps {
  onFilterChange?: (filters: FilterFormValues) => void
}

const FeedFilter: React.FC<FeedFilterProps> = ({ onFilterChange }) => {
  const { control, watch } = useForm<FilterFormValues>({
    resolver: zodResolver(filterSchema),
    defaultValues: {
      sort: "newest",
    },
  })

  const watchAllFields = watch()

  useEffect(() => {
    onFilterChange?.({ ...watchAllFields })
  }, [watchAllFields, onFilterChange])

  return (
    <div className="">
      <Form layout="inline" className="mt-4 ">
        <Divider className="!border-gray-300 !mb-1 !mt-0 " orientation="right">
          <Controller
            name="sort"
            control={control}
            render={({ field }) => (
              <div className="flex items-center gap-2">
                <label className="text-gray-500">Sort by:</label>
                <Select
                  variant={"borderless"}
                  className="rounded min-w-[100px]"
                  {...field}
                  suffixIcon={<CaretDownOutlined className="text-backdrop" />}
                >
                  <Option value="newest">Newest</Option>
                  <Option value="oldest">Oldest</Option>
                  <Option value="popular">Most Popular</Option>
                </Select>
              </div>
            )}
          />
        </Divider>
      </Form>
    </div>
  )
}

export default FeedFilter
