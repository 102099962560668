import { message } from "antd"
import { useMutation, useQueryClient } from "react-query"
import {
  updateCommunityPost,
  deleteCommunityPost,
  // addPostView,
} from "../apis/feed-post"
import { CollectionType, reactToPostorComment } from "../apis/feed-reaction"
import { AccountContext } from "../../../../common/layout/PageLayout"
import React from "react"
import { ICommunityPost } from "../types/commonn"

export const useCommunityPost = (post: ICommunityPost) => {
  const queryClient = useQueryClient()
  const reactToPost = useMutation(reactToPostorComment)
  // const addView = useMutation(addPostView)
  const account = React.useContext(AccountContext)
  const deletePost = useMutation(deleteCommunityPost)
  const updatePost = useMutation(updateCommunityPost)
  const [liked, setLiked] = React.useState({
    liked: post?.is_liked,
    likesCount: post?.total_responses,
  })

  const isUserPost = account?.data?.data?.id === post?.user?.id

  const handleLike = async () => {
    setLiked({
      liked: true,
      likesCount: liked.likesCount + 1,
    })
    await reactToPost.mutateAsync(
      {
        collection_id: post.id,
        collection: CollectionType.POST,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["community-post-list"])
        },
        onError: (error: any) => {
          setLiked({
            liked: false,
            likesCount: liked.likesCount - 1,
          })
          message.error({
            content:
              error?.response?.data?.message ||
              "Something went wrong whilst commenting, try again!",
          })
          console.error(error)
        },
      },
    )
  }

  const handleDislike = async () => {
    setLiked({
      liked: false,
      likesCount: liked.likesCount - 1,
    })
    await reactToPost.mutateAsync(
      {
        collection_id: post.id,
        collection: CollectionType.POST,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["community-post-list"])
        },
        onError: (error: any) => {
          setLiked({
            liked: true,
            likesCount: liked.likesCount + 1,
          })
          message.error({
            content:
              error?.response?.data?.message ||
              "Something went wrong whilst commenting, try again!",
          })
          console.error(error)
        },
      },
    )
  }

  const handleDelete = async (
    e: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
  ) => {
    e?.stopPropagation()
    await deletePost.mutateAsync(post.id, {
      onSuccess: () => {
        queryClient.invalidateQueries("community-post-list")
        message.success("Post deleted successfully")
      },
      onError: (error: any) => {
        message.error({
          content:
            error?.response?.data?.message ||
            "Something went wrong whilst deleting post, try again!",
        })
        console.error(error)
      },
    })
  }

  const onSubmit = async (data: any) => {
    await updatePost.mutateAsync(
      {
        id: post.id,
        post: data,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("community-post-list")
          message.success("Post updated successfully")
        },
        onError: (error: any) => {
          message.error({
            content:
              error?.response?.data?.message ||
              "Something went wrong whilst updating post, try again!",
          })
          console.error(error)
        },
      },
    )
  }
  return {
    liked,
    isUserPost,
    handleLike,
    handleDislike,
    handleDelete,
    onSubmit,
    updatePost,
  }
}
