import React from "react"
import { Button } from "antd"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"

const RegisterRecruiter = () => {
  const navigate = useNavigate()

  return (
    <div className="w-full h-full bg-primary-bg">
      <div className="px-10 py-3">
        <Button className="" onClick={() => navigate("/register")}>
          <ArrowLeftOutlined className="" /> Back
        </Button>
      </div>
      <div className="flex justify-center items-center ">
        <div id="form-response" />
      </div>
    </div>
  )
}

export default RegisterRecruiter
