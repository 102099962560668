export function openInNewTab(url: string) {
  window.open(url, "_blank")
}

export function restrictToTwoDecimalPlaces(value: number | null | undefined) {
  if (typeof value === "number" && value !== null && value !== undefined) {
    return value.toFixed(2)
  } else {
    return 0
  }
}

export function removeUndefinedKeys<T extends object>(obj: T): Partial<T> {
  return Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(obj).filter(([_, value]) => value !== undefined),
  ) as Partial<T>
}

export function convertToDateString(dateString: string) {
  const date = new Date(dateString)

  if (isNaN(date.getTime())) {
    return {
      date: undefined,
      time: undefined,
    }
  }

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0") // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0")
  const hours = String(date.getHours()).padStart(2, "0")
  const minutes = String(date.getMinutes()).padStart(2, "0")

  const formattedDate = `${year}-${month}-${day}`
  const formattedTime = `${hours}${minutes}`

  return {
    date: formattedDate,
    time: Number(formattedTime),
  }
}

export function isNewUser() {
  return sessionStorage?.getItem("newUser") === "true"
}

export function isNotNullAndUndefined<T>(
  value: T | null | undefined,
): value is T {
  return value !== null && value !== undefined
}

export function isNotNullOrUndefined<T>(
  value: T | null | undefined,
): value is T {
  return value !== null || value !== undefined
}

export function isNotNullOrEmpty<T>(
  value: T[] | null | undefined,
): value is T[] {
  return value !== null && value !== undefined && value.length > 0
}

export function isNotNullOrZero<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined && value !== 0
}

export function isNullOrUndefined<T>(
  value: T | null | undefined,
): value is null | undefined {
  return value === null || value === undefined
}

export function isNullOrEmpty<T>(
  value: T[] | null | undefined,
): value is null | undefined | [] {
  return value === null || value === undefined || value.length === 0
}

export function isNullOrUndefinedOrEmptyString(
  value: string | null | undefined,
): value is null | undefined | "" {
  return value === null || value === undefined || value === ""
}

export function isNullOrUndefinedOrEmptyObject(
  value: object | null | undefined,
): value is null | undefined | {} {
  return (
    value === null || value === undefined || Object.keys(value).length === 0
  )
}

export function isNullOrUndefinedOrEmptyArray(
  value: any[] | null | undefined,
): value is null | undefined | [] {
  return value === null || value === undefined || value.length === 0
}
export const truncateText = (text: string = "", maxLength: number) => {
  if (text?.length > maxLength) {
    return text.slice(0, maxLength) + "..."
  }
  return text
}
