import React from "react"
import { Controller } from "react-hook-form"
import { Button, Form } from "antd"
import FormImageUploader from "../../../../../common/components/FormImageUploader"
import { PlusOutlined, DeleteOutlined, SendOutlined } from "@ant-design/icons"
import NewPostIcons from "../NewPostIcons"
import { useMediaForm } from "../../hooks/useMediaForm"

const MediaForm: React.FC = () => {
  const {
    handleSubmit,
    control,
    formState,
    fields,
    append,
    remove,
    update,
    MAX_MEDIA,
    onSubmit,
    createPost,
    closeModal,
  } = useMediaForm()

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
      className="relative my-6 space-y-6"
    >
      {fields.map((field, index) => (
        <div key={index} className="">
          <div className="flex items-center justify-between mb-3">
            <h3>#{index + 1}</h3>
            {index !== 0 && (
              <Button
                className="text-xl"
                type="link"
                danger
                onClick={() => remove(index)}
              >
                <DeleteOutlined />
              </Button>
            )}
          </div>
          <Controller
            name={`files.${index}.image` as const}
            control={control}
            defaultValue={undefined}
            render={({ field: { onChange, value } }) => (
              <FormImageUploader
                value={value}
                onChange={(file) => {
                  onChange(file)
                  update(index, {
                    image: file,
                  })
                }}
                className=""
                height={300}
              />
            )}
          />
          {formState?.errors?.files?.[index]?.image && (
            <div className="text-danger">
              {formState?.errors?.files?.[index]?.image?.message?.toString()}
            </div>
          )}
        </div>
      ))}
      {fields.length < MAX_MEDIA && (
        <div className="flex justify-center w-full my-4">
          <button
            className="p-4 mx-auto text-white rounded-full cursor-pointer bg-primary"
            onClick={() =>
              append({
                image: [],
              })
            }
          >
            <PlusOutlined className="text-xl" />
          </button>
        </div>
      )}
      <div className="flex items-center justify-between w-full">
        <NewPostIcons />
        <div className="flex items-center gap-4">
          <Button onClick={closeModal}>Cancel</Button>
          <Button
            className="text-white bg-primary"
            loading={createPost.isLoading}
            icon={<SendOutlined />}
            disabled={createPost.isLoading || !formState.isDirty}
            onClick={handleSubmit(onSubmit)}
          >
            Post
          </Button>
        </div>
      </div>
    </Form>
  )
}

export default MediaForm
