/* eslint-disable react/no-unknown-property */
import React from "react"
import { Breadcrumbs } from "../../../common/components"
import { HomeOutlined } from "@ant-design/icons"

const items = [
  {
    path: "/dashboard/launchpad",
    title: <HomeOutlined className="text-gray-400" />,
  },
  {
    title: "Account setting",
  },
]
export const Header = ({ title, breadcrumbs = items }) => {
  return (
    <div className="p-3 px-6 bg-white" style={{ top: "10px" }}>
      <Breadcrumbs items={breadcrumbs} className="" />
      {title && (
        <div className="my-3">
          <h1 level={3} className="">
            {title}
          </h1>
        </div>
      )}
    </div>
  )
}
