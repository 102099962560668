import { useState } from "react"

export const useMenuHeader = (
  props: {
    key: string
    label: string
  }[],
) => {
  const [active, setActive] = useState<{
    key: string
    label: string
  }>(props[0])

  const handleSelectMenu = (val: { key: string; label: string }) => {
    setActive(val)
  }

  return { active, handleSelectMenu }
}
