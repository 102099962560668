export function formatNumber(x: number): string {
  if (x >= 1000) {
    let formattedValue = (x / 1000)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
    return formattedValue.replace(/\.00$/, "") + "k"
  }
  return x.toFixed(2)
  // return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
