import React from "react"
import { EnvironmentOutlined, CheckOutlined } from "@ant-design/icons"
import { Button } from "antd"
import { Job } from "../../types/jobBoard"
import { useModalStore } from "../../../../stores"
import { useMsal } from "@azure/msal-react"

interface Props {
  data: Job | undefined
  action: () => void
}
export const DetailApply: React.FC<Props> = ({ data, action }) => {
  const { instance: user } = useMsal()
  const activeAccount = user?.getActiveAccount()
  const openModal = useModalStore((state) => state.openModal)
  return (
    <div className=" p-5 rounded-xl shadow-md bg-[#fafafa] w-full">
      <div className="flex gap-1  my-1">
        <EnvironmentOutlined />
        <div>{data?.location?.name || "unknown"}</div>
        <div>{data?.remote ? "(remote)" : "(on-site)"}</div>
      </div>
      <div className="my-1">
        <strong>Team/Function: </strong>
        {data?.team || "unknown"}
      </div>
      {helperSalary(
        data?.salary_currency?.code,
        data?.salary_from,
        data?.salary_up_to,
        data?.salary_frequency?.name,
      )}
      <div className="my-1">
        <strong>Type: </strong>
        {data?.job_type?.name || "unknown"}
      </div>
      {!data?.status_application?.has_applied ? (
        <Button
          type="primary"
          className="w-full mt-3"
          onClick={() => {
            if (!activeAccount?.localAccountId) {
              return openModal()
            } else {
              action()
            }
          }}
        >
          Apply with Green CV
        </Button>
      ) : (
        <div className="flex items-center justify-center gap-2 text-primary mt-3">
          <CheckOutlined /> <div>Already applied</div>
        </div>
      )}
    </div>
  )
}

const helperSalary = (
  code?: string,
  salary_min?: number,
  salary_max?: number,
  freq?: string,
) => {
  if (salary_min && salary_max) {
    return (
      <div className="my-1">
        <strong>Gross/Salary: </strong>
        {`${code} ${salary_min.toLocaleString()} - ${code} ${salary_max.toLocaleString()} ${freq}`}
      </div>
    )
  }
  if (salary_min || salary_max) {
    return (
      <div className="my-1">
        <strong>Gross/Salary: </strong>
        {`${code} ${salary_min?.toLocaleString() || 0} - ${
          salary_max?.toLocaleString() || 0
        } ${freq}`}
      </div>
    )
  }
  return null
}
