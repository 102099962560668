import React from "react"
import {
  Language as LanguageType,
  Proficiency,
  Common,
} from "../../../hooks/cv-builder/useLanguage"
import { LanguageEdit } from "./LanguageEdit"
import { LanguageView } from "./LanguageView"

interface Props {
  data: LanguageType
  proficiency: {
    data: Proficiency[] | undefined
    loading: boolean
  }
  commonLanguages: {
    data: Common[] | undefined
    loading: boolean
  }
  index: number
  updateTempData: (index: number, data: LanguageType) => void
  onRemove: (val: string) => Promise<void>
  loadingremoveLanguage: boolean
  onRemoveTemp: (val: number) => void
  tempData: LanguageType[]
}
export const Language: React.FC<Props> = ({
  data,
  proficiency,
  commonLanguages,
  index,
  updateTempData,
  onRemove,
  loadingremoveLanguage,
  onRemoveTemp,
  tempData,
}) => {
  return data.isEdit ? (
    <LanguageEdit
      data={data}
      proficiency={proficiency}
      commonLanguage={commonLanguages}
      index={index}
      updateTempData={updateTempData}
      tempData={tempData}
    />
  ) : (
    <LanguageView
      data={data}
      index={index}
      updateTempData={updateTempData}
      onRemove={onRemove}
      loadingremoveLanguage={loadingremoveLanguage}
      onRemoveTemp={onRemoveTemp}
    />
  )
}
