import { useCallback, useEffect, useRef, useState } from "react"
import { useQuery } from "react-query"
import instance from "../../../../config/config"
import { debounce } from "../../../../utils/debounce"
import {
  ApiResponse,
  PaginatedResponse,
} from "../../../../common/types/ApiResponse"
import { useMsal } from "@azure/msal-react"
import { Company } from "../../types/company"
import { useFollowUnFollowCompany } from "./useCompanyFollowUnFollow"
import { errorMessage } from "../../../../common/utils/utils"

export const useCompanies = (isFollow: boolean) => {
  const { instance: account } = useMsal()
  const activeAccount = account.getActiveAccount()
  const [params, setParams] = useState<{
    page: number
    limit: number
    name: string
    sector?: { label: string; value: string }[]
    hq?: { label: string; value: string }[]
    size?: { label: string; value: string }[]
    user_id?: string
    is_follow: boolean
  }>({
    page: 1,
    limit: 20,
    name: "",
    sector: undefined,
    hq: undefined,
    size: undefined,
    user_id: activeAccount?.localAccountId,
    is_follow: isFollow,
  })
  const [dataList, setDataList] = useState<Company[]>([])
  const [total, setTotal] = useState<number>(0)
  const [dataFollow, setDataFollow] = useState<string[]>([])

  const { handleFollow, handleUnfollow, loading } = useFollowUnFollowCompany()

  const handleFollowCompany = (val: any) => {
    handleFollow(val).then((res) => {
      if (res) {
        setDataFollow((prev) => [...prev, val.id])
      }
    })
  }
  const handleUnfollowCompany = (val: any) => {
    handleUnfollow(val).then((res) => {
      if (res) {
        setDataFollow((prev) => prev.filter((item) => item !== val))
      }
    })
  }

  const { data, isLoading } = useQuery<ApiResponse<PaginatedResponse<Company>>>(
    ["company-lists", params],
    () =>
      instance.get(`/companies`, {
        params: {
          ...params,
          sector: params.sector?.map((item) => item.value).join(",") || "",
          hq: params.hq?.map((item) => item.value).join(",") || "",
          size: params.size?.map((item) => item.value).join(",") || "",
        },
      }),
    {
      onError: (err: any) =>
        errorMessage(err?.response?.data?.data || "internal server error", err),
    },
  )

  const handleChageParams = (newParams: any) => {
    setParams((prev) => ({ ...prev, ...newParams }))
  }

  const handleAddSector = (value: { label: string; value: string }) => {
    if (!params.sector) {
      setParams((prev) => ({ ...prev, sector: [value], page: 1 }))
    } else {
      const newsector = [...params.sector, value]
      setParams((prev) => ({ ...prev, sector: newsector, page: 1 }))
    }
  }
  const handleRemoveSector = (value: string) => {
    const newsector = params?.sector?.filter((loc) => loc.value !== value)
    setParams((prev) => ({ ...prev, sector: newsector, page: 1 }))
  }

  const handleSearch = debounce((value: string) => {
    setParams((prev) => ({ ...prev, name: value, page: 1 }))
  }, 1000)

  const handleAddHQ = (value: { label: string; value: string }) => {
    if (!params.hq) {
      setParams((prev) => ({ ...prev, hq: [value], page: 1 }))
    } else {
      const newhq = [...params.hq, value]
      setParams((prev) => ({ ...prev, hq: newhq, page: 1 }))
    }
  }
  const handleRemoveHQ = (value: string) => {
    const newhq = params?.hq?.filter((hq) => hq.value !== value)
    setParams((prev) => ({ ...prev, hq: newhq, page: 1 }))
  }

  const handleAddSize = (value: { label: string; value: string }) => {
    if (!params.size) {
      setParams((prev) => ({ ...prev, size: [value], page: 1 }))
    } else {
      const newsize = [...params.size, value]
      setParams((prev) => ({ ...prev, size: newsize, page: 1 }))
    }
  }
  const handleRemoveSize = (value: string) => {
    const newsize = params?.size?.filter((size) => size.value !== value)
    setParams((prev) => ({ ...prev, size: newsize, page: 1 }))
  }

  const handleIsFollow = () => {
    setParams((prev) => ({ ...prev, is_follow: !prev.is_follow, page: 1 }))
  }

  //   handle
  const observer = useRef<IntersectionObserver | null>(null)
  const lastCardElementRef = useCallback(
    (node: Element | null) => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          dataList.length &&
          dataList?.length < total &&
          !isLoading
        ) {
          handleChageParams({ page: params.page + 1 })
        }
      })
      if (node) observer.current.observe(node)
    },

    [dataList?.length],
  )

  useEffect(() => {
    if (!isLoading) {
      if (params.page === 1) {
        setDataList(data?.data.data?.list || [])
        setTotal(data?.data.data.total_data || 0)
      } else if (
        dataList?.[dataList?.length - 1]?.id !==
        data?.data.data.list?.[data?.data.data.list?.length - 1]?.id
      ) {
        setDataList((prev) => [...prev, ...(data?.data.data.list || [])])
        setTotal(data?.data.data.total_data || 0)
      }
    }
  }, [data?.data.data.list])

  useEffect(() => {
    setDataFollow(
      dataList?.filter((item) => item.is_favorite).map((item) => item.id),
    )
  }, [dataList])
  return {
    dataList,
    isLoading,
    handleSearch,
    lastCardElementRef,
    params,
    setParams,
    handleAddSector,
    handleRemoveSector,
    handleAddHQ,
    handleRemoveHQ,
    handleAddSize,
    handleRemoveSize,
    handleIsFollow,
    dataFollow,
    handleFollowCompany,
    handleUnfollowCompany,
    loadingFollowUnfollow: loading,
  }
}
